<div [ngBusy]="loadingPromise"
	class="h-100-percent w-100-percent bg-dashboard p-relative d-flex flex-direction-column">
	<div *ngIf="currentBook" class="dashboard-header-tabs hide-pdf bg-secondary">
		<div class="br-book-header d-flex w-100-percent justify-between ph-32 align-items-start">
			<div class="pr-16 overflow-hidden">
				<h1 id="main-heading"
					class="text-ellipsis m-0 __TEST-dashboard-title">
					{{currentBook.name}}</h1>
				<h3 *ngIf="currentBook.description"
					class="text-ellipsis m-0 mt-8 __TEST-dashboard-description">
					{{currentBook.description}}
				</h3>
			</div>
			<fullscreen-autorefresh-panel></fullscreen-autorefresh-panel>

			<div class="pull-right flex-fixed br-dashboard-nav br-book-actions-panel d-flex align-items-center">

				<button *ngIf="canEdit()"
					data-testid="btn-dashboard-edit"
					class="btn btn-primary hidden-fullscreen"
					(click)="processEditButton()"
					[hidden]="isFullscreen()"
					[attr.aria-label]="'dashboard.editAriaLabel' | i18n:{name: currentBook.name}">
					{{'dashboard.editBook' | i18n}}
				</button>

				<button
					*ngIf="canShare()"
					data-testid="btn-dashboard-share"
					class="btn btn-secondary br-book-share-button hidden-fullscreen"
					(click)="shareBook()"
					[attr.aria-label]="'dashboard.shareAriaLabel' | i18n:{name: currentBook.name}"
				>
					{{'dashboard.bookShare' | i18n}}
				</button>

				<fullscreen-button
					class="br-book-fullscreen-button"
					[ariaName]="currentBook.name">
				</fullscreen-button>

				<actions-menu
					[hidden]="isFullscreen()"
					[menuItems]="bookMenuItems"
					[dashboard]="currentBook"
				></actions-menu>
			</div>
		</div>

		<book-tabs *ngIf="!!bookTabs"
			class="w-100-percent mt-8 d-block"
			[tabs]="bookTabs"
			(tabSelect)="selectDashboardTab($event)">
		</book-tabs>
	</div>

	<div class="dashboard-view-container border-pewter-200 w-100-percent h-100-percent p-relative">
		<div *ngFor="let bookTab of bookTabs; let $index = index"
			class="dashboard-tab-view overflow-hidden"
			[class.active]="bookTab.active"
			(keydown.tab)="onTabKeydown($event)"
			[ngSwitch]="bookTab.type">
			<ng-container *ngSwitchCase="'DASHBOARD'">
				<ng-container [ngSwitch]="!!(bookTab.metadata.viewAllowed && !bookTab.metadata.removed)">
					<ng-container *ngSwitchCase="true">
						<div id="active-dashboard-tab"
							*ngIf="bookTab.rendered"
							class="h-100-percent w-100-percent">
							<book-dashboard-view
								[dashboard]="bookTab.metadata.dashboard"
								[dashboardHistory]="bookTab.metadata.dashboardHistory"
								[widgets]="bookTab.metadata.widgets"
								[active]="bookTab.active"
								[index]="$index"
								(editDashboard)="onEditDashboard($event)">
							</book-dashboard-view>
						</div>
					</ng-container>
					<div *ngSwitchCase="false" class="dashboard-tab-message bg-dashboard">
						<div class="m-16"
							*ngIf="bookTab.metadata.removed">
							<h3>{{'dashboard.bookDashboardIsRemoved' | i18n}}</h3>
						</div>
						<div class="m-16"
							*ngIf="!bookTab.metadata.removed && !bookTab.metadata.viewAllowed">
							<h3>{{'dashboard.bookDashboardIsPreventedFromSharing' | i18n}}</h3>
						</div>
						<div class="m-16"
							*ngIf="isMissingSnapshot(bookTab.metadata)">
							<h3>{{'dashboard.dashboardNoPublishedReport' | i18n}}</h3>
						</div>
					</div>
				</ng-container>
			</ng-container>
			<ng-container *ngSwitchCase="'EMBED'">
				<embed-dashboard *ngIf="bookTab.rendered"
					class="h-100-percent w-100-percent"
					[bookId]="currentBook.id"
					[active]="bookTab.active"
					[name]="bookTab.name"
					[metadata]="bookTab.metadata"
					(embedDataLoaded)="updateEmbedData(bookTab, $event)"
				></embed-dashboard>
			</ng-container>
		</div>
	</div>
</div>
