import { Component, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { MetricFormat } from '@cxstudio/metrics/entities/metric-format.class';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

@Component({
	selector: 'simple-number-format-preview',
	template: `
	<p class="mb-0">
		<span *ngIf="!omitPreviewPrefix">{{'widget.preview'|i18n}}:</span>
		{{applyFormat()}}
	</p>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SimpleNumberFormatPreviewComponent implements OnInit {

	readonly FALLBACK_DEMO_NUMBER = 1000654.321;

	@Input() format: MetricFormat;
	@Input() previewValue: number | string; // should be a number but we'll convert it if necessary
	@Input() defaultFormat: NumberFormatSettings;
	@Input() omitPreviewPrefix = true;

	formatter: IFormatBuilder;

	constructor(
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService
	) {}

	ngOnInit(): void {
		this.formatter = this.defaultDataFormatterBuilder.getDefaultFormatterBuilder(this.format as CalculationWithFormat);
		let value = Number(this.previewValue);
		this.previewValue = isNaN(value) ? this.FALLBACK_DEMO_NUMBER : value;
	}

	applyFormat = () => {
		return this.formatter.format(this.previewValue, this.format as CalculationWithFormat, this.defaultFormat, {ignoreAlignment: true});
	};
}

app.directive('simpleNumberFormatPreview', downgradeComponent({component: SimpleNumberFormatPreviewComponent}));
