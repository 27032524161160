import { PeriodOverPeriodMetric } from './period-over-period-metric';
import { HistoricalMetric } from './historical-metric';
import { DeltaMetric } from './delta-metric';
import { PercentChangeMetric } from './percent-change-metric';
import { PValueMetric } from './p-value-metric';
import { SignificanceMetric } from './significance-metric';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import ILocale from '@cxstudio/interfaces/locale-interface';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';

export class PeriodOverPeriodMetricService {
	readonly popMetrics: PeriodOverPeriodMetric[];

	constructor(
		private locale: ILocale,
		private dashboardFiltersService: DashboardFiltersService,
		private DateRange,
		historicalMetric: HistoricalMetric,
		deltaMetric: DeltaMetric,
		percentChangeMetric: PercentChangeMetric,
		pValueMetric: PValueMetric,
		significanceMetric: SignificanceMetric,
	) {
		this.popMetrics = [
			historicalMetric, deltaMetric, percentChangeMetric, pValueMetric, significanceMetric
		];
	}

	createMetric = (parentMetric: ReportCalculation, type: PeriodOverPeriodMetricType): ReportCalculation => {
		let periodOverPeriodMetric = this.getPeriodOverPeriodMetricByType(type);

		let name = periodOverPeriodMetric.getName(parentMetric.name);
		let displayName = periodOverPeriodMetric.getDisplayName(parentMetric.displayName);

		let metric = angular.copy(parentMetric);
		delete metric.dataType;
		metric.name = name;
		metric.displayName = displayName;
		metric.isPopMetric = true;
		metric.parentMetricName = parentMetric.name;
		metric.parentMetricDisplayName = parentMetric.displayName;
		metric.parentMetricId = parentMetric.id;

		return periodOverPeriodMetric.applyFormatting(metric);
	};

	isPoPMetricOfType = (metric: ReportCalculation, type: PeriodOverPeriodMetricType): boolean => {
		return this.getPeriodOverPeriodMetric(metric) === this.getPeriodOverPeriodMetricByType(type);
	};

	getName = (parentMetric: ReportCalculation, type: PeriodOverPeriodMetricType): string => {
		return this.getPeriodOverPeriodMetricByType(type)?.getName(parentMetric.name);
	};

	getPeriodOverPeriodMetric = (metric: ReportCalculation): PeriodOverPeriodMetric => {
		if (!metric.isPopMetric) return;
		return this.popMetrics.find(popMetric => metric.name.startsWith(popMetric.getNamePrefix()));
	};

	getPeriodOverPeriodMetricByPrefix = (prefix: string): PeriodOverPeriodMetric => {
		return this.popMetrics.find(popMetric => prefix === popMetric.getNamePrefix());
	};

	getPeriodOverPeriodMetricByType = (type: PeriodOverPeriodMetricType): PeriodOverPeriodMetric => {
		return this.popMetrics.find(popMetric => type === popMetric.getType());
	};

	getActualPeriodOverPeriodMetricDisplayName = (metric: ReportCalculation, actualParentMetricDisplayName: string): string => {
		let popMetricType = this.getPeriodOverPeriodMetric(metric);
		return popMetricType
			? popMetricType.getDisplayName(actualParentMetricDisplayName)
			: actualParentMetricDisplayName;
	};

	// figure out which of several options to use for metric period labels
	getPeriodLabel = (period: 1 | 2, options: VisualProperties, props: WidgetProperties, containerId: string): string => {
		let periodLabelKey =  `period_${period}_`;
		if ((options.periodLabel && options.periodLabel[periodLabelKey]
				&& options.periodLabel[periodLabelKey].trim().length > 0)) {
			return options.periodLabel[periodLabelKey];
		}

		let activeFilterMode = this.dashboardFiltersService.getActiveDateFilterMode(props, period, containerId);
		if (activeFilterMode && activeFilterMode !== this.DateRange.options.CUSTOM.value) {
			return this.DateRange.valueOf(activeFilterMode).displayName;
		}
	};

	periodHasCustomLabel = (period: 1 | 2, options): boolean => {
		let periodLabelKey =  `period_${period}_`;
		return options?.periodLabel?.[periodLabelKey]?.trim().length > 0;
	};

	getMetricDisplayNameWithPeriodPrefix = (metric: ReportCalculation, visualProps): string => {
		let metricDisplayName = metric.displayName;
		if (metric.isPopMetric && metric.name.indexOf(ReportPeriods.HISTORIC) === 0) { //isHistoricMetric
			if (metric.parentMetricDisplayName) {
				metricDisplayName = metric.parentMetricDisplayName;
			} else {
				return metric.displayName;
			}
			metricDisplayName = this.getPeriodPrefix(visualProps,
				ReportPeriods.HISTORIC, this.locale.getString('widget.historic')) + metricDisplayName;
		} else if (!metric.isPopMetric) {
			metricDisplayName = this.getPeriodPrefix(visualProps,
				ReportPeriods.CURRENT, '') + metricDisplayName;
		}

		return metricDisplayName;
	};

	isStatisticalMetricsSupported = (metric: ReportCalculation): boolean => {
		return this.getPeriodOverPeriodMetricByType(PeriodOverPeriodMetricType.P_VALUE)?.isSupported(metric, []);
	};

	private getPeriodPrefix(visualProps: VisualProperties, period: ReportPeriods, defaultValue: string): string {
		let prefix;
		if (visualProps?.periodLabel) {
			prefix = visualProps.periodLabel[period];
		}
		prefix = (!prefix || prefix === '') ? defaultValue : prefix;
		return prefix === '' ? prefix : prefix + ' ';
	}

	getAllHistoricalMetrics = (metric: ReportCalculation): ReportCalculation[] => {
		return [
			this.createMetric(metric, PeriodOverPeriodMetricType.DELTA),
			this.createMetric(metric, PeriodOverPeriodMetricType.HISTORICAL),
			this.createMetric(metric, PeriodOverPeriodMetricType.PERCENT_CHANGE),
			this.createMetric(metric, PeriodOverPeriodMetricType.P_VALUE),
			this.createMetric(metric, PeriodOverPeriodMetricType.SIGNIFICANCE)
		];
	};
}

app.service('periodOverPeriodMetricService', PeriodOverPeriodMetricService);
