import { IAttributeValuesParams } from '@app/modules/project/attribute/attributes.service';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpParams } from '@angular/common/http';
import { CacheOptions } from '@cxstudio/common/cache-options';

@Injectable({
	providedIn: 'root'
})
export class StudioAdminProjectAPI {
	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	getStudioAdminProjectSuggestions = (params: Partial<IAttributeValuesParams>): Promise<string[]> => {
		let config = {
			cache: CacheOptions.CACHED,
			params: new HttpParams()
				.append('attributeName', params.attributeNames[0])
				.append('filter', params.filter ?? '')
		};
		return this.cxHttp.get('rest/studio-admin/filter/suggestions', config);
	};
}
