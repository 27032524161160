import {
	Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output,
	ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef
} from '@angular/core';
import { UntypedFormControl, NgForm } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { IOAuthAuthorizedGrantType, OAuthAuthorizedGrantTypes } from '../oauth-authorized-grant-type.factory';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';
import OAuthScope from '../oauth-scope';

export interface OAuthClientDetailsDesignerScopeChangedEvent {
	scope: OAuthScope[];
	designerScopeValid: boolean;
	autoApprovedScope: string[];
}

export interface OAuthDesignerClientScope {
	scope: OAuthScope;
	autoApprove: boolean;
	authorizedGrantTypes: any;
	id: number;
}

@Component({
	selector: 'oauth-client-details-designer-scope',
	templateUrl: './oauth-client-details-designer-scope.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class OauthClientDetailsDesignerScopeComponent implements OnInit, AfterViewInit, OnDestroy {
	@Output() designerScopeUpdated = new EventEmitter<OAuthClientDetailsDesignerScopeChangedEvent>();
	@Input() clientDetails: OAuthClientDetails;
	@ViewChild('designerScopeForm') public designerScopeForm: NgForm;

	designerScopes: OAuthDesignerClientScope[];

	grantTypes: IOAuthAuthorizedGrantType[];
	private processScopeAddedRemoved: boolean = false;
	scopeSubscription: Subscription;

	constructor(
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		private ref: ChangeDetectorRef
	) { }

	ngOnInit(): void {
		this.grantTypes = this.oauthAuthorizedGrantTypes.getAllGrantTypes();
		this.initDesignerScopes();
	}

	ngOnDestroy(): void {
		this.scopeSubscription.unsubscribe();
	}

	// after form is rendered, register additional handlers:
	ngAfterViewInit(): void {
		this.scopeSubscription = this.designerScopeForm.valueChanges.subscribe(this.scopeAddedRemovedObserver);
	}

	private scopeAddedRemovedObserver = (): void => {
		//handler for added or removed scopes
		if (!this.processScopeAddedRemoved) {
			return;
		}

		//Check form and model for consistency. Render might be incomplete.
		const expectedInputFieldsNumer = this.designerScopes.length;
		const scopeInputsNumber = _.filter(Object.keys(this.designerScopeForm.controls),
			(scope: string) => scope.startsWith('scopeName_')).length;
		if (scopeInputsNumber !== expectedInputFieldsNumer) {
			return;
		}

		for (let i: number = 0; i < this.designerScopes.length; i++) {
			const item: OAuthDesignerClientScope = this.designerScopes[i];
			const nameControl: UntypedFormControl = this.designerScopeForm.form.get('scopeName_' + i) as UntypedFormControl;
			if (_.isUndefined(nameControl) || _.isNull(nameControl)) {
				return;
			}
			if (nameControl.value !== item.scope.name) {
				return;
			}
			nameControl.markAsDirty();
			nameControl.markAsTouched();
		}

		this.processScopeAddedRemoved = false;
		this.ref.markForCheck();
		this.updateDesignerScope();
	};

	private initDesignerScopes(): void {
		this.designerScopes = [];
		(this.clientDetails.scope || []).forEach((scope: OAuthScope, index: number) => {
			const scopeDescription: OAuthDesignerClientScope = {
				scope,
				autoApprove: this.clientDetails.autoApproveScopes.contains(scope.name),
				id: index,
				authorizedGrantTypes: this.getScopeAuthorizedGrantTypes(scope)
			};
			this.designerScopes.push(scopeDescription);
		});
	}

	private getScopeAuthorizedGrantTypes = (scope: OAuthScope): any => {
		const authorizedGrantTypes: any = {};
		(scope.authorizedGrantTypes || []).forEach(grantType => {
			authorizedGrantTypes[grantType] = true;
		});
		return authorizedGrantTypes;
	};

	updateDesignerScope = (): void => {
		const newScope: OAuthScope[] = [];
		const autoApprovedScope: string[] = [];

		this.designerScopes.forEach(scopeDescription => {
			const scope = scopeDescription.scope;
			if (scopeDescription.autoApprove) {
				autoApprovedScope.push(scope.name);
			}
			scope.authorizedGrantTypes = [];
			Object.keys(scopeDescription.authorizedGrantTypes).forEach((key) => {
				if (scopeDescription.authorizedGrantTypes[key]) {
					scope.authorizedGrantTypes.push(key);
				}
			});
			newScope.push(scope);
		});

		this.designerScopeUpdated.emit({
			scope: newScope,
			designerScopeValid: this.designerScopeForm.valid,
			autoApprovedScope
		});
	};

	addDesignerScope = (): void => {
		const scope = new OAuthScope();
		scope.explicit = false;
		scope.extendable = false;
		const scopeDescription: OAuthDesignerClientScope = {
			scope,
			autoApprove: false,
			id: 0,
			authorizedGrantTypes: {}
		};
		this.designerScopes.unshift(scopeDescription);
		this.assignScopeIds();
		this.processScopeAddedRemoved = true;
	};

	removeDesignerScope = (index: number): void => {
		this.designerScopes.splice(index, 1);
		this.assignScopeIds();
		this.processScopeAddedRemoved = true;
	};

	private assignScopeIds = (): void => {
		this.designerScopes.forEach((scope: any, index: number) => {
			scope.id = index;
		});
	};

	trackDesignerScope = (index: number, scope: any): string => {
		// needed to ensure the form is working correctly when input is added or removed.
		return scope.id as unknown as string;
	};

	scopeSelected(): boolean {
		if (this.clientDetails.applicationKind === OauthClientDetailsApplicationKind.CX_SUITE) {
			// allow empty scopes for suite clients (effectively meaning any scope is acceptable)
			return true;
		}
		return !_.isUndefined(this.designerScopes) && this.designerScopes.length > 0;
	}

}

app.directive('oauthClientDetailsDesignerScopeComponent',
	downgradeComponent({ component: OauthClientDetailsDesignerScopeComponent }));
