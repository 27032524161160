import { Injectable, Inject } from '@angular/core';
import { CxHttpService, CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { HomePageReportProperties } from '@app/modules/home-page/home-page-common/entities/home-page-report-properties';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';

@Injectable({
	providedIn: 'root'
})
export class HomePageApiService {
	readonly HOME_PAGE_ENDPOINT = 'rest/home-pages';

	constructor(
		private cxHttp: CxHttpService,
		private locale: CxLocaleService,
		@Inject('httpHandlers') private httpHandlers: HttpHandlers,
		@Inject('cachedHttpService') private cachedHttpService: CachedHttpService,
	) {}

	getHomePages(): Promise<HomePage[]> {
		return this.cxHttp.get(this.HOME_PAGE_ENDPOINT).then(this.localizeDefaultHomePage);
	}

	private localizeDefaultHomePage = (homePages: HomePage[]): HomePage[] => {
		let defaultPage = _.find(homePages, {default: true});
		if (!defaultPage.name) {
			defaultPage.name = this.locale.getString('homePage.defaultName');
		}
		return homePages;
	};

	createHomePage(homePage: HomePage): Promise<void> {
		return this.cxHttp.post(`${this.HOME_PAGE_ENDPOINT}`, homePage);
	}

	copyHomePage(homePage: HomePage): Promise<void> {
		return this.cxHttp.post(`${this.HOME_PAGE_ENDPOINT}/${homePage.id}/copy`, homePage.name);
	}

	updateHomePage(homePage: HomePage): Promise<void> {
		return this.cxHttp.put(`${this.HOME_PAGE_ENDPOINT}/${homePage.id}`, homePage);
	}

	deleteHomePage(id: number): Promise<void> {
		return this.cxHttp.delete(`${this.HOME_PAGE_ENDPOINT}/${id}`);
	}

	disableHomePage(id: number): Promise<void> {
		return this.cxHttp.put(`${this.HOME_PAGE_ENDPOINT}/${id}/disable`);
	}

	enableHomePage(id: number): Promise<void> {
		return this.cxHttp.put(`${this.HOME_PAGE_ENDPOINT}/${id}/enable`);
	}

	getHomePageAccess(id: number) {
		return this.cxHttp.get(`${this.HOME_PAGE_ENDPOINT}/${id}/access`);
	}

	setHomePageRank(id: number, rank: number) {
		return this.cxHttp.put(`${this.HOME_PAGE_ENDPOINT}/${id}/rank/${rank}`);
	}

	getOwnerCandidates = (search: string, reportProperties: HomePageReportProperties): Promise<string[]> => {
		let config = this.getOwnerCandidatesConfig(search, reportProperties);
		return this.cxHttp.get(`${this.HOME_PAGE_ENDPOINT}/owner-candidates`, config);
	};

	getConfiguredWidgets(homePageId: number): Promise<Widget[]> {
		return this.cxHttp.get(`${this.HOME_PAGE_ENDPOINT}/${homePageId}/configured-widgets`);
	}

	private getOwnerCandidatesConfig(searchString: string, reportProperties: HomePageReportProperties): CxHttpRequestOptions<HttpParams> {
		return {
			params: new HttpParams()
				.append('search', searchString)
				.append('limit', '10')
				.append('contentProviderId', reportProperties.contentProviderId.toString())
				.append('accountId', reportProperties.accountId.toString())
				.append('projectId', reportProperties.projectId.toString())
		};
	}

	getUserHomePages(): Promise<HomePage[]> {
		return this.cxHttp.get('rest/home-page/list').then(this.localizeDefaultHomePage);
	}

	getQuickInsightsWidgets(homePageId: number): Promise<Widget[]> {
		return this.cxHttp.get(`rest/home-page/${homePageId}/quick-insights`);
	}

	getCustomWidgets(homePageId: number): Promise<Widget[]> {
		return this.cxHttp.get(`rest/home-page/${homePageId}/custom-widgets`);
	}

	getSearchAttributes(homePageId: number): Promise<IReportAttribute[]> {
		let url = `rest/home-page/${homePageId}/search-attributes`;
		let cache = CacheOptions.CACHED;

		return this.cachedHttpService.cache(Caches.ATTRIBUTES)
			.get(url, { cache })
			.then(this.httpHandlers.success) as unknown as Promise<IReportAttribute[]>;
	}

	copyWidgetToDashboard(homePageId: number, widgetId: number, dashboard: HomePageApiDashboard): Promise<Dashboard> {
		return this.cxHttp.post(`rest/home-page/${homePageId}/copy/${widgetId}`, dashboard);
	}

	logSearchEvent(homePageId: number, searchText: string): Promise<void> {
		return this.cxHttp.post(`rest/home-page/${homePageId}/search`, searchText);
	}

	logSearchSelectEvent(homePageId: number, objectName: string): Promise<void> {
		return this.cxHttp.post(`rest/home-page/${homePageId}/search/select`, objectName);
	}

	logViewEvent(homePageId: number): Promise<void> {
		return this.cxHttp.post(`rest/home-page/${homePageId}/view`);
	}
}

export interface HomePageApiDashboard {
	dashboardId?: number;
	widgetName: string;
	widgetDescription: string;
	name: string;
	query: string;
}
