import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { ContentProviderAccount } from '../system-administration/content-provider/content-provider-account';
import { ContentProviderApi } from '../system-administration/content-provider/content-provider.api.service';
import * as cloneDeep from 'lodash.clonedeep';
import { PromiseUtils } from '@app/util/promise-utils';
import { ContentProvider } from '../system-administration/content-provider/content-provider';
import { AccountId, ContentProviderId } from '@cxstudio/generic-types';
import { TagsService } from '../account-administration/properties/tags.service';
import { RestUserData } from './entities/rest-user-data';
import { UserUpdateDataRequest } from './entities/user-update-data-request';

export interface ContentProviderWithAccounts extends ContentProvider {
	accounts: ContentProviderAccount[];
}

@Injectable({
	providedIn: 'root'
})
export class UserManagementHelperService {

	constructor(
		private readonly tagService: TagsService,
		@Inject('security') private readonly security: Security,
		private readonly contentProviderApiService: ContentProviderApi) {
	}

	getLinkedToCurrentUserAccounts = (accountsMap, accountsMapDiff?): Promise<ContentProviderWithAccounts[]> => {
		let deferred = PromiseUtils.defer<ContentProviderWithAccounts[]>();

		if (_.isUndefined(accountsMapDiff)) {
			accountsMapDiff = {
				remained: accountsMap
			};
		}

		this.contentProviderApiService.getContentProviderList().then((contentProviders: any[]) => {
			if (!contentProviders) {
				return;
			}

			let added = accountsMapDiff.added ? accountsMapDiff.added : [];
			let remained = accountsMapDiff.remained ? accountsMapDiff.remained : [];
			if (accountsMap) {
				let filteredCps = this.filterSelectedCps(contentProviders, accountsMap);
				contentProviders = filteredCps;
			}

			let accountsPromises = contentProviders.map((contentProvider) => {
				return this.contentProviderApiService.getContentProviderAccounts(contentProvider.id)
					.then((accounts) => {
						if (accountsMap) {
							let addedAccounts = added[contentProvider.id];
							let filteredAddedAccounts = addedAccounts ?
								this.filterSelectedAccounts(
									accounts, addedAccounts, 'add') : [];
							let remainedAccounts = remained[contentProvider.id];
							let filteredRemainedAccounts = remainedAccounts ?
								this.filterSelectedAccounts(
									accounts, remainedAccounts, 'update') : [];
							accounts = filteredAddedAccounts.concat(
								filteredRemainedAccounts);
						}

						contentProvider.accounts = accounts;
					});
			});

			Promise.all(accountsPromises as any[]).then(() => {
				let filtered = this.filterCpsWithAccounts(contentProviders);
				deferred.resolve(filtered);
			});
		});
		return deferred.promise;
	};

	private filterSelectedCps(providers, accountsMap): any[] {
		let selectedCps = [];
		let keys = Object.keys(accountsMap);
		for (let key of keys) {
			let cpId = parseInt(key, 10);
			if (accountsMap[cpId].length > 0) {
				let provider = _.findWhere(providers, {id: cpId});
				if (provider) {
					selectedCps.push(provider);
				}
			}
		}
		return selectedCps;
	}

	private filterSelectedAccounts(accounts: any[], accountsList, state): any[] {
		let selectedAccounts = [];
		for (let accountId of accountsList) {
			let account = _.findWhere(accounts, { accountId });
			if (account) {
				account.state = state;
				selectedAccounts.push(account);
			}
		}
		return selectedAccounts;
	}

	private filterCpsWithAccounts(contentProviders): any[] {
		let result = [];
		for (let cp of contentProviders) {
			if (cp.accounts.length > 0) {
				result.push(cp);
			}
		}
		return result;
	}

	getRestUserData = (uiUser): RestUserData => {
		let userData = {
			userId: uiUser.userId,
			firstName: uiUser.firstName,
			lastName: uiUser.lastName,
			userEmail: uiUser.email,
			countryCode: uiUser.countryCode,
			authUniqueId: uiUser.authUniqueId,
			xmAccountId: uiUser.xmAccountId,
			tags: this.tagService.tagObjectsToStringArray(uiUser.tags),
			password: uiUser.password,
			licenseTypeId: uiUser.licenseTypeId,
			registered: !uiUser.forceRegistration,
			defaultMasterAccountId: uiUser.defaultMasterAccountId,
			masterAccountPermissions: uiUser.permissions,
			systemAdministrator: uiUser.systemAdministrator,
			liteAdmin: uiUser.liteAdmin,
			studioApiUser: uiUser.studioApiUser,
			platformApiUser: uiUser.platformApiUser,
			manageInternalTemplates: uiUser.manageInternalTemplates,
			shareInternalTemplates: uiUser.shareInternalTemplates,
			createInternalTemplates: uiUser.createInternalTemplates,
			downloadTrainingData: uiUser.downloadTrainingData,
			customerAdmin: uiUser.customerAdmin,
			customField: uiUser.customField
		};

		// call does not allow these properties to be empty string.
		// they either need to have data or be completely omitted.
		if (userData.hasOwnProperty('firstName')
				&& (typeof userData.firstName === 'string')
				&& userData.firstName.length < 1) {
			delete userData.firstName;
		}
		if (userData.hasOwnProperty('lastName')
				&& (typeof userData.lastName === 'string')
				&& userData.lastName.length < 1) {
			delete userData.lastName;
		}

		return userData;
	};

	getUserUpdateRequestData = (user): UserUpdateDataRequest => {
		return {
			userId: user.userId,
			firstName: user.firstName,
			lastName: user.lastName,
			userEmail: user.email,
			password: user.password,
			integrationData: {
				authUniqueId: user.authUniqueId,
				xmAccountId: user.xmAccountId
			},
			securityData: {
				defaultMasterAccountId: user.defaultMasterAccountId,
				licenseTypeId: user.licenseTypeId,
				tags: this.tagService.tagObjectsToStringArray(user.tags),
				permissions: user.permissions,
				specialRights: {
					systemAdmin: user.systemAdministrator,
					liteAdmin: user.liteAdmin,
					customerAdmin: user.customerAdmin,
					studioApiUser: user.studioApiUser,
					platformApiUser: user.platformApiUser,
					allowManageTemplates: user.manageInternalTemplates,
					allowShareTemplates: user.shareInternalTemplates,
					allowCreateTemplates: user.createInternalTemplates,
					allowDownloadTrainingData: user.downloadTrainingData
				}
			},
			metadata: {
				registered: !user.forceRegistration,
				customField: user.customField,
				countryCode: user.countryCode
			}
		};
	};

	isEmptyLinkingAccounts = (linkingInfo) => {
		return $.isEmptyObject(linkingInfo.remainedAccounts)
			&& $.isEmptyObject(linkingInfo.removedAccounts)
			&& $.isEmptyObject(linkingInfo.addedAccounts);
	};

	getLinkedAccountsChange = (current, previous) => {
		let added = cloneDeep(current);
		let remained = cloneDeep(previous);
		let removed = {};

		let cpId: ContentProviderId;
		let accountIdx;
		let accountId: AccountId;
		let previousCpAccounts;
		let currentCpAccounts;

		for ((cpId as unknown as string) in previous) {
			if (!current[cpId]) {
				removed[cpId] = previous[cpId];
				if (cpId in added) {
					//delete empty accountsIds array
					delete added[cpId];
				}
			} else {
				previousCpAccounts = previous[cpId];
				currentCpAccounts = added[cpId];

				for (accountIdx = 0; accountIdx < previousCpAccounts.length; accountIdx++) {
					accountId = previousCpAccounts[accountIdx];
					if (currentCpAccounts.indexOf(accountId) !== -1) {
						currentCpAccounts.remove(accountId);
					} else {
						if (removed[cpId]) {
							removed[cpId].push(accountId);
						} else {
							removed[cpId] = [accountId];
						}

						if (remained[cpId]) {
							if (remained[cpId].indexOf(accountId) !== -1) {
								remained[cpId].remove(accountId);
							}

							if (!remained[cpId].length) {
								delete remained[cpId];
							}
						}
					}
				}

				if (!added[cpId].length) {
					delete added[cpId];
				}
			}
		}

		let diff = {
			added,
			removed,
			remained
		};

		return diff;
	};

	isOnlyOneAccountSelected = (userAccountsMap): boolean => {
		if (_.isUndefined(userAccountsMap)) {
			return false;
		}
		let contentProviderIds = Object.keys(userAccountsMap);
		if (contentProviderIds.length !== 1) {
			return false;
		}

		let contentProviderId = contentProviderIds[0];
		let accountIds = userAccountsMap[contentProviderId];
		return accountIds.length === 1;
	};

	isSelfCustomerAdmin = (userId: number): boolean => {
		return this.security.isCustomerAdmin() && this.security.getUser().userId === userId;
	};
}

app.service('userManagementHelper', UserManagementHelperService);
