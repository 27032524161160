import { Security } from '@cxstudio/auth/security-service';
import { IWindowService } from 'angular';
import { Logger } from './logger.service';
import { EnvironmentService } from './environment-service';

export class ExternalAuthService {

	constructor(
		private security: Security,
		private $window: IWindowService,
		private logger: Logger,
		private environmentService: EnvironmentService,
	) {}

	tryExpireExternalSession = (): boolean => {
		if (!!this.hasExternalLandingPage()) {
			this.redirectToAuthInitiatingPage();
			return true;
		} else {
			return false;
		}
	};

	hasExternalLandingPage(): boolean {
		return !!this.security.getContext()?.authLandingPage;
	}

	private redirectToAuthInitiatingPage = (): void => {
		let redirectTo = this.security.getContext().authLandingPage;
		this.logger.logExternalSessionExpired(redirectTo);

		this.security.cleanSession();
		this.security.setRedirectionInProgress();
		if (this.environmentService.isIframe()) {
			this.$window.location.reload();
		} else {
			this.$window.location.href = redirectTo;
		}
	};
}

app.service('externalAuthService', ExternalAuthService);
