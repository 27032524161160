import { Component, forwardRef, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListOption } from '@app/shared/components/forms/list-option';

@Component({
	selector: 'radio-buttons',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => RadioButtonsComponent), multi: true},
	],
	template: `
	<div class="btn-group">
		<button *ngFor="let option of options"
			class="btn option-{{option.value}}"
			[ngClass]="{'btn-selected': isOptionSelected(option), 'w-100-percent': fillWidth}"
			(click)="select(option)"
			[disabled]="isOptionDisabled(option)">
			{{option.name}}
		</button>
	</div>
	`
})
export class RadioButtonsComponent<T> implements ControlValueAccessor, OnInit {

	@Input() options: ListOption<T>[];
	@Input() disabled: boolean;
	@Input() fillWidth: boolean;
	// (ngModelChange) breaks component and everything beneath it in some cases,
	// e.g. in object-viewer-type-selector. Already spent hours figuring out the root cause, so using this hack to get around that
	@Output() onChange = new EventEmitter<T>();

	value: T;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: T) => void = _.noop;

	constructor(
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		// without this the initial value of radio-buttons remains null when passing value from parent component
		setTimeout(() => this.ref.markForCheck());
	}

	isOptionDisabled = (option: ListOption<T>): boolean => {
		return option.disabled || this.disabled;
	};

	isOptionSelected = (option: ListOption<T>): boolean => {
		return option.value === this.value;
	};

	select = (option: ListOption<T>): void => {
		if (this.isOptionSelected(option))
			return;
		this.value = option.value;
		this.onChangeCallback(this.value);
		this.onChange.emit(this.value);
		this.onTouchedCallback();
	};

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value !== this.value) {
			this.value = value;
			this.onChangeCallback(this.value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.ref.markForCheck();
	}
}
