import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class RubricDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let scorecard = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		if (!_.isNumber(scorecard?.id) || _.isNaN(scorecard?.id)) {
			return '';
		}
		let total = this.getTotal(data);
		return this.utils.getWidgetUtils(this.widget).then(utils => {
			let rubric = _.findWhere(utils.scorecards, { id: scorecard.id });
			return this.locale.getString('widgetDescription.rubricViewerDescription', {
				rubric: rubric?.name || scorecard.name,
				total
			});
		}) as any;
	};

	private getTotal(data?: any): string {
		let total = this.visual.normalized ? data?.metadata?.totalN : data?.metadata?.total;
		return this.visual.showSampleSize && total >= 0
			? this.locale.getString('widgetDescription.withTotal', { total })
			: '';
	}

}
