import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { AssetTemplateActions, AssetTemplateActionsFactoryService } from '@app/modules/unified-templates/common-templates/asset-template-actions-factory.service';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { AssetTemplateMenu, AssetTemplateMenuFactoryService } from '@app/modules/unified-templates/common-templates/asset-template-menu-factory.service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { UnifiedTemplate, UnifiedTemplateTreeItem } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { ColDef, GetContextMenuItemsParams, GridApi, GridReadyEvent, RowNode } from 'ag-grid-enterprise';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { TemplateTransferModalComponent } from '@app/modules/unified-templates/template-transfer-modal/template-transfer-modal.component';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

@Component({
	selector: 'asset-templates-table',
	templateUrl: './asset-templates-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetTemplatesTableComponent extends SelfCleaningComponent implements OnInit {
	@Input() searchLabel: string;
	@Input() assetType: TemplateAssetType;

	searchText: string;
	loading: Promise<any>;
	columnDefs: ColDef[];
	templates: (UnifiedTemplateTreeItem | IFolderItem)[];

	gridApi: GridApi;

	assetTemplateActions: AssetTemplateActions;
	assetTemplateMenu: AssetTemplateMenu;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private objectListColumns: ObjectListColumnsService,
		private assetTemplateApi: AssetTemplateApiService,
		private assetTemplateActionsFactory: AssetTemplateActionsFactoryService,
		private assetTemplateMenuFactory: AssetTemplateMenuFactoryService,
		private betaFeaturesService: BetaFeaturesService,
		private cxDialogService: CxDialogService,
		@Inject('authorization') private authorization: Authorization,
		@Inject('security') private readonly security: Security,
	) {
		super();
	}

	ngOnInit(): void {
		this.assetTemplateActions = this.assetTemplateActionsFactory.get(this.assetType);
		this.assetTemplateMenu = this.assetTemplateMenuFactory.get(this.assetTemplateActions);

		this.addSubscription(this.assetTemplateActions.getChangeObserver().subscribe(changes => {
			if (!_.isEmpty(changes)) {
				this.gridApi.applyTransaction({update: changes});
				this.ref.markForCheck();
			} else {
				this.reloadTree();
			}
		}));

		this.addSubscription(this.assetTemplateActions.getLoadingObserver().subscribe(loading => {
			this.loading = Promise.all([this.loading, loading]);
			this.ref.markForCheck();
		}));

		this.columnDefs = [
			this.objectListColumns.contextMenuColumn({isVisible: (item) => this.hasMenu(item)}),
			this.objectListColumns.textColumn('description', this.locale.getString('common.description')),
			this.objectListColumns.dateColumn('createdDate', this.locale.getString('dashboard.createdDate')),
			this.objectListColumns.dateColumn('modifiedDate', this.locale.getString('templates.lastModified')),
			this.objectListColumns.ownerColumn('ownerName', this.locale.getString('common.owner')),
			this.objectListColumns.sharingStatusRendererColumn(),
		];
		this.reloadTree();
	}

	private reloadTree(): void {
		this.loading = this.assetTemplateApi.getTemplatesTree(this.assetType).then(templatesTree => {
			this.templates = templatesTree;
			this.ref.markForCheck();
		});
		this.ref.markForCheck();
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
	}

	getContextMenuItems = (params: GetContextMenuItemsParams) => {
		if (!params.node) {
			return;
		}
		if (!this.hasMenu(params.node.data))
			return [];
		return this.assetTemplateMenu.getMenuOptions(params.node as RowNode, this.templates);
	};

	canManageTemplates(): boolean {
		return this.authorization.hasManageTemplatePermission();
	}

	addFolder(): void {
		this.assetTemplateActions.createFolder(this.templates);
	}

	onTemplateAction(template: UnifiedTemplate): void {
		if (this.security.has(MasterAccountPermissionAction.CREATE_METRIC)) {
			this.assetTemplateActions.createFromTemplate(template);
		}
	}

	private hasMenu(item: UnifiedTemplateTreeItem): boolean {
		return !FolderUtils.isFolder(item as any)
			|| this.security.has(ApplicationPermissionAction.MANAGE_INTERNAL_TEMPLATES);
	}

	canTransferTemplates = (): boolean => {
		return this.security.isAnyAdmin() && this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATES_IMPORT_EXPORT)
			&& this.assetType === TemplateAssetType.DASHBOARD;
	};

	transferTemplates = () => {
		this.cxDialogService.openDialog(TemplateTransferModalComponent, {type: this.assetType}).result.finally(() => this.reloadTree());
	};
}
