
import * as _ from 'underscore';
import { Security } from '@cxstudio/auth/security-service';
import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterValidationMessages } from '@cxstudio/report-filters/constants/filter-validation-messages.constant';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { IDateRuleDefinition } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DateFilterUtils } from '@cxstudio/report-filters/date-filter-utils.service';

export class FilterValidationService {

	constructor(
		private filterMatchModes: FilterMatchModes,
		private security: Security,
		private esQueryService: EsQueryService,
		private dateFilterUtils: DateFilterUtils,
	) {}

	validateDashboardFilterRules = (rule) => {
		return this.validateFilterRules(rule, true);
	};

	validateFilterRules =  (rule, treatEmptyAsAll?: boolean) => {
		if (!rule || !rule.filterRules || !rule.filterRules.length) return false;
		for (let ruleDefinition of rule.filterRules) {
			let valid = this.validateFilterRuleDefinition(ruleDefinition, treatEmptyAsAll);
			if (valid !== true) {
				return valid;
			}
		}

		return true;
	};

	validateFilterRuleDefinition = (ruleDefinition, treatEmptyAsAll?: boolean) => {
		let correctTypedFilter;
		if (FilterRuleTypes.isText(ruleDefinition)) return true;

		if (FilterRuleTypes.isAttributeRule(ruleDefinition)) {
			correctTypedFilter = ruleDefinition.attributeName && ruleDefinition.matchMode;
			if (!correctTypedFilter || !this.filterMatchModes.valueOf(ruleDefinition).validate(ruleDefinition, treatEmptyAsAll)) {
				return false;
			}
		} else if (FilterRuleTypes.isTopicRule(ruleDefinition)) {
			correctTypedFilter = ruleDefinition.topicId && ruleDefinition.matchMode;
			if (!correctTypedFilter || !this.filterMatchModes.valueOf(ruleDefinition).validate(ruleDefinition, treatEmptyAsAll)) {
				return false;
			}
		} else if (FilterRuleTypes.isDateRangeRule(ruleDefinition)) { // date filter doesn't have match mode
			return this.validateDateRange(ruleDefinition); // only one date rule is possible
		} else if (FilterRuleTypes.isSavedFilterRule(ruleDefinition)
					|| FilterRuleTypes.isPredefinedRule(ruleDefinition)
					|| FilterRuleTypes.isScorecardFilterRule(ruleDefinition)) {
			return true;
		} else if (FilterRuleTypes.isEsQueryRule(ruleDefinition)) {
			return ruleDefinition.esQueryObject && this.esQueryService.validate(ruleDefinition.esQueryObject);
		} else {
			return false; // empty rule
		}

		return true;
	};

	getValidationResult = (rule) => {
		let result = this.validateFilterRules(rule);
		if (result === true)
			return FilterValidationMessages.VALID;
		if (result === false)
			return FilterValidationMessages.INCOMPLETE_RULES;
		return result;
	};

	/**
	 * If you update this code, make sure to update its backend counterpart DateFilterUtil::getDateFilterLength
	 */
	private validateDateRange(ruleDefinition: IDateRuleDefinition): boolean | string {
		if (ruleDefinition.dateFilterMode !== 'custom')
			return true;

		if (!ruleDefinition.fromMode || !ruleDefinition.toMode)
			return false;

		let diffInYears = this.dateFilterUtils.getDateRulePeriodLengthInYears(ruleDefinition);

		let upperLimit = 1; // in years
		if (!this.security.isAdminOrgUser() && diffInYears > upperLimit) {
			return FilterValidationMessages.DATE_RANGE_LIMIT;
		}

		if (diffInYears < 0) {
			return FilterValidationMessages.DATE_RANGE_NEGATIVE;
		}

		return true;
	}
}

app.service('filterValidationService', FilterValidationService);
