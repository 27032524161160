import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DocumentExplorerModule } from '@app/modules/document-explorer/document-explorer.module';
import { LayoutModule } from '@app/modules/layout/layout.module';
import { PlotLinesModule } from '@app/modules/plot-lines/plot-line.module';
import { RealDataPreviewComponent } from '@app/modules/reports/real-data-preview/real-data-preview.component';
import { SystemAdministrationModule } from '@app/modules/system-administration/system-administration.module';
import { UnitsModule } from '@app/modules/units/units.module';
import { WidgetContainerModule } from '@app/modules/widget-container/widget-container.module';
import { AdvancedChartSettingsComponent } from '@app/modules/widget-settings/advanced-chart-settings/advanced-chart-settings.component';
import { CbObjectViewerDefinitionComponent } from '@app/modules/widget-settings/cb-object-viewer/cb-object-viewer-definition.component';
import { ObjectViewerTypeSelectorComponent } from '@app/modules/widget-settings/cb-object-viewer/object-viewer-type-selector.component';
import { ObjectViewerWidgetPreviewUpgrade } from '@app/modules/widget-settings/cb-object-viewer/object-viewer-widget-preview-upgrade.directive';
import { CommonSettingsModule } from '@app/modules/widget-settings/common-settings/common-settings.module';
import { ContentWidgetSettingsModule } from '@app/modules/widget-settings/content-widget/content-widget-settings.module';
import { DateFiltersModule } from '@app/modules/widget-settings/date-filters/date-filters.module';
import { EmbedWidgetConfigurationComponent } from '@app/modules/widget-settings/endpoint/embed-widget-configuration.component';
import { DocExplorerSelectedItemsComponent } from '@app/modules/widget-settings/feedback-widget/doc-explorer-selected-items.component';
import { ReportGroupingModule } from '@app/modules/widget-settings/grouping/report-grouping.module';
import { SelectorWidgetSettingsModule } from '@app/modules/widget-settings/selector-widget/selector-widget-settings.module';
import { WidgetBuilderService } from '@app/modules/widget-settings/services/widget-builder.service';
import { FilterManagementTabUpgrade } from '@app/modules/widget-settings/settings/filter-management-tab.upgrade.directive';
import { WidgetEndpointUpgrade } from '@app/modules/widget-settings/settings/widget-endpoint.upgrade.directive';
import { WidgetDataAccessService } from '@app/modules/widget-settings/widget-data-access.service';
import { WidgetDriversAccessService } from '@app/modules/widget-settings/widget-drivers-access.service';
import { WidgetEditorService } from '@app/modules/widget-settings/widget-editor.service';
import { SidebarWidgetEditorService } from '@app/modules/widget-settings/widget-editor/sidebar-widget-editor.service';
import { WidgetFiltersAccessService } from '@app/modules/widget-settings/widget-filters-access.service';
import { WidgetMetricsAccessService } from '@app/modules/widget-settings/widget-metrics-access.service';
import { WidgetValidationService } from '@app/modules/widget-settings/widget-validation.service';
import { WidgetVisualizationsModule } from '@app/modules/widget-visualizations/widget-visualizations.module';
import { SanitizePipe } from '@app/shared/pipes/sanitize.pipe';
import { SharedModule } from '@app/shared/shared.module';
import { PushButtonsModule, SortDirectionModule, UnifiedAngularComponentsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { ClipboardModule } from 'ngx-clipboard';
import { NgPipesModule } from 'ngx-pipes';
import { AssetManagementModule } from '../asset-management/asset-management.module';
import { DialogModule } from '../dialog/dialog.module';
import { FilterBuilderModule } from '../filter-builder/filter-builder.module';
import { AdvancedCloudSettingsComponent } from './advanced-cloud-settings/advanced-cloud-settings.component';
import { BubbleConfigurationService } from './bubble-configuration.service';
import { BubbleConfigurationModalComponent } from './bubble-configuration/bubble-configuration-modal/bubble-configuration-modal.component';
import { CaseVizSettingsComponent } from './case-viz-settings/case-viz-settings.component';
import { ComparisonConfigurationModalComponent } from './cb-metric/comparison-configuration-modal/comparison-configuration-modal.component';
import { ComparisonItemComponent } from './cb-metric/comparison-item/comparison-item.component';
import { ComparisonsListComponent } from './cb-metric/comparisons-list/comparisons-list.component';
import { CloudSettingsComponent } from './cloud-settings/cloud-settings.component';
import { ExternalEmbeddingSettingsComponent } from './external-embedding-settings/external-embedding-settings.component';
import { ExternalWidgetSettingsComponent } from './external/external-widget-settings/external-widget-settings.component';
import { QualtricsWidgetSettingsComponent } from './external/qualtrics-widget-settings/qualtrics-widget-settings.component';
import { OptionsTemplatesService } from './options/options-templates.service';
import { ReportColorSelectorComponent } from './report-color-selector/report-color-selector.component';
import { ReportPeriodSelectorComponent } from './report-period-selector/report-period-selector.component';
import { ReportVisualizationSelectorComponent } from './report-visualization-selector.component';
import { SelectorDisplaySizeComponent } from './selector-display-size.component';
import { AnAxisScaleComponent } from './settings/an-axis-scale/an-axis-scale.component';
import { PeerReportWarningComponent } from './settings/peer-report-warning/peer-report-warning.component';
import { WidgetTitleSettingsComponent } from './settings/widget-title-settings/widget-title-settings.component';
import { WidgetPreviewComponent } from './widget-preview/widget-preview.component';
import { WidgetPropertiesTabComponent } from './widget-properties-tab/widget-properties-tab.component';
import { WidgetSettingsComponent } from './widget-settings/widget-settings.component';
import { ReportCalculationModule } from '@app/modules/widget-settings/calculation/report-calculation.module';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { WidgetFiltersErrorsComponent } from '@app/modules/widget-settings/filter-management/widget-filters-errors.component';
import { FeedbackDataGeneratorService } from './feedback-widget/feedback-data-generator.service';
import { AnalyticsModule } from '../analytics/analytics.module';
import { WidgetSettingsModalService } from '@app/modules/widget-settings/services/widget-settings-modal.service';
import { FeedbackSortButtonComponent } from './feedback-widget/feedback-sort-button/feedback-sort-button.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
	imports: [
		SharedModule,
		I18nModule,
		ClipboardModule,
		SortDirectionModule,
		PushButtonsModule,
		DialogModule,
		DragDropModule,
		AssetManagementModule,
		FilterBuilderModule,
		UnifiedAngularComponentsModule,
		CommonModule,
		FormsModule,
		CommonSettingsModule,
		ContentWidgetSettingsModule,
		SelectorWidgetSettingsModule,
		LayoutModule,
		PlotLinesModule,
		WidgetVisualizationsModule,
		DocumentExplorerModule,
		WidgetContainerModule,
		SystemAdministrationModule,
		NgPipesModule,
		UnitsModule,
		ReportCalculationModule,
		ReportGroupingModule,
		DateFiltersModule,
		AnalyticsModule,
		IconModule,
	],
	exports: [
		EmbedWidgetConfigurationComponent,
		CbObjectViewerDefinitionComponent,
		ReportVisualizationSelectorComponent,
		ObjectViewerWidgetPreviewUpgrade,
		ObjectViewerTypeSelectorComponent,
		ComparisonConfigurationModalComponent,
		ComparisonsListComponent,
		RealDataPreviewComponent,
	],
	declarations: [
		EmbedWidgetConfigurationComponent,
		CbObjectViewerDefinitionComponent,
		ReportVisualizationSelectorComponent,
		ObjectViewerWidgetPreviewUpgrade,
		ObjectViewerTypeSelectorComponent,
		ComparisonConfigurationModalComponent,
		CaseVizSettingsComponent,
		ComparisonItemComponent,
		ComparisonsListComponent,
		QualtricsWidgetSettingsComponent,
		SelectorDisplaySizeComponent,
		WidgetTitleSettingsComponent,
		ExternalWidgetSettingsComponent,
		PeerReportWarningComponent,
		AnAxisScaleComponent,
		BubbleConfigurationModalComponent,
		WidgetPreviewComponent,
		WidgetSettingsComponent,
		WidgetEndpointUpgrade,
		FilterManagementTabUpgrade,
		CloudSettingsComponent,
		RealDataPreviewComponent,
		AdvancedChartSettingsComponent,
		AdvancedCloudSettingsComponent,
		ReportColorSelectorComponent,
		ReportPeriodSelectorComponent,
		DocExplorerSelectedItemsComponent,
		WidgetPropertiesTabComponent,
		ExternalEmbeddingSettingsComponent,
		WidgetFiltersErrorsComponent,
		FeedbackSortButtonComponent,
	],
	providers: [
		WidgetEditorService,
		WidgetDataAccessService,
		WidgetFiltersAccessService,
		WidgetMetricsAccessService,
		WidgetDriversAccessService,
		WidgetBuilderService,
		OptionsTemplatesService,
		BubbleConfigurationService,
		SanitizePipe,
		WidgetValidationService,
		SidebarWidgetEditorService,
		RealDataPreviewComponent,
		FeedbackDataGeneratorService,
		WidgetSettingsModalService
	],
})
export class WidgetSettingsModule {}

