import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';

interface IVisualTabScope extends ISimpleScope {
	isExperimentalUI: () => boolean;
	utils: WidgetUtils;
	redrawTrigger: number;
	widget: Widget;
	updateChartSettings: () => void;
	showRealDataPreview: () => boolean;
	updateDataLoading: (loading: Promise<any>) => void;
	realDataLoading: Promise<any>;
	staticData: {
		data: Record<string, unknown>;
		multiData: Record<string, unknown>;
		ready: boolean;
	};
	initTab: () => void;
	tabs: any[];
	props?: any;
}
// eslint-disable-next-line prefer-arrow-callback
app.controller('CBVisualTabCtrl', function(
	$scope: IVisualTabScope,
	security: Security,
	widgetUtilsService: WidgetUtilsService,
	realDataPreviewService: RealDataPreviewService
) {

	$scope.tabs.push($scope);

	$scope.initTab = () => {
		$scope.staticData = { // for children
			data: {},
			multiData: {},
			ready: false
		};
	};

	$scope.initTab();
	$scope.updateChartSettings = (refreshType?: PreviewChartRefreshType) => {
		widgetUtilsService.getBuilder($scope.widget).build().then((utils) => {
			$scope.utils = utils;
			$scope.staticData.ready = true;

			let showRealDataPreview = realDataPreviewService.showRealDataPreview($scope.props.widgetType);
			if (showRealDataPreview && refreshType !== PreviewChartRefreshType.UI_ONLY) {
				realDataPreviewService.checkChangesForPreview($scope.widget);
			}

			if (!(showRealDataPreview && realDataPreviewService.hasPreviewChanges())) {
				$scope.redrawTrigger = _.isUndefined($scope.redrawTrigger) ? 1 : $scope.redrawTrigger + 1;
			}
		});
	};

	$scope.showRealDataPreview = (): boolean => {
		return realDataPreviewService.showRealDataPreview($scope.props?.widgetType);
	};

	$scope.isExperimentalUI = () => {
		return security.getContext() && security.getContext().experimentalUI;
	};

	$scope.updateDataLoading = (loading): void => {
		$scope.realDataLoading = loading;
	};
});
