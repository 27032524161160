import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { SelectedReportGroupings } from '@app/modules/widget-settings/services/selected-report-groupings';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { CalculationWithFormat } from '../calculations/report-calculation';
import { PromiseUtils } from '@app/util/promise-utils';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';


export class AnalyticDefinitionSelectionController {

	multiAttributeSelection = this.selectionConfiguration.multiAttributeSelection;
	previousNodes = angular.copy(this.$scope.props.selectedAttributes);
	selectedReportGroupings = new SelectedReportGroupings(this.$scope.props);

	constructor(
		private readonly $scope,
		private readonly $controller,
		private readonly selectionConfiguration,
		private readonly customCallback: { addGroup: (...args) => void; removeGroup: (...args) => void },
		private readonly groupColorService: GroupColorService,
		private readonly colorUtils: ColorUtils,
		private readonly reportSettingsService: ReportSettingsService,
		private readonly realDataPreviewService: RealDataPreviewService,
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private readonly $q: ng.IQService
	) {
		this.$controller('AnalyticDefinitionCommonCtrl', { $scope: this.$scope });

		this.$scope.ui = this.$scope.ui || {};
		this.$scope.ui.staticData = { data: {} };

		this.$scope.onWidgetMetricConfigured = this.onWidgetMetricConfigured;
		this.$scope.addAttribute = this.addAttribute;
		this.$scope.initAttributesSelectionPanel = this.initAttributesSelectionPanel;
		this.$scope.showMinus = this.showMinus;
		this.$scope.showPlus = this.showPlus;
		this.$scope.addGroup = this.addGroup;
		this.$scope.removeGroup = this.removeGroup;
		this.$scope.reorderGroups = this.reorderGroups;
		this.$scope.isReorderable = this.isReorderable;
		this.$scope.showTotalCount = this.showTotalCount;
		this.$scope.changeColor = this.changeColor;
		this.$scope.selectMetric = this.selectMetric;
		this.$scope.selectMetricAndRefreshView = this.selectMetricAndRefreshView;
		this.$scope.applyVisualChanges = this.applyVisualChanges;
	}

	$onInit(): void { }

	onWidgetMetricConfigured = () => {
		this.$scope.applyVisualChanges();
	};

	addAttribute = (node, index, previousNode) => {
		index = index || 0;

		let promise = this.reportSettingsService.populateGroupingProjectDefaults(this.$scope.props, node)
			.then((item) => {
				this.updateAttribute(item, index, previousNode);
			});

		this.$scope.addLoadingPromise(promise);
		return promise;
	};

	private moveAttribute(node, index): void {
		let item = angular.copy(node);
		this.updateAttribute(item, index);
	}

	private updateAttribute(node, index, previousNode?): void {
		if (this.multiAttributeSelection) {
			previousNode = this.previousNodes[index];
			this.$scope.props.selectedAttributes[index] = angular.copy(node);
			let group = this.getGroups(index);
			AnalyticsDefinitionUtils.toggleAllowedSelections(group, node, previousNode);
			if (this.groupColorService.isGroupColorSupported(previousNode)
				&& this.groupColorService.isGroupColor(this.$scope.visualProps.color)) {
				this.$scope.visualProps.color = null;
			}
			this.selectedReportGroupings.populateTopicFields();
		} else {
			this.$scope.props.selectedAttributes[0] = angular.copy(node);
			this.$scope.visualProps.primaryGroup = node.name;
			delete this.$scope.props.primaryTimeGrouping;
			if (AnalyticMetricTypes.isTime(node)) {
				this.$scope.props.primaryTimeGrouping = node;
			}
		}
		this.$scope.props.selectedAttributes[index].identifier = GroupIdentifierHelper.generateIdentifier(node, index);
		if (this.groupColorService.isGroupColorSupported(node)) {
			this.$scope.visualProps.color = this.groupColorService.GROUP_COLOR_PREFIX + node.name;
		}

		if (this.customCallback?.addGroup) {
			this.customCallback.addGroup(node, index, previousNode);
		}

		this.$scope.processDynamicCalculations(node);
		this.$scope.applyVisualChanges();
	}

	initAttributesSelectionPanel = () => {
		if (this.multiAttributeSelection) {
			let attributes = this.$scope.props.selectedAttributes;
			if (attributes && attributes.length) {
				for (let i = 0; i < attributes.length; i++) {
					let group = this.getGroups(i);
					AnalyticsDefinitionUtils.toggleAllowedSelections(group, attributes[i]);
				}
			}
		}
	};

	showMinus = (index: number) => index > 0;

	showPlus = (index: number, customLimit) => {
		let limit = customLimit || 3;
		return (this.$scope.props.selectedAttributes.length < limit) && (index === this.$scope.props.selectedAttributes.length - 1)
			&& (this.$scope.props.selectedAttributes[index]);
	};

	addGroup = () => {
		this.$scope.props.selectedAttributes.push(undefined);
	};

	removeGroup = (index: number) => {
		if (this.multiAttributeSelection) {
			let groups = this.getGroups();
			let node = this.$scope.props.selectedAttributes[index];
			AnalyticsDefinitionUtils.enableAllowedMultiSelection(groups, node);
			if (index < this.$scope.options.groupingOptions.length - 1)
				this.$scope.options.groupingOptions.swap(index, index + 1);
		}

		let attribute = this.$scope.props.selectedAttributes[index];

		if (this.groupColorService.isGroupColorSupported(attribute)
			&& this.groupColorService.isGroupColor(this.$scope.visualProps.color)) {
			this.$scope.visualProps.color = null;
		}

		this.$scope.props.selectedAttributes.splice(index, 1);
		GroupIdentifierHelper.populateIdentifier(this.$scope.props.selectedAttributes);
		if (this.multiAttributeSelection) {
			this.selectedReportGroupings.populateTopicFields(attribute);
		}
		if (this.customCallback.removeGroup) {
			this.customCallback.removeGroup(attribute, index);
		}
		this.$scope.applyVisualChanges();
	};

	reorderGroups = (group, newIndex) => {
		let originalIndex = _.indexOf(this.$scope.props.selectedAttributes, group);

		// prevent invalid reorder
		if (_.isUndefined(originalIndex) ||
			originalIndex < 0 ||
			!this.$scope.isReorderable(originalIndex) ||
			!this.$scope.isReorderable(newIndex))
			return;

		this.$scope.props.selectedAttributes.move(originalIndex, newIndex);
		_.each(this.$scope.props.selectedAttributes, (node, index) => {
			this.moveAttribute(this.$scope.props.selectedAttributes[index], index);
		});
	};

	isReorderable = (index) => {
		// the current item must be defined,
		// and there must be at least two defined groupings for anything to be reorderable
		let isThisDefined = this.$scope.props.selectedAttributes[index];
		let isMultipleDefined = _.filter(this.$scope.props.selectedAttributes, (attr) => !!attr).length > 1;

		return isMultipleDefined && isThisDefined;
	};

	showTotalCount = () => {
		return !isEmpty(this.$scope.staticData.totalCount) && this.$scope.visualProps.showSampleSize;
	};

	changeColor = () => {
		let metrics = [].concat(this.$scope.options.studioMetrics || [])
			.concat(this.$scope.options.predefinedMetrics || []);
		let metricsForColoring = this.colorUtils.getColoringMetrics(this.$scope.visualProps, metrics);

		let formatPromises = metricsForColoring.map(metric => {
			return PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.$scope.props, metric).then((customSettings) => {
				let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(metric as CalculationWithFormat,
					this.$scope.options.studioMetrics);
				_.extend(metric, defaultSettings, customSettings);
			}));
		});

		this.$q.all(formatPromises).then(() => {
			// add any required coloring metrics to the selected metric for y-axis
			if (this.$scope.props.widgetType === WidgetType.SCATTER) {
				this.$scope.props.selectedMetrics = this.addMissingColoringMetrics(this.$scope.props.selectedMetrics, metricsForColoring);
			} else {
				this.$scope.props.selectedMetrics = [this.$scope.props.selectedMetrics[0]].concat(metricsForColoring);
				this.$scope.props.selectedMetrics = _.uniq(_.filter(this.$scope.props.selectedMetrics), 'name');
			}

			this.$scope.applyVisualChanges();
		});
	};

	private addMissingColoringMetrics(target, source): any[] {
		target = _.chain(target)
			.first(3)
			.filter(metric => !!metric)
			.value();

		let targetNames = target.map(targetMetric => targetMetric.name);

		let metricsToAdd = source.filter(sourceMetric => !targetNames.contains(sourceMetric.name));

		return target.concat(metricsToAdd);
	}

	selectMetricAndRefreshView = (node) => {
		this.selectMetric(node).then((settings) => {
			this.$scope.applyVisualChanges();
			this.$scope.processSelections();
		});
	};

	selectMetric = (node) => {
		// don't reselect same metric, otherwise formatting may get reset
		if (this.$scope.props.selectedMetrics.length && (node.name === this.$scope.props.selectedMetrics[0].name)) {
			return;
		}

		let item = angular.copy(node);
		return this.reportSettingsService.getCalculationSettings(this.$scope.props, item).then((customSettings) => {
			let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(item, this.$scope.options.studioMetrics);
			_.extend(item, defaultSettings, customSettings);
			this.$scope.props.selectedMetrics[0] = item;
			this.$scope.visualProps.attributeSelections.size = item;
		});
	};

	applyVisualChanges = () => {
		if (this.realDataPreviewService.showRealDataPreview(this.$scope.props?.widgetType) && this.hasAttributes()) {
			this.realDataPreviewService.checkChangesForPreview(this.$scope.widget);
		}

		let groupNum = 1;
		if (this.multiAttributeSelection) {
			this.previousNodes = angular.copy(this.$scope.props.selectedAttributes);
			groupNum = 3;
		}

		let data = angular.copy(this.$scope.staticData.data.data);
		let metadata = angular.copy(this.$scope.staticData.data.metadata);

		let staticDataModifiers = this.$scope.getStaticDataModifiers(this.$scope.staticData.data.data, this.$scope.visualProps);
		if (data && this.$scope.props.selectedAttributes) {
			this.$scope.ui.staticData.data = _.chain(data).filter((row) => {
				return !this.multiAttributeSelection || row.level < this.$scope.props.selectedAttributes.length;
			}).each((row) => {
				if (this.$scope.props.selectedAttributes) {
					let index = 0;
					this.$scope.props.selectedAttributes.forEach((attr) => {
						if (attr && attr.name) {
							let identifier = GroupIdentifierHelper.getIdentifier(attr);
							row[identifier] = row['group' + (index % groupNum + 1)]; // group1, group2, ... groupN
							index++;
						}
					});
				}

				if (this.$scope.props.selectedMetrics) {
					this.$scope.props.selectedMetrics
						.filter(metric => !!metric)
						.forEach((metric, metricIndex) => {
							let name = metric.name;
							this.$scope.setProperMetricValue(row, name, staticDataModifiers[metricIndex]);
						});
				}
			}).value();


			this.$scope.ui.staticData.metadata = metadata;
		}

		if (this.realDataPreviewService.showRealDataPreview(this.$scope.props?.widgetType)) {
			let refreshType: PreviewChartRefreshType = this.hasAttributes()
				? PreviewChartRefreshType.DATA_REQUEST
				: PreviewChartRefreshType.UI_ONLY;
			this.$scope.updateChartSettings(refreshType);
		} else if (data) {
			this.$scope.updateChartSettings();
		}

		if (!this.$scope.props.isCustomTitle && this.$scope.updateAutoTitle) {
			// some component-like settings don't inherit updateAutoTitle, e.g. selector widget settings
			this.$scope.updateAutoTitle();
		}
	};

	private hasAttributes(): boolean {
		return !_.isEmpty(this.$scope.props.selectedAttributes);
	}

	private getGroups(index?: number): any {
		if (!this.$scope.options || !this.$scope.options.groupingOptions) {
			return;
		}

		let groups = [];

		for (let i = 0; i < this.$scope.options.groupingOptions.length; i++) {
			if (index === undefined || i !== index) {
				groups = _.union(groups, this.$scope.options.groupingOptions[i]);
			}
		}

		return groups;
	}
}

app.controller('AnalyticDefinitionSelectionController', AnalyticDefinitionSelectionController);
