
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as _ from 'underscore';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'ok-modal-footer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div class="modal-footer">
		<button
			id="btn-dialog-cancel"
			type="button"
			class="btn btn-primary"
			(click)="cancel.emit()">
			<span>{{okText}}</span>
		</button>
	</div>
	`
})

export class OkModalFooterComponent implements OnInit {
	@Input() okText: string;
	@Output() cancel: EventEmitter<string> = new EventEmitter();

	constructor(private locale: CxLocaleService) { }

	ngOnInit(): void {
		if (!this.okText)
			this.okText = this.locale.getString('common.ok');
	}
}

app.directive('okModalFooter', downgradeComponent({component: OkModalFooterComponent}));
