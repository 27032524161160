import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';


export interface MetricMultiplier {
	name: string;
	displayName: string;
	apply: (value: number) => number;
}

@Injectable({
	providedIn: 'root'
})
export class MetricMultipliersService {

	public NONE: MetricMultiplier;
	DIVIDE_HUNDRED: MetricMultiplier;
	TIMES_HUNDRED: MetricMultiplier;

	options: MetricMultiplier[];

	constructor(
		private readonly locale: CxLocaleService
	) {
		this.NONE = {
			name: MetricMultiplierType.NONE,
			displayName: this.locale.getString('widget.noConversion'),
			apply: value => {
				return value;
			}
		};

		this.DIVIDE_HUNDRED = {
			name: MetricMultiplierType.DIVIDE_HUNDRED,
			displayName: this.locale.getString('widget.hundredthsConversion'),
			apply: value => {
				return value * 0.01;
			}
		};

		this.TIMES_HUNDRED = {
			name: MetricMultiplierType.TIMES_HUNDRED,
			displayName: this.locale.getString('widget.hundredConversion'),
			apply: value => {
				return value * 100;
			}
		};

		this.options = [ this.DIVIDE_HUNDRED, this.NONE, this.TIMES_HUNDRED ];
	}

	findByName = (name: MetricMultiplierType): MetricMultiplier => {
		if (!name)
			return this.NONE;

		let result = _.findWhere(this.options, { name });
		return result || this.NONE;
	};
}

app.service('MetricMultipliers', downgradeInjectable(MetricMultipliersService));
