import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

@Component({
	selector: 'content-widget-settings',
	template: `
	<content-image-settings *ngIf="widget.properties.widgetType === WidgetType.IMAGE"
		class="br-content-image"
		[widget]="widget">
	</content-image-settings>
	<content-text-settings
		*ngIf="widget.properties.widgetType === WidgetType.TEXT || widget.properties.widgetType === WidgetType.LABEL"
		class="br-text-settings content-text p-24 d-block"
		[widget]="widget">
	</content-text-settings>
	<content-button-settings *ngIf="widget.properties.widgetType === WidgetType.BUTTON"
		class="p-24 d-block"
		[widget]="widget">
	</content-button-settings>
	<content-video-settings *ngIf="widget.properties.widgetType === WidgetType.VIDEO"
		class="p-24 d-block"
		[widget]="widget">
	</content-video-settings>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentWidgetSettingsComponent {

	@Input() widget: ContentWidget;
	WidgetType = WidgetType;
}

app.directive('contentWidgetSettings', downgradeComponent({component: ContentWidgetSettingsComponent}));
