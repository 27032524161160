import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';

@Injectable({
	providedIn: 'root'
})
export class CloudD3UtilsService {

	constructor(
		private readonly locale: CxLocaleService) { }

	getPointFormatter = (reportDefinitionUtils, textSize) => {
		let formatter = reportDefinitionUtils.dataFormatter;
		let localizedSentiment = this.locale.getString('widget.sentiment');
		let localizedVolume = this.locale.getString('widget.volume');
		return function(): string {
			/* eslint-disable no-invalid-this */
			let tooltip = '<span style="color:' + this.color + '">\u25CF</span> '
				+ this.displayName + ': <b>' + formatter(this.rawValue) + '</b><br/>';
			if (textSize !== 'sentiment' && this.sentiment) {
				tooltip += '<br/>' + localizedSentiment + ': ' + Number(this.sentiment).toFixed(2);
			}
			if (textSize !== 'volume' && this.volume) {
				tooltip += '<br/>' + localizedVolume + ': ' + this.volume;
			}
			return tooltip;
			/* eslint-enable no-invalid-this */
		};
	};

	getSpiralFunctionName = (cloudShape: OptionsConstant) => {
		return cloudShape === OptionsConstant.CLOUD_SHAPE_RECTANGLE ?
			'gaussianSpiral' :
			'archimedeanSpiral';
	};

	getAnglesArray = (orientation: number) => {
		let orientations = [ [0], [-90, 0], [-90, -45, 0], [-90, -45, 0, 45]];

		return orientations[orientation - 1];
	};

	generateWordSpaceCoefficient = (avgSize, avgLength, width, height, numWords, cloudSizing) => {
		let scaledCoefficient = 1;
		if (height && width) {
			scaledCoefficient = ( height * width ) / ( 2 * avgLength * avgSize * numWords);
		}
		switch (cloudSizing) {
		case OptionsConstant.CLOUD_SIZING_OPTION_ONE: //legacy option, aka no coefficient
			return 1;
		case OptionsConstant.CLOUD_SIZING_OPTION_TWO:
			return scaledCoefficient;
		default:
			return 1;
		}
	};

	populateDefaultOptions = (options) => {
		options.orientations = options.orientations || 2;
		options.minimumFontSize = options.minimumFontSize || 8;
		options.cloudShape = options.cloudShape || OptionsConstant.CLOUD_SHAPE_CLOUD;
		options.cloudSizing = options.cloudSizing || OptionsConstant.CLOUD_SIZING_OPTION_ONE;
	};
}

app.service('cloudD3Utils', CloudD3UtilsService);
