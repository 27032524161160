import * as _ from 'underscore';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { AccountOrWorkspace, AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ScorecardMetricsManagementApiService } from '@app/modules/scorecards/metrics/scorecard-metrics-management-api.service';
import { ObjectUtils } from '@app/util/object-utils';
import { WorkspaceScorecardMetricsManagementApiService } from '@app/modules/scorecards/metrics/workspace-scorecard-metrics-management-api.service';

@Injectable({
	providedIn: 'root'
})
export class ScorecardMetricsManagementService {
	folderSvc: IFolderService;
	SCORECARD_FOLDER;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly scorecardMetricsManagementApi: ScorecardMetricsManagementApiService,
		private readonly workspaceScorecardMetricsManagementApi: WorkspaceScorecardMetricsManagementApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('FolderService') private readonly FolderService,
	) {
		this.folderSvc = new this.FolderService(FolderTypes.METRIC);

		this.SCORECARD_FOLDER = {
			name: this.locale.getString('metrics.scorecardMetrics'),
			id: ReportAssetType.SCORECARD,
			description: '',
			type: FolderTypes.METRIC,
			level: 0,
			_collapsed: true,
			generatedFolderType: GeneratedFolderType.SYSTEM,
			children: []
		};
	}

	getScorecardFolders = (
		scorecardMetrics: ScorecardMetric[], project: AccountOrWorkspaceProjectData, masterAccountId: number
	): any[] => {
		const projectData = WorkspaceTransitionUtils.getProjectData(project);
		let scorecardFolders = [];
		let rootScorecardFolder = ObjectUtils.copy(this.SCORECARD_FOLDER);

		scorecardMetrics.forEach((metric: ScorecardMetric): void => {
			let scorecardFolder = _.findWhere(scorecardFolders, { id: 'scorecard_' + metric.scorecardId });
			if (!scorecardFolder) {
				scorecardFolder = this.folderSvc.getScorecardsFolder(
					metric.scorecardName, metric.scorecardId, FolderTypes.METRIC, rootScorecardFolder);
				_.extend(scorecardFolder, { masterAccountId }, projectData);
				scorecardFolders.push(scorecardFolder);
			}

			metric.parentId = scorecardFolder.id;
			metric.id = metric.name; // scorecard metric doens't have numeric id, use name as unique id
		});

		if (!isEmpty(scorecardFolders)) {
			scorecardFolders.push(rootScorecardFolder);
		}

		return scorecardFolders;
	};

	getScorecardMetrics = (project: AccountOrWorkspaceProject): AssetPromise<ScorecardMetric[]> => {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]) as AssetPromise<ScorecardMetric[]>;
		}
		return ProjectAssetsHelper.getAssetsForProject<ScorecardMetric[]>(
			project,
			(accountProject) => this.scorecardMetricsManagementApi.getMetrics(accountProject),
			(workspaceProject) => this.workspaceScorecardMetricsManagementApi.getMetrics(workspaceProject),
			(projectId) => []
		);
	};

	getWorkspaceScorecardMetrics = (workspace: AccountOrWorkspace): Promise<ScorecardMetric[]> => {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			return Promise.resolve([]) as Promise<ScorecardMetric[]>;
		}
		return WorkspaceTransitionUtils.isWorkspace(workspace)
			? this.workspaceScorecardMetricsManagementApi.getMetricsForWorkspace(workspace)
			: this.scorecardMetricsManagementApi.getMetricsForAccount(workspace);
	};

	updateDisableState = (metric: ScorecardMetric, disabled: boolean): Promise<boolean> => {
		let scorecardMetric = _.omit(metric, 'id', 'parent', 'parentId', 'definition');
		return this.scorecardMetricsManagementApi.updateDisableState(scorecardMetric as ScorecardMetric, disabled);
	};
}

app.service('scorecardMetricsManagementService', downgradeInjectable(ScorecardMetricsManagementService));
