import { ChangeDetectionStrategy } from '@angular/core';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import ChartType from '@cxstudio/reports/entities/chart-type';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { HeatmapSubtype } from '@cxstudio/reports/providers/cb/constants/heatmap-visualizations.service';

export type SubChartTypes = ChartType | HeatmapSubtype | WidgetVisualization;

export interface IVisualization {
	type: SubChartTypes;
	title: string;
	icon?: string;
	additionalClass?: string;
}

export interface IConversion {
	targetWidget: string;
	title: string;
	icon: string;
	widgetType: WidgetType;
}

@Component({
	selector: 'report-visualization-selector',
	template: `
<div class="btn-group">
	<button *ngFor="let vis of visualizations"
		href="javascript:void(0);"
		class="btn btn-icon"
		(click)="onChangeInternal(vis.type)"
		attr.data-testid="report-subtype-{{vis.type.toLowerCase()}}"
		[ngClass]="[vis.icon || '', vis.additionalClass || '']"
		[class.btn-selected]="subType === vis.type"
		title="{{vis.title}}"
		attr.aria-label="{{vis.title}}">
	</button>
	<button
		*ngIf="hasConversions()"
		[disabled]="conversionDisabled"
		class="btn btn-icon p-0 d-flex"
		data-testid="conversions-selector"
		title="{{'widget.conversionBtn'|i18n}}"
		ngbDropdown>
		<span class="q-icon-dots icon-rotate-90-ccw dropdown-toggle" ngbDropdownToggle></span>
		<ul ngbDropdownMenu class="dropdown-menu dropdown-menu-right text-left" role="menu">
			<li *ngFor="let conv of conversions">
				<a
					href="javascript:void(0)"
					(click)="onConvertInternal(conv.targetWidget);"
					title="{{conv.title}}"
					attr.aria-label="{{conv.title}}">
					<span [ngClass]="[conv.icon || '', conv.widgetType || '']" aria-hidden="true"></span>
					<span class="ml-8">{{conv.title}}</span>
				</a>
			</li>
		</ul>
	</button>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class ReportVisualizationSelectorComponent {
	@Input() visualizations: IVisualization[];
	@Input() subType: SubChartTypes;
	@Input() conversions: IConversion[];
	@Input() conversionDisabled: boolean;

	@Output() subTypeChange = new EventEmitter<SubChartTypes>();
	@Output() conversionListener = new EventEmitter<any>();

	hasConversions = () => {
		return !_.isEmpty(this.conversions);
	};

	onChangeInternal = (type: SubChartTypes) => {
		this.subTypeChange.emit(type);
	};

	onConvertInternal = (targetWidget) => {
		this.conversionListener.emit({targetWidget});
	};
}

app.directive('reportVisualizationSelector',
	downgradeComponent({component: ReportVisualizationSelectorComponent}));
