import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'speech-bubble',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<article class="bubble mb-8 p-16 border-radius-8"
			[style.border-color]="border">
			<ng-content></ng-content>
		</article>
		<span class="p-absolute arrow-down-border" [ngStyle]="{'border-top-color': border}"></span>
		<span class="p-absolute arrow-down"></span>`,
	styles: [`
		.bubble {
			word-break: break-word;
		}

		.arrow-down, .arrow-down-border {
			border-style: solid;
			border-width: 10px 10px 0 10px;
			z-index: 1;
		}

		.arrow-down {
			bottom: -8px;
			left: 83px;
		}
		.arrow-down-border {
			bottom: -9px;
			left: 83px;
		}`]
})
export class SpeechBubbleComponent {
	@Input() border: string;
}

app.directive('speechBubble', downgradeComponent({ component: SpeechBubbleComponent }));
