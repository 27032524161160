import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import PlotBandsOptions from '@app/modules/plot-lines/plot-bands/plot-bands-options';
import ChartType from './chart-type';
import ReferenceLine from '@app/modules/plot-lines/reference-lines/reference-line';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import TimeReferenceLine from '@app/modules/plot-lines/reference-lines/time-reference-line';
import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { CaseVisualizationConfig } from '@app/modules/widget-settings/case-viz-settings/case-viz-settings.component';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { SelectorDisplaySize } from '@app/modules/widget-settings/selector-display-size.class';
import { ITableColumn } from '../providers/cb/services/table-column-service.service';

export default class VisualProperties {
	color?: string;
	customColor?: string;
	points?: boolean;
	pointColor?: string;
	pointCustomColor?: string;
	backgroundColor?: string;
	subChartType?: ChartType;
	visualization?: WidgetVisualization | VisualizationType | SelectorWidgetNavigationType;

	referenceLines?: ReferenceLine[];

	cluster?: boolean;
	timeReferenceLines?: TimeReferenceLine[];

	plotBandsEnabled?: boolean;
	plotBands?: PlotBandsOptions;

	secondaryChartType?: string;

	recolors?: any[];
	periodLabel?: {period_1_?: string; period_2_?: string};

	stackedType?: any;

	attributeSelections?: {
		primaryGroup?: any; //ReportGrouping;
		secondaryGroup?: any; //ReportGrouping;
		stackedGroup?: any; //ReportGrouping;
		sortBy?: ReportCalculation;
		size?: ReportCalculation;
		secondarySize?: ReportCalculation;
		color?: ReportCalculation;
		yAxis?: ReportCalculation;
		secondaryYAxis?: ReportCalculation;
	};

	sentimentHighlightingEnabled?: boolean;
	sentencePaneEnabled?: boolean;
	contextPaneEnabled?: boolean;
	preferences?: any;

	axisTitle?: string;

	yAxis?: string;
	yAxisAutoMax?: boolean;
	yAxisAutoMin?: boolean;
	yAxisMax?: string;
	yAxisMin?: string;


	secondaryYAxis?: string;
	secondaryAxisAutoMax?: boolean;
	secondaryAxisAutoMin?: boolean;
	secondaryAxisMax?: string;
	secondaryAxisMin?: string;
	secondarySize?: string;

	//scatter chart
	xAxis?: string;
	xAxisAutoMax?: boolean;
	xAxisAutoMin?: boolean;
	xAxisMax?: string;
	xAxisMin?: string;

	lockAxisLimits?: boolean;
	alignTicks?: boolean;

	dottedLine?: boolean;
	secondaryDottedLine?: boolean;

	primaryGroup?: string;
	showLegend?: boolean;
	showLabels?: boolean;
	showGroupNames?: boolean;
	showNodeNames?: boolean;
	showTooltips?: boolean;
	normalized?: boolean; //not storable
	size?: string;
	scale?: number;
	secondaryScale?: number;
	showSampleSize?: boolean;
	showAnimation?: boolean;

	customPopColor?: string;
	popColor?: string;
	secondaryPopColor?: string;
	stackedGroup?: string;
	secondaryGroup?: string;
	secondaryColor?: string;
	secondaryPoints?: boolean;
	secondaryPointColor?: string;
	secondaryPointCustomColor?: string;
	secondaryCustomColor?: string;
	secondaryCustomPopColor?: string;
	secondaryStacked?: boolean;
	secondaryPop?: boolean;

	columns?: any[];
	sortBy?: any;
	direction?: SortDirection;
	stackedDirection?: SortDirection | '';

	previousPeriod?: boolean;
	trendArrow?: boolean;

	orientation?: DirectionalOrientation;

	showTotal?: boolean;

	globalOtherExplorer?: boolean;
	calculationSeries?: any[];
	layout?: 'compact' | 'comfort';

	table?: {
		columns: Array<ITableColumn>;
	};
	hideTitle?: boolean;
	caseVisualizations?: CaseVisualizationConfig;
	tuningSuggestionsEnabled?: boolean;
	mapBackground?: any;
	sections?: any;
	zoom?: { // this is populated in runtime for scatter
		xAxis: {
			min: any;
			max: any;
		};
		yAxis: {
			min: any;
			max: any;
		};
	};
	legends?: { // this is populated in runtime for scatter
		hideLegends: any;
	};
	donut?: boolean;
	displaySize?: SelectorDisplaySize;

	// extract out to pie class
	sideBySide?: boolean;
}
