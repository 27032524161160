import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ConcurrentEditApiService } from '@app/modules/dashboard/concurrent-edit-alert/concurrent-edit-api.service';
import { DebounceUtilsService } from '@app/util/debounce-utils.service';
import { RandomUtils } from '@app/util/random-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Security } from '@cxstudio/auth/security-service';
import { Subject } from 'rxjs';

export interface ConcurrentEditItem {
	userId: number;
	userName: string;
}

@Component({
	selector: 'concurrent-edit-panel',
	template: `<alert *ngIf="!!concurrentEditMessage" type="warning">{{concurrentEditMessage}}</alert>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConcurrentEditAlertComponent extends SelfCleaningComponent implements OnInit, OnDestroy {

	private readonly MARK_EDIT_INTERVAL = 30 * 1000;
	private readonly CONCURRENT_CHECK_INTERVAL = 20 * 1000;

	@Input() dashboardId: number;
	private readonly editingUniqueId = RandomUtils.randomString();

	private readonly markEditSubject = new Subject<void>();
	private readonly concurrentCheckSubject = new Subject<void>();

	concurrentEditMessage: string;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly concurrentEditApi: ConcurrentEditApiService,
		private readonly locale: CxLocaleService,
		private readonly debounceUtils: DebounceUtilsService,
		@Inject('security') private readonly security: Security,
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.markEditSubject.pipe(
			this.debounceUtils.ngZoneLeaveDebounce(this.MARK_EDIT_INTERVAL),
			this.debounceUtils.ngZoneEnterCallback())
				.subscribe(() => this.markEdit()));
		this.addSubscription(this.concurrentCheckSubject.pipe(
			this.debounceUtils.ngZoneLeaveDebounce(this.CONCURRENT_CHECK_INTERVAL),
			this.debounceUtils.ngZoneEnterCallback())
				.subscribe(() => this.checkConcurrentEditors()));
		this.concurrentEditApi.markEdited(this.dashboardId, this.editingUniqueId, true).then(() => {
			this.checkConcurrentEditors();
			this.markEditSubject.next();
		});

	}

	private checkConcurrentEditors(): void {
		this.concurrentEditApi.getConcurrentEditInfo(this.dashboardId, this.editingUniqueId).then(concurrentEditors => {
			let editors = concurrentEditors.filter(editor => editor.userId !== this.security.getUser().userId)
				.map(editor => editor.userName);
			if (editors.length === 1) {
				this.concurrentEditMessage = this.locale.getString('dashboard.currentlyEditedOne', { username: editors[0] });
			} else if (editors.length === 2) {
				this.concurrentEditMessage = this.locale.getString('dashboard.currentlyEditedTwo', { username: editors[0] });
			} else if (editors.length > 2) {
				this.concurrentEditMessage = this.locale.getString('dashboard.currentlyEdited',
					// eslint-disable-next-line id-blacklist
					{ username: editors[0], number: (editors.length - 1) });
			} else {
				this.concurrentEditMessage = '';
			}

			this.ref.markForCheck();
			this.concurrentCheckSubject.next();
		});
	}

	private markEdit(): void {
		this.concurrentEditApi.markEdited(this.dashboardId, this.editingUniqueId).then(() => this.markEditSubject.next());
	}

	ngOnDestroy(): void {
		super.ngOnDestroy();
		this.concurrentEditApi.unmarkEdited(this.dashboardId, this.editingUniqueId);
	}

}

app.directive('concurrentEditAlert', downgradeComponent({component: ConcurrentEditAlertComponent}));
