import { TextAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/text-attribute-data';
import { NumericAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/numeric-attribute-data';
import { ProjectSettingsData } from '@cxstudio/asset-management/configuration/settings-data/project-settings-data';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class AttributeSettingsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxHttp: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.ATTRIBUTES);
	}

	getProjectSettings = (
		project: IProjectSelection, cache: CacheOptions
	): AssetPromisePlatformProject<ProjectSettingsData> => {
		return this.cachedHttp.get(`${this.getProjectUrl(project)}/attributes`, { cache }) as AssetPromisePlatformProject<ProjectSettingsData>;
	};

	getTextAttributeSettings = (
		project: IProjectSelection, attributeId: number, cache: CacheOptions
	): AssetPromisePlatformProject<TextAttributeSettings> => {
		return this.cachedHttp.get(
			this.getTextAttributeUrl(project, attributeId), { cache }
		) as AssetPromisePlatformProject<TextAttributeSettings>;
	};

	saveTextAttributeSettings = (
		project: IProjectSelection, attributeId: number, settings: TextAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getTextAttributeUrl(project, attributeId), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	getNumericAttributeSettings = (
		project: IProjectSelection, attributeId: number, cache: CacheOptions
	): AssetPromisePlatformProject<NumericAttributeSettings> => {
		return this.cachedHttp.get(
			this.getNumericAttributeUrl(project, attributeId), { cache }
		) as AssetPromisePlatformProject<NumericAttributeSettings>;
	};

	saveNumericAttributeSettings = (
		project: IProjectSelection, attributeId: number, settings: NumericAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getNumericAttributeUrl(project, attributeId), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	getWordAttributeSettings = (
		project: IProjectSelection, attributeName: string, cache: CacheOptions
	): AssetPromisePlatformProject<TextAttributeSettings> => {
		return this.cachedHttp.get(
			this.getWordAttributeUrl(project, attributeName), { cache }
		) as AssetPromisePlatformProject<TextAttributeSettings>;
	};

	saveWordAttributeSettings = (
		project: IProjectSelection, attributeName: string, settings: TextAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getWordAttributeUrl(project, attributeName), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	private getProjectUrl = (project: IProjectSelection): string => {
		return `rest/settings/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	};

	private getTextAttributeUrl = (project: IProjectSelection, attributeId: number): string => {
		return `${this.getProjectUrl(project)}/text-attribute/${attributeId}`;
	};

	private getNumericAttributeUrl = (project: IProjectSelection, attributeId: number): string => {
		return `${this.getProjectUrl(project)}/numeric-attribute/${attributeId}`;
	};

	private getWordAttributeUrl = (project: IProjectSelection, attributeName: string): string => {
		return `${this.getProjectUrl(project)}/word-attribute/${attributeName}`;
	};
}
