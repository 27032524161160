import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { KeyTermsUtils } from '@app/modules/utils/key-terms-utils';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { DefaultMetricConfig } from '@cxstudio/reports/providers/cb/constants/default-metric-config';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { DualAxisTypes } from '@cxstudio/reports/providers/cb/definitions/dual-axis-types';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { ReportPeriods } from '@cxstudio/reports/utils/analytic/report-periods';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { AttributeToFilterService } from './point-to-filter/attribute-to-filter.service';
import { CustomGroupToFilterService } from '@app/modules/reports/utils/context-menu/drill/point-to-filter/custom-group-to-filter.service';
import { CustomToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/custom-to-filter.service';
import { HierarchyModelToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/hierarchy-model-to-filter.service';
import { IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';
import { TimeToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/time-to-filter.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import * as moment from 'moment';
import * as cloneDeep from 'lodash.clonedeep';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import {
	TopicOrTermsToFilterService
} from '@app/modules/reports/utils/context-menu/drill/group-by/topic-or-terms-to-filter/topic-or-terms-to-filter.service';
import { PredefinedMetricToFilterService } from './point-to-filter/predefined-metric-to-filter.service';

@Injectable({
	providedIn: 'root'
})
export class CommonDrillService {

	private readonly constants;
	private readonly historicOptions;

	private readonly groupByToFilterProcessors: {[key in AnalyticMetricType]?: IPointToFilter} = {};
	private readonly filterStringParsers: {[key in DrillType]?: Pick<IPointToFilter, 'getRuleString'>} = {};

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils,
		@Inject('metricUtils') private readonly metricUtils: MetricUtils,
		@Inject('DateRange') private readonly DateRange,
		@Inject('contentProviderOptionsService') contentProviderOptionsService: ContentProviderOptionsService,
		@Inject('metricConstants') metricConstants: MetricConstants,
		private readonly attributeToFilterService: AttributeToFilterService,
		@Inject('timeToFilter') timeToFilter: TimeToFilter,
		topicOrTermsToFilterService: TopicOrTermsToFilterService,
		@Inject('customToFilter') customToFilter: CustomToFilter,
		@Inject('hierarchyModelToFilter') hierarchyModelToFilter: HierarchyModelToFilter,
		private readonly predefinedMetricToFilter: PredefinedMetricToFilterService,
		private readonly customGroupToFilterService: CustomGroupToFilterService
	) {
		this.constants = metricConstants.get();

		this.groupByToFilterProcessors[AnalyticMetricType.ATTRIBUTE] = attributeToFilterService;
		this.groupByToFilterProcessors[AnalyticMetricType.TIME] = timeToFilter;
		this.groupByToFilterProcessors[AnalyticMetricType.CLARABRIDGE] = topicOrTermsToFilterService;
		this.groupByToFilterProcessors[AnalyticMetricType.CUSTOM] = customToFilter;
		this.groupByToFilterProcessors[AnalyticMetricType.HIERARCHY_MODEL] = hierarchyModelToFilter;
		this.groupByToFilterProcessors[AnalyticMetricType.METRIC_PREDEFINED] = predefinedMetricToFilter;
		this.groupByToFilterProcessors[AnalyticMetricType.DRIVERS] = customGroupToFilterService;

		this.historicOptions = DateRange.historicOptionsArray;

		this.filterStringParsers[DrillType.ALERT_DOCUMENT] = {
			getRuleString: () => {
				return locale.getString('filter.alertDocumentFilter');
			}
		};

		this.filterStringParsers[DrillType.SENTENCE] = {
			getRuleString: () => {
				return locale.getString('filter.sentenceFilter');
			}
		};

		this.filterStringParsers[DrillType.SENTIMENT_RANGE] = {
			getRuleString: (filter: DrillFilter) => {
				return `${locale.getString('metrics.sentiment')}: ${filter.values[0]}`;
			}
		};

		Object.keys(this.groupByToFilterProcessors).forEach((key: AnalyticMetricType) => {
			this.groupByToFilterProcessors[key].toFilterTypes(this.filterStringParsers); // need to rewrite in a more clear way
		});
	}

	drillFrom = (widget: Widget, keepOriginalRunAs: boolean, defaultColor: IColorSelectorPalette): Widget => {
		const newWidget = cloneDeep(widget);
		if (!keepOriginalRunAs) {
			newWidget.properties.runAs = this.security.getEmail();
		}
		newWidget.drillDepth = newWidget.drillDepth ? 1 : newWidget.drillDepth + 1;

		if (newWidget.posX + newWidget.width >= GridsterConfigurer.GRIDSTER_COLUMNS) {
			newWidget.posX = 0;
			newWidget.posY = newWidget.posY + newWidget.height;
		} else {
			newWidget.posX = newWidget.posX + newWidget.width;
		}

		if (widget.properties.widgetType === WidgetType.TABLE) {
			newWidget.visualProperties.color = this.colorUtils.getDefaultColor(defaultColor);
			newWidget.visualProperties.customColor = this.colorUtils.getDefaultCustomColor(defaultColor);
		} else {
			newWidget.properties.useHistoricPeriod = false;
		}
		const selectedMetrics = newWidget.properties.selectedMetrics;
		if (DualAxisTypes.isParetoVisualization(newWidget.visualProperties)) {
			selectedMetrics.remove(_.findWhere(selectedMetrics, {name: StandardMetricName.CUMULATIVE_PERCENT_TOTAL}));
			delete newWidget.visualProperties.attributeSelections.secondaryYAxis;
			delete newWidget.visualProperties.attributeSelections.secondarySize;
			delete newWidget.visualProperties.secondaryYAxis;
			delete newWidget.visualProperties.secondaryChartType;
		}
		if (KeyTermsUtils.isKeyTermsWidget(widget.properties.selectedAttributes)) {
			selectedMetrics.remove(_.findWhere(selectedMetrics, {name: ClarabridgeMetricName.PERCENT_DELTA}));
			selectedMetrics.remove(_.findWhere(selectedMetrics, {name: ClarabridgeMetricName.KEY_TERM_RANK}));
		}

		newWidget.id = undefined;
		newWidget.properties.isCustomTitle = true;
		newWidget.details = undefined;
		delete newWidget.embedConfig;

		return newWidget;
	};

	addFiltersFromPoint = (source: Widget, point: DrillPoint, target?: Widget, isLinked?: boolean): void => {
		if (!target)
			target = source;
		const isDocumentLevelOnly = target.properties && target.properties.documentLevelOnly;
		const filters = this.getFiltersFromPoint(source, point, isDocumentLevelOnly);

		if (point._pop && (point._pop === ReportPeriods.HISTORIC)) {
			this.adjustPopProperties(source, point, target);
		}

		this.adjustKeyTermsDrillFilters(target, point, filters, isLinked);

		if (isLinked && ReportConstants.isSelectorWidget(source.properties.widgetType)) {
			_.each(filters, (linkedFilter) => {
				linkedFilter.parentWidgetId = source.id;
			});
		}

		if (target.properties.drillFilters) {
			this.checkDuplicatedAndAdd(target.properties.drillFilters, filters);
		} else {
			target.properties.drillFilters = filters || [];
		}
	};

	getFiltersFromPoint = (source: Widget, point: DrillPoint, isDocumentLevelOnly: boolean = false): any[] => {
		const filters = [];
		if (point._custom_filter) {
			filters.push(point._custom_filter);
		} else if (!_.isEmpty(source.properties.selectedAttributes)) {
			for (const groupBy of source.properties.selectedAttributes) {
				if (isDocumentLevelOnly && !AnalyticMetricTypes.isDocumentLevel(groupBy)) {
					continue;
				}
				const toFilterProcessor: IPointToFilter = this.groupByToFilterProcessors[groupBy.metricType];
				const points = point.link ? [point.link.from, point.link.to] : [point];
				for (const filterPoint of points) {
					const filter = toFilterProcessor.toFilter(source, filterPoint, groupBy);
					if (filter)	{
						filters.push(filter);
					}
				}
			}
		}
		return filters;
	};


	getRuleString = (filter: DrillFilter, widget: Widget): Promise<string | void> => {
		const parser = this.filterStringParsers[filter.type];
		if (parser) {
			return Promise.resolve(parser.getRuleString(filter, widget));
		}
		return Promise.resolve();
	};

	adjustColorProperty = (visualprops: VisualProperties, field: ColorTypes, defaultColor: IColorSelectorPalette): void => {
		if (ColorUtilsHelper.isObjectBasedColor(visualprops, field) ) {
			visualprops[field] = this.colorUtils.getDefaultColor(defaultColor);
			visualprops[ReportConstants.customColorFields[field]] = this.colorUtils.getDefaultCustomColor(defaultColor);
		}
	};

	checkDuplicatedAndAdd = (drillFilters: DrillFilter[], filters: DrillFilter[]): void => {
		const findFunc = (filter) => {
			return (f) => {
				return _.isEqual(f, filter);
			};
		};

		for (const filter of filters) {
			if (!_.find(drillFilters, findFunc(filter))) {
				drillFilters.push(filter);
			}
		}
	};

	getPrimaryGroupSettingsExtension = (widget: Widget, groupBy: ReportGrouping): any => {
		// copying config from lowest level group
		let result;
		if (!widget.properties || !widget.properties.selectedAttributes || !widget.properties.selectedAttributes.length) {
			result = {};
		} else {
			const lastAttribute = widget.properties.selectedAttributes[widget.properties.selectedAttributes.length - 1];
			if (lastAttribute.metricType === AnalyticMetricType.TIME) {
				result = DefaultMetricConfig;
			} else {
				const properties = CommonInherentProperties.attributeProperties
					.filter((property) => {
						return !groupBy.hasOwnProperty(property);
					});

				const settingsExtension = _.defaults(lastAttribute, DefaultMetricConfig);
				result = _.pick(settingsExtension, properties);
			}
		}

		if (groupBy.metricType === AnalyticMetricType.TIME) {
			// do not show empty trailing period for trend drilling from widget with time grouping
			result.emptyPeriodType = widget.properties && widget.properties.primaryTimeGrouping
				? EmptyPeriodType.SHOW_INTERSPERSED
				: EmptyPeriodType.SHOW_ALL;
		}
		result.identifier = GroupIdentifierHelper.generateIdentifier(groupBy, 0);

		return result;
	};

	adjustPopProperties = (widget: Widget, point: DrillPoint, target?: Widget): void => {
		target = target || widget;
		const dateFilterMode = widget.properties.dateRangeP2.dateFilterMode;
		if (widget.name !== 'cb_an_table') {
			// cb_an_table specific processing occurs in processSelectedMetric method
			if ( _.findWhere(this.historicOptions, {value: dateFilterMode})) {
				const dateRangeP1 = {
					dateFilterMode: 'custom',
					from: point.dateRangeP2.from,
					to: point.dateRangeP2.to
				};
				target.properties.dateRangeP1 = dateRangeP1;
			} else {
				target.properties.dateRangeP1 = cloneDeep(widget.properties.dateRangeP2);
				//compatible with preview widget
				target.properties.dateRangeP1.from = point.dateRangeP2.from;
				target.properties.dateRangeP1.to = point.dateRangeP2.to;
			}
		}
		let label = widget.visualProperties.periodLabel[ReportPeriods.HISTORIC];
		if (!label && widget.properties && widget.properties.dateRangeP2 && widget.properties.dateRangeP2.dateDisplayName) {
			label = widget.properties.dateRangeP2.dateDisplayName;
		}
		target.displayName += ' - ' + (label ? label : this.locale.getString('dateRange.' + dateFilterMode));
	};

	private readonly adjustKeyTermsDrillFilters = (
		target: Widget, point: DrillPoint, foregroundFilters: DrillFilter[] , isLinked: boolean
	): void => {
		const drillFilters = target.properties.drillFilters || [];
		_.each(drillFilters, (drillFilter) => {
			if (!isLinked && drillFilter.foregroundFilter) {
				drillFilter.foregroundFilter = false;
			}
		});

		if (point.groupBy && point.groupBy.name === this.constants.KEY_TERMS.name) {
			_.each(foregroundFilters, (foregroundFilter) => {
				foregroundFilter.foregroundFilter = true;
			});
		}
	};

	setPopForWidgetDrilledFromTableHistoricPoint = (widget: Widget, point: DrillPoint): void => {
		if (point._pop && (point._pop === ReportPeriods.HISTORIC)) {
			if (this.DateRange.valueOf(widget.properties.dateRangeP2.dateFilterMode).id >= 100) {
				// processing filter like "previous period", which has no explicit dates or queries
				if (point.P2StartDate && point.P2EndDate) {
					widget.properties.dateRangeP1 = {
						dateFilterMode: 'custom',
						from: moment(point.P2StartDate).format(),
						to: moment(point.P2EndDate).format()
					};
				}
			} else {
				widget.properties.dateRangeP1 = cloneDeep(widget.properties.dateRangeP2);
			}
		}
	};

	processSelectedMetric = (
		point: DrillPoint, widget: Widget,
		setCalculation: (metric: ReportCalculation, widget: Widget) => void
	): void => {
		const selectedMetricName = point.selectedMetricName || point._calculation_series;
		if (selectedMetricName) {
			const sourceSelectedMetrics = widget.properties.selectedMetrics;
			widget.properties.selectedMetrics = [];

			const selectedMetric = _.find(sourceSelectedMetrics, (metric) => {
				return metric.name === selectedMetricName;
			});

			if (selectedMetric) {
				if (selectedMetric.isPopMetric) {
					const parentMetric = this.getOriginalMetric(selectedMetric, sourceSelectedMetrics);

					if (parentMetric) {
						widget.properties.selectedMetrics.push(parentMetric);
						setCalculation(parentMetric, widget);
					}
				} else {
					widget.properties.selectedMetrics.push(selectedMetric);
					setCalculation(selectedMetric, widget);
				}
			} else if (sourceSelectedMetrics && sourceSelectedMetrics.length > 0) {
				const firstMetric = this.getOriginalMetric(sourceSelectedMetrics[0], sourceSelectedMetrics);
				widget.properties.selectedMetrics.push(firstMetric);
				setCalculation(firstMetric, widget);
			}
		}
	};

	processHierarchyMetrics = (widget: Widget): void => {
		if (!AnalyticMetricTypes.isHierarchyModel(widget.properties.selectedAttributes[0])) {
			const selectedMetrics = cloneDeep(widget.properties.selectedMetrics);
			const hierarchyMetrics = _.filter(widget.properties.selectedMetrics, this.metricUtils.isHierarchyMetric);
			widget.properties.selectedMetrics = _.filter(widget.properties.selectedMetrics, (metric) => {
				return !_.contains(hierarchyMetrics, metric);
			});
			if (selectedMetrics && selectedMetrics.length !== widget.properties.selectedMetrics.length) {
				if (!_.findWhere(widget.properties.selectedMetrics, {name: this.constants.VOLUME.name})) {
					widget.properties.selectedMetrics.push(this.constants.VOLUME);
				}
			}
			// replace hiearchy metrics in visual props (line/bar/scatter)
			const fieldsWithHierarchyMetric = ['yAxis', 'secondaryYAxis', 'xAxis'];
			const hierarchyMetricNames = _.pluck(hierarchyMetrics, 'name');
			_.each(fieldsWithHierarchyMetric, (key) => {
				if (widget.visualProperties.attributeSelections) {
					const value = widget.visualProperties.attributeSelections[key];
					if (value && this.metricUtils.isHierarchyMetric(value)) {
						widget.visualProperties.attributeSelections[key] = cloneDeep(this.constants.VOLUME);
					}
				}
				if (_.contains(hierarchyMetricNames, widget.visualProperties[key])) {
					widget.visualProperties[key] = this.constants.VOLUME.name;
				}
			});
		}
	};

	private readonly getOriginalMetric = (metric: ReportCalculation, selectedMetrics: ReportCalculation[]): ReportCalculation => {
		if (metric && metric.isPopMetric) {
			return _.findWhere(selectedMetrics, {name: metric.parentMetricName});
		} else {
			return metric;
		}
	};

	getSelectedMetric = (widget: Widget, metricName: string, calculation?: ReportCalculation): ReportCalculation => {
		if (metricName === this.constants.STRENGTH.name
				|| (calculation && _.isMatch(calculation, this.constants.CONSTANT_SIZE))) // strength is not supported by any drill
			return this.constants.VOLUME;
		const metric = _.findWhere(widget.properties.selectedMetrics, { name: metricName });
		return !isEmpty(metric) ? metric : this.constants.VOLUME;
	};

	getCalculationAttribute = (widget: Widget, seriesType?: string): ReportCalculation => {
		let calculationAttribute;
		if (!widget.properties.selectedMetrics || widget.properties.selectedMetrics.length === 0) {
			return this.constants.VOLUME;
		} else if (widget.properties.selectedMetrics.length === 1) {
			calculationAttribute = widget.properties.selectedMetrics[0];
		} else {
			calculationAttribute = (seriesType && seriesType === 'secondary')
				? widget.properties.selectedMetrics[1]
				: widget.properties.selectedMetrics[0];
		}
		return this.getSelectedMetric(widget, calculationAttribute.name, calculationAttribute);
	};

	adjustMaxGroupingSize = (widget: Widget, targetType: string): void => {
		if (targetType === 'key_terms') {
			_.each(widget.properties.selectedAttributes, (attribute) => {
				if (attribute.size && attribute.size > 100) {
					attribute.size = 10;
				}
			});
		} else if (widget.properties.widgetType === WidgetType.TABLE && targetType !== 'table') {
			_.each(widget.properties.selectedAttributes, (attribute) => {
				if (attribute.size && attribute.size > 1000) {
					attribute.size = 1000;
				}
			});
		}
	};
}

app.service('commonDrill', downgradeInjectable(CommonDrillService));
