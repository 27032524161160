import { Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CollapsingBaseComponent } from '@app/shared/components/structural/collapsing-base';


@Component({
	selector: 'collapsing-panel',
	template: `
<section class="panel panel-default" [attr.aria-expanded]="!panelCollapsed" [ngClass]="{'border-radius-0 border-0 mb-0': !rounded}">
	<div class="panel-heading d-flex justify-start pv-16"
		[ngClass]="{
			'cursor-pointer': !disableCollapse,
			'disable-collapse': disableCollapse,
			'expanded': !panelCollapsed,
			'border-radius-0': !rounded,
			'border-0': !rounded
		}"
		[style.borderBottomWidth]="panelCollapsed ? 0 : '1px'"
		(click)="toggleCollapse()">
		<div class="panel-actions kb-focusable mr-4" *ngIf="!disableCollapse" tabindex="0" (keydown.enter)="toggleCollapse()">
			<i class="q-icon q-icon-angle-bottom" *ngIf="!panelCollapsed" aria-hidden="true"></i>
			<i class="q-icon q-icon-angle-right" *ngIf="panelCollapsed" aria-hidden="true"></i>
		</div>
		<ng-content select="panel-heading"></ng-content>
	</div>
	<div class="panel-body" *ngIf="!panelCollapsed">
		<ng-content select="panel-body"></ng-content>
	</div>
</section>
`,
	styles: [
		`:host ::ng-deep panel-heading {
			display: flex;
			align-items: center;
		}
		`
	]
})
export class CollapsingPanelComponent extends CollapsingBaseComponent {
	constructor(locale: CxLocaleService) {
		super(locale);
	}
}
app.directive('collapsingPanel', downgradeComponent({ component: CollapsingPanelComponent }));
