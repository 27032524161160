import { Injectable, Inject, EventEmitter } from '@angular/core';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { SamlIdentityProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-identity-providers/saml-identity-providers.component';
import { SamlIdentityProviderApiService } from '@app/modules/system-administration/saml-settings/saml-identity-provider-api.service';

@Injectable()
export class SamlIdentityProvidersListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private locale: CxLocaleService,
		private identityProviderApi: SamlIdentityProviderApiService,
		@Inject('exportUtils') private exportUtils,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService
	) {}

	edit(identityProvider: SamlIdentityProvider, controller: SamlIdentityProvidersComponent): void {
		controller.editIdentityProvider(identityProvider);
	}

	create = (identityProvider: SamlIdentityProvider): void => {
		this.identityProviderApi.createIdentityProvider(identityProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('administration.samlIDPAdded', {itemName: identityProvider.entityId}));
			this.onChange.emit();
		});
	};

	update = (identityProvider: SamlIdentityProvider): void => {
		this.identityProviderApi.updateIdentityProvider(identityProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(
				this.locale.getString('administration.samlIDPUpdated', {itemName: identityProvider.entityId}));
			this.onChange.emit();
		});
	};

	delete(identityProvider: SamlIdentityProvider): void {
		this.cbDialogService.confirm(this.locale.getString('common.delete'),
			this.locale.getString('administration.samlConfirmIDPDelete', {itemName: identityProvider.entityId})).result
			.then(() => {
				this.identityProviderApi.deleteIdentityProvider(identityProvider.entityId).then(() => {
					this.globalNotificationService.addSuccessNotification(
						this.locale.getString('administration.samlIDPDeleted', {itemName: identityProvider.entityId}));
					this.onChange.emit();
				});
			});
	}

	toggle(identityProvider: SamlIdentityProvider): void {
		identityProvider.enabled = !identityProvider.enabled;

		let notificationMessage = identityProvider.enabled
			? this.locale.getString('administration.samlIDPEnabled', {itemName: identityProvider.entityId})
			: this.locale.getString('administration.samlIDPDisabled', {itemName: identityProvider.entityId});

		this.identityProviderApi.updateIdentityProvider(identityProvider).then(() => {
			this.globalNotificationService.addSuccessNotification(notificationMessage);
			this.onChange.emit();
		});
	}

	downloadMetadataFile(identityProvider: SamlIdentityProvider): void {
		this.identityProviderApi.downloadMetadataFile(identityProvider.entityId).then(response => {
			this.exportUtils.downloadResponseFile(response);
		});
	}
}
