/**
 * Cache service for single doc explorer, need to be cleared before usage
 */

import * as _ from 'underscore';
import * as moment from 'moment';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { Verbatim } from '../entities/verbatim';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { DocumentLink } from '../entities/document-link';
import { ContentProviderId, ProjectId, AccountId } from '@cxstudio/generic-types';
import { DocumentTextMode } from '../document-text-mode.enum';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IRequestShortcutConfig } from 'angular';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';
import { ObjectUtils } from '@app/util/object-utils';

export interface IDocumentProperties extends WidgetProperties {
	documentId?: number;
	includeSentenceAttributes: boolean;
	voiceFolder?: string;
	audioDocument?: boolean;
	analyticFeedbackPreviewDocument?: boolean;
	documentLevelOnly?: boolean;
	encodedDescriptor?: string;
	includeTopics?: boolean;
	excludeModels?: number[];
	selectedModels?: number[];
	textMode?: DocumentTextMode;
}

export interface IDocumentWidget extends Widget {
	properties: IDocumentProperties;
}

interface ICacheDocument {
	id: number;
	natural_id: string;
	tweet: any;
	sentences: PreviewSentence[];
	verbatims: Verbatim[];
	sm_service: string;
}


export class DocumentCacheService {
	documents: {[id: string]: ICacheDocument} = {};
	tweets = {};

	processedDocuments = {};
	private contentProviderId: ContentProviderId;
	private projectId: ProjectId;
	private parentWidgetId: number;
	private accountId: AccountId;

	constructor(
		private $http: ng.IHttpService,
		private $q: ng.IQService,
		private $log: ng.ILogService,
		private reportDataApiService: ReportDataApiService,
		private widgetDataServiceFactory,
		private httpHandlers: HttpHandlers
	) {}

	updateTwitterDocuments = (twitterDocuments, mapping) => {
		if (!twitterDocuments || !twitterDocuments.length)
			return;
		twitterDocuments.forEach((tweet) => {
			if (mapping[tweet.id_str])
				this.tweets[mapping[tweet.id_str]] = tweet;
		});
		for (let tweetId in mapping) { // some documents don't have tweet, but we still should filter them
			if (mapping.hasOwnProperty(tweetId)) {
				this.processedDocuments[mapping[tweetId]] = true;
			}
		}
	};

	getDocumentMetadata = (
			project: IProjectSelection,
			workspaceProject: WorkspaceProject,
			documentId: number,
			naturalId, fileId?: string, encodedDescriptor?: string, runAsUser?: string) => {

		let parameters = {
			contentProviderId: project.contentProviderId,
			accountId: project.accountId,
			projectId: project.projectId,
			workspaceProject,
			userEmail: runAsUser
		};

		let config = {
			params: { naturalId, fileId, encodedDescriptor },
			cache: CacheOptions.CACHED
		};

		let endpoint: string = `rest/common/document/${documentId}/metadata`;
		return this.$http.post(endpoint, parameters, config);
	};

	decodeDocumentDescriptor = (descriptor): ng.IPromise<DocumentLink> => {
		return this.$http.post('rest/common/document/decode', descriptor, {
			cache: CacheOptions.CACHED, local: true} as IRequestShortcutConfig).then(this.httpHandlers.success);
	};

	getDocumentKey(documentId: number, widget: IDocumentWidget): string {
		return `${widget.properties.contentProviderId}_${widget.properties.accountId}_${widget.properties.project}_${documentId}`;
	}

	getAnDocument = (documentId: number, widget: IDocumentWidget,
		isDocExplorer: boolean = false): ng.IPromise<any> => {

		let documentKey = this.getDocumentKey(documentId, widget);
		if (documentKey && this.documents[documentKey]) {
			let doc = this.documents[documentKey];
			if (this.tweets[documentKey])
				doc.tweet = this.tweets[documentKey];
			return this.$q.when(ObjectUtils.copy(doc)); // return copy to avoid polluting cached value with ui changes
		} else {
			let documentPromise = this.sendAnDocumentRequest(widget, documentId, isDocExplorer);
			return documentPromise.then((resp) => {
				let data = resp.data[0];
				data.metadata = resp.metadata;
				this.processAnDocument(data);
				this.documents[documentKey] = data;
				return this.processTwitter(data, documentId).then((doc) => {
					return ObjectUtils.copy(doc);
				});
			});
		}
	};

	isDocumentRequired = (docId): boolean => {
		return !this.processedDocuments[docId];
	};

	processAnDocument = (doc: ICacheDocument): void => {
		if (doc.sentences) {
			doc.verbatims = _.chain(doc.sentences)
				.groupBy(sentence => sentence.verbatimId)
				.map(verbSentences => ({
					id: verbSentences[0].verbatimId,
					documentId: doc.id,
					type: verbSentences[0].verbatimType,
					sentences: verbSentences
				} as Verbatim))
				.sortBy(verbatim => verbatim.type?.toLowerCase())
				.value();
		}
	};

	sendAnDocumentRequest = (widget: IDocumentWidget, documentId: number, isDocExplorer: boolean) => {
		let docWidget: IDocumentWidget = angular.copy(widget);
		let widgetDataService = this.widgetDataServiceFactory.create(widget);

		docWidget.name = 'document';
		docWidget.properties = {
			contentProviderId: widget.properties.contentProviderId,
			contentProviderName: widget.properties.contentProviderName,
			accountId: widget.properties.accountId,
			accountName: widget.properties.accountName,
			project: widget.properties.project,
			projectName: widget.properties.projectName,
			workspaceProject: widget.properties.workspaceProject,
			runAs: widget.properties.runAs,
			documentId,
			widgetType: WidgetType.DOCUMENT_EXPLORER,
			timezoneOffset: new Date().getTimezoneOffset(),
			timezoneName: moment.tz.guess(),
			includeSentenceAttributes: widget.properties.includeSentenceAttributes,
			audioDocument: !!widget.properties.audioDocument,
			analyticFeedbackPreviewDocument: !isDocExplorer,
			documentLevelOnly: widget.properties.documentLevelOnly,
			encodedDescriptor: widget.properties.encodedDescriptor,
			orgFilters: widget.properties.orgFilters,
			dashboardAttributeFilters: widget.properties.dashboardAttributeFilters,
			adhocFilter: widget.properties.adhocFilter,
			appliedFilters: widget.properties.appliedFilters,
		};

		if (widget.name === WidgetType.PREVIEW && !isDocExplorer && widget.properties.includeTopics) {
			docWidget.properties.selectedModels = widget.properties.selectedModels;
		}

		if (docWidget.properties.audioDocument) {
			docWidget.properties.voiceFolder = widget.properties.voiceFolder;
		}

		this.excludeGlobalOtherModelFromClassification(docWidget);

		return widgetDataService.getAnDocumentData(docWidget);
	};

	private excludeGlobalOtherModelFromClassification = (docWidget: IDocumentWidget): void => {
		let filterRules: any[] = docWidget.parentWidget?.properties?.adhocFilter?.filterRules;
		if (docWidget.containerId === 'adhocGlobalOther' && filterRules) {
			let globalOtherModel = _.findWhere(filterRules, {type: 'TOPIC_GLOBAL_OTHER'});
			if (globalOtherModel) {
				docWidget.properties.excludeModels = [globalOtherModel.topicId];
			}
		}
	};

	private isTwitter = (service: string): boolean => {
		return service && service.toLowerCase() === 'twitter';
	};

	private isDocumentOrTweetProcessed = (documentId: number): boolean => {
		return this.processedDocuments[documentId] || this.tweets[documentId];
	};

	processTwitter = (doc: ICacheDocument, documentId: number): ng.IPromise<ICacheDocument> => {
		let smService = doc.sm_service;
		if (this.isDocumentOrTweetProcessed(documentId) || !this.isTwitter(smService)) {
			if (this.tweets[documentId])
				doc.tweet = this.tweets[documentId];
			return this.$q.when(doc);
		} else { // documents request still pending, use single tweet api
			this.processedDocuments[documentId] = true;
			let idAttr = doc.natural_id;
			if (!idAttr) {
				return this.$q.when(doc);
			}

			return this.reportDataApiService.loadSingleTweetFromTwitter(idAttr).then(
				this.getSuccessfulTweetHandler(doc, documentId),
				this.getFailedTweetHandler(doc));
		}
	};

	private getSuccessfulTweetHandler = (doc: ICacheDocument, documentId) => {
		return (resp) => {
			let tweet = resp.data;
			this.tweets[documentId] = tweet;
			doc.tweet = this.tweets[documentId];
			return doc;
		};
	};

	private getFailedTweetHandler = (doc: ICacheDocument) => {
		return (err) => {
			this.$log.error('Cannot load tweet', err.data);
			return doc;
		};
	};

	init = (cp: number, account: number, project: number, widgetId: number) => {
		this.documents = {};
		this.tweets = {};
		this.processedDocuments = {};
		this.contentProviderId = cp;
		this.accountId = account;
		this.projectId = project;
		this.parentWidgetId = widgetId;
	};
}

app.service('documentCacheService', DocumentCacheService);
