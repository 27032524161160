import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import * as _ from 'underscore';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { MasterAccountQualtricsStatus } from './master-account-qualtrics-status';

@Injectable({
	providedIn: 'root'
})
export class QualtricsStatusTableColumnsService {

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		return [
			{
				name: 'masterAccountId',
				displayName: this.locale.getString('administration.masterAccountId'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'masterAccountName',
				displayName: this.locale.getString('common.masterAccount'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'qualtricsIntegration',
				displayName: this.locale.getString('mAccount.qualtricsIntegrationLabel'),
				formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountQualtricsStatus>('qualtricsIntegration'),
				width: 0.07
			}, {
				name: 'brandName',
				displayName: this.locale.getString('mAccount.qualtricsBrandAlias'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'brandId',
				displayName: this.locale.getString('mAccount.qualtricsBrandId'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'datacenter',
				displayName: this.locale.getString('mAccount.qualtricsDataCenter'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'mappedUsers',
				displayName: this.locale.getString('mAccount.mappedUsers'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'qualtricsTicketing',
				displayName: this.locale.getString('mAccount.qualtricsTicketing'),
				formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountQualtricsStatus>('qualtricsTicketing'),
				width: 0.07
			}, {
				name: 'qualtricsSSOProvider',
				displayName: this.locale.getString('mAccount.qualtricsSSOProvider'),
				formatter: this.tableFormattersService.plainTextFormatter,
				width: 0.07
			}, {
				name: 'enforceExclusiveAuth',
				displayName: this.locale.getString('mAccount.enforceXmAuthentication'),
				formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountQualtricsStatus>('enforceExclusiveAuth'),
				width: 0.07
			}, {
				name: 'userSync',
				displayName: this.locale.getString('mAccount.userSync'),
				formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountQualtricsStatus>('userSync'),
				width: 0.07
			}, {
				name: 'qualtricsAPIIntegration',
				displayName: this.locale.getString('mAccount.qualtricsAPIIntegration'),
				formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountQualtricsStatus>('qualtricsAPIIntegration'),
				width: 0.07
			}
		];
	};
}
