import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class LineDescriptionBuilder extends BasicWidgetDescriptionBuilder {

	getDescription = (data?: any) => {
		let selections = this.visual.attributeSelections;
		if (!selections) {
			return '';
		}
		let primaryGroup = new Grouping(selections.primaryGroup?.displayName);
		let calculation = selections.yAxis?.displayName;
		let secondaryGroup = new Grouping(selections.secondaryGroup?.displayName || '',
			this.utils.getGroupingType(selections.secondaryGroup?.type)
		);
		let split = selections.secondaryGroup?.name
			? this.locale.getString('widgetDescription.splitBy', {grouping: secondaryGroup})
			: '';
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('primaryAxisPalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.lineDescription', {
			chartName: this.utils.getChartName(this.visual.subChartType),
			grouping: primaryGroup,
			calculation,
			split,
			total
		});
		if (this.visual.secondaryYAxis) {
			let secondaryPalette = this.utils.getPaletteDescription('secondaryAxisPalette', this.visual.secondaryColor);
			let secondary = this.locale.getString('widgetDescription.secondaryAxis', {
				calculation: selections.secondaryYAxis?.displayName,
				displayType: this.utils.getChartName(this.visual.secondaryChartType)
			});
			description = `${description} ${secondary}${palette}${secondaryPalette}`;
		} else {
			description = `${description}${palette}`;
		}
		if (!!data?.data && selections.primaryGroup && selections.yAxis) {
			let peak = this.utils.getChartFeatureDescription(selections.primaryGroup, selections.yAxis, data, true);
			let trough = this.utils.getChartFeatureDescription(selections.primaryGroup, selections.yAxis, data, false);
			description = this.utils.joinMessageParts([description, peak, trough]);

			if (this.visual.caseVisualizations?.enabled) {
				let range = this.locale.getString(`dateRange.${this.properties.dateRangeP1.dateFilterMode}`);
				let chartCases = this.locale.getString('widgetDescription.chartCases', { range });

				return this.utils.getWidgetUtils(this.widget).then((widgetUtils) => {
					let count = _.filter(widgetUtils.engagorCases, (engagorCase) => engagorCase.events?.length > 0).length;
					let chartManagedCases = count > 0
						? this.locale.getString('widgetDescription.chartManagedCases', { count })
						: '';
					return this.utils.joinMessageParts([description, chartCases, chartManagedCases]);
				}) as any;
			}
		}
		return description;
	};

}
