export class LocalizedConstants<T> {
	protected options: {[key: string]: T};

	values = (): {[key: string]: T} => this.options;

	valuesArray = (): T[] => {
		let retArray: T[] = [];

		for (let key in this.options) {
			if (!_.isUndefined(this.options[key]))
				retArray.push(this.options[key]);
		}

		return retArray;
	};

	findByValue = (value, valueProperty: keyof T = 'value' as keyof T): T => {
		let matchOption = this.valuesArray().filter(option => option[valueProperty] === value);
		return !matchOption.isEmpty() ?
			matchOption[0] :
			undefined;
	};
}
