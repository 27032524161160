import { ApplicationRef, ComponentFactoryResolver, Injectable, Injector } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { WidgetError } from '../common/widget-error/widget-error.class';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WidgetErrorComponent } from '../common/widget-error/widget-error.component';

@Injectable({
	providedIn: 'root'
})
export class ReportErrorMessagesService {

	constructor(
		private readonly resolver: ComponentFactoryResolver,
		private readonly injector: Injector,
		private readonly locale: CxLocaleService,
		private readonly appRef: ApplicationRef,
	) { }

	addErrorMessageToElement = (element: JQuery, messageText: string) => {
		const factory = this.resolver.resolveComponentFactory(WidgetErrorComponent);
		const componentRef = factory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);

		componentRef.instance.widgetError = new WidgetError(messageText);
		element.empty();
		element.append(componentRef.location.nativeElement);
		return false;
	};


	addWidgetErrorToElement = (element: JQuery, widgetError: WidgetError) => {
		const factory = this.resolver.resolveComponentFactory(WidgetErrorComponent);
		const componentRef = factory.create(this.injector);
		this.appRef.attachView(componentRef.hostView);

		componentRef.instance.widgetError = widgetError;

		element.empty();
		element.append(componentRef.location.nativeElement);

		return false;
	};

	putContentProviderUnavailableError = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString(
			'widget.contentProviderUnavailable'));
	};

	putInvalidDataFormatError = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString(
			'widget.invalidDataFormat'));
	};

	putNoDataWarning = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString('widget.noData'));
	};

	putNullIncludesWarning = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString('widget.nullIncludes'));
	};

	putInternalServerError = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString('widget.dataError'));
	};

	putMetricFormattingErrorMessage = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString('widget.metricFormattingError'));
	};

	putNegativeSizeWarning = (element: JQuery) => {
		this.addErrorMessageToElement(element, this.locale.getString('widget.negativeSizeMetric'));
	};

	putFilteredByConfidentiality = (element: JQuery) => {
		this.addWidgetErrorToElement(element, WidgetError.DATA_RESTRICTED);
	};
}

app.service('reportErrorMessages', downgradeInjectable(ReportErrorMessagesService));
