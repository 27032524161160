import ILocale from '@cxstudio/interfaces/locale-interface';
import { BoundaryField } from './boundary-field';
import { BoundaryType } from './boundary-type';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';

export type SetGroupingGeographyFunc = (attribute: IAdminAttribute | IAdminModel, value: BoundaryField) => void;
export type SetModelGeographyFunc = (model, level: number, value: BoundaryField) => void;

export class GeographyOptionsService {

	constructor(
		private locale: ILocale
	) {

	}

	getModelOptions = (model: IAdminModel, depth: number, setGeographyFunc?: SetModelGeographyFunc): any => {
		let options = [];

		for (let i = 0; i < depth; i++) {
			let selectedOption: BoundaryField = model.boundaryFields?.[i];
			let items = this.buildGroupingGeographyOptions(model, (grouping, value) => setGeographyFunc(grouping, i, value));

			let levelOption: any = {
				name: `level${i}`,
				text: `${this.locale.getString('widget.level')} ${i + 1}`,
				items
			};

			if (selectedOption) {
				levelOption.classes = ['checked'];
				this.checkOption(selectedOption, items);
			}

			options.push(levelOption);
		}

		return options;
	};

	getNumericAttributeGeographyOptionsOld = (attribute?, setGeographyFunc?: SetGroupingGeographyFunc): any => {
		let items = this.buildNumericAttributeGeographyOptions(attribute, setGeographyFunc);
		let selectedOption = attribute.geography?.boundaryField;
		if (selectedOption) {
			this.checkOption(selectedOption, items);
		}

		return items;
	};

	private buildNumericAttributeGeographyOptions = (attribute?, setGeographyFunc?: SetGroupingGeographyFunc): any => {
		return [
			this.getNoneOption(attribute, setGeographyFunc),
			this.getFolder(BoundaryType.STATE, [
				this.getBoundaryFieldOption(BoundaryField.STATE_FIPS_CODE, BoundaryType.STATE, attribute, setGeographyFunc)
			]),
			this.getFolder(BoundaryType.US_COUNTY, [
				this.getBoundaryFieldOption(BoundaryField.US_COUNTY_FIPS_CODE, BoundaryType.US_COUNTY, attribute, setGeographyFunc)
			]),
			this.getBoundaryFieldOption(BoundaryField.POSTAL_ZIP, BoundaryType.POSTAL, attribute, setGeographyFunc)
		];
	};

	getNumericAttributeGeographyOptions(): UIOption<BoundaryField>[] {
		return [
			{value: null, displayName: ' '},
			this.getFlatOption(BoundaryType.STATE, BoundaryField.STATE_FIPS_CODE),
			this.getFlatOption(BoundaryType.US_COUNTY, BoundaryField.US_COUNTY_FIPS_CODE),
			this.getFlatOption(BoundaryType.US_COUNTY, BoundaryField.US_COUNTY_FIPS_CODE),
			this.getFlatOption(null, BoundaryField.POSTAL_ZIP),
		];
	}

	getTextAttributeGeographyOptions(): UIOption<BoundaryField>[] {
		return [
			{value: null, displayName: ' '},
			this.getFlatOption(BoundaryType.COUNTRY, BoundaryField.COUNTRY_NAME),
			this.getFlatOption(BoundaryType.COUNTRY, BoundaryField.COUNTRY_THREE_LETTER_CODE),
			this.getFlatOption(BoundaryType.COUNTRY, BoundaryField.COUNTRY_TWO_LETTER_CODE),
			this.getFlatOption(BoundaryType.STATE, BoundaryField.STATE_NAME),
			this.getFlatOption(BoundaryType.STATE, BoundaryField.STATE_FOUR_LETTER_CODE),
			this.getFlatOption(BoundaryType.STATE, BoundaryField.STATE_POSTAL_CODE),
			this.getFlatOption(BoundaryType.STATE, BoundaryField.STATE_FIPS_CODE),
			this.getFlatOption(BoundaryType.US_COUNTY, BoundaryField.US_COUNTY_NAME),
			this.getFlatOption(BoundaryType.US_COUNTY, BoundaryField.US_COUNTY_FIPS_CODE),
			this.getFlatOption(null, BoundaryField.POSTAL_ZIP),
		];
	}

	private getFlatOption(boundaryType: BoundaryType, boundaryField: BoundaryField): UIOption<BoundaryField> {
		const folderName = boundaryType ? this.locale.getString(`attributes.boundary_${boundaryType.toLowerCase()}`) : undefined;
		const optionName = this.getBoundaryFieldDisplayName(boundaryField);
		const displayName = boundaryType ? `${folderName} > ${optionName}` : optionName;
		return {value: boundaryField, displayName};
	}

	getGroupingGeographyOptionsOld = (grouping?, setGeographyFunc?: SetGroupingGeographyFunc): any => {
		let items = this.buildGroupingGeographyOptions(grouping, setGeographyFunc);
		let selectedOption = grouping?.geography?.boundaryField;
		if (selectedOption) {
			this.checkOption(selectedOption, items);
		}

		return items;
	};

	private buildGroupingGeographyOptions = (grouping?, setGeographyFunc?: SetGroupingGeographyFunc): any => {
		return [
			this.getNoneOption(grouping, setGeographyFunc),
			this.getFolder(BoundaryType.COUNTRY, [
				this.getBoundaryFieldOption(BoundaryField.COUNTRY_NAME, BoundaryType.COUNTRY, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.COUNTRY_THREE_LETTER_CODE, BoundaryType.COUNTRY, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.COUNTRY_TWO_LETTER_CODE, BoundaryType.COUNTRY, grouping, setGeographyFunc)
			]),
			this.getFolder(BoundaryType.STATE, [
				this.getBoundaryFieldOption(BoundaryField.STATE_NAME, BoundaryType.STATE, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.STATE_FOUR_LETTER_CODE, BoundaryType.STATE, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.STATE_POSTAL_CODE, BoundaryType.STATE, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.STATE_FIPS_CODE, BoundaryType.STATE, grouping, setGeographyFunc)
			]),
			this.getFolder(BoundaryType.US_COUNTY, [
				this.getBoundaryFieldOption(BoundaryField.US_COUNTY_NAME, BoundaryType.US_COUNTY, grouping, setGeographyFunc),
				this.getBoundaryFieldOption(BoundaryField.US_COUNTY_FIPS_CODE, BoundaryType.US_COUNTY, grouping, setGeographyFunc)
			]),
			this.getBoundaryFieldOption(BoundaryField.POSTAL_ZIP, BoundaryType.POSTAL, grouping, setGeographyFunc)
		];
	};

	private checkOption = (selectedOption: BoundaryField, items: any[]): void => {
		let flatItems = this.flattifyOptions(items);
		let selectedFlatItem = _.findWhere(flatItems, { name: selectedOption.toLowerCase() });

		_.findWhere(flatItems, { name: selectedFlatItem.name }).classes = ['checked'];
		if (selectedFlatItem.parentName) {
			_.findWhere(flatItems, { name: selectedFlatItem.parentName }).classes = ['checked'];
		}
	};

	private getNoneOption = (object, setGeographyFunc: SetGroupingGeographyFunc): any => {
		return { name: 'none', text: this.locale.getString('common.none'), func: () => setGeographyFunc(object, null) };
	};

	private getFolder = (boundaryType: BoundaryType, items: any[]): any => {
		let folderName = boundaryType.toLowerCase();
		return { boundaryType, name: folderName, text: this.locale.getString(`attributes.boundary_${folderName}`), items };
	};

	getOptionDropdownName = (boundaryField: BoundaryField): string => {
		let option = _.findWhere(this.getFlatGeographyOptions(), { name: boundaryField.toLowerCase() });
		return option.parentText ? option.parentText : option.text;
	};

	private getFlatGeographyOptions = (): any[] => {
		let options = this.getGroupingGeographyOptionsOld();
		return this.flattifyOptions(options);
	};

	private flattifyOptions = (options: any[]): any[] => {
		let flatOptions = [];

		for (let option of options) {
			flatOptions.push(option);

			if (option.items) {
				option.items.forEach(item => {
					item.parentText = option.text;
					item.parentName = option.name;
				});
				flatOptions.pushAll(option.items);
			}
		}

		return flatOptions;
	};

	private getBoundaryFieldOption = (boundaryField: BoundaryField, boundaryType: BoundaryType,
			object?: any, setGeographyFunc?: SetGroupingGeographyFunc): any => {
		let lowercasedField = boundaryField.toLowerCase();
		return {
			name: lowercasedField,
			boundaryField,
			boundaryType,
			text: this.getBoundaryFieldDisplayName(boundaryField),
			func: () => setGeographyFunc(object, boundaryField)
		};
	};

	getBoundaryFieldDisplayName = (boundaryField: BoundaryField): string => {
		return this.locale.getString(`attributes.${boundaryField.toLowerCase()}`);
	};

	getBoundaryFieldBoundaryType = (boundaryField: BoundaryField): BoundaryType => {
		let options = this.getFlatGeographyOptions();
		let option = _.findWhere(options, { boundaryField });
		return option.boundaryType;
	};

}

app.service('geographyOptionsService', GeographyOptionsService);
