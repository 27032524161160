import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { NameService } from '@cxstudio/common/name-service';
import { TreeService } from '@cxstudio/services/tree-service.service';

export interface GridDeleteModalSettings {
	title: string;
	warning: string;
}

@Injectable({
	providedIn: 'root'
})
export class GridUpdateService {

	constructor(
		private dialogService: CxDialogService,
		private locale: CxLocaleService,
		@Inject('treeService') private treeService: TreeService,
		@Inject('nameService') private nameService: NameService
	) {}

	delete = (items: any[], list: any[], apiFunction: (items: any[]) => ng.IPromise<any>,
		modalSettings: GridDeleteModalSettings): Promise<void> => {
		let confirmation = this.dialogService.delete(modalSettings.title, modalSettings.warning);
		return confirmation.result.then(() => {
			return PromiseUtils.wrap(apiFunction(items)).then(() => {
				items.forEach(item => {
					this.treeService.deleteItem(list, item);
				});
				return;
			});
		});
	};

	copy = (item: any, list: any[], apiFunction: (item: any) => ng.IPromise<any>,
		field: string = 'name', modifyFn?: (copiedItem: any) => any): Promise<any> => {

		let itemCopy = ObjectUtils.copy(item);
		itemCopy[field] = this.nameService.uniqueName(
			item[field] + ' - ' + this.locale.getString('dashboard.copyName'), list, field);

		if (modifyFn) {
			itemCopy = modifyFn(itemCopy);
		}
		return PromiseUtils.wrap(apiFunction(itemCopy)).then(newItem => {
			this.treeService.addItem(list, newItem);
			return newItem;
		});
	};
}
app.service('gridUpdateService', downgradeInjectable(GridUpdateService));
