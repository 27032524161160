import * as _ from 'underscore';
import { UserQueryType } from '@cxstudio/query/user-query-type.enum';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { DateUtilsService } from '@app/modules/utils/dates/date-utils.service';
import { UserSearchAPI } from '@app/modules/user-administration/search-panel/user-search-api.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { IUserColumn } from '@cxstudio/user-administration/users/users.component';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { Component, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'user-column',
	templateUrl: './user-column.component.html'
})
export class UserColumnComponent implements OnInit {

	@Input() column: IUserColumn;
	@Output() onChange: EventEmitter<void> = new EventEmitter();

	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;

	licenseTypes: LicenseTypeItem[];
	filterText: string;
	userQueryDateRange: IDateRange;
	isFilterable: boolean;


	constructor(
		private licenseApiService: LicenseApiService,
		@Inject('dateUtils') private dateUtils: DateUtilsService,
		private userSearchAPI: UserSearchAPI
	) {}

	ngOnInit(): void {
		this.licenseApiService.getLicenseTypes().then((result) => {
			this.licenseTypes = result;
		});
		this.filterText = this.column.filterText;
		this.isFilterable = !isEmpty(this.column.filterField);
		if (this.isDate())
			this.userQueryDateRange = {} as IDateRange;
	}

	getCssClass(): string {
		return 'br-user-column' + this.column.filterField;
	}

	showFilterIcon(): boolean {
		return  this.isFilterable && !isEmpty(this.column.filterText);
	}

	isText(): boolean {
		return this.column.userQueryType === UserQueryType.TEXT;
	}

	isSelect(): boolean {
		return this.column.userQueryType === UserQueryType.SELECT;
	}

	isDate(): boolean {
		return this.column.userQueryType === UserQueryType.DATE;
	}

	onClear(): void {
		this.clear();
		this.onApply();
	}

	onApply(): void {
		if (this.isDate()) {
			if (this.userQueryDateRange.from && this.userQueryDateRange.to) {
				this.column.filterText = this.dateUtils.formatDateRangeQuery(this.userQueryDateRange);
				this.column.filterDisplayText = this.dateUtils.formatDateRange(this.userQueryDateRange, true);
			} else {
				this.column.filterText = '';
				this.column.filterDisplayText = '';
			}
		} else {
			this.column.filterText = this.filterText;
		}
		this.dropdown?.close();
		this.onChange.emit();
	}

	onToggle(event): void {
		event.stopPropagation();
		event.preventDefault();
		if (this.isDropdownOpened() && isEmpty(this.column.filterText)) {
			this.clear();
		}
	}

	isDropdownOpened(): boolean {
		return this.dropdown?.isOpen();
	}

	suggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(term => {
				if (this.column.filterField === 'customField') {
					return this.userSearchAPI.getCustomFieldsSuggestion(term);
				} else {
					return Promise.resolve([]);
				}
			})
		);
	};

	private clear(): void {
		this.filterText = '';
		this.userQueryDateRange = {} as IDateRange;
	}

}

app.directive('userColumn', downgradeComponent({ component: UserColumnComponent }));
