import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';
import { AppPage } from '@app/util/app-page';
import { Security } from '@cxstudio/auth/security-service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { Interval } from '@cxstudio/interval/interval.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { ExternalAuthService } from '@cxstudio/services/external-auth.service';
import { IHttpPromiseCallbackArg } from 'angular';
/**
 * Modify rest url, add custom headers
 */
app.factory('AuthErrorHandler', ($window, $location, $q: ng.IQService, $log, $injector,
	security: Security, authenticationService: AuthenticationService, interval: Interval,
	environmentService: EnvironmentService, externalAuthService: ExternalAuthService) => {

	return class AuthErrorHandler {
		renewTokenPromise;
		supportedStatuses = [401, 403];

		handleError = (response: IHttpPromiseCallbackArg<any>): ng.IPromise<any> | void => {
			let errorHandlingResult;
			if (response.status === 401) {
				errorHandlingResult = this.handleUnauthorized(response);
			} else if (response.status === 403) {
				errorHandlingResult = this.handleForbidden(response);
			}

			if (errorHandlingResult) {
				return errorHandlingResult;
			}

			$log.error('Unsupported auth error:', response.status);
			return $q.reject(response);
		};

		support = (status): boolean => _.contains(this.supportedStatuses, status);

		private renewTokenOrExpireSession(response: IHttpPromiseCallbackArg<any>): ng.IPromise<any> {
			let defer = $q.defer();

			if (!this.renewTokenPromise) {
				this.renewTokenPromise = authenticationService.getAccessToken();
			}

			this.renewTokenPromise.then((renewResponse) => {
				this.renewTokenPromise = null;
				security.setAccessToken(renewResponse.token);

				let $http: ng.IHttpService = $injector.get('$http');
				$http(response.config).then((newResponse) => {
					interval.startAllIntervals();
					defer.resolve(newResponse);
				}, () => {
					this.handleOAuthSessionExpiration(defer, response);
				});
			}, () => {
				this.renewTokenPromise = null;
				this.handleOAuthSessionExpiration(defer, response);
			});

			return defer.promise;
		}

		/*
		** returns : Promise
		*/
		private showSessionDialogAndDelayRequest(response: IHttpPromiseCallbackArg<any>): ng.IPromise<void> {
			$log.debug('Invalid token');

			let defer = $q.defer<void>();

			let routeService = $injector.get('routeService');
			if (security.getEngageDashboardAccessToken() && environmentService.isIframe()) {
				routeService.goToRefreshRequiredPage();
				$log.debug('Session Refresh required for Embedded dashboard in Engage');
				return defer.promise;
			}

			//singleton dialog
			let sessionDialogService = $injector.get('sessionDialogService');
			let dlg = sessionDialogService.showSessionExpiredDialog();

			// try to resend request
			if (response.config.url) {
				dlg.result.then(() => {
					if (environmentService.isApplicationOutdated()) {
						return environmentService.disregardChangesAndRefresh();
					}

					let $http = $injector.get('$http');
					$http(response.config).then((newResponse) => {
						interval.startAllIntervals();
						defer.resolve(newResponse);
					}, () => {
						defer.reject(response);
					});
				}, () => {
					defer.reject(response);
				});
			} else {
				defer.reject(response);
			}
			return defer.promise;
		}

		//handling error 401
		private handleUnauthorized(response: IHttpPromiseCallbackArg<any>): ng.IPromise<any> | void {
			interval.stopAllIntervals();

			if ($location.path() === AppPage.LOGIN) {
				security.cleanSession();
				return $q.reject(response);
			}

			if (security.loggedUser) {
				if (environmentService.isApplicationOutdated()) {
					return environmentService.disregardChangesAndRequireNewLogin();
				}

				return security.isOAuthAuthentication()
					? this.renewTokenOrExpireSession(response)
					: this.showSessionDialogAndDelayRequest(response);
			}

			if (!externalAuthService.tryExpireExternalSession() && !security.isRedirectionInProgress()) {
				security.cleanSession();
				$window.location.reload();
			}
		}

		//handling error 403
		private handleForbidden(response: IHttpPromiseCallbackArg<any>): ng.IPromise<any> {
			//stop propagation
			(response.config as any).processed = true;
			$log.debug('no permissions');
			let errorDialogService: ErrorDialogService = $injector.get('errorDialogService');
			let locale = $injector.get('locale');
			let key = 'administration.noPerms';
			if (response.headers && response.headers().error === 'ipAccessBlocked') {
				key = 'error.ipAccessBlocked';
			}
			errorDialogService.error(locale.getString(key));
			return $q.reject(response);
		}

		private handleOAuthSessionExpiration(defer, response: IHttpPromiseCallbackArg<any>): void {
			if (!externalAuthService.tryExpireExternalSession()) {
				this.showSessionDialogAndDelayRequest(response).then(defer.resolve, defer.reject);
			}
		}
	};
});
