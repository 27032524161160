import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import PopoverUtils from '@cxstudio/services/popover-utils.service';

export enum FiltersToggle {
	ICON = 'icon',
	BUTTON = 'button'
}

@Component({
	selector: 'dashboard-filters-panel',
	templateUrl: './dashboard-filters-panel.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardFiltersPanelComponent implements OnInit {
	@Input() toggle: FiltersToggle;
	@Input() dashboard: Dashboard;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() personalization: PersonalizationState;
	@Input() projectTimezone: string;
	@Input() isPdfExport: boolean;
	@Output() stateChange = new EventEmitter<{active: boolean}>();
	@Input() embedded: boolean;
	@Input() truncate: boolean;
	@Input() isEmbeddedInternally = false;

	active: boolean;

	constructor(
		private locale: CxLocaleService,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('dateFilterService') private dateFilterService: DateFilterService,
		@Inject('dashboardFilterLabels') private dashboardFilterLabels: DashboardFilterLabelsService,
		@Inject('popoverUtils') private popoverUtils: PopoverUtils
	) { }

	ngOnInit(): void {
		this.active = false;
	}

	getFilterLabel(df: DashboardFilter): string {
		return this.dashboardFilterLabels.getDashboardHeaderFilterLabel(df);
	}

	getHierarchyLabel = () => {
		if (this.hasHierarchy()) {
			let personalization = this.personalization;
			return `${personalization.currentHierarchyName}: ${personalization.currentHierarchyNode.name}`;
		}
		return '';
	};

	getAriaLabel = (): string => {
		return this.locale.getString('filter.viewDashboardFilters', { name: this.dashboard.name });
	};

	getAppliedDashboardFilters = (): DashboardFilter[] => {
		return this.dashboardHistory.getAppliedFilters();
	};

	getAppliedFiltersWithValue = (): DashboardFilter[] => {
		return this.getAppliedDashboardFilters().filter(filter => this.dashboardFilterLabels.hasValue(filter));
	};

	getAttrFilterTooltip = (dashboardFilter: DashboardFilter): string => {
		if (this.isDateRange(dashboardFilter.selectedAttribute)
				&& dashboardFilter.selectedAttributeValue.dateFilterMode === DateFilterMode.CUSTOM) {

			return this.dateFilterService
				.formatDateFilter(dashboardFilter.selectedAttributeValue.dateFilterRange, this.projectTimezone);
		}

		return this.dashboardFilterLabels.getFilterTooltip(dashboardFilter);
	};

	hasVisibleAppliedFilters = (): boolean => {
		if (!this.dashboard) return false;
		return this.toggle
			? this.dashboardFiltersService.canChangeFilters(this.getAppliedDashboardFilters(), this.isHierarchyNodeSelectable())
			: this.dashboardFiltersService.canViewFilters(this.getAppliedDashboardFilters(), this.isHierarchyNodeSelectable());
	};

	hasHierarchy = (): boolean => {
		return Boolean(this.personalization?.isHierarchyEnabled() && this.personalization.currentHierarchyNode?.name);
	};

	showFiltersBar = (): boolean => {
		if (this.isPdfExport) {
			return false;
		}
		if (this.hasHierarchy())
			return true;
		let appliedFilters = this.getAppliedDashboardFilters();
		return appliedFilters && this.hasVisibleAppliedFilters()
				&& this.dashboardFiltersService.isValidFilter(appliedFilters[0]);

	};

	isHierarchyNodeSelectable = (): boolean => {
		return this.personalization && this.personalization.isHierarchySelectable
			&& this.personalization.isHierarchySelectable();
	};

	getFilterType = (filter: DashboardFilterSelection) => {
		return this.dashboardFiltersService.getFilterType(filter);
	};

	isDateRange = (attr): boolean => {
		let type = this.getFilterType(attr);
		return type === DashboardFilterTypes.DATE_RANGE;
	};

	isButtonToggle = (): boolean => {
		return this.toggle === FiltersToggle.BUTTON;
	};

	panelClickHandler = (): void => {
		if (!this.isButtonToggle()) {
			this.clickHandler();
		}
	};

	clickHandler = (): void => {
		this.active = !this.active;
		if (this.active) {
			//.popover-content became .popover-body in NG
			this.popoverUtils.initOutsideClickHandler('.dashboard-filters-pop', this.getClickHandlerName, this.outsideClickHandler);
		}
		this.stateChange.emit({active: this.active});
	};

	outsideClickHandler = () => {
		this.active = false;
		this.stateChange.emit({active: this.active});
	};

	getClickHandlerName = () => {
		return 'click.dashboardFiltersPopup';
	};

	onFilterPanelKbAction = (event: any): void => {
		if (!this.isButtonToggle()) {
			this.onToggleKbAction(event);
		}
	};

	onToggleKbAction = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.clickHandler();
			setTimeout(() => $('#dashboard-filters-edit :focusable').first().trigger('focus'), 100);
		}
	};
}

app.directive('dashboardFiltersPanel', downgradeComponent({ component: DashboardFiltersPanelComponent }));

