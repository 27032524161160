import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import * as _ from 'underscore';
import { ContextMenuBaseController } from './context-menu-base-controller';
import {LayoutHelper} from '@cxstudio/dashboards/layout-helper.service';

export class DrillContextMenuController extends ContextMenuBaseController {

	constructor(
		contextMenuTree: ContextMenuTree,
		$element: ng.IAugmentedJQuery,
		$timeout: ng.ITimeoutService,
		private keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper,
		private layoutHelperService: LayoutHelper
	) {
		super(contextMenuTree, $element, $timeout);
	}

	onDrillMenuKeyboard = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.UP)) {
			KeyboardUtils.intercept(event);
			let drillMainMenuItems = this.keyboardNavigationDrillHelper.getDrillMainMenuItems();
			this.keyboardNavigationDrillHelper.focusPreviousItem(event.target, drillMainMenuItems);
		} else if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			KeyboardUtils.intercept(event);
			let drillMainMenuItems = this.keyboardNavigationDrillHelper.getDrillMainMenuItems();
			this.keyboardNavigationDrillHelper.focusNextItem(event.target, drillMainMenuItems);
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)
			|| KeyboardUtils.isEventKey(event, Key.TAB)
			|| KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.intercept(event);
			this.keyboardNavigationDrillHelper.focusActiveDataPoint();
			this.close();
		}
	};

	private close(): void {
		this.keyboardNavigationDrillHelper.clearCloudFocusState();
		if (this.onMenuClose) {
			this.onMenuClose();
		}
	}
}

app.component('drillContextMenu', {
	controller: DrillContextMenuController,
	bindings: {
		options: '<',
		object: '<',
		posX: '<',
		posY: '<',
		onMenuClose: '&'
	},
	templateUrl: 'partials/context-menu/drill-context-menu.component.html'
});
