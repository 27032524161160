import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import Hierarchy from './Hierarchy';

interface IOrganizationNameParams {
	headerText: string;
	bodyText: string;
	hierarchy?: Hierarchy;
}

export class OrganizationNameDialogController extends ModalBindings<IOrganizationNameParams> {
	hierarchyName: string;
	headerText: string;
	bodyText: string;

	organizationNameExists: boolean;
	organizationNameForbiddenCharacters: boolean;
	editMode: boolean;

	loading: ng.IPromise<any> | null;

	hierarchyForm: ng.IFormController;

	HIERARCHY_PARAMETER_DELIMITER: string = '>>';

	constructor(
		private organizationApiService: OrganizationApiService,
	) {
		super();
	}

	$onInit(): void {
		this.headerText = this.resolve.headerText;
		this.bodyText = this.resolve.bodyText;
		this.hierarchyName = '';
		this.organizationNameExists = false;

		if (!_.isUndefined(this.resolve.hierarchy)) {
			this.hierarchyName = this.resolve.hierarchy.name;
			this.editMode = true;
		}
	}

	save(): void {
		if (!this.hasErrors()) {
			this.close({$value: this.hierarchyName});
		}
	}

	hasErrors = (): boolean => this.organizationNameExists || this.hierarchyFormHasErrors();

	private hierarchyFormHasErrors = (): boolean => {
		return !this.hierarchyName || (this.hierarchyName && this.hierarchyForm.$invalid);
	};

	hasChanges = (): boolean => {
		return this.hierarchyName !== ''
			&& (!this.editMode || this.hierarchyName !== this.resolve.hierarchy.name);
	};

	cancel(): void {
		this.dismiss({$value: 'cancel'});
	}

	validateOrganizationName = () => {
		if (this.hasChanges()) {
			if (this.hierarchyName) {
				this.hierarchyForm.organizationName.$setValidity('forbiddenCharacters',
					!this.hierarchyName.contains(this.HIERARCHY_PARAMETER_DELIMITER), undefined);
				this.hierarchyForm.organizationName.$setValidity('invalidHierarchyName', true, undefined);
				this.loading = this.organizationApiService.organizationExists({ name: this.hierarchyName } as Hierarchy).then((response) => {
					this.organizationNameExists = response.data.exist;
					this.loading = null;
				});
			} else {
				this.hierarchyForm.organizationName.$setValidity('invalidHierarchyName', false, undefined);
				this.organizationNameExists = false;
				this.loading = null;
			}
		} else {
			this.organizationNameExists = false;
			this.hierarchyForm.organizationName.$setValidity('forbiddenCharacters', true, undefined);
			this.hierarchyForm.organizationName.$setValidity('invalidHierarchyName', true, undefined);
		}
	};
}

app.component('organizationNameDialog', {
	controller: OrganizationNameDialogController,
	templateUrl: 'partials/organizations/org-hierarchy-add-rename-dialog.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
