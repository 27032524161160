import * as _ from 'underscore';

import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { AnalyticMetricType, AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { WidgetMetricConfigurationOptions } from '../constants/widget-metric-configuration-options.constant';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportCalculation } from '../calculations/report-calculation';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MetricColorDirection } from '@cxstudio/metrics/entities/metric-color-direction.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';

// NOTE: this is deprecated and rewritten in sidebar-editor, don't refactor this
export class MetricConfigurationRules {

	constructor(
		private configItem: AttributeGrouping | ReportCalculation, // original item
		private props: WidgetProperties,
		private visualProps: VisualProperties,
		private configurationOptions: WidgetMetricConfigurationOptions[],
		private scopeItem: AttributeGrouping | ReportCalculation, // copy of original item, which is used on UI and can be modified
		private itemType: KeyMetricListTypes,
	) {
		this.populateDefaults();
	}

	private isGrouping(item?: AttributeGrouping | ReportCalculation): item is AttributeGrouping {
		return this.itemType === KeyMetricListTypes.GROUPING;
	}

	private isCalculation(item?: AttributeGrouping | ReportCalculation): item is ReportCalculation {
		return this.itemType === KeyMetricListTypes.CALCULATION || this.isCalculationSeries();
	}

	private populateDefaults(): void {
		if (this.isGrouping(this.scopeItem)
				&& this.isPeerHierarchyGrouping()
				&& !this.scopeItem.peerReportType) {
			this.scopeItem.peerReportType = PeerReportType.SIMPLE_PEER_REPORT;
		}
	}

	isTopicOrLeaf = (): boolean => {
		return this.configItem.type === 'topics' || this.configItem.type === 'topicLeaf';
	};

	isHierarchyModel = (): boolean => {
		return this.configItem.type === ReportAssetType.HIERARCHY_MODEL;
	};

	wordsSelectionAllowed = (): boolean => {
		if (this.configItem.metricType === AnalyticMetricType.ATTRIBUTE) {
			return (this.configItem.type === ReportAssetType.TEXT) ||
				(this.configItem.type === ReportAssetType.NUMBER && this.isCustomSortOrder());
		} else {
			return AnalyticMetricTypes.isWordsOrAssociatedWordsGrouping(this.configItem) || this.isPredefinedMetric();
		}
	};

	showLimitTo = (): boolean => {
		return this.wordsSelectionAllowed() && !this.configItem.standardMetric;
	};

	isTextAttribute = (): boolean => {
		return this.configItem.metricType === AnalyticMetricType.ATTRIBUTE && this.configItem.type === ReportAssetType.TEXT;
	};

	isWordsOrAssociatedWordsGrouping = (): boolean => {
		return AnalyticMetricTypes.isWordsOrAssociatedWordsGrouping(this.configItem);
	};

	isUsingTimeGrouping = (): boolean => {
		return AnalyticMetricTypes.isTime(this.configItem);
	};

	isDriversGrouping = (): boolean => {
		return AnalyticMetricTypes.isDrivers(this.configItem);
	};

	isMultiLevelTopic = (): boolean => {
		return !!(this.configItem as TopicReportGrouping).selectedLevels;
	};

	isNumericBreakdownGrouping = (): boolean => {
		return AnalyticMetricTypes.isPredefinedMetric(this.configItem)
			&& this.configItem.definition.type === MetricType.NUMERIC_BREAKDOWN;
	};

	isStackedGroupingItem = (): boolean => {
		return _.contains(this.configurationOptions, WidgetMetricConfigurationOptions.STACKED_GROUPING);
	};

	isSeriesGroupingItem = (): boolean => {
		return _.contains(this.configurationOptions, WidgetMetricConfigurationOptions.SERIES_GROUPING);
	};

	isAttributeMetric = (): boolean => {
		return AnalyticMetricTypes.isAttribute(this.configItem);
	};

	isSatScore = (): boolean => {
		return this.configItem.type && this.configItem.type === ReportAssetType.SATSCORE;
	};

	isDeltaMetric = (): boolean => {
		return this.isCalculation(this.configItem)
			&& this.configItem.isPopMetric && this.configItem.name.startsWith(PopDiffPrefix.DELTA);
	};

	isHistoricMetric = (): boolean => {
		return this.isCalculation(this.configItem)
			&& this.configItem.isPopMetric && this.configItem.name.startsWith(PopDiffPrefix.HISTORIC);
	};

	isPercentChangeMetric = (): boolean => {
		return this.isCalculation(this.configItem)
			&& this.configItem.isPopMetric && this.configItem.name.startsWith(PopDiffPrefix.PERCENT_CHANGE);
	};

	showCalculationType = (): boolean => {
		return this.isCalculation(this.configItem) && this.isAttributeMetric() && !this.isSatScore()
			&& (!this.configItem.isPopMetric || this.isMetricWidgetPoP());
	};

	showColorDirection = (): boolean => {
		return this.isDeltaMetric() || this.isPercentChangeMetric()
			|| this.showColorDirectionForMetricWidget();
	};

	private showColorDirectionForMetricWidget = (): boolean => {
		if (!this.isCalculation(this.configItem)) return false;
		if (!this.isMetricWidgetPoP()) return false;
		if (this.isMultiMetric()) return true;
		let popField = this.configItem.popField;
		return popField !== PeriodOverPeriodMetricType.P_VALUE && popField !== PeriodOverPeriodMetricType.SIGNIFICANCE;
	};


	getColorDirectionRadioOptions = (): UIOption<MetricColorDirection>[] => {
		const options: UIOption<MetricColorDirection>[] = [];
		if (!this.showColorDirection()) return options;
		if (this.isEaseScore()) return [
			{value: MetricColorDirection.DIRECT, displayName: 'effort', htmlId: 'effort'},
			{value: MetricColorDirection.NONE, displayName: 'noColor', htmlId: 'noColor'}
		];
		return [
			{value: MetricColorDirection.DIRECT, displayName: 'direct', htmlId: 'direct'},
			{value: MetricColorDirection.REVERSED, displayName: 'reversed', htmlId: 'reversed'},
			{value: MetricColorDirection.NONE, displayName: 'noColor', htmlId: 'noColor'}
		];
	};

	private isEaseScore(): boolean {
		return this.isCalculation(this.configItem)
			&& (this.configItem.name === PredefinedMetricConstants.EASE_SCORE
				|| this.configItem.parentMetricName === PredefinedMetricConstants.EASE_SCORE);
	}

	isMetricWidgetPoP = (): boolean => {
		return !!(this.props
			&& this.props.widgetType === WidgetType.METRIC
			&& this.isComparison()
			&& (this.isChangeGraphEnabled() || this.isCompareToHistoricPeriodEnabled()));
	};

	private isChangeGraphEnabled(): boolean {
		return _.contains(this.configurationOptions, WidgetMetricConfigurationOptions.TREND_ARROW) || this.isGaugeWithComparison();
	}

	private isCompareToHistoricPeriodEnabled(): boolean {
		return _.contains(this.configurationOptions, WidgetMetricConfigurationOptions.PREVIOUS_PERIOD);
	}

	private isGaugeWithComparison(): boolean {
		return !!(this.visualProps.visualization === WidgetVisualization.GAUGE &&
			(this.props as MetricWidgetProperties).comparisons?.length);
	}

	showArrowToggle = (): boolean => {
		return this.props && this.isComparison() && this.isMultiMetric() && this.isChangeGraphEnabled();
	};

	private isComparison(): boolean {
		if (!this.props) {
			return false;
		}

		if (this.props.widgetType === WidgetType.METRIC) {
			let metricProps = this.props as MetricWidgetProperties;
			return metricProps.useHistoricPeriod || !_.isEmpty(metricProps.comparisons);
		} else {
			return this.props.useHistoricPeriod;
		}
	}

	isMultiMetric = (): boolean => {
		return this.props && this.props.widgetType === WidgetType.METRIC && this.props.selectedMetrics.length > 1;
	};

	isSingleMetric = (): boolean => {
		return this.props && this.props.widgetType === WidgetType.METRIC && this.props.selectedMetrics.length === 1;
	};

	isNumberSelected = (): boolean => {
		return this.isCalculation(this.scopeItem) && this.scopeItem.dataType.startsWith('number');
	};

	isPercentSelected = (): boolean => {
		return this.scopeItem.dataType.startsWith('percent');
	};

	isNumeric = (): boolean => {
		return this.scopeItem.type === ReportAssetType.NUMBER;
	};

	isHashtag = (): boolean => {
		return this.scopeItem.name === '_hashtag';
	};

	isCalculationSeries = (): boolean => {
		return this.itemType === KeyMetricListTypes.CALCULATION_SERIES;
	};

	isPeerHierarchyGrouping = (): boolean => {
		return _.contains(this.configurationOptions, WidgetMetricConfigurationOptions.PEER_HIERARCHY_GROUPING);
	};

	isInheritedHierarchyGrouping = (): boolean => {
		return this.isGrouping(this.scopeItem)
			&& this.scopeItem.peerReportType === PeerReportType.INHERITED_REPORT;
	};

	showInclusionList = (): boolean => {
		return this.isGrouping(this.scopeItem)
			&& this.isHierarchyModel() && this.isPeerHierarchyGrouping()
			&& this.scopeItem.peerReportType === PeerReportType.PARENT_PEER_REPORT;
	};

	private isPredefinedMetric(): boolean {
		return AnalyticMetricTypes.isPredefinedGroup(this.configItem);
	}

	hideSortSettings = (): boolean => {
		return this.isPredefinedMetric() || this.isUsingTimeGrouping() || this.isDriversGrouping() || this.isMultiLevelTopic();
	};

	showSortSettingsForGrouping = (): boolean => {
		return this.isGrouping() && !this.hideSortSettings();
	};

	showSubtopics = (): boolean => {
		return this.isGrouping(this.configItem) && this.isTopicOrLeaf() && this.configItem.showLevel && !this.isMultiLevelTopic();
	};

	showUrlType = (): boolean => {
		return this.isGrouping(this.configItem)
			&& (this.props.widgetType === WidgetType.TABLE
				|| this.props.widgetType === WidgetType.SCORECARD)
			&& this.configItem.metricType === AnalyticMetricType.ATTRIBUTE
			&& ((this.configItem.type === ReportAssetType.TEXT)
			|| (this.configItem.type === ReportAssetType.DIMENSIONAL));
	};

	showCapitalization = (): boolean => {
		return this.isGrouping()
			&& !this.isNumeric()
			&& !this.isHashtag()
			&& !this.isUrl()
			&& !InternalProjectTypes.isAdminProject(this.props.project);
	};

	private isUrl = (): boolean => {
		return this.isGrouping(this.scopeItem) && this.scopeItem.urlType
			&& (this.props.widgetType === WidgetType.TABLE
				|| this.props.widgetType === WidgetType.SCORECARD);
	};

	showThresholds = (): boolean => {
		return this.isGrouping() && !this.isPredefinedMetric()
			&& !this.isUsingTimeGrouping() && this.props.selectedMetrics && this.props.selectedMetrics.length >= 1
			&& !this.isCustomSortOrder()
			&& !this.isMultiLevelTopic();
	};

	isCustomSortOrder = (): boolean => {
		return this.isGrouping(this.scopeItem) && this.scopeItem.sortOrder === 'custom';
	};

	isCustomSortOrderAllowed = (): boolean => {
		return this.isTopicOrLeaf() || this.wordsSelectionAllowed() || this.isNumeric();
	};
}
