import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { LabelsRendererComponent } from '@app/modules/object-list/cell-renderers/labels-renderer/labels-renderer.component';
import { SharingStatusRendererComponent } from '@app/modules/object-list/cell-renderers/sharing-status-renderer/sharing-status-renderer.component';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { ColumnType } from '@app/modules/object-list/types/column-type';
import { ExtendedColDef } from '@app/modules/object-list/types/extended-column-definition';
import { SortUtils } from '@app/shared/util/sort-utils';
import { TypeGuards } from '@app/util/typeguards.class';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { Label } from '@cxstudio/dashboards/entity/label';
import { DateService } from '@cxstudio/services/date-service.service';
import { ColDef, IRichCellEditorParams, ITextFilterParams, ValueFormatterParams, ValueGetterParams } from 'ag-grid-enterprise';
import * as moment from 'moment';
import { ContextMenuCellParams, ContextMenuRendererComponent } from './cell-renderers/context-menu-renderer/context-menu-renderer.component';
import { ProgressBarRendererComponent } from './cell-renderers/progress-bar-renderer/progress-bar-renderer.component';
import { ToggleCellParams, ToggleRendererComponent } from '@app/modules/object-list/cell-renderers/toggle-renderer/toggle-renderer.component';

export enum ColumnField {
	NAME = 'name',
	LABELS = 'labels',
	CONTEXT_MENU = 'contextMenu',
	MODIFIED_DATE = 'modifiedDate',
	OWNER = 'ownerName',
	SHARING_STATUS = 'sharingStatus',
}

export const IconColumnHeaderBase = {
		minWidth: 48,
		maxWidth: 64,
		resizable: false,
		cellClass: 'pv-0 ph-8',
		headerClass: 'pv-0 ph-8',
		sortable: true,
};

@Injectable({
	providedIn: 'root'
})
export class ObjectListColumnsService {
	static readonly ICON_COLUMN_MINWIDTH = 60;
	static readonly ICON_COLUMN_WIDTH = 100;

	static readonly CONTEXT_MENU_COLUMN = ColumnField.CONTEXT_MENU;
	static readonly PRIMARY_COLUMN = 'ag-Grid-AutoColumn';

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('dateService') private readonly dateService: DateService,
		@Inject('security') private readonly security: Security,
	) { }

	textColumn(field: string, headerName: string, searchable: boolean = true): ColDef {
		return {
			type: ColumnType.TEXT,
			headerName,
			field,
			filter: 'agTextColumnFilter',
			filterParams: {
				buttons: ['reset'],
			},
			minWidth: 100,
			width: 300,
			cellClass: `cell-${field}`,
			getQuickFilterText: (params) => searchable ? params.value : '',
		};
	}

	categoryColumn(field: string, headerName: string): ColDef {
		return {
			type: ColumnType.TEXT,
			headerName,
			field,
			filter: 'agSetColumnFilter',
			filterParams: {
				applyMiniFilterWhileTyping: true,
				buttons: ['reset'],
			},
			minWidth: 100,
			width: 300,
			cellClass: `cell-${field}`,
		};
	}

	yesNoBooleanColumn(field: string, headerName: string): ColDef {
		return {
			type: ColumnType.TEXT,
			headerName,
			field,
			filter: 'agSetColumnFilter',
			filterParams: {
				buttons: ['reset'],
			},
			minWidth: 60,
			width: 100,
			cellClass: `cell-${field}`,
			valueFormatter: (params: ValueFormatterParams) => {
				if (ObjectListUtils.isFolder(params.data) || params.data === undefined) return '';

				return params.value ? this.locale.getString('common.yes') : this.locale.getString('common.no');
			},
		};
	}

	numberColumn(field: string, headerName: string): ColDef {
		return {
			type: ColumnType.NUMBER,
			headerName,
			field,
			filter: 'agNumberColumnFilter',
			filterParams: {
				filterOptions: [
					'equals',
					'notEqual',
					'lessThanOrEqual',
					'greaterThanOrEqual',
					'inRange'
				],
				defaultOption: 'equals',
				buttons: ['reset'],
			},
			minWidth: 60,
			width: 100,
			cellClass: `cell-${field}`,
		};
	}

	percentColumn(field: string, headerName: string): ColDef {
		return _.extend(this.numberColumn(field, headerName), {
			valueFormatter: (params: ValueFormatterParams) => {
				if (ObjectListUtils.isFolder(params.data)) return '';
				if (params.value === undefined) return ' - ';
				return (params.value * 100).toFixed(2) + '%';
			},
		});
	}

	labelsColumn(): ColDef {
		const headerName = this.locale.getString('common.labels');
		return {
			type: [ColumnType.TEXT, ColumnType.MULTIVALUE],
			headerName,
			field: ColumnField.LABELS,
			minWidth: 100,
			width: 120,
			wrapText: true,
			autoHeight: true,
			initialHide: true,
			cellRenderer: LabelsRendererComponent,
			valueGetter: (params: ValueGetterParams) => {
				let labels: Label[] = params.data.labels || [];
				return labels
					.map(label => label.text)
					.sort(SortUtils.getAlphanumericComparator(val => val, SortDirection.ASC));
			},
			comparator: (valueA, valueB, nodeA, nodeB, isDescending: boolean) => {
				return SortUtils.compareAlphanumerically(valueA?.join?.(), valueB?.join?.());
			},
			getQuickFilterText: (params) => params.value.join(),
			filter: 'agMultiColumnFilter',
			filterParams: {
				filters: [
					{
						filter: 'agTextColumnFilter',
						filterParams: {
							defaultOption: 'equals',
							filterOptions: [
								{
									displayKey: 'equals',
									displayName: 'Equals',
									predicate: (filterValue, cellValue) => {
										return cellValue.contains(filterValue);
									}
								},
								{
									displayKey: 'notEqual',
									displayName: 'Not equal',
									predicate: (filterValue, cellValue) => {
										return !cellValue.contains(filterValue);
									}
								},
								{
									displayKey: 'hasAnyValue',
									displayName: 'Has any value',
									numberOfInputs: 0,
									predicate: (filterValue, cellValue) => {
										return cellValue.length !== 0;
									}
								},
								{
									displayKey: 'hasNoValue',
									displayName: 'Has no value',
									numberOfInputs: 0,
									predicate: (filterValue, cellValue) => {
										return cellValue.length === 0;
									}
								}
							],
							buttons: ['reset'],
						} as ITextFilterParams,
					},
					{
						filter: 'agSetColumnFilter',
						filterParams: {
							buttons: ['reset'],
						},
					},
				],
			}
		};
	}

	contextMenuColumn(params?: Partial<ContextMenuCellParams>): ColDef {
		return {
			headerName: this.locale.getString('common.menu'),
			field: ObjectListColumnsService.CONTEXT_MENU_COLUMN,
			headerComponentParams: {template: '<i class="q-icon q-icon-layer"></i>'},
			headerTooltip: this.locale.getString('common.menu'),
			lockVisible: true,
			minWidth: 34,
			maxWidth: 34,
			resizable: false,
			cellClass: 'pv-0 ph-8',
			headerClass: 'pv-0 ph-8',
			cellRenderer: ContextMenuRendererComponent,
			cellRendererParams: params
		};
	}

	dateColumn(field: string, headerName: string, format?: string): ColDef {
		return {
			type: ColumnType.DATE,
			headerName,
			field,
			minWidth: 80,
			width: 260,
			filter: 'agDateColumnFilter',
			filterParams: {
				buttons: ['reset'],
			},
			cellClass: 'cell-date',
			valueFormatter: (params: ValueFormatterParams) => {
				if (!params.value)
					return;
				let date;
				if (format) {
					date = moment(params.value, format).toDate();
				} else {
					date = new Date(params.value);
				}
				return this.dateService.format(date);
			},
		};
	}

	ownerColumn(field: string, headerName: string): ColDef {
		return {
			type: ColumnType.TEXT,
			headerName,
			field,
			minWidth: 80,
			width: 260,
			cellClass: 'cell-owner-name',
			filter: 'agSetColumnFilter',
			filterParams: {
				applyMiniFilterWhileTyping: true,
				buttons: ['reset'],
			},
			valueFormatter: (params: ValueFormatterParams) => {
				if (ObjectListUtils.isFolder(params.data)) return '';
				if (ObjectListUtils.isGeneratedAsset(params.data)) {
					return this.locale.getString('common.clarabridgeType');
				}

				if (!TypeGuards.isString(params.value)) return '';

				if (this.security.isCurrentUser(params.value)) {
					return this.locale.getString('dashboard.me');
				}

				return params.value;
			},
		};
	}

	sharingStatusRendererColumn(): ColDef {
		const headerName = this.locale.getString('dashboard.status');
		return {
			type: ColumnType.TEXT,
			field: ColumnField.SHARING_STATUS,
			headerName,
			minWidth: ObjectListColumnsService.ICON_COLUMN_MINWIDTH,
			width: ObjectListColumnsService.ICON_COLUMN_WIDTH,
			headerClass: 'text-center',
			cellRenderer: SharingStatusRendererComponent,
			filter: 'agSetColumnFilter',
			filterParams: {
				suppressMiniFilter: true,
				suppressRemoveEntries: true, // hide the (blanks) option
				values: [
					SharingStatus.PRIVATE,
					SharingStatus.PUBLIC,
					SharingStatus.SHARED
				],
				valueFormatter: (params: ValueFormatterParams) => {
					if (params.value === SharingStatus.PRIVATE) {
						return this.locale.getString('dashboard.private');
					} else if (params.value === SharingStatus.PUBLIC) {
						return this.locale.getString('dashboard.public');
					}
					return this.locale.getString('dashboard.shared');
				},
				buttons: ['reset'],
			},
			getQuickFilterText: params => {
				switch (params.value) {
					case SharingStatus.PUBLIC: return this.locale.getString('dashboard.public');
					case SharingStatus.SHARED: return this.locale.getString('dashboard.shared');
					case SharingStatus.PRIVATE: return this.locale.getString('dashboard.private');
					default: return '';
				}
			}
		};
	}

	simpleDropdownColumn<T, V>(field: string, headerName: string,
			getOptions: (data: T) => UIOption<V>[], editable: (data: T) => boolean): ColDef {
		return this.dropdownColumn(field, headerName, getOptions,
			(data) => data[field],
			(data, value) => data[field] = value,
			editable);
	}

	dropdownColumn<T, V>(field: string, headerName: string, getOptions: (data: T) => UIOption<V>[],
		valueGetter: (data: T) => V, valueSetter: (data: T, value: V) => void, editable: (data: T) => boolean): ColDef {

		const cellEditorParams = (params: IRichCellEditorParams) => {
			const data = (params as any).data;
			const options = getOptions(data);
			return {
				values: options,
				formatValue: (option: UIOption<V> | string) => _.isObject(option) ? (option as UIOption<V>).displayName : option,
			};
		};
		return {
			type: ColumnType.TEXT,
			headerName,
			field,
			minWidth: 100,
			width: 300,
			cellClass: `cell-${field}`,
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams,
			editable: (params) => editable(params.data),
			valueSetter: (params) => {
				const option = params.newValue as UIOption<V>;
				valueSetter(params.data, option.value);
				return true;
			},
			valueGetter: (params): string => {
				const options = getOptions(params.data);
				const value = valueGetter(params.data);
				return options.find(option => option.value === value)?.displayName;
			},
			...this.getDirtyEditorProperties(),
		};
	}

	checkboxColumn(field: string, headerName: string, editable: (data) => boolean = () => false): ColDef {
		return {
			headerName,
			field,
			minWidth: 50,
			width: 50,
			cellClass: `cell-${field}`,
			// this requires ag grid v30, which requires newer typescript
			// cellRenderer: 'agCheckboxCellRenderer',
			// cellRendererParams: () => ({
			// 	disabled: editable()
			// }),
			//cellEditor: 'agCheckboxCellEditor',
			// using simple droplist instead
			cellEditor: 'agRichSelectCellEditor',
			cellEditorParams: () => ({
				values: [true, false],
			}),

			editable: (params) => editable(params.data),
			...this.getDirtyEditorProperties(),
		};
	}

	toggleColumn(field: string, headerName: string, action: (data, value) => void, isDisabled: (data) => boolean): ColDef {
		return {
			field,
			headerName,
			minWidth: 50,
			width: 100,
			cellClass: `cell-${field}`,
			cellRenderer: ToggleRendererComponent,
			cellRendererParams: {
				action,
				isDisabled,
			} as ToggleCellParams
		};
	}

	progressColumn(field: string, headerName: string): ColDef {
		return {
			headerName,
			field,
			width: 300,
			minWidth: 300,
			maxWidth: 300,
			cellClass: `cell-${field}`,
			cellRenderer: ProgressBarRendererComponent,
		};
	}

	/**
	 *
	 * @param iconClass CSS class needed to render icon
	 * @param customizations Any additional ColDef properties that need to be set or customized
	 * @returns ColDef
	 */
	getSortableIconColumnDefinition(iconClass: string, customizations: Partial<ExtendedColDef> = {}): ColDef {
		return {
			headerComponentParams: {
				template: `
				<div class="ag-cell-label-container" role="presentation">
					<span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
					<div ref="eLabel" class="ag-header-cell-label" role="presentation">
						<span class="q-icon ${iconClass}" role="columnheader"></span>
						<span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>
						<span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>
						<span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>
						<span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>
						<span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
					</div>
				</div>`
			},
			...IconColumnHeaderBase,
			...customizations
		};
	}

	private getDirtyEditorProperties(): Partial<ColDef> {
		return {
			cellClassRules: {
				'cell-dirty': (params) => ObjectListUtils.isDirty(params.data, params.colDef.field),
			},
			onCellValueChanged: (params) => {
				if (params.oldValue !== params.newValue) {
					ObjectListUtils.markDirty(params.data, params.column.getColDef().field);
					params.api.redrawRows();
				}
			}
		};
	}
}
