import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomePageManagementModule } from '@app/modules/home-page/home-page-management/home-page-management.module';
import { SharedModule } from '@app/shared/shared.module';
import { UnifiedAngularComponentsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { NgPipesModule } from 'ngx-pipes';
import { DashboardActionsModule } from '../dashboard-actions/dashboard-actions.module';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DialogModule } from '../dialog/dialog.module';
import { UnitsModule } from '../units/units.module';
import { AboutDialogComponent } from './about-dialog/about-dialog.component';
import { AccessibilityStatementDialogComponent } from './accessibility-statement-dialog/accessibility-statement-dialog.component';
import { CbCommandPaletteComponent } from './command-palette/cb-command-palette.component';
import { ImpersonateBarComponent } from './impersonate-bar/impersonate-bar.component';
import { CxNavigationService } from './services/cx-navigation.service';
import { SpaceDropdownComponent } from './space-dropdown/space-dropdown.component';
import { SuiteApplicationsComponent } from './suite-applications/suite-applications.component';
import { UserMenuComponent } from './user-menu/user-menu.component';
import { UserPreferencesPageComponent } from './user-preferences-page/user-preferences-page.component';
import { UserPreferencesDialogComponent } from './user-properties/user-preferences-dialog.component';
import { UserLinkedAccountsComponent } from './user-linked-accounts/user-linked-accounts.component';
import { IconModule } from '../icon/icon.module';

@NgModule({
	imports: [
		SharedModule,
		HomePageManagementModule,
		NgPipesModule,
		UnifiedAngularComponentsModule,
		DialogModule,
		CommonModule,
		DashboardModule,
		DashboardActionsModule,
		UnitsModule,
		IconModule
	],
	exports: [
		UserPreferencesDialogComponent,
		CbCommandPaletteComponent,
		ImpersonateBarComponent,
		UserMenuComponent,
		SpaceDropdownComponent,
		SuiteApplicationsComponent,
	],
	declarations: [
		UserPreferencesDialogComponent,
		CbCommandPaletteComponent,
		UserPreferencesPageComponent,
		ImpersonateBarComponent,
		UserMenuComponent,
		AccessibilityStatementDialogComponent,
		AboutDialogComponent,
		SpaceDropdownComponent,
		SuiteApplicationsComponent,
		UserLinkedAccountsComponent,
	],
	providers: [
		CxNavigationService
	],
})
export class HeaderModule { }
