import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ArchiveItem } from '@app/modules/archive/archive-page/archive-item';
import { ArchiveApi } from '@app/modules/archive/archive-page/archive-api.service';
import { Subject } from 'rxjs';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { RestoreConfig } from '@app/modules/archive/archive-page/restore-config';

@Injectable()
export class ArchiveListActions {
	private static readonly TRANSFER_NESTED_PARAM = 'transferNested';
	private changeSource = new Subject<void>();
	change$ = this.changeSource.asObservable();

	constructor(
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		private readonly archiveApi: ArchiveApi,
		private readonly cxDialogService: CxDialogService,
	) {}

	restore = (archiveItem: ArchiveItem): void => {
		this.archiveApi.getRestoreParams(archiveItem)
			.then(restoreParams => {
				if (!restoreParams.isEmpty() && restoreParams.contains(ArchiveListActions.TRANSFER_NESTED_PARAM)) {
					return this.cxDialogService.openAlternateConfirmDialog(
						this.locale.getString('homePage.restoreHomePage'),
						this.locale.getString('homePage.restoreOptions'),
						this.locale.getString('archive.restoreOriginal'),
						this.locale.getString('archive.restoreAndTransfer')).result.then((regularRestore: boolean) => {
							return new RestoreConfig().addParam(ArchiveListActions.TRANSFER_NESTED_PARAM, String(!regularRestore));
						});
				}
				return new RestoreConfig();
			}).then((restoreConfig: RestoreConfig) => {
				return this.archiveApi.restore(archiveItem, restoreConfig);
			}).then(() => {
				this.toastService.addToast(
					this.locale.getString('archive.itemRestored', {itemName: archiveItem.assetName}), AlertLevel.CONFIRM);
				this.changeSource.next();
			});
	};

	restoreTo = (archiveItem: ArchiveItem): void => {
		this.cxDialogService.openAsyncSelectDialog(
			this.locale.getString('archive.restore'),
			this.locale.getString('mAccount.newOwner'),
			this.archiveApi.getRestoreToOptions(archiveItem)
				.then(candidates => candidates.map(candidate => {
					return { name: candidate.email, value: candidate.id };
				}))
		).then((newOwnerId: number) => {
			return this.archiveApi.restoreTo(archiveItem, newOwnerId);
		}).then(() => {
			this.toastService.addToast(
				this.locale.getString('archive.itemRestored', {itemName: archiveItem.assetName}), AlertLevel.CONFIRM);
			this.changeSource.next();
		}).catch(() => {});
	};
}
