import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';

@Component({
	selector: 'peer-report-settings',
	template: `
<div>
	<label for="peerReportingType">{{'widget.reportType'|i18n}}</label>
	<cb-push-buttons id="peerReportingType"
		[list]="peerReportingOptions"
		[selectedValue]="innerValue"
		(selectedValueChange)="onChange($event)">
	</cb-push-buttons>
</div>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => PeerReportSettingsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PeerReportSettingsComponent extends BaseControlValueAccessor<PeerReportType> implements OnInit {

	peerReportingOptions: UIOption<PeerReportType>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref, PeerReportType.SIMPLE_PEER_REPORT);
	}

	ngOnInit(): void {
		this.peerReportingOptions = [
			{displayName: this.locale.getString('widget.simplePeerReportType'), value: PeerReportType.SIMPLE_PEER_REPORT},
			{displayName: this.locale.getString('widget.parentPeerReportType'), value: PeerReportType.PARENT_PEER_REPORT},
			{displayName: this.locale.getString('widget.inheritedReportType'), value: PeerReportType.INHERITED_REPORT},
		];
	}

}
