import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import HierarchyNodeSelection from '@cxstudio/organizations/hierarchy-node-selection.interface';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { AccountOrWorkspaceProject, WorkspaceProject } from '../units/workspace-project/workspace-project';
import { HttpPromise } from '@app/core/http-promise';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { AssetPromise, AssetPromisePlatformProject, ProjectAssetsHelper } from '../units/project-assets-helper.service';
import { IReportModel } from '../project/model/report-model';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { HierarchyLevel } from '@app/modules/hierarchy/hierarchy-level';
import { UrlService } from '@cxstudio/common/url-service.service';
import {
	IProject,
	IWorkspaceProject
} from '@app/modules/hierarchy/filters/upload/organization-filters-wizard-upload-modal.component';

export interface HierarchyEnrichmentFilter {
	reportableOnly?: boolean;
	numericOnly: boolean;
}

export interface HierarchyEnrichmentProperty {
	propertyName: string;
	reportable: boolean;
	numeric: boolean;
}


@Injectable({
	providedIn: 'root'
})
export class OrganizationApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private http: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		@Inject('urlService') private urlService: UrlService
	) {
		this.cachedHttp = this.cxCachedHttpService.cache(Caches.ORGANIZATIONS);
	}

	organizationExists = (organization) => this.http.rawPost('rest/organization/exist', angular.toJson(organization)) as any;

	getOrganizationList = () => this.http.rawGet('rest/organization') as any;

	getPagedOrganizationList = (queryParams): HttpPromise<{ totalCount: number; data: Hierarchy[] }> => {
		let params = new HttpParams({ fromObject: queryParams });
		return this.http.rawGet('rest/organization/paged/', { params });
	};

	getOrgHierarchyModels(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IReportModel[]> {
		return ProjectAssetsHelper.getAssetsForProject<IReportModel[]>(
			project,
			(accountProject) => this.getOrgHierarchyModelsForAccountProject(accountProject, cache),
			(workspaceProject) => this.getHierarchyModelsForWorkspaceProject(workspaceProject, cache),
			() => []
		);
	}

	getOrgHierarchyModelsForAccountProject = (project: IProjectSelection, cache = CacheOptions.CACHED) => {
		let params = new HttpParams()
			.append('contentProviderId', '' + project.contentProviderId)
			.append('projectId', '' + project.projectId);
		return this.cachedHttp.get('rest/organization/models', { params, cache })
			.then(result => result.data) as AssetPromisePlatformProject<IReportModel[]>;
	};

	getHierarchyModelsForWorkspaceProject(project: WorkspaceProject, cache = CacheOptions.CACHED) {
		let params = new HttpParams()
			.append('workspaceId', '' + project.workspaceId)
			.append('projectId', '' + project.projectId);
		return this.cachedHttp.get('rest/organization/ws/models', { params, cache })
			.then(result => result.data) as AssetPromisePlatformProject<IReportModel[]>;
	}

	addOrgHierarchy = (organization) => this.http.rawPost('rest/organization/', angular.toJson(organization));

	removeOrgHierarchy = (hierarchyId: number, deletingGroups) => {
		deletingGroups = deletingGroups || false;
		let params = new HttpParams().append('deletingGroups', '' + deletingGroups);
		return this.http.delete(`rest/organization/hierarchy/${hierarchyId}`, { params });
	};

	removeOrganizationGroups = (hierarchyId: number) => this.http.delete(`rest/organization/hierarchy/${hierarchyId}/groups`);

	getHierarchyGroupsCount = (hierarchyId: number) => {
		let params = new HttpParams().append('hierarchyId', '' + hierarchyId);
		return this.http.rawGet<number>('rest/groups/hierarchy/count', { params });
	};

	activateOrgHierarchy = (orgId: number) => this.http.rawPost(`rest/organization/activate/${orgId}`);

	deactivateOrgHierarchy = (orgId: number) => this.http.rawPost(`rest/organization/deactivate/${orgId}`);

	changeHierarchicalFiltering = (orgId: number, hierarchical: boolean) => {
		let path = hierarchical ? 'hierarchical-filtering' : 'non-hierarchical-filtering';
		return this.http.put(`rest/organization/hierarchy/${orgId}/${path}`);
	};

	getOrgHierarchy = (hierarchyId: number) => this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}`) as any;

	getOrgHierarchyCached = (hierarchyId: number, cache = CacheOptions.CACHED, local = false): Promise<Hierarchy> => {
		const url = `rest/organization/hierarchy/${hierarchyId}`;
		const config = { cache, local };
		return this.cachedHttp.get(url, config);
	};

	getOrgHierarchyIgnoreErrors = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}`, { local: true }) as any;

	getHierarchyNodes = (hierarchyId: number, cache = CacheOptions.CACHED, local = false): Promise<IHierarchyNode[]> => {
		if (!hierarchyId) {
			return Promise.resolve([]);
		}

		const url = `rest/organization/hierarchy/${hierarchyId}/structure`;
		const config = { cache, local };
		return this.cachedHttp.get(url, config);
	};

	getUserHighestHierarchyNode = (hierarchyId: number) => this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}/highest_node`) as any;

	getUserHierarchyNodes = (hierarchyId: number, cache = CacheOptions.CACHED, local = false): Promise<any> => {
		const url = `rest/organization/hierarchy/${hierarchyId}/nodes`;
		const config = { cache, local };
		return this.cachedHttp.get(url, config);
	};

	getViewAsUserHierarchyNodes = (hierarchyId: number, userEmail, cache = CacheOptions.CACHED, local = false): Promise<any> => {
		const url = `rest/organization/hierarchy/${hierarchyId}/view_as`;
		const config = { cache, local };
		return this.cachedHttp.post(url, { userEmail }, config);
	};

	getHierarchyNode = (hierarchyId: number, hierarchyNodeId: number) =>
		this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}/node/${hierarchyNodeId}`) as any;

	getOrgHierarchyNodesAssigned = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}/nodesAssigned`) as any;

	getHierarchyTemplate = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/users/template/${hierarchyId}`, { responseType: 'arraybuffer' });

	getHierarchyUserData = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/users/file/${hierarchyId}`, { responseType: 'arraybuffer' });

	getOrganizationFilters = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/filters/file/${hierarchyId}`, { responseType: 'arraybuffer' });

	getOrganizationFiltersTemplateForProject = (hierarchyId: number, selectedProject: IProject, cpId: number) => {
		return this.http.rawPost(`rest/organization/filters/template/withProject/${hierarchyId}/cpId/${cpId}`,
			selectedProject, { responseType: 'arraybuffer' });
	};

	getOrganizationFiltersTemplateForWorkspaceProject = (hierarchyId: number, selectedWorkspaceProject: IWorkspaceProject) => {
		return this.http.rawPost(`rest/organization/filters/template/with-workspace-project/${hierarchyId}`,
			selectedWorkspaceProject, { responseType: 'arraybuffer' });
	};

	getOrganizationStructure = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/structure/file/${hierarchyId}`, { responseType: 'arraybuffer' });

	getOrganizationStructureTemplate = () =>
		this.http.rawGet('rest/organization/structure/template', { responseType: 'arraybuffer' });

	getOrganizationEnrichment = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/enrichment/file/${hierarchyId}`, { responseType: 'arraybuffer' });

	getOrganizationEnrichmentTemplate = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/enrichment/template/${hierarchyId}`, { responseType: 'arraybuffer' });

	publishHierarchy = (id: number, publishingConfiguration) =>
		this.http.rawPost(`rest/organization/hierarchy/${id}/publish`, publishingConfiguration);

	republishHierarchy = (id: number, publishingConfiguration) =>
		this.http.rawPost(`rest/organization/hierarchy/${id}/republish`, publishingConfiguration);

	applyUpdate = (id: number, publishingConfiguration) =>
		this.http.rawPost(`rest/organization/hierarchy/${id}/applyUpdate`, publishingConfiguration);

	discardUpdate = (id: number) =>
		this.http.rawPost(`rest/organization/hierarchy/${id}/discardUpdate`);

	unpublishHierarchy = (id: number) =>
		this.http.rawPost(`rest/organization/hierarchy/${id}/unpublish`);

	getOrganizationCalculations = (
		hierarchyId: number, filter?: HierarchyEnrichmentFilter
	): Promise<HierarchyEnrichmentProperty[]> => {
		if (!filter) {
			filter = { reportableOnly: false, numericOnly: false };
		}

		let params = new HttpParams()
			.append('reportableOnly', '' + filter.reportableOnly)
			.append('numericOnly', '' + filter.numericOnly);
		return this.http.get(`rest/organization/enrichment/${hierarchyId}/enrichmentProperties`, { params, local: true });
	};

	setOrganizationReportableParameters = (hierarchyId: number, reportableParameters) =>
		this.http.rawPut(`rest/organization/enrichment/${hierarchyId}/enrichmentProperties`, reportableParameters);

	getReportableHierarchies = (project: IProjectSelection) => {
		let params = new HttpParams()
			.append('contentProviderId', '' + project.contentProviderId)
			.append('projectId', '' + project.projectId);
		return this.http.get('rest/organization/enrichment/reportableHierarchies', { params }) as any;
	};

	getPeerReportNode(hierarchyId: number, nodeId: number): Promise<{ present: boolean; node: any }> {
		let params = new HttpParams().append('nodeId', '' + nodeId);
		return this.http.get(`rest/organization/peerReportNode/hierarchy/${hierarchyId}`, { params, local: true });
	}

	isHierarchyGroupInDashboard = (dashboardId: number, hierarchyId: number) =>
		this.http.rawGet(`rest/organization/dashboard/${dashboardId}/contains/${hierarchyId}`);

	getDrillingInfo = (hierarchyId: number, hierarchyNodeId: number) => {
		return this.http.get(`rest/organization/hierarchy/${hierarchyId}/node/${hierarchyNodeId}/drilling-info`) as any;
	};

	getGroupHierarchies = (contentProviderId: number, projectId: number, groupId: number) => {
		return this.http.get(
			`rest/organization/group-hierarchies/contentProvider/${contentProviderId}/project/${projectId}/group/${groupId}`) as any;
	};

	getHierarchyLevels = (hierarchyId: number): Promise<HierarchyLevel[]> => {
		return this.cachedHttp.get(`rest/organization/hierarchy/${hierarchyId}/hierarchy-levels`, {cache: CacheOptions.CACHED});
	};

	createVersion = (hierarchyId: number) =>
		this.http.rawPost(`rest/organization/hierarchy/${hierarchyId}/createVersion`);

	replaceOriginal = (hierarchyId: number) =>
		this.http.rawPost(`rest/organization/hierarchy/${hierarchyId}/replaceOriginal`);

	refreshHierarchyPublicationStatus = (hierarchyId: number) =>
		this.http.rawGet(`rest/organization/hierarchy/${hierarchyId}/publicationStatus`);

	saveExplicitNodeSelection = (hierarchyId: number, nodeId: number): Promise<void> =>
		this.http.put(`rest/organization/hierarchy/${hierarchyId}/select_node/${nodeId}`, {});

	getExplicitNodeSelection = (hierarchyId: number, cache = CacheOptions.CACHED, local = false): Promise<HierarchyNodeSelection> => {
		const url = `rest/organization/hierarchy/${hierarchyId}/node_selection`;
		const config = { cache, local };
		return this.cachedHttp.get(url, config);
	};

	renameOrganizationHierarchy = (hierarchyId: number, newHierarchyName: string): Promise<void> =>
		this.http.put(`rest/organization/hierarchy/${hierarchyId}/rename`, newHierarchyName);

	getStructureUploadUrl(hierarchyId: number, update: boolean): string {
		return this.urlService.getAPIUrl(
			`rest/organization/structure/file/upload/${hierarchyId}/update/${update}`
		);
	}

	getFiltersUploadUrl(hierarchyId: number): string {
		return this.urlService.getAPIUrl(
			`rest/organization/filters/file/upload/${hierarchyId}`
		);
	}

	getEnrichmentUploadUrl(hierarchyId: number): string {
		return this.urlService.getAPIUrl(
			`rest/organization/enrichment/file/upload/${hierarchyId}`
		);
	}
}

app.service('organizationApiService', downgradeInjectable(OrganizationApiService));
