import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import { Injectable, Inject, EventEmitter } from '@angular/core';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { MasterAccountsTableComponent } from '@app/modules/system-administration/master-account/master-accounts-table/master-accounts-table.component';
import { MasterAccountDisableDialogComponent, MasterAccountDisableDialogInput }
	from '@app/modules/system-administration/master-account/master-account-disable-dialog/master-account-disable-dialog.component';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { LicenseType } from '@cxstudio/common/license-types';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import MasterAccountDisableData from '@app/modules/system-administration/master-account/entities/master-account-disable-data';
import { MasterAccountExpirationDialogComponent, MasterAccountExpirationDialogInput }
	from '@app/modules/system-administration/master-account/master-account-expiration-dialog/master-account-expiration-dialog.component';
import MasterAccountExpirationData from '@app/modules/system-administration/master-account/entities/master-account-expiration-data';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { RestUserData } from '@app/modules/user-administration/entities/rest-user-data';
import { AccessExpirationOption } from '@cxstudio/user-administration/users/access-expiration/access-expiration-option.service';

@Injectable()
export class MasterAccountsListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private locale: CxLocaleService,
		private masterAccountManageApi: MasterAccountManageApi,
		private cxDialogService: CxDialogService,
		@Inject('accessExpirationOption') private accessExpirationOption: AccessExpirationOption,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('usersGroupsApiService') private usersGroupsApiService: UsersGroupsApiService,
		@Inject('userApiService') private userApiService: UserApiService
	) {}

	edit(masterAccount: MasterAccount, controller: MasterAccountsTableComponent): void {
		controller.editMasterAccount(masterAccount);
	}

	create(masterAccount: MasterAccount): Promise<any> {
		return this.masterAccountManageApi.addMasterAccount(masterAccount).then(() => {
			this.globalNotificationService.addSuccessNotification(this.locale.getString('common.itemAdded', {
				itemName: masterAccount.accountName
			}));

			this.onChange.emit();
		});
	}

	update(masterAccount: MasterAccount): Promise<any> {
		return this.addAccountOwnersToMasterAccount(masterAccount)
			.then(() => this.saveMasterAccount(masterAccount))
			.then(() => {
				this.globalNotificationService.addSuccessNotification(this.locale.getString('common.itemUpdated', {
					itemName: masterAccount.accountName
				}));

				this.onChange.emit();
			});
	}

	toggle(masterAccount: MasterAccount): void {
		if (masterAccount.restricted) return;

		if (!masterAccount.enabled) {
			this.enable(masterAccount);
		} else {
			this.disable(masterAccount);
		}
	}

	enable(masterAccount: MasterAccount): void {
		let dialog = this.cbDialogService.confirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('mAccount.enableNote'));

		dialog.result.then(() => {
			this.masterAccountManageApi.enableMasterAccount(masterAccount.accountId).then(() => {
				masterAccount.enabled = !masterAccount.enabled;
				this.globalNotificationService.addEnabledNotification(masterAccount.accountName);
				this.onChange.emit();
			});
		});
	}

	disable(selectedMasterAccount: MasterAccount): void {
		let dialogInput = {
			masterAccount: selectedMasterAccount,
		} as MasterAccountDisableDialogInput;

		this.cxDialogService.openDialog(MasterAccountDisableDialogComponent, dialogInput, {size: ModalSize.SMALL}).result
			.then((masterAccountData: MasterAccountDisableData) => {
				this.changeUsersDefaultMasterAccount(masterAccountData).then(() => {
					this.masterAccountManageApi.disableMasterAccount(masterAccountData.masterAccount.accountId).then(() => {
						selectedMasterAccount.enabled = !selectedMasterAccount.enabled;
						this.globalNotificationService.addDisabledNotification(selectedMasterAccount.accountName);
						this.onChange.emit();
					});
				});
			}).catch(() => {});
	}

	deleteMasterAccount(masterAccount: MasterAccount): Promise<any> {
		return Promise.resolve(this.masterAccountManageApi.deleteMasterAccount(masterAccount.accountId).then(() => {
			this.globalNotificationService.addDeletedNotification(masterAccount.accountName);
			this.onChange.emit();
		}));
	}

	updateExpiration(selectedMasterAccount: MasterAccount): void {
		if (selectedMasterAccount.restricted) return;

		let dialogInput = {
			masterAccount: selectedMasterAccount,
		} as MasterAccountExpirationDialogInput;

		this.cxDialogService.openDialog(MasterAccountExpirationDialogComponent, dialogInput, {size: ModalSize.SMALL}).result
			.then((masterAccountExpirationData: MasterAccountExpirationData) => {
				selectedMasterAccount.expirationSettings = masterAccountExpirationData;

				this.update(selectedMasterAccount);
			}).catch(() => {});
	}

	private changeUsersDefaultMasterAccount = (masterAccountData: MasterAccountDisableData): Promise<any> => {
		let userIds = Object.keys(masterAccountData.userDefaultMasterAccountMap);
		if (userIds.length === 0) return Promise.resolve();

		let promises: Promise<any>[] = _.map(userIds, (userId: string) => {
			let defaultMasterAccountId = masterAccountData.userDefaultMasterAccountMap[userId];
			return this.userApiService.changeDefaultMA(Number(userId), defaultMasterAccountId) as unknown as Promise<void>;
		});

		return Promise.all(promises);
	};

	private addAccountOwnersToMasterAccount = (masterAccount: MasterAccount): Promise<any> => {
		let editingMasterAccountId = masterAccount.accountId;
		let accountOwners = masterAccount.owners || [];

		let accountOwnersBeyondMasterAccount = accountOwners.filter(owner => {
			return !owner.masterAccountData[editingMasterAccountId];
		});

		if (accountOwnersBeyondMasterAccount.length === 0) {
			return Promise.resolve();
		}

		return Promise.resolve(this.usersGroupsApiService.getPermissions().then((permissions) => {
			let usersData: RestUserData[] = accountOwnersBeyondMasterAccount.map(accountOwner => {
				let accountOwnerData: RestUserData = cloneDeep(accountOwner);

				accountOwnerData.masterAccountPermissions = permissions;
				accountOwnerData.licenseTypeId = LicenseType.ANALYZE;
				accountOwnerData.accessExpirationDate = moment()
					.add(this.accessExpirationOption.values().NOW.period, 'days')
					.toDate();

				return accountOwnerData;
			});

			return this.userApiService.addAccountOwnersToMasterAccount(editingMasterAccountId, usersData);
		}) as PromiseLike<any>);
	};

	private saveMasterAccount = (masterAccount: MasterAccount): Promise<void> => {
		delete masterAccount.disabled;
		delete masterAccount.brandingColors;

		return this.masterAccountManageApi.updateMasterAccount(masterAccount);
	};
}
