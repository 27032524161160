import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { PreviewBaseComponent } from '@app/modules/widget-visualizations/feedback/preview-base.component';
import { VisualizationDataService } from '@app/modules/widget-visualizations/visualization-data.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { ITableColumnFormatter } from '@cxstudio/reports/entities/table-column';
import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { SentenceMetadataService } from '@cxstudio/reports/preview/sentence-metadata.service';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

@Component({
	selector: 'preview-bubbles',
	templateUrl: './preview-bubbles.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewBubblesComponent extends PreviewBaseComponent implements OnInit {

	private sentences: PreviewSentence[];

	columns: any[];
	formatSentence: ITableColumnFormatter<PreviewSentence>;
	formatSentiment: ITableColumnFormatter<PreviewSentence>;
	formatEaseScore: ITableColumnFormatter<PreviewSentence>;
	formatEmotion: ITableColumnFormatter<PreviewSentence>;
	sentimentColorFunction: (sentence: PreviewSentence) => string;

	constructor(
		ref: ChangeDetectorRef,
		locale: CxLocaleService,
		private visualizationDataService: VisualizationDataService,
		profanityDisguiseService: ProfanityDisguiseService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('suggestionMenu') suggestionMenu: SuggestionMenu,
		@Inject('reportUtils') reportUtils: ReportUtils,
		@Inject('dateFilterService') private dateFilterService: DateFilterService,
		@Inject('analyticPreviewFormatting') private analyticPreviewFormatting: AnalyticPreviewFormatting,
		@Inject('pointSelectionUtils') private pointSelectionUtils: PointSelectionUtils,
		@Inject('sentenceMetadataService') private sentenceMetadataService: SentenceMetadataService,
		@Inject('elementUtils') private elementUtils: ElementUtils,
	) {
		super(ref, reportUtils, locale, profanityDisguiseService, suggestionMenu);
	}


	ngOnInit(): void {
		if (this.checkError(this.visualizationDataService.validateData(this.dataObject, this.widget.name)))
			return;

		this.initializeData();
	}

	private initializeData = (): void => {
		this.columns = [];
		this.sentences = this.dataObject.data;
		this.formatEaseScore = this.analyticPreviewFormatting.easeScore(
			_.find(this.utils.metrics, {name: PredefinedMetricConstants.EASE_SCORE}), {backgroundHighlight: false});
		this.formatEmotion = this.analyticPreviewFormatting.emotion(
			_.find(this.utils.metrics, {name: PredefinedMetricConstants.EMOTION}), {backgroundHighlight: false});
		let sentiment = _.findWhere(this.utils.metrics, {name: PredefinedMetricConstants.SENTIMENT});
		this.formatSentiment = this.analyticPreviewFormatting.sentimentFormatter(sentiment, {backgroundHighlight: false});
		this.formatSentence = this.analyticPreviewFormatting.sentenceFormatter(
				this.widget.visualProperties.sentimentHighlightingEnabled, sentiment);

		this.sentimentColorFunction = this.analyticPreviewFormatting.sentimentColorFunction(sentiment);

		let itemsPerColumn = this.sentences.length / this.widget.visualProperties.bubbleColumns;
		this.columns = _.toArray(_.groupBy(this.sentences, (element, index) => {
			return Math.floor(index / itemsPerColumn);
		}));

		let selectedSentenceId = this.pointSelectionUtils.getSelectedPoint(this.utils.containerId, this.utils.widgetId);
		if (selectedSentenceId && !this.demo) {
			this.sentences.forEach(row => delete (row as any).selected);
			this.pointSelectionUtils.enablePointSelection(this.utils.containerId, this.utils.widgetId, true);
			let sentence = _.find(this.sentences, {id: selectedSentenceId as unknown as number });
			if (sentence) {
				(sentence as any).selected = true;
				this.elementUtils.scrollTo(`widgetId-${this.utils.widgetId}-bubbleId-${sentence.id}`);
			}
		}

		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
	};

	hasSMUserName = (sentence: PreviewSentence): boolean => {
		return !isEmpty(sentence.userName) || !isEmpty(sentence.attributes?.full_name);
	};

	getSMUserName = (sentence: PreviewSentence): string => {
		return isEmpty(sentence.userName)
			? sentence.attributes.full_name
			: sentence.userName;
	};

	getBorderColor = (sentence: PreviewSentence): string => {
		if (this.widget.visualProperties.sentimentHighlightingEnabled && !this.isVerbatimView())
			return this.sentimentColorFunction(sentence);
		return '';
	};

	formatDate = (utcDateStr, withTimezone: boolean): string => {
		let format = withTimezone ? DateTimeFormat.BASIC_DATE_TIME : DateTimeFormat.BASIC_DATE;
		return utcDateStr ? this.dateFilterService.formatDocDatePoint(utcDateStr,
			this.utils.projectTimezone, withTimezone, format) : '';
	};

	showDate = () => {
		return !!_.findWhere(this.widget.visualProperties.columns, {name: PreviewColumn.DOC_DATE});
	};

	getSourceName = (sentence: PreviewSentence): string => {
		return sentence.smService as string;
	};

	showSentiment = (): boolean => {
		return !!_.findWhere(this.widget.visualProperties.columns, {name: PreviewColumn.SENTIMENT});
	};

	showSource = (sentence: PreviewSentence): boolean => {
		return !!_.findWhere(this.widget.visualProperties.columns, {name: PreviewColumn.SOURCE}) && !!sentence.smService;
	};

	showEaseScore = (sentence: PreviewSentence): boolean => {
		return !!_.findWhere(this.widget.visualProperties.columns, {name: PreviewColumn.EFFORT})
			&& this.sentenceMetadataService.hasEaseScore(sentence);
	};

	showEmotion = (sentence: PreviewSentence): boolean => {
		return !!_.findWhere(this.widget.visualProperties.columns, {name: PreviewColumn.EMOTION})
			&& this.sentenceMetadataService.hasEmotion(sentence);
	};

	onContainerClick = (event: MouseEvent): void => {

		// let link clicks bubble up so we can check them for external links
		if (!this.urlService.isLinkClick(event)) {
			event.stopPropagation(); // to not fire click handler for empty space
		}
		if (_.isEmpty(this.widget.linkedWidgets)) {
			this.onSentenceClick(null, event);
		}
	};

	handleEnterKeyOnContainer = (event: KeyboardEvent): void => {
		const target = event.target;
		const childElem = $(target).find('speech-bubble').first();
		$(childElem)?.trigger('click');
	};

	onSentenceClick = (sentence: PreviewSentence, event: MouseEvent): void => {
		super.handleSentenceClick(sentence, event, '.feedback-preview-bubble-item');
	};

	handleEnterKeyOnSentence  = (sentence: PreviewSentence, event: KeyboardEvent): void => {
		const target = event.target;
		$(target)?.trigger('click');
	};

	getBubbleId(sentence: PreviewSentence): string {
		return `widgetId-${this.utils.widgetId}-bubbleId-${sentence.id}`;
	}

	getAriaLabelForBubble(sentence: PreviewSentence): string {
		return (this.utils.translationEnabled) ? this.getTranslationText(sentence): sentence.text;
	}

}
