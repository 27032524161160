import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import Authorization from '@cxstudio/auth/authorization-service';
import { ExportService } from '@cxstudio/services/export-service.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ProjectSelectionDialogComponent } from '../project-selection-dialog/project-selection-dialog.component';
import { ReportProjectSelectionDialogComponent } from '../report-project-selection-dialog/report-project-selection-dialog.component';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { IAutoselectProviders } from '@cxstudio/services/autoselect-providers.factory';
import { Security } from '@cxstudio/auth/security-service';
import { TrackingAssetType } from '@app/modules/consumption-tracker/entities/tracking-asset-type.enum';
import { StatisticRefinementMode } from '@app/modules/consumption-tracker/entities/statistic-refinement-mode.enum';

@Component({
	selector: 'account-overview',
	templateUrl: './account-overview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccountOverviewComponent implements OnInit {
	TrackingAssetType = TrackingAssetType;
	StatisticRefinementMode = StatisticRefinementMode;

	providers: any = {};
	loading: Promise<any>;
	RESULT_MESSAGE: string = 'administration.requestResultMessage';

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		@Inject('security') private security: Security,
		@Inject('exportService') private readonly exportService: ExportService,
		@Inject('exportApiService') private readonly exportApiService: ExportApiService,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('AutoselectProviders') private readonly AutoselectProviders
	) { }

	ngOnInit(): void {
		const autoselectProcess: IAutoselectProviders = new this.AutoselectProviders(this.providers);
		autoselectProcess.init();
	}

	getDashInfoReport = () => {
		this.exportService.requestDashInfoReport().then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.dashInfoReportMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	getDataAccessReport = () => {
		this.exportService.requestDataAccessReport().then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.dataAccessReportMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	getPermissionMappingReport = () => {
		this.exportService.requestPermissionMappingReport().then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.permissionMappingReportMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	getWidgetVersionReport = () => {
		this.exportService.requestWidgetVersionReport().then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.widgetVersionReportMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	getEmbeddedWidgetsReport = () => {
		this.exportService.requestEmbeddedWidgetsReport().then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.embeddedWidgetsReportMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	createMetricSharingReport = () => {
		this.exportService.requestMetricSharingReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.metricSharingReport'),
				this.locale.getString('administration.requestMetricSharingReportMessage'));
		});
	};

	createFilterSharingReport = () => {
		this.exportService.requestFilterSharingReport().then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.filterSharingReport'),
				this.locale.getString('administration.requestFilterSharingReportMessage'));
		});
	};

	getLexiconSuggestions = () => {
		if (this.providers.contentProviders && this.providers.contentProviders.length === 1 &&
			this.providers.accounts && this.providers.accounts.length === 1 &&
			this.providers.projects && this.providers.projects.length === 1) {
			const lexiconProjectData = {
				contentProviderId: this.providers.props.contentProviderId,
				accountId: this.providers.props.accountId,
				projectIds: [this.providers.props.project.projectId]
			};
			this.doGetLexiconSuggestions(lexiconProjectData);
		} else {
			this.openProjectSelectionDialog().result.then((result) => {
				this.doGetLexiconSuggestions(result);
			}).catch(_.noop);
		}
	};

	private readonly doGetLexiconSuggestions = (props) => {
		this.loading = Promise.resolve(this.exportService.requestLexiconSuggestions(props).then(() => {
			this.cxDialogService.notify(this.locale.getString('administration.lexiconSuggestionHeader'),
				this.locale.getString('administration.lexiconSuggestionMessage'));
		}) as PromiseLike<any>);
	};

	openProjectSelectionDialog = () => {
		return this.cxDialogService.openDialog(ReportProjectSelectionDialogComponent, undefined, {
			class: 'br-report-project-selection-dialog'
		});
	};

	showLexiconSuggestionsButton = () => {
		return this.authorization.hasLexiconSuggestionsAccess();
	};

	showTranslateUsage = () => {
		return this.security.getCurrentMasterAccount().publicProperties.quickTranslate;
	};

	showModelAccessReportDialog = () => {
		this.cxDialogService.openDialog(ProjectSelectionDialogComponent).result
			.then(this.createModelAccessReport).catch(_.noop);
	};

	createModelAccessReport = (projectSelection: IProjectSelection) => {
		this.exportService.requestModelAccessReport(projectSelection).then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.modelAccessReport'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	};

	showAlertSubscriptionReportDialog(): void {
		this.cxDialogService.openDialog(ProjectSelectionDialogComponent).result
			.then(project => this.createAlertSubscriptionsReport(project), _.noop);
	}

	createAlertSubscriptionsReport(project: IProjectSelection): void {
		this.exportApiService.requestAlertSubscriptionsReport(project).then(() => {
			this.cxDialogService.notify(
				this.locale.getString('administration.requestResultMessage'),
				this.locale.getString(this.RESULT_MESSAGE));
		});
	}
}

app.directive('accountOverview', downgradeComponent({ component: AccountOverviewComponent }));
