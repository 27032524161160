import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { TemplateCommonService } from '@cxstudio/dashboard-templates/template-common-service.service';

enum InternalDashboardTemplate {
	BLANK = 1000,
	XM_PROGRAM = 1001,
	SEGMENT_LEVEL_SCORECARD = 1002,
	SINGLE_ISSUE = 1003,
}

@Component({
	selector: 'template-tiles',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div [ngBusy]="loadingTemplates">
			<div *ngFor="let template of templates" class="col-md-3 col-sm-4 text-center mv-16 _t-dashboard-template">
				<div class="box p-relative template-preview-wrap">
					<div (click)="createDashboard(template)" class="template-preview p-relative cursor-pointer" tabindex="0"
						 (keydown.enter)="createDashboard(template)">
						<div
							class="preview-img"
							*ngIf="template.image && template.image.length !== 0"
							[style.background-image]="getBackgroundImage(template)"></div>
						<p class="text-center template-plus">
							<i class="q-icon q-icon-add action-color" [attr.aria-label]="'templates.useTemplate'|i18n"></i>
						</p>
						<h4 class="text-center">{{template.name}}</h4>
					</div>

					<p>{{template.title}}</p>

					<button *ngIf="!hideTemplateModalButton(template)" (click)="openTemplateModalHandler(template)" class="templates-info action-color">
						<span class="q-icon q-icon-info" [attr.aria-label]="'templates.description'|i18n"></span>
					</button>
				</div>
			</div>
		</div>`
})

export class TemplateTilesComponent implements OnInit {
	templates: any[];
	loadingTemplates: Promise<any>;

	constructor(
		private locale: CxLocaleService,
		@Inject('dashboardTemplatesApiService') private dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('templateCommonService') private templateCommonService: TemplateCommonService,
	) {}

	ngOnInit(): void {
		this.templates = [];
		this.getTemplates();
	}

	getTemplates = () => {
		this.loadingTemplates = this.dashboardTemplatesApiService.getSystemTemplates()
			.then((response) => {
				this.templates = response.data
					.sort((a, b) => (a.id as number) - (b.id as number))
					.map((template: {id: InternalDashboardTemplate}) => {
						return {...template, ...this.localizeTemplateDetails(template.id)};
					});
				this.loadingTemplates = null;
			}) as unknown as Promise<any>;
	};

	getBackgroundImage(template): string {
		return `url(img/templates/${template.image})`;
	}

	private localizeTemplateDetails(id: InternalDashboardTemplate): {name; title; description?; image?} {
		switch (id) {
			case InternalDashboardTemplate.BLANK:
				return {
					name: this.locale.getString('dashboardTemplates.name1000'),
					title: this.locale.getString('dashboardTemplates.title1000')
				};
			case InternalDashboardTemplate.XM_PROGRAM:
				return {
					name: this.locale.getString('dashboardTemplates.name1001'),
					title: this.locale.getString('dashboardTemplates.title1001'),
					description: this.locale.getString('dashboardTemplates.description1001'),
					image: 'program-summary-template.png'
				};
			case InternalDashboardTemplate.SEGMENT_LEVEL_SCORECARD:
				return {
					name: this.locale.getString('dashboardTemplates.name1002'),
					title: this.locale.getString('dashboardTemplates.title1002'),
					description: this.locale.getString('dashboardTemplates.description1002'),
					image: 'segment-level-template.png'
				};
			case InternalDashboardTemplate.SINGLE_ISSUE:
				return {
					name: this.locale.getString('dashboardTemplates.name1003'),
					title: this.locale.getString('dashboardTemplates.title1003'),
					description: this.locale.getString('dashboardTemplates.description1003'),
					image: 'single-issue-template.png'
				};
			default:
				throw new Error(`Unknown dashboard template id: ${id}`);
		}
	}

	hideTemplateModalButton = (template): boolean => this.templateCommonService.hideTemplateModalButton(template);

	openTemplateModalHandler = (template) => {
		return this.templateCommonService.openTemplateModal(template);
	};
	createDashboard = (template) => this.templateCommonService.createDashboard(template) as unknown as Promise<any>;
}
