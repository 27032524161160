import * as _ from 'underscore';
import { TimePrimaryGroup, TimePrimaryGroups } from '@cxstudio/reports/attributes/time-primary-group.enum';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { GroupingUtils } from '@cxstudio/reports/utils/analytic/grouping-utils.class';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { MetricValues } from '@cxstudio/reports/providers/cb/constants/metric-values';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { AnalyticDataUtils } from '@app/modules/widget-visualizations/utilities/analytic-data-utils.class';
import { SlickHeaderUtils } from '@app/modules/item-grid/slick-header-utils.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { RefinementBehavior } from '@app/modules/reports/refinement-behavior-type';
import { TransferMode } from '@app/modules/user-administration/transfer/transfer-mode';
import { TreeSelectionBuilder, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { SortUtils } from '@app/shared/util/sort-utils';
import { AssetEditPermissionAction } from './asset-management/asset-edit-permission-action';
import { AnSortDirection } from './common/an-sort-direction';
import { CacheOptions } from './common/cache-options';
import { Caches } from './common/caches';
import { RequestConfigTimeout } from './common/content-provider-timeouts/request-config-timeout';
import { RequestDomainTimeout } from './common/content-provider-timeouts/request-domain-timeout';
import { RequestMethodTimeout } from './common/content-provider-timeouts/request-method-timeout';
import { FontOptions } from './common/font-options.class';
import { GlobalNotificationType } from './common/global-notification/global-notification';
import { HiddenItemType } from './common/hidden-item-type';
import { LicenseLevel } from './common/license-levels';
import { LicenseType } from './common/license-types';
import ModalMode from './common/modal/modal-mode.enum';
import { ShareAction } from './common/share-actions.constant';
import { SharingStatus } from './common/sharing-status';
import { WidgetGridsterSelectors } from './dashboards/components/widget-gridster-selectors.constant';
import { DashboardRunStatus } from './dashboards/constants/dashboard-run-status';
import { DashboardFilterTypes } from './dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DriversType } from './drivers/entities/drivers-type';
import { FolderTypes } from './folders/folder-types-constant';
import { Folders } from './folders/folders-constant';
import { GridContext } from './grids/grid-context-constant';
import { GridMode } from './grids/grid-mode';
import { ApplicationThemeScope } from './header/application-theme-scope';
import { ExportTypes } from './master-accounts/export-types.constant';
import { MetricMode } from './metrics/metric-modes-constant';
import { PredefinedMetricValue } from './metrics/predefined/predefined-metric-value';
import { HierarchyLoadStatus } from './organizations/hierarchy-load-status';
import { HierarchyPublicationState } from './organizations/hierarchy-publication-state';
import { FilterQueryField } from './query/filter-query-field.enum';
import { UserQueryType } from './query/user-query-type.enum';
import { AnalyticMetricTypes, AnalyticMetricType } from './report-filters/constants/analytic-metric-types';
import { FilterAttributeTypes } from './report-filters/constants/filter-attribute-types.constant';
import { FilterEmptyObject } from './report-filters/constants/filter-empty-object.constant';
import { FilterRuleTypes, FilterRuleType } from './report-filters/constants/filter-rule-type.value';
import { FilterTypes } from './report-filters/constants/filter-types-constant';
import { FilterValidationMessages } from './report-filters/constants/filter-validation-messages.constant';
import { GeneratedFolderType } from './report-filters/generated-folder-type';
import { ConversationAttributes } from './reports/document-explorer/conversations/conversation-attributes.constant';
import { PreviewDataType } from './reports/entities/preview-data-type';
import { PreviewExportContextType } from './reports/entities/preview-export-context-types.class.';
import { PreviewMode } from './reports/entities/preview-mode';
import { PreviewSortAttributes } from './reports/entities/preview-sort-attributes';
import { ReportAssetType } from './reports/entities/report-asset-type';
import { WidgetVisualization } from './reports/entities/widget-visualization';
import { PreviewColumn } from './reports/preview/preview-predefined-columns';
import { AttributeUrlType } from './reports/providers/cb/constants/attribute-url-type.constant';
import { DefaultMetricConfig } from './reports/providers/cb/constants/default-metric-config';
import { WordsFilteringMode } from './reports/providers/cb/constants/words-filtering-mode';
import { DualAxisTypes } from './reports/providers/cb/definitions/dual-axis-types';
import { EmptyPeriodType } from './reports/providers/cb/definitions/empty-period-type';
import { ReportResponsiveness } from './reports/responsiveness/report-responsiveness';
import { WidgetReportDisplayState } from './reports/responsiveness/widget-report-display-state';
import { OptionsConstant } from './reports/settings/options/options-constant';
import { AnalyticsDefinitionUtils } from './reports/utils/analytic/analytics-definition-utils.service';
import { ReportPeriods } from './reports/utils/analytic/report-periods';
import { ColorConstants } from './reports/utils/color/color-constants';
import { CommonInherentProperties } from './reports/utils/contextMenu/drill/common-inherent-properties.class';
import { DrillWidgetType } from './reports/utils/contextMenu/drill/drill-widget-type';
import { PredefinedMetricIcons } from '../../../src/app/util/predefined-metric-icons';
import { MetricFilters } from './reports/utils/metric-filters.service';
import { ValueUtils } from './reports/utils/value-utils.service';
import { VisualizationType } from './reports/visualization-types.constant';
import { TableRowHeights } from './reports/visualizations/definitions/slick/table-row-heights.constant';
import { WidgetVisibility } from './reports/widget-visibility';
import EventType from './services/event/event-type.enum';
import BulkUpdateValue from './user-administration/bulk/bulk-update-value.enum';
import { DashboardTransferMode } from './user-administration/users/asset-transfer/dashboard-transfer-mode';
import { UserBulkActionMode } from './user-administration/users/entities/user-bulk-action-mode';
import { UserManagementTabMode } from './user-administration/users/entities/user-management-tab-mode';
import { UserSelectionMethod } from './user-administration/users/entities/user-selection-method.enum';
import { UserTabLoadMode } from './user-administration/users/entities/user-tab-load-mode';
import { BulkAccessMode } from './user-administration/users/project-access/bulk-access-mode';
import { UserRemoveModes } from './user-administration/users/removal/user-remove-modes';

// keeping some constants here which are required by JS code.
// remove once they are replaced with direct imports


app.constant('AnalyticDataUtils', AnalyticDataUtils);
app.constant('AnSortDirection', AnSortDirection);
app.constant('ApplicationThemeScope', ApplicationThemeScope);
app.constant('AssetEditPermissionAction', AssetEditPermissionAction);
app.constant('AttributeUrlType', AttributeUrlType);
app.constant('BulkAccessMode', BulkAccessMode);
app.constant('BulkUpdateValue', BulkUpdateValue);
app.constant('Caches', Caches);
app.constant('ColorConstants', ColorConstants);
app.constant('ColorUtilsHelper', ColorUtilsHelper);
app.constant('ConversationAttributes', ConversationAttributes);
app.constant('DashboardFilterTypes', DashboardFilterTypes);
app.constant('DashboardRunStatus', DashboardRunStatus);
app.constant('DashboardTransferMode', DashboardTransferMode);
app.constant('DefaultMetricConfig', DefaultMetricConfig);
app.constant('DrillWidgetType', DrillWidgetType);
app.constant('DriversType', DriversType);
app.constant('EmptyPeriodType', EmptyPeriodType);
app.constant('EventType', EventType);
app.constant('exportTypes', ExportTypes);
app.constant('FilterAttributeTypes', FilterAttributeTypes);
app.constant('FilterEmptyObject', FilterEmptyObject);
app.constant('FilterQueryField', FilterQueryField);
app.constant('FilterTypes', FilterTypes);
app.constant('FilterValidationMessages', FilterValidationMessages);
app.constant('Folders', Folders);
app.constant('FolderTypes', FolderTypes);
app.constant('GeneratedFolderType', GeneratedFolderType);
app.constant('GridContext', GridContext);
app.constant('GridMode', GridMode);
app.constant('groupIdentifierHelper', GroupIdentifierHelper);
app.constant('groupingUtils', GroupingUtils);
app.constant('HiddenItemType', HiddenItemType);
app.constant('HierarchyLoadStatus', HierarchyLoadStatus);
app.constant('HierarchyPublicationState', HierarchyPublicationState);
app.constant('LicenseLevel', LicenseLevel);
app.constant('LicenseType', LicenseType);
app.constant('MetricMode', MetricMode);
app.constant('MetricTypes', MetricType);
app.constant('MetricValues', MetricValues);
app.constant('ModalMode', ModalMode);
app.constant('OptionsConstant', OptionsConstant);
app.constant('PredefinedMetricIcons', PredefinedMetricIcons);
app.constant('PreviewColumn', PreviewColumn);
app.constant('PreviewDataType', PreviewDataType);
app.constant('PreviewExportContextType', PreviewExportContextType);
app.constant('PreviewMode', PreviewMode);
app.constant('PreviewSortAttributes', PreviewSortAttributes);
app.constant('RefinementBehavior', RefinementBehavior);
app.constant('ReportAssetType', ReportAssetType);
app.constant('ReportPeriods', ReportPeriods);
app.constant('ReportResponsiveness', ReportResponsiveness);
app.constant('RequestConfigTimeout', RequestConfigTimeout);
app.constant('RequestDomainTimeout', RequestDomainTimeout);
app.constant('RequestMethodTimeout', RequestMethodTimeout);
app.constant('ShareAction', ShareAction);
app.constant('SharingStatus', SharingStatus);
app.constant('TableRowHeights', TableRowHeights);
app.constant('TimePrimaryGroup', TimePrimaryGroup);
app.constant('TimePrimaryGroups', TimePrimaryGroups);
app.constant('TransferMode', TransferMode);
app.constant('UserBulkActionMode', UserBulkActionMode);
app.constant('UserManagementTabMode', UserManagementTabMode);
app.constant('UserQueryType', UserQueryType);
app.constant('UserRemoveModes', UserRemoveModes);
app.constant('UserSelectionMethod', UserSelectionMethod);
app.constant('UserTabLoadMode', UserTabLoadMode);
app.constant('VisualizationTypes', VisualizationType);
app.constant('WidgetGridsterSelectors', WidgetGridsterSelectors);
app.constant('WidgetReportDisplayState', WidgetReportDisplayState);
app.constant('WidgetType', WidgetType);
app.constant('WidgetVisibility', WidgetVisibility);
app.constant('WidgetVisualization', WidgetVisualization);
app.constant('WordsFilteringMode', WordsFilteringMode);

app.value('AnalyticMetricTypes', _.extend(AnalyticMetricTypes, AnalyticMetricType));
app.value('analyticsDefinitionUtils', angular.extend({}, AnalyticsDefinitionUtils));
app.value('BetaFeature', BetaFeature);
app.value('CacheOptions', CacheOptions);
app.value('commonInherentProperties',  CommonInherentProperties);
app.value('dualAxisTypes', angular.extend({}, DualAxisTypes));
app.value('FilterRuleTypes', angular.extend(FilterRuleTypes, FilterRuleType));
app.value('FontOptions', FontOptions.getOptions());
app.value('GlobalNotificationType', GlobalNotificationType);
app.value('metricFilters', MetricFilters);
app.value('PredefinedMetricValue', PredefinedMetricValue);
app.value('SlickHeaderUtils', SlickHeaderUtils);
app.value('sortUtils', SortUtils);
app.value('TreeSelection', TreeSelectionBuilder);
app.value('TreeSelectionStrategy', TreeSelectionStrategy);
app.value('valueUtils', ValueUtils);
