import { DatePeriodType } from '@app/modules/utils/dates/date-period-constants.service';

export enum DatePointMode {
	CUSTOM = 'custom',
	START = 'start',
	END = 'end',
	CURRENT = 'current',
	TODAY = 'today'
}

export enum DatePickerMode {
	DAY = 'day',
	MONTH = 'month',
	YEAR = 'year'
}

export interface IPointValue {
	date: string;
	period: DatePeriodType;
	amount?: any;
}

export class DatePointModes {
	static getPointModes = (type: 'from' | 'to') => {
		if (type === 'from') {
			return [DatePointMode.START, DatePointMode.CURRENT, DatePointMode.CUSTOM];
		} else {
			return [DatePointMode.TODAY, DatePointMode.END, DatePointMode.CURRENT];
		}
	};
}
