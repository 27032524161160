import { IHttpService, IRequestConfig } from 'angular';

/**
 * Retry policy
 */
app.factory('RequestRepeater', ($http: IHttpService, $q, $timeout, $log) => {

	const DEFAULT_DELAY = 10000; // delay between attempts
	const DEFAULT_ATTEMPTS = 5;

	return class RequestRepeater {
		currentAttempt: number;
		config: IRequestConfig;
		delay: number;
		attempts: number;

		constructor(
			config: IRequestConfig,
			delay: number,
			attempts: number
		) {
			this.currentAttempt = 0;
			this.delay = delay || DEFAULT_DELAY;
			this.attempts = attempts || DEFAULT_ATTEMPTS;
			this.config = config;
		}

		/**
		 *  Return new request promise, or false if max attempts reached
		 */
		retry = () => {
			if (this.currentAttempt++ >= this.attempts) {
				$log.info(`Stop repeating:${this.config.url}`);
				return false;
			}
			$log.info(`Repeat in ${this.delay / 1000} secs:${this.config.url}`);
			let defer = $q.defer();
			$timeout(() => {
				$http(this.config).then(defer.resolve, defer.reject);
			}, this.delay);
			return defer.promise;
		};
	};
});
