import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import ReferenceLine from '@app/modules/plot-lines/reference-lines/reference-line';
import TimeReferenceLine from '@app/modules/plot-lines/reference-lines/time-reference-line';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ObjectUtils } from '@app/util/object-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetDashboardDateFilterProcessorService } from '@app/modules/dashboard/dashboard-filter-processors/widget-dashboard-date-filter-processor.service';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import ChartType from '@cxstudio/reports/entities/chart-type';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import * as _ from 'underscore';

export interface IAdvancedChartSettings {
	widget: Widget;
	calculationOptions: any[];
	showSecondary: boolean;
	primaryPrefix: string;
	secondaryPrefix: string;
	primaryAxisDisplayName: string;
	secondaryAxisDisplayName: string;
	lockAxisLimits: boolean;
	widgetType: WidgetType;
	isCalculationSeries: boolean;
	projectIdentifier: ProjectIdentifier;
	selectedMetrics: ReportCalculation[];
}

@Component({
	selector: 'advanced-chart-settings',
	templateUrl: './advanced-chart-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdvancedChartSettingsComponent extends ModalBindings<IAdvancedChartSettings> implements OnInit {
	widget: Widget;
	calculationOptions: any[];
	showSecondary: boolean;
	primaryPrefix: string;
	secondaryPrefix: string;
	primaryAxisDisplayName: string;
	secondaryAxisDisplayName: string;
	lockAxisLimits: boolean;
	widgetType: WidgetType;
	isCalculationSeries: boolean;
	selectedMetrics: ReportCalculation[];
	projectIdentifier: ProjectIdentifier;
	newReferenceLines: ReferenceLine[];
	ui: {
		addLineCheckbox: boolean;
		addTimeLineCheckbox: boolean;
	};
	axisTitle: string;
	axisSelections: any;
	axisNames: {
		primaryName?: string;
		secondaryName?: string;
	};
	configurerAxisNames: string[];
	newTimeReferenceLines: TimeReferenceLine[];
	referenceLineValues: string[];
	dateTitle: string;
	visualProps: VisualProperties;
	studioMetrics: Metric[];
	widgetProject: AccountOrWorkspaceProject;
	widgetDateRange: DateFilter;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly metricsService: MetricsService,
		private readonly widgetDashboardDateFilterProcessor: WidgetDashboardDateFilterProcessorService,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		modal: NgbActiveModal,
		private readonly ref: ChangeDetectorRef
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.ui = {} as AdvancedChartSettingsComponent['ui'];
		this.visualProps = this.input.widget.visualProperties;
		this.calculationOptions = this.input.calculationOptions;
		this.showSecondary = this.input.showSecondary;
		this.primaryPrefix = this.input.primaryPrefix;
		this.secondaryPrefix = this.input.secondaryPrefix;
		this.primaryAxisDisplayName = this.input.primaryAxisDisplayName;
		this.secondaryAxisDisplayName = this.input.secondaryAxisDisplayName;
		this.lockAxisLimits = this.input.lockAxisLimits;
		this.widgetType = this.input.widgetType;
		this.isCalculationSeries = this.input.isCalculationSeries;
		this.selectedMetrics = this.input.selectedMetrics;

		const originalReferenceLines = this.visualProps.referenceLines || [];
		this.newReferenceLines = ObjectUtils.copy(originalReferenceLines);
		this.newReferenceLines.unshift({} as ReferenceLine);
		this.ui.addLineCheckbox = this.referenceLinesExist();
		this.axisTitle = this.visualProps.axisTitle;
		this.axisSelections = !this.isScatter() ? this.visualProps.attributeSelections :
			{
				yAxis: this.input.selectedMetrics[0],
				secondaryYAxis: this.input.selectedMetrics[1]
			};

		this.axisNames = {};
		if (this.isScatter()) {
			this.axisNames.primaryName = this.formatAxisName(this.visualProps.yAxis);
			this.axisNames.secondaryName = this.formatAxisName(this.visualProps.xAxis);
			this.configurerAxisNames = _.values(this.axisNames);
		}

		const originalTimeReferenceLines = this.visualProps.timeReferenceLines || [];
		this.newTimeReferenceLines = ObjectUtils.copy(originalTimeReferenceLines);
		this.newTimeReferenceLines.unshift({} as TimeReferenceLine);
		this.ui.addTimeLineCheckbox = this.timeReferenceLinesExist();
		this.dateTitle = this.locale.getString('widget.date');

		if (this.showCaseSettings()) {
			let widgetSettings = ObjectUtils.copy(this.input.widget);
			let filtersProvider = this.currentWidgets.getDashboardHistory(widgetSettings.containerId);
			let filterApplication = new WidgetDashboardFilterApplication(widgetSettings, filtersProvider);
			this.widgetProject = this.reportAssetUtilsService.getWidgetProject(widgetSettings);
			this.widgetDashboardDateFilterProcessor.process(filterApplication).then(() => {
				this.widgetDateRange = widgetSettings.properties.dateRangeP1;
			});

			this.visualProps.caseVisualizations = this.visualProps.caseVisualizations ||
				{
					enabled: false,
					selectedCases: [],
				};

		}
		if (this.showReferenceLines()) {
			this.metricsService.getMetrics(this.input.projectIdentifier, CacheOptions.CACHED).then(metrics => {
				this.studioMetrics = metrics;
			});
		}
	}

	onChangeHandler() {
		this.ref.detectChanges();
	}

	submit = (): void => {
		this.visualProps.referenceLines = ObjectUtils.copy(this.newReferenceLines.slice(1));
		this.visualProps.timeReferenceLines = ObjectUtils.copy(this.newTimeReferenceLines.slice(1));
		this.visualProps.axisTitle = ObjectUtils.copy(this.axisTitle);
		this.close(this.visualProps);
	};

	showReferenceLines = (): boolean => {
		return this.input.widgetType !== WidgetType.METRIC;
	};

	showAddReferenceLineCheckbox = (): boolean => {
		return !this.referenceLinesExist() && !this.isBarOrLineWithTimeGrouping();
	};

	isDynamicReferenceBetaEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.DYNAMIC_REFERENCE_LINES);
	};

	showReferenceLineBuilder = (): boolean => {
		return this.ui.addLineCheckbox;
	};

	showTimeReferenceLineBuilder = (): boolean => {
		return this.ui.addTimeLineCheckbox;
	};

	private readonly referenceLinesExist = (): boolean => {
		return this.newReferenceLines?.length > 1;
	};

	addReferenceLine = (refLine: ReferenceLine): void => {
		this.newReferenceLines.push(refLine);
		this.newReferenceLines[0] = {} as ReferenceLine;
	};

	removeReferenceLine = (index: number): void => {
		this.newReferenceLines.splice(index, 1);
	};

	showCaseSettings = (): boolean => {
		return ((this.input.widgetType === WidgetType.LINE) ||
				(this.input.widgetType === WidgetType.BAR && this.visualProps.subChartType === ChartType.COLUMN)) &&
			this.isTimeGrouping() &&
			this.betaFeaturesService.isFeatureEnabled(BetaFeature.CASE_VIZ_ON_TRENDS);
	};

	updateReferenceLine = (index: number, newLineDefinition: ReferenceLine): void => {
		this.newReferenceLines[index] = newLineDefinition;
	};

	showDuplicateErrorMessage = (newLine: ReferenceLine, index: number): boolean => {
		if (_.isUndefined(newLine)) {
			return false;
		}
		const otherReferenceLines = this.newReferenceLines.slice(0, index).concat(this.newReferenceLines.slice(index + 1));
		const existingValues = _.filter(otherReferenceLines,
			(line: ReferenceLine) => this.referenceLineTypeExist(newLine, line)
				&& newLine.axis === line.axis);
		return !_.isUndefined(existingValues) && existingValues.length > 0;
	};

	referenceLineTypeExist = (newLine: ReferenceLine, existingLine: ReferenceLine): boolean => {
		return (newLine.value != null && newLine.value === existingLine.value)
			|| (newLine.dynamic && newLine.dynamic?.name === existingLine.dynamic?.name);
	};

	private readonly isScatter = (): boolean => {
		return this.input.widgetType === WidgetType.SCATTER;
	};

	showQuadrants = (): boolean => this.isScatter();

	//Time Reference Lines
	showAddMetricReferenceLineCheckbox = (): boolean => {
		return !this.referenceLinesExist() && this.isBarOrLineWithTimeGrouping();
	};

	showAddTimeReferenceLineCheckbox = (): boolean => {
		return !this.timeReferenceLinesExist() && this.isBarOrLineWithTimeGrouping();
	};

	private readonly timeReferenceLinesExist = (): boolean => {
		return this.newTimeReferenceLines && this.newTimeReferenceLines.length > 1;
	};

	isBarOrLineWithTimeGrouping = (): boolean => {
		return (this.input.widgetType === WidgetType.LINE || this.input.widgetType === WidgetType.BAR)
			&& this.isTimeGrouping();
	};

	isTimeGrouping = (): boolean => {
		const attributeSelections = this.visualProps.attributeSelections;

		return attributeSelections?.primaryGroup
			&& AnalyticMetricTypes.isTime(attributeSelections.primaryGroup);
	};

	addTimeReferenceLine = (refLine: TimeReferenceLine): void => {
		this.newTimeReferenceLines.push(refLine);
		this.newTimeReferenceLines[0] = {} as TimeReferenceLine;
	};

	updateTimeReferenceLine = (index: number, newLineDefinition: TimeReferenceLine): void => {
		this.newTimeReferenceLines[index] = newLineDefinition;
	};

	removeTimeReferenceLine = (index: number): void => {
		this.newTimeReferenceLines.splice(index, 1);
	};

	updateDateTitle = (title: string): void => {
		this.dateTitle = title;
	};

	private readonly formatAxisName = (metricName: string): string | undefined => {
		const metric: ReportCalculation = _.findWhere(this.input.selectedMetrics, {name: metricName});
		return metric?.displayName;
	};
}
