import { Component, OnInit, Input } from '@angular/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { downgradeComponent } from '@angular/upgrade/static';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CxLocaleService } from '@app/core';
import { TypeGuards } from '@app/util/typeguards.class';

enum axis_state {
	MIN_AUTOMATIC = 1,
	MIN_FIXED = 2,
	MAX_AUTOMATIC = 3,
	MAX_FIXED = 4
}

@Component({
	selector: 'an-axis-scale',
	templateUrl: './an-axis-scale.component.html'
})
export class AnAxisScaleComponent implements OnInit {

	@Input() visualProps: VisualProperties;
	@Input() prefix: string;
	@Input() label: string;
	@Input() locked: boolean;

	axisAutoMax: string;
	axisAutoMin: string;
	axisMin: string;
	axisMax: string;

	lastMinValue: number;
	lastMaxValue: number;

	minRadioOptions: UIOption<any>[];
	maxRadioOptions: UIOption<any>[];

	// unique values of the radio inputs are 1y, 2y, 1secondary, 2secondary
	autoMinVal: any;
	autoMaxVal: any;

	minFixedVal = '';
	maxFixedVal = '';

	constructor(private readonly locale: CxLocaleService) { }

	ngOnInit(): void {
		this.axisAutoMax = this.prefix + 'AxisAutoMax'; // this.prefix is y or x, so xAxisAutoMax or yAxisAutoMax
		this.axisAutoMin = this.prefix + 'AxisAutoMin'; // xAxisAutoMin or yAxisAutoMin

		// if (this.visualProps[yAxisAutoMax] is undefined or true)
		if (this.visualProps[this.axisAutoMax] === undefined || isTrue(this.visualProps[this.axisAutoMax])) {
			this.visualProps[this.axisAutoMax] = true;
		} else {
			// (this.visualProps[yAxisAutoMax] is false
			this.visualProps[this.axisAutoMax] = false;
		}

		if (this.visualProps[this.axisAutoMin] === undefined || isTrue(this.visualProps[this.axisAutoMin])) {
			this.visualProps[this.axisAutoMin] = true;
		} else {
			this.visualProps[this.axisAutoMin] = false;
		}

		if (isEmpty(this.visualProps.alignTicks) ) {
			this.visualProps.alignTicks = false;
		}

		this.axisMin = this.prefix + 'AxisMin';
		this.axisMax = this.prefix + 'AxisMax';

		this.minRadioOptions = [
			{
				value: axis_state.MIN_AUTOMATIC + this.prefix,
				displayName: this.locale.getString('widget.auto'),
				htmlId: this.getHTMLId(true, true)
			},
			{
				value: axis_state.MIN_FIXED + this.prefix,
				displayName: this.locale.getString('widget.fixed'),
				htmlId: this.getHTMLId(true, false)
			},
		];
		this.maxRadioOptions = [
			{
				value: axis_state.MAX_AUTOMATIC + this.prefix,
				displayName: this.locale.getString('widget.auto'),
				htmlId: this.getHTMLId(false, true)
			},
			{
				value: axis_state.MAX_FIXED + this.prefix,
				displayName: this.locale.getString('widget.fixed'),
				htmlId: this.getHTMLId(false, false)
			},
		];
		this.autoMinVal = this.visualProps[this.axisAutoMin] ? axis_state.MIN_AUTOMATIC + this.prefix: axis_state.MIN_FIXED + this.prefix;
		this.autoMaxVal = this.visualProps[this.axisAutoMax] ? axis_state.MAX_AUTOMATIC + this.prefix: axis_state.MAX_FIXED + this.prefix;

		this.minFixedVal = this.visualProps[this.axisMin] === undefined ?
			'' : this.visualProps[this.axisMin];
		this.maxFixedVal = this.visualProps[this.axisMax] === undefined ?
			'' : this.visualProps[this.axisMax];
	}

	changeMinVal = ($event: any) => {
		if ($event === this.autoMinVal) {
			return;
		}
		if (this.clickedTrue($event)) {
			this.visualProps[this.axisAutoMin] = true;
			this.lastMinValue = this.visualProps[this.axisMin];
			this.visualProps[this.axisMin] = undefined;
		} else {
			this.visualProps[this.axisAutoMin] = false;
			this.visualProps[this.axisMin] = this.lastMinValue;
		}
	};

	changeMaxVal = ($event: any) => {
		if ($event === this.autoMaxVal) {
			return;
		}
		if (this.clickedTrue($event, true)) {
			this.visualProps[this.axisAutoMax] = true;
			this.lastMaxValue = this.visualProps[this.axisMax];
			this.visualProps[this.axisMax] = undefined;
		} else {
			this.visualProps[this.axisAutoMax] = false;
			this.visualProps[this.axisMax] = this.lastMaxValue;
		}
	};

	private clickedTrue = (val: string, max = false) => {
		if (!max && (val === undefined || val.indexOf(axis_state.MIN_AUTOMATIC.toString()) === 0)) {
			return true;
		} else return max && ((val === undefined || val.indexOf(axis_state.MAX_AUTOMATIC.toString()) === 0));
	};

	private getHTMLId = (min, auto) => {
		return `${min ? 'min': 'max'}-${auto ? 'auto' : 'fixed'}_${this.label.split(' ').join('').replace(',','')}`;
	};

	validate = (maxChanged) => {
		let minValue = this.minFixedVal;
		let maxValue = this.maxFixedVal;
		if (!TypeGuards.isNumber(parseFloat(minValue))) {
			minValue = undefined;
		}
		if (!TypeGuards.isNumber(parseFloat(maxValue))) {
			maxValue = undefined;
		}

		if (minValue !== undefined && maxValue !== undefined && Number(minValue) > Number(maxValue)) {
			if (maxChanged) // change another value
				minValue = maxValue;
			else maxValue = minValue;
		}
		this.visualProps[this.axisMin] = minValue;
		this.visualProps[this.axisMax] = maxValue;
	};
}

app.directive('anAxisScale', downgradeComponent({component: AnAxisScaleComponent}));
