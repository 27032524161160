import { BookEvent } from '@app/core/cx-event.enum';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { BookListHelperService } from '@app/modules/book/book-list-helper.service';
import { BookValidationService } from '@cxstudio/dashboards/books/book-validation.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Book, BookDashboardTab, BookEmbedTab, BookTabType, IBookTab } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { HeaderConstants } from '@cxstudio/header/header-constants';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { IDashboardListController } from '@app/modules/dashboard-list/services/dashboard-grid-definition.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { BookEditTabController } from './book-edit-tab.component';
import { DashboardBookStateService } from './dashboard-book-state.service';


enum TabIndex {
	DISCOVER_DASHBOARDS = 0,
	XM_DASHBOARDS = 1
}

export class BookEditor implements ng.IController, IDashboardListController {
	// matches 'Tabs 123', but not any modification of it
	readonly DEFAULT_NAME_REGEX = new RegExp(`^${this.locale.getString('dashboard.tab', {index: '\\d+'})}$`);
	book: Book;
	visibleDashboards: Dashboard[]; // not used when NEW_TABLES beta is enabled

	protected currentTab: IBookTab;
	save: (book: Book) => void;
	cancel: () => void;
	createNew: () => void;
	private inputLength: { bookName: number; bookDescription: number; tabName: number };
	bookActionMenu: any[];

	gridMode = GridMode.EDIT;
	gridType = GridTypes.DASHBOARD;
	gridOptions: SlickgridOptions;
	lastChange: any[];

	private tooltipTimer: any;
	tooltipStyle = {
		top: 0,
		left: 0,
		visibility: 'hidden',
		opacity: 0
	};
	tooltipDashboard: Dashboard;
	loadingPromise: ng.IPromise<any>;

	currTabXMDashboardId: string;
	activeTabIndex: TabIndex;
	embedNameCheck: string = 'Embed';

	newTablesEnabled: boolean;

	private dashboardsSubscription: Subscription;

	constructor(
		private locale: ILocale,
		private $scope: ISimpleScope,
		private $rootScope: ng.IRootScopeService,
		private dashboardService: DashboardService,
		private bookValidationService: BookValidationService,
		private dashboardBookState: DashboardBookStateService,
		private $location: ng.ILocationService,
		private $timeout: ng.ITimeoutService,
		private bookListHelperService: BookListHelperService,
		private redirectService: RedirectService,
		private betaFeaturesService: BetaFeaturesService,
		private dashboardListService: DashboardListService,
		private downgradeDialogService: DowngradeDialogService,
		private globalUnloadService: GlobalUnloadService,
		private security: Security,
	) {}

	$onInit = () => {

		this.newTablesEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES);

		if (_.isEmpty(this.book.tabs)) {
			this.initTabs();
		}

		this.onTabSelect(this.book.tabs[0]);

		this.inputLength = {
			bookName: 100,
			bookDescription: 300,
			tabName: BookEditTabController.MAX_TAB_LENGTH
		};
		this.book.tabs.forEach(tab => tab.active = false);
		this.book.tabs[0].active = true;

		this.generateBookActionMenu();

		this.gridOptions = {
			onClick: this.onClick,
			onMouseEnter: this.onMouseEnter,
			onMouseLeave: this.onMouseLeave,
			onKeyDown: this.onKeyDown
		};

		if (this.currentTab && this.currentTab.type === BookTabType.EMBED) {
			let tabData = this.currentTab.properties as BookEmbedTab;
			this.currTabXMDashboardId = tabData.embedDashboardId;
			this.activeTabIndex = TabIndex.XM_DASHBOARDS;
		}
		this.$rootScope.commandPaletteHidden = false;

		this.globalUnloadService.addHandler('book-edit', () => this.checkForChanges());

		this.dashboardsSubscription = this.dashboardListService.getDashboards().subscribe((dashboards) => {
			this.initDashboards(dashboards);
		});
	};

	private initDashboards(dashboards: Dashboard[]): void {
		this.visibleDashboards = ObjectUtils.copy(
			_.filter(dashboards, dash => dash.type !== DashboardType.BOOK));
		let updated = this.bookListHelperService.markBookDisallowedDashboards(this.book, this.visibleDashboards);
		this.refreshGrid(updated);
		if (this.currentTab && this.currentTab.type === BookTabType.DASHBOARD) {
			let tabData = this.currentTab.properties as BookDashboardTab;
			this.bookListHelperService.markConnectedDashboard(tabData?.dashboardId, this.visibleDashboards, this.refreshGrid);
			this.activeTabIndex = TabIndex.DISCOVER_DASHBOARDS;
		}

	}

	private initTabs = () => {
		this.book.tabs = [];
		this.book.tabs.push({
			name: this.locale.getString('dashboard.tab', {index: 1})
		} as IBookTab);

	};

	onTabSelect = (tab: IBookTab): void => {
		if (this.currentTab === tab)
			return;

		this.currentTab = tab;
		if (this.currentTab) {
			if (this.currentTab.type === BookTabType.DASHBOARD) {
				// unlink embed string
				let tabData = this.currentTab.properties as BookDashboardTab;
				this.currTabXMDashboardId = '';
				this.bookListHelperService.markConnectedDashboard(tabData.dashboardId, this.visibleDashboards, this.refreshGrid);
				this.activeTabIndex = TabIndex.DISCOVER_DASHBOARDS;
			} else if (this.currentTab.type === BookTabType.EMBED) {
				// unlink dashboard highlights
				this.bookListHelperService.markConnectedDashboard(undefined, this.visibleDashboards, this.refreshGrid);
				let tabData = this.currentTab.properties as BookEmbedTab;
				this.currTabXMDashboardId = tabData.embedDashboardId;
				this.activeTabIndex = TabIndex.XM_DASHBOARDS;
			} else {
				this.bookListHelperService.markConnectedDashboard(undefined, this.visibleDashboards, this.refreshGrid);
				this.activeTabIndex = TabIndex.DISCOVER_DASHBOARDS;
			}
		}
	};

	getTabDashboard = (tab: IBookTab): Dashboard => {
		if (!tab || tab.type !== BookTabType.DASHBOARD) {
			return undefined;
		}
		return _.findWhere(this.dashboardListService.getCurrentDashboardsList(), {id: (tab.properties as BookDashboardTab).dashboardId});
	};

	getCurrentTab = () => {
		return this.book.tabs.find(tab => tab.active);
	};

	onSave = () => {
		this.saveBook(this.book).then((updated) => {
			_.extend(this.book, updated);
			this.globalUnloadService.removeHandler('book-edit');
			this.save(this.book);
		});
	};

	onCancel = () => {
		if (this.isBookChanged()) {
			this.showUnsavedBookDialog().then((save) => {
				if (save) {
					this.onSave();
				} else {
					this.globalUnloadService.removeHandler('book-edit');
					this.cancel();
				}
			}).catch(() => {});
		} else {
			this.cancel();
		}

	};

	private saveBook(dashboard: Dashboard): Promise<Dashboard> {
		this.book.tabs = _.filter(this.book.tabs, (tab): boolean => !_.isUndefined(tab.properties) );
		this.loadingPromise = this.dashboardService.saveDashboard(dashboard);
		return PromiseUtils.wrap(this.loadingPromise);
	}

	private readonly isBookChanged = () => {
		const currentDashboard: Partial<Book> = this.book;
		const oldDashboard: Partial<Book> = _.findWhere(this.dashboardListService.getCurrentDashboardsList(), { id: currentDashboard.id });

		// New book case
		if (!oldDashboard && !currentDashboard.id) {
			const nonEmptyTabs = _.filter(currentDashboard.tabs, (tab: IBookTab) => !!tab.properties);
			return nonEmptyTabs.length > 0; // leave without a warning if user hasn't added any dashboard
		}

		if (!oldDashboard) {
			return false;
		}

		if (currentDashboard.name !== oldDashboard.name
			|| currentDashboard.description !== oldDashboard.description) {
			return true;
		}

		let oldValues = {};
		let newValues = {};
		_.each(['name', 'dashboardId'], (field) => {
			oldValues[field] = _.map(oldDashboard.tabs, field);
			newValues[field] = _.map(currentDashboard.tabs, field);
		});
		return !_.isEqual(oldValues, newValues); // checks tab dashboards and names
	};

	private readonly showUnsavedBookDialog = (): Promise<boolean> => {
		const disableSave = this.bookValidationService.hasValidationWarnings(this.book,
			this.dashboardListService.getCurrentDashboardsList());

		return this
			.downgradeDialogService
			.showUnsavedChangesDialog(
				'dashboard.unsavedBookHeader',
				'dashboard.unsavedBookChanges',
				undefined,
				disableSave
			)
		;
	};

	onShare = () => {
		this.dashboardService.shareDashboard(this.book).then((changedDashboards) => {
			this.dashboardListService.updateDashboardsOld(changedDashboards);
		});
	};

	canShare = () => {
		if (!this.book.id) {
			return false; // new book
		}
		return this.dashboardService.canShare(this.book);
	};

	selectTab = (tab: IBookTab) => {
		this.book.tabs.forEach(aTab => aTab.active = false);
		tab.active = true;

		this.onTabSelect(tab);
	};

	onDashboardSelected = (dashboard: Dashboard) => {
		let changeNameRequired = this.isNameChangeRequired();
		let currentTab = this.getCurrentTab();
		currentTab.type = BookTabType.DASHBOARD;
		currentTab.properties = {
			dashboardId: dashboard.id
		};
		if (changeNameRequired)
			currentTab.name = this.trimString(dashboard.name, this.inputLength.tabName);
		this.$scope.$broadcast('book:scrollToActiveTab'); // it may go outside scroll due to name change
	};

	private isNameChangeRequired(): boolean {
		let currentTab = this.getCurrentTab();
		// default tab name
		if (!currentTab.name || currentTab.name.trim() === '')
			return true;
		if (this.DEFAULT_NAME_REGEX.test(currentTab.name))
			return true;
		if (currentTab.type === BookTabType.EMBED) {
			return true;
		}
		if (currentTab.type === BookTabType.DASHBOARD) {
			let tabData = currentTab.properties as BookDashboardTab;
			let previouslySelectedDashboard: Dashboard = _.findWhere(this.dashboardListService.getCurrentDashboardsList(),
				{id: tabData?.dashboardId});
			if (previouslySelectedDashboard) {
				// if previous was the name of dashboard
				if (currentTab.name === this.trimString(previouslySelectedDashboard.name, this.inputLength.tabName)) {
					return true;
				}
			} else {
				// original name not available, but dashboard selected
				if (tabData) {
					return true;
				}
			}
		}
		return false;
	}

	private checkForChanges(): Promise<void> {
		if (this.isBookChanged())
			return this.showUnsavedChanges();
		else return Promise.resolve();
	}

	private showUnsavedChanges(): Promise<any> {
		return this.showUnsavedBookDialog().then((save) => {
			if (save) {
				return this.saveBook(this.book);
			}
			return undefined;
		});
	}

	trimString = (value, maxLength) => {
		let postfix = '...';
		return value.length > maxLength
			? (value.substring(0, maxLength - postfix.length) + postfix)
			: value;
	};

	bookNameInvalid = () => {
		return this.bookValidationService.bookNameInvalid(this.book);
	};

	bookNameExists = () => {
		return this.bookValidationService.bookNameExists(this.book, this.dashboardListService.getCurrentDashboardsList());
	};

	getBookNameError = () => {
		if (this.bookNameInvalid())
			return this.locale.getString('dashboard.emptyBookName');
		if (this.bookNameExists())
			return this.locale.getString('dashboard.notUniqueBookName');
		return '';
	};

	hasValidationWarnings = () => {
		return this.bookValidationService.hasValidationWarnings(this.book, this.dashboardListService.getCurrentDashboardsList());
	};

	canDelete = () => {
		return _.isUndefined(this.book.permissions) || this.book.permissions.OWN;
	};

	copyAndShowBook = (book: Book) => {
		this.dashboardService.copyDashboard(book).then((newBook: Book) => {
			this.dashboardBookState.resetEditedFromBook();
			this.$location.path('/dashboard/' + newBook.id);
		});
	};

	shareBook = () => {
		this.dashboardService.shareDashboard(this.book);
	};

	private getDivider(): any {
		return {
			...MenuDivider,
			onClick: (dashboard: Dashboard, event: any) => {
				event.stopPropagation();
			}
		};
	}

	generateBookActionMenu = () => {
		this.bookActionMenu = [];

		this.bookActionMenu.push({
			text: this.locale.getString('dashboard.editProps'),
			name: 'edit_properties',
			onClick: (dashboard: Book) => {
				this.dashboardService.editBookProperties(dashboard);
			}
		});

		this.bookActionMenu.push(this.getDivider());

		this.bookActionMenu.push({
			text: this.locale.getString('dashboard.duplicate'),
			name: 'copy',
			onClick: (book: Book) => {
				this.checkForChanges().then(() => this.copyAndShowBook(book), _.noop);
			}
		});

		this.bookActionMenu.push({
			text: this.locale.getString('dashboard.newBook'),
			name: 'create',

			onClick: () => {
				this.checkForChanges().then(() => {
					if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES)) {
						this.createNew();
					} else {
						this.dashboardBookState.resetState();
						this.dashboardBookState.isCreateBook = true;
						this.initTabs();
						this.selectTab(this.book.tabs[0]);
						this.createNew();
					}
				}, _.noop);
			}
		});

		if (this.canShare()) {
			this.bookActionMenu.push(this.getDivider());

			this.bookActionMenu.push({
				text: this.locale.getString('dashboard.dashboardShare'),
				name: 'share',
				onClick: this.shareBook
			});
		}

		if (this.canDelete()) {
			this.bookActionMenu.push(this.getDivider());

			this.bookActionMenu.push({
				text: this.locale.getString('dashboard.deleteBook'),
				name: 'delete',
				onClick: (dashboard: Book) => {
					this.dashboardService.removeDashboard(dashboard).then( () => {
						this.dashboardBookState.resetState();
						this.cancel();
						this.redirectService.goToDashboardList();
					});
				}
			});
		}
	};

	private onClick = (event: JQuery.MouseDownEvent, object) => {
		this.handleCellAction(object);
	};

	private onKeyDown = (event: KeyboardEvent, object, args?) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && (!args || args.row !== -1)) {
			this.handleCellAction(object, args);
			event.stopImmediatePropagation();
		}
	};

	private handleCellAction = (object: Dashboard, args?) => {
		if (BookListHelperService.isBookAllowedItem(this.book, object, this.security.getEmail())) {
			this.onDashboardSelected(object);
			this.bookListHelperService.markConnectedDashboard(object.id, this.visibleDashboards, this.refreshGrid);
		}
		this.focusCell(args);
	};

	private refreshGrid = (items?) => {
		this.lastChange = [].concat(items);
	};

	private focusCell = (args) => {
		if (!args) return;
		setTimeout(() => {
			this.$scope.$broadcast('focusGridCell', args.row, args.cell);
		});
	};

	private onMouseEnter = (e, dashboard) => {
		if (this.tooltipTimer) {
			this.$timeout.cancel(this.tooltipTimer);
		}
		if ($(e.target).hasClass('cell-title')) {
			this.tooltipTimer = this.$timeout(() => this.showTooltip(e, dashboard), 300);
		}
	};

	private showTooltip = (e: JQuery.MouseEnterEvent, dashboard: Dashboard) => {
		if (dashboard.type === DashboardType.FOLDER)
			return;

		if (this.tooltipDashboard !== dashboard) {
			this.$timeout.cancel(this.tooltipTimer);
		}
		this.tooltipTimer = this.$timeout(() => {
			this.tooltipDashboard = dashboard;
			this.positionTooltip(e);
		}, 200);
	};

	private positionTooltip = (e: JQuery.MouseEnterEvent) => {
		let target = $(e.target).parents('.slick-row');
		if (!target.length)
			target = $(e.target);
		this.tooltipStyle = {
			top: target.offset().top - HeaderConstants.PRIMARY_HEADER_HEIGHT,
			left: e.pageX,
			visibility: 'visible',
			opacity: 1
		};
	};

	private onMouseLeave = () => {
		this.$timeout.cancel(this.tooltipTimer);
		this.tooltipStyle = {
			top: 0,
			left: 0,
			visibility: 'hidden',
			opacity: 0
		};
	};

	isBookEdit(): boolean {
		return true;
	}

	onTabClick = (tab: IBookTab) => {
		if (tab.type === BookTabType.DASHBOARD) {
			let tabData = (tab.properties as BookDashboardTab);
			if (tabData?.dashboardId)
				this.$rootScope.$broadcast(BookEvent.SCROLL_TO_DASHBOARD, tabData.dashboardId);
		}
	};

	showEmbeddedDashboardsTab = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_DASHBOARDS)
			&& this.security.isQualtricsDashboardEmbeddingEnabled();
	};

	allowConfigurationEditing = () => {
		return this.currentTab.type === BookTabType.EMBED
			? this.security.isQualtricsDashboardEmbeddingEnabled()
			: true;
	};

	getConfigurationEditingError = () => {
		return this.currentTab.type === BookTabType.EMBED
			? this.locale.getString('dashboard.qualtricsEmbeddingDisabled')
			: '';
	};

	saveEmbedDashboardId = (event: string) => {
		let currentTab = this.getCurrentTab();
		let changeNameRequired = this.isNameChangeRequired();

		currentTab.type = BookTabType.EMBED;
		if (changeNameRequired) {
			currentTab.name = this.locale.getString('dashboard.embedTabName');
		}
		currentTab.properties = {
			embedDashboardId: event
		} as BookEmbedTab;

		this.currTabXMDashboardId = currentTab.properties?.embedDashboardId;
	};

	openEmbeddingTab = () => {
		let currentTab = this.getCurrentTab();
		this.currTabXMDashboardId = (currentTab.properties as BookEmbedTab)?.embedDashboardId;
	};

	$onDestroy(): void {
		this.globalUnloadService.removeHandler('book-edit');
		this.dashboardsSubscription?.unsubscribe();
	}
}

app.component('bookEditor', {
	bindings: {
		book: '<',
		save: '&',
		cancel: '&',
		createNew: '&',
	},
	templateUrl: 'partials/dashboards/tabs/book-editor.component.html',
	controller: BookEditor
});
