import * as _ from 'underscore';
import { Component, EventEmitter, Inject, Input, OnInit, Output,
	SimpleChanges, ChangeDetectionStrategy, OnChanges } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { UserEditFormData } from '../editor/user-edit-form-data';
import { ContentProviderAccess } from '@cxstudio/user-administration/users/project-access/content-provider-access-class';
import { IProjectsAccess } from './project-access-interface';
import { ProjectAccessProvider } from './project-access-provider.service';
import { ProjectAccessResponse } from '@cxstudio/user-administration/users/project-access/projects-access-response-class';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { ProjectAccessLevel } from '@cxstudio/user-administration/users/project-access/project-access-level-class';
import { ContentProviderWithAccounts } from '@app/modules/user-administration/user-management-helper.service';
import { ProjectAccessDataHelperService } from '@app/modules/user-administration/projects-access/project-access-data-helper.service';
import { LicenseLevel } from '@cxstudio/common/license-levels';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { AccountAccessData } from './account-access-data';
import { ChangeUtils } from '@app/util/change-utils';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { CacheOptions } from '@cxstudio/common/cache-options';


export interface CpAccountSelection {
	cpId: number;
	accountId: number;
}

export interface AccessModel {
	cp: ContentProvider;
	account: ContentProviderAccount;
	accountAccessData: AccountAccessData;
}

//WORKSPACE could be deleted after GA
@Component({
	selector: 'projects-access',
	templateUrl: './projects-access.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ProjectsAccessComponent implements OnInit, OnChanges {
	@Input() user: UserEditFormData;
	@Input() license: LicenseTypeItem;

	@Input() licenses: any[];
	@Input() allowDisabled: boolean;
	@Input() contentProvidersWithAccounts: ContentProviderWithAccounts[];
	@Input() sourceUserId?: number;

	@Output() changeProjectAccess = new EventEmitter<ContentProviderAccess[]>();

	dropdownsSelection: CpAccountSelection = {
		cpId: undefined,
		accountId: undefined
	};

	accessModel: AccessModel = {
		cp: undefined,
		account: undefined,
		accountAccessData: {
			accountAdmin: false,
			projects: [],
			changed: false
		}
	};

	editorAccess: IProjectsAccess;
	editorErrors: any[] = [];

	targetAccess: IProjectsAccess;
	targetErrors: any[] = [];

	//for spinner
	projectsLoading: Promise<any>;

	private accessLevels: {[value: string]: ProjectAccessLevel};

	private analyzeAccessOptions: ProjectAccessLevel[];
	private cxStudioAccessOptions: ProjectAccessLevel[];
	private disabledAccessOptions: ProjectAccessLevel[];

	constructor(
		private locale: CxLocaleService,
		private projectAccessProvider: ProjectAccessProvider,
		private projectAccessDataHelperService: ProjectAccessDataHelperService,
		@Inject('ProjectAccessLevels') private ProjectAccessLevels: ProjectAccessLevelItems,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService
	) {}

	ngOnInit(): void {
		this.editorAccess = this.projectAccessProvider.getInstance();
		this.targetAccess = this.projectAccessProvider.getInstance();

		this.accessLevels = this.ProjectAccessLevels.valuesAsMap();
		this.analyzeAccessOptions = [this.accessLevels.MANAGER, this.accessLevels.VIEWER, this.accessLevels.NONE];
		this.cxStudioAccessOptions = [this.accessLevels.VIEWER, this.accessLevels.NONE];
		this.disabledAccessOptions = [this.accessLevels.MANAGER, this.accessLevels.VIEWER, this.accessLevels.CUSTOM];

		this.init();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.license)) {
			this.targetAccess.setLite(!this.isAdminLevelLicense(changes.license.currentValue.licenseTypeId));
			this.accessLevelCallback();
		}
	}

	init(): void {
		let loadProjectsPromise = this.projectsLoading = this.processProjectAccessPromises(this.getEditorProjectsAccessPromise);
		let loadProjectAccessPromise = this.processProjectAccessPromises(this.getTargetProjectsAccessPromise);

		this.projectsLoading = Promise.all([loadProjectsPromise, loadProjectAccessPromise]);

		this.projectsLoading.then(responses => {
			this.processProjectsResponse(responses[0]);
			this.processProjectsAccessResponse(responses[1]);
			this.preselectCpAndAccount();
		});
	}

	private updateDropdownsSelection(): void {
		this.dropdownsSelection.cpId = this.accessModel.cp.id;
		this.dropdownsSelection.accountId = this.accessModel.account.accountId;
	}

	private processProjectsResponse(result: ProjectAccessResponse) {
		this.editorErrors = result.errors;
		this.editorAccess.withAccessMap(result.data);

		this.targetAccess.withContentProvidersMap(result.data, !this.isAdminLevelLicense());
	}

	private processProjectsAccessResponse(result: ProjectAccessResponse) {
		this.targetErrors = result.errors;
		this.targetAccess.merge(result.data);
	}

	preselectCpAndAccount(): void {
		let cps = this.contentProvidersWithAccounts;
		let cp = cps[0];
		if (!cp) {
			return;
		}
		let account = cp.accounts[0];
		this.accessModel.cp = cp;
		this.accessModel.account = account;
		this.accessModel.accountAccessData = this.targetAccess.getAccountData(
			cp.id, account.accountId);
		this.updateDropdownsSelection();
		this.accessLevelCallback();
	}

	onCpChange() {
		this.accessModel.cp = _.findWhere(this.contentProvidersWithAccounts, {id: this.dropdownsSelection.cpId});
		this.updateAccounts();
	}

	onAccountChange() {
		this.accessModel.account = _.findWhere(this.accessModel.cp.accounts, {accountId: this.dropdownsSelection.accountId});
		this.updateProjects();
	}

	updateAccounts(): void {
		let cp = this.accessModel.cp;
		let account = cp.accounts[0];
		this.accessModel.account = account;
		this.updateDropdownsSelection();
		this.updateProjects();
	}

	updateProjects(): void {
		this.accessModel.accountAccessData = this.targetAccess.getAccountData(
			this.accessModel.cp.id, this.accessModel.account.accountId);
	}

	isEditorAccountAdmin(): boolean {
		if (!this.accessModel.cp || !this.accessModel.account) {
			return false;
		}
		let cpId = this.accessModel.cp.id;
		let accountId = this.accessModel.account.accountId;
		let accountData = this.editorAccess.getAccountData(cpId, accountId);
		return accountData.accountAdmin;
	}

	isLinkedToMultipleContentProviders(): boolean {
		return this.contentProvidersWithAccounts.length > 1;
	}

	isLinkedToMultipleAccountsInContentProvider(contentProvider: ContentProvider): boolean {
		return contentProvider.accounts.length > 1;
	}

	isDisabledOption(projectId: number, accessLevel): boolean {
		if (!this.isAdminLevelLicense()
				&& accessLevel === this.accessLevels.MANAGER.value) {
			return true;
		}

		let editorLevel = this.getEditorProjectLevel(projectId);
		let targetLevel = this.ProjectAccessLevels.findByValue(accessLevel);
		return !this.editorHasOption(editorLevel, targetLevel);
	}

	getActiveAccessValues(projectId: number): any[] {
		let options = this.isAdminLevelLicense() ? this.analyzeAccessOptions : this.cxStudioAccessOptions;
		let editorLevel = this.getEditorProjectLevel(projectId);
		options = _.filter(options, (option) => {
			return this.editorHasOption(editorLevel, option);
		});
		return options;
	}

	getDisabledAccessValues(): any[] {
		return this.disabledAccessOptions;
	}

	grantAccountAdmin(value: boolean): void {
		this.accessModel.accountAccessData.accountAdmin = value;

		if (this.accessModel.accountAccessData.accountAdmin) {
			this.changeProjectsAccessLevel(
				this.accessModel.accountAccessData.projects,
				this.analyzeAccessOptions[0].value);
		}

		this.accessLevelChangedCallback();
	}

	projectAccessChanged(): void {
		this.accessLevelChangedCallback();
	}

	getError(): string {
		if (!this.accessModel.cp && !this.accessModel.account) {
			return;
		}

		let cpId = this.accessModel.cp.id;
		let accountId = this.accessModel.account.accountId;
		if (this.editorErrors[cpId] && this.editorErrors[cpId][accountId]) {
			return this.editorErrors[cpId][accountId];
		}
		if (this.targetErrors[cpId] && this.targetErrors[cpId][accountId]) {
			return this.targetErrors[cpId][accountId];
		}
	}

	hasMarkAllAdmin(): boolean {
		return this.isAdminLevelLicense();
	}

	markAllAdmin(): void {
		if (!this.isAdminLevelLicense()) {
			return;
		}
		this.markAll(this.accessLevels.MANAGER.value);
	}

	markAllReadOnly(): void {
		this.markAll(this.accessLevels.VIEWER.value);
	}

	markAllNoAccess(): void {
		this.markAll(this.accessLevels.NONE.value);
	}

	private accessLevelChangedCallback(): void {
		this.accessModel.accountAccessData.changed = true;
		this.accessLevelCallback();
	}

	private accessLevelCallback(): void {
		this.changeProjectAccess.emit(this.targetAccess.getData());
	}

	private getTargetProjectsAccessPromise = (cp: ContentProvider): any => {
		let promise;
		let accountIds = this.getSelectedAccountsIds(cp, 'update');
		if (accountIds.length > 0) {
			let targetUserId = this.sourceUserId || this.user.userId;
			promise = this.contentProviderOptionsService.getProjectsAccessLevel(
				cp.id, accountIds, targetUserId, this.allowDisabled, CacheOptions.NOT_CACHED);
		} else {
			promise = Promise.resolve();
		}
		return promise;
	};

	private getEditorProjectsAccessPromise = (cp): any => {
		let addedAccounts = this.getSelectedAccountsIds(cp, 'add');
		let updatedAccounts = this.getSelectedAccountsIds(cp, 'update');
		let accountIds = [].concat(addedAccounts).concat(updatedAccounts);

		return this.projectAccessDataHelperService.getCurrentUserProjectAccess(cp.id, accountIds);
	};

	private getErrorsByAccounts(errorsList): any {
		if (!errorsList) {
			return;
		}
		let errors = {};
		errorsList.map((error) => {
			let accountId = error.parameters.accountId;
			let message;
			if (error.errorCode === 'notLocalized') {
				message = error.parameters.message;
			} else {
				message = this.locale.getString(
					'contentProvider.' + error.errorCode);
			}
			errors[accountId] = message;
		});
		return errors;
	}

	processProjectAccessPromises(promiseFunction): any {
		let contentProviders = this.contentProvidersWithAccounts;
		if (!contentProviders) {
			return;
		}

		let data = {};
		let errors = {};
		function updateAccounts(cp, accounts): void {
			let cpAccounts = data[cp.id];
			if (!cpAccounts) {
				cpAccounts = [];
			}
			cpAccounts = cpAccounts.concat(accounts);
			data[cp.id] = cpAccounts;
		}
		let promises = contentProviders.map((cp) => {
			return promiseFunction(cp).then((accountsResponse) => {
				if (!accountsResponse) {
					return;
				}
				let responseData = accountsResponse.data;
				errors[cp.id] = this.getErrorsByAccounts(responseData.errors);
				let accounts = responseData.data;
				updateAccounts(cp, accounts);
			});
		});

		return Promise.all(promises).then(() => {
			return {
				data,
				errors
			};
		});
	}

	private getSelectedAccountsIds(contentProvider, state: string): number[] {
		let result = [];
		let accounts = contentProvider.accounts;
		for (const account of accounts) {
			if (account.state === state) {
				result.push(account.accountId);
			}
		}
		return result;
	}

	private getEditorProjectLevel(projectId: number): ProjectAccessLevel {
		let cpId = this.accessModel.cp.id;
		let accountId = this.accessModel.account.accountId;
		let projectData = this.editorAccess.getProjectData(
			cpId, accountId, projectId);

		return this.ProjectAccessLevels.findByValue(projectData.accessLevel);
	}

	private isAdminLevelLicense(licenseTypeId?: number): boolean {
		let license: any = this.getLicense(licenseTypeId);
		return license?.licenseLevel === LicenseLevel.ADMIN;
	}

	private getLicense(licenseTypeId?: number): any {
		let licenseId = licenseTypeId ? licenseTypeId : this.user.licenseTypeId;
		return _.findWhere(this.licenses, { licenseTypeId: licenseId });
	}

	private editorHasOption(editorLevel, targetLevel): boolean {
		if (targetLevel.value === this.accessLevels.CUSTOM.value) {
			return false;
		}
		return targetLevel.level >= editorLevel.level;
	}

	private changeProjectsAccessLevel(projects, accessLevel): void {
		for (const project of projects) {
			project.accessLevel = accessLevel;
		}
	}

	private markAll(value): void {
		if (this.accessModel.accountAccessData.accountAdmin) {
			return;
		}

		let newLevel = this.ProjectAccessLevels.findByValue(value);
		_.each(this.accessModel.accountAccessData.projects, (projectData) => {
			let editorLevel = this.getEditorProjectLevel(projectData.project.id);
			let currentLevel = this.ProjectAccessLevels.findByValue(projectData.accessLevel);
			if (this.editorHasOption(editorLevel, currentLevel)
					&& this.editorHasOption(editorLevel, newLevel)) {
				projectData.accessLevel = value;
			}
		});
		this.accessLevelChangedCallback();
	}

}

app.directive('projectsAccess', downgradeComponent({component: ProjectsAccessComponent}));
