import { AbstractControl, UntypedFormGroup, ValidationErrors } from '@angular/forms';


export class FormValidationService {
	private formGroup: UntypedFormGroup;

	constructor(formGroup: UntypedFormGroup) {
		this.formGroup = formGroup;
	}
	
	updateControlErrors(controlName: string, errorName: string, hasError: boolean): void {
		let control: AbstractControl = this.formGroup.controls[controlName];
		let controlErrors: ValidationErrors = this.formGroup.controls[controlName].errors || {};

		if (hasError) {
			controlErrors[errorName] = hasError;
			control.setErrors(controlErrors);
		} else {
			if (!_.isEmpty(controlErrors)) {
				controlErrors = _.omit(controlErrors, errorName);
				control.setErrors(_.isEmpty(controlErrors) ? null : controlErrors);
			}
		}
	}

	hasError(controlName: string, errorName: string) {
		return this.formGroup.controls[controlName]?.hasError(errorName);
	}

	isPristine(controlName: string) {
		return this.formGroup.controls[controlName]?.pristine;
	}

}