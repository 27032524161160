import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetLinkingItem } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-linking-action';

export type ZoomLevel = 50 | 75 | '';

// basically used to make layout values available across scopes
export class LayoutHelper {

	zoomLevel: ZoomLevel;
	pushing: boolean;
	pushOnDrop: boolean;
	scrollbarOffset: number;
	heightLimit: number;
	gridsterHeight: number;
	linkingState: WidgetLinkingItem[];
	linkingSource: Widget; // widget id which started linking

	resizing: boolean;
	dragging: boolean;
	floating: boolean;

	constructor(private sessionPreferences: SessionPreferencesService) {
		this.reset();
	}

	reset = (): void => {
		let preferences = this.sessionPreferences.get('dashboardEditor') || {};

		this.zoomLevel = '';
		this.pushOnDrop = preferences.pushOnDrop;
		this.scrollbarOffset = 0;
		this.heightLimit = null;
		this.gridsterHeight = 0;

		this.linkingSource = null;
		this.resizing = false;
		this.dragging = false;
	};

	changeZoomLevel = (zoomLevel: ZoomLevel, callBack?: (zoomLevel: ZoomLevel) => void): void => {
		this.zoomLevel = zoomLevel;
		if (callBack) {
			callBack(zoomLevel);
		}
	};

	isZoomModeOn(): boolean {
		return this.zoomLevel !== '' && this.zoomLevel < 100;
	}
}

app.service('layoutHelperService', LayoutHelper);
