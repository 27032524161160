import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class IntelligentScoringService {
	constructor(
		@Inject('security') private security: Security,
		private betaFeaturesService: BetaFeaturesService,
	) { }

	isAvailable = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	};

	isRebuttalAvailable = (): boolean => {
		return this.isAvailable() && this.security.has('rebut_scorecards');
	};
}

app.service('intelligentScoringService', downgradeInjectable(IntelligentScoringService));
