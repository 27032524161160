import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import MasterAccountExpirationData from '@app/modules/system-administration/master-account/entities/master-account-expiration-data';
import { MasterAccountExpirationMode } from '@app/modules/system-administration/master-account/entities/master-account-expiration-mode';
import { NgbDateAdapter, NgbDateStruct, NgbDatepicker, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { AccessExpirationOption } from '@cxstudio/user-administration/users/access-expiration/access-expiration-option.service';

export interface MasterAccountExpirationDialogInput {
	masterAccount: MasterAccount;
}

@Component({
	selector: 'master-account-expiration-dialog',
	templateUrl: './master-account-expiration-dialog.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})

export class MasterAccountExpirationDialogComponent implements OnInit {
	@Input() input: MasterAccountExpirationDialogInput;

	masterAccount: MasterAccount;

	minDate: NgbDateStruct;
	maxDate: NgbDateStruct;

	expirationDate: Date;
	expirationMode: MasterAccountExpirationMode;

	expirationOptions: UIOption<MasterAccountExpirationMode>[];

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('accessExpirationOption') private accessExpirationOption: AccessExpirationOption,
	) {
	}

	ngOnInit(): void {
		this.expirationOptions = [{
			value: MasterAccountExpirationMode.NEVER_EXPIRE,
			displayName: this.locale.getString('mAccount.neverExpireOption')
		}, {
			value: MasterAccountExpirationMode.EXPIRE_ON_DATE,
			displayName: this.locale.getString('mAccount.expireOnDateOption')
		}];

		this.masterAccount = cloneDeep(this.input.masterAccount);
		this.expirationMode = this.masterAccount.expirationSettings?.expirationMode || MasterAccountExpirationMode.NEVER_EXPIRE;

		let expirationDate = this.masterAccount.expirationSettings?.expirationDate;
		this.expirationDate = moment().isAfter(expirationDate)
			? moment(expirationDate).add(this.accessExpirationOption.values().DAYS_90.period, 'days').toDate()
			: moment(expirationDate).toDate();
	}

	changeHandler = (expirationMode: MasterAccountExpirationMode): void => {
		this.expirationMode = expirationMode;
	};

	isExpireOnDateSelected(): boolean {
		return this.expirationMode === MasterAccountExpirationMode.EXPIRE_ON_DATE;
	}

	hasWrongSelection(): boolean {
		return this.isExpireOnDateSelected() && _.isUndefined(this.expirationDate);
	}

	save(): void {
		this.modal.close(new MasterAccountExpirationData(this.expirationMode, this.expirationDate));
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
