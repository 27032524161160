import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as moment from 'moment';
import { LoginHistoryService } from './login-history.service';
import { CxLocaleService } from '@app/core';
import { Moment } from 'moment';

export interface LoginItems {
	userTime?: string;
	ipAddress?: string;
	geoLocation?: any;
	userDate?: Moment;
}
@Component({
	selector: 'login-history',
	templateUrl: './login-history.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginHistoryComponent implements OnInit {

	sortDescending: boolean;
	loading: any;
	loginItems: LoginItems[];

	constructor(
		private readonly loginHistoryService: LoginHistoryService,
		private readonly locale: CxLocaleService
	) { }

	ngOnInit() {
		this.sortDescending = true;
		this.loading = this.loginHistoryService.getLoginHistory().then((response: LoginItems[]) => {
			// Our ng9 wrapper accounts for data return on response
			let items = response;
			this.loginItems = _.forEach(items, (item: LoginItems) => {
				item.userDate = moment(item.userTime); // required for sorting
			});
			this.sortLoginItems();
		});
	}

	getLocation = (location): string => {
		if (!location || !location.country) {
			return this.locale.getString('header.locationNA');
		}
		let array = [];
		if (location.city) {
			array.push(location.city);
		}
		if (location.state && location.state !== location.city) {
			array.push(location.state);
		}
		array.push(location.country);
		return array.join(', ');
	};

	sortLoginItems = (): void => {
		if (this.sortDescending) {
			this.loginItems.sort((a, b) => +new Date(b.userTime) - +new Date(a.userTime));
		} else {
			this.loginItems.sort((a, b) => +new Date(a.userTime) - +new Date(b.userTime));
		}
	};

	toggleSortOrder = (): void => {
		this.sortDescending = !this.sortDescending;
		this.sortLoginItems();
	};
}

app.directive('loginHistory', downgradeComponent({ component: LoginHistoryComponent }));
