import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import {
	ColorPalettesApi
} from '@app/modules/account-administration/appearance/color-palettes/api/color-palettes-api.service';
import {
	ColorPalette
} from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { NameService } from '@cxstudio/common/name-service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import {
	ColorPaletteStoreService
} from '@app/modules/account-administration/appearance/color-palettes/services/color-palette-store.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { PromiseUtils } from '@app/util/promise-utils';
import {
	ColorPaletteReplaceComponent
} from '@app/modules/account-administration/appearance/color-palette-replace/color-palette-replace.component';

@Injectable({
	providedIn: 'root'
})
export default class ColorPaletteActions {
	loading: Promise<unknown>;
	readonly MAX_PALETTES = 20;

	constructor(
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly colorPalettesApi: ColorPalettesApi,
		private readonly colorPaletteStore: ColorPaletteStoreService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('nameService') private readonly nameService: NameService,
	) {
	}

	public createPalette = (): void => {
		if (this.limitReached(this.colorPaletteStore.getColorPalettes())) {
			this.cxDialogService.notify(this.locale.getString('appearance.palettesLimitExceeded'), '');
			return;
		}

		let INCLUDE_NUMBER = true;
		let generatedName = this.nameService.uniqueName(
			this.locale.getString('appearance.defaultPaletteName'),
			this.colorPaletteStore.getColorPalettes(),
			'displayName',
			false,
			INCLUDE_NUMBER
		);
		let newItem: Partial<ColorPalette> = {
			displayName: generatedName,
			colors: [],
		};

		this.cbDialogService.showColorPaletteModal(newItem as ColorPalette, this.colorPaletteStore.getColorPalettes()).then(
			(colorPalette: ColorPalette): void => {
				const createdPalettePromise = this.colorPalettesApi.createPalette(colorPalette);

				createdPalettePromise.then(
					(createdColorPalette) => {
						this.toastService.addToast(
							this.locale.getString('common.itemAdded', {itemName: createdColorPalette.displayName}), AlertLevel.SUCCESS
						);

						this.colorPaletteStore.refreshColorPalettes();
					}
				);
			}, _.noop);
	};

	public editPalette = (paletteToUpdate: ColorPalette): void => {
		if (this.isSystemPalette(paletteToUpdate)) {
			return;
		}

		PromiseUtils.wrap(this.cbDialogService.showColorPaletteModal(paletteToUpdate, this.colorPaletteStore.getColorPalettes()))
			.then(
				async (updatedPalette): Promise<void> => {
					await this.colorPalettesApi.updatePalette(updatedPalette);

					this.toastService.addToast(
						this.locale.getString('common.itemUpdated', {itemName: updatedPalette.displayName}), AlertLevel.SUCCESS
					);

					this.colorPaletteStore.updateColorPalette(updatedPalette);
				}, _.noop);
	};

	public limitReached(colorPalettes: ColorPalette[]): boolean {
		return colorPalettes?.filter(palette => !this.isSystemPalette(palette)).length >= this.MAX_PALETTES ?? false;
	}

	public copyPalette = (paletteToCopy: ColorPalette): void => {
		if (this.limitReached(this.colorPaletteStore.getColorPalettes())) {
			this.cxDialogService.notify(this.locale.getString('appearance.palettesLimitExceeded'), '');
			return;
		}

		let copy = Object.assign(paletteToCopy);

		const WITH_BRACES = true;
		copy.displayName = this.nameService.copyName(
			paletteToCopy.displayName,
			this.colorPaletteStore.getColorPalettes(),
			'displayName',
			WITH_BRACES
		);

		this.colorPalettesApi.createPalette(copy as ColorPalette)
			.then(
				(createdColorPalette) => {
					this.toastService.addToast(
						this.locale.getString('common.itemAdded', {itemName: createdColorPalette.displayName}), AlertLevel.SUCCESS
					);

					this.colorPaletteStore.refreshColorPalettes();
				}
			);
	};

	public replacePalette = (paletteToReplace: ColorPalette): void => {
		this.cxDialogService.openDialog(
			ColorPaletteReplaceComponent,
			{
				palette: paletteToReplace,
				itemList: this.colorPaletteStore.getColorPalettes(),
				isDelete: false
			}
		).result.then((
			replaceWithColorPalette: ColorPalette
		) => {
			const replacedPalettePromise =
				this.colorPalettesApi.replacePalette(paletteToReplace.name, replaceWithColorPalette.name);

			replacedPalettePromise.then(
				() => {
					this.toastService.addToast(
						this.locale.getString(
							'common.itemReplaced',
							{
								itemName: paletteToReplace.displayName,
								replacedName: replaceWithColorPalette.displayName
							}), AlertLevel.SUCCESS
					);

					this.colorPaletteStore.refreshColorPalettes();
				}
			);
		}, _.noop);
	};

	public deleteAndReplacePalette = (paletteToDelete: ColorPalette): void => {
		this.cxDialogService.openDialog(
			ColorPaletteReplaceComponent,
			{
				palette: paletteToDelete,
				itemList: this.colorPaletteStore.getColorPalettes(),
				isDelete: true
			}
		).result.then((
			replaceWithColorPalette: ColorPalette
		) => {
			const deletedPalettePromise = this.colorPalettesApi.deleteAndReplacePalette(paletteToDelete.name, replaceWithColorPalette.name);

			deletedPalettePromise.then(
				() => {
					this.toastService.addToast(
						this.locale.getString(
							'common.itemDeletedAndReplaced',
							{
								itemName: paletteToDelete.displayName,
								replacedName: replaceWithColorPalette.displayName
							}), AlertLevel.SUCCESS
					);

					this.colorPaletteStore.refreshColorPalettes();
				}
			);
		}, _.noop);
	};

	public togglePalette = (palette: ColorPalette): void => {
		if (palette.defaultPalette) return;

		if (this.isPredefinedPalette(palette)) {
			this.togglePredefinedPalette(palette);
		} else {
			this.toggleCustomPalette(palette);
		}
	};

	public makeDefault = (palette: ColorPalette): void => {
		if (!palette.enabled) return;

		this.colorPalettesApi.makeDefault(palette.name).then(
			() => {
				this.toastService.addToast(
					this.locale.getString(
						'appearance.defaultPaletteNotification',
						{
							name: palette.displayName
						}), AlertLevel.SUCCESS
				);

				this.colorPaletteStore.refreshColorPalettes();
			}
		);
	};

	// predefined palette can only be disabled as it doesn't exist yet
	private togglePredefinedPalette(palette: ColorPalette): void {
		palette.enabled = !palette.enabled;

		this.colorPalettesApi.disablePalette(palette.name)
			.then(
				() => {
					this.toastService.addToast(
						this.locale.getString(
							'common.itemDisabled',
							{
								itemName: palette.displayName
							}), AlertLevel.SUCCESS
					);

					this.colorPaletteStore.toggleColorPalette(palette);
				}
			);
	}

	private toggleCustomPalette(palette: ColorPalette): void {
		const promise = palette.enabled
			? this.colorPalettesApi.disablePalette(palette.name)
			: this.colorPalettesApi.enablePalette(palette.name);

		promise.then(
			() => {
				palette.enabled = !palette.enabled;

				const translationKey = palette.enabled ? 'common.itemEnabled' : 'common.itemDisabled';

				this.toastService.addToast(
					this.locale.getString(
						translationKey,
						{
							itemName: palette.displayName
						}), AlertLevel.SUCCESS
				);

				this.colorPaletteStore.toggleColorPalette(palette);
			}
		);
	}

	private isSystemPalette = (palette: ColorPalette): boolean => palette.system;

	private isPredefinedPalette = (palette: ColorPalette): boolean => palette.id < 0;
}
