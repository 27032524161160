import { CxHttpService } from '@app/core';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CalendarSettingsEntry } from '../calendar-settings.entity';

@Injectable({ providedIn: 'root' })
export class CalendarFormatApi {

	readonly BASE_URL = 'rest/calendar-format';

	constructor(
		private readonly http: CxHttpService,
	) { }

	getCalendarFormatForProjects = (workspaceId: number): Promise<CalendarSettingsEntry[]> => {
		return this.http.get(`${this.BASE_URL}/ws/${workspaceId}/all-projects`);
	};

	saveCalendarFormatByProject = (calendarSettings: CalendarSettingsEntry): Promise<void> => {
		return this.http.put(`${this.BASE_URL}`, calendarSettings);
	};
}

app.service('calendarFormatApi', downgradeInjectable(CalendarFormatApi));
