import { Label } from '@cxstudio/dashboards/entity/label';
import { Input, Component, OnInit, Output, EventEmitter, ChangeDetectionStrategy, ViewChild } from '@angular/core';

@Component({
	selector: 'labels-input',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './labels-input.component.html'
})
export class LabelsInputComponent implements OnInit {
	@Input() labels: Label[];
	@Input() loadLabels: (query: string) => Promise<Label[]>;

	@Output() onLabelAdded = new EventEmitter<Label>();
	@Output() onLabelRemoved = new EventEmitter<Label>();
	@ViewChild('tagsInput') tagsInput;

	values: string[];

	ngOnInit(): void {
		this.values = this.labels ? this.labels.map(label => label.text) : [];
		if (!this.loadLabels) {
			this.loadLabels = (query: string): Promise<Label[]> => {
				return Promise.resolve([]);
			};
		}
	}

	labelAdded = (value: string): void => {
		this.onLabelAdded.emit({text: value});
	};
	
	labelRemoved = (value: string): void => {
		this.onLabelRemoved.emit({text: value});
	};

	tagsSource = (query: string): Promise<string[]> => {
		return this.loadLabels(query).then(labels => labels.map(label => label.text));
	};
}