
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import LinkedFilter from '@cxstudio/dashboards/widgets/linked-filter';
import LinkedFilterExport from '@cxstudio/dashboards/widgets/linked-filter-export';
import LinkedFilterContainer from '@cxstudio/dashboards/widgets/linked-filters-container';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import * as _ from 'underscore';
import PDFExportUtils from './pdf-export.utils';
import { WidgetsCache } from '@cxstudio/dashboards/widgets/widgets-cache.factory';

export default interface IDashboardWidgets {
	setWidgets(newWidgets: Widget[]): void;
	getWidgets(): Widget[];
	setBaseWidget(widgetId: number, widget: Widget): void;
	getBaseWidget(widgetId: number): Widget;
	setLinkedFilters(widgetId: number, filters: LinkedFilter[]): void;
	getLinkedFilters(widgetId: number): LinkedFilter[];
	getAllLinkedFilters(): {[widgetId: number]: LinkedFilter[]};
	getLinkedFiltersForExport(): {[positionId: number]: LinkedFilterExport[]};
	resetLinkedFilters(): void;
	setPagination(widgetId: number, page: number): void;
	getPagination(widgetId: number): number;
	getPaginationForExport(): {[positionId: number]: number};
	setPaginationTotal(widgetId: number, totalItem: number): void;
	getPaginationTotal(widgetId: number): number;
	getWidgetsCache(): WidgetsCache;
	getDashboardHistory(): any;
	setDashboardHistory(dashboardHistory: IDashboardHistoryInstance): void;
	setSelectedSentence(widgetId: number, sentenceId: number): void;
	getSelectedSentence(widgetId: number): number;
	getSelectedSentencesForExport(): {[positionId: number]: number};
	getTextFiltersForExport(): {[positionId: number]: string};
	processExportParams(exportParams: any): void;
	getPersonalization(): PersonalizationState;
	setPersonalization(personalization: PersonalizationState): void;

}

// eslint-disable-next-line prefer-arrow-callback
app.factory('DashboardWidgets', function(
		pointSelectionUtils: PointSelectionUtils) {
	return class DashboardWidgets implements IDashboardWidgets {
		private widgets: Widget[] = [];
		private linkedFilters: LinkedFilterContainer = new LinkedFilterContainer();
		private pagination: {[widgetId: number]: number} = {};
		private paginationTotal: {[widgetId: number]: number} = {};
		private selectedSentences: {[widgetId: number]: number} = {};
		private cache: WidgetsCache = new WidgetsCache();
		private dashboardHistory: IDashboardHistoryInstance;
		private baseWidgets: {[widgetId: number]: Widget} = {};
		private personalization: PersonalizationState = null;

		constructor(widgets: Widget[]) {
			this.widgets = widgets;
		}

		setWidgets(newWidgets: Widget[]): void {
			this.widgets = newWidgets;
		}

		getWidgets(): Widget[] {
			return this.widgets;
		}

		setBaseWidget(widgetId: number, widget: Widget): void {
			this.baseWidgets[widgetId] = widget;
		}

		getBaseWidget(widgetId: number): Widget {
			return this.baseWidgets[widgetId];
		}

		setLinkedFilters(widgetId: number, filters: LinkedFilter[]): void {
			this.linkedFilters.setFilters(widgetId, filters);
		}

		getLinkedFilters(widgetId: number): LinkedFilter[] {
			return this.linkedFilters.getFilters(widgetId);
		}

		getAllLinkedFilters(): {[widgetId: number]: LinkedFilter[]} {
			return this.linkedFilters.getAllFilters();
		}

		getLinkedFiltersForExport(): {[widgetId: number]: LinkedFilterExport[]} {
			return this.linkedFilters.toExport(this.widgets);
		}

		resetLinkedFilters(): void {
			this.linkedFilters.reset();
		}

		private processWidgetLinking(widgetLinkingParams): void {
			LinkedFilterContainer.initWidgetLinking(this.widgets);
			if (widgetLinkingParams) {
				this.linkedFilters.setFromExport(widgetLinkingParams, this.widgets);
				// highlight points for pdf
				_.each(this.widgets, (widget) => {
					_.each(this.linkedFilters.getFilters(widget.id), (filter) => {
						pointSelectionUtils.selectPoint(widget.containerId, filter.widget, filter.point, false);
					});
				});
			}
		}

		setPagination(widgetId: number, page: number): void {
			this.pagination[widgetId] = page;
		}

		getPagination(widgetId: number): number {
			return this.pagination[widgetId];
		}

		getPaginationForExport(): {[positionId: number]: number} {
			return PDFExportUtils.toExport(
				Object.keys(this.pagination), this.widgets,
				widgetId => this.getPagination(widgetId)
			);
		}

		setPaginationTotal(widgetId: number, totalItems: number): void {
			this.paginationTotal[widgetId] = totalItems;
		}

		getPaginationTotal(widgetId: number): number {
			return this.paginationTotal[widgetId];
		}

		processWidgetPagination(widgetPaginationParams: {[positionId: number]: number}): void {
			_.each(widgetPaginationParams, (page: number, widgetPositionId: string) => {
				if (_.isUndefined(widgetPositionId)) {
					return;
				}
				let widget = PDFExportUtils.findWidgetByPositionId(Number(widgetPositionId), this.widgets);
				if (widget) {
					this.setPagination(widget.id, page);
				}
			});
		}

		getDashboardHistory(): any {
			return this.dashboardHistory;
		}

		setDashboardHistory(dashboardHistory: IDashboardHistoryInstance): void {
			this.dashboardHistory = dashboardHistory;
		}

		getWidgetsCache(): WidgetsCache {
			return this.cache;
		}

		setSelectedSentence(widgetId: number, sentenceId: number): void {
			if (sentenceId)
				this.selectedSentences[widgetId] = sentenceId;
			else delete this.selectedSentences[widgetId];
		}
		getSelectedSentence(widgetId: number): number {
			return this.selectedSentences[widgetId];
		}
		getSelectedSentencesForExport(): {[positionId: number]: number} {
			return PDFExportUtils.toExport(
				Object.keys(this.selectedSentences), this.widgets,
				widgetId => this.getSelectedSentence(widgetId)
			);
		}

		private processSentenceSelection(sentenceSelectionParams: {[positionId: number]: number}): void {
			_.each(sentenceSelectionParams, (sentenceId: number, widgetPositionId: string) => {
				if (_.isUndefined(widgetPositionId)) {
					return;
				}
				let widget = PDFExportUtils.findWidgetByPositionId(Number(widgetPositionId), this.widgets);
				if (widget) {
					this.setSelectedSentence(widget.id, sentenceId);
				}
			});
		}

		private getWidgetTextFilter(widgetId: number): string {
			let widget = _.findWhere(this.widgets, {id: widgetId});
			return widget && widget.filterUi && widget.filterUi.textFilter;
		}

		getTextFiltersForExport(): {[positionId: number]: string} {
			let ids = _.chain(this.widgets)
				.filter(widget => !!(widget.filterUi && widget.filterUi.textFilter))
				.pluck('id')
				.value();
			return PDFExportUtils.toExport(
				ids, this.widgets,
				widgetId => this.getWidgetTextFilter(widgetId)
			);
		}

		private processTextFilters(textFilters: {[positionId: number]: string}): void {
			_.each(textFilters, (textFilter: string, widgetPositionId: string) => {
				if (_.isUndefined(widgetPositionId)) {
					return;
				}
				let widget = PDFExportUtils.findWidgetByPositionId(Number(widgetPositionId), this.widgets);
				if (widget) {
					widget.filterUi = angular.extend({}, widget.filterUi, {textFilter});
				}
			});

		}

		processExportParams(exportParams: any): void {
			this.processWidgetLinking(exportParams.widgetLinkingParams);
			if (exportParams.widgetPaginationParams)
				this.processWidgetPagination(exportParams.widgetPaginationParams);
			if (exportParams.widgetSentenceSelectionParams)
				this.processSentenceSelection(exportParams.widgetSentenceSelectionParams);
			if (exportParams.widgetTextFilters)
				this.processTextFilters(exportParams.widgetTextFilters);
		}

		getPersonalization(): PersonalizationState {
			return this.personalization;
		}

		setPersonalization(personalization: PersonalizationState): void {
			this.personalization = personalization;
		}
	};
});
