import { Inject, ChangeDetectionStrategy } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { MasterAccountPropertiesApiService } from '@app/modules/account-administration/api/master-account-properties-api.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { MasterAccountDefaultPermissions, MasterAccountDefaultPermissionsUtils } from './master-account-default-permissions.class';
import { MasterAccountPermission } from '@app/modules/user-administration/permissions/master-account-permission.class';
import { License } from '@app/modules/user-administration/permissions/license.class';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { LicenseType } from '@cxstudio/common/license-types';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';

@Component({
	selector: 'licensing-tab',
	template: `
<div [ngBusy]="loading" class="ph-16 page-tab-content">
	<div *ngIf=ready>
		<p>{{"licensing.head"|i18n}}</p>

		<section class="panel panel-default" *ngFor="let licenseTypeId of orderedLicenseIds">
			<licensing-permissions
				[availablePermissions]=availablePermissions
				[license]=getLicenseType(licenseTypeId)
				[licenseDefaultPermissions]=getLicenseDefaultPermissions(licenseTypeId)
				[systemDefaultPermissions]=getSystemDefaultPermissions(licenseTypeId)>
			</licensing-permissions>
		</section>
		</div>
	</div>
`,
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class LicensingTabComponent implements OnInit {

	masterAccountDefaultPermissions: MasterAccountDefaultPermissions;
	systemDefaultPermissions: MasterAccountDefaultPermissions;

	availablePermissions: MasterAccountPermission[];
	licenseTypes: License[];
	ready: boolean;
	loading: Promise<any>;
	readonly orderedLicenseIds: number[]
		= [LicenseType.CX_STUDIO_BASIC, LicenseType.CX_STUDIO, LicenseType.CX_STUDIO_AND_CM, LicenseType.ANALYZE];

	constructor(
		private masterAccountPropertiesApiService: MasterAccountPropertiesApiService,
		private permissionsService: PermissionsService,
		private licenseApiService: LicenseApiService,
		@Inject('usersGroupsApiService') private usersGroupsApiService: UsersGroupsApiService) { }

	ngOnInit(): void {
		this.loading = Promise.all([this.permissionPromise(), this.defaultsPromise(), this.systemDefaultsPromise(), this.licenseTypesPromise()])
			.then(() => {
				this.ready = true;
		});
	}

	getLicenseDefaultPermissions = (licenseTypeId: number): MasterAccountPermission[] => {
		return MasterAccountDefaultPermissionsUtils.getLicenseDefaultPermissions(
				this.masterAccountDefaultPermissions, licenseTypeId);
	};

	getSystemDefaultPermissions = (licenseTypeId: number): MasterAccountPermission[] => {
		return MasterAccountDefaultPermissionsUtils.getLicenseDefaultPermissions(
			this.systemDefaultPermissions, licenseTypeId);
	};

	getLicenseType = (licenseTypeId: number): License => {
		return _.findWhere(this.licenseTypes, { licenseTypeId });
	};

	private permissionPromise = (): Promise<any> => {
		return Promise.resolve(this.usersGroupsApiService.getPermissions().then((response) => {
			this.availablePermissions = this.permissionsService.filterProfanityPermission(response);
		}) as PromiseLike<void>);
	};

	private defaultsPromise = (): Promise<any> => {
		return Promise.resolve(this.masterAccountPropertiesApiService.getDefaultPermissions().then((response) => {
			this.masterAccountDefaultPermissions = response;
		}) as PromiseLike<void>);
	};

	private systemDefaultsPromise = (): Promise<any> => {
		return Promise.resolve(this.masterAccountPropertiesApiService.getSystemDefaultPermissions().then((response) => {
			this.systemDefaultPermissions = response;
		}) as PromiseLike<void>);
	};

	private licenseTypesPromise = (): Promise<any> => {
		return Promise.resolve(this.licenseApiService.getLicenseTypes().then((response) => {
			this.licenseTypes = response;
		}) as PromiseLike<void>);
	};
}

app.directive('licensingTab', downgradeComponent({component: LicensingTabComponent}));
