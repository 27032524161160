import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { Pill, PillType } from '@app/modules/pills/pill';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { AttributeValues } from '@app/modules/project/attribute/attribute-values';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { DocExplorerFavoriteUtils } from '@app/modules/document-explorer/context-pane/doc-explorer-favorite-utils.class';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import { FavoriteAttribute, FavoriteId, FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

@Component({
	selector: 'explorer-world-awareness',
	templateUrl: './explorer-world-awareness.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ExplorerWorldAwarenessComponent extends SelfCleaningComponent implements OnInit, AfterViewInit, OnChanges {

	private readonly TYPE = FavoriteType.WORLD_AWARENESS;

	@Input() attribute: AttributeValues & FavoriteAttribute;
	@Input() favoriteAttributes: FavoriteId[];
	@Input() disableFavorites: boolean;
	@Input() favoritesPanel: boolean;
	@Input() highlightItem: string;
	@Input() selectionChangeTrigger: number;
	@Output() highlightFn = new EventEmitter<{sentences: number[]; value: string; name: string; displayName: string}>();
	@Output() onToggleFavorite = new EventEmitter<{attribute: FavoriteAttribute; type: FavoriteType}>();

	favoriteId: FavoriteId;
	pills: Pill[];
	width: number;
	private widthElement: Element;
	private readonly debouncedResize = new Subject<void>();

	constructor(
		@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService,
		private ref: ChangeDetectorRef,
		private elementRef: ElementRef
	) {
		super();
	}

	ngOnInit(): void {
		this.favoriteId = DocExplorerFavoriteUtils.getFavoriteId(this.attribute, this.TYPE);
		this.pills = this.attribute.values.map(attributeValue => {
			return {
				name: attributeValue.value,
				type: PillType.ATTRIBUTE,
				title: this.attribute.displayName,
				value: attributeValue,
				html: this.formatValue(this.attribute.objectType, attributeValue)
			};
		});
	}

	ngAfterViewInit(): void {
		this.updateWidth();
		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.updateWidth()));
		this.widthElement = this.elementRef.nativeElement.firstElementChild;
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(this.widthElement, () => this.debouncedResize.next()));
	}

	ngOnChanges(changes: SimpleChanges): void {
		let trigger = changes.selectionChangeTrigger;
		if (trigger && !trigger.firstChange && trigger.currentValue !== trigger.previousValue) {
			this.pills = this.pills.slice(0);
		}
	}

	pillClick = (attributeValue: any): void => {
		this.highlightFn.emit({
			sentences: attributeValue.sentences,
			value: attributeValue.value,
			name: this.attribute.name,
			displayName: this.attribute.displayName
		});
	};

	getPillClass = (pill: Pill): string => {
		return `${pill.value.value === this.highlightItem ? 'selected ' : ''}world-awareness-bubble`;
	};

	formatValue = (objectType: AttributeObjectType, attributeValue): string => {
		let formattedValue = this.enrichmentAttributesService.formatAttributeValue(
			attributeValue.value, this.attribute.type, this.attribute.settings, false);
		return objectType === AttributeObjectType.DOCUMENT
			? formattedValue
			: `(${attributeValue.sentences.length}) ${formattedValue}`;
	};

	toggleFavorite = (): void => {
		this.onToggleFavorite.emit({attribute: this.attribute, type: this.TYPE});
	};

	private updateWidth = () => {
		let outerWidth = $(this.widthElement).outerWidth();
		let newWidth = outerWidth && Math.floor(outerWidth);
		if (this.width !== newWidth) {
			this.width = newWidth;
			this.ref.detectChanges();
		}
	};
}
