import { SimplePOPData } from '@app/modules/widget-visualizations/metric-widget/metric-widget-rendering.service';

export class VerticalMetricChart {
	static CENTER = 52.5;
	static CENTER_WIDE = 72.5;
	static ARROW_WIDTH = 15;

	static getSVGPath(data: SimplePOPData, options, useWideGraph: boolean): string {
		// if labels are long, use a slightly narrower arrow
		let centerPoint = useWideGraph ? this.CENTER_WIDE : this.CENTER;

		let current = (data.max - data.currentValue) * (200 / (data.max - data.min));
		let last = (data.max - data.lastValue) * (200 / (data.max - data.min));
		let left = (centerPoint - (this.ARROW_WIDTH / 2));
		let right = (centerPoint + (this.ARROW_WIDTH / 2));
		let arrowHeight = Math.abs(current - last) <= this.ARROW_WIDTH ? Math.abs(
			current - last) / 4 : (this.ARROW_WIDTH / 4);

		arrowHeight = data.currentValue > data.lastValue ?
			arrowHeight :
			arrowHeight * -1;

		if (_.any([current, last, left, right, arrowHeight], val => isNaN(val) || val === Infinity )) return '';

		// eslint-disable-next-line max-len
		return  `M ${centerPoint} ${current} L ${right} ${current + arrowHeight} L ${right} ${last} L ${left} ${last} L ${left} ${current + arrowHeight} Z`;
	}
}
