import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { TypeGuards } from '@app/util/typeguards.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class HighchartsUtilsService {

	constructor(
		private locale: CxLocaleService,
		@Inject('dateService') private readonly dateService: DateService,
		@Inject('reportUtils') private reportUtils: ReportUtils,
	) { }

	getAxisScale(prefix: 'x'|'y'|'secondary', props: VisualProperties) {
		let min = null;
		let max = null;

		if (!_.isUndefined(props[prefix + 'AxisAutoMax']) && !_.isUndefined(props[prefix + 'AxisAutoMin'])) {
			if (!props[prefix + 'AxisAutoMin']) {
				let minValue = props[prefix + 'AxisMin'];
				if (TypeGuards.isNumber(parseFloat(minValue)))
					min = Number(minValue);
			}
			if (!props[prefix + 'AxisAutoMax']) {
				let maxValue = props[prefix + 'AxisMax'];
				if (TypeGuards.isNumber(parseFloat(maxValue)))
					max = Number(maxValue);
			}

		} else if (!props[prefix + 'AxisAuto']) {
			let autoValue = props[prefix + 'AxisMin'];
			if (TypeGuards.isNumber(parseFloat(autoValue)))
				min = Number(autoValue);
			autoValue = props[prefix + 'AxisMax'];
			if (TypeGuards.isNumber(parseFloat(autoValue)))
				max = Number(autoValue);
		}
		return {
			min,
			max
		};
	}

	getAxisTitle(axis: string, defaultName: string): string {
		switch (axis) {
		case StandardMetricName.VOLUME: return this.locale.getString('widget.volume');
		case StandardMetricName.PERCENT_OF_TOTAL: return this.locale.getString('widget.volumePercTotal');
		case StandardMetricName.SENTIMENT: return this.locale.getString('widget.sentiment');
		case PredefinedMetricConstants.EASE_SCORE: return this.locale.getString('metrics.easeScore');
		case PredefinedMetricConstants.EMOTION: return this.locale.getString('metrics.emotion');
		default: return this.escapeHtmlBrackets(defaultName);
		}
	}

	private escapeHtmlBrackets(text: string): string {
		if (typeof text !== 'string') return text;
		return text.bracketsToEntities();
	}

	getDefaultConfig(): Highcharts.Options {
		return {
			credits: {
				enabled: false
			},
			title: {
				text: null
			},
			legend: {
				enabled: false,
				symbolRadius: 0
			},
			exporting: {
				enabled: false
			}
		};
	}

	getSpacingBottom(options: VisualProperties, minOffset: number = 0) {
		return options.showSampleSize === false ? 10 : minOffset;
	}

	getSelectedPoint(point: Partial<HighchartsFunctionScope>): DrillPoint {
		let selectedPoint: DrillPoint = {
			id: point.id,
			name: point.name,
			uniqueName: point._uniqueName,
			displayName: point.displayName,
			fullPath: point.fullPath,
			idPath: point.idPath,
			color: point.color || (point.series as any).color,
			attributeName: point.attributeName
		} as any;
		if (point.graphic) {
			selectedPoint._element = point.graphic.element;
			selectedPoint._element.chart = point.series.chart; // chart is required to highlight similar points
		}
		return _.extend(selectedPoint, point.object);
	}

	handleRenderedEvent(scope: Partial<HighchartsFunctionScope>, utils: WidgetUtils) {
		(scope.chart.series[scope.index] as any).rendered = true;

		let amountOfRenderedSeries = _.filter(scope.chart.series, (item: any) => item.rendered).length;

		if (amountOfRenderedSeries === scope.chart.series.length) {
			this.reportUtils.handleWidgetRenderedEvent(utils.widgetId, utils.widgetType, utils.containerId);
		}
	}

	cleanDataNames(options: Highcharts.Options) {
		if (!(options && options.series)) return;

		options.series.forEach((oneSeries: any) => this.cleanArrayProperties(oneSeries.data, 'name'));
	}

	cleanAxisCategories(axis: Highcharts.AxisOptions): void {
		if (!axis || !axis.categories) return;

		axis.categories = axis.categories.map(cat => this.cleanStringForDisplay(cat));
	}

	private cleanStringForDisplay(checkString: string): string {
		if (typeof checkString !== 'string') return checkString;

		checkString = checkString.bracketsToEntities();
		return checkString;
	}

	private cleanArrayProperties(array: any[], prop: string): void {
		if (!array || !array.length) return;

		array.forEach(item => {
			if (item && item[prop]) {
				item[prop] = this.cleanStringForDisplay(item[prop]);
			}
		});
	}

	formatTrendLabel = (label, format): string => {
		switch (format) {
		case 'HOUR':
			return this.formatDateLabel(label, DateTimeFormat.TREND_DATE_LONG, '');
		case 'DAY':
			return this.formatDateLabel(label, DateTimeFormat.TREND_DATE, '');
		case 'WEEK':
			if (moment(label, 'YYYY-MM-DD', true).isValid()) {
				return this.formatDateLabel(label, DateTimeFormat.TREND_DATE, this.locale.getString('widget.weekOf') + ' ');
			}
			return label;
		default:
			return label;
		}
	};

	private formatDateLabel(label, format: DateTimeFormat, prefix: string): string {
		return prefix + this.dateService.format(label, format);
	}
}
