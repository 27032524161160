import { IScroller, IScrollerApi } from '@app/modules/scroll-carousel/scrollers/scroller.interface';

export type ITimeoutLike = (fn: () => any, delay: number) => any;
enum ScrollDirection {
	FORWARD = 1,
	BACKWARD = -1
}

export class SimpleContainerScroller implements IScroller, IScrollerApi {

	protected readonly INTERVAL = 150;
	protected readonly EDGE_WIDTH = 20; // width of gradient fading
	protected readonly DEFAULT_SCROLL_OFFSET = 20; // when scrolling to element - go little bit farther for better ui
	protected readonly ANIMATION_DELAY = 100; //ms

	protected active: boolean = false;
	protected element: JQuery; // container of scrollable items

	private direction: ScrollDirection;

	constructor(
		protected timeoutFn: ITimeoutLike,
		protected scrollOffset?: number
	) {
		if (_.isUndefined(this.scrollOffset))
			this.scrollOffset = this.DEFAULT_SCROLL_OFFSET;
	}

	init(element: JQuery): void {
		this.element = element;
	}

	scrollToStart(): void {
		this.startItemScroll(ScrollDirection.BACKWARD);
	}

	scrollToEnd(): void {
		this.startItemScroll(ScrollDirection.FORWARD);
	}

	protected startItemScroll(direction: ScrollDirection): void {
		this.direction = direction;
		if (!this.active) {
			this.active = true;
			this.doScroll();
		}
	}

	stopScroll(): void {
		this.active = false;
	}

	private doScroll = () => {
		if (this.active) {
			this.singleItemScroll(this.direction);
			this.timeoutFn(this.doScroll, this.INTERVAL);
		}
	};

	protected singleItemScroll(direction: ScrollDirection): void {
		let targetIndex;
		if (direction === ScrollDirection.BACKWARD) {
			let containerLeft = this.element.offset().left;
			targetIndex = this.getItems().filter((index, item) => {
				return $(item).offset().left < containerLeft;
			}).last().index();
		} else {
			let containerRight = this.element.offset().left + this.element.width();
			targetIndex = this.getItems().filter((index, item) => {
				return $(item).offset().left + $(item).width() > containerRight;
			}).first().index();
		}
		this.scrollToItem(targetIndex);
	}

	scrollToItem(index: number): void {
		if (!this.element)
			return;
		let target = this.getItems()[index];
		if (!target)
			return;
		// X positions relative to scroll panel
		let itemLeft = $(target).offset().left - this.element.offset().left;
		let itemRight = itemLeft + $(target).width();
		if (itemLeft < this.EDGE_WIDTH && this.element.scrollLeft() > 0) {
			let scrollLeft = Math.max(0, this.element.scrollLeft() + itemLeft - this.scrollOffset);
			this.element.animate({scrollLeft}, this.ANIMATION_DELAY);
		} else if (itemRight > this.element.width() - this.EDGE_WIDTH) {
			let delta = itemRight - (this.element.width() - this.EDGE_WIDTH);
			if (delta > 1) { // don't scroll if already edge
				let scrollLeft = this.element.scrollLeft() + delta + this.scrollOffset;
				this.element.animate({scrollLeft}, this.ANIMATION_DELAY);
			}
		}
		this.timeoutFn(() => {}, this.ANIMATION_DELAY + 1); // to start digest cycle after animation

	}

	canScrollStart(): boolean {
		return Math.floor(this.element.scrollLeft()) > 0;
	}

	canScrollEnd(): boolean {
		return Math.ceil(this.element.scrollLeft() + this.element.width()) < this.element[0].scrollWidth;
	}

	private getItems(): JQuery {
		return this.element.children(':not(.scroll-carousel-ignore)');
	}
}
