import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, ViewChild, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { LoginMessage } from '@app/modules/user/login-message';
import { PasswordState } from '@app/modules/user/new-password-form/password-state';
import { PasswordValidatorService } from '@app/modules/user/new-password-form/password-validator.service';
import { PasswordResetApiService } from '@app/modules/user/password-reset-api.service';
import { PasswordResetContext } from '@app/modules/user/password-reset-context';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';

interface PasswordNote {
	text: string;
	getState: () => boolean;
}

@Component({
	selector: 'new-password-form',
	templateUrl: './new-password-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class NewPasswordFormComponent implements OnInit {
	@Input() email: string;
	@Output() done = new EventEmitter<void>();

	@ViewChild('newPasswordForm', { static: false }) newPasswordForm: NgForm;

	loading: Promise<any>;
	loadingGenerate: any = false;
	newPassword: string;
	confirmPassword: string;

	resetContext: PasswordResetContext;
	passwordState: PasswordState;
	passwordNotes: PasswordNote[];
	passwordErrors: string[];

	passwordGeneration: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly passwordResetApiService: PasswordResetApiService,
		private readonly passwordValidatorService: PasswordValidatorService,
		private readonly toastService: ToastService,
	) { }

	ngOnInit(): void {
		this.loading = this.passwordResetApiService.getContext()
			.then(context => {
				this.resetContext = context;
				this.initPasswordNotes();
				this.ref.markForCheck();
			}, (error) => {
				if (this.isAuthError(error)) {
					this.showPasswordCodeVerificationError();
					this.done.emit();
				} else {
					this.done.emit();
				}
			});
	}

	isAuthError = (error: string): boolean => {
		return error === 'authorization-failed';
	};

	getMessage(): LoginMessage {
		let message = this.locale.getString('login.newPasswordMessage');
		return {
			message
		};
	}

	initPasswordNotes(): void {
		let policy = this.resetContext.passwordPolicy;
		this.passwordState = this.passwordValidatorService.getPasswordState(this.newPassword, policy);
		this.passwordNotes = [{
			text: this.locale.getString('login.passwordExpectLowercaseChar'),
			getState: () => this.passwordState.lowercase
		}, {
			text: this.locale.getString('login.passwordExpectUppercaseChar'),
			getState: () => this.passwordState.uppercase
		}, {
			text: this.locale.getString('login.passwordExpectNumber'),
			getState: () => this.passwordState.number
		}, {
			text: this.locale.getString('login.passwordExpectSpecialChar'),
			getState: () => this.passwordState.special
		}];
		if (policy.minLength > 0) {
			this.passwordNotes.insert(0, {
				text: this.locale.getString('login.passwordExpectMinLength', { minLength: policy.minLength }),
				getState: () => this.passwordState.acceptedLength
			});
		}
	}

	getNoteClass = (note: PasswordNote): string => {
		return note.getState() ? 'text-success' : 'text-gray-500';
	};

	generate = (): void => {
		this.loadingGenerate = this.passwordResetApiService.generatePassword(this.resetContext.passwordPolicy)
			.then(suggestedPassword => {
				this.passwordGeneration = true;
				this.newPassword = suggestedPassword;
				this.confirmPassword = suggestedPassword;
				this.onPasswordChange();
				setTimeout(() => {
					//hack to workaround error alert which appear on each second generate click
					this.passwordGeneration = false;
					this.ref.markForCheck();
					this.loadingGenerate = false;
				}, 500);
			});
	};

	onPasswordChange = (): void => {
		this.passwordState = this.passwordValidatorService.getPasswordState(
			this.newPassword, this.resetContext.passwordPolicy);

		this.passwordErrors = this.newPassword
			? this.passwordValidatorService.validateForErrors(this.newPassword, this.resetContext.email)
			: [];
		let formState = this.isPasswordInvalid() ? { 'invalid-password': true } : null;
		this.newPasswordForm.controls['newPassword'].setErrors(formState);
		this.newPasswordForm.controls['newPassword'].markAsTouched();
		this.ref.markForCheck();
	};

	private isPasswordInvalid(): boolean {
		let numberOfSets = this.passwordState.numberOfSets;
		let hasRequiredSets = this.resetContext.passwordPolicy.requireSpecialChars
			? numberOfSets >= 3
			: true;
		return !this.passwordState.acceptedLength
			|| !hasRequiredSets
			|| !_.isEmpty(this.passwordErrors);
	}

	isPasswordValid(): boolean {
		if (!this.newPassword) {
			return false;
		}
		return this.newPasswordForm?.valid;
	}

	updatePassword = (): void => {
		this.loading = this.passwordResetApiService.updatePassword(this.newPassword)
			.then(() => {
				this.done.emit();
			}, (error) => {
				if (this.isAuthError(error)) {
					this.showPasswordCodeVerificationError();
					this.done.emit();
				}
			});
	};

	private showPasswordCodeVerificationError() {
		const message = this.locale.getString('login.resetPasswordCodeVerificationFailed');
		this.toastService.addToast(message, AlertLevel.ERROR);
	}

	cancel = (): void => {
		this.done.emit();
	};

	onPasswordCompareChange = (): void => {
		this.ref.markForCheck();
	};
}

app.directive('newPasswordForm', downgradeComponent({ component: NewPasswordFormComponent }));
