import * as _ from 'underscore';
import { Model } from '../entities/model';


export class AssetVisibilityUtils {
	static getVisibleModels = (models: Model[]): any[] => {
		return _.filter(models, (model) => model.hidden !== true);
	};

	static getHiddenModels = (models: Model[]): any[] => {
		return _.filter(models, (model) => model.hidden === true);
	};
}
