import * as _ from 'underscore';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricTreeItem } from '@cxstudio/metrics/metric-tree-item';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export interface IMetricSettings {
	predefinedMetrics: Metric[];
	metrics: MetricTreeItem[];
}

export default class MetricSettingsService {

	constructor(
		private readonly $q: ng.IQService,
		private readonly metricsService: MetricsService
	) {}

	getSettings = (project: AccountOrWorkspaceProject): ng.IPromise<IMetricSettings> => {
		let predefinedMetricsPromise = this.metricsService.getDynamicPredefinedMetrics(project, true);
		let metricsPromise = this.metricsService.getMetrics(project, CacheOptions.CACHED);

		let resultPromise = this.$q.all([
			PromiseUtils.old(predefinedMetricsPromise),
			PromiseUtils.old(metricsPromise)
		]).then((resultArray: any[]): IMetricSettings => {
			return {
				predefinedMetrics: resultArray[0],
				metrics: resultArray[1],
			};
		});
		return resultPromise;
	};
}

app.service('metricSettingsService', MetricSettingsService);
