import { Security } from '@cxstudio/auth/security-service';
import { SessionFlow } from '@cxstudio/auth/session-flow';

enum WebApplicationFlow {
	EXTERNAL_OAUTH = 'EXT_OAUTH',
	INTERNAL_OAUTH = 'INT_OAUTH',
	REGULAR = 'REGULAR'
}

export class Logger {

	/**
	 * Unique session id between 0 and 1000000 to differentiate in logs
	 */
	readonly SESSION_ID = Math.floor(Math.random() * 1000000);

	constructor(
		private security: Security,
		private $log: ng.ILogService
	) {
	}

	logUserLogin = (): void => {
		let metadata = {
			from: this.security.getContext()?.authLandingPage || '/home'
		};

		this.logUserEvent('login', metadata);
	};

	logExternalSessionExpired = (redirectTo: string): void => {
		let metadata = { rTo: redirectTo, ext: true };
		this.logUserEvent('sessionExpired', metadata);
	};

	logInternalSessionExpired = (): void => {
		let metadata = { ext: false };
		this.logUserEvent('sessionExpired', metadata);
	};

	logExternalLogout = (redirectTo: string): void => {
		let metadata = { rTo: redirectTo, ext: true };
		this.logUserEvent('logout', metadata);
	};

	logInternalLogout = (): void => {
		let metadata = { ext: false };
		this.logUserEvent('logout', metadata);
	};

	private logUserEvent = (eventName: string, metadata?: any): void => {
		try {
			if (!metadata) {
				metadata = {};
			}

			metadata.u = this.security.getEmail();
			metadata.f = this.getWebApplicationFlow();
			metadata.id = this.SESSION_ID;
			metadata.im = this.security.isImpersonateMode();
			metadata.t = new Date().getTime();

			let message = JSON.stringify(metadata);
			this.$log.info(`${eventName} ${message}`);
		} catch (err) {
			// preventing any possible logger errors, so the main program flow continues
			this.$log.error(`Could not log user event. Reason: ${err.message}`);
		}
	};

	private getWebApplicationFlow = (): WebApplicationFlow => {
		let sessionFlow = this.security.getSessionFlow();

		if (SessionFlow.EXTERNAL_OAUTH === sessionFlow) {
			return WebApplicationFlow.EXTERNAL_OAUTH;
		} else if (SessionFlow.INTERNAL_OAUTH === sessionFlow) {
			return WebApplicationFlow.INTERNAL_OAUTH;
		} else {
			return WebApplicationFlow.REGULAR;
		}
	};
}

app.service('logger', Logger);
