import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { IConfig } from '@cxstudio/config';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ConversationSentence } from '@cxstudio/reports/document-explorer/conversations/conversation-sentence.class';

@Injectable({
	providedIn: 'root'
})
export class DevToolsService {

	constructor(
		private widgetDescriptionService: WidgetDescriptionService
	) { }

	/**
	 * @param $event - The click event
	 * @description Is the click a valid dev click event: Alt + Click with CONFIG.dev === true
	 * @returns True if alt key is pressed during click, and CONFIG.dev === true
	 */
	isDevClickEvent($event: MouseEvent): boolean {
		return $event?.altKey && (CONFIG as IConfig).dev;
	}

	/**
	 * @param $event - The click event
	 * @param sentence - The sentence that was clicked
	 * @returns true if the attempt to log metadata was allowed, otherwise false
	 * @description Attempts to log sentence metadata to the console, returning true if successful
	 */
	tryLogSentenceMetadata($event: MouseEvent, sentence: ConversationSentence): boolean | undefined {
		if (!this.isDevClickEvent($event)) return;

		const targetAttrs = ['cb_conv_participant_id',
			'cb_conv_participant_type',
			'cb_conv_sentence_start_time_ms',
			'cb_conv_sentence_duration_ms',
			'cb_conv_participant_kind',
			'cb_interaction_type',
			'_verbatimtype'];

		let metadata = _.pick(sentence.attributes, targetAttrs);

		/* eslint-disable no-console */
		console.log(`Sentence:\n${sentence.text}`);
		console.table(metadata);
		/* eslint-enable no-console */

		return true;
	}

	/**
	 * @param $event - The click event
	 * @param widget - The widget that was clicked
	 * @description Logs generated widget description
	 */
	 logWidgetDescription(widget: Widget, data?: any): void {
		this.widgetDescriptionService.generateDescription(widget, data).then(desc => {
			/* eslint-disable no-console */
			console.log(`Generated description for widget ${widget.id}:\n${desc}`);
			/* eslint-enable no-console */
		});
	}
}

app.factory('devTools', downgradeInjectable(DevToolsService));
