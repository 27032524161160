import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { AgGridNestable } from '@app/modules/object-list/types/ag-grid-nestable.interface';
import { ColumnType } from '@app/modules/object-list/types/column-type';
import { ExtendedColDef } from '@app/modules/object-list/types/extended-column-definition';
import { QGridOptions } from '@app/modules/object-list/types/generic-object-list.class';
import { ObjectListWithFolders } from '@app/modules/object-list/types/object-list-with-folders.class';
import { SortLabels } from '@app/modules/object-list/types/sort-labels';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import {
	ColDef, ColumnApi, GetContextMenuItemsParams, GetRowIdFunc, GetRowIdParams, GridApi,
	GridOptions, GridReadyEvent, ITooltipParams, MenuItemDef, RowClickedEvent, RowNode
} from 'ag-grid-enterprise';
import { LocalStorageService } from 'angular-2-local-storage';

export interface TypeFilterCriteria {
	searchFilterValue: string;
	itemType: string;
}

/**
 * Universal ag-grid table which incapsulates most of default values for easier use.
 * Mandatory fields are:
 * columnDefs - list of columns (use ObjectListColumnsService for common column types)
 * rowData - data in tree format, can be produced from data which satisfies AgGridNestable interface by using 'nestedObjects' pipe
 * */
@Component({
	selector: 'default-objects-table',
	template: `
<ag-grid-angular
	class="fill-container ag-theme-alpine"
	[rowSelection]="rowSelection"
	[isRowSelectable]="isRowSelectable"
	[getContextMenuItems]="getContextMenuItems"
	[getMainMenuItems]="getMainMenuItems"
	[excludeChildrenWhenTreeDataFiltering]="true"
	[gridOptions]="gridOptions"
	[suppressRowClickSelection]="true"
	[suppressRowDeselection]="suppressRowDeselection"
	[suppressHorizontalScroll]="false"
	[autoGroupColumnDef]="autoGroupColumnDef"
	[columnDefs]="columnDefs"
	[defaultColDef]="defaultColDef"
	[tooltipShowDelay]="500"
	[rowData]="rowData"
	[treeData]="withFolders"
	[getDataPath]="getDataPath"
	[rowClassRules]="rowClassRules"
	[cacheQuickFilter]="true"
	[quickFilterText]="getQuickFilter()"
	[isExternalFilterPresent]="isExternalFilterPresent"
	[doesExternalFilterPass]="doesExternalFilterPass"
	[animateRows]="true"
	[suppressScrollOnNewData]="true"
	[getRowId]="getRowId"
	(gridReady)="onGridReady($event)"
	(firstDataRendered)="firstDataRendered.emit()"
	(rowClicked)="rowClicked.emit($event)"
	[localeText]="getDefaultTranslationsForAgGrid()"
>
</ag-grid-angular>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DefaultObjectsTableComponent extends ObjectListWithFolders implements OnInit {

	/* eslint-disable @typescript-eslint/member-ordering */
	@Input() tableName; // persistence key for storing columns state
	@Input() rowSelection = 'single';
	@Input() suppressRowDeselection = false;
	@Input() gridOptions?: GridOptions;
	@Input() nameField = 'name';
	@Input() showCheckbox = false;
	@Input() columnDefs: ColDef[];
	@Input() defaultColDef: ColDef;
	@Input() autoGroupColumnDef?: ColDef;
	@Input() rowData: AgGridNestable[];
	@Input() withFolders: boolean = true;
	@Input() rowClassRules?: any;
	@Input() getContextMenuItems: (params: GetContextMenuItemsParams) => (string | MenuItemDef)[] = () => [];
	@Input() getNameIcon: (data: AgGridNestable) => string;
	@Input() quickFilterText?: string;
	@Input() tableFilterManager?: TableFilterManager;
	@Input() autoGroupColumnName?: string;
	@Output() gridReady = new EventEmitter<GridReadyEvent>();
	@Output() firstDataRendered = new EventEmitter<void>();
	@Output() rowClicked = new EventEmitter<RowClickedEvent>();
	@Output() rowAction = new EventEmitter<any>(); // double click on any row except folders

	private gridApi: GridApi;
	private typeSortLabels: {[key in ColumnType]?: SortLabels};
	private currentRowId: number;

	constructor(
		private ref: ChangeDetectorRef,
		locale: CxLocaleService,
		private localStorageService: LocalStorageService,
	) {
		super(locale);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.defaultColDef = _.extend({
			sortable: true,
			resizable: true,
			flex: 1, // automatically fill the remaining space
			tooltipValueGetter: (params: ITooltipParams) => {
				return params.valueFormatted;
			},
			getQuickFilterText: () => '',
		}, this.defaultColDef);

		const objectListDefaultGridOptions: Partial<QGridOptions> = {
			onColumnMoved: (params) => this.saveColumnState(params.columnApi),
			onColumnPinned: (params) => this.saveColumnState(params.columnApi),
			onColumnResized: (params) => this.saveColumnState(params.columnApi),
			onColumnVisible: (params) => this.saveColumnState(params.columnApi),
		};
		this.gridOptions = _.extend({}, this.defaultGridOptions, objectListDefaultGridOptions, this.gridOptions);
		this.autoGroupColumnDef = _.extend({}, this.defaultAutoGroupColumnDef, this.autoGroupColumnDef);
		this.typeSortLabels = this.initSortLabels();

		this.populateDefaultHeaderTooltip();

		if (this.tableFilterManager) {
			this.addSubscription(this.tableFilterManager.getFilterChangeObservable().subscribe(() => {
				this.gridApi?.onFilterChanged();
				this.ref.detectChanges();
			}));
		}

		this.currentRowId = 0;
	}

	getRowId: GetRowIdFunc = (params: GetRowIdParams) => {
		return params.data.id || this.currentRowId++;
	};

	getDefaultTranslationsForAgGrid(): Record<string, string> {
		return {
			pinLeft: this.locale.getString('common.pinLeft'),
			pinRight: this.locale.getString('common.pinRight'),
			noPin: this.locale.getString('common.noPin'),

			autosizeThiscolumn: this.locale.getString('common.autosizeThisColumn'),
			autosizeAllColumns: this.locale.getString('common.autosizeAllColumns'),

			resetColumns: this.locale.getString('common.resetColumns'),
			expandAll: this.locale.getString('common.expandAll'),
			collapseAll: this.locale.getString('common.collapseAll'),
			selectAll: this.locale.getString('common.selectAll'),

			pinColumn: this.locale.getString('common.pinColumn'),
			search: this.locale.getString('common.search'),

			hasAnyValue: this.locale.getString('reportFilters.hasAnyValue'),
			hasNoValue: this.locale.getString('reportFilters.hasNoValue'),

			contains: this.locale.getString('common.contains'),
			notContains: this.locale.getString('reportFilters.doesNotContain'),

			blanks: this.locale.getString('dashboard.blanksFilter'),
			blank: this.locale.getString('dashboard.blank'),
			notBlank: this.locale.getString('dashboard.notBlank'),
			equals: this.locale.getString('dashboard.equal'),
			notEqual: this.locale.getString('dashboard.notEqual'),
			inRange: this.locale.getString('dashboard.inRange'),

			lessThan: this.locale.getString('metrics.lt'),
			greaterThan: this.locale.getString('metrics.gt'),
			lessThanOrEqual: this.locale.getString('metrics.lte'),
			greaterThanOrEqual: this.locale.getString('metrics.gte'),

			filterOoo: this.locale.getString('common.filter'),
			searchOoo: this.locale.getString('common.search'),

			andCondition: this.locale.getString('common.and').toUpperCase(),
			orCondition: this.locale.getString('common.or').toUpperCase(),

			resetFilter: this.locale.getString('common.clearFilter'),
		};
	}

	private initSortLabels(): Record<string, Record<string, string>> {
		return {
			textColumn: {
				asc: this.locale.getString('common.sortAZ'),
				desc: this.locale.getString('common.sortZA')
			},
			numberColumn: {
				asc: this.locale.getString('common.sortLowToHigh'),
				desc: this.locale.getString('common.sortHighToLow')
			},
			dateColumn: {
				asc: this.locale.getString('common.sortByLeastRecent'),
				desc: this.locale.getString('common.sortByMostRecent')
			}
		};
	}

	private populateDefaultHeaderTooltip() {
		this.columnDefs.forEach(columnDef =>
			columnDef.headerTooltip = columnDef.headerTooltip || columnDef.headerName);
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.restoreColumnState(params.columnApi);
		this.gridReady.emit(params);
	}

	getQuickFilter(): string {
		return this.quickFilterText || this.tableFilterManager?.getQuickFilter();
	}

	getAutoGroupColumnName(): string {
		return this.autoGroupColumnName ? this.autoGroupColumnName : super.getAutoGroupColumnName();
	}

	getNameField(): string {
		return this.nameField;
	}

	getNameCellIcon(data: AgGridNestable): string {
		return this.getNameIcon?.(data);
	}

	isCheckboxVisible(): boolean {
		return this.showCheckbox;
	}

	isRowSelectable(rowNode: RowNode): boolean {
		return !ObjectListUtils.isFolder(rowNode.data);
	}

	onObjectClick(object: any): void {
		this.rowAction.emit(object);
	}

	getMainMenuItems = (params) => {
		const newMenuItems: (string | MenuItemDef)[] = [];
		const defaultItems = [...params.defaultItems];

		const sort = (type: string | null): void => {
			const prevWidths: number[] = params.columnApi.columnModel.gridColumns.map(col => col.actualWidth);
			const colIds: string[] = params.columnApi.columnModel.gridColumns.map(col => col.colId);

			params.columnApi.applyColumnState({
				state: [ { colId: params.column.colId, sort: type, actualWidth: params.column.actualWidth }],
				defaultState: { sort: null }
			});

			colIds.forEach( (id, index) => params.columnApi.setColumnWidth(id, prevWidths[index]));
		};

		if (params.column.colDef.sortable) {
			newMenuItems.push('separator');

			const sortLabels = this.getSortLabels(params.column.colDef);

			newMenuItems.push({
				name: sortLabels.asc,
				action: () => sort('asc')
			});

			newMenuItems.push({
				name: sortLabels.desc,
				action: () => sort('desc')
			});

			newMenuItems.push('separator');
		}

		defaultItems.insertAll(1, newMenuItems);

		return defaultItems;
	};

	private getSortLabels(colDef: ExtendedColDef): SortLabels {
		return colDef.headerMenuSortLabels || this.getSortTypeLabels(colDef);
	}

	private getSortTypeLabels(colDef: ColDef): SortLabels {
		let labels = this.typeSortLabels;
		let types = _.isArray(colDef.type) ? colDef.type : [colDef.type];
		for (let type of types) {
			if (labels[type]) {
				return labels[type];
			}
		}
		return labels[ColumnType.TEXT];
	}

	isExternalFilterPresent = (): boolean => {
		return this.tableFilterManager?.hasFilter();
	};

	doesExternalFilterPass = (node: RowNode): boolean => {
		return this.tableFilterManager?.doesPassFilter(node);
	};

	private saveColumnState(columnApi: ColumnApi): void {
		if (this.tableName) {
			const columnState = columnApi.getColumnState().map(state => _.pick(state, [
				'colId',
				'width',
				'hide',
				'pinned',
				'flex',
			]));
			this.localStorageService.set(`ag-grid.${this.tableName}`, JSON.stringify(columnState));
		}
	}

	private restoreColumnState(columnApi: ColumnApi): void {
		if (this.tableName) {
			const columnState = this.localStorageService.get<string>(`ag-grid.${this.tableName}`);
			if (columnState) {
				const state = JSON.parse(columnState);
				columnApi.applyColumnState({state, applyOrder: true});
			}
		}
	}

}
