import { SimpleContainerScroller } from '@app/modules/scroll-carousel/scrollers/simple-container-scroller.class';

export enum ScrollSpeed {
	SLOW = 50, 
	NORMAL = 100, 
	FAST = 200
}

type ITimeoutLike = (fn: () => any, delay: number) => any;

export class DragDropContainerScroller extends SimpleContainerScroller {

	private speed: ScrollSpeed = ScrollSpeed.NORMAL;

	constructor(
		timeoutFn: ITimeoutLike
	) {
		super(timeoutFn);
	}

	startSmoothScroll(speed: ScrollSpeed): void {
		this.speed = speed;
		if (!this.active) {
			this.active = true;
			this.doSmoothScroll();
		}
	}

	/**
	 * @param offset value between 0 and areaWidth. Bigger offset - higher speed
	 * @param scrollAreaWidth scrolling area width. Evenly divided by 3 parts, each has its own speed
	 */
	calculateSpeed(offset: number, scrollAreaWidth: number): ScrollSpeed {
		let speed = ScrollSpeed.FAST;
		if (offset < scrollAreaWidth * 2 / 3)
			speed = ScrollSpeed.NORMAL;
		if (offset < scrollAreaWidth / 3)
			speed = ScrollSpeed.SLOW;
		return speed;
	}

	private doSmoothScroll = () => {
		if (this.active) {
			this.singleSmoothScroll(this.speed);
			this.timeoutFn(this.doSmoothScroll, this.INTERVAL);
		}
	};

	private singleSmoothScroll(delta: number): void {
		this.element.animate({scrollLeft: this.element.scrollLeft() + delta}, this.ANIMATION_DELAY);
	}

}