import { IBookTab, BookTabType, BookDashboardTab } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export class BookComponentsSharingUtil {

	static getSharableBookDashboards = (dashboards: Dashboard[], tabs: IBookTab[]): Dashboard[] => {
		return tabs
			.filter(tab => tab.type === BookTabType.DASHBOARD)
			.map(tab => BookComponentsSharingUtil.getTabDashboard(dashboards, tab.properties as BookDashboardTab))
			.filter(dashboard => !!dashboard)
			.filter(BookComponentsSharingUtil.hasShareEditPermission);
	};

	private static getTabDashboard(dashboards: Dashboard[], tabData: BookDashboardTab): Dashboard {
		return _.findWhere(dashboards, { id: tabData.dashboardId });
	}

	private static hasShareEditPermission(dashboard: Dashboard): boolean {
		return dashboard.permissions.OWN
			|| (dashboard.permissions.EDIT && dashboard.properties.preventDashboardEditorsShare === false);
	}
}
