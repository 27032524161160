
import * as _ from 'underscore';
import SimpleGroupingMobileScreenSettings from './simple-grouping-mobile-screen-settings.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from './mobile-app-dialog-tab.enum';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import EventType from '@cxstudio/services/event/event-type.enum';

export default class SimpleGroupingMobileScreenConfigurationController implements ng.IComponentController {

	private static MAX_ALLOWED_CALCULATIONS = 3;

	screen: SimpleGroupingMobileScreenSettings;
	screenIndex: number;
	projectIdentifier: ProjectIdentifier;
	widgetSettingsCache: CachedInvocation;
	errors: Errors;

	constructor(
		private eventEmitterService: EventEmitterService
	) {
	}

	$onInit(): void {
		// required for typescript validation of IController
	}

	onGroupingChange = (grouping: ReportGrouping): void => {
		this.screen.grouping = grouping;
		this.errors.setValid([ MobileAppDialogTab.SCREEN_SETUP, `screen-${this.screenIndex}` ], 'select-grouping', !_.isEmpty(grouping));
		this.eventEmitterService.emit(EventType.UPDATE_CALCULATION_SELECTOR, {screenIndex: this.screenIndex, grouping});
	};

	onGroupingClear = (): void => {
		this.onGroupingChange(null);
		this.screen.additionalCalculations = [];
		this.eventEmitterService.emit(EventType.UPDATE_CALCULATION_SELECTOR, {screenIndex: this.screenIndex, grouping: null});
	};

	onUpdateSortBy = (calculation: any): void => {
		this.screen.additionalCalculations = [ calculation ];
	};

	onCalculationChange = (calculation: any, index: number): void => {
		this.screen.calculations[index] = calculation;
		this.errors.setValid([ MobileAppDialogTab.SCREEN_SETUP, `screen-${this.screenIndex}` ],
			`select-calculation-${index}`, !_.isEmpty(calculation));
	};

	onGroupingCalculationChange = (groupingCalculation: any): void => {
		this.screen.groupingCalculation = groupingCalculation;
		this.errors.setValid([ MobileAppDialogTab.SCREEN_SETUP, `screen-${this.screenIndex}` ],
			'select-grouping-calculation', !_.isEmpty(groupingCalculation));
	};

	onGroupingCalculationClear = (): void => {
		this.onGroupingCalculationChange(null);
	};

	getMaxCalculationsCount = (): number => {
		return SimpleGroupingMobileScreenConfigurationController.MAX_ALLOWED_CALCULATIONS;
	};

	addCalculation = (): void => {
		if (this.screen.calculations.length < this.getMaxCalculationsCount()) {
			this.screen.calculations.push(null);
		}
	};

	removeCalculation = (index: number): void => {
		this.screen.calculations.splice(index, 1);
		this.errors.setValid([ MobileAppDialogTab.SCREEN_SETUP, `screen-${this.screenIndex}` ], `select-calculation-${index}`, true);
	};

}

app.component('simpleGroupingMobileScreenConfiguration', {
	controller: SimpleGroupingMobileScreenConfigurationController,
	templateUrl: 'partials/mobile/simple-grouping-mobile-screen-configuration.html',
	bindings: {
		screen: '=',
		screenIndex: '=',
		projectIdentifier: '<',
		widgetSettingsCache: '=?',
		errors: '='
	}
});
