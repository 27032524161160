import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';

@Component({
	selector: 'book-simple-tab',
	template: `
	<div id="tab-title" class="ph-32 h-100-percent pv-8 _t-tab-title kb-focusable-inset d-flex flex-direction-row"
		[attr.tabindex]="index"
		(click)="tabSelect.emit()"
		(keydown)="onTabTitleKeydown($event)">
		<ng-content></ng-content>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookSimpleTabComponent implements OnInit {
	@Input() index: number;
	@Output() tabSelect = new EventEmitter<void>();

	ngOnInit(): void {
		// Set default value for index if not provided
		this.index = this.index ?? 0;
	}

	onTabTitleKeydown(event: KeyboardEvent) {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.tabSelect.emit();
		} else if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			KeyboardUtils.intercept(event);
			this.shiftInsideTab(event);
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.intercept(event);
			this.shiftToBookHeader();
		} else if (KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			KeyboardUtils.intercept(event);
			this.shiftToNextTabTitle(event);
		} else if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
			KeyboardUtils.intercept(event);
			this.shiftToPreviousTabTitle(event);
		}
	}

	private shiftInsideTab(event: KeyboardEvent): void {
		let qualtricsTabCheck: boolean = $(event.target).find('.qualtrics-tab').length === 1;

		if (qualtricsTabCheck) {
			$(event.target).find('.qualtrics-tab').first().trigger('focus');
		} else {
			$('.dashboard-tab-view.active :focusable').first().trigger('focus');
		}
	}

	private shiftToBookHeader(): void {
		$('.br-book-header :focusable').last().trigger('focus');
	}

	private shiftToNextTabTitle(event: KeyboardEvent): void {
		let titleElements: JQuery = $('.dashboard-tab #tab-title');
		let currentTabIndex: number = _.indexOf(titleElements, event.target as HTMLElement);
		if (currentTabIndex === titleElements.length - 1) {
			$(`#book-tab-0 #tab-title`).trigger('focus');
		} else {
			$(`#book-tab-${currentTabIndex + 1} #tab-title`).trigger('focus');
		}
	}

	private shiftToPreviousTabTitle(event: KeyboardEvent): void {
		let titleElements: JQuery = $('.dashboard-tab #tab-title');
		let currentTabIndex: number = _.indexOf(titleElements, event.target as HTMLElement);
		if (currentTabIndex === 0) {
			$(`#book-tab-${titleElements.length - 1} #tab-title`).trigger('focus');
		} else {
			$(`#book-tab-${currentTabIndex - 1} #tab-title`).trigger('focus');
		}
	}
}

app.directive('bookSimpleTab', downgradeComponent({component: BookSimpleTabComponent}));
