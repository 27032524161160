import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import IProjectsProvider from '@cxstudio/common/projects-provider-interface';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { ProjectAccessLevel } from '@cxstudio/user-administration/users/project-access/project-access-level-class';

export abstract class ProjectsProviderBase implements IProjectsProvider {
	contentProviders: ContentProvider[];
	currentContentProviderId: number;
	accounts: ContentProviderAccount[];
	currentAccountId: number;
	projects: AccountProject[];
	currentProjectId: number;
	currentProject: AccountProject;

	constructor() {
		this.contentProviders = [];
		this.accounts = [];
		this.projects = [];
	}

	abstract getContentProviders(): ng.IPromise<ContentProvider[]>;
	abstract getAccounts(cpId: number): ng.IPromise<ContentProviderAccount[]>;
	abstract getProjects(cpId: number, accountId: number, projectAccessLevel?: ProjectAccessLevel): ng.IPromise<AccountProject[]>;

	reloadContentProviders = () => {
		return this.getContentProviders().then((contentProviders) => {
			this.contentProviders = contentProviders;

			if (contentProviders && contentProviders.length > 0 ) {
				if (ValueUtils.isNotSelected(this.currentContentProviderId)) {
					this.currentContentProviderId = (this.contentProviders.length === 1) ? this.contentProviders[0].id : -1;
				} else {
					if (!_.findWhere(this.contentProviders, {id: this.currentContentProviderId})) {
						this.currentContentProviderId = -1;
					}
				}
			} else {
				this.currentContentProviderId = -1;
			}
		});
	};

	reloadAccounts = (contentProviderId: number): ng.IPromise<void> => {
		return this.getAccounts(contentProviderId).then((accounts) => {
			this.accounts = accounts;

			if (accounts && accounts.length > 0) {
				if (ValueUtils.isNotSelected(this.currentAccountId)
						|| !_.findWhere(this.accounts, {accountId: this.currentAccountId})) {
					this.currentAccountId = (this.accounts.length === 1) ? this.accounts[0].accountId : -1;
				}
			} else {
				this.currentAccountId = -1;
			}
		});
	};

	reloadProjects = (contentProviderId: number, accountId: number, projectAccessLevel?: ProjectAccessLevel) => {
		return this.getProjects(contentProviderId, accountId, projectAccessLevel).then(projects => {
			this.projects = projects;

			if (this.projects && this.projects.length > 0) {
				if (ValueUtils.isNotSelected(this.currentProjectId)
						|| !_.findWhere(this.projects, {projectId: this.currentProjectId})) {
					this.currentProjectId = (this.projects.length === 1) ? this.projects[0].projectId : -1;
					this.currentProject = (this.projects.length === 1) ? this.projects[0] : undefined;
				}
			} else {
				this.currentProjectId = -1;
			}
		});
	};

}