import Widget from '@cxstudio/dashboards/widgets/widget';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import ReportRunService from '@cxstudio/reports/report-run.service';
import { ReportDataApiService } from './report-data-api.service';

export interface IReportMetrics {
	studioMetrics: Metric[];
	predefinedMetrics: Metric[];
}

export class WidgetDataService {
	widget: Widget;
	priority: number;

	constructor(
		private reportDataApiService: ReportDataApiService,
		private reportRunService: ReportRunService,
		widget: Widget
	) {
		this.widget = widget;
		this.priority = widget ? (widget.posY * GridsterConfigurer.GRIDSTER_COLUMNS + widget.posX) : -1;
		if (isNaN(this.priority))
			this.priority = -1;
	}

	getReportData = (reportSettings): ng.IPromise<any> => {
		return this.reportRunService.runReport(this.widget, reportSettings, this.priority);
	};

	getAnSentenceData = (reportSettings): ng.IPromise<any> => {
		return this.reportRunService.runReport(this.widget, reportSettings, this.priority, this.reportDataApiService.getAnSentenceData);
	};

	getAnDocumentData = (reportSettings): ng.IPromise<any> => {
		return this.reportRunService.runReport(this.widget, reportSettings, this.priority, this.reportDataApiService.getAnDocumentData);
	};
}
