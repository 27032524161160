import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { WidgetEditingPreviewMode, WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { Security } from '@cxstudio/auth/security-service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { AnalyticCacheOptions } from '@cxstudio/reports/entities/analytic-cache-options';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { ComparisonValue, MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportRun } from '@cxstudio/reports/entities/report-run.interface';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { AppliedFilters, WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { WidgetDataServiceFactory } from '@cxstudio/services/data-services/widget-data-service.factory';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { RefinementBehavior } from '../refinement-behavior-type';
import { ReportRunHelperService } from '../utils/report-run-helper/report-run-helper.service';
import { RequestSourceType } from './request-source-type';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';

@Injectable({
	providedIn: 'root'
})
export class RealDataPreviewService {

	private readonly SUPPORTED_WIDGETS: string[] = [
		WidgetType.BAR,
		WidgetType.LINE,
		WidgetType.CLOUD,
		WidgetType.PIE,
		WidgetType.SCATTER,
		WidgetType.HEATMAP,
		WidgetType.NETWORK,
		WidgetType.SELECTOR,
		WidgetType.MAP,
		WidgetType.METRIC
	];
	private previewHasChanges: boolean;

	private readonly previewHasChangesChangeSubject = new Subject<boolean>();

	// when preview widget tab changes
	private readonly tabModifiedChangeSubject = new Subject<boolean>();

	// when report should be run
	private readonly reportRunTriggerSubject: Subject<void> = new Subject<void>();

	private readonly previewUIChangeSubject = new Subject<void>();

	previousWidgetProps: WidgetProperties;

	constructor(
		private readonly reportRunHelperService: ReportRunHelperService,
		@Inject('widgetDataServiceFactory') private readonly widgetDataServiceFactory: WidgetDataServiceFactory,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		@Inject('security') private security: Security
	) {
		this.previewHasChanges = false;
	}

	checkChangesForPreview = (widget: Widget): void => {
		let currWidgetProps = widget.properties;
		this.runWidgetPropCompare(currWidgetProps);
	};

	private runWidgetPropCompare = (currWidgetProps: WidgetProperties): void => {
		if (!this.previousWidgetProps) {
			this.setPropsAndChanges(currWidgetProps, true);
			return;
		}

		if (this.hasDateChanges(currWidgetProps)
			|| this.hasTimingChanges(currWidgetProps)
			|| this.hasAttributeChanges(currWidgetProps.selectedAttributes)
			|| this.hasMetricChanges(currWidgetProps.selectedMetrics)
			|| this.hasWidgetSpecificChanges(currWidgetProps)) {
			this.setPropsAndChanges(currWidgetProps, true);
		} else {
			this.setPrevWidgetProps(currWidgetProps);
		}

	};

	private hasDateChanges(currWidgetProps: WidgetProperties): boolean {
		if (currWidgetProps.useHistoricPeriod !== this.previousWidgetProps.useHistoricPeriod) {
			return true;
		}
		if (currWidgetProps.useHistoricPeriod && !_.isUndefined(this.previousWidgetProps.dateRangeP2)
			&& !this.isSameDateRange(currWidgetProps.dateRangeP2, this.previousWidgetProps.dateRangeP2)) {
			return true;
		}

		if (!_.isUndefined(this.previousWidgetProps.dateRangeP1)
			&& !this.isSameDateRange(currWidgetProps.dateRangeP1, this.previousWidgetProps.dateRangeP1)) {
			return true;
		}
	}

	private isSameDateRange(dateRange1: DateFilter, dateRange2: DateFilter): boolean {
		if (dateRange1.dateFilterMode !== dateRange2.dateFilterMode)
			return false;
		if (dateRange1.dateFilterMode === DateFilterMode.CUSTOM) {
			return dateRange1.from === dateRange2.from && dateRange1.to === dateRange2.to;
		}
		return true;
	}

	private hasTimingChanges(currWidgetProps: WidgetProperties): boolean {
		let keys = ['metricType', 'name', 'trendBy', 'timeName', 'size', 'dateAttributeType', 'emptyPeriodType'];

		if (!_.isEqual(_.pick(currWidgetProps.primaryTimeGrouping, keys),
			_.pick(this.previousWidgetProps.primaryTimeGrouping, keys))) {
			return true;
		}
	}

	private hasAttributeChanges(currSelectedAttributes: AttributeGrouping[]): boolean {
		let currentSelectedAttributes: AttributeGrouping[]  = _.filter(currSelectedAttributes, attribute => !_.isUndefined(attribute));

		let prevSelectedAttributes: AttributeGrouping[] = this.previousWidgetProps.selectedAttributes;
		let previousSelectedAttributes: AttributeGrouping[] = _.filter(prevSelectedAttributes, attribute => !_.isUndefined(attribute));

		if (_.size(currentSelectedAttributes) !== _.size(previousSelectedAttributes)) {
			return true;
		} else {
			return currentSelectedAttributes.some((attr, i) => {
				if (this.attributesNotEqual(attr, previousSelectedAttributes[i])) {
					return true;
				}
				return false;
			});
		}
	}

	private attributesNotEqual = (curAttObj, preAttObj): boolean => {
		if (!preAttObj && !curAttObj) return false;
		if ((!curAttObj && !!preAttObj) || (!!curAttObj && !preAttObj)) return true;

		let keys = ['metricType', 'name', 'type', 'identifier', 'size', 'displayThreshold', 'sortBy', 'nullInclude', 'sortOrder',
			'minDocCount'];
		if (_.isUndefined(preAttObj.displayThreshold)) {
			preAttObj.displayThreshold = {};
		}
		if (_.isUndefined(curAttObj.displayThreshold)) {
			curAttObj.displayThreshold = {};
		}
		if (_.isEqual(_.pick(curAttObj, keys), _.pick(preAttObj, keys))) {
			return this.specificAttributeTypesNotEqual(curAttObj, preAttObj);
		}
		return true;
	};

	private specificAttributeTypesNotEqual = (curAttObj: ReportGrouping, preAttObj: ReportGrouping): boolean => {
		if (AnalyticMetricTypes.isTopics(curAttObj)) {
			return !_.isEqual(this.getSpecificTopicsFieldsWithDefault(preAttObj), this.getSpecificTopicsFieldsWithDefault(curAttObj));
		} else if (curAttObj.type === ReportAssetType.TEXT || curAttObj.type === ReportAssetType.SYS) {
			return !_.isEqual(this.getSpecificTextOrSysFieldsWithDefault(preAttObj), this.getSpecificTextOrSysFieldsWithDefault(curAttObj));
		} else if (curAttObj.type === ReportAssetType.HIERARCHY_MODEL) {
			return !_.isEqual(this.getSpecificOHfieldsWithDefault(preAttObj), this.getSpecificOHfieldsWithDefault(curAttObj));
		}
	};

	private getSpecificTopicsFieldsWithDefault = (attObj: TopicReportGrouping) => {
		return {
			selectedLevel: _.isUndefined(attObj.selectedLevel) ? 1 : attObj.selectedLevel,
			selectedNodes: _.isUndefined(attObj.selectedNodes) ? [] : attObj.selectedNodes,
			inheritTopics: _.isUndefined(attObj.inheritTopics) ? false : attObj.inheritTopics,
			updateOnParentFilter: _.isUndefined(attObj.updateOnParentFilter) ? false : attObj.updateOnParentFilter,
		};
	};

	private getSpecificTextOrSysFieldsWithDefault = (attObj) => {
		return {
			wordsFilteringMode: _.isUndefined(attObj.wordsFilteringMode) ? WordsFilteringMode.EXCLUDE : attObj.wordsFilteringMode,
			wordsList: _.isUndefined(attObj.wordsList) ? [] : attObj.wordsList,
			inheritAttributeValues: _.isUndefined(attObj.inheritAttributeValues) ? false : attObj.inheritAttributeValues,
		};
	};

	private getSpecificOHfieldsWithDefault = (attObj) => {
		return {
			selectedLevel: _.isUndefined(attObj.selectedLevel) ? 1 : attObj.selectedLevel,
			peerReportType: _.isUndefined(attObj.peerReportType) ? '' : attObj.peerReportType,
			checkedInclusionNodes: _.isUndefined(attObj.checkedInclusionNodes) ? [] : attObj.checkedInclusionNodes,
		};
	};

	private hasMetricChanges(currWidgetSelectedMetrics: ReportCalculation[]): boolean {
		if (_.isUndefined(this.previousWidgetProps.selectedMetrics) || _.isUndefined(currWidgetSelectedMetrics)) {
			return;
		}
		if (_.size(currWidgetSelectedMetrics) > _.size(this.previousWidgetProps.selectedMetrics)) {
			return true;
		} else {
			return currWidgetSelectedMetrics.some((metric, i) => {
				if (this.metricsNotEqual(metric, this.previousWidgetProps.selectedMetrics[i])) {
					return true;
				}
				return false;
			});
		}
	}

	private hasWidgetSpecificChanges(currWidgetProps: WidgetProperties): boolean {
		let widgetType: WidgetType = currWidgetProps.widgetType;
		if (widgetType === WidgetType.NETWORK) {
			return this.networkSpecificPropsChanged(currWidgetProps);
		}

		if (widgetType === WidgetType.METRIC && currWidgetProps.selectedMetrics?.length !== 0) {
			return this.comparisonsChanged((currWidgetProps as MetricWidgetProperties).comparisons);
		}
	}

	private networkSpecificPropsChanged(currWidgetProps: WidgetProperties): boolean {
		let keys = ['minCoOccurrenceMetric', 'minCoOccurrence', 'documentLevelOnly'];

		return !_.isEqual(_.pick(currWidgetProps, keys), _.pick(this.previousWidgetProps, keys));
	}

	private comparisonsChanged(currComparisons: MetricWidgetComparison[]): boolean {
		if (currComparisons.length === 0) {
			return false;
		}

		let prevComparisons: MetricWidgetComparison[] = (this.previousWidgetProps as MetricWidgetProperties).comparisons;
		if (currComparisons.length > prevComparisons.length) {
			return true;
		}

		return currComparisons.some((currComparison, i) => {
			return this.comparisonPropertiesChanged(currComparison, prevComparisons[i]);
		});
	}

	private comparisonPropertiesChanged(currComparison: MetricWidgetComparison, prevComparison: MetricWidgetComparison): boolean {
		let keys = ['type', 'calculation'];

		return !_.isEqual(_.pick(currComparison, keys), _.pick(prevComparison, keys))
			|| this.comparisonValueChanged(currComparison.type, currComparison.value, prevComparison.value);
	}

	private comparisonValueChanged(type: MetricComparisonType, currValue: ComparisonValue, prevValue: ComparisonValue): boolean {
		switch (type) {
			case MetricComparisonType.GOAL:
				return currValue.goal !== prevValue.goal;
			case MetricComparisonType.TIME:
				if (currValue.dateFilterMode === DateFilterMode.CUSTOM && prevValue.dateFilterMode === DateFilterMode.CUSTOM) {
					return currValue.from !== prevValue.from || currValue.to !== prevValue.to;
				}

				return currValue.dateFilterMode !== prevValue.dateFilterMode;
			case MetricComparisonType.HIERARCHY_ENRICHMENT:
				return currValue.propertyName !== prevValue.propertyName;
		}
	}

	private metricsNotEqual = (currentMetric: ReportCalculation, previousMetric: ReportCalculation): boolean => {
		let keys = ['metricType', 'name', 'calculationType', 'popField'];

		return !_.isEqual(this.pickMetricProps(currentMetric, keys), this.pickMetricProps(previousMetric, keys));
	};

	private pickMetricProps(metric: ReportCalculation, keys: string[]): Partial<ReportCalculation> {
		return _.pick(metric, (value, key, object) => {
			return keys.contains(key) && !_.isUndefined(value);
		});
	}

	private setPropsAndChanges(currWidgetProps: WidgetProperties, prevChangeFlag: boolean): void {
		this.setPrevWidgetProps(currWidgetProps);
		this.setPreviewHasChanges(prevChangeFlag);
	}

	setPrevWidgetProps(currWidgetProps: WidgetProperties): void {
		this.previousWidgetProps = ObjectUtils.copy(currWidgetProps);
	}

	hasPreviewChanges(): boolean {
		return this.previewHasChanges;
	}

	setPreviewHasChanges(previewHasChanges: boolean): void {
		this.previewHasChanges = previewHasChanges;
		this.previewHasChangesChangeSubject.next(previewHasChanges);
	}

	getPreviewChangeObserver(): Observable<boolean> {
		return this.previewHasChangesChangeSubject;
	}

	notifyPreviewHasUIChanges(): void {
		this.previewUIChangeSubject.next();
	}

	getPreviewUIChangeObserver(): Observable<void> {
		return this.previewUIChangeSubject;
	}

	getTabModifiedObserver(): Observable<boolean> {
		return this.tabModifiedChangeSubject;
	}

	setTabModified(tabHasBeenModified: boolean): void {
		this.tabModifiedChangeSubject.next(tabHasBeenModified);
	}

	getReportRunTriggerObserver(): Observable<void> {
		return this.reportRunTriggerSubject;
	}

	triggerReportRun(): void {
		this.reportRunTriggerSubject.next();
	}


	showRealDataPreview(widgetType: WidgetType): boolean {
		return this.SUPPORTED_WIDGETS.contains(widgetType) && this.isRealDataPreviewEnabled();
	}

	private isRealDataPreviewEnabled(): boolean {
		let currentMasterAccount: CurrentMasterAccount = this.security.getCurrentMasterAccount();
		let widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings = currentMasterAccount.widgetEditingPreviewsSettings;

		return widgetEditingPreviewsSettings?.widgetEditingPreviewMode !== WidgetEditingPreviewMode.OFF;
	}

	getData(reportRun: ReportRun, requestType: RequestSourceType): Promise<any> {
		return this.prepareDataForReportRun(reportRun).then(widget => {

			const runWidgetSettings: Widget = ObjectUtils.copy(widget);
			runWidgetSettings.properties.reportCacheOption = AnalyticCacheOptions.Try;
			runWidgetSettings.properties.cacheOption = AnalyticCacheOptions.Normal;

			this.filterEmptyFilters(runWidgetSettings.properties.appliedFilters);
			this.filterEmptyAdhocFilters(runWidgetSettings.properties.adhocFilter);

			let lastReportRequestTimestamp = moment.utc();
			this.reportRunHelperService.populateGetDataProperties(runWidgetSettings,
				lastReportRequestTimestamp, requestType);

			//request should always be optimized
			runWidgetSettings.properties.refinementBehavior = RefinementBehavior.OPTIMIZED;

			const postProcessSettings = this.reportRunHelperService.postProcessSettings(runWidgetSettings,
				this.currentWidgets.getDashboardHistory(widget.containerId), false);

			return postProcessSettings.then((updatedSettings: Widget) => {
				const retrieveDataCall: Promise<any> = PromiseUtils.wrap(
					this.widgetDataServiceFactory.getReportData(updatedSettings)
				);

				return retrieveDataCall.then((data) => {
					return data;
				});
			});
		});
	}

	private filterEmptyFilters(appliedFilters: AppliedFilters): void {
		if (!_.isUndefined(appliedFilters)) {
			appliedFilters.filters = _.filter(appliedFilters.filters, filter => {
				return filter.type !== FilterRuleType.empty;
			});
		}
	}

	private filterEmptyAdhocFilters(adhocFilters: AdhocFilter): void {
		if (!_.isUndefined(adhocFilters)) {
			adhocFilters.filterRules = _.filter(adhocFilters.filterRules, filter => {
				return filter.type !== FilterRuleType.empty;
			});
		}
	}

	private prepareDataForReportRun(reportRun: ReportRun): Promise<Widget> {
		let widget: Widget = reportRun.widget;
		const viewUtils: WidgetUtils = reportRun.utils;

		this.reportRunHelperService.updateInitialWidget(widget, viewUtils.metrics);
		widget = this.reportRunHelperService.getWidgetCopyForReportRun(widget);

		return this.reportRunHelperService.updateWidgetForReportRun(widget, viewUtils.attributes, viewUtils.metrics).then(() => {
			return widget;
		});
	}
}

app.service('realDataPreviewService', downgradeInjectable(RealDataPreviewService));
