<collapsing-panel disableCollapse="true" class="page-tab-content">
	<panel-heading>
		<span>{{'jms.failedMessages'|i18n}}</span>
		<refresh-trigger
			class="btn btn-primary ml-8"
			[refreshFunction]="loadMessages"
			refreshLimit="30">
		</refresh-trigger>
	</panel-heading>
	<panel-body>
		<ngb-accordion>
			<ngb-panel *ngFor="let type of getTypes()">
				<ng-template ngbPanelTitle>
					<span *ngIf="type === 'alerts'">{{'jms.alertsMessages'|i18n}}({{failedMessages[type].length}})</span>
					<span *ngIf="type === 'completion'">{{'jms.completionMessages'|i18n}}({{failedMessages[type].length}})</span>
				</ng-template>
				<ng-template ngbPanelContent>
					<div *ngFor="let messageItem of failedMessages[type]">
						<table>
							<tr *ngFor="let pair of messageItem | keyvalue">
								<td *ngIf="pair.key !== 'message'"> {{ pair.key}} </td>
								<td *ngIf="pair.key !== 'message'"> {{ pair.value }} </td>
							</tr>
							<tr *ngFor="let pair of messageItem.message | keyvalue">
								<td> {{pair.key}} </td> <td> {{ pair.value }} </td>
							</tr>
						</table>
						<button class="btn btn-secondary" (click)="processMessage(type, messageItem.messageId)">
							{{'jms.processMessage'|i18n}}
						</button>
						<hr>
					</div>
				</ng-template>
			</ngb-panel>
		</ngb-accordion>
	</panel-body>
</collapsing-panel>
