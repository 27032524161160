import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable({
	providedIn: 'root'
})
export class UserPropertiesApiService {
	constructor(
		private cxHttp: CxHttpService
	) {}

	updateProperties = (properties): Promise<any> => {
		return this.cxHttp.put('rest/user-properties', angular.toJson(properties));
	};

	confirmPreferredEmail = (code: string): Promise<void> => {
		return this.cxHttp.put(`rest/user-properties/confirm-preferred-email/${code}`, null, {local: true});
	};

	updateFavoriteProperties = (properties): Promise<any> => {
		return this.cxHttp.put('rest/user-properties/favorite', angular.toJson(properties));
	};

	getGridColumns = (gridType: GridTypes): Promise<any> => {
		return this.cxHttp.get(`rest/user-properties/grid-columns/${gridType}`);
	};

	updateGridColumns = (gridType: GridTypes, gridColumns: {[column: string]: boolean}): Promise<any> => {
		return this.cxHttp.put(`rest/user-properties/grid-columns/${gridType}`, gridColumns);
	};
}

app.service('userPropertiesApiService', downgradeInjectable(UserPropertiesApiService));
