
import * as _ from 'underscore';

export enum DropdownClass {
	DROPDOWN_LEFT = 'dropdown-left',
	DROPDOWN_RIGHT = 'dropdown-right'
}

export class DropdownOrientationUtils {

	readonly PADDING = {
		bottom: 30,
		left: 30,
		right: 50,
		top: 300 // extra padding to account for header
	};

	constructor(
		private $window: ng.IWindowService
	) {

	}

	getAvailableSpace = (clickEvent: JQuery.Event) => {
		return {
			right: this.$window.innerWidth - clickEvent.clientX - this.PADDING.right,
			left: clickEvent.clientX - this.PADDING.left,
			bottom: this.$window.innerHeight - clickEvent.clientY - this.PADDING.bottom,
			top: clickEvent.clientY - this.PADDING.top,
		};
	};

	getDropdownDirectionClass = (element: ng.IAugmentedJQuery): DropdownClass => {
		return $(element)[0]?.getBoundingClientRect().left < this.$window.innerWidth / 2
			? DropdownClass.DROPDOWN_RIGHT
			: DropdownClass.DROPDOWN_LEFT;
	};

}

app.service('dropdownOrientationUtils', DropdownOrientationUtils);
