import * as _ from 'underscore';
import { BetaFeatureTracker, FeatureType } from './beta-feature-tracker-class';

const DEFAULT_ENABLED = true;

export class BetaFeature {
	static VERBATIM_ALERTING =           BetaFeatureTracker.beta('new_alerting');
	static METRIC_ALERT_CASES =          BetaFeatureTracker.beta('metric_alert_cases');
	static NEW_TABLES =                  BetaFeatureTracker.beta('new_tables');
	static NEW_METRIC_ALERT_WIZARD =     BetaFeatureTracker.beta('new_metric_alert_wizard');
	static NEW_PROJECTS_PAGE =           BetaFeatureTracker.beta('new_projects_page');
	static PRODUCT_FEEDBACK =            BetaFeatureTracker.beta('product_feedback');
	static AUTOMATED_NARRATIVES =        BetaFeatureTracker.beta('automated_narratives');
	static VOICE_PLAYBACK_MERCURY =      BetaFeatureTracker.beta('voice_playback_mercury');
	static SAMPLED_AUDITS =              BetaFeatureTracker.beta('sampled_audits');
	static METRIC_WIDGET_SMALL_AFFIX =   BetaFeatureTracker.beta('metric_widget_small_affix');
	static TODO_CASE_NOTES =             BetaFeatureTracker.beta('todo_case_notes');
	static CASE_VIZ_ON_TRENDS =          BetaFeatureTracker.beta('case_viz_on_trends');
	static QUALTRICS_WIDGETS =           BetaFeatureTracker.beta('qualtrics_widgets');
	static QUALTRICS_DASHBOARDS =        BetaFeatureTracker.beta('qualtrics_dashboards');
	static WORKSPACE =                   BetaFeatureTracker.beta('workspace');
	static ASSETS_EXP_OPTIMIZATION =     BetaFeatureTracker.beta('assets_exp_optimization');
	static SHARE_DASHBOARDS_EMBED =      BetaFeatureTracker.beta('share_dashboards_embed');
	static PACKAGED_SOLUTIONS =          BetaFeatureTracker.beta('packaged_solutions');
	static ADVANCED_CALENDARS =          BetaFeatureTracker.beta('advanced_calendars');
	static OH_IMPLIED_FILTER =           BetaFeatureTracker.beta('oh_implied_filter');
	static APPLICATION_SUITE_MENU =      BetaFeatureTracker.beta('applications_suite_menu');
	static RETRIEVE_REPORT_ASSETS =      BetaFeatureTracker.beta('retrieve_report_assets');
	static OH_ENRICHMENTS =              BetaFeatureTracker.beta('oh_enrichments');
	static REFRESHED_USER_UPLOAD =       BetaFeatureTracker.beta('refreshed_user_upload');
	static WIDGET_EDIT_OVERHAUL =        BetaFeatureTracker.beta('widget_edit_overhaul');
	static CB_STATED_DURATION =          BetaFeatureTracker.beta('cb_stated_duration');
	static QUALTRICS_SESSION_DIALOG =    BetaFeatureTracker.beta('qualtrics_session_dialog');
	static DASHBOARD_CONFIDENTIALITY =   BetaFeatureTracker.beta('dashboard_confidentiality');
	static FRONTLINE_DASHBOARDS =        BetaFeatureTracker.beta('frontline_dashboards');
	static PREVIOUS_WEEK_FILTERS =       BetaFeatureTracker.beta('previous_week_filters');
	static EMBED_DASHBOARDS =            BetaFeatureTracker.beta('embed_dashboards');
	static BAR_LINE_SUBTYPES =           BetaFeatureTracker.beta('bar_line_subtypes');
	static DYNAMIC_FILTERING_HIERARCHY = BetaFeatureTracker.beta('dynamic_filtering_hierarchy');
	static DASHBOARD_TRANSLATION =       BetaFeatureTracker.beta('dashboard_translation');
	static NEW_HIERARCHY_UPLOAD =        BetaFeatureTracker.beta('new_hierarchy_upload');
	static JAPANESE_UI =                 BetaFeatureTracker.beta('japanese_ui');
	static IMPROVED_FEEDBACK_EXPORT =    BetaFeatureTracker.beta('scheduled_preview_export');
	static AUTOMATED_SUMMARIES =         BetaFeatureTracker.beta('automated_summaries');
	static COACHING_ASSISTANT =          BetaFeatureTracker.beta('coaching_assistant');
	static COMPLIANCE_MANAGEMENT =       BetaFeatureTracker.beta('compliance_management');
	static MACHINE_LEARNING_SENTIMENT =  BetaFeatureTracker.beta('machine_learning_sentiment');
	static SHOW_RUBRICS_IN_PROJECTS_PAGE =  BetaFeatureTracker.beta('show_rubrics_in_projects_page');
	static DYNAMIC_REFERENCE_LINES =     BetaFeatureTracker.beta('dynamic_reference_lines');

	static CHAPTERS =                    BetaFeatureTracker.internalBeta('chapters');
	static ISSUE_RESOLUTION_ATTRIBUTES = BetaFeatureTracker.internalBeta('issue_resolution_attributes');
	static SPECIAL_DATE_ATTRIBUTES =     BetaFeatureTracker.internalBeta('special_date_attributes');
	static TEMPLATES_IMPORT_EXPORT =     BetaFeatureTracker.internalBeta('templates_import_export');
	static NEW_DASHBOARD_TEMPLATES =     BetaFeatureTracker.internalBeta('new_dashboard_templates');
	static METRIC_TEMPLATES =            BetaFeatureTracker.internalBeta('metric_templates');
	static FILTER_TEMPLATES =            BetaFeatureTracker.internalBeta('filter_templates');

	static VALUE_NORMALIZATION =         BetaFeatureTracker.controlledRelease('value_normalization');
	static COUNT_DISTINCT =              BetaFeatureTracker.controlledRelease('count_distinct');
	static TEMPLATE_FROM_DASHBOARD =     BetaFeatureTracker.controlledRelease('template_from_dashboard');
	static SCORECARDING =                BetaFeatureTracker.controlledRelease('scorecarding');

	static REPORT_DURING_CLASSIFY =      BetaFeatureTracker.spike('report_during_classify');

	static values = (): BetaFeatureTracker[] => {
		return _.chain(Object.keys(BetaFeature))
			.map(name => BetaFeature[name])
			.filter(value => !_.isFunction(value))
			.value();
	};

	static getGroup = (type: FeatureType): BetaFeatureTracker[] => {
		return _.where(BetaFeature.values(), { type });
	};

	static getDefaultBetaFeatures = (): BetaFeatureTracker[] => {
		return BetaFeature.values().filter(feature => feature.defaultState === true);
	};

}
