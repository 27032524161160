import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject } from '@angular/core';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';


@Component({
	selector: 'hierarchy-node',
	templateUrl: './hierarchy-node.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class HierarchyNodeComponent implements OnInit {

	@Input() node: IHierarchyNode;
	@Input() selectedNode: any;
	@Input() displayAttribute: string;
	@Input() groupLevel: number;
	@Input() personalization: any;
	@Input() showContext: boolean;
	@Input() isLast: boolean;
	@Output() nodeClick = new EventEmitter<IHierarchyNode>();
	
	constructor(
		@Inject('hierarchyService') private hierarchyService: HierarchyService
	) {}

	ngOnInit(): void {
		this.displayAttribute = this.displayAttribute || 'name';
	}

	isEmptyFolder = (node: IHierarchyNode) => {
		return this.isFolder(node) && (_.isEmpty(node.children));
	};

	isFolder = (node: IHierarchyNode) => {
		return /.*folder.*/i.test(node.type);
	};

	showItem = () => {
		return !this.isEmptyFolder(this.node);
	};

	nodePath = (node: IHierarchyNode) => {
		if (node.fullPath) {
			return this.hierarchyService.formatPathForDisplay(node.fullPath);
		}
		return node.name;
	};

	getNodeWidth = (node: IHierarchyNode) => {
		let maxWidth;
		if (this.isNeedToggle(node)) {
			maxWidth = 24;
		} else {
			maxWidth = 25;
		}
		return (maxWidth - (node.level)) + 'em';
	};

	isNeedToggle = (node: IHierarchyNode) => {
		return (node.children && node.children.length)
			|| this.isFolder(node);
	};

	expandNode = (node: IHierarchyNode) => {
		node.expanded = !node.expanded;
		if (this.node.children && node.expanded === true ) {
			this.personalization.hierarchyExpansion(this.node);
		}
	};

	onNodeClick = (node: IHierarchyNode) => {
		if (!node.disabled) {
			this.nodeClick.emit(node);
		}
	};

	mouseOver = ($event: ng.IAngularEvent) => {
		if (!this.showContext)
			return;
		$event.stopPropagation();
		if (!this.node.disabled) {
			this.personalization.hierarchyPath = this.node.ancestors.concat([{
				id: this.node.id, 
				name: this.node.name, 
				type: this.node.type
			} as IHierarchyNode]);
		}
	};

	mouseLeave = ($event: ng.IAngularEvent) => {
		if (!this.showContext)
			return;
		$event.stopPropagation();
		if (!this.node.disabled) {
			this.personalization.hierarchyPath = this.selectedNode.ancestors.concat([{
				id: this.selectedNode.id, 
				name: this.selectedNode.name, 
				type: this.selectedNode.type
			} as IHierarchyNode]);
		}
	};

	onNodeKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			KeyboardUtils.intercept(event);
			if (!this.node.disabled) {
				this.nodeClick.emit(this.node);
				setTimeout(() => {
					$('#hierarchy-dropdown-button').trigger('focus');
				});
			}
		} else if (KeyboardUtils.isEventKey(event, Key.ENTER, KeyModifier.CTRL)) {
			KeyboardUtils.intercept(event);
			this.expandNode(this.node);
		}
	};

	getNodeDisplay = (node: IHierarchyNode): string => {
		return node[this.displayAttribute];
	};
}
