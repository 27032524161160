import * as moment from 'moment-timezone';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { DatePointModes, DatePointMode, IPointValue } from '@cxstudio/reports/settings/date-point-constants';
import { DatePeriodType } from '@app/modules/utils/dates/date-period-constants.service';
import { IDateRuleDefinition } from '@cxstudio/reports/entities/adhoc-filter.class';
import { OnInit, Component, Output, EventEmitter, Input, Inject, ViewChild, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbDateAdapter, NgbDropdown, NgbDateStruct, NgbDatepicker, NgbDropdownToggle, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { DateRangeResolveContext } from '@app/modules/filter/entities/date-range-resolve-context';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';

export interface IDateFilterText {
	startDate: string;
	endDate: string;
}

@Component({
	selector: 'date-range',
	templateUrl: './date-range.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})
export class DateRangeComponent implements OnInit {
	@Input() dateRange: IDateRuleDefinition;
	@Output() dateRangeChange: EventEmitter<IDateRuleDefinition> = new EventEmitter();
	@Input() exactOnly: boolean;
	@Input() timezone: string;
	@Input() hideTime: boolean;
	@Input() projectSelection: AccountOrWorkspaceProject;
	@Input() disableDates: boolean;
	@Output() onChange: EventEmitter<void> = new EventEmitter();
	@ViewChild(NgbDropdown, {static: false}) ngDropdown: NgbDropdown;
	@ViewChild('picker', {static: false}) private picker: NgbDatepicker;
	@ViewChild('popup', {static: false}) private popup: ElementRef;
	@ViewChild(NgbDropdownToggle, {static: false}) private dropdownToggle: NgbDropdownToggle;

	datepickerOptions;
	fromModes;
	toModes;
	fromValue;
	toValue;
	loadingResolvedDateFilter;
	today: Date;
	resolvedDateFilterText: IDateFilterText;
	toSelectorHidden: boolean;
	appendToBody: boolean;

	pickerDate: NgbDateStruct;

	constructor(
		public adapter: NgbDateAdapter<Date>,
		private readonly filtersService: FiltersService,
		@Inject('dateFilterService') private dateFilterService: DateFilterService) { }

	ngOnInit(): void {
		this.init();
	}

	init(): void {
		this.datepickerOptions = {
			from: {showWeeks: false},
			to: {showWeeks: false}
		};

		this.clearResolvedDateFilterText();

		if (!this.exactOnly) {
			this.fromModes = DatePointModes.getPointModes('from');
			this.toModes = DatePointModes.getPointModes('to');
		} else {
			this.fromModes = this.toModes = DatePointMode.CUSTOM;
		}
		this.initDateCalendar();
		this.initializeDefaults();

		this.fromValue = this.getValue('from');
		this.toValue = this.getValue('to');
		this.loadingResolvedDateFilter = null;
	}

	initializeDefaults(): void {
		if (!this.dateRange.fromMode || !this.dateRange.toMode) {
			this.dateRange.fromMode = DatePointMode.CUSTOM;
			this.dateRange.fromDate = moment().startOf('month').format();

			this.dateRange.toMode = DatePointMode.CUSTOM;
			this.dateRange.toDate = moment().endOf('day').format();
		}
		if (!this.dateRange.fromPeriod) {
			this.dateRange.fromPeriod = DatePeriodType.MONTH;
			this.dateRange.fromAmount = 3;
		}
		if (!this.dateRange.toPeriod) {
			this.dateRange.toPeriod = DatePeriodType.MONTH;
			this.dateRange.toAmount = 0;
		}
		if (this.dateRange.fromMode === DatePointMode.CUSTOM)
			this.toSelectorHidden = true;
		this.updateDateLimits();
	}

	private updatePickerValue = (): void => {
		if (!this.picker) {
			return;
		}
		//only use date, ignore time and timezone
		let date = moment.parseZone(this.today).toObject();
		let ngbDate = this.fromDate(new Date(date.years, date.months, date.date));
		this.picker.navigateTo(ngbDate);
		this.picker.focusDate(ngbDate);
		this.picker.focusSelect();
	};

	onDropdownToggle = (open: boolean) => {
		if (open) {
			this.updatePickerValue();
			setTimeout(() => $(this.popup.nativeElement).find(':focusable').first().trigger('focus'));
		}
	};

	closeDropdown = (): void => {
		this.ngDropdown.close();
	};

	focusToggle = (event: KeyboardEvent): void => {
		KeyboardUtils.intercept(event);
		this.dropdownToggle.nativeElement.focus();
	};

	initDateCalendar(): void {
		this.today = new Date();
		this.updatePickerValue();
	}

	getValue(type): {date: any; period: any; amount: any} {
		return {
			date: this.dateRange[`${type}Date`],
			period: this.dateRange[`${type}Period`],
			amount: this.dateRange[`${type}Amount`]
		};
	}

	fromDate = (date: Date | string): NgbDateStruct => {
		return this.adapter.fromModel(new Date(date));
	};

	toDate = (date: NgbDateStruct): Date => {
		return this.adapter.toModel(date);
	};

	onFromValueChange(fromValue: {mode: DatePointMode; value: IPointValue}): void {
		let { mode, value } = fromValue;
		this.dateRange.fromMode = mode;
		if (mode === DatePointMode.CUSTOM) {
			if (!this.dateRange.fromDate) {
				this.updateDate('from', moment().startOf('month').format());
				this.updateDate('to', moment().endOf('day').format());
			} else {
				this.dateRange.fromDate = moment(value.date).startOf('minute').format(); // adjust seconds field as it can be changed by limit
			}
			if (moment(this.dateRange.fromDate).isAfter(moment(this.dateRange.toDate))) {
				this.updateDate('to', this.dateRange.fromDate);
			}
			this.toSelectorHidden = true;
			this.dateRange.toMode = DatePointMode.CUSTOM;
		} else {
			delete this.dateRange.fromDate;
			this.dateRange.fromPeriod = value.period;
			this.dateRange.fromAmount = value.amount;

			this.toSelectorHidden = false;
			if (this.dateRange.toMode === DatePointMode.CUSTOM) {
				this.dateRange.toMode = DatePointMode.TODAY;
				delete this.dateRange.toDate;
			}
		}

		this.fireChangeHandler();
		this.updateDateLimits();
	}

	onToValueChange(toValue: {mode: DatePointMode; value: IPointValue}): void {
		let { mode, value } = toValue;
		this.dateRange.toMode = mode;
		if (mode === DatePointMode.CUSTOM) {
			this.dateRange.toDate = moment(value.date).endOf('minute').format(); // adjust seconds field as it can be changed by limit
			if (moment(this.dateRange.toDate).isBefore(moment(this.dateRange.fromDate))) {
				this.updateDate('from', this.dateRange.toDate);
			}
		} else {
			delete this.dateRange.toDate;
			this.dateRange.toPeriod = value.period;
			this.dateRange.toAmount = value.amount;
		}
		this.fireChangeHandler();
		this.updateDateLimits();
	}

	fireChangeHandler(): void {
		this.dateRangeChange.emit(this.dateRange);
		this.onChange.emit();
	}

	updateDate(type, date): void {
		this[`${type}Value`].date = date;
		this.dateRange[`${type}Date`] = date;
	}

	updateDateLimits(): void {
		this.datepickerOptions.to.minDate = new Date(this.dateRange.fromDate);
		this.datepickerOptions.from.maxDate = new Date(this.dateRange.toDate);
	}

	isExactDateRange(): boolean {
		return this.dateRange.fromMode === DatePointMode.CUSTOM;
	}

	setAsToday(): void {
		this.today = new Date();
		this.updatePickerValue();
	}

	dateChange = (date: NgbDateStruct) => {
		this.today = this.toDate(date);
	};

	resolveDateFilter(today, dateRange): void {
		this.clearResolvedDateFilterText();

		this.loadingResolvedDateFilter = this.filtersService
			.resolveDateRange(this.projectSelection, this.toDateRangeFilter(today, dateRange))
			.then((result) => {
				this.resolvedDateFilterText.startDate = this.formatDate(result.startDate);
				this.resolvedDateFilterText.endDate = this.formatDate(result.endDate);
			}, _.noop);
	}

	private formatDate(date: string): string {
		if (this.hideTime) {
			return this.dateFilterService.formatDatePointWithTZ(date, undefined, false, false, DateTimeFormat.BASIC_DATE);
		} else {
			return this.dateFilterService.formatDatePointWithTZ(date, this.timezone, true, false);
		}
	}

	toDateRangeFilter(today, dateRange): DateRangeResolveContext {
		let dateRangeFilter: DateRangeResolveContext = {
			today: moment(today).format(),
			timezoneName: moment.tz.guess(),
			dateRangeFilter: {
				fromDate: {
					mode: dateRange.fromMode,
					period: dateRange.fromPeriod,
					amount: dateRange.fromAmount
				},
				toDate: {
					mode: dateRange.toMode,
					period: dateRange.toPeriod,
					amount: dateRange.toAmount
				},
				dateFilterMode: 'dynamic'
			},
			ignoreTime: this.hideTime
		};

		return dateRangeFilter;
	}

	hasResolvedDateFilterText(): boolean {
		return Boolean(this.resolvedDateFilterText
			&& this.resolvedDateFilterText.startDate
			&& this.resolvedDateFilterText.endDate);
	}

	clearResolvedDateFilterText(): void {
		this.resolvedDateFilterText = {
			startDate: '',
			endDate: ''
		};
	}
}


// same as dateFilters, but has 2 selectors
app.directive('dateRange', downgradeComponent({ component: DateRangeComponent }));
