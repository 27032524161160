import { DashboardTemplateService } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-service.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { DashboardTemplatesFolderApi } from '@cxstudio/dashboard-templates/api/dashboard-templates-folder-api.service';
import { ICustomTemplatesScope } from '@cxstudio/dashboard-templates/custom-templates.component';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { TemplateCommonService } from '@cxstudio/dashboard-templates/template-common-service.service';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { TreeService } from '@cxstudio/services/tree-service.service';



export interface ICustomTemplateActions {
	createDashboard(template: CustomTemplate): void;
	shareTemplate(template: CustomTemplate): void;
	unshareTemplate(template: CustomTemplate): void;
	confirmDeleteTemplate(template: CustomTemplate): void;
	deleteTemplate(template: CustomTemplate): void;
	upgradeTemplate(template: CustomTemplate): void;

	renameFolder(folder: IFolderItem): void;
	removeFolder(folder: IFolderItem): void;
	createFolder(parent?: IFolderItem): void;
	moveToFolder(item: CustomTemplate, folderTo: IFolderItem): void;

	editProperties(template: CustomTemplate): void;
	editMappings(template: CustomTemplate): void;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('CustomTemplateActions', function(
	dashboardTemplatesApiService: DashboardTemplatesApiService,
	cbDialogService: CBDialogService,
	locale: ILocale,
	templateCommonService: TemplateCommonService,
	dashboardTemplateService: DashboardTemplateService,
	FolderService,
	dashboardTemplatesFolderApi: DashboardTemplatesFolderApi,
	globalNotificationService: GlobalNotificationService,
	treeService: TreeService,
) {
	return class TemplateActionsService implements ICustomTemplateActions {
		private folderSvc: IFolderService;

		constructor(private scope: ICustomTemplatesScope) {
			this.folderSvc = new FolderService(FolderTypes.DASHBOARD_TEMPLATES);
		}

		createDashboard = (template: CustomTemplate) => templateCommonService.createDashboard(template);

		editProperties = (template: CustomTemplate) => {
			dashboardTemplateService.editDashboardTemplate(template).then(() => {
				this.scope.reloadCustomTemplates();
			});
		};

		editMappings = (template: CustomTemplate) => {
			dashboardTemplateService.editTemplateMappings(template).then(() => {
				this.scope.reloadCustomTemplates();
			});
		};

		shareTemplate = (template: CustomTemplate) => {
			this.changeTemplatePublicStatus(template, dashboardTemplatesApiService.shareTemplate);
		};

		unshareTemplate = (template: CustomTemplate) => {
			this.changeTemplatePublicStatus(template, dashboardTemplatesApiService.unshareTemplate);
		};

		private changeTemplatePublicStatus = (template: CustomTemplate, apiCall) => {
			this.scope.promises.operatingOnTemplate = apiCall(template.id).then(() => {
				this.scope.reloadCustomTemplates();
				this.scope.promises.operatingOnTemplate = null;
			});
		};

		confirmDeleteTemplate = (template: CustomTemplate) => {
			let deleteTemplatePromise = cbDialogService.danger(locale.getString('templates.deleteTemplateHeader', {templateName: template.name}),
				locale.getString('templates.deleteTemplateConfirmationMsg', {templateName: template.name})
			);
			deleteTemplatePromise.result.then(() => this.deleteTemplate(template));
		};

		deleteTemplate = (template: CustomTemplate) => {
			this.scope.promises.operatingOnTemplate = dashboardTemplatesApiService.deleteTemplate(template.id as number)
				.then(() => {
					this.scope.customTemplates.remove(template);
					this.scope.promises.operatingOnTemplate = null;
				});
		};

		upgradeTemplate = (template: CustomTemplate) => {
			this.scope.promises.operatingOnTemplate = dashboardTemplatesApiService.upgradeTemplate(template.id as number)
				.then(() => {
					this.scope.customTemplates.remove(template);
					this.scope.promises.operatingOnTemplate = null;
				});
		};

		renameFolder = (folder: IFolderItem): void => {
			this.folderSvc.renameFolder(folder, this.scope.customTemplates).then(() => {
				this.scope.redrawTemplates([folder]);
			});
		};

		removeFolder = (folder: IFolderItem): void => {
			this.folderSvc.removeFolder(folder, this.scope.customTemplates)
				.then((children) => this.scope.redrawTemplates(children))
				.then(() => globalNotificationService.deleteFolderNotification(folder.name));
		};

		createFolder = (parent?: IFolderItem): void => {
			this.folderSvc.createFolder(parent,
				this.scope.customTemplates)
			.then((created) => {
				this.scope.redrawTemplates([parent, created]);
			});
		};

		moveToFolder = (item: CustomTemplate, folderTo: IFolderItem): void => {
			if (!folderTo) {
				return;
			}

			let parent = item.parent;
			dashboardTemplatesFolderApi.moveToFolder(item, folderTo.id as number).then(() => {
				if (!folderTo.id || folderTo.id === 0)
					folderTo = null;

				treeService.moveItem(this.scope.customTemplates, item, folderTo);

				let refreshItems = [item, folderTo, parent];
				refreshItems = refreshItems.concat(this.traverseFolderChain(parent));
				refreshItems = refreshItems.concat(this.traverseFolderChain(item));

				this.scope.redrawTemplates(refreshItems);
			}).then(() => globalNotificationService.addItemMovedNotification(item.name, folderTo));
		};

		private traverseFolderChain = (item: CustomTemplate): any[] => {
			let levels = [];
			let currentLevel = item ? item.parent : undefined;
			while (currentLevel) {
				levels.push(currentLevel);
				currentLevel = currentLevel.parent;
			}
			return levels;
		};
	};
});
