import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ResponsiveDashboardService } from '@cxstudio/reports/responsiveness/responsive-dashboard-service';
import { ResponsiveReportService } from '@cxstudio/reports/responsiveness/responsive-report-service';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { Subscription } from 'rxjs';
import { DashboardRunStatus } from '../constants/dashboard-run-status';
import { HeadlessChromeRenderingStatus } from '../headless-chrome-render-status';

export class DashboardViewController implements ng.IController {
	//inputs
	dashboard: Dashboard;
	widgets: Widget[];
	containerId: string;
	personalization: PersonalizationState;
	dashboardHistory: IDashboardHistoryInstance;
	loaded: boolean;
	firstLoad: boolean;
	//outputs
	scrollEdgeChange: (edge: boolean) => void;

	pdfExport: boolean;

	private subscription: Subscription;

	constructor(
		private $scope: ISimpleScope,
		private $rootScope: ng.IRootScopeService,
		private currentWidgets: ICurrentWidgets,
		private reportUtils: ReportUtils,
		private dashboardExportService: DashboardExportService,
		private environmentService: EnvironmentService,
		private responsiveDashboardService: ResponsiveDashboardService,
		private dashboardRunHelperService: DashboardRunHelperService,
		private responsiveReportService: ResponsiveReportService,
	) {}

	$onInit(): void {
		this.pdfExport = this.environmentService.isPdfExport();
		(document as any).headlessChromeRenderingStatus = HeadlessChromeRenderingStatus.LOADING;
		this.subscription = this.personalization.getPostInitObserver().subscribe((status) => {
			if (!status) {
				this.initEventHandlers();
				this.initWidgets(this.widgets);
			}
		});
	}

	$onDestroy(): void {
		this.subscription?.unsubscribe();
	}

	private initWidgets(widgets): void {
		if (this.firstLoad) {
			DashboardUtils.sortWidgets(widgets);

			this.currentWidgets.setWidgets(this.containerId, widgets, this.dashboardHistory);
		}

		this.responsiveDashboardService.enterDashboard(Number.parseInt(this.containerId, 10));

		if (this.$rootScope.pdf) {
			let exportConfig = this.dashboardExportService.getExportConfig(widgets);

			if (exportConfig.pdfPageBreakEnabled) {
				this.recalculatePageHeightForExport(exportConfig);
			}
		}

		if (isEmpty(widgets)) {
			this.$rootScope.$broadcast('dashboardLoadingFinishedEvent');
			return;
		}

		// Register widget loading
		this.reportUtils.registerWidgetsRendering(widgets);
	}

	private recalculatePageHeightForExport(config): void {
		// We should consider height of the pageBreak widget
		let globalHeight = ((config.dashboardHeight - 1) * config.pixelPerUnit);
		let amountOfPages = Math.ceil(globalHeight / config.pageHeight);

		$('.br-global-view').height((amountOfPages * config.pageHeight) + 'px');
	}

	private initEventHandlers(): void {
		this.$scope.$on('dashboardLoadingFinishedEvent', () => {
			if (this.$rootScope.pdfToken) {
				// legacy loading indication
				(document as any).headlessChromeRenderingCallback = true;
				// external PDF service loading indication
				(document as any).headlessChromeRenderingStatus = HeadlessChromeRenderingStatus.READY;
			}
			this.dashboardRunHelperService.setRunStatus(this.dashboard?.id, DashboardRunStatus.COMPLETED);
		});
	}

	isResponsivenessEnabled = () => {
		return this.responsiveReportService.isResponsivenessEnabled();
	};
}

app.component('dashboardView', {
	controller: DashboardViewController,
	bindings: {
		dashboard: '<',
		widgets: '<',
		containerId: '<',
		dashboardHistory: '<',
		personalization: '<',
		loaded: '<',
		firstLoad: '<',
		scrollEdgeChange: '&',
	},
	templateUrl: 'partials/dashboard/dashboard-view-component.html',
});
