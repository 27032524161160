import { IActionsMenuItem } from '@app/modules/dashboard-actions/actions-menu/actions-menu.component';

export class MenuUtils {
	static getDivider<T>(): IActionsMenuItem<T> {
		return {
			isDivider: true,
			name: 'divider',
			onClick: this.ignoreClick
		};
	}

	static ignoreClick = (item, event: MouseEvent) => {
		event.stopPropagation();
	};
}
