import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';

export class DiscussionComponent implements ng.IComponentController {
	discussionRedrawTrigger = true;
	loading = {
		comments: false
	};
	dashboard: Dashboard;

	constructor(
		private $scope: ISimpleScope,
	) { }

	$onInit = () => {
		this.$scope.$watch(() => this.dashboard, (newValue, oldValue) => {
			if (oldValue === newValue) return;
			this.discussionRedrawTrigger = !this.discussionRedrawTrigger;
		});
	};
}

app.component('discussion', {
	bindings: {
		dashboard: '<',
		loading: '<'
	},
	controller: DiscussionComponent,
	template: `
<div>
	<div class="panel panel-default">
		<div class="panel-heading" floatable-handle>
			<b>{{::'comments.title'|i18n}} <span ng-if="$ctrl.commentCount && $ctrl.commentCount > 0">({{$ctrl.commentCount}})</span></b>
			<span class="q-icon-refresh rotate-infinite pull-right" ng-show="$ctrl.loading.comments || $ctrl.loading.users"></span>
		</div>
		<div class="panel-body">
			<discussion-widget
				trigger="$ctrl.discussionRedrawTrigger"
				object-id="$ctrl.dashboard.id"
				type="'dashboard'"
				comment-count="$ctrl.commentCount"
				loading="$ctrl.loading">
			</discussion-widget>
		</div>
	</div>
</div>`
});
