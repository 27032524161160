import * as _ from 'underscore';


export enum ColorPaletteNames {
	PALETTE_1 = 'clarabridge',			// formerly known as "clarabridge" but please use PALETTE_1
	PALETTE_2 = 'quantum',				// formerly known as "quantum" but please use PALETTE_2
	PALETTE_3 = 'sunset',				// formerly known as "sunset" but please use PALETTE_3
	PROVIDER_PALETTE = 'provider',		// comes from content provider -- not to be confused with single custom color
	INHERIT = 'inherit',				// inherit the parent color
	CUSTOM = 'custom',					// single custom color -- not to be confused with custom palette
	STUDIO_PALETTE = 'studio',
	SENTIMENT_3_BAND = 'sentiment3',
	SENTIMENT_5_BAND = 'sentiment5',
	LIGHTEN = 'lighten'
}

export class ColorPaletteConstants {

	static getPalette1 = (): string[] => [
		'#77a4e3',
		'#09b5cf',
		'#3765a7',
		'#f78e17',
		'#e65100',
		'#003668',
		'#007d91',
		'#6C6E82',
		'#549d3b',
		'#75c23b'
	];

	static getPalette2 = (): string[] => [
		'#5D7C76',
		'#92B7B0',
		'#C5E6E2',
		'#C0C1B9',
		'#252422',
		'#F7A58E',
		'#F05A49',
		'#CA2023',
		'#939597',
		'#D1D2D4'
	];

	static getPalette3 = (): string[] => [
		'#F5CF7A',
		'#E98F23',
		'#EE5E25',
		'#E82027',
		'#0B2433',
		'#094C67',
		'#1A7091',
		'#3AADC0',
		'#98D2DC',
		'#C7CED1'
	];

	static getStudioPalette = (isDark?: boolean): string[] => {
		return isDark ? [
				'#62ABED',
				'#47B4C1',
				'#B595F3',
				'#F78483',
				'#E55F06',
				'#8AC1F2',
				'#1897A5',
				'#DCCDF9',
				'#7CB559',
				'#FDA4A0'
			] : [
				'#278CE6',
				'#1897A5',
				'#9B70EF',
				'#EB555C',
				'#F8884B',
				'#006FD3',
				'#47B4C1',
				'#6827E6',
				'#529A2A',
				'#DA1C3D'
			];
	};
}
