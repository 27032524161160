import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';

export interface DocExplorerFilter {
	sentiment?: PredefinedFilterValue;
	easeScore?: PredefinedFilterValue;
	emotion?: PredefinedFilterValue;
	words?: string;
}

export class AppliedFiltersFactory {
	constructor(
	) {}

	getAppliedFilters(widgetMetadata: WidgetFiltersMetadata, showDashboardFilters: boolean, isDocExplorer: boolean): AppliedFilters {
		return new AppliedFilters(widgetMetadata, showDashboardFilters, isDocExplorer);
	}
}

app.service('appliedFiltersFactory', AppliedFiltersFactory);

export class AppliedFilters {

	constructor(
		private widgetMetadata: WidgetFiltersMetadata,
		private showDashboardFilters: boolean,
		private isDocExplorer: boolean,
	) {}

	getWidgetMetadata(): WidgetFiltersMetadata {
		return this.widgetMetadata;
	}

	private notEmptyOrTextFilter(filter: IFilterRule): boolean {
		return filter.type !== FilterRuleType.empty
			&& filter.type !== FilterRuleType.text;
	}

	private standardFiltersApplied(): boolean {
		return !_.isEmpty(this.widgetMetadata?.appliedFilters?.filters);
	}

	private adhocFiltersApplied(): boolean {
		return !!_.find(this.widgetMetadata?.adhocFilter?.filterRules, this.notEmptyOrTextFilter);
	}

	private drillFiltersApplied(): boolean {
		return !_.isEmpty(this.widgetMetadata?.parsedDrillFilters);
	}

	private modelGroupingFiltersApplied(): boolean {
		return !_.isEmpty(this.widgetMetadata?.parsedModelGroupingFilters);
	}

	dashboardFiltersApplied = (): boolean => {
		return this.showDashboardFilters && DashboardUtils.getNonHierarchyFiltersCount(this.widgetMetadata?.dashboardFilters) > 0;
	};

	drillToDashboardFiltersApplied = (): boolean => {
		return this.showDashboardFilters && !isEmpty(this.widgetMetadata?.drillToDashboardFilters);
	};

	showFilterDropdown = (): boolean => {
		return this.showAppliedFilters() || this.linkedFiltersApplied() || this.dashboardFiltersApplied()
			|| this.personalizationApplied() || this.drillToDashboardFiltersApplied()
			|| this.showTextFilter();
	};

	showAppliedFilters = (): boolean => {
		return !!this.getWidgetDateFilter() || this.standardFiltersApplied()
			|| this.adhocFiltersApplied()
			|| this.drillFiltersApplied() || this.modelGroupingFiltersApplied();
	};

	getWidgetDateFilter = () => {
		if (!this.widgetMetadata) {
			return;
		}

		return this.widgetMetadata.widgetDateFilter;
	};

	linkedFiltersApplied = (): boolean => {
		return !_.isEmpty(this.widgetMetadata?.parsedLinkedFilters);
	};

	personalizationApplied = (): boolean => {
		let personalization = this.widgetMetadata.personalization;
		return personalization && personalization.currentHierarchyNode && personalization.currentHierarchyNode.name;
	};

	ignorePersonalization = (): boolean => {
		return this.widgetMetadata.ignorePersonalization;
	};

	getPersonalizationString = (): string => {
		if (this.personalizationApplied()) {
			let personalization = this.widgetMetadata.personalization;
			let nodeName = personalization.currentHierarchyNode.name;
			if (!this.ignorePersonalization() && !!this.widgetMetadata.organizationFilterNode) {
				let parentNode = this.widgetMetadata.organizationFilterNode;
				nodeName = parentNode.name;
			}
			return `${personalization.currentHierarchyName}: ${nodeName}`;
		}
		return '';
	};

	docExplorerFiltersApplied = (): boolean => {
		return this.widgetMetadata?.docExplorerFilters?.length > 0;
	};


	showTextFilter = (): boolean => {
		return !this.isDocExplorer && !!_.find(this.widgetMetadata?.adhocFilter?.filterRules, {type: FilterRuleType.text});
	};

	notTextFilter = (filter: IFilterRule): boolean => {
		return filter.type !== FilterRuleType.text;
	};
}
