import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';

@Component({
	selector: 'selector-navigation-type',
	templateUrl: './selector-navigation-type.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectorNavigationTypeComponent {

	@Input() visualization: SelectorWidgetNavigationType;
	@Output() visualizationChange = new EventEmitter<SelectorWidgetNavigationType>();
	@Input() multiselect: boolean;
	@Output() multiselectChange = new EventEmitter<boolean>();

	options: Array<Partial<UIOption<SelectorWidgetNavigationType>>> = [
		{value: SelectorWidgetNavigationType.SELECTOR_BUTTON, displayName: this.locale.getString('common.button')},
		{value: SelectorWidgetNavigationType.SELECTOR_DROPLIST, displayName: this.locale.getString('common.droplist')},
		{value: SelectorWidgetNavigationType.SELECTOR_SEARCH, displayName: this.locale.getString('common.search')}
	];

	constructor(
		private locale: CxLocaleService,
	) {}

	isMultiSelectVisible(): boolean {
		return this.visualization !== SelectorWidgetNavigationType.SELECTOR_SEARCH;
	}
}

app.directive('selectorNavigationType', downgradeComponent({component: SelectorNavigationTypeComponent}));
