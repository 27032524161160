import { EventEmitter, Inject, Input, Component, ChangeDetectionStrategy, ViewChild, Output } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

export interface WidgetCacheSettings {
	enabled: boolean;
	acceptableAgeMinutes?: number | string;
	acceptableWaitingSeconds?: number | string;
}


@Component({
	selector: 'widget-cache-settings',
	templateUrl: './widget-cache-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetCacheSettingsComponent {

	readonly NUMBER_PATTERN = /^[0-9]*$/;

	loading: Promise<any>;
	options = {
		maxAcceptableAgeMinutes: 10800,
		maxAcceptableWaitingSeconds: 1200
	};

	@Input() widgetCacheSettings: WidgetCacheSettings;
	@Output() widgetCacheSettingsChange = new EventEmitter<WidgetCacheSettings>();
	@ViewChild('widgetCacheSettingsForm', {static: true}) public widgetCacheSettingsForm: UntypedFormGroup;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly toastService: ToastService,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService,
	) { }

	toggleWidgetCacheSettings = (): void => {
		const allowedToSave = !this.widgetCacheSettings?.enabled && this.widgetCacheSettingsForm?.valid;
		if (allowedToSave) {
			this.saveWidgetCacheSettings(false).then(() => {
				this.toastService.addToast(this.locale.getString('administration.featureDisabled',
					this.locale.getString('mAccount.widgetCacheFeatureName')), AlertLevel.CONFIRM);
			});
		}
		this.widgetCacheSettingsChange.emit(this.widgetCacheSettings);
	};

	saveWidgetCacheSettings = (enabled: boolean = true): Promise<void> => {
		this.loading = this.masterAccountApiService.saveWidgetCacheSettings(this.widgetCacheSettings).then(() => {
			if (enabled) {
				this.toastService.addToast(this.locale.getString('administration.featureEnabled',
					this.locale.getString('mAccount.widgetCacheFeatureName')), AlertLevel.CONFIRM);
			}
		}) as unknown as Promise<any>;
		return this.loading;
	};

	hasAcceptableAgeMinutesPatternError = (): boolean => {
		return this.widgetCacheSettingsForm?.controls?.acceptableAgeMinutes?.errors?.pattern;
	};

	hasAcceptableAgeMinutesRangeError = (): boolean => {
		return (this.widgetCacheSettingsForm?.controls?.acceptableAgeMinutes?.errors?.max
			|| this.widgetCacheSettingsForm?.controls?.acceptableAgeMinutes?.errors?.min);
	};

	hasAcceptableAgeSecondsPatternError = (): boolean => {
		return this.widgetCacheSettingsForm?.controls?.acceptableWaitingSeconds?.errors?.pattern;
	};

	hasAcceptableAgeSecondsRangeError = (): boolean => {
		return (this.widgetCacheSettingsForm?.controls?.acceptableWaitingSeconds?.errors?.max
			|| this.widgetCacheSettingsForm?.controls?.acceptableWaitingSeconds?.errors?.min);
	};

	isWidgetCacheSettingsFormValid = (): boolean => {
		return this.widgetCacheSettingsForm?.valid;
	};
}
app.directive('widgetCacheSettings', downgradeComponent({component: WidgetCacheSettingsComponent}));
