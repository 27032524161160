/* global app: true */

import { AppLoadingService } from '@cxstudio/app-loading';

(window as any).app = angular.module('brooklyn.dashboard', [
	'templates', // cached html templates
	'ngRoute',
	'ngCookies', // to store language, token, etc
	'ngDragDrop',
	'gridster',
	'ui.bootstrap.showErrors',
	'angularFileUpload',
	'ui.bootstrap', // modals, bootstrap elements, etc
	'cgBusy',
	'ngclipboard',
	'ngSanitize',
	'ngTagsInput',
	'mentio',
	'ngDraggable'
]);

app.run((appLoading: AppLoadingService, $rootScope) => {
	appLoading.enterApplication();

		$rootScope.safeApply = function(fn) {
		// eslint-disable-next-line no-invalid-this
		let phase = this.$root.$$phase;
		if (phase === '$apply' || phase === '$digest') {
			if (fn && (typeof (fn) === 'function')) {
				fn();
			}
		} else {
			// eslint-disable-next-line no-invalid-this
			this.$apply(fn);
		}
	};
});

