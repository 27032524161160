import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { DriverResourcesContainer } from '@cxstudio/drivers/editor/driver-resources-container';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

@Injectable({
	providedIn: 'root'
})
export class DriverResourcesProviderService {

	constructor(
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
	) {}

	getResources = (props: IProjectSelection): DriverResourcesContainer => {
		return new DriverResourcesContainer(props,
			this.projectContextService, this.attributesService, this.modelsService);
	};
}

app.service('driverResourcesProvider', downgradeInjectable(DriverResourcesProviderService));
