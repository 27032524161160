import ILocale from '@cxstudio/interfaces/locale-interface';

/**
 * Service for creating context menus
 */

import * as _ from 'underscore';
import { ContextMenuItem } from './context-menu-item';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';


export class ContextMenuTree {

	readonly OPTION_HEIGHT = 32; // used to estimate submenu height to prevent displaying out of screen
	readonly MENU_PADDINGS = 8 * 2; // 12 - top and bottom paddings
	readonly SEARCH_HEIGHT = 40;
	readonly MAX_SUBMENU_HEIGHT = 365;

	contextMenu: JQuery;
	modalClose: ng.IDeferred<void>;
	focusCallback?: () => void;
	focusOnOpen?: boolean;

	constructor(
		private locale: ILocale,
		private $rootScope: ng.IRootScopeService,
		private $compile: ng.ICompileService,
		private $q: ng.IQService
	) {
		this.contextMenu = undefined;
		this.focusCallback = undefined;
	}

	/**
	 * Helper method to show context menu on object lists
	 * Persists hover state on object that menu was opened from
	 */
	showObjectListMenu = (event, object, options, className?: string, maxWidth: number = 360,
			isBasicWidget?: boolean, focusCallback?: () => void): ng.IPromise<void> => {
		let row = $(event.target).closest('.slick-row');
		let burgerMenu = $(event.target).closest('.burger-wrap');

		row.addClass('hover');
		burgerMenu.attr('aria-expanded', 'true');

		return this.show(event, object, options, className, maxWidth, isBasicWidget, focusCallback).then(() => {
			row.removeClass('hover');
			burgerMenu.attr('aria-expanded', 'false');
		});
	};

	show = (event, object, options, className?: string, maxWidth?: number,
			isBasicWidget?: boolean, focusCallback?: () => void): ng.IPromise<void> => {
		this.focusCallback = focusCallback;
		this.focusOnOpen = KeyboardUtils.isKeyDown(event);
		let menu = this.compileMenuComponent(
			event, object, options, className, maxWidth, isBasicWidget);
		return this.appendMenu(menu);
	};

	showDrillMenu = (event, object, options): void => {
		let menu = this.compileDrillMenuComponent(event, object, options);
		this.appendMenu(menu);
	};

	showScorecardSelectMenu = (event, object, options): void => {
		let menu = this.compileScorecardSelectComponent(event, object, options);
		this.appendMenu(menu);
	};

	private appendMenu(menu): ng.IPromise<void> | undefined {
		if (this.isMenuOpened()) {
			return;
		}
		angular.element(document.body).append(menu);
		this.contextMenu = menu;
		this.modalClose = this.$q.defer();

		return this.modalClose.promise;
	}

	isMenuOpened = (): boolean => {
		return !_.isUndefined(this.contextMenu);
	};

	closeMenu = (): void => {
		if (this.contextMenu) {
			this.contextMenu.remove();
			this.modalClose.resolve();
			this.contextMenu = undefined;
		}
	};

	private getScope(options, object): ng.IScope {
		let newScope = this.$rootScope.$new();
		newScope.onMenuClose = () => {
			this.closeMenu();
			if (!this.focusCallback) {
				newScope.$destroy();
			}
		};
		newScope.onMenuInit = () => {
			if (!this.focusOnOpen) return;
			setTimeout(() => this.contextMenu.find('a').first().trigger('focus'));
		};
		newScope.onKeyAction = () => {
			if (this.focusCallback) {
				this.focusCallback();
				this.focusCallback = undefined;
				newScope.$destroy();
			}
		};
		newScope.options = options;
		newScope.object = object;

		return newScope;
	}

	private compileMenuComponent<T>(event: MouseEvent, object, options: Array<ContextMenuItem<T>>,
			className: string, maxWidth: number, isBasicWidget?: boolean): any | undefined {
		if (options === null || options.length === 0) {
			return;
		}

		let newScope = this.getScope(options, object);

		let height = this.getContainerHeight();
		let menuHeight = this.getSubmenuHeight(options.length + 1, false);

		let left, top;
		if (KeyboardUtils.isKeyDown(event)) {
			let target = $(event.target);
			let offset = target.offset();
			left = target.width() / 2 + offset.left;
			top = target.height() / 2 + offset.top;
		} else {
			left = event.pageX;
			top = event.pageY;
		}
		if (top + menuHeight > height) {
			top = height - menuHeight;
		}

		let attributes = this.getMenuAttributes(left, top, maxWidth);
		if (className) {
			attributes.push(`class-name="${className}"`);
		}

		if (isBasicWidget) {
			attributes.push('basic-widget-type');
		}

		let html = `<context-menu ${attributes.join(' ')}></context-menu>`;
		return this.$compile(html)(newScope);
	}

	private compileDrillMenuComponent<T>(event: MouseEvent, object, options: Array<ContextMenuItem<T>>): any {
		return this.compileNamedMenuComponent('drill-context-menu', event, object, options);
	}

	private compileScorecardSelectComponent<T>(event: MouseEvent, object, options: Array<ContextMenuItem<T>>): any {
		return this.compileNamedMenuComponent('scorecard-select-menu', event, object, options);
	}

	private compileNamedMenuComponent<T>(name: string, event: MouseEvent, object, options: Array<ContextMenuItem<T>>): any | undefined {
		if (options === null || options.length === 0) {
			return;
		}

		let newScope = this.getScope(options, object);
		let attributes = this.getMenuAttributes(event.pageX, event.pageY);

		let html = `<${name} ${attributes.join(' ')}></${name}>`;
		return this.$compile(html)(newScope);
	}


	private getMenuAttributes(left: number, top: number, maxWidth?: number): any[] {
		let attributes = [];
		attributes.push(`pos-x="${left}"`);
		attributes.push(`pos-y="${top}"`);
		if (maxWidth)
			attributes.push(`max-width="${maxWidth}"`);
		attributes.push('options=options');
		attributes.push('object=object');
		attributes.push('on-menu-close=onMenuClose()');
		attributes.push('on-menu-init=onMenuInit()');
		attributes.push('on-key-action=onKeyAction()');
		return attributes;
	}

	getContainerHeight = (): number => {
		return Math.max(
			document.body.scrollHeight, document.documentElement.scrollHeight,
			document.body.offsetHeight, document.documentElement.offsetHeight,
			document.body.clientHeight, document.documentElement.clientHeight
		);
	};

	getContainerWidth = (): number => {
		return Math.max(
			document.body.scrollWidth, document.documentElement.scrollWidth,
			document.body.offsetWidth, document.documentElement.offsetWidth,
			document.body.clientWidth, document.documentElement.clientWidth
		);
	};

	getContainerStyle = () => {
		let height = this.getContainerHeight();
		return {
			width: '100%',
			height: height + 'px',
			position: 'absolute',
			overflow: 'hidden',
			top: 0,
			left: 0,
			zIndex: 9999
		};
	};

	getPositionStyle = (posElement, clickX: number, clickY: number) => {
		let left = this.getMenuLeft(posElement, clickX);
		let top = this.getMenuTop(posElement, clickY);
		return {
			display: 'block',
			position: 'absolute',
			left: left + 'px',
			top: top + 'px'
		};
	};

	getMenuLeft = (posElement, clickX: number): number => {
		let width = this.getContainerWidth();
		let left = clickX;
		let elemWidth = $(posElement).width();
		if (clickX + elemWidth + 20 > width) {
			left = width - elemWidth - 20;
		}
		return left;
	};

	getMenuTop = (posElement, clickY: number): number => {
		let height = this.getContainerHeight();
		let top = clickY;
		let elemHeight = $(posElement).height();
		if (clickY + elemHeight + 20 > height) {
			top = height - elemHeight - 20;
		}
		return top;
	};

	getSubmenuHeight = (optionsCount: number, hasSearch: boolean): number => {
		let itemsHeight = Math.min(this.OPTION_HEIGHT * optionsCount, this.MAX_SUBMENU_HEIGHT);
		return this.MENU_PADDINGS + itemsHeight + (hasSearch ? this.SEARCH_HEIGHT : 0);
	};
}

app.service('contextMenuTree', ContextMenuTree);
