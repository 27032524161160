import { Input, Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';


@Component({
	selector: 'oauth-settings-tab',
	templateUrl: './oauth-settings-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthSettingsTabComponent {
	@Input() masterAccounts: MasterAccount[];

	constructor(
	) {}
}

app.directive('oauthSettingsTab', downgradeComponent({component: OAuthSettingsTabComponent}));
