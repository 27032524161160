import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { ArchivePageComponent, ListArchiveItem } from '@app/modules/archive/archive-page/archive-page.component';
import { SortDirection } from '@cxstudio/common/sort-direction';
import * as moment from 'moment';
import { SlickGridFormatter } from '@app/modules/item-grid/slick-grid-formatter.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable()
export class ArchiveGridDefinition implements IGridDefinition<ArchivePageComponent> {
	
	private authorFormatter;

	constructor(
		private locale: CxLocaleService, 
		private slickGridFormatter: SlickGridFormatter<ListArchiveItem>,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter
	) {
		this.authorFormatter = this.gridFormatterService.getAuthorFormatter();
	}

	init = (gridMode: GridMode, controller: ArchivePageComponent): Promise<IGridColumn[]> => {
		//Columns should be: Name, Object Type, Days Remaining, Date Archived, Owner, Project
		let columns = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'assetName',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-display-name',
			formatter: this.slickGridFormatter.getSimpleNameFormatter(GridTypes.ARCHIVE)
		}, {
			id: 'type',
			name: this.locale.getString('archive.objectType'),
			field: 'localizedAssetType',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'cell-object-type'
		}, {
			id: 'remaining',
			name: this.locale.getString('archive.daysRemaining'),
			field: 'archivedDate',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'cell-days-remining',
			formatter: this.remainingDaysFormatter
		}, {
			id: 'archived',
			name: this.locale.getString('archive.dateArchived'),
			field: 'archivedDate',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'cell-date-archived',
			defaultSortColumn: SortDirection.DESC,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'owner',
			name: this.locale.getString('common.owner'),
			field: 'ownerEmail',
			sortable: true,
			minWidth: 80,
			width: 260,
			cssClass: 'cell-owner-name',
			formatter: this.ownerFormatter
		}, {
			id: 'project',
			name: this.locale.getString('common.project'),
			field: 'projectName',
			sortable: true,
			minWidth: 80,
			width: 200,
			cssClass: 'cell-project-name'
		}];
		return Promise.resolve(columns);
	};

	private remainingDaysFormatter: IRowFormatter = (row, cell, value: string, columnDef, dataContext: ListArchiveItem): string => {
		let now = moment();
		let archived = moment(value);
		let daysFromArchived = now.diff(archived, 'days');
		let daysRemaining = 30 - daysFromArchived;
		return daysRemaining < 10 
			? `<span class="text-danger">${daysRemaining}</span>` 
			: `<span>${daysRemaining}</span>`;
	};

	private ownerFormatter: IRowFormatter = (row, cell, value: string, columnDef, dataContext: ListArchiveItem): string => {
		if (!value) return this.locale.getString('archive.noOwner');
		return this.authorFormatter(row, cell, value, columnDef, dataContext);
	};
}

app.service('archiveGridDefinition', downgradeInjectable(ArchiveGridDefinition));