import { TableColumn } from '@cxstudio/reports/entities/table-column';

import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';

interface StatusIconData {
	icon: string;
	label: string;
	class: string;
}

@Injectable()
export class DependenciesColumnsProvider {

	private statuses: {[status: string]: StatusIconData};

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('dateService') private readonly dateService,
	) {
		this.initStatuses();
	}

	private initStatuses = (): void => {
		this.statuses = {
			PRIVATE: {
				icon: 'q-icon-lock',
				label: this.locale.getString('dashboard.private'),
				class: 'br-private'
			},
			SHARED: {
				icon: 'q-icon-users',
				label: this.locale.getString('dashboard.shared'),
				class: 'br-shared'
			},
			PUBLIC: {
				icon: 'q-icon-globe',
				label: this.locale.getString('dashboard.public'),
				class: 'br-public'
			}
		};
	};

	getColumns = (type: ObjectType): Array<TableColumn<any>> => {
		let columns: Array<TableColumn<any>> = [];
		let objectName;
		if (type === ObjectType.DASHBOARD)
			objectName = this.locale.getString('dashboard.dashboard');
		if (type === ObjectType.FILTER)
			objectName = this.locale.getString('common.filter');
		if (type === ObjectType.METRIC)
			objectName = this.locale.getString('metrics.metric');
		if (type === ObjectType.ALERT)
			objectName = this.locale.getString('alert.alert');

		columns.push({
			name: 'name',
			displayName: objectName,
			formatter: this.defaultFormatter,
			width: 0.3
		});
		columns.push({
			name: 'owner',
			displayName: this.locale.getString('common.owner'),
			formatter: this.defaultFormatter,
			width: 0.4
		});
		if (type === ObjectType.DASHBOARD || type === ObjectType.ALERT) {
			columns.push({
				name: 'lastRun',
				displayName: type === ObjectType.ALERT ? this.locale.getString('alert.lastAlert') : this.locale.getString('dashboard.lastRun'),
				formatter: (object: any, field: string): string => {
					let value = object[field];
					if (!value) return value;
					let date: Date = new Date(value);
					return this.dateService.format(date);
				},
			});
		}
		if (type === ObjectType.METRIC) {
			columns.push({
				name: 'definitionTypeName',
				displayName: this.locale.getString('metrics.calculationType'),
				formatter: this.defaultFormatter
			});
		}
		columns.push({
			name: 'status',
			displayName: this.locale.getString('dashboard.status'),
			formatter: (object: any, field: string): string => {
				return this.getStatusIcon(object[field]);
			},
			width: 0.1,
			align: 'center'
		});
		return columns;
	};

	private defaultFormatter = (object: any, field: string): string => {
		return object[field];
	};

	private getStatusIcon = (status: string): string => {
		let statusData = this.statuses[status];
		let classes: string = `${statusData.icon} ${statusData.class}`;
		return `<span class='${classes}' title='${statusData.label}'></span>`;
	};
}

app.service('dependenciesColumnsProvider', downgradeInjectable(DependenciesColumnsProvider));
