import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'login-history-button',
	template: `
<a *ngIf="lastLoginDate" href="javascript:void(0)"
	class="text-0_875rem"
	(click)="showLoginHistory()">
	<span>{{'header.lastLogin'|i18n}}</span>:&nbsp;
	<span class="br-last-login-date">{{lastLoginDate | userTimeFormat}}</span>
</a>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginHistoryButtonComponent implements OnInit {

	lastLoginDate: string;

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
	) { }

	ngOnInit(): void {
		this.lastLoginDate = this.security.getLastLoginDate();
	}

	showLoginHistory(): void {
		const title = `${this.locale.getString('header.loginHistory')} ${this.security.getEmail()}`;
		this.cbDialogService.custom(title, 'partials/header/login-history-list.html', {}, this.locale.getString('common.close'),
			null, 'br-login-history');
	}

}

app.directive('loginHistoryButton', downgradeComponent({component: LoginHistoryButtonComponent}));
