import { Pagination } from '@app/shared/components/pagination/pagination';
import { PagedResponse } from '@cxstudio/paged-response';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';

import { AssetComponent } from '@cxstudio/asset-management/components/asset-component';
import { FilterComponent } from '@cxstudio/asset-management/components/filter-component';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';

import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { IExportHandler } from '@app/modules/asset-management/entities/export-handler.interface';
import { AssetApiUtils } from '@app/modules/asset-management/services/asset-api-utils.service';
import { DashboardComponents } from './dashboard-components-sharing-utils';


@Injectable()
export class DashboardComponentsApiService implements IExportHandler<StudioAsset> {

	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	export(asset: StudioAsset): Promise<void> {
		let url: string = `${ this.getBaseUrl(asset) }/export/xls`;
		let params = AssetApiUtils.getAssetNameQueryParams(asset);
		return this.cxHttp.get(url, {params});
	}

	getProjects = (asset: StudioAsset): Promise<AccountProject[]> => {
		let url: string = `rest/dashboard/${asset.assetId}/projects`;
		return this.cxHttp.get(url);
	};

	getSharableComponents(asset: ProjectAsset): Promise<DashboardComponents> {
		let url: string = `rest/dashboard/${asset.assetId}/components/sharable`;
		let params: HttpParams = AssetApiUtils.getAssetQueryParams(asset);
		return this.cxHttp.get(url, { params });
	}

	exportBookComponents = (exportData: any): Promise<void> => {
		let url: string = `rest/export/dashboard/book-components`;
		return this.cxHttp.post(url, exportData);
	};

	getAttributes = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<AssetComponent>> => {
		return this.getComponents(asset, 'attributes', pagination);
	};

	getModels = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<AssetComponent>> => {
		return this.getComponents(asset, 'models', pagination);
	};

	getFilters = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<FilterComponent>> => {
		return this.getComponents(asset, 'filters', pagination);
	};

	getMetrics = (asset: ProjectAsset, pagination: Pagination): Promise<PagedResponse<AssetComponent>> => {
		return this.getComponents(asset, 'metrics', pagination);
	};

	private getComponents(asset: ProjectAsset, postfix: string, pagination: Pagination): Promise<PagedResponse<FilterComponent>> {
		let baseUrl: string = this.getBaseUrl(asset);
		let url: string = `${baseUrl}/${postfix}`;

		let params: HttpParams = AssetApiUtils.getAssetPaginationQueryParams(asset, pagination);

		return this.cxHttp.get(url, { params });
	}

	private getBaseUrl = (asset: StudioAsset): string => {
		return `rest/dashboard/${asset.assetId}/components`;
	};

}

app.service('dashboardComponentsApiService', downgradeInjectable(DashboardComponentsApiService));
