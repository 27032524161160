import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';

@Component({
	selector: 'line-stack-settings',
	template: `
<div>
	<label for="lineStackType">{{'widget.stackType' | i18n}}</label>
	<cb-push-buttons id="lineStackType"
		[list]="stackOptions"
		[selectedValue]="innerValue"
		(selectedValueChange)="onChange($event)">
	</cb-push-buttons>
</div>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LineStackSettingsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LineStackSettingsComponent extends BaseControlValueAccessor<StackType> implements OnInit {

	stackOptions: UIOption<StackType>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref, StackType.NONE);
	}

	ngOnInit(): void {
		this.stackOptions = [
			{ displayName: this.locale.getString('widget.optionNone'), value: StackType.NONE },
			{ displayName: this.locale.getString('widget.optionStacked'), value: StackType.NORMAL },
			{ displayName: this.locale.getString('widget.optionStackedHundredPercent'), value: StackType.HUNDRED_PERCENT }
		];
	}

}
