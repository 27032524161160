import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { PromiseUtils } from '@app/util/promise-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers, IPointToFilter } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';

export class CustomToFilter implements IPointToFilter {
	constructor(
		private readonly metricApiService: MetricManagementApiService,
		private readonly analyticsDataFormatting: AnalyticsDataFormattingService,
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: ReportGrouping): DrillFilter | null => {
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);
		const value = point && point[identifier];

		if (_.isUndefined(value)) {
			return null;
		}
		const id = '' + groupBy.id;

		if (value === undefined)
			return null;

		return {
			type: DrillType.METRIC_STUDIO,
			name: id,
			values: [value]
		};
	};

	toFilterTypes = (parsers: DrillParsers): void => {
		[DrillType.METRIC_STUDIO]
			.forEach((type) => {
				parsers[type] = this;
			});
	};

	getRuleString = (filter: DrillFilter, widget: Widget): Promise<string | void> => {
		const metricId = parseInt(filter.name, 10);
		return PromiseUtils.wrap(this.metricApiService.getMetricDefinition(metricId).then((res: any) => {
			const displayName = res ? res.data.displayName : filter.name;
			const value = this.addConfiguredMetricNameToFilter(res, filter);
			return `${displayName}: ${value}`;
		}));
	};

	private readonly addConfiguredMetricNameToFilter = (res, filter: DrillFilter): string => {
		if (res && res.data && res.data.definition) {
			return this.analyticsDataFormatting.formatStudioMetricLabel(filter.values[0], res.data.definition);
		} else {
			return filter.values[0];
		}
	};
}

app.service('customToFilter', CustomToFilter);
