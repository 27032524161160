import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { TopicDrillUtils } from '@cxstudio/reports/utils/contextMenu/drill/topic-drill-utils.class';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import * as _ from 'underscore';

import IndeterminateTopicSelectionConfig from './indeterminate-topic-selection-config.interface';
import IndeterminateTopicSelection from './indeterminate-topic-selection.interface';
import TopicSelectionNode from './topic-selection-node.interface';

export class IndeterminateTopicSelectionImpl implements IndeterminateTopicSelection {

	constructor(private config: IndeterminateTopicSelectionConfig) {
	}

	updateSelectedLevel = (): void => {
		let highestLevel = _.max(this.config.getSelectedLevels());
		this.reduceSelectedLevel(highestLevel);
		this.processSelectedNodes();
	};

	private reduceSelectedLevel = (level: number): void => {
		let indeterminateNodes = this.getIndeterminateNodesAtLevel(level);
		this.getSelectedNodesWithHigherLevel(level).forEach(this.removeNode);
		indeterminateNodes.forEach(this.selectNode);
	};

	private getIndeterminateNodesAtLevel = (level: number): TopicSelectionNode[] => {
		return this.getModelNodesAtLevel(level).filter(this.isIndeterminateNode);
	};

	private getSelectedNodesWithHigherLevel = (level: number): TopicSelectionNode[] => {
		let currentSelection = this.config.getCurrentSelection();
		if (!currentSelection) {
			return [];
		}
		return currentSelection.filter(node => node.level > level);
	};

	isIndeterminateNode = (checkedNode: TopicSelectionNode): boolean => {
		if (this.config.isIndeterminateDisabled?.())
			return false;
		if (!checkedNode.children)
			return false;

		return _.some(checkedNode.children, (child => this.isNodeSelected(child) || this.isIndeterminateNode(child)));
	};

	private getModelNodeById = (id: number): TopicSelectionNode => {
		let modelTree = this.config.getModelTree();

		if (modelTree.id === id) {
			return modelTree;
		}

		return modelTree && modelTree.children
			? TopicDrillUtils.findInHierarchy(modelTree.children, 'id', id)
			: null;
	};

	private getModelNodesAtLevel = (level: number): TopicSelectionNode[] => {
		let modelTree = this.config.getModelTree();

		if (!modelTree || !modelTree.children) {
			return [];
		}

		return HierarchyUtils.findItems(modelTree, item => level === item.level);
	};

	private markNodeSelected = (node: TopicSelectionNode): void => {
		let currentSelection = this.config.getCurrentSelection();

		if (_.findIndex(currentSelection, {id: node.id}) < 0)
			currentSelection.push({id: node.id, level: node.level, children: (node.children && node.children.length) as any});
		this.config.setCurrentSelection(currentSelection);
	};

	private clearNodeSelection = (node: TopicSelectionNode, index?: number) => {
		let currentSelection = this.config.getCurrentSelection();

		index = index || _.findIndex(currentSelection, {id: node.id});
		if (index >= 0) {
			currentSelection.removeAt(index);
		}
		this.config.setCurrentSelection(currentSelection);
	};

	isNodeClickable = (node: TopicSelectionNode): boolean => {
		let maxLevel = _.max(this.config.getSelectedLevels());
		let minLevel = _.min(this.config.getSelectedLevels());
		if (this.config.isTopicLeafEnabled() || maxLevel === TopicReportGrouping.LEAF_LEVEL)
			return true;
		return node.level === maxLevel
			|| (node.level < maxLevel && node.depth > minLevel);
	};

	isNodeChecked = (checkedNode: TopicSelectionNode): boolean => {

		if (!this.isNodeClickable(checkedNode))
			return false;

		if (this.isNodeSelected(checkedNode))
			return true;

		let parent = this.getNodeParent(checkedNode);
		return !!(parent && this.isNodeChecked(parent));
	};

	isNodeHighlighted = (node: TopicSelectionNode): boolean => {
		let levels = this.config.getSelectedLevels();
		let nodePartiallyChecked = this.isNodeChecked(node) || this.isIndeterminateNode(node);
		let nodeMatchLevel = _.contains(levels, node.level);
		let nodeMatchLeaf = _.contains(levels, TopicReportGrouping.LEAF_LEVEL) && !node.children;
		return nodePartiallyChecked && (nodeMatchLevel || nodeMatchLeaf);
	};

	private getNodeParent = (node: TopicSelectionNode): TopicSelectionNode => {
		return node.parentId && this.getModelNodeById(node.parentId);
	};

	isNodeSelected = (node: TopicSelectionNode): boolean => {
		return _.findIndex(this.config.getCurrentSelection(), {id: node.id}) >= 0;
	};

	handleNodeClick = (clickedNode: TopicSelectionNode): void => {
		if (!clickedNode || !this.isNodeClickable(clickedNode))
			return;

		if (!this.isNodeChecked(clickedNode) && !this.isIndeterminateNode(clickedNode)) {
			this.selectNode(clickedNode);
		} else if (this.isNodeChecked(clickedNode) && !this.isLeafNode(clickedNode) && !this.config.isIndeterminateDisabled?.()) {
			this.indeterminateNode(clickedNode);
		} else {
			this.removeNode(clickedNode);
		}
		this.processSelectedNodes();
	};

	selectNode = (node: TopicSelectionNode): void => {
		if (this.isNodeClickable(node)) {
			this.removeSelectedChildrenRecursively(node);
			this.markNodeSelected(node);
		}
	};

	private indeterminateNode = (node: TopicSelectionNode): void => {
		if (!this.isNodeSelected(node)) {
			// node was checked because its parent is checked
			this.selectSiblingTree(node);
		}

		this.clearNodeSelection(node);
		if (node.children)
			node.children.forEach(this.markNodeSelected);
	};

	private removeNode = (node: TopicSelectionNode): void => {
		// if node was checked because its parent was checked
		if (!this.isNodeSelected(node) && !this.isIndeterminateNode(node)) {
			this.selectSiblingTree(node);
		}

		this.removeSelectedChildrenRecursively(node);
	};

	private selectSiblingTree = (node: TopicSelectionNode): void => {
		let nodeLevel: number = node.level;
		let selectAllProcessingRequired = nodeLevel > 2 && this.isSelectAll();

		// we select unselected node siblings
		if (nodeLevel !== 1) {
			this.selectSiblings(node);
		}

		if (selectAllProcessingRequired) {
			let parent: TopicSelectionNode = this.getNodeParent(node);
			this.selectSameFirstLevelParentChildren(parent);
		}

		// after we selected siblings we also need to select children from the other branches of that selected grand-parent
		let selectedParent = this.findSelectedParent(node);
		selectedParent.children
			.filter(child => !this.isIndeterminateNode(child))
			.forEach(this.selectNode);

		this.clearNodeSelection(selectedParent);
	};

	private isSelectAll = (): boolean => {
		let currentSelection = this.config.getCurrentSelection();
		return currentSelection && currentSelection.length === 1 && currentSelection[0].level === 0;
	};

	private selectSameFirstLevelParentChildren = (node: TopicSelectionNode): void => {
		let parent: TopicSelectionNode = this.getNodeParent(node);
		if (parent.level !== 0) {
			_.each(parent.children, child => {
				if (child.id !== node.id) {
					this.selectNode(child);
				}
			});
			this.selectSameFirstLevelParentChildren(parent);
		}
	};

	findSelectedParent = (node: TopicSelectionNode): TopicSelectionNode => {
		let parent = this.getNodeParent(node);
		while (!this.isNodeSelected(parent)) {
			parent = this.getNodeParent(parent);
		}

		return parent;
	};

	selectSiblings = (node: TopicSelectionNode): void => {
		let parent = this.getNodeParent(node);
		let siblings = parent.children
			.filter(child => child.id !== node.id);

		siblings.forEach(this.selectNode);
	};

	private removeSelectedChildrenRecursively = (node: TopicSelectionNode): void => {

		this.clearNodeSelection(node);

		if (!node.children)
			return;

		node.children.forEach(this.removeSelectedChildrenRecursively);
	};

	private isLeafNode = (node: TopicSelectionNode): boolean => {
		return this.config.isTopicLeafEnabled()
			? (!node.children || node.children.length === 0)
			: _.contains(this.config.getSelectedLevels(), node.level);
	};

	processSelectedNodes = (): void => {
		if (this.allNodesSelected()) {
			let modelTree = this.config.getModelTree();
			if (!!modelTree) {
				this.config.setSelectedNodes([ modelTree.id ]);
			}
		} else {
			let currentSelection = this.config.getCurrentSelection();
			if (!!currentSelection) {
				this.config.setSelectedNodes(currentSelection.map(checkedNode => checkedNode.id));
			}
		}
	};

	private allNodesSelected = (): boolean =>  {
		let modelTree = this.config.getModelTree();

		return modelTree && this.isNodeSelected(modelTree);
	};

	selectAll = (): void => {
		this.config.setCurrentSelection([]);

		let modelTree = this.config.getModelTree();
		this.selectNode(modelTree);
	};

	deselectAll = (): void => {
		this.config.setCurrentSelection([]);
	};

	processInclusionList = (): void => {
		this.config.setCurrentSelection([]);
		if (_.isUndefined(this.config.getSelectedNodes())) {
			this.selectAll();
		} else if (this.isRootSelected()) {
			this.selectAll();
		} else {
			this.config.getSelectedNodes()
				.map(this.getModelNodeById)
				.filter(node => !!node)
				.forEach(this.selectNode);
		}

		let rootNode = this.config.getModelTree();
		(rootNode as any)._autoexpanded = true;
		(rootNode as any)._hideExpansionToggle = true;
	};

	private isRootSelected = (): boolean => {
		let selectedNodes = this.config.getSelectedNodes();
		return selectedNodes.length === 1
			&& selectedNodes[0] === this.config.getModelTree().id;
	};

}
