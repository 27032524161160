import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { IReportWidgetContext, ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { WidgetPropertyService } from '@app/modules/widget-settings/services/widget-property-service.service';

export abstract class SimpleReportWidget extends ReportWidget {

	constructor(widgetType: WidgetType) {
		super(widgetType);
	}

	protected initSimpleReport(context: IReportWidgetContext): void {
		super.initFromContext(context);
		super.withFilters(context);

		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = [];
		this.widget.visualProperties.attributeSelections = {};

		super.withDateRange(context);
	}

	// filter - additional filter
	protected initSingleCalculation(context: IReportWidgetContext, defaultMetric: ReportCalculation, filter?): void {
		// always apply default filter
		filter = filter ? _.compose(this.defaultCalculationFilter, filter) : this.defaultCalculationFilter;
		let calculations = WidgetPropertyService.getValue('properties.selectedMetrics', context, []);
		calculations = filter(calculations);

		if (_.isEmpty(calculations))
			calculations = [defaultMetric]; // this.metricConstants.get().VOLUME

		let calculation = calculations[0];
		super.addCalculation(calculation);
		this.widget.visualProperties.attributeSelections.size = calculation;

		super.withColors(context);
	}

	private defaultCalculationFilter = (calculations: ReportCalculation[]) => {
		return _.reject(calculations, this.isPoPCalculation);
	};

	protected initGroupings(context: IReportWidgetContext, count: number, emptyAllowed: boolean): void {
		let groupings = WidgetPropertyService.getValue('properties.selectedAttributes', context, []);
		groupings = _.reject(groupings, this.isEmptyGroup);
		groupings = _.reject(groupings, AnalyticMetricTypes.isTime);
		groupings = _.first(groupings, count);
		if (groupings.length === 0 && !emptyAllowed) {
			groupings.push(undefined);
		}
		if (groupings.length > 1 && this.widgetType === WidgetType.HEATMAP) {
			this.widget.properties.normalizeGroups = context.source.properties.normalizeGroups;
		}
		groupings.forEach(grouping => {
			this.addGrouping(grouping);
		});
	}

	private addGrouping(grouping): void {
		if (grouping !== undefined)
			delete grouping.stackType;
		this.widget.properties.selectedAttributes.push(grouping);
	}
}
