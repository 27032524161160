import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import * as _ from 'underscore';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { MenuDividerShorthand, MenuOptionType } from './drill-menu-option.component';
import { HtmlUtils } from '@app/shared/util/html-utils.class';


export class ContextMenuOptionController implements ng.IController {


	option: IDrillMenuOption;
	object: any;
	level: number;
	active: boolean;
	bounded: boolean;
	onSelect: (params: {$option: IDrillMenuOption}) => void;
	onAction: () => void;
	onKeyAction: () => void;

	activeChild: IDrillMenuOption;
	selectedTreeNode: any;

	constructor(
		private $scope: ISimpleScope,
		private $timeout: ng.ITimeoutService
	) {}

	$onInit(): void {
		this.activeChild = undefined;
		this.$scope.$watch(() => this.active, (newValue, oldValue) => {
			if (newValue === oldValue) return;
			if (newValue === false) {
				this.activeChild = undefined;
				this.selectedTreeNode = undefined;
			}
		});
	}

	getOptionType = (): MenuOptionType => {
		if (this.option === MenuDividerShorthand || this.option.isDivider) {
			return MenuOptionType.DIVIDER;
		}

		if (this.option.tree) {
			return MenuOptionType.TREE;
		}

		if (this.option.selectable) {
			return MenuOptionType.SELECTABLE;
		}

		if (this.option.items) {
			return MenuOptionType.NESTED;
		}

		return MenuOptionType.ACTION;
	};

	select = (option) => {
		option.obj.selected = !option.obj.selected;
		this.option.func(option);
	};

	onKeydownSelect = (event: KeyboardEvent) => {
		if (this.enterPressed(event)) {
			this.select(this.option);
			event.stopPropagation();
		}
	};

	private enterPressed = (event: KeyboardEvent): boolean => {
		return KeyboardUtils.isEventKey(event, Key.ENTER);
	};

	performAction = ($event, node?) => {
		$event.preventDefault();
		$event.stopPropagation();
		this.toggleCurrentItem($event);
		if (this.onAction) {
			this.onAction();
		}
		if (this.enterPressed($event)) {
			let result = this.option.func(this.object, this.option.obj, this.option.models, $event);
			if (result) {
				setTimeout(() => $('.modal-title').first().trigger('focus'));
				if (this.onKeyAction) {
					result.finally(this.onKeyAction);
				}
			} else {
				if (this.onKeyAction) {
					this.onKeyAction();
				}
			}
			return;
		}
		if (node && !this.option.func) {
			node.func(this.object, node.obj, node.models, $event);
		} else {
			this.option.func(this.object, this.option.obj, this.option.models, $event);
		}
	};

	onActionClick = (event, node?) => {
		if (!this.option.disabled) {
			this.performAction(event, node);
		}
	};

	onKeydownAction = (event: KeyboardEvent) => {
		if (this.enterPressed(event) && !this.option.disabled) {
			this.performAction(event);
		}
	};

	toggleCurrentItem = ($event: JQuery.Event) => {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		if (this.onSelect) {
			this.onSelect({$option: this.option});
		}
	};

	onKeydownToggle = (event: KeyboardEvent) => {
		if (this.enterPressed(event) || KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			this.toggleCurrentItem(event as any);
			this.$timeout(() => {
				let submenuParent = (event.target as HTMLElement).classList.contains('dropdown-submenu') ?
					$(event.target) :
					$(event.target).closest('.dropdown-submenu');
				submenuParent.find('menu-option-submenu :focusable').first().trigger('focus');
			});
		}
	};

	optionClass = () => {
		let classes = [];
		if (this.level === 1) {
			classes.push('option');
			classes.push(this.option[1]);
		} else {
			classes.push('sub-option');
		}
		if (this.option.name !== undefined) {
			classes.push(this.option.name);
		}
		if (this.option.disabled) {
			classes.push('disabled');
		}

		if (this.option.classes) {
			classes = classes.concat(this.option.classes);
		}

		if (this.option.focusSubmenuOption) {
			classes.push('p-static');
		}

		return classes.join(' ');
	};

	getOptionTooltip = () => {
		return this.option.disabled && this.option.disabledMessage
			? this.option.disabledMessage
			: (this.option.tooltip ? this.option.tooltip : this.option.text);
	};

	selectNode = (node, $event) => {
		$event.stopPropagation();
		this.toggleCurrentItem($event);
		this.selectedTreeNode = node;
		if (this.option.func) {
			this.option.func(this.object, this.option.tree, node);
		}
	};

	nestedMenuClass = () => {
		let nestedMenuLevel = this.level + 1;
		return `menu-level-${nestedMenuLevel}`;
	};

	getNestedOptionListItemClass = (): string => {
		let classes = this.getSubmenuLevelClass();
		if (this.active) {
			classes += ' open-menu';
		}
		return classes;
	};

	getSubmenuLevelClass = (): string => {
		return `submenu-level-${this.level}`;
	};

	getOptionHtml = (): string => {
		return this.option.rawHtml || HtmlUtils.escapeHtml(this.option.text);
	};

}

app.component('contextMenuOption', {
	controller: ContextMenuOptionController,
	templateUrl: 'partials/context-menu/context-menu-option.html',
	bindings: {
		option: '=',
		object: '<',
		level: '<',
		active: '<',
		bounded: '<',
		onSelect: '&',
		onAction: '&',
		onKeyAction: '&?'
	}
});
