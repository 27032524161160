import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccountOrWorkspace, AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import IFilter from '@cxstudio/report-filters/entity/filter';
import WorkspaceFiltersApiService from '@app/modules/filter/services/workspace-filters-api.service';
import FiltersApiService from './filters-api.service';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { PredefinedFiltersService } from '@cxstudio/metrics/predefined/predefined-filters.service';
import { Inject } from '@angular/core';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { DateRange } from '@app/modules/filter/entities/date-range';
import { DateRangeResolveContext } from '@app/modules/filter/entities/date-range-resolve-context';

@Injectable({
	providedIn: 'root'
})
export class FiltersService {
	constructor(
		private readonly filtersApi: FiltersApiService,
		private readonly workspaceFiltersApi: WorkspaceFiltersApiService,
		@Inject('predefinedFiltersService') private readonly predefinedFiltersService: PredefinedFiltersService,
	) {}

	getFiltersWithFolders(
		project: AccountOrWorkspaceProject, includeDateFilters = false, cache = CacheOptions.CACHED
	): AssetPromise<IFilter[]> {
		return ProjectAssetsHelper.getAssetsForAnyProject(
			project,
			accountProject => this.filtersApi.getFiltersWithFolders(accountProject, includeDateFilters, cache),
			workspaceProject => this.workspaceFiltersApi.getFiltersWithFolders(workspaceProject, includeDateFilters, cache))
		.then(filters => {
			return InternalProjectTypes.isStudioAdminProject(project.projectId)
				? filters
				: this.predefinedFiltersService.withStudioPredefinedFilters(filters);
		}) as AssetPromise<IFilter[]>;
	}

	getFiltersForWorkspace(space: AccountOrWorkspace, cache = CacheOptions.CACHED): AssetPromise<IFilter[]> {
		let spaceFiltersPromise = WorkspaceTransitionUtils.isWorkspace(space)
			? this.workspaceFiltersApi.getFiltersForWorkspace(space, cache)
			: this.filtersApi.getFiltersForAccount(space, cache);

		return spaceFiltersPromise.then(filters => {
			return this.predefinedFiltersService.withStudioPredefinedFilters(filters);
		}) as AssetPromise<IFilter[]>;
	}

	getPlatformFilters(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IPlatformFilter[]> {
		return ProjectAssetsHelper.getAssetsForProject<IPlatformFilter[]>(
			project,
			(accountProject) => this.filtersApi.getPlatformFilters(accountProject, cache),
			(workspaceProject) => this.workspaceFiltersApi.getPlatformFilters(workspaceProject, cache),
			(projectId) => []
		);
	}

	getStudioFilters(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IFilter[]> {
		return ProjectAssetsHelper.getAssetsForAnyProject(
			project,
			(accountProject) => this.filtersApi.getStudioFilters(accountProject, cache),
			(workspaceProject) => this.workspaceFiltersApi.getStudioFilters(workspaceProject, cache));
	}

	getStudioFiltersWithPredefined(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IFilter[]> {
		return this.getStudioFilters(project, cache).then(studioFilters => {
			let predefinedFilters = this.predefinedFiltersService.getAllFilters();
			let result = [];
			return _.union(result, predefinedFilters, studioFilters);
		}) as AssetPromise<IFilter[]>;
	}

	getStudioDateFilters = (project: AccountOrWorkspaceProject, daysLimit?: number): Promise<IFilter[]> => {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? this.workspaceFiltersApi.getStudioDateFilters(project, daysLimit)
			: this.filtersApi.getStudioDateFilters(project, daysLimit);
	};

	resolveDateRange = (
		project: AccountOrWorkspaceProject, resolveData: DateRangeResolveContext
	): Promise<DateRange> => {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? this.workspaceFiltersApi.resolveDateRange(project, resolveData)
			: this.filtersApi.resolveDateRange(project, resolveData);
	};
}

app.service('filtersService', downgradeInjectable(FiltersService));
