import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { HierarchyNode } from '@cxstudio/organizations/hierarchy-node';
import { DrillToDashboardService } from '@cxstudio/services/drill-to-dashboard.service';
import * as _ from 'underscore';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';


interface IFilterChange {
	type: 'add' | 'update' | 'remove';
	filter: DashboardFilter;
}


export interface IDashboardHistoryInstance {
	init(dashboard: Dashboard): void;
	reset(dashboard: Dashboard): void;
	clear(): void;
	applyHierarchyNode(node: HierarchyNode): void;
	applyHierarchyNodeIfEmpty(node: HierarchyNode): void;
	getAppliedHierarchyNode(): HierarchyNode;
	clearAppliedHierarchyNode(): void;
	addFilterChange(change: IFilterChange): void;
	addViewFilterChange(change: IFilterChange): void;
	clearFilterHistory(): void;
	clearAppliedFilters(): void;
	getFilterHistory(): IFilterChange[];
	getLastFilterSave(): DashboardFilter[];
	revertFilterChanges(): DashboardFilter[];
	setAppliedFilters(filters: DashboardFilter[]): void;
	getAppliedFilters(): DashboardFilter[];
	getPersistentFilters(): DashboardFilter[];
	applyFilterUpdates(updates: DashboardFilter[]): void;
	saveFilterUpdates(updates: DashboardFilter[]): void;
	isWidgetChanged(): boolean;
	isFilterChanged(): boolean;
	isViewFilterChanged(): boolean;
	dashboardNameChanged(): void;
	isChanged(): boolean;
	markDirty(): void;
	isAppliedFiltersUnsaved(): boolean;
	isAppliedFiltersChanged(currentFilters: DashboardFilter[]): boolean;
	setPersonalization(pers: PersonalizationState): void;
	getPersonalization(): PersonalizationState;
	hasDrillToDashboardFilter(): boolean;
	resetTextFilter(): void;
	setTextFilter(textFilter: string): void;
	getTextFilter(): string;
	hasTextFilter(): boolean;
	isInitialized(): boolean;
	getDashboard(): Dashboard;
}


// eslint-disable-next-line prefer-arrow-callback
app.factory('DashboardHistory', function(drillToDashboardService: DrillToDashboardService,
	dashboardFiltersService: DashboardFiltersService) {

	return class DashboardHistoryInstance implements IDashboardHistoryInstance {
		dirty: boolean;
		filterHistory: IFilterChange[];
		savedFilters: DashboardFilter[];
		appliedFilters: DashboardFilter[];
		dashNameChanged: boolean;
		viewFilterHistory: IFilterChange[];
		personalization: PersonalizationState;
		appliedHierarchyNode: HierarchyNode;
		private dashboard: Dashboard;
		initialized: boolean = false;

		constructor() {
			this.dirty = false;
			this.filterHistory = [];
			this.appliedFilters = [];
			this.savedFilters = [];
			this.dashNameChanged = false;
			this.viewFilterHistory = [];
			this.appliedHierarchyNode = null;
		}

		init = (dashboard: Dashboard) => {
			this.dirty = false;
			this.filterHistory = [];
			this.appliedFilters = [];
			this.savedFilters = [];
			this.dashNameChanged = false;
			this.viewFilterHistory = [];
			this.appliedHierarchyNode = null;
			this.dashboard = dashboard;
			this.initialized = true;
		};

		isInitialized = (): boolean => {
			return this.initialized;
		};

		reset = (dashboard: Dashboard) => {
			this.clear();
			if (dashboard) {
				this.dashboard = dashboard;
			}
		};

		clear = () => {
			this.clearFilterHistory();
			if (this.isAppliedFiltersChanged()) {
				this.setAppliedFilters(this.getLastFilterSave());

			}
			this.dashNameChanged = false;
			this.dirty = false;
		};

		applyHierarchyNode = (node: HierarchyNode) => {
			if (node && node.id)
				this.appliedHierarchyNode = node;
		};

		applyHierarchyNodeIfEmpty = (node: HierarchyNode) => {
			if (!this.appliedHierarchyNode)
				this.applyHierarchyNode(node);
		};

		getAppliedHierarchyNode = (): HierarchyNode => {
			return this.appliedHierarchyNode;
		};

		clearAppliedHierarchyNode = (): void => {
			this.appliedHierarchyNode = null;
		};

		addFilterChange = (change: IFilterChange): void => {
			this.filterHistory.push(change);
		};

		addViewFilterChange = (change): void => {
			this.viewFilterHistory.push(change);
		};

		clearFilterHistory = (): void => {
			this.filterHistory = [];
			this.viewFilterHistory = [];
		};

		clearAppliedFilters = (): void => {
			this.appliedFilters = [];
		};

		getFilterHistory = (): IFilterChange[] => {
			return this.filterHistory;
		};

		getLastFilterSave = (): DashboardFilter[] => {
			return this.savedFilters ? angular.copy(this.savedFilters) : [];
		};

		revertFilterChanges = (): DashboardFilter[] => {
			this.clearFilterHistory();
			return this.getLastFilterSave();
		};

		setAppliedFilters = (filters: DashboardFilter[]): void => {
			// keeping applied text filter while not saving it to dashboard
			// little bit hacky =/ need to find a better way
			let textFilter = this.getTextFilter();
			this.appliedFilters = angular.copy(filters) || [];
			if (this.dashboard && drillToDashboardService.hasFilters(this.dashboard.id)) {
				let drillFilters: DashboardFilter[] = drillToDashboardService.getFilters(this.dashboard.id);
				drillFilters.forEach(drillFilter => {
					let alreadyAppliedFilter = _.find(this.appliedFilters, (appliedFilter) => {
						return appliedFilter.drillableFilter
							&& appliedFilter.drillableFilter.name === drillFilter.drillableFilter.name;
					});

					if (_.isUndefined(alreadyAppliedFilter)) {
						this.appliedFilters.push(drillFilter);
					}
				});
			}
			if (textFilter)
				this.setTextFilter(textFilter);
		};

		getAppliedFilters = (): DashboardFilter[] => {
			return this.appliedFilters ? this.appliedFilters : [];
		};

		getPersistentFilters = (): DashboardFilter[] => {
			let copy: DashboardFilter[] = angular.copy(this.getAppliedFilters());
			_.each(copy, (filter: DashboardFilter) => {
				if (DashboardFilterSelection.isText(filter.selectedAttribute))
					delete filter.selectedAttributeValue;
			});
			return copy;
		};

		applyFilterUpdates = (updates: DashboardFilter[]): void => {
			this.savedFilters = angular.copy(updates);
			this.setAppliedFilters(updates);
		};

		saveFilterUpdates = (updates: DashboardFilter[]): void => {
			this.applyFilterUpdates(updates);
			this.clearFilterHistory();
		};

		isWidgetChanged = (): boolean => {
			return this.dirty;
		};

		markDirty = () => {
			this.dirty = true;
		};

		isFilterChanged = (): boolean => {
			return this.filterHistory.length > 0;
		};

		isViewFilterChanged = (): boolean => {
			return this.viewFilterHistory && this.viewFilterHistory.length > 0;
		};

		dashboardNameChanged = (): void => {
			this.dashNameChanged = true;
		};

		isChanged = (): boolean => {
			return this.isWidgetChanged() || this.isFilterChanged() || this.dashNameChanged;
		};

		isAppliedFiltersUnsaved = (): boolean => {

			if (this.isAppliedFiltersChanged(this.savedFilters)) {
				return true;
			}

			return false;
		};

		isAppliedFiltersChanged = (currentFilters?: DashboardFilter[]): boolean => {
			return !dashboardFiltersService.areFiltersIdentical(currentFilters, this.appliedFilters);
		};

		setPersonalization = (pers: PersonalizationState): void => {
			this.personalization = pers;
		};

		getPersonalization = (): PersonalizationState => {
			return this.personalization;
		};

		hasDrillToDashboardFilter = (): boolean => {
			return !!_.findWhere(this.appliedFilters, { isDrillToDashboardFilter: true });
		};

		private getTextFilterObject(): DashboardFilter {
			return _.find(this.appliedFilters, (filter: DashboardFilter) => {
				return DashboardFilterSelection.isText(filter.selectedAttribute);
			}) as any;
		}

		resetTextFilter = (): void => {
			let textFilter = this.getTextFilterObject();
			if (textFilter)
				delete textFilter.selectedAttributeValue;
		};

		setTextFilter = (text: string): void => {
			let textFilter = this.getTextFilterObject();
			if (!textFilter)
				return;
			if (text) {
				textFilter.selectedAttributeValue = {value: text};
			} else this.resetTextFilter();
		};

		getTextFilter = (): string => {
			let textFilter = this.getTextFilterObject();
			return textFilter && textFilter.selectedAttributeValue
				&& textFilter.selectedAttributeValue.value;
		};

		hasTextFilter = (): boolean => {
			return !!this.getTextFilterObject();
		};

		getDashboard = (): Dashboard => {
			return this.dashboard;
		};
	};
});
