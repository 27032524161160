import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';

export interface IDrillTargetType extends Omit<IDrillMenuOption, 'name'> {
	name?: string;	// required in base interface, so we omit it then make it optional
	type?: string;
}

export interface ILimitedSupportDrillTargetType extends IDrillTargetType {
	supportedTypes: DrillWidgetType[];
}

export interface IDrillToHelper {
	getDrillTo: (type: DrillWidgetType[] | DrillWidgetType, defaultWidgetType: WidgetType) => IDrillTargetType[];
	getDrillToANPreview: () => IDrillTargetType;
	getDrillToAnDocExplorer: () => IDrillTargetType;
	getDrillToRecolor: () => IDrillTargetType;
	getDrillToMetricAlert: (disabled, callback) => IDrillTargetType;
	getDrillToKeyTerms: () => IDrillTargetType;
	getDrillToFullPageDocExplorer: () => IDrillTargetType;
}

@Injectable({
	providedIn: 'root'
})
export class DrillHelperService {
	constructor(
		@Inject('DrillTo') private readonly DrillTo
	) { }

	create = (params: IDrillParams): IDrillToHelper => {
		return new this.DrillTo(
			params.widget, params.menuActions, params.defaultColor);
	};
}

app.service('drillToHelper', downgradeInjectable(DrillHelperService));
