import * as _ from 'underscore';
import { CancellableDeferredFactory } from '@cxstudio/directives/utils/cancellable-deferred.factory';
import { CancellableDeferred } from '@cxstudio/directives/utils/cancellable-deferred';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ContentProviderLimiter } from '@cxstudio/reports/content-provider-limiter.service';
import { ReportDataApiService } from '@cxstudio/services/data-services/report-data-api.service';


interface Report {
	id: ReportId;
	data: ReportMetadata;
}

interface ReportMetadata {
	reportRun: ng.IDeferred<any>;
	dataCall: any;
	reportSettings: any;
	canceler?: CancellableDeferred<any>;
}

//feedback in doc mode produces couple queries
interface ReportId {
	widgetId: number;
	reportName: string;
	containerId: string;
}

export default class ReportRunService {

	private reports: Report[];
	private stoppedReports: ReportId[];
	private cancelledRuns: ReportId[];

	constructor(
		private $q: ng.IQService,
		private $log: ng.ILogService,
		private cancellableDeferredFactory: CancellableDeferredFactory,
		private reportDataApiService: ReportDataApiService,
		private contentProviderLimiter: ContentProviderLimiter,
	) {
		this.reports = [];
		this.stoppedReports = [];
		this.cancelledRuns = [];
	}

	runReport = (widget: Widget, reportSettings: Widget, priority: number,
			requestFunction = this.reportDataApiService.getReportData): ng.IPromise<any> => {
		let reportId = this.getReportId(widget, reportSettings);
		let runningReport = this.getReportMetadata(reportId);
		if (runningReport) {
			this.logReportMessage(reportId, 'is already running, stopping it');
			this.cancelledRuns.push(reportId);
			runningReport.canceler.cancel();
		}

		let reportRun = this.$q.defer();
		let queuedDataCall = this.contentProviderLimiter.wrap(requestFunction, priority, widget);
		let reportData: ReportMetadata = { reportRun, reportSettings, dataCall: queuedDataCall };
		this.putReport(reportId, reportData);
		this.logReportMessage(reportId, 'run started');

		this.requestData(reportId);
		return reportRun.promise;
	};

	isLoading = (widgetId: number, reportName: string, containerId: string): boolean => {
		return !!this.getReportMetadata({ widgetId, reportName, containerId});
	};

	private requestData = (reportId: ReportId): void => {
		let reportMetadata = this.getReportMetadata(reportId);
		reportMetadata.canceler = this.cancellableDeferredFactory.defer();
		this.logReportMessage(reportId, 'data call started');
		reportMetadata.dataCall(reportMetadata.reportSettings, reportMetadata.canceler)
			.then((reportData) => {
				this.logReportMessage(reportId, 'data call promise resolved');
				this.deleteReport(reportId);
				reportMetadata.reportRun.resolve(reportData);
			}, (reason) => {
				if (_.find(this.cancelledRuns, (storedId) => _.isEqual(storedId, reportId))) {
					this.logReportMessage(reportId, 'data call promise rejected due to new run');
					this.cancelledRuns = _.reject(this.cancelledRuns, (storedId) => _.isEqual(storedId, reportId));
				} else if (_.find(this.stoppedReports, (storedId) => _.isEqual(storedId, reportId))) {
					this.logReportMessage(reportId, 'data call promise rejected due to widget editing');
				} else {
					this.logReportMessage(reportId, ' data call promise rejected due to ' + reason);
					this.deleteReport(reportId);
					reportMetadata.reportRun.reject(reason);
				}
			});
	};

	stopAllReports(): number {
		this.logMessage('Stopping all data calls');
		for (const report of this.reports) {
			this.stoppedReports.push(report.id);
			report.data.canceler.cancel();
			this.logReportMessage(report.id, 'stopped');
		}
		return this.stoppedReports.length;
	}

	rerunStoppedReports(): void {
		this.logMessage('Rerunning stopped data calls');
		while (this.stoppedReports.length) {
			let reportId = this.stoppedReports.shift();
			this.logReportMessage(reportId, 'rerun started');
			this.requestData(reportId);
		}
	}

	private getReportId(widget: Widget, reportSettings: Widget): ReportId {
		let widgetId = widget?.id || 0;
		let containerId = widget?.containerId || 'settings'; // widget is null in settings
		return { widgetId, reportName: reportSettings.name, containerId };
	}

	private putReport(reportId: ReportId, data: ReportMetadata): void {
		this.deleteReport(reportId);
		this.reports.push({id: reportId, data});
	}

	private deleteReport(reportId: ReportId): void {
		this.reports = _.reject(this.reports, (report) => _.isEqual(report.id, reportId));
	}

	private getReportMetadata(reportId: ReportId): ReportMetadata {
		let storedReport = _.find(this.reports, (report) => _.isEqual(report.id, reportId));
		return storedReport && storedReport.data;
	}

	private logMessage(message: string): void {
		if (CONFIG.log) {
			this.$log.info(message);
		}
	}

	private logReportMessage(reportId: ReportId, message: string): void {
		this.logMessage(`${reportId.widgetId}-${reportId.reportName}: ${message}`);
	}
}

app.service('reportRunService', ReportRunService);
