import { Component, OnInit, Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { ContractApiService } from '@cxstudio/master-accounts/contracts/contract-api-service.service';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';
import { ContractMode } from '@cxstudio/master-accounts/contracts/contract-mode';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'contracts-tab',
	templateUrl: './contracts-tab.component.html',
})
export class ContractsTabComponent implements OnInit {

	licenses: LicenseTypeItem[];
	contracts: Contract[];

	constructor(
		private licenseApiService: LicenseApiService,
		private locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('contractApiService') private contractApiService: ContractApiService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
	) { }

	ngOnInit(): void {
		this.reloadContracts();
		this.reloadLicenses();
	}

	canManageContracts = () => {
		return this.security.has('account_owner') || this.security.has('system_administration');
	};

	private reloadContracts(): void {
		this.contractApiService.getContracts().then(result => {
			this.contracts = result.data;
		});
	}

	private reloadLicenses(): void {
		this.licenseApiService.getLicenseTypes().then(result => {
			this.licenses = result;
		});
	}

	showCreateContractDialog = () => {
		this.cbDialogService.showContractDialog(undefined, ContractMode.CREATE, this.licenses).then(contract => {
			this.contractApiService.createContract(contract).then(() => {
				this.reloadContracts();
			});
		});
	};

	showEditContractDialog = (currentContract: Contract) => {
		this.cbDialogService.showContractDialog(currentContract, ContractMode.EDIT, this.licenses).then(changedContract => {
			this.contractApiService.updateContract(changedContract).then(() => {
				this.reloadContracts();
			});
		});
	};

	showViewContractDialog = (currentContract: Contract) => {
		this.cbDialogService.showContractDialog(currentContract, ContractMode.VIEW, this.licenses);
	};

	showDeleteContractConfirmation = (contract: Contract) => {
		let confirmModal = this.cbDialogService.danger(
			this.locale.getString('mAccount.deleteContractTitle', { contractName: contract.name }),
			this.locale.getString('mAccount.deleteContractMessage', { contractName: contract.name })
		);
		confirmModal.result.then(() => {
			this.contractApiService.deleteContract(contract).then(() => {
				this.reloadContracts();
			});
		});
	};
}

app.directive('contractsTab', downgradeComponent({ component: ContractsTabComponent }));
