import { Pagination } from '@app/shared/components/pagination/pagination';
import { PagedResponse } from '@cxstudio/paged-response';
import EmailMessage from './email-message';
import EmailsSearch from './emails-search';
import { EmailsQuery } from './emails-query';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class EmailStatusApi {

	constructor(
		private cxHttp: CxHttpService
	) {}

	getEmailMessages = (search: EmailsSearch, pagination: Pagination):
			Promise<PagedResponse<EmailMessage>> => {
		let url = 'rest/emails';
		let params = this.getQueryParams(search, pagination);
		return this.cxHttp.get(url, params);
	};

	deleteEmailMessages = (search: EmailsSearch): Promise<void> => {
		let url = 'rest/emails';
		let params = this.getQueryParams(search, new Pagination(20));
		return this.cxHttp.delete(url, params).then(() => {});
	};

	private getQueryParams(search: EmailsSearch, pagination: Pagination): CxHttpRequestOptions<HttpParams> {
		let queryParams: EmailsQuery = new EmailsQuery(search, pagination);
		return {
			params: queryParams.toHttpParams()
		};
	}
}
