import { Inject, Injectable } from '@angular/core';
import { DrillToDashboardService } from '@cxstudio/services/drill-to-dashboard.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { RouteService } from '@cxstudio/services/route-service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { DrillableDashboard } from '@cxstudio/dashboards/entity/drillable-dashboard';
import { CommonDrillService } from '@app/modules/reports/utils/context-menu/drill/common-drill.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import { CxLocationService } from '@app/core/cx-location.service';
import { Base64Utils } from '@app/util/base64-utils';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { IPromise } from 'angular';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';

@Injectable({
	providedIn: 'root'
})
export class DashboardDrillService {
	constructor(
		@Inject('drillToDashboardService') private readonly drillToDashboardService: DrillToDashboardService,
		private readonly cxLocationService: CxLocationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('routeService') private readonly routeService: RouteService,
		private readonly commonDrill: CommonDrillService,
		private readonly reportProcessingService: ReportProcessingService,
	) {}

	public getOptions = (params: IDrillParams, point): IPromise<IDrillMenuOption[]> => {
		const promises = this.getDrillableDashboards(params).map((dashboard: DrillableDashboard) => {
			return this.getDrillDashboardOption(params, dashboard, point);
		});

		return PromiseUtils.old(Promise.all(promises));
	};

	public hasDrillableDashboards = (params: IDrillParams): boolean => {
		return !this.drillToDashboardService.hasFilters(params.widget.dashboardId)
			&& !isEmpty(this.getDrillableDashboards(params));
	};

	private getDrillableDashboards = (params: IDrillParams): DrillableDashboard[] => {
		const drillableDashboards = params.dashboardProperties && params.dashboardProperties.drillableDashboards;
		if (!isEmpty(drillableDashboards)) {
			const dashboards: DrillableDashboard[] = this.dashboardService.getAllDrillableDashboards(this.getWidgetDashboardSetting(params));
			return dashboards.filter(dash => drillableDashboards.find(d => d.id === dash.id));
		}

		return [];
	};

	private getWidgetDashboardSetting = (params: IDrillParams): Dashboard => {
		return {
			id: params.widget.dashboardId,
			properties: {
				cbContentProvider: params.widget.properties.contentProviderId,
				cbAccount: params.widget.properties.accountId,
				project: params.widget.properties.project
			} as DashboardProperties
		} as Dashboard;
	};

	private getDrillDashboardOption = (params: IDrillParams, dashboard: DrillableDashboard, point): Promise<IDrillMenuOption> => {
		return this.getDrillToDashboardUrl(params, dashboard, point).then(drillUrl => {
			return {
				group: IDrillMenuOptionGroup.dashboardDrill,
				priority: 60,
				text: dashboard.name,
				name: 'drillableDashboard',
				ref: drillUrl
			};
		});
	};

	private getDrillToDashboardUrl = (params: IDrillParams, dashboard: DrillableDashboard, point): Promise<string> => {
		let filters = this.commonDrill.getFiltersFromPoint(params.widget, point);
		const labelPromise = this.reportProcessingService.getDrillFilters(
			filters,
			params.widget
		);

		return labelPromise.then((labels) => {
			if (!isEmpty(filters)) {
				filters = filters.map((dashboardFilter, index) => {
					const label = labels[index];

					return {
						label,
						drillableFilter: dashboardFilter
					};
				});
			}

			const encodedFilters = encodeURIComponent(Base64Utils.encode(filters));
			const dashboardUrl = this.urlService.getDashboardUrl(dashboard.id);

			let drillUrl = `${dashboardUrl}?drillDashboardFilter=${encodedFilters}`;
			if (!this.routeService.isEmbeddedView()) {
				const sourceUrl = /tab=/.test(this.cxLocationService.url())
					? this.cxLocationService.url()
					: '/dashboard/' + params.widget.dashboardId;
				drillUrl += `&sourceUrl=${sourceUrl}`;
			}

			return drillUrl;
		});
	};

	public handleDrillToDashboard = (dashboardId: number): void => {
		const encodedFilters = this.cxLocationService.search().drillDashboardFilter;
		if (!encodedFilters) return;

		const sourceUrl = this.cxLocationService.search().sourceUrl;
		this.cxLocationService.search({});

		let filters: any[] = Base64Utils.decode(encodedFilters);
		if (!isEmpty(filters)) {
			filters = filters.map(
				(filter) => {
					filter.isDrillToDashboardFilter = true;

					return filter;
				}
			);
		}

		this.drillToDashboardService.setSourceUrl(sourceUrl);
		this.drillToDashboardService.setTargetDashboardId(dashboardId);
		this.drillToDashboardService.setFilters(filters);
	};
}

app.service('dashboardDrill', downgradeInjectable(DashboardDrillService));
