import * as cloneDeep from 'lodash.clonedeep';

import { Injectable, Inject, EventEmitter } from '@angular/core';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { NameService } from '@cxstudio/common/name-service';
import { HomePageManagementComponent } from '@app/modules/home-page/home-page-management/home-page-management/home-page-management.component';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';

@Injectable()
export class HomePageListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService,
		private readonly homePageApi: HomePageApiService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('nameService') private readonly nameService: NameService,
		@Inject('security') private readonly security: Security,
	) {}

	edit(homePage: HomePage, controller: HomePageManagementComponent): void {
		controller.editHomePage(homePage);
	}

	create = (homePage: HomePage): void => {
		this.homePageApi.createHomePage(homePage).then(() => {
			this.toastService.addToast(
				this.locale.getString('common.itemAdded', {itemName: homePage.name}), AlertLevel.CONFIRM);
			this.onChange.emit();
		});
	};

	copy = (homePage: HomePage, controller: HomePageManagementComponent): void => {
		let copy = cloneDeep(homePage);
		copy.name = this.nameService.copyName(homePage.name, controller.homePages, 'name');
		this.homePageApi.copyHomePage(copy).then(() => {
			this.toastService.addToast(
				this.locale.getString('common.itemAdded', {itemName: copy.name}), AlertLevel.CONFIRM);
			this.onChange.emit();
		});
	};

	update = (homePage: HomePage): void => {
		this.homePageApi.updateHomePage(homePage).then(() => {
			let name = homePage.name || this.locale.getString('homePage.defaultName');
			this.toastService.addToast(
				this.locale.getString('common.itemUpdated', {itemName: name}), AlertLevel.CONFIRM);
			this.onChange.emit();
		});
	};

	delete(homePage: HomePage): void {
		const ownerName = homePage.reportProperties.owner;
		const confirmDeleteMessage = this.security.isCurrentUser(ownerName)
			? this.locale.getString('common.confirmDeleteItem', { itemName: homePage.name })
			: this.locale.getString('homePage.deleteMessage', { homePageName: homePage.name, ownerName });
		this.cbDialogService.confirm(this.locale.getString('common.delete'), confirmDeleteMessage).result
			.then(() => {
				this.homePageApi.deleteHomePage(homePage.id).then(() => {
					this.toastService.addToast(
						this.locale.getString('common.itemDeleted', { itemName: homePage.name }), AlertLevel.CONFIRM);
					this.onChange.emit();
				});
			});
	}

	toggle(homePage: HomePage): void {
		homePage.enabled = !homePage.enabled;
		let notificationMessage = homePage.enabled ?
			this.locale.getString('common.itemEnabled', {itemName: homePage.name}) :
			this.locale.getString('common.itemDisabled', {itemName: homePage.name});
		let promise = homePage.enabled
			? this.homePageApi.enableHomePage(homePage.id)
			: this.homePageApi.disableHomePage(homePage.id);
		promise.then(() => {
			this.toastService.addToast(notificationMessage, AlertLevel.CONFIRM);
			this.onChange.emit();
		}, () => homePage.enabled = !homePage.enabled);
	}

	changeRank(homePage: HomePage, rank: number): void {
		this.homePageApi.setHomePageRank(homePage.id, rank).then(() => {
			this.toastService.addToast(
				this.locale.getString('homePage.homePagesReordered'), AlertLevel.CONFIRM);
			this.onChange.emit();
		});
	}

}
