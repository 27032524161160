import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';

@Injectable({
	providedIn: 'root'
})
export class ThresholdMetricService {
	constructor(
		private locale: CxLocaleService
	) { }

	getDefault(): ThresholdMetric {
		return new ThresholdMetric(
			FilterRuleType.empty,
			true,
			this.locale.getString('common.default')
		);
	}
}

export class ThresholdMetric {
	constructor(
		private type: string,
		private isThreshold: boolean,
		private displayName: string,
		private attributeName?: string,
		private value?: number,
		private matchMode?: string) {
	}

	getAttributeName = (): string => {
		return this.attributeName;
	};

	getValue = (): number => {
		return this.value;
	};
}
