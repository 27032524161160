import * as cloneDeep from 'lodash.clonedeep';

import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, EventEmitter, Output } from '@angular/core';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { MixedFilter, MixedFilterItem, MixedItemType } from '@cxstudio/report-filters/filter-builder/mixed-filter';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterBuilderUtils } from '@app/modules/filter-builder/filter-builder-utils';

@Component({
	selector: 'mixed-filter-builder',
	templateUrl: './mixed-filter-builder.component.html'
})

export class MixedFilterBuilderComponent {
	@Input() filter: MixedFilter;
	@Input() filterableFields;
	@Input() projectSelection: IProjectSelection;
	@Input() appendToBody: boolean;
	@Input() savedFilterOptions: any;
	@Input() canAdd: () => boolean;

	@Output() onItemAdded = new EventEmitter<void>();
	@Output() onItemRemoved = new EventEmitter<void>();
	@Output() onChangedSavedFilter = new EventEmitter<any>();
	@Output() onItemChanged = new EventEmitter<void>();

	constructor(
	) {}

	getClasses = (rule: IFilterRule): any => {
		return FilterBuilderUtils.getClasses(rule);
	};

	canAddItem = () => {
		return this.canAdd ? this.canAdd() : true;
	};

	canRemoveItem = (item: MixedFilterItem) => {
		return !item.pinned;
	};

	isFilterRuleDefined = (rule: IFilterRule) => {
		if (rule.type !== FilterRuleType.empty)
			return true;
	};

	shouldOpen = (rule: IFilterRule): boolean => {
		return !this.isFilterRuleDefined(rule);
	};

	addItem = () => {
		if (this.canAddItem()) {
			this.filter.items.push({
				type: MixedItemType.FILTER_RULE,
				entity: cloneDeep(FilterEmptyObject.RULE)
			});
			this.onItemAdded.emit();
		}
	};

	removeItem = (index: number) => {
		this.filter.items.removeAt(index);
		this.onItemRemoved.emit();
	};
	
	changeSavedFilter = (index: number, filter) => {
		this.onChangedSavedFilter.emit({ index, filter });
	};

	moveFilterItem = (item: CdkDragDrop<MixedFilterItem[]>): void => {
		let pinnedItemsCount = this.getPinnedItemsCount();
		let index = item.currentIndex;
		if (index >= pinnedItemsCount) {
			moveItemInArray(this.filter.items, item.previousIndex, item.currentIndex);
		}
	};

	private getPinnedItemsCount = (): number => {
		return this.filter.items.filter(item => item.pinned).length;
	};

	onRuleChange = (): void => {
		this.onItemChanged.emit();
	};
}