import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export class ImageSettingsEntry {
	altText: string;
	imageWidth: number;
	imageHeight: number;
}

@Component({
	selector: 'image-settings',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './image-settings-modal.component.html'
})

export class ImageSettingsModalComponent implements OnInit {
	@Input() input: ImageSettingsEntry;

	maxLength = 100;
	textLength: number;
	isSizeLocked: boolean;
	aspectRatio: number;

	constructor(private modal: NgbActiveModal) {}

	ngOnInit(): void {
		this.updateLength();
		// need to adjust once resize is enabled
		this.isSizeLocked = true;
		this.aspectRatio = this.input.imageWidth  / this.input.imageHeight;
	}

	updateLength = (): void => {
		this.textLength = this.input.altText?.length || 0;
	};

	save = (): void => {
		this.modal.close(this.input);
	};

	cancel = () => this.modal.dismiss();

	toggleSizeLock = (): void => {
		this.isSizeLocked = !this.isSizeLocked;
	};

	syncHeight = (): void => {
		if (this.isSizeLocked) {
			this.input.imageHeight = Math.round(this.input.imageWidth / this.aspectRatio);
		}
	};

	syncWidth = (): void => {
		if (this.isSizeLocked) {
			this.input.imageWidth = Math.round(this.input.imageHeight * this.aspectRatio);
		}
	};

	closeModal = (event: UIEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		this.cancel();
	};
}