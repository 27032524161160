import { Component, OnInit, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { WidgetRenameAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-rename-action';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'editable-widget-title',
	template: `
	<div class="d-flex w-100-percent" title="">
		<cb-expanding-input [(ngModel)]="displayName" (ngModelChange)="detectTitleChange($event)" [maxLength]="MAX_LENGTH"
							[tooltipLabel]="getTooltipLabel()"></cb-expanding-input>
	</div>
	`,
	changeDetection: ChangeDetectionStrategy.Default,
	styles: [
		`
			:host ::ng-deep cb-expanding-input input {
				height: 2rem !important;
				font-weight: 300;
			}
			:host ::ng-deep cb-expanding-input .popover {
				display: block !important;
			}
			:host ::ng-deep cb-expanding-input .popover .arrow {
				border-width: 5px !important;
			}
		`
	]
})
export class EditableWidgetTitleComponent implements OnInit {

	@Input() widget: Widget;
	@Input() widgetActions: IWidgetActions;
	@HostBinding('class') hostClass = 'd-flex flex-fill align-center overflow-hidden ml-8';

	readonly MAX_LENGTH = 200;

	displayName: string;

	private widgetTitleChangeSubject = new Subject<string>();

	constructor(private readonly locale: CxLocaleService) {
	}

	ngOnInit(): void {
		this.displayName = this.widget.displayName;
		this.widgetTitleChangeSubject
			.pipe(debounceTime(300))
			.subscribe((newVal: string) => {
				this.updateTitle(newVal);
			});
	}

	detectTitleChange = (val: string) => {
		this.widgetTitleChangeSubject.next(val);
	};

	updateTitle = (displayName = this.displayName): void => {
		if (this.widget.displayName !== displayName) {
			if (HomePageWidgetConstants.isHomePageWidget(this.widget)) {
				this.updateTitleProperties();
				this.widgetActions.emit(WidgetAction.RENAME);
			} else {
				this.widgetActions.emit(
					WidgetAction.RENAME,
					WidgetRenameAction.wrapChange(() => this.widget, () => this.updateTitleProperties())
				);
			}
		}
	};

	getTooltipLabel = (): string => {
		return this.locale.getString('templates.genericButton', null, 'Click to Edit');
	};

	private updateTitleProperties = (): void => {
		this.widget.properties.isCustomTitle = true;
		this.widget.displayName = this.displayName;
	};
}
