import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { IScorecardFilterListItem } from '@app/modules/scorecards/entities/scorecard-filter-list-item';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ScorecardFiltersApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.FILTERS);
	}

	getFilters = (
		project: IProjectSelection, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<IScorecardFilterListItem[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/scorecard-filters';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<IScorecardFilterListItem[]>;
	};

	private getBasePath = (project: IProjectSelection): string => {
		return `rest/resources/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	};
}
