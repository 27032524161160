import { downgradeInjectable } from '@angular/upgrade/static';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Inject, Injectable } from '@angular/core';

@Injectable()
export class TuningSuggestionsService {
	constructor(
		private engagorService: EngagorService,
		@Inject('security') private security: Security,
		private betaFeaturesService: BetaFeaturesService,
	) { }

	isAvailable = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.PRODUCT_FEEDBACK)
			&& this.engagorService.isIntegrationEnabled()
			&& this.security.has('interaction_audit');
	};
}

app.service('tuningSuggestionsService', downgradeInjectable(TuningSuggestionsService));
