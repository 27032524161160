import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { IContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { ICustomTemplateActions } from '@cxstudio/dashboard-templates/custom-templates-actions.service';
import { DashboardTemplateTypes } from '@cxstudio/dashboard-templates/dashboard-template-types';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { TemplateCommonService } from '@cxstudio/dashboard-templates/template-common-service.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import * as _ from 'underscore';

export interface ICustomTemplatesScope {
	reloadCustomTemplates: () => void;
	customTemplates: ITreeItem[];
	promises: {
		loadingCustomTemplates: ng.IPromise<any>;
		operatingOnTemplate: ng.IPromise<any>;
	};
	redrawTemplates: (templates: any[]) => void;
}

export class CustomTemplatesComponent implements ng.IComponentController, ICustomTemplatesScope {

	customTemplatesEnabled = false;
	gridType = GridTypes.TEMPLATES;

	promises = {
		loadingCustomTemplates: null,
		operatingOnTemplate: null
	};

	ui;
	customTemplates: ITreeItem[];
	lastChange;
	gridOptions: SlickgridOptions;
	contextMenuUtils: IContextMenuUtils<CustomTemplate>;
	actionsService: ICustomTemplateActions;

	constructor(
		private dashboardTemplatesApiService: DashboardTemplatesApiService,
		private betaFeaturesService: BetaFeaturesService,
		private security: Security,
		private authorization: Authorization,
		private contextMenuTree: ContextMenuTree,
		private gridUtils: GridUtilsService,
		private CustomTemplateContextMenu,
		private CustomTemplateActions,
		public templateCommonService: TemplateCommonService,
	) {}

	$onInit = () => {
		this.ui = this.ui || {};
		this.ui.searchFilter = '';
		this.security.restrictPage(this.authorization.hasCreateDashboardAccess);

		this.gridOptions = {
			onContextMenu: _.noop,
			onClick: this.onDashboardTemplateClick,
			onDblClick: _.noop,
			onMouseEnter: _.noop,
			onMouseLeave: _.noop
		};

		this.contextMenuUtils = new this.CustomTemplateContextMenu(this);
		this.actionsService = new this.CustomTemplateActions(this);

		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATE_FROM_DASHBOARD) || !this.security.isAdminOrgUser()) {
			return;
		}

		this.customTemplatesEnabled = true;
		this.reloadCustomTemplates();
	};

	reloadCustomTemplates = () => {
		this.promises.loadingCustomTemplates = this.dashboardTemplatesApiService.getTemplates()
			.then((response) => {
				_.each(response.data, (item: ITreeItem) => {
					if (item.type === DashboardTemplateTypes.CUSTOM)
						item.type = GridContext.DASHBOARD_TEMPLATE;
					if (this.gridUtils.isFolder(item))
						(item as IFolderItem)._collapsed = true;
				});
				this.customTemplates = this.gridUtils.processItemsTree(response.data);
				this.redrawTemplates(this.customTemplates);
			});
	};

	redrawTemplates = (templates: any[]) => {
		// make sure arrays are never the same instance
		this.lastChange = [].concat(templates);
	};

	private onDashboardTemplateClick = (event, item: ITreeItem) => {
		if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, item, this.contextMenuUtils.getContextMenu(item));
		} else if (item.type !== FolderTypes.DASHBOARD_TEMPLATES) {
			this.actionsService.createDashboard(item);
		}
	};

	addFolder = () => this.actionsService.createFolder();

	canManageTemplates = () => this.security.has('manage_internal_templates');
}

app.component('customTemplates', {
	controller: CustomTemplatesComponent,
	template: `
<section>
	<div ng-if="$ctrl.customTemplatesEnabled"
		cg-busy="[ $ctrl.promises.loadingCustomTemplates, $ctrl.promises.operatingOnTemplate, $ctrl.templateCommonService.creatingDashboard ]">
		<div class="grid-list-tools">
			<div class="tools">
				<div class="grid-filters">
					<span ng-if="$ctrl.isLoading()" class="loading-spinner q-icon-spinner rotate-infinite"></span>
					<input
						type="text"
						ng-escape="$ctrl.ui.searchFilter=''"
						class="br-dash-search-bar grid-search-bar"
						placeholder="{{::'templates.templateSearchPlaceholder'|i18n}}"
						aria-label="{{::'templates.templateSearchPlaceholder'|i18n}}"
						ng-model="$ctrl.ui.searchFilter"
						ng-model-options="{debounce: 300}">
				</div>
				<div class="grid-buttons" ng-if="$ctrl.canManageTemplates()">
					<button
						ng-click="$ctrl.addFolder()"
						class="btn btn-primary">{{::'dashboard.newFolder'|i18n}}</button>
				</div>
			</div>
		</div>

		<item-grid
			class="h-100-percent w-100-percent br-dash-grid br-grid"
			tree-data="$ctrl.customTemplates"
			grid-type="$ctrl.gridType"
			grid-filter="$ctrl.ui.searchFilter"
			grid-change="$ctrl.lastChange"
			grid-options="$ctrl.gridOptions"
			controller="$ctrl">
		</item-grid>
	</div>
</section>`
});
