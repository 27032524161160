import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { MenuItemDef, RowNode } from 'ag-grid-enterprise';
import { AgGridDashboardFolderActionsService } from '../actions/ag-grid-dashboard-folder-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { MoveToOptionService } from '../options/move-to-option.service';

@Injectable({
	providedIn: 'root'
})
export class FolderMenuOptionsProviderService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly agGridDashboardFolderActionsService: AgGridDashboardFolderActionsService,
		private readonly moveToOptionService: MoveToOptionService,
		private readonly dashboardGridHelperService: DashboardGridHelperService,
		@Inject('security') private readonly security: Security,
		@Inject('environmentService') private environmentService: EnvironmentService,
	) {}

	getOptions(node: RowNode): (string | MenuItemDef)[] {
		let folder: IFolderItem = node.data;

		let items: any[] = [];

		if (this.security.has('create_dashboard') && !this.environmentService.isTablet()) {
			items.push(this.getCreateDashboardOption(folder));
			items.push(this.getCreateBookOption(folder));
		}

		if (this.areSubfoldersAvailable(node)) {
			items.push(this.getCreateFolderOption(folder));
		}

		items.push(this.getRenameOption(node));

		let moveToSubmenuOptions: (string | MenuItemDef)[] = this.moveToOptionService.getSubMenu(folder);
		if (moveToSubmenuOptions && moveToSubmenuOptions.length > 0) {
			items.push(this.moveToOptionService.get(moveToSubmenuOptions));
		}

		items.push(this.getRemoveOption(folder));

		return items;
	}

	private getCreateDashboardOption(folder: IFolderItem): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.createNewDashboard'),
			action: () => this.agGridDashboardFolderActionsService.createDashboard(folder)
		};
	}

	private getCreateBookOption(folder: IFolderItem): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.createNewBook'),
			action: () => this.agGridDashboardFolderActionsService.createBook(folder)
		};
	}

	areSubfoldersAvailable(node: RowNode): boolean {
		return node.level < FolderContextMenuUtils.MAX_DEPTH - 1;
	}

	private getCreateFolderOption(folder: IFolderItem): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.addSubfolder'),
			action: () => this.agGridDashboardFolderActionsService.createFolder(folder)
		};
	}

	private getRenameOption(node: RowNode): MenuItemDef {
		let folder: IFolderItem = node.data;

		return {
			name: this.locale.getString('common.rename'),
			action: () => {
				this.agGridDashboardFolderActionsService.renameFolder(folder).then((renamedFolder) => {
					renamedFolder.displayName = renamedFolder.name;
					this.dashboardGridHelperService.updateGridRow(node, renamedFolder);
				});
			}
		};
	}

	private getRemoveOption(folder: IFolderItem): MenuItemDef {
		return {
			name: this.dashboardGridHelperService.getDeletionText(folder),
			action: () => this.agGridDashboardFolderActionsService.removeFolder(folder)
		};
	}

}
