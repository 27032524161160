import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';

export enum FilterRuleType {
	numericRange = 'RANGE_NUMERIC_ATTRIBUTE',
	numericOpenRange = 'OPEN_RANGE_NUMERIC_ATTRIBUTE',
	numericEquality = 'EQUALS_NUMERIC_ATTRIBUTE',
	stringEquality = 'EQUALS_STRING_ATTRIBUTE',
	stringEqualityLC = 'EQUALS_STRING_ATTRIBUTE_LC',
	esQueryRule = 'ES_QUERY_RULE',
	and = 'AND',
	or = 'OR',
	empty = 'EMPTY',
	raw = 'RAW',
	topicEquality = 'TOPIC',
	topicGlobalOther = 'TOPIC_GLOBAL_OTHER',
	dateRange = 'DATE_RANGE',
	savedFilter = 'SAVED_FILTER',
	scorecardFilter = 'SCORECARD_FILTER',
	predefined = 'PREDEFINED',
	text = 'TEXT_FILTER',
}

export class FilterRuleTypes {
	private static attributeRuleTypes = [
		FilterRuleType.numericRange, FilterRuleType.numericEquality,
		FilterRuleType.stringEquality, FilterRuleType.stringEqualityLC,
		FilterRuleType.numericOpenRange
	];

	private static numericAttributeRuleTypes = [
		FilterRuleType.numericRange, FilterRuleType.numericEquality,
		FilterRuleType.numericOpenRange
	];

	static readonly TEXT_FILTER_WITH_INPUT = 'TEXT_FILTER_INPUT';

	static isSavedFilterRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.savedFilter;
	};

	static isScorecardFilterRule = (rule: any): boolean => {
		return rule.type === FilterRuleType.scorecardFilter;
	};

	static isPredefinedRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.predefined;
	};

	static isTopicRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.topicEquality;
	};

	static isAttributeRule = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.attributeRuleTypes.contains(rule.type);
	};

	static isNumericAttributeRule = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.numericAttributeRuleTypes.contains(rule.type);
	};

	static isDocumentDateRule = (rule: IFilterRule): boolean => {
		return rule.name === OptionsConstant.DATE_RANGE || rule.name === OptionsConstant.PINNED_DATE_RANGE;
	};

	static isDateRangeRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.dateRange;
	};

	static isDocumentDateAttributeRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.dateRange && rule.name === ClarabridgeMetricName.DOCUMENT_DATE;
	};

	static isAnalyzeFilter = (rule: IFilterRule): boolean => {
		return rule.filterType === FilterTypes.CXANALYZE;
	};

	static isEsQueryRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.esQueryRule;
	};

	static isText = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.text;
	};

	static isTextWithInput = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.text && rule.name === FilterRuleTypes.TEXT_FILTER_WITH_INPUT;
	};

	static isCBAttributeFilter = (rule: IFilterRule): boolean => {
		return rule.filterType === FilterTypes.ATTRIBUTE && rule.name.startsWith('cb_');
	};

	static isGlobalOtherRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.topicGlobalOther;
	};

	static isRawRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.raw;
	};

	static isEmptyRule = (rule: IFilterRule): boolean => {
		return rule.type === FilterRuleType.empty;
	};
}
