import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { ExportBuilder } from '@cxstudio/reports/utils/export/export-builder.class';
import { ExportDataProcessors } from '@cxstudio/reports/utils/export/export-data-processors.service';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { DateService } from '@cxstudio/services/date-service.service';
import { FilterParsingService } from '@cxstudio/services/filter-parsing-service';
import * as _ from 'underscore';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { CombinedFiltersService, FiltersGroup } from '@app/modules/filter/services/combined-filters.service';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { AppliedFiltersFactory } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import { ContentProviderService } from '../content-provider.service';
import { HttpResponse } from '@cxstudio/common/http-response';

export interface ExportAssetsOptions {
	models: IReportModel[];
	attributes: IReportAttribute[];
	filters: IPlatformFilter[];
	newFilters: FiltersGroup[];
	projectTimezone: string;
	predefinedMetrics: Metric[];
	studioMetrics: Metric[];
}

export interface IExportAssets {
	contentProviders: ContentProvider[];
	accounts: ContentProviderAccount[];
	projects: AccountProject[];
	options?: ExportAssetsOptions;
	hasChanges?: boolean;
	allWidgets?: boolean;
}

export class ExportUtils {

	readonly OCTET_STREAM_TYPE = {type: 'application/octet-stream;' };

	constructor(
		private $q: ng.IQService,
		private $log: ng.ILogService,
		private dateService: DateService,
		private locale: ILocale,
		private contentProviderService: ContentProviderService,
		private DateRange,
		private filterParsingService: FilterParsingService,
		private dashboardFiltersService: DashboardFiltersService,
		private dateFilterService: DateFilterService,
		private dashboardFilterLabels: DashboardFilterLabelsService,
		private appliedFiltersFactory: AppliedFiltersFactory,
		private exportDataProcessors: ExportDataProcessors,
		private readonly reportProjectContextService: ReportProjectContextService,
		private readonly reportAttributesService: ReportAttributesService,
		private readonly reportModelsService: ReportModelsService,
		private readonly reportMetricsService: ReportMetricsService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly combinedFiltersService: CombinedFiltersService,
	) {}

	exportCSV = (fileName: string, csv: string) => {
		let blob = new Blob(['\ufeff' + csv], {type: 'text/csv;charset=utf-8;'});
		this.downloadExportedData(blob, fileName);
	};

	exportXLSX = (fileName: string, data) => {
		let blob = new Blob([data], this.OCTET_STREAM_TYPE);
		this.downloadExportedData(blob, fileName);
	};

	exportPDF = (fileName: string, fileData) => {
		let blob = new Blob([fileData], this.OCTET_STREAM_TYPE);
		this.downloadExportedData(blob, fileName);
	};

	exportText = (fileName: string, fileData) => {
		let blob = new Blob([fileData], this.OCTET_STREAM_TYPE);
		this.downloadExportedData(blob, fileName);
	};

	exportMp3 = (fileName: string, fileData) => {
		let blob = new Blob([fileData], { type: 'audio/mpeg3'} );
		this.downloadExportedData(blob, fileName);
	};

	downloadResponseFile = (response, defaultFilename?: string) => {
		let headers = response.headers;
		let data = response.data;

		let filename = headers('Content-Disposition').match('filename=\"(.*?)\"')[1];

		if (_.isUndefined(filename)) {
			filename = defaultFilename;
		}

		this.exportXLSX(filename, data);
	};

	downloadExportedData = (data, fileName) => {
		if ((navigator as any).msSaveBlob) { // IE 10+
			(navigator as any).msSaveBlob(data, fileName);
		} else {
			let link = document.createElement('a');
			if (link.download !== undefined) { // feature detection
				// Browsers that support HTML5 download attribute
				let url = URL.createObjectURL(data);
				link.setAttribute('href', url);
				link.setAttribute('download', fileName);
				link.setAttribute('style', 'visibility:hidden');
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			}
		}
	};

	findInObjectArray = (arr: any[], id, propId, propName) => {
		let result = _.find(arr, item => item[propId] === id);
		return result?.[propName];
	};

	getFullPathByNodeId = (nodeId, result, currentNode, currPath) => {
		if (currentNode.id === nodeId) {
			result.found = (currPath ? currPath + ' >> ' : '') + currentNode.name;
			return;
		}
		if (!result.found && currentNode.children) {
			currentNode.children.forEach(child => {
				this.getFullPathByNodeId(nodeId, result, child,
					(currPath ? currPath + ' >> ' : '') + currentNode.name);
			});
		}
	};

	private exportCPandAccountData(widget: Widget, contentProviders, accounts, projects, utils): void {
		let valuesToExport = [];

		if (contentProviders.length > 0) {
			let projectName;
			let contentProviderName = this.findInObjectArray(contentProviders, widget.properties.contentProviderId, 'id', 'name');
			let accountName = this.findInObjectArray(accounts, widget.properties.accountId, 'accountId', 'accountName');
			projectName = this.findInObjectArray(projects, widget.properties.project, 'projectId', 'name');
			valuesToExport = [contentProviderName, accountName, projectName];
		}
		valuesToExport.push(widget.properties.runAs, widget.description ? widget.description : (widget.properties as any).description);
		utils.joinColumns(valuesToExport);
		utils.addEmptyRows();
	}

	private exportAdditionalSpecificationsAN(widget: Widget, utils: ExportBuilder, data): void {
		let headers, values;

		_.forEach(widget.properties.selectedAttributes, (attribute) => {
			headers = [];
			values = [];
			utils.joinColumns([attribute.displayName]);

			headers.push(this.locale.getString('widget.show').toUpperCase());
			if (!attribute.sortOrder || attribute.sortOrder === 'desc') {
				values.push(this.locale.getString('widget.top'));
			} else {
				values.push(this.locale.getString('widget.bottom'));
			}

			headers.push(this.locale.getString('widget.quantity').toUpperCase());

			if (!AnalyticMetricTypes.isTime(attribute)) {
				values.push(attribute.size);
			}

			headers.push(this.locale.getString('widget.minimumVolume').toUpperCase());
			if (attribute.minDocCount) {
				values.push(attribute.minDocCount);
			} else {
				values.push('');
			}

			utils.joinColumns(headers);
			utils.joinColumns(values);
			utils.addEmptyRows();
		});


		utils.joinColumns([
			this.locale.getString('widget.nequals')
		]);

		let n = 0;
		if (data.metadata && data.metadata.totalCount > 0) {
			n = data.metadata.totalCount;
		}
		utils.joinColumns([
			n
		]);

		utils.addEmptyRows();
	}

	private exportScorecardViewerSpecifications(data, utils: ExportBuilder): void {
		let metadata = data.metadata;
		if (!metadata)
			return;
		let cols = [
			this.locale.getString('scorecards.totalLabel'),
			this.locale.getString('scorecards.targetLabel'),
			this.locale.getString('scorecards.targetNLabel'),
			this.locale.getString('widget.nequals'),
		];
		let values = [
			metadata.total,
			metadata.target,
			metadata.targetN,
			data.total.volume
		];

		utils.joinColumns(cols);
		utils.joinColumns(values);
		utils.addEmptyRows();
	}

	private getSampling(sample): string {
		switch (sample) {
		case 'sample': return this.locale.getString('common.sampled');
		case 'refine': return this.locale.getString('common.refined');
		case 'full': return this.locale.getString('common.full');
		}
		return sample;
	}

	private exportFilterSpecifications(utils: ExportBuilder, widgetMetadata: WidgetFiltersMetadata, properties: WidgetProperties): void {
		let appliedFilters = this.appliedFiltersFactory.getAppliedFilters(widgetMetadata, true, false);
		let dashboardFilters = widgetMetadata.dashboardFilters;
		let drillToDashboardFilters = widgetMetadata.drillToDashboardFilters;

		if (!appliedFilters.ignorePersonalization()) {
			utils.joinColumns(this.locale.getString('dashboard.organizationFilters').toUpperCase());
			utils.joinColumns(appliedFilters.getPersonalizationString());
			utils.addEmptyRows();
		}

		if (dashboardFilters && dashboardFilters.length > 0) {
			let labels = _.chain(dashboardFilters)
				.filter(filter => !filter.ignored)
				.map((filter) => {
					let filterLabel = this.dashboardFilterLabels.getFilterLabel(filter, widgetMetadata.projectTimezone, true);
					if (filterLabel && filter.selectedAttribute?.displayName) {
						filterLabel = `${filter.selectedAttribute.displayName} ${filterLabel}`;
					}
					return filterLabel;
				}).value();

			utils.joinColumns(this.locale.getString('dashboard.exportDashboardFilter'));
			utils.joinColumns(labels.filter(str => str && str.length).join(' / '));
			utils.addEmptyRows();
		}

		if (widgetMetadata.textFilter) {
			utils.joinColumns(this.locale.getString('filter.textFilter').toUpperCase());
			utils.joinColumns(widgetMetadata.textFilter);
			utils.addEmptyRows();
		}

		if (properties.quickFilter) {
			utils.joinColumns(this.locale.getString('widget.quickFilter').toUpperCase());
			utils.joinColumns(properties.quickFilter.text);
			utils.addEmptyRows();
		}

		if (!isEmpty(drillToDashboardFilters)) {
			let drillToDashboardFilterLabels = [];
			drillToDashboardFilters.forEach( (filter) => {
				let filterLabel = this.dashboardFilterLabels.getFilterLabel(filter, widgetMetadata.projectTimezone);
				drillToDashboardFilterLabels.push(filterLabel);
			});

			utils.joinColumns(this.locale.getString('dashboard.drilledFilters').toUpperCase());
			utils.joinColumns(drillToDashboardFilterLabels.filter((str) => {
				return str && str.length;
			}).join(' / '));
			utils.addEmptyRows();
		}

		if (widgetMetadata.widgetDateFilter) {
			utils.joinColumns(this.locale.getString('widget.dateFilterLabel').toUpperCase());
			utils.joinColumns(widgetMetadata.widgetDateFilter);
			utils.addEmptyRows();
		}

		if (widgetMetadata.appliedFilters && widgetMetadata.appliedFilters.filters.length > 0) {
			utils.joinColumns(this.locale.getString('widget.exportAppliedFilters'));
			utils.joinColumns(widgetMetadata.appliedFilters.filters.map(f => f.name).join(','));
			utils.addEmptyRows();
		}

		if (widgetMetadata.adhocFilter && widgetMetadata.adhocFilter.filterRules.length > 0) {
			utils.joinColumns(this.locale.getString('widget.exportAdditionalConditions'));
			utils.joinColumns(widgetMetadata.adhocFilter.filterRules.map(r => this.filterParsingService.getRuleString(r))
				.join(','));
			utils.addEmptyRows();
		}

		if (!_.isEmpty(widgetMetadata.parsedDrillFilters)) {
			utils.joinColumns(this.locale.getString('widget.exportDrilledFilters'));
			utils.joinColumns(widgetMetadata.parsedDrillFilters.join(','));
			utils.addEmptyRows();
		}

		if (!_.isEmpty(widgetMetadata.parsedLinkedFilters)) {
			utils.joinColumns(this.locale.getString('widget.exportLinkedFilters'));
			utils.joinColumns(widgetMetadata.parsedLinkedFilters.join(','));
			utils.addEmptyRows();
		}

		if (!_.isEmpty(widgetMetadata.docExplorerFilters)) {
			utils.joinColumns(this.locale.getString('dashboard.docExplorerFilters'));
			utils.joinColumns(widgetMetadata.docExplorerFilters.join(','));
			utils.addEmptyRows();
		}
	}


	private exportWidgetConfigurationSpecs(widget: Widget, contentProviders, accounts, projects,
			utils: ExportBuilder, widgetMetadata: WidgetFiltersMetadata, data): void {

		if (contentProviders.length > 0) {
			utils.joinColumns([
				this.locale.getString('preview.cp'),
				this.locale.getString('preview.acc'),
				this.locale.getString('preview.proj'),
				this.locale.getString('common.owner'),
				this.locale.getString('preview.description')
			]);
		} else {
			utils.joinColumns([
				this.locale.getString('common.owner'),
				this.locale.getString('preview.description')
			]);
		}

		this.exportCPandAccountData(widget, contentProviders, accounts, projects, utils);
		utils.joinColumns(this.locale.getString('widget.exportTitle'));
		utils.joinColumns(widget.documentExplorerName ? widget.documentExplorerName : widget.displayName);
		utils.addEmptyRows();

		if (widget.properties.widgetType !== WidgetType.PREVIEW) {
			if (ReportConstants.isAnalyticWidget(widget.properties.widgetType)) {
				this.exportAdditionalSpecificationsAN(widget, utils, data);
			} else if (ReportConstants.isObjectViewerWidget(widget.properties.widgetType)) {
				this.exportScorecardViewerSpecifications(data, utils);
			}
		}
		this.exportFilterSpecifications(utils, widgetMetadata, widget.properties);
	}

	getWidgetTableData = (widget: Widget, data, allOptions, allCols): ng.IPromise<string> => {
		let utils = new ExportBuilder([]);
		let options = allOptions && allOptions.options || { models: [], attributes: [], filters: [] };
		let tmpData = data.data;
		if (!tmpData) {
			return this.$q.when('');
		}

		return this.appendTableExportData(utils, widget, options, allOptions, data, tmpData, allCols);
	};

	private appendTableExportData = (utils: ExportBuilder, widget: Widget, options,
			allOptions, data, tmpData, allCols): ng.IPromise<string> => {
		let processorResult = this.exportDataProcessors[widget.name]
			? this.exportDataProcessors[widget.name](utils, widget, options, allOptions, data, tmpData, allCols)
			: this.exportDataProcessors.general(utils, widget, options, allOptions, data, tmpData);

		return this.$q.resolve(processorResult).then(() => this.$q.when(utils.joinRows()));
	};

	getWidgetDataForExport = (widget: Widget, data, allOptions, allCols, widgetMetadata: WidgetFiltersMetadata): ng.IPromise<string> => {
		let exportContext = new ExportBuilder([]);

		return this.getWidgetHeaderForExport(widget, data, allOptions, widgetMetadata, exportContext).then(() => {
			let options = allOptions && allOptions.options || { models: [], attributes: [], filters: [] };

			let tmpData = !data.data ? angular.copy(data) : angular.copy(data.data);
			if (!tmpData || tmpData.length === 0) {
				exportContext.joinColumns(this.locale.getString('widget.noData'));
				return this.$q.when(exportContext.joinRows());
			}

			if (widget.visualProperties && widget.visualProperties.visualization === 'CLOUD') {
				this.cleanCloud(tmpData);
			}

			return this.appendTableExportData(exportContext, widget, options, allOptions, data, tmpData, allCols);
		});
	};

	getWidgetHeaderForExport(
		widget: Widget,
		data: ReportDataObject,
		allOptions,
		widgetMetadata: WidgetFiltersMetadata,
		exportContext?: ExportBuilder
	): ng.IPromise<string> {

		if (!data) {
			return this.$q.reject();
		}

		exportContext = exportContext || new ExportBuilder([]);

		let contentProviders = allOptions && allOptions.contentProviders || [];
		let accounts = allOptions && allOptions.accounts || [];
		let projects = allOptions && allOptions.projects || [];

		if (allOptions && allOptions.hasChanges) {
			exportContext.joinColumns(this.locale.getString('widget.hasChangesInViewMode'));
			exportContext.addEmptyRows();
		}

		this.exportWidgetConfigurationSpecs(widget, contentProviders, accounts, projects, exportContext, widgetMetadata, data);
		this.appendDates(widget, exportContext, data.metadata);

		return this.$q.when(exportContext.joinRows());
	}

	private appendDates(widget: Widget, utils: ExportBuilder, dataMetadata): void {
		let cols = [
			this.locale.getString('widget.exportDateRun'),
			this.locale.getString('widget.exportDateExported')
		];
		let values = [
			widget.details ? widget.details.runDate : this.dateService.format(new Date()),
			this.dateService.format(new Date())
		];
		if (ReportConstants.isObjectViewerWidget(widget.properties.widgetType)) {
			cols.push(this.locale.getString('widget.exportRubricUpdated'));
			values.push(this.dateService.format(new Date(dataMetadata.lastModified)));
		}
		utils.joinColumns(cols);
		utils.joinColumns(values);
		utils.addEmptyRows();
		if (ReportConstants.isAnalyticWidget(widget.properties.widgetType) && !!widget.id) {
			utils.joinColumns([this.locale.getString('widget.periods')]);
			this.assemblePeriodRecord(widget, utils, 1, dataMetadata);
			this.assemblePeriodRecord(widget, utils, 2, dataMetadata);
		}
		utils.addEmptyRows();
	}

	private cleanCloud(widgetData): void {
		widgetData.forEach(data => {
			delete data.cnt;
			delete data.series;
			delete data.getLabelConfig;
		});
	}

	private assemblePeriodRecord(widget: Widget, utils: ExportBuilder, period, dataMetadata): void {
		if (period === 2 && !widget.properties.useHistoricPeriod) {
			return;
		}
		let cells = [];
		let periodName = `period_${period}_`;
		if (widget.visualProperties.periodLabel && widget.visualProperties.periodLabel[periodName]) {
			cells.push(widget.visualProperties.periodLabel[periodName]);
		} else {
			cells.push('');
		}
		cells.push(this.DateRange.valueOf(this.dashboardFiltersService
			.getActiveDateFilterMode(widget.properties, period, widget.containerId)).displayName);
		if (dataMetadata) {
			let start = dataMetadata[`P${period}StartDate`];
			let end = dataMetadata[`P${period}EndDate`];
			if (start && end) {
				cells.push(this.dateFilterService.formatZonedDateRange({from: start, to: end}));
			}
		}
		utils.joinColumns(cells);
	}

	getCBExportOptions = (widget: Widget, data: ReportDataObject): ng.IPromise<IExportAssets> => {
		let props = widget.properties;
		return this.getProjectOptions(props).then((allOptions) => {
			let widgetFiltersPromise = PromiseUtils.old(this.getWidgetPlatformFilters(widget, data));
			let widgetNewFilters = PromiseUtils.old(this.getWidgetFilters(widget, data));
			let widgetModelsPromise = PromiseUtils.old(this.reportModelsService.getWidgetModels(widget));
			let widgetAttributesPromise = PromiseUtils.old(this.reportAttributesService.getWidgetAttributes(widget));
			let projectTimezonePromise = this.getProjectTimezone(widget);
			let predefinedMetricsPromise = PromiseUtils.old(this.reportMetricsService.getWidgetPredefinedMetrics(widget));
			let studioMetricsPromise = PromiseUtils.old(this.reportMetricsService.getWidgetMetrics(widget));

			return this.$q.all([widgetFiltersPromise, widgetModelsPromise, widgetAttributesPromise,
				widgetNewFilters, projectTimezonePromise, predefinedMetricsPromise, studioMetricsPromise]).then((result) => {
				let filters = result[0];
				let models = result[1];
				let attributes = result[2];
				let newFilters = result[3];
				let projectTimezone = result[4];
				let predefinedMetrics = result[5];
				let studioMetrics = result[6];
				allOptions.options = {
					models,
					attributes,
					filters,
					newFilters,
					projectTimezone,
					predefinedMetrics,
					studioMetrics
				};
				return allOptions;
			}, (cause) => {
				this.$log.warn(cause);
				return allOptions;
			});
		});
	};

	private getWidgetPlatformFilters(widget: Widget, data: ReportDataObject): Promise<IPlatformFilter[]> {
		if (!data?.metadata?.reportExecutionAssets) {
			return this.reportFiltersService.getWidgetPlatformFilters(widget);
		}

		return Promise.resolve(data.metadata.reportExecutionAssets.designerFilters);
	}

	private getWidgetFilters(widget: Widget, data: ReportDataObject): Promise<FiltersGroup[]> {
		if (!data?.metadata?.reportExecutionAssets) {
			return this.combinedFiltersService.getWidgetFilters(widget);
		}

		let assets = data.metadata.reportExecutionAssets;

		let filterList = this.combinedFiltersService.convertAndGroupFilters(
			assets.designerFilters, assets.appliedFilters, assets.scorecardFilters, widget.properties.project);

		return Promise.resolve(filterList);
	}

	private getProjectOptions(props): ng.IPromise<IExportAssets> {
		if (props.projectName) {
			return this.$q.when({
				contentProviders: [{id: props.contentProviderId, name: props.contentProviderName} as ContentProvider],
				accounts: [{accountId: props.accountId, accountName: props.accountName} as ContentProviderAccount],
				projects: [{projectId: props.project, name: props.projectName} as AccountProject]
			});
		} else {
			let allOptions = {} as any;
			return this.contentProviderService.getContentProvider(props.contentProviderId)
				.then((cp) => {
					if (!cp) {
						return allOptions;
					}
					allOptions.contentProviders = [cp];
					return cp.getAccounts().then((accounts: any[]) => {
						allOptions.accounts = accounts;
						let acc = _.findWhere(accounts, {accountId: props.accountId});
						if (!acc) {
							return allOptions;
						} else {
							return acc.getProjects().then((projects) => {
								allOptions.projects = projects;
								return allOptions;
							});
						}
					});
				});
		}
	}

	private getProjectTimezone = (widget: Widget): ng.IPromise<string> => {
		return PromiseUtils.old(this.reportProjectContextService.getWidgetProjectTimezone(widget));
	};

	reportExportFilename = (widget: Widget, extension: string, suffix?: string): string => {
		let widgetName = widget.displayName.substring(0, 30);
		let runDate = suffix ? `...${suffix}` : '';
		return  `${widgetName + runDate}.${extension}`;
	};

	explorerExportFilename = (widget: Widget, extension: string): string => {
		let explorerName = widget.documentExplorerName.length > 30 ?
			`${widget.documentExplorerName.substring(0, 30)}...` :
			widget.documentExplorerName;
		return  `${explorerName}.${extension}`;
	};

	getGridsterClientWidth = (): number => {
		let wrapper = $('.gridster-wrapper')[0];
		return wrapper && wrapper.clientWidth;
	};

	downloadXLSX = (apiResponse: HttpResponse<unknown>): void => {
		const headers = apiResponse.headers;
		const data = apiResponse.data;

		let filename = headers('Content-Disposition').match('filename=\"(.*?)\"')[1];

		if (_.isUndefined(filename)) {
			filename = 'export.xlsx';
		}

		this.exportXLSX(filename, data);
	};
}

app.service('exportUtils', ExportUtils);
