import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { DocumentTypeUtils } from '../document-type-utils.class';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { AmplitudeEvent } from '../../analytics/amplitude/amplitude-event';
import { contextSectionsVisibility } from '@app/core/storage/locally-stored-items';

@Component({
	selector: 'document-explorer-menu',
	templateUrl: './document-explorer-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerMenuComponent implements OnInit {

	@Input() sharingFeedbackSelection;
	@Input() preferences: DocViewPreferences;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() panels: {[key: string]: {key: string; visible: boolean}};
	@Input() trigger: boolean; // used for change detection when dropdown is opened

	contextSectionToggles: {key: string; callback: () => void}[] = [];

	constructor(
		private betaFeaturesService: BetaFeaturesService,
		private engagorService: EngagorService,
	) { }

	ngOnInit(): void {
		this.initContextSectionOptions();
	}

	private getToggleCallback(key: string) {
		this.preferences.settings.contextSectionsVisibility[key] = this.panels[key].visible;
		this.preferences.storeUpdates();
	}

	private showToggle(key: string) {
		if (key === 'narrative') {
			return this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES);
		}
		if (key === 'scorecards') {
			return this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
		}
		if (key === 'engage') {
			return this.engagorService.isIntegrationEnabled();
		}
		if (key === 'automatedCallSummary') {
			return this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_SUMMARIES);
		}
		if (key === 'complianceManagement') {
			return this.betaFeaturesService.isFeatureEnabled(BetaFeature.COMPLIANCE_MANAGEMENT);
		}
		return true;
	}

	explorerArrowNav(event: KeyboardEvent): void {
		let focusedItem = $(event.target);
		if (focusedItem.length) {
			KeyboardUtils.intercept(event);
			if (KeyboardUtils.isEventKey(event, Key.UP)) {
				let focusedList = $('document-explorer-menu').find(':focusable:not(button)');
				let focusedMenuItemIndex = focusedList.index($(document.activeElement as HTMLElement));
				if(focusedMenuItemIndex > 0)
				{
					(focusedList.get(focusedMenuItemIndex - 1) as HTMLElement).focus();
				}
			}
			else if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
				let focusedList = $('document-explorer-menu').find(':focusable:not(button)');
				let focusedMenuItemIndex = focusedList.index($(document.activeElement as HTMLElement));
				if(focusedMenuItemIndex < focusedList.length - 1)
				{
					(focusedList.get(focusedMenuItemIndex + 1) as HTMLElement).focus();
				}
			}
		}
	}

	private checkVisibleContextSubsections(): void {
		for (let key in this.panels) {
			if (this.panels[key].visible) {
				// as soon as we find a visible subsection, return
				return;
			}
		}

		// no subsections visible
		this.preferences.settings.visiblePanes.context = false;
		this.preferences.storeUpdates();
	}

	private initContextSectionOptions() {
		this.contextSectionToggles = [];
		const allPanels = {
			...contextSectionsVisibility,
			...this.preferences.settings.contextSectionsVisibility
		};
		_.each(allPanels, (visible, section) => {
			if (this.showToggle(section)) {
				this.contextSectionToggles.push({
					key: section,
					callback: () => {
						this.checkVisibleContextSubsections();
						this.getToggleCallback(section);
					}
				});
			}
			this.panels[section] = {
				key: section,
				visible
			};
		});
	}

	private getCurrentDocument() {
		return this.documentManager?.documents[this.documentManager?.selectedDocument];
	}

	isConversation() {
		return DocumentTypeUtils.hasConversationMetrics(this.getCurrentDocument());
	}

	getAmplitudeEvent(setting: string): {event; group; properties} {
		const document = this.documentManager.documents[this.documentManager.selectedDocument];
		const {source, documentId, documentType } = AmplitudeEventUtils.getBaseDocumentViewEvent(document, true);

		return {
			event: AmplitudeEvent.DOCEXPLORER_SETTING_CHANGE,
			group: {
				documentId
			},
			properties: {
				setting,
				source,
				documentType
			}
		};
	}
}
