import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FileUploadResult } from '@app/modules/hierarchy/upload/upload-result/file-upload-result';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { HierarchyWizardScope } from '@cxstudio/organizations/hierarchy-wizard-scope';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { FileUploaderService } from '@cxstudio/services/file-upload.service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';

interface LocalScope extends HierarchyWizardScope {
	generateGroupsSelected: boolean;
	skipNonExistUsers: boolean;

	removeGroupsPromise: PromiseLike<any>;
	removeOrganizationGroups: () => void;
	getUsersForNode: (node) => string;
	generateGroupChange: (result) => void;
	generateSkipNonExistUsersChange: (result) => void;
	userNonExistence: boolean;
	userNonExistenceList: any[];
}

export class OrganizationUsersWizardController implements ng.IController {
	private readonly MAX_FILE_SIZE = 5 * Math.pow(2, 21); //10Mb

	constructor(
		private $scope: LocalScope,
		private $q,
		private update,
		private hierarchy: Hierarchy,
		private $uibModalInstance,
		private errorDialogService: ErrorDialogService,
		private fileUploadService: FileUploaderService,
		private urlService: UrlService,
		private $timeout,
		private organizationApiService: OrganizationApiService,
		private $log,
		private exportUtils: ExportUtils,
		private locale: ILocale,
		private cxDialogService: CxDialogService,
		private betaFeaturesService: BetaFeaturesService,
	) {
		this.$scope.update = update;
		this.$scope.hierarchy = hierarchy;
		this.$scope.hierarchyId = hierarchy.id;

		this.$scope.uploadResult = FileUploadResult.empty();
		this.$scope.defer = null;
		this.$scope.uploadPromise = this.$q.when();

		this.$scope.fileReady = false;
		this.$scope.generateGroupsSelected = false;
		this.$scope.skipNonExistUsers = hierarchy.skipNonExistUsers || false;

		this.$scope.result = null;

		$scope.steps = this.isDynamicFiltering() ?  [{
			templateUrl: 'partials/organizations/users/organization-users-wizard-upload.html',
			nextAllowed: () => {
				return $scope.fileReady === true;
			}
		}, {
			templateUrl: 'partials/organizations/users/organization-users-wizard-confirm.html',
			prevAllowed: () => {
				return $scope.fileReady === false;
			}
		}] : [{
			templateUrl: 'partials/organizations/users/organization-users-wizard-upload.html',
			nextAllowed: () => {
				return $scope.fileReady === true;
			}
		}, {
			templateUrl: 'partials/organizations/users/organization-users-wizard-group.html',
			nextAllowed: () => {
				return $scope.generateGroupsSelected === true;
			},
			prevAllowed: () => {
				return true;
			}
		}, {
			templateUrl: 'partials/organizations/users/organization-users-wizard-confirm.html',
			prevAllowed: () => {
				return $scope.fileReady === false;
			}
		}];

		this.$scope.currentStep = 0;

		this.$scope.getCurrentStepTemplate = () => {
			return $scope.steps[$scope.currentStep].templateUrl;
		};

		this.$scope.allowedNext = () => {
			return $scope.steps[$scope.currentStep].nextAllowed && $scope.steps[$scope.currentStep].nextAllowed() === true;
		};

		this.$scope.allowedPrev = () => {
			return $scope.steps[$scope.currentStep].prevAllowed
					&& $scope.steps[$scope.currentStep].prevAllowed() === true;
		};

		this.$scope.hasNext = () => {
			return $scope.currentStep < $scope.steps.length - 1;
		};

		this.$scope.hasPrev = () => {
			return this.$scope.currentStep > 0;
		};

		this.$scope.next = () => {
			this.$scope.currentStep++;
			if (this.$scope.currentStep === this.$scope.steps.length - 1) {
				if (this.isDynamicFiltering()) {
					this.$scope.generateGroupChange(false);
				}
				this.$scope.defer = $q.defer();
				this.$scope.uploadPromise = this.$scope.defer.promise;
				this.$scope.fileItem.upload();
			}
		};

		this.$scope.back = () => {
				this.$scope.currentStep--;
		};

		$scope.cancel = () => {
			this.$uibModalInstance.dismiss('cancel');
		};

		this.$scope.finish = () => {
			// return to save
			this.$uibModalInstance.close($scope.result);
		};

		let timer;
		this.$scope.passClickEvent = (event) => {
			let inputElement = (event.target.tagName.toUpperCase() === 'BUTTON')
				? event.target.getElementsByTagName('input')
				: event.target.nextElementSibling;

			timer = $timeout(() => {
				angular.element(inputElement).trigger('click');
				$timeout.cancel(timer);
			}, 0, false);
		};

		this.$scope.url = this.urlService.getAPIUrl(`rest/organization/users/file/upload/${$scope.hierarchyId}`);
		this.$scope.uploader = this.fileUploadService.getUploader({
			url: $scope.url,
			filters: [this.fileUploadService.getOHFileExtensionFilter()]
		});

		this.$scope.uploader.onErrorItem = (fileItem, response, status, headers) => {
			this.$scope.fileReady = false;
			if (_.isObject(response) && response.errorCode === 'userNonExistence') {
				this.$scope.uploadResult = FileUploadResult.error(this.locale.getString('organization.userNonExistence'));
				this.generateUserNonExistenceTable(response.userNonExistenceList);
			} else {
				this.$scope.uploadResult = FileUploadResult.failed(headers, response, this.locale);
			}
			this.$scope.defer.reject();
		};

		this.$scope.uploader.onWhenAddingFileFailed = (item, filter, options) => {
			this.$log.debug(item);
			this.$log.debug(filter);
			this.$log.debug(options);
			errorDialogService.error(item.size <= this.MAX_FILE_SIZE
				? this.locale.getString('common.unsupportedFileFormat')
				: this.locale.getString('organization.exceedMaxSize'));
		};

		this.$scope.uploader.onAfterAddingFile = (fileItem) => {
			this.$scope.loadingItem = {
				progress: 0,
				name: fileItem.file.name
			};
			this.$scope.fileItem = fileItem;
			this.$scope.fileReady = true;
		};

		this.$scope.uploader.onProgressItem = (item, progress) => {
			if (this.$scope.loadingItem)
				this.$scope.loadingItem.progress = progress;
		};

		this.$scope.uploader.onSuccessItem = (item, response, status, headers) => {
			this.$scope.loadingItem = null;
			this.$scope.fileReady = true;

			this.$scope.result = response;
			if (!_.isEmpty(response)) {
				//only userNonExistence return as warning for now
				this.$scope.uploadResult = FileUploadResult.warning(this.locale.getString('organization.userSkipped'));
				this.generateUserNonExistenceTable(response[0].arguments);
			} else {
				this.$scope.uploadResult = FileUploadResult.success();

				this.$scope.uploadResult.customUploadSuccessMessage = locale.getString('organization.asyncGroupNotification');
			}
			this.$scope.defer.resolve();
		};

		this.$scope.downloadDataFile = () => {
			this.organizationApiService.getHierarchyUserData(this.$scope.hierarchyId).then((apiResponse) => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
		};

		this.$scope.downloadTemplateFile = () => {
			this.organizationApiService.getHierarchyTemplate(this.$scope.hierarchyId).then((apiResponse) => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
		};

		this.$scope.isEditable = () => {
			return !!this.$scope.hierarchy.editable;
		};

		this.$scope.removeOrganizationGroups = () => {
			this.$scope.removeGroupsPromise = this.organizationApiService.getHierarchyGroupsCount(this.hierarchy.id)
				.then((groupCountResponse) => {
					let groupCount = groupCountResponse.data;

					if (groupCount > 0) {
						return this.cxDialogService.regularWithConfirm(
							this.locale.getString('organization.removeHierarchyTitle', { hierarchyName: this.hierarchy.name }),
							this.locale.getString('organization.removeHierarchyGroupsCountPrompt', { hierarchyGroupsCount: groupCount }))
							.result.then(() => {
								return this.organizationApiService.removeOrganizationGroups(this.$scope.hierarchyId);
							});
					} else {
						return this.cxDialogService.notify(
							this.locale.getString('organization.removeHierarchyTitle', { hierarchyName: this.hierarchy.name }),
							this.locale.getString('organization.noHierarchyGroupsExist'))
							.result;
					}
				});
		};

		this.$scope.getUsersForNode = (node) => {
			return node.users.join(', ');
		};

		this.$scope.generateGroupChange = (result: boolean) => {
			$scope.generateGroupsSelected = true;
			let generateGroups = result;
			if ($scope.fileItem) {
				let generateGroupsOption = this.findOrPush($scope.fileItem.formData, (option) => {
					return option.generateGroups !== undefined;
				});
				generateGroupsOption.generateGroups = generateGroups;
			}
		};

		$scope.generateSkipNonExistUsersChange = () => {
			$scope.skipNonExistUsers = !$scope.skipNonExistUsers;
			if ($scope.fileItem) {
				let generateGroupsOption = this.findOrPush($scope.fileItem.formData, (option) => {
					return option.skipNonExistUsers !== undefined;
				});
				generateGroupsOption.skipNonExistUsers = $scope.skipNonExistUsers;
			}
		};
	}

	$onInit(): void {}

	private isDynamicFiltering = (): boolean => {
		return this.$scope.hierarchy?.hierarchyMode === HierarchyMode.DYNAMIC_FILTERING;
	};

	private generateUserNonExistenceTable = (userNonExistenceList) => {
		this.$scope.userNonExistence = true;
		this.$scope.userNonExistenceList = userNonExistenceList;
	};

	private findOrPush = (array, filter) => {
		let matchingOption = array.filter(filter)[0];
		if (matchingOption) {
			return matchingOption;
		}

		let newOption = {};
		array.push(newOption);
		return newOption;
	};
}

app.controller('OrganizationsUsersWizardCtrl', OrganizationUsersWizardController);
