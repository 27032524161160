import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';

export class PointClassProviders {
	static sentiment(colors: ConversationEnrichment, data: ConversationDataPoint): string {
		if (data.isSilence) {
			return 'silence';
		}

		const isMlSentimentEnabled = colors.isMlSentimentEnabled();
		if (isMlSentimentEnabled) {
			return PointClassProviders._mlSentiment(colors, data);
		} else {
			return PointClassProviders._legacySentiment(colors, data);
		}
	}

	static _legacySentiment(colors: ConversationEnrichment, data: ConversationDataPoint): string {
		const NEUTRAL_INDEX = colors.useFiveBandPalette()
			? 2 // middle for legacy sentiment5
			: 1; // middle for legacy sentiment3

		let sentimentIndex = colors.getColorIndex(data);
		if (sentimentIndex === NEUTRAL_INDEX) {
			return data.channel;
		}  else {
			return ConversationStyleUtils.SENTIMENT_PREFIX + sentimentIndex;
		}
	}

	static _mlSentiment(colors: ConversationEnrichment, data: ConversationDataPoint): string {
		const NO_ML_SENTIMENT_INDEX = 5;

		let sentimentIndex = colors.getColorIndex(data);
		const isNeutralIndex = sentimentIndex === NO_ML_SENTIMENT_INDEX;
		if (isNeutralIndex) {
			return data.channel;
		}  else {
			return ConversationStyleUtils.SENTIMENT_PREFIX + sentimentIndex;
		}
	}

	static emotion(colors: ConversationEnrichment, data: ConversationDataPoint): string {
		if (data.isSilence) return 'silence';

		const NO_EMOTION_INDEX = 5;
		let emotionIndex = colors.getColorIndex(data);

		if (emotionIndex === NO_EMOTION_INDEX)
			return data.channel;
		else return ConversationStyleUtils.EMOTION_PREFIX + emotionIndex;
	}

	static effort(colors: ConversationEnrichment, data: ConversationDataPoint): string {
		if (data.isSilence) return 'silence';

		const NEUTRAL_INDEX = colors.useFiveBandPalette()
			? 2 // middle for effort5
			: 1; // middle for effort3
		const NO_EFFORT_INDEX = 5;
		let effortIndex = colors.getColorIndex(data);

		if (effortIndex === NO_EFFORT_INDEX || effortIndex === NEUTRAL_INDEX)
			return data.channel;
		else return ConversationStyleUtils.EFFORT_PREFIX + effortIndex;
	}

	static none(data: ConversationDataPoint): string {
		if (data.isSilence) return 'silence';

		return data.channel;
	}
}
