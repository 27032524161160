import { DashboardReportType } from '@app/modules/dashboard/entity/dashboard-report-type.enum';

export const DEFAULT_CONFIDENTIALITY_THRESHOLD = 5;

export class ConfidentialitySettings {
	enabled: boolean = false;
	dataThreshold: number = DEFAULT_CONFIDENTIALITY_THRESHOLD;
	commentsThreshold: number = DEFAULT_CONFIDENTIALITY_THRESHOLD;
}

export class DashboardProperties {
	allowPersonalization: boolean;
	cbAccount: number;
	cbContentProvider: number;
	color: string;
	customColor: string;
	isAttributeFiltersApplied: boolean;
	optimization: boolean;
	pdfExportPageHeight: number;
	pdfPageBreakEnabled: boolean;
	preventDashboardEditorsShare: boolean;
	project: number;
	userPlacementColor: string;
	userPlacementEnabled: boolean;
	hierarchyId: number;
	drillableDashboards: Array<{id: number; name: string}>;
	showOrganizationContext: boolean;
	allowSharingThroughEmbedding: boolean;
	viewOnlyDrill: boolean = true;
	defaultShowTotal: boolean = true;
	confidentiality: ConfidentialitySettings = new ConfidentialitySettings();
	reportType: DashboardReportType;
	defaultLocale: string;
	modernLookAndFeelEnabled: boolean;
}
