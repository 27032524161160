import { HighchartsClosureUtils, HighchartsFunctionScope } from './highcharts-closure-utils.class';

export class HighchartsCaseLabelUtils {

	static readonly RIGHT_EDGE_PADDING = 16;
	static readonly LABEL_PADDING = 32;

	static preventLabelOverflow = HighchartsClosureUtils.closureWrapper((scope: HighchartsFunctionScope) => {
		let labels = HighchartsCaseLabelUtils.getLabelDimensions(scope);

		HighchartsCaseLabelUtils.preventOverflow(labels);
	});

	static getCaseLabelFormatter = (maxLabelWidth?: number) => {
		return HighchartsClosureUtils.closureWrapper((scope: HighchartsFunctionScope) => {
			let caseItem = scope.point.object;

			let linkMarkup = `<a href="${caseItem.permalink}"
				target="_blank"
				class="mr-8"><i class="q-icon q-icon-clipboard"></i></a>${caseItem.title}`;

			if (maxLabelWidth === undefined) {
				return linkMarkup;
			} else {
				return `
				<span
					class="d-block overflow-hidden"
					style="text-overflow: ellipsis; max-width: ${maxLabelWidth}px">
					${linkMarkup}
				</span>`;
			}
		});
	};

	private static preventOverflow(labels): any {
		for (let level of _.keys(labels)) {
			for (let i = 0; i < labels[level].length; i++) {
				let oneLabel = labels[level][i];
				if (i > 0) {
					let distanceToNextLabel = labels[level][i - 1].start -
						HighchartsCaseLabelUtils.LABEL_PADDING - oneLabel.start;

					// if distanceToNextLabel is less than zero, next label must be off the chart
					oneLabel.maxWidth = distanceToNextLabel <= 0 ?
						oneLabel.maxWidth :
						Math.min(oneLabel.maxWidth, distanceToNextLabel);
				}

				if (oneLabel.maxWidth < 0) {
					oneLabel.maxWidth = 0;
				}

				// prevent infinite looping by checking if the max width actually changed
				if (oneLabel.maxWidth !== oneLabel.currentMaxWidth) {
					oneLabel.data.update({
						dataLabels: {
							formatter: HighchartsCaseLabelUtils.getCaseLabelFormatter(oneLabel.maxWidth)
						}
					}, true);
				}
			}
		}
	}

	static getMaxWidth(label: any): number {
		let maxWidthStyle = /max-width: ([0-9]+)/.exec(label);
		if (!maxWidthStyle || maxWidthStyle.length < 2) {
			return 0;
		}
		return Number.parseFloat(maxWidthStyle[1]);
	}

	static getLabelDimensions(scope: HighchartsFunctionScope) {
		let labels = {};
		_.forEach(scope.series, (series: Highcharts.Series) => {
			if (series.options.type === 'xrange') {
				_.forEach(series.data, (data: any) => {
					let end = data.dataLabel.width + data.dataLabel.x;
					let maxWidth = data.series.chart.plotSizeX - data.dataLabel.x - HighchartsCaseLabelUtils.RIGHT_EDGE_PADDING;

					labels[data.options.y] = [
						{
							start: data.dataLabel.x,
							end,
							data,
							maxWidth,
							currentMaxWidth: HighchartsCaseLabelUtils.getMaxWidth(data.dataLabel.textStr)
						}].concat(labels[data.options.y] || []);
				});
			}
		});
		return labels;
	}
}
