import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { DeletionDocumentMetadata } from '@app/modules/interaction-explorer/interaction-actions.service';
import { DeleteReason } from '@app/modules/interaction-explorer/delete-reason.enum';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { DialogStyle } from '@app/modules/dialog/dialog-style';


export interface  DeleteDocumentsDialogInput {
	documentsMetadata: DeletionDocumentMetadata[];
}

@Component({
	selector: 'delete-documents-dialog',
	templateUrl: './delete-documents-dialog.component.html'
})
export class DeleteDocumentsDialogComponent implements OnInit {
	@Input() input: DeleteDocumentsDialogInput;

	dialogHeader: string;
	confirmationMessage: string;

	reason: DeleteReason;
	reasonOptions: UIOption<DeleteReason>[];

	readonly styles = DialogStyle;

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
	) {}

	ngOnInit(): void {
		this.initReasonOptions();

		if (this.input.documentsMetadata.length === 1) {
			const documentMetadata: DeletionDocumentMetadata = this.input.documentsMetadata[0];
			let documentId = documentMetadata.id;
			this.dialogHeader = this.locale.getString('interactionExplorer.deleteDocumentDialogHeader', { documentId });
			this.confirmationMessage = this.locale.getString('interactionExplorer.deleteDocumentDialogMessage', { documentId });
		} else {
			const count = this.input.documentsMetadata.length;
			this.dialogHeader = this.locale.getString('interactionExplorer.deleteDocumentsDialogHeader');
			this.confirmationMessage = this.locale.getString('interactionExplorer.deleteDocumentsDialogMessage', { count });
		}
	}

	private initReasonOptions() {
		this.reasonOptions = [
			{ value: DeleteReason.COMPLIANCE, displayName: this.locale.getString('interactionExplorer.reasonCompliance') },
			{ value: DeleteReason.PII_DATA, displayName: this.locale.getString('interactionExplorer.reasonPII') },
			{ value: DeleteReason.INACCURATE_DATA, displayName: this.locale.getString('interactionExplorer.reasonInaccurate') },
			{ value: DeleteReason.OLD_DATA, displayName: this.locale.getString('interactionExplorer.reasonOld') },
			{ value: DeleteReason.TEST_DATA, displayName: this.locale.getString('interactionExplorer.reasonTest') },
			{ value: DeleteReason.OTHER, displayName: this.locale.getString('interactionExplorer.reasonOther') },
		];
	}

	confirm(): void {
		this.modal.close(this.reason);
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
