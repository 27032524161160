import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';
import { BubbleLegendProcessor } from '@cxstudio/reports/utils/highchart/legend/bubble-legend-processor';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { XAxisLabelsOptions, XAxisOptions, YAxisLabelsOptions } from 'highcharts';

@Injectable({
	providedIn: 'root'
})
export class HighchartsScatterUtilsService {

	constructor(
		private readonly highchartsUtils: HighchartsUtilsService,
		@Inject('reportNumberFormatUtils') private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
	) {}

	getBaseOptions(reportDefinition: ReportDefinitionScope, element: JQueryStatic, range: {min: number; max: number}) {
		let maxSize = 20;
		let minSize = 1.0 * maxSize * range.min / range.max;

		let scaleX = this.highchartsUtils.getAxisScale('x', reportDefinition.options);
		let scaleY = this.highchartsUtils.getAxisScale('y', reportDefinition.options);
		return $.extend(this.highchartsUtils.getDefaultConfig(), {
			chart: {
				spacingTop: 30,
				spacingBottom: this.highchartsUtils.getSpacingBottom(reportDefinition.options),
				type: 'bubble',
				renderTo: element[0],
				pinchType: 'xy',
				zoomType: !reportDefinition.demo ? 'xy' : undefined,
				events: {
					selection: HighchartsClosureUtils.closureWrapper((scope, event) => {
						if (event.xAxis?.[0] && event.yAxis?.[0]) {
							reportDefinition.options.zoom = {
								xAxis: {
									min: event.xAxis[0].min,
									max: event.xAxis[0].max
								},
								yAxis: {
									min: event.yAxis[0].min,
									max: event.yAxis[0].max
								}
							};
							BubbleLegendProcessor.hideOuterLegends(reportDefinition,
								reportDefinition.options.zoom);
						} else {
							if (reportDefinition.options.zoom)
								reportDefinition.options.zoom = undefined;
							if (reportDefinition.options.legends) {
								BubbleLegendProcessor.resetHiddenLegends(reportDefinition,
									reportDefinition.options.legends.hideLegends);
							} else {
								BubbleLegendProcessor.resetHiddenLegends(reportDefinition, null);
							}
						}
					})
				}
			},
			tooltip: {
				enabled: true
			},
			legend: {
				enabled: isTrue(reportDefinition.options.showLegend),
				labelFormatter: HighchartsClosureUtils.closureWrapper(scope => {
					if (reportDefinition.utils.hasObjectColor) {
						return scope.options.category.name;
					}
					return scope.options.displayName || scope.name;
				}),
				itemStyle: {
					width: 300
				},
				layout: 'vertical',
				floating: false,
				align: 'right',
				verticalAlign: 'middle',
				itemMarginTop: 5,
				itemMarginBottom: 5,
				borderWidth: 0
			},
			plotOptions: {

				series: {
					dataLabels: {
						enabled: isTrue(reportDefinition.options.showLabels),
						formatter: HighchartsClosureUtils.closureWrapper(scope => {
							return this.reportNumberFormatUtils.formatMetric(reportDefinition.options.yAxis, scope.y);
						})
					},
					stickyTracking: false,
					events: {
						mouseOver: HighchartsClosureUtils.closureWrapper(scope => {
							reportDefinition.selectedPoint = {
								id: scope.options.id,
								fullPath: scope.options.fullPath,
								name: scope.options.name,
								color: scope.color,
								idPath: scope.options.idPath,
								attributeName: scope.options.attributeName
							};

						}),

						mouseOut: HighchartsClosureUtils.closureWrapper(() => {
							reportDefinition.selectedPoint = null;
						}),
						legendItemClick: HighchartsClosureUtils.closureWrapper(scope => {
							// legend click does nothing in demo mode
							if (reportDefinition.demo) {
								return false;
							}

							let visible = scope.visible;
							let id = reportDefinition.utils.hasObjectColor ? scope.options.category.id : scope.options.id;
							if (!reportDefinition.options.legends || reportDefinition.options
								.legends.hideLegends.length === 0)
								reportDefinition.options.legends = {
									hideLegends: [id]
								};
							else if (!visible && reportDefinition.options.legends.hideLegends
								.indexOf(id) !== -1)
								reportDefinition.options.legends.hideLegends.remove(id);
							else if (visible)
								reportDefinition.options.legends.hideLegends.push(id);
						}),
						afterAnimate: HighchartsClosureUtils.closureWrapper(scope => {
							this.highchartsUtils.handleRenderedEvent(scope, reportDefinition.utils);
						})
					}
				},
				bubble: {
					maxSize: maxSize + '%',
					minSize: minSize < 5 ? 8 : (Math.round(minSize) + '%')
				}
			},
			xAxis: {
				title: {
					text: reportDefinition.utils.titleFormatter(reportDefinition.options.xAxis)
				},
				startOnTick: false,
				endOnTick: false,
				showLastLabel: true,
				gridLineWidth: 1,
				labels: {} as XAxisLabelsOptions,
				min: scaleX.min,
				max: scaleX.max,
				maxPadding: 0.15,
				minPadding: 0.15
			} as XAxisOptions,
			yAxis: [{
				title: {
					text: reportDefinition.utils.titleFormatter(reportDefinition.options.yAxis)
				},
				allowDecimals: (reportDefinition.options as any).decimals, // not sure if this is used
				endOnTick: false,
				startOnTick: false,
				labels: {} as YAxisLabelsOptions,
				min: scaleY.min,
				max: scaleY.max,
				maxPadding: 1.0,
				minPadding: 0.5
			}]
		}) as Highcharts.Options;
	}
}

app.service('highchartsScatterUtils', downgradeInjectable(HighchartsScatterUtilsService));
