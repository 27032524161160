import {EventEmitter, Input, Output, Component, ChangeDetectionStrategy} from '@angular/core';
import { Model } from '@cxstudio/reports/entities/model';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';

@Component({
	selector: 'topic-checkboxes',
	templateUrl: './topic-checkboxes.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicCheckboxesComponent {
	@Input() filterText: string;
	@Input() usableModels:Model[];
	@Input() userPreferences: DocViewPreferences;
	@Input() projUniqueId: string;
	@Output() selectionChange = new EventEmitter<void>();

	constructor() { }

	toggleModel = (modelId: number) => {
		this.userPreferences.settings.modelSearch[this.projUniqueId][modelId]
			= this.userPreferences.settings.modelSearch[this.projUniqueId][modelId] === false;
		this.selectionChange.emit();
	};

	selectAllModels = (): void => {
		let models = this.usableModels;
		models.forEach((model: Model) =>  {
			this.userPreferences.settings.modelSearch[this.projUniqueId][model.id] = true;
		});
		this.selectionChange.emit();
	};

	deselectAllModels = (): void => {
		let models = this.usableModels;
		models.forEach((model: Model) =>  {
			this.userPreferences.settings.modelSearch[this.projUniqueId][model.id] = false;
		});
		this.selectionChange.emit();
	};

	showDeselectButton = (): boolean => {
		let models = this.userPreferences.settings.modelSearch[this.projUniqueId];
		for (const key in models) {
			if (models[key] !== false) {
				return true;
			}
		}
		return false;
	};
}
