import { Security } from '@cxstudio/auth/security-service';
import { AccountId, ContentProviderId, ProjectId } from '@cxstudio/generic-types';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { DataProvidersService } from './data-providers.service';


export interface IAutoselectProviders {
	init(): void;
	onSelectContentProvider(selectContentProviderFn: (cpId: ContentProviderId) => void): IAutoselectProviders;
	onLoadContentProviders(loadContentProviderFn: (contentProviders: any[]) => void): IAutoselectProviders;
	onSelectAccount(selectAccountFn: (cpId: ContentProviderId, acctId: AccountId) => void): IAutoselectProviders;
	onLoadAccounts(loadAccountFn: (accounts: any[]) => void): IAutoselectProviders;

	onSelectProject(
		selectProjectFn: (cpId: ContentProviderId, acctId: AccountId, projectId: ProjectId, projects: any[]) => void
	): IAutoselectProviders;

	onLoadProjects(loadProjectFn: (projects: any[]) => void): IAutoselectProviders;
	withSelectedContentProvider(cpId: ContentProviderId): IAutoselectProviders;
	withSelectedAccount(accId: AccountId): IAutoselectProviders;
	getPromises(): {
		contentProviders: ng.IPromise<void>;
		accounts: ng.IPromise<void>;
		projects: ng.IPromise<void>;
	};
	reloadContentProviders(): ng.IPromise<void>;
	reloadAccounts(contentProviderId: ContentProviderId): ng.IPromise<void>;
	reloadProjects(contentProviderId: ContentProviderId, accountId: AccountId, allProjects?: boolean): ng.IPromise<void>;
}

app.factory('AutoselectProviders', (
		dataProvidersRefreshService: DataProvidersService,
		$q,
		$log,
		security: Security
	) => {

	let scope;
	let selectProviderCallback;
	let selectAccountCallback;
	let selectProjectCallback;
	let loadProvidersCallback;
	let loadAccountsCallback;
	let loadProjectsCallback;

	let promises = {
		contentProviders: undefined,
		accounts: undefined,
		projects: undefined
	};

	return class {
		selectedContentProvider: ContentProviderId;
		selectedAccount: AccountId;
		favoriteProps;


		constructor(initScope) {
			scope = initScope;

			this.favoriteProps = security.getFavoriteProperties() || {};
			scope.favoriteProps = this.favoriteProps;
			scope.hasFavoriteProps = this.hasFavoriteContentProvider();

			if (scope.hasFavoriteProps) {
				scope.props = {};
				scope.props.contentProviderId = this.favoriteProps.favoriteCP;
				scope.props.accountId = this.favoriteProps.favoriteAccount;
				scope.favoriteProjectId = this.favoriteProps.favoriteProject;
				scope.loadingAccounts = true;
				scope.loadingProjects = true;
			} else {
				scope.props = {};
			}
		}

		reloadContentProviders = (): ng.IPromise<void> => {
			delete scope.props.contentProviderId;

			return scope.loading = promises.contentProviders = dataProvidersRefreshService.reloadContentProviders().then(() => {
				scope.contentProviders = dataProvidersRefreshService.contentProviders;

				if (loadProvidersCallback)
					loadProvidersCallback(scope.contentProviders);

				if (scope.contentProviders && scope.contentProviders.length === 1) {
					scope.props.contentProviderId = scope.contentProviders[0].id;
					this.onSelectContentProviderInternal();
				} else if (this.selectedContentProvider) {
					scope.props.contentProviderId = this.selectedContentProvider;
					this.onSelectContentProviderInternal();
				} else if (this.hasFavoriteContentProvider()
						&& _.findWhere(scope.contentProviders, { id: this.favoriteProps.favoriteCP })) {
					scope.props.contentProviderId = this.favoriteProps.favoriteCP;
					this.onSelectContentProviderInternal();
				}
			});
		};

		private onSelectContentProviderInternal(): void {
			this.reloadAccounts(scope.props.contentProviderId);

			if (selectProviderCallback)
				selectProviderCallback(scope.props.contentProviderId);
		}

		/**
		 * @returns Promise
		 */
		reloadAccounts = (contentProviderId: ContentProviderId): ng.IPromise<void> => {
			scope.showCreateErrors = false;
			scope.loadingAccounts = true;
			delete scope.props.accountId;
			delete scope.props.project;

			$log.debug('Reloading accounts');
			if (!_.isNumber(contentProviderId) || contentProviderId < 0)
				return $q.when();

			return scope.loading = promises.accounts = dataProvidersRefreshService.reloadAccounts(contentProviderId).then(() => {
				scope.accounts = dataProvidersRefreshService.accounts;

				if (loadAccountsCallback)
					loadAccountsCallback(scope.accounts);

				if (scope.accounts && scope.accounts.length === 1) {
					scope.props.accountId = scope.accounts[0].accountId;
					this.onSelectAccountInternal();
				} else if (!isEmpty(this.selectedAccount) && this.selectedContentProvider === contentProviderId) {
					scope.props.accountId = this.selectedAccount;
					this.onSelectAccountInternal();
				} else if (this.favoriteContentProviderSelected() && this.hasFavoriteAccount()
						&& _.findWhere(scope.accounts, { accountId: this.favoriteProps.favoriteAccount })) {
					scope.props.accountId = this.favoriteProps.favoriteAccount;
					this.onSelectAccountInternal();
				}

				scope.loadingAccounts = false;
			});
		};

		private onSelectAccountInternal(): void {
			this.reloadProjects(scope.props.contentProviderId, scope.props.accountId);

			if (selectAccountCallback)
				selectAccountCallback(scope.props.contentProviderId, scope.props.accountId);
		}

		/**
		 * @returns Promise
		 */
		reloadProjects = (contentProviderId: ContentProviderId, accountId: AccountId, allProjects = false): ng.IPromise<void> => {
			scope.showCreateErrors = false;
			scope.loadingProjects = true;
			delete scope.props.project;

			$log.debug('Reloading projects');
			if (!_.isNumber(contentProviderId) || contentProviderId < 0)
				return $q.when();
			if (!_.isNumber(accountId) || accountId < 0)
				return $q.when();

			return scope.loading = promises.projects = dataProvidersRefreshService.reloadProjects(contentProviderId, accountId).then(() => {
				scope.projects = dataProvidersRefreshService.projects;

				if (loadProjectsCallback)
					loadProjectsCallback(scope.projects);
				if (allProjects) {
					scope.loadingProjects = false;
					return;
				}

				if (scope.projects?.length === 1) {
					scope.props.project = scope.projects[0];
					this.onSelectProjectInternal();
				} else if (this.favoriteContentProviderSelected() && this.favoriteAccountSelected() && this.hasFavoriteProject()) {
					let existingFavoriteProject = _.find(scope.projects, { projectId: scope.favoriteProjectId });
					if (existingFavoriteProject) {
						scope.props.project = existingFavoriteProject;
						this.onSelectProjectInternal();
					}
				}

				scope.loadingProjects = false;
			});
		};

		private onSelectProjectInternal(): void {
			if (selectProjectCallback) {
				selectProjectCallback(
					scope.props.contentProviderId, scope.props.accountId,
					scope.props.project.projectId, scope.projects);
			}
		}

		init = () => {
			this.reloadContentProviders();
		};

		onSelectContentProvider = (selectContentProviderFn: (cpId: ContentProviderId) => void): IAutoselectProviders => {
			selectProviderCallback = selectContentProviderFn;
			return this;
		};

		onLoadContentProviders = (loadContentProviderFn: (contentProviders: any[]) => void): IAutoselectProviders => {
			loadProvidersCallback = loadContentProviderFn;
			return this;
		};

		onSelectAccount = (selectAccountFn: (cpId: ContentProviderId, acctId: AccountId) => void): IAutoselectProviders => {
			selectAccountCallback = selectAccountFn;
			return this;
		};

		onLoadAccounts = (loadAccountFn: (accounts: any[]) => void): IAutoselectProviders => {
			loadAccountsCallback = loadAccountFn;
			return this;
		};

		onSelectProject = (
			selectProjectFn: (cpId: ContentProviderId, acctId: AccountId, projectId: ProjectId, projects: any[]) => void
			): IAutoselectProviders => {
			selectProjectCallback = selectProjectFn;
			return this;
		};

		onLoadProjects = (loadProjectFn: (projects: any[]) => void): IAutoselectProviders => {
			loadProjectsCallback = loadProjectFn;
			return this;
		};

		withSelectedContentProvider = (cpId: ContentProviderId): IAutoselectProviders => {
			this.selectedContentProvider = cpId;
			return this;
		};

		withSelectedAccount = (accId: AccountId): IAutoselectProviders => {
			this.selectedAccount = accId;
			return this;
		};

		getPromises = () => promises;

		private favoriteContentProviderSelected(): boolean {
			return this.hasFavoriteContentProvider() && scope.props.contentProviderId === scope.favoriteProps.favoriteCP;
		}

		private favoriteAccountSelected(): boolean {
			return this.hasFavoriteAccount() && scope.props.accountId === scope.favoriteProps.favoriteAccount;
		}

		private hasFavoriteContentProvider(): boolean {
			return this.hasFavoriteField('favoriteCP');
		}

		private hasFavoriteAccount(): boolean {
			return this.hasFavoriteField('favoriteAccount');
		}

		private hasFavoriteProject(): boolean {
			return this.hasFavoriteField('favoriteProject');
		}

		private hasFavoriteField(field: string): boolean {
			return !isEmpty(scope.favoriteProps)
				&& ValueUtils.isSelected(scope.favoriteProps[field]);
		}
	};
});
