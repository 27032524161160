import { ChangeDetectionStrategy, Input, Component, OnInit } from '@angular/core';
import { CalendarSettingsEntry, CalendarType, OverlappingYears, WeekIdentifier } from '../../calendar-settings.entity';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'calendar-format-modal',
	templateUrl: './calendar-format-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarFormatModalComponent implements OnInit {
	@Input() input: CalendarSettingsEntry;

	model: {
		type: CalendarType;
		weekIdentifier: WeekIdentifier;
		overlappingYears: OverlappingYears;
	};

	readonly calendarTypeOptions = [
		{value: CalendarType.USA, displayName: this.locale.getString('calendar.usa')},
		{value: CalendarType.EUROPEAN, displayName: this.locale.getString('calendar.european')}
	];

	readonly weekIdentiferOptions = [
		{value: WeekIdentifier.WEEK_OF, displayName: this.locale.getString('calendar.weekOf')},
		{value: WeekIdentifier.WEEK_DESCRIPTION, displayName: this.locale.getString('calendar.weekDescription')}
	];

	readonly overlappingYearsOptions = [
		{value: OverlappingYears.FOLLOWING_YEAR, displayName: this.locale.getString('calendar.followingYear')},
		{value: OverlappingYears.CURRENT_YEAR, displayName: this.locale.getString('calendar.currentYear')}
	];

	constructor(
		private locale: CxLocaleService,
		private readonly modal: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.model = {
			type: this.input.type,
			weekIdentifier: this.input.weekIdentifier,
			overlappingYears: this.input.overlappingYears
		};
	}

	getProjectNameFormat = () => {
		return this.input.projectName
			+ ' - ' + this.locale.getString('calendar.calendarFormat');
	};

	selectCalendarType = (calendarType) => {
		this.model.type = calendarType.value;
	};

	save(): void {
		let combinedCalendarFormat = {...this.input, ...this.model};
		this.modal.close(combinedCalendarFormat);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	resetToDefault = () => {
		this.model = {
			type: CalendarType.USA,
			weekIdentifier: WeekIdentifier.WEEK_OF,
			overlappingYears: OverlappingYears.FOLLOWING_YEAR,
		};
	};
}
