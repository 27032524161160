import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, Output, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DocumentLinkCopyService } from '@app/modules/document-explorer/document-link-copy.service';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { DocumentCaseService } from '@app/modules/document-explorer/document-cases/document-case.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewVerbatim } from '@cxstudio/reports/document-explorer/preview-verbatim';
import { DocExplorerQidsService } from '@cxstudio/reports/document-explorer/doc-explorer-qids.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { Security } from '@cxstudio/auth/security-service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { DocumentTypeUtils } from '../document-type-utils.class';
import { AmplitudeDocumentSource } from '../amplitude-document-source.enum';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';

@Component({
	selector: 'document-actions',
	templateUrl: './document-actions.component.html',
	 // can't use onPush until we implement subscription mechanism to documentManager changes
	changeDetection: ChangeDetectionStrategy.Default,
})
export class DocumentActionsComponent {

	@Input() document: PreviewDocument;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() auditMode: boolean;
	@Input() translateActive: boolean;
	@Input() verbatimMode: boolean;
	@Input() documentExplorer: boolean;
	@Input() showExportData: boolean;
	@Input() showStats: boolean;
	@Input() conversation: boolean;

	@Output() translate = new EventEmitter<void>();
	@Output() share = new EventEmitter<void>();
	@Output() exportData = new EventEmitter<void>();
	@ViewChild(NgbDropdown, {static: false}) dropdown: NgbDropdown;
	@ViewChild('menuButton', {static: false}) menuButton: ElementRef;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly documentCaseService: DocumentCaseService,
		private readonly documentLinkCopyService: DocumentLinkCopyService,
		@Inject('security') private security: Security,
		@Inject('docExplorerQids') private readonly docExplorerQids: DocExplorerQidsService,
	) {}

	isCurationMode(): boolean {
		return this.documentManager.isShowingCuratingControls() && !this.documentManager.isFeedbackSharingAvailable();
	}

	isFeedbackSelectionEnabled(): boolean {
		return this.documentManager.isFeedbackSelectionEnabled?.();
	}

	isShowingCuratingControls(): boolean {
		return this.documentManager.isShowingCuratingControls?.();
	}

	isFeedbackSharingAvailable(): boolean {
		return this.documentManager.isFeedbackSharingAvailable?.();
	}

	hasAnyCuratedItems(): boolean {
		return this.documentManager.hasAnyCuratedItems();
	}

	isCurrentItemCurated(): boolean {
		let currentItem = this.getCurrentItem();
		return !!(currentItem && this.documentManager.isItemCurated(currentItem));
	}

	private getCurrentItem(): PreviewDocument | PreviewVerbatim {
		return this.verbatimMode
			? this.documentManager.getSelectedVerbatim()
			: this.document;
	}

	processArrowDropdown(event: KeyboardEvent): void {
		let focusedItem = $(event.target);
		if (focusedItem.length) {
			KeyboardUtils.intercept(event);
			if (KeyboardUtils.isEventKey(event, Key.UP)) {
				let focusedList = $('.share-menu').find(':focusable');
				let focusedMenuItemIndex = focusedList.index($(document.activeElement as HTMLElement));
				if(focusedMenuItemIndex > 0)
				{
					(focusedList.get(focusedMenuItemIndex - 1) as HTMLElement).focus();
				}
			}
			else if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
				let focusedList = $('.share-menu').find(':focusable');
				let focusedMenuItemIndex = focusedList.index($(document.activeElement as HTMLElement));
				if(focusedMenuItemIndex < focusedList.length - 1)
				{
					(focusedList.get(focusedMenuItemIndex + 1) as HTMLElement).focus();
				}
			}
		}
	}

	close(event: KeyboardEvent): void {
		this.dropdown.close();
		setTimeout(() => this.moveFocus(event));
	}

	moveFocus(event: KeyboardEvent): void {
		let focusedItem = $(event.target);
		KeyboardUtils.intercept(event);
		let focusedList = focusedItem.closest('.an-document-explorer').find(':focusable');
		let focusedMenuItemIndex = focusedList.index($(this.menuButton.nativeElement));
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			(focusedList.get(focusedMenuItemIndex + 1) as HTMLElement).focus();
		}
		else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			(focusedList.get(focusedMenuItemIndex - 1) as HTMLElement).focus();
		}
		else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)){
			(focusedList.get(focusedMenuItemIndex) as HTMLElement).focus();
		}
	}

	curateCurrentItem(): void {
		this.documentManager.curateItem(this.getCurrentItem());
	}

	getCurateAriaLabel(): string {
		return this.getDocumentActionAriaLabel('preview.clipInteraction', 'preview.addInteractionToClipped');
	}

	private getDocumentActionAriaLabel(singleKey: string, multipleTemplate: string): string {
		let count = this.getRelevantCuratedItemsCount();
		return count === 0
			? this.locale.getString(singleKey)
			: this.locale.getString(multipleTemplate, { count });
	}

	getRelevantCuratedItemsCount(): number {
		return this.documentManager.getRelevantCuratedItemsCount();
	}

	getRelevantCuratedItemsCountLabel(): string {
		return this.getRelevantCuratedItemsCount() > 0
			? this.documentManager.getRelevantCuratedItemsCountLabel()
			: this.locale.getString('preview.clipFeedback');
	}

	canCopyLink(): boolean {
		return this.documentManager.canCopyLink();
	}

	copyDocumentsLink(event): Promise<void> {
		let documents = this.documentManager.getAllCuratedDocuments();
		if (!this.isCurrentItemCurated()) {
			documents.push(this.document);
		}
		let documentIds = _.map(documents, (document: any) => document.id);
		return this.documentLinkCopyService.copyDocumentsLink(event, documentIds,
			this.documentManager.getProjectIdentifier(),
			this.documentManager.getWorkspaceProject(),
			this.documentManager.widget.properties.runAs,
			this.documentManager.widget.properties.encodedDescriptor).then(() => {

				const { documentType, source} = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.documentExplorer);

				AmplitudeAnalyticsService.trackEvent(
					AmplitudeEvent.DOCEXPLORER_COPY_LINK,
					{ documentId: documentIds },
					{ documentType, source }
				);
			});
	}

	onMultiDocumentCopyLinkKeyboard(event: KeyboardEvent) {
		KeyboardUtils.intercept(event);
		if (!this.auditMode) {
			this.copyDocumentsLink(event).then(() => KeyboardUtils.focus($(event.currentTarget as HTMLElement)));
		}
	}

	downloadAudio(): void {
		if (this.documentManager?.conversationMethods?.downloadAudio) {
			this.documentManager.conversationMethods.downloadAudio(this.document);
			this.trackExportEvent('Audio');
		}
	}

	downloadTranscript(): void {
		if (this.documentManager?.conversationMethods?.downloadTranscript) {
			this.documentManager.conversationMethods.downloadTranscript(this.document);
			this.trackExportEvent('Transcript');
		}
	}

	private trackExportEvent(type: string): void {
		const {documentId, documentType, source} = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.documentExplorer);

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DOCEXPLORER_EXPORT,
			{ documentId },
			{ documentType, source, type }
		);
	}

	isCaseStatusLoading(): boolean {
		return this.documentManager.caseExistStatusLoading !== false;
	}

	showCreateCaseIcon(): boolean {
		return this.documentManager.allowCaseCreation() && !this.isCaseStatusLoading();
	}

	isCreateCaseDisabled(): boolean {
		return this.verbatimMode || this.isOverCreateCaseDocumentsLimit();
	}

	private isOverCreateCaseDocumentsLimit(): boolean {
		return this.documentManager.getAllCuratedDocumentsCount() > (5 - (!this.isCurrentItemCurated() ? 1 : 0));
	}

	getCreateCaseTooltip(): string {
		let key = 'docExplorer.create';
		key += !this.security.isEngagorIntegrationEnabled()
			? 'Ticket'
			: 'Case';

		if (this.verbatimMode) {
			return this.locale.getString(key + 'DisabledReasonVerbatim');
		} else if (this.isOverCreateCaseDocumentsLimit()) {
			return this.locale.getString(key + 'DisabledReasonOverLimit');
		}

		return this.locale.getString(key);
	}

	createCase(): void {
		if (this.isCreateCaseDisabled()) {
			return;
		}

		let documents = this.documentManager.getAllCuratedDocuments();
		if (!this.isCurrentItemCurated()) {
			documents.push(this.document);
		}

		this.documentCaseService.createCaseFromExplorer(documents, this.documentManager)
			.then(() => {

				const {documentType, source} = AmplitudeEventUtils.getBaseDocumentViewEvent(this.document, this.documentExplorer);

				AmplitudeAnalyticsService.trackEvent(
					AmplitudeEvent.DOCEXPLORER_CASE_CREATE,
					{ documentId: documents.map(d => d.id) },
					{ documentType, source}
				);
			});
	}

	getShareLinkAriaLabel(): string {
		return this.getDocumentActionAriaLabel('preview.shareLink', 'preview.shareLinkHasClipped');
	}

	getCreateCaseAriaLabel(): string {
		return this.getDocumentActionAriaLabel('preview.createCase', 'preview.createCaseHasClipped');
	}

	openStatsDialog() {
		this.docExplorerQids.openStatsDialog();
	}

	/**
	 * Returns true if share menu is valid (has options) and should be displayed
	 */
	showShareMenu(): boolean {
		return !this.documentManager.isDemo() &&
			(this.documentExplorer ||
				(this.conversation && this.documentManager?.conversationMethods?.allowAudioDownload()) ||
				(this.conversation && this.documentManager?.conversationMethods?.allowTranscriptDownload())
			);
	}
}

app.directive('documentActions', downgradeComponent({component: DocumentActionsComponent}));
