import * as _ from 'underscore';
import { MentionConfig } from 'angular-mentions';
import { downgradeComponent } from '@angular/upgrade/static';
import { Component, OnInit, Input, OnChanges, SimpleChanges, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import EngagorCreateCaseRequestDocument from '@cxstudio/engagor/engagor-create-case-request-document';
import IAlertSubscriptionTemplate from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { InboxTemplateTitleUtils } from '@app/modules/inbox-template/inbox-template-title-utils.class';
import { SubscriptionTemplateTitleVariable } from '@cxstudio/alert-subscription-templates/types/subscription-template-title-variable';
import { CasePriorityTypesService, ICasePriorityType } from '@app/modules/alert-subscription-template/services/case-priority-types.service';

@Component({
	selector: 'manual-create-case-table',
	templateUrl: './manual-create-case-table.component.html'
})
export class ManualCreateCaseTableComponent implements OnInit, OnChanges {

	readonly MAX_LENGTH: number = 500;
	readonly CASE_TITLE_LIMIT: number = 150;
	@Input() documents: EngagorCreateCaseRequestDocument[];
	@Input() template: IAlertSubscriptionTemplate;
	@Input() topics: EngagorTopic[];
	@Input() engagorAssignees: EngagorAssignees;
	@Input() isTitleLocked: boolean;
	@Input() isPriorityLocked: boolean;
	@Input() isAssigneeLocked: boolean;
	@Input() withHierarchyAssignee: boolean;
	@Input() xflowTemplateSelected: boolean;
	topic: Pick<EngagorTopic, 'name'>;
	nonEditablePriority: ICasePriorityType;
	columns: string[];
	priorityOptions: ICasePriorityType[] = this.casePriorityTypes.getPriorityTypes();

	caseTitleVariables: SubscriptionTemplateTitleVariable[];
	titleVariableMentioConfig: MentionConfig;

	constructor(
		private casePriorityTypes: CasePriorityTypesService,
		private locale: CxLocaleService) {
	}

	ngOnInit(): void {
		this.updateSettings();

		this.titleVariableMentioConfig = {
			triggerChar: '{',
			mentionSelect: (variable) => InboxTemplateTitleUtils.getVariableDisplayText(variable)
		};
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.template?.previousValue !== changes.template?.currentValue) {
			this.updateSettings();
		}
	}

	private updateSettings(): void {
		this.updateColumns();

		this.topic = _.findWhere(this.topics, {id: this.template.topicId});
		if (!this.topic) {
			let key = this.template.topicId ? 'common.deleted' : 'common.default';
			this.topic = { name: this.locale.getString(key)};
		}

		if (this.isPriorityLocked) {
			this.nonEditablePriority = _.findWhere(this.priorityOptions, {value: this.template.caseSettings.priority});
		}

		this.caseTitleVariables = InboxTemplateTitleUtils.getAttributeTitleVariables(this.template);
	}

	setAssignee = (assignee: Assignee, document: any): void => {
		document.caseAssignee = assignee;
	};

	clearAssignee = (document: any): void => {
		document.caseAssignee = null;
	};

	private updateColumns(): void {
		if (this.xflowTemplateSelected) {
			this.columns = [
				this.locale.getString('preview.naturalId'),
				this.locale.getString('alertTemplates.notes')
			];
		} else {
			this.columns = [
				this.locale.getString('preview.naturalId'),
				this.locale.getString('alertTemplates.topic'),
				this.locale.getString('alertTemplates.caseTitle'),
				this.locale.getString('cases.assignee'),
				this.locale.getString('alertTemplates.priority'),
				this.locale.getString('alertTemplates.notes')
			];
		}
	}
}

app.directive('manualCreateCaseTable', downgradeComponent({component: ManualCreateCaseTableComponent}));
