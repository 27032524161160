import * as _ from 'underscore';
import { ValidatorFn, ValidationErrors, UntypedFormGroup } from '@angular/forms';
import { PRE_DEFINED_PROPERTY_NAMES } from '../oauth-client-details-additional-information.component';


export function forbiddenNameValidator(): ValidatorFn {
	return (control: UntypedFormGroup): ValidationErrors | null => {

		let violatingPropertyNames: string[] = [];
		Object.keys(control.controls).forEach((controlName: string) => {
			if (controlName.startsWith('propertyName_')) {
				let controlValue: string = control.controls[controlName].value;
				if (_.contains(PRE_DEFINED_PROPERTY_NAMES, controlValue)) {
					violatingPropertyNames.push(controlValue);
				}
			}
		});

		return violatingPropertyNames.length > 0 ? { forbiddenName: { value: violatingPropertyNames.join(', ') } } : null;
	};
}