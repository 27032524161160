import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { CaseVisualization } from '@app/modules/widget-settings/case-viz-settings/case-visualization';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import EngagorCreateCaseRequest from '@cxstudio/engagor/engagor-create-case-request';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { User } from '@cxstudio/user-administration/users/entities/user';
import * as moment from 'moment';
import { DateRange } from '@app/modules/filter/entities/date-range';
import { Security } from '@cxstudio/auth/security-service';


interface EngagorAssignee {
	id: number;
	name: string;
}

export interface EngagorAssignees {
	users: User[];
	groups: EngagorAssignee[];
	hierarchies: EngagorAssignee[];
}

export class EngagorApiService {
	constructor(
		private $http: ng.IHttpService,
		private cachedHttpService: CachedHttpService,
		private httpHandlers: HttpHandlers,
		private security: Security,
		private $q: ng.IQService) {
	}

	getTopics = (cache = CacheOptions.CACHED): ng.IPromise<EngagorTopic[]> => {
		if (!this.security.isEngagorIntegrationEnabled()) {
			return this.$q.when([]);
		}
		return this.cachedHttpService.cache(Caches.ENGAGOR).get('rest/engagor/topics', {
			params: {}, local: true, cache
		} as ng.IRequestShortcutConfig).then(this.httpHandlers.success, () => []);
	};

	getAssignees = (cache = CacheOptions.CACHED): ng.IPromise<EngagorAssignees> => {
		if (!this.security.isEngagorIntegrationEnabled()) {
			return this.$q.when({users: [], groups: [], hierarchies: []});
		}
		return this.cachedHttpService.cache(Caches.ENGAGOR).get(`rest/engagor/assignees`, { cache }).then(this.httpHandlers.success);
	};

	getCases = (project: AccountOrWorkspaceProject, documentId: number): ng.IPromise<EngagorCase[]> => {
		const projectPath = WorkspaceTransitionUtils.isWorkspaceProject(project)
			? `ws/${project.workspaceId}/projectId/${project.projectId}`
			: `contentProviderId/${project.contentProviderId}/projectId/${project.projectId}`;
		const url = `rest/engagor/cases/${projectPath}/documentId/${documentId}`;
		return this.$http.get(url, {params: {}, local: true} as ng.IRequestShortcutConfig)
			.then(this.httpHandlers.success);
	};

	getCasesByQuery = (
		topics: EngagorTopic[], dateRange: DateRange,
		query?: string, cache = CacheOptions.CACHED
	): ng.IPromise<EngagorCase[]> => {
		let params = {
			query
		} as any;
		if (!_.isEmpty(topics)) {
			params.topicId = _.map(topics, topic => topic.id);
		}
		params.dateFrom = moment(dateRange.startDate).add(-1, 'year').toDate().getTime();
		params.dateTo = moment(dateRange.endDate).toDate().getTime();
		return this.cachedHttpService.cache(Caches.ENGAGOR).get('rest/engagor/cases/query',
			{ params, local: true, cache } as ng.IRequestShortcutConfig)
			.then(this.httpHandlers.success);
	};

	getCasesById = (cases: CaseVisualization[], cache = CacheOptions.CACHED): ng.IPromise<EngagorCase[]> => {
		let params = {
			id: _.map(cases, caseOption => caseOption.sourceId),
			topicId: _.map(cases, caseOption => caseOption.topicId),
			dateFrom: (_.min(cases, caseOption => caseOption.date.from) as CaseVisualization).date.from
		};

		return this.cachedHttpService.cache(Caches.ENGAGOR).get('rest/engagor/cases/id',
			{ params, local: true, cache } as ng.IRequestShortcutConfig)
			.then(this.httpHandlers.success);
	};

	createCase = (createCaseRequest: EngagorCreateCaseRequest):
		ng.IPromise<any> => this.$http.post('rest/engagor/case', createCaseRequest);

	createXflowMessage = (createCaseRequest: EngagorCreateCaseRequest):
		ng.IPromise<any> => this.$http.post('rest/engagor/xflow-ticket', createCaseRequest);
}

app.service('engagorApiService', EngagorApiService);
