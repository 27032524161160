import * as _ from 'underscore';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { ErrorType } from './error-type.enum';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';


interface IErrorPageParams {
	//errorCode: number,
	errorType: ErrorType;
	errorParams: string[];
}

export class RedirectErrorPageController implements ng.IController {

	loading: ng.IPromise<any>;

	constructor(
		private $routeParams: IErrorPageParams,
		private $location: ng.ILocationService,
		private cbDialogService: CBDialogService,
		private locale: ILocale,
	) {
	}

	$onInit(): void {
		if (this.$routeParams.errorType === ErrorType.REDIRECT) {
			this.handleError(this.$routeParams.errorParams);
		} else {
			this.redirectToHome();
		}
	}

	private handleError = (errorParams: string[]) => {
		let appName  = !_.isEmpty(errorParams) ? errorParams : this.locale.getString('common.unknown');

		this.cbDialogService.notify(this.locale.getString('common.error'),
					this.locale.getString('error.redirectError', {app: appName})
				).result.then(this.redirectToHome);
	};

	private redirectToHome = () => {
		this.$location.url('/dashboards');
	};
}

app.component('redirectErrorPage', {
	controller: RedirectErrorPageController,
	template: `
		<div class="w-100-percent h-100-percent"></div>
	`
});
