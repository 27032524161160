/* eslint-disable max-len */
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { DocExplorerPreferences } from '@app/modules/document-explorer/preferences/doc-explorer-preferences.provider';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { DocumentExplorerMenuComponent } from '../document-explorer-menu/document-explorer-menu.component';

@Component({
	selector: 'document-explorer-menu-button',
	template: `
<div #ngDropdown
	ngbDropdown
	class="explorer-header-menu"
	(openChange)="headerMenuOpen = $event"
	(keydown.tab)="closeDropdown($event);"
	(keydown.shift.tab)="closeDropdown($event);"
	(keydown.enter)="focusHeaderMenu()"
	(keydown.arrowup)="processArrowDropdown($event)"
	(keydown.arrowdown)="processArrowDropdown($event)"
	placement="bottom-right"
	container="body"
	autoClose="outside"
	[class.audit-mode]="auditMode">
	<button #menuButton
		class="q-icon q-icon-dots icon-rotate-90 font-size-4 mr-8 no-border no-background btn btn-icon p-8 hover:bg-action-100 hover:text-action-800"
		role="button"
		[attr.aria-label]="'preview.hamburgerMenuAriaLabel' | i18n:{widgetTitle:documentExplorerName}"
		ngbDropdownToggle>
	</button>
	<document-explorer-menu
		class="_t-doc-explorer-menu"
		ngbDropdownMenu
		role="menu"
		[preferences]="preferences"
		[trigger]="headerMenuOpen"
		[documentManager]="documentManager"
		(keydown.tab)="closeDropdown($event);"
		(keydown.shift.tab)="closeDropdown($event);"
		(keydown.escape)="closeDropdown($event);"
		(keydown.arrowup)="processArrowDropdown($event)"
		(keydown.arrowdown)="processArrowDropdown($event)"
		[sharingFeedbackSelection]="sharingFeedbackSelection"
		[panels]="panels">
	</document-explorer-menu>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerMenuButtonComponent {

	@Input() documentExplorerName: string;
	@Input() auditMode: boolean;
	@Input() preferences: DocExplorerPreferences;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() sharingFeedbackSelection: IAnalyticFeedbackSelection;
	@Input() panels: {[panelName: string]: ExplorerSectionGroup};
	@Input() exportDataEnabled: boolean;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;
	@ViewChild(DocumentExplorerMenuComponent, {static: false}) dropdownMenu: DocumentExplorerMenuComponent;
	@ViewChild('menuButton', {static: true}) menuButton: ElementRef;

	headerMenuOpen: boolean;

	closeDropdown(event: KeyboardEvent): void {
		this.ngDropdown.close();
		setTimeout(() => this.moveFocus(event));
	}

	moveFocus(event: KeyboardEvent): void {
		let focusedItem = $(event.target);
		KeyboardUtils.intercept(event);
		let focusedList = focusedItem.closest('.an-document-explorer').find(':focusable');
		let focusedMenuItemIndex = focusedList.index($(this.menuButton.nativeElement));
		if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			(focusedList.get(focusedMenuItemIndex + 1) as HTMLElement).focus();
		}
		else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			(focusedList.get(focusedMenuItemIndex - 1) as HTMLElement).focus();
		}
		else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)){
			(focusedList.get(focusedMenuItemIndex) as HTMLElement).focus();
		}
	}

	focusHeaderMenu(): void {
		if (!this.headerMenuOpen) {
			setTimeout(() => {
				$('.explorer-header-menu ul :focusable').first().trigger('focus');
			});
		}
	}

	processArrowDropdown(event: KeyboardEvent): void {
		this.dropdownMenu.explorerArrowNav(event);
	}
}
