import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IShareEntity, SharingService, UIShareGroup } from '@cxstudio/sharing/sharing-service.service';

export class SimpleShareTableController implements ng.IController {
	readonly MAX_DISPLAYED_USERS = CONFIG.sharing ? parseInt(CONFIG.sharing['largeGroupSize'], 10) : 20;

	items: IShareEntity[];
	searchText: string;
	onRemove: (params: {$item: IShareEntity}) => void;

	constructor(
		private locale: ILocale,
		private sharingService: SharingService
	) {}

	$onInit(): void {

	}

	getTableClasses = (item: IShareEntity) => {
		return [`share-${item.action}`];
	};

	isLargeGroup = (group: IShareEntity) => {
		return group.entity.usersCount > this.MAX_DISPLAYED_USERS;
	};

	toggleGroup = (group: UIShareGroup): void => {
		this.sharingService.toggleGroup(group);
	};

	getItemPostfix = (item: IShareEntity) => {
		if (item.type === 'group' && this.isLargeGroup(item)) {
			return `(${this.locale.getString('dashboard.nUsers', { count: item.entity.usersCount })})`;
		} else {
			return '';
		}
	};

	getIcon = (item: IShareEntity): string => {
		return {
			user: 'q-icon-user',
			group: 'q-icon-users',
			publicStatus: 'q-icon-globe',
			organizationGroup: 'q-icon-flow',
			cbUsers: 'q-icon-qualtrics'
		}[item.iconType];
	};
}

app.component('simpleShareTable', {
	controller: SimpleShareTableController,
	templateUrl: 'partials/dashboards/simple-share-table.html',
	bindings: {
		items: '<',
		searchText: '<',
		onRemove: '&'
	}
});
