<div #ngDropdown ngbDropdown
	[autoClose]="false"
	class="fake-select">
	<button ngbDropdownToggle
		role="button"
		class="dropdown-toggle btn-plot-line-dropdown pr-32"
		[disabled]="isDisabled">
		<plot-line-preview
			[plotLine]="plotLine"
			previewWidth="100"
		></plot-line-preview>
		<span class="caret"></span>
	</button>
	<div ngbDropdownMenu
		role="menu"
		class="reference-line-builder-dropdown"
		[ngClass]="{ 'pull-right': dropdownDirection === 'left', 'no-line-selected': isNoLine() }"
		(click)="$event.stopPropagation()">

		<div>
			<div class="plot-line-column" [class.separator-right]="!isNoLine()">
				<ul class="nav nav-pills nav-stacked">
					<li *ngFor="let style of lineStyles"
						role="presentation"
						class="mt-0"
						[class.selected]="plotLine.type === style.type"
						(click)="changeLineType(style.type)">
						<a>
							<plot-line-preview
								[plotLine]="style"
								previewWidth="175">
							</plot-line-preview>
						</a>
					</li>
				</ul>
			</div>
			<div class="plot-line-column separator-right" *ngIf="!isNoLine()">
				<ul class="nav nav-pills nav-stacked">
					<li *ngFor="let widthStyle of lineWidthStyles"
						role="presentation"
						class="mt-0"
						[class.selected]="plotLine.width === widthStyle.width"
						(click)="changeLineWidth(widthStyle.width)">
						<a>
							<plot-line-preview
								[plotLine]="widthStyle"
								previewWidth="150"
							></plot-line-preview>
						</a>
					</li>
				</ul>
			</div>
			<div #colorDropdownDiv class="plot-line-column" *ngIf="!isNoLine()">
				<color-dropdown
					class="br-color-picker col-sm-10 pt-4 ph-8"
					(dropdownClick)="$event.stopPropagation()"
					[(ngModel)]="plotLine.color">
					<div class="d-flex align-center">
						{{plotLine.color}}
						<div class="ml-8 w-16 h-16" [style.background-color]="plotLine.color"></div>
					</div>
				</color-dropdown>
			</div>
			<a type="button"
				class="close text-gray-900"
				(click)="closeDropdown()">&times;</a>
		</div>
	</div>
</div>
