import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ISettingsEditor } from '@app/modules/asset-management/entities/settings-editor.interface';
import { InclusionListSettingsGroup } from '@app/modules/asset-management/entities/settings-groups/inclusion-list-settings-group';
import { SettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.enum';
import { ISettingsGroup } from '@app/modules/asset-management/entities/settings-groups/settings-group.interface';
import { ShowQuantitySettingsGroup } from '@app/modules/asset-management/entities/settings-groups/show-quantity-settings-group';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import IndeterminateTopicSelectionConfig from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection-config.interface';
import { IndeterminateTopicSelectionImpl } from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.class';
import IndeterminateTopicSelection from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.interface';
import { CapitalizationService } from '@cxstudio/services/capitalization.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';




/**
 * All new properties (in settings) should be added to AnalyticDefinitionMultiSelectionController::extendAdditionalProperties
 */
interface SelectOption {
	name: string | number;
	value: string | boolean | number;
}

@Component({
	selector: 'configuration-settings',
	templateUrl: './configuration-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationSettingsComponent implements OnInit {

	public SettingsGroup = SettingsGroup;

	@Input() editor: ISettingsEditor<any>;

	readonly TABLE_ROW_HEIGHT_DEFAULT = 50;

	groups: {[key: string]: ISettingsGroup<any>};

	options: {
		sortOrder?: SelectOption[];
		sortBy?;
		nullInclude?: SelectOption[];
		capitalization?: SelectOption[];
		urlTypes?: SelectOption[];
		levels?: SelectOption[];
	};

	tempSort;
	modelTree;
	selectedLevel;
	checkedNodes;

	indeterminateTopicSelection: IndeterminateTopicSelection;

	initializers;

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('capitalization') private readonly capitalization: CapitalizationService,
		@Inject('hierarchyService') private readonly hierarchyService: HierarchyService,
	) {}

	ngOnInit(): void {
		this.groups = this.editor.getGroups();

		this.initializers = {};
		this.initializers[SettingsGroup.SHOW_QUANTITY] = this.initShowQuantity;
		this.initializers[SettingsGroup.NULL_INCLUDE] = this.initNullInclude;
		this.initializers[SettingsGroup.INCLUSION_LIST] = this.initInclusionList;
		this.initializers[SettingsGroup.CAPITALIZATION] = this.initCapitalization;
		this.initializers[SettingsGroup.URL] = this.initURL;

		this.options = {};

		for (const group of Object.keys(this.groups)) {
			let initializer = this.initializers[group];
			if (initializer) {
				initializer();
			}
		}
	}

	private createIndeterminateTopicSelection = (): IndeterminateTopicSelection => {
		let config: IndeterminateTopicSelectionConfig = {
			getSelectedNodes: () => {
				let group = this.groups[SettingsGroup.INCLUSION_LIST] as InclusionListSettingsGroup;
				return group.settings.selectedNodes;
			},
			setSelectedNodes: selectedNodes => {
				let group = this.groups[SettingsGroup.INCLUSION_LIST] as InclusionListSettingsGroup;
				group.settings.selectedNodes = selectedNodes.filter((node) => node);
			},
			setCurrentSelection: selection => this.checkedNodes = selection,
			getCurrentSelection: () => this.checkedNodes,
			getModelTree: () => this.modelTree,
			getSelectedLevels: () => [this.selectedLevel],
			isTopicLeafEnabled: () => {
				let group = this.groups[SettingsGroup.INCLUSION_LIST] as InclusionListSettingsGroup;
				return group.settings.selectedLevel === TopicReportGrouping.LEAF_LEVEL;
			}
		};

		return new IndeterminateTopicSelectionImpl(config);
	};

	hasGroup = (group: SettingsGroup): boolean => {
		return !!this.groups[group];
	};

	getVolumeLabel = (): string => {
		let group = this.groups[SettingsGroup.SHOW_QUANTITY] as ShowQuantitySettingsGroup;
		return group.sentenceLevel ? this.locale.getString('widget.minimumSentenceCount')
			: this.locale.getString('widget.minimumVolume');
	};

	changeAttrSort = (node): void => {
		let group = this.groups[SettingsGroup.SHOW_QUANTITY] as ShowQuantitySettingsGroup;
		group.settings.sortBy = node.name;
	};

	private initShowQuantity = (): void => {
		let group = this.groups[SettingsGroup.SHOW_QUANTITY] as ShowQuantitySettingsGroup;

		this.options.sortOrder = [
			{name: this.locale.getString('widget.top'), value: 'desc'},
			{name: this.locale.getString('widget.bottom'), value: 'asc'}
		];

		this.options.sortBy = group.sortOptions;
		if (group.settings.sortBy)
			this.tempSort = SearchableHierarchyUtils.findMetricInHierarchy(this.options.sortBy, {name: group.settings.sortBy});

		if (!group.settings.sortBy || !this.tempSort) {
			let node = this.options.sortBy[0].children[0];
			group.settings.sortBy = node.name;
			this.tempSort = node;
		}
	};

	private initNullInclude = (): void => {
		this.options.nullInclude = [
			{name: this.locale.getString('metrics.include'), value: true},
			{name: this.locale.getString('metrics.exclude'), value: false}
		];
	};

	private initCapitalization = (): void => {
		this.options.capitalization = this.capitalization.getOptions();
		let settings = this.groups[SettingsGroup.CAPITALIZATION].settings;
		if (_.isUndefined(settings.capitalization)) {
			settings.capitalization = this.options.capitalization[0].value;
		}
	};

	private initURL = (): void => {
		this.options.urlTypes = [
			{ name: this.locale.getString('widget.optionNone'), value: AttributeUrlType.NONE },
			{ name: this.locale.getString('widget.image'), value: AttributeUrlType.IMAGE },
			{ name: this.locale.getString('widget.link'), value: AttributeUrlType.LINK }
		];
		let settings = this.groups[SettingsGroup.URL].settings;
		if (_.isUndefined(settings.urlType)) {
			settings.urlType = AttributeUrlType.NONE;
		}
		if (_.isUndefined(settings.tableRowHeight)) {
			settings.tableRowHeight = this.TABLE_ROW_HEIGHT_DEFAULT;
		}
	};

	handleNodeClick = (node: INode): void => {
		this.indeterminateTopicSelection.handleNodeClick(node);
	};

	isNodeClickable = (callback: {node: INode}): boolean => {
		return this.indeterminateTopicSelection.isNodeClickable(callback.node);
	};

	isNodeChecked = (callback: {node: INode}): boolean => {
		return this.indeterminateTopicSelection.isNodeChecked(callback.node);
	};

	isNodeHighlighted = (callback: {node: INode}) => {
		return this.indeterminateTopicSelection.isNodeHighlighted(callback.node);
	};
	isIndeterminateNode = (callback: {node: INode}): boolean => {
		return this.indeterminateTopicSelection.isIndeterminateNode(callback.node);
	};

	isIndeterminateFollowedByUncheck = (callback: {node: INode}): boolean => true;

	selectAll = (): void => this.indeterminateTopicSelection.selectAll();
	deselectAll = (): void => this.indeterminateTopicSelection.deselectAll();

	levelChanged = (): void => {
		let group = this.groups[SettingsGroup.INCLUSION_LIST] as InclusionListSettingsGroup;
		group.settings.selectedLevel = this.selectedLevel;

		this.indeterminateTopicSelection.updateSelectedLevel();
	};

	private initInclusionList = (): void => {
		this.indeterminateTopicSelection = this.createIndeterminateTopicSelection();

		let group = this.groups[SettingsGroup.INCLUSION_LIST] as InclusionListSettingsGroup;
		group.processSelectedNodes = this.indeterminateTopicSelection.processSelectedNodes;

		this.modelTree = group.modelTree;

		let maxLevels = this.hierarchyService.getDepth(group.modelTree);
		this.options.levels = [];
		for (let i = 1; i <= maxLevels; i++) {
			this.options.levels.push({value: i, name: i});
		}

		this.options.levels.push({value: TopicReportGrouping.LEAF_LEVEL, name: this.locale.getString('widget.leaf')});

		this.selectedLevel = !!group.settings.selectedLevel ? group.settings.selectedLevel : this.options.levels[0].value;
		group.settings.selectedLevel = this.selectedLevel;

		this.indeterminateTopicSelection.processInclusionList();
	};

	resetDefaultSettings = (): void => {
		for (const group of Object.keys(this.groups)) {
			this.groups[group].resetDefaultSettings();
			let initializer = this.initializers[group];
			if (initializer) initializer();
		}
	};
}
