import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocationService } from '@app/core/cx-location.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { QuickInsightsQuery } from '@app/modules/home-page/quick-insights/quick-insights/quick-insights-page/quick-insights-query';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

// !!! This doesn't support attribute values yet
@Component({
	selector: 'quick-insights-page',
	template: `
<quick-insights *ngIf="quickSearch"
	class="flex-fill m-16"
	[(searchValue)]="quickSearch"
	(searchValueChange)="updateSearch($event)"
	[dashboards]="dashboards">
</quick-insights>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QuickInsightsPageComponent extends SelfCleaningComponent implements OnInit {

	quickSearch: AttributeValueOption;
	dashboards: Dashboard[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly cxLocation: CxLocationService,
		private readonly dashboardListService: DashboardListService,
	) {
		super();
	}

	ngOnInit(): void {
		this.quickSearch = QuickInsightsQuery.fromQueryParams(this.cxLocation.search());
		if (!this.quickSearch) {
			this.goToHome();
		}
		this.addSubscription(this.dashboardListService.getDashboards().subscribe(dashboards => {
			this.dashboards = dashboards;
			this.ref.markForCheck();
		}));
	}

	goToHome(): void {
		this.cxLocation.path('/dashboards').search({});
	}

	updateSearch(value: AttributeValueOption): void {
		if (value)
			this.cxLocation.search(QuickInsightsQuery.toQueryParams(value));
		else this.goToHome();
	}

}

app.directive('quickInsightsPage', downgradeComponent({component: QuickInsightsPageComponent}));
