import { Component, OnInit, Inject, Input, Optional,
	AfterViewInit, ViewChild, ElementRef, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { MultiselectUtils } from '@app/modules/filter-builder/attribute/multiselect/multiselect-utils';
import { AttributeValueOption, MultiselectComponent } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { PagingMultiselectComponent } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-multiselect.component';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { Subscription } from 'rxjs';

@Component({
	selector: 'multiselect-button',
	templateUrl: './multiselect-button.component.html'
})
export class MultiselectButtonComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() values: AttributeValueOption[];
	@Input() itemFormatter: (...args) => string;
	@Input() isDisabled: boolean;

	@ViewChild('toggleButton', {static: false}) private toggleButton: ElementRef;
	@ViewChild('toggleInput', {static: false}) private toggleInput: ElementRef;

	parent: MultiselectComponent;
	toggle: ElementRef;

	private focusSubscription$: Subscription;

	constructor(
		@Optional() private multiselect: MultiselectComponent,
		@Optional() private pagingMultiselect: PagingMultiselectComponent,
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
	) { }

	ngOnInit(): void {
	}

	ngAfterViewInit(): void {
		this.parent =  this.pagingMultiselect ? this.pagingMultiselect.multiselect : this.multiselect;
		setTimeout(() => {
			this.toggle = this.parent.useChips ? this.toggleInput : this.toggleButton;
			this.focusSubscription$ = this.parent.setButtonFocus.asObservable().subscribe(this.onFocus);
		}, 0);
		// using detectChanges as this component doesn't work properly under mixed OnPush+Default change detection
		// of his parent components
		this.ref.detectChanges();
	}

	ngOnDestroy(): void {
		if (this.focusSubscription$) {
			this.focusSubscription$.unsubscribe();
		}
	}

	private onFocus = (): void => {
		this.toggle.nativeElement.focus();
	};

	toggleDropdown = (): void => {
		this.parent.ngDropdown.toggle();
	};

	getSelectedOptionsCount = (): number => {
		return this.values
			? this.dashboardFiltersService.getNestedSelectedChildren(this.values).length
			: 0;
	};

	getInputText = (): string => {
		// this could potentially be refactored out later if it is too specific to dashboard filter application
		switch (this.getSelectedOptionsCount()) {
		case 0:
			return this.locale.getString('common.selectPrompt');
		case 1:
			return this.getSelectedOption();
		default:
			return this.locale.getString('common.selectionCount', {count: this.getSelectedOptionsCount()});
		}
	};

	getChips = (): any[] => {
		return this.dashboardFiltersService.getNestedSelectedChildren(this.values);
	};

	getSelectedOption = (): string => {
		let option = _.filter(this.values, MultiselectUtils.optionSelected);
		let filter;
		if (option[0] && option[0].children) {
			filter = _.filter(option[0].children, MultiselectUtils.optionSelected)[0];
		} else {
			filter = option[0];
		}

		if (filter.existMatch) {
			return this.locale.getString('reportFilters.' + filter.name);
		}

		return this.itemFormatter
			? this.itemFormatter(filter)
			: filter.displayName;
	};

	getFilterName = (filter): string => {
		return filter.existMatch
			? this.locale.getString('reportFilters.' + filter.name)
			: filter.displayName;
	};

	remove = (chip, $event): void => {
		$event.stopPropagation();
		this.parent.clickItem(chip);
	};

	checkInputClick = ($event): void => {
		if (this.parent.isDropdownOpen()) {
			$event.stopPropagation();
		}
	};

	checkInputChange = (): void => {
		if (!this.parent.filterText.length) {
			this.parent.ngDropdown.close();
		}
	};
}

app.directive('multiselectButton', downgradeComponent({component: MultiselectButtonComponent}));
