import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { CxLocationService } from '@app/core/cx-location.service';
import { HttpPromise } from '@app/core/http-promise';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { Context } from '@cxstudio/auth/context';

@Injectable()
export class UserSessionApiService {
	constructor(
		private cxHttp: CxHttpService,
		private cxLocation: CxLocationService,
		private sessionPreferences: SessionPreferencesService,
	) {}

	login = (user, extendConfig?): HttpPromise<any> => {
		let defaultConfig: CxHttpRequestOptions<HttpParams> = {
			headers: {'Content-Type': 'application/x-www-form-urlencoded'}
		};

		let config = $.extend(defaultConfig, extendConfig);

		if (this.isCxSuiteLogin()) {
			let params = this.cxLocation.search();
			config.params = new HttpParams()
				.append('clientId', params.clientId)
				.append('redirectTo', params.redirectTo || '/');
			if (params.masterAccountId) {
				config.params = config.params.append('masterAccountId', params.masterAccountId);
			}
			if (params.state) {
				config.params = config.params.append('state', params.state);
			}
			return this.cxHttp.rawPost('rest/security/token/cx-suite', $.param(user), config);
		} else {
			return this.cxHttp.rawPost('rest/security/token', $.param(user), config);
		}
	};

	private isCxSuiteLogin = (): boolean => {
		return !!this.cxLocation.search().clientId;
	};

	getContext = (): HttpPromise<Context> => {
		let sessionHomePage = this.sessionPreferences.get('homePageId');
		let params = sessionHomePage ? new HttpParams().append('homePage', sessionHomePage) : undefined;
		return this.cxHttp.rawGet('rest/security/context', {params});
	};

	continueSession = (user): HttpPromise<any> => {
		let local = {local: {unauthorized: true}};
		return this.login(user, local);
	};

	updateAccessTime = (): Promise<any> =>
		this.cxHttp.put('rest/security/access_time', undefined, {ignoreConnectionErrors: true});

		// login/logout
	logout = (): HttpPromise<any> =>
		this.cxHttp.rawPost('rest/security/logout');
}

app.service('userSessionApiService', downgradeInjectable(UserSessionApiService));
