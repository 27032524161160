import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';

export abstract class PeriodOverPeriodMetric {
	abstract getType(): PeriodOverPeriodMetricType;
	abstract getNamePrefix(): string;
	abstract getDisplayName(parentMetricDisplayName: string): string;

	isSupported = (metric: ReportCalculation, metricList: Metric[]): boolean => {
		return true;
	};

	getName = (parentMetricName: string): string => {
		return this.getNamePrefix() + parentMetricName;
	};

	applyFormatting = (metric: ReportCalculation): ReportCalculation => {
		return metric;
	};
}