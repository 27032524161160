import { Component, Input, EventEmitter, Output, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { SortMetric } from '@cxstudio/reports/entities/sort-metric';

@Component({
	selector: 'feedback-sort-button',
	template: `
<button class="btn sort-button"
	(click)="toggleSort()"
	[disabled]="disabled"
	[ngClass]="{'btn-selected' : isSelected()}"
	title="{{tooltip}}">
	<span>
		<icon [name]="icon"></icon>
		<icon class="text-0_875rem" [name]="getSortDirection()"></icon>
	</span>
</button>`
})
export class FeedbackSortButtonComponent {
	@Input() value: string;
	@Input() tooltip: string;
	@Input() sortMetric: SortMetric;
	@Input() icon: string;
	@Input() disabled: boolean;
	@Output() sortChange = new EventEmitter<void>();

	toggleSort = () => {
		if (this.isSelected()) {
			this.toggleDirection();
		} else {
			this.sortMetric.displayName = this.value;
			this.sortMetric.direction = AnSortDirection.DESC;
		}
		this.sortChange.emit();
	};

	private toggleDirection(): void {
		this.sortMetric.direction = this.sortMetric.direction === AnSortDirection.DESC ? AnSortDirection.ASC : AnSortDirection.DESC;
	}

	isSelected = (): boolean => this.sortMetric && this.sortMetric.displayName === this.value;

	getSortDirection = (): 'sort-up' | 'sort-down' => {
		// when not selected - show descending as well
		let isDescending = !this.isSelected() || this.sortMetric.direction === AnSortDirection.DESC;
		return isDescending ?
			'sort-down' :
			'sort-up';
	};

}

app.directive('feedbackSortButton', downgradeComponent({component: FeedbackSortButtonComponent}));
