import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { AppliedFilters } from '@cxstudio/reports/entities/widget-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AnalyticWidget extends Widget {}
class AnalyticWidget implements Widget {
	constructor() {
		this.type = WidgetDisplayType.CB;
	}

	withId(id: number): AnalyticWidget {
		this.id = id;
		return this;
	}

	withProjectSelection(projectSelection: AccountOrWorkspaceProjectData): AnalyticWidget {
		if (WorkspaceTransitionUtils.isWorkspaceProject(projectSelection)) {
			const projectData = WorkspaceTransitionUtils.getProjectData(projectSelection);
			_.extend(this.properties, projectData);
		} else {
			this.properties.contentProviderId = projectSelection.contentProviderId;
			this.properties.accountId = projectSelection.accountId;
			this.properties.project = projectSelection.projectId; // pay attention that this is "project = projectId"!
			this.properties.projectName = projectSelection.projectName;
		}
		return this;
	}

	withGroupings(groupings: AttributeGrouping[]): AnalyticWidget {
		this.properties.selectedAttributes = groupings;
		return this;
	}

	withCalculations(calculations: ReportCalculation[]): AnalyticWidget {
		this.properties.selectedMetrics = calculations;
		return this;
	}

	withPeriods(current: DateFilter, historical?: DateFilter): AnalyticWidget {
		this.properties.dateRangeP1 = current;
		this.properties.dateRangeP2 = historical;
		return this;
	}

	withAutoTitle(autoTitle: boolean, withDateRange = false): AnalyticWidget {
		this.properties.isCustomTitle = !autoTitle;
		this.properties.titleWithDateRange = withDateRange;
		return this;
	}

	withAutoDescription(autoDescription: boolean) {
		this.properties.autoDescription = autoDescription;
		return this;
	}

	withDisplayName(displayName: string): AnalyticWidget {
		this.displayName = displayName;
		return this;
	}

	withRunAs(runAs: string): AnalyticWidget {
		this.properties.runAs = runAs;
		return this;
	}

	withVisualProperties(visualProperties: VisualProperties): AnalyticWidget {
		_.extend(this.visualProperties, visualProperties);
		return this;
	}

	withWidth(width: number): AnalyticWidget {
		this.width = width;
		return this;
	}

	withHeight(height: number): AnalyticWidget {
		this.height = height;
		return this;
	}

	withPosition(posX: number, posY: number): AnalyticWidget {
		this.posX = posX;
		this.posY = posY;
		return this;
	}

	withAdHocFilters(adhocFilter: AdhocFilter): AnalyticWidget {
		if (adhocFilter) {
			this.properties.adhocFilter = adhocFilter;
		}

		return this;
	}

	withAppliedFilters(appliedFilters: AppliedFilters): AnalyticWidget {
		if (appliedFilters) {
			this.properties.appliedFilters = appliedFilters;
		}

		return this;
	}

	withLinkedWidgets(linkedWidgets: number[]): AnalyticWidget {
		if (linkedWidgets) {
			this.linkedWidgets = linkedWidgets;
		}

		return this;
	}

	withDateRangeP1(dateFilter: DateFilter): AnalyticWidget {
		if (dateFilter) {
			this.properties.dateRangeP1 = dateFilter;
		}
		return this;
	}

	withDateRangeP2(dateFilter: DateFilter): AnalyticWidget {
		if (dateFilter) {
			this.properties.dateRangeP2 = dateFilter;
		}
		return this;
	}
}

export default AnalyticWidget;
