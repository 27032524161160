import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { ICBAccount } from './cb-account';

export interface IContentProvider {
	id: number;
	name: string;
	accountPromise: any;

	getAccounts(): ng.IPromise<ICBAccount[]>;
}

app.factory('ContentProvider', ($q, $log, $timeout, contentProviderOptionsService: ContentProviderOptionsService, CBAccount) => {
	return class implements IContentProvider {
		id: number;
		name: string;
		accountPromise: any;

		constructor(cpObject: ContentProvider) {
			this.id = cpObject.id;
			this.name = cpObject.name;

			this.accountPromise = null;
		}

		getAccounts = (): ng.IPromise<ICBAccount[]> => {
			if (!this.accountPromise) {
				let defer = $q.defer();
				this.accountPromise = defer.promise;

				contentProviderOptionsService.getUserAccounts(this.id).then((response) => {
					let accounts = response?.data;
					defer.resolve(_.map(accounts, (acc) => new CBAccount(this.id, acc)));
				}, (reason) => {
					$log.warn(reason);
					defer.resolve([]);
					$timeout(() => { // reset this value in 1 sec to avoid multiple error messages
						this.accountPromise = null;
					}, 1000);
				});
			}
			return this.accountPromise;
		};

	};
});
