
import * as _ from 'underscore';
import * as uib from 'angular-ui-bootstrap';
import WidgetSettingsService from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import AssetSelectorComponentController from './asset-selector.component';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { WidgetProject } from '@cxstudio/reports/entities/widget-project';
import { IHandlerCallback, EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import EventType from '@cxstudio/services/event/event-type.enum';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';

export default class CalculationSelectorComponentController extends AssetSelectorComponentController {

	calculationUpdateEventHandler: IHandlerCallback;
	withHierarchyMetrics: boolean;

	constructor(
		$scope: ng.IScope,
		widgetSettingsService: WidgetSettingsService,
		optionsBuilderProvider: OptionsBuilderProvider,
		$uibModal: uib.IModalService,
		tagService,
		cbSettingsService,
		reportMetricService: ReportMetricService,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private reportSettingsService: ReportSettingsService,
		private eventEmitterService: EventEmitterService,
		private hierarchySettingsService: HierarchySettingsService,
		private metricUtils,
		private optionsTemplatesService: OptionsTemplatesService,
		private metricConstants: MetricConstants,
	) {
		super(
			$scope,
			widgetSettingsService,
			optionsBuilderProvider,
			$uibModal,
			tagService,
			cbSettingsService,
			reportMetricService,
			false);
	}

	init(): void {
		if (this.withHierarchyMetrics) {
			this.calculationUpdateEventHandler = this.eventEmitterService.subscribe(
				EventType.UPDATE_CALCULATION_SELECTOR,
				(param) => this.processDynamicCalculations(param.screenIndex, param.grouping));
		}
	}

	$onDestroy(): void {
		if (this.calculationUpdateEventHandler) {
			this.calculationUpdateEventHandler.unsubscribe();
		}
	}

	onChange = (node): void => {
		if (this.projectIdentifier) {
			let defaultFormat = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(node);
			_.extend(node, defaultFormat);
			let project: WidgetProject = {
				project: this.projectIdentifier.projectId,
				contentProviderId: this.projectIdentifier.contentProviderId,
				accountId: this.projectIdentifier.accountId
			};
			this.reportSettingsService.getCalculationSettings(project, node).then(defaultSettings => {
				_.extend(node, defaultSettings);
				this.asset = node;
				this.updateAsset();
			});
		} else {
			this.updateAsset();
		}
	};

	processDynamicCalculations = (screenIndex, grouping) => {
		if (screenIndex !== this.screenIndex) return;
		if (AnalyticMetricTypes.isHierarchyModel(grouping)) {
			this.hierarchySettingsService.getOrganizationCalculations(grouping).then((calculations) => {
				this.populateCurrentHierarchyCalculations(this.additionalMetrics, grouping, calculations);
			});
		} else {
			this.removeHierarchyCalculationsAndOptions();
		}
	};

	private hasAnyHierarchyCalculations = (calculations): boolean => !isEmpty(calculations.enrichment) || !isEmpty(calculations.custom);

	private populateCurrentHierarchyCalculations = (metrics, hierarchyGrouping, calculations) => {
		if (!hierarchyGrouping) return;

		this.removeNotMatchingHierarchyCalculations(hierarchyGrouping);
		if (!this.hasAnyHierarchyCalculations(calculations)) {
			this.removeHierarchyCalculationOptions();
		} else {
			let hierarchyId = parseInt(hierarchyGrouping.name, 10);
			let allOptions = this.metricUtils.buildOrgHierarchyMetricOptions(hierarchyId, calculations);
			let hierarchyMetricsGroup = this.optionsTemplatesService.buildHierarchyMetricsGroup(allOptions);

			HierarchyUtils.replaceOrAddGroup(metrics, OptionsConstant.HIERARCHY_CALCULATIONS, hierarchyMetricsGroup);
		}
	};

	private removeHierarchyCalculationOptions = (): void => {
		if (this.additionalMetrics) {
			HierarchyUtils.removeGroup(this.additionalMetrics, OptionsConstant.HIERARCHY_CALCULATIONS);
		}
	};

	private removeNotMatchingHierarchyCalculations = (hierarchyGrouping): void => {
		let hierarchyId = parseInt(hierarchyGrouping.name, 10);
		let differentHierarchyEnrichmentProperty = this.asset
			&& AnalyticMetricTypes.isHierarchyEnrichmentProperty(this.asset)
			&& this.asset.hierarchyId !== hierarchyId;
		let differentHierarchyCustomCalculation = this.asset
			&& this.metricUtils.isHierarchyCustomMetric(this.asset)
			&& !this.metricUtils.isSpecificHierarchyCustomMetric(this.asset, hierarchyId);
		if (differentHierarchyEnrichmentProperty || differentHierarchyCustomCalculation) {
			this.asset = this.metricConstants.get().VOLUME;
		}
	};

	private removeHierarchyCalculationsAndOptions = () => {
		this.removeHierarchyCalculationOptions();

		if (this.asset && this.metricUtils.isHierarchyMetric(this.asset)) {
			this.asset = this.metricConstants.get().VOLUME;
		}
	};

}

app.component('calculationSelector', {
	controller: CalculationSelectorComponentController,
	templateUrl: 'partials/components/calculation-selector.html',
	bindings: {
		projectIdentifier: '<',
		asset: '=',
		onAssetChange: '&',
		onAssetClear: '&?',
		showLabel: '<?',
		widgetSettingsCache: '=?',
		errors: '=',
		withHierarchyMetrics: '<',
		linkedGrouping: '<',
		screenIndex: '<'
	}
});
