import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

export class RenameService {

	constructor(
		private readonly downgradeDialogService: DowngradeDialogService
	) {}

	modal = (item: any, itemList: any[], typeString: string): NgbModalRef => {
		return this.downgradeDialogService.openRenameModalDialog({
			currentList: itemList,
			renameItem: item,
			typeString
		});
	};
}

app.service('renameService', RenameService);
