import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { TopicChickletsService } from '@app/modules/document-explorer/topic-chicklet.service';
import { Pill, PillType } from '@app/modules/pills/pill';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { ChangeUtils } from '@app/util/change-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { IDocumentClassification, IDocumentClassificationNode } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { DocExplorerFavoriteUtils } from '@app/modules/document-explorer/context-pane/doc-explorer-favorite-utils.class';
import { FavoriteAttribute, FavoriteId, FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
@Component({
	selector: 'topic-chicklets',
	templateUrl: './topic-chicklets.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class TopicChickletsComponent extends SelfCleaningComponent implements OnInit, AfterViewInit, OnChanges {

	private readonly TYPE = FavoriteType.MODEL;

	@Input() model: FavoriteAttribute & IDocumentClassification;
	@Input() favoriteAttributes: FavoriteId[];
	@Input() disableFavorites: boolean;
	@Input() favoritesPanel: boolean;
	@Input() selectionChangeTrigger: number;
	@Input() leafOnly: boolean;

	@Output() chickletClick = new EventEmitter<IDocumentClassificationNode>();
	@Output() chickletHover = new EventEmitter<IDocumentClassificationNode>();
	@Output() onToggleFavorite = new EventEmitter<{attribute: FavoriteAttribute; type: FavoriteType}>();

	favoriteId: FavoriteId;
	pills: Pill[];
	width: number;
	private resizeObserver: any;
	private widthElement: Element;
	private readonly debouncedResize = new Subject<void>();

	constructor(
		private topicChickletsService: TopicChickletsService,
		private ref: ChangeDetectorRef,
		private elementRef: ElementRef
	) {
		super();
	}

	ngOnInit(): void {
		this.favoriteId = DocExplorerFavoriteUtils.getFavoriteId(this.model, this.TYPE);
		this.initPills();
	}

	private initPills(): void {
		this.pills = this.model.nodes
			.filter(node => !this.leafOnly || node.isLeaf)
			.map(node => {
				return {
					name: this.getTopicChickletDisplayName(node),
					type: PillType.ATTRIBUTE,
					sortValue: node.name,
					title: this.getPath(node),
					value: node
				};
			});
	}

	ngAfterViewInit(): void {
		this.updateWidth();
		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.updateWidth()));
		this.widthElement = this.elementRef.nativeElement.firstElementChild;
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(this.widthElement, () => this.debouncedResize.next()));
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.selectionChangeTrigger)) {
			this.initPills();
		}
	}

	getPillClass = (pill: Pill): string => {
		return pill.value.selected ? 'selected' : '';
	};

	toggleFavorite = () => {
		this.onToggleFavorite.emit({attribute: this.model, type: this.TYPE});
	};

	getTopicDisplayName = (name: string): string => {
		return this.topicChickletsService.getTopicDisplayName(name);
	};

	getPath = (node: IDocumentClassificationNode) => {
		return this.topicChickletsService.getPath(node, this.model);
	};

	getTopicChickletDisplayName = (node: IDocumentClassificationNode) => {
		return this.topicChickletsService.getTopicChickletDisplayName(node);
	};

	private updateWidth = () => {
		let outerWidth = $(this.widthElement).outerWidth();
		let newWidth = outerWidth && Math.floor(outerWidth);
		if (this.width !== newWidth) {
			this.width = newWidth;
			this.ref.detectChanges();
		}
	};
}
