import {Component, OnInit, Input, Output, Inject, EventEmitter, SimpleChanges} from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import {MasterAccountPermission} from '@app/modules/user-administration/permissions/master-account-permission.class';
import {License} from '@app/modules/user-administration/permissions/license.class';
import { PermissionsService } from '@app/modules/user-administration/permissions/permissions.service';
import { PermissionGroup } from '@app/modules/user-administration/permissions/permission-group';
import { Security } from '@cxstudio/auth/security-service';
import { UserEditFormData } from '@app/modules/user-administration/editor/user-edit-form-data';
import { OnChanges } from '@angular/core';
import { ChangeUtils } from '@app/util/change-utils';

export enum PermissionsLayout {
	VERTICAL = 'vertical',
	HORIZONTAL = 'horizontal'
}

@Component({
	selector: 'permissions',
	template: `
		<div class="d-flex justify-between mb-16" *ngIf="!calculated">
			<p class="mb-16">
				{{head}}
			</p>
			<div class="mb-16">
				<button
					[disabled]="viewOnly"
					type="button"
					class="btn btn-secondary"
					(click)="enableAllPermissions()">
					{{'common.selectAll'|i18n}}
				</button>
				<button
					[disabled]="viewOnly"
					type="button"
					class="btn btn-secondary"
					(click)="disableAllPermissions()">
					{{'common.deselectAll'|i18n}}
				</button>
			</div>
		</div>
		<section class="d-flex permissions-section">
			<div *ngFor="let column of columns" class="permission-column">
				<div class="mb-16 permission-group" *ngFor="let group of column" [hidden]="!hasGroupPermissions(group)">
					<h3 class="pl-16 mb-0">{{getGroupDisplayName(group)}}</h3>
					<div *ngFor="let permission of getGroupPermissions(group);let idx=index;"
						 [ngClass]="getPermissionClasses(permission)">
						 <div class="labeled-checkbox">
							<checkbox-button
								class="cx-permission-checkbox"
								[label]="getPermissionText(permission)"
								[disabled]="isPermissionDisabled(permission) || viewOnly"
								[innerValue]=isSelected(permission)
								(onChange)=permissionsChanged(permission)>
							</checkbox-button>
							<cb-inline-help *ngIf="isProfanityPermission(permission)">
								<help-body [i18n]="'administration.viewProfanityHelp'">
								</help-body>
							</cb-inline-help>
						</div>
					</div>
				</div>
			</div>
		</section>
	`
})

export class PermissionsComponent implements OnInit, OnChanges {

	@Input() permissions: MasterAccountPermission[];
	@Input() selectedPermissions: MasterAccountPermission[];
	@Input() license: License;
	@Input() layout: PermissionsLayout;
	@Input() internal: boolean;
	@Input() head: string;
	@Input() prefix: string;
	@Input() calculated: boolean;
	@Input() user: UserEditFormData;
	@Input() viewOnly: boolean;
	@Output() onChange = new EventEmitter<any>();

	help: boolean = false;
	helpMessage: string;
	columns: string[][];
	availablePermissions: MasterAccountPermission[];

	private verticalGroups: PermissionGroup[][];
	private horizontalGroups: PermissionGroup[][];

	constructor(
		private locale: CxLocaleService,
		private permissionsService: PermissionsService,
		@Inject('security') private security: Security,
	) {}

	ngOnInit(): void {
		this.head = this.head || '';
		this.layout = this.layout || PermissionsLayout.VERTICAL;
		this.internal = !_.isUndefined(this.internal) ? this.internal : false;

		this.verticalGroups = [[
			PermissionGroup.DASHBOARD, PermissionGroup.OBJECT
		], [
			PermissionGroup.GROUP, PermissionGroup.USER, PermissionGroup.MASTER_ACCOUNT,
			PermissionGroup.CASE_MANAGEMENT, PermissionGroup.INTERACTION
		]];

		this.horizontalGroups = [
			[PermissionGroup.DASHBOARD],
			[PermissionGroup.OBJECT],
			[PermissionGroup.GROUP, PermissionGroup.USER],
			[PermissionGroup.MASTER_ACCOUNT],
			[PermissionGroup.CASE_MANAGEMENT, PermissionGroup.INTERACTION]
		];

		this.columns = this.layout === PermissionsLayout.HORIZONTAL
			? this.horizontalGroups
			: this.verticalGroups;
		if (!this.internal) {
			this.columns[this.columns.length - 1].push(PermissionGroup.CONNECTORS);
			this.columns[this.columns.length - 1].push(PermissionGroup.NARRATIVE);
		}
		this.availablePermissions = this.getPermissionsForActualGroups();

	}

	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.permissions)) {
			this.availablePermissions = this.getPermissionsForActualGroups();
		}
	}

	isSelected(permission: MasterAccountPermission): boolean {
		return this.isPermissionInList(this.selectedPermissions, permission)
			&& (!this.isPermissionRestricted(permission) || this.isCustomerAdminPermission(permission));
	}

	private getPermissionsForActualGroups(): MasterAccountPermission[] {
		let groups: string[] = _.flatten(this.columns);
		return _.filter(this.permissions, (permission: MasterAccountPermission) => {
			let group: string = this.permissionsService.getPermissionGroup(permission);
			return _.contains(groups, group);
		});
	}

	getGroupDisplayName(group: string): string {
		return this.locale.getString(`administration.${ group }Permissions`);
	}

	hasGroupPermissions(group: string): boolean {
		return this.getGroupPermissions(group).length > 0;
	}

	getGroupPermissions(group: string): MasterAccountPermission[] {
		let groupPermissions =  _.filter(this.availablePermissions, (permission: MasterAccountPermission) => {
			return group === this.permissionsService.getPermissionGroup(permission);
		});
		return groupPermissions;
	}

	getPermissionClasses(permission: MasterAccountPermission): string[] {
		let permissionClass: string = this.calculated ? 'calculated-permission' : 'permission';
		return [permissionClass, permission.action];
	}

	getPermissionText(permission: MasterAccountPermission): string {
		return this.permissionsService.getPermissionText(permission);
	}

	isPermissionDisabled(permission: MasterAccountPermission): boolean {
		if (this.calculated) return true;
		if (this.isProfanityPermission(permission)) {
			return !this.security.getCurrentMasterAccount().screeningSettings.feedbackScreeningEnabled;
		}
		if (!this.license) {
			return false;
		}
		if (this.isCustomerAdminPermission(permission)) {
			return this.isUserSelfEditing();
		}

		let restricted = this.isPermissionRestricted(permission);
		let granted = this.isPermissionInList(this.license.grantedPermissions, permission);

		return restricted || granted;
	}

	isProfanityPermission(permission: MasterAccountPermission): boolean {
		return permission.action === 'viewProfanity';
	}

	private isCustomerAdmin() {
		return this.user && this.user.customerAdmin;
	}

	private isUserSelfEditing() {
		return this.security.getUser().userId === this.user.userId;
	}

	private isCustomerAdminPermission(permission: MasterAccountPermission): boolean {
		if (!this.license || !this.isCustomerAdmin()) {
			return false;
		}

		return this.isPermissionInList(this.license.customerAdminPermissions, permission);
	}

	private isPermissionRestricted(permission: MasterAccountPermission): boolean {
		if (this.license) {
			let applicationRestricted: boolean = this.license.restrictedPermissionApplications
				&& this.license.restrictedPermissionApplications.contains(permission.application);
			return applicationRestricted || this.isPermissionInList(this.license.restrictedMasterAccountPermissions, permission);
		}
		return false;
	}

	private isPermissionInList(list: MasterAccountPermission[], permission: MasterAccountPermission): boolean {
		let item = _.findWhere(list, {group: permission.group, action: permission.action});
		return item !== undefined;
	}

	getPermissionTooltip(permission: MasterAccountPermission): string {
		let tooltip = '';
		if (this.license && this.isPermissionInList(this.license.restrictedMasterAccountPermissions, permission)) {
			tooltip = this.locale.getString('administration.restrictedPermission');
		}
		return tooltip;
	}

	permissionsChanged = (permission?: MasterAccountPermission): void => {
		if (permission) {
			let foundId = _.findIndex(this.selectedPermissions, (item) => item.action === permission.action);
			if (foundId > -1) {
				let permissions = this.selectedPermissions;
				this.selectedPermissions = permissions.slice(0, foundId).concat(permissions.slice(foundId + 1));
			} else {
				this.selectedPermissions.push(permission);
			}
			this.processPermissionDependencies(permission);
		}
		this.onChange.emit({
			selectedPermissions: this.selectedPermissions
		});
	};

	private processPermissionDependencies(permission: MasterAccountPermission): void {
		let enabled = !!_.contains(this.selectedPermissions, permission);
		if (enabled) {
			let dependsOn = this.permissionsService.getDependensOn(permission);
			_.each(dependsOn, actionToAdd => {
				if (!_.findWhere(this.selectedPermissions, {action: actionToAdd}))
					this.selectedPermissions.push(_.findWhere(this.availablePermissions, {action: actionToAdd}));
			});
		} else {
			let dependants = this.permissionsService.getDependants(permission);
			_.each(dependants, actionToRemove => {
				let existing = _.findWhere(this.selectedPermissions, {action: actionToRemove});
				if (!!existing)
					this.selectedPermissions.remove(existing);
			});
		}
	}

	enableAllPermissions(): void {
		this.availablePermissions.map((permission: MasterAccountPermission) => {
			if (!this.isPermissionRestricted(permission) && !_.findWhere(this.selectedPermissions, permission)) {
				this.selectedPermissions.push(permission);
			}
		});
		this.permissionsChanged();
	}

	disableAllPermissions(): void {
		this.selectedPermissions = [];
		this.permissionsChanged();
	}
}
app.directive('permissions', downgradeComponent({component: PermissionsComponent}));
