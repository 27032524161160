<modal-header class="kb-focusable-inset"
	(cancel)="cancel()"
	[modalTitle]="'header.userPreferences' | i18n">
</modal-header>
<div class="modal-body br-user-props">
	<form #propsDialogForm="ngForm"
		class="form-horizontal"
		name="propsDialog"
		(submit)="save()"
		role="form">
		<ul id="user-preferences-tabs" ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="false">
			<li ngbNavItem>
				<a ngbNavLink role="tab">{{'header.properties'|i18n}}</a>
				<ng-template ngbNavContent>
					<div [ngBusy]="preferencesLoading">
						<div class="form-group" *ngIf="passwordPolicy && showPasswordControls">
							<div class="col-sm-3 control-label">
								<label>{{'userDialog.prefChangePassword'|i18n}}</label>
							</div>
							<div class="col-sm-9">
								<span [i18n]="'userDialog.passwordPolicyLength'"
									[i18nParams]="{pwdLength: passwordPolicy?.minLength}"></span>
								<span *ngIf="passwordPolicy?.requireSpecialChars" [i18n]="'userDialog.passwordPolicyChars'"></span>
							</div>
						</div>
						<div class="form-group" [hidden]="!showPasswordControls">
							<label for="oldPassword" class="col-sm-3 control-label">{{'userDialog.oldPassword'|i18n}}</label>
							<div class="col-sm-9">
								<input
									type="password"
									class="form-control"
									id="oldPassword"
									name="oldPassword"
									autocomplete="nope"
									placeholder=""
									[(ngModel)]="model.oldPassword"
									#oldPassword="ngModel"
									[disabled]="isForcedExternalAuthentication"
									[required]="!!model.newPassword.length">
								<span
									class="help-block"
									*ngIf="oldPassword.errors?.required"
									[i18n]="'administration.oldPasswordRequired'">
								</span>
							</div>
						</div>
						<div class="form-group" [hidden]="!showPasswordControls">
							<label for="password" class="col-sm-3 control-label">{{'userDialog.newPassword'|i18n}}</label>
							<div class="col-sm-9">
								<input
									#passwordInput
									type="password"
									autocomplete="new-password"
									id="password"
									class="form-control"
									placeholder=""
									name="newPassword"
									[pattern] = "pwdPattern"
									[minlength]="passwordPolicy?.minLength"
									[(ngModel)]="model.newPassword"
									#newPassword="ngModel"
									[disabled]="isForcedExternalAuthentication">
								<span class="help-block"
									*ngIf="newPassword.errors?.minlength"
									[i18n]="'administration.passwordInvalid'"
									[i18nParams]="{pwdLength: passwordPolicy?.minLength}"></span>
								<span
									class="help-block"
									*ngIf="newPassword.errors?.pattern"
									[i18n]="'administration.specialCharsRequired'"></span>
							</div>
						</div>
						<div class="form-group" [hidden]="!showPasswordControls">
							<label for="confirmPassword" class="col-sm-3 control-label">{{'userDialog.confirmPassword'|i18n}}</label>
							<div class="col-sm-9">
							<input
								id="confirmPassword"
								type="password"
								class="form-control"
								placeholder=""
								name="confirmPassword"
								autocomplete="new-password"
								#confirmPassword="ngModel"
								[pwCheck]="model.newPassword"
								(pwCompareChange)="onPasswordCompareChange()"
								[disabled]="isForcedExternalAuthentication"
								[(ngModel)]="model.confirmPassword">
							<span class="help-block"
								*ngIf="confirmPassword.errors?.pwmatch"
								[i18n]="'administration.passwordNotMatch'"></span>
							</div>
						</div>
						<div *ngIf="showPreferredEmail()" class="form-group">
							<label for="preferredEmail" class="col-sm-3 control-label">{{'userDialog.preferredEmail'|i18n}}</label>
							<div class="col-sm-9">
								<input id="preferredEmail" class="form-control"
									type="email"
									name="preferredEmail"
									autocomplete="new"
									placeholder=""
									[(ngModel)]="model.preferredEmail"
									[debounce]="500"
									(debouncedChange)="validatePreferredEmail()"
									[disabled]="!isInternalUser()"
									email
									#preferredEmail="ngModel">
								<span
									class="help-block"
									*ngIf="preferredEmail.errors?.email"
									[i18n]="'administration.emailInvalid'">
								</span>
								<span
									class="help-block"
									*ngIf="preferredEmail.errors?.domainRestricted">
									{{allowedDomainsErrorMessage}}
								</span>
								<span
									class="help-block"
									*ngIf="!preferredEmail.errors && isNeedPreferredEmailConfirm()"
									[i18n]="'userDialog.preferredEmailConfirmNote'">
								</span>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-3 control-label">{{'userDialog.prefLang'|i18n}}</label>
							<div class="col-sm-9">
								<simple-dropdown
									[(value)]="model.language"
									valueField="id"
									displayField="name"
									role="listbox"
									[attr.aria-label]="'userDialog.prefLang'|i18n"
									[options]="languages">
								</simple-dropdown>
							</div>
						</div>
						<div class="form-group">
							<label class="col-sm-3 control-label">{{'userDialog.prefDateFormat'|i18n}}</label>
							<div class="col-sm-9">
								<simple-dropdown
									[(value)]="model.dateFormat"
									valueField="id"
									displayField="name"
									role="listbox"
									[attr.aria-label]="'userDialog.prefDateFormat'|i18n"
									[options]="dateFormats">
								</simple-dropdown>
							</div>
						</div>
						<div class="form-group" *ngIf="showTranslateProps" data-testid="translate-language">
							<label class="col-sm-3 control-label">{{'userDialog.translateLanguage'|i18n}}</label>
							<div class="col-sm-9">
								<simple-dropdown
									[(value)]="model.translateLanguage"
									valueField="id"
									displayField="name"
									role="listbox"
									[attr.aria-label]="'userDialog.translateLanguage'|i18n"
									[options]="translateLanguages">
								</simple-dropdown>
							</div>
						</div>

						<hr *ngIf="isDarkModeAvailable()"/>
						<div class="form-group" data-testid="user-color-theme" *ngIf="isDarkModeAvailable()">
							<label class="col-sm-3 control-label">{{'common.colorTheme'|i18n}}</label>
							<div class="col-sm-9">
								<simple-dropdown
									[options]="availableThemeOptions"
									[(value)]="model.applicationTheme"
									displayField="displayName"
									valueField="name"
									role="listbox"
									[attr.aria-label]="'common.colorTheme'|i18n"
									sortField="order">
								</simple-dropdown>
							</div>
						</div>

						<hr *ngIf="isNeedFavoriteMasterAccountSelector() || isNeedHomePageSelector()"/>

						<div class="form-group" *ngIf="isNeedFavoriteMasterAccountSelector()">
							<label class="col-sm-3 control-label">{{'userDialog.favoriteMasterAccount'|i18n}}
								<cb-inline-help
									id="fav-description"
									role="tooltip"
									tabindex="0"
									[attr.aria-label]="'userDialog.favoriteMasterAccountHelp'|i18n">
									<help-body>{{'userDialog.favoriteMasterAccountHelp'|i18n}}</help-body>
								</cb-inline-help>
							</label>
							<div class="col-sm-9">
								<search-list
									[dropdown]="true"
									[listOptions]="availableMasterAccounts"
									[data]="model.favoriteMasterAccount"
									(onNodeSelection)="onFavoriteMasterAccountChange($event.node)"
									(onClearSelection)="clearFavoriteMasterAccount()"
									fullWidth="true"
									matchSize="true"
									displayField="accountName"
									sortField="accountName"
									selectPrompt="{{'common.none'|i18n}}"
									role="listbox"
									aria-describedby="fav-description"
									[attr.aria-label]="'userDialog.favoriteMasterAccount'|i18n"
									allowClear="true">
								</search-list>
							</div>
						</div>

						<div class="form-group ph-16" *ngIf="isQualtricsIntegrationEnabled()">
							<user-linked-accounts></user-linked-accounts>
						</div>

						<div class="form-group" *ngIf="isNeedHomePageSelector()">
							<label class="col-sm-3 control-label">{{'userDialog.favoriteHomePage'|i18n}}</label>
							<div class="col-sm-9">
								<search-list
									class="_t-favorite-home-page"
									[dropdown]="true"
									[listOptions]="availableHomePages"
									[data]="selectedHomePage"
									(onNodeSelection)="onFavoriteHomePageChange($event.node)"
									(onClearSelection)="clearFavoriteHomePage()"
									fullWidth="true"
									matchSize="true"
									displayField="name"
									sortField="sortKey"
									selectPrompt="{{'userDialog.automaticHomePage'|i18n}}"
									allowClear="true">
								</search-list>
							</div>
						</div>
					</div>
				</ng-template>
			</li>

			<li ngbNavItem>
				<a ngbNavLink>{{'dashboard.defaultsTitle'|i18n}}</a>
				<ng-template ngbNavContent>
					<div [ngBusy]="[projectsLoading]">
						<div *ngIf="showFavoriteProjectProps">
							<project-selector
								*ngIf="!isWorkspaceEnabled"
								[projectSelection]="model.properties"
								(projectSelectionChange)="onProjectSelectionChange($event)"
								(errorsChange)="onCpErrorsChange($event)"
								(loading)="onProjectsLoading($event)"
								[clear]="clearDefaultsSubject.asObservable()"
							></project-selector>
							<workspace-project-selector
								*ngIf="isWorkspaceEnabled"
								[(value)]="model.workspaceProject"
								[inline]="false"
								labelPosition="left"
								(workspaceChange)="workspaceChanged($event)"
								(valueChange)="workspaceProjectChanged($event)"
								(loading)="onProjectsLoading($event)"
								[hideInternalProjects]="true"
								[clear]="clearDefaultsSubject.asObservable()"
							></workspace-project-selector>
						</div>
						<div class="form-group">
							<label class="w-25-percent control-label">{{'administration.application'|i18n}}</label>
							<div class="w-75-percent ph-16">
								<simple-dropdown
									[options]="availableStartupApps"
									[(value)]="model.properties.startupApp"
									sortField="order"
									displayField="displayName"
									role="listbox"
									[attr.aria-label]="'administration.application'|i18n"
									valueField="name">
								</simple-dropdown>
							</div>
						</div>
						<div class="form-group" *ngIf="model.properties.startupApp === 'STUDIO'">
							<label class="w-25-percent control-label">{{'common.favoriteDashboardOrBook'|i18n}}</label>
							<div class="w-75-percent ph-16">
								<simple-dropdown
									[options]="availableDashboards"
									[(value)]="model.properties.favoriteDashboard"
									displayField="name"
									valueField="id"
									role="listbox"
									[attr.aria-label]="'common.favoriteDashboardOrBook'|i18n"
									class="br-dashboard-select">
								</simple-dropdown>
							</div>
						</div>
						<button type="button" class="btn btn-link" (click)="clearFavorites()">{{'common.clearDefaults'|i18n}}</button>
						<alert type="danger" *ngIf="showErrorForHomePage()">
							<span>{{ getErrorForHomePage() }}</span>
						</alert>
						<alert type="danger" *ngFor="let cperror of showErrorsForCP">
							<span>{{ cperror }}</span>
						</alert>
					</div>
				</ng-template>
			</li>

		</ul>
		<div [ngbNavOutlet]="nav" class="mt-2"></div>
		<div *ngIf="errorMessage" class="form-group">
			<div>{{errorMessage}}</div>
		</div>
	</form>
</div>
<div class="modal-footer">
	<button
		id="btn-preferences-cancel"
		type="button"
		class="btn btn-secondary"
		[i18n]="'common.cancel'"
		(click)="cancel()">
	</button>
	<button
		id="btn-preferences-save"
		type="button"
		class="btn btn-primary"
		[btnLoading]="saving"
		loadingText="{{'dashboard.saving'|i18n}}"
		[i18n]="'common.save'"
		(click)="save()"
		[disabled]="!isValid()">
	</button>
</div>
