import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { IgnoredDashboardFilterService } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import * as _ from 'underscore';
import DashboardFilterBuildersService from '@app/modules/dashboard-edit/services/dashboard-filter-builders.service';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { DashboardFilterValue } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-value';
import { DashboardFilterCategory } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-category.enum';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateRangeConstantService } from '@cxstudio/report-filters/constants/date-range-constant.service';

export class DashboardFiltersService {

	isTopic = DashboardFilterSelection.isTopic;
	isAttribute = DashboardFilterSelection.isAttribute;
	isText = DashboardFilterSelection.isText;
	isMetric = DashboardFilterSelection.isMetricFilter;
	isDateRange = DashboardFilterSelection.isDateRange;
	getFilterType = DashboardFilterSelection.getFilterType;

	constructor(
		private locale: ILocale,
		private DateRange: DateRangeConstantService,
		private dashboardFilterBuilders: DashboardFilterBuildersService,
		private currentWidgets: ICurrentWidgets,
		private ignoredDashboardFilterService: IgnoredDashboardFilterService
	) {}

	buildDashboardFilterRule = (dashboardFilter: DashboardFilter): IFilterRule => {
		let filterType = DashboardFilterSelection.getFilterType(dashboardFilter.selectedAttribute);
		let builder = this.dashboardFilterBuilders.getBuilder(filterType);
		return builder(dashboardFilter);
	};

	getDefaultFilterValue = (selectedItem: DashboardFilterSelection): DashboardFilterValue => {
		let defaultValue;
		let filterType = DashboardFilterSelection.getFilterType(selectedItem);

		if (filterType === DashboardFilterTypes.METRIC) {
			defaultValue = {} as DateFilter;
		}

		if (filterType === DashboardFilterTypes.DATE_RANGE) {
			defaultValue = {
				dateFilterMode: this.DateRange.defaultDateRange.value,
				dateFilterRange: {}
			} as DateFilter;
		}

		return defaultValue;
	};

	getFilterDisplayName = (filter: DashboardFilterSelection): string => {
		let result = '';
		if (filter) {
			let filterType = DashboardFilterSelection.getFilterType(filter);
			if (filterType === DashboardFilterTypes.DATE_RANGE) {
				result = this.locale.getString('filter.dateRange');
			} else {
				result = filter.displayName;
			}
		}
		return result;
	};

	isDashboardDateFilterPresent = (dashboard: Dashboard): boolean => {
		if (!dashboard || !dashboard.appliedFiltersArray) {
			return false;
		}
		let dashboardFilters = dashboard.appliedFiltersArray;
		let dateFilter = this.getDashboardDateFilter(dashboardFilters);
		return Boolean(dateFilter);
	};

	isDashboardDateFilterApplied = (dashboard: Dashboard): boolean => {
		return (this.checkDashboardFiltersApplicationConditions(dashboard)
				&& this.isDashboardDateFilterPresent(dashboard));
	};

	isSavedFilterApplied = (dashboard: Dashboard): boolean => {
		if (!dashboard || !dashboard.appliedFiltersArray) {
			return false;
		}
		return Boolean(this.getSavedFilter(dashboard.appliedFiltersArray));
	};

	getDashboardDateFilter = (appliedFiltersArray: DashboardFilter[]): DashboardFilter => {
		return _.find(appliedFiltersArray, filter => DashboardFilterSelection.isDateRange(filter?.selectedAttribute));
	};

	getSavedFilter = (appliedFiltersArray: DashboardFilter[]): DashboardFilter => {
		return _.find(appliedFiltersArray, filter => DashboardFilterSelection.isSavedFilter(filter?.selectedAttribute));
	};

	checkDashboardFiltersApplicationConditions = (dashboard: Dashboard): boolean => {
		if (dashboard && dashboard.properties && isTrue(dashboard.properties.isAttributeFiltersApplied)
				&& dashboard.appliedFiltersArray) {
			return true;
		}
		return false;
	};

	getActiveDateFilterMode = (widgetProperties: WidgetProperties,
			periodIndex: number, containerId?: string): DateFilterMode => {

		let dateFilterMode = widgetProperties['dateRangeP' + periodIndex]
			&& widgetProperties['dateRangeP' + periodIndex].dateFilterMode;
		let shouldIgnoreDashboardFilter =
				this.ignoredDashboardFilterService.hasDateTag(widgetProperties.ignoredDashboardFilters);

		if (periodIndex === 1 && !shouldIgnoreDashboardFilter) {
			let dashboardHistory = this.currentWidgets.getDashboardHistory(containerId);
			let dashboardDateFilter = this.getDashboardDateFilter(dashboardHistory.getAppliedFilters());
			if (dashboardDateFilter) {
				return dashboardDateFilter.selectedAttributeValue.dateFilterMode;
			}
		}
		return dateFilterMode;
	};

	private collectChildren = (filters: any[], filterFunction) => {
		let values = [];
		if (filters) {
			filters.forEach((value) => {
				if (value.children && value.children.length) {
					//Only want to add children that were selected
					values = _.union(values, _.filter(value.children, filterFunction));
				} else {
					if (filterFunction(value)) {
						values.push(value);
					}
				}
			});
		}
		return values;
	};

	getNestedSelectedChildren = (filter: any[]) => {
		let isSelected = (item) => {
			return item.selected === true;
		};
		return this.collectChildren(filter, isSelected);
	};

	getNestedMultiValueFilters = (filter) => {
		let noFilterFunction = () => {
			return true;
		};
		return this.collectChildren(filter, noFilterFunction);
	};

	displayNameCompareFunc = (query: string) => {
		return (child) => {
			return this.nameContainsIgnoreCase(child.displayName, query);
		};
	};

	nameContainsIgnoreCase = (name: string, query: string): boolean => {
		return name.toLowerCase().indexOf(query.toLowerCase()) > -1;
	};

	addHierarchyFilter = (dashboard: Dashboard): void => {
		if (!dashboard.appliedFiltersArray || dashboard.appliedFiltersArray.length === 0) {
			dashboard.appliedFiltersArray = [{isOrgHierarchy: true}];
		} else {
			if (this.getHierarchyFilter(dashboard.appliedFiltersArray).length === 0)
				dashboard.appliedFiltersArray.unshift({isOrgHierarchy: true});
		}
	};

	getHierarchyFilter = (dashboardFilters: DashboardFilter[]): DashboardFilter[] => {
		dashboardFilters = dashboardFilters || [];
		return dashboardFilters.filter(filter => filter.isOrgHierarchy);
	};

	getAttributeFilters = (dashboardFilters: DashboardFilter[]): DashboardFilter[] => {
		dashboardFilters = dashboardFilters || [];
		return dashboardFilters.filter(filter => !filter.isOrgHierarchy);
	};

	removeInvalidFilters = (dashboard: Dashboard) => {
		if (dashboard && dashboard.appliedFiltersArray) {
			dashboard.appliedFiltersArray = dashboard.appliedFiltersArray.filter(this.isValidFilter);
		}
	};

	isValidFilter = (filter: DashboardFilter): boolean => {
		return !!filter.selectedAttribute || filter.isOrgHierarchy || filter.isDrillToDashboardFilter;
	};

	isInvalidFilter = (filter: DashboardFilter): boolean => {
		return !filter.selectedAttribute && !filter.isOrgHierarchy && !filter.isDrillToDashboardFilter;
	};

	hasValue = (filter: DashboardFilter): boolean => {
		if (!_.isUndefined(filter.genericFilterFormat)) {
			if (filter.genericFilterFormat.type === FilterRuleType.numericOpenRange) {
				return !_.isUndefined(filter.genericFilterFormat.value);
			}

			if (filter.genericFilterFormat.type === FilterRuleType.numericRange) {
				return !_.isUndefined(filter.genericFilterFormat.from) && !_.isUndefined(filter.genericFilterFormat.to);
			}

			if (filter.genericFilterFormat.existMatch) {
				return true;
			}
		}

		if (_.isUndefined(filter.multiValues)) {
			return !_.isUndefined(filter.selectedAttributeValue);
		}

		return !isEmpty(filter.multiValues);
	};

	getVisibleFilters = (dashboardFilters: DashboardFilter[], hierarchyVisible: boolean) => {
		let result = dashboardFilters;
		if (!hierarchyVisible) {
			result = this.getAttributeFilters(dashboardFilters);
		}
		result = _.filter(result, this.notEmptyTextFilter);

		return result;
	};


	canChangeFilters = (dashboardFilters: DashboardFilter[], hierarchyVisible: boolean): boolean => {
		if (hierarchyVisible) {
			return dashboardFilters && dashboardFilters.length > 0;
		} else {
			return _.any(dashboardFilters, filter => !filter.isOrgHierarchy);
		}
	};

	canViewFilters = (dashboardFilters: DashboardFilter[], hierarchyVisible: boolean): boolean => {
		if (hierarchyVisible) {
			return dashboardFilters && dashboardFilters.length > 0;
		} else {
			return _.any(dashboardFilters, filter => !filter.isOrgHierarchy && this.notEmptyTextFilter(filter));
		}
	};

	private notEmptyTextFilter = (filter: DashboardFilter): boolean => {
		return !DashboardFilterSelection.isText(filter.selectedAttribute)
			|| !!filter.selectedAttributeValue;
	};

	getContainer = (): any => {
		let filterContainer = $('#dashboard-filters-container');
		return {
			element: filterContainer,
			top: filterContainer.offset().top,
			bottom: filterContainer.offset().top + filterContainer.height(),
			height: filterContainer.height(),
			scroll: {
				top: filterContainer.scrollTop()
			}
		};
	};

	getPointer = (): any => {
		let filterClone = $('#filter-clone');
		return {
			element: filterClone,
			top: filterClone.position().top
		};
	};

	getPlaceholder = (): any => {
		let filterPlaceholder = $('#filter-placeholder');
		return {
			height: filterPlaceholder.height()
		};
	};

	isNumericAttribute = (attribute: DashboardFilterSelection): boolean => {
		return attribute
			&& this.isAttribute(attribute)
			&& attribute.type === FilterAttributeTypes.NUMBER;
	};

	updateSavedFilterSettings = (filters: IFilter[], appliedFilters: DashboardFilter[]): DashboardFilter[] => {
		appliedFilters.forEach(appliedFilter => {
			let selectedAttribute = appliedFilter.selectedAttribute;
			if (selectedAttribute && appliedFilter.multiValues && DashboardFilterSelection.isSavedFilter(selectedAttribute)) {
				appliedFilter.multiValues.forEach(filterValue => {
					let filter = _.findWhere(filters, { id: filterValue.object.id });
					if (filter) {
						filterValue.displayName = filter.name;
						filterValue.object.displayName = filter.name;
					}
				});
			}
		});
		return appliedFilters;
	};

	areFiltersIdentical(filters1: DashboardFilter[], filters2: DashboardFilter[]): boolean {
		if (!filters1 || !filters2)
			return !filters1 && !filters2;
		if (filters1?.length !== filters2?.length)
			return false;
		for (let i = 0; i < filters1.length; i++) {
			if (!this.areFiltersEqual(filters1[i], filters2[i])) {
				return false;
			}
		}
		return true;
	}

	private areFiltersEqual(first: DashboardFilter, second: DashboardFilter): boolean {
		const type = this.getDashboardFilterCategory(first);
		if (type !== this.getDashboardFilterCategory(second)) {
			return false;
		}
		switch (type) {
			case DashboardFilterCategory.HIERARCHY: return first.node === second.node;
			case DashboardFilterCategory.TEXT: return true;
			case DashboardFilterCategory.DASHBOARD_DRILL: return _.isEqual(first.drillableFilter, second.drillableFilter);
			case DashboardFilterCategory.DATE_RANGE: {
				return _.isEqual(first.selectedAttributeValue.dateFilterMode, second.selectedAttributeValue.dateFilterMode)
					&& _.isEqual(first.selectedAttributeValue.dateFilterRange, second.selectedAttributeValue.dateFilterRange);
			}
			case DashboardFilterCategory.SAVED_FILTER: {
				// name is unique identifier
				return _.isEqual(
					first.multiValues?.map(value => value?.name),
					second.multiValues?.map(value => value?.name));
			}
			case DashboardFilterCategory.ATTRIBUTE: {
				// generic filter works for numeric ranges,
				// but for text it's not updated properly, so need to check multivalues as well
				const genericFilterEquals = _.isEqual(first.genericFilterFormat, second.genericFilterFormat);

				const attributeFields = ['name', 'matchMode', 'existMatch'];
				const regularFilterEquals = _.isEqual(
						_.pick(first.selectedAttribute, attributeFields),
						_.pick(second.selectedAttribute, attributeFields))
					&& _.isEqual(first.multiValues?.map(value => value?.name),
						second.multiValues?.map(value => value?.name));
				return genericFilterEquals && regularFilterEquals;
			}
			case DashboardFilterCategory.TOPIC: {
				// can't use genericFilterFormat as it has bad values for "modelMatch" case
				const modelFields = ['name', 'matchMode', 'modelMatch'];
				return _.isEqual(
						_.pick(first.selectedAttribute, modelFields),
						_.pick(second.selectedAttribute, modelFields))
					&& _.isEqual(first.selectedAttributeValue, second.selectedAttributeValue);
			}
			// fallback just in case of some legacy, but normally shouldn't reach here
			default: return _.isEqual(first, second);
		}
	}

	private getDashboardFilterCategory(filter: DashboardFilter): DashboardFilterCategory {
		if (filter.isOrgHierarchy) {
			return DashboardFilterCategory.HIERARCHY;
		}
		if (filter.isDrillToDashboardFilter) {
			return DashboardFilterCategory.DASHBOARD_DRILL;
		}
		if (DashboardFilterSelection.isText(filter.selectedAttribute)) {
			return DashboardFilterCategory.TEXT;
		}
		if (DashboardFilterSelection.isDateRange(filter.selectedAttribute)) {
			return DashboardFilterCategory.DATE_RANGE;
		}
		if (DashboardFilterSelection.isSavedFilter(filter.selectedAttribute)) {
			return DashboardFilterCategory.SAVED_FILTER;
		}
		if (DashboardFilterSelection.isAttribute(filter.selectedAttribute)) {
			return DashboardFilterCategory.ATTRIBUTE;
		}
		if (DashboardFilterSelection.isTopic(filter.selectedAttribute)) {
			return DashboardFilterCategory.TOPIC;
		}
		return undefined;
	}
}

app.service('dashboardFiltersService', DashboardFiltersService);
