import ILocale from '@cxstudio/interfaces/locale-interface';
import { ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';


type TopicPredicate = (item?: ModelTreeNode) => boolean;
interface TreeSelectionMode {
	name?: string;
	predicate: TopicPredicate;
}

export class TopicSelectionComponentController implements ng.IComponentController {

	selectedModel: any;
	currentModelTree: SimpleTopicSelection;
	topicSelectionModes: TreeSelectionMode[];

	searchObject: {hierarchySearch?: string};
	selectAll: TopicPredicate;
	selectNone: TopicPredicate;

	constructor(
		private $scope: ISimpleScope,
		private locale: ILocale
	) {}

	$onInit = (): void => {
		this.searchObject = {};

		this.$scope.$watch('$ctrl.currentModelTree', () => {
			if (this.currentModelTree) {
				this.initTopicSelectionModes(this.currentModelTree.getRoot());
			}
		});
	};

	private initTopicSelectionModes(root: ModelTreeNode): void {
		let leaves = HierarchyUtils.findItems(root, item => !item.children);
		let maxLevelNode = _.max(leaves, (leaf: ModelTreeNode) => leaf.level);
		let levelModes = _.range(1, (maxLevelNode as ModelTreeNode).level + 1).map(level => {
			return {id: level, name: '' + level, predicate: item => item.level === level};
		});
		let leafMode = {id: 100, name: this.locale.getString('widget.leaf'), predicate: item => !item.children};
		this.selectAll = item => item.id !== root.id;
		this.selectNone = () => false;

		this.topicSelectionModes = [];
		this.topicSelectionModes.pushAll(levelModes);
		this.topicSelectionModes.push(leafMode);
	}

	applyTopicSelection = (topicPredicate: TopicPredicate): void => {
		if (topicPredicate && this.currentModelTree) {
			let root = this.currentModelTree.getRoot();
			let selectedItems = HierarchyUtils.findItems(root, topicPredicate);
			let selectedIds = _.pluck(selectedItems, 'id');
			this.currentModelTree.setSelectedIds(selectedIds);
			this.updateModelNodeIds();
		}

		if (topicPredicate === this.selectAll || topicPredicate === this.selectNone) {
			this.clearTopicLevelSelection();
		}
	};

	topicLevelChanged = (levelSelection: any): void => {
		if (levelSelection && this.currentModelTree) {
			this.applyTopicSelection(levelSelection.predicate);
		}
	};

	handleTopicClick = (node: ModelTreeNode): void => {
		this.currentModelTree.handleNodeClick(node);
		this.updateModelNodeIds();

		this.clearTopicLevelSelection();
	};

	clearTopicLevelSelection = (): void => {
		delete this.selectedModel.topicLevel;
	};

	private updateModelNodeIds(): void {
		this.selectedModel.nodeIds = angular.copy(this.currentModelTree.getSelectedIds());
	}
}

app.component('topicSelection', {
	controller: TopicSelectionComponentController,
	templateUrl: 'partials/components/topic-selection.component.html',
	bindings: {
		selectedModel: '=',
		currentModelTree: '<'
	}
});
