import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'engagor-settings-integration',
	templateUrl: './engagor-settings-integration.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`.rounded-grouping-label { top: -20px; }`]
})
export class EngagorSettingsIntegrationComponent extends SelfCleaningComponent implements OnInit {

	@Input('masterAccountId') masterAccountId: number;

	hierarchies: any[];
	engageApplications: ExternalApplication[];
	loadingPromise: Promise<void>;
	engagorSettings: UntypedFormGroup;

	constructor(readonly formUtils: CxFormUtils, private fb: UntypedFormBuilder,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
		private externalApplicationsApiService: ExternalApplicationsApiService
	) {
		super();
		this.engagorSettings = this.fb.group({
			engagorEnabled: [ false ],
			accountId: [{ value: null, disabled: true }, [ Validators.required ]],
			topicId: [{ value: null, disabled: true }, [ Validators.required ]],
			applicationId: [{ value: null, disabled: true }, [ Validators.required ]],
			selectedHierarchy: this.fb.group({
				id: [{ value: null, disabled: true }],
				name: [],
			}),
			externalEngagementEnabled: [{ value: false, disabled: true }],
			redirectToEngageLandingPage: [{ value: false, disabled: true }],
		});

		this.addSubscription(this.engagorSettings.get('engagorEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.accountId, this.topicId, this.applicationId,
					this.selectedHierarchyId, this.externalEngagementEnabled, this.redirectToEngageLandingPage);
				this.engagorSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.accountId, this.topicId, this.applicationId,
					this.selectedHierarchyId, this.externalEngagementEnabled, this.redirectToEngageLandingPage);
				this.engagorSettings.updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		let organizationsPromise = this.masterAccountId
			? Promise.resolve(this.systemAdminApiService.getOrganizationList(this.masterAccountId))
			: Promise.resolve([]);
		this.loadingPromise = Promise.all([
			organizationsPromise,
			this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.ENGAGE)
		]).then(results => {
			this.hierarchies = results[0];
			this.engageApplications = results[1];
		});
	}

	selectHierarchy = (hierarchy): void => {
		if (!hierarchy) {
			this.selectedHierarchyName.setValue(null);
		} else {
			this.selectedHierarchyName.setValue(hierarchy.name);
		}
	};

	getGroup(): UntypedFormGroup {
		return this.engagorSettings;
	}

	get accountId(): UntypedFormControl {
		return this.engagorSettings.controls.accountId as UntypedFormControl;
	}

	get topicId(): UntypedFormControl {
		return this.engagorSettings.controls.topicId as UntypedFormControl;
	}

	get applicationId(): UntypedFormControl {
		return this.engagorSettings.controls.applicationId as UntypedFormControl;
	}

	get selectedHierarchyId(): UntypedFormControl {
		return this.engagorSettings.get('selectedHierarchy.id') as UntypedFormControl;
	}

	get selectedHierarchyName(): UntypedFormControl {
		return this.engagorSettings.get('selectedHierarchy.name') as UntypedFormControl;
	}

	get externalEngagementEnabled(): UntypedFormControl {
		return this.engagorSettings.controls.externalEngagementEnabled as UntypedFormControl;
	}

	get redirectToEngageLandingPage(): UntypedFormControl {
		return this.engagorSettings.controls.redirectToEngageLandingPage as UntypedFormControl;
	}
}
