/* eslint-disable @typescript-eslint/indent */
import { Pipe, PipeTransform } from '@angular/core';
import { Contract } from '@cxstudio/master-accounts/contracts/contract.class';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';

@Pipe({
    name: 'licenseHasContractWithSeats'
})
export class LicenseHasContractWithSeats implements PipeTransform {
    transform(license: LicenseTypeItem, contracts: Contract[]): any {
        return _.filter(contracts, (contract) => {
            return contract.licenses[license.licenseTypeId] > 0;
        }).length;
    }
}