
import ILocale from '@cxstudio/interfaces/locale-interface';


export enum AlertTriggerCriteria {
	LESS_THAN_OR_EQUAL = 'LESS_THAN_OR_EQUAL',
	GREATER_THAN_OR_EQUAL = 'GREATER_THAN_OR_EQUAL',
	INCREASE_GREATER_OR_EQUAL = 'INCREASE_GREATER_OR_EQUAL',
	DECREASE_GREATER_OR_EQUAL = 'DECREASE_GREATER_OR_EQUAL',
	CHANGE_GREATER_OR_EQUAL = 'CHANGE_GREATER_OR_EQUAL',
	SIGNIFICANT_CHANGE = 'SIGNIFICANT_CHANGE'
}

export interface CriteriaOption {
	displayName: string;
	value: AlertTriggerCriteria;
}

export class AlertTriggerCriteriaOptions {

	readonly BASIC_CRITERIA = [
		AlertTriggerCriteria.LESS_THAN_OR_EQUAL,
		AlertTriggerCriteria.GREATER_THAN_OR_EQUAL,
		AlertTriggerCriteria.INCREASE_GREATER_OR_EQUAL,
		AlertTriggerCriteria.DECREASE_GREATER_OR_EQUAL,
		AlertTriggerCriteria.CHANGE_GREATER_OR_EQUAL
	];

	constructor(private locale: ILocale) {}

	getOption = (criterion: AlertTriggerCriteria): CriteriaOption => {
		return {
			displayName: this.locale.getString(`alert.${criterion}`),
			value: criterion
		};
	};

	getOptions = (criteria: AlertTriggerCriteria[]): CriteriaOption[] => {
		return criteria.map(criterion => this.getOption(criterion));
	};

	getBasicOptions = (): CriteriaOption[] => {
		return this.getOptions(this.BASIC_CRITERIA);
	};
}

app.service('alertTriggerCriteriaOptions', AlertTriggerCriteriaOptions);
