import { Injectable, Inject } from '@angular/core';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';

@Injectable({
	providedIn: 'root'
})
export class WidgetDashboardHierarchyFiltersService {

	constructor(
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
		private organizationApiService: OrganizationApiService,
	) {}

	getPeerReportingMetadata(
		properties: WidgetProperties, filtersProvider: IDashboardHistoryInstance
	): Promise<{ignorePersonalization?: boolean; organizationFilterNode?: any}> {
		let personalization = filtersProvider?.getPersonalization();
		if (!personalization) {
			return Promise.resolve({});
		}

		let nodeId = personalization.currentHierarchyNode && personalization.currentHierarchyNode.id;
		let hierarchyId = personalization.getHierarchyId();

		let hierarchyGroup = this.hierarchyService.findSingleHierarchyGrouping(properties);

		if (!hierarchyGroup || parseInt(hierarchyGroup.name, 10) !== hierarchyId) {
			return  Promise.resolve({});
		}

		if (hierarchyGroup.peerReportType === PeerReportType.PARENT_PEER_REPORT) {
			return  Promise.resolve({ignorePersonalization: true});
		}

		if (hierarchyGroup.peerReportType === PeerReportType.INHERITED_REPORT) {
				return  Promise.resolve({
					ignorePersonalization: false
				}
			);
		}

		return this.organizationApiService.getPeerReportNode(hierarchyId, nodeId).then(nodeData => {
			return {
				ignorePersonalization: false,
				organizationFilterNode: nodeData.present ? nodeData.node : undefined
			};
		});
	}
}
