import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateNativeUTCAdapter, NgbInputDatepicker } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'date-picker-popup',
	templateUrl: './date-picker-popup.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})

export class DatePickerPopupComponent {
	@Input() value: Date;
	@Input() minDate?: Date;
	@Input() maxDate?: Date;
	@Output() valueChange = new EventEmitter<Date>();
	@Output() onChange = new EventEmitter<void>();
	@ViewChild('datepicker') date: NgbInputDatepicker;

	constructor() {}

	onValueChange = (): void => {
		this.valueChange.emit(this.value);
		this.onChange.emit();
	};

}