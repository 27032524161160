import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';


@Component({
	selector: 'dashboard-tooltip',
	template: `
<div role="tooltip" class="dashboard-tooltip"
	[ngStyle]="tooltipStyle"
	[style.top.px]="tooltipStyle.top"
	[style.left.px]="tooltipStyle.left">
	<ng-content></ng-content>
</div>`,
	styles: [`
::ng-deep .dashboard-tooltip > * {
	display: flex;
	justify-content: center;
}`]
})

export class DashboardTooltipComponent {
	@Input() tooltipStyle: {
		top: number;
		left: number;
		visibility: string;
		opacity: number;
	};
}

app.directive('dashboardTooltip', downgradeComponent({component: DashboardTooltipComponent}));