import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewVerbatim } from '@cxstudio/reports/document-explorer/preview-verbatim';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { AnalyticFeedbackSelectionBase } from '@cxstudio/reports/preview/analytic-feedback-selection-base.class';
import { AnalyticFeedbackSelectionUtils } from '@cxstudio/reports/preview/analytic-feedback-selection-utils.service';
import { CuratedItem, CuratedItemType } from '@cxstudio/reports/preview/curated-item';
import { PreviewColumn, PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { ReportScope, ReportScopeView } from '@cxstudio/reports/report-scope';
import * as _ from 'underscore';

export class PreviewAnalyticFeedbackSelection extends AnalyticFeedbackSelectionBase {
	constructor(
		private $scope: ReportScope,
		private previewPredefinedColumns: PreviewPredefinedColumns,
		widgetsEditService: WidgetsEditService,
		locale: ILocale) {
		super(locale, widgetsEditService, () => {
			if (PreviewMode.DOCUMENT === $scope.widget.properties.previewMode) {
				$scope.$broadcast('DocumentPreviewRefresh', { fullReload: true });
			} else {
				$scope.state.loading = $scope.reloadUtils($scope.widget).then(() => {
					$scope.runReportPaginated({
						widget: $scope.widget,
						utils: $scope.utils
					});
				});
			}
		});
	}

	protected enableVisually = (): void => {
		if (!this.isPaneMode()) {
			this.addFeedbackSelectionColumn();
		}
	};

	private addFeedbackSelectionColumn = (): void => {
		if (_.findWhere(this.getWidget().visualProperties.columns, { name: PreviewColumn.FEEDBACK_SELECTION }))
			return;

		let feedbackSelectionColumn = this.previewPredefinedColumns.get(PreviewColumn.FEEDBACK_SELECTION);
		this.getWidget().visualProperties.columns.splice(0, 0, feedbackSelectionColumn);
	};

	protected disableVisually = (): void => {
		if (!this.isPaneMode()) {
			this.removeFeedbackSelectionColumn();
		}
	};

	private removeFeedbackSelectionColumn = (): void => {
		this.getWidget().visualProperties.columns = _.reject(this.getWidget().visualProperties.columns,
			(column) => _.isMatch(column, { name: PreviewColumn.FEEDBACK_SELECTION }));
	};

	private isPaneMode = (): boolean => {
		return this.getWidgetProperties().previewMode === PreviewMode.DOCUMENT;
	};

	private getView = (): ReportScopeView => {
		return this.$scope.state.view;
	};

	protected applyVisualChanges = (): void => {
		if (!this.isPaneMode()) {
			this.getView().trigger++;
		}
	};

	protected getCuratedItemType = (): CuratedItemType => {
		return AnalyticFeedbackSelectionUtils.getCuratedItemType(this.getWidget());
	};

	protected getWidget = (): Widget => {
		return this.$scope.widget;
	};

	protected convertToCuratedItem = (previewItem: PreviewSentence | PreviewVerbatim | PreviewDocument): CuratedItem => {
		let curatedItemType = this.getCuratedItemType();
		let curatedItem: CuratedItem = { type: curatedItemType };

		if (curatedItemType === CuratedItemType.DOCUMENT) {
			let document = previewItem as PreviewDocument;
			curatedItem.documentId = document.id;
		} else if (curatedItemType === CuratedItemType.VERBATIM) {
			if (this.isPaneMode()) {
				let verbatim = previewItem as PreviewVerbatim;
				curatedItem.verbatimId = verbatim.id;
				curatedItem.documentId = verbatim.documentId;
			} else {
				let sentence = previewItem as PreviewSentence;
				curatedItem.verbatimId = sentence.verbatimId;
				curatedItem.documentId = sentence.documentId;
			}
		} else {
			let sentence = previewItem as PreviewSentence;
			curatedItem.sentenceId = sentence.id;
			curatedItem.verbatimId = sentence.verbatimId;
			curatedItem.documentId = sentence.documentId;
		}

		return curatedItem;
	};

	editFeedbackSelection = () => {
		this.enable();
		this.$scope.$broadcast('feedbackSelection:editStarted');
	};

	saveFeedbackSelection = (): void => {
		this.submit();
		this.$scope.$broadcast('feedbackSelection:editFinished');
	};

	cancelFeedbackSelection = (): void => {
		this.cancel();
		this.$scope.$broadcast('feedbackSelection:editFinished');
	};

}
