<div class="row mb-16 d-flex flex-wrap align-items-center">

	<div class="col-lg-2 col-xl-1 btn-group p-0 pl-16 mb-8">
		<button *ngFor="let type of types"
			class="btn br-axis-btn col-xs-12 col-md-6 text-ellipsis"
			[ngClass]="{'btn-selected': plotLine.timeReferenceType === type.name}"
			(click)="changeType(type.name)"
			[disabled]="editingForbidden()"
			title="{{type.displayName}}">
			{{type.displayName}}
		</button>
	</div>

	<div class="col-sm-2 p-0 pl-16 mb-8">
		<input class="form-control"
			type="text"
			[(ngModel)]="plotLine.label"
			placeholder="{{'common.name'|i18n}}"
			[disabled]="editingForbidden()">
	</div>

	<div *ngIf="isEventType()" class="col-sm-2 p-0 pl-16 mb-8">
		<plot-line-dropdown
			[plotLine]="plotLine"
			[isDisabled]="editingForbidden()"
			dropdownDirection="left">
		</plot-line-dropdown>
	</div>

	<div *ngIf="isSpanType()" class="col-sm-2 p-0 pl-16 mb-8">
		<color-input [(ngModel)]="plotLine.color" [disabled]="editingForbidden()">
		</color-input>
	</div>

	<div *ngIf="isEventType()" class="p-0 pl-16 mb-8">
		<div class="cx-date-range-selector v-middle">
			<date-point class="cx-date-range-from"
				adjustTime="end" [autoClose]="true" [hideTime]="true"
				[modes]="pointMode" [pointMode]="pointMode" [selectorHidden]="true"
				[pointValue]="datePointValue" [datepickerOptions]="datePickerOptions"
				[disableDate]="editingForbidden()">
			</date-point>
		</div>
	</div>

	<div *ngIf="isSpanType()" class="p-0 pl-16 mb-8">
		<date-range
			[exactOnly]="true"
			[(dateRange)]="dateRange"
			[hideTime]="hideTime"
			[disableDates]="editingForbidden()">
		</date-range>
	</div>

	<div class="p-0 pl-16 mb-8 d-flex flex-nowrap">
		<button *ngIf="showAddButton()" class="btn btn-primary add-plotline"
			(click)="addPlotLine()">{{'common.add'|i18n}}</button>
		<button *ngIf="showUpdateButton()" class="btn btn-primary btn-icon update-plotline"
			(click)="updatePlotLine()" title="{{'common.apply'|i18n}}">
			<span class="q-icon q-icon-check" aria-hidden="true"></span>
		</button>
		<button *ngIf="showEditButton()" class="btn btn-primary btn-icon edit-plotline"
			(click)="editPlotLine()" title="{{'common.edit'|i18n}}">
			<span class="q-icon q-icon-edit" aria-hidden="true"></span>
		</button>
		<button *ngIf="showRemoveButton()" class="btn btn-secondary btn-icon delete-plotline"
			(click)="removePlotLine()" title="{{'common.delete'|i18n}}">
			<span class="q-icon q-icon-trash" aria-hidden="true"></span>
		</button>
		<button *ngIf="showClearButton()" class="btn btn-secondary btn-icon revert-plotline"
			(click)="clearChanges()" title="{{'common.cancel'|i18n}}">×</button>
	</div>
</div>
