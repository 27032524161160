import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import GeographyDataItem from '@cxstudio/attribute-geography/geography-data-item';
import { MapBackground } from '@cxstudio/attribute-geography/map-background';
import { BoundaryType } from '@cxstudio/attribute-geography/boundary-type';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MapboxBuilderOptions } from '@app/modules/reports/visualizations/definitions/mapbox/mapbox-builder.options';
import { MapBounds } from '@app/modules/reports/visualizations/definitions/mapbox/map.bounds';
import { MapboxUtilsService } from '@app/modules/reports/visualizations/definitions/mapbox/mapbox-utils.service';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';

@Injectable({
	providedIn: 'root'
})
export class MapboxBuilderService {
	static MAPBOX_SOURCE = 'source';
	static MAPBOX_LAYER = 'layer';
	static BOUNDARY_LAYER = 'waterway-label';

	static FEATURE_COLOR_PROPERTY = 'color';
	static FEATURE_PATTERN_PROPERTY = 'pattern';
	static FEATURE_OPACITY_PROPERTY = 'opacity';
	static ORIGINAL_FEATURE_COLOR_PROPERTY = 'originalColor';
	static ORIGINAL_FEATURE_OPACITY_PROPERTY = 'originalOpacity';

	static DEFAULT_OPACITY = 0.75;
	static HIGHLIGHTED_OPACITY = 1;
	static UNHIGHLIGHTED_OPACITY = .3;
	static DEFAULT_BOUNDS_PADDING = 5;

	static FEATURE_DATA_PROPERTY = 'data';

	static COUNTRY_MAP_BOUNDS: MapBounds = { west: -160, south: -50, east: 180, north: 70 };
	static STATE_MAP_BOUNDS: MapBounds = { west: -170, south: 10, east: 45, north: 75 };
	static US_COUNTY_MAP_BOUNDS: MapBounds = { west: -130, south: 25, east: -60, north: 50 };

	constructor(
		private readonly mapboxUtils: MapboxUtilsService,
		@Inject('security') private readonly security: Security,
		private readonly realDataPreviewService: RealDataPreviewService,
	) {}

	public buildMap = (options: MapboxBuilderOptions): any => {
		const tilesetName = options.dataObject.metadata.polyTilesetName;
		const sourceLayerName = options.dataObject.metadata.polyLayerName;
		if (!tilesetName || !sourceLayerName) {
			return;
		}

		mapboxgl.accessToken = CONFIG.mapboxToken;
		mapboxgl.workerUrl = CONFIG.mapboxWorkerUrl;
		const boundsValues = this.calculateBounds(options);
		const bounds = [[boundsValues.west, boundsValues.south], [boundsValues.east, boundsValues.north]];
		// fit whole map in viewport to calculate bounds
		const abnormalRatio = !options.isDemo || this.realDataPreviewService.showRealDataPreview(options.widgetType)
			? this.adjustViewportRatio(options.uniqueId)
			: false;

		const map = new mapboxgl.Map({
			container: options.uniqueId,
			bounds
		});

		if (!options.showLabels) {
			map.on('styledata', (e: any) => {
				this.removeLabelLayers(e, map);
			});
		}

		options.dataObject.data.forEach(dataItem => {
			dataItem._highlightPoint = () => this.highlightDataItem(map, options, dataItem);
			dataItem._unhighlightPoint = () => this.resetSelections(map, options);
		});

		map.setStyle(this.getMapboxStyle(options));

		const patternsPromise = this.initPatterns(options).then((images) =>
			Promise.all(
				images.map(img => new Promise((resolve, reject) => {
					map.loadImage(img.url, (error: any, res: any) => {
						map.addImage(img.id, res);
						resolve(undefined);
					});
				}))
			)
		);

		map.on('load', () => patternsPromise.then(() => {
			map.addSource(MapboxBuilderService.MAPBOX_SOURCE, {
				type: 'vector',
				url: `mapbox://${tilesetName}`
			} as any);

			const paint = {
				'fill-color': [
					'coalesce',
					[ 'feature-state', MapboxBuilderService.FEATURE_COLOR_PROPERTY ],
					'rgba(255, 255, 255, 0)'
				],
				'fill-opacity': [
					'coalesce',
					[ 'feature-state', MapboxBuilderService.FEATURE_OPACITY_PROPERTY ],
					0
				]
			};
			if (this.usePatternFills()) {
				paint['fill-pattern'] = [ 'feature-state', MapboxBuilderService.FEATURE_PATTERN_PROPERTY ];
				this.addPatternLayers(map, sourceLayerName, options.patternLayers);
			}

			map.addLayer({
				id: MapboxBuilderService.MAPBOX_LAYER,
				type: 'fill',
				source: MapboxBuilderService.MAPBOX_SOURCE,
				'source-layer': sourceLayerName,
				paint
			}, MapboxBuilderService.BOUNDARY_LAYER);

			const setAfterLoad = (e): void => {
				if (e.sourceId === MapboxBuilderService.MAPBOX_SOURCE && e.isSourceLoaded) {
					this.setData(map, options, sourceLayerName);
					map.off('sourcedata', setAfterLoad);
				}
			};

			if (map.isSourceLoaded(MapboxBuilderService.MAPBOX_SOURCE)) {
				this.setData(map, options, sourceLayerName);
			} else {
				map.on('sourcedata', setAfterLoad);
			}

			map.on('click', MapboxBuilderService.MAPBOX_LAYER, (e: any) => {
				options.onClick(e.originalEvent);
			});

			map.on('contextmenu', (e: any) => {
				options.onContextMenu(e.originalEvent);
			});

			map.on('mousemove', MapboxBuilderService.MAPBOX_LAYER, (e: any) => {
				const dataItem = this.getEventDataItem(e);
				options.onChangeActiveDataItem(dataItem);
				map.getCanvas().style.cursor = dataItem ? 'pointer' : '';
			});

			const applyBounds = () => {
				map.__initialBoundsAreSet = true;

				setTimeout(
					() => {
						map.fitBounds(map.__customBounds || bounds, { padding: MapboxBuilderService.DEFAULT_BOUNDS_PADDING });
						options.onBoundsSet();
					}
				);
			};

			const handleRenderAbnormalRatio = () => {
				if (!map.__customBoundsCalculated) { // calculate bounds when whole map is visible in viewport
					if (this.calculateCustomBounds(options, map)) {
						this.restoreViewportRatio(options.uniqueId);
					} else {
						options.onBoundsSet();
					}
				} else if (this.isOriginalRatio(options.uniqueId)) { // apply bounds when map is original size
					applyBounds();
				}
			};

			const handleRender = () => {
				if (!map.__customBoundsCalculated) {
					if (this.calculateCustomBounds(options, map)) {
						applyBounds();
					} else {
						options.onBoundsSet();
					}
				}
			};

			map.on('idle', () => {
				const realDataPreview: boolean = this.realDataPreviewService.showRealDataPreview(options.widgetType);
				if ((!options.isDemo || realDataPreview) && !map.__initialBoundsAreSet && map.__dataIsSet) {
					if (abnormalRatio) {
						handleRenderAbnormalRatio();
					} else {
						handleRender();
					}
				}
			});
		}));

		return map;
	};

	private usePatternFills = (): boolean => {
		return this.security.loggedUser.patternFills;
	};

	private initPatterns = (options: MapboxBuilderOptions): Promise<any[]> => {
		if (!this.usePatternFills()) {
			return Promise.resolve([]);
		}
		const patterns = [];
		options.patternLayers = [];
		options.dataObject.data.forEach((dataItem: any) => {
			if (dataItem.__featureId) {
				patterns.push(dataItem.color.pattern);
				const id = this.mapboxUtils.getPatternId(dataItem.color.pattern);
				const layer = _.findWhere(options.patternLayers, { id });
				if (layer) {
					layer.features.push(dataItem.__featureId);
				} else {
					options.patternLayers.push({ id, features: [dataItem.__featureId] });
				}
			}
		});
		return this.mapboxUtils.getPatternImages(_.uniq(patterns, (p) => this.mapboxUtils.getPatternId(p)));
	};

	private addPatternLayers = (map: any, sourceLayerName: any, layers: any): void => {
		layers.forEach((layer: any) => {
			map.addLayer({
				id: layer.id,
				type: 'fill',
				source: MapboxBuilderService.MAPBOX_SOURCE,
				'source-layer': sourceLayerName,
				filter: [ 'in', ['id'], ['literal', layer.features] ],
				paint: {
					'fill-opacity': [
						'coalesce',
						[ 'feature-state', MapboxBuilderService.FEATURE_OPACITY_PROPERTY ],
						0
					],
					'fill-pattern': layer.id
				}
			}, MapboxBuilderService.BOUNDARY_LAYER);
		});
	};

	private restoreViewportRatio = (uniqueId: string) => {
		$(`#${uniqueId}`).attr('style', '');
	};

	private isOriginalRatio = (uniqueId: string): boolean => {
		return !$(`#${uniqueId}`).attr('style');
	};

	private adjustViewportRatio = (uniqueId: string): boolean => {
		const el = $(`#${uniqueId}`);
		const width = el.width();
		const height = el.height();
		if (width / height < 2 || !this.isOriginalRatio(uniqueId)) {
			el.attr('style', `height: ${Math.floor(width / 2)}px !important`);
			return true;
		}
		return false;
	};

	// should only apply once filled layer is rendered
	private calculateCustomBounds = (options: MapboxBuilderOptions, map: any): boolean => {
		const layerFeatures = map.queryRenderedFeatures({ layers: [MapboxBuilderService.MAPBOX_LAYER] });
		const validFeatures = options.dataObject.data.filter(item => !!item.__featureId);
		const dataFeatureIds = options.dataObject.data.map(item => item.__featureId);
		const renderedFeatures = layerFeatures
			.filter((feature: any) => dataFeatureIds.contains(feature.id))
			.filter((feature: any) => !!feature.geometry);

		const mapBounds = this.mapboxUtils.getMapBounds(renderedFeatures, validFeatures);
		map.__customBoundsCalculated = true;
		if (mapBounds) {
			map.__customBounds = [
				[mapBounds.sw.longitude, mapBounds.sw.latitude],
				[mapBounds.ne.longitude, mapBounds.ne.latitude]
			];
		}
		return map.__customBoundsCalculated;
	};

	private setData = (map: any, options: MapboxBuilderOptions, sourceLayerName: string): void => {
		options.dataObject.data.forEach((dataItem: any, index: number) => {
			if (dataItem.__featureId) {
				const color = dataItem.color.pattern
					? dataItem.color.pattern.color
					: dataItem.color;
				const opacity = MapboxBuilderService.DEFAULT_OPACITY;
				const state = {
					[ MapboxBuilderService.FEATURE_COLOR_PROPERTY ]: color,
					[ MapboxBuilderService.FEATURE_OPACITY_PROPERTY ]: opacity,
					[ MapboxBuilderService.ORIGINAL_FEATURE_COLOR_PROPERTY ]: color,
					[ MapboxBuilderService.ORIGINAL_FEATURE_OPACITY_PROPERTY ]: opacity,
					[ MapboxBuilderService.FEATURE_DATA_PROPERTY ]: dataItem
				};

				if (this.usePatternFills() && dataItem.color.pattern) {
					state[MapboxBuilderService.FEATURE_PATTERN_PROPERTY] = this.mapboxUtils.getPatternId(dataItem.color.pattern);
				}

				map.setFeatureState({
					source: MapboxBuilderService.MAPBOX_SOURCE,
					sourceLayer: sourceLayerName,
					id: dataItem.__featureId
				}, state);
			}
		});
		options.onRender();
		map.__dataIsSet = true;
	};

	private highlightDataItem = (map: any, options: MapboxBuilderOptions, highlightedItem: GeographyDataItem): void => {
		options.dataObject.data.forEach((dataItem: GeographyDataItem) => {
			if (dataItem.__featureId) {
				const opacity = highlightedItem.__featureId === dataItem.__featureId
					? MapboxBuilderService.HIGHLIGHTED_OPACITY
					: MapboxBuilderService.UNHIGHLIGHTED_OPACITY;

				this.updateFeatureState(map, {
					source: MapboxBuilderService.MAPBOX_SOURCE,
					sourceLayer: options.dataObject.metadata.polyLayerName,
					id: dataItem.__featureId
				}, {
					opacity
				});
			}
		});
	};

	private resetSelections = (map: any, options: MapboxBuilderOptions): void => {
		options.dataObject.data.forEach((dataItem: GeographyDataItem) => {
			if (dataItem.__featureId) {
				this.updateFeatureState(map, {
					source: MapboxBuilderService.MAPBOX_SOURCE,
					sourceLayer: options.dataObject.metadata.polyLayerName,
					id: dataItem.__featureId
				}, {
					opacity: MapboxBuilderService.DEFAULT_OPACITY
				});
			}
		});
	};

	private updateFeatureState = (map: any, featureStateLocator: any, update: any): void => {
		const featureState = map.getFeatureState(featureStateLocator);
		_.extend(featureState, update);
		map.setFeatureState(featureStateLocator, featureState);
	};

	private getEventDataItem = (event: any): GeographyDataItem => {
		return event.features && event.features.length > 0 && event.features[0].state[MapboxBuilderService.FEATURE_DATA_PROPERTY];
	};

	private removeLabelLayers = (e: any, map: any): void => {
		e.style.stylesheet.layers.forEach((layer: any) => {
			if (layer.type === 'symbol' && layer.id !== MapboxBuilderService.BOUNDARY_LAYER && map.getLayer(layer.id)) {
				map.removeLayer(layer.id);
			}
		});
	};

	private getMapboxStyle = (options: MapboxBuilderOptions): string => {
		const background = options.background;
		if (background === MapBackground.BASIC) {
			return 'mapbox://styles/mapbox/light-v10';
		}

		if (background === MapBackground.STREET) {
			return 'mapbox://styles/mapbox/streets-v11';
		}

		if (background === MapBackground.SATELLITE) {
			return 'mapbox://styles/mapbox/satellite-streets-v11';
		}

		return options.isDarkMode
			? 'mapbox://styles/clarabridge/ckfy16de402cc1arws94z2826'
			: 'mapbox://styles/clarabridge/ckgtvn4ky2t2p19o4smq5gz1c';
	};

	private calculateBounds = (options: MapboxBuilderOptions): MapBounds => {
		const boundaryType = options.dataObject.metadata.boundaryType;

		if (boundaryType === BoundaryType.COUNTRY) {
			return MapboxBuilderService.COUNTRY_MAP_BOUNDS;
		}

		if (boundaryType === BoundaryType.STATE) {
			return MapboxBuilderService.STATE_MAP_BOUNDS;
		}

		return MapboxBuilderService.US_COUNTY_MAP_BOUNDS;
	};
}

app.service('mapboxBuilder', downgradeInjectable(MapboxBuilderService));
