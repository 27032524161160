import { SamlIdentityProviderApiService } from '@app/modules/system-administration/saml-settings/saml-identity-provider-api.service';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import OAuthExternalProvider from '../../oauth/oauth-external-provider';
import { OAuthExternalProvidersApiService } from '../../oauth/service/oauth-external-providers-api-service';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { conditionalValidator } from '@app/modules/cx-form/validation/conditional-validator.directive';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'external-auth-settings-integration',
	templateUrl: './external-auth-settings-integration.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalAuthSettingsIntegrationComponent extends SelfCleaningComponent implements OnInit {

	externalAuthSettings: UntypedFormGroup;

	oauthProviderLoading: Promise<OAuthExternalProvider[]>;
	oauthProviders: OAuthExternalProvider[];

	samlProvidersLoading: Promise<SamlIdentityProvider[]>;
	samlProviders: SamlIdentityProvider[];

	constructor(
		readonly formUtils: CxFormUtils,
		private fb: UntypedFormBuilder,
		private oauthExternalProvidersApiService: OAuthExternalProvidersApiService,
		private samlIdentityProviderApiService: SamlIdentityProviderApiService,
		private ref: ChangeDetectorRef
	) {
		super();
		this.externalAuthSettings = this.fb.group({
			enabled: [ false ],
			oauthSettings: this.fb.group({
				enabled: [{ value: false, disabled: true }],
				providerId: [{ value: null, disabled: true }, [ Validators.required ]],

			}),
			samlSettings: this.fb.group({
				enabled: [{ value: false, disabled: true }],
				aliasName: [{ value: null, disabled: true }, [ Validators.required ]],
				entityId: [],
			}),
			forcedExternalAuthentication: [{ value: null, disabled: true }]
		}, {
			validators: conditionalValidator(() => this.enabled.value, this.atLeastOneValidator('oauthSettings.enabled', 'samlSettings.enabled'))
		});

		this.addSubscription(this.enabled.valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.oauthSettingsEnabled, this.samlSettingsEnabled, this.forcedExternalAuthentication);
				if (this.oauthSettingsEnabled.value) {
					this.formUtils.enableFields(this.oauthSettingsProvider);
				}
				if (this.samlSettingsEnabled.value) {
					this.formUtils.enableFields(this.samlSettingsProvider);
				}
				this.externalAuthSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.oauthSettingsEnabled, this.oauthSettingsProvider,
					this.samlSettingsEnabled, this.samlSettingsProvider, this.forcedExternalAuthentication);
				this.externalAuthSettings.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.oauthSettingsEnabled.valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.oauthSettingsProvider);
				this.externalAuthSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.oauthSettingsProvider);
				this.externalAuthSettings.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.samlSettingsEnabled.valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.samlSettingsProvider);
				this.externalAuthSettings.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.samlSettingsProvider);
				this.externalAuthSettings.updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		this.reloadExternalProviders();
	}

	reloadExternalProviders = (): void => {
		this.oauthProviderLoading = this.oauthExternalProvidersApiService.getExternalProviders();
		this.oauthProviderLoading.then(providers => {
			this.oauthProviders = providers;
			this.ref.markForCheck();
		});

		this.samlProvidersLoading = this.samlIdentityProviderApiService.getIdentityProviders();
		this.samlProvidersLoading.then(providers => {
			this.samlProviders = providers;
			this.ref.markForCheck();
		});
	};

	updateSamlProvider(provider: SamlIdentityProvider) {
		this.samlSettingsEntityId.setValue(provider?.entityId);
	}

	private atLeastOneValidator = (...fields: string[]) => {
		return (group: UntypedFormGroup) => {
			for (const fieldName of fields) {
				if (group.get(fieldName).value) {
					return null;
				}
			}
			return { atLeastOneValid: true };
		};
	};

	getGroup(): UntypedFormGroup {
		return this.externalAuthSettings;
	}

	get enabled(): UntypedFormControl {
		return this.externalAuthSettings.controls.enabled as UntypedFormControl;
	}

	get oauthSettingsEnabled(): UntypedFormControl {
		return this.externalAuthSettings.get('oauthSettings.enabled') as UntypedFormControl;
	}

	get oauthSettingsProvider(): UntypedFormControl {
		return this.externalAuthSettings.get('oauthSettings.providerId') as UntypedFormControl;
	}

	get samlSettingsEnabled(): UntypedFormControl {
		return this.externalAuthSettings.get('samlSettings.enabled') as UntypedFormControl;
	}

	get samlSettingsProvider(): UntypedFormControl {
		return this.externalAuthSettings.get('samlSettings.aliasName') as UntypedFormControl;
	}

	get samlSettingsEntityId(): UntypedFormControl {
		return this.externalAuthSettings.get('samlSettings.entityId') as UntypedFormControl;
	}

	get forcedExternalAuthentication(): UntypedFormControl {
		return this.externalAuthSettings.controls.forcedExternalAuthentication as UntypedFormControl;
	}
}
