import * as _ from 'underscore';
import { RandomUtils } from '@app/util/random-utils.class';
import ILocale from '@cxstudio/interfaces/locale-interface';


export class HierarchyDropdownController implements ng.IController {

	multiselect: boolean;
	onNodeClick: () => void;
	evaluateCurrentValue: () => any;
	hierarchyList: any[];
	displayProperty: string;
	valuePlaceholder: string;
	searchPlaceholder: string;
	selectedItem: any;
	nodeIsChecked: (node) => boolean;
	nodeCheckHandler: (node) => void;
	hideSearch: boolean;
	showNodeCheckbox: (node) => boolean;
	folderClickIgnore: boolean;
	selectedItemTemplate: string;
	itemTemplate: string;
	ngDisabled: boolean;
	buttonStyle: () => {[key: string]: string};
	evaluateTooltip: () => string;
	onDropdownToggle: (open: boolean) => void;
	buttonStyleId: string;
	containerTitle: string;

	status: {
		isDropdownOpen: boolean;
	};

	constructor(
		private locale: ILocale,
	) {}

	$onInit(): void {
		this.status = {
			isDropdownOpen: false
		};
		this.buttonStyleId = `s-${RandomUtils.randomString()}`;
	}

	getSelectedValue = () => {
		let currentValue = this.evaluateCurrentValue && this.evaluateCurrentValue()
			&& this.evaluateCurrentValue()[this.displayProperty];
		return currentValue ? currentValue
			: (this.selectedItem && this.selectedItem[this.displayProperty]
				? this.selectedItem[this.displayProperty]
				: this.valuePlaceholder);
	};

	getTooltip = (): string => {
		let customTooltip = this.evaluateTooltip && this.evaluateTooltip();
		return customTooltip || this.getSelectedValue();
	};

	getDropListLabel = (): string => {
		if (this.containerTitle) {
			let panelName = this.getTooltip();
			return this.locale.getString('widget.hierarchyDroplistLabel', {
				listLabel: panelName,
				widgetName: this.containerTitle
			});
		} else {
			return '';
		}
	};
}

app.component('hierarchyDropdown', {
	controller: HierarchyDropdownController,
	templateUrl: 'partials/custom/hierarchy-dropdown.html',
	bindings: {
		multiselect: '<?',
		onNodeClick: '&',
		showClearButton: '<?',
		selectNone: '&?',
		selectNoneText: '@',
		evaluateCurrentValue: '&?',
		hierarchyList: '=',
		displayProperty: '@',
		valuePlaceholder: '@',
		searchPlaceholder: '@',
		selectedItem: '=?',
		nodeIsChecked: '&?',
		nodeCheckHandler: '&?',
		hideSearch: '<?',
		showNodeCheckbox: '&?',
		folderClickIgnore: '<',
		selectedItemTemplate: '@?',
		itemTemplate: '@?',
		ngDisabled: '<',
		buttonStyle: '&?',
		evaluateTooltip: '&?',
		onDropdownToggle: '&?',
		containerTitle: '<?'
	},
});
