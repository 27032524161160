import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ITreeSelection, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { FilterAttributeTypes } from '@cxstudio/report-filters/constants/filter-attribute-types.constant';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';

@Injectable({
	providedIn: 'root'
})
export default class DashboardFilterBuildersService {

	dashboardFilterRuleBuilders: {[key: string]: (dashboardFilter: any) => IFilterRule} = {};

	constructor(
		@Inject('filterMatchModes') private filterMatchModes: FilterMatchModes,
	) {

		this.dashboardFilterRuleBuilders[DashboardFilterTypes.METRIC] = (dashboardFilter: DashboardFilter): IFilterRule => {
			return {
				type: FilterRuleType.numericRange,
				matchMode: dashboardFilter.selectedAttribute.matchMode || filterMatchModes.definitions.IS.apiValue,
				from: dashboardFilter.selectedAttributeValue.min,
				to: dashboardFilter.selectedAttributeValue.max
			};
		};

		this.dashboardFilterRuleBuilders[DashboardFilterTypes.TOPIC] = (dashboardFilter: DashboardFilter): IFilterRule => {
			let model = dashboardFilter.selectedAttribute;
			let treeSelection = dashboardFilter.selectedAttributeValue as ITreeSelection;

			let rule: IFilterRule = {
				type: FilterRuleType.topicEquality,
				matchMode: dashboardFilter.selectedAttribute.matchMode || filterMatchModes.definitions.IS.apiValue,
				topicId: model.id,
				values: !treeSelection?.nodes || treeSelection?.strategy === TreeSelectionStrategy.NONE
					? []
					: treeSelection.nodes.map((node) => {
						return {
							modelId: node.modelId,
							path: node.path,
							name: node.name,
							id: node.id,
							idPath: node.idPath
						};
					})
			};
			if (filterMatchModes.isModelMatch(rule)) {
				rule.modelMatch = true;
			}
			return rule;
		};
	}

	private defaultDashboardFilterRuleBuilder = (dashboardFilter): IFilterRule => {
		let attributeValue = dashboardFilter.multiValues !== undefined
			? dashboardFilter.multiValues[0]
			: dashboardFilter.selectedAttributeValue;
		attributeValue = attributeValue || {};

		let filterRule: IFilterRule =  {
			type: this.getFilterType(dashboardFilter),
			attributeName: dashboardFilter.selectedAttribute.name,
			matchMode: attributeValue.matchMode || dashboardFilter.selectedAttribute.matchMode || this.filterMatchModes.definitions.IS.apiValue,
			attributeDisplayName: dashboardFilter.selectedAttribute.displayName,
		} as IFilterRule;
		if (attributeValue.existMatch || dashboardFilter.selectedAttribute.existMatch) {
			filterRule.existMatch = true;
		}

		return this.populateFilterRuleValues(filterRule, dashboardFilter);
	};

	// handles selection array or single item filter type
	private getFilterValues = (selectedAttributes = []): any[] => {
		if (selectedAttributes.constructor !== Array) {
			selectedAttributes = (selectedAttributes.constructor !== Object) ? [{name: selectedAttributes}] : [selectedAttributes];
		}
		return selectedAttributes.filter(item => item?.name).map(item => ({text: item.name}));
	};

	getBuilder = (dashboardFilterType: DashboardFilterTypes): (dashboardFilter: any) => IFilterRule => {
		let dashboardFilterTypeBuilder = this.dashboardFilterRuleBuilders[dashboardFilterType];
		return dashboardFilterTypeBuilder
			? dashboardFilterTypeBuilder
			: this.defaultDashboardFilterRuleBuilder;
	};

	private getFilterType = (dashboardFilter): FilterRuleType => {
		if (dashboardFilter.genericFilterFormat !== undefined) {
			return dashboardFilter.genericFilterFormat.type;
		}

		if (dashboardFilter.selectedAttribute.type === FilterAttributeTypes.NUMBER) {
			return FilterRuleType.numericEquality;
		}

		if (dashboardFilter.selectedAttribute.type === FilterAttributeTypes.DATE) {
			return FilterRuleType.dateRange;
		}

		return FilterRuleType.stringEquality;
	};

	private populateFilterRuleValues = (filterRule: IFilterRule, dashboardFilter): IFilterRule => {
		if (!filterRule.existMatch && dashboardFilter.multiValues !== undefined) {
			filterRule = {...filterRule, values: this.getFilterValues(dashboardFilter.multiValues)};
		}

		if (dashboardFilter.genericFilterFormat === undefined) {
			return filterRule;
		}

		if (dashboardFilter.genericFilterFormat.type === FilterRuleType.numericRange) {
			filterRule = {
				...filterRule,
				from: dashboardFilter.genericFilterFormat.from,
				to: dashboardFilter.genericFilterFormat.to
			};
		}

		if (dashboardFilter.genericFilterFormat.type === FilterRuleType.numericOpenRange) {
			filterRule = {...filterRule, value: dashboardFilter.genericFilterFormat.value};
		}

		return filterRule;
	};
}

app.service('dashboardFilterBuilders', downgradeInjectable(DashboardFilterBuildersService));

