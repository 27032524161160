import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthAuthorizedGrantTypes } from '../oauth-authorized-grant-type.factory';
import { OAuthAuthorityRoles } from '../oauth-authority-roles.factory';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';

import { OAuthClientDetailsEditDialogComponent } from '../oauth-client-details-edit-dialog.component';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'oauth-designer-client-details-edit-dialog',
	templateUrl: '../oauth-client-details-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthDesignerClientDetailsEditDialogComponent extends OAuthClientDetailsEditDialogComponent {

	supportDesignerScope = true;

	constructor(
		protected modal: NgbActiveModal,
		protected ref: ChangeDetectorRef,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected oauthAuthorityRoles: OAuthAuthorityRoles,
		locale: CxLocaleService
	) {
		super(modal, ref, oauthAuthorizedGrantTypes, oauthAuthorityRoles, locale, OauthClientDetailsApplicationKind.DESIGNER);
	}

	protected init(): void {
		this.registeredRedirectUri = this.clientDetails.registeredRedirectUri;
		this.initGrantTypes();
		this.initAuthorities();
	}

	save = (): void => {
		this.clientDetails.registeredRedirectUri = this.registeredRedirectUri;
		this.saveCommon();
		this.cleanupLegacyClientId();

		this.modal.close(this.clientDetails);
	};

	disableSave = (): boolean => {
		return this.disableSaveCommon();
	};

	private cleanupLegacyClientId = (): void => {
		if (this.clientDetails.legacyClientId === '') {
			delete this.clientDetails.legacyClientId;
		}
	};

}
