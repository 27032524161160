import { MultiMetricDataProcessor } from '@cxstudio/services/multi-metric-data-processor';
import { TableService } from '@cxstudio/services/table-service';
import { IAugmentedJQuery } from 'angular';
import { ElementUtils } from '../utils/visualization/element-utils.service';
import { ITableReportScope, ReportUtils } from '../utils/visualization/report-utils.service';
import { CommonSlickTableDefinitionService } from './definitions/slick/common-slick-table-definition.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricConstants } from '../providers/cb/constants/metric-constants.service';
import { WidgetProperties } from '../entities/widget-properties';
import { ReportDataObject } from '../entities/report-interfaces';
import VisualProperties from '../entities/visual-properties';
import { SortDirection } from '@cxstudio/common/sort-direction';


interface ISlickTableScope extends ng.IScope {
	utils;
	columns_all: any[];
	props: WidgetProperties;
	demo: boolean;
	grid;
	handleClick(data, e: MouseEvent): unknown;
	handleRightClick(e: MouseEvent): unknown;
	columnpicker;
	dataObject: ReportDataObject;
	options: VisualProperties;
	data: any[];
};

/**
 * Table visualization
 */
app.directive('slickTable', (locale: ILocale, $rootScope, $timeout,
	tableService: TableService, reportUtils: ReportUtils, elementUtils: ElementUtils,
	commonSlickTableDefinition: CommonSlickTableDefinitionService, metricConstants: MetricConstants) => {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		scope: {
			options: '=',
			props: '=',
			dataObject: '=data',
			demo: '=',
			view: '=',
			trigger: '=',
			utils: '=',
			handleClick: '=',
			handleRightClick: '='
		},

		link: (scope: ISlickTableScope, element) => {
			if (!tableService.processIfNoData(element, scope.dataObject, undefined, 'simple', scope.utils)) {
				return;
			}

			let tableOptions = commonSlickTableDefinition.getTableOptions();
			scope.columns_all = commonSlickTableDefinition.getColumns(scope);
			let comparisons = scope.props.comparisons;
			let data = MultiMetricDataProcessor.processData(scope.dataObject.data, scope.props.selectedMetrics,
				scope.options, metricConstants, comparisons);
			scope.data = data;
			let dataView = new Slick.Data.DataView();

			let buildTableElement = (): IAugmentedJQuery => {
				return scope.demo ? reportUtils.buildReportElement({
					left: '0px',
					right: '0px',
					bottom: '30px',
					top: '20px'
				}) : reportUtils.buildReportElement({
					left: '0px',
					right: '0px',
					bottom: '30px',
					top: '0px'
				});
			};

			let tableElement = buildTableElement();
			elementUtils.composeWithDefaultFooter(element, {
				body: tableElement
			});

			let initialColumns;

			function createTable(extCols?): void {
				if (!_.isUndefined(extCols)) {
					scope.grid = new Slick.Grid(tableElement, dataView, tableService.wrapColumns(
						extCols), tableOptions);
				} else {
					let columns;
					columns = scope.columns_all;

					scope.grid = new Slick.Grid(tableElement, dataView, tableService.wrapColumns(
						columns), tableOptions);
					initialColumns = angular.copy(columns);
				}
				scope.$on('$destroy', scope.grid.destroy);
				scope.$on('focusFirstGridHeader', (event) => {
					scope.grid.resetActiveCell();
					scope.grid.navigateNext();
				});

				scope.grid.registerPlugin(new Slick.AutoTooltips({
					enableForHeaderCells: false
				}));
				if ($rootScope.isMobile) {
					dataView.setPagingOptions({
						pageSize: 25
					});
				}

				addColumnPicker();
				tableService.registerCommonHandlers(dataView, scope.options, scope.grid,
					scope.utils.widgetType, element);

				tableService.registerColumnsResizeHandler(scope.options, scope.grid);
				tableService.registerGridSorter(dataView, scope.options, scope.grid, scope.utils.widgetType, element);

				processDynamicDefinition(scope.grid.getColumns(), true);
				processDynamicDefinition(initialColumns, false);

				if (!scope.demo) {
					scope.grid.onClick.subscribe((e) => {
						let cell = scope.grid.getCellFromEvent(e);

						if (scope.grid.getColumns()[0].link) {
							return;
						}

						e.preventDefault();
						if (!_.isUndefined(scope.handleClick)) {
							scope.handleClick(scope.data[cell.row], e);
						}
					});

					scope.grid.onContextMenu.subscribe((e) => {
						e.preventDefault();
						if ($(e.target).hasClass('no-drill')) {
							scope.handleRightClick(e);
							return;
						}
						let cell = scope.grid.getCellFromEvent(e);
						if (!_.isUndefined(scope.handleClick)) {
							scope.handleClick(scope.data[cell.row], e);
						}
					});
					scope.grid.onHeaderContextMenu.subscribe((e) => {
						e.preventDefault();
						scope.handleRightClick(e);
					});
				}

				if (!_.isUndefined(scope.options.sortBy)) {
					let dir = scope.options.direction ? scope.options.direction === SortDirection.ASC :
						false;
					scope.grid.setSortColumn(scope.options.sortBy, dir);
					tableService.gridSingleSorter(scope.options.sortBy, dir, scope.grid,
						data);
				}

				function setTableColumns(columns): void {
					scope.grid.setColumns(columns);
					destroyColumnPicker();
					addColumnPicker();
					scope.grid.invalidate();
					scope.grid.render();
				}

				function processDynamicDefinition(columns, rerender): void {
					if (rerender) {
						setTableColumns(columns);
					}
				}
				dataView.onRowsChanged.subscribe(() => {
					processDynamicDefinition(scope.grid.getColumns(), true);
					processDynamicDefinition(initialColumns, false);
				});

				dataView.setItems(data);
				scope.grid.invalidate();

				scope.grid.render(() => {
					scope.$$postDigest(() => {
						reportUtils.handleWidgetRenderedEvent(scope.utils.widgetId, scope.utils.widgetType,
							scope.utils.containerId);
					});
				});
			}

			createTable();

			reportUtils.initResizeHandler(scope, element, reportUtils.tableResizeHandler(scope as unknown as ITableReportScope, element));

			scope.$watch('$parent.isMobile', (isMobile, previous) => {
				if (isMobile === previous)
					return;
				$timeout(() => {
					createTable(initialColumns);
				}, 500);
			});

			function addColumnPicker(): void {
				scope.columnpicker = Slick.Controls.ColumnPicker(scope.columns_all,
					scope.grid, tableOptions, element,
					locale.getString('preview.synchronousResize'), locale.getString(
						'preview.forceFitColumns'));
			}

			function destroyColumnPicker(): void {
				if (!_.isUndefined(scope.columnpicker)) {
					scope.columnpicker.destroy();
				}
			}

			scope.$watch('trigger', (newValue, oldValue) => {
				if (newValue === oldValue) return;
				if (_.isUndefined(scope.dataObject.data) || scope.dataObject.data.length === 0) {
					return;
				}

				let requiresRerender = false;
				let columnsAllNew = commonSlickTableDefinition.getColumns(scope);

				scope.columns_all = columnsAllNew;
				scope.grid.setColumns(scope.columns_all);
				destroyColumnPicker();
				addColumnPicker();
				requiresRerender = true;

				dataView.setItems(MultiMetricDataProcessor.processData(scope.dataObject.data,
					scope.props.selectedMetrics, scope.options, metricConstants, comparisons));

				if (requiresRerender) {
					scope.grid.invalidate();
					scope.grid.render();
				}
			});
		}
	};
});
