import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { SelectorDisplaySize } from '@app/modules/widget-settings/selector-display-size.class';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { ChangeUtils } from '@app/util/change-utils';
import { FontOptions } from '@cxstudio/common/font-options.class';
import { DirectionalOrientation } from '@cxstudio/common/orientation';
import { SortDirection } from '@cxstudio/common/sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { SelectorWidgetVisualProperties } from '@cxstudio/reports/providers/cb/components/selector-widget-visualization-properties.class';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { WordsFilteringMode } from '@cxstudio/reports/providers/cb/constants/words-filtering-mode';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { IMapAlikeCommonSettings } from '@cxstudio/reports/providers/cb/definitions/map-alike-common-settings.factory';
import { IWidgetSettingsOptions } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import { ColorUtils, PaletteType } from '@cxstudio/reports/utils/color-utils.service';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { SelectorWidgetUtils } from '@cxstudio/reports/widget-types/selector/selector-widget-utils';
import WidgetService from '@cxstudio/services/widget-service';
import { Subscription } from 'rxjs';
import { MetricConstants } from '../constants/metric-constants.service';
import { RealDataPreviewWidget } from '../definitions/real-data-preview-widget.class';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';

export interface ISelectorSettingsOptions extends IWidgetSettingsOptions {
	searchAttributes: AttributeGrouping[];
}

export interface SelectorVisualizationOptions {
	visualization: WidgetVisualization | VisualizationType | SelectorWidgetNavigationType;
	multiselect: boolean;
}

export class SelectorVisualizationSettingsComponent extends RealDataPreviewWidget {
	widgetType: WidgetType;

	readonly DYNAMIC_CHECKS = {
		COLOR: 'color',
		SORT: 'sort'
	};

	readonly SEARCH_ATTRIBUTES_LIMIT = 10;

	readonly BUTTON_COLORS = [PaletteType.PALETTE, PaletteType.PROVIDER, PaletteType.SOLID,
		PaletteType.GROUP, PaletteType.CALCULATION];
	readonly SOLID_COLOR = [PaletteType.SOLID];

	readonly orientations = DirectionalOrientation;

	readonly DEFAULTS: Partial<SelectorWidgetVisualProperties> = {
		direction: SortDirection.ASC,
		orientation: DirectionalOrientation.HORIZONTAL,
		backgroundColor: WidgetBackgroundColor.DEFAULT,
		displaySize: SelectorDisplaySize.JUSTIFY,
		visualization: SelectorWidgetNavigationType.SELECTOR_BUTTON,
		font: FontOptions.getOptions()[0].name,
		sortBy: StandardMetricName.ALPHANUMERIC,
		showSampleSize: false
	};

	heatmapCommonMethods: IMapAlikeCommonSettings;
	listTypes = KeyMetricListTypes;
	staticData: any;
	visualProps: SelectorWidgetVisualProperties;
	props: WidgetProperties;
	ui;
	widget: Widget;
	applyVisualChanges: () => void; // added by AnalyticDefinitionSelectionController
	options: ISelectorSettingsOptions;
	defaultColor: Partial<IColorSelectorPalette>;
	utils: WidgetUtils;

	realChartLastAppliedBackgroundColor: WidgetBackgroundColor;
	previewHasChangesSubscription: Subscription;

	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	updateCustomDateFilters?: (dateFilters: any) => void;
	populateCurrentHierarchyCalculations: (metrics: any, grouping: any, calculations: any, models?: any) => void;
	initializePeriods: () => void;
	reloadCommonSettings: (config: any) => ng.IPromise<IWidgetSettings>;
	updateChartSettings: (refreshType?: PreviewChartRefreshType) => void;
	showRealDataPreview: () => boolean;
	updateDataLoading: (dataLoading: Promise<any>) => void;

	constructor(
		protected $scope,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly metricConstants: MetricConstants,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private $controller,
		private MapAlikeCommonSettings,
		private $timeout: ng.ITimeoutService,
		private calculationColorService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private metricUtils: MetricUtils,
		private widgetService: WidgetService
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit = () => {
		this.initRealDataPreview();

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this,
			selectionConfiguration: {
				multiAttributeSelection: true
			},
			customCallback: {}
		});

		this.heatmapCommonMethods = new this.MapAlikeCommonSettings(this, this.visualProps.visualization || this.DEFAULTS.visualization);

		this.$scope.$on('clearSettings', this.heatmapCommonMethods.initProps);
		this.$scope.$on('reloadSettings', (event, callback) => {
			let wrappedCallback = (result: boolean) => {
				this.initializeSearchAttributes();
				callback(result);
			};
			this.heatmapCommonMethods.reloadSettings(event, wrappedCallback);
		});

		this.staticData.ready = false;

		this.setDefaults();

		this.ui = this.ui || {};
		delete this.ui.periods;

		this.heatmapCommonMethods.initialize();
	};

	private initRealDataPreview(): void {
		this.widgetType = this.props.widgetType;
		this.initScopeProps();
		super.$onInit();

		if (this.showRealDataPreview()) {
			this.realChartLastAppliedBackgroundColor = this.visualProps.backgroundColor;

			this.previewHasChangesSubscription = this.realDataPreviewService.getPreviewChangeObserver().subscribe((hasChanges) => {
				if (!hasChanges) {
					this.realChartLastAppliedBackgroundColor = this.visualProps.backgroundColor;
				}
			});
		}
	}

	private initScopeProps(): void {
		this.$scope.widget = this.widget;
		this.$scope.widgetType = this.props.widgetType;
		this.$scope.props = this.props;
		this.$scope.visualProps = this.visualProps;
		this.$scope.options = this.options;
		this.$scope.staticData = this.staticData;
		this.$scope.updateChartSettings = this.updateChartSettings;
	}

	$onChanges = (changes) => {
		if (ChangeUtils.hasChange(changes.utils)) {
			this.utils = changes.utils.currentValue;
		}
	};

	$onDestroy = () => {
		if (this.previewHasChangesSubscription) {
			this.previewHasChangesSubscription.unsubscribe();
		}
	};

	$on = (event: string, handler: () => void): () => void => {
		return this.$scope.$on(event, handler);
	};

	private setDefaults = (): void => {
		for (let key in this.DEFAULTS) {
			if (_.isUndefined(this.visualProps[key]) || this.widget.created)
				this.visualProps[key] = this.DEFAULTS[key];
		}
	};

	private checkOrientation = (newOrientation: {value: DirectionalOrientation}): void => {
		if (newOrientation.value === DirectionalOrientation.VERTICAL) {
			this.visualProps.displaySize = SelectorDisplaySize.JUSTIFY;
		}
	};

	onDirectionChange(direction: SortDirection): void {
		if (this.visualProps.direction !== direction) {
			this.visualProps.direction = direction;
			this.applyVisualChanges();
		}
	}

	orientationChange = (newOrientation: DirectionalOrientation) => {
		this.checkOrientation({ value: newOrientation });
		if (this.visualProps.orientation !== newOrientation) {
			this.visualProps.orientation = newOrientation;
			this.applyVisualChanges();
		}
	};

	sizeChange = (value: SelectorDisplaySize) => {
		this.visualProps.displaySize = value;
		this.$timeout(this.applyVisualChanges);
	};

	setSortBy = (node) => {
		this.visualProps.sortBy = node.name;
		this.visualProps.attributeSelections.sortBy = node;

		this.heatmapCommonMethods.processAttributesAndMetrics(['color', 'sortBy']);
		this.applyVisualChanges();
	};

	isIgnoreFilters = (): boolean => {
		return this.isCustomSelection() || this.isSearch();
	};

	isCustomSelection = (): boolean => {
		return SelectorWidgetUtils.isCustomSelection(this.props);
	};

	needsDynamicWarning = (property: string): boolean => {
		if (!this.isCustomSelection()) return false;

		if (property === this.DYNAMIC_CHECKS.COLOR) {
			return this.calculationColorService.isCalculationColor(this.visualProps.color);
		}

		if (property === this.DYNAMIC_CHECKS.SORT) {
			return this.visualProps.sortBy !== StandardMetricName.ALPHANUMERIC;
		}

		return false;
	};

	onGroupingChange = (grouping: AttributeGrouping): void => {
		if (AnalyticMetricTypes.isPredefinedGroup(grouping)) {
			grouping.sortOrder = GroupingSortOrder.CUSTOM;
			grouping.wordsFilteringMode = WordsFilteringMode.INCLUDE;
			grouping.wordsList = this.metricUtils.getPredefinedMetricValues(grouping);
		} else {
			this.props.isCustomSelectorWidget = false;
		}
		this.updateModelSortOptions();
	};

	private updateModelSortOptions(): void {
		if (this.hasTopicGrouping()) {
			this.setModelDisplayVisible(true);
		} else {
			this.setModelDisplayVisible(false);
			if (this.visualProps.sortBy === StandardMetricName.MODEL_ORDER) {
				this.setSortBy(this.constants.ALPHANUMERIC);
			}
		}
	}

	private setModelDisplayVisible(visible: boolean): void {
		let modelOrderOption = this.options.sortMetrics?.find(item => item.name === StandardMetricName.MODEL_ORDER);
		if (modelOrderOption) {
			modelOrderOption.hidden = !visible;
		}
	}

	private hasTopicGrouping(): boolean {
		return this.props.selectedAttributes?.filter(AnalyticMetricTypes.isTopics).length > 0;
	}

	isButton = (): boolean => {
		return this.widget.visualProperties.visualization === SelectorWidgetNavigationType.SELECTOR_BUTTON;
	};

	isSearch = () => {
		return this.widget.visualProperties.visualization === SelectorWidgetNavigationType.SELECTOR_SEARCH;
	};

	private initializeSearchAttributes(): void {
		this.options.searchAttributes = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
			.withAttributes(this.options.attributes, MetricFilters.SEARCH_FILTER)
			.build();
		_.each(this.props.selectedAttributes, item => {
			if (!item)
				return;
			let selected = SearchableHierarchyUtils.findMetricInHierarchy(this.options.searchAttributes, item);
			if (selected)
				selected.hidden = true;
		});
	}

	addSearchAttribute = (node: AttributeGrouping & INode) => {
		this.props.selectedAttributes.push(node);
		node.hidden = true;
		if (!this.props.isCustomTitle) {
			this.widget.displayName = this.widgetService.updateAutoTitle(this.props, this.options);
		}
		this.applyVisualChanges();
	};

	removeSearchAttribute = (node: AttributeGrouping & INode) => {
		this.props.selectedAttributes.remove(node);
		delete node.hidden;
		if (!this.props.isCustomTitle) {
			this.widget.displayName = this.widgetService.updateAutoTitle(this.props, this.options);
		}
	};

	isAttributeLimitReached = () => {
		return this.props.selectedAttributes.length >= this.SEARCH_ATTRIBUTES_LIMIT;
	};

	getWidgetDefaultColor = (): Partial<IColorSelectorPalette> => {
		return this.defaultColor;
	};

	onColorInputChange = (): void => {
		this.realDataPreviewService.notifyPreviewHasUIChanges();
	};

	onBackgroundChange = (): void => {
		if (this.showRealDataPreview() && !this.realDataPreviewService.hasPreviewChanges()) {
			this.realChartLastAppliedBackgroundColor = this.visualProps.backgroundColor;
		}
	};

	hasNoBackground = (): boolean => {
		if (this.showRealDataPreview()) {
			return this.realChartLastAppliedBackgroundColor === WidgetBackgroundColor.NONE;
		} else {
			return this.visualProps.backgroundColor === WidgetBackgroundColor.NONE;
		}
	};

	onVisualizationChange = (): void => {
		this.visualProps.color = this.isButton() ? undefined : 'custom';

		HierarchyUtils.applyRecursively({children: this.options.searchAttributes} as INode, item => delete item.hidden);
		if (this.visualProps.visualization === SelectorWidgetNavigationType.SELECTOR_SEARCH) {
			this.props.selectedAttributes = [];
		}
		if (this.visualProps.visualization !== SelectorWidgetNavigationType.SELECTOR_SEARCH) {
			if (_.size(this.props.selectedAttributes) >= 1) {
				this.props.selectedAttributes = _.first(this.props.selectedAttributes, 1);
			} else {
				this.props.selectedAttributes = [undefined];
			}
		}
		this.applyVisualChanges();
	};

	onMultiselectChange = (): void => {
		this.applyVisualChanges();
	};

}

app.component('selectorVisualizationSettings', {
	bindings: {
		props: '<',
		loadingPromise: '<',
		addLoadingPromise: '<',
		visualProps: '=',
		widget: '<',
		ui: '<',
		updateChartSettings: '<',
		showRealDataPreview: '<',
		updateDataLoading: '<',
		realDataLoading: '<',
		clearErrors: '<',
		staticData: '<',
		options: '<',
		utils: '<',
		redrawTrigger: '<',
		defaultColor: '<',
		personalization: '<',
	},
	controller: SelectorVisualizationSettingsComponent,
	templateUrl: 'partials/widgets/settings/cb/components/selector-visualization-settings.component.html'
});
