import { Component, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import MobileAppSettingsApiService from '@cxstudio/mobile/mobile-app-settings-api.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

@Component({
	selector: 'wrong-topic-reporting',
	templateUrl: './wrong-topic-reporting.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WrongTopicReportingComponent {
	@Input() recipients: string[];
	@Output() recipientsChange = new EventEmitter<string[]>();

	search: string;
	searchingUsers: boolean;

	constructor(
		@Inject('mobileAppSettingsApiService') private readonly mobileAppSettingsApiService: MobileAppSettingsApiService
	) { }

	userSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			switchMap((query: string) => {
				this.searchingUsers = true;
				return PromiseUtils.wrap(this.mobileAppSettingsApiService.getWrongTopicReportRecipientsSuggestion(query))
					.then(users => {
						this.searchingUsers = false;
						return users.map(user => user.userEmail);
					});
			})
		);
	};

	addRecipient = (userEmail: string): void => {
		if (!userEmail)
			return;

		if (!this.isRecipient(userEmail)) {
			this.recipients.push(userEmail);
			this.recipientsChange.emit(this.recipients);
		}
		this.search = null;
	};

	removeRecipient = (email: string): void => {
		this.recipients.remove(email);
		this.recipientsChange.emit(this.recipients);
	};

	private isRecipient = (email: string): boolean => {
		return this.recipients.contains(email);
	};
}

app.directive('wrongTopicReporting', downgradeComponent({component: WrongTopicReportingComponent}));
