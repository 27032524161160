import {Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges } from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';

import * as _ from 'underscore';
import { LicenseType } from '@cxstudio/common/license-types';
import { UserEditFormData } from '@app/modules/user-administration/editor/user-edit-form-data';
import { Security } from '@cxstudio/auth/security-service';
import { UserManageApi } from '@app/modules/user-administration/editor/user-manage-api.service';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'customer-permissions',
	templateUrl: './customer-permissions.component.html'
})
export class CustomerPermissionsComponent implements OnInit, OnChanges {
	
	@Input() user: UserEditFormData;
	@Input() licenseType: number;
	@Output() customerAdminChange = new EventEmitter<UserEditFormData>();

	loading: Promise<any>;
	hasSeats: boolean;

	private initialCustomerAdmin: boolean;

	constructor(
		@Inject('security') private security: Security,
		private locale: CxLocaleService,
		private userManageApi: UserManageApi
	) {}

	ngOnInit(): void {
		this.initialCustomerAdmin = this.user.customerAdmin;
		this.loading = this.userManageApi.getCustomerAdminsCount().then(count => 
			this.hasSeats = count < this.security.getCurrentMasterAccount().maxCustomerAdmins);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.licenseType?.firstChange && changes.licenseType?.previousValue !== changes.licenseType?.currentValue) {
			if (this.isBasicLicense()) {
				this.user.customerAdmin = false;
				this.changeHandler();
			}
		}
	}

	isCustomerAdminDisabled = (): boolean => {
		return this.isBasicLicense() || this.isNoSeats();
	};

	private isBasicLicense = (): boolean => {
		return this.licenseType === LicenseType.CX_STUDIO_BASIC;
	};

	private isNoSeats = (): boolean => {
		return !this.initialCustomerAdmin && !this.hasSeats;
	};

	getCustomerAdminTooltip = (): string | undefined => {
		if (this.isBasicLicense()) return;
		if (this.isNoSeats())
			return this.locale.getString('administration.customerAdminLimitTooltip');
	};

	changeHandler = (): void => {
		this.customerAdminChange.emit(this.user);
	};
}

app.directive('customerPermissions', downgradeComponent({component: CustomerPermissionsComponent}));