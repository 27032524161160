import * as _ from 'underscore';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { WidgetLinkingService } from '@cxstudio/reports/utils/analytic/widget-linking-service';
import GridsterConfigurer, { GridsterWidgetMapping } from '@cxstudio/home/gridster-configurer';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';


interface IDashboardGridsterScope {
	layout: LayoutHelper;
}
export class DashboardGridsterController implements ng.IController {

	dashboard: Dashboard;
	widgets: Widget[];
	dashboardHistory: IDashboardHistoryInstance;

	dashboardData: IDashboardData;
	gridster: HTMLElement;
	gridsterOptions: any;

	constructor(
		private $element: ng.IAugmentedJQuery,
		private $scope: ISimpleScope & IDashboardGridsterScope,
		private $timeout: ng.ITimeoutService,
		private widgetLinkingService: WidgetLinkingService,
		private gridsterConfigurer: GridsterConfigurer,
		private layoutHelperService: LayoutHelper,
	) {}

	$onInit(): void {
		this.dashboardData = {
			dashboard: this.dashboard,
			dashboardHistory: this.dashboardHistory,
			isBook: true
		};

		// scope.layout is used by scroll-compensator directive
		this.$scope.layout = this.layoutHelperService;

		this.gridster = $(this.$element).find(this.gridsterConfigurer.getGridsterSelector()).get(0) as HTMLElement;

		this.gridsterOptions = this.gridsterConfigurer.getDefaultGridsterOptions({
			widgetsProvider: () => this.widgets
		});

		this.$scope.$on('gridster-resized', () => {
			this.recalculateScrollbar();
			this.$timeout(_.noop, 250);
		});
	}

	getWidgetMapping(widget: Widget): GridsterWidgetMapping {
		return this.gridsterConfigurer.getWidgetMapping(widget);
	}

	private recalculateScrollbar(): void {
		if (!this.gridster)
			return;
		this.$scope.layout.scrollbarOffset = this.gridster.offsetWidth - this.gridster.clientWidth;
		_.noop();
	}

	private clearLinkedWidgets = () => {
		$(this.$element).find('.linked-widget-parent,.linked-widget-child')
			.removeClass('linked-widget-parent linked-widget-child');
	};

	private addParentClass = (id) => {
		$(this.$element).find('#widget-' + id).addClass('linked-widget-parent');
	};

	private addChildClass = (id) => {
		$(this.$element).find('#widget-' + id).addClass('linked-widget-child');
	};

	mouseEnterWidget = (widget) => {
		this.clearLinkedWidgets();
		this.widgetLinkingService.highlightLinkedWidgets(widget, this.addParentClass, this.addChildClass);
	};

	mouseLeaveWidget = () => {
		this.clearLinkedWidgets();
	};

	isWidgetVisible = (widget: Widget) => {
		return widget.name !== WidgetType.PAGE_BREAK;
	};
}

app.component('dashboardGridster', {
	controller: DashboardGridsterController,
	bindings: {
		dashboard: '<',
		widgets: '<',
		dashboardHistory: '<'
	},
	templateUrl: 'partials/dashboard/dashboard-gridster-component.html',
});
