import MobileAppSettings from './mobile-app-settings.interface';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import { UibTab } from '@cxstudio/common/uib-tab';
import MobileScreenSettings from './mobile-screen-settings.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MobileScreenType } from './mobile-screen-type.enum';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import SimpleGroupingMobileScreenSettings from './simple-grouping-mobile-screen-settings.interface';
import { Errors } from '@cxstudio/common/errors';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';

export default class MobileNavigationController implements ng.IComponentController {

	private readonly MAX_ALLOWED_SCREENS = 3;

	settings: MobileAppSettings;
	widgetSettingsCache: CachedInvocation;
	errors: Errors;

	screenTabs: UibTab[];
	activeTabIndex: string;

	constructor(
		private locale: ILocale,
		private $scope: ISimpleScope,
		private mobileUtils: MobileUtils
	) {}

	$onInit(): void {
		this.initializeScreenTabs();
		this.$scope.$on('settings.jumpToErrors', this.jumpToErrors);
	}

	jumpToErrors = (): void => {
		let jumped = false;

		this.settings.screens.forEach((screen, index) => {
			if (!jumped && this.canScreenTabBeDisplayed(screen) && !screen.hidden && this.errors.hasScope(`screen-${index}`)) {
				this.activeTabIndex = screen.nameKey;
				jumped = true;
			}
		});
	};

	hideScreen = (screen: MobileScreenSettings, index: number): void => {
		screen.hidden = true;
		this.selectLastDisplayedScreenTab();

		this.errors.removeScope(`screen-${index}`);
	};

	canAddScreen = (): boolean => {
		return this.getDisplayedScreenTabs().length < this.MAX_ALLOWED_SCREENS;
	};

	addScreen = (): void => {
		const firstHiddenScreen = this.getFirstHiddenScreen();
		firstHiddenScreen.hidden = false;
		this.selectLastDisplayedScreenTab();
	};

	private initializeScreenTabs = (): void => {
		this.screenTabs = this.settings.screens
				.filter(this.canScreenTabBeDisplayed)
				.map(this.createScreenTab);

		this.activeTabIndex = this.settings.screens[0].nameKey;
	};

	private canScreenTabBeDisplayed = (screen: MobileScreenSettings): boolean => {
		return screen.type === MobileScreenType.SIMPLE_GROUPING;
	};

	private createScreenTab = (screen: MobileScreenSettings, index: number): UibTab => {
		if (this.isOptionalMissconfiguredScreen(screen)) {
			screen.hidden = true;
		}
	
		return {
			index: screen.nameKey,
			heading: this.locale.getString('mobile.screenNumber', { index: index + 1 })
		};
	};

	private isOptionalMissconfiguredScreen = (screen: MobileScreenSettings): boolean => {
		const optional = screen.optional;
		const configured = screen.type === MobileScreenType.SIMPLE_GROUPING
			&& this.mobileUtils.isSimpleGroupingScreenConfigured(screen as SimpleGroupingMobileScreenSettings);

		return optional && !configured;
	};

	private selectLastDisplayedScreenTab = (): void => {
		const displayedTabIndexes = this.getDisplayedScreenTabIndexes();
		this.activeTabIndex = displayedTabIndexes[displayedTabIndexes.length - 1];
	};

	private getDisplayedScreenTabIndexes = (): string[] => {
		return this.getDisplayedScreenTabs()
			.map(screenTab => screenTab.index);
	};

	private getDisplayedScreenTabs = (): UibTab[] => {
		return this.screenTabs
			.filter((screenTab, index) => !this.settings.screens[index].hidden);
	};

	private getFirstHiddenScreen = (): MobileScreenSettings => {
		return this.settings.screens.filter(screen => screen.hidden)[0];
	};

}

app.component('mobileNavigation', {
	controller: MobileNavigationController,
	templateUrl: 'partials/mobile/mobile-navigation.html',
	bindings: {
		settings: '=',
		widgetSettingsCache: '=',
		errors: '='
	}
});
