import { Component, OnInit, ChangeDetectionStrategy, Output, Inject, Input, EventEmitter } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { NameService } from '@cxstudio/common/name-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { DashboardSaveMode } from '@app/modules/dashboard/services/utils/dashboard-save-mode';
import { DashboardHistoryApiService } from '../services/dashboard-history-api-service/dashboard-history-api-service';
import { RecentDateService } from '../services/recent-date-service/recent-date.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';

export interface IDashboardVersion {
	id: number;
	performerName: string;
	timestamp: string;
	widgetsCount: number;
	published: boolean;
	saveMode: DashboardSaveMode;
	dashboard?: Dashboard;
}
@Component({
	selector: 'dashboard-versions',
	templateUrl: './dashboard-versions.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DashboardVersionsComponent implements OnInit {

	@Input() dashboardId: number;
	@Input() dashboardParentId: number;
	@Input() dashboardName: string;
	@Output() onChange = new EventEmitter<{ dashboardId: number; version: IDashboardVersion; saveAndQuit?: boolean }>();
	@Output() saveVersionAs = new EventEmitter<{ dashboardId: number; versionId: number; saveAsName: string; dashboardConfig: any }>();
	versions: IDashboardVersion[];
	loading: Promise<any>;
	selectedId: number;

	// Note: $rootScope needs to be addressed in a refactor
	constructor(
		private readonly dashboardHistoryApiService: DashboardHistoryApiService,
		private readonly locale: CxLocaleService,
		private readonly recentDateService: RecentDateService,
		private readonly dashboardListService: DashboardListService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		@Inject('dashboardService') private readonly dashboardService,
		@Inject('nameService') private readonly nameService: NameService,
		@Inject('security') private readonly security: Security,
	) { }

	ngOnInit(): void {
		this.loading = this.dashboardHistoryApiService.getDashboardVersions(this.dashboardId)
			.then(this.processVersions);
		this.selectedId = 0;
	}

	processVersions = (versions: IDashboardVersion[]): void => {
		this.versions = versions;
	};

	isSelected = (version: IDashboardVersion): boolean => {
		return version.id === this.selectedId;
	};

	selectVersion = (version: IDashboardVersion): void => {
		if (this.selectedId === version.id) {
			return;
		}
		this.selectedId = version.id;
		this.onChange.emit({ dashboardId: this.dashboardId, version });
	};

	revertVersion = (version: IDashboardVersion) => {
		let versionStr = this.recentDateService.formatToRecentDate(version.timestamp);
		let dlg = this.cbDialogService.confirm(this.locale.getString('home.restoreTitle'),
			this.locale.getString('home.restoreText', {
				timestamp: versionStr
			}),
			this.locale.getString('common.ok'), this.locale.getString('common.cancel'));
		dlg.result.then(() => {
			this.onChange.emit({ dashboardId: this.dashboardId, version, saveAndQuit: true });
		});
	};

	getVersionDescription = (version: IDashboardVersion) => {
		const formattedDate = this.recentDateService.formatToRecentDate(version.timestamp);
		const singleWidget = version.widgetsCount === 1;
		const countMessage = singleWidget
			? this.locale.getString('home.widgetsCountSingle',  { count: 1 })
			: this.locale.getString('home.widgetsCountPlural', { count: version.widgetsCount });
		return `${formattedDate} (${countMessage})`;
	};

	getVersionSaveStatus = (version: IDashboardVersion) => {
		let key = 'home.autosavedFor';
		if (version.published) {
			key = 'home.publishedBy';
		} else if (version.saveMode === DashboardSaveMode.MANUAL) {
			key = 'home.savedBy';
		}
		return this.locale.getString(key, { userName: version.performerName });
	};

	saveVersion = (version: IDashboardVersion) => {
		let newName = this.nameService.uniqueName(`${this.dashboardName} - ${this.locale.getString(
				'dashboard.copyName')}`, this.dashboardListService.getCurrentDashboardsList(), 'name');

		let customModalText = {
			title: this.locale.getString('dashboard.saveAsModalTitle'),
			nameInput: this.locale.getString('dashboard.saveAsNameLabel')
		};

		this.dashboardService.dashboardPropertiesDialog({
			name: newName,
			description: '',
			type: 'dashboard',
			parentId: this.dashboardParentId
		}, {
			isProperties: false,
			existingItem: false,
			hiddenItems: {
				description: true
			},
			customLabels: customModalText,
			explicitelyAllowFolderSelection: true,
			itemList: this.dashboardListService.getCurrentDashboardsList()
		}).result.then(saveResult => {
			let props = saveResult.model;
			let dashboardPropertiesExtension = {
				parentId: props.parentId
			};
			this.saveVersionAs.emit({
				dashboardId: this.dashboardId,
				versionId: version.id,
				saveAsName: props.name,
				dashboardConfig: dashboardPropertiesExtension
			});
		});
	};

	showSaveAs = () => {
		return this.security.has('create_dashboard');
	};
}

app.directive('dashboardVersions', downgradeComponent({ component: DashboardVersionsComponent }));
