import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { PreviewBaseComponent } from '@app/modules/widget-visualizations/feedback/preview-base.component';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { VisualizationDataService } from '@app/modules/widget-visualizations/visualization-data.service';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { WorldAwarenessService } from '@cxstudio/reports/document-explorer/world-awareness-attributes.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { PreviewService } from '@cxstudio/reports/preview/preview-service';
import { PreviewPredefinedColumns, PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { Security } from '@cxstudio/auth/security-service';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { ObjectUtils } from '@app/util/object-utils';
import { PreviewTableColumn } from '@cxstudio/reports/preview/preview-table-column';
import { ITableColumnFormatter } from '@cxstudio/reports/entities/table-column';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { PromiseUtils } from '@app/util/promise-utils';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { IColResizableOptions } from '@app/modules/widget-visualizations/feedback/col-resizable.directive';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { WidgetContentEventType } from '@app/modules/widget-visualizations/visualization-component.interface';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { UrlService } from '@cxstudio/common/url-service.service';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';

interface FormatterColumn {
	name: string;
	capitalization: CapitalizationType;
	type: string;
}

@Component({
	selector: 'preview-table',
	templateUrl: './preview-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewTableComponent extends PreviewBaseComponent implements OnInit, AfterViewInit {
	@ViewChild('tableContainer') private tableContainer: ElementRef<HTMLElement>;
	@ViewChild('tableBodyContainer') private tableBodyContainer: ElementRef<HTMLElement>;

	rows: PreviewSentence[];
	columns: PreviewTableColumn[];
	tableOptions: IColResizableOptions;

	readonly DEFAULT_SCROLL_WIDTH = 16;

	readonly DEFAULT_WIDTH_FOR_ENRICHMENT_COLUMNS = 205;
	readonly MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS = 52;
	readonly PREVIEW_COLUMN_HEADER_SELECTOR = '.preview-column-header';
	readonly COLUMN_RESIZER_SELECTOR = '.grip-resizable';

	sentimentMinified: boolean;
	easeScoreMinified: boolean;
	emotionMinified: boolean;

	PreviewColumn = PreviewColumn;

	constructor(
		ref: ChangeDetectorRef,
		locale: CxLocaleService,
		private visualizationDataService: VisualizationDataService,
		profanityDisguiseService: ProfanityDisguiseService,
		private currentObjects: CurrentObjectsService,
		private domSanitizer: DomSanitizer,
		@Inject('suggestionMenu') suggestionMenu: SuggestionMenu,
		@Inject('reportUtils') reportUtils: ReportUtils,
		@Inject('analyticPreviewFormatting') private analyticPreviewFormatting: AnalyticPreviewFormatting,
		@Inject('pointSelectionUtils') private pointSelectionUtils: PointSelectionUtils,
		@Inject('elementUtils') private elementUtils: ElementUtils,
		@Inject('worldAwarenessService') private worldAwarenessService: WorldAwarenessService,
		@Inject('previewService') private previewService: PreviewService,
		@Inject('previewPredefinedColumns') private previewPredefinedColumns: PreviewPredefinedColumns,
		@Inject('security') private security: Security,
		@Inject('urlService') private readonly urlService: UrlService
	) {
		super(ref, reportUtils, locale, profanityDisguiseService, suggestionMenu);
	}



	ngOnInit(): void {
		if (this.checkError(this.visualizationDataService.validateData(this.dataObject, this.widget.name)))
			return;

		this.previewService.changeColumnNames(this.widget.visualProperties, this.utils.attributes);

		this.addSubscription(this.currentObjects.getEditModeChange().subscribe(() => this.updateColumnResizing()));
		this.rows = [];
		this.columns = [];

		this.tableOptions = {
			onColumnDrag: this.onColumnDrag,
			resizeMode: 'fit',
			disable: !this.demo,
		};
		this.updateColumnResizing();
	}

	ngAfterViewInit(): void {
		this.initializeData();
		let tableContainerResizeHandler = _.throttle(this.onContainerResize, 400);
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(this.tableContainer.nativeElement, tableContainerResizeHandler));
	}

	onContainerResize = (): void => {
		this.setTableBodyVariables(this.getScrollWidth());
	};

	initializeData = (): void => {
		let filteringFeedbackSelection = this.getFilteringFeedbackSelection();
		if (filteringFeedbackSelection && filteringFeedbackSelection.isDisabled()) {
			filteringFeedbackSelection.ensureDisabled();
		}
		this.updateColumnUrlType();
		this.rows = this.dataObject.data;
		this.columns = ObjectUtils.copy(this.demo
			? _.filter(this.widget.visualProperties.columns, (column) => !column.disabled)
			: this.widget.visualProperties.columns);
		_.each(this.columns, column => {
			let formatterColumn = {
				name: column.name,
				type: column.type,
				capitalization: column.capitalization
			};
			column.displayName = this.getColumnName(column);

			column.formatter = column.urlType
				? this.analyticPreviewFormatting.urlFormatter(column)
				: this.getPredefinedFormatter(formatterColumn);
		});

		this.initWidths();

		let selectedSentenceId = this.pointSelectionUtils.getSelectedPoint(this.utils.containerId, this.utils.widgetId);
		if (selectedSentenceId && !this.demo) {
			this.rows.forEach(row => delete (row as any).selected);
			this.pointSelectionUtils.enablePointSelection(this.utils.containerId, this.utils.widgetId, true);
			let sentence = _.find(this.rows, {id: Number(selectedSentenceId)});
			if (sentence) {
				(sentence as any).selected = true;
				this.elementUtils.scrollTo(this.getRowId(sentence));
			}
		}

		this.reportUtils.handleWidgetRenderedEvent(this.utils.widgetId, this.utils.widgetType, this.utils.containerId);
		this.updateTableOptions({});

		this.ref.detectChanges(); // tests fail with ExpressionChangedAfterItHasBeenCheckedError without this
	};

	private getColumnName(column: PreviewTableColumn): string {
		if (column.name === PreviewColumn.SENTENCE) {
			switch (this.widget.properties.previewMode){
				case PreviewMode.SENTENCES_WITH_CONTEXT:
					return this.locale.getString('preview.sentencesWithContext');
				case PreviewMode.SENTENCES:
					// this is the default displayName for sentence col, so sort of unnecessary, but more clear if we make it explicit
					return this.locale.getString('preview.sentence');
				case PreviewMode.VERBATIM:
					return this.locale.getString('preview.verbatim');
				default:
					break;
			}
		}

		return this.previewPredefinedColumns.get(column.name as PreviewColumn)?.displayName || column.displayName;
	}

	private initWidths(): void {
		if (!this.customColumnsWidthSet()) {
			// init columns as though the table has scroll
			this.initDefaultColumnWidths(this.DEFAULT_SCROLL_WIDTH);
			setTimeout(() => {
				// recalculate columns widths based on relevant scroll width
				this.initDefaultColumnWidths(this.getScrollWidth());
			});
		} else {
			this.initColumnWidths(_.pluck(this.widget.visualProperties.columns, 'width'));
		}
	}

	private customColumnsWidthSet(): boolean {
		return !!this.widget.visualProperties.columns[0].width;
	}

	private updateColumnUrlType = (): void => {
		let attributes = this.widget.properties.selectedAttributes || [];
		attributes.forEach((attribute) => {
			let column = _.findWhere(this.widget.visualProperties.columns, { name: attribute.name });
			if (attribute.type === ReportAssetType.TEXT && column.urlType === undefined) {
				column.urlType = attribute.urlType;
				column.tableRowHeight = attribute.tableRowHeight;
			}
		});
	};

	private initColumnWidths(widths: number[]): void {
		let totalWidth = 0;
		_.each(this.columns, (column, index) => {
			column.width = _.isNumber(widths[index]) ? widths[index] : this.getDefaultColumnWidth(column.name);
			totalWidth += column.width;
		});
		_.each(this.columns, column => column.width /= totalWidth); // normalize width

		let scrollWidth: number = this.tableSizingRendered() ? this.getScrollWidth() : this.DEFAULT_SCROLL_WIDTH;
		this.setTableBodyVariables(scrollWidth);
		this.ref.markForCheck();
	}

	private tableSizingRendered(): boolean {
		return $(this.tableContainer.nativeElement).find('.sizing-header th').length > 0;
	}

	private initDefaultColumnWidths(scrollWidth: number): void {
		let tableWidth: number = this.getTableWidth(scrollWidth);

		let enrichmentColumnsAmount: number = 0;
		let nonEnrichmentColumnsPercentage: number = 0;
		_.each(this.columns, (column) => {
			if (this.previewPredefinedColumns.isEnrichmentColumn(column)) {
				enrichmentColumnsAmount++;
			} else {
				nonEnrichmentColumnsPercentage += this.getDefaultColumnWidth(column.name);
			}
		});

		this.populateColumnWidthsInPixels(tableWidth, enrichmentColumnsAmount, nonEnrichmentColumnsPercentage);

		_.each(this.columns, column => column.width /= tableWidth); // normalize width

		this.setTableBodyVariables(scrollWidth);
	}

	private getTableWidth(scrollWidth: number): number {
		return $(this.tableContainer.nativeElement).width() - scrollWidth;
	}

	private setTableBodyVariables(scrollWidth: number): void {
		let tableWidth: number = this.getTableWidth(scrollWidth);

		_.each(this.columns, (column) => {
			if (this.previewPredefinedColumns.isSentimentColumn(column)) {
				let columnWidthPixels: number = column.width * tableWidth;
				this.sentimentMinified = columnWidthPixels < this.DEFAULT_WIDTH_FOR_ENRICHMENT_COLUMNS;
			} else if (this.previewPredefinedColumns.isEffortColumn(column)) {
				let columnWidthPixels: number = column.width * tableWidth;
				this.easeScoreMinified = columnWidthPixels < this.DEFAULT_WIDTH_FOR_ENRICHMENT_COLUMNS;
			} else if (this.previewPredefinedColumns.isEmotionColumn(column)) {
				let columnWidthPixels: number = column.width * tableWidth;
				this.emotionMinified = columnWidthPixels < this.DEFAULT_WIDTH_FOR_ENRICHMENT_COLUMNS;
			}
		});
		this.ref.markForCheck();
	}

	private populateColumnWidthsInPixels(
		tableContainerWidth: number, enrichmentColumnsAmount: number, nonEnrichmentColumnsPercentage: number): void {
			let widthForNonEnrichmentColumns: number = tableContainerWidth - (enrichmentColumnsAmount * this.DEFAULT_WIDTH_FOR_ENRICHMENT_COLUMNS);
			let widthPerOnePercent: number = widthForNonEnrichmentColumns / (nonEnrichmentColumnsPercentage * 100);
			_.each(this.columns, (column) => {
				column.width = widthPerOnePercent * this.getDefaultColumnWidth(column.name) * 100;
			});
	}

	private onColumnDrag = (pixelWidths: number[], event: MouseEvent) => {
		if (event.type === 'mouseup') {
			_.each(pixelWidths, (columnWidth, columnIndex) => {
				let column: PreviewTableColumn = this.columns[columnIndex];
				if (this.previewPredefinedColumns.isEnrichmentColumn(column) && columnWidth < this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS) {
						this.updateColumnResizerPosition(columnWidth, columnIndex);
						this.updateHeaderColumnsWidths(columnIndex);
						this.updateBodyColumnsPixelWidths(pixelWidths, columnIndex);
				}
			});
		}

		this.initColumnWidths(pixelWidths);
		this.contentEvent.emit({type: WidgetContentEventType.RESIZE_TABLE_COLUMNS, args: [this.columns]});
	};

	private updateColumnResizerPosition = (decreasedColumnWidth: number, decreasedColumnIndex: number): void => {
		let differenceWithMinimalWidth: number = this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS - decreasedColumnWidth;

		let colResizerElement: JQuery = $($(this.tableContainer.nativeElement)
			.find(this.COLUMN_RESIZER_SELECTOR)[decreasedColumnIndex]);

		if (colResizerElement && colResizerElement[0]) {
			let colResizerOffset = colResizerElement.offset();
			colResizerOffset.left += differenceWithMinimalWidth;
			colResizerElement.offset(colResizerOffset);

			this.updateTableOptions({});
		}
	};

	private updateHeaderColumnsWidths = (decreasedColumnIndex: number): void => {
		let decreasedColumnHeaderWidth = $($(this.tableContainer.nativeElement)
			.find(this.PREVIEW_COLUMN_HEADER_SELECTOR)[decreasedColumnIndex].parentElement)
			.outerWidth();
		let decreasedColumnWidthDifference = this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS - decreasedColumnHeaderWidth;

		this.increaseColumnHeaderToMinimalValue(decreasedColumnIndex);
		this.decreaseColumnHeaderWidth(decreasedColumnIndex + 1, decreasedColumnWidthDifference);
	};

	private increaseColumnHeaderToMinimalValue(columnIndex: number): void {
		let descreasedColumnHeader: JQuery = $($(this.tableContainer.nativeElement)
			.find(this.PREVIEW_COLUMN_HEADER_SELECTOR)[columnIndex].parentElement);
		descreasedColumnHeader.css('width', `${this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS}`);
	}

	private decreaseColumnHeaderWidth(columnIndex: number, decreaseToWidth: number): void {
		let columnHeader: JQuery = $($(this.tableContainer.nativeElement)
			.find(this.PREVIEW_COLUMN_HEADER_SELECTOR)[columnIndex].parentElement);
		columnHeader.css('width', columnHeader.outerWidth() - decreaseToWidth);
	}

	private updateBodyColumnsPixelWidths = (columnsWidthsInPixels: number[], decreasedColumnIndex: number): void => {
		let differenceWithMin = this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS - columnsWidthsInPixels[decreasedColumnIndex];
		columnsWidthsInPixels[decreasedColumnIndex] = this.MINIMAL_WIDTH_FOR_ENRICHMENT_COLUMNS;
		columnsWidthsInPixels[decreasedColumnIndex + 1] -= differenceWithMin;
	};

	private getPredefinedFormatter(column: FormatterColumn): ITableColumnFormatter<PreviewSentence> {
		if (column.type === PreviewDataType.SYS) {
			return this.getSystemColumnFormatter(column);
		}
		//VERBATIM_TYPE has TEXT type, so not handled by SYS condition
		if (column.name === PreviewColumn.VERBATIM_TYPE) {
			return this.analyticPreviewFormatting.verbatimTypeFormatter();
		}
		if (column.type === 'DATE') {
			return this.analyticPreviewFormatting.dateAttributeFormatter();
		}
		if (column.type === PreviewDataType.NUMBER) {
			return this.analyticPreviewFormatting.numberAttributeFormatter();
		}
		if (this.worldAwarenessService.isNLPAttribute(column.name))
			return this.analyticPreviewFormatting.nlpAttributeFormatter(column.capitalization);
		return this.analyticPreviewFormatting.attributeFormatter(column.capitalization);
	}

	private getSystemColumnFormatter(column: FormatterColumn): ITableColumnFormatter<PreviewSentence> {
		switch (column.name) {
			case PreviewColumn.FEEDBACK_SELECTION:
				return this.analyticPreviewFormatting.feedbackSelectionFormatter(this.getFilteringFeedbackSelection());
			case PreviewColumn.SOURCE:
				return this.analyticPreviewFormatting.sourceFormatter(column.capitalization);
			case PreviewColumn.SENTIMENT: {
				let sentiment = _.findWhere(this.utils.metrics, {name: PredefinedMetricConstants.SENTIMENT});
				return this.analyticPreviewFormatting.sentimentFormatter(sentiment, {asPill: true},
					this.isEnrichmentTuningAllowed(SuggestionType.SENTIMENT));
			}
			case PreviewColumn.SENTENCE: {
				let sentiment = _.findWhere(this.utils.metrics, {name: PredefinedMetricConstants.SENTIMENT});
				let isFormatSentence = this.widget.visualProperties.sentimentHighlightingEnabled;
				return this.analyticPreviewFormatting.sentenceFormatter(isFormatSentence, sentiment, column.capitalization);
			}
			case PreviewColumn.EFFORT:
			case PreviewColumn.EMOTION: {
				let metric = _.findWhere(this.utils.metrics, {name: column.name});
				if (metric)
					if (column.name === PreviewColumn.EFFORT)
						return this.analyticPreviewFormatting.easeScore(metric, {asPill: true},
							this.isEnrichmentTuningAllowed(SuggestionType.EFFORT));
					else return this.analyticPreviewFormatting.emotion(metric, {asPill: true},
						this.isEnrichmentTuningAllowed(SuggestionType.EMOTION));
				break;
			}
			case PreviewColumn.DOC_DATE:
				return this.analyticPreviewFormatting.documentDateFormatter(this.utils.projectTimezone);
			case PreviewColumn.TOPICS:
				return this.analyticPreviewFormatting.topicsFormatter(this.isEnrichmentTuningAllowed(SuggestionType.TOPIC),
					this.utils.tuningSuggestionModels, column.capitalization);
		}
		throw new Error(`Threre is no formatter for requested system column ${column.name}`);
	}

	private isEnrichmentTuningAllowed(type: SuggestionType): boolean {
		return this.utils.auditMode && this.security.getCurrentMasterAccount().tuningSuggestionsSettings[type];
	}

	getHtml = (column: PreviewTableColumn, row: PreviewSentence): SafeHtml => {
		let html: string;
		if (this.previewPredefinedColumns.isEnrichmentColumn(column)) {
			// generate 2 versions: minified and fullsize. css is used to show only one of them
			html = column.formatter(row, column.name) + column.formatter(row, column.name, {iconWithBorder: true});
		} else {
			html = column.formatter(row, column.name);
		}

		return this.domSanitizer.bypassSecurityTrustHtml(html);
	};

	private getDefaultColumnWidth(name: string): number {
		switch (name) {
			case PreviewColumn.FEEDBACK_SELECTION:
			case PreviewColumn.SOURCE:
			case PreviewColumn.VERBATIM_TYPE: return 0.1;
			case PreviewColumn.EMOTION:
			case PreviewColumn.EFFORT:
			case PreviewColumn.SENTIMENT: return 0.2;
			case PreviewColumn.SENTENCE: return 0.7;
		}
		return Math.min(1.0 / this.widget.visualProperties.columns.length, 0.25);
	}

	handleRowClick = (sentence: PreviewSentence, event: MouseEvent): void => {
		if (this.isFeedbackSelection()) {
			this.curateSentence(sentence);
			event.stopPropagation();
			return;
		}

		// bypass sentence click functionality if a link element was clicked
		if (!this.urlService.isLinkClick(event)) {
			super.handleSentenceClick(sentence, event, '.cb-table-row');
		}
	};

	handleRowEnter = (sentence: PreviewSentence, event: KeyboardEvent): void => {
		if (this.isFeedbackSelection()) {
			this.curateSentence(sentence);
			event.stopPropagation();
			return;
		}
		if (!this.urlService.isLinkClick(event)) {
			// pass this keyboardEvent after casting it as the properties used in the functions overlap with MouseEvent
			super.handleSentenceClick(sentence, event as any as MouseEvent, '.cb-table-row');
		}
	};

	handleCellClick = (sentence: PreviewSentence, column: PreviewTableColumn, event: MouseEvent | KeyboardEvent): void => {
		if (this.utils.auditMode && sentence) {
			if (this.profanityDisguiseService.needToMaskSentence(sentence)) {
				return;
			}
			if (this.isPillColumn(column)) {
				if (column.name === PreviewColumn.TOPICS) {
					// this will be replaced with regular components with native click handling once we switch to ag-grid
					if (this.hasClassInDomTree(event.target, 'pill-remove')) {
						event.stopPropagation();
						this.wrapMenuLoading(this.suggestionMenu.handleSentenceTopicRemoval(event, sentence, this.widget));
					}
				} else {
					if (this.hasClassInDomTree(event.target, 'enrichment-pill')) {
						event.stopPropagation();
						this.wrapMenuLoading(PromiseUtils.wrap(
							this.suggestionMenu.openSentenceSuggestionMenu(event, sentence, this.widget,
								this.widget.visualProperties.preferences?.settings?.leafOnly,
								PillsUtils.previewColumnToPillType(column.name as PreviewColumn))));
					}
				}
			}
		}
	};

	private isPillColumn(column: PreviewTableColumn): boolean {
		return column.type === PreviewDataType.SYS
			&& (column.name === PreviewColumn.TOPICS
				|| column.name === PreviewColumn.SENTIMENT
				|| column.name === PreviewColumn.EFFORT
				|| column.name === PreviewColumn.EMOTION);
	}

	private hasClassInDomTree(element: EventTarget, className: string): boolean {
		return !!$(element).parents('.' + className).length || $(element).hasClass(className);
	}

	private wrapMenuLoading(promise: Promise<any>): void {
		this.menuLoading = true;
		this.ref.markForCheck();
		promise.finally(() => {
			this.menuLoading = false;
			this.ref.markForCheck();
		});
	}

	private isFeedbackSelection(): boolean {
		let filteringFeedbackSelection = this.utils.filteringFeedbackSelection;
		return filteringFeedbackSelection && filteringFeedbackSelection.isEnabled();
	}

	curateSentence(sentence: PreviewSentence): void {
		this.getFilteringFeedbackSelection().curateItem(sentence);
	}

	private getFilteringFeedbackSelection(): IAnalyticFeedbackSelection {
		return this.utils.filteringFeedbackSelection;
	}

	getHeaderWidth = (): string => {
		let scrollWidth = this.getScrollWidth();
		if (scrollWidth > 0) {
			return `calc(100% - ${scrollWidth}px)`;
		}
		return null;
	};

	private getScrollWidth(): number {
		let body = this.tableBodyContainer?.nativeElement;
		if (body && (body.scrollHeight > body.clientHeight)) {
			return body.offsetWidth - body.clientWidth;
		}
		return 0;
	}

	private updateColumnResizing = (): void => {
		let resizingEnabled = this.currentObjects.isEditMode() || this.demo;
		this.updateTableOptions({disable: !resizingEnabled});
	};

	onKeydown = (event: KeyboardEvent): void => {
		let focusedItem = $(event.target);
		if (KeyboardUtils.isEventKeyOneOf(event, [Key.LEFT, Key.RIGHT, Key.UP, Key.DOWN])
			&& focusedItem.length) {
			KeyboardUtils.intercept(event);
			if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
				focusedItem.prev().trigger('focus');
			}
			if (KeyboardUtils.isEventKey(event, Key.RIGHT)) {
				focusedItem.next().trigger('focus');
			}
			let rowIdx = focusedItem.parents('tr').index();
			let cellIdx = focusedItem.index();
			let isHeader = !!focusedItem.parents('.table-header').length;
			if (KeyboardUtils.isEventKey(event, Key.UP) && !isHeader) {
				this.focusCell(rowIdx - 1, cellIdx);
			}
			if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
				let newRowIdx = isHeader ? 0 : rowIdx + 1;
				this.focusCell(newRowIdx, cellIdx);
			}
		}
	};

	private focusCell = (rowIdx: number, cellIdx: number): void => {
		if (rowIdx === -1) {
			$(this.tableContainer.nativeElement).find('th:focusable').eq(cellIdx).trigger('focus');
		} else {
			$(this.tableContainer.nativeElement).find('.cb-table-row').eq(rowIdx)
				.find('td:focusable').eq(cellIdx).trigger('focus');
		}
	};

	getSentimentColumnClass(): string {
		if (this.isSentimentColumnClassAppliable()) {
			return this.sentimentMinified ? 'sentiment-minified' : 'sentiment-fullsize';
		}
		return '';
	}

	private isSentimentColumnClassAppliable(): boolean {
		return !!_.findWhere(this.columns, { name: PreviewColumn.SENTIMENT });
	}

	getEaseScoreColumnClass(): string {
		if (this.isEaseScoreColumnClassAppliable()) {
			return this.easeScoreMinified ? 'easeScore-minified' : 'easeScore-fullsize';
		}
		return '';
	}

	private isEaseScoreColumnClassAppliable(): boolean {
		return !!_.findWhere(this.columns, { name: PreviewColumn.EFFORT });
	}

	getEmotionColumnClass(): string {
		if (this.isEmotionColumnClassAppliable()) {
			return this.emotionMinified ? 'emotion-minified' : 'emotion-fullsize';
		}
		return '';
	}

	private isEmotionColumnClassAppliable(): boolean {
		return !!_.findWhere(this.columns, { name: PreviewColumn.EMOTION });
	}

	getRowId(row: PreviewSentence): string {
		return `widgetId-${this.utils.widgetId}-rowId-${row.id}`;
	}

	private updateTableOptions(newOptions: Partial<IColResizableOptions>): void {
		this.tableOptions = _.extend({}, this.tableOptions, newOptions);
		this.ref.markForCheck();
	}
}
