import * as cloneDeep from 'lodash.clonedeep';

import { Component, Input, Output, Inject, ChangeDetectionStrategy, ViewChild, EventEmitter, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterRuleType, FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterValidationMessages } from '@cxstudio/report-filters/constants/filter-validation-messages.constant';
import { HardcodedFilterType, MixedFilter, MixedFilterItem, MixedItemType as MixedFilterItemType, MixedFilterRuleEntity } from '@cxstudio/report-filters/filter-builder/mixed-filter';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

@Component({
	selector: 'interaction-filter',
	changeDetection: ChangeDetectionStrategy.Default,
	templateUrl: './interaction-filter.component.html'
})
export class InteractionFilterComponent {

	static readonly MAX_ITEMS = 15;

	@Input() projectSelection: IProjectSelection;

	@Output() onApplyFilter = new EventEmitter<{ filter: MixedFilter }>();
	@Output() onResetFilter = new EventEmitter<void>();

	@ViewChild(NgbDropdown)
	private dropdown: NgbDropdown;

	maxItems = InteractionFilterComponent.MAX_ITEMS;
	_filter: MixedFilter;

	filterItems;
	initialSavedFilterOptions;
	savedFilterOptions;
	dropdownDisplayed: boolean = false;

	filterRulesError;
	countError: boolean;

	constructor(
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
		private locale: CxLocaleService,
		@Inject('filterValidationService') private filterValidationService: FilterValidationService,
		private elementRef: ElementRef) {
	}

	isProjectSelected(): boolean {
		return this.projectSelection && this.projectSelection.projectId >= 0;
	}

	applyFilter = (): void => {
		if (this.isFilterValid()) {
			this.onApplyFilter.emit({ filter: this.cleanUpEmptyFilter(this._filter) });
			this.dropdown.close();
		}
	};

	private cleanUpEmptyFilter(filter: MixedFilter): MixedFilter {
		return {
			items: _.filter(filter.items, filterItem => {
				if (filterItem.type === MixedFilterItemType.FILTER_RULE)
					return !FilterRuleTypes.isEmptyRule(filterItem.entity as IFilterRule);
				else return true;
			})
		};
	}

	private isFilterValid = (): boolean => {
		let validationResult = this.validateFilter();
		let validResult = validationResult === FilterValidationMessages.VALID || validationResult === null;
		this.filterRulesError = validResult ? null : validationResult;

		return validResult;
	};

	private validateFilter = (): any => {
		if (this._filter && this._filter.items) {
			let filterRules = this.getFilterRules().filter((filterRule) => {
				return filterRule.type !== FilterRuleType.empty;
			});

			if (filterRules.length) {
				let validationResult = this.filterValidationService.getValidationResult({ filterRules });
				if (FilterValidationMessages.VALID !== validationResult) {
					return validationResult;
				}
			}
		}

		return null;
	};

	resetFilter = (): void => {
		this.onResetFilter.emit();
		this.filterRulesError = false;
		this.countError = false;
		this.checkRestrictedRuleOptions();
	};

	private checkCount = (): void => {
		let count = this._filter ? _.filter(this._filter.items, (item) =>  !this.isTextFilter(item)).length : 0;
		this.countError = count >= this.maxItems;
		if (count === 0) {
			this.onResetFilter.emit();
		}
	};

	private isTextFilter = (rule: MixedFilterItem): boolean => {
		return rule.type === MixedFilterItemType.FILTER_RULE && rule.entity.type === FilterRuleType.text;
	};

	canAddMore = (): boolean => {
		return !this.countError && !this.hasSharedDocumentFilter();
	};

	hasSharedDocumentFilter(): boolean {
		return !this.getFilterItems(MixedFilterItemType.HARDCODED_FILTER)
			.filter((entity) => entity?.type === HardcodedFilterType.SHARED_DOCUMENTS)
			.isEmpty();
	}

	getCountNote = (): string => {
		return this.locale.getString('filter.filterLimitPrompt', { maxCount: this.maxItems });
	};

	@Input() set assets(assets: IProjectSettings) {
		this.populateFilterItems(assets);
	}

	@Input() set savedFilters(savedFilters) {
		this.populateSavedFilterOptions(savedFilters);
	}

	@Input() set filter(filter: MixedFilter) {
		this._filter = filter;
		this.repopulateAvailableSavedFilterOptions();
		this.checkRestrictedRuleOptions();
	}

	private populateFilterItems = (assets: IProjectSettings): void => {
		if (assets) {
			let documentLevelOnly = false;
			this.filterItems = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.filterItemsDefault({text: 'input'}))
				.withModels(assets.models)
				.withAttributes(cloneDeep(assets.attributes), MetricFilters.NOT_DOCUMENT_DATE)
				.withWordAttributes(assets.wordAttributes)
				.withDocumentLevelOnly(documentLevelOnly)
				.build();
		}
	};

	private populateSavedFilterOptions = (savedFilters): void => {
		if (savedFilters) {
			savedFilters = cloneDeep(savedFilters);
			_.each(savedFilters, (folder: any) => {
				if (!folder) {
					return;
				}
				folder.list = _.filter(folder.list, (filter: any) => {
					return !filter.hide;
				});
			});
			this.initialSavedFilterOptions = savedFilters;
			this.repopulateAvailableSavedFilterOptions();
		}
	};

	onAddSavedFilter = (filter): void => {
		this.addSavedFilter(filter);
		this.repopulateAvailableSavedFilterOptions();
		this.onItemAdded();
	};

	private addSavedFilter = (filter): void => {
		this._filter.items.push({
			type: MixedFilterItemType.SAVED_FILTER,
			entity: filter
		});
	};

	onItemAdded = (): void => {
		this.onItemsChanged();
		setTimeout(() => this.scrollNewFilterRuleIntoView(), 0);
	};

	private scrollNewFilterRuleIntoView = (): void => {
		$(this.elementRef.nativeElement).find('.filter-statement').last()[0].scrollIntoView();
	};

	onItemRemoved = (): void => {
		this.onItemsChanged();
	};

	onItemChanged = (): void => {
		this.onItemsChanged();
	};

	private onItemsChanged = (): void => {
		this.repopulateAvailableSavedFilterOptions();
		this.checkRestrictedRuleOptions();
		this.checkCount();
	};

	onChangedSavedFilter = (index, savedFilter): void => {
		this._filter.items[index].entity = savedFilter;
		this.repopulateAvailableSavedFilterOptions();
	};

	repopulateAvailableSavedFilterOptions = (): void => {
		if (!this.savedFilterOptions) {
			this.savedFilterOptions = cloneDeep(this.initialSavedFilterOptions);
		}

		if (this.savedFilterOptions) {
			let appliedSavedFilters = this.getAppliedSavedFilters();
			this.savedFilterOptions.forEach(folder => {
				if (folder && folder.list) {
					folder.list.forEach(option => {
						option.hideOption = !!_.findWhere(appliedSavedFilters, { name: option.name, type: option.type });
					});
				}
			});
		}
	};

	private getAppliedSavedFilters = (): any[] => {
		return this.getFilterItems(MixedFilterItemType.SAVED_FILTER);
	};

	private getFilterRules = (): IFilterRule[] => {
		return this.getFilterItems(MixedFilterItemType.FILTER_RULE) as IFilterRule[];
	};

	private getFilterItems = (type: MixedFilterItemType): MixedFilterRuleEntity[] => {
		if (this._filter && this._filter.items) {
			return this._filter.items
				.filter(item => item.type === type)
				.map(item => item.entity);
		} else {
			return [];
		}
	};

	private checkRestrictedRuleOptions = () => {
		let textRule = _.find(this.getFilterRules(), (entity) => entity.type === FilterRuleType.text);
		let textOption = SearchableHierarchyUtils.findMetricInHierarchy(this.filterItems || [],
			{name: FilterRuleTypes.TEXT_FILTER_WITH_INPUT, type: FilterRuleType.text});
		if (textOption) textOption._disabled = !!textRule;
	};
}

app.directive('interactionFilter', downgradeComponent({ component: InteractionFilterComponent }));
