import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { DrillToSentenceProcessorService } from './drill-to-sentence-processor.service';

@Injectable({
	providedIn: 'root'
})
export class DrillToDocumentExplorerProcessorService {

	constructor(
		readonly drillToSentenceProcessor: DrillToSentenceProcessorService,
		readonly locale: CxLocaleService,
	) { }

	drillTo = (widget: DocumentExplorerWidget, drillToType, point: DrillPoint): DocumentExplorerWidget => {
		const sentenceReport = this.drillToSentenceProcessor.drillTo(widget, drillToType, point);

		let documentExplorerDrillPointDisplayName = point.displayName;
		if (point.documentId) {
			documentExplorerDrillPointDisplayName = this.locale.getString(
				'widget.documentIdentifier',
				{ documentIdentifier: documentExplorerDrillPointDisplayName }
			);
		}

		// eslint-disable-next-line max-len
		sentenceReport.documentExplorerName = `${this.locale.getString('widget.docExplorer')} - ${(documentExplorerDrillPointDisplayName ?? widget.displayName)}`;
		sentenceReport.documentExplorerDrillPointDisplayName = documentExplorerDrillPointDisplayName;

		//add information about drilling from pop parent widget
		if (point._pop === DatePeriodName.PERIOD2) {
			sentenceReport.ignoreDateRangeFilters = true;
		}

		return sentenceReport;
	};

}
