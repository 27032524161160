import { ElementRef } from '@angular/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { MetricCssVariables } from './metric-css-variables.class';
import { MetricWidgetSizer } from './metric-widget-sizer.class';

export class VerticalMetricWidgetSizer extends MetricWidgetSizer {

	constructor() {
		super();
	}

	getChartWidth(widget: ElementRef): number {
		let container = widget.nativeElement.querySelector('.svg-wrapper');
		return container ? container.offsetWidth : 0;
	}

	isFitHorizontal(widget: ElementRef, cssVars: MetricCssVariables): boolean {
		let availableSpace = widget.nativeElement.offsetWidth;
		let neededChartSpace = this.getChartWidth(widget) > 0 ?
			cssVars.chartWidth + cssVars.chartSpacing : 0;

		let neededSpace = cssVars.mainFontSize * this.defaults.fontLetterWidthRatio
			* this.defaults.targetNumberOfDigits + cssVars.mainFontSize * this.defaults.separatorCharacterWidthRatio
			* (this.defaults.targetNumberOfSeparators + this.defaults.targetNumberOfDigits)
			+ (2 * this.defaults.margin) + neededChartSpace;
		return availableSpace >= neededSpace;
	}

	isFitVertical(widget: ElementRef, cssVars: MetricCssVariables,
		visualProps: VisualProperties, isHistoricValueHidden: boolean = false): boolean {
		let availableSpace = widget.nativeElement.offsetHeight;
		let neededSpace;

		if (!visualProps.previousPeriod) {
			neededSpace = cssVars.mainFontSize + this.defaults.labelSpacing + this.defaults.labelFontSize;
		} else {
			let historicValueSpace = isHistoricValueHidden ? 0 :
				cssVars.verticalSeparation + cssVars.secondaryFontSize + this.defaults.labelSpacing + this.defaults.labelFontSize;

			neededSpace = cssVars.mainFontSize + this.defaults.labelSpacing + this.defaults.labelFontSize + this.defaults.deltaSpacing +
				cssVars.deltaFontSize + historicValueSpace + (2 * this.defaults.margin);
		}

		return availableSpace >= neededSpace;
	}

	isPeriodTextFit(widget: ElementRef, cssVars: MetricCssVariables, visualProps: VisualProperties): boolean {
		return this.isFitHorizontal(widget, cssVars) && this.isFitVertical(widget, cssVars, visualProps);
	}


	getAdditionalCssVars = (widget: ElementRef, mainMetricSize: number): Partial<MetricCssVariables> => {
		const MAX_CHART_WIDTH = 96;
		const MIN_CHART_WIDTH = 80;

		let widgetSize = this.getWidgetDimensions(widget);
		let cssVars: Partial<MetricCssVariables> = {};
		cssVars.chartSpacing = 16;
		cssVars.chartWidth = 80;

		if (widgetSize.width > 400) {
			cssVars.chartSpacing = Math.max((mainMetricSize / 2) - ((mainMetricSize / 2) % 8), 16);
		}
		if (widgetSize.width > (400 + cssVars.chartSpacing)) {
			cssVars.chartWidth = MAX_CHART_WIDTH;
		}

		return cssVars;
	};
}
