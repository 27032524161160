import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';

@Component({
	selector: 'report-text-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex flex-fill no-border">
			<clearable-input
				class="flex-fill"
				[(ngModel)]="currentValue"
				(keydown)="onSearchKeydown($event)"
				[placeholder]="placeholder"
				[ariaLabel]="inputLabel"
				[maxlength]="120"
				(onClear)="currentValue = ''; applyTextFilter()">
			</clearable-input>
		</div>
		<button type="button"
			class="btn btn-primary q-icon-search"
			(click)="applyTextFilter()"
			(keydown)="onSearchKeydown($event)"
			[disabled]="!textFilterChanged()"
			[attr.aria-label]="'filter.applyTextFilter'|i18n">
		</button>`
})

export class ReportTextFilterComponent implements OnInit, OnChanges {
	@Input() initialValue: string;
	@Input() inputLabel: string;
	@Input() placeholder: string;
	@Output() filterChange: EventEmitter<string> = new EventEmitter();

	currentValue: string;

	constructor(
	) {}

	ngOnInit(): void {
		this.currentValue = this.initialValue;
	}

	ngOnChanges(changes: SimpleChanges<ReportTextFilterComponent>): void {
		if (ChangeUtils.hasChange(changes.initialValue)) {
			this.currentValue = this.initialValue;
		}
	}

	applyTextFilter = (): void => {
		if (this.textFilterChanged()) {
			this.initialValue = this.currentValue;
			this.filterChange.emit(this.currentValue);
		}
	};

	textFilterChanged = (): boolean => {
		return this.currentValue !== this.initialValue;
	};

	onSearchKeydown = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.preventDefault();
			event.stopPropagation();
			this.applyTextFilter();
		}
	};

}

app.directive('reportTextFilter', downgradeComponent({component: ReportTextFilterComponent}));
