import { ChangeDetectionStrategy, Component, Inject, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { SearchableHierarchyUtils, INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { INumericFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DisplayThreshold } from '@cxstudio/reports/entities/display-threshold';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';
import * as cloneDeep from 'lodash.clonedeep';
import { ThresholdMetricService } from '@app/modules/widget-settings/grouping/threshold-configuration/threshold-metric.service';

@Component({
	selector: 'threshold-configuration',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div class="mb-16 threshold-selection">
	<label>{{'widget.displayThreshold'|i18n}}</label>
	<div class="d-flex align-items-center">
		<filter-rule class="d-flex flex-wrap"
			attr.aria-label="{{'widget.displayThreshold'|i18n}}"
			[rule]="thresholdFilter"
			(ruleChange)="processDisplayThreshold()"
			[projectSelection]="project"
			[filterableFields]="thresholdMetrics">
			<simple-number-format-preview
				class="ml-8"
				[format]="getThresholdFormat()"
				[previewValue]="thresholdFilter.value"
				*ngIf="showSingleValueFormat()">
			</simple-number-format-preview>

			<div *ngIf="showRangeFormat()" class="d-flex ml-8">
				<simple-number-format-preview
					[format]="getThresholdFormat()"
					[previewValue]="thresholdFilter.from">
				</simple-number-format-preview>
				<span class="mh-4" [i18n]="'reportFilters.to'"></span>
				<simple-number-format-preview
					[format]="getThresholdFormat()"
					[previewValue]="thresholdFilter.to">
				</simple-number-format-preview>
			</div>
		</filter-rule>
	</div>
</div>
<cb-notice type="'warning'" [hidden]="!ui.thresholdError">{{'widget.invalidThreshold'|i18n}}</cb-notice>`
})

export class ThresholdConfigurationComponent implements OnInit {

	@Input() ui: {
		thresholdError?: boolean;
		validationChecks: Array<() => boolean>;
	} = { validationChecks: [] };
	@Input() item: AttributeGrouping;
	@Input() project: AccountOrWorkspaceProject;
	@Input() allMetrics: INode[];
	@Input() selectedMetrics: ReportCalculation[];
	@Input() useHistoricPeriod: boolean;
	@Output() validityChange = new EventEmitter<boolean>();

	thresholdMetrics: INode[];
	thresholdFilter: INumericFilterRule;

	constructor(
		@Inject('filterMatchModes') private readonly filterMatchModes: FilterMatchModes,
		private readonly thresholdMetricService: ThresholdMetricService,
		@Inject('MetricCalculationsType') private readonly MetricCalculationsType: MetricCalculationsTypeService,
		@Inject('periodOverPeriodMetricService') private readonly periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private readonly locale: CxLocaleService
	) {}


	ngOnInit(): void {
		let defaultThresholdFilter = cloneDeep(this.thresholdMetricService.getDefault());
		this.thresholdFilter = _.extend({}, defaultThresholdFilter,
			this.getThresholdFilterRule(this.item.displayThreshold));

		let popOptions = this.getPopOptions();
		let popThresholdMetrics = [];
		if (popOptions.length > 0) {
			popThresholdMetrics = [{
				name: 'popOptions',
				displayName: this.locale.getString('widget.period_over_period'),
				forcedOrder: true,
				children: popOptions
			}];
		}

		this.thresholdMetrics = [ cloneDeep(this.thresholdMetricService.getDefault()) ].concat(this.allMetrics).concat(popThresholdMetrics);

		this.ui.validationChecks.push(this.processDisplayThreshold);
	}


	private getPopOptions = (): ReportCalculation[] => {
		let popOptions = [];

		// if PoP isnt enabled, just return empty array
		if (!this.useHistoricPeriod) {
			return [];
		}

		this.selectedMetrics.forEach(metric => {
			if (metric.name.startsWith(PopDiffPrefix.DELTA) ||
				metric.name.startsWith(PopDiffPrefix.HISTORIC) ||
				metric.name.startsWith(PopDiffPrefix.PERCENT_CHANGE) ||
				metric.name.startsWith(PopDiffPrefix.P_VALUE) ||
				metric.name.startsWith(PopDiffPrefix.SIGNIFICANCE)) {
					return;
			}

			[ PeriodOverPeriodMetricType.HISTORICAL,
				PeriodOverPeriodMetricType.DELTA,
				PeriodOverPeriodMetricType.PERCENT_CHANGE].forEach(
					metricType => popOptions.push(this.periodOverPeriodMetricService.createMetric(metric, metricType))
				);

			if (this.periodOverPeriodMetricService.isStatisticalMetricsSupported(metric)) {
				[PeriodOverPeriodMetricType.SIGNIFICANCE, PeriodOverPeriodMetricType.P_VALUE].forEach(
					metricType => popOptions.push(this.periodOverPeriodMetricService.createMetric(metric, metricType))
				);
			}
		});

		popOptions = _.chain(popOptions)
				.filter((metric: Partial<ReportCalculation>) => {
					return !metric.definition || !this.MetricCalculationsType.VARIABLE.is(metric);
				}).map(metric => {
					return _.pick(metric, ['displayName', 'name', 'type', 'metricType', 'calculationType', 'definition',
						'isPopMetric']);
				}).value();

		// need to remove property flags used to control adding/removing calculations in table widget
		_.map(popOptions, (metric: any) => {
			delete metric.hidden;
			delete metric._disabled;
		});

		return popOptions;
	};

	private getThresholdCriteria = (filter: INumericFilterRule ) => {
		switch (filter.type) {
		case FilterRuleType.numericOpenRange:
			return filter.matchMode === FilterMatchModeValue.IS ? {gte: filter.value} : {lte: filter.value};
		case FilterRuleType.numericRange:
			return filter.matchMode === FilterMatchModeValue.IS
				? {gte: filter.from, lte: filter.to}
				: {gt: filter.from, lt: filter.to};
		default:
			return {};
		}
	};

	private getThresholdFilterRule(displayThreshold: DisplayThreshold): INumericFilterRule {
		if (_.isEmpty(displayThreshold)) {
			return undefined;
		}

		let criteria = displayThreshold.criteria;
		let metric = _.findWhere(this.selectedMetrics, {name: displayThreshold.metricName});
		let filterRule = {
			attributeName: displayThreshold.metricName,
			displayName: metric && metric.displayName,
			isThreshold: true
		};
		let isOpenRange = _.filter([criteria.gte, criteria.lte], val => !_.isUndefined(val)).length === 1;
		if (isOpenRange) {
			return _.extend(filterRule, {
				type: FilterRuleType.numericOpenRange,
				matchMode: !_.isUndefined(criteria.gte) ? FilterMatchModeValue.IS : FilterMatchModeValue.IS_NOT,
				value: !_.isUndefined(criteria.gte) ? criteria.gte : criteria.lte
			});
		} else {
			return _.extend(filterRule, {
				type: FilterRuleType.numericRange,
				matchMode: displayThreshold.notInBetween ? FilterMatchModeValue.IS_NOT : FilterMatchModeValue.IS,
				from: displayThreshold.notInBetween ? criteria.gt : criteria.gte,
				to: displayThreshold.notInBetween ? criteria.lt : criteria.lte,
			});
		}
	}

	private isThresholdValid = (filter) => {
		if (filter.type === FilterRuleType.empty) {
			return true;
		}
		this.ui.thresholdError = !this.filterMatchModes.valueOf(filter).validate(filter);
		return !this.ui.thresholdError;
	};

	processDisplayThreshold = (): boolean | undefined => {
		if (this.thresholdFilter && this.thresholdFilter.type !== FilterRuleType.empty) {
			if (!this.isThresholdValid(this.thresholdFilter)) {
				this.validityChange.emit(false);
				return;
			}
			this.item.displayThreshold = {
				metricName: this.thresholdFilter.attributeName,
				criteria: this.getThresholdCriteria(this.thresholdFilter),
				notInBetween: this.thresholdFilter.type === FilterRuleType.numericRange
					&& this.thresholdFilter.matchMode === this.filterMatchModes.definitions.NOT_BETWEEN.apiValue
			};
		} else {
			this.ui.thresholdError = false;
			this.item.displayThreshold = {};
		}
		this.validityChange.emit(true);
		return !this.ui.thresholdError;
	};

	getThresholdFormat = (): NumberFormatSettings => {
		let metric: Metric = SearchableHierarchyUtils.deepSearchByName(this.thresholdMetrics, this.thresholdFilter.attributeName);

		// check if format has been customized within the widget
		if (metric && (metric.useDefaultFormat || metric.useDefaultFormat === undefined)) {
			let matchedMetric = _.find(this.selectedMetrics, {name: this.thresholdFilter.attributeName});
			if (matchedMetric) {
				return matchedMetric;
			}
		}

		return metric?.format;
	};

	private isFormatAvailable = (): boolean => {
		return !!this.getThresholdFormat();
	};

	showSingleValueFormat = (): boolean => {
		return this.isFormatAvailable() && (!_.isUndefined(this.thresholdFilter.value));
	};

	showRangeFormat = (): boolean => {
		return this.isFormatAvailable() &&
			(!_.isUndefined(this.thresholdFilter.to) && !_.isUndefined(this.thresholdFilter.from));
	};
}

app.directive('thresholdConfiguration', downgradeComponent({component: ThresholdConfigurationComponent}));
