import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { WidgetDashboardFilterApplication } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterApplication';
import { WidgetDashboardFilterProcessor } from '@cxstudio/dashboards/dashboard-filters/WidgetDashboardFilterProcessor';

@Injectable({
	providedIn: 'root'
})
export class WidgetDrillableDashboardFilterProcessorService extends WidgetDashboardFilterProcessor {

	constructor() {
		super();
	}

	process = (filterApplication: WidgetDashboardFilterApplication): ng.IPromise<void> => {
		// drillToDashboard filters cannot be ignored
		const dashboardFilters = filterApplication.filtersProvider.getAppliedFilters();
		const widgetProperties = filterApplication.widgetSettings.properties;

		const drillableFilters = _.chain(dashboardFilters)
			.filter(dashboardFilter => dashboardFilter.isDrillToDashboardFilter)
			.map(dashboardFilter => dashboardFilter.drillableFilter)
			.value();
		if (!drillableFilters.isEmpty()) {
			widgetProperties.drillFilters = widgetProperties.drillFilters || [];
			widgetProperties.drillFilters.pushAll(drillableFilters);
		}
		return PromiseUtils.old(Promise.resolve());
	};

}
app.service('widgetDrillableDashboardFilterProcessor', downgradeInjectable(WidgetDrillableDashboardFilterProcessorService));
