import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ListOption } from './list-option';

@Component({
	selector: 'checkbox-buttons',
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CheckboxButtonsComponent), multi: true},
	],
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="btn-group">
			<button *ngFor="let option of options;"
				class="btn"
				[ngClass]="{'btn-selected': isOptionSelected(option)}"                                    
				title="{{option.name}}"
				attr.aria-label="{{option.name}}"
				(click)="select(option)">
				{{option.shortName}}
			</button>
		</div>
	`
})
export class CheckboxButtonsComponent implements ControlValueAccessor, OnInit {

	options: ListOption<boolean>[];
	private touched = false;

	@Output() updateSelection = new EventEmitter<void>();

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: ListOption<boolean>[]) => void = _.noop;

	constructor(
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		// without this the initial value of checkbox-buttons remains null when passing value from parent component 
		setTimeout(() => this.ref.markForCheck());
	}

	isOptionSelected = (option: ListOption<boolean>): boolean => option.value;

	select = (option: ListOption<boolean>): void => {
		this.markAsTouched();
		option.value = !option.value;
		this.onChangeCallback(this.options);
		this.updateSelection.emit();
		this.onTouchedCallback();
	};

	markAsTouched = () => {
		if (!this.touched) {
			this.onTouchedCallback();
			this.touched = true;
		}
	};

	//ControlValueAccessor
	writeValue(options: ListOption<boolean>[]): void {
		this.options = options;
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}
