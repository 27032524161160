import * as _ from 'underscore';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { ShareUtilitiesService } from '@cxstudio/sharing/share-utilities.service';
import { IShareEntity, SharingService, UIShareEntity } from '@cxstudio/sharing/sharing-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { SharingUser } from '@cxstudio/sharing/access-management/entities/sharing-user';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { EmailUtils } from '@app/modules/user-administration/editor/email-utils.class';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { DomainsProcessingService} from '@app/modules/account-administration/api/domains-processing.service';
import { AllowedDomains } from '@app/modules/system-administration/master-account/email-domains/allowed-domains';
import { Security } from '@cxstudio/auth/security-service';

export interface IShareInviteUiBindings {
	label: string;	// custom label if necessary
	userSearchLoading: boolean;
	invalidEntityAlert?: string;
	showPreviewAsNotSupportError: boolean;
	targetAlreadyAddedEntity: any;	// used to scroll to/highlight added user/group
	targetAlreadySharedEntity: any;	// used to scroll to/highlight previously shared user/group
	hideShareAll: boolean; // can share to all user
	openPreviouslyAddedPanel: boolean;
	previouslyAddedPanelToggle: number;
	addPanelToggle: number;
	showShareHelp: boolean;
}

export class ShareInviteComponent implements ng.IComponentController {

	ui: Partial<IShareInviteUiBindings>;
	uiDefaults: Partial<IShareInviteUiBindings> = {
		label: this.locale.getString('common.shareUsersOrGroupsPrompt'),
		userSearchLoading: false,
		openPreviouslyAddedPanel: true,
		previouslyAddedPanelToggle: 0,
		addPanelToggle: 0,
		showShareHelp: false
	};

	typeaheadSearch: ng.IPromise<any>;
	defaultSharePermission: any;

	allEntities: IShareEntity[];
	allowedDomains: AllowedDomains;
	inviteEntity: string;

	newShares: any[];
	existingShares: any[];
	getAvailableEntities: (usersAndGroups: any) => any[];
	isOwner: (object: any) => boolean;
	isValidInviteByObject: (object: any) => boolean | ng.IPromise<boolean>; // any last validations, and return true if share is successful
	onAddNew: () => void;
	initialValue: any;
	assetEditPermissionType: AssetEditPermissionAction;
	editPermission: string;
	projectToCheck: IProjectSelection;

	constructor(
		private locale: ILocale,
		private $q: ng.IQService,
		private sharingService: SharingService,
		private domainsProcessingService: DomainsProcessingService,
		private $timeout: ng.ITimeoutService,
		private shareUtilities: ShareUtilitiesService,
		private userApiService: UserApiService,
		private masterAccountApiService: MasterAccountApiService,
		private readonly security: Security
	) {
	}

	$onInit = () => {
		this.userApiService.getAllowedDomains().then((response) => this.allowedDomains = response);

		this.ui = { ...this.uiDefaults, ...this.ui };

		this.ui.showShareHelp = this.security.isDataIsolationEnabled();

		this.ui = $.extend(this.uiDefaults, this.ui);
		this.getAvailableEntities = _.isUndefined(this.getAvailableEntities) ?
			usersAndGroups => usersAndGroups :	// if no custom fn is provided, just return the value
			this.getAvailableEntities;

		this.isValidInviteByObject = _.isUndefined(this.isValidInviteByObject) ?
			(object: any) => true :			// if no custom validation is provided, then no more validation is required
			this.isValidInviteByObject;

		this.onAddNew = _.isUndefined(this.onAddNew) ? _.noop : this.onAddNew;
		if (this.initialValue) {
			this.$timeout(() => {
				this.completeShare(this.initialValue);
				this.onAddNew();
			});
		}
	};

	onInviteEntityChange = (input): void => {
		if (input === '')
			return;

		this.ui.invalidEntityAlert = undefined; // if input is changing we can hide the alert for now.
		this.ui.userSearchLoading = true;
		let queryParams = {
			action: this.assetEditPermissionType
		} as any;

		if (this.projectToCheck) {
			queryParams.cpId = this.projectToCheck.contentProviderId;
			queryParams.accountId = this.projectToCheck.accountId;
			queryParams.projectId = this.projectToCheck.projectId;
		}

		this.typeaheadSearch = this.sharingService.searchUsersAndGroups(input, undefined, queryParams)
			.then((usersAndGroups) => {
				this.allEntities = this.getAvailableEntities(usersAndGroups);
				this.validateStringInput(input);
				delete this.ui.userSearchLoading;
			});
	};

	getSearchedEntities = (): ng.IPromise<any[]> => {
		let entitiesPromise = this.typeaheadSearch
			? this.typeaheadSearch.then(() => this.allEntities)
			: this.$q.when(this.allEntities);

		return entitiesPromise.then((entities: IShareEntity[]) => {
			let result = entities
				.filter((entity) => {
					return entity.displayName.toLowerCase().contains(this.inviteEntity.toLowerCase()) ||
						entity.entity.userFullName?.toLowerCase().contains(this.inviteEntity.toLowerCase());
				});

			result.filter(entity => this.isOwner(entity)).forEach(entity => entity.isOwner = true);

			return result.slice(0, this.sharingService.getSharingSearchLimit());
		});
	};

	private validateEmail = (entity): boolean => {
		let domain = entity.split('@')[1];
		let isEmailValid = EmailUtils.validate(entity)
			&& this.domainsProcessingService.validateLowerCaseEmailDomain(this.allowedDomains, domain);

		if (!isEmailValid) {
			this.ui.invalidEntityAlert = this.locale.getString('error.domainRestricted');
		}

		return isEmailValid;
	};

	private validateGroupName = (entry): boolean => {
		let filteredGroups = this.allEntities.filter((item) => {
			return item.type === 'group' && item.entity.groupName.toLowerCase() === entry.toLowerCase();
		});

		return filteredGroups.length > 0;
	};

	private validateStringInput = (entityName: string) => {
		let isEmailEntered = !_.isUndefined(entityName.split('@')[1]);

		return isEmailEntered
			? this.validateEmail(entityName)
			: this.validateGroupName(entityName);
	};

	// basic validations are performed here
	// object-specific validations performed by parent scopes
	private isValidInvite = (entityName: string, isMetaGroup: boolean) => {
		return isMetaGroup || this.validateStringInput(entityName);
	};

	private isDuplicateShare = (entity, searchForMatchedItems: boolean = false): boolean => {
		if ($.type(entity) === 'string') {
			// check if user is already allowed access to dashboard
			if (searchForMatchedItems) {
				this.ui.targetAlreadySharedEntity = _.find(this.existingShares, (item) => item._name.toLowerCase() === entity.toLowerCase());
				this.ui.targetAlreadyAddedEntity = _.find(this.newShares, (item) => item._name.toLowerCase() === entity.toLowerCase());
				return !!(this.ui.targetAlreadySharedEntity || this.ui.targetAlreadyAddedEntity);
			} else {
				return !!_.find(this.existingShares.concat(this.newShares), (item) => item._name.toLowerCase() === entity.toLowerCase());
			}
		}

		return false;
	};

	isDuplicate = (entity) => {
		if (this.isDuplicateShare(entity, true)) {
			this.resetShareInput();
			if (this.ui.targetAlreadySharedEntity) {
				this.ui.openPreviouslyAddedPanel = true;
				this.ui.previouslyAddedPanelToggle++; // use a number bc anytime this changes we need it to open
			} else if (this.ui.targetAlreadyAddedEntity) {
				this.ui.addPanelToggle++;
			}
			return true;
		}
		return false;
	};

	share = (entity: any): void => {
		if (entity === null || _.isUndefined(entity)) return;

		let shareTarget = entity;
		let isMetaGroup: boolean = false;

		if (entity._name) {
			shareTarget = entity._name;
			isMetaGroup = entity.type === 'metaGroup';
		}

		if (this.isDuplicate(shareTarget)) return;

		if (!this.isValidInvite(shareTarget, isMetaGroup)) return;

		// perform the actual share at the parent level
		// additional verification should be done there if necessary
		let validationResult = this.isValidInviteByObject(shareTarget);
		this.$q.resolve(validationResult).then((valid: boolean) => {
			if (valid && this.completeShare(shareTarget)) {
				this.onAddNew();
				this.resetShareInput();
			}
		});
	};

	private completeShare = (entity): boolean => {
		let shareEntity;

		if ($.type(entity) === 'string') {
			let existingEntity = this.shareUtilities.findExistingEntity(this.allEntities, entity);

			shareEntity = existingEntity || entity;
		} else {
			shareEntity = entity;
		}

		if (!shareEntity._name) {
			shareEntity = {
				entity: shareEntity,
				action: ShareAction.CREATE,
				type: 'user',
				_name: shareEntity,
				displayName: shareEntity
			};
		} else {
			shareEntity.action = ShareAction.ADD;
		}

		shareEntity.permission = this.defaultSharePermission ?
			this.defaultSharePermission :
			PermissionType.VIEW;

		shareEntity.shared = true;
		shareEntity.newAdd = true;
		this.$timeout(() => {
			delete shareEntity.newAdd;
		}, 2000);
		this.ui.targetAlreadyAddedEntity = shareEntity;
		this.newShares.push(shareEntity);

		if (shareEntity.type === 'user' && shareEntity.entity.userId) {
			this.ensureUserAssetEditPermissionPopulated(shareEntity);
		}

		return true;
	};

	private resetShareInput = (): void => {
		$('#permission-invite-input').trigger('focus');
		this.ui.invalidEntityAlert = undefined;
		this.inviteEntity = '';
	};

	private ensureUserAssetEditPermissionPopulated = (entity: UIShareEntity<SharingUser>): void => {
		let user = entity.entity;
		if (!user.assetEditPermissionPromise) {
			user.populatingAssetEditPermission = true;
			user.assetEditPermissionPromise = this.masterAccountApiService.hasEditPermission(user.userId, this.assetEditPermissionType);
		}

		user.assetEditPermissionPromise.then(result => {
			if (result && !user.assetEditPermissions.contains(this.editPermission)) {
				user.assetEditPermissions.push(this.editPermission);
			}

			user.populatingAssetEditPermission = false;
		});
	};

}

app.component('shareInvite', {
	bindings: {
		isValidInviteByObject: '<customValidation',
		existingShares: '<',
		newShares: '<',
		getAvailableEntities: '<',
		ui: '=',
		isOwner: '<',
		onAddNew: '<',
		initialValue: '<',
		defaultSharePermission: '<',
		assetEditPermissionType: '<',
		editPermission: '<',
		projectToCheck: '<'
	},
	controller: ShareInviteComponent,
	template: `
<div
	class="form-group form-group-stacked"
	ng-class="{'has-error': !!$ctrl.ui.invalidEntityAlert}">
	<div class="d-flex align-items-center" ng-class="{'mb-8': !$ctrl.ui.showShareHelp}">
		<label for="permission-invite-input" class="font-bold m-0">{{::$ctrl.ui.label}}</label>
	<cb-inline-help fixed="true" ng-if="$ctrl.ui.showShareHelp">
		<help-body>
		{{::'common.exactMatchWarning'|i18n}}
		</help-body>
	</cb-inline-help>
</div>

	<div class="align-items-center">
		<input
			type="text"
			class="flex-fill no-ms-clear"
			ng-model="$ctrl.inviteEntity"
			id="permission-invite-input"
			aria-label="{{::'dashboard.inviteMessage'|i18n}}"
			autocomplete="off"
			uib-typeahead="entity as entity.displayName for entity in $ctrl.getSearchedEntities()"
			typeahead-wait-ms="200"
			typeahead-template-url="partials/assets/asset-share-invite.html"
			typeahead-on-select="$ctrl.share($ctrl.inviteEntity)"
			ng-focus="invite.dropdown = false"
			ng-enter="$ctrl.share($ctrl.inviteEntity)"
			ng-change="$ctrl.onInviteEntityChange($ctrl.inviteEntity)"
			ng-model-options="{updateOn: 'default blur', debounce: {default: 300, blur: 0}}">
		<span ng-show="$ctrl.ui.userSearchLoading" class="ml-4 loading-spinner q-icon-spinner rotate-infinite"></span>
	</div>
</div>
<alert type="danger" ng-if="!!$ctrl.ui.invalidEntityAlert">{{::$ctrl.ui.invalidEntityAlert}}</alert>`
});
