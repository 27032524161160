import { Component, OnInit, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Errors } from '@cxstudio/common/errors';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import MobileAppConfigurationPromises from '@cxstudio/mobile/mobile-app-configuration-promises';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { PredefinedFiltersService } from '@cxstudio/metrics/predefined/predefined-filters.service';
import MobileSavedFilter from './mobile-saved-filter.interface';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import IFilter from '@cxstudio/report-filters/entity/filter';

@Component({
	selector: 'mobile-app-saved-filters',
	templateUrl: './mobile-app-saved-filters.component.html'
})

export class MobileAppSavedFiltersComponent implements OnInit {
	@Input() settings: MobileAppSettings = {} as MobileAppSettings;
	@Input() errors: Errors;
	@Input() promises: MobileAppConfigurationPromises;

	readonly SAVED_FILTERS_LIMIT: number = 10;

	savedFilters: any[] = [];
	private limitExceeded: boolean = false;

	constructor(
		private locale: CxLocaleService,
		@Inject('predefinedFiltersService') private predefinedFiltersService: PredefinedFiltersService,
		private readonly filtersService: FiltersService
	) {
	}

	ngOnInit(): void {
		this.settings.availableSavedFilters = this.settings.availableSavedFilters || [];
		this.loadSavedFilters();
	}

	private loadSavedFilters = (): void => {
		if (!ProjectIdentifier.isProjectSelected(this.settings)) {
			return;
		}

		this.promises.savedFilters.promise = this.loadStudioSavedFilters().then((response) => {
			let selectedStudioFilters = this.settings.availableSavedFilters.map(filter => filter.filterId);
			let studioFilters = _.chain(response)
				.map(filter => {
					return {
						filterId: filter.id.toString(),
						name: filter.name,
						type: FilterTypes.STUDIO
					} as MobileSavedFilter;
				})
				.filter(filter => !_.contains(selectedStudioFilters, filter.filterId))
				.value();

			let selectedPredefinedFilters = this.settings.availableSavedFilters.map(filter => `${filter.value}:${filter.subtype}`);
			let predefinedFilters = _.chain(this.predefinedFiltersService.getAllFilters())
				.map(filter => {
					return {
						name: filter.name,
						subtype: filter.subtype,
						type: FilterTypes.PREDEFINED,
						value: filter.value
					} as MobileSavedFilter;
				})
				.filter(filter => !_.contains(selectedPredefinedFilters, `${filter.value}:${filter.subtype}`))
				.value();

			this.savedFilters.push({
				name: this.locale.getString('widget.studioFilters'),
				children: studioFilters,
				type: FolderTypes.FILTER,
				filter: (filter) => filter.type === FilterTypes.STUDIO
			});

			this.savedFilters.push({
				name: this.locale.getString('widget.predefinedFilters'),
				children: predefinedFilters,
				type: FolderTypes.FILTER,
				filter: (filter) => filter.type === FilterTypes.PREDEFINED
			});
		});
	};

	private loadStudioSavedFilters = (): Promise<IFilter[]> => {
		const project = {
			contentProviderId: this.settings.contentProviderId,
			accountId: this.settings.accountId,
			projectId: this.settings.projectId
		};
		return this.filtersService.getStudioFilters(project);
	};

	hasUserTriedToExceedSavedFiltersLimit = (): boolean => {
		return this.limitExceeded;
	};

	private isAvailableSavedFiltersLimitReached = (): boolean => {
		return this.settings.availableSavedFilters.length >= this.SAVED_FILTERS_LIMIT;
	};

	selectSavedFilter = (savedFilter: any): void => {
		if (this.isFolder(savedFilter)) {
			return;
		}

		this.limitExceeded = this.isAvailableSavedFiltersLimitReached();

		if (!this.isAvailableSavedFiltersLimitReached()) {
			this.settings.availableSavedFilters.push(savedFilter);
			this.savedFilters.forEach((hierarchy) =>
				HierarchyUtils.removeFromHierarchy(hierarchy, savedFilter));
		}
	};

	removeSavedFilter = (savedFilter: any): void => {
		if (this.isFolder(savedFilter)) {
			return;
		}

		this.limitExceeded = this.isAvailableSavedFiltersLimitReached();

		this.settings.availableSavedFilters.remove(savedFilter);
		this.savedFilters.forEach((hierarchy) =>
			HierarchyUtils.addToHierarchy(hierarchy, savedFilter));
	};

	dropSavedFilter = (event: CdkDragDrop<string[]>): void => {
		moveItemInArray(this.settings.availableSavedFilters, event.previousIndex, event.currentIndex);
	};

	private isFolder = (savedFilter): boolean => {
		return savedFilter.type === FolderTypes.FILTER;
	};
}

app.directive('mobileAppSavedFilters', downgradeComponent({component: MobileAppSavedFiltersComponent}));
