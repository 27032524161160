import { WidgetEvent, DashboardEvent } from '@app/core/cx-event.enum';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { NameService } from '@cxstudio/common/name-service';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { IDrillObject } from '@cxstudio/dashboards/widgets/widget';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import { } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { RecolorUtils } from '@cxstudio/reports/providers/cb/services/recolor-utils';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import WidgetService from '@cxstudio/services/widget-service';


export class WidgetContainerController implements ng.IController {

	refreshTrigger: boolean = false;
	widget: Widget;
	itemInitialized: boolean;
	dashboardData: IDashboardData;
	editMode: boolean;
	layout: LayoutHelper;

	private baseWidget: Widget;

	constructor(
		private $rootScope: ng.IRootScopeService,
		private $scope: ISimpleScope,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService,
		private analyticDrillUtils,
		private widgetService: WidgetService,
		private nameService: NameService,
		private locale: ILocale,
		private widgetTimingService: WidgetTimingService,
		private $window: ng.IWindowService,
		private reportUtils: ReportUtils,
		private currentWidgets: ICurrentWidgets,
		private dashboardListService: DashboardListService,
		private widgetDescriptionService: WidgetDescriptionService,
	) {

	}

	private getContentElement(): JQuery {
		return this.$element.find('widget-item').first();
	}

	$onInit(): void {
		this.initializeWhenReady();
		if (this.widget.id) {
			this.baseWidget = this.currentWidgets.getBaseWidget(this.widget.containerId, this.widget.id);
		}
		this.baseWidget = this.baseWidget || angular.copy(this.widget);

		// if widget gets updated and saved, we need to update $scope.baseWidget as well
		this.$scope.$on(DashboardEvent.SAVE, () => {
			this.baseWidget = angular.copy(this.widget);
		});

		this.$scope.$on(WidgetEvent.RELOAD, (event, ids: number[]) => this.reloadWidget(ids));

		this.$scope.$on('drill:set', (event, index) => {
			if (index < 0) {
				this.resetWidgetDrill();
				return;
			}
			let drillPath = this.widget.drillPath;
			drillPath.splice(index + 1);

			angular.copy(this.baseWidget, this.widget);

			this.setDrillTo(drillPath);
		});

		this.$scope.$on('drill:add', (event, drillObject: IDrillObject) => {
			let drillPath = this.widget.drillPath || [];
			drillPath.push(drillObject);
			this.updateDrillProps(drillPath, drillObject);
			this.currentWidgets.setBaseWidget(this.widget.containerId, this.widget.id, this.baseWidget);
		});


		this.$scope.$on('drill:reset', this.resetWidgetDrill);

		this.$scope.$on('widget:export-to-dashboard', this.exportToDashboard);
	}

	private initializeWhenReady(): void {
		this.itemInitialized = undefined;
		let stopWatcher = this.$scope.$watch(() => {
			return this.getContentElement().height() > 0;
		}, (hasHeight) => {
			if (hasHeight) {
				let widget = this.widget;
				if (this.isSkipInitialization()
					|| ReportConstants.isLegacyWidget(this.widget)) {
					// don't load widgets below viewport when making a screenshot
					stopWatcher();
					this.reportUtils.handleWidgetRenderedEvent(widget.id, widget.name, widget.containerId);
					return;
				}

				if (this.isScreenshot()) {
					this.reportUtils.registerWidgetRendering(widget.id, widget.name);
				}

				this.$timeout(() => {
					this.itemInitialized = true;
					this.widgetTimingService.initWidget(widget);
				}, 1);
				stopWatcher();
			}
		});
	}

	private isScreenshot(): boolean {
		return  this.$rootScope.pdfToken && !this.$rootScope.pdf;
	}

	private isSkipInitialization(): boolean {
		let isScreenshot = this.$rootScope.pdfToken && !this.$rootScope.pdf;
		return isScreenshot && this.getContentElement().offset().top > this.$window.innerHeight;
	}

	private reloadWidget(ids?: number[]): void {
		if (ids?.length && !ids.contains(this.widget.id)) {
			return;
		}
		this.refreshTrigger = !this.refreshTrigger;
		this.initializeWhenReady();
	}

	private updateDrillProps(drillPath: IDrillObject[], drillObject: IDrillObject, isStepToSpecificDrill?: boolean): void {
		let created;

		// if we are removing steps from the drill, start over at the base and add only what we want to keep
		if (isStepToSpecificDrill) {
			created = angular.copy(this.baseWidget);
			drillPath.forEach(pathItem => {
				created = this.analyticDrillUtils.createDrillWidget(created, pathItem.point, pathItem.type,
					this.dashboardData.dashboard.properties);
			});
		} else {
			created = this.analyticDrillUtils.createDrillWidget(this.widget, drillObject.point, drillObject.type,
				this.dashboardData.dashboard.properties);
		}

		created.properties.runAs = this.widget.properties.runAs;
		created.id = this.widget.id;
		created.posX = this.widget.posX;
		created.drillPath = drillPath;
		if (!this.editMode) {
			created.linkedTo = this.widget.linkedTo;
		}

		// CXS-101 - don't change the widget name
		created.displayName = this.baseWidget.displayName;

		if (created.properties.autoDescription) {
			this.widgetDescriptionService.generateDescription(created).then((description) => {
				created.description = description;
				this.copyAndReload(created);
			});
		} else {
			this.copyAndReload(created);
		}
	}

	private resetWidgetDrill = () => {
		this.copyAndReload(this.baseWidget);
	};

	private copyAndReload(source: Widget): void {
		angular.copy(source, this.widget);
		this.reloadWidget();
	}

	private setDrillTo(drillPath): void {
		let IS_STEP_TO = true;
		this.updateDrillProps(drillPath, undefined, IS_STEP_TO);
	}

	private performExportWidgetToDashboard = (baseWidget): void => {
		let drillPath = this.widget.drillPath || [];
		let newWidgets = [];
		let name = this.locale.getString('widget.drillOn') + ' ' + baseWidget.displayName;
		let newDashboardName = this.nameService.uniqueName(name, this.dashboardListService.getCurrentDashboardsList(), 'name');

		baseWidget.posX = 0;
		baseWidget.posY = 0;
		baseWidget.width = GridsterConfigurer.GRIDSTER_ITEM_WIDTH;
		baseWidget.height = GridsterConfigurer.GRIDSTER_ITEM_HEIGHT;

		drillPath.forEach(pathItem => {
			RecolorUtils.addWidgetRecolor(pathItem.point, '#868898', baseWidget.visualProperties);
			newWidgets.push(baseWidget);
			let drilledWidget = this.analyticDrillUtils.createDrillWidget(
				baseWidget, pathItem.point, pathItem.type, this.dashboardData.dashboard.properties);
			let displayName = pathItem.point.displayName || pathItem.point[pathItem.point._group.identifier];
			drilledWidget.displayName = baseWidget.displayName + ' - ' + displayName;
			baseWidget = angular.copy(drilledWidget);
		});
		newWidgets.push(baseWidget);
		this.$scope.$emit('createDashboardFromWidgetsEvent', newDashboardName, newWidgets);
	};

	private exportToDashboard = () => {
		this.widgetService.copyWidget(this.baseWidget).then(this.performExportWidgetToDashboard);
	};
}

const COMMON_HTML = `
	class="d-flex w-100-percent h-100-percent"
	ng-class="{'no-animation':!$ctrl.itemInitialized}"
	widget="::$ctrl.widget"
	base-widget="$ctrl.baseWidget"
	widget-mode="{{::$ctrl.widgetMode}}"
	edit-mode="$ctrl.editMode"
	item-initialized="::$ctrl.itemInitialized"
	dashboard-data="::$ctrl.dashboardData"
	widget-actions="::$ctrl.widgetActions"
	layout="::$ctrl.layout"`;

app.component('widgetContainer', {
	controller: WidgetContainerController,
	bindings: {
		widget: '<',
		widgetMode: '@',
		editMode: '<',
		dashboardData: '<',
		widgetActions: '<',
		layout: '<'
	},
	// using 2 same components to completely recompile them when refreshTrigger changes
	template: `
		<widget-item ng-if="$ctrl.refreshTrigger"
			${COMMON_HTML}
		></widget-item>
		<widget-item ng-if="!$ctrl.refreshTrigger"
			${COMMON_HTML}
		></widget-item>
	`,
});
