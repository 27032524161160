import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { SidebarService } from '@app/modules/layout/sidebar.service';
import { AppLoadingService } from '@cxstudio/app-loading';
import { AppRootScope } from '@cxstudio/app-root-scope';
import { Security } from '@cxstudio/auth/security-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticsService } from '@app/modules/analytics/analytics.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { Logger } from '@cxstudio/services/logger.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { IPromise } from 'angular';
import { HeadlessChromeRenderingStatus } from './dashboards/headless-chrome-render-status';
import { ImpersonateUserService } from './services/impersonate-service.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { RouteService } from './services/route-service';
import { FilterMatchModes } from './report-filters/constants/filter-match-modes.service';
import { Context } from '@cxstudio/auth/context';
import { LanguageLoaderService } from '@app/core/i18n/language-loader.service';
import { SupportedLocaleGenerated } from '@locales/supported-locales';

interface IDocument extends Document {
	headlessChromeRenderingCallback: any;
}

export class MainController implements ng.IController {
	sidebarSvc;

	constructor(
		private $scope,
		private $rootScope: AppRootScope,
		private $q: ng.IQService,
		private analyticsService: AnalyticsService,
		private amplitudeAnalytics: AmplitudeAnalyticsService,
		private $log: ng.ILogService,
		private $window: ng.IWindowService,
		private $location: ng.ILocationService,
		private languageLoader: LanguageLoaderService,
		private locale: ILocale,
		private urlService: UrlService,
		private applicationThemeService: ApplicationThemeService,
		private security: Security,
		private exportApiService: ExportApiService,
		private $route,
		private routeService: RouteService,
		private logger: Logger,
		private appLoading: AppLoadingService,
		private betaFeaturesService: BetaFeaturesService,
		private environmentService: EnvironmentService,
		private redirectService: RedirectService,
		private readonly sidebarService: SidebarService,
		readonly impersonateUserService: ImpersonateUserService,
		private readonly filterMatchModes: FilterMatchModes,
		private readonly DateRange) {

		this.$scope.loaded = undefined;

		// required to fire initial route event, which doesn't fire automatically as ng-view is inside ng-include
		this.$route.reload();

		this.processPageParams();
		this.initializeWindow();

		this.$rootScope.globalWarning = {
			visible: false,
			message: null
		};

		this.$scope.maUrl = this.urlService.getAPIUrl('rest/master_account/logo/');
		// handle logout/login
		this.$scope.$on('user:updated', (event, user) => {
			if (!user) {
				this.$scope.loaded = undefined;
			} else {
				this.loadApplicationInformation().then(() => {
					this.onApplicationLoaded();
					this.logger.logUserLogin();

					this.initAnalytics();
					this.checkLanguage(user).then(() => this.loadAccounts());
				});

			}
		});

		this.$scope.getDashboardCtrlLandmark = (): string => {
			return this.redirectService.isOnDashboardList() ? undefined : 'main';
		};

		this.$scope.isShowingDarkTheme = this.applicationThemeService.isShowingDarkTheme;
		this.$scope.isEmbeddedView = (): boolean => this.routeService.isEmbeddedView();
		this.$scope.sidebarSvc = this.sidebarService;
		this.$scope.isPreviewMode = (): boolean => this.routeService.isDashboardPreview();

		this.$scope.isImpersonateActive = impersonateUserService.isImpersonateActive;

		this.$scope.isNewDashboardList = () => {
			return this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES);
		};
	}

	$onInit(): void {}

	private readonly isPdfExport = (): boolean => {
		const search = this.$location.search();
		return search.dashboardExport;
	};

	private processPageParams = (): void => {
		const search = this.$location.search();

		this.$rootScope.pdfToken = this.isPdfExport();
		if (search.pdf) this.$rootScope.pdf = true;
		if (search.scheduled) this.$rootScope.scheduled = true;
		if (search.onePage) this.$rootScope.onePage = true;
		if (search.dashboardClientParametersId) this.$rootScope.dashboardClientParametersId = search.dashboardClientParametersId;
		if (search.exportingBrowser) this.$rootScope.exportingBrowser = search.exportingBrowser;

		if (this.$rootScope.pdfToken) {
			this.applyPdfExportCss();

			if (!this.$rootScope.pdf) {
				// to support dark theme switching during dashboard preview generation
				this.$window.applyDashboardDarkTheme = (): boolean => {
					// legacy loading indication
					(document as IDocument).headlessChromeRenderingCallback = false;
					// external PDF service loading indication
					(document as any).headlessChromeRenderingStatus = HeadlessChromeRenderingStatus.LOADING;
					this.applicationThemeService.applyDashboardDarkThemeForPreview();
					return true;
				};
			}
		}
	};

	private applyPdfExportCss = (): void => {
		let htmlElement = $('html');
		htmlElement.addClass('pdf-export');
	};

	private initializeWindow = (): void => {
		let initializeWindowSize = () => {
			this.$rootScope.isMobile = this.$window.matchMedia('(max-width: 767px)').matches && !this.environmentService.isIframe();
		};

		initializeWindowSize();
		angular.element(this.$window).bind('resize', () => {
			initializeWindowSize();
			if (!this.$scope.$$phase) {
				this.$scope.$apply();
			}

		});
		if (this.environmentService.isIframe()) {
			this.$rootScope.inIframe = true;
		}
	};


	private checkLanguage = (user: Context): IPromise<any> => {
		if (user.locale) {
			this.languageLoader.ensureLanguage(user.locale as SupportedLocaleGenerated);
			this.updateStrings();
			return this.$q.when();
		} else {
			return this.$q.when();
		}
	};

	/**
	 * In areas where strings are pre-loaded, update to correct language.
	 * This is not ideal and we should try to avoid localizing strings in class constructors and instead load them on the fly as needed
	 */
	private updateStrings = (): void => {
		this.filterMatchModes.onLanguageChange();
		this.DateRange.onLanguageChange();
	};

	private initAnalytics = (): void => {
		try {
			this.amplitudeAnalytics.initAmplitude();
			this.analyticsService.initPendo();
		} catch (e) {
			this.$log.error(e);
		}
	};

	private onApplicationLoaded = (): void => {
		this.$log.debug('Completed loading of controllers and dictionaries');
		this.$rootScope.cgBusyMessage = this.locale.getString('common.spinnerPleaseWait');
		this.$scope.loaded = true;

		this.hideApplicationSpinner();
	};

	private hideApplicationSpinner = (): void => {
		this.appLoading.hideAppSpinner();
	};

	private loadApplicationInformation = (): ng.IPromise<void> => {
		return this.$rootScope.dashboardClientParametersId
			? this.loadDashboardClientParameters()
			: this.$q.when();
	};

	private loadDashboardClientParameters = (): IPromise<any> => {
		return this.exportApiService.getDashboardClientParameters(this.$rootScope.dashboardClientParametersId)
			.then((parameters) => {
				if (parameters.clientTimezone) this.$rootScope.clientTimezone = parameters.clientTimezone;
				if (parameters.clientTimezoneName) this.$rootScope.clientTimezoneName = parameters.clientTimezoneName;

				if (parameters.hierarchyId) this.$rootScope.preselectedHierarchyId = parameters.hierarchyId;
				if (parameters.hierarchyNodeId) this.$rootScope.preselectedNodeId = parameters.hierarchyNodeId;
				if (parameters.snapshotId) this.$rootScope.snapshotId = parameters.snapshotId;

				if (parameters.appliedFiltersArray) this.$rootScope.appliedFiltersArray = JSON.parse(parameters.appliedFiltersArray);
				if (parameters.tabId) this.$rootScope.preselectedTabId = parameters.tabId + '';
				if (parameters.tabSnapshotId) this.$rootScope.tabSnapshotId = parameters.tabSnapshotId;

				if (parameters.widgetLinkingParams) this.$rootScope.widgetLinkingParams = parameters.widgetLinkingParams;
				if (parameters.widgetPaginationParams)
					this.$rootScope.widgetPaginationParams = parameters.widgetPaginationParams;
				if (parameters.widgetSentenceSelectionParams)
					this.$rootScope.widgetSentenceSelectionParams = parameters.widgetSentenceSelectionParams;
				if (parameters.widgetTextFilters)
					this.$rootScope.widgetTextFilters = parameters.widgetTextFilters;

				return parameters;
			});
	};

	// initialize general options (dashboards, some settings, notifications, etc)
	private loadAccounts = (): void => {
		let currentMasterAccount = this.security.getCurrentMasterAccount();
		if (currentMasterAccount) {
			this.$scope.customBranding = currentMasterAccount.customBrandingEnabled;
			this.betaFeaturesService.init(currentMasterAccount.betaFeatures);
		}

		this.applicationThemeService.applySelectedThemes();
	};

}

app.controller('MainCtrl', MainController);
