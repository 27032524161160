import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, Inject } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import OAuthExternalProvider from '@app/modules/system-administration/oauth/oauth-external-provider';
import { OAuthExternalProvidersApiService } from '@app/modules/system-administration/oauth/service/oauth-external-providers-api-service';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { QualtricsApiProvider } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-provider';
import { QualtricsApiProvidersApiService } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-providers-api.service';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { debounceTime, distinctUntilChanged, switchMap, tap, first } from 'rxjs/operators';
import { from, of, Subject } from 'rxjs';
import { Observable } from 'rxjs';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';

@Component({
	selector: 'qualtrics-integration',
	templateUrl: './qualtrics-integration.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class QualtricsIntegrationComponent extends SelfCleaningComponent implements OnInit {
	qualtricsIntegration: UntypedFormGroup;
	qualtricsApiIntegration: UntypedFormGroup;

	loadingPromise: Promise<void>;
	ssoExternalProviders: OAuthExternalProvider[];
	apiProviders: QualtricsApiProvider[];
	ticketingApplications: ExternalApplication[];

	onInputChanged: Subject<Event> = new Subject<Event>();
	private brandChecking: boolean;

	constructor(
		private fb: UntypedFormBuilder,
		readonly ref: ChangeDetectorRef,
		readonly formUtils: CxFormUtils,
		private readonly oauthExternalProvidersApiService: OAuthExternalProvidersApiService,
		private readonly qualtricsApiProvidersApiService: QualtricsApiProvidersApiService,
		private readonly externalApplicationsApiService: ExternalApplicationsApiService,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
	) {
		super();
		this.createGroup();
		this.addSubscription(this.qualtricsIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.aliasName, this.brandId, this.datacenter, this.loginUrl,
					this.ssoProviderName, this.userSyncEnabled, this.enforceXmAuthentication, this.ticketingEnabled);
				if (this.ticketingEnabled.value) {
					this.formUtils.enableFields(this.ticketingApplicationId);
				}
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.aliasName, this.brandId, this.datacenter, this.loginUrl,
					this.ssoProviderName, this.ticketingEnabled, this.userSyncEnabled, this.enforceXmAuthentication,
					this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.qualtricsApiIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.providerId);
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.providerId);
				this.qualtricsIntegration.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.ticketingEnabled.valueChanges.subscribe(value => {
			if (value) {
				this.formUtils.enableFields(this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.ticketingApplicationId);
				this.qualtricsIntegration.updateValueAndValidity();
			}
		}));

		this.addSubscription(this.onInputChanged.pipe(
			tap(() => this.brandChecking = true),
			debounceTime(500),
			distinctUntilChanged(),
			switchMap(event => this.validateBrandId((event.target as HTMLInputElement).value))
		).subscribe((exist) => this.existPostCheck(exist)));
	}

	existPostCheck(exist: boolean): void {
		if (exist) {
			this.brandId.setErrors({ brandIdExists: true });
		}
		this.brandChecking = false;
		this.ref.markForCheck();
	}

	ngOnInit(): void {
		this.reloadExternalProviders();
		this.reloadApiProviders();
		this.reloadTicketingApplications();
	}

	reloadExternalProviders = (): void => {
		this.oauthExternalProvidersApiService.getExternalQualtricsProviders()
			.then(providers => {
				this.ssoExternalProviders = providers;
				this.ref.markForCheck();
			});
	};

	reloadApiProviders = (): void => {
		this.qualtricsApiProvidersApiService.getApiProviders()
			.then(providers => {
				this.apiProviders = providers;
				this.ref.markForCheck();
			});
	};

	validateBrandId = (value: string): Observable<boolean> => {
		if (this.isBrandIdValid() && value.length !== 0) {
			return from(this.systemAdminApiService.isQualtricsBrandIdExisting(value)
				.then(response => response.data.exist));
		}
		return of(false);
	};

	isBrandIdValid = (): boolean => {
		return this.brandId?.pristine
			|| !this.brandId?.errors?.brandIdExists;
	};

	private reloadTicketingApplications = (): void => {
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.TICKETING)
			.then(applications => {
				this.ticketingApplications = applications;
				this.ref.markForCheck();
			});
	};

	private createGroup(): void {
		this.qualtricsIntegration = this.fb.group({
			enabled: [ false, [ Validators.required ]],
			aliasName: [{ value: '', disabled: true }, [ Validators.required ]],
			brandId: [{ value: '', disabled: true }, [ Validators.required ]],
			datacenter: [{ value: '', disabled: true }, [ Validators.required ]],
			loginUrl: [{ value: '', disabled: true }, [ Validators.required ]],
			authSettings: this.fb.group({
				ssoProviderName: [{ value: '', disabled: true }, [ Validators.required ]],
			}),
			userSyncEnabled: [ { value: false, disabled: true} ],
			enforceXmAuthenticationEnabled: [ { value: false, disabled: true} ],
			ticketingEnabled: [ { value: false, disabled: true } ],
			ticketingApplicationId: [{ value: null, disabled: true }, [ Validators.required ]],
			ticketLink: [ { value: '', disabled: false } ]
		});
		this.qualtricsApiIntegration = this.fb.group({
			enabled: [ false, [ Validators.required ]],
			providerId: [{ value: '', disabled: true }, [ Validators.required ]],
		});
	}

	getApiGroup(): UntypedFormGroup {
		return this.qualtricsApiIntegration;
	}

	getGroup(): UntypedFormGroup {
		return this.qualtricsIntegration;
	}

	get enabled(): UntypedFormControl {
		return this.qualtricsIntegration.controls.enabled as UntypedFormControl;
	}
	get aliasName(): UntypedFormControl {
		return this.qualtricsIntegration.controls.aliasName as UntypedFormControl;
	}
	get brandId(): UntypedFormControl {
		return this.qualtricsIntegration.controls.brandId as UntypedFormControl;
	}
	get datacenter(): UntypedFormControl {
		return this.qualtricsIntegration.controls.datacenter as UntypedFormControl;
	}
	get loginUrl(): UntypedFormControl {
		return this.qualtricsIntegration.controls.loginUrl as UntypedFormControl;
	}
	get ticketingEnabled(): UntypedFormControl {
		return this.qualtricsIntegration.controls.ticketingEnabled as UntypedFormControl;
	}
	get userSyncEnabled(): UntypedFormControl {
		return this.qualtricsIntegration.controls.userSyncEnabled as UntypedFormControl;
	}
	get enforceXmAuthentication(): UntypedFormControl {
		return this.qualtricsIntegration.controls.enforceXmAuthenticationEnabled as UntypedFormControl;
	}
	get ticketingApplicationId(): UntypedFormControl {
		return this.qualtricsIntegration.controls.ticketingApplicationId as UntypedFormControl;
	}
	get ssoProviderName(): UntypedFormControl {
		return this.qualtricsIntegration.get('authSettings.ssoProviderName') as UntypedFormControl;
	}
	get providerId(): UntypedFormControl {
		return this.qualtricsApiIntegration.controls.providerId as UntypedFormControl;
	}
	get integrationTicketLink(): UntypedFormControl {
		return this.qualtricsIntegration.controls.ticketLink as UntypedFormControl;
	}
}
