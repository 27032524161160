import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { IDriversActionsService } from '@cxstudio/drivers/drivers-actions-service';
import { DriversItem, DriversStatus } from '@cxstudio/drivers/entities/drivers-item';
import {DriversType} from '@cxstudio/drivers/entities/drivers-type';
import ManageDriversService from '@cxstudio/drivers/manage-drivers.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IDriversFolder } from '@cxstudio/drivers/entities/drivers-folder';
import { DriversTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';


export interface IDriversContextMenuScope {
	driversList: DriversTreeItem[];
	props: Partial<IProjectSelection>;
	loading: any;
	ui: any;
	selectionUtils: GenericSelectUtils<DriversTreeItem>;
}

export interface IDriversContextMenuUtils {
	getContextMenu(item: any): any[];
	isVisibleObject(item: any): boolean;
}

// eslint-disable-next-line prefer-arrow-callback
app.factory('DriversContextMenuUtils', function(
	locale: ILocale,
	security: Security,
	DriversActionsService,
	manageDriversService: ManageDriversService,
	FolderService
) {
	return class DriversContextMenuUtils extends BaseContextMenuUtils {
		private actionsService: IDriversActionsService;
		private folderSvc: any;

		private readonly OPTIONS = {

			REFRESH: {
				name: 'refresh',
				text: locale.getString('common.refresh'),
				func: (driver: DriversItem) => this.actionsService.refreshDrivers(driver)
			},

			EDIT: {
				name: 'edit',
				text: locale.getString('common.edit'),
				func: (driver: DriversItem) => this.actionsService.editDrivers(driver)
			},

			VIEW: {
				name: 'view',
				text: locale.getString('common.view'),
				func: (driver: DriversItem) => this.actionsService.viewDrivers(driver)
			},

			COPY: {
				name: 'copy',
				text: locale.getString('dashboard.copy'),
				func: (driver: DriversItem) => this.actionsService.copyDrivers(driver)
			},

			SHARE: {
				name: 'share',
				text: locale.getString('dashboard.share'),
				func: (driver: DriversItem) => this.actionsService.shareDrivers([driver])
			},

			DELETE: {
				name: 'delete',
				text: locale.getString('common.delete'),
				func: (driver: DriversItem) => this.actionsService.deleteDrivers(driver)
			},

			RENAME_FOLDER: {
				name: 'rename',
				text: locale.getString('common.rename'),
				func: (folder: IDriversFolder) => this.actionsService.renameFolder(folder)
			},
			MOVE: {
				text: locale.getString('dashboard.move'),
				name: 'move',
				searchBox: true,
				isExpandable: true
			},
			CREATE_DRIVERS: {
				text: locale.getString('drivers.createNewDrivers'),
				name: 'create_drivers_in_folder',
				func: (folder: IDriversFolder) => this.actionsService.createDrivers(folder)
			},
			CREATE_SUBFOLDER: {
				text: locale.getString('dashboard.addSubfolder'),
				name: 'add_folder',
				func: (folder: IDriversFolder) => this.actionsService.createFolder(folder)
			},
			REMOVE_FOLDER: {
				text: locale.getString('common.delete'),
				name: 'delete',
				func: (folder: IDriversFolder) => this.actionsService.removeFolder(folder)
			},
			REMOVE_SUBFOLDER: {
				text: locale.getString('dashboard.menuDeleteSubfolder'),
				name: 'delete',
				func: (folder: IDriversFolder) => this.actionsService.removeFolder(folder)
			},

			TRANSFER_SELECTED: {text: locale.getString('dashboard.transferOwnerWidgetConfirmOption'), name: 'transfer'},

			SHOW: {text: locale.getString('common.show'), name: 'show-item',
				func: (driver: DriversTreeItem) => this.actionsService.toggleHide(driver as DriversItem), disabled: false},

			HIDE: {text: locale.getString('common.hide'), name: 'hide-item',
				func: (driver: DriversTreeItem) => this.actionsService.toggleHide(driver as DriversItem), disabled: false},
			SHOW_HIDE: {text: locale.getString('common.showHide'), name: 'show_hide'},

			SHOW_HIDDEN: { text: locale.getString('dashboard.showAllHidden'), name: 'toggleHidden', func: () => {
				this.scope.ui.hideDrivers = false;
			}},

			HIDE_HIDDEN: { text: locale.getString('dashboard.hideAllHidden'), name: 'toggleHidden', func: () => {
				this.scope.ui.hideDrivers = true;
			}},
		};

		constructor(private scope: IDriversContextMenuScope) {
			super();
			this.actionsService = new DriversActionsService(this.scope);
			this.folderSvc = new FolderService(FolderTypes.DRIVERS);
		}

		getContextMenu(item: DriversTreeItem): Array<ContextMenuItem<DriversTreeItem>> {
			if (this.scope.selectionUtils.multipleObjectsSelected()) {
				return this.getMultiSelectMenuOptions();
			}

			if (item.type === DriversType.drivers_template) {
				return [this.OPTIONS.EDIT];
			}

			if (item.type === DriversType.folder) {
				return this.getFolderMenuOptions(item as IDriversFolder);
			}
			return this.getDriverMenuOptions(item as DriversItem);
		}

		private getFolderMenuOptions(folder: IDriversFolder): Array<ContextMenuItem<DriversTreeItem>> {
			let items = [];

			items.push(this.OPTIONS.CREATE_DRIVERS);

			if (folder.level < FolderContextMenuUtils.MAX_DEPTH - 1) {
				items.push(this.OPTIONS.CREATE_SUBFOLDER);
			}

			items.push(this.OPTIONS.RENAME_FOLDER);

			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.driversList, folder, (item, folderTo) => {
				this.actionsService.moveToFolder(item, folderTo);
			});

			if (moveToItems && moveToItems.length > 0) {
				items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}

			items.push(DriversContextMenuUtils.MENU_DIVIDER);

			if (folder.level > 0) {
				items.push(this.OPTIONS.REMOVE_SUBFOLDER);
			} else {
				items.push(this.OPTIONS.REMOVE_FOLDER);
			}

			return BaseContextMenuUtils.enforceDividerRules(items);

		}

		private getDriverMenuOptions(item: DriversItem): Array<ContextMenuItem<DriversTreeItem>> {
			let mainItems: any[] = [];
			let moveCopyItems: any[] = [];
			let bottomItems: any[] = [];

			if (this.canEditDriver(item)) {
				if (item.status === DriversStatus.reportable && !security.getCurrentMasterAccount().predictionProviderDefault) {
					mainItems.push(this.OPTIONS.REFRESH);
				}
			}

			if (item.status === DriversStatus.defined || item.status === DriversStatus.reportable) {
				if (this.canEditDriver(item)) {
					mainItems.push(this.OPTIONS.EDIT);
				} else {
					mainItems.push(this.OPTIONS.VIEW);
				}
			}

			if (this.isOwner(item)) {
				mainItems.push(this.OPTIONS.SHARE);
			}

			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.driversList, item, (itemToMove, folderTo) => {
				this.actionsService.moveToFolder(itemToMove, folderTo);
			});
			if (moveToItems && moveToItems.length > 0) {
				moveCopyItems.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}
			moveCopyItems.push(this.OPTIONS.COPY);

			let showHideOptions = this.getShowHideOptions(item);
			let showHideItems = [this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions })];

			if (this.isOwner(item)) {
				bottomItems.push(this.OPTIONS.DELETE);
				this.addTransferringOption(bottomItems, [item]);
			}

			return this.concatMenus(mainItems, moveCopyItems, showHideItems, bottomItems);
		}

		private getMultiSelectMenuOptions(): Array<ContextMenuItem<DriversTreeItem>> {
			let items: any[] = [];

			let selectedItems = _.filter(this.scope.driversList, (drivers) =>
				this.scope.selectionUtils.isSupportedType(drivers) && drivers.selected
			) as DriversItem[];

			let moveToItems = _.uniq(_.flatten(_.map(selectedItems, (metric) => {
				let bulkFunction = this.wrapFunctionForBulkAction(
					(item, folderTo) => this.actionsService.moveToFolder(item, folderTo), selectedItems);

				return this.folderSvc.getFoldersForMove(this.scope.driversList, metric, bulkFunction);
			})), false, (moveItem) => moveItem.text);

			let isOwner = (itemsList) => {
				return _.filter(itemsList, this.isOwner).length === itemsList.length;
			};

			items.push(this.extend(this.OPTIONS.SHARE, {func: () => {
				this.actionsService.shareDrivers(selectedItems);
			}}));

			if (moveToItems && moveToItems.length > 0) {
				items.push(DriversContextMenuUtils.MENU_DIVIDER, this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}

			let showHideOptions = this.getShowHideOptions(selectedItems);
			items.push(DriversContextMenuUtils.MENU_DIVIDER, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			if (isOwner(selectedItems)) {
				items.push(DriversContextMenuUtils.MENU_DIVIDER, this.extend(this.OPTIONS.DELETE, {func: () =>
					this.actionsService.bulkDeleteDrivers(selectedItems)
				}));

				this.addTransferringOption(items, selectedItems);
			}

			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		private addTransferringOption(menuItems: any[], selectedItems: any[]): void {
			if (!(this.scope.props.projectId > 0)) {
				return;
			}

			menuItems.push(this.extend(this.OPTIONS.TRANSFER_SELECTED, {
				func: () => {
					let props = this.scope.props;
					let projectIdentifier = new ProjectIdentifier(props.contentProviderId, props.accountId, props.projectId);

					this.actionsService.bulkTransferDrivers(selectedItems, projectIdentifier).then(() => {
						this.scope.selectionUtils.clearSelections();
						this.scope.selectionUtils.refreshObjects(selectedItems);
					});
				}
			}));
		}

		private canEditDriver = (driver: DriversItem): boolean => {
			return manageDriversService.canEdit(driver);
		};

		private isOwner(driver: DriversItem): boolean {
			return manageDriversService.isOwner(driver);
		}

		isVisibleObject(item: any): boolean {
			return true;
		}

		private getShowHideOptions = (item: any | any[]) => {
			let showOption = angular.copy(this.OPTIONS.SHOW);
			let hideOption = angular.copy(this.OPTIONS.HIDE);
			let toggleVisibility = (this.scope.ui.hideDrivers) ?
				this.OPTIONS.SHOW_HIDDEN :
				this.OPTIONS.HIDE_HIDDEN;

			if (item.constructor === Array) {
				let selectedItems = item;
				hideOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(true, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
				showOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(false, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
			} else {
				if (item.hide) {
					hideOption.disabled = true;
					hideOption.func = _.noop;
				} else if (!item.hide) {
					showOption.disabled = true;
					showOption.func = _.noop;
				}
			}

			return [showOption, hideOption, BaseContextMenuUtils.MENU_DIVIDER, toggleVisibility];
		};
	};
});
