import { DriversStatus } from '@cxstudio/drivers/entities/drivers-item';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { Security } from '@cxstudio/auth/security-service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';
import * as _ from 'underscore';
import * as moment from 'moment';
import { DriversItem } from '../entities/drivers-item';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import { IDriversDialogUI } from './drivers-dialog-ui.interface';
import { DriversHelperService } from './drivers-helper.service';
import { DriverResourcesContainer } from '@cxstudio/drivers/editor/driver-resources-container';
import { IListOption } from '@cxstudio/drivers/editor/list-option.interface';
import { AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { PromiseUtils } from '@app/util/promise-utils';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { DriverResourcesProviderService } from '@app/modules/drivers/services/driver-resources-provider.service';

interface IDriversEditorScope extends ISimpleScope {
	driversSettings: any; // UI form validation
}

interface IDriversEditorParams {
	driversObject: DriversItem;
	itemList: DriversItem[];
	viewMode: boolean;
	openSpotCheck: boolean;
}

export class DriversEditorComponent extends ModalBindings<IDriversEditorParams> implements ng.IComponentController {

	// Do not allow clicking on the button again for 20 seconds.
	readonly DOWNLOAD_LOCK_PERIOD = 20 * 1000;
	readonly DEFAULT_MIN_VOLUME = 10;

	resolve: IDriversEditorParams;

	driversItem: DriversItem;
	ui: IDriversDialogUI;

	driverResources: DriverResourcesContainer;
	errors: {
		filterInvalid: boolean;
		targetInvalid: boolean;
		definitionEmpty: boolean;
	};

	downloadButtonLocked = false;

	private initialState: any;

	private initialNonDatasetState: {
		displayName: string;
	};
	private timezone: string;

	constructor(
		private $scope: IDriversEditorScope,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private filterValidationService,
		private security: Security,
		private cbDialogService: CBDialogService,
		private locale: ILocale,
		private driversUtils: DriversUtils,
		private driversHelperService: DriversHelperService,
		private driverResourcesProvider: DriverResourcesProviderService,
		private readonly downgradeDialogService: DowngradeDialogService,
	) {
			super();
	}

	$onInit(): void {
		this.driversItem = angular.copy(this.resolve.driversObject);
		this.initDriversObject(this.driversItem);
		this.initUI();

		this.errors = {
			filterInvalid: false,
			targetInvalid: false,
			definitionEmpty: false
		};
	}

	private initUI(): void {
		this.ui = {} as any;
		this.ui.projectSelection = _.pick(this.driversItem, ['contentProviderId', 'accountId', 'projectId']) as IProjectSelection;
		this.ui.viewMode = this.resolve.viewMode;
		this.ui.isDriversTemplate = this.driversItem.type === DriversType.drivers_template;
		this.ui.disableSaveButtons = false;

		this.reloadOptions();

		if (this.resolve.openSpotCheck) {
			this.openSpotCheckDialog(this.driversItem);
		}
	}

	private initDriversObject(item: DriversItem): void {
		let isNew = _.isUndefined(item.id);
		let defaultDefinition = {
			attributes: [],
			models: [],
			dateRange: {
				type: FilterRuleType.dateRange
			},
			timezone: {
				name: moment.tz.guess(),
				offset: new Date().getTimezoneOffset()
			},
			additionalFilters: {
				type: 'AND',
				filterRules: []
			},
			includeSentiment: false,
			includeEaseScore: false,
			includeTopicNames: false
		};
		let defaultTarget = {
			filters: {
				type: FilterTypes.AND,
				filterRules: [{
					type: FilterRuleType.empty
				}]
			} as AdhocFilter
		};

		item.definition = _.extend({}, defaultDefinition, item.definition); // fulfill empty fields if needed
		item.target = _.extend({}, defaultTarget, item.target); // fulfill empty fields if needed
		item.sharingStatus = SharingStatus.PRIVATE;

		if (isNew && _.isUndefined(item.minVolume))
			item.minVolume = this.DEFAULT_MIN_VOLUME;

		if (!isNew) {
			this.initialNonDatasetState = this.getNonDatasetSettings(item);
		}
	}

	private reloadOptions(): void {
		this.driverResources = this.driverResourcesProvider.getResources(this.ui.projectSelection);

		this.ui.loading = PromiseUtils.old(this.driverResources.get().then(result => {
			this.timezone = result.timezone;
			this.ui.loaded = true;
			this.$timeout(() => {
				this.initialState = angular.copy(this.getCurrentSettings());
				this.refreshAttributesStats();
				this.refreshDisabledItems();
			});
		}));
	}

	private getCurrentSettings(): any {
		return {
			selectedAttributes: this.driversItem.definition.attributes,
			selectedModels: this.driversItem.definition.models,
			filterRules: this.driversHelperService.getNonEmptyFilters(
				this.driversItem.definition.additionalFilters.filterRules),
			includeSentiment: this.driversItem.definition.includeSentiment,
			includeEaseScore: this.driversItem.definition.includeEaseScore,
			includeTopicNames: this.driversItem.definition.includeTopicNames,
			dateRange: this.driversItem.definition.dateRange,
			target: this.driversHelperService.getNonEmptyFilters(this.driversItem.target.filters.filterRules),
			// consider timezone only if "use user's timezone"
			timezoneName: !this.timezone && this.driversItem.definition.timezone.name
		};
	}

	private getNonDatasetSettings(item: DriversItem): any {
		return _.pick(item, 'displayName');
	}

	isReportableAndChanged = (): boolean => this.driversItem.status === DriversStatus.reportable && this.hasDatasetChanges();

	private hasDatasetChanges = (): boolean => this.initialState && !angular.equals(this.initialState, this.getCurrentSettings());

	private hasNonDatasetChanges = (): boolean => {
		return this.initialNonDatasetState && !angular.equals(this.initialNonDatasetState,
			this.getNonDatasetSettings(this.driversItem));
	};

	hasErrors = (): boolean => this.errors.filterInvalid || this.errors.targetInvalid || this.errors.definitionEmpty;

	save = (runExecution?: boolean) => {
		let driver = this.processDriverBeforeSave();
		this.validate(driver);
		if (this.hasErrors())
			return;
		let checkSave = this.$q.when();
		if (!runExecution && driver.status === DriversStatus.reportable
			&& this.hasDatasetChanges()) {
			checkSave = this.cbDialogService.confirm(
				this.locale.getString('common.confirm'),
				this.locale.getString('drivers.updateReportableWarning'),
				this.locale.getString('common.save'),
				this.locale.getString('common.cancel')
			).result;
		}


		if (runExecution) {
			this.ui.disableSaveButtons = true;
			this.ui.loading = this.driversUtils.driverHasDatasetErrors(driver).then((hasErrors: boolean) => {
				this.ui.disableSaveButtons = false;
				if (hasErrors) {
					return this.openSpotCheckDialog(driver);
				} else {
					return this.closeModal(driver, checkSave, runExecution);
				}
			});
		} else {
			return this.closeModal(driver, checkSave, runExecution);
		}
	};

	private closeModal = (driver: DriversItem, checkSave: ng.IPromise<any>, runExecution: boolean): any => {
		return checkSave.then(() => {
			this.driverResources.get().then((resources) => {
				return this.close({
					$value: {
						data: driver,
						needRun: runExecution,
						datasetChanged: this.hasDatasetChanges(),
						models: resources.models
					}
				});
			});
		});
	};

	private processDriverBeforeSave(): DriversItem {
		let driver = angular.copy(this.driversItem);
		let definition = driver.definition;
		definition.attributes = _.filter(definition.attributes, (attr: IListOption) => !attr.disabled);
		definition.models = _.filter(definition.models, (model: IListOption) => !model.disabled);
		definition.additionalFilters.filterRules = this.driversHelperService.getNonEmptyFilters(
			definition.additionalFilters.filterRules);
		driver.target.filters.filterRules = this.driversHelperService.getNonEmptyFilters(
			driver.target.filters.filterRules);
		return driver;
	}

	private validate(driver: DriversItem): void {
		this.errors.targetInvalid = !this.ui.isDriversTemplate
			&& !this.filterValidationService.validateFilterRules(driver.target.filters);
		this.errors.definitionEmpty = _.isEmpty(driver.definition.models) && _.isEmpty(driver.definition.attributes);
		this.errors.filterInvalid = !this.driversHelperService.areFiltersValid(driver.definition.additionalFilters);
	}

	disableSave = (): boolean => {
		if (this.ui.isDriversTemplate) {
			return !this.ui.loaded;
		} else {
			return !this.ui.loaded
				|| !this.isNameCorrect()
				|| this.ui.disableSaveButtons;
		}
	};

	private isNameCorrect(): boolean {
		let nameControl = this.$scope.driversSettings.name;
		return nameControl && !(nameControl.$error.required || nameControl.$error.unique);

	}

	// don't allow running drivers if we only renaming
	disableSaveAndExecute = (): boolean => this.disableSave()
		|| (this.driversItem.status === DriversStatus.reportable && !this.hasDatasetChanges());

	cancel = (): void => {
		if (this.hasDatasetChanges() || this.hasNonDatasetChanges()) {
			this
				.downgradeDialogService
				.showUnsavedChangesDialogAndResolve(
					this.save,
					this.dismiss,
					'drivers.unsavedChangesHeader',
					'drivers.unsavedChanges',
					false
				)
				.catch(() => {})
			;

			return;
		}

		this.dismiss();
	};

	test = () => {
		let driver = this.processDriverBeforeSave();
		this.validate(driver);
		if (this.hasErrors())
			return;
		this.openSpotCheckDialog(driver);
	};

	openSpotCheckDialog = (driver: DriversItem) => {
		this.cbDialogService.custom(
			this.locale.getString('drivers.spotCheckTitle', {driversName: driver.displayName}),
			'partials/drivers/drivers-modal.html',
			{
				item: driver,
				type: 'stats'
			}
		);
	};

	getSaveText = (): string => {
		let key = 'drivers.saveAndClose';
		if (this.ui.isDriversTemplate) {
			key = 'drivers.saveDefaults';
		} else {
			if (this.isReportableAndChanged()) {
				key = 'drivers.saveAndClear';
			}
		}
		return this.locale.getString(key);
	};

	getModalTitle = (): string => {
		return this.ui.isDriversTemplate
			? this.locale.getString('drivers.editDefaultDriversTitle')
			: this.locale.getString('drivers.driversEditTitle');
	};

	refreshAttributesStats = (): void => {
		this.$scope.$broadcast('drivers:refresh-attributes-stats');
	};

	refreshDisabledItems = (): void => {
		this.$scope.$broadcast('drivers:refresh-disabled-items');
	};
}

app.component('driversEditor', {
	controller: DriversEditorComponent,
	templateUrl: 'partials/drivers/drivers-editor-modal.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	}
});
