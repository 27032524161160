import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import BetaFeatureState from '@app/modules/context/beta-features/beta-feature-state';
import { BetaFeatureTracker, FeatureType } from '@app/modules/context/beta-features/beta-feature-tracker-class';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';

export interface IBetaFeatureRow {
	id: string;
	betaFeature: BetaFeatureTracker;
	name: string;
	description: string;
	enabled: boolean;
	type: FeatureType;
	available?: boolean;
}

@Component({
	selector: 'beta-features-tab',
	templateUrl: './beta-features-tab.component.html',
	styles: [`
		::ng-deep .beta-features-grid .slick-cell {
			height: 50px !important;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BetaFeaturesTabComponent implements OnInit {

	featureGroups: Array<{title: string; rows: IBetaFeatureRow[]}>;
	gridOptions: any;
	betaSearch: string;
	gridType = GridTypes.BETA_FEATURES;

	changedItems: IBetaFeatureRow[];

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private readonly toastService: ToastService,
		@Inject('security') private readonly security: Security,
		private betaFeaturesService: BetaFeaturesService,
		private gridUtils: GridUtilsService,
	) { }

	ngOnInit(): void {

		this.featureGroups = [
			this.getFeatureGroup(FeatureType.BETA_FEATURE, 'administration.beta'),
			this.getFeatureGroup(FeatureType.CONTROLLED_RELEASE, 'administration.controlledRelease'),
			this.getFeatureGroup(FeatureType.DEV_TEST, 'administration.spike')
		].filter(group => !_.isUndefined(group) && !_.isEmpty(group.rows));

		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};
	}

	private getFeatureGroup(type: FeatureType, titleKey: string): {title: string; rows: IBetaFeatureRow[]} {
		if(type === FeatureType.DEV_TEST) {
			if(!this.security.isSysAdmin() && !this.security.isAccountOwner()) {
				return;
			}
		}

		if(type === FeatureType.CONTROLLED_RELEASE) {
			if(!this.security.isAdminOrgUser()) {
				return;
			}
		}

		const betaFeatures = BetaFeature.getGroup(type);

		const betaFeatureStates: {[key: string]: BetaFeatureState} = _.object(
			betaFeatures.map(betaFeature => [
					betaFeature.id, this.betaFeaturesService.getFeature(betaFeature)
				]
			)
		) as any;

		const rows = betaFeatures
			.map(betaFeature => ({
				id: betaFeature.id,
				betaFeature,
				name: this.locale.getString('betaFeatureNames.' + betaFeature.id),
				description: this.locale.getString('betaFeatureDescriptions.' + betaFeature.id),
				available: betaFeatureStates[betaFeature.id].enabled,
				enabled: betaFeatureStates[betaFeature.id].switchOn,
				type: betaFeature.type
			}))
			.filter((f: IBetaFeatureRow) => f.available);
			// When this filter is removed, please check beta-feature.e2e.ts, as this will likely break tests

		return { title: this.locale.getString(titleKey), rows };
	}

	private onItemClick(event, feature: IBetaFeatureRow): void {
		if (this.gridUtils.isToggleClick(event) && !this.gridUtils.isToggleDisabled(event)) {
			let newValue = !feature.enabled;
			feature.enabled = newValue;
			this.refreshGrid(feature);
			this.betaFeaturesService.setBetaFeatureState(feature.betaFeature, newValue).then(() => {
				let key = newValue ? 'administration.featureEnabled' : 'administration.featureDisabled';
				this.toastService.addToast(this.locale.getString(key, [feature.name]), AlertLevel.SUCCESS);
			});

			return;
		}

		if (this.gridUtils.isElementClick(event, 'js-btn-request')) {
			this.betaFeaturesService.requestFeature(feature.betaFeature).then(() => {
				this.toastService.addToast(this.locale.getString('administration.featureRequested', [feature.name]), AlertLevel.SUCCESS);

			}).finally(() => {
				this.refreshGrid(feature);
			});
		}
	}

	private refreshGrid(feature?: IBetaFeatureRow): void {
		this.changedItems = [feature];
		this.ref.markForCheck();
	}
}

app.directive('betaFeaturesTab', downgradeComponent({component: BetaFeaturesTabComponent}));
