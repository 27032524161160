import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { SpotCheckService } from '@app/modules/dashboard-actions/dashboard-actions/spot-check/spot-check.service';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class CurrentObjectsService {
	private editMode: boolean; // currently used to pass editMode to the deepest places of widget controllers
	private readonly editModeChangeSubject: Subject<boolean> = new Subject<boolean>(); // used to inform about editMode change

	private snapshotView: boolean;
	private readonly snapshotViewChangeSubject: Subject<boolean> = new Subject<boolean>();

	constructor(
		private spotCheckService: SpotCheckService,
	) {
		this.editMode = false;
		this.snapshotView = false;
	}

	isEditMode(): boolean {
		return this.editMode;
	}

	setEditMode = (editMode: boolean): void => {
		this.spotCheckService.clearIgnoreList();
		this.editMode = editMode;
		this.editModeChangeSubject.next(editMode);
		if (editMode && this.snapshotView) {
			this.setSnapshotView(false);
		}
	};

	getEditModeChange(): Observable<boolean> {
		return this.editModeChangeSubject.asObservable();
	}

	getSnapshotViewChange(): Observable<boolean> {
		return this.snapshotViewChangeSubject.asObservable();
	}

	isSnapshotView(): boolean {
		return this.snapshotView;
	}

	setSnapshotView(value: boolean): void {
		this.snapshotView = value;
		this.snapshotViewChangeSubject.next(value);
	}
}

app.service('currentObjects', downgradeInjectable(CurrentObjectsService));
