import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, OnInit } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';

@Component({
	selector: 'time-grouping-settings',
	template: `
<div>
	<label>{{'widget.emptyPeriods'|i18n}}</label>
	<cb-push-buttons
		[list]="emptyPeriodTypes"
		[selectedValue]="innerValue"
		(selectedValueChange)="onChange($event)">
	</cb-push-buttons>
</div>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimeGroupingSettingsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeGroupingSettingsComponent extends BaseControlValueAccessor<EmptyPeriodType> implements OnInit {

	emptyPeriodTypes: UIOption<EmptyPeriodType>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref);
	 }

	ngOnInit(): void {
		this.emptyPeriodTypes = [
			{
				displayName: this.locale.getString('widget.showAllEmptyPeriods'),
				value: EmptyPeriodType.SHOW_ALL, htmlId: 'emptyPeriod_0'
			},
			{
				displayName: this.locale.getString('widget.showInterspersedEmptyPeriods'),
				value: EmptyPeriodType.SHOW_INTERSPERSED, htmlId: 'emptyPeriod_1'
			},
			{
				displayName: this.locale.getString('widget.doNotShowEmptyPeriods'),
				value: EmptyPeriodType.DO_NOT_SHOW, htmlId: 'emptyPeriod_2'
			}
		];
	}
}
