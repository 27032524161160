import { downgradeInjectable } from '@angular/upgrade/static';
import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { AccountOrWorkspace } from '@app/modules/units/workspace-project/workspace-project';
import IAlertSubscriptionTemplate from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { PromiseUtils } from '@app/util/promise-utils';

@Injectable({
	providedIn: 'root'
})
export class ManageAlertTemplatesService {


	constructor(
		@Inject('security') private readonly security: Security,
		@Inject('ProjectAccessLevels') private readonly ProjectAccessLevels: ProjectAccessLevelItems,
		@Inject('projectAccessService') private readonly projectAccessService: ProjectAccessService
	) {}

	getAdminAccessProjects = (workspace: AccountOrWorkspace): ng.IPromise<number[]> => {
		return PromiseUtils.old(this.projectAccessService.getProjectsAccessLevel(workspace).then((projects) => {
			return _.filter(projects, project => project.accessLevel === this.ProjectAccessLevels.MANAGER.value)
				.map(adminAccessProject => adminAccessProject.project.id);
		}));
	};

	canEdit = (template: IAlertSubscriptionTemplate, adminAccessProjects?: number[]): boolean => {
		return !template.owner
			|| this.security.isCurrentUser(template.owner)
			|| adminAccessProjects?.includes(template.projectId);
	};
}

app.service('manageAlertTemplatesService', downgradeInjectable(ManageAlertTemplatesService));

