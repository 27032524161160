import { IDateRange } from '@cxstudio/reports/entities/date-period';
import * as _ from 'underscore';
import * as moment from 'moment';
import * as cloneDeep from 'lodash.clonedeep';
import { DateUtilsService } from '@app/modules/utils/dates/date-utils.service';
import { NgbDateAdapter, NgbDateNativeAdapter, NgbDateStruct, NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { downgradeComponent } from '@angular/upgrade/static';
import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';


interface IDatePickerLimits {
	minDate?: NgbDateStruct;
	maxDate?: NgbDateStruct;
}

interface ILocalDateModel {
	from?: Date;
	to?: Date;
}

@Component({
	selector: 'date-picker',
	templateUrl: './date-picker.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}],
	styles: [`
		.btn.date-picker-anchor {
			padding-right: 15px !important;
		}
	`]
})
export class DatePickerComponent implements OnInit {

	@Input() dateRange: IDateRange;
	@Output() onChange: EventEmitter<IDateRange> = new EventEmitter();

	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;

	localDateModel: ILocalDateModel;
	datePickerLimits: IDatePickerLimits;
	dateRangeText: string;

	private originalDateRange: IDateRange;

	constructor(
		@Inject('dateUtils') private dateUtils: DateUtilsService,
		public adapter: NgbDateAdapter<Date>,
	) {}

	ngOnInit(): void {
		this.localDateModel = {};
		this.datePickerLimits = {};
		if (!this.dateRange)
			this.dateRange = {} as IDateRange;
	}

	onDropdownAnchor(): void {
		if (!this.dropdown.isOpen()) {
			this.showPopup();
		} else {
			this.applyAndClosePopup();
		}
	}

	showPopup = () => {
		this.originalDateRange = cloneDeep(this.dateRange);
		this.initializeModel();
		this.dropdown.open();
	};

	private initializeModel(): void {
		this.localDateModel = {};
		this.localDateModel.from = moment(this.dateRange.from).startOf('day').toDate();
		this.localDateModel.to = moment(this.dateRange.to).endOf('day').toDate();
		this.updateDateLimits();
	}

	updateDateLimits = (): void => {
		if (this.localDateModel.from)
			this.datePickerLimits.minDate = this.fromModel(this.localDateModel.from);
		if (this.localDateModel.to)
			this.datePickerLimits.maxDate = this.fromModel(this.localDateModel.to);
	};

	private fromModel = (date: string | Date): NgbDateStruct => {
		return this.adapter.fromModel(new Date(date));
	};

	setFromToday = () => {
		this.localDateModel.from = moment().startOf('day').toDate();
		this.updateDateLimits();
	};

	setToToday = () => {
		this.localDateModel.to = moment().endOf('day').toDate();
		this.updateDateLimits();
	};

	disableToTodayButton = (): boolean => {
		return moment().isBefore(moment(this.localDateModel.from));
	};

	disableFromTodayButton = (): boolean => {
		return moment().isAfter(moment(this.localDateModel.to));
	};

	saveDate = () => {
		if (!this.localDateModel.from || !this.localDateModel.to) {
			this.clear();
			return;
		}
		this.dateRange.from = moment(this.localDateModel.from).startOf('day').format();
		this.dateRange.to = moment(this.localDateModel.to).endOf('day').format();
		this.applyAndClosePopup();
	};

	clear = () => {
		this.localDateModel = {};
		delete this.dateRange.from;
		delete this.dateRange.to;
		this.applyAndClosePopup();
	};

	private applyAndClosePopup = (): void => {
		this.updateDateRangeText();
		if (this.onChange && !_.isEqual(this.dateRange, this.originalDateRange)) {
			this.onChange.emit(this.dateRange);
		}
		this.dropdown.close();
	};

	updateDateRangeText = () => {
		this.dateRangeText = this.dateUtils.formatDateRange(this.dateRange, true);
	};

}

app.directive('datePicker', downgradeComponent({ component: DatePickerComponent }));
