import * as cloneDeep from 'lodash.clonedeep';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { WidgetEvent } from '@app/core/cx-event.enum';
import { HomePageWidgetUtilsService } from '@app/modules/home-page/home-page-common/home-page-widget-utils.service';
import { WeightedFilterPipe } from '@app/shared/pipes/weighted-filter.pipe';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { QuickInsightsSearchService } from '@app/modules/home-page/quick-insights/quick-insights-search/quick-insights-search.service';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';


@Component({
	selector: 'quick-insights',
	templateUrl: './quick-insights.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})

export class QuickInsightsComponent extends SelfCleaningComponent implements OnInit {

	readonly CONTAINER_ID = 'quickInsights';

	@Input() searchValue: AttributeValueOption;
	@Output() searchValueChange = new EventEmitter<AttributeValueOption>();
	@Input() dashboards: Dashboard[];

	currentSearch: AttributeValueOption;
	dashboardData: IDashboardData;
	widgets: Widget[];
	baseWidgets: Widget[] = [];
	widgetMode = WidgetDisplayMode.HOME_PAGE;
	loadingPromise: PromiseLike<any>;
	loaded: boolean;
	hasData: boolean;

	availableDashboards: Dashboard[];
	filteredDashboards: Dashboard[] = [];
	gridOptions: SlickgridOptions;
	gridType = GridTypes.QUICK_INSIGHTS_DASHBOARD;
	gridMode = GridMode.EDIT;

	homePage: HomePage;

	constructor(
		private locale: CxLocaleService,
		private eventBus: GlobalEventBus,
		private ref: ChangeDetectorRef,
		private homePageWidgetUtils: HomePageWidgetUtilsService,
		private weightedFilter: WeightedFilterPipe<Dashboard>,
		private homePageApi: HomePageApiService,
		private currentObjects: CurrentObjectsService,
		private userHomePageService: UserHomePageService,
		private quickInsightsSearchService: QuickInsightsSearchService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		private gridUtils: GridUtilsService,
		@Inject('treeService') private treeService: TreeService,
		@Inject('navigationService') private navigationService: NavigationService
	) {
		super();
	}

	ngOnInit(): void {
		this.gridOptions = {
			onClick: this.onDashboardClick,
			disableInitialSort: true
		};
		this.availableDashboards = this.getAvailableDashboards();
		this.addSubscription(this.userHomePageService.getHomePage().subscribe(homePage => {
			this.initializeHomePage(homePage);
		}));

	}

	private initializeHomePage(homePage: HomePage): void {
		this.homePage = homePage;
		let widgetsPromise = this.userHomePageService.getQuickInsightsWidgets(homePage.id);
		let dashboardDataPromise = this.homePageWidgetUtils.getDashboardData(homePage);

		this.loadingPromise = Promise.all([widgetsPromise, dashboardDataPromise]).then(result => {
			let widgets = result[0];
			let dashboardData = result[1];
			this.widgets = this.processWidgets(widgets);
			this.dashboardData = dashboardData;
			this.currentObjects.setEditMode(false);
			this.currentWidgets.setWidgets(this.CONTAINER_ID, this.widgets, this.dashboardData.dashboardHistory);
			this.baseWidgets = cloneDeep(this.widgets);
			this.loaded = true;
			this.changeSearch(this.searchValue);
		});
	}

	private onDashboardClick = (event, dashboard: Dashboard): void => {
		if (this.gridUtils.isNameClick(event)) {
			this.homePageApi.logSearchSelectEvent(this.homePage.id, dashboard.name);
			this.navigationService.changeDashboard(dashboard, false);
		}
	};

	getAvailableDashboards(): Dashboard[] {
		return _.chain(this.dashboards)
			.filter(obj => (obj.type === DashboardType.DASHBOARD || obj.type === DashboardType.BOOK) && !obj.hide)
			.map(this.treeService.copyItem)
			.value() as Dashboard[];
	}

	processWidgets(widgets: Widget[]): Widget[] {
		widgets[0].displayName = this.locale.getString('homePage.metricWidgetTitle');
		widgets[0].description = this.locale.getString('homePage.metricWidgetDescription');

		widgets[1].displayName = this.locale.getString('homePage.lineWidgetTitle');
		widgets[1].description = this.locale.getString('homePage.lineWidgetDescription');

		widgets[2].displayName = this.locale.getString('homePage.tableWidgetTitle');
		widgets[2].description = this.locale.getString('homePage.tableWidgetDescription');

		_.each(widgets, widget => {
			widget.properties.altTextFromTitle = true;
			widget.properties.altText = widget.displayName;
		});
		return widgets;
	}

	changeSearch(searchValue: AttributeValueOption): void {
		this.currentSearch = searchValue;
		this.searchValueChange.emit(searchValue);
		if (this.currentSearch)
			this.runReports();

		this.filteredDashboards = this.getFilteredDashboards();
	}

	private getFilteredDashboards = (): Dashboard[] => {
		let dashboardsByName = this.weightedFilter.transform(this.availableDashboards, 'name', this.currentSearch?.displayName);
		let dashboardsByLabel = this.weightedFilter.transform(this.availableDashboards, (item) => {
			return item.labels?.length > 0
				? item.labels.map(label => label.text || '').join('\n')
				: undefined;
		}, this.currentSearch?.displayName);
		return _.uniq([...dashboardsByName, ...dashboardsByLabel]);
	};

	runReports(): void {
		this.widgets = cloneDeep(this.baseWidgets);
		let widgetIds = this.widgets.map(widget => widget.id);
		this.loadingPromise = this.quickInsightsSearchService.applyFilter(this.homePage.id, this.dashboardData.dashboardHistory,
				this.widgets, this.currentSearch)
			.then(() => this.homePageWidgetUtils.checkReportCount(this.widgets[0], this.dashboardData))
			.then(() => {
				this.hasData = true;
				this.eventBus.broadcast(WidgetEvent.RELOAD, widgetIds);
				this.ref.markForCheck();
			}, () => {
				this.hasData = false;
				this.ref.markForCheck();
			});
	}
}
