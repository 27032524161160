import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, SimpleChanges, Inject, OnChanges } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SentencePreviewElementBuildersService } from '@app/modules/reports/utils/sentence-preview-element-builders.service';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { downgradeComponent } from '@angular/upgrade/static';

export interface SentencePreviewActionEvent {
	event: UIEvent;
	sentence: PreviewSentence;
}

@Component({
	selector: 'sentence-preview',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<span
		id="sentence-preview-preview-{{sentence.id}}"
		class="br-sentence-text"
		(click)="onClick($event)"
		[ngClass]="{'sentence-highlighted': sentence.highlight, 'font-bold': isBold(), 'sentence-preview-hover': sentence.suggestionMenuOpened}"
		[innerHTML]="formattedSentence | safeHTML">
	</span>
	`
})
export class SentencePreviewComponent implements OnInit, OnChanges {
	@Input() sentence: PreviewSentence;
	@Input() document: boolean;
	@Input() selectedSentence: number;
	@Input() showSentiment: boolean;
	@Input() sentimentColorFunc;
	@Input() highlightTrigger: number;
	@Output() sentenceClick = new EventEmitter<SentencePreviewActionEvent>();

	formattedSentence: string;

	constructor(
		private locale: CxLocaleService,
		private sentencePreviewElementBuilders: SentencePreviewElementBuildersService
	) { }

	ngOnInit(): void {
		if (_.isUndefined(this.sentence)) {
			return;
		}
		this.initSentence();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.showSentiment?.previousValue !== changes.showSentiment?.currentValue) {
			this.formatSentence();
		}
	}

	isBold = () => this.sentence.id === this.selectedSentence && this.document;

	onClick = ($event) => {
		this.sentenceClick.emit({event: $event, sentence: this.sentence});
	};

	private initSentence = (): void => {
		if (this.sentence.chunks.length === 0) {
			this.sentence.chunks = [{ text: this.locale.getString('preview.emptyVerb') }];
		}
		if (this.sentence.chunks.length === 1 && this.sentence.chunks[0].text === '') {
			this.sentence.chunks[0].text = this.locale.getString('preview.emptyVerb');
		}
		this.formatSentence();
	};

	private formatSentence = (): void => {
		let spans = this.sentencePreviewElementBuilders.buildSentenceText(this.sentence,
			this.showSentiment, this.sentimentColorFunc);
		let sentenceHtml = _.chain(spans).pluck('outerHTML').join('').value();
		this.formattedSentence = this.document ? sentenceHtml : `<span>${sentenceHtml}</span>`;
	};
}

app.directive('sentencePreview', downgradeComponent({component: SentencePreviewComponent}));
