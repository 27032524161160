export class SlickHeaderUtils {
	private static readonly PRIMARY_HEADER_HEIGHT = 60; // from _measurements.scss
	private static readonly VIEWPORT_HEIGHT_LIMIT = 660;
	
	static pinSlickHeader = (): boolean => {
		if (window.innerHeight >= SlickHeaderUtils.VIEWPORT_HEIGHT_LIMIT) {
			let offset = $('item-grid:visible').offset();
			return offset && offset.top < SlickHeaderUtils.PRIMARY_HEADER_HEIGHT;
		} else {
			return false;
		}
	};
}