import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, Inject, ViewChild,
	AfterViewChecked } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { DatePickerPopupComponent } from '@app/shared/components/date-picker/date-picker-popup.component';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ExportTypes } from '@cxstudio/master-accounts/export-types.constant';
import { ExportQueryParameters, ExportService } from '@cxstudio/services/export-service.service';
import { NgbDateAdapter, NgbDateNativeUTCAdapter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';

@Component({
	selector: 'export-selector',
	templateUrl: './export-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeUTCAdapter}]
})
export class ExportSelectorComponent implements OnInit, AfterViewChecked {
	@Input() type: ExportTypes;
	@Input() logQueryBy: string;
	@Output() cancel = new EventEmitter<void>();

	@Input() query: any;
	@Output() queryChange = new EventEmitter<any>();
	@Input() dateFilters: {startDate: Date; endDate: Date};
	@Output() dateFiltersChange = new EventEmitter<{startDate: Date; endDate: Date}>();

	@ViewChild('startDate') from: DatePickerPopupComponent;
	@ViewChild('endDate') to: DatePickerPopupComponent;

	public amountOfRecords: number = 0;
	public exportDialogBusy: Promise<any> = null;
	public filters: {startDate: Date; endDate: Date};
	public searchFilterBy: string = '$';
	public searchFilter: string = '';
	public logQuery: any;
	public saving: boolean = false;
	public options: ListOption<string>[];

	constructor(
		@Inject('exportService') private exportService: ExportService,
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private adapter: NgbDateAdapter<Date>
	) { }

	ngOnInit(): void {
		this.options = [
			{name: this.locale.getString('mAccount.searchEverything'), value: '$' },
			{name: this.locale.getString('mAccount.searchSubject'), value: 'userName' },
			{name: this.locale.getString('mAccount.searchObject'), value: 'objectName' },
			{name: this.locale.getString('mAccount.searchAction'), value: 'action' },
			{name: this.locale.getString('mAccount.searchApiCall'), value: 'apiCall' },
		];

		this.filters = this.dateFilters || {
			startDate: new Date(),
			endDate: new Date()
		};

		this.logQuery = this.query;
		if (!this.logQueryBy) {
			this.logQueryBy = '$';
			this.logQuery = {};
		}

		this.onLogQueryByChange();
		this.retrieveAmountOfRecords();
	}

	ngAfterViewChecked(): void {
		this.from.date.maxDate = this.adapter.fromModel(this.filters.endDate);
		this.to.date.minDate = this.adapter.fromModel(this.filters.startDate);
	}

	onCancel(): void {
		this.cancel.emit();
	}

	export(): void {
		let queryParameters = this.createQueryParameters();

		this.exportDialogBusy = this.exportService.export(this.type, queryParameters).then((): any => {
			this.cancel.emit();
			this.cxDialogService.notify(
				this.locale.getString('administration.auditReport'),
				this.locale.getString('administration.requestResultMessage'),
				{keyboard: true});
		}, (): any => {
			this.saving = false;
		}) as unknown as Promise<any>;
	}

	retrieveAmountOfRecords(): void {
		let queryParameters = this.createQueryParameters();

		this.exportDialogBusy = this.exportService.retrieveAmountOfRecords(this.type, queryParameters).then((count: number) => {
			this.saving = false;
			this.amountOfRecords = count;
		}) as unknown as Promise<any>;
	}

	createQueryParameters(): ExportQueryParameters {
		this.saving = true;
		let properties = {};
		let queryRange = {
			startDate: moment(this.filters.startDate).startOf('day'),
			endDate: moment(this.filters.endDate).endOf('day')
		};

		properties['startDate'] = moment(queryRange.startDate).format();
		properties['endDate'] = moment(queryRange.endDate).format();

		properties['filterText'] = this.searchFilter;
		properties['filterField'] = this.searchFilterBy;
		return properties;
	}

	onLogQueryByChange(): void {
		this.searchFilterBy = this.logQueryBy;
		this.searchFilter = this.logQuery[this.logQueryBy];
	}

}
