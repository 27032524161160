import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import Listener from '@cxstudio/common/listener';
import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { ISharePermissionNameGetter, IShareUiConfig } from '@cxstudio/sharing/generic-share.component';
import { SharableAsset, SharingModalResult, SharingService } from '@cxstudio/sharing/sharing-service.service';

@Component({
	selector: 'home-page-sharing',
	template: `
<generic-share
	class="no-modal-sharing"
	objectTypeName="{{'object.homePage'|i18n}}"
	[resolve]="sharingParams"
	[getPermissionName]="getPermissionName"
	[getSharedUsersAndGroups]="getSharedUsersAndGroups"
	[getShareStatus]="getShareStatus"
	[saveListener]="saveListener"
	[embedded]="true"
	[uiConfig]="uiConfig"
	[onChange]="onSharingChange"
	[oneClickRemoval]="true"
	[singlePermissionMode]="true">
</generic-share>
`})
export class HomePageSharingComponent implements OnInit {

	@Input() homePage: HomePage;
	@Input() saveListener: Listener;
	@Output() onChange = new EventEmitter<void>();

	sharingParams: {
		items: SharableAsset[];
		data: SharingModalResult;
	};

	getPermissionName: ISharePermissionNameGetter = {
		edit: (): string => 'manage_settings',
		shareView: (): string => 'manage_settings',
		shareEdit: (): string => '-',
		defaultSharePermission: (): string => PermissionType.VIEW
	} as any;

	uiConfig: IShareUiConfig;

	constructor(
		private homePageApi: HomePageApiService,
		private locale: CxLocaleService,
		@Inject('sharingService') private sharingService: SharingService,
	) { }

	ngOnInit(): void {
		let sharedItem = this.homePage;

		this.sharingParams = {
			items: [ sharedItem ],
			data: {
				sharedEntities: [],
				changedEntities: []
			} as SharingModalResult
		};
		this.uiConfig = {
			inviteLabel: ''
		};
	}

	
	getShareStatus = (): string => {
		return this.homePage.sharingStatus;
	};

	getSharedUsersAndGroups = () => {
		return this.homePage.id ? this.homePageApi.getHomePageAccess(this.homePage.id) : Promise.resolve({users: [], groups: []});
	};

	onSharingChange = () => {
		this.onChange.emit();
		this.saveListener.invokeListeners();
		let allEntities = this.sharingService.prepareEntities(this.sharingParams.data);
		let accessChange = _.groupBy(allEntities, entity => entity.type);
		this.homePage.accessChange = accessChange;
	};
}
