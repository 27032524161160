<modal-header
	(cancel)="dismiss()"
	[modalTitle]="'widget.advancedOptions' | i18n"
></modal-header>
<section class="modal-body">
	<div class="row">
		<div class="col-xs-12 col-sm-6 col-md-4">
			<an-axis-scale
				[visualProps]="visualProps"
				[prefix]="primaryPrefix"
				[label]="primaryAxisDisplayName"
				[locked]="lockAxisLimits">
			</an-axis-scale>
		</div>
		<div class="col-xs-12 col-sm-6 col-md-4" [hidden]="!showSecondary">
			<an-axis-scale
				[visualProps]="visualProps"
				[prefix]="secondaryPrefix"
				[label]="secondaryAxisDisplayName"
				[locked]="lockAxisLimits">
			</an-axis-scale>
		</div>
	</div>
	<div class="mb-16" [hidden]="!isCalculationSeries">
		<div>
			<label>{{'widget.axisTitle' | i18n}}</label>
		</div>
		<div class="adv-chart-settings-block">
			<input class="cb-axis-title" type="text"
			   [(ngModel)]="axisTitle"
			   maxlength="30"
			   placeholder="{{'widget.title' | i18n}}"
			>
		</div>
	</div>
	<div class="mt-16" [hidden]="!showReferenceLines()">
		<h2>{{'widget.referenceLine' | i18n}}</h2>
		<div [hidden]="!showAddReferenceLineCheckbox()">
			<checkbox-button
				[(ngModel)]="ui.addLineCheckbox"
				(ngModelChange)="onChangeHandler()"
				[buttonClass]="'btn-sm mb-8'"
				[label]="'widget.addReferenceLine'| i18n"
				data-testid="add-line-checkbox"
			>
			</checkbox-button>
		</div>
		<div [hidden]="!showAddMetricReferenceLineCheckbox()">
			<checkbox-button
				[(ngModel)]="ui.addLineCheckbox"
				(ngModelChange)="onChangeHandler()"
				[buttonClass]="'btn-sm mb-8'"
				[label]="'widget.showMetricReferenceLines' | i18n"
				data-testid="add-line-checkbox"
			>
			</checkbox-button>
		</div>
	</div>
	<div class="mt-16" *ngIf="showReferenceLines() && studioMetrics">
		<div [hidden]="!showReferenceLineBuilder()">
			<div class="row d-flex align-items-center">
				<b class="col-lg-2 col-xl-1" [hidden]="!showSecondary">{{'widget.axis' | i18n}}</b>
				<b class="col-lg-2 col-xl-1" [hidden]="!isDynamicReferenceBetaEnabled()">{{'widget.calculation' | i18n}}</b>
				<b class="col-sm-2">{{'widget.referenceLine' | i18n}}</b>
				<b class="col-lg-2 col-xl-1">{{'common.value' | i18n}}</b>
				<b class="col-sm-2">{{'widget.type' | i18n}}</b>
			</div>

			<div *ngFor="let referenceLine of newReferenceLines; let i = index"
				 data-testid="reference-line-builder-wrap">
				<reference-line-builder
					(addLine)="addReferenceLine($event)"
					(updateLine)="updateReferenceLine(i, $event)"
					(removeLine)="removeReferenceLine(i)"
					[plotLine]="referenceLine"
					[index]="i"
					[showSecondary]="showSecondary"
					[calculationOptions]="calculationOptions"
					[showDuplicate]="showDuplicateErrorMessage"
					[axisNames]="axisNames"
					[axisSettings]="axisSelections"
					[allMetrics]="studioMetrics">
				</reference-line-builder>
			</div>
		</div>
		<div [hidden]="!showAddTimeReferenceLineCheckbox()">
			<checkbox-button
				data-testid="show-time-reference-lines"
				[(ngModel)]="ui.addTimeLineCheckbox"
				(ngModelChange)="onChangeHandler()"
				[buttonClass]="'btn-sm mb-8'"
				[label]="'widget.showEventReferenceLines' | i18n">
			</checkbox-button>
		</div>
		<div [hidden]="!showTimeReferenceLineBuilder()">
			<div class="row">
				<strong class="col-lg-2 col-xl-1">{{'widget.type' | i18n}}</strong>
				<strong class="col-sm-2">{{'widget.referenceLine' | i18n}}</strong>
				<strong class="col-sm-2">{{'widget.style' | i18n}}</strong>
				<div>
					<strong class="col-sm-2">{{dateTitle}}</strong>
				</div>
			</div>
			<div *ngFor="let timeReferenceLine of newTimeReferenceLines; let i = index">
				<time-reference-line-builder
					(addLine)="addTimeReferenceLine($event)"
					(updateLine)="updateTimeReferenceLine(i, $event)"
					(removeLine)="removeTimeReferenceLine(i)"
					(updateDateTitle)="updateDateTitle($event)"
					[plotLine]="timeReferenceLine"
					[index]="i">
				</time-reference-line-builder>
			</div>
		</div>
	</div>
	<case-viz-settings
		class="d-block mt-16"
		*ngIf="showCaseSettings()"
		[(ngModel)]="visualProps.caseVisualizations"
		[project]="widgetProject"
		[widgetDateRange]="widgetDateRange">
	</case-viz-settings>
	<div class="mt-16" *ngIf="showQuadrants()">
		<plot-bands-configurer
			[visualProps]="visualProps"
			[axisNames]="configurerAxisNames">
		</plot-bands-configurer>
	</div>
</section>
<save-modal-footer
	(save)="submit()"
	(cancel)="dismiss()"
	[saveText]="'common.update' | i18n"
	[cancelText]="'common.cancel' | i18n">
</save-modal-footer>
