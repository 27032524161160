import * as _ from 'underscore';
import * as moment from 'moment';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { LicenseType } from '@cxstudio/common/license-types';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { UserManagementHelperService } from '@app/modules/user-administration/user-management-helper.service';
import { AccessExpirationOption } from './access-expiration/access-expiration-option.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { InternalUser } from '@cxstudio/user-administration/users/entities/internal-user';


interface IWizardParams {
	internalUserList: User[];
	isExtend: boolean;
	currentMaName: string;
	userId: string;
}

export class AddInternalUserWizardController extends ModalBindings<IWizardParams> {

	loading: ng.IPromise<any>;
	internalUsers: User[];
	isExtend: boolean = false;
	currentMaName: string;

	formData: {
		selectedAccounts: any;
		accessExpiration: any;
		selectedUserEmail?: string;
		selectedUser?: User | InternalUser;
	};

	descriptionText: string;
	addInternalUserNote: string;
	headerText: string;
	finishButtonText: string;
	internalUserDialog: ng.IFormController;

	private expirationOptions: any;
	private accountsAutoPopulated: boolean = false;

	constructor(
		private locale: ILocale,
		private usersGroupsApiService: UsersGroupsApiService,
		private security: Security,
		private userApiService: UserApiService,
		private userManagementHelper: UserManagementHelperService,
		private accessExpirationOption: AccessExpirationOption
	) {
		super();
	}

	$onInit(): void {
		this.expirationOptions = this.accessExpirationOption.values();

		this.loading = null;
		this.internalUsers = this.resolve.internalUserList;
		this.isExtend = !!this.resolve.isExtend;
		this.currentMaName = this.resolve.currentMaName;

		this.formData = {
			selectedAccounts: {},
			accessExpiration: {}
		};

		this.checkForPassedInUserId();

		if (!this.security.has('system_administration')) {
			this.loading = this.userApiService.getLinkedCpAccounts().then((response) => {
				let currentUserAccounts = response.data;
				if (this.userManagementHelper.isOnlyOneAccountSelected(currentUserAccounts)) {
					this.formData.selectedAccounts = currentUserAccounts;
					this.accountsAutoPopulated = true;
				}
			});
		}

		this.descriptionText = this.isExtend ?
			this.locale.getString(
				'administration.extendInternalUserDescription',
				{userEmail: this.formData.selectedUser?.userEmail, currentMaName: this.currentMaName}
			)
			: this.locale.getString('administration.addInternalUserDescription', {currentMaName: this.currentMaName});
		this.addInternalUserNote = this.locale.getString('administration.addInternalUserNote');

		this.headerText = this.isExtend ? this.locale.getString('administration.extendInternalUserHeader')
			: this.locale.getString('administration.addInternalUserDialog');

		this.finishButtonText = this.isExtend ? this.locale.getString('common.save') : this.locale.getString('common.add');
	}

	isFormValid = () => {
		return this.internalUserDialog
			&& !this.internalUserDialog.$pending && this.internalUserDialog.$valid
			&& this.formData.selectedUser;
	};

	isAccountsPopulated = () => {
		return this.accountsAutoPopulated || this.isExtend;
	};

	isAccountSelected = () => {
		let keys = Object.keys(this.formData.selectedAccounts);
		for (let key of keys) {
			if (this.formData.selectedAccounts[key].length > 0) {
				return true;
			}
		}
		return false;
	};

	private getCPAccounts(selectedAccounts): any[] {
		let cpAccounts = [];
		Object.keys(selectedAccounts).forEach((contentProviderId) => {
			let accounts = [];
			selectedAccounts[contentProviderId].forEach((accountId) => {
				accounts.push({
					accountId,
					accountAdmin: true
				});
			});

			cpAccounts.push({
				id: contentProviderId,
				accounts
			});
		});

		return cpAccounts;
	}

	private finish(): void {
		this.close({$value: undefined});
	}

	private saveUserCallback = () => {
		let user = this.formData.selectedUser;
		let linkingInfo = {
			userId: user.userId,
			addedAccounts: this.formData.selectedAccounts,
			remainedAccounts: {},
			removedAccounts: {},
			projectsAccess: {
				cpAccounts: this.getCPAccounts(this.formData.selectedAccounts)
			}
		};

		if (!this.userManagementHelper.isEmptyLinkingAccounts(linkingInfo)) {
			this.syncCPUsers(linkingInfo);
		} else {
			this.finish();
		}
	};

	syncCPUsers = (linkingInfo) => {
		let options = {
			forceUpdate: true,
			showDialog: true
		};
		this.loading = this.userApiService.syncUserInContentProviders(
			linkingInfo, options).then((errorMessages) => {
			this.finish();
		});
	};

	private saveUserErrorCallback = () => {
		this.loading = null;
	};

	add = () => {
		let currentUser = this.formData.selectedUser;
		let accessExpiration = this.formData.accessExpiration;

		if (this.isExtend) {
			let accessExpirationDate = moment()
				.add(accessExpiration.period, 'days')
				.toDate();

			this.loading = this.userApiService.extendUserMasterAccountAccess(currentUser.userId, accessExpirationDate)
				.then(this.saveUserCallback, this.saveUserErrorCallback);

			return;
		}

		this.loading = this.usersGroupsApiService.getPermissions().then((data) => {
			let permissions = data;
			let userData = this.userManagementHelper.getRestUserData(currentUser);
			userData.userEmail = currentUser.userEmail;
			userData.masterAccountPermissions = permissions;
			userData.licenseTypeId = LicenseType.ANALYZE;
			userData.accessExpirationDate = moment()
				.add(accessExpiration.period, 'days')
				.toDate();

			this.loading = this.userApiService.addInternalUserToMasterAccount(currentUser.userId, userData)
				.then(this.saveUserCallback, this.saveUserErrorCallback);
		});
	};

	cancel = () => {
		this.dismiss({$value: 'cancel'});
	};

	private async checkForPassedInUserId(): Promise<void> {
		if (!this.resolve.userId) {
			return;
		}

		const users = await this.userApiService.searchUsersByText(
			this.resolve.userId,
			'id',
			1
		) as User[];

		if (users.length === 0) {
			this.cancel();

			return;
		}

		this.formData.selectedUser = users[0];
	}

	handleUserSelect = (user: User | InternalUser) => {
		this.formData.selectedUser = user;

		if (isEmpty(user)) {
			return;
		}

		this.formData.accessExpiration = user.liteAdmin
			? this.security.isAccountOwner()
				? this.expirationOptions.DAYS_30
				: this.expirationOptions.DAYS_3
			: this.expirationOptions.DAYS_90;
	};

	getExpirationPeriods = () => {
		let user = this.formData.selectedUser;

		if (isEmpty(user)) {
			return [];
		}

		if (user.liteAdmin) {
			return this.security.isAccountOwner()
				? this.accessExpirationOption.liteAdminByAccountOwner()
				: this.accessExpirationOption.adminValuesArray();
		}

		return this.accessExpirationOption.nonAdminValuesArray();
	};
}

app.component('addInternalUserWizard', {
	controller: AddInternalUserWizardController,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	templateUrl: 'partials/user-administration/users/add-internal-user-wizard-component.html',
});
