import { Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

/**
 * Required by protractor, used in idex.html
 */

@Component({
	selector: 'cx-ng-init',
	template: '<div keepAlive></div>'
})
export class CxNgInitComponent implements OnInit {
	constructor() {}
	ngOnInit(): void {}
}

app.directive('cxNgInit', downgradeComponent({ component: CxNgInitComponent }));
