export default class Browser {
	private name: string;

	constructor(
		private readonly $window: ng.IWindowService
	) {
		this.name = this.getBrowser();
	}

	getBrowser = (): string => {
		if (!this.$window) {
			return 'unknown';
		}

		let userAgent = this.$window.navigator.userAgent;
		let  browsers = {chrome: /chrome/i, safari: /safari/i, firefox: /firefox/i, ie: /msie|trident/i};

		for (let key in browsers) {
			if (browsers[key].test(userAgent)) {
				return key;
			}
		}
		return 'unknown';

	};
}

app.service('browser', Browser);
