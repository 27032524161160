import { LocalizedConstants } from '@app/util/localized-constants.class';
import ILocale from '@cxstudio/interfaces/locale-interface';

export interface IAccessExpirationOption {
	period: number;
	name: string;
	title: string;
	menuText: string;
	dialogTitle: string;
	dialogConfirm: string;
}

export class AccessExpirationOption extends LocalizedConstants<IAccessExpirationOption> {

	constructor(
		private readonly locale: ILocale
	) {
		super();

		this.options = {
			NOW: {
				period: 0,
				name: 'now',
				title: this.locale.getString('administration.accessExpireNow'),
				menuText: this.locale.getString('administration.expireNow'),
				dialogTitle: this.locale.getString('administration.expireNowConfirmationDialog'),
				dialogConfirm: this.locale.getString('administration.expireNowConfirm')
			},
			DAYS_1: this.getExpirationObjectByDays(1),
			DAYS_3: this.getExpirationObjectByDays(3),
			DAYS_7: this.getExpirationObjectByDays(7),
			DAYS_30: this.getExpirationObjectByDays(30),
			DAYS_90: this.getExpirationObjectByDays(90)
		};
	}

	private getExpirationObjectByDays(days: 1 | 3 | 7 | 30 | 90): IAccessExpirationOption {
		return {
			period: days,
			name: `${days}days`,
			title: this.locale.getString('administration.accessExpireDays', { days }),
			menuText: this.locale.getString('administration.extendAccessDays', { days }),
			dialogTitle: this.locale.getString('administration.extendAccessConfirmationDialog'),
			dialogConfirm: this.locale.getString('administration.extendAccessDaysConfirm', { days })
		};
	}

	adminValuesArray = () => [
		this.options.DAYS_1,
		this.options.DAYS_3,
		this.options.DAYS_7
	];

	nonAdminValuesArray = () => [
		this.options.DAYS_7,
		this.options.DAYS_30,
		this.options.DAYS_90
	];

	liteAdminByAccountOwner = () => [
		this.options.DAYS_1,
		this.options.DAYS_3,
		this.options.DAYS_7,
		this.options.DAYS_30,
		this.options.DAYS_90
	];

	findByPeriod = (period) => this.findByValue(period, 'period');

	findByName = (name) => this.findByValue(name, 'name');
}

app.service('accessExpirationOption', AccessExpirationOption);
