import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { GeographyService } from '@app/modules/model/services/geography.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { IndeterminateTopicSelectionImpl } from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.class';
import IndeterminateTopicSelection from '@cxstudio/reports/providers/cb/definitions/indeterminate-topic-selection.interface';
import TopicSelectionNode from '@cxstudio/reports/providers/cb/definitions/topic-selection-node.interface';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';

@Component({
	selector: 'topic-inclusion-settings',
	templateUrl: './topic-inclusion-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicInclusionSettingsComponent implements OnInit {

	@Input() project: AccountOrWorkspaceProject;
	@Input() modelId: number;

	@Input() selectedNodes: number[];
	@Output() selectedNodesChange = new EventEmitter<number[]>();

	@Input() inheritTopics: boolean;
	@Output() inheritTopicsChange = new EventEmitter<boolean>();

	@Input() selectedLevel: number;
	@Output() selectedLevelChange = new EventEmitter<number>();

	@Input() selectedLevels: number[];
	@Output() selectedLevelsChange = new EventEmitter<number[]>();

	@Input() geography?: boolean; // map widget
	@Input() customMultiselect: boolean; // for selector widget

	@Input() showLevel: boolean;

	@Input() subtopicsOnly: boolean;
	@Output() subtopicsOnlyChange = new EventEmitter<boolean>();

	@Input() updateOnParentFilter: boolean;
	@Output() updateOnParentFilterChange = new EventEmitter<boolean>();

	private checkedNodes: TopicSelectionNode[];
	allowedLevels?: number[]; // for geography models
	topicLevelsList: UIOption<number>[];
	modelRoot: ModelTreeNode;
	indeterminateTopicSelection: IndeterminateTopicSelection;
	loading: Promise<unknown>;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private modelsService: ModelsService,
		private reportSettingsService: ReportSettingsService,
		private geographyService: GeographyService,
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
	) {
		this.indeterminateTopicSelection = new IndeterminateTopicSelectionImpl({
			getSelectedNodes: () => this.selectedNodes,
			setSelectedNodes: (selectedNodes) => {
				this.selectedNodesChange.emit(selectedNodes);
			},
			getCurrentSelection: () => this.checkedNodes,
			setCurrentSelection: (selection) => {
				this.checkedNodes = selection;
			},
			getModelTree: () => this.modelRoot,
			getSelectedLevels: () => this.isMultiLevel()
				? this.selectedLevels
				: [this.selectedLevel],
			isTopicLeafEnabled: () => this.isTopicLeaf(),
			isIndeterminateDisabled: () => this.customMultiselect
		});
	}

	ngOnInit(): void {
		this.loading = this.getModelTree().then(tree => {
			this.modelRoot = tree.root;
			this.generateLevels(this.modelRoot);
			this.applyModelDefaults();
			this.ref.markForCheck();
		});

	}

	private getModelTree(): Promise<ModelTree> {
		return this.modelsService.getModelTree(this.project, this.modelId).then(modelTree => {
			if (this.geography) {
				return this.geographyService.getGeographyModel(
					this.project, this.modelId).then(geography => {
						this.allowedLevels = Object.keys(geography.boundaryFields)
							.map(level => parseInt(level, 10))
							.sort();
						return modelTree;
					});
			} else {
				return modelTree;
			}
		});
	}

	levelChanged(): void {
		this.indeterminateTopicSelection.updateSelectedLevel();
	}

	private isTopicLeaf(): boolean {
		return this.selectedLevel === TopicReportGrouping.LEAF_LEVEL;
	}

	private generateLevels(root: ModelTreeNode): void {
		let maxLevels = this.hierarchyService.getDepth(root);
		if (this.allowedLevels) {
			this.topicLevelsList = this.allowedLevels.map(level => ({value: level, displayName: level + ''}));
		} else {
			this.topicLevelsList = _.range(1, maxLevels + 1).map(level => ({value: level, displayName: level + ''}));
			this.topicLevelsList.push({value: TopicReportGrouping.LEAF_LEVEL, displayName: this.locale.getString('widget.leaf')});
		}
	}


	applyModelDefaults(): void {
		if (this.inheritTopics) {
			const settingsPromise = this.reportSettingsService.getTopicGroupingSettings(this.project, this.modelId);
			settingsPromise.then((modelSettings) => {
				this.selectedLevel = modelSettings ? modelSettings.selectedLevel : undefined;
				this.selectedLevelChange.emit(this.selectedLevel);
				this.selectedNodes = modelSettings ? modelSettings.selectedNodes : undefined;
				this.selectedNodesChange.emit(this.selectedNodes);

				this.levelChanged();
				this.checkDefaultSelectedLevel();
				this.indeterminateTopicSelection.processInclusionList();
				this.ref.markForCheck();
			});
			this.loading = settingsPromise;
			this.ref.markForCheck();
		} else {
			if (!this.isMultiLevel())
				this.checkDefaultSelectedLevel();
			this.indeterminateTopicSelection.processInclusionList();
		}
	}

	private checkDefaultSelectedLevel(): void {
		if (!this.selectedLevel) {
			this.selectedLevelChange.emit(this.topicLevelsList[0].value);
		}
	}

	deselectAll(): void {
		this.indeterminateTopicSelection.deselectAll();
	}

	selectAll(): void {
		this.indeterminateTopicSelection.selectAll();
	}

	isNodeCheckboxDisabled = (): boolean => {
		return this.inheritTopics;
	};

	isMultiLevel(): boolean {
		return !!this.selectedLevels;
	}

	isIndeterminateFollowedByUncheck(): boolean {
		return !this.customMultiselect;
	}

	wrapPredicate(predicate: (node: INode) => boolean): ($event: {node: INode}) => boolean {
		return ($event: {node: INode}) => predicate($event.node);
	}

}
