import * as _ from 'underscore';
import { PreviewColumn, PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { PreviewTableColumn } from '@cxstudio/reports/preview/preview-table-column';


export class AnPreviewSelectedColumns implements angular.IComponentController {

	columns: PreviewTableColumn[];
	selectedModels;
	availableAttributes: any[];
	availableModels: any[];
	isTable: boolean;
	isVerbatim: boolean;
	onDrop: (column: PreviewTableColumn) => void;
	onRemove: (column: PreviewTableColumn) => void;
	onConfig: (column: PreviewTableColumn) => void;
	onModelSelected: (model) => void;

	constructor(
		private readonly previewPredefinedColumns: PreviewPredefinedColumns
	) {}

	$onInit(): void {
		this.columns.forEach((col) => {
			if (col.type === ReportAssetType.SYS) {
				const baseCol = this.previewPredefinedColumns.get(col.name as PreviewColumn);
				col.displayName = baseCol?.displayName ?? col.displayName;
			}
		});
	}

	isDisabled = (item: PreviewTableColumn): boolean => {
		return item.disabled || (!this.isTable && (item.type !== PreviewDataType.SYS || item.name === PreviewColumn.TOPICS));
	};

	isRemovable = (item: PreviewTableColumn): boolean => {
		return item.name !== PreviewColumn.SENTENCE;
	};

	showTopicSelection = (item: PreviewTableColumn): boolean => {
		return item.name === PreviewColumn.TOPICS && !this.isDisabled(item);
	};

	isModelSelected(model: any): boolean {
		return _.contains(this.selectedModels, model.id);
	}

	// all non-system non-numeric columns should have capitalization options
	// only some system columns should have capitalization options
	hasOptions = (item: PreviewTableColumn): boolean => {
		if (this.isDisabled(item)) return false;

		if (!this.isTable) {
			return item.name === PreviewColumn.SENTENCE && !this.isVerbatim;
		}

		return (item.type === PreviewDataType.SYS) ? item.isConfigurable : (item.type !== ReportAssetType.NUMBER);
	};

	isDraggable = (): boolean => this.isTable && this.columns.length > 1;
}

app.component('anPreviewSelectedColumns', {
	controller: AnPreviewSelectedColumns,
	templateUrl: 'partials/widgets/settings/cb/components/an-preview-selected-columns.html',
	bindings: {
		columns: '<',
		selectedModels: '<',
		availableAttributes: '<?',
		availableModels: '<?',
		onDrop: '&',
		onRemove: '&',
		onConfig: '&',
		onModelSelect: '&',
		isTable: '<',
		isVerbatim: '<'
	}
});
