import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export interface TemplateOptions {
	project: AccountOrWorkspaceProject;
	attributes: any[];
	models: any[];
	metrics: any[];
	hierarchyModels: IReportModel[];
	projectContext: IProjectContext;
	wordAttributes: any[];
}

export class TemplateOptionsService {

	constructor(
		private $q: ng.IQService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private metricConstants: MetricConstants,
		private organizationApiService: OrganizationApiService,
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
		private readonly metricsService: MetricsService
	) {}

	//TODO: add predefined group by and calculation

	buildCalculationOptions = (templateOptions: TemplateOptions, includingDefaults: boolean = false) => {

		let builder = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withAttributes(templateOptions.attributes, MetricFilters.CALCULATION)
			.withMetrics(templateOptions.metrics, templateOptions.project.projectId);

		if (includingDefaults) {
			builder.withStandardMetrics(this.metricConstants.getStandardCalculations());
		}

		return builder.build();
	};

	buildGroupingOptions = (templateOptions: TemplateOptions) => {
		return this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
			.withModels(templateOptions.models)
			.withTime(templateOptions.attributes)
			.withAttributes(templateOptions.attributes, MetricFilters.GROUP_FILTER)
			.withWordAttributes(templateOptions.wordAttributes)
			.withOrgHierarchyModels(templateOptions.models, templateOptions.hierarchyModels)
			.withMetrics(templateOptions.metrics, templateOptions.project.projectId)
			.build();
	};

	loadTemplateOptions = (project: AccountOrWorkspaceProject): ng.IPromise<TemplateOptions> => {
		let hierarchyModelsPromise = this.organizationApiService.getOrgHierarchyModels(project);
		return this.$q.all([
			PromiseUtils.old(this.attributesService.getAttributes(project)),
			PromiseUtils.old(this.modelsService.getModelsWithHierarchies(project)),
			PromiseUtils.old(this.metricsService.getMetrics(project)),
			PromiseUtils.old(hierarchyModelsPromise),
			PromiseUtils.old(this.projectContextService.getProjectContext(project)),
			PromiseUtils.old(this.attributesService.getWordAttributes(project))
		]).then((res: any) => {
			return {
				project,
				attributes : res[0],
				models: res[1],
				metrics: res[2],
				hierarchyModels: res[3],
				projectContext: res[4],
				wordAttributes: res[5]
			};
		});
	};
}
app.service('templateOptionsService', TemplateOptionsService);
