import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild, OnChanges, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgForm } from '@angular/forms';
import { Output } from '@angular/core';
import { IBookTab } from '@cxstudio/dashboards/entity/book';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';

enum EditorMode {
	VIEW = 'modifyState',
	EDIT = 'saveCancelState'
}

@Component({
	selector: 'book-editor-qualtrics-embed',
	templateUrl: './book-editor-qualtrics-embed.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookEditorQualtricsEmbedComponent implements OnInit, OnChanges {
	@Input() dashboardId: string;
	@Input() currentTab: IBookTab;
	@ViewChild('embedDashboardForm', {static: false}) embedDashboardForm: NgForm;
	@Output() dashboardIdChange = new EventEmitter<string>();

	initialEmbedDashboardId: string;
	embedDashboardId: string;
	viewState: EditorMode;
	lastSavedEmbedId: string;

	constructor() { }

	ngOnInit(): void {
		if(!this.dashboardId) {
			this.embedDashboardId = '';
			this.viewState = EditorMode.EDIT;
		} else {
			this.initialEmbedDashboardId = this.dashboardId;
			this.embedDashboardId = this.dashboardId;
			this.viewState = EditorMode.VIEW;
		}
		this.lastSavedEmbedId = this.dashboardId;
	}

	ngOnChanges(changes: SimpleChanges<BookEditorQualtricsEmbedComponent>): void {
		if (ChangeUtils.hasChange(changes.dashboardId)) {
			this.initialEmbedDashboardId = changes.dashboardId.currentValue;
			this.embedDashboardId = changes.dashboardId.currentValue;
			this.lastSavedEmbedId = this.embedDashboardId;
		}

		// Covers case where we switch to a new tab, and click on tab for new embed value
		if(!changes.dashboardId?.currentValue) {
			this.embedDashboardId = '';
			this.lastSavedEmbedId = this.embedDashboardId;
			this.viewState = EditorMode.EDIT;
		}

		// when changing from two embeds
		if(changes.currentTab && changes.dashboardId?.currentValue) {
			this.viewState = EditorMode.VIEW;
		}

		// local tab changes to embed id
		if(!changes.currentTab && changes.dashboardId?.currentValue) {
			this.changeBaseState();
		}
	}

	changeBaseState = (): void => {
		switch(this.viewState) {
			case EditorMode.VIEW: {
				this.viewState = EditorMode.EDIT;
				break;
			}
			case EditorMode.EDIT: {
				this.viewState = EditorMode.VIEW;
				break;
			}
			default: {
				break;
			}
		}
	};

	saveXMDashboardId = (): void => {
		if (this.embedDashboardId === this.lastSavedEmbedId) {
			// if no change has been made, just cancel
			this.cancelDashboardId();
			return;
		}

		this.dashboardIdChange.emit(this.embedDashboardId);
	};

	modifyDashboardId = (): void => {
		this.viewState = EditorMode.EDIT;
	};

	cancelDashboardId = (): void => {
		this.embedDashboardId = this.initialEmbedDashboardId;
		this.viewState = EditorMode.VIEW;
	};
}

app.directive('bookEditorQualtricsEmbed', downgradeComponent({component: BookEditorQualtricsEmbedComponent}));
