import { Component, OnInit, ChangeDetectionStrategy, Input, ViewChild } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';


export interface RenameModalInput {
	currentList: any[];
	renameItem: any;
	typeString: string;
}

@Component({
	selector: 'rename-modal',
	templateUrl: './rename-modal.component.html',
	styles: [
		`
		[hidden] {
			display: none !important;
		}
		`
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RenameModalComponent implements OnInit {

	@Input() input: RenameModalInput;
	@ViewChild('propertiesDialog', {static: true}) public propertiesDialog: UntypedFormGroup;

	itemList: any[];
	model: {name: any; description: any};
	initialItem: any;
	modalText: any;
	isFolder: boolean;
	nameField: string;
	readonly maxLength = 256;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly activeModal: NgbActiveModal,
		private readonly cxDialogService: CxDialogService
	) { }

	ngOnInit(): void {
		this.populateIsFolder();
		this.populateNameField();

		this.itemList = this.input.currentList;
		this.model = {
			name: this.input.renameItem.name,
			description: this.input.renameItem.description
		};
		this.initialItem = cloneDeep(this.model);
		this.modalText = {
			dialogHeader: this.locale.getString('rename.unsavedChangesHeader', this.input.typeString),
			dialogBody: this.locale.getString('rename.unsavedChanges', this.input.typeString),
			title: this.locale.getString('rename.propsTitle', this.input.typeString),
			nameLabel: this.locale.getString('rename.propsNameLabel', this.input.typeString),
			namePlaceholder: this.locale.getString('rename.propsNamePlaceholder', this.input.typeString),
			nameEmptyWarning: this.locale.getString('rename.propsNameEmptyWarning', this.input.typeString),
			nameUniqueWarning: this.locale.getString('rename.propsNameUniqueWarning', this.input.typeString),
			descriptionLabel: this.locale.getString('rename.propsDescriptionLabel', this.input.typeString),
			descriptionPlaceholder: this.locale.getString('rename.propsDescriptionPlaceholder', this.input.typeString)
		};
	}

	private populateIsFolder = (): void => {
		let foldersTypes: string[] = Object.values(FolderTypes) as string[];
		this.isFolder = foldersTypes.includes(this.input.renameItem.type);
	};

	private populateNameField = (): void => {
		this.nameField = this.input.renameItem.type === 'metricStudio' ? 'displayName' : 'name';
	};

	save = (): void => {
		this.activeModal.close(this.model);
	};

	cancel = (): void => {
		if (!_.isEqual(this.initialItem, this.model)) {
			this.showUnsavedChangesDialog();
		} else {
			this.closeModal();
		}
	};

	showUnsavedChangesDialog = (): void => {
		this.cxDialogService.showUnsavedChangesDialogAndResolve(
			this.save,
			this.closeModal,
			this.modalText.dialogHeader,
			this.modalText.dialogBody,
			this.propertiesDialog?.invalid && this.isFormModalNameNotUnique()
		).catch(() => {});
	};

	closeModal = (): void => {
		this.activeModal.dismiss('cancel');
	};

	// Status Checks
	isFormModalValid = (): boolean => this.propertiesDialog.valid === true;
	isFormModalNameInvalid = (): boolean => {
		return this.propertiesDialog.controls.name?.dirty === true &&
			this.propertiesDialog.controls.name?.invalid === true;
	};

	// Validation Checks
	hasFormModalName = (): boolean => {
		return this.propertiesDialog.controls.name?.errors?.required === true;
	};

	isFormModalNameNotUnique = (): boolean => {
		return this.propertiesDialog?.controls?.name?.dirty && (this.propertiesDialog.controls.name.errors?.unique !== undefined);
	};
}
