import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';

@Injectable({
	providedIn: 'root'
})
export class PreviewChunkService {

	constructor(
		private locale: CxLocaleService
	) {}

	private getLinkUrl = (text: string): string => {
		let matches = text.match(/([^\'\"]+)/g);
		return (matches[1]) ? matches[1] : matches[0];
	};

	private handleBlankChunks = (sentence: PreviewSentence): PreviewSentence => {
		if (this.isNoVerbatim(sentence)) {
			sentence.chunks = [{text: this.locale.getString('preview.emptyVerb')}];
		}
		return sentence;
	};

	private assembleSentenceChunks = (sentence: PreviewSentence): string => {
		let text = '';
		for (let chunk of sentence.chunks) {
			if (!_.isUndefined(chunk.descriptors) && chunk.descriptors[0] === 'link') {
				text = text + this.getLinkUrl(chunk.text);
			} else {
				text = text + chunk.text;
			}
		}
		return text;
	};

	isNoVerbatim = (sentence: PreviewSentence): boolean => {
		return _.isEmpty(sentence.chunks)
			|| (sentence.chunks.length === 1 && (sentence.chunks[0].text === '' || sentence.chunks[0].text === '<b></b>'));
	};

	processSentenceChunks = (sentenceArray: PreviewSentence[]): void => {
		for (let arrayPiece of sentenceArray) {
			let sentence = this.handleBlankChunks(arrayPiece);
			sentence.text = this.assembleSentenceChunks(sentence);
		}
	};
}

app.service('previewChunkService', downgradeInjectable(PreviewChunkService));
