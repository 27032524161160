import { Injectable } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import * as moment from 'moment';
import { ScheduleWizardComponent } from '@app/modules/dashboard/schedule/schedule-wizard/schedule-wizard.component';
import { ScheduleIdentity } from '@app/modules/schedules/schedule-identity';
import { ScheduleSettings } from '@app/modules/schedules/schedule-settings';
import { ScheduleApiService } from '@app/modules/schedules/schedule-api-service';
import { HttpResponse } from '@angular/common/http';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';

@Injectable({
	providedIn: 'root'
})

export class DashboardScheduleService {
	constructor(
		private scheduleApiService: ScheduleApiService,
		private cxDialogService: CxDialogService
	) {}

	openScheduleDialog = (dashboard: Dashboard, selectedIdentity?: ScheduleIdentity): Promise<ScheduleSettings[]> => {
		return this.cxDialogService.openDialog(
			ScheduleWizardComponent,
			{
				dashboard,
				selectedScheduleIdentity: selectedIdentity
			},
			{
				size: ModalSize.LARGE,
				scrollable: true
			}).result;
	};

	updateSchedule = (settings: ScheduleSettings, dashboardId: number): ng.IPromise<HttpResponse<ScheduleIdentity>> => {
		let scheduleObject = this.convertSettingsForBackend(settings);
		return this.scheduleApiService.updateSchedule(dashboardId, scheduleObject) as any;
	};

	removeSchedule = (settings: ScheduleSettings, dashboardId: number): ng.IPromise<any> => {
		let key = settings.scheduleIdentity.key;
		let group = settings.scheduleIdentity.group;
		return this.scheduleApiService.deleteSchedule(dashboardId, key, group) as any;
	};

	renewSchedule = (settings: ScheduleSettings, expirationDate: Date): ng.IPromise<any> => {
		let key = settings.scheduleIdentity.key;
		let group = settings.scheduleIdentity.group;
		let newDate = this.transformDateTimezone(expirationDate, settings.timezoneOffset);
		return this.scheduleApiService.renewSchedule(key, group, newDate) as any;
	};

	convertSettingsForBackend(settings: ScheduleSettings): ScheduleSettings {
		this.transformStartDateTimeZone(settings);
		return settings;
	}

	getDashboardSchedules(dashboardList: Dashboard[]) {
		// need to put this into backend of dashboards call
		return this.scheduleApiService.getActiveSchedulesForUser().then((response) => {
			let scheduleCounts = (response as any) || {};
			let changes = [];

			_.each(dashboardList, (dashboard) => {
				if (scheduleCounts[dashboard.id]) {
					dashboard.scheduleCount = scheduleCounts[dashboard.id];
					changes.push(dashboard);
				}
			});
			return changes;
		});
	}

	private transformStartDateTimeZone(settings: ScheduleSettings): void {
		if (_.isUndefined(settings) || _.isUndefined(settings.timezoneOffset)) {
			return;
		}

		settings.clientTimezoneName = moment.tz.guess();
		settings.startDate = this.transformDateTimezone(settings.startDate, settings.timezoneOffset);
	}

	private transformDateTimezone(date: Date, timezoneOffset: number): Date {
		return new Date(date.getTime() + ((moment(date).utcOffset() - timezoneOffset) * 60000));
	}
}

app.service('dashboardScheduleService', downgradeInjectable(DashboardScheduleService));
