import { Directive, ElementRef, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

@Directive({
	selector: '[i18n]'
})
export class I18nDirective implements OnInit {

	@Input('i18n') key: string;
	private params: any;

	constructor(
		private el: ElementRef,
		private locale: CxLocaleService,
	) {}

	ngOnInit(): void {
		this.el.nativeElement.innerHTML = this.locale.getString(this.key, this.params);
	}

	@Input() set i18nParams(value: Record<string, any>) {
		this.params = _.mapObject(value, propValue => HtmlUtils.escapeHtml('' + propValue));
		this.el.nativeElement.innerHTML = this.locale.getString(this.key, this.params);
	}
}