import { Component, ChangeDetectionStrategy, Input, OnInit, OnChanges } from '@angular/core';
import { QualtricsIconsId } from '@discover/unified-icons/src/types/qualtrics-icons';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'icon',
	templateUrl: './icon.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconComponent implements OnInit, OnChanges {
	@Input() name: QualtricsIconsId;
	@Input() className?: string;

	iconClasses: string;

	ngOnInit(): void {
		this.init();
	}

	ngOnChanges(): void {
		this.init();
	}

	private init(): void {
		this.iconClasses = `q-icon q-icon-${this.name} ${this.className ?? ''}`;
	}
}

app.directive('icon', downgradeComponent({ component: IconComponent }));
