import { NgModule } from '@angular/core';
import { LayoutModule } from '@app/modules/layout/layout.module';
import { ReportGroupingSelectorComponent } from '@app/modules/widget-settings/grouping/report-grouping-selector/report-grouping-selector.component';
import { ThresholdConfigurationComponent } from '@app/modules/widget-settings/grouping/threshold-configuration/threshold-configuration.component';
import { UrlTypeComponent } from '@app/modules/widget-settings/grouping/url-type/url-type.component';
import { I18nModule } from '@app/shared/i18n/i18n.module';
import { SharedModule } from '@app/shared/shared.module';
import { PushButtonsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { AttributeInclusionSettingsComponent } from './attribute-inclusion-settings/attribute-inclusion-settings.component';
import { BarStackSettingsComponent } from './bar-stack-settings/bar-stack-settings.component';
import { CapitalizationSettingsComponent } from './capitalization-settings/capitalization-settings.component';
import { GroupingSelectionSettingsComponent } from './grouping-selection-settings/grouping-selection-settings.component';
import { HierarchyModelSettingsComponent } from './hierarchy-model-settings/hierarchy-model-settings.component';
import { LineStackSettingsComponent } from './line-stack-settings/line-stack-settings.component';
import { NullValuesSettingsComponent } from './null-values-settings/null-values-settings.component';
import { PeerReportSettingsComponent } from './peer-report-settings/peer-report-settings.component';
import { ReportGroupingPropertiesComponent } from './report-grouping-properties/report-grouping-properties.component';
import { TimeGroupingSettingsComponent } from './time-grouping-settings/time-grouping-settings.component';
import { TopicInclusionSettingsComponent } from './topic-inclusion-settings/topic-inclusion-settings.component';
import { FilterBuilderModule } from '@app/modules/filter-builder/filter-builder.module';
import { AssetManagementModule } from '@app/modules/asset-management/asset-management.module';

@NgModule({
	imports: [
		I18nModule,
		SharedModule,
		PushButtonsModule,
		LayoutModule,
		FilterBuilderModule,
		AssetManagementModule,
	],
	declarations: [
		ReportGroupingSelectorComponent,
		ReportGroupingPropertiesComponent,
		GroupingSelectionSettingsComponent,
		PeerReportSettingsComponent,
		TimeGroupingSettingsComponent,
		TopicInclusionSettingsComponent,
		NullValuesSettingsComponent,
		AttributeInclusionSettingsComponent,
		CapitalizationSettingsComponent,
		HierarchyModelSettingsComponent,
		BarStackSettingsComponent,
		LineStackSettingsComponent,
		UrlTypeComponent,
		ThresholdConfigurationComponent,
	],
	exports: [
		ReportGroupingSelectorComponent,
		ReportGroupingPropertiesComponent,
	],
})
export class ReportGroupingModule { }
