import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class AgeService {

	constructor(
		private readonly locale: CxLocaleService
	) { }

	getAgeText = (seconds: number, precision: number = 0) => {
		let returnVal;

		if (seconds === undefined || seconds <= 0) {
			return '-';
		}
		// per CES-1319
		if (seconds <= 64) {
			return '1 ' + this.locale.getString('common.minute');
		}

		let minutes = seconds / 60;
		if (minutes < 60) {
			returnVal = this.roundAndCrop(minutes, precision);
			returnVal = this.pluralize(returnVal, 'Minute');
			return returnVal;
		}

		let hours = minutes / 60;
		if (hours < 24) {
			returnVal = this.roundAndCrop(hours, precision);
			returnVal = this.pluralize(returnVal, 'Hour');
			return returnVal;
		}

		let days = hours / 24;
		if (days < 14) {
			returnVal = this.roundAndCrop(days, precision);
			returnVal = this.pluralize(returnVal, 'Day');
			return returnVal;
		}

		let weeks = days / 7;
		if (weeks <= 8) {
			returnVal = this.roundAndCrop(weeks, precision);
			returnVal = this.pluralize(returnVal, 'Week');
			return returnVal;
		}

		returnVal = this.roundAndCrop((weeks / 4), precision);
		returnVal = this.pluralize(returnVal, 'Month');
		return returnVal;
	};

	getAsOfAgoText(date: Date): string {
		let deltaSeconds = moment(new Date()).diff(date, 'seconds');
		let ageText = deltaSeconds <= 60
			? this.locale.getString('common.lessThanMinute')
			: this.getAgeText(deltaSeconds);
		return this.locale.getString('widget.asOf', [ageText]);
	}

	private pluralize(value, unit): string {
		let returnVal = `${value} ${this.locale.getString(`common.${unit.toLowerCase()}`)}`;
		return (value === 1) ?
			returnVal :
			`${value} ${this.locale.getString(`common.${unit.toLowerCase()}`)}s`;
	}


	private crop(num): number {
		if (num === Math.trunc(num)) {
			return Math.trunc(num);
		}
		return num;
	}

	roundAndCrop = (num: number, precision: number = 0) => {
		if (precision === 0) {
			return Number(Math.trunc(num));
		} else {
			return Number(this.crop(num.toFixed(precision)));
		}
	};
}

app.service('ageService', AgeService);
