import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

export interface QualtricsEmbeddingSettings {
	dashboardEmbeddingEnabled: boolean;
	widgetEmbeddingEnabled: boolean;
}

@Component({
	selector: 'qualtrics-embedding-settings-panel',
	templateUrl: './qualtrics-embedding-settings-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class QualtricsEmbeddingSettingsPanelComponent implements OnInit {
	@Input() settings: QualtricsEmbeddingSettings;
	showDashboardToggle = this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_DASHBOARDS);
	showWidgetToggle = this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_WIDGETS);

	constructor(
		private toastService: ToastService,
		private locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
	) {
	}

	ngOnInit(): void {
		this.settings = this.settings || {
			dashboardEmbeddingEnabled: false,
			widgetEmbeddingEnabled: false,
		};
	}

	changeSettings = (enabled: boolean): void => {
		this.masterAccountApiService.updateQualtricsEmbeddingSettings(this.settings).then(() => {
			let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
			this.toastService.addToast(this.locale.getString(key,
				this.locale.getString('mAccount.qualtricsEmbeddingHeader')), AlertLevel.CONFIRM);
		});
	};
}

app.directive('qualtricsEmbeddingSettingsPanel',
	downgradeComponent({component: QualtricsEmbeddingSettingsPanelComponent}));
