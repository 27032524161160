import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountsListActions } from '@app/modules/system-administration/master-account/master-accounts-table/miscellaneous/master-accounts-list-actions.service';
import { MasterAccountsListMenu } from '@app/modules/system-administration//master-account/master-accounts-table/miscellaneous/master-accounts-list-menu.service';
import { MasterAccountEditDialogComponent, MasterAccountEditDialogInput, MasterAccountEditDialogOutput } from '@app/modules/system-administration/master-account/master-account-edit-dialog/master-account-edit-dialog.component';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { MasterAccountManageApi } from '@app/modules/system-administration/master-account/master-account-manage-api.service';
import MasterAccountSearch from '@app/modules/system-administration/master-account/entities/master-account-search';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { CxLocaleService } from '@app/core';
import { IRouteParams } from '@app/shared/providers/route-params-provider';

export enum MasterAccountSearchFields {
	MA_ID = 'maid',
	MA_NAME = 'cn'
}

export default class MasterAccountSearchOption {
	displayName: string;
	searchField: MasterAccountSearchFields;

	constructor(displayName: string, searchField: MasterAccountSearchFields) {
		this.displayName = displayName;
		this.searchField = searchField;
	}
}

@Component({
	selector: 'master-accounts-table',
	templateUrl: './master-accounts-table.component.html',
	providers: [MasterAccountsListActions, MasterAccountsListMenu]
})
export class MasterAccountsTableComponent implements OnInit {
	gridOptions: SlickgridOptions;
	gridType = GridTypes.MASTER_ACCOUNTS;

	loading: Promise<any>;
	changedItems: any[];
	masterAccounts: MasterAccount[];
	licenses: LicenseTypeItem[];

	pagination: Pagination;
	masterAccountSearch: MasterAccountSearch;

	searchOptions: MasterAccountSearchOption[] = [
		new MasterAccountSearchOption(this.locale.getString('administration.masterAccountName'), MasterAccountSearchFields.MA_NAME),
		new MasterAccountSearchOption(this.locale.getString('administration.masterAccountId'), MasterAccountSearchFields.MA_ID)
	];

	constructor(
		private actionsService: MasterAccountsListActions,
		private contextMenuUtils: MasterAccountsListMenu,
		private cxDialogService: CxDialogService,
		private masterAccountManageApi: MasterAccountManageApi,
		private locale: CxLocaleService,
		private licenseApiService: LicenseApiService,
		private gridUtils: GridUtilsService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		@Inject('security') private security: Security,
		@Inject('securityApiService') private securityApiService: SecurityApiService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree,
	) {
	}

	ngOnInit(): void {
		this.pagination = new Pagination(10);
		this.masterAccountSearch = new MasterAccountSearch();
		const search: string = this.$routeParams.masterAccountSearch;
		if (search) {
			this.masterAccountSearch.filterText = search;
		}

		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row),
			disableInitialSort: true
		};

		this.reloadMasterAccounts();
		this.loadLicenseTypes();

		this.actionsService.onChange.subscribe(() => this.reloadMasterAccounts());

		this.masterAccountSearch.filterField = MasterAccountSearchFields.MA_NAME;
	}

	private onItemClick(event, object: MasterAccount): void {
		if (object.restricted) {
			return;
		}
		if (this.gridUtils.isNameClick(event)) {
			this.editMasterAccount(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object, this), 'master-accounts', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.toggle(object);
		}
	}

	onSearchChange = (): void => {
		this.pagination = new Pagination(10);
		this.reloadMasterAccounts();
	};

	loadLicenseTypes = (): void => {
		this.licenseApiService.getLicenseTypes()
			.then(result => this.licenses = result);
	};

	reloadMasterAccounts = (): void => {
		this.loading = this.masterAccountManageApi.getMasterAccounts(this.masterAccountSearch, this.pagination).then((response) => {
			this.masterAccounts = response.data;
			this.pagination.totalItems = response.totalCount;
			this.masterAccounts.map(this.security.applyAccountTagRestrictions);
			this.refreshGrid();
		});
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reloadMasterAccounts();
	};

	private refreshGrid(): void {
		this.changedItems = this.masterAccounts;
	}

	createMasterAccount(): void {
		let dialogInput = {
			masterAccount: undefined,
			licenseTypes: this.licenses
		} as MasterAccountEditDialogInput;

		this.cxDialogService.openDialog(MasterAccountEditDialogComponent, dialogInput, {size: ModalSize.LARGE}).result
			.then((masterAccountDialogOutput: MasterAccountEditDialogOutput) => {
				this.loading = this.actionsService.create(masterAccountDialogOutput.masterAccount);
			}).catch(() => {});
	}

	editMasterAccount(selectedMasterAccount: MasterAccount) {
		if (selectedMasterAccount.restricted) return;

		let dialogInput = {
			masterAccount: selectedMasterAccount,
			licenseTypes: this.licenses
		} as MasterAccountEditDialogInput;

		this.cxDialogService.openDialog(MasterAccountEditDialogComponent, dialogInput, {size: ModalSize.LARGE}).result
			.then((masterAccountDialogOutput: MasterAccountEditDialogOutput) => {
				let maUpdatePromise = this.actionsService.update(masterAccountDialogOutput.masterAccount);
				this.loading = maUpdatePromise;
				maUpdatePromise.then(() => {});
			}).catch(() => {});
	}

	deleteMasterAccount(masterAccount: MasterAccount) {
		if (masterAccount.restricted) return;

		let dialog = this.cxDialogService.warningWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('mAccount.deleteNote', { name: masterAccount.accountName }),
			this.locale.getString('common.delete'));

		dialog.result.then(() => {
			this.loading = this.actionsService.deleteMasterAccount(masterAccount);
		}).catch(() => {});
	}
}

app.directive('masterAccountsTable', downgradeComponent({component: MasterAccountsTableComponent}));
