import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { DashboardChangeType } from '@app/modules/dashboard-actions/undo/dashboard-change-type.enum';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewVerbatim } from '@cxstudio/reports/document-explorer/preview-verbatim';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { AnalyticFeedbackSelectionResult } from '@cxstudio/reports/preview/analytic-feedback-selection-result.interface';
import IAnalyticFeedbackSelection from '@cxstudio/reports/preview/analytic-feedback-selection.interface';
import { CuratedItem, CuratedItemType } from '@cxstudio/reports/preview/curated-item';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import * as _ from 'underscore';
import { PreviewMode } from '../entities/preview-mode';



/**
 * Class to encorporate all actions regarding analytic feedback selection.
 * The instance of this class is to be propagated in all necessary places.
 */
export abstract class AnalyticFeedbackSelectionBase implements IAnalyticFeedbackSelection {

	private enabled: boolean = false;

	processedResult: AnalyticFeedbackSelectionResult = {
		excluding: false,
		items: []
	};

	protected abstract getCuratedItemType: () => CuratedItemType;
	protected abstract getWidget: () => Widget;
	protected abstract convertToCuratedItem: (previewItem: PreviewSentence | PreviewVerbatim | PreviewDocument) => CuratedItem;

	constructor(
		private locale: ILocale,
		private widgetsEditService: WidgetsEditService,
		private runReportCallback: () => void
	) {}

	protected enableVisually = (): void => {};
	protected disableVisually = (): void => {};
	protected applyVisualChanges = (): void => {};
	protected hasCuratingPermissions = (): boolean => true;

	enable = (): void => {
		this.enabled = true;

		this.populateProcessedResult();
		this.enableVisually();
		this.applyVisualChanges();
		this.runReportCallback();
	};

	isEnabled = (): boolean => {
		return this.enabled;
	};

	isDisabled = (): boolean => {
		return !this.isEnabled();
	};

	isShowingCuratingControls = (): boolean => {
		return this.isEnabled() && this.hasCuratingPermissions();
	};

	clearSelection = (): void => {
		this.resetItems();
		this.applyVisualChanges();
		this.runReportCallback();
	};

	resetItems = (): void => {
		this.processedResult = {
			excluding: false,
			items: []
		};
	};

	cancel = (): void => {
		this.disable();
		this.applyVisualChanges();
		this.runReportCallback();
	};

	submit = (): void => {
		this.saveResult();

		this.disable();
		this.applyVisualChanges();

		this.runReportCallback();
	};

	private getRelevantItems(selection: AnalyticFeedbackSelectionResult): CuratedItem[] {
		return selection.items.filter(item => item.type === this.getCuratedItemType());
	}

	ensureDisabled = (): void => {
		this.disableVisually();
	};

	curateItem = (previewItem: PreviewSentence | PreviewVerbatim | PreviewDocument): void => {
		let curatedItem = this.convertToCuratedItem(previewItem);
		let processedCuratedItem = this.findCuratedItem(curatedItem);

		if (processedCuratedItem) {
			this.processedResult.items = _.without(this.processedResult.items, processedCuratedItem);
		} else {
			this.processedResult.items.push(curatedItem);
		}

		let itemId: number = previewItem.id;
		if (this.isSentencesPreviewSelectionMode() && !!this.getSentenceCheckbox(itemId)) {
			this.changeSentenceCheckboxState(itemId);
		} else {
			this.applyVisualChanges();
		}
	};

	isItemCurated = (previewItem: PreviewSentence | PreviewVerbatim | PreviewDocument): boolean => {
		return !!(this.findCuratedItem(this.convertToCuratedItem(previewItem)));
	};

	isSentenceItemCurated = (sentence: PreviewSentence): boolean => {
		let curatedItemType = this.getCuratedItemType();
		let item = curatedItemType === CuratedItemType.DOCUMENT
			? { id: sentence.documentId } as PreviewDocument
			: { id: sentence.verbatimId, documentId: sentence.documentId } as PreviewVerbatim;

		return this.isItemCurated(item);
	};

	getRelevantCuratedItemsCount = (): number => {
		return this.getRelevantItems(this.processedResult).length;
	};

	getAllCuratedItemsCount = (): number => {
		return this.processedResult.items.length;
	};

	getRelevantCuratedItems = (): CuratedItem[] => {
		return this.getRelevantItems(this.processedResult);
	};

	hasAnyCuratedItems = (): boolean => {
		return this.getAllCuratedItemsCount() > 0;
	};

	/*
	 For searchability:
	 preview.document, preview.documents, preview.verbatim, preview.verbatims, preview.sentence, preview.sentences
	*/
	getLabel = (): string => {
		let items = this.getRelevantItems(this.processedResult);
		let prefixKey = this.getPrefixKey(this.getCuratedItemType(), items.length !== 1);
		let itemType = this.locale.getString('preview.' + prefixKey);

		return this.locale.getString('preview.itemsSelected', { itemCount: items.length, itemType });
	};

	private getPrefixKey(itemType: CuratedItemType, multiple: boolean): string {
		let postfixKey;

		if (itemType === CuratedItemType.DOCUMENT)
			postfixKey = 'document';
		else if (itemType === CuratedItemType.VERBATIM)
			postfixKey = 'verbatim';
		else
			postfixKey = 'sentence';

		if (multiple)
			postfixKey += 's';

		return postfixKey;
	}

	private disable = (): void => {
		this.enabled = false;
		this.ensureDisabled();
	};

	private findCuratedItem = (curatedItem: CuratedItem): CuratedItem => {
		return _.findWhere(this.processedResult.items, curatedItem);
	};

	saveResultTo = (widget: Widget): void => {
		widget.properties.analyticFeedbackSelection = angular.copy(this.processedResult);
	};

	isFeedbackSharingAvailable = (): boolean => false;

	private saveResult = (): void => {
		let action = WidgetUpdateAction.wrapChange(() => this.getWidget(), () => {
			this.getWidgetProperties().analyticFeedbackSelection = angular.copy(this.processedResult);
		});
		this.widgetsEditService.applyDashboardChanges(this.getWidget().containerId, action);
		this.widgetsEditService.addDashboardHistoryState(DashboardChangeType.UPDATED, [action]);
	};

	protected populateProcessedResult = (): void => {
		this.processedResult = angular.copy(this.getWidgetProperties().analyticFeedbackSelection) || {
			excluding: false,
			items: []
		};
	};

	protected getWidgetProperties(): WidgetProperties {
		return this.getWidget().properties;
	}

	// checkbox selection visual changes

	private isSentencesPreviewSelectionMode(): boolean {
		let previewMode: PreviewMode = this.getWidgetProperties().previewMode;
		return this.isEnabled()
			&& (previewMode === PreviewMode.SENTENCES || previewMode === PreviewMode.SENTENCES_WITH_CONTEXT);
	}

	private getSentenceCheckbox(sentenceId: number): JQuery {
		let rowId: string = `widgetId-${this.getWidget().id}-rowId-${sentenceId}`;
		return $(`#${rowId} .analytic-feedback-selection-flag`);
	}

	private changeSentenceCheckboxState(itemId: number): void {
		let sentenceCheckbox: JQuery = this.getSentenceCheckbox(itemId);
		this.setCheckboxState(sentenceCheckbox);
	}

	private setCheckboxState(sentenceCheckbox: JQuery): void {
		const CHECKED_CLASSES: string = `q-icon-check toggled`;
		const UNCHECKED_CLASSES: string = 'q-icon-circle';

		if (sentenceCheckbox.hasClass(CHECKED_CLASSES)) {
			sentenceCheckbox.removeClass(CHECKED_CLASSES).addClass(UNCHECKED_CLASSES);
		} else {
			sentenceCheckbox.removeClass(UNCHECKED_CLASSES).addClass(CHECKED_CLASSES);
		}
	}
}
