import { Component, OnInit, ChangeDetectionStrategy, Output, Inject, EventEmitter, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

@Component({
	selector: 'open-doc-explorer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<li (click)="onAction.emit()" *ngIf="hasOpenInExplorer()">
	<a class="option explorer" [i18n]="'widget.openInExplorer'" href="javascript:void(0)" role="menuitem"></a>
</li>`
})
export class OpenDocExplorerComponent {
	@Input() widget: Widget;
	@Input() dashboard: Dashboard;
	@Output() onAction = new EventEmitter<void>();

	constructor(
		private readonly dashboardAccessService: DashboardAccessService,
	) { }

	hasOpenInExplorer = () => {
		const canDrill = this.dashboardAccessService.canDrillToFeedbackInView(this.dashboard);
		return canDrill
			&& this.widget.properties.widgetType === WidgetType.PREVIEW
			&& this.widget.properties.previewMode === PreviewMode.DOCUMENT;
	};
}

app.directive('openDocExplorer', downgradeComponent({ component: OpenDocExplorerComponent }));
