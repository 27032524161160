import { IReportAttribute } from './report-attribute';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { AttributeValuesSearchParams } from '@app/modules/project/attribute/attribute-values-search-params';
import { ISearchValue } from '@app/modules/project/attribute/attributes.service';

@Injectable({
	providedIn: 'root'
})
export default class WorkspaceAttributesApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.ATTRIBUTES);
	}

	getAttributes = (
		project: WorkspaceProject, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<IReportAttribute[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/attributes';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<IReportAttribute[]>;
	};

	getWordAttributes = (
		project: WorkspaceProject, cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<IReportAttribute[]> => {
		let config = { cache };
		let url = this.getBasePath(project) + '/word-attributes';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<IReportAttribute[]>;
	};

	getAttributeValues = (
		project: WorkspaceProject, searchParams: AttributeValuesSearchParams, cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<ISearchValue[]> => {
		let params = searchParams.toHttpParams();
		let config = { cache, params };
		let url = this.getBasePath(project) + '/attribute-values';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ISearchValue[]>;
	};

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/resources/ws/${project.workspaceId}/project/${project.projectId}`;
	};
}
