import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { MetricColorDirection } from '@cxstudio/metrics/entities/metric-color-direction.enum';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { PopDiffPrefix } from '@cxstudio/services/constants/pop-difference-prefix.constant';


export class PopFormatterBuilder {

	readonly COLORED_DELTA_FORMAT_TEMPLATE =
		'<div class=\'slick-align-right\'>' +
			'<span style=\'margin-right:5px; color:{color};\'>{valueString}</span>' +
			'<span style=\'color:{color};\'>{arrow}</span>' +
		'</div>';

	readonly SIMPLE_DELTA_FORMAT_TEMPLATE =
		'<div class=\'slick-align-right\'>' +
			'<span style=\'margin-right:5px;\'>{valueString}</span>' +
			'<span>{arrow}</span>' +
		'</div>';

	constructor(
		private locale: ILocale,
		private metric,
		private builder: IFormatBuilder,
		private metricPrefix: PopDiffPrefix,
		private isDelta: boolean,
		private applicationThemeService: ApplicationThemeService) {
	}

	format = (row, cell, value, columnDef, dataContext, options, forExport: boolean, metricDefinition: Metric): string => {
		if (!options) options = {};

		let metricName = columnDef.id.replace(this.metricPrefix, '');

		let currentField = options.fields ? options.fields.current : metricName;
		let historicField = options.fields?.historic;

		let currentValue = dataContext[currentField];
		let historicValue = historicField ? dataContext[historicField] : undefined;
		let result;
		let colorStyle = false;

		let hideArrow = Boolean(this.metric.hideArrow);
		let isNAResult = false;

		if (this.valueExists(currentValue) && (!this.valueExists(value) || this.isNewDelta(value, currentValue, historicValue))) {
			result = this.locale.getString('widget.new');
		} else if (!this.valueExists(currentValue)) {
			result = '---';
		} else if (_.isUndefined(value)) {
			result = ' ';
		} else {
			result = this.builder.format(Math.abs(value), options, metricDefinition?.format, {ignoreAlignment: forExport});
			colorStyle = value !== 0;
		}

		if (AnalyticMetricTypes.isHierarchyEnrichmentProperty(this.metric)) {
			if (columnDef.id.startsWith(PopDiffPrefix.PERCENT_CHANGE) || columnDef.id.startsWith(PopDiffPrefix.DELTA)) {
				result = this.locale.getString('widget.na');
				isNAResult = true;
			}
		}

		let darkModePrefix = this.applicationThemeService.isDarkMode(ApplicationThemeScope.DASHBOARD_CONTAINER) ? 'DARK_MODE_' : '';

		if (colorStyle && !isNAResult) {
			if (this.isDelta) {
				result = result.replace('%', ' ' + this.locale.getString('widget.pts'));
			}
			let valueString = value < 0 && !options.simpleDeltaFormat ? `(${result})` : result;

			if (forExport) {
				return valueString;
			}

			let noDirectionalColor = (this.metric.colorDirection === MetricColorDirection.NONE);
			const isEaseScore = this.metric.name === PredefinedMetricConstants.EASE_SCORE
				|| this.metric.parentMetricName === PredefinedMetricConstants.EASE_SCORE;
			let directColor = (this.metric.colorDirection !== MetricColorDirection.REVERSED || isEaseScore);
			let deltaClass = value < 0 ? 'q-icon-triangle-down' : 'q-icon-triangle-up';
			let color;
			if (noDirectionalColor) {
				color = ColorConstants[`${darkModePrefix}GREY`];
			} else {
				const upColor = isEaseScore ? ColorConstants[`${darkModePrefix}LIGHT_BLUE`] : ColorConstants[`${darkModePrefix}LIGHT_GREEN`];
				const downColor = isEaseScore ? ColorConstants[`${darkModePrefix}LIGHT_ORANGE`] : ColorConstants[`${darkModePrefix}LIGHT_RED`];
				const isUp = value >= 0 ? directColor : !directColor;
				color = isUp ? upColor : downColor;
			}
			let arrow = hideArrow ? '' : `<i class='${deltaClass}'></i>`;

			let template = options.simpleDeltaFormat
				? this.SIMPLE_DELTA_FORMAT_TEMPLATE
				: this.COLORED_DELTA_FORMAT_TEMPLATE;

			let templateData = {
				valueString,
				color,
				arrow
			};

			return template.doFormat(templateData);
		} else {
			if (forExport) {
				return result;
			}
			return `<div class='slick-align-right'><span style='margin-right:5px;'>${result}</span></div>`;
		}
	};

	private valueExists = (value: any): boolean => {
		return !_.isUndefined(value) && value !== null && !isNaN(value);
	};

	private isNewDelta = (value: any, currentValue: any, historicValue: number): boolean => {
		return this.isDelta && value === currentValue && _.isNaN(historicValue);
	};

}
