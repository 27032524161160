import { ChangeDetectorRef } from '@angular/core';
import { ChangeDetectionStrategy, Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';

@Component({
	selector: 'widget-title-settings',
	template: `
<!-- AUTO TITLE -->
<div *ngIf="!props.isCustomTitle">
	<h3 class="props-widget-name">{{displayName}}</h3>
	<button *ngIf="isCustomTitleAllowed()"
		class="btn btn-secondary"
		data-testid="btn-enable-custom-title"
		(click)="props.isCustomTitle = true">
		{{'widget.customTitle'|i18n}}</button>
</div>

<!-- CUSTOM TITLE -->
<div *ngIf="props.isCustomTitle" class="d-flex w-100-percent">
	<input type="text" class="edit-title flex-fill mr-16"
		data-testid="widget-custom-title"
		[(ngModel)]="displayName"
		(ngModelChange)="onChangeCallback($event)">
	<button
		data-testid="btn-enable-auto-title"
		class="btn btn-secondary flex-fixed" (click)="enableAutoTitle()">
		{{'widget.autoTitle'|i18n}}
	</button>
</div>

<div *ngIf="allowHiddenTitle" class="mt-8">
	<div class="d-flex align-items-center">
		<checkbox-button [(ngModel)]="visualProperties.hideTitle" label="{{'widget.hideTitle'|i18n}}"></checkbox-button>
		<cb-inline-help>
			<help-body>{{'widget.hideTitleHelpText'|i18n}}</help-body>
		</cb-inline-help>
	</div>
</div>
`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => WidgetTitleSettingsComponent), multi: true}
	]
})
export class WidgetTitleSettingsComponent implements ControlValueAccessor {

	@Input() visualProperties: VisualProperties;
	@Input() allowHiddenTitle: boolean;
	@Input() props: WidgetProperties;
	@Output() updateAutoTitle = new EventEmitter<void>();
	displayName: string;

	private onTouchedCallback: (val: string) => void = _.noop;
	onChangeCallback: (val: string) => void = _.noop;

	constructor(private ref: ChangeDetectorRef) {}

	isCustomTitleAllowed = (): boolean => {
		return WidgetType.OBJECT_VIEWER !== this.props.widgetType;
	};

	enableAutoTitle(): void {
		this.props.isCustomTitle = false;
		this.updateAutoTitle.emit();
	}

	writeValue(val: string): void {
		if (val !== this.displayName) {
			this.displayName = val;
			this.onChangeCallback(this.displayName);
			this.ref.markForCheck();
		}
	}

	registerOnChange(fn: (val: string) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: (val: string) => void): void {
		this.onTouchedCallback = fn;
	}
}

app.directive('widgetTitleSettings', downgradeComponent({component: WidgetTitleSettingsComponent}));
