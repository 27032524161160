<div>
	<div *ngIf="sharedEntities.length" class="share-row no-border">
		<b class="name-column">{{'common.name'|i18n}}</b>
		<b class="access-column">{{'common.sharedVia'|i18n}}</b>
	</div>

	<ng-container *ngFor="let item of sharedEntities | orderBy: '_name'">
		<div class="share-row"
			*ngIf="!(item._collapsed && item.type === 'inner-user')">
			<div class="name-column">
				<span
					*ngIf="item.type !== 'inner-user'"
					[ngClass]="{user:'q-icon-user', group:'q-icon-users', publicStatus:'q-icon-globe', cbUsers:'q-icon-qualtrics'}[item.type]">
				</span>
				<div [ngClass]="{'pl-48':item.type==='inner-user'}" title="{{item.displayName}}">
					{{item.displayName}}
				</div>
			</div>

			<div class="access-column">
				<div [ngbPopover]="indirect"
					 	#pop="ngbPopover"
						class="share-permission-level"
						triggers="manual"
						popoverClass="wide-popover"
						(mouseenter)="showPopover(item, pop)"
						(mouseleave)="hidePopover(pop)"
						container="body">
					{{getShareText(item)}}
				</div>
			</div>
		</div>
	</ng-container>
</div>

<ng-template #indirect let-sharedItem="item">
	<div class="books-sharee-item-popover popover-content">
		<table>
			<tr>
				<th [i18n]="'common.name'"></th>
				<th>&nbsp;&nbsp;&nbsp;&nbsp;</th>
				<th [i18n]="'common.owner'"></th>
			</tr>
			<tr *ngFor="let book of sharedItem.bookList"
				class="list-item">
				<td class="book-name">{{book.name}}</td>
				<td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
				<td class="owner-name">{{book.ownerName}}</td>
			</tr>
		</table>
	</div>
</ng-template>
