import * as _ from 'underscore';
import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { CaseVisualizationConfig } from '@app/modules/widget-settings/case-viz-settings/case-viz-settings.component';
import { HighchartsCase } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case';
import { HighchartsCaseUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case-utils.service';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { CacheOptions } from '@cxstudio/common/cache-options';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { Model } from '@cxstudio/reports/entities/model';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { EngagorApiService } from '@cxstudio/services/data-services/engagor-api.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ReportMetricsService } from '@app/modules/metric/services/report-metrics.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { NumericFormats } from '@app/modules/project/attribute/attribute-settings.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';

export interface WidgetUtilsLazyResources {
	attributes: () => ng.IPromise<any[]>;
	metrics: () => ng.IPromise<Metric[]>;
	dateFilters: () => ng.IPromise<IFilter[]>;
	palettes: () => ng.IPromise<{studioPalettes: WidgetColorPalette[]; providerColors: string[]}>;
	projectTimezone: () => ng.IPromise<string>;
	scorecards: () => ng.IPromise<Scorecard[]>;
	scorecardModels: () => ng.IPromise<Model[]>;
	formats: () => ng.IPromise<NumericFormats>;
	tuningSuggestionModels: () => ng.IPromise<IReportModel[]>;
	engagorCases: () => ng.IPromise<HighchartsCase[]>;
}

@Injectable({
	providedIn: 'root'
})
export class WidgetUtilsResourcesService {

	constructor(
		@Inject('colorPalettes') private colorPalettes: ColorPalettes,
		@Inject('scorecardsApiService') private scorecardsApiService: ScorecardsApiService,
		private reportSettingsService: ReportSettingsService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		@Inject('engagorApiService') private engagorApiService: EngagorApiService,
		private highchartsCaseUtils: HighchartsCaseUtilsService,
		private readonly reportMetricsService: ReportMetricsService,
		private readonly reportAttributesService: ReportAttributesService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly reportProjectContextService: ReportProjectContextService,
	) {}

	getLazyResources(widget: Widget): WidgetUtilsLazyResources {
		return {
			attributes: this.cacheWrapper(() => this.getAttributes(widget)),
			metrics: this.cacheWrapper(() => PromiseUtils.old(this.getMetrics(widget))),
			dateFilters: this.cacheWrapper(() => this.getDateFilters(widget.properties)),
			palettes: this.cacheWrapper(() => PromiseUtils.old(this.getPalettes(widget))),
			projectTimezone: this.cacheWrapper(() => this.getProjectTimezone(widget)),
			scorecards: this.cacheWrapper(() => this.getScorecards(widget.properties)),
			scorecardModels: this.cacheWrapper(() => this.getScorecardModels(widget.properties)),
			formats: this.cacheWrapper(() => this.getFormats(widget.properties)),
			tuningSuggestionModels: this.cacheWrapper(() => this.getTuningSuggestionModels(widget)),
			engagorCases: this.cacheWrapper(() => this.getEngagorCases(widget.visualProperties.caseVisualizations)),
		};
	}

	private cacheWrapper<T>(fn: () => ng.IPromise<T>): () => ng.IPromise<T> {
		let promise = null;
		return () => {
			if (promise === null)
				promise = fn();
			return promise;
		};
	}

	private getAttributes(widget: Widget): ng.IPromise<IReportAttribute[]> {
		return PromiseUtils.old(this.reportAttributesService.getWidgetAttributes(widget));
	}

	// this also includes metrics, available in the properties, but missing from api
	// e.g. hidden/removed, or runtime metric from home pages
	private getMetrics(widget: Widget): Promise<Metric[]> {
		let widgetMetricsPromise = this.getWidgetMetrics(widget);
		let predefinedMetricsPromise = this.getDynamicPredefinedMetrics(widget);

		return PromiseUtils.all([
			PromiseUtils.wrap(widgetMetricsPromise),
			PromiseUtils.wrap(predefinedMetricsPromise)]
		).then((response) => {
			let studioMetrics = _.filter(response[0] as Array<{projectId: number}>,
				metric => metric.projectId === widget.properties.project);

			let allMetrics = [].concat(studioMetrics).concat(response[1]);
			return allMetrics.concat(this.getMissingMetrics(allMetrics, widget.properties));
		});
	}

	private getDynamicPredefinedMetrics(widget: Widget): ng.IPromise<Metric[]> {
		return PromiseUtils.old(this.reportMetricsService.getWidgetDynamicPredefinedMetrics(widget, false));
	}

	private getWidgetMetrics(widget: Widget): ng.IPromise<Metric[]> {
		return PromiseUtils.old(this.reportMetricsService.getWidgetMetrics(widget));
	}

	private getMissingMetrics(list: Metric[], props: WidgetProperties): Metric[] {
		let usedMetrics = [].concat(props.selectedAttributes).concat(props.selectedMetrics)
			.filter(asset => AnalyticMetricTypes.isStudioMetric(asset));
		return usedMetrics.filter(metric => !_.findWhere(list, {id: metric.id}));
	}

	private getDateFilters(props: WidgetProperties): ng.IPromise<IFilter[]> {
		return PromiseUtils.old(this.reportFiltersService.getWidgetPropertiesDateFilters(props));
	}

	private getPalettes(widget: Widget): Promise<{studioPalettes: WidgetColorPalette[]; providerColors: string[]}> {
		return PromiseUtils.all([
			PromiseUtils.wrap(this.colorPalettes.getWidgetPalettes()),
			PromiseUtils.wrap(this.colorPalettes.getWidgetDesignerPalette(widget.properties))
		]).then(results => ({
			studioPalettes: results[0],
			providerColors: results[1]
		}));
	}

	private getProjectTimezone(widget: Widget): ng.IPromise<string> {
		return PromiseUtils.old(this.reportProjectContextService.getWidgetProjectTimezone(widget));
	}

	private getScorecards(props: WidgetProperties): ng.IPromise<Scorecard[]> {
		return this.scorecardsApiService.getProjectScorecards(ProjectIdentifier.fromWidgetProperties(props), CacheOptions.CACHED, props.runAs);
	}

	private getScorecardModels({contentProviderId, accountId, project, runAs}: WidgetProperties): ng.IPromise<Model[]> {
		return this.scorecardsApiService.getScorecardModels(contentProviderId, accountId, project, CacheOptions.CACHED, runAs);
	}

	private getFormats(props: WidgetProperties): ng.IPromise<NumericFormats> {
		return PromiseUtils.old(this.reportSettingsService.getWidgetProjectSettings(props))
			.then(settings => settings?.numericAttributes);
	}

	private getTuningSuggestionModels(widget: Widget): ng.IPromise<IReportModel[]> {
		return this.masterAccountApiService.getTuningSuggestionsAvailableModels(
			ProjectIdentifier.fromWidgetProperties(widget.properties),
			widget.properties.workspaceProject,
			AssetParametersFactory.fromWidget(widget));
	}

	private getEngagorCases(caseVisualizationConfig: CaseVisualizationConfig): ng.IPromise<HighchartsCase[]> {
		return this.engagorApiService.getCasesById(caseVisualizationConfig.selectedCases).then(engagorCases => {
			return _.chain(engagorCases)
				.map(engagorCase => {
					let caseConfig = _.findWhere(caseVisualizationConfig.selectedCases, {id: engagorCase.id});
					return this.highchartsCaseUtils.convertEngagorCase(engagorCase, caseConfig);
				}).flatten()
				.value();
		});
	}



}

app.service('widgetUtilsResourcesService', downgradeInjectable(WidgetUtilsResourcesService));

