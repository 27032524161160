import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { DefaultMetricConfig } from '@cxstudio/reports/providers/cb/constants/default-metric-config';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '../../drill-menu-option';
import { DrillWidgetType } from '../drill-widget-type';

export class AttributeDrillService {

	constructor(
		private readonly locale: ILocale,
		private readonly DrillTo,
		private readonly reportSettingsService: ReportSettingsService
	) { }

	createAddGroupByFunction = (widget: Widget) => {
		return (point, attribute) => {
			let groupBy: any = angular.copy(DefaultMetricConfig);
			_.extend(groupBy, attribute);
			groupBy.metricType = AnalyticMetricType.ATTRIBUTE;

			return this.reportSettingsService.populateGroupingProjectDefaults(widget.properties, groupBy)
				.then((enrichedAttribute) => {
					point.groupBy = enrichedAttribute;
				});
		};
	};

	getAttributes = (widget, menuActions, attributes, defaultColor) => {
		let attributeOptions = [];

		for (let attribute of attributes) {
			let drillToHelper = new this.DrillTo(widget, menuActions, defaultColor);

			attributeOptions.push({
				text: attribute.displayName,
				items: drillToHelper.getDrillTo(this.getWidgetFilter(attribute), widget.properties.widgetType),
				name: `attribute-${attribute.name.toLowerCase()}`,
				obj: attribute,
				asyncFunc: this.createAddGroupByFunction(widget)
			});
		}

		return attributeOptions;
	};

	private getWidgetFilter(attribute): DrillWidgetType[] {
		let base = [DrillWidgetType.DEFAULT];
		if (attribute.type === 'NUMBER' || attribute.type === 'SATSCORE')
			base.push(DrillWidgetType.NUMERIC);
		return base;
	}

	getOptions = (widget, menuActions, attributes, defaultColor): IDrillMenuOption => {
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 30,
			text: this.locale.getString('widget.attributes'),
			items: this.getAttributes(widget, menuActions, attributes, defaultColor),
			name: 'attribute',
			searchBox: true,
			levels: 2,
			noDefaultAction: true
		};
	};

	getDrillEnrichmentOptions = (widget: Widget, menuActions, attributes, defaultColor: IColorSelectorPalette, languageAndOtherOptions) => {
		let enrichment = this.getOptions(widget, menuActions, attributes, defaultColor);
		enrichment.text = this.locale.getString('widget.enrichment');

		if (languageAndOtherOptions.length) {
			let languageAndOther = this.getOptions(widget, menuActions, languageAndOtherOptions, defaultColor);
			languageAndOther.text = this.locale.getString('widget.languageAndOther');
			(enrichment.items as IDrillMenuOption[]).push(languageAndOther);
		}

		return enrichment;
	};
}

app.service('attributeDrill', AttributeDrillService);
