import Widget, { ContentWidget, ExternalWidget, WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';


export class ReportConstants {
	static readonly NOT_VALID_NODE_ID = -1;
	static readonly FULL_PATH = '_fullPath';

	static sentimentFilters = [
		{id: 'all_sentences', text: 'preview.allSentences', css: 'br-filter-all'},
		{id: 'pos_sentences', text: 'preview.positiveSentences', css: 'br-filter-pos'},
		{id: 'neg_sentences', text: 'preview.negativeSentences', css: 'br-filter-neg'},
		{id: 'mix_sentences', text: 'preview.mixedSentences', css: 'br-filter-mix'},
		{id: 'non_sentiment_bearing', text: 'preview.nonSBSentences', css: 'br-filter-non-sentiment'},
		{id: 'all_sentiment_bearing', text: 'preview.allSBSentences', css: 'br-filter-all-sentiment'}
	];

	static docExplorerSentimentFilterMap = {
		pos_sentences: 'reportFilters.positiveSentences',
		neg_sentences: 'reportFilters.negativeSentences',
		neutral_sentences: 'reportFilters.neutralSentences'
	};

	static primaryColorFields = [ColorTypes.PRIMARY, ColorTypes.POINT, ColorTypes.PERIOD_OVER_PERIOD];
	static secondaryColorFields = [ColorTypes.SECONDARY, ColorTypes.SECONDARY_POINT, ColorTypes.SECONDARY_PERIOD_OVER_PERIOD];
	static customColorFields = {
		color: 'customColor',
		popColor: 'customPopColor',
		pointColor: 'pointCustomColor',
		secondaryColor: 'secondaryCustomColor',
		secondaryPopColor: 'secondaryCustomPopColor',
		secondaryPointColor: 'secondaryPointCustomColor'
	};

	static colorFields = ReportConstants.primaryColorFields.concat(ReportConstants.secondaryColorFields);

	static AN_WIDGETS: WidgetType[] = [
		WidgetType.LINE,
		WidgetType.BAR,
		WidgetType.METRIC,
		WidgetType.HEATMAP,
		WidgetType.CLOUD,
		WidgetType.PIE,
		WidgetType.TABLE,
		WidgetType.SCATTER,
		WidgetType.PREVIEW,
		WidgetType.SCORECARD,
		WidgetType.SELECTOR,
		WidgetType.MAP,
		WidgetType.NETWORK,
		WidgetType.MODEL_VIEWER,
	];

	static CHART_WIDGETS: WidgetType[] = [
		WidgetType.LINE,
		WidgetType.BAR,
		WidgetType.METRIC,
		WidgetType.HEATMAP,
		WidgetType.CLOUD,
		WidgetType.PIE,
		WidgetType.TABLE,
		WidgetType.SCATTER,
		WidgetType.MAP,
		WidgetType.NETWORK,
		WidgetType.MODEL_VIEWER,
	];

	static isSelectorWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.SELECTOR;

	static isTableWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.TABLE;

	static isNetworkWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.NETWORK;

	static isHeatmapWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.HEATMAP;

	static isCloudWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.CLOUD;

	static isObjectViewerWidget = (widgetType: WidgetType): boolean => widgetType === WidgetType.OBJECT_VIEWER;

	static isAnalyticWidgetNotSelector = (widgetType: WidgetType): boolean =>
		!ReportConstants.isSelectorWidget(widgetType) && ReportConstants.isAnalyticWidgetAndSupport(widgetType);

	static isAnalyticWidget = (widgetType: WidgetType): boolean => ReportConstants.isAnalyticWidgetAndSupport(widgetType);

	static isChartWidget = (widgetType: WidgetType) => ReportConstants.CHART_WIDGETS.contains(widgetType);

	static isAnalyticWidgetAndSupport = (widgetType: WidgetType, options?, usesPlacementColor?: boolean): boolean => {
		options = options || {};

		let support: boolean = true;
		if (options.color) {
			const DISALLOWED_WIDGETS = [WidgetType.METRIC, WidgetType.TABLE, WidgetType.SCORECARD];

			support = (!DISALLOWED_WIDGETS.contains(widgetType) && !usesPlacementColor);
		}

		return support && ReportConstants.AN_WIDGETS.contains(widgetType);
	};

	static isLegacyWidget = (widget: Widget): boolean => {
		return widget && !ReportConstants.isAnalyticWidget(widget.name as WidgetType)
			&& !ReportConstants.isObjectViewerWidget(widget.name as WidgetType)
			&& !ReportConstants.isContentWidget(widget)
			&& !ReportConstants.isExternalWidget(widget);
	};

	static isContentWidget(widget: Widget): widget is ContentWidget {
		return widget && widget.type === WidgetDisplayType.CONTENT;
	}

	static isExternalWidget(widget: Widget): widget is ExternalWidget {
		return widget && widget.type === WidgetDisplayType.EXTERNAL;
	}

}
