export class PromiseUtils {
	static wrap<T>(promise: ng.IPromise<T> | T): Promise<T> {
		return Promise.resolve(promise as any);
	}

	static old<T>(promise: Promise<T>): ng.IPromise<T> {
		return promise as unknown as ng.IPromise<T>;
	}

	static defer<T>(): Deferred<T> {
		return new Deferred<T>();
	}

	// copied this from $q - this is useful to keep original return types instead of having "any"
	/* eslint-disable max-len */
	static all<T1, T2, T3, T4, T5, T6, T7, T8, T9, T10>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>, Promise<T6>, Promise<T7>, Promise<T8>, Promise<T9>, Promise<T10>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9, T10]>;
	static all<T1, T2, T3, T4, T5, T6, T7, T8, T9>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>, Promise<T6>, Promise<T7>, Promise<T8>, Promise<T9>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8, T9]>;
	static all<T1, T2, T3, T4, T5, T6, T7, T8>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>, Promise<T6>, Promise<T7>, Promise<T8>]): Promise<[T1, T2, T3, T4, T5, T6, T7, T8]>;
	static all<T1, T2, T3, T4, T5, T6, T7>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>, Promise<T6>, Promise<T7>]): Promise<[T1, T2, T3, T4, T5, T6, T7]>;
	static all<T1, T2, T3, T4, T5, T6>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>, Promise<T6>]): Promise<[T1, T2, T3, T4, T5, T6]>;
	static all<T1, T2, T3, T4, T5>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>, Promise<T5>]): Promise<[T1, T2, T3, T4, T5]>;
	static all<T1, T2, T3, T4>(values: [Promise<T1>, Promise<T2>, Promise<T3>, Promise <T4>]): Promise<[T1, T2, T3, T4]>;
	static all<T1, T2, T3>(values: [Promise<T1>, Promise<T2>, Promise<T3>]): Promise<[T1, T2, T3]>;
	static all<T1, T2>(values: [Promise<T1>, Promise<T2>]): Promise<[T1, T2]>;
	static all<TAll>(promises: Promise<TAll>[]): Promise<TAll[]> { return Promise.all(promises); }
	/* eslint-enable max-len */

	static sleep(delay: number): Promise<void> {
		return new Promise(resolve => {
			setTimeout(resolve, delay);
		});
	}

	static tick(): Promise<void> {
		return this.sleep(0);
	}

	/**
	 * Used for lazy-loaded resources which can be called multiple times
	 * */
	static cacheWrapper<T>(fn: () => Promise<T>): () => Promise<T> {
		let promise = null;
		return () => {
			if (promise === null)
				promise = fn();
			return promise;
		};
	}
}

export class Deferred<T> {
	resolve: (value?: T) => void;
	reject: (reason?: any) => void;
	promise: Promise<T>;
	constructor() {
		this.promise = new Promise((resolve, reject) => {
			this.resolve = resolve;
			this.reject = reject;
		});
	}
}
