import { Inject, Injectable } from '@angular/core';
import { AttributesStats } from '@cxstudio/drivers/entities/attributes-stats';
import { DatasetStats } from '@cxstudio/drivers/entities/dataset-stats';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DriversModel } from '@cxstudio/drivers/entities/drivers-model';
import { DriversTreeItem, DriversReportableTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxHttpService } from '@app/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { DriversFilters } from '@cxstudio/drivers/entities/drivers-filters';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { HttpParams } from '@angular/common/http';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { Model } from '@cxstudio/reports/entities/model';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';

@Injectable({ providedIn: 'root' })
export class DriversApi {
	private cachedHttp: CxCachedHttp;

	constructor(
		cachedHttpService: CxCachedHttpService,
		private http: CxHttpService,
		private httpHandlers: CxHttpHandlers,
		@Inject('treeService') private treeService: TreeService,
	) {
		this.cachedHttp =  cachedHttpService.cache(Caches.ATTRIBUTES);
	}

	getDrivers = (contentProviderId: number, accountId: number, projectId?: number): Promise<DriversTreeItem[]> => {
		let httpParams = new HttpParams()
			.append('cpId', contentProviderId + '')
			.append('accountId', accountId + '');
		if (projectId) {
			httpParams = httpParams.append('projectId', projectId + '');
		}
		return this.http.get('rest/drivers', {
			params: httpParams,
			cache: CacheOptions.NOT_CACHED
		});
	};

	getDriversTemplates = (contentProviderId: number, accountId: number, projectId?: number): Promise<DriversItem[]> => {
		let httpParams = new HttpParams()
			.append('cpId', contentProviderId + '')
			.append('accountId', accountId + '');
		if (projectId) {
			httpParams = httpParams.append('projectId', projectId + '');
		}
		return this.http.get('rest/drivers-templates', {
			params: httpParams,
			cache: CacheOptions.NOT_CACHED
		});
	};

	getReportableDrivers = (contentProviderId: number, accountId: number): Promise<DriversItem[]> => {
		let httpParams = new HttpParams()
			.append('cpId', contentProviderId + '')
			.append('accountId', accountId + '');
		return this.cachedHttp.get('rest/drivers/reportable', {
			params: httpParams,
			cache: CacheOptions.CACHED
		});
	};

	getAccountProjectReportableDrivers(project: IProjectSelection): AssetPromisePlatformProject<DriversItem[]> {
		const url = `rest/drivers/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}/reportable`;
		return this.cachedHttp.get(url, {cache: CacheOptions.CACHED}) as AssetPromisePlatformProject<DriversItem[]>;
	}

	getReportableDriversForModel(project: IProjectSelection, modelId: number): Promise<DriversReportableTreeItem[]> {
		const url =
			`rest/drivers/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}/models/${modelId}/reportable`;
		return this.cachedHttp.get(url, {cache: CacheOptions.CACHED}) as Promise<DriversReportableTreeItem[]>;
	}

	getWorkspaceProjectReportableDrivers(project: WorkspaceProject): AssetPromisePlatformProject<DriversItem[]> {
		const url = `rest/drivers/ws/${project.workspaceId}/project/${project.projectId}/reportable`;
		return this.cachedHttp.get(url, {cache: CacheOptions.CACHED}) as AssetPromisePlatformProject<DriversItem[]>;
	}

	createDrivers = (driversData: DriversItem, models?: Model[]): Promise<DriversItem> => {
		return this.http.post('rest/drivers', (this.treeService.copyItem(driversData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS))
			.then((savedDriver) => {
				this.trackEvent(savedDriver, models);
				return savedDriver;
			});
	};

	createDriversTemplate = (driversData: DriversItem): Promise<DriversItem> => {
		return this.http.post('rest/drivers-templates', (this.treeService.copyItem(driversData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	updateDrivers = (driversData: DriversItem, models?: Model[]): Promise<DriversItem> => {
		return this.http.put(`rest/drivers/${driversData.id}`, (this.treeService.copyItem(driversData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS))
			.then((savedDriver) => {
				this.trackEvent(savedDriver, models);
				return savedDriver;
			});
	};
	updateDriversTemplate = (driversData: DriversItem): Promise<DriversItem> => {
		return this.http.put(`rest/drivers-templates/${driversData.id}`, (this.treeService.copyItem(driversData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	copyDrivers = (driverName: string, originalDriversId: number): Promise<DriversItem> => {
		return this.http.post(`rest/drivers/${originalDriversId}/copy`, driverName)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	updateNonDatasetFields = (driversData: DriversItem, models?: Model[]): Promise<DriversItem> => {
		return this.http.put(`rest/drivers/${driversData.id}/non-dataset`, (this.treeService.copyItem(driversData)))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS))
			.then((savedDriver) => {
				this.trackEvent(savedDriver, models);
				return savedDriver;
			});
	};

	deleteDrivers = (driversId: number): Promise<any> => {
		return this.http.delete(`rest/drivers/${driversId}`)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	deleteDriversConfigs = (driversConfigs: DriversItem[]): Promise<any> => {
		let httpParams = new HttpParams();
		driversConfigs.forEach(driversConfig => httpParams = httpParams.append('driversId', driversConfig.id + ''));
		return this.http.delete(`rest/drivers`, { params: httpParams })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	executeDrivers = (driversId: number): Promise<any> => {
		return this.http.post(`rest/drivers/${driversId}/execute`)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	getDriverById = (driverId: number): Promise<DriversItem> => {
		return this.http.get(`rest/drivers/${driverId}`);
	};

	getDriversModel = (driversId: number): Promise<DriversModel> => {
		return this.http.get(`rest/drivers/${driversId}/model`);
	};

	getDriversFilters = (driversId: number, local: boolean = false): Promise<DriversFilters> => {
		return this.cachedHttp
			.get(`rest/drivers/${driversId}/filters`, { local, cache: CacheOptions.CACHED } );
	};

	reloadDriversStatus = (driversIds: number[]): Promise<any> => {
		return this.http.post('rest/drivers/status', (driversIds));
	};

	refreshDrivers = (driversId: number): Promise<any> => {
		return this.http.post(`rest/drivers/${driversId}/refresh`)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	getDatasetStats = (driversData: DriversItem): Promise<DatasetStats> => {
		return this.http.post('rest/drivers/dataset-stats', (this.treeService.copyItem(driversData)));
	};

	shareDrivers = (drivers: DriversItem[], shareData) => {
		let httpParams = new HttpParams();
		drivers.forEach(item => httpParams = httpParams.append('driverId', item.id + ''));

		return this.http.post('rest/drivers/share', (shareData), { params: httpParams })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.DRIVERS));
	};

	getSharedUsersAndGroups = (driverId: number): Promise<DriversItem> => {
		return this.http.get(`rest/drivers/share/${driverId}/users`);
	};

	getAttributesStats = (driversData: DriversItem): Promise<AttributesStats> => {
		// reenabled this feature under controlled release + internal beta flag
		return this.http.post('rest/drivers/attributes-stats', (this.treeService.copyItem(driversData)));
	};

	verifyDriversAccess = (drivers): Promise<any> => {
		let httpParams = new HttpParams();
		drivers.forEach(item => httpParams = httpParams.append('driverId', item.id + ''));

		return this.http.get('rest/drivers/verify-access', { params: httpParams });
	};

	private trackEvent(driversData: DriversItem, models?: Model[]): void {
		let isModelQM = (modelId) => !!models.find(availableModel => availableModel.id === modelId)?.qualityManagement;

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DRIVER_SAVE,
			{},
			{
				outcome: driversData.target.filters.filterRules.map(f => f.type).filter(type => type !== FilterRuleType.empty),
				relevantData: [...(driversData.definition.attributes ?? []), ...(driversData.definition.models ?? [])],
				rubricModelSelect: models?.length > 0 &&
					!!(driversData.definition.models?.find(
						selModel => isModelQM(parseInt(selModel.name, 10))
					) ||
					driversData.target.filters.filterRules.some(f => f.type !== FilterRuleType.empty && isModelQM(f.topicId)))
			}
		);
	}
}

app.service('driversApi', downgradeInjectable(DriversApi));
