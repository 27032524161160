import * as cloneDeep from 'lodash.clonedeep';
import {
	Component, Input, OnInit, ChangeDetectionStrategy, EventEmitter, Output, ChangeDetectorRef, Inject
} from '@angular/core';
import * as _ from 'underscore';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { CxLocaleService } from '@app/core';
import { TruncateNamesListPipe } from '@app/shared/pipes/truncate-names-list.pipe';

export interface UnifiedLinksVisibilityChangedEvent {
	unifiedLinksVisibility:  Map<number, Array<number>>;
	unifiedLinkVisibilityValid: boolean;
}

export interface MasterAccountGroup {
	groupId: number;
	groupName: string;
}

export interface ILinkRestrictionNode extends INode {
	parentId?: number;
}

@Component({
	selector: 'unified-link-visibility',
	templateUrl: './unified-link-visibility.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UnifiedLinksVisibilityComponent implements OnInit {
	@Output() unifiedLinksVisibilityUpdated = new EventEmitter<UnifiedLinksVisibilityChangedEvent>();
	@Input() additionalInformation: any;
	@Input() masterAccounts: Array<MasterAccount>;

	private readonly NUMBER_OF_ENTRIES_IN_DISPLAY_NAME: number = 2;

	displayProperty: string;

	groupsLoading:any;
	linkVisibility: Map<number, Array<number>>;

	accountHierarchy: ILinkRestrictionNode[];
	masterAccountGroups:  Map<number, MasterAccountGroup[]>;
	accountGroupHierarchy: ILinkRestrictionNode[];

	constructor(
		private locale: CxLocaleService,
		@Inject('usersGroupsApiService') private usersGroupsApiService: UsersGroupsApiService,
		private truncateNamesList: TruncateNamesListPipe,
	) { }

	ngOnInit(): void {
		this.groupsLoading = null;
		this.masterAccountGroups = new Map<number, MasterAccountGroup[]>();

		if (!this.additionalInformation.unifiedLinksVisibility) {
			this.linkVisibility = new Map<number, Array<number>>();
			this.rebuildAccountHierarchy();
		} else {

			this.linkVisibility = this.additionalInformation.unifiedLinksVisibility;
			//reload groups and populate group names
			this.rebuildAccountHierarchy();
			this.reloadGroups();
		}
	}

	updateUnifiedLinksVisibility = (): void => {
		let event: UnifiedLinksVisibilityChangedEvent = {
			unifiedLinksVisibility: this.linkVisibility,
			unifiedLinkVisibilityValid: this.visibilitySettingValid()
		};

		this.unifiedLinksVisibilityUpdated.emit(event);
	};

	handleAccountSelection = (node: ILinkRestrictionNode): void => {
		if (_.isUndefined(this.linkVisibility[node.id])) {
			this.linkVisibility[node.id] = [];
			this.reloadGroups();
		} else {
			delete this.linkVisibility[node.id];
			delete this.masterAccountGroups[node.id];
			this.rebuildGroupHierarchy();
		}
		
		this.updateUnifiedLinksVisibility();
	};


	handleGroupSelection = (node: ILinkRestrictionNode): void => {
		if (_.isUndefined(node.parentId)) {
			this.selectAllAccountGroupNodes(node);
		} else {
			this.selectGroupNode(node);
		}

		this.updateUnifiedLinksVisibility();
	};

	private selectAllAccountGroupNodes = (node: ILinkRestrictionNode): void => {
		if (_.isEmpty(this.masterAccountGroups[node.id])) {
			// no groups for master account
			return;
		}

		let masterAccountVisibleGroups: Array<number> = this.linkVisibility[node.id];
		if (masterAccountVisibleGroups.length !== this.masterAccountGroups[node.id].length) {
			// selectAll
			this.linkVisibility[node.id] = [];
			_.each(cloneDeep(this.masterAccountGroups[node.id]), (group:MasterAccountGroup) => {
				this.linkVisibility[node.id].push(group.groupId);
			});
		} else {
			// deselectAll
			this.linkVisibility[node.id] = [];
		}
	};

	private selectGroupNode = (node: ILinkRestrictionNode): void => {
		let accountGroups: Array<number> = this.linkVisibility[node.parentId];

		let existingGroup: number = _.find(accountGroups, (groupId: number) => {
			return groupId === node.id;
		});

		if (!existingGroup) {
			accountGroups.push(node.id);
		} else {
			accountGroups.splice(accountGroups.indexOf(existingGroup), 1);
		}
	};

	private reloadGroups = (): void => {
		let masterAccountIds: number[] = _.map(_.keys(this.linkVisibility),
			(maIdString: string) => parseInt(maIdString, 10));

		this.groupsLoading = this.usersGroupsApiService.getGroupsForMasterAccounts(masterAccountIds)
		.then((response) => {
			this.masterAccountGroups = response.data as Map<number, MasterAccountGroup[]>;
			this.cleanupRemovedGroups();
			this.rebuildGroupHierarchy();
			this.groupsLoading = null;
		});
	};

	private getMasterAccountName = (masterAccountId: number): string => {
		let masterAccount: MasterAccount =  _.find(this.masterAccounts, (account: MasterAccount) => {
			return account.accountId === masterAccountId;
		});

		if (_.isUndefined(masterAccount)) {
			return this.locale.getString('error.unknown');
		} else {
			return masterAccount.accountName;
		}
	};

	private cleanupRemovedGroups = (): void => {
		let result: Map<number, Array<number>> = new Map<number, Array<number>>();
		_.forEach(_.keys(this.linkVisibility), (accountKey: string) => {
			let masterAccountId: number = parseInt(accountKey, 10);
			let groupIds: Array<number> = this.linkVisibility[masterAccountId];
			result[masterAccountId] = [];
			for (const groupId of groupIds) {
				if (this.masterAccountGroupExists(masterAccountId, groupId)) {
					result[masterAccountId].push(groupId);
				}
			}
		});
		this.linkVisibility = result;
		this.updateUnifiedLinksVisibility();
	};

	private masterAccountGroupExists = (masterAccountId: number, groupId: number): boolean => {
		return !_.isUndefined(this.masterAccountGroups[masterAccountId]) && !_.isUndefined(
			_.find(this.masterAccountGroups[masterAccountId], (groupEntry: MasterAccountGroup) => {
				return groupEntry.groupId === groupId;
			})
		);
	};
	
	private getMasterAccountGroupName = (masterAccountId: number, groupId: number): string => {
		if (_.isUndefined(this.masterAccountGroups[masterAccountId])) {
			return this.locale.getString('error.unknown');
		} 

		let group: MasterAccountGroup = _.find(this.masterAccountGroups[masterAccountId], (groupEntry: MasterAccountGroup) => {
			return groupEntry.groupId === groupId;
		});

		if (_.isUndefined(group)) {
			return this.locale.getString('error.unknown');
		} else {
			return group.groupName;
		}
	};

	private rebuildGroupHierarchy = () => {
		let result: ILinkRestrictionNode[] = [];
		_.forEach(_.keys(this.linkVisibility), (accountKey: string) => {
			let masterAccountId = parseInt(accountKey, 10);
			let node: ILinkRestrictionNode = {};
			node.name = this.getMasterAccountName(masterAccountId);
			node.id = masterAccountId;
			node.children = [];
			let currentAccountGroups: MasterAccountGroup[] = this.masterAccountGroups[masterAccountId];
			if (!_.isUndefined(currentAccountGroups)) {
				for (const currentGroup of currentAccountGroups) {
					let subNode: ILinkRestrictionNode = {};
					subNode.name = currentGroup.groupName;
					subNode.id = currentGroup.groupId;
					subNode.parentId = parseInt(accountKey, 10);
					node.children.push(subNode);
				}
			}
			result.push(node);
		});
		this.accountGroupHierarchy = _.sortBy(result, 'name');
	};

	private rebuildAccountHierarchy = () => {
		let result: ILinkRestrictionNode[] = [];
		_.forEach(this.masterAccounts, (masterAccount:MasterAccount) => {
			let node: ILinkRestrictionNode = {};
			node.name = masterAccount.accountName;
			node.id = masterAccount.accountId;
			result.push(node);
		});
		this.accountHierarchy = result;
	};

	accountNodeIsChecked = (node: ILinkRestrictionNode): boolean => {
		return !!this.linkVisibility[node.id];
	};

	groupNodeIsChecked = (node: ILinkRestrictionNode): boolean => {
		if (_.isUndefined(node.parentId)){
			// account node is "checked" if all groups are selecetd
			if (_.isUndefined(this.masterAccountGroups[node.id])) {
				return false;
			}
			return this.linkVisibility[node.id].length === this.masterAccountGroups[node.id].length;

		} else {
			// group node
			return _.contains(this.linkVisibility[node.parentId], node.id);
		}
	};

	nodeIsMarked = (node: ILinkRestrictionNode): boolean => {
		if (!!node.parentId) {
			return false;
		}

		return this.linkVisibility[node.id].length > 0 && 
			this.linkVisibility[node.id].length !== this.masterAccountGroups[node.id].length;
	};

	showAccountNodeCheckbox = (node: ILinkRestrictionNode): boolean => {
		return true;
	};

	showGroupNodeCheckbox = (node: ILinkRestrictionNode): boolean => {
		return true;
	};

	getAccountSelectorDisplayName = (): string => {
		if (_.isEmpty(this.linkVisibility)) {
			return this.locale.getString('administration.selectMasterAccount');	
		}

		let masterAccountNames: string[] = 
			_.map(_.keys(this.linkVisibility), key => this.getMasterAccountName(parseInt(key, 10)));

		return this.truncateNamesList.transform(masterAccountNames, this.NUMBER_OF_ENTRIES_IN_DISPLAY_NAME);
		
	};

	getGroupSelectorDisplayName = (): string => {
		let selectedGroupNames: string[] = _.chain(this.linkVisibility)
			.map((groups: number[], accountId: string) => {
				return groups.map(groupId => {
					return {accountId: parseInt(accountId, 10), groupId};
				});
			})
			.flatten()
			.map(groupItem => this.getMasterAccountGroupName(groupItem.accountId, groupItem.groupId) 
				+ '(' + this.getMasterAccountName(groupItem.accountId) + ')').value();

		if (_.isUndefined(selectedGroupNames) || _.isEmpty(selectedGroupNames)) {
			return this.locale.getString('administration.selectMasterAccountGroup');	
		} else {
			return this.truncateNamesList.transform(selectedGroupNames, this.NUMBER_OF_ENTRIES_IN_DISPLAY_NAME);
		}
	};

	nodeCheckboxDisabled = (node: ILinkRestrictionNode): boolean => {
		if (!!node.parentId) {
			return false;
		}
		return _.isUndefined(this.masterAccountGroups[node.id]) || _.isEmpty(this.masterAccountGroups[node.id]);
	};

	accountWithoutGroupsSelected = (): boolean => {
		if (this.groupsLoading) {
			return false;
		}

		return !!_.find(_.keys(this.linkVisibility), (accountKey: string) => {
			let masterAccountGroups: MasterAccountGroup[] = this.masterAccountGroups[parseInt(accountKey, 10)];
			if (_.isUndefined(masterAccountGroups) || masterAccountGroups.length === 0) {
				return true;
			}
		});
	};

	getAccountWithoutGroupNames = (): string => {
		return  _.chain(this.linkVisibility)
			.map((groups: number[], accountId: string) => {
				return {accountId: parseInt(accountId, 10), groups};
			}).filter(visibilityItem => {
				return _.isUndefined(visibilityItem.groups) || visibilityItem.groups.length === 0;
			}).map(filteredVisibilityItem => this.getMasterAccountName(filteredVisibilityItem.accountId))
			.join(', ').value();
	};

	noAccountSelected = (): boolean => {
		return _.keys(this.linkVisibility).length === 0;
	};

	noGroupSelected = (): boolean => {
		return !!_.find(_.keys(this.linkVisibility), (accountKey: string) => {
			return _.isUndefined(this.linkVisibility[accountKey]) || this.linkVisibility[accountKey].length === 0;
		});
	};

	private visibilitySettingValid = (): boolean => {
		return !(this.accountWithoutGroupsSelected() || this.noAccountSelected() || this.noGroupSelected());
	};

}

