import * as _ from 'underscore';
import { NavigationDirection } from '@cxstudio/common/entities/navigation-direction.enum';
import { DocumentExplorerEvents, Node } from '@app/modules/document-explorer/events/document-explorer-events';
import { DocumentTypeUtils } from '@app/modules/document-explorer/document-type-utils.class';


export enum ContextHighlightType {
	WORLD_AWARENESS,
	TOPIC
}

export interface IExplorerHighlighter {
	update: (sentence, callback?) => void;
	clearChickletSelection: () => void;
	highlightModel: (node: Node) => void;
	highlightWorldAwareness: (node: Node) => void;
	highlightSelected: () => void;
	clearHighlightedNode: () => void;
	highlightItem: any;
	highlightSentenceIndex: number;
	currentHighlight: {
		node: Node;
		type?: ContextHighlightType;
	};
	switchSentence: (direction: NavigationDirection, worldAwareness?: boolean) => void;
	clearHighlighting: () => void;
	hoverModel: (model: any) => void;
}

interface Sentence {
	id: number;
	highlight: boolean;
	attributes: Record<string, any>;
}

// eslint-disable-next-line prefer-arrow-callback
app.factory('ExplorerHighlighter', function() {
	return class ExplorerHighlighter implements IExplorerHighlighter {
		private currentSentence: any;
		private highlightCallback: (eventName: string, node: Node, direction?: NavigationDirection) => void;
		highlightItem: any;
		private element: JQuery;

		/**
		 * The last topic that has been highlighted. Should not be undefined once set.
		 * Covers case where topic is deselected, but then reselected with no other topic selected in-between.
		 */
		lastHighlightedNode: Node;
		/**
		 * The currently highlighted topic/world awareness node. Can be undefined.
		 */
		currentHighlight: {
			node: Node;
			type?: ContextHighlightType;
		};
		/**
		 * The index of the current focus sentence
		 */
		highlightSentenceIndex: number;

		constructor(
			element, sentence, callback,
			private events: DocumentExplorerEvents
		) {
			this.element = element;
			this.update(sentence, callback);
			this.currentHighlight = {
				node: {} as Node
			};
		}

		update = (sentence, callback?): void => {
			this.currentSentence = sentence;
			this.highlightCallback = this.highlightCallback || callback;
		};

		private readonly clearSentencesHighlighting = (): void => {
			this.currentSentence?.sentences?.forEach((sentence) => {
				sentence.highlight = false;
			});
		};

		private readonly highlightSentences = (node: Node): void => {
			this.clearSentencesHighlighting();
			let documentSentences: Sentence[] = this.currentSentence.sentences;
			if (node && node.sentences) {
				node.sentences.forEach((sentenceId: number) => {
					let sentence = _.findWhere(documentSentences, { id: sentenceId });
					if (sentence) {
						sentence.highlight = true;
					}
				});
			} else if (node && node.value) {
				_.filter(documentSentences, (oneSentence: Sentence) => {
					return oneSentence.attributes &&
							oneSentence.attributes[node.name] &&
							oneSentence.attributes[node.name].indexOf(node.value) > -1;
				}).map((sentence: Sentence) => { sentence.highlight = true; });
			}
		};

		highlightSelected = (): void => {
			let selectedItem: JQuery = this.element.find('.dex-list-group > li.selected');
			let container: JQuery = this.element.find('.dex-list-group');
			if (!selectedItem.length) {
				return;
			}
			let topOfSelectedItem: number = (selectedItem.get(0) as HTMLElement).offsetTop;
			let bottomOfSelectedItem: number = topOfSelectedItem + selectedItem.outerHeight();

			let OFFSET: number = 70; // looks better when we don't scroll the current item up to the very top

			if (selectedItem) {
				// if the new document is not already visible in the sentence pane, scroll to it
				let notVisible = (bottomOfSelectedItem > (container.scrollTop() + container.height())) ||
					(topOfSelectedItem < container.scrollTop());
				if (notVisible)
					container.scrollTop(topOfSelectedItem - OFFSET);
			}
		};

		private highlightChicklet = (node: Node) => {
			this.clearChickletSelection();

			if (node) {
				node.selected = true;
			}
		};

		clearChickletSelection = (): void => {
			let document = this.currentSentence;
			if (document && document.classification) {
				document.classification.forEach((classification) => {
					if (classification && classification.nodes) {
						classification.nodes.forEach((node) => {
							node.selected = false;
						});
					}
				});
			}

			delete this.highlightItem;
		};

		switchSentence = (direction: NavigationDirection, worldAwareness: boolean = false): void => {
			if (worldAwareness)
				this.highlightWorldAwareness(this.currentHighlight.node, direction);
			else
				this.highlightModel(this.currentHighlight.node, direction);
		};

		clearHighlighting = (): void => {
			this.clearHighlightedNode();
			this.clearChickletSelection();
			if (DocumentTypeUtils.isConversation(this.currentSentence)) {
				this.highlightCallback('highlightModel', undefined);
			} else {
				this.clearSentencesHighlighting();
				this.events.clearHighlighting();
			}
		};

		clearHighlightedNode = (): void => {
			this.lastHighlightedNode = undefined;
			this.currentHighlight.node = undefined;
			this.currentHighlight.type = undefined;
		};

		private setCurrentHighlight = (node: Node, direction: NavigationDirection = NavigationDirection.NEXT,
				type: ContextHighlightType): void => {
			this.currentHighlight.type = type;
			if ((this.currentHighlight.node !== node) && (this.lastHighlightedNode !== node)) {
				this.highlightSentenceIndex = 1;
				if (node)
					this.lastHighlightedNode = node;
			} else {
				if (!this.currentHighlight.node)
					this.currentHighlight.node = node;

				this.highlightSentenceIndex += direction;
				if (this.highlightSentenceIndex > this.currentHighlight.node.sentences.length)
					this.highlightSentenceIndex = 1;
				if (this.highlightSentenceIndex < 1 )
					this.highlightSentenceIndex = this.currentHighlight.node.sentences.length;
			}

			this.currentHighlight.node = node;
			this.highlightChicklet(node);
		};

		highlightModel = (node: Node, direction: NavigationDirection = NavigationDirection.NEXT): void => {
			this.setCurrentHighlight(node, direction, ContextHighlightType.TOPIC);
			if (!node) this.clearHighlightedNode();

			if (DocumentTypeUtils.isConversation(this.currentSentence)) {
				this.highlightCallback('highlightModel', node, direction);
			} else {
				this.highlightSentences(node);
				this.events.highlightModel(node, direction);
			}
		};

		hoverModel = (node: Node|undefined): void => {
			if (!DocumentTypeUtils.isConversation(this.currentSentence)) return;

			this.highlightCallback('hoverModel', node);
		};

		highlightWorldAwareness = (node: Node, direction: NavigationDirection = NavigationDirection.NEXT): void => {
			this.setCurrentHighlight(node, direction,  ContextHighlightType.WORLD_AWARENESS);
			if (!node) this.clearHighlightedNode();

			this.highlightItem = node.value;
			if (DocumentTypeUtils.isConversation(this.currentSentence)) {
				this.highlightCallback('highlightWorldAwareness', node, direction);
			} else {
				this.highlightSentences(node);
				this.events.highlightWorldAwareness(node, direction);
			}
		};
	};
});
