import { CssClasses } from '@app/util/css-classes';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';

export class FilterBuilderUtils {
	static getClasses = (rule: IFilterRule): CssClasses => {
		return {
			'filter-statement-empty': rule.type === FilterRuleType.empty,
			'filter-statement-text': rule.type === FilterRuleType.stringEquality
				|| rule.type === FilterRuleType.stringEqualityLC,
			'filter-statement-numeric': rule.type === FilterRuleType.numericEquality,
			'filter-statement-numeric-range': rule.type === FilterRuleType.numericRange
				|| rule.type === FilterRuleType.numericOpenRange,
			'filter-statement-topic': rule.type === FilterRuleType.topicEquality,
			'filter-statement-date': rule.type === FilterRuleType.dateRange
		};
	};
}