import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { FailedMessagesApiService } from '@app/modules/system-administration/platform/jms-failed-messages/failed-messages-api.service';
import { JmsMessagesState } from '@app/modules/system-administration/platform/jms-failed-messages/jms-messages-state';

@Component({
	selector: 'jms-failed-messages',
	templateUrl: './jms-failed-messages.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class JmsFailedMessagesComponent implements OnInit {

	loading: Promise<any>;
	failedMessages: JmsMessagesState;

	constructor(
		private failedMessageApi: FailedMessagesApiService,
		private dialogService: CxDialogService,
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		this.failedMessages = {} as JmsMessagesState;
	}

	loadMessages = (): Promise<void> => {
		if (!this.loading) {
			this.loading = this.failedMessageApi.getFailedMessages().then((result) => {
				this.failedMessages = result;
				this.loading = null;
				this.ref.markForCheck();
			});
		}

		return this.loading;
	};

	getTypes(): string[] {
		return this.failedMessages ? Object.keys(this.failedMessages) : [];
	}

	processMessage(type: keyof JmsMessagesState, messageId: number): void {
		if (type === 'alerts')
			this.failedMessageApi.processAlertMessage(messageId).then(() => {
				this.dialogService.notify(this.locale.getString('common.done'), this.locale.getString('common.done'));
			});
		else if (type === 'completion') {
			this.failedMessageApi.processCompletionMessage(messageId).then(() => {
				this.dialogService.notify(this.locale.getString('common.done'), this.locale.getString('common.done'));
			});
		}
	}
}
