import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { ContentWidgetProperties, MarginType, MAX_TEXT_LENGTH, BackgroundType } from '@cxstudio/reports/entities/content-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'content-text-settings',
	template: `
	<div>
		<cb-text-editor
			[isLabel]="false"
			[hierarchyEnrichmentOptions]="hierarchyEnrichmentOptions"
			[content]="props.text"
			(contentChange)="updateContent($event)"
			(lengthChange)="updateLength($event)"
			[rotation]="props.rotation || ''"
			(rotationChange)="props.rotation = $event"
			[valign]="props.valign || ''"
			(valignChange)="props.valign = $event"
			[dashboardId]="widget.dashboardId"
			[maxLength]="maxLength"
			[backgroundColor]="props.background"
			></cb-text-editor>
		<characters-left-counter
			[maxLength]="maxLength"
			[textLength]="props.textLength"
		></characters-left-counter>
		<div class="form-group mt-16">
			<label class="control-label">{{'widget.labelBgLabel'|i18n}}</label>
			<background-options
				[color]="props.background"
				[type]="props.backgroundType"
				(onTypeChange)="onBackgroundTypeChange($event)"
				(onColorChange)="onBackgroundColorChange($event)"
			></background-options>
		</div>
		<div class="form-group">
			<label class="control-label">{{'widget.labelBorders'|i18n}}</label>
			<border-options [values]="props"></border-options>
		</div>
		<div class="form-group">
			<label class="control-label">{{'widget.margins'|i18n}}</label>
			<margin-options
				ng-model="props.margins">
			</margin-options>
		</div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentTextSettingsComponent implements OnInit {

	@Input() widget: ContentWidget;

	props: ContentWidgetProperties;
	visualProps: VisualProperties;
	maxLength = MAX_TEXT_LENGTH;

	hierarchyEnrichmentOptions: any[];
	colorOptions: Array<{ value: string; text: string }>;

	constructor(
		private textWidgetUtils: TextWidgetUtilsService,
		private locale: CxLocaleService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
	) {}

	ngOnInit(): void {
		this.props = this.widget.properties as ContentWidgetProperties;
		this.visualProps = this.widget.visualProperties as VisualProperties;
		this.convertLabelToText();
		this.visualProps.visualization = WidgetVisualization.TEXT;
		this.props.imageNames = this.textWidgetUtils.getImageNames(this.props.text, this.widget.dashboardId);
		this.setDefaultTextColors();

		this.props.text = this.props.text || '';
		if (!this.props.margins) this.props.margins = MarginType.NORMAL;

		let personalization = this.currentWidgets.getPersonalization(this.widget.containerId);
		this.hierarchyEnrichmentOptions = [];
		if (personalization.hasEnrichments()) {
			personalization.getEnrichments().forEach((enrichment) => {
				this.hierarchyEnrichmentOptions.push(enrichment.propertyName);
			});
		}
		if (!this.props.newTextFormat) {
			this.props.newTextFormat = true;
			// <font> should have one of color/face/size attribute per tag for correct parsing by prosemirror
			this.props.text = this.textWidgetUtils.splitFontAttributes(this.props.text);
		}
	}

	convertLabelToText(): void {
		if (this.visualProps.visualization === WidgetVisualization.LABEL) {
			let converted = this.textWidgetUtils.convertLabelToText(this.widget);
			if (converted.name === WidgetType.TEXT) {
				this.widget.name = converted.name;
				this.props = converted.properties as ContentWidgetProperties;
				this.visualProps = converted.visualProperties;
			}
		}
	}

	updateLength(length: number) {
		this.props.textLength = length;
	}

	updateContent(text: string) {
		this.props.text = text;
	}

	onBackgroundTypeChange(backgroundType: BackgroundType) {
		this.props.backgroundType = backgroundType;
	}

	onBackgroundColorChange(background: string) {
		this.props.background = background;
	}

	private setDefaultTextColors(): void {
		this.colorOptions = [
			{
				value: '',
				text: this.locale.getString('widget.colorNone')
			},
			{
				value: '#ffffff',
				text: this.locale.getString('widget.colorWhite')
			}
		];
	}

}
