import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { EmailUtils } from '@app/modules/user-administration/editor/email-utils.class';
import { LoginMessage } from '@app/modules/user/login-message';
import { PasswordResetApiService } from '@app/modules/user/password-reset-api.service';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

@Component({
	selector: 'reset-password-form',
	templateUrl: './reset-password-form.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
	.btn-25 { flex: 1 1 25%; }
	.btn-75 { flex: 1 1 75%; }
`]
})
export class ResetPasswordFormComponent {
	@Input() email: string;
	@Output() done = new EventEmitter<void>();

	submitted: boolean;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly passwordResetApiService: PasswordResetApiService
	) { }

	getMessage(): LoginMessage {
		let message = this.submitted
			? this.locale.getString('login.resetPasswortSubmittedMessage', {email: HtmlUtils.escapeHtml(this.email)})
			: this.locale.getString('login.resetPasswordMessage');
		return {
			message
		};
	}

	isResetDisabled(): boolean {
		return _.isEmpty(this.email) || !EmailUtils.validate(this.email);
	}

	resetPassword = (): void => {
		this.passwordResetApiService.requestResetPassword(this.email)
			.then(() => {
				this.submitted = true;
				this.ref.markForCheck();
			});
	};

	return = (): void => {
		this.done.emit();
	};
}

app.directive('resetPasswordForm', downgradeComponent({component: ResetPasswordFormComponent}));
