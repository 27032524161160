import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { UserEditData } from '@app/modules/user-administration/editor/user-edit-data';
import { UserUpdateDataRequest } from '@app/modules/user-administration/entities/user-update-data-request';

@Injectable()
export class UserManageApi {
	constructor(
		private cxHttp: CxHttpService
	) {}

	getMasterAccountUser = (userEmail: string): Promise<UserEditData> => {
		return this.cxHttp.post(`rest/users/manage`, { userEmail });
	};

	getCustomerAdminsCount = (): Promise<number> => {
		return this.cxHttp.get(`rest/users/manage/customer-admins-count`);
	};

	updateUser = (userId: number, userUpdateDataRequest: UserUpdateDataRequest): Promise<void> => {
		return this.cxHttp.put(`rest/users/manage/${userId}`, userUpdateDataRequest);
	};
}

app.service('userManageApi', downgradeInjectable(UserManageApi));
