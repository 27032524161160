import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';

@Injectable({
	providedIn: 'root'
})
export class ProjectAccessDataHelperService {

	constructor(
		@Inject('security') private readonly security: Security,
		@Inject('contentProviderOptionsService') private readonly contentProviderOptionsService: ContentProviderOptionsService,
		@Inject('ProjectAccessLevels') private readonly ProjectAccessLevels: ProjectAccessLevelItems
	) {}

	getCurrentUserProjectAccess = (cpId: number, accountIds: number[]): any => {
		let currentUser = this.security.getUser();
		if (this.security.isAnyAdmin() || this.security.isCustomerAdmin()) {
			return this.contentProviderOptionsService.getAccountProjects(cpId, accountIds)
				.then((response) => {
					response.data.map((accountAccess) => {
						accountAccess.accountAdmin = true;
						accountAccess.projects.map((projectAccess) => {
							projectAccess.accessLevel = this.ProjectAccessLevels.MANAGER.value;
						});
					});
					return {
						data: response
					};
				});
		} else {
			let currentUserId = currentUser.userId;
			let allowDisabled = false;
			return this.contentProviderOptionsService.getProjectsAccessLevel(
				cpId, accountIds, currentUserId, allowDisabled, CacheOptions.NOT_CACHED);
		}
	};
}

app.service('projectAccessDataHelperService', downgradeInjectable(ProjectAccessDataHelperService));
