import * as cloneDeep from 'lodash.clonedeep';

import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { Subject } from 'rxjs';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { IColorGradeLaneItem } from './color-grade-lane-item';
import { ThresholdRule } from '@cxstudio/reports/utils/color/metric-threshold-rules.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ElementRef } from '@angular/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';

export interface IColorGrades {
	thresholds: number[];
	thresholdRules: ThresholdRule[];
	colorPalette: string[];
	displayNames?: string[];
	defaultNames?: string[];
	dividers?: boolean[];
}

export interface IColorGradeOptions {
	minGrades: number;
	maxGrades: number;
	canAddRemoveGrades: boolean;
	areLimitsDisplayed: boolean;
	areLimitsEditable: boolean;
	areGradesEditable: boolean;
	canChangeColor: boolean;
	fixedValueThreshold?: boolean;
	isNameDisabled: boolean;
	integerOnly: boolean;
	decimals: number;
}

@Component({
	selector: 'cb-color-grade-bar',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cb-color-grade-bar.component.html'
})
export class CbColorGradeBarComponent implements OnInit {

	readonly DECIMAL_REGEX = /^[-+]?[0-9]*\.?[0-9]+([eE][-+]?[0-9]+)?$/;
	readonly INTEGER_REGEX = /^(0|\-?[1-9][0-9]*)$/;

	readonly DEFAULT_GRADES: IColorGrades = {
		thresholds: [-0.33, 0.33],
		thresholdRules: [ThresholdRule.LEFT_INCLUSIVE, ThresholdRule.LEFT_INCLUSIVE],
		colorPalette: [ColorConstants.RED, ColorConstants.GREY, ColorConstants.GREEN],
		displayNames: ['≤ -0.33', '≤ 0.33']
	};

	readonly DEFAULT_THRESHOLD_RULE = ThresholdRule.LEFT_INCLUSIVE;

	readonly DEFAULT_OPTIONS: IColorGradeOptions = {
		minGrades: 2,
		maxGrades: 5,
		canAddRemoveGrades: true,
		areLimitsDisplayed: true,
		areLimitsEditable: true,
		areGradesEditable: true,
		canChangeColor: true,
		fixedValueThreshold: false,
		isNameDisabled: false,
		integerOnly: true,
		decimals: undefined
	};

	@Input() validateLanesOnInit: boolean;
	@Input() grades: IColorGrades;
	@Output() gradesChange = new EventEmitter<IColorGrades>();
	@Input() options: Partial<IColorGradeOptions>;
	@Input() min: number;
	@Input() max: number;
	@Output() minMaxChange = new EventEmitter<{isMin: boolean; value: number}>();
	@Input() allowMinMaxMatching: boolean;
	@Output() validityChange = new EventEmitter<boolean>();
	@Input() format: NumberFormatSettings;

	@Input() isDisabled: boolean;

	canChangeColor: boolean;
	decimalTest: RegExp;

	gradesUpdateSubject: Subject<void> = new Subject<void>();

	private oldthresholds: number[];
	private oldMin: number;
	private oldMax: number;

	constructor(private elementRef: ElementRef) {}

	ngOnInit(): void {
		this.grades = this.grades || this.DEFAULT_GRADES;
		this.allowMinMaxMatching = this.allowMinMaxMatching || false;
		this.min = this.min === undefined ? 0 : this.min;
		this.max = this.max === undefined ? 10 : this.max;

		this.options = _.defaults(this.options || {}, this.DEFAULT_OPTIONS);

		this.canChangeColor = this.options.canChangeColor || false;

		this.decimalTest = this.options.integerOnly ? this.INTEGER_REGEX : this.DECIMAL_REGEX;

		this.calculateGrades();
	}

	private calculateGrades(): void {
		this.grades.thresholds.sort((a, b) => {
			return a - b;
		});
	}

	displayLimits(): boolean {
		return this.options.areLimitsDisplayed;
	}

	calculatePercent = (colorIndex, absolute?): number => {
		if (colorIndex < 0 || colorIndex > this.grades.thresholds.length) return 0;

		let previous = (colorIndex === 0 || absolute)
			? this.getVisibleRangeMinimum()
			: this.grades.thresholds[colorIndex - 1];
		let current = colorIndex === this.grades.thresholds.length
			? this.getVisibleRangeMaximum()
			: this.grades.thresholds[colorIndex];

		return Math.abs((current - previous) * 100 / this.getVisibleRange());
	};

	getHandlePositionStyle = (index: number): string => {
		return 'calc(' + this.calculatePercent(index, true) + '% - ' + this.getHandleOffset(this.grades.thresholdRules[index]) + 'px)';
	};

	private getHandleOffset = (rule: ThresholdRule): number => {
		return rule === ThresholdRule.RIGHT_INCLUSIVE ? 0 : 40;
	};

	getIndicatorColor = (index: number, rule: ThresholdRule): string => {
		return rule === ThresholdRule.RIGHT_INCLUSIVE ? this.grades.colorPalette[index + 1] : this.grades.colorPalette[index];
	};

	getDirectionClass = (rule: ThresholdRule): string => {
		return rule === ThresholdRule.RIGHT_INCLUSIVE ? 'right-inclusive' : 'left-inclusive';
	};

	private getVisibleRange(): number {
		return (this.getVisibleRangeMaximum() - this.getVisibleRangeMinimum()) || 1;
	}

	private getVisibleRangeMinimum(): number {
		let min = this.parseValue(this.min);
		return this.allowMinMaxMatching ? (min - this.getOuterBoundLength() / 2) : min;
	}

	private getVisibleRangeMaximum(): number {
		let max = this.parseValue(this.max);
		return this.allowMinMaxMatching ? (max + this.getOuterBoundLength() / 2) : max;
	}

	private getOuterBoundLength(): number {
		let min = this.parseValue(this.min);
		let max = this.parseValue(this.max);
		return (max - min) / 10;
	}

	private isValueInUse(val): boolean {
		let valueUsageCount = this.grades.thresholds.filter(threshold => threshold === val).length;
		return valueUsageCount > 1;
	}

	onChange = (index: number): void => {
		this.grades.thresholds[index] = this.parseValue(this.grades.thresholds[index]);

		if (this.oldthresholds.length > index) {
			if (!this.validate(this.grades.thresholds[index])) {
				this.grades.thresholds[index] = this.oldthresholds[index];
				this.updateInput(index);
			} else {
				this.calculateGrades();
			}
		}

		this.gradesUpdateSubject.next();
		this.gradesChange.emit(this.grades);
	};

	updateInput(index: number): void {
		let input: HTMLElement = $(this.elementRef.nativeElement).find('.grade-handler-input input').get(index) as HTMLElement;
		$(input).val(this.grades.thresholds[index]);
	}

	onChangeColor = (): void => {
		this.gradesUpdateSubject.next();
		this.gradesChange.emit(this.grades);
	};

	onChangeMinMax = (isMin?: boolean): void => {
		this.min = this.parseValue(this.min);
		if (isMin && !this.validateMinMax(this.min, isMin)) {
			this.min = this.oldMin;
		}

		this.max = this.parseValue(this.max);
		if (!isMin && !this.validateMinMax(this.max)) {
			this.max = this.oldMax;
		}

		this.gradesUpdateSubject.next();
		this.minMaxChange.emit({isMin, value: isMin ? this.min : this.max});
	};

	private validate(newValue: string | number): boolean {
		if (!this.decimalTest.test(newValue as string)) return false;

		let _newValue = this.parseValue(newValue);
		if (isNaN(_newValue)) return false;

		if (this.allowMinMaxMatching && (_newValue < this.min || _newValue > this.max)) return false;
		if (!this.allowMinMaxMatching && (_newValue <= this.min || _newValue >= this.max)) return false;
		if (this.isValueInUse(newValue)) return false;

		return true;
	}

	private validateMinMax(value: string | number, isMin = false): boolean {
		if (!this.decimalTest.test(value as string)) return false;
		let _value = this.parseValue(value);

		return isMin
			? (this.allowMinMaxMatching
				? _value <= this.grades.thresholds[0]
				: _value < this.grades.thresholds[0])
			: (this.allowMinMaxMatching
				? _value >= this.grades.thresholds[this.grades.thresholds.length - 1]
				: _value > this.grades.thresholds[this.grades.thresholds.length - 1]);
	}

	private parseValue(value: string | number): number {
		if (_.isUndefined(this.options.decimals))
			return parseFloat(value as string);
		let numeric = parseFloat(value as string);
		if (!isNaN(numeric)) {
			numeric = parseFloat(Number(numeric).toFixed(this.options.decimals));
		}
		return numeric;
	}

	onFocus = (): void => {
		this.oldthresholds = cloneDeep(this.grades.thresholds);
		this.oldMin = this.min;
		this.oldMax = this.max;
	};

	addColorGrade = (colorIndex: number): void => {
		if (this.grades.thresholds.length >= this.options.maxGrades) {
			return;
		}

		let left = colorIndex === 0 ? this.min : this.grades.thresholds[colorIndex - 1];
		let right = (colorIndex === this.grades.thresholds.length) ? this.max : this.grades.thresholds[colorIndex];
		let value = Math.round(100 * (left + right ) / 2) / 100;

		let leftColor = this.grades.colorPalette[colorIndex];
		let rightColor = colorIndex === this.grades.thresholds.length ? '#ffffff' : this.grades.colorPalette[colorIndex + 1];
		let color = ColorUtilsHelper.average(leftColor, rightColor);

		this.grades.thresholds.splice(colorIndex, 0, value);
		this.grades.thresholdRules.splice(colorIndex, 0, this.DEFAULT_THRESHOLD_RULE);
		this.grades.colorPalette.splice(colorIndex + 1, 0, color);
		if (this.grades.displayNames)
			this.grades.displayNames.splice(colorIndex + 1, 0, undefined);
		this.calculateGrades();

		this.gradesUpdateSubject.next();
		this.gradesChange.emit(this.grades);
	};

	deleteColorGrade = (colorIndex: number): void => {
		if (this.grades.colorPalette.length <= this.options.minGrades) {
			return;
		}
		this.grades.thresholds.splice(colorIndex, 1);
		this.grades.thresholdRules.splice(colorIndex, 1);
		this.grades.colorPalette.splice(colorIndex, 1);
		if (this.grades.displayNames)
			this.grades.displayNames.splice(colorIndex, 1);

		this.gradesUpdateSubject.next();
		this.gradesChange.emit(this.grades);
	};

	onLaneChange = (index: number, lane: IColorGradeLaneItem): void => {
		this.grades.colorPalette[index] = lane.color;
		if (this.grades.displayNames) {
			this.grades.displayNames[index] = lane.name;
		} else {
			this.grades.displayNames = _.map(this.grades.colorPalette, (color, idx) => idx === index ? lane.name : undefined);
		}
		if (lane.rightThresholdRule === ThresholdRule.LEFT_INCLUSIVE && index !== this.grades.colorPalette.length - 1) {
			this.grades.thresholds[index] = lane.rightThreshold;
		}
		if (lane.leftThresholdRule === ThresholdRule.RIGHT_INCLUSIVE && index !== 0 ) {
			this.grades.thresholds[index - 1] = lane.leftThreshold;
		}

		this.gradesChange.emit(this.grades);
	};

	validityChangeHandler = (isValid: boolean): void => {
		this.validityChange.emit(isValid);
	};

}
