import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { RowNode } from 'ag-grid-enterprise';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';

@Injectable({
	providedIn: 'root'
})
export class DashboardGridHelperService {

	static readonly MENU_OPTIONS_SEPARATOR = 'separator';

	constructor(
		private readonly locale: CxLocaleService,
		private dashboardListService: DashboardListService,
		@Inject('security') private readonly security: Security,
	) {}

	isLabelsEditAllowed(objects: Dashboard[]): boolean {
		let allowedForEditLabelObjects = _.filter(objects, (object: Dashboard) => {
			return object.ownerId === this.security.loggedUser.user.userId || object.permissions.EDIT === true;
		});

		return allowedForEditLabelObjects.length === objects.length;
	}

	isOwner(objects: Dashboard[]): boolean {
		return _.filter(objects, (object: any) => {
			return object.ownerId === this.security.loggedUser.user.userId;
		}).length === objects.length;
	}

	hasNoFolders(objects: Dashboard[]): boolean {
		let dashboardsAndBooks: Dashboard[] = _.filter(objects, (dashboard: any) => {
			return dashboard.type === DashboardType.DASHBOARD || dashboard.type === DashboardType.BOOK;
		});
		return dashboardsAndBooks.length === objects.length;
	}

	getDeletionText(item: any): string {
		if (item.type === 'dashboard') return this.locale.getString('dashboard.menuDeleteDashboard');
		if (item.type === 'dashboardSet') return this.locale.getString('dashboard.menuDeleteBook');
		if (item.type === 'folder') {
			if (item.level > 0) return this.locale.getString('dashboard.menuDeleteSubfolder');
			return this.locale.getString('dashboard.menuDeleteFolder');
		}
		return this.locale.getString('common.delete');
	}

	updateGridRow(node: RowNode, data?: Dashboard | IFolderItem): void {
		if (data) {
			node.setData(data);
		} else {
			node.setData(node.data);
		}
		this.dashboardListService.updateDashboards();
	}
}
