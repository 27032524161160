import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ObjectListSort } from './object-list-sort';
import { ObjectSortDirection } from './object-sort-direction';
import { CxLocaleService } from '@app/core';

interface ObjectSortDirectionOption {
	value: ObjectSortDirection;
	name: string;
}

@Component({
	selector: 'sort-lane',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex-inline">
			<simple-dropdown
				[options]="columns"
				[(value)]="sort.field"
				[disableSort]="true"
				selectPrompt="{{'widget.column'|i18n}}"
				(onChange)="onColumnChange($event)"
			></simple-dropdown>
			<simple-dropdown
				class="ml-8"
				[options]="sortDirections"
				[(value)]="sort.direction"
				(onChange)="onSortDirectionChange()"
			></simple-dropdown>
		</div>
	`
})
export class SortLaneComponent implements OnInit {
	@Input() sort: ObjectListSort;
	@Output() sortChange = new EventEmitter<ObjectListSort>();
	@Input() columns: Array<ListOption<string>>;

	sortDirections: ObjectSortDirectionOption[] = [];
	options: Array<ListOption<string>>;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.initSortDirections();
		if (!this.sort.direction) {
			this.sort.direction = ObjectSortDirection.ASC;
		}
	}

	private initSortDirections = (): void => {
		this.sortDirections = [
			{ value: ObjectSortDirection.ASC, name: this.locale.getString('widget.ascending') },
			{ value: ObjectSortDirection.DESC, name: this.locale.getString('widget.descending') }
		];
	};

	onColumnChange = (column: ColumnFilterOption): void => {
		this.sort.field = column.value;
		this.onChange();
	};

	onSortDirectionChange = (): void => {
		this.onChange();
	};

	private onChange = (): void => {
		this.sortChange.emit(this.sort);
	};

}
