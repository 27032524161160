import MobileAppSettingsApiService from './mobile-app-settings-api.service';
import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import Listener from '@cxstudio/common/listener';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from './mobile-app-dialog-tab.enum';
import MobileAppSettings from './mobile-app-settings.interface';
import { ISharePermissionNameGetter } from '@cxstudio/sharing/generic-share.component';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { PermissionType } from '@app/core/authorization/all-permissions.service';

export default class MobileAppSettingsSharingComponent implements ng.IComponentController {

	getPermissionName: ISharePermissionNameGetter = {
		edit: (): string => 'manage_settings',
		shareView: (): string => 'manage_settings',
		shareEdit: (): string => 'manage_settings',
		defaultSharePermission: (): string => PermissionType.VIEW
	};

	settings?: MobileAppSettings;
	settingsModel: MobileAppSettingsListModel;
	dialogData: any;
	saveListener: Listener;
	errors: Errors;

	hasInviteError: boolean = false;

	resolve: {
		items: MobileAppSettingsListModel[];
		data: any;
	};

	constructor(
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private mobileUtils: MobileUtils,
	) {}

	$onInit = () => {
		let sharedItem = this.settingsModel as any || {};
		sharedItem.reshareAllowed = this.settingsModel.allowEditorsToShare;
		sharedItem.ownerName = this.settingsModel.submitterEmail;

		this.resolve = {
			items: [ sharedItem ],
			data: this.dialogData
		};
	};

	getShareStatus = (): string => {
		return this.settingsModel.sharingStatus;
	};

	getSharedUsersAndGroups = () => {
		if (this.isNewSettings()) {
			return this.mobileAppSettingsApiService.getNewMobileAppAccess();
		} else {
			return this.mobileAppSettingsApiService.getMobileAppAccess(this.settingsModel.id);
		}
	};

	private isNewSettings(): boolean {
		return !this.settingsModel.id;
	}

	validateInvite = (email: string) => {
		let currentEditedSettings = this.getCurrentEditedSettings();

		let validInvite = this.mobileUtils.isAllowedToViewApp(currentEditedSettings, email);
		this.hasInviteError = !validInvite;
		return validInvite;
	};

	onSharingChange = () => {
		let currentEditedSettings = this.getCurrentEditedSettings();
		let illegalAccessProvided = this.mobileUtils.isIllegalAccessProvided(currentEditedSettings, this.dialogData);

		this.errors.setValid([ MobileAppDialogTab.USERS ], 'illegal-external-user-access', !illegalAccessProvided);
	};

	private getCurrentEditedSettings = (): MobileAppSettings | MobileAppSettingsListModel => {
		return this.settings || this.settingsModel;
	};

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.MANAGE_SETTINGS;

}

app.component('mobileAppSharing', {
	bindings: {
		settings: '<?',
		settingsModel: '<',
		dialogData: '<',
		saveListener: '<',
		errors: '<',
		onInvite: '&?'
	},
	controller: MobileAppSettingsSharingComponent,
	template: `
<alert
	ng-if="$ctrl.errors.has('illegal-external-user-access') || $ctrl.hasInviteError"
	type="danger"
	text="{{::'mobile.internalInviteError'|i18n}}">
</alert>
<generic-share
	object-type-name="{{::'object.mobileApp'|i18n}}"
	resolve="::$ctrl.resolve"
	get-permission-name="::$ctrl.getPermissionName"
	get-shared-users-and-groups="$ctrl.getSharedUsersAndGroups"
	get-share-status="$ctrl.getShareStatus"
	embedded="true"
	save-listener="$ctrl.saveListener"
	on-change="$ctrl.onSharingChange()"
	one-click-removal="true"
	custom-validation="$ctrl.validateInvite"
	asset-edit-permission-type="$ctrl.getAssetEditPermissionType()">
</generic-share>`
});
