import { Component } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'percent-input',
	template:
		`<div class="numeric-with-suffix">
			<input type="number"
				   #numericInput
				   [attr.min]="0"
				   [attr.max]="100"
				   [ngModel]="value"
				   (ngModelChange)="valueChangeHandler($event)"
			>
			<span class="input-suffix ml-8"
				  *ngIf="value >= 0"
				  [style.left]="getSuffixOffset(numericInput.value)">
			%
		</span>
		</div>
	`
})
export class PercentInputComponent {
	private lastOffset: number; // required to avoid issue when entering invalid number
	value;

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val) => void = _.noop;

	getSuffixOffset = (value: string): string => {
		const emValues = [1.125, 1.75, 2.25];
		if (!value) {
			return `${this.lastOffset || 2}em`;
		}
		this.lastOffset = emValues[value.length - 1];
		return `${this.lastOffset}em`;
	};


	valueChangeHandler(value?): void {
		this.value = value;
		this.onChangeCallback(value);
		this.onTouchedCallback();
	}

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value && value !== this.value) {
			this.value = value;
			this.onChangeCallback(value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}

app.directive('percentInput', downgradeComponent({component: PercentInputComponent}));

