
import * as _ from 'underscore';
import { ConversationChartOptions, ExternalChartConfig, ConversationType, SingleLaneEnrichmentTypes } from './conversation-chart-options.class';
import { SizingStrategy } from './sizing/sizing-strategy.class';
import { BasicSizing } from './sizing/basic-sizing.class';
import { PlaybackIndicator } from './playback-indicator.class';
import { ScrollIndicator } from './scroll-indicator.class';
import { BasicConversationChart } from './basic-conversation-chart.class';
import { OvertalkIndicatorGroup } from './overtalk-indicator-group.class';
import { SecondaryMetricRenderer } from './secondary-metric-renderer.class';
import { ConversationDataPoint } from './conversation-data-point.class';
import { ReasonTrack } from './secondary-tracks/reason-track.class';
import { PredefinedMetricTrack } from '@cxstudio/conversation/secondary-tracks/predefined-metric-track.class';
import { ChatSizing } from '@cxstudio/conversation/sizing/chat-sizing.class';
import { ConversationRowIndicator } from './conversation-row-indicator.class';
import { ScorecardTrack } from '@cxstudio/conversation/secondary-tracks/scorecard-track.class';
import * as _d3 from 'd3';
import { SpineTooltipManager } from '@cxstudio/conversation/spine-tooltip-manager.class';
import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';

export type IChartSVG = d3.Selection<SVGElement, any, any, any>;

export class ConversationChart {	
	private config: ConversationChartOptions;
	
	fullSvgWidth: number;
	readonly height: number = 600;
	readonly totalWidth: number = 300;
	readonly miniChartWidth: number = 30;
	readonly SPINE_HEADER_HEIGHT: number = 24;
	readonly PREFIX: string = '__';

	lastItemEndPos: number = 0;
	staticWidth: number;
	sizingFunctions: SizingStrategy;
	destroy: () => void;

	tooltipManager: SpineTooltipManager;
	

	scrollIndicator: ScrollIndicator;
	playbackIndicator: Partial<PlaybackIndicator> = { update: () => undefined };	
	svg: IChartSVG;
	targetDataSet: ConversationDataPoint[];
	overtalk: OvertalkIndicatorGroup;
	rowIndicators: ConversationRowIndicator;
	
	conversation: BasicConversationChart;
	private targetElement;
	
	constructor(targetElement, private chartConfig: Partial<ExternalChartConfig>) {
		this.targetElement = targetElement;
		this.config = new ConversationChartOptions(chartConfig);
		this.height = this.getAvailableHeight(targetElement);
		this.fullSvgWidth = targetElement.offsetWidth;

		this.tooltipManager = new SpineTooltipManager();
		
		this.svg = d3.select(targetElement).select('svg');
		if (this.svg.empty())
			this.svg = d3.select(targetElement).append('svg').attr('height', this.height);

		$(targetElement)
			.on('keydown', this.handleKeydown);
		this.destroy = (): void => {
			$(targetElement).off('keydown');
			this.svg.remove();
			this.tooltipManager.hideTooltip();
		};
	}

	private handleKeydown = (event) => {
		let element = $(document.activeElement);
		// spine container in focus
		if ($(this.targetElement)[0] === element[0]) {
			if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
				this.focusSpineRow(event, element);
			}
			return;
		}
		// spine row in focus
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			let data = _.findWhere(this.targetDataSet, { id: element.attr('id')?.substr(this.PREFIX.length) as any * 1 });
			if (data?.events?.click)
				data.events.click();
			this.conversation.select(data);
		} else if (KeyboardUtils.isEventKey(event, Key.UP) || KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			this.focusSpineRow(event, element, -1);
		} else if (KeyboardUtils.isEventKey(event, Key.DOWN) || KeyboardUtils.isEventKey(event, Key.TAB)) {
			this.focusSpineRow(event, element, 1);
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.stopPropagation();
			this.tooltipManager.hideTooltip(true);
			$(this.targetElement).first().trigger('focus');
		}
	};

	private focusSpineRow = (event, activeElement, direction?: number) => {
		event.preventDefault();
		event.stopPropagation();
		let sorted = _.sortBy(this.targetDataSet, 'yPosition');
		let index = direction === undefined
			? 0
			: _.findIndex(sorted, { id: 1 * activeElement.attr('id')?.substr(this.PREFIX.length) }) + direction;
		if (index < 0 || index >= sorted.length) {
			return;
		}
		let data = sorted[index];
		let target = $(this.targetElement).find(`#${this.PREFIX}${data.id}`).first();
		this.tooltipManager.showTooltip(this.config.getTooltip(data), this.config.theme, event, target[0], true);
		setTimeout(() => target.trigger('focus'));
	};

	private getAvailableHeight(element): number {
		// workaround while we have to support old version of spine
		return element.offsetHeight || (element.parentElement.offsetHeight - this.SPINE_HEADER_HEIGHT);
	}

	private getSizingStrategy = (height: number, config: ConversationChartOptions): SizingStrategy => {
		switch (config.type) {
			case ConversationType.AUDIO: return new BasicSizing(height, config);
			case ConversationType.CHAT: return new ChatSizing(height, config);
			default: throw new Error(`unsupported conversation type ${config.type}`);
		} 

	};

	renderData(data): void {
		this.svg.selectAll('*').remove();
		this.initializeData(data);

		this.sizingFunctions = this.getSizingStrategy(this.height, this.config);
		this.sizingFunctions.initialize(this.targetDataSet);
		this.svg.attr('height', this.getMinChartHeight());

		this.renderChart(this.targetDataSet);
	}

	updatePlayback = (time: number): void => {
		let playingSentence: ConversationDataPoint;
		if (time > this.targetDataSet[0].timestamp) // first sentence starts not from 0
			playingSentence = this.sizingFunctions.getPointFromTimestamp(time, this.targetDataSet);
		else playingSentence = this.targetDataSet[0];
		
		this.conversation.select(playingSentence);
	};

	updateScrollArea = (startIndex: number, endIndex: number): void => {
		this.scrollIndicator.update(this.targetDataSet[startIndex], this.targetDataSet[endIndex]);
	};

	private getMinChartHeight(): number {
		return this.targetDataSet.last().yPosition + this.targetDataSet.last().height;
	}

	private renderChart = (targetDataSet: ConversationDataPoint[]): void => {
		this.scrollIndicator = new ScrollIndicator(this.config, this.svg);
		this.rowIndicators = new ConversationRowIndicator(targetDataSet, this.config, this.svg);
		this.conversation = new BasicConversationChart(targetDataSet, this.config, this.svg, this.tooltipManager);
		this.overtalk = new OvertalkIndicatorGroup(targetDataSet, this.config, this.svg);

		let secondaryTracks = _.map(this.config.singleLaneEnrichments, enrichment => {
			if (enrichment.enrichmentType === SingleLaneEnrichmentTypes.SCORECARD)
				return new ScorecardTrack(enrichment, this.config, this.tooltipManager);
			if (enrichment.enrichmentType === SingleLaneEnrichmentTypes.SENTENCE_TYPE)
				return new ReasonTrack(enrichment, this.config, this.tooltipManager);
			return new PredefinedMetricTrack(enrichment, this.config, this.tooltipManager);
		});

		let trackIndex = 0;
		secondaryTracks.forEach((trackConfig) => {
			SecondaryMetricRenderer.render(targetDataSet, this.config, this.svg,
				trackConfig, ++trackIndex);
		});

		this.svg.attr('class', `secondary-tracks-${trackIndex}`);
		
		// no playback indicator right now....
		// this.playbackIndicator = new PlaybackIndicator(this.config, this.sizingFunctions, this.width, this.svg);
	};

	private initializeData = (targetDataSet: ConversationDataPoint[]): void => {
		targetDataSet.forEach((d) => {
			if (d.duration) return;

			d.duration = this.getDurationForType(d, this.config.type);
		});

		this.targetDataSet = targetDataSet;
	};

	private getDurationForType(sentence: ConversationDataPoint, type: ConversationType): number {
		switch (type) {
			case ConversationType.AUDIO: return sentence.endTimestamp - sentence.timestamp;
			case ConversationType.CHAT: return sentence.isSilence ? 0 : sentence.wordCount;
		}
	}

	clear(): void {
		this.svg.selectAll('*').remove();
	}
}