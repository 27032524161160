import { TextAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/text-attribute-data';
import { NumericAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/numeric-attribute-data';
import { ProjectSettingsData } from '@cxstudio/asset-management/configuration/settings-data/project-settings-data';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { CxHttpService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceAttributeSettingsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxHttp: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.ATTRIBUTES);
	}

	getProjectSettings = (
		project: WorkspaceProject, cache: CacheOptions
	): AssetPromisePlatformProject<ProjectSettingsData> => {
		return this.cachedHttp.get(
			`${this.getProjectUrl(project)}/attributes`, { cache }
		) as AssetPromisePlatformProject<ProjectSettingsData>;
	};

	getTextAttributeSettings = (
		project: WorkspaceProject, attributeId: number, cache: CacheOptions
	): AssetPromisePlatformProject<TextAttributeSettings> => {
		return this.cachedHttp.get(
			this.getTextAttributeUrl(project, attributeId), { cache }
		) as AssetPromisePlatformProject<TextAttributeSettings>;
	};

	saveTextAttributeSettings = (
		project: WorkspaceProject, attributeId: number, settings: TextAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getTextAttributeUrl(project, attributeId), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	getNumericAttributeSettings = (
		project: WorkspaceProject, attributeId: number, cache: CacheOptions
	): AssetPromisePlatformProject<NumericAttributeSettings> => {
		return this.cachedHttp.get(
			this.getNumericAttributeUrl(project, attributeId), { cache }
		) as AssetPromisePlatformProject<NumericAttributeSettings>;
	};

	saveNumericAttributeSettings = (
		project: WorkspaceProject, attributeId: number, settings: NumericAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getNumericAttributeUrl(project, attributeId), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	getWordAttributeSettings = (
		project: WorkspaceProject, attributeName: string, cache: CacheOptions
	): AssetPromisePlatformProject<TextAttributeSettings> => {
		return this.cachedHttp.get(
			this.getWordAttributeUrl(project, attributeName), { cache }
		) as AssetPromisePlatformProject<TextAttributeSettings>;
	};

	saveWordAttributeSettings = (
		project: WorkspaceProject, attributeName: string, settings: TextAttributeSettings
	): AssetPromisePlatformProject<void> => {
		return this.cxHttp.put(this.getWordAttributeUrl(project, attributeName), settings)
			.then(this.cachedHttp.invalidate) as AssetPromisePlatformProject<void>;
	};

	private getProjectUrl = (project: WorkspaceProject): string => {
		return `rest/settings/ws/${project.workspaceId}/project/${project.projectId}`;
	};

	private getTextAttributeUrl = (project: WorkspaceProject, attributeId: number): string => {
		return `${this.getProjectUrl(project)}/text-attribute/${attributeId}`;
	};

	private getNumericAttributeUrl = (project: WorkspaceProject, attributeId: number): string => {
		return `${this.getProjectUrl(project)}/numeric-attribute/${attributeId}`;
	};

	private getWordAttributeUrl = (project: WorkspaceProject, attributeName: string): string => {
		return `${this.getProjectUrl(project)}/word-attribute/${attributeName}`;
	};
}
