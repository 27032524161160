import { Component, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { CxLocaleService } from '@app/core';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';
import { FormattedTableColumnsService } from '@app/shared/components/table/formatted-table-columns.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';

export interface OrganizationStructureWizardUploadDialogInput {
	hierarchy: Hierarchy;
	update: boolean;
}

@Component({
	selector: 'organization-structure-wizard-upload',
	templateUrl: './organization-structure-wizard-upload-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationStructureWizardUploadModalComponent implements OnInit {
	public readonly MediaType = MediaType;
	@Input() input: OrganizationStructureWizardUploadDialogInput;

	public successfulUpload = undefined;

	validationErrors: DetailedValidationError[];
	errorTableColumns: TableColumn<DetailedValidationError>[];

	constructor(
		private readonly locale: CxLocaleService,
		private readonly organizationApiService: OrganizationApiService,
		private readonly formattedTableColumnsService: FormattedTableColumnsService,
		private readonly ref: ChangeDetectorRef,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils
	) {}

	ngOnInit(): void {
		this.errorTableColumns = this.formattedTableColumnsService.getValidateErrorColumns();
	}

	public getFileUploadUrl(): string {
		return this.organizationApiService.getStructureUploadUrl(this.input.hierarchy.id, this.input.update);
	}

	public handleSuccessfulUpload(_response: boolean): void {
		this.successfulUpload = {$value: true};
	}

	public handleRemovedUpload(): void {
		this.successfulUpload = undefined;
	}

	getTitle = (): string => {
		return this.locale.getString('organization.structureWizardTitle', {
			hierarchyName: this.input.hierarchy.name
		});
	};

	isEditable = () => {
		return !!this.input.hierarchy.editable;
	};

	downloadDataFile = () => {
		this.organizationApiService.getOrganizationStructure(this.input.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	};

	downloadTemplateFile = () => {
		this.organizationApiService.getOrganizationStructureTemplate()
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	};

	updateErrorTable = (errors: DetailedValidationError[]): void => {
		this.validationErrors = errors;
		this.ref.markForCheck();
	};
}
