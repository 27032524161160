import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'accessibility-statement-dialog',
	template: `
<div class="bg-white">
	<cb-modal-close
		class="float-right"
		(click)="cancel()">
	</cb-modal-close>
	<div class="text-center">
		<img class="w-max-50-percent" *ngIf="!isDarkMode" src="img/QualtricsXM-Logo.png">
		<img class="w-max-50-percent" *ngIf="isDarkMode" src="img/QualtricsXM-Logo.png">
	</div>
</div>
<div class="modal-body p-16">
	<h2 class="text-center" [i18n]="'accessibility.header'"></h2>
	<p [i18n]="'accessibility.description'"></p>
	<br>
	<p [i18n]="'accessibility.note'"></p>
	<br>
	<p class="font-bold"><u>{{'accessibility.commitmentToHelpHeader'|i18n}}</u></p>
	<p [i18n]="'accessibility.commitmentToHelpDescription'"></p>
	<br>
	<p [i18n]="'accessibility.commitmentToHelpContactUs'"></p>
</div>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessibilityStatementDialogComponent implements OnInit {
	isDarkMode: boolean;

	constructor(
		private modal: NgbActiveModal,
		private applicationThemeService: ApplicationThemeService
	) { }

	ngOnInit(): void {
		this.isDarkMode = this.applicationThemeService.isShowingDarkTheme();
	}

	cancel = (): void => {
		this.modal.dismiss();
	};
}
