import { Inject, Injectable } from '@angular/core';
import * as cloneDeep from 'lodash.clonedeep';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';

@Injectable({
	providedIn: 'root'
})
export class ScheduleDistributionService {
	private readonly DistributionTypes = {
		HIERARCHY: {
			getUsersCount: (distribution) => this.organizationApiService.getOrgHierarchyNodesAssigned(distribution.hierarchyId),
			copyToSettings: (selection, settings) => {
				settings.distribution = settings.distribution || {};
				settings.distribution.type = selection.type;
				settings.distribution.hierarchyId = selection.id;
			},
			apiPreprocessor: _.noop,
			getEmailWarning: (count) => {
				return count !== 1
					? this.locale.getString('dashboard.countFilteredDashboards', {count})
					: this.locale.getString('dashboard.oneFilteredDashboard');
			},
			hierarchyNode: (distribution) => this.organizationApiService.getUserHighestHierarchyNode(distribution.hierarchyId)
		},

		SPECIFIC_USERS: {
			getUsersCount: (distribution) => {
				let totalCount = 0;
				if (distribution && distribution.subscribers) {
					const groupedData = _.groupBy(distribution.subscribers, 'type');

					let usersEmails = [];

					_.each(groupedData.user, (user: any) => {
						usersEmails.push(user.entity.userEmail);
					});

					_.map(groupedData.group, (group: any) => {
						_.each(group.entity.userEmails, (groupUserEmail) => {
							if (!_.contains(usersEmails, groupUserEmail)) {
								usersEmails.push(groupUserEmail);
							}
						});
					});

					totalCount = usersEmails.length;
				}
				return Promise.resolve({ data: totalCount });
			},
			copyToSettings: (selection, settings) => {
				settings.distribution = settings.distribution || {};
				settings.distribution.type = selection.type;
			},
			apiPreprocessor: (settings) => {
				const subscribers = cloneDeep(settings.distribution.subscribers);
				delete settings.distribution.subscribers;

				const groupedData = _.groupBy(subscribers, 'type');

				settings.distribution.users = groupedData.user;
				settings.distribution.groups = groupedData.group;
			},
			getEmailWarning: _.noop
		}
	};
	private readonly DistributionOptions = {
		SPECIFIC_USERS: {
			type: 'specific_users',
			uniqueValue: 'specific_users',
			displayName: this.locale.getString('schedule.specificUsers')
		},
		HIERARCHY: {
			type: 'hierarchy',
			uniqueValue: 'hierarchy',
			displayName: this.locale.getString('schedule.organizationHierarchy'),
			class: 'cxs-dropdown-divider',
			disabled: true
		}
	};

	constructor(
		private readonly locale: CxLocaleService,
		private readonly organizationApiService: OrganizationApiService
	) {}

	getTypes = () => this.DistributionTypes;

	getOptions = () => this.DistributionOptions;

	getTypesArray = () => {
		let retArray = [];

		Object.keys(this.DistributionTypes).forEach(key => {
			retArray.push(this.DistributionTypes[key]);
		});
		return retArray;
	};

	findType = (value) => {
		if (value === this.DistributionOptions.HIERARCHY.type) {
			return this.DistributionTypes.HIERARCHY;
		} else if (value === this.DistributionOptions.SPECIFIC_USERS.type) {
			return this.DistributionTypes.SPECIFIC_USERS;
		}
		return undefined;
	};
}
