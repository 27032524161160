import { AuthenticationService } from '@app/modules/authentication/services/authentication.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { ExternalAuthService } from '@cxstudio/services/external-auth.service';
import { LoginFlow } from './login-flow';
import { Security } from './security-service';

/**
 * Service for working with security tokens
 */
export class SingleLoginService {

	readonly LOGOUT_EVENT = 'event-logout';
	readonly SESSION_EXPIRATION_EVENT = 'event-session-timeout';

	readonly ISSUED_BY_STUDIO = 'studio';
	readonly ISSUED_BY_ANALYZE = 'analyze';

	readonly PATH_LOGIN = '/login';

	readonly COOKIE_EXPIRE_DAYS = {
		logoutEvent: 0.04
	};

	constructor(
		private readonly $location,
		private readonly $cookies,
		private readonly $q,
		private readonly $window,
		private readonly $log,
		private readonly security: Security,
		private readonly securityApiService: SecurityApiService,
		private readonly authenticationService: AuthenticationService,
		private readonly externalAuthService: ExternalAuthService
	) {}

	isAnalyzeSessionExpiration = () => {
		if (this.isCookieExist(this.SESSION_EXPIRATION_EVENT)) {
			let event = JSON.parse(this.getCookieValue(this.SESSION_EXPIRATION_EVENT));
			if (this.isIssuedBy(event.issued, this.ISSUED_BY_ANALYZE)) {
				return true;
			}
		}
		return false;
	};

	getLogoutEvent = () => {
		return this.getCookieValue(this.LOGOUT_EVENT);
	};

	removeAnalyzeSessionExpirationEvent = () => {
		let config = {
			domain: this.security.getDomain(),
			path: '/'
		};
		this.$cookies.remove(this.SESSION_EXPIRATION_EVENT, config);
	};

	removeLogoutEvent = () => {
		this.removeCookie(this.LOGOUT_EVENT);
	};

	saveLogoutEvent = () => {
		let logoutEvent = {
			issued: this.ISSUED_BY_STUDIO
		};

		this.createObjectCookie(this.LOGOUT_EVENT, logoutEvent, this.COOKIE_EXPIRE_DAYS.logoutEvent);
	};

	handleLogoutEvent = (): void => {
		if (this.isCookieExist(this.LOGOUT_EVENT)) {
			let logoutEvent = JSON.parse(this.getLogoutEvent());
			if (this.isIssuedBy(logoutEvent.issued, this.ISSUED_BY_ANALYZE)) {
				this.handleLogoutEventInternal();
			}

			this.removeLogoutEvent();
		}

	};

	removeNonHttpOnlyCookies = () => {
		this.$cookies.remove('brooklyn-token', {
			path: '/cxstudio/'
		});

		this.$cookies.remove('brooklyn-token', {
			domain: this.security.getDomain(),
			path: '/'
		});
	};

	private handleLogoutEventInternal(returnTo = ''): void {
		delete this.$window.sessionStorage.token;
		this.$window.location.href = returnTo || ''; // to clean all variables

		// redirect user to account specific login page
		if (!this.externalAuthService.tryExpireExternalSession()) {
			this.$location.path(this.PATH_LOGIN);
		}
	}

	loginByExistingToken = (urlToken) => {
		let deferred = this.$q.defer();

		// Try to login by Analyze token
		this.loginUsingSSOToken(urlToken).then((response) => {
			this.$log.log('User logged in using SSO token');
			deferred.resolve(response);
		}, () => {
			// Try to login by OAuth access token
			this.authenticationService.getAccessToken().then((response) => {
				this.$log.log('User logged in using OAuth access token from cookies');
				deferred.resolve({accessToken: response.token});
			}, () => {
				// Try to login by Studio token (brooklyn-token)
				this.loginUsingStudioToken().then((response) => {
					deferred.resolve({token: response});
					this.$log.log('User logged in using Studio token from cookies');
				}, () => {
					this.$log.log('No existing token');
					deferred.reject();
				});
			});
		});

		return deferred.promise;
	};

	private loginUsingStudioToken(): ng.IPromise<any> {
		let deferred = this.$q.defer();

		this.securityApiService.getStudioToken().then((response) => {
			let token = response.data.token;

			if (token && !_.isUndefined(token)) {
				deferred.resolve(token);
			} else {
				deferred.reject();
			}
		}, () => {
			deferred.reject();
		});

		return deferred.promise;
	}

	private loginUsingSSOToken(urlToken): ng.IPromise<any> {
		let deferred = this.$q.defer();

		this.securityApiService.loginBySSOToken(urlToken).then((response) => {
			let token = response.data.token;
			let accessToken = response.data.accessToken;

			if (token || accessToken) {
				this.security.setLoginFlow(LoginFlow.SSO);
				deferred.resolve({ token, accessToken });
			} else {
				deferred.reject();
			}
		}, () => {
			deferred.reject();
		});

		return deferred.promise;
	}

	// Internal utility functions
	private isIssuedBy(value, model): boolean {
		if (!value || _.isUndefined(value)) {
			return false;
		}

		return value.toLowerCase() === model.toLowerCase();
	}

	private getCookieValue<T = any>(cookieName: string): T {
		return this.$cookies.get(cookieName);
	}

	private isCookieExist(cookieName: string): boolean {
		let value = this.getCookieValue(cookieName);
		return value !== null && !_.isUndefined(value);
	}

	private getCookieConfig(expireDays?: number): any {
		let config: any = {
			domain: this.security.getDomain(),
			path: '/'
		};

		if (expireDays && expireDays > 0) {
			config.expires = new Date();
			config.expires.setTime(config.expires.getTime() + (expireDays * 24 * 60 * 60 * 1000));
		}

		return config;
	}

	private createObjectCookie(cookieName, cookieValue, expireDays): void {
		this.$cookies.putObject(cookieName, cookieValue, this.getCookieConfig(expireDays));
	}

	private removeCookie(cookieName: string): void {
		this.$cookies.remove(cookieName, this.getCookieConfig());
	}
}

app.service('singleLoginService', SingleLoginService);
