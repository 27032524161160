export class UserPermissionsService {
	
	static clearRestrictedPermissions = (user, licenseTypes) => {
		let filteredUserPermissions = UserPermissionsService.getFilteredPermissions(
			user.permissions, licenseTypes, user.licenseTypeId);
		user.permissions = filteredUserPermissions;

		let filteredGroupPermissions = UserPermissionsService.getFilteredGroupPermissions(
			user, licenseTypes);
		user.filteredGroupPermissions = filteredGroupPermissions;

		UserPermissionsService.refreshDerivedPermissions(user);
	};

	static grantLicensePermissions = (user, licenseTypes, allPermissions) => {
		let license = _.findWhere(licenseTypes, {licenseTypeId: user.licenseTypeId});
		if (license && license.grantedPermissions) {
			license.grantedPermissions.forEach( (granted) => {
				let permission = _.findWhere(allPermissions,
					{group: granted.group, action: granted.action});

				if (permission && !_.findWhere(user.permissions, permission)) {
					user.permissions.push(permission);
				}
			});
		}

		UserPermissionsService.refreshDerivedPermissions(user);
	};

	static setToDefaultPermissions = (user, licenseTypes, defaultPermissions) => {
		user.permissions = defaultPermissions;
		UserPermissionsService.clearRestrictedPermissions(user, licenseTypes);
	};

	static getFilteredGroupPermissions = (user, licenseTypes) => {
		return UserPermissionsService.getFilteredPermissions(
			user.groupPermissions, licenseTypes, user.licenseTypeId);
	};

	static getFilteredPermissions = (permissions, licenseTypes, licenseTypeId) => {
		let license = _.findWhere(licenseTypes, { licenseTypeId });
		if (license && permissions) {
			let filtered = UserPermissionsService.getFilteredPermissionsByLicense(permissions, license);
			return filtered;
		}
		return permissions;
	};

	static getFilteredPermissionsByLicense = (permissions, license) => {
		let filteredPermissions = [];

		let directRestrictions = license.restrictedMasterAccountPermissions;
		let restrictedApplications = license.restrictedPermissionApplications;

		for (let permission of permissions) {
			let directRestriction = _.findWhere(directRestrictions,
				{group: permission.group, action: permission.action});
			let applicationRestrictionExists = restrictedApplications
				&& restrictedApplications.indexOf(permission.application) > -1;

			let permissionRestricted = directRestriction || applicationRestrictionExists;
			if (!permissionRestricted) {
				filteredPermissions.push(permission);
			}
		}

		return filteredPermissions;
	};

	static refreshDerivedPermissions = (user) => {
		user.calculatedPermissions =
			_.union(user.filteredGroupPermissions, user.permissions, user.implicitPermissions);
	};	
}