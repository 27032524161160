import { Input } from '@angular/core';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ExternalWidget } from '@cxstudio/dashboards/widgets/widget';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'external-widget-settings',
	template: `
	<qualtrics-widget-settings class="p-24 d-block"
		[widget]="widget">
	</qualtrics-widget-settings>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExternalWidgetSettingsComponent implements OnInit {

	@Input() widget: ExternalWidget;

	constructor() { }

	ngOnInit(): void {
		this.widget.visualProperties.visualization = this.widget.name.toUpperCase() as WidgetVisualization;
	}

}

app.directive('externalWidgetSettings', downgradeComponent({component: ExternalWidgetSettingsComponent}));

