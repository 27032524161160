import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';

export interface DashboardRun {
	requestId: string;
	timestamp: string;
	runStatus?: DashboardRunStatus;
	initialPromise: ng.IPromise<any>;
}

/**
 * Do not inject http services as the sevice is used in httpInterceptor and it will cause circular dependency.
 * Use DashboardRunHelperService instead.
 */
@Injectable()
export class DashboardRunService {

	private dashboardRuns: { [dashboardId: number]: DashboardRun } = {};
	private lastDashboardRunRequestId: string;

	private bookRunNotSpecifiedTabId: number = -1;
	private initBookRuns: { [bookId: number]: DashboardRun } = {};

	private dashboardPageRegExp: RegExp = new RegExp('/home/\\d+');
	private bookPageRegExp: RegExp = new RegExp('/dashboard/\\d+');

	setDashboardRun = (dashboardId: number, tabId: number, dashboardRunRequestId: string, timestamp: string,
			initialPromise: ng.IPromise<any>) => {
		if (tabId === this.bookRunNotSpecifiedTabId) {
			this.setRunForBookInit(dashboardId, dashboardRunRequestId, timestamp, initialPromise);
		} else if (tabId && tabId !== this.bookRunNotSpecifiedTabId) {
			this.setRun(tabId, dashboardRunRequestId, timestamp, initialPromise);
		} else {
			this.setRun(dashboardId, dashboardRunRequestId, timestamp, initialPromise);
		}
	};

	private setRun = (dashboardId: number, dashboardRunRequestId: string, timestamp: string,
			initialPromise: ng.IPromise<any>): void => {
		this.dashboardRuns[dashboardId] = { requestId: dashboardRunRequestId, timestamp, initialPromise };

		this.lastDashboardRunRequestId = dashboardRunRequestId;
	};

	private setRunForBookInit = (bookId: number, bookRunRequestId: string, timestamp: string,
			initialPromise: ng.IPromise<any>): void => {
		this.initBookRuns[bookId] = { requestId: bookRunRequestId, timestamp, initialPromise };
	};

	postProcessBookInit = (dashboardId: number, bookId?: number): void => {
		let run: DashboardRun;
		if (bookId && this.initBookRuns[bookId]) {
			run = this.initBookRuns[bookId];
			this.setRun(dashboardId, run.requestId, run.timestamp, run.initialPromise);
		}
	};

	getDashboardRun = (dashboardId: number): DashboardRun => {
		return this.dashboardRuns[dashboardId];
	};

	getDashboardRunRequestId = (): string => {
		return this.lastDashboardRunRequestId;
	};

	getBookRunNotSpecifiedTabId = (): number => {
		return this.bookRunNotSpecifiedTabId;
	};

	// It is used for report requets as they run from queue
	// Such requests can be triggered not from dashboards
	// So it's requried to check dashboardRun and timestamp exist
	getHeadersObjectForReports = (dashboardId: number, timestamp: string): any => {
		let dashboardRun: DashboardRun = this.dashboardRuns[dashboardId];
		return dashboardRun && timestamp && timestamp === dashboardRun.timestamp ? this.buildHeaders(dashboardRun.requestId) : {};
	};

	private buildHeaders = (dashboardRunRequestId: string): any => {
		return { dashboardRunRequestId };
	};

	isDashboardRunLogRequest = (): boolean => {
		let currentAbsoluteUrl: string = window.location.href;

		return this.dashboardPageRegExp.test(currentAbsoluteUrl) || this.bookPageRegExp.test(currentAbsoluteUrl);
	};

	hasDashboardRunRequestIdHeader = (headers: any): boolean => {
		return headers?.dashboardRunRequestId;
	};

}

app.service('dashboardRunService', downgradeInjectable(DashboardRunService));
