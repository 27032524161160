import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { CxHttpService } from '@app/core';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceScorecardsApiService {
	constructor(
		private readonly http: CxHttpService,
	) {}

	getScorecards = (
		project: WorkspaceProject, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<Scorecard[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/scorecards';
		return this.http.get(url, config) as AssetPromisePlatformProject<Scorecard[]>;
	};

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/resources/ws/${project.workspaceId}/project/${project.projectId}`;
	};
}
