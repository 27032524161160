import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import * as _ from 'underscore';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';


export class InternalProjectTypes {

	static readonly STUDIO_ADMIN_PROJECT_ID = -200;

	private static readonly SUPPORTED_WIDGETS = [
		WidgetType.LINE,
		WidgetType.BAR,
		//WidgetType.METRIC, // need to implement backend
		WidgetType.HEATMAP,
		//WidgetType.CLOUD,
		WidgetType.PIE,
		WidgetType.TABLE,
		WidgetType.SCATTER,
	];

	static isAdminProject = (projectId: number): boolean => {
		return projectId === InternalProjectTypes.STUDIO_ADMIN_PROJECT_ID;
	};

	static isStudioAdminProject = (projectId: number): boolean => {
		return InternalProjectTypes.isAdminProject(projectId);
	};

	static isStudioAdminAttribute = (attribute: {objectType?: AttributeObjectType}): boolean => {
		return attribute?.objectType === AttributeObjectType.STUDIO_GROUPING;
	};

	static isStudioAdminSupported = (widgetType: WidgetType): boolean => {
		return _.contains(InternalProjectTypes.SUPPORTED_WIDGETS, widgetType);
	};
}
