import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardPermissions } from '@cxstudio/dashboards/entity/dashboard-permissions';

@Injectable({
	providedIn: 'root'
})
export class DashboardAccessService {
	constructor(
		@Inject('security') private security: Security,
		private dashboardListService: DashboardListService,
	) {}

	canDrillToFeedbackInView = (dashboard: Dashboard): boolean => {
		return this.security.has('drill_to_feedback_in_view')
			&& (this.canEditDashboard(dashboard) || dashboard.properties.viewOnlyDrill);
	};

	canShare = (dashboard: Dashboard): boolean => {
		if (!dashboard) {
			return false;
		}

		if (dashboard.permissions.OWN) {
			return this.hasSharePermission();
		}

		let preventShare = dashboard.properties.preventDashboardEditorsShare;
		return !_.isUndefined(preventShare) && isFalse(preventShare)
				&& dashboard.permissions.EDIT && this.hasSharePermission();
	};

	canEditDashboard = (dashboard: Dashboard) => {
		if (!this.security.has('edit_dashboard') || !dashboard) {
			return false;
		}

		let permissions: DashboardPermissions;
		if (!_.isEmpty(dashboard.permissions)) {
			permissions = dashboard.permissions;
		} else {
			permissions = _.find(this.dashboardListService.getCurrentDashboardsList(), { id: dashboard.id })?.permissions;
		}
		return permissions?.EDIT;
	};

	hasSharePermission = (): boolean => {
		return this.security.has('share_edit') || this.security.has('share_view');
	};
}

app.service('dashboardAccessService', downgradeInjectable(DashboardAccessService));
