import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';

import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Security } from '@cxstudio/auth/security-service';
import { Model } from '@cxstudio/reports/entities/model';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { ScorecardEditorService } from '@app/modules/project/scorecard/editor/scorecard-editor.service';
import { ScorecardFormatterUtils } from '@app/modules/project/scorecard/editor/scorecard-formatter-utils.class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { NumericAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/numeric-attribute-data';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { IScorecardTreeNode } from '@cxstudio/projects/scorecards/scorecard-model-context';
import { Component, Input, ChangeDetectionStrategy, OnInit, Inject } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { Deferred } from 'jquery';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { AttributeSettingsService } from '@app/modules/project/attribute/attribute-settings.service';
import ScorecardPreviewResultService from './preview-result/scorecard-preview-result-service';
import { NumericAttributeGroupingSettingsEditorProvider } from '@app/modules/attribute/services/assets/settings-editors/providers/numeric-attribute-grouping-settings-editor.provider';
import { NumericAttributeCalculationSettingsEditorProvider } from '@app/modules/attribute/services/assets/settings-editors/providers/numeric-attribute-calculation-settings-editor.provider';
import { NameService } from '@cxstudio/common/name-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { PromiseUtils } from '@app/util/promise-utils';


export interface IScoringTreeItem {
	scoringEntry: IScorecardTreeNode;
	children?: IScoringTreeItem[];
}

export interface ScorecardEditorWizardInput {
	mode: CxWizardMode;
	scorecard: Scorecard;
	scorecards: Scorecard[];
	props: IProjectSelection;
	models: Model[];
}

@Component({
	selector: 'scorecard-editor-wizard',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './scorecard-editor-wizard.component.html'
})
export class ScorecardsEditorWizardComponent implements OnInit {

	@Input()
	private input: ScorecardEditorWizardInput;

	scorecard: Scorecard;
	scorecards: Scorecard[];
	props: IProjectSelection;
	mode: CxWizardMode;
	models: Model[];

	isNew: boolean;
	isViewMode: boolean;
	initialScorecard: Scorecard;
	modalTitle: string;
	currentStep: number;

	driverTrigger: boolean;

	loading;

	validationDeferred: Promise<void>;

	constructor(
		@Inject('security') private readonly security: Security,
		@Inject('scorecardsApiService') private readonly scorecardsApiService: ScorecardsApiService,
		@Inject('globalNotificationService') private readonly globalNotificationService: GlobalNotificationService,
		@Inject('nameService') private readonly nameService: NameService,
		private readonly attributeSettingsService: AttributeSettingsService,
		private readonly numericAttributeGroupingSettingsEditorProvider: NumericAttributeGroupingSettingsEditorProvider,
		private readonly numericAttributeCalculationSettingsEditorProvider: NumericAttributeCalculationSettingsEditorProvider,
		private readonly scorecardEditorService: ScorecardEditorService,
		private readonly scorecardPreviewResultService: ScorecardPreviewResultService,
		private readonly locale: CxLocaleService,
		private readonly modal: NgbActiveModal,
		private readonly cxDialogService: CxDialogService
	) {}

	ngOnInit(): void {
		this.props = this.input.props;
		this.mode = this.input.mode || CxWizardMode.EDIT;
		this.models = this.input.models;
		this.scorecards = this.input.scorecards;

		this.isViewMode = this.mode === CxWizardMode.VIEW || this.mode === CxWizardMode.VIEW_LAST_PAGE_ONLY;
		this.modalTitle = this.getModalTitle();

		this.isNew = !this.input.scorecard;
		this.scorecard = this.isNew ? this.initScorecard() : cloneDeep(this.input.scorecard);
		this.currentStep = this.isNew ? 0 : 1;

		if (this.scorecard.threshold) {
			this.scorecard.threshold = ScorecardFormatterUtils.normalizeThreshold(this.scorecard.threshold);
		}
		if (this.scorecard.thresholdRaw) {
			this.scorecard.thresholdRaw = ScorecardFormatterUtils.normalizeThreshold(this.scorecard.thresholdRaw);
		}
		if (this.models.length) {
			if (this.scorecard.modelId) {
				this.populateContextFromScorecard(this.scorecard);
			}
		}

		this.initialScorecard = cloneDeep(this.scorecard);
	}

	getModalTitle = (): string => {
		if (this.isNew) {
			return this.locale.getString('scorecards.createScorecardHeader');
		} else if (this.isViewMode) {
			return this.locale.getString('scorecards.viewScorecardHeader');
		} else {
			return this.locale.getString('scorecards.editScorecardHeader');
		}
	};

	initScorecard = (): Scorecard => {
		return {
			name: this.generateUniqueScorecardName(),
			creator: this.security.getEmail(),
			active: false,
			scorecardTopics: []
		};
	};

	private generateUniqueScorecardName = () => {
		const SCORECARD_BASE_NAME: string = this.locale.getString('scorecards.scorecardNameBase');
		const SCORECARD_NAME_FIELD = 'name';

		return this.nameService.uniqueName(SCORECARD_BASE_NAME, this.scorecards, SCORECARD_NAME_FIELD);
	};

	private populateContextFromScorecard = (scorecard: Scorecard): void => {
		let model: Model = _.findWhere(this.models, { id: scorecard.modelId });
		this.scorecardEditorService.populateModelContext(model);
		this.scorecardEditorService.populateScorecardContext(scorecard);
	};

	hasChanged = (): boolean => {
		return !_.isEqual(this.initialScorecard, this.scorecard);
	};

	hasTopicsChanged = (): boolean => {
		return !_.isEqual(this.initialScorecard.scorecardTopics, this.scorecard.scorecardTopics);
	};

	save = (): void => {
		this.scorecardEditorService.enshureTopicsHaveWeight();
		this.populateScorecardFromContext();
		const totalUpdated = this.scorecardEditorService.autoPopulateTotal();

		this.validationDeferred = Promise.resolve();

		this.loading = this.validationDeferred.then(() => {
			let message = '';
			if (totalUpdated) {
				message = this.locale.getString('scorecards.totalUpdated');
			}
			if (this.isNew) {
				return PromiseUtils.wrap(this.scorecardsApiService.save(this.props.contentProviderId, this.props.accountId, this.scorecard))
					.then(newScorecard => {
						this.loading = this.saveScorecardAttributeSettings(newScorecard).then(() => {
							this.closeModal(newScorecard);
							this.showNotification(message);
						});
					});
			} else {
				this.cleanupUnselectedNodes();

				let updateCases = this.security.getCurrentMasterAccount().engagorEnabled
					&& this.hasTopicsChanged();
				return PromiseUtils.wrap(this.scorecardsApiService.update(this.props.contentProviderId, this.props.accountId,
					this.scorecard, updateCases))
					.then(updatedScorecard => {
						this.loading = this.saveScorecardAttributeSettings(updatedScorecard).then(() => {
							this.closeModal(updatedScorecard);
							this.showNotification(message);
						});
					});
			}
		});
	};

	private closeModal = (scorecard): void => {
		this.scorecardEditorService.clear();
		this.modal.close(scorecard);
	};

	cleanupUnselectedNodes = () => {
		this.scorecard.scorecardTopics =
			this.scorecardEditorService.filterTopicsByScoringEntries(this.scorecard.scorecardTopics);
	};

	showNotification = (message: string): void => {
		if (message)
			this.globalNotificationService.addSuccessNotification(message);
	};

	cancel = (): void => {
		this.populateScorecardFromContext();

		if (!this.isViewMode && this.hasChanged()) {
			this
				.cxDialogService
				.showUnsavedChangesDialogAndResolve(
					this.save,
					this.dismissModal,
					'scorecards.unsavedChangesHeader',
					'scorecards.unsavedChanges',
					false
				)
				.catch(() => {})
			;

			return;
		}

		this.dismissModal();
	};

	private dismissModal = (): void => {
		this.scorecardEditorService.clear();
		this.modal.dismiss('Manually dismissed');
	};

	populateScorecardFromContext = (): void => {
		this.scorecardEditorService.populateScorecardFromContext(this.scorecard);
	};

	test = (): void => {
		this.populateScorecardFromContext();
		this.scorecardPreviewResultService.open({ scorecard: this.scorecard, props: this.props, isNew: this.isNew });
	};

	driverSelected = (): void => {
		this.driverTrigger = !this.driverTrigger;
	};

	private saveScorecardAttributeSettings = (scorecard: Scorecard): Promise<void> => {
		if (!scorecard)
			return Promise.resolve();

		let attribute = {
			id: scorecard.attributeId,
			objectType: AttributeObjectType.DOCUMENT
		};

		let project: ProjectIdentifier =
			new ProjectIdentifier(this.props.contentProviderId, this.props.accountId, this.props.projectId);

		let settingsPromise = this.attributeSettingsService.getNumericAttributeSettings(project, attribute.id, CacheOptions.NOT_CACHED);
		return settingsPromise.then(savedSettings => {
			let groupingSettings = savedSettings ? savedSettings.grouping : undefined;
			let groupingEditor =  this.numericAttributeGroupingSettingsEditorProvider.getInstance();
			let groupingPromise = groupingEditor.setSettings(attribute, groupingSettings, project) as PromiseLike<any>;

			let calculationSettings = savedSettings ? savedSettings.calculation : undefined;
			let calculationEditor =  this.numericAttributeCalculationSettingsEditorProvider.getInstance();
			let calculationPromise = calculationEditor.setSettings(calculationSettings) as PromiseLike<any>;

			return Promise.all([groupingPromise, calculationPromise]).then(() => {
				let settings: NumericAttributeSettings = {
					grouping: groupingEditor.getSettings(),
					calculation: calculationEditor.getSettings()
				};

				settings.calculation.decimals = ScorecardFormatterUtils.getThresholdPrecision(scorecard.threshold);
				return this.attributeSettingsService.saveNumericAttributeSettings(this.props, attribute.id, settings);
			});
		});
	};

	loadingHandler = (loadingPromise) => {
		this.loading = loadingPromise;
	};

}
