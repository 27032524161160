import { SentenceSentiment } from '@cxstudio/reports/preview/sentence-sentiment';
import { SentenceChunk } from '@cxstudio/reports/preview/sentence-chunk-class';
import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';
import { SentenceContext, ISupportDescriptors } from '@app/modules/profanity/SentenceContext';

export interface SentenceTopic {
	id: number;
	modelId: number;
	name: string;
	path: string[];
	selected?: boolean;
	isLeaf: boolean;
	modelIdPath: number[];
}

export interface SentenceScorecardTopic {
	id: number;
	name: string;
	result: ScorecardTopicResult;
	weight: number;
	rebutted: boolean;
	scorecardId?: number;
}

export class PreviewSentence implements ISupportDescriptors {
	static readonly TOP_LEVEL_ATTRIBUTES: {[key: string]: string} = {
		SM_SERVICE: 'smService',
		USER_ID: 'userId',
		USER_NAME: 'userName',
		USER_PROFILE_URL: 'userProfileUrl',
		PERMALINK: 'messageUrl',
	};

	chunks?: SentenceChunk[];
	text?: string;
	sentenceContext?: SentenceContext;
	descriptors?: string[];

	sentiment?: SentenceSentiment; // sentiment bound id
	dScore?: number; // raw sentiment value
	easeScore?: number;
	emotionIntensity?: number;
	id?: number;
	verbatimId?: number;
	verbatimType?: string;
	documentId?: number;
	documentDate?: string;
	smService?: string;
	userId?: string;
	userName?: string;
	userProfileUrl?: string;
	messageUrl?: string;
	feedbackTextComplete?: boolean;

	attributes?: {[name: string]: any};
	topics?: SentenceTopic[];
	sentenceTopics?: SentenceTopic[];
	scorecardTopics?: {[scorecardId: number]: SentenceScorecardTopic[]};

	highlight?: boolean;
	suggestionMenuOpened?: boolean; // used for sentence-preview sentences

	selected?: boolean;

	scorecards?: {[scorecardId: number]: number};
	models?: {[modelId: number]: number};
}
