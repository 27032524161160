import * as uib from 'angular-ui-bootstrap';
import * as _ from 'underscore';

import ILocale from '@cxstudio/interfaces/locale-interface';
import { MasterAccountApiService, SharingEntitiesConfig, SharingEntitiesSearchConfig } from '@cxstudio/services/data-services/master-account-api.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';

export enum IShareEntityType {
	USER = 'user',
	GROUP = 'group',
	META_GROUP = 'metaGroup',
	PUBLIC = 'publicStatus'
}

export interface IShareEntity {
	type: IShareEntityType;
	_name: string;
	displayName: string;
	fullName?: string;
	iconType: string;
	assetEditPermissions?: any[];
	entity: string | any;
	permission?;
	action?: ShareAction;
	onRemove?: () => void;
	isOwner?: boolean;
}

export interface UsersAndGroups {
	users: IShareEntity[];
	groups: IShareEntity[];
}

export type IAccessChange = {[key in IShareEntityType]?: IShareEntity[]};

export class SharingService {
	static readonly USER = 'USER';
	static readonly GROUP = 'GROUP';
	static readonly META_GROUP = 'META_GROUP';
	static readonly PUBLIC_STATUS = 'publicStatusPUBLIC';
	static readonly PUBLIC_GROUP_ID = -1;
	static readonly SHARING_SEARCH_LIMIT = CONFIG.sharing ? CONFIG.sharing.search.size : 20;

	readonly CB_USERS: IShareEntity = {
		type: IShareEntityType.META_GROUP,
		_name: 'CB_USERS',
		displayName: this.locale.getString('common.clarabridgeUsers'),
		iconType: 'cbUsers',
		assetEditPermissions: [],
		entity: 'CB_USERS'
	};

	readonly PUBLIC: IShareEntity = {
		type: IShareEntityType.PUBLIC,
		_name: 'PUBLIC',
		displayName: this.locale.getString('common.allUsers'),
		iconType: 'publicStatus',
		assetEditPermissions: [],
		entity: 'PUBLIC'
	};

	constructor(
		private locale: ILocale,
		private $q: ng.IQService,
		private masterAccountApiService: MasterAccountApiService,
		private cbDialogService: CBDialogService,
		private globalNotificationService: GlobalNotificationService,
		private $uibModal: ng.ui.bootstrap.IModalService
	) {}

	shareWithDialog(toShare: SharableAsset[] | SharableAsset, params: SharingParams): ng.IPromise<void> {
		let itemsToShare: SharableAsset[] =
			Array.isArray(toShare) ? toShare : [toShare];

		let context: SharingContext = {
			bulkMode: itemsToShare.length > 1,
			items: itemsToShare,
			params
		};

		return this.checkUnsharable(context)
			.then((updatedContext) => this.showDialog(updatedContext))
			.then(() => this.$q.when());
	}

	checkUnsharable = (context: SharingContext): ng.IPromise<SharingContext> => {
		if (!context.bulkMode) return this.$q.when(context);

		let itemsToShare = context.items;
		let unsharable = _.reject(itemsToShare, this.canShare);
		if (unsharable.length === 0) return this.$q.when(context);

		let confirmDialog = this.cbDialogService.confirmTable(
			this.locale.getString('common.unableToShareHeading'),
			this.locale.getString('common.unableToShareMessage'),
			this.getConfirmTableColumns(),
			unsharable,
			this.locale.getString('common.continue'),
			this.locale.getString('common.cancel'));
		return confirmDialog.result.then(() => {
			let sharable = _.filter(itemsToShare, this.canShare);
			if (_.isEmpty(sharable)) {
				return this.cbDialogService.notify(
					this.locale.getString('common.unableToShareHeading'),
					this.locale.getString('common.nothingToShare')
				).result.then(() => this.$q.reject());

			}
			context.items = sharable;
			return this.$q.when(context);
		});
	};

	canShare = (asset: SharableAsset): boolean => {
		return this.isOwner(asset) || this.couldBeReshared(asset);
	};

	private isOwner = (asset: SharableAsset): boolean => {
		return this.hasPermission(asset, AssetPermission.OWN);
	};

	private couldBeReshared = (asset: SharableAsset): boolean => {
		return this.isReshareAllowed(asset) || this.isNotPreventShare(asset);
	};

	//metrics and filters
	private isReshareAllowed = (asset: SharableAsset): boolean => {
		return asset.permissionLevel === AssetPermission.EDIT && asset.reshareAllowed;
	};

	//dashboards
	private isNotPreventShare = (asset: SharableAsset): boolean => {
		return asset.permissions && asset.permissions[AssetPermission.EDIT]
			&& (asset.properties.preventDashboardEditorsShare === false
				|| asset.properties.preventDashboardEditorsShare === 'false');
	};

	private hasPermission = (asset: SharableAsset, permission: AssetPermission): boolean => {
		return asset.permissionLevel === permission
			|| (asset.permissions && asset.permissions[permission]);
	};

	private getConfirmTableColumns(): Array<TableColumn<any>> {
		return [{
			name: 'displayName',
			displayName: this.locale.getString('common.object'),
			formatter: (object: SharableAsset, field: string): string => {
				return this.getAssetDisplayName(object);
			}
		}, {
			name: 'ownerName',
			displayName: this.locale.getString('common.owner')
		}];
	}

	private showDialog = (context: SharingContext): ng.IPromise<any[]> => {
		let dlg;

		dlg = this.$uibModal.open({
			component: context.params.component,
			backdrop: 'static',
			windowClass: 'modal-md',
			keyboard: false,
			resolve: {
				bulkMode: () => context.bulkMode,
				items: () => context.items,
				modalTitle: () => context.params.modalTitle,
				saveFunction: () => this.getSaveHandler(context),
				objectTypePlural: () => context.params.objectTypePlural,
				shareToUser: () => this.getShareToUserEntity(context.params.shareToUser)
			}
		});

		return dlg.result;
	};

	private getSaveHandler = (context: SharingContext): any => {
		return (modalInstance: uib.IModalInstanceService, data: SharingModalResult, isComponentModal: boolean = false): any => {
			if (data.loading.save)
				return;
			data.loading.save = true;

			let closeModalMethod: (returnValue: any) => void;
			closeModalMethod = isComponentModal ?
				(value) => modalInstance.close({$value: value}) :	// need different close call if using component
				(value) => modalInstance.close(value);

			this.checkOwners(data, context).then(() => {
				this.handleReshare(data, context);

				let allEntities = this.prepareEntities(data);
				if (!allEntities.length) { // nothing changed
					closeModalMethod(data.items);
					return;
				}

				let restData = _.groupBy(allEntities, 'type'); // user and group
				data.loading.init = context.params.share(data.items, restData)
					.then(this.getSharingResponseHandler(data.items, closeModalMethod))
					.then(() => this.reloadAssets(data, context))
					.then((actualAssets: SharableAsset[]) => {
						this.showNotifications(actualAssets);

						if (context.params.clearSelection) context.params.clearSelection();
						context.params.refreshGrid(actualAssets);
					})
					.finally(() => {
						data.loading.save = false;
					});
			});
		};
	};

	private handleCancel = (modalInstance: uib.IModalInstanceService, data: SharingModalResult): any => {
		if (!data.changedEntities.length)
			modalInstance.dismiss();
		else {
			let confirm = this.cbDialogService.confirm(
				this.locale.getString('filter.unsavedChangesHeader'),
				this.locale.getString('filter.unsavedSharingChanges'),
				this.locale.getString('administration.dontshare'),
				this.locale.getString('common.cancel'));
			confirm.result.then(() => modalInstance.dismiss());
		}
	};

	private checkOwners = (data: SharingModalResult, context: SharingContext): ng.IPromise<void> => {
		if (!context.bulkMode) return this.$q.when();

		let owners = context.items.map(item => item.ownerName);
		let selectedOwners = data.changedEntities
			.filter(item => item.type === 'user')
			.filter(item => owners.contains(item.entity.userEmail))
			.map(item => item.entity.userEmail);
		if (selectedOwners.length === 0) return this.$q.when();

		let ownedBySelectedUsers = context.items.filter(
			item => selectedOwners.contains(item.ownerName));

		let notifyDialog = this.cbDialogService.notifyTable(
			this.locale.getString('common.unableToShareHeading'),
			this.locale.getString('common.unableToChangeOwnerMessage'),
			this.getConfirmTableColumns(),
			ownedBySelectedUsers,
			this.locale.getString('common.ok'));
		return notifyDialog.result.then(() => {
			return this.$q.reject();
		});
	};

	private handleReshare = (data: SharingModalResult, context: SharingContext): void => {
		if (context.bulkMode) return;

		let item = context.items[0];
		const isOwner = this.isOwner(item);
		if (isOwner && context.params.updateReshare) {
			context.params.updateReshare(item, !data.preventReshare);
		}
	};

	prepareEntities = (data: SharingModalResult): IShareEntity[] => {
		let cleanUpItem = (item) => {
			for (let k of Object.keys(item)) {
				if (k.indexOf('_') === 0)
					delete item[k];
			}
		};

		// subarrays: create, add, delete, update
		return _.chain(data.sharedEntities)
			.filter(item => item.action === 'update') // updates are within sharedEntities
			.union(data.changedEntities)
			.map(item => angular.extend({}, item)) // copy items to not change ui
			.each(cleanUpItem)
			.value();
	};

	private reloadAssets = (data: SharingModalResult, context: SharingContext): ng.IPromise<SharableAsset[]> => {
		let changedItems: SharableAsset[] = [];
		let promises = _.map(data.items, item => context.params.getAsset(item.id));
		return this.$q.all(promises).then((responses) => {
			responses.forEach((asset) => {
				let changedItem = asset;
				if (changedItem) {
					changedItems.push(changedItem);

				}
			});
			return this.$q.when(changedItems);
		});
	};

	private showNotifications = (actualAssets: SharableAsset[]): void => {
		actualAssets.forEach((asset) => {
			this.globalNotificationService.addItemSavedNotification(
				this.getAssetDisplayName(asset));
		});
	};

	private getAssetDisplayName = (asset: SharableAsset): string => {
		return asset.displayName ? asset.displayName : asset.name; //dashboard do not have displayName
	};

	private getSharingResponseHandler = (items, closeModal: (value: any) => void): any => {
		return (response) => {
			let errors = response.data;

			if (!$.isEmptyObject(errors)) {
				let formattedErrors = [];
				let formatError = (type, item, message) => type + ' ' + item + ': ' + message;

				for (let type of Object.keys(errors)) {
					if (errors[type]) {
						for (let name of Object.keys(errors[type])) {
							formattedErrors.push(formatError(type, name, errors[type][name]));
						}
					}
				}
				let model = {
					errors: formattedErrors
				};
				closeModal(items);
				this.cbDialogService.custom(this.locale.getString('common.error'),
					'partials/dashboards/errors-dialog.html', model);
			} else {
				closeModal(items);
			}
		};
	};

	getSharingSearchLimit = (): number => {
		return SharingService.SHARING_SEARCH_LIMIT;
	};

	searchUsersAndGroups = (input: string, groupConfig: GroupsSearchConfig = {}, queryParams?: any): ng.IPromise<any> => {
		let config: SharingEntitiesSearchConfig = {
			simpleOnly: groupConfig.simpleGroupsOnly,
			search: input,
			searchLimit: SharingService.SHARING_SEARCH_LIMIT
		};
		return this.masterAccountApiService.searchSharingEntities(config, queryParams)
			.then(this.getShareEntities);
	};

	loadUsersAndGroups = (entities, action: AssetEditPermissionAction,  groupConfig: GroupsSearchConfig = {}): ng.IPromise<any> => {
		let config: SharingEntitiesConfig = {
			simpleOnly: groupConfig.simpleGroupsOnly
		};

		if (entities) {
			config.userIds = this.getUserIds(entities);
			config.groupIds = this.getGroupIds(entities);
		}

		config.assetEditAction = action;

		return this.masterAccountApiService.getSharingEntities(config)
			.then(this.getShareEntities);
	};

	getWholeOrganizationGroupSharingEntity = (hierarchyId: number) => {
		return this.masterAccountApiService.getWholeOrganizationGroup(hierarchyId).then(wholeOrganizationGroup => {
			if (wholeOrganizationGroup) {
				let sharingEntities: SharingEntities = this.wrapWholeOrganizationGroupInSharingEntities(wholeOrganizationGroup);
				return this.getShareEntities(sharingEntities).groups[0];
			} else {
				return null;
			}
		});
	};

	private wrapWholeOrganizationGroupInSharingEntities = (wholeOrganizationGroup) => {
		return {
			users: [],
			groups: [
				wholeOrganizationGroup
			]
		};
	};

	private getUserIds = (entities): number[] => {
		let ids = entities.shareEntities.USER.map(user => user.entity.userId);
		if (entities.booksMetadata && entities.booksMetadata.USER) {
			ids.pushAll(entities.booksMetadata.USER.map(user => user.sharedTo.userId));
		}
		return ids;
	};

	private getGroupIds = (entities): number[] => {
		let ids = entities.shareEntities.GROUP.map(group => group.entity.groupId);
		if (entities.booksMetadata && entities.booksMetadata.GROUP) {
			ids.pushAll(entities.booksMetadata.GROUP.map(group => group.sharedTo.groupId));
		}
		return ids;
	};

	private getShareEntities = (sharingEntities: SharingEntities): UIShareEntities => {
		let users: UIShareUser[] = _.map(sharingEntities.users, (user) => {
			let userFullName: string = user.userFullName;
			let userEmail: string = user.userEmail;
			let userDisplayName: string =  _.isEmpty(userFullName) ? userEmail : `${userFullName} (${userEmail})`;

			let userEntity: UIShareUser = {
				entity: user,
				type: 'user',
				_name: userEmail,
				displayName: userDisplayName,
				iconType: 'user'
			};
			return userEntity;
		});

		let groups: UIShareGroup[] = _.map(sharingEntities.groups, (group) => {
			let groupEntity: UIShareGroup = {
				entity: group,
				type: 'group',
				_name: group.groupName,
				displayName: group.groupName,
				iconType: 'group'
			};

			if (group.usersCount) {
				groupEntity.usersCount = group.usersCount;
			}

			groupEntity._collapsed = true;
			return groupEntity;
		});

		return {
			users,
			groups,
			detailedSearchRequired: sharingEntities.detailedSearchRequired
		};
	};

	toggleGroup = (group: UIShareGroup): ng.IPromise<void> => {
		if (group._collapsed) {
			return this.populateGroupEntityUsersIfAbsent(group)
				.then(() => { group._collapsed = false; });
		} else {
			group._collapsed = true;
			return this.$q.when();
		}
	};

	private populateGroupEntityUsersIfAbsent = (groupEntity: UIShareGroup): ng.IPromise<any> => {
		if (groupEntity._children) {
			return this.$q.when();
		} else {
			groupEntity._loadingChildren = true;

			return this.masterAccountApiService.getSharingGroupMembers(groupEntity.entity.groupId)
				.then(users => {
					let children: UIShareGroupUser[] = [];
					users.forEach(user => {
						let innerItem: UIShareGroupUser = {
							entity: user,
							type: 'inner-user',
							_name: user.userEmail,
							permission: groupEntity.entity.permission,
							displayName: user.userEmail
						};
						children.push(innerItem);
					});

					groupEntity._children = children;
					groupEntity._loadingChildren = false;
				});
		}
	};

	produceSharingTableContent = (sharedEntities, entityMap): any => {
		let sharingTableContent = this.getSharingTableContent(sharedEntities.shareEntities, entityMap,
			this.sharingItemEntityProducer, this.sharingItemPostprocessor);

		if (sharedEntities.booksMetadata) {
			let booksSharingTableContent = this.getSharingTableContent(sharedEntities.booksMetadata, entityMap,
				this.booksSharingItemEnityProducer, this.booksItemPostprocessor);

			let indirectPublicGroupItem = this.findIndirectPublicGroupItem(sharedEntities.booksMetadata[SharingService.GROUP]);
			if (indirectPublicGroupItem) {
				booksSharingTableContent.groups.push(indirectPublicGroupItem);
			}

			_.extend(sharingTableContent, {
				sharedBookUsers: booksSharingTableContent.users,
				sharedBookGroups: booksSharingTableContent.groups,
				sharedBookMetaGroups: booksSharingTableContent.metaGroups
			});
		}

		return sharingTableContent;
	};

	private getSharingTableContent = (shareEntities, entityMap, entityProducer, entityPostprocessor): SharingTableContent => {
		let users = _.chain(shareEntities[SharingService.USER])
			.map((user) => {
				let entity = entityProducer(user);
				let u = this.getUser(entityMap, entity.userEmail);
				if (!u) // removed user
					return;
				u.displayName = entity.userEmail;
				u.permission = user.permission;
				entityPostprocessor(u, user);
				return u;
			})
			.filter(user =>  user !== undefined)
			.value();

		let groups = _.chain(shareEntities[SharingService.GROUP])
			.map((group) => {
				let entity = entityProducer(group);
				let g = this.getGroup(entityMap, entity.groupName);
				if (!g) // removed group
					return;
				g.permission = group.permission;
				entityPostprocessor(g, group);
				return g;
			})
			.filter(group => group !== undefined)
			.value();

		let metaGroups = _.chain(shareEntities[SharingService.META_GROUP])
			.map((metaGroup) => {
				let entity = entityProducer(metaGroup);
				let g = this.getMetaGroup(entityMap, entity);
				if (!g) // outdated meta group
					return;
				g.permission = metaGroup.permission;
				entityPostprocessor(g, metaGroup);
				return g;
			})
			.filter(group => group !== undefined)
			.value();

		return {
			users,
			groups,
			metaGroups
		};
	};

	private findIndirectPublicGroupItem = (groupItems) => {
		if (!groupItems) {
			return groupItems;
		}
		let publicGroupItems = groupItems.filter(indirectGroupItem =>
			indirectGroupItem.sharedTo.groupId === SharingService.PUBLIC_GROUP_ID
		);

		return publicGroupItems && publicGroupItems[0];
	};

	private sharingItemEntityProducer = (item) => {
		return item.entity;
	};

	private sharingItemPostprocessor = (sharingTableItem) => {
		return sharingTableItem;
	};

	private booksSharingItemEnityProducer = (item) => {
		return item.sharedTo;
	};

	private booksItemPostprocessor = (sharingTableItem, originalItem) => {
		sharingTableItem.sharedTo = originalItem.sharedTo;
		sharingTableItem.bookList = originalItem.bookList;
		return sharingTableItem;
	};

	private getUser = (entityMap, userEmail) => {
		return angular.copy(entityMap['user' + userEmail]);
	};

	private getGroup = (entityMap, groupName) => {
		return angular.copy(entityMap['group' + groupName]);
	};

	private getMetaGroup = (entityMap, metaGroupName) => {
		// legacy conversion for 'PUBLIC' meta group
		if (metaGroupName === 'PUBLIC') {
			return angular.copy(entityMap[SharingService.PUBLIC_STATUS]);
		}

		return angular.copy(entityMap['metaGroup' + metaGroupName]);
	};

	getNewCountMessage = (changedEntities): string => {
		let newShares = _.chain(changedEntities)
			.filter(item => item.action !== 'delete')	// do not count revoked permission
			.groupBy('type')
			.value();
		let newCountMessage = this.generateCountsMessage(newShares, true);
		return this.locale.getString('dashboard.shareNotifyMessage',
			{entities: newCountMessage || this.locale.getString('dashboard.shareNotifyDefault')});
	};

	getCountMessage = (sharedEntities, ownerName): string => {
		let oldShares = _.chain(sharedEntities)
			.filter(item => item._name !== ownerName) // owner is shown, but not counted
			.groupBy('type')
			.value();
		let oldCountMessage = this.generateCountsMessage(oldShares, false);
		return oldCountMessage ? this.locale.getString('dashboard.shareMessage', {entities: oldCountMessage})
			: this.locale.getString('dashboard.notShared');
	};

	getIndirectCountMessage = (numIndirectShares): string => {
		if (numIndirectShares) {
			return this.locale.getString('dashboard.numSharedViaBooks', {numShared: numIndirectShares});
		} else {
			return this.locale.getString('dashboard.notSharedViaBooks');
		}
	};

	private generateCountsMessage = (usersAndGroups, isNewCountMessage): string => {
		let userCount = usersAndGroups.user ? usersAndGroups.user.length : 0;
		let groupCount = usersAndGroups.group ? usersAndGroups.group.length : 0;
		let metaGroupCount = usersAndGroups.metaGroup ? usersAndGroups.metaGroup.length : 0;
		let totalGroupCount = groupCount + metaGroupCount;
		let publicStatus = usersAndGroups.publicStatus;
		let users = userCount + ' ' + this.getMessageForm('dashboard.shareUser', userCount);
		let totalGroups = totalGroupCount + ' ' + this.getMessageForm('dashboard.shareGroup', totalGroupCount);
		if (publicStatus && !isNewCountMessage) {
			return this.locale.getString('dashboard.public');
		}
		if (userCount && totalGroupCount)
			return this.locale.getString('dashboard.shareConnectUserGroup', {users, groups: totalGroups});
		return userCount && users || totalGroupCount && totalGroups || undefined;
	};

	private getMessageForm = (base, count) => {
		return this.locale.getString(base + (count > 1 ? 'Plural' : 'Single'));
	};

	// remove shared items from the list, or mark them as shared
	removeSharedEntities = (allEntities, sharedEntities, flagOnly: boolean = false): void => {
		sharedEntities.forEach((item) => {
			let searchResult: any = _.findWhere(allEntities, {type: item.type, _name: item._name});

			if (searchResult) {
				if (!flagOnly) {
					allEntities.remove(searchResult);
				} else {
					searchResult.shared = true;
				}
			}
		});
	};

	private getShareToUserEntity(shareToUser: SharingUser): any {
		if (!shareToUser)
			return null;
		return {
			entity: shareToUser,
			type: 'user',
			_name: shareToUser.userEmail,
			displayName: shareToUser.userEmail,
			iconType: 'user'
		};
	}

}

app.service('sharingService', SharingService);

export interface SharableAsset {
	name?: string;
	displayName?: string;
	ownerName?: string;
	permissionLevel?: AssetPermission;
	reshareAllowed?: boolean;
	//dashboards
	permissions?: AssetPermission[];
	properties?: {
		preventDashboardEditorsShare?: boolean | string;
	};
}

export interface SharingParams {
	modalTitle: string;
	component: string;
	objectTypePlural: string;
	updateReshare?: (sharingItem: SharableAsset, state: boolean) => void;
	share: (assets: any[], data: any) => ng.IPromise<any>;
	getAsset: (id: number) => ng.IPromise<SharableAsset>;
	clearSelection?: () => void;
	refreshGrid: (changed: SharableAsset[]) => void;
	shareToUser?: SharingUser;
}

interface SharingContext {
	bulkMode: boolean;
	items: SharableAsset[];
	params?: SharingParams;
}

export interface SharingModalResult {
	loading?: {
		init: ng.IPromise<any>;
		save: boolean;
	};
	items: any[];
	sharedEntities: IShareEntity[];
	changedEntities: IShareEntity[];
	preventReshare?: boolean;
}

interface SharingTableContent {
	users: any[];
	groups: any[];
	metaGroups: any[];
}

interface GroupsSearchConfig {
	simpleGroupsOnly?: boolean;
}

interface SharingEntities {
	users: SharingUser[];
	groups: SharingGroup[];
	detailedSearchRequired?: boolean;
}

interface SharingUser {
	userEmail: string;
	userFullName?: string;
}

interface SharingGroupUser {
	userEmail: string;
}

interface SharingGroup {
	groupId: number;
	groupName: string;
	usersCount: number;
	users: SharingGroupUser[];
	permission: string;
}

interface UIShareEntities {
	users: UIShareUser[];
	groups: UIShareGroup[];
	detailedSearchRequired?: boolean;
}

export interface UIShareEntity<T> {
	entity: T;
	type: string;
	_name: string;
	displayName: string;
	iconType?: string;
}

type UIShareUser = UIShareEntity<SharingUser>;

interface UIShareGroupUser extends UIShareEntity<SharingGroupUser> {
	permission: string;
}

export interface UIShareGroup extends UIShareEntity<SharingGroup> {
	usersCount?: number;
	_children?: UIShareGroupUser[];
	_collapsed?: boolean;
	_loadingChildren?: boolean;
}
