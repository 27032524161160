import { Injectable } from '@angular/core';
import { MathAggregation, MathKeyword } from '../tokenizer/custom-math-tokenizer.service';



@Injectable({
	providedIn: 'root'
})
export class CustomMathKeywordHelper {

	readonly METRICS_AND_AGGREGATIONS_KEYWORDS: string[] = [].concat(Object.values(MathKeyword)).concat(Object.values(MathAggregation));

	readonly ESCAPE_CHARACTER = '\\';

	isKeywordBefore = (text: string, caretPosition: number): boolean => {
		let isKeywordBefore = false;
		for (let keyword of this.METRICS_AND_AGGREGATIONS_KEYWORDS) {
			let keywordLength = keyword.length;
			if (keywordLength <= caretPosition) {
				let keywordStartIndex = caretPosition - keywordLength;
				let leadingText = text.slice(keywordStartIndex, caretPosition);
				if (keyword === leadingText && !this.isInsideOuterSquareBrackets(text, keywordStartIndex)) {
					isKeywordBefore = true;
					break;
				}
			}
		}
		return isKeywordBefore;
	};

	private isInsideOuterSquareBrackets = (text: string, keywordStartsAtIndex: number): boolean => {
		if (keywordStartsAtIndex === 0) {
			return false;
		}

		let textBeforeKeyword: string = text.slice(0, keywordStartsAtIndex);

		let previousKeywordContentStartsFromIndex: number = this.findPreviousKeywordContentStartsFromIndex(textBeforeKeyword);
		let previousNotEscapedOpenBracket: number = this.findPreviousNotEscapedKeywordBracket(textBeforeKeyword, '[');
		let previousNotEscapedClosingBracket: number = this.findPreviousNotEscapedKeywordBracket(textBeforeKeyword, ']');

		return this.isInsideKeywordBrackets(previousKeywordContentStartsFromIndex, previousNotEscapedClosingBracket)
			|| this.isInsideSystemBrackets(previousNotEscapedOpenBracket, previousNotEscapedClosingBracket);
	};

	private isInsideKeywordBrackets = (previousKeywordContentStartsFromIndex: number, previousNotEscapedClosingBracket: number): boolean => {
		return previousKeywordContentStartsFromIndex !== -1 && previousKeywordContentStartsFromIndex > previousNotEscapedClosingBracket;
	};

	private isInsideSystemBrackets = (previousNotEscapedOpenBracket: number, previousNotEscapedClosingBracket: number): boolean => {
		return previousNotEscapedOpenBracket !== -1 && previousNotEscapedOpenBracket > previousNotEscapedClosingBracket;
	};

	private findPreviousKeywordContentStartsFromIndex = (textBeforeKeyword: string): number => {
		let previousKeywordStartsFromIndex: number = -1;
		let previousKeywordWithOpenBracketLength: number = -1;

		for (let keyword of this.METRICS_AND_AGGREGATIONS_KEYWORDS) {
			let textToSearch: string =  keyword + '[';
			if (textToSearch.length <= textBeforeKeyword.length) {
				let lastIndexOfLeadingKeyword: number = textBeforeKeyword.lastIndexOf(textToSearch);
				if (lastIndexOfLeadingKeyword > previousKeywordStartsFromIndex) {
					previousKeywordStartsFromIndex = lastIndexOfLeadingKeyword;
					previousKeywordWithOpenBracketLength = textToSearch.length;
				}
			}
		}

		return previousKeywordStartsFromIndex !== -1 ? previousKeywordStartsFromIndex + previousKeywordWithOpenBracketLength : -1;
	};

	private findPreviousNotEscapedKeywordBracket = (textBeforeKeyword: string, bracket: string): number => {
		let lastIndexOfClosingBracket = textBeforeKeyword.lastIndexOf(bracket);
		if (lastIndexOfClosingBracket === -1 || lastIndexOfClosingBracket === 0) {
			return lastIndexOfClosingBracket;
		}

		let previousCharacterIndex = lastIndexOfClosingBracket - 1;
		let previousCharacter: string = textBeforeKeyword.slice(previousCharacterIndex, lastIndexOfClosingBracket);
		if (previousCharacter !== this.ESCAPE_CHARACTER) {
			return lastIndexOfClosingBracket;
		}

		if (previousCharacterIndex > 0) {
			return this.findPreviousNotEscapedKeywordBracket(textBeforeKeyword.slice(0, previousCharacterIndex), bracket);
		}	
	};

	nextCharIsClosingBracket = (text: string, caretPosition: number): boolean => {
		return text.length > caretPosition && text.slice(caretPosition, caretPosition + 1) === ']';
	};

	addBrackets = (text: string, caretPosition: number): string => {
		return text.slice(0, caretPosition) + '[]' + text.slice(caretPosition, text.length);
	};

}