import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { AdminWorkspace } from '@app/modules/system-administration/platform/workspace/admin-workspace';
import { CacheOptions } from '@cxstudio/common/cache-options';

@Injectable({
	providedIn: 'root'
})
export default class WorkspaceManagementApiService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	getWorkspaces = (): Promise<AdminWorkspace[]> => {
		let config = {
			cache: CacheOptions.NOT_CACHED
		};

		return this.cxHttp.get('rest/system-admin/workspaces', config);
	};

	updateWorkspace = (workspace: AdminWorkspace): Promise<void> => {
		return this.cxHttp.put(`rest/system-admin/workspaces/${workspace.id}`, workspace);
	};

	createWorkspace = (workspace: AdminWorkspace): Promise<AdminWorkspace> => {
		return this.cxHttp.post(`rest/system-admin/workspaces`, workspace);
	};

	deleteWorkspace = (workspaceId: number): Promise<void> => {
		return this.cxHttp.delete(`rest/system-admin/workspaces/${workspaceId}`);
	};

	isExist = (contentProviderId: number, accountId: number): Promise<boolean> => {
		return this.cxHttp.get(`rest/system-admin/workspaces/content-provider/${contentProviderId}/account/${accountId}`);
	};
}
