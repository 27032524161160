import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface SecurityAuditDetailsInput {
	eventName: string;
	details: string;
}

@Component({
	selector: 'security-audit-details',
	template: `
		<modal-header
			(cancel)="cancel()"
			[modalTitle]="getModalTitle()">
		</modal-header>

		<div class="audit-details-panel modal-body">
 			{{input.details}}
		</div>

		<ok-modal-footer
			(cancel)="cancel()"
			[okText]="'common.close' | i18n">
		</ok-modal-footer>
		`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityAuditDetailsComponent {
	@Input('input') input: SecurityAuditDetailsInput;

	constructor(
		public activeModal: NgbActiveModal,
		private locale: CxLocaleService
	) {
	}

	cancel(): void {
		this.activeModal.dismiss('cancel');
	}

	getModalTitle = (): string => {
		return this.input.eventName + ' ' + this.locale.getString('mAccount.details');
	};

}
