import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import { OAuthAuthorizedGrantTypes } from '../oauth-authorized-grant-type.factory';
import { OAuthAuthorityRoles } from '../oauth-authority-roles.factory';
import { OAuthClientDetailsEditDialogComponent } from '../oauth-client-details-edit-dialog.component';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';
import { CxLocaleService } from '@app/core';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';


@Component({
	selector: 'oauth-suite-client-details-edit-dialog',
	templateUrl: '../oauth-client-details-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthSuiteClientDetailsEditDialogComponent extends OAuthClientDetailsEditDialogComponent {

	private legacySuiteApplications: ExternalApplication[];
	supportDesignerScope = true;

	constructor(
		protected modal: NgbActiveModal,
		protected ref: ChangeDetectorRef,
		private externalApplicationsApiService: ExternalApplicationsApiService,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected oauthAuthorityRoles: OAuthAuthorityRoles,
		locale: CxLocaleService
	) {
		super(modal, ref, oauthAuthorizedGrantTypes, oauthAuthorityRoles, locale, OauthClientDetailsApplicationKind.CX_SUITE);
	}

	protected init(): void {
		this.registeredRedirectUri = this.clientDetails.registeredRedirectUri;
		this.oauthScopes = _.map(this.clientDetails.scope, scope => scope.name);

		this.initGrantTypes();
		this.initAuthorities();
		this.loading = this.loadLegacySuiteApplications();
	}

	loadLegacySuiteApplications = (): Promise<any> => {
		return this.externalApplicationsApiService.getLegacySuiteApplications().then(apps => this.legacySuiteApplications = apps);
	};

	isLegacySuiteApplication = (): boolean => {
		if (!this.legacySuiteApplications) {
			return false;
		}
		let legacySuiteClientIds = this.legacySuiteApplications.map(app => app.suiteClientId).filter(clientId => !!clientId);
		return legacySuiteClientIds.contains(this.clientDetails.clientId);
	};

	save = (): void => {
		this.saveCommon();
		this.cleanupRegisteredUrls();
		this.modal.close(this.clientDetails);
	};

	private cleanupRegisteredUrls = (): void => {
		// URL is not mandatory. This is to avoid sending empty property.
		if (_.isEmpty(this.registeredRedirectUri)) {
			delete this.clientDetails.registeredRedirectUri;
		} else {
			this.clientDetails.registeredRedirectUri = this.registeredRedirectUri;
		}
	};

	disableSave = (): boolean => {
		return this.disableSaveCommon() || !this.unifiedLinksValid;
	};

	scopeSelected = (): boolean => {
		// allow empty scopes for suite clients (effectively meaning any scope is acceptable)
		return true;
	};

}
