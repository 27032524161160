import * as _ from 'underscore';
import * as moment from 'moment';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import PopoverUtils from '@cxstudio/services/popover-utils.service';
import { DatePointMode, IPointValue } from '@cxstudio/reports/settings/date-point-constants';
import { Component, EventEmitter, Input, Output, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbDatepicker, NgbDateNativeAdapter } from '@ng-bootstrap/ng-bootstrap';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';
import { IDatePeriod, DatePeriodConstantsService } from '@app/modules/utils/dates/date-period-constants.service';

@Component({
	selector: 'date-point',
	templateUrl: './date-point.component.html',
	providers: [{provide: NgbDateAdapter, useClass: NgbDateNativeAdapter}]
})

export class DatePointComponent implements OnInit {

	@Input() pointMode: DatePointMode;
	@Input() pointValue: IPointValue; // timezone in date string is ignored if this.timezone is not null
	@Input() timezone: string; // if null, use user's timezone
	@Input() modes: DatePointMode[];
	@Input() autoClose: boolean;
	@Input() appendToBody: boolean;
	@Input() label: string;
	@Input() popupId: string; // unique id to manage auto-close
	@Input() datepickerOptions: {
		minDate: Date;
		maxDate: Date;
	};
	@Input() selectorHidden: boolean; // hide mode selector
	@Input() adjustTime: 'start' | 'end'; // to adjust "Today" to start or end of day
	@Input() hideTime: boolean;
	@Input() disableDate: boolean;
	@Output() onChange: EventEmitter<{mode: DatePointMode; value: IPointValue}> = new EventEmitter();

	@ViewChild('minutesInput', {static: false}) minutesInput: ElementRef;
	@ViewChild('hoursInput', {static: false}) hoursInput: ElementRef;
	@ViewChild('amountInput', {static: false}) amountInput: ElementRef;
	@ViewChild('pickerToggle', {static: false}) pickerToggle: ElementRef;
	@ViewChild('popup', {static: false}) popup: ElementRef;
	@ViewChild(NgbDatepicker, {static: false}) private picker: NgbDatepicker;

	customDateLabel: {
		displayName?: string;
		tooltipText?: string;
	};
	showCalendar: boolean;
	modesArray: Array<{value: DatePointMode; displayName: string}>;
	selectedMode: {value: DatePointMode; displayName: string};
	periods: IDatePeriod[];
	selectedPeriod: IDatePeriod;
	customDate: any;
	pickerDate: NgbDateStruct;

	constructor(
		private adapter: NgbDateAdapter<Date>,
		private locale: CxLocaleService,
		private datePeriodConstants: DatePeriodConstantsService,
		@Inject('dateFilterService') private dateFilterService: DateFilterService,
		@Inject('popoverUtils') private popoverUtils: PopoverUtils,
	) {}

	ngOnInit(): void {
		if (!this.popupId)
			this.popupId = 'dateCustomPicker';

		this.customDateLabel = {};

		this.showCalendar = false;

		if (this.pointMode === DatePointMode.CUSTOM) {
			this.initializeCustomDate();
		}

		this.modesArray = _.map(this.modes, (mode) => {
			let label = mode === DatePointMode.CUSTOM ? 'exactDate' : mode;
			return {
				value: mode,
				displayName: this.locale.getString('dateRange.' + label)
			};
		});
		this.selectedMode = _.find(this.modesArray, {value: this.pointMode});

		this.periods = this.datePeriodConstants.getPeriods();
		this.selectedPeriod = _.find(this.periods, {value: this.pointValue.period});
	}

	private filterChangedCallback(): void {
		this.onChange.emit({
			mode: this.pointMode,
			value: this.pointValue
		});
	}

	showCalendarPopup = ($event: Event) => {
		if (this.appendToBody) {
			let popup = $('#' + this.popupId);

			let button = $event.currentTarget as Element;
			let buttonRect = button.getBoundingClientRect();
			let dropdown = $(button).closest('.dropdown-menu')[0];
			let dropdownRect = dropdown.getBoundingClientRect();

			popup.css('position', 'fixed');
			popup.css('left', (buttonRect.left - dropdownRect.left) + 'px');
			popup.css('top', (buttonRect.bottom - dropdownRect.top) + 'px');
		}

		this.showCalendar = true;

		if (this.autoClose)
			this.popoverUtils.initOutsideClickHandler('#' + this.popupId, this.getClickHandlerName, this.saveDate);
		this.updatePickerValue();
	};

	closeCalendarPopup(): void {
		this.showCalendar = false;
		$(document).off(this.getClickHandlerName());
	}

	focusToggle = (event: KeyboardEvent): void => {
		KeyboardUtils.intercept(event);
		this.pickerToggle.nativeElement.focus();
	};

	focusPicker = (): void => {
		setTimeout(() => $(this.popup.nativeElement).find(':focusable').first().trigger('focus'));
	};

	private getClickHandlerName = (): string => {
		return 'click.' + this.popupId;
	};

	private initializeCustomDate(): void {
		if (!this.pointValue.date) {
			this.pointValue.date = this.getCurrentTime();
		}
		this.updateCustomDisplayName();
		this.customDate = this.getCustomDate(this.pointValue.date);
		this.updatePickerValue();
	}

	private updatePickerValue = (): void => {
		if (!this.picker) {
			return;
		}
		//only use date, ignore time and timezone
		let date = moment.parseZone(this.customDate.date).toObject();
		let ngbDate = this.fromModel(new Date(date.years, date.months, date.date));
		this.picker.navigateTo(ngbDate);
		this.picker.focusDate(ngbDate);
		this.picker.focusSelect();
	};

	isNeedCalendar = () => {
		return this.pointMode === DatePointMode.CUSTOM;
	};

	setAsToday = () => {
		this.customDate = this.getCustomDate(this.getCurrentTime());
		this.updatePickerValue();
	};

	saveDate = () => {
		this.updateDate();
		this.closeCalendarPopup();
		this.updateCustomDisplayName();
		this.filterChangedCallback();
	};

	private updateDate(): void {
		//fromDate get date yyyy mm dd info, and replace with hh mm in fromTime
		let newDate = moment.parseZone(this.customDate.date).toObject();
		newDate.hours = this.convertHoursBack(
			this.customDate.time.hours,
			this.customDate.time.amPm
		);
		newDate.minutes = this.customDate.time.minutes;
		this.pointValue.date = moment(newDate).format();
	}

	private updateCustomDisplayName(): void {
		if (this.isNeedCalendar() && this.pointValue.date) {
			let withTimezone = true;
			if (this.hideTime) {
				let format = DateTimeFormat.BASIC_DATE;
				let hideUtcTimeSuffix = true;
				this.customDateLabel.displayName = this.dateFilterService.formatDatePoint(
					this.pointValue.date, this.timezone, !withTimezone, format, hideUtcTimeSuffix);
				this.customDateLabel.tooltipText = this.customDateLabel.displayName;
			} else {
				this.customDateLabel.displayName = this.dateFilterService.formatDatePoint(this.pointValue.date, this.timezone, !withTimezone);
				this.customDateLabel.tooltipText = this.dateFilterService.formatDatePoint(this.pointValue.date, this.timezone, withTimezone);
			}
		} else {
			this.customDateLabel.displayName = this.locale.getString('dateRange.custom');
			this.customDateLabel.tooltipText = undefined;
		}
	}

	private convertHours(hours): number {
		if (hours >= 13) {
			return hours - 12;
		}
		if (hours < 1) {
			return 12;
		}
		return hours;
	}

	private convertHoursBack(hours, amPm): number {
		if (amPm === 'AM') {
			return hours === 12 ? 0 : hours;
		}
		return hours === 12 ? 12 : hours + 12;
	}

	private getCustomDate(pointDate): any {
		// convert to local timezone if project tz not specified
		let date = this.timezone ? pointDate : moment(pointDate).format();
		let time = moment.parseZone(date).toObject() as any;
		let customDate = {
			date, //used for date info: yyyy mm dd
			time //used for hours and min: hh mm.
		};

		//handle 24 -> 12 formats
		customDate.time.amPm = customDate.time.hours > 11 ? 'PM' : 'AM';
		customDate.time.hours = this.convertHours(customDate.time.hours);

		return customDate;
	}

	private getCurrentTime(): string {
		if (this.adjustTime === 'start')
			return moment().startOf('day').format();
		if (this.adjustTime === 'end')
			return moment().endOf('day').format();
		return moment().format();
	}

	isTodayEnabled = () => {
		if (!this.datepickerOptions)
			return true;
		if (this.datepickerOptions.minDate)
			return moment(this.getCurrentTime()).isAfter(moment(this.datepickerOptions.minDate));
		if (this.datepickerOptions.maxDate)
			return moment(this.getCurrentTime()).isBefore(moment(this.datepickerOptions.maxDate));
		return true;
	};

	selectMode = (mode) => {
		this.pointMode = mode.value;
		this.selectedMode = mode;
		this.filterChangedCallback();
	};

	selectPeriod = (period) => {
		this.selectedPeriod = period;
		this.pointValue.period = period.value;
		this.filterChangedCallback();
	};

	onAmountChange = (amount: number, blur?: boolean) => {
		if ((!blur && amount === null) || (blur && !this.amountInput.nativeElement.validity.badInput)) {
			return;
		}
		this.pointValue.amount = this.limitValue(amount, -367, 367);
		this.amountInput.nativeElement.value = this.pointValue.amount;
		this.filterChangedCallback();
	};

	hoursChange = (val: number) => {
		this.customDate.time.hours = this.limitValue(val, 1, 12);
		this.hoursInput.nativeElement.value = this.customDate.time.hours;
	};

	minutesChange = (val: number) => {
		this.customDate.time.minutes = this.limitValue(val, 0, 59);
		this.minutesInput.nativeElement.value = this.customDate.time.minutes;
	};

	dateChange = (date: NgbDateStruct) => {
		this.customDate.date = moment(this.adapter.toModel(date)).format();
		this.updateDate();
		this.updateCustomDisplayName();
	};

	getSelectedMode = () => {
		return _.findWhere(this.modesArray, {value: this.pointMode});
	};

	getSelectedPeriod = () => {
		return _.findWhere(this.periods, {value: this.pointValue.period});
	};

	fromModel = (date: string | Date): NgbDateStruct => {
		return this.adapter.fromModel(new Date(date));
	};

	private limitValue = (value: number, min: number, max: number): number => {
		let val = Number(value);
		if (isNaN(val) || val < min) {
			return min;
		}
		if (val > max) {
			return max;
		}
		return val;
	};
}

app.directive('datePoint', downgradeComponent({ component: DatePointComponent }));
