import { Pagination } from '@app/shared/components/pagination/pagination';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { PagedResponse } from '@cxstudio/paged-response';
import { SocialCaseLogItem } from './social-case-log-item';
import { SocialCasesLogTableColumnsService } from './social-cases-log-table-columns.service';
import { SocialCaseLogApi } from './social-case-log.api.service';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';

@Component({
	selector: 'social-cases-log-tab',
	templateUrl: './social-cases-log-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SocialCasesLogTabComponent implements OnInit {
	busy: Promise<PagedResponse<SocialCaseLogItem>>;
	columns: Array<TableColumn<any>>;
	rows: any[];
	pagination: Pagination;
	filters: ObjectListFilter[];

	constructor(
		private socialCaseLogApi: SocialCaseLogApi,
		private socialCasesLogTableColumns: SocialCasesLogTableColumnsService
	) {
	}

	ngOnInit(): void {
		this.pagination = new Pagination(20);
		this.columns = this.socialCasesLogTableColumns.getColumns();
		this.filters = [];
		this.reload();
	}

	reload = (): void => {
		this.busy = this.socialCaseLogApi.getSocialCasesLog(this.pagination, this.filters);
		this.busy.then((response) => {
			this.rows = response.data;
			this.pagination.totalItems = response.totalCount;
		});
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reload();
	};

	applyFilters = (filters: ObjectListFilter[]): void => {
		this.filters = filters;
		this.reload();
	};
}

app.directive('socialCasesLogTab', downgradeComponent({ component: SocialCasesLogTabComponent }));
