import { MetricCalculation } from '@cxstudio/metrics/entities/metric-calculation';
import { MetricType } from '@app/modules/metric/entities/metric-type';

export abstract class MetricDefinition {
	type: MetricType;
	thresholds: number[];
	colorPalette: string[];
	displayNames: string[];
	calculation: MetricCalculation;
	min?: number;
	max?: number;
	hexColors?: string[];

	protected constructor(type: MetricType) {
		this.type = type;
	}
}
