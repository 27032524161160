import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';

/**
 * This service should not be used in NG code. Change to `toastService.addToast(...)` when converted
 * */
@Injectable()
export class DowngradeToastService {

	constructor(
		private readonly toastService: ToastService,
	) { }

	addToast(message: string, type = AlertLevel.CONFIRM, html?: boolean): void {
		this.toastService.addToast(message, type, html);
	}
}

app.service('downgradeToastService', downgradeInjectable(DowngradeToastService));
