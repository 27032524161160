import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import { DashboardRunService } from '@app/modules/dashboard/dashboard-run.service';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';

export class ReportDataApiService {
	constructor(
		private readonly $http,
		private readonly httpHandlers: HttpHandlers,
		private readonly widgetTimingService: WidgetTimingService,
		private readonly dashboardRunService: DashboardRunService,
		private readonly dashboardRunHelperService: DashboardRunHelperService
	) {}

	getANPreviewData = (originalSettings) => this.getReportData(originalSettings);

	getReportData = (runSettings, canceler?) => {
		if (!runSettings.firstPass) {
			this.dashboardRunHelperService.setRunStatus(runSettings.dashboardId, DashboardRunStatus.INTERRUPTED_INTERACTION);
		}

		let config: any = {
			headers: this.dashboardRunService.getHeadersObjectForReports(runSettings.dashboardId, runSettings.dashboardRunTimestamp),
			local: true
		};

		if (canceler) {
			config.timeout = canceler.promise;
		}
		let request = this.$http.post('rest/common/reportData', runSettings, config);
		this.widgetTimingService.startDataLoading(runSettings);
		return this.calculateTime(request, this.addTimeToMetadataFunc(runSettings))
			.then(this.httpHandlers.success, this.httpHandlers.error);
	};

	private addTimeToMetadataFunc(widget): (...args) => any {
		return (response, time) => {
			let reportData = response.data;
			if (reportData.metadata) {
				reportData.metadata.frontendExecutionTime = time;
				if (response.headers) {
					reportData.metadata.studioTotalTime = response.headers().request_total_time;
				}
			}
			this.widgetTimingService.finishDataLoading(widget, reportData.metadata);
			return response;
		};
	}

	private calculateTime(promise, callback): any {
		let start = Date.now();
		return promise.then((result) => {
			let time = Date.now() - start;
			return callback(result, time);
		});
	}

	getAnSentenceStatistics = (runWidgetSettings) => {
		runWidgetSettings.properties.widgetType = 'cb_an_doc_count';
		runWidgetSettings.name = 'doc_count';
		return this.getReportData(runWidgetSettings);
	};

	getAnSentenceData = (runWidgetSettings, canceler) => {
		return this.getReportData(runWidgetSettings, canceler);
	};

	getAnDocumentData = (runWidgetSettings, canceler) => {
		// for preview 2.0
		runWidgetSettings.properties.widgetType = 'cb_an_document';
		delete runWidgetSettings.properties.previewMode;
		return this.getReportData(runWidgetSettings, canceler);
	};

	loadSingleTweetFromTwitter = (tweetId) => {
		let config = {
			params: { tweetId }
		};

		return this.$http.get('rest/external/twitter/tweet', config);
	};

	loadTweetsFromTwitter = (tweetIds) => this.$http.post('rest/external/twitter/tweets', angular.toJson(tweetIds));

	getTimeGroupingStatistics = (runWidgetSettings) => {
		return this.$http.post('rest/common/time-grouping-statistics', runWidgetSettings)
			.then(this.httpHandlers.success, this.httpHandlers.error);
	};
}

app.service('reportDataApiService', ReportDataApiService);
