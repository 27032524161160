/**
 * Directive for right click menu
 */
app.directive('ngRightClick', ($compile, $parse, $timeout) => {
	return {
		restrict: 'A',
		controller: [
			'$scope',
			($scope) => {
				$scope.removeRightClickMenu = (e) => {
					if (e !== undefined) {
						e.stopPropagation();
						e.preventDefault();
					}

					let ul = document.querySelector('.ng-right-click-ul');
					if (!isEmpty(ul)) {
						ul.parentNode.removeChild(ul);
					}
				};
			}
		],
		link: ($scope, element, attrs) => {
			// Reference to the container element
			let $this = angular.element(element)[0];
			$scope.$menuItems = $scope.$eval(attrs.menuItems);

			element.bind('contextmenu', (event) => {
				$scope.$apply(() => {

					let functionResult;
					if (attrs.ngRightClick !== undefined) {
						event.preventDefault();
						let fn = $parse(attrs.ngRightClick);
						functionResult = fn($scope, {$event: event});
					}

					//set the position to right-clicked parent as relative, so that if there is context menu, that becomes a child
					if (!($($this).hasClass('br-widget-header') || $($this).hasClass('br-widget-content'))) {
						($this as HTMLElement).style.position = 'relative';
					}

					let skipExecution = document.querySelector('.ng-right-click-ul') !== null ||
						(functionResult !== undefined && functionResult === false);

					if (skipExecution) {
						$scope.removeRightClickMenu();
						return;
					}

					//If user has provided right-click menu, render it
					if ($scope.$menuItems !== undefined) {
						//create a menu structure
						// eslint-disable-next-line max-len
						let menuItemsUL = angular.element('<ul class=\'ng-right-click-ul\' style=\'visibility:hidden\' ng-mouseleave=\'removeRightClickMenu($event)\'></ul>');

						//add custom class if supplied
						if (attrs.menuClass !== undefined) {
							menuItemsUL.addClass(attrs.menuClass);
						}

						//create list elements from supplied list items
						// eslint-disable-next-line max-len
						let menuItemsLI = angular.element('<li ng-repeat="item in $menuItems" ng-click="item.callback();removeRightClickMenu($event)">{{item.text}}</li>');

						//Push LI element to UL menu.
						menuItemsUL.append(menuItemsLI);

						$timeout(() => {
							//Fixed the Position of Context Menu
							(menuItemsUL[0] as HTMLElement).style.left = `${event.clientX - 10}px`;
							(menuItemsUL[0] as HTMLElement).style.top = `${event.clientY - 2}px`;
							(menuItemsUL[0] as HTMLElement).style.position = 'fixed';
							(menuItemsUL[0] as HTMLElement).style.display = 'block';
							(menuItemsUL[0] as HTMLElement).style.width = '70px';

							//show the element now that its width and height are properly calculated.
							(menuItemsUL[0] as HTMLElement).style.visibility = 'visible';

							//Finally compile and make it angular friendly!
							$compile(element.contents())($scope);
						}, 10);

						element.append(menuItemsUL);
					}
				});
			});
		}
	};
});
