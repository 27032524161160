import * as _ from 'underscore';
import { User } from '../users/entities/user';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { EngagorApiService } from '@cxstudio/services/data-services/engagor-api.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { DesignerAlertType } from '@cxstudio/alerts/designer/designer-alert-type';
import Group from './Group';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { AlertSubscription } from '@app/modules/user-administration/groups/alert-subscription/alert-subscription';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { Alert } from '@app/modules/user-administration/groups/alert-subscription/alert.class';
import { IAssetAccess } from '@app/modules/access-management/groups/asset-access';
import { SubscriptionsService } from '@app/modules/user-administration/groups/alert-subscription/subscriptions.service';
import { ProjectAssetsErrors } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { GroupDialogMode } from '@app/modules/user-administration/groups/group-dialog-mode';

enum GroupAction {
	ADD = 'add',
	EDIT = 'edit',
	PERMISSION = 'permission',
	MEMBER = 'member',
}

interface IGroupEditDialogParams {
	groupItem;
	permissionItems;
	action: GroupAction;
}

export class GroupEditModalController extends ModalBindings<IGroupEditDialogParams> {
	projectErrors: ProjectAssetsErrors = {};
	isWorkspaceEnabled: boolean;
	readonly UNDEFINED_ASSIGNEE: User = {
		firstName: this.locale.getString('cases.unassigned'),
		lastName: '',
	};

	private readonly OWNER_SEARCH_LIMIT = 10;

	group: Group;
	groupItem;
	users: {
		added: User[];
		removed: User[];
	};

	owners: {
		available: User[];
		search: string;
	};

	editGroup: boolean;
	addGroup: boolean;
	mode: GroupDialogMode;

	loading;
	subscriptionsTabLoading;
	options: {
		permissions: any[];
	};
	forms: {
		nameDialog;
	};

	asyncNameValidationPromise;

	alerts;
	userAlerts: AlertSubscription[];
	alertErrors;
	alertWarnings;

	alertsPager;
	alertSubscriptionQuery;
	filteredSubscriptions: AlertSubscription[];

	defaultTopicId;
	socialTopics;
	socialTopicsError;
	socialErrors;
	hierarchies;

	assetsAccess: IAssetAccess[];

	activeTabIndex: number;

	constructor(
		private subscriptionsService: SubscriptionsService,
		private locale: ILocale,
		private cbDialogService: CBDialogService,
		private engagorApiService: EngagorApiService,
		private usersGroupsApiService,
		private masterAccountApiService: MasterAccountApiService,
		private organizationApiService: OrganizationApiService,
		private security: Security,
		private betaFeaturesService: BetaFeaturesService,
	) {
		super();
	}

	$onInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.groupItem = this.resolve.groupItem;
		this.group = {
			groupId: this.groupItem.groupId,
			groupName: this.groupItem.groupName,
			description: this.groupItem.description,
			permission: this.groupItem.permission,
			users: this.groupItem.users,
			usersCount: this.groupItem.usersCount,
			type: this.groupItem.type || GroupType.SIMPLE,
			ownerEmail: this.groupItem.ownerEmail,
			owner: {
				userEmail: this.groupItem.ownerEmail
			}
		};
		this.users = {
			added: [],
			removed: [],
		};

		this.options = {
			permissions: this.resolve.permissionItems
		};

		this.owners = {
			available: [],
			search: this.group.ownerEmail || ''
		};

		if (this.groupItem.ownerEmail) {
			this.owners.available.push({ userEmail: this.groupItem.ownerEmail });
		}

		switch (this.resolve.action) {
			case 'edit':
				this.editGroup = true;
				this.addGroup = false;
				this.mode = GroupDialogMode.EDIT;
				break;
			case 'add':
				this.editGroup = false;
				this.addGroup = true;
				this.mode = GroupDialogMode.ADD;
				this.assetsAccess = [];
				break;
			case 'permission':
				this.editGroup = true;
				this.addGroup = false;
				this.mode = GroupDialogMode.EDIT;
				this.activeTabIndex = 1;
				break;
			case 'member':
				this.editGroup = true;
				this.addGroup = false;
				this.mode = GroupDialogMode.EDIT;
				this.activeTabIndex = 2;
				break;
			}

		this.group.permissions = this.groupItem.masterAccountPermissions || [];

		this.alerts = {};

		this.userAlerts = [];
		this.alertErrors = [];
		this.alertWarnings = [];
		this.socialErrors = [];

		this.loading = {};

		if (this.isSocialIntegrationEnabled()) {
			this.initSocialSettings();
		}

		this.alertsPager = {
			currentPage: 1,
			totalItems: 0,
			maxSize: 10,
			perPage: 5
		};

		this.alertSubscriptionQuery = '';
	}

	private initSocialSettings = (): void => {
		this.masterAccountApiService.getEngagorSettings().then((response: any) => {
			this.defaultTopicId = response.topicId;
		});

		this.loading.socialTopics = this.engagorApiService.getTopics().then((socialTopics) => {
			this.socialTopics = socialTopics;
		}, this.checkTopicsForError);
	};

	private checkTopicsForError = (response) => {
		if (response && response.data) {
			//some error, show message and disable streams
			this.socialTopicsError = true;
			this.socialErrors.push(response.data);
		}
	};

	canManageGroups = (): boolean => {
		return this.security.has('manage_groups');
	};

	isGroupOwner = (): boolean => {
		return this.groupItem.ownerEmail === this.security.getEmail();
	};

	isOwnerView = (): boolean => {
		return !this.canManageGroups() && this.isGroupOwner();
	};

	hasContentProvider = (): boolean => {
		return this.security.has('content_provider');
	};

	getOwnerCandidates = (search): ng.IPromise<User[]> => {
		return this.usersGroupsApiService.searchGroupOwnerCandidates(search, this.OWNER_SEARCH_LIMIT)
			.then((candidates: User[]) => {
				candidates.forEach(this.addOwnerCandidate);
				return candidates;
			});
	};

	private addOwnerCandidate = (candidate: User): void => {
		if (!_.findWhere(this.owners.available, { userEmail: candidate.userEmail })) {
			this.owners.available.push(candidate);
		}
	};

	setGroupOwner = (): void => {
		let email = this.owners.search;
		if (this.validateOwner(email)) {
			this.group.ownerEmail = email ? email : null;
		}
	};

	private validateOwner = (email: string): boolean => {
		// empty group owner field should be allowed
		let correctOwner = !email || !!_.findWhere(this.owners.available, { userEmail: email });
		this.forms.nameDialog.groupOwner.$setValidity('valid', correctOwner);
		return correctOwner;
	};

	isHierarchyGroup = (): boolean => {
		return this.groupItem.type === GroupType.HIERARCHY
			|| this.groupItem.type === GroupType.WHOLE_HIERARCHY;
	};

	onAlertSearchChange = (alertSubscriptionQuery): void => {
		this.alertSubscriptionQuery = alertSubscriptionQuery;
		this.filteredSubscriptions = _.filter(this.userAlerts, (subscription: AlertSubscription) => {
			let alert: Alert = subscription.alert || {} as any;
			return this.containsSubstring(alert.alertName, alertSubscriptionQuery);
		});
		this.alertsPager.totalItems = this.filteredSubscriptions.length;
	};

	private containsSubstring = (target, search): boolean => {
		return (target || '').toLowerCase().indexOf((search || '').toLowerCase()) >= 0;
	};

	alertPageChanged = (currentPage): void => {
		this.alertsPager.currentPage = currentPage;
	};

	populateUserAlerts = (alertsWithSubscriptions): void => {
		// fill in display fields
		alertsWithSubscriptions.forEach((alertWithSubscription) => {
			if (alertWithSubscription && alertWithSubscription.alert) {
				let alert = alertWithSubscription.alert;
				if (alert.type === DesignerAlertType.NEW_VERBATIM) {
					alert.typeName =
						this.locale.getString('alert.newVerbatim');
					alert.modelName =
						alert.modelName || this.locale.getString('alert.projectWideModel');
				}
				if (alert.type === DesignerAlertType.SCORECARD) {
					alert.typeName =
						this.locale.getString('alert.scorecard');
					alert.modelName = alert.modelName;
				}
			}
		});

		this.userAlerts = alertsWithSubscriptions;
		this.filteredSubscriptions = alertsWithSubscriptions;
		this.alertsPager.totalItems = alertsWithSubscriptions.length;
		this.getHierarchies();
	};

	updateSubscriptionsTabLoading = (loading): void => {
		this.subscriptionsTabLoading = loading;
	};

	private getHierarchies = (): void => {
		this.hierarchies = [];
		this.getHierarchyNames().then((hierarchies) => {
			this.hierarchies = hierarchies;
		});
	};

	private getHierarchyNames = (): any => {
		return this.organizationApiService.getOrganizationList()
			.then((resp) => {
				return _.map(resp.data, (organization) => {
					return _.pick(organization, 'id', 'name');
				});
			});
	};

	private convertAlertsToSubscriptions = (masterAccountAlerts): void => {
		let alertsWithSubscriptions = [];
		masterAccountAlerts.forEach((masterAccountAlert) => {
			alertsWithSubscriptions.push({ alert: masterAccountAlert, subscribed: false });
		});

		if (this.group.groupId) {
			this.loading.alerts = this.subscriptionsService.getGroupSubscriptions(this.group.groupId)
				.then((groupSubscriptions) => {
					this.mergeAlertsAndSubscriptions(alertsWithSubscriptions, groupSubscriptions, this.group.groupId);
					this.populateUserAlerts(alertsWithSubscriptions);
				});
		} else {
			this.populateUserAlerts(alertsWithSubscriptions);
		}
	};

	mergeAlertsAndSubscriptions = (alertsWithSubscriptions, groupSubscriptions, groupId): void => {
		alertsWithSubscriptions.forEach((maAlert) => {
			groupSubscriptions.forEach((groupAlert) => {
				if (maAlert.alert.masterAccountId === groupAlert.alert.masterAccountId
						&& maAlert.alert.contentProviderId === groupAlert.alert.contentProviderId
						&& maAlert.alert.accountId === groupAlert.alert.accountId
						&& maAlert.alert.projectId === groupAlert.alert.projectId
						&& maAlert.alert.alertId === groupAlert.alert.alertId) {
					maAlert.subscribed = true;
					maAlert.alert.defaultAssignee = groupAlert.alert.defaultAssignee;
					maAlert.alert.topicId = groupAlert.alert.topicId;
					maAlert.alert.topicSelection = groupAlert.alert.topicSelection;
					maAlert.alert.hierarchySelections = groupAlert.alert.hierarchySelections;

					maAlert.details = {};
					if (groupAlert.details && groupAlert.details[groupId]) {
						maAlert.sendEmail = groupAlert.details[groupId].sendEmail;
						maAlert.createCase = groupAlert.details[groupId].createCase;
						maAlert.createTicket = groupAlert.details[groupId].createTicket;
					}
				}
			});

			if (!maAlert.alert.hierarchySelections) maAlert.alert.hierarchySelections = {};
		});
	};

	selectProject = (projectValue): void => {
		if (WorkspaceTransitionUtils.isProjectSelected(projectValue)) {
			this.loading.alerts =
				this.subscriptionsService.getMasterAccountAlertsForProject(projectValue.contentProviderId, projectValue.accountId,
					projectValue.projectId, false).then((result) => {
						this.convertAlertsToSubscriptions(result.data);
				});
		} else {
			this.userAlerts = [];
			this.filteredSubscriptions = [];
		}
	};

	selectWorkspaceProject = (projectValue): void => {
		this.loading.alerts =
			this.subscriptionsService.getMasterAccountAlertsForWorkspaceProject(projectValue, false).then((result) => {
				this.convertAlertsToSubscriptions(result.data);
			});
	};

	isSocialIntegrationEnabled = (): boolean => {
		return this.security.getCurrentMasterAccount().engagorEnabled;
	};

	private generateGroupUpdatesMessage = (): string => {
		let message = this.locale.getString('administration.groupMembersChanged');
		let addedUsers = this.users.added;
		let removedUsers = this.users.removed;

		let generateEmailInfoPart = (changedUsers, startPart) => {
			let msg = startPart;
			if (changedUsers.length <= 3) {
				msg += _.map(changedUsers, 'userEmail').join(', ');
			} else {
				msg += changedUsers.length;
			}
			msg += '. ';

			return msg;
		};

		if (addedUsers.length > 0) {
			message += generateEmailInfoPart(addedUsers, this.locale.getString('administration.usersAddedToGroup'));
		}
		if (removedUsers.length > 0) {
			message += generateEmailInfoPart(removedUsers, this.locale.getString('administration.usersRemovedFromGroup'));
		}
		message += '<br>' + this.locale.getString('organization.warningChangesConfirmation');
		return message;
	};

	save = () => {
		let result = {
			group: this.group,
			subscriptions: this.userAlerts,
			addedUsersIds: _.map(this.users.added, 'userId'),
			removedUsersIds: _.map(this.users.removed, 'userId'),
			assetsAccess: this.assetsAccess
		};

		if (this.editGroup && (this.users.added.length > 0 || this.users.removed.length > 0)) {
			this.cbDialogService.confirm(
				this.locale.getString('administration.groupUpdates'),
				this.generateGroupUpdatesMessage(),
				this.locale.getString('administration.confirm'),
				this.locale.getString('administration.cancel')
			).result.then(() => {
				this.close({$value: result});
			});
		} else {
			this.close({$value: result});
		}
	};

	cancel = () => {
		this.dismiss({$value: 'cancel'});
	};

	permissionsChangedCallback = (selectedPermissions): void => {
		this.group.permissions = selectedPermissions && selectedPermissions.selectedPermissions;
	};

	startAsyncNameValidation = (): void => {
		this.setAsyncNameValidity(false);
	};

	validateNameUniqueness = (): void => {
		if (this.isMatchingInitialGroupName()) {
			this.setNameUniquenessValidity(true);
		} else if (this.group.groupName) {
			this.asyncNameValidationPromise = this.usersGroupsApiService.isUniqueGroupName(
					this.group.type, this.group.groupName).then((unique) => {
				this.setAsyncNameValidity(true);
				this.setNameUniquenessValidity(unique);
			});
		}
	};

	validateNameCharacters = (): boolean => {
		const regExp = /[\|,;][@\+=\-]/;
		if (this.group.groupName) {
			let invalidValue = regExp.test(this.group.groupName);
			if (invalidValue) {
				this.forms.nameDialog.groupName.$setValidity('invalid', false);
				return true;
			}
		}
		this.forms.nameDialog.groupName.$setValidity('invalid', true);
		return false;
	};

	private isMatchingInitialGroupName = (): boolean => {
		let initialGroupName = this.getInitialGroupName();
		let currentGroupName = this.group.groupName;
		return initialGroupName
			&& initialGroupName.length > 0
			&& currentGroupName === initialGroupName;
	};

	private setAsyncNameValidity = (value: boolean): void => {
		this.forms.nameDialog.groupName.$setValidity('async', value);
	};

	private setNameUniquenessValidity = (valid: boolean): void => {
		this.forms.nameDialog.groupName.$setValidity('unique', valid);
	};

	private getInitialGroupName = (): string => {
		return this.groupItem.groupName;
	};
}

app.component('groupEditModal', {
	controller: GroupEditModalController,
	templateUrl: 'partials/user-administration/groups/group-edit-modal.component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
});
