import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { AbstractManageAssetService } from '@cxstudio/asset-management/management/abstract-manage-asset.service';
import { Security } from '@cxstudio/auth/security-service';

@Injectable({
	providedIn: 'root'
})
export default class ManageFilterService extends AbstractManageAssetService {

	constructor(
		@Inject('security') security: Security
	) {
		super(security);
	}

	protected hasCreatePermission = (): boolean => {
		return this.security.has(MasterAccountPermissionAction.CREATE_FILTER);
	};

	hasSharePermission = (): boolean => {
		return this.security.has('share_filter') || this.security.has('share_edit_filter');
	};
}

app.service('manageFilterService', downgradeInjectable(ManageFilterService));
