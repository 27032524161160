import { AccountReportExportSettings } from '@app/modules/system-administration/admin-reports/account-report-export/account-report-export-dialog.component';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import LinkedFilterExport from '@cxstudio/dashboards/widgets/linked-filter-export';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ExportQueryParameters } from '@cxstudio/services/export-service.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';

export interface DashboardClientParameters {
	id: number;
	snapshotId: number;
	tabId: number;
	tabSnapshotId: number;
	clientTimezone: string;
	clientTimezoneName: string;
	hierarchyId: number;
	hierarchyNodeId: number;
	appliedFiltersArray: string;
	widgetLinkingParams: {[key: number]: LinkedFilterExport[]};
	widgetPaginationParams: {[positionId: number]: number};
	widgetSentenceSelectionParams: any;
	widgetTextFilters: any;
}

export class ExportApiService {

	private EXCEL_REPORT_BASE = 'rest/export/report';

	constructor(
		private $http: ng.IHttpService,
		private dateService,
		private httpHandlers: HttpHandlers,
		private exportUtils: ExportUtils
	) {}

	//========
	// Excel Reports
	//========

	requestMaAudit = (queryParameters: ExportQueryParameters): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/audit-log`, null, { params: queryParameters });
	};

	requestSystemAudit = (queryParameters: ExportQueryParameters): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/audit-log/system`, null, { params: queryParameters });
	};

	requestWidgetUsageReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/widgets`, null);
	};

	requestUsageReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/usage`, null);
	};

	requestDashboardAccessReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/dashboard-access`, null);
	};

	requestPermissionMappingReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/permission-mapping`, null);
	};

	requestUserDataReport = (queryParameters: ExportQueryParameters, masterAccountFiltered: boolean): ng.IHttpPromise<void> => {
		masterAccountFiltered = isTrue(masterAccountFiltered);
		queryParameters.adminExport = !masterAccountFiltered;
		queryParameters.isPaginated = false;
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/user-data`, queryParameters);
	};

	requestGroupDataReport = (queryParameters: ExportQueryParameters): ng.IHttpPromise<void> => {
		queryParameters.isPaginated = false;
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/groups-data`, queryParameters);
	};

	requestAccountOverviewReport = (exportSettings: AccountReportExportSettings): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/account-overview`, exportSettings);
	};

	requestAccountPropertiesReport = (exportSettings: AccountReportExportSettings): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/account-properties`, exportSettings);
	};

	requestDataAccessReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/data-access`, null);
	};

	requestWidgetVersionReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/widget-version`, null);
	};

	requestEmbeddedWidgetsReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/embedded-widgets`, null);
	};

	requestBetaFeaturesStatusReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/beta-features`, null);
	};

	requestInternalTemplatesReport = (): ng.IHttpPromise<void> => {
		return this.$http.post('rest/asset-templates/export', null);
	};

	requestMetricSharingReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/metrics-access`, null);
	};

	requestFilterSharingReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/filters-access`, null);
	};

	requestLexiconSuggestions = (lexiconProjectData): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/lexicon-suggestions`, lexiconProjectData);
	};

	requestOrganizationHierarchyReport = (): ng.IHttpPromise<void> => {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/hierarchies`, null);
	};

	requestModelAccessReport = (projectSelection: IProjectSelection): ng.IHttpPromise<void> => {
		return this.$http.post(`rest/model/export/access-report`, projectSelection);
	};

	requestAlertSubscriptionsReport(projectSelection: IProjectSelection): ng.IHttpPromise<void> {
		return this.$http.post(`${this.EXCEL_REPORT_BASE}/alert-subscriptions`, projectSelection);
	}

	//========
	// Other
	//========

	getLogs = (queryParameters: any): ng.IHttpPromise<any[]> => {
		return this.$http.get('rest/log', queryParameters);
	};

	getMaAuditRecordsCount = (queryParameters): ng.IPromise<number> => {
		return this.$http.get('rest/log/amount', { params: queryParameters }).then(this.httpHandlers.success);
	};

	getSystemAuditRecordsCount = (queryParameters: ExportQueryParameters): ng.IPromise<number> => {
		return this.$http.get('rest/log/amount/system', { params: queryParameters }).then(this.httpHandlers.success);
	};

	// dashboards / widgets
	requestDashboardPdf = (dashboardId, parameters, versionId): ng.IHttpPromise<void> => {
		return this.$http.post('rest/export/dashboard/pdf/' + dashboardId + this.getVersionSuffix(versionId), parameters);
	};

	downloadDashboardPdf = (dashboardId, versionId, parameters): ng.IHttpPromise<any> => {
		return this.$http.post('rest/export/dashboard/instant-pdf/' + dashboardId + this.getVersionSuffix(versionId),
			parameters, { responseType: 'arraybuffer' });
	};

	private getVersionSuffix(versionId: string): string {
		return !_.isUndefined(versionId) ? '/version/' + versionId : '';
	}

	getDashboardExcelExport = (dashboardId, dashboardName, exportData): ng.IPromise<{filename: string; data: any}> => {
		let submitData = {
			dashboardId,
			fileTitle: dashboardName.substring(0, 100) + '_' + this.dateService.format(new Date()),
			data: exportData
		};

		let formData = new FormData();
		let fileContent = angular.toJson(submitData);
		formData.append('widgetsData', fileContent);

		return this.$http.post('rest/export/dashboard/excel', formData, {
			headers: {
				'Content-Type': undefined
			},
			responseType: 'arraybuffer'
		}).then((response) => {
			let filename = response.headers('Content-Disposition').match('filename=\"(.*?)\"')[1];
			if (_.isUndefined(filename)) {
				filename = 'export.xlsx';
			}

			return {
				filename,
				data: response.data
			};
		});
	};

	getDashboardClientParameters = (id: number): ng.IPromise<DashboardClientParameters> => {
		return this.$http.get('rest/export/dashboard/client-parameters/' + id)
			.then(this.httpHandlers.success, this.httpHandlers.error);
	};

	convertToExcel = (exportData): ng.IHttpPromise<any> => {
		return this.$http.post('rest/export/dashboard/convert/excel', exportData,
			{ responseType: 'arraybuffer' });
	};

	//voci
	downloadVociFile = (url): ng.IHttpPromise<any> => {
		return this.$http.get(url, { responseType: 'arraybuffer' });
	};

	downloadDocumentTranscription = (transcription): ng.IHttpPromise<any> => {
		return this.$http.get(transcription, { local: true } as any);
	};
}

app.service('exportApiService', ExportApiService);
