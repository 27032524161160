import { Inject, Injectable } from '@angular/core';
import { Interval } from '@cxstudio/interval/interval.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { downgradeInjectable } from '@angular/upgrade/static';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { FullScreenAutoRefreshStoreService } from '@app/modules/dashboard/services/full-screen-auto-refresh/full-screen-auto-refresh-store.service';

@Injectable({
	providedIn: 'root'
})
export class FullScreenAutoRefreshService {
	readonly REFRESH_INTERVAL = 3000;
	readonly REFRESH_DELTA = 0.05;
	readonly INTERVAL_NAME = 'dashboardAutoRefresh';

	refreshState = {
		autoRefreshTime: 0,
		lastRefreshTime: 0,
		refreshingDashboard: false
	};

	dashboardIdProvider;

	constructor(
		@Inject('interval') private readonly interval: Interval,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		private readonly fullScreenAutoRefreshStore: FullScreenAutoRefreshStoreService
	) {}

	// clean up when not in use
	destroy = (): void => {
		this.refreshState.autoRefreshTime = 0;
		this.refreshState.lastRefreshTime = 0;
		this.refreshState.refreshingDashboard = false;
		this.dashboardIdProvider = null;
	};

	getRefreshState = (): any => this.refreshState;

	setDashboardIdProvider = (provider): void => {
		this.dashboardIdProvider = provider;
	};

	start = (time): void => {
		this.refreshState.lastRefreshTime = 0;
		this.refreshState.autoRefreshTime = time;
		this.refreshState.refreshingDashboard = false;
		this.interval.registerInterval(this.INTERVAL_NAME, this.dashboardRefreshProgress, this.REFRESH_INTERVAL, true);
	};

	stop = (destroy: boolean = false): void => {
		this.interval.stopIntervalWithName(this.INTERVAL_NAME, destroy);
	};

	private suspendInterval = (): void => {
		this.refreshState.lastRefreshTime = 0;
		this.stop();
	};

	private resumeInterval = (): void => {
		this.refreshState.lastRefreshTime = 0;
		this.start(this.refreshState.autoRefreshTime);
	};

	private dashboardRefreshProgress = (): void => {
		if (this.refreshState.lastRefreshTime >= this.refreshState.autoRefreshTime) {
			this.refreshDashboard();
			this.refreshState.lastRefreshTime = 0;

			return;
		}

		this.refreshState.lastRefreshTime = this.refreshState.lastRefreshTime + this.REFRESH_DELTA;
		this.refreshState.lastRefreshTime = parseFloat(this.refreshState.lastRefreshTime.toFixed(2));
	};

	refreshDashboard = (): void => {
		this.suspendInterval();
		this.refreshState.refreshingDashboard = true;
		const containerId = this.dashboardIdProvider ? this.dashboardIdProvider() : undefined;
		this.refreshWidgets(containerId);
	};

	private refreshWidgets = (containerId): void => {
		const widgets: Widget[] = this.currentWidgets.getAllWidgets(containerId);

		this.fullScreenAutoRefreshStore.refreshWidgets(widgets);

		const completionObserver = {
			complete: () => {
				this.refreshState.refreshingDashboard = false;
				this.resumeInterval();
			},
		};

		this.fullScreenAutoRefreshStore
			.getRefreshWidgetObserver()
			.subscribe(completionObserver);

		let widget: Partial<Widget>;

		if (!widgets.isEmpty()) {
			widget = widgets[0];
		}

		if (widget) {
			this.fullScreenAutoRefreshStore.refreshDashboard(widget.dashboardId, true);
		}
	};

}

app.service('fullscreenAutorefresh', downgradeInjectable(FullScreenAutoRefreshService));
