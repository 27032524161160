import { NavigationService } from '@cxstudio/services/navigation.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { CbCommandPaletteOption } from '@app/modules/header/command-palette/cb-command-palette.component';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Book } from '@cxstudio/dashboards/entity/book';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CxNavigationService } from '@app/modules/header/services/cx-navigation.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { URLEvent } from '@app/core/cx-event.enum';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { LicenseType } from '@cxstudio/common/license-types';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';

declare let app: ng.IModule;

export class CommandPaletteComponent implements ng.IComponentController {

	readonly MAPropertiesTitle: string = this.locale.getString('mAccount.menuOption');
	readonly MASTER_ACCOUNT_PROPERTIES_PATH = 'master-account-properties';

	readonly ROOT_MENU_ITEMS: Array<Partial<CbCommandPaletteOption>> = [{
			name: this.locale.getString('commandPalette.aboutStudio'),
			action: this.cxNavigationService.showAboutStudio
		}, {
			name: this.locale.getString('commandPalette.accessibilityStatement'),
			action: this.cxNavigationService.showAccessibilityStatement
		}, {
			name: this.locale.getString('commandPalette.dashboardList'),
			action: this.openLocation('dashboards')
		}, {
			name: `${this.locale.getString('commandPalette.userManagement')}: ${this.locale.getString('administration.users')}`,
			action: this.openLocation('user-group-management', {tab: 'users'})
		}, {
			name: `${this.locale.getString('commandPalette.userManagement')}: ${this.locale.getString('administration.groups')}`,
			action: this.openLocation('user-group-management', {tab: 'groups'})
		}, {
			name: this.locale.getString('administration.systemAdministrationTitle'),
			action: this.openLocation('system')

		}, {
			name: this.locale.getString('header.interactionExplorer'),
			action: this.openLocation('interactions')
		}, {
			name: this.locale.getString('object.filters'),
			action: this.openLocation('filters')
		}, {
			name: this.locale.getString('object.metrics'),
			action: this.openLocation('metrics')
		}, {
			name: this.locale.getString('object.drivers'),
			action: this.openLocation('drivers')
		}, {
			name: this.locale.getString('commandPalette.interactionAlerts'),
			action: this.openLocation('alerts', { tab: 'interaction' }),
			betaFeature: BetaFeature.VERBATIM_ALERTING,
		}, {
			name: this.locale.getString('commandPalette.metricAlerts'),
			action: this.openLocation('alerts', { tab: 'metric' })
		},
		{
			name: this.locale.getString('alert.inboxTemplates'),
			action: this.openLocation('alert_templates')
		},
		{name: this.locale.getString('common.projects'), action: this.openLocation('projects')},
		{name: this.locale.getString('header.schedules'), action: this.openLocation('schedules')},
		{
			name: this.locale.getString('header.archive'),
			action: this.openLocation('recycle_bin')
		},
		{name: this.locale.getString('header.userPreferences'), action: this.navigationService.showUserPreferences},
		{name: this.locale.getString('commandPalette.updatePassword'), action: this.navigationService.showUserPreferences },
		{name: this.locale.getString('commandPalette.setDateFormat'), action: this.navigationService.showUserPreferences },
		{name: this.locale.getString('commandPalette.setFavoriteProject'), action: this.navigationService.showUserPreferences },
		{name: this.locale.getString('commandPalette.setFavoriteDashboard'), action: this.navigationService.showUserPreferences },
		{name: this.locale.getString('commandPalette.setLanguage'), action: this.navigationService.showUserPreferences },
		{
			name: this.MAPropertiesTitle,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'properties'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.securityAuditTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'audit'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.orgTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'organization'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.reports')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'reports'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.contractsTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'contracts'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.mobileApplicationTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'mobile-application'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.appearance')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'appearance'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.betaTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'beta-features'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.betaTabAlias')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'beta-features'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('appearance.customBranding')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'appearance'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('appearance.colorPalettes')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'appearance'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('appearance.conversationDisplayHeading')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'appearance'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.homePage')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'home-page'})
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.tuningSuggestionsTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'tuning-suggestions'}),
			betaFeature: BetaFeature.PRODUCT_FEEDBACK,
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('administration.packagedSolutionsTab')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'packaged-solutions'}),
			betaFeature: BetaFeature.PACKAGED_SOLUTIONS,
		},
		{
			name: `${this.MAPropertiesTitle}: ${this.locale.getString('topicConversions.heading')}`,
			action: this.openLocation(this.MASTER_ACCOUNT_PROPERTIES_PATH, {tab: 'topic-conversions'}),
			showCondition: (): boolean => {
				return (
					this.security.isAdminOrgUser()
					&& this.security.getLicenseTypeId() === LicenseType.ANALYZE
					&& this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.QUICK_TRANSLATE)
				);
			}
		},
	];

	isLoggedIn = this.security.isLoggedIn;

	constructor(
		private $location: ng.ILocationService,
		private $rootScope: ng.IRootScopeService,
		private navigationService: NavigationService,
		private security: Security,
		private locale: ILocale,
		private dashboardService: DashboardService,
		private betaFeaturesService: BetaFeaturesService,
		private cxNavigationService: CxNavigationService,
		private dashboardListService: DashboardListService,
		private maPropertiesService: MAPropertiesService
	) {}

	$onInit = (): void => {
		this.$rootScope.$on(URLEvent.SCOPE_LOCATION_CHANGE_START, () => {
			this.$rootScope.commandPaletteHidden = false;
		});
		this.dashboardListService.reloadDashboardsOld();
	};

	getOptions = (): Array<Partial<CbCommandPaletteOption>> => {
		return this.ROOT_MENU_ITEMS
			.filter(item => (!item.betaFeature || this.betaFeaturesService.isFeatureEnabled(item.betaFeature))
				&& (!item.showCondition || item.showCondition()))
			.concat({
				name: this.locale.getString('header.help'),
				action: this.openExternalLink('https://www.qualtrics.com/support/?utm_medium=product&utm_source=studio')
			}).concat(
				this.dashboardListService.getCurrentDashboardsList()
					.filter(obj => ((obj.type === DashboardType.DASHBOARD) || (obj.type === DashboardType.BOOK)) && !obj.hide)
					.map((obj: Dashboard | Book) => {
						let dashboardPaletteActions: Partial<CbCommandPaletteOption> = {
							name: (obj.type === DashboardType.DASHBOARD) ?
								this.locale.getString('commandPalette.dashboardByName', obj) :
								this.locale.getString('commandPalette.bookByName', obj),
							action: () => { this.navigationService.changeDashboard(obj, false); },
							lastUsedTime: obj.useDate,
							icon: `q-icon ${(obj.type === DashboardType.DASHBOARD) ? 'q-icon-chart-5' : 'q-icon-book'}`
						};

						if ((obj.type === DashboardType.DASHBOARD) && this.dashboardService.canEditDashboard(obj)) {
							dashboardPaletteActions.shift = {
								name: this.locale.getString('commandPalette.editDashboardByName', obj),
								action: () => { this.navigationService.changeDashboard(obj, true); }
							};
						}

						if (this.dashboardService.canShare(obj)) {
							dashboardPaletteActions.ctrl = {
								name: (obj.type === DashboardType.DASHBOARD) ?
									this.locale.getString('commandPalette.shareDashboardByName', obj) :
									this.locale.getString('commandPalette.shareBookByName', obj),
								action: () => { this.dashboardService.shareDashboard(obj).then(_.noop); }
							};
						}

						return dashboardPaletteActions;
					})
			);
	};

	private openLocation(path: string, search?: any): () => void {
		return () => {
			if (search) {
				this.$location.path(path).search(search);
			} else {
				this.$location.path(path).search({});
			}
		};
	}

	private openExternalLink(url: string): () => void {
		return () => {
			if (!url) return;
			window.open(url, '_blank');
		};
	}

	isPaletteHidden = () => {
		return this.$rootScope.commandPaletteHidden;
	};

}

app.component('commandPalette', {
	controller: CommandPaletteComponent,
	template: `
<cb-command-palette ng-if="$ctrl.isLoggedIn()"
	class="flex-fill mr-16"
	[get-options]="$ctrl.getOptions"
	ng-hide="$ctrl.isPaletteHidden()">
</cb-command-palette>`
});
