import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { IDrillMenuOption } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import * as _ from 'underscore';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { KeyboardNavigationDrillHelper } from '@app/modules/keyboard-navigation/drilling/keyboard-navigation-drill-helper.service';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { RouteService } from '@cxstudio/services/route-service';

export enum MenuOptionType {
	DIVIDER = 'divider',
	LINK = 'link',
	NESTED = 'nested',
	RECOLOR = 'recolor',
	ACTION = 'action',
	TREE = 'tree',
	SELECTABLE = 'selectable'
}

export const MenuDividerShorthand = null;

export const MenuDivider = {
	name: MenuOptionType.DIVIDER,
	isDivider: true,
	func: _.noop,
	onClick: _.noop
};

export class DrillMenuOptionController implements ng.IController {
	private readonly DRILL_SUBMENU_HEIGHT = 350;
	private readonly RECOLOR_HEIGHT = 356;
	private readonly DRILL_COLUMN_WIDTH = 200;
	private readonly EXTRA_MENU_OFFSET = 16;

	option: IDrillMenuOption;
	object: DrillPoint;
	active: boolean;
	onSelect: ({$option}) => void;
	onAction: () => void;

	submenuUp: boolean;
	submenuLeft: boolean;
	recolorValue: string;
	recolorPosition: any;

	clickLocation: { y: number; x: number };
	recolorLeft: boolean;

	constructor(
		private $timeout: ng.ITimeoutService,
		private contextMenuTree: ContextMenuTree,
		private $element: ng.IAugmentedJQuery,
		private $q: ng.IQService,
		private routeService: RouteService,
		private keyboardNavigationDrillHelper: KeyboardNavigationDrillHelper,
		private chartAccessibilityService: ChartAccessibilityService
	) {}

	$onInit(): void {
		if (this.getOptionType() === MenuOptionType.RECOLOR)
			this.initRecolor();
		this.$timeout(() => this.initDirection());
	}

	private initRecolor(): void {
		let point = this.object as any;
		let color = point.color;
		color = point.nativeColor ? point.nativeColor : color;
		color = point.lineColor ? point.lineColor : color;

		if (this.chartAccessibilityService.isPatternFillEnabled()) {
			color = point.color.pattern ? point.color.pattern.color : color;
		}

		let hexColor = ColorUtilsHelper.rgb2hex(color);
		if (hexColor === '') {
			hexColor = color;
		}
		this.recolorValue = hexColor;
	}

	private initDirection(): void {
		if (!this.option) {
			return;
		}

		if (this.getOptionType() === MenuOptionType.RECOLOR) {
			this.initRecolorDirection();
			return;
		}

		let optionTop = this.$element.offset().top;
		this.submenuUp = optionTop > this.contextMenuTree.getContainerHeight() - this.DRILL_SUBMENU_HEIGHT;
		if (this.option.levels) {
			let optionRight = this.$element.offset().left + this.DRILL_COLUMN_WIDTH;

			let estimatedRight = optionRight + this.option.levels * this.DRILL_COLUMN_WIDTH + this.EXTRA_MENU_OFFSET;
			if (estimatedRight > this.contextMenuTree.getContainerWidth()) {
				this.submenuLeft = true;
			}
		}
	}

	initRecolorDirection(): void {
		const VERTICAL_APPROX_WIDTH = 350;
		const VERTICAL_APPROX_HEIGHT = 600;
		const HORIZ_APPROX_WIDTH = 700;

		let optionTop = this.$element.offset().top;
		let neededSpace = VERTICAL_APPROX_WIDTH;
		let optionRight = this.$element.offset().left + this.DRILL_COLUMN_WIDTH;

		if (optionTop + VERTICAL_APPROX_HEIGHT + this.EXTRA_MENU_OFFSET > (window.innerHeight)) {
			neededSpace = HORIZ_APPROX_WIDTH;
		}

		if ((optionRight + neededSpace + this.EXTRA_MENU_OFFSET) > this.contextMenuTree.getContainerWidth()) {
			this.recolorLeft = true;
		} else {
			this.recolorLeft = false;
		}
	}

	getOptionType = (): MenuOptionType => {
		if (this.option === null) {
			return MenuOptionType.DIVIDER;
		}
		if (this.option.ref) {
			return MenuOptionType.LINK;
		}
		if (this.option.items) {
			return MenuOptionType.NESTED;
		}
		if (this.option.name === MenuOptionType.RECOLOR) {
			return MenuOptionType.RECOLOR;
		}
		return MenuOptionType.ACTION;
	};

	getLinkTarget = (): string => {
		return this.routeService.isEmbeddedView() ? '_blank' : '_self';
	};

	optionClass = (): string => {
		let classes = [];
		classes.push('option');
		classes.push(this.option[1]);

		if (this.option.name !== undefined) {
			classes.push(this.option.name);
		}
		if (this.option.disabled) {
			classes.push('disabled');
		}

		if (this.option.classes) {
			classes = classes.concat(this.option.classes);
		}

		return classes.join(' ');
	};

	getOptionLabel = (): string => {
		return this.option.label
			? this.option.label
			: this.option.text;
	};

	toggleCurrentItem = ($event: JQuery.Event): void => {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		if (this.onSelect) {
			this.onSelect({$option: this.option});
		}
	};

	performAction = ($event: JQuery.Event): void => {
		if (this.option.noDefaultAction) {
			this.toggleCurrentItem($event);
			return;
		}
		$event.preventDefault();
		$event.stopPropagation();
		let promise;
		if (this.option.defaultAction) {
			promise = this.option.defaultAction(this.object);
		} else if (this.option.asyncFunc) {
			promise = this.option.asyncFunc(this.object, this.option.obj);
		} else {
			promise = this.$q.when();
			if (this.option.func)
				this.option.func(this.object, this.option.obj, this.option.models);
		}
		promise.then(this.emitActionEvent);
	};

	emitActionEvent = (): void => {
		if (this.onAction) {
			this.onAction();
		}
	};

	applyRecolor = ($event?: JQuery.Event): void => {
		if ($event) {
			$event.preventDefault();
			$event.stopPropagation();
		}
		this.option.func(this.object, this.recolorValue);
		this.emitActionEvent();
	};

	adjustRecolorPosition = ($event: JQuery.Event): void => {
		this.recolorPosition = {};
		this.clickLocation = {y: $event.pageY, x: $event.pageX };
		if ($event.pageY + this.RECOLOR_HEIGHT > this.contextMenuTree.getContainerHeight()) {
			this.recolorPosition = {
				top: 'auto',
				bottom: '0'
			};
		}
	};

	onArrowToggleKeyboard(event: any): void {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			KeyboardUtils.intercept(event);
			this.toggleCurrentItem(event);
			this.$timeout(() => {
				$('menu-option-submenu :focusable').first().trigger('focus');
			}, 100);
		}
	}

	onDrillOptionKeyboard(event: any): void {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)
			|| (KeyboardUtils.isEventKey(event, Key.RIGHT) && event.target.parentElement.classList.contains('dropdown-submenu'))) {
			KeyboardUtils.intercept(event);
			this.performAction(event);
			this.$timeout(() => {
				$('menu-option-submenu :focusable').first().trigger('focus');
			}, 100);
		}
	}

	onRecolorKeydown(event: any): void {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.RIGHT)) {
			KeyboardUtils.intercept(event);
			this.toggleCurrentItem(event);
			this.adjustRecolorPosition(event);
		}
	}

	onSubmenuKeyboard(event: any): void {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			this.onSubmenuEscape(event);
		} else if (KeyboardUtils.isEventKey(event, Key.UP)) {
			this.onSubmenuUp(event);
		} else if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			this.onSubmenuDown(event);
		} else if (KeyboardUtils.isEventKey(event, Key.LEFT)) {
			this.onSubmenuLeft(event);
		}
		// RIGHT is implemented in context-menu-option
	}

	private onSubmenuEscape(event: any): void {
		KeyboardUtils.intercept(event);
		let activeDrillMenuOption: JQuery = this.keyboardNavigationDrillHelper.getActiveMainMenuItem();
		activeDrillMenuOption.trigger('focus');
		activeDrillMenuOption.trigger('click');
	}

	private onSubmenuUp(event: any): void {
		KeyboardUtils.intercept(event);
		let submenuLevelClass: string = this.keyboardNavigationDrillHelper.findSubmenuLevelClass(event.target.parentElement.classList);
		let currentSubmenuItems: any[] = this.keyboardNavigationDrillHelper.getSubmenuItems(submenuLevelClass);
		this.keyboardNavigationDrillHelper.focusPreviousItem(event.target, currentSubmenuItems);
	}

	private onSubmenuDown(event: any): void {
		KeyboardUtils.intercept(event);
		let submenuLevelClass: string = this.keyboardNavigationDrillHelper.findSubmenuLevelClass(event.target.parentElement.classList);
		let currentSubmenuItems: any[] = this.keyboardNavigationDrillHelper.getSubmenuItems(submenuLevelClass);
		this.keyboardNavigationDrillHelper.focusNextItem(event.target, currentSubmenuItems);
	}

	private onSubmenuLeft(event: any): void {
		KeyboardUtils.intercept(event);
		let submenuLevel: number = this.keyboardNavigationDrillHelper.getSubmenuLevel(event.target.parentElement.classList);
		if (submenuLevel === 1) {
			let activeDrillMenuOption: JQuery = this.keyboardNavigationDrillHelper.getActiveMainMenuItem();
			activeDrillMenuOption.trigger('focus');
			activeDrillMenuOption.trigger('click');
		} else {
			let previousSubmenuLevelClass: string = this.keyboardNavigationDrillHelper.getSubmenuLevelClass(submenuLevel - 1);
			this.keyboardNavigationDrillHelper.focusPreviousSubmenu($(event.target), previousSubmenuLevelClass);
		}
	}

}

app.component('drillMenuOption', {
	controller: DrillMenuOptionController,
	bindings: {
		option: '=',
		object: '<',
		active: '<',
		onSelect: '&',
		onAction: '&'
	},
	templateUrl: 'partials/context-menu/drill-menu-option.component.html'
});
