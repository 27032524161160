import { Component, OnInit, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core';
import { User } from '@cxstudio/user-administration/users/entities/user';


export interface Comment {
	id: number;
	isLiked: boolean;
	likes: number[];
	content: string;
	ownerId: number;
	owner?: User;
	createTime: string;
}

@Component({
	selector: 'cb-discussion-comment-list',
	template: `
<div class="discussion" *ngFor="let comment of comments">
	<cb-discussion-comment
		[comment]=comment
		[canDelete]=canDelete(comment)
		(onToggleLike)=toggleLike($event.comment)
		(onDeleteComment)=deleteComment($event.comment)>
	</cb-discussion-comment>	
</div>`
})

export class CbDiscussionCommentListComponent implements OnInit {

	@Input() comments: Comment[];
	@Input() type;
	@Input() updateCommentCount;
	@Input() user;
	@Input() objectId;

	constructor(
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		@Inject('security') private security: Security,
		@Inject('discussionService') private discussionService,
		private locale: CxLocaleService) {		
	}

	ngOnInit(): void {}

	toggleLike = (comment: Comment) => {
		this.discussionService.toggleLikeButton(this.type, this.objectId, comment.id);
		comment.isLiked = !comment.isLiked;
		this.updateLikeList(comment);
	};

	updateLikeList = (comment: Comment) => {
		let userId = this.user.user.userId;

		if (comment.isLiked) {
			comment.likes.push(userId);
		} else {
			comment.likes = comment.likes.filter(user => user !== userId);
		}
	};

	updateDeleteList = (comment: Comment) => {
		this.comments.remove(comment);
	};

	deleteComment = (comment: Comment) => {
		let template = 'partials/custom/confirm-delete-comment-content.html';
		let model = {
			msg: this.locale.getString('comments.deleteCommentConfirm'),
			content: comment.content
		};
		let confirm = this.cbDialogService.custom(this.locale.getString('comments.deleteComment'), template, model,
			this.locale.getString('comments.delete'), this.locale.getString('common.cancel'));
		confirm.result.then(() => {			
			this.discussionService.deleteComment(this.type, this.objectId, comment.id).then((result) => {
				this.updateDeleteList(comment);
				if (!isEmpty(result.totalCount)) {
					this.updateCommentCount(result.totalCount);
				}
			}, (error) => { });
		});
	};

	canDelete = (comment: Comment) => {
		if (this.security.has('system_administration'))
			return true;
			
		let userId = this.user.user.userId;
		return comment.ownerId === userId;
	};
}

app.directive('cbDiscussionCommentList', downgradeComponent({component: CbDiscussionCommentListComponent}));