
import { EventEmitter, Output } from '@angular/core';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime } from 'rxjs/internal/operators/debounceTime';
import { distinctUntilChanged } from 'rxjs/internal/operators/distinctUntilChanged';
import { switchMap } from 'rxjs/internal/operators/switchMap';
import { UserSearchAPI } from '../user-administration/search-panel/user-search-api.service';

@Component({
	selector: 'user-custom-field',
	templateUrl: './user-custom-field.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserCustomFieldComponent {
	@Input() name: string;
	@Input() value: string;

	@Output() updateName = new EventEmitter<string>();
	@Output() updateValue = new EventEmitter<string>();

	showNameInput: boolean;

	constructor(
		@Inject('security') private security: Security,
		private userSearchAPI: UserSearchAPI
	) {}

	isAccountOwner(): boolean {
		return this.security.has('account_owner');
	}

	toggle = (value?: boolean): void => {
		if (!this.isAccountOwner()) {
			return;
		}

		this.showNameInput = _.isUndefined(value)
			? !this.showNameInput
			: value;
	};

	valueSuggestions = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(search => this.userSearchAPI.getCustomFieldsSuggestion(search))
		);
	};

	onNameChange = (): void => {
		this.updateName.emit(this.name);
	};

	onValueChange = (): void => {
		this.updateValue.emit(this.value);
	};

	valueDisabled = (): boolean => {
		return !this.name || !this.canEditValue();
	};

	private canEditValue(): boolean {
		return this.isAccountOwner() || this.security.has('manage_settings');
	}

}

app.directive('userCustomField', downgradeComponent({component: UserCustomFieldComponent}));