import { Component, OnInit, ChangeDetectionStrategy, forwardRef, ChangeDetectorRef, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MetricColorDirection } from '@cxstudio/metrics/entities/metric-color-direction.enum';
import { CxLocaleService } from '@app/core';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';

@Component({
	selector: 'direction-color',
	template: `
<label>{{'singleMetric.dirColor'|i18n}}</label>
<cb-radio-group
	class="_t-directional-color"
	[options]="colorDirectionRadioOptions"
	[ngModel]="innerValue"
	(ngModelChange)="onChange($event)">
</cb-radio-group>
	`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DirectionColorComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DirectionColorComponent extends BaseControlValueAccessor<boolean> implements OnInit {

	@Input() calculation: ReportCalculation;

	innerValue: boolean;

	colorDirectionRadioOptions: UIOption<MetricColorDirection>[];

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref);
	}

	ngOnInit(): void {
		if (this.isEaseScore()) {
			this.colorDirectionRadioOptions = [
				{value: MetricColorDirection.DIRECT, displayName: this.locale.getString('singleMetric.effort'), htmlId: 'effort'},
				{value: MetricColorDirection.NONE, displayName: this.locale.getString('singleMetric.noColor'), htmlId: 'noColor'}
			];
		} else {
			this.colorDirectionRadioOptions = [
				{value: MetricColorDirection.DIRECT, displayName: this.locale.getString('singleMetric.direct'), htmlId: 'direct'},
				{value: MetricColorDirection.REVERSED, displayName: this.locale.getString('singleMetric.reversed'), htmlId: 'reversed'},
				{value: MetricColorDirection.NONE, displayName: this.locale.getString('singleMetric.noColor'), htmlId: 'noColor'}
			];
		}
	}

	private isEaseScore(): boolean {
		return this.calculation.name === PredefinedMetricConstants.EASE_SCORE
				|| this.calculation.parentMetricName === PredefinedMetricConstants.EASE_SCORE;
	}
}
