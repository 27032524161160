import * as cloneDeep from 'lodash.clonedeep';

import { Component, OnInit, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { CxLocaleService } from '@app/core';
import { ConversationSettingsListActions } from './conversation-settings-list-actions.service';
import { ConversationSettingsContextMenu } from '@app/modules/account-administration/conversation-settings/conversation-settings-context-menu.service';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { Subject } from 'rxjs';
import { ConversationSettingsEntry, ConversationSettingId, ConversationSettingsType } from './conversation-settings.entity';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { ConversationSettingsApi } from '@app/modules/conversation/conversation-settings-api.service';

@Component({
	selector: 'conversation-settings-list',
	templateUrl: './conversation-settings-list.component.html',
	providers: [ConversationSettingsContextMenu, ConversationSettingsListActions]
})

export class ConversationSettingsListComponent implements OnInit {

	loading: Promise<any>;
	settingsArray: ConversationSettingsEntry[] = [];
	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.CONVERSATION_SETTINGS;
	contextMenuUtils: ConversationSettingsContextMenu;
	actionsService: ConversationSettingsListActions;
	projectProperties: IProjectPreselection;
	projects?: Project[];
	showErrorsForCP: string[];
	isWorkspaceEnabled: boolean;
	workspaceProject: WorkspaceProject;

	clearDefaultsSubject: Subject<void> = new Subject<void>();
	changedItems: ConversationSettingsEntry[];

	constructor(
		private locale: CxLocaleService,
		private conversationSettingsContextMenu: ConversationSettingsContextMenu,
		private conversationSettingsListActions: ConversationSettingsListActions,
		private readonly conversationSettingsApi: ConversationSettingsApi,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: any,
		private readonly betaFeaturesService: BetaFeaturesService) { }

	ngOnInit(): void {
		this.loadSettings();

		this.gridOptions = {
			onClick: this.onClick
		};
		this.projectProperties = {};
		this.contextMenuUtils = this.conversationSettingsContextMenu;
		this.actionsService = this.conversationSettingsListActions;
		this.actionsService.onChange.subscribe(this.loadCustomSettings);
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	private onClick = (event, object: ConversationSettingsEntry): void => {
		if (this.gridUtils.isNameClick(event)) {
			this.actionsService.edit(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'palettes', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			if (object.id === ConversationSettingId.DEFAULT) return;
			this.actionsService.toggle(object);
		}
	};

	loadSettings(): void {
		this.loading = this.conversationSettingsApi.getDefaultSpineSettings()
			.then(spineSettings => {
				this.settingsArray.push({
					id: ConversationSettingId.DEFAULT,
					displayName: this.locale.getString('common.default'),
					name: this.locale.getString('common.default'),
					type: ConversationSettingsType.SYSTEM,
					settings: spineSettings
				} as ConversationSettingsEntry);
			});
		// need to load other saved values as well, when they become available
	}

	loadCustomSettings = (): void => {
		if (this.projectProperties?.project) {
			this.loading = this.conversationSettingsApi.getCustomSpineSettings(this.projectProperties.cbContentProvider,
				this.projectProperties.cbAccount)
				.then(customSettingsList => {
					this.settingsArray = [this.getDefaultSettings(), ...customSettingsList];
					this.changedItems = customSettingsList;
				});
		}
	};

	private getDefaultSettings = (): ConversationSettingsEntry => {
		return _.find(this.settingsArray, {id: ConversationSettingId.DEFAULT});
	};

	createSettings(): void {
		let newSettings = cloneDeep(this.getDefaultSettings());
		newSettings.type = ConversationSettingsType.CUSTOM;
		newSettings.contentProviderId = this.projectProperties.cbContentProvider;
		newSettings.accountId = this.projectProperties.cbAccount;
		newSettings.projectId = this.projectProperties.project;
		newSettings.workspaceProject = this.workspaceProject;
		newSettings.displayName = this.projectProperties.projectName;
		newSettings.name = this.projectProperties.projectName;
		this.actionsService.edit(newSettings);
	}

	onProjectsLoading(loadingPromise: Promise<any>): void {
		this.loading = loadingPromise;
	}

	onProjectSelectionChange = (projectSelection: IProjectPreselection): void => {
		this.projectProperties.cbContentProvider = projectSelection.cbContentProvider;
		this.projectProperties.cbAccount = projectSelection.cbAccount;
		this.projectProperties.project = projectSelection.project;
		this.projectProperties.projectName = projectSelection.projectName;
		if (this.projectProperties.cbAccount > -1) {
			this.loadCustomSettings();
		}
	};

	onWorkspaceChange = (workspace: Unit): void => {
		if (workspace) {
			this.projectProperties.cbContentProvider = workspace.contentProviderId;
			this.projectProperties.cbAccount = workspace.accountId;
		} else {
			this.projectProperties.cbContentProvider = -1;
			this.projectProperties.cbAccount = -1;
		}
	};

	onProjectChange = (workspaceProject: WorkspaceProjectData): void => {
		this.workspaceProject = workspaceProject;
		this.projectProperties.project = workspaceProject.projectId;
		this.projectProperties.projectName = workspaceProject.projectName;
		if (this.projectProperties.cbAccount > -1) {
			this.loadCustomSettings();
		}
	};

	disableNewDisplayCreation = (): boolean => {
		return this.projectProperties?.project && this.projectProperties?.projectName
			? this.customDisplaySettingsExist()
			: true;
	};

	customDisplaySettingsExist = (): boolean => {
		return _.some(this.settingsArray, (settingsEntry: ConversationSettingsEntry) => {
			return settingsEntry.projectId === this.projectProperties.project
				&& settingsEntry.type === ConversationSettingsType.CUSTOM;
		});
	};

	onCpErrorsChange = (errors: string[]) => {
		this.showErrorsForCP = errors;
	};

	getNewDisplayTitle = (): string => {
		if (this.disableNewDisplayCreation() && this.projectProperties?.projectName) {
			return this.locale.getString('appearance.customDisplayExistsTooltip',
				{projectName: this.projectProperties.projectName});
		}
		return '';
	};
}

app.directive('conversationSettingsList',
	downgradeComponent({component: ConversationSettingsListComponent}));
