import { Component, Input, ViewChild, AfterViewInit } from '@angular/core';
import { QualtricsIntegrationComponent } from './qualtrics-integration/qualtrics-integration.component';
import { NarrativeIntegrationComponent } from './narrative-integration/narrative-integration.component';
import { VoiceSettingsIntegrationComponent } from './voice-settings-integration.component';
import { PredictiveSettingsIntegrationComponent } from './predictive-settings-integration.component';
import { ConnectorsSettingsIntegrationComponent } from './connectors-settings-integration.component';
import { TuningSuggestionSettingsIntegrationComponent } from './tuning-suggestion-integration.component';
import { EngagorSettingsIntegrationComponent } from './engagor-settings-integration.component';
import { ExternalAuthSettingsIntegrationComponent } from './external-auth-settings-integration.component';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';


@Component({
	selector: 'master-account-integration',
	templateUrl: './master-account-integration.component.html'
})
export class MasterAccountIntegrationComponent implements AfterViewInit {
	@Input() masterAccountId: number;
	@Input() masterAccountName: number;
	@Input() upload: any;
	@Input() initialAliasName: string;

	@ViewChild(QualtricsIntegrationComponent, { static: false })
	qualtricsIntegration: QualtricsIntegrationComponent;

	@ViewChild(NarrativeIntegrationComponent, { static: false })
	narrativeIntegration: NarrativeIntegrationComponent;

	@ViewChild(VoiceSettingsIntegrationComponent, { static: false })
	voiceSettings: VoiceSettingsIntegrationComponent;

	@ViewChild(PredictiveSettingsIntegrationComponent, { static: false })
	predictionSettings: PredictiveSettingsIntegrationComponent;

	@ViewChild(ConnectorsSettingsIntegrationComponent, { static: false })
	connectorsSettings: ConnectorsSettingsIntegrationComponent;

	@ViewChild(TuningSuggestionSettingsIntegrationComponent, { static: false })
	tuningSuggestionIntegration: TuningSuggestionSettingsIntegrationComponent;

	@ViewChild(EngagorSettingsIntegrationComponent, { static: false })
	engagorSettings: EngagorSettingsIntegrationComponent;

	@ViewChild(ExternalAuthSettingsIntegrationComponent, { static: false })
	externalAuthSettings: ExternalAuthSettingsIntegrationComponent;

	integration: UntypedFormGroup;

	constructor(private fb: UntypedFormBuilder) {
		this.integration = this.fb.group({
			unitIds: [ ]
		});
	}

	ngAfterViewInit(): void {
		this.integration = this.fb.group({
			qualtricsIntegration: this.qualtricsIntegration.getGroup(),
			qualtricsApiIntegration: this.qualtricsIntegration.getApiGroup(),
			narrativeIntegration: this.narrativeIntegration.getGroup(),
			voiceSettings: this.voiceSettings.getGroup(),
			predictionSettings: this.predictionSettings.getGroup(),
			connectorsSettings: this.connectorsSettings.getGroup(),
			tuningSuggestionIntegration: this.tuningSuggestionIntegration.getGroup(),
			unitIds: [ ],
			engagorSettings: this.engagorSettings.getGroup(),
			externalAuthSettings: this.externalAuthSettings.getGroup(),
		});
	}

	isNewMasterAccount(): boolean {
		return !this.masterAccountId;
	}

	getGroup(): UntypedFormGroup {
		return this.integration;
	}

	get qualtricsIntegrationForm(): UntypedFormGroup {
		return this.integration.controls.qualtricsIntegration as UntypedFormGroup;
	}

	get qualtricsApiIntegrationForm(): UntypedFormGroup {
		return this.integration.controls.qualtricsApiIntegration as UntypedFormGroup;
	}

	get narrativeIntegrationForm(): UntypedFormGroup {
		return this.integration.controls.narrativeIntegration as UntypedFormGroup;
	}

	get voiceSettingsForm(): UntypedFormGroup {
		return this.integration.controls.voiceSettings as UntypedFormGroup;
	}

	get predictionSettingsForm(): UntypedFormGroup {
		return this.integration.controls.predictionSettings as UntypedFormGroup;
	}

	get connectorsSettingsForm(): UntypedFormGroup {
		return this.integration.controls.connectorsSettings as UntypedFormGroup;
	}

	get tuningSuggestionIntegrationForm(): UntypedFormGroup {
		return this.integration.controls.tuningSuggestionIntegration as UntypedFormGroup;
	}

	get engagorSettingsForm(): UntypedFormGroup {
		return this.integration.controls.engagorSettings as UntypedFormGroup;
	}

	get externalAuthSettingsForm(): UntypedFormGroup {
		return this.integration.controls.externalAuthSettings as UntypedFormGroup;
	}
}
