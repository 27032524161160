import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { MetricEditorUtilsService } from '@app/modules/metric/editor/metric-editor-utils.service';
import { TabsValidity } from '@app/modules/metric/editor/metric-editor.component';
import { MetricData } from '@app/modules/metric/entities/metric-data';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import ManageMetricService from '@app/modules/metric/services/manage-metric.service';
import { MetricMode } from '@cxstudio/metrics/metric-modes-constant';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { MetricsManagementService } from '@app/modules/metric/services/metrics-management.service';
import { ObjectUtils } from '@app/util/object-utils';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { IMetricAssets } from './metric-assets.interface';
import { MetricEditorDialogInput } from './metric-editor-dialog-input.interface';
import { MetricEditorDialogOutput } from './metric-editor-dialog-output.interface';

@Component({
	selector: 'metric-editor-dialog',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './metric-editor-dialog.component.html'
})
export class MetricEditorDialogComponent implements OnInit {

	@Input() input: MetricEditorDialogInput;

	metric: MetricData;
	project: AccountOrWorkspaceProject;
	viewMode: boolean;
	canShare: boolean;

	assets: IMetricAssets;
	loading: Promise<any>;
	assetsLoading: any;
	modalTitle: string;
	closeButtonText: string;

	uniqueNameError: boolean;
	tabsValidity: TabsValidity;

	constructor(
		private readonly modal: NgbActiveModal,
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		private readonly metricsManagementService: MetricsManagementService,
		private readonly manageMetricService: ManageMetricService,
		@Inject('metricEditorUtils') private readonly metricEditorUtils: MetricEditorUtilsService,
	) {}

	ngOnInit(): void {
		this.viewMode = MetricMode.VIEW === this.input.mode;
		this.modalTitle = this.locale.getString('metrics.metricEditTitle');
		this.closeButtonText = this.locale.getString('common.cancel');
		if (this.viewMode) {
			this.modalTitle = this.locale.getString('metrics.viewMetric');
			this.closeButtonText = this.locale.getString('common.close');
		}

		this.uniqueNameError = false;
		let metricData: any = this.input.metric;
		this.metric = ObjectUtils.copy(metricData);
		this.project = ObjectUtils.copy(this.input.project);
		if (!WorkspaceTransitionUtils.isProjectSelected) {
			this.project.projectId = metricData?.projectId;
		}

		this.canShare = this.manageMetricService.canShare(this.input.metric)
			&& (MetricMode.EDIT_AND_SHARE === this.input.mode || MetricMode.CREATE === this.input.mode);
		this.initAssets();
	}

	private initAssets(): void {
		if (!this.isPredefinedMetric()) {
			this.assetsLoading = this.metricEditorUtils.getMetricEditorAssets(this.project).then(assets => {
				this.assets = assets;
				this.ref.markForCheck();
			});
		} else {
			this.assets = {} as IMetricAssets;
		}
	}

	isSaveDisabled = (): boolean => {
		return this.hasError();
	};

	hasError = (): boolean => {
		return this.hasInvalidTabs()
			|| this.uniqueNameError;
	};

	save = (shareAfterSave: boolean = false): void => {
		if (this.viewMode) {
			this.modal.close();
		}

		this.isValid().then(valid => {
			if (!valid) return;
			let output: MetricEditorDialogOutput = {
				result: this.metric,
				doShare: shareAfterSave
			};
			this.modal.close(output);
		});
	};

	close = (): void => {
		this.modal.dismiss();
	};

	metricChangeHandler = (metric: MetricData): void => {
		this.metric = metric;
	};

	nameChangeHandler = (): void => {
		this.uniqueNameError = false;
	};

	validityChangeHandler = (tabsValidity: TabsValidity): void => {
		this.tabsValidity = tabsValidity;
	};

	isPredefinedMetric = (): boolean => {
		let type = this.metric?.definition?.type;
		return type === MetricType.EASE_SCORE
			|| type === MetricType.SENTIMENT
			|| type === MetricType.NUMERIC_BREAKDOWN;
	};

	private isValid = (): Promise<boolean> => {
		let displayName = this.metric.displayName;

		if (!displayName) {
			this.uniqueNameError = false;
			return Promise.resolve(false);
		}

		if (this.isPredefinedMetric()) {
			this.uniqueNameError = false;
			return Promise.resolve(true);
		} else {
			let promise = this.metricsManagementService.validateMetricName(this.project, displayName);

			this.loading = promise.then((result) => {
				if (result && (!this.input.metric || (this.input.metric.displayName !== displayName))) {
					this.uniqueNameError = true;
					return false;
				} else if (this.hasInvalidTabs()) {
					this.uniqueNameError = false;
					return false;
				} else {
					this.uniqueNameError = false;
					return true;
				}
			});
			return this.loading;
		}
	};

	hasInvalidTabs = (): boolean => {
		return _.values(this.tabsValidity).contains(false);
	};
}
