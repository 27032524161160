import * as _ from 'underscore';

import IAlertSubscriptionTemplate, {
	AlertSubscriptionTemplateType
} from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';
import { CaseTitleMode } from '@cxstudio/alert-subscription-templates/types/case-title-mode.enum';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import EngagorCreateCaseRequestDocument, { CaseTitle } from '@cxstudio/engagor/engagor-create-case-request-document';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { EngagorApiService, EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { InboxTemplateTitleUtils } from '@app/modules/inbox-template/inbox-template-title-utils.class';
import {
	SubscriptionTemplateTitleVariable
} from '@cxstudio/alert-subscription-templates/types/subscription-template-title-variable';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { ConversationChannelLabels } from '@cxstudio/conversation/entities/conversation-channel-labels.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import AlertSubscriptionTemplatesApi
	from '@cxstudio/alert-subscription-templates/alert-subscription-templates-api.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { AnalyticPreviewFormatting } from '@cxstudio/reports/providers/cb/services/analytic-preview-formatting.service';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import ScorecardUtils from '@app/modules/scorecards/utils/scorecard-utils';
import { AuditSuggestion, AuditSuggestionEnrichment } from '@cxstudio/reports/document-explorer/audit-suggestion';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { SearchListGroup } from '@app/shared/components/forms/lists/search-list.component';
import { ConversationSettingsApi } from '@app/modules/conversation/conversation-settings-api.service';
import { PromiseUtils } from '@app/util/promise-utils';

interface ICreateEngagorCaseModalParams {
	projectIdentifier: IProjectSelection;
	workspaceProject: WorkspaceProject;
	documents: PreviewDocument[];
	type: EngagorCaseType;
	assetParameters: AssetParameters;
}

export enum EngagorCaseType {
	SUGGESTION,
	REGULAR
}

export interface ICreateEngagorCaseModalResult {
	templateId?: number;
	xflowSubscriptionId?: string;
	isXflow: boolean;
	documents: EngagorCreateCaseRequestDocument[];
}

export class CreateEngagorCaseModalController extends ModalBindings<ICreateEngagorCaseModalParams> {

	templates: Array<SearchListGroup<IAlertSubscriptionTemplate>>;
	topics: EngagorTopic[];
	documents: PreviewDocument[];
	template: IAlertSubscriptionTemplate;
	result: ICreateEngagorCaseModalResult;
	metricFormatters: IMetricFormatters;
	channelLabels: ConversationChannelLabels;
	engagorAssignees: EngagorAssignees;
	withHierarchyAssignee: boolean;
	isSuggestionFeatureEnabled: boolean;
	isTodoCaseNotesFeatureEnabled: boolean;
	isTuningMode: boolean;
	readonly MAX_LENGTH: number = 500;
	readonly CASE_TITLE_LIMIT: number = 150;
	readonly TODO: string = '- [ ] ';
	readonly TODO_LIMIT: number = 10;
	readonly TODO_PATTERN: RegExp = new RegExp(`${this.TODO.escapeRegExp()}`, 'g');
	loadingPromise: ng.IPromise<any>;
	loaded: boolean;
	xflowTemplateSelected: boolean;

	caseTitleVariables: SubscriptionTemplateTitleVariable[];

	constructor(
		private security: Security,
		private locale: ILocale,
		private betaFeaturesService: BetaFeaturesService,
		private engagorApiService: EngagorApiService,
		private readonly conversationSettingsApi: ConversationSettingsApi,
		private alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		private analyticPreviewFormatting: AnalyticPreviewFormatting,
		private $timeout: ng.ITimeoutService,
		private $q: ng.IQService,
	) {
		super();
	}

	$onInit(): void {
		this.result = {} as ICreateEngagorCaseModalResult;

		this.isSuggestionFeatureEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.PRODUCT_FEEDBACK);
		this.isTodoCaseNotesFeatureEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.TODO_CASE_NOTES);
		let projectIdentifier = this.resolve.projectIdentifier;
		let channelLabelsCall = PromiseUtils.old(this.conversationSettingsApi.getSpineSettings(this.getProject()));
		let templatesCall = this.alertSubscriptionTemplatesApi.getEnabledTemplates(
			projectIdentifier, this.resolve.workspaceProject, this.resolve.assetParameters);
		let xflowSubscriptionsCall = this.alertSubscriptionTemplatesApi.getXflowSubscriptions(
			projectIdentifier, this.resolve.workspaceProject, this.resolve.assetParameters);

		let topicsCall = this.engagorApiService.getTopics(CacheOptions.CACHED);
		let assigneesCall = this.engagorApiService.getAssignees();

		let formattersCall = this.analyticPreviewFormatting.getProjectSentenceFormatters(projectIdentifier, this.resolve.workspaceProject, true);
		this.loadingPromise = this.$q.all([channelLabelsCall, templatesCall, xflowSubscriptionsCall, topicsCall,
			formattersCall, assigneesCall]).then(results => {
			this.channelLabels = results[0].labels;
			let templates = results[1];
			let xflowSubscriptions = results[2];
			this.topics = results[3];
			this.metricFormatters = results[4];
			this.engagorAssignees = results[5];
			this.processTemplates(this.resolve.type, templates, xflowSubscriptions, this.resolve.documents);
			this.documents = this.resolve.documents;
			this.result.documents = this.getDocuments(this.resolve.documents);
			this.withHierarchyAssignee = this.resolve.type !== EngagorCaseType.SUGGESTION;
			this.loaded = true;
		});
	}

	private getProject(): AccountOrWorkspaceProject {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE) && this.resolve.workspaceProject
			? this.resolve.workspaceProject
			: this.resolve.projectIdentifier;
	}

	isTemplateRequired = () => {
		return this.isCaseInfoRequired();
	};

	private processTemplates = (type: EngagorCaseType, loadedTemplates: IAlertSubscriptionTemplate[],
								xflowSubscriptions: IAlertSubscriptionTemplate[],
								documents: PreviewDocument[]): void => {
		this.templates = [];

		if (type !== EngagorCaseType.SUGGESTION) {
			let newVerbatimTemplates: IAlertSubscriptionTemplate[] =
				loadedTemplates.filter(t => t.type === AlertSubscriptionTemplateType.NEW_VERBATIM);

			let scorecardTemplates: IAlertSubscriptionTemplate[] = loadedTemplates
				.filter(t => t.type === AlertSubscriptionTemplateType.SCORECARD)
				.filter(template => _.contains(this.getScorecardsFromDocuments(documents), template.scorecardId));

			// Multiple documents selected: Gray out scorecard templates if 1+ documents do not have an associated rubric score.
			if (documents.length > 1) {
				scorecardTemplates.forEach(template => {
					template.disabled = !_.every(documents, document => {
						let scorecardsFromDocuments = this.getScorecardsFromDocuments([document]);
						return _.contains(scorecardsFromDocuments, template.scorecardId);
					});
				});
			}

			this.templates.push({
				label: this.locale.getString('alert.newVerbatim'),
				list: newVerbatimTemplates
			});

			this.templates.push({
				label: this.locale.getString('alert.scorecard'),
				list: scorecardTemplates
			});

			if (this.security.isTicketingEnabled()) {
				this.templates.push({
					label: this.locale.getString('alert.xflowScorecard'),
					list: xflowSubscriptions
						.filter(subscription => subscription.type === AlertSubscriptionTemplateType.SCORECARD)
						.filter(subscription => _.contains(this.getScorecardsFromDocuments(documents), subscription.scorecardId))
				});
				this.templates.push({
					label: this.locale.getString('alert.xflowVerbatim'),
					list: xflowSubscriptions.filter(
						subscription => subscription.type === AlertSubscriptionTemplateType.VERBATIM)
				});
			}
		}

		this.isTuningMode = this.isTuningSuggestion(type);
		if (this.isTuningMode) {
			this.templates.push({
				label: this.locale.getString('alertTemplates.tuningSuggestions'),
				list: loadedTemplates.filter(t => t.type === AlertSubscriptionTemplateType.PRODUCT_FEEDBACK)
			});
		}
	};

	private isTuningSuggestion(type: EngagorCaseType = this.resolve.type): boolean {
		return this.isSuggestionFeatureEnabled && type === EngagorCaseType.SUGGESTION;
	}

	isCaseInfoRequired(): boolean {
		return !this.isTuningEmotionOrEffort();
	}

	isEngagorEnabled = (): boolean => this.security.getCurrentMasterAccount().engagorEnabled;

	private isTuningEmotionOrEffort(): boolean {
		return this.isTuningMode && _.any(this.documents,
					(doc) => {
						return [AuditSuggestionEnrichment.EFFORT, AuditSuggestionEnrichment.EMOTION].contains(doc.auditSuggestion?.enrichment);
				});
	}

	private getScorecardsFromDocuments(documents: PreviewDocument[]): number[] {
		return _.chain(documents)
			.map(document => _.pluck(document.attributes, 'name'))
			.flatten()
			.filter(name => ScorecardUtils.isScorecardAttribute(name))
			.map(name => ScorecardUtils.getScorecardId(name))
			.value();
	}

	getAssignee = (): Assignee => {
		return this.result?.documents[0].caseAssignee;
	};

	setAssignee = (assignee: Assignee): void => {
		this.result.documents[0].caseAssignee = assignee;
	};

	clearAssignee = (): void => {
		this.result.documents[0].caseAssignee = null;
	};

	getModalTitle = (): string => {
		return this.resolve.type === EngagorCaseType.REGULAR
			? (this.security.isEngagorIntegrationEnabled()
				? this.locale.getString('docExplorer.caseCreateModalTitle')
				: this.locale.getString('docExplorer.ticketCreateModalTitle'))
			: this.locale.getString('docExplorer.submitSuggestion');
	};

	getNotesHelp = (): string => {
		if (!this.security.isEngagorIntegrationEnabled()) {
			return this.locale.getString('alertTemplates.ticketNotesHelp');
		}
		return this.locale.getString('alertTemplates.notesHelp');
	};

	save = (): void => {
		if (this.hasError()) {
			return;
		}
		this.prepareDocumentsToSave();
		this.close({$value: this.result});
	};

	private prepareDocumentsToSave = (): void => {
		_.each(this.result.documents, document => {
			document.caseTitle = !document.caseTitle ?
				{ text: this.locale.getString('cases.untitledCase') } :
				InboxTemplateTitleUtils.prepareTitleToSave({
					text: document.caseTitle.text,
					variables: this.caseTitleVariables
				});

			// must have a priority
			document.casePriority = document.casePriority || CasePriority.MEDIUM;

			if (this.resolve.type === EngagorCaseType.REGULAR) {
				delete document.auditSuggestion;
			} else {
				document.auditSuggestion = _.omit(document.auditSuggestion, ['verbatim', 'currentOption', 'newOption']) as AuditSuggestion;
			}
		});
	};

	cancel = (): void => {
		this.dismiss();
	};

	selectTemplate = (template: IAlertSubscriptionTemplate): void => {
		this.template = angular.copy(template);
		this.result.templateId = this.template.id;
		this.result.xflowSubscriptionId = this.template.ulid;

		if (!this.isXflow(template)) {
			this.result.isXflow = false;
			this.xflowTemplateSelected = false;
			this.template.caseSettings.priority = this.template.caseSettings.priority || CasePriority.LOW;
			this.caseTitleVariables = InboxTemplateTitleUtils.getAttributeTitleVariables(this.template);
			InboxTemplateTitleUtils.prepareTitleVariablesToView(this.template);
			this.populateDocuments();
		} else {
			this.result.isXflow = true;
			this.xflowTemplateSelected = true;
		}
	};

	private isXflow = (template: IAlertSubscriptionTemplate): boolean => {
		return !!template.xflowMetadata;
	};

	private populateDocuments = (): void => {
		_.each(this.result.documents, document => {
			document.withCaseTitle(this.getDocumentCaseTitle(document.auditSuggestion))
					.withCasePriority(this.template?.caseSettings.priority || CasePriority.LOW)
					.withCaseAssignee(this.template?.caseSettings.assigneeObject);
		});
	};

	private getDocumentCaseTitle = (auditSuggestion?: AuditSuggestion): CaseTitle => {
		return this.isCustomCaseTitle()
			? {text: this.template.caseSettings.customTitle, variables: this.template.caseSettings.titleVariables}
			: {text: auditSuggestion ? this.getAuditSuggestionCaseTitle(auditSuggestion) : this.getDefaultCaseTitle()};
	};

	private isCustomCaseTitle = (): boolean => {
		return this.template?.caseSettings.titleMode === CaseTitleMode.CUSTOM;
	};

	private getDefaultCaseTitle(): string {
		let key = this.security.isEngagorIntegrationEnabled()
			? 'alertTemplates.defaultManualCaseTitle'
			: 'alertTemplates.defaultManualTicketTitle';
		return this.locale.getString(key, {email: this.security.getEmail()});
	}

	private getAuditSuggestionCaseTitle(auditSuggestion: AuditSuggestion): string {
		let { enrichment, currentValue, newValue, currentOption, newOption } = auditSuggestion;
		if (enrichment === AuditSuggestionEnrichment.TOPIC) {
			return !!currentValue
				? this.locale.getString('alertTemplates.defaultTitleForTopicRemoval', {currentValue})
				: this.locale.getString('alertTemplates.defaultTitleForTopicAddition', {newValue});
		}
		if (enrichment === AuditSuggestionEnrichment.SENTIMENT) {
			return this.locale.getString('alertTemplates.defaultTitleForSentiment', {
				currentValue: currentOption.displayName,
				newValue: newOption.displayName
			});
		}
		return this.getDefaultCaseTitle();
	}

	getVariableOutput = (variable: SubscriptionTemplateTitleVariable): string => {
		return InboxTemplateTitleUtils.getVariableDisplayText(variable);
	};

	hasError = (): boolean => {
		return this.isInvalidCase() || this.isInvalidTuning();
	};

	private isInvalidCase(): boolean {
		return !this.isTuningMode &&
			(this.templateMissingError()
			|| this.titleMissingError()
			|| this.titleTooLongError()
			|| this.isNoteInvalid()
			|| this.topicNotAvailable());
	}

	private isInvalidTuning(): boolean {
		return this.isTuningSuggestion && this.notesRequiredError();
	}

	getModalActionName = (): string => {
		return this.locale.getString(this.isTuningMode ? 'common.submit' : 'common.save');
	};

	templateMissingError = (): boolean => {
		return !this.result.templateId;
	};

	titleMissingError = (): boolean => {
		if (this.xflowTemplateSelected) {
			return false;
		}
		return !this.isCustomCaseTitle() && this.documentHasError((doc) => !doc.caseTitle);
	};

	titleTooLongError = (): boolean => {
		if (this.xflowTemplateSelected) {
			return false;
		}
		return !this.isCustomCaseTitle() && this.documentHasError((doc) => doc.caseTitle && doc.caseTitle.text.length > this.CASE_TITLE_LIMIT);
	};

	private isNoteInvalid(): boolean {
		return this.notesTooLongError() || this.notesRequiredError();
	}

	notesTooLongError = (): boolean => {
		return this.documentHasError((doc) => doc.notes?.length > this.MAX_LENGTH);
	};

	notesRequiredError = (): boolean => {
		return this.isTuningMode && this.documentHasError(doc => !doc.notes?.length);
	};

	topicNotAvailable = (): boolean => {
		return this.template?.topicId && !_.findWhere(this.topics, {id: this.template.topicId });
	};

	getPreviewText = (document: CbDocument): string => {
		let text = '';
		for (let sentence of document.sentences) {
			text += ' ' + sentence.text;
			if (text.length > 500) {
				return text.substring(0, 500) + '...';
			}
		}
		return text;
	};

	private getDocuments = (documents: PreviewDocument[]): EngagorCreateCaseRequestDocument[] => {
		return _.map(documents, (document) => {
			return new EngagorCreateCaseRequestDocument()
				.withDocumentId(document.id)
				.withNaturalId(document.natural_id)
				.withAuditSuggestion(document.auditSuggestion)
				.withNotes('');
		});
	};

	private documentHasError = (predicate: (doc: EngagorCreateCaseRequestDocument) => boolean): boolean => {
		return _.any(this.result.documents, predicate);
	};

	// if locks field doesn't exist condition could be removed in 139th sprint
	isTitleLocked = (): boolean => {
		return !this.template?.caseSettings.locks || this.template?.caseSettings?.locks.title;
	};

	// if locks field doesn't exist condition could be removed in 139th sprint
	isPriorityLocked = (): boolean => {
		return !this.template?.caseSettings.locks || this.template?.caseSettings.locks.priority;
	};

	// if locks field doesn't exist condition could be removed in 139th sprint
	isAssigneeLocked = (): boolean => {
		return !this.template?.caseSettings.locks || this.template?.caseSettings.locks.assignee;
	};

	isTemplateDisabled = (template: IAlertSubscriptionTemplate): boolean => {
		return !!template.disabled;
	};

	getTemplateDisabledTooltip = (template: IAlertSubscriptionTemplate): string => {
		// The Scorecard Attribute name is hardcoded because it is the cheapest way to get it at the moment
		let scorecardAttributeName = template.name.replace('Inbox Template', 'Score');
		return this.locale.getString('alertTemplates.templateDisabledTooltip', { attributeName: scorecardAttributeName });
	};

	addToDo(): void {
		if (this.result.documents.length === 1) {
			this.$timeout(() => {
				angular.element('#caseNotes').trigger('focus');
				let note = this.result.documents[0].notes;
				if (!this.isEmptyOrNewLine(note)) {
					note += '\n';
				}
				this.result.documents[0].notes = note + this.TODO;
			}, 100);
		}
	}

	getToDoTitle(): string {
		return this.isToDoLimitReached()
				? this.locale.getString('alertTemplates.todoLimit')
				: this.locale.getString('alertTemplates.todo');
	}

	isToDoLimitReached(): boolean {
		let note = this.result.documents[0].notes;
		const amount = (note.match(this.TODO_PATTERN) || []).length;
		return amount >= this.TODO_LIMIT;
	}

	private isEmptyOrNewLine(note: string): boolean {
		return note.trim().length === 0 || note.endsWith('\n');
	}
}

app.component('createEngagorCaseModal', {
	controller: CreateEngagorCaseModalController,
	templateUrl: 'partials/document-explorer/create-engagor-case-modal.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
});
