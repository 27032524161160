import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ExternalApplicationsApiService } from '@app/modules/system-administration/oauth/service/external-applications-api.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'tuning-suggestion-integration',
	template: `
<form [formGroup]="tuningSuggestionIntegration">
	<article class="rounded-grouping">
		<div class="rounded-grouping-label">
			<div class="labeled-checkbox">
				<checkbox-button
					class="mr-0"
					id="enableTuningSuggestion"
					name="enableTuningSuggestion"
					label="{{'mAccount.enableTuningSuggestion'|i18n}}"
					formControlName="enabled">
				</checkbox-button>
			</div>
		</div>
		<div class="form-group">
			<label class="control-label col-md-3">{{'mAccount.application'|i18n}}</label>
			<div class="col-md-8">
				<simple-dropdown
					[options]="tuningSuggestionApplications"
					formControlName="applicationId"
					valueField="id"
					displayField="name"
					allowClear="true">
				</simple-dropdown>
				<span *ngIf="applicationId?.errors?.required" class="text-danger"><span class="q-icon-warning" aria-hidden="true"></span>
					{{ formUtils.getErrorMessage('mAccount.application') }}
				</span>
			</div>
		</div>
	</article>
</form>`,
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TuningSuggestionSettingsIntegrationComponent extends SelfCleaningComponent implements OnInit {

	tuningSuggestionApplications: ExternalApplication[];
	tuningSuggestionIntegration: UntypedFormGroup;

	constructor(readonly formUtils: CxFormUtils, private fb: UntypedFormBuilder,
		private externalApplicationsApiService: ExternalApplicationsApiService,
		private ref: ChangeDetectorRef
	) {
		super();
		this.tuningSuggestionIntegration = this.fb.group({
			enabled: [ false ],
			applicationId: [{ value: null, disabled: true }, [ Validators.required, Validators.min(1) ]],
		});

		this.addSubscription(this.tuningSuggestionIntegration.get('enabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.applicationId);
				this.tuningSuggestionIntegration.updateValueAndValidity();
			} else {
				this.formUtils.disableFields(this.applicationId);
				this.tuningSuggestionIntegration.updateValueAndValidity();
			}
		}));
	}

	ngOnInit(): void {
		this.externalApplicationsApiService.getApplicationsByType(ExternalApplicationType.TUNING_SUGGESTION).then(response => {
			this.tuningSuggestionApplications = response;
			this.ref.markForCheck();
		});
	}

	getGroup(): UntypedFormGroup {
		return this.tuningSuggestionIntegration;
	}

	get applicationId(): UntypedFormControl {
		return this.tuningSuggestionIntegration.controls.applicationId as UntypedFormControl;
	}

}
