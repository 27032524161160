import { Injectable } from '@angular/core';
import { MetricType } from '../entities/metric-type';
import { CxLocaleService } from '@app/core';
import { ListOption } from '@app/shared/components/forms/list-option';
import { MetricDefinition } from '@app/modules/metric/entities/metric-definition';
import { ColorMetricDefinition, MetricCalculation } from '@app/modules/metric/definition/color-metric-definition';
import { BoxMetricDefinition } from '@app/modules/metric/definition/box-metric-definition';
import { PredefinedMetricDefinition } from '@app/modules/metric/definition/predefined-metric-definition';
import { IColorGrades } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import { MetricThresholdRules, ThresholdRule } from '@cxstudio/reports/utils/color/metric-threshold-rules.service';


export class MetricObject {
	displayName: string;
	getDefaults?: () => MetricDefinition;
	getColorData?: (definition) => IColorGrades;
	getCalculation?: (definition?) => MetricCalculation;
	getCalculationColorData?: (definition) => IColorGrades;
}


@Injectable({
	providedIn: 'root'
})
export class MetricTypesService {

	readonly DEFAULT_THRESHOLDS = [0.66];
	readonly DEFAULT_SATISFACTION_THRESHOLDS = [-0.33, 0.33];

	private metricObjects: {[key: string]: MetricObject};

	constructor(
		private locale: CxLocaleService,
	) {
		this.metricObjects = {
			tb: this.topBoxMetric(),
			bb: this.bottomBoxMetric(),
			nps: this.satisfactionMetric(),
			filter: this.filterMetric(),
			variable: this.variableMetric(),
			custom: this.customMathMetric(),
			ease: this.effortMetric(),
			sentiment: this.sentimentMetric(),
			numeric_breakdown: this.emotionMetric()
		};
	}

	getUserMetricTypes = (): ListOption<MetricType>[] => {
		return [{
			value: MetricType.TOP_BOX,
			name: this.locale.getString('metrics.topBoxType')
		}, {
			value: MetricType.BOTTOM_BOX,
			name: this.locale.getString('metrics.bottomBoxType')
		}, {
			value: MetricType.SATISFACTION,
			name: this.locale.getString('metrics.satisfactionType')
		}, {
			value: MetricType.FILTER,
			name: this.locale.getString('metrics.filterMetric')
		}, {
			value: MetricType.VARIABLE,
			name: this.locale.getString('metrics.variable')
		}, {
			value: MetricType.CUSTOM_MATH,
			name: this.locale.getString('metrics.custom')
		}];
	};

	getMetricObjectByType = (type: MetricType): MetricObject => this.metricObjects[type];

	getDefaultDefinition = (type: MetricType): MetricDefinition => {
		let metricObject = this.metricObjects[type];
		let defaults = metricObject.getDefaults();
		let calculation = metricObject.getCalculation?.();
		return _.extend(defaults, { calculation });
	};

	getDefaultMetricDisplayName = (type: MetricType): string => this.metricObjects[type].displayName;

	private validateCalculation = (calculation: MetricCalculation): boolean => {
		if (!calculation)
			return false;
		if (!calculation.colorPalette || !calculation.thresholds)
			return false;
		if (calculation.colorPalette.length !== calculation.thresholds.length + 1)
			return false;
		return true;
	};

	private calculationFunc = (defaults: MetricCalculation) => ((definition?: ColorMetricDefinition) => {
		if (!definition || !this.validateCalculation(definition.calculation)) {
			return defaults;
		} else {
			return definition.calculation;
		}
	});

	private getBaseCalculationColorData = (definition: ColorMetricDefinition): IColorGrades => ({
		thresholds: [...definition.calculation.thresholds],
		colorPalette: [...definition.calculation.colorPalette],
		thresholdRules: _.map(definition.calculation.thresholds, (th) => ThresholdRule.LEFT_INCLUSIVE)
	});

	private topBoxMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.topBoxDefaultTitle'),
		getDefaults: () => ({
			type: MetricType.TOP_BOX,
			min: 0,
			max: 10,
			topThreshold: 9,
			topColor: ColorConstants.GREEN,
			otherColor: ColorConstants.GREY,
			topDisplayName: this.locale.getString('metrics.topBoxDefaultName'),
			otherDisplayName: this.locale.getString('metrics.otherBoxDefaultName')
		}),
		getColorData: (definition: BoxMetricDefinition) => ({
			thresholds: [definition.topThreshold],
			colorPalette: [definition.otherColor, definition.topColor],
			thresholdRules: [ThresholdRule.RIGHT_INCLUSIVE],
			displayNames: [definition.otherDisplayName, definition.topDisplayName],
			defaultNames: [this.locale.getString('metrics.otherBoxDefaultName'), this.locale.getString('metrics.topBoxDefaultName')]
		}),
		getCalculation: this.calculationFunc({
			thresholds: [...this.DEFAULT_THRESHOLDS],
			colorPalette: [ColorConstants.GREY, ColorConstants.GREEN],
			min: 0,
			max: 1
		}),
		getCalculationColorData: this.getBaseCalculationColorData
	});

	private bottomBoxMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.bottomBoxDefaultTitle'),
		getDefaults: () => ({
			type: MetricType.BOTTOM_BOX,
			min: 0,
			max: 10,
			bottomThreshold: 6,
			bottomColor: ColorConstants.RED,
			otherColor: ColorConstants.GREY,
			bottomDisplayName: this.locale.getString('metrics.bottomBoxDefaultName'),
			otherDisplayName: this.locale.getString('metrics.otherBoxDefaultName')
		}),
		getColorData: (definition: BoxMetricDefinition) => ({
			thresholds: [definition.bottomThreshold],
			colorPalette: [definition.bottomColor, definition.otherColor],
			thresholdRules: [ThresholdRule.LEFT_INCLUSIVE],
			displayNames: [definition.bottomDisplayName, definition.otherDisplayName],
			defaultNames: [this.locale.getString('metrics.bottomBoxDefaultName'), this.locale.getString('metrics.otherBoxDefaultName')]
		}),
		getCalculation: this.calculationFunc({
			thresholds: [...this.DEFAULT_THRESHOLDS],
			colorPalette: [ColorConstants.GREY, ColorConstants.RED],
			min: 0,
			max: 1
		}),
		getCalculationColorData: this.getBaseCalculationColorData
	});

	private satisfactionMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.satisfactionDefaultTitle'),
		getDefaults: () => ({
			type: MetricType.SATISFACTION,
			min: 0,
			max: 10,
			bottomThreshold: 6,
			topThreshold: 9,
			topColor: ColorConstants.GREEN,
			bottomColor: ColorConstants.RED,
			middleColor: ColorConstants.GREY,
			topDisplayName: this.locale.getString('metrics.topBoxDefaultName'),
			bottomDisplayName: this.locale.getString('metrics.bottomBoxDefaultName'),
			middleDisplayName: this.locale.getString('metrics.neutralBoxDefaultName')
		}),
		getColorData: (definition: BoxMetricDefinition) => ({
			thresholds: [definition.bottomThreshold, definition.topThreshold],
			colorPalette: [definition.bottomColor, definition.middleColor, definition.topColor],
			thresholdRules: [ThresholdRule.LEFT_INCLUSIVE, ThresholdRule.RIGHT_INCLUSIVE],
			displayNames: [definition.bottomDisplayName, definition.middleDisplayName, definition.topDisplayName],
			defaultNames: [this.locale.getString('metrics.bottomBoxDefaultName'),
				this.locale.getString('metrics.neutralBoxDefaultName'), this.locale.getString('metrics.topBoxDefaultName')]
		}),
		getCalculation: this.calculationFunc({
			thresholds: [...this.DEFAULT_SATISFACTION_THRESHOLDS],
			colorPalette: [ColorConstants.RED, ColorConstants.GREY, ColorConstants.GREEN],
			min: -1,
			max: 1
		}),
		getCalculationColorData: this.getBaseCalculationColorData
	});

	private filterMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.filterMetric'),
		getDefaults: () => ({
			type: MetricType.FILTER,
			rule: {
				type: FilterTypes.AND,
				filterRules: [FilterEmptyObject.RULE]
			}
		}),
		getCalculation: this.calculationFunc({
			thresholds: [...this.DEFAULT_THRESHOLDS],
			colorPalette: [ColorConstants.GREY, ColorConstants.GREEN],
			min: 0,
			max: 1
		}),
		getCalculationColorData: this.getBaseCalculationColorData
	});

	private variableMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.variable'),
		getDefaults: () => ({
			type: MetricType.VARIABLE,
			defaultValue: 42
		})
	});

	private customMathMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.customMetricDefaultName'),
		getDefaults: () => ({
			type: MetricType.CUSTOM_MATH,
			mathComponents: [],
			replaceNullsWithZeroes: false
		}),
		getCalculation: this.calculationFunc({
			thresholds: [...this.DEFAULT_THRESHOLDS],
			colorPalette: [ColorConstants.GREY, ColorConstants.GREEN],
			min: 0,
			max: 1
		}),
		getCalculationColorData: this.getBaseCalculationColorData
	});

	private getPredefinedMetricDisplayNames = (definition: PredefinedMetricDefinition) =>
		_.map(definition.displayNames, name => this.locale.getString('metrics.' + definition.name + '_' + name));

	private getPredefinedColorData = (definition: PredefinedMetricDefinition, extension): IColorGrades => {
		let base = {
			thresholds: [...definition.thresholds],
			colorPalette: [...definition.colorPalette],
			displayNames: this.getPredefinedMetricDisplayNames(definition)
		};
		return _.extend(base, extension);
	};

	private getPredefinedCalculationColorData = (definition: PredefinedMetricDefinition, extension): IColorGrades => {
		let base = this.getBaseCalculationColorData(definition);
		base.displayNames = this.getPredefinedMetricDisplayNames(definition);
		return _.extend(base, extension);
	};

	private effortMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.easeScore'),
		getColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedColorData(definition, {
			thresholdRules: MetricThresholdRules.getEaseRules(),
			dividers: [false, true, true, false]
		}),
		getCalculationColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedCalculationColorData(definition, {
			thresholdRules: MetricThresholdRules.getEaseRules(),
			dividers: [false, true, true, false]
		})
	});

	private sentimentMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.sentiment'),
		getColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedColorData(definition, {
			thresholdRules: MetricThresholdRules.getSentimentGroupRules(),
			dividers: [false, true, true, false]
		}),
		getCalculationColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedCalculationColorData(definition, {
			thresholdRules: MetricThresholdRules.getSentimentCalculationRules(),
			dividers: [false, true, true, false]
		})
	});

	private emotionMetric = (): MetricObject => ({
		displayName: this.locale.getString('metrics.emotion'),
		getColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedColorData(definition, {
			thresholdRules: MetricThresholdRules.getNumericBreakdownGroupRules(),
			dividers: [true, true]
		}),
		getCalculationColorData: (definition: PredefinedMetricDefinition) => this.getPredefinedCalculationColorData(definition, {
			thresholdRules: MetricThresholdRules.getNumericBreakdownCalculationRules(),
			dividers: [false, false]
		})
	});

}
