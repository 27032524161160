import { ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import * as _ from 'underscore';
import { HighchartsStackedUtilService } from '@app/modules/reports/utils/highcharts-stacked-util.service';

export class ReportDefinitionInitializers {

	constructor(private highchartsStackedUtil: HighchartsStackedUtilService) {}

	initializeSize = (reportDefinition: ReportDefinitionScope) => {
		let utils = reportDefinition.utils;
		if (_.isUndefined(utils.sizeFunction)) {
			utils.sizeFunction = item => 20;
		}
	};

	initializeNormalizedSize = (reportDefinition: ReportDefinitionScope, min: number, max: number) => {
		this.initializeSize(reportDefinition);
		let originalSizeFn = reportDefinition.utils.sizeFunction;
		let minSize = Number.POSITIVE_INFINITY;
		let maxSize = Number.NEGATIVE_INFINITY;
		_.each(reportDefinition.dataObject.data, point => {
			let size = point ? originalSizeFn(point) : 0;
			if (size > maxSize)
				maxSize = size;
			if (size < minSize)
				minSize = size;
		});
		if (Number.isFinite(minSize) && Number.isFinite(maxSize)) {
			reportDefinition.utils.sizeFunction = point => {
				if (reportDefinition.options.size === StandardMetricName.CONSTANT_SIZE)
					return reportDefinition.options.scale || min;
				let size = point && originalSizeFn(point);
				if (isNaN(size))
					return min;
				if (minSize === maxSize)
					return max;
				return (size - minSize) / (maxSize - minSize) * (max - min) + min;
			};
		}
	};

	initializeDualChart = (reportDefinition: ReportDefinitionScope) => {

		if (_.isUndefined(reportDefinition.options.yAxis)) {
			reportDefinition.options.yAxis = 'volume';
		}

		this.initializeSize(reportDefinition);

		if (!_.isUndefined(reportDefinition.options.sortBy)) {
			let dir = reportDefinition.options.direction ? reportDefinition.options.direction ===
				'ASC' : false;
			this.highchartsStackedUtil.gridSingleSorter(reportDefinition.options.sortBy,
				dir, reportDefinition.dataObject.data);
		}
	};

}

app.service('reportDefinitionInitializers', ReportDefinitionInitializers);
