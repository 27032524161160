import { ContentProviderId } from '@cxstudio/generic-types';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { IContentProvider } from '../entities/content-provider';

export class ContentProviderService {
	contentProviders;
	cpMap: {[key: string]: IContentProvider} = {};

	constructor(
		private readonly $log,
		private readonly $q,
		private readonly contentProviderOptionsService: ContentProviderOptionsService,
		private readonly ContentProvider
	) {}

	clear = () => {
		this.contentProviders = undefined;
		this.cpMap = {};
	};

	getContentProvider = (cpId: ContentProviderId): ng.IPromise<IContentProvider> => {
		if (!this.contentProviders) {
			let defer = this.$q.defer();
			this.contentProviderOptionsService.getContentProviders().then((response) => {
				let cps = response && response.data;
				defer.resolve(_.map(cps, (cp) => {
					if (!this.cpMap[cp.id])
						this.cpMap[cp.id] = new this.ContentProvider(cp);
					return this.cpMap[cp.id];
				}));
			}, (reason) => {
				this.$log.warn(reason);
				defer.resolve([]);
			});

			this.contentProviders = defer.promise;
		}

		let result = this.$q.defer();
		this.contentProviders.then((cps) => {
			result.resolve(_.findWhere(cps, {id: cpId}));
		});
		return result.promise;
	};
}

app.service('contentProviderService', ContentProviderService);
