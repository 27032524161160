import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';

export class GenericRadioButtons implements ng.IComponentController {

	options: UIOption<any>[];
	id: string;
	ngModel: ng.INgModelController;

	constructor() {	}

	$onInit = (): void => {

	};

	getId = (option: UIOption<any>, index: number): string => {
		if (option.htmlId) return option.htmlId;

		return `${this.id}_${index}`;
	};

	getLabelContent = (option) => {
		if (option.htmlLabel) return option.htmlLabel;

		return option.displayName;
	};
}

app.component('genericRadioButtons', {
	bindings: {
		inline: '@',
		id: '@',
		ngChange: '&',
		options: '<',
		ngModel: '=',
		ngDisabled: '<'
	},
	controller: GenericRadioButtons,
	template: `
<fieldset ng-disabled="$ctrl.ngDisabled">
	<div class="labeled-radio"
		ng-class="::{'labeled-radio-inline': $ctrl.inline}"
		ng-repeat="option in $ctrl.options"
		class="{{::option.class}}">
		<input type="radio"
			id="{{::$ctrl.getId(option, $index)}}"
			value="{{::option.value}}"
			ng-model="$ctrl.ngModel"
			ng-change="$ctrl.ngChange({value: option.value})"
			ng-disabled="$ctrl.ngDisabled">
		<label for="{{::$ctrl.getId(option, $index)}}" class="{{::option.labelClasses}}">
			<span ng-bind-html="::$ctrl.getLabelContent(option)"></span>
		</label>
	</div>
</fieldset>`
});
