import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { BookTabType } from '@cxstudio/dashboards/entity/book';

@Component({
	selector: 'book-tab-info-label',
	template: `
	<div class="d-flex flex-singleline overflow-hidden">
		<i class="q-icon-info pr-4"></i>
		<span class="pr-4" [i18n]="'dashboard.tabLinkingLabel'" [i18nParams]="{tabName: tabName}"></span>
		<div *ngIf="type === 'DASHBOARD'"
			class="flex-shrink overflow-hidden">
			<span *ngIf="dashboard"
				class="__TEST-tab-dashboard-label cursor-pointer text-ellipsis d-block w-100-percent"
				(click)="labelClick.emit()"
				[i18n]="'dashboard.tabCurrentlyConnected'"
				[i18nParams]="{dashboardName: dashboard.name}">
			</span>
			<span *ngIf="!dashboard"
				class="text-ellipsis d-block w-100-percent"
				[i18n]="'dashboard.tabDashboardNotAvailable'">
			</span>
		</div>
	</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class BookTabInfoLabelComponent {

	@Input() tabName: string;
	@Input() type: BookTabType;
	@Input() dashboard: Dashboard;
	@Output() labelClick = new EventEmitter<void>();

}

app.directive('bookTabInfoLabel', downgradeComponent({component: BookTabInfoLabelComponent}));
