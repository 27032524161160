import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import ChartType from '@cxstudio/reports/entities/chart-type';
import * as _ from 'underscore';
import * as $ from 'jquery';
import { MetricReferenceLinesUtils } from '../reference-lines/metric-reference-lines-utils.service';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { Injectable } from '@angular/core';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import PlotBandsOptions from '@app/modules/plot-lines/plot-bands/plot-bands-options';
import PlotBand from '@app/modules/plot-lines/plot-bands/plot-band';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TimeReferenceLinesUtils } from '@app/modules/plot-lines/reference-lines/time-reference-lines-utils.service';
import ReferenceLine from '@app/modules/plot-lines/reference-lines/reference-line';
import TimeReferenceType from '@app/modules/plot-lines/reference-lines/time-reference-line-type';
import TimeReferenceLine from '@app/modules/plot-lines/reference-lines/time-reference-line';

interface IPoint {
	x: number;
	y: number;
}

export interface IPlotBandSeries extends Highcharts.SeriesPolygonOptions {
	ignoreWhenScaling?: boolean;
}

const ALMOST_INFINITY = 1e18; // really big number, infinity is not suitable here :(

const BANDS_ZINDEX = -5;
const GRID_ZINDEX = -2;
const LABELS_ZINDEX = -1;

@Injectable({
	providedIn: 'root'
})
export class ChartPlotUtils {

	constructor(
		private readonly timeReferenceLinesUtils: TimeReferenceLinesUtils,
		private readonly metricReferenceLinesUtils: MetricReferenceLinesUtils
	) { }

	processPlotLines = (options: VisualProperties,
		utils: WidgetUtils, chartOptions: Highcharts.Options, totals: {[key: string]: number}): void => {

		let isHorizontalBar: boolean = options.subChartType === ChartType.BAR;
		let isScatter: boolean = utils.widgetType === WidgetType.SCATTER;

		_.each(options.referenceLines, refLine => {
			let axis: Highcharts.AxisOptions = this.metricReferenceLinesUtils.getAxis(chartOptions, refLine.axis, isScatter);

			let value = refLine.dynamic ? totals[refLine.dynamic.name] : refLine.value;

			let metricReferencePlotLine = this.metricReferenceLinesUtils.buildMetricReferenceLine(refLine, value);
			this.addPlotLine(axis, metricReferencePlotLine);

			if (!isEmpty(refLine.label)) {
				let labelLine: Highcharts.AxisPlotLinesOptions =
					this.metricReferenceLinesUtils.buildMetricReferenceLabelLine(refLine, isHorizontalBar, isScatter, value);
				this.addPlotLine(axis, labelLine);
			}
		});

		if (options.plotBandsEnabled && isScatter) {
			_.each([PlotLineAxis.primary, PlotLineAxis.secondary], axisType => {
				let axis = this.metricReferenceLinesUtils.getAxis(chartOptions, axisType, isScatter);
				_.each(options.plotBands.lines[axisType], value => {
					let refLine = new ReferenceLine(value, options.plotBands.lineStyle);
					this.addPlotLine(axis, this.metricReferenceLinesUtils.buildMetricReferenceLine(refLine, value));
				});
			});
		}
	};

	processTimeReferences = (
		options: VisualProperties, utils: WidgetUtils, chartOptions: Highcharts.Options, hierarchyData: any[]
	): void => {
		_.each(options.timeReferenceLines, timeRefLine => {
			let axis: Highcharts.AxisOptions = this.timeReferenceLinesUtils.getAxis(chartOptions);

			let plotOptions: Highcharts.AxisPlotLinesOptions | Highcharts.AxisPlotBandsOptions;
			switch (timeRefLine.timeReferenceType) {
				case TimeReferenceType.EVENT:
					plotOptions = this.addTimeReferencePlotLine(timeRefLine, hierarchyData, options, utils, axis);
					break;
				case TimeReferenceType.SPAN:
					plotOptions = this.addTimeReferencePlotBand(timeRefLine, hierarchyData, options, utils, axis);
					break;
			}

			if (plotOptions && !isEmpty(timeRefLine.label)) {
				this.addTimeReferenceLineLabel(timeRefLine, hierarchyData, options, utils, axis);
			}
		});
	};

	private addTimeReferencePlotLine = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties,
		utils: WidgetUtils, axis: Highcharts.AxisOptions
	): Highcharts.AxisPlotLinesOptions => {
		let eventPlotLine: Highcharts.AxisPlotLinesOptions =
			this.timeReferenceLinesUtils.buildEvent(timeRefLine, hierarchyData, options, utils);
		if (eventPlotLine) {
			this.addPlotLine(axis, eventPlotLine);
		}

		return eventPlotLine;
	};

	private addTimeReferencePlotBand = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties,
		utils: WidgetUtils, axis: Highcharts.AxisOptions
	): Highcharts.AxisPlotBandsOptions => {
		let spanPlotBand: Highcharts.AxisPlotBandsOptions = this.timeReferenceLinesUtils.buildSpan(
			timeRefLine, hierarchyData, options, utils);
		if (spanPlotBand) {
			this.addPlotBand(axis, spanPlotBand);
		}
		return spanPlotBand;
	};

	private addTimeReferenceLineLabel = (
		timeRefLine: TimeReferenceLine, hierarchyData: any[], options: VisualProperties,
		utils: WidgetUtils, axis: Highcharts.AxisOptions
	): void => {
		switch (timeRefLine.timeReferenceType) {
			case TimeReferenceType.EVENT:
				let eventLabelLine = this.timeReferenceLinesUtils.buildEventLabelLine(
					timeRefLine, hierarchyData, options, utils);
				this.addPlotLine(axis, eventLabelLine);
				break;
			case TimeReferenceType.SPAN:
				let spanLabelBand = this.timeReferenceLinesUtils.buildSpanLabelLine(
					timeRefLine, hierarchyData, options, utils);
				this.addPlotBand(axis, spanLabelBand);
				break;
		}
	};

	private addPlotLine(axis: Highcharts.AxisOptions, plotLine: Highcharts.AxisPlotLinesOptions): void {
		if (axis) {
			if (!axis.plotLines)
				axis.plotLines = [];
			axis.plotLines.push(plotLine);
		}
	}

	private addPlotBand(axis: Highcharts.AxisOptions, plotBand: Highcharts.AxisPlotBandsOptions): void {
		if (axis) {
			if (!axis.plotBands)
				axis.plotBands = [];
			axis.plotBands.push(plotBand);
		}
	}

	processPlotBands = (options: VisualProperties, utils: WidgetUtils, chartOptions: Highcharts.Options, element: any): void => {
		if (!options.plotBandsEnabled || utils.widgetType !== WidgetType.SCATTER)
			return;
		this.configureZIndex(chartOptions);

		let plotBands = options.plotBands;
		// vertical goes from +infinity to -infinity top->bottom
		let verticalRanges = [ALMOST_INFINITY].concat(plotBands.lines.primary).concat([-ALMOST_INFINITY]);
		let horizontalRanges = [-ALMOST_INFINITY].concat(plotBands.lines.secondary).concat([ALMOST_INFINITY]);
		for (let i = 0; i < verticalRanges.length - 1; i++) {
			for (let j = 0; j < horizontalRanges.length - 1; j++) {
				let bandTopLeft = {x: horizontalRanges[j], y: verticalRanges[i]};
				let bandBottomRight = {x: horizontalRanges[j + 1], y: verticalRanges[i + 1]};
				let bandIndex = i * (plotBands.lines.primary.length + 1) + j;
				let bandSeries = this.generatePolygonSeries(bandTopLeft, bandBottomRight,
					plotBands.bands[bandIndex].backgroundColor);
				chartOptions.series.push(bandSeries);
			}
		}
		this.addPlotBandLabels(plotBands, chartOptions, element);
	};

	private configureZIndex(chartOptions: Highcharts.Options): void {
		(chartOptions.chart as any).advancedZIndex = true; // to enable super hacks, see highcharts-extensions
		(chartOptions.yAxis[0] as Highcharts.AxisOptions).gridZIndex = GRID_ZINDEX;
		(chartOptions.xAxis as Highcharts.AxisOptions).gridZIndex = GRID_ZINDEX;
	}

	private generatePolygonSeries(p1: IPoint, p2: IPoint, color: string): IPlotBandSeries {
		return {
			name: '',
			type: 'polygon',
			data: [[p1.x, p1.y], [p1.x, p2.y], [p2.x, p2.y], [p2.x, p1.y]],
			color,
			showInLegend: false,
			enableMouseTracking: false,
			ignoreWhenScaling: true,
			zIndex: BANDS_ZINDEX
		};
	}

	private addPlotBandLabels(plotBands: PlotBandsOptions, chartOptions: Highcharts.Options, element: any): void {
		// highly coupled to 4 quadrants options, need to rewrite for ninth
		let xAxis = chartOptions.xAxis as Highcharts.AxisOptions;
		xAxis.plotBands = xAxis.plotBands || [];

		let xDivider = plotBands.lines.secondary[0];
		let index = 0;
		_.each(['top', 'bottom'], verticalAlign => {
			_.each(['left', 'right'], horizontalAlign => {
				let highchartsPlotBand = this.generatePlotBand(plotBands.bands[index++], plotBands.fontSize,
					xDivider, verticalAlign, horizontalAlign);
				if (highchartsPlotBand)
					xAxis.plotBands.push(highchartsPlotBand);
			});
		});
		// xAxis visibility is for free due to locating bands on xAxis
		this.addVisibilityHandlers(plotBands.lines.primary[0], chartOptions, element);
	}

	private generatePlotBand(band: PlotBand, fontSize: number, x: number, vAlign: any, hAlign: any): Highcharts.AxisPlotBandsOptions {
		if (!band.text)
			return null;
		let isLeft = hAlign === 'left';

		let from = isLeft ? -ALMOST_INFINITY : x;
		let to = isLeft ? x : ALMOST_INFINITY;

		let PADDING = 3;
		let xOffset = isLeft ? PADDING : -PADDING;
		let yOffset = vAlign === 'top' ? fontSize : -PADDING;

		let label = {
			text: band.text,
			verticalAlign: vAlign,
			align: hAlign,
			textAlign: hAlign,
			x: xOffset,
			y: yOffset,
			className: 'quadrant' + vAlign.capitalizeFirstLetter() + hAlign.capitalizeFirstLetter(),
			style: {
				color: band.textColor,
				fontSize: fontSize + 'px'
			}
		};
		return {
			color: 'transparent',
			from,
			to,
			label,
			zIndex: LABELS_ZINDEX
		};
	}

	private addVisibilityHandlers(threshold: number, chartOptions: Highcharts.Options, element: any): void {
		if (!chartOptions.chart.events)
			chartOptions.chart.events = {};

		let originalLoadHandler = chartOptions.chart.events.load;
		chartOptions.chart.events.load = function(event): void { // eslint-disable-line prefer-arrow-callback
			let chart = this as Highcharts.Chart; // eslint-disable-line no-invalid-this, @typescript-eslint/no-this-alias
			checkExtremes(chart.yAxis[0].getExtremes());
			if (originalLoadHandler)
				originalLoadHandler.call(chart, event);
		};

		// required to hide top-bottom bands depending on zoom
		let xAxis = chartOptions.xAxis as Highcharts.AxisOptions;
		xAxis.events = xAxis.events || {};

		xAxis.events.afterSetExtremes = (event: any): void => {
			let yAxis = event.target.chart.axes[1];

			checkExtremes(yAxis);
		};

		function checkExtremes(extremes: Highcharts.ExtremesObject): void {
			// left-right sides are hidden automatically due to using plot bands
			// but there is no xy plot bands, so top-bottom are handled manually
			let TOP_HIDDEN_CLASS = 'plot-bands-top-hidden';
			let BOTTOM_HIDDEN_CLASS = 'plot-bands-bottom-hidden';
			if (extremes) {
				let min = extremes.min;
				let max = extremes.max;
				if (threshold < min) {
					$(element).addClass(BOTTOM_HIDDEN_CLASS);
				} else if (threshold > max) {
					$(element).addClass(TOP_HIDDEN_CLASS);
				} else {
					$(element).removeClass(TOP_HIDDEN_CLASS + ' ' + BOTTOM_HIDDEN_CLASS);
				}
			} else {
				$(element).removeClass(TOP_HIDDEN_CLASS + ' ' + BOTTOM_HIDDEN_CLASS);
			}
		}
	}
}

app.service('chartPlotUtils', downgradeInjectable(ChartPlotUtils));
