import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { RandomUtils } from '@app/util/random-utils.class';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { Subscription } from 'rxjs';
import { CustomDomSharedStylesHost } from '@app/core/CSP/shared-styles-host.service';

export interface ISearchTerm {
	name: string;
	displayName: string;
	attribute: AttributeGrouping;
}

export class SelectorWidgetSearch implements ng.IComponentController {
	properties: WidgetProperties;
	visualProps: VisualProperties;
	onSearch: (params: {$items: ISearchTerm[]}) => void;
	initialSelection: ISearchTerm[];
	demo: boolean;
	selectorSearch: string = '';
	sortedValues: any[];

	selection: {
		multiValues: any[];
	};
	//dropdownContainer: string; - to use with new multiselect
	dropdownContainer: ng.IAugmentedJQuery;

	styleId: string;
	style: JQuery;

	searchTimeout = null;

	customColorChangeSubscription: Subscription;

	constructor(
		private $scope: ISimpleScope,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService,
		private realDataPreviewService: RealDataPreviewService,
		private customDomSharedStylesHost: CustomDomSharedStylesHost,
	) {}

	$onInit = () => {
		this.selection = {
			multiValues: []
		};
		if (this.initialSelection) {
			this.selection.multiValues = _.map(this.initialSelection, item => ({
				name: item.name,
				displayName: item.displayName,
				attributeName: item.attribute.name
			}));
		}

		if (!this.demo) {
			//this.dropdownContainer = 'body'; - to use with new multiselect
			this.dropdownContainer = this.$element.parents('.gridster-wrapper');
			if (this.dropdownContainer.length === 0)
				this.dropdownContainer = angular.element('body');
		}
		this.styleId = `s-${RandomUtils.randomString()}`;
		this.addStyles(this.styleId);

		this.searchTimeout = null;
		this.$scope.$on('selector:clearSelections', this.clearSelections);

		if (this.demo && this.realDataPreviewService.showRealDataPreview(this.properties?.widgetType)) {
			this.customColorChangeSubscription = this.realDataPreviewService.getPreviewUIChangeObserver().subscribe(() => {
				if (!this.realDataPreviewService.hasPreviewChanges()) {
					this.updateStyles();
				}
			});
		}
	};

	$onDestroy(): void {
		if (this.customColorChangeSubscription) {
			this.customColorChangeSubscription.unsubscribe();
		}
	}

	setInputFocus = () => {
		this.$element.find('input').trigger('focus');
	};

	onSelectionChange = (clearInput: boolean = true) => {
		if (clearInput) {
			this.selectorSearch = '';
			this.setInputFocus();
		}
		this.sort();
		let searchItems: ISearchTerm[] = _.map(this.selection.multiValues, value => ({
			name: value.name,
			displayName: value.displayName,
			attribute: _.find(this.properties.selectedAttributes,
				attr => attr.name.toLowerCase() === value.attributeName.toLowerCase())
		}));
		this.searchTimeout = this.$timeout(() => {
			this.onSearch({$items: searchItems});
			this.searchTimeout = null;
		}, 1000);
	};

	private sort(): void {
		this.sortedValues = _.sortBy(this.selection.multiValues, (value) => value.name);
	}

	onSearchChange = () => {
		if (this.searchTimeout !== null) {
			if (this.selectorSearch !== '') {
				this.$timeout.cancel(this.searchTimeout);
			} else {
				this.onSelectionChange(false);
			}
		}
	};

	deleteKeyHandler =  (event: KeyboardEvent): void => {
		KeyboardUtils.keyHandler(event, Key.BACKSPACE, () => {
			let values = this.sortedValues;
			if (!values || !values.length) return;
			if (this.selectorSearch === '') {
				this.removeSelection(values[values.length - 1]);
			}
		});
	};

	removeSelection = (node: AttributeValueOption) => {
		node.selected = false;
		this.selection.multiValues.remove(node);
		this.onSelectionChange(false);
	};

	getTooltip = (node: AttributeValueOption): string => {
		let prefix = this.properties.selectedAttributes.length > 1 ? node.attributeDisplayName + ': ' : '';
		return prefix + node.displayName;
	};

	private addStyles(styleId: string): void {
		let backgroundColor: string = this.visualProps.customColor;
		if (backgroundColor) {
			let color: string = ColorUtilsHelper.pickContrastTextColor(backgroundColor);
			let style = `<style ${this.customDomSharedStylesHost.getCSPNonceAttribute()} type="text/css">
				#${styleId} .selector-title, #${styleId} .search-term {
					background-color: ${backgroundColor} !important;
					color: ${color} !important;
				}
			</style>`;
			this.style = angular.element(style);
			this.$element.append(style);
		}
	}

	private updateStyles(): void {
		this.style.remove();
		this.addStyles(this.styleId);
	}

	private clearSelections = (): void => {
		this.selection.multiValues.forEach(node => delete node.selected);
		this.selection.multiValues.removeAll();
		this.onSelectionChange(false);
		this.$scope.$emit('selector:updateClearSelections', false);
	};
}

app.component('selectorWidgetSearch', {
	bindings: {
		properties: '<',
		visualProps: '<',
		onSearch: '&',
		initialSelection: '<',
		demo: '<'
	},
	controller: SelectorWidgetSearch,
	templateUrl: 'partials/widgets/views/selector-widget-search.component.html'
});
