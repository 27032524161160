import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { GeoLocation } from './geolocation.class';

@Injectable({
	providedIn: 'root'
})
export class GeoLocationApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.LOCATION);
	}

	getGeoLocations = (): Promise<GeoLocation[]> => {
		let cache = CacheOptions.CACHED;
		return this.cachedHttp.get('rest/external/geolocation', { cache });
	};
}

app.service('geoLocationApiService', downgradeInjectable(GeoLocationApiService));
