import { Inject, OnInit, Directive } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';


@Directive()
export abstract class FeatureToggleBaseComponent implements OnInit {

	abstract feature: MasterAccountProperty;
	abstract label: string;
	featureEnabled: boolean;

	constructor(
		@Inject('maPropertiesService') protected maPropertiesService: MAPropertiesService,
		protected toastService: ToastService,
		protected locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.featureEnabled = this.maPropertiesService.isFeatureEnabled(this.feature);
	}

	toggleFeature(enabled: boolean): void {
		this.maPropertiesService.setFeatureEnabled(this.feature, enabled).then(() => {
			let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
			this.toastService.addToast(this.locale.getString(key, [this.label]), AlertLevel.CONFIRM);
		});
	}
}
