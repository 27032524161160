
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Security } from '@cxstudio/auth/security-service';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';

import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { ITreeItem, IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ICustomTemplatesScope } from '@cxstudio/dashboard-templates/custom-templates.component';
import { ICustomTemplateActions } from '@cxstudio/dashboard-templates/custom-templates-actions.service';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import Authorization from '@cxstudio/auth/authorization-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';


// eslint-disable-next-line prefer-arrow-callback
app.factory('CustomTemplateContextMenu', function(
	locale: ILocale,
	security: Security,
	CustomTemplateActions,
	FolderService,
	authorization: Authorization,
	folderContextMenuUtils: FolderContextMenuUtils,
	betaFeaturesService: BetaFeaturesService,
) {
	return class CustomTemplateContextMenu extends BaseContextMenuUtils {
		private actionsService: ICustomTemplateActions;
		private folderSvc: IFolderService;

		private readonly OPTIONS = {
			CREATE_DASHBOARD: {
				name: 'createDashboard',
				text: locale.getString('templates.createDashboardFromTemplate'),
				func: item => this.actionsService.createDashboard(item)
			},
			SHARE_TEMPLATE: {
				name: 'shareTemplate',
				text: locale.getString('templates.shareTemplate'),
				func: item => this.actionsService.shareTemplate(item)
			},
			UNSHARE_TEMPLATE: {
				name: 'unshareTemplate',
				text: locale.getString('templates.unshareTemplate'),
				func: item => this.actionsService.unshareTemplate(item)
			},
			DELETE_TEMPLATE: {
				name: 'deleteTemplate',
				text: locale.getString('templates.deleteTemplate'),
				func: item => this.actionsService.confirmDeleteTemplate(item)
			},
			RENAME_FOLDER: {
				name: 'rename',
				text: locale.getString('common.rename'),
				func: (folder: IFolderItem) => this.actionsService.renameFolder(folder)
			},
			MOVE: {
				text: locale.getString('dashboard.move'),
				name: 'move',
				searchBox: true,
				isExpandable: true
			},
			CREATE_SUBFOLDER: {
				text: locale.getString('dashboard.addSubfolder'),
				name: 'add_folder',
				func: (folder: IFolderItem) => this.actionsService.createFolder(folder)
			},
			REMOVE_FOLDER: {
				text: locale.getString('common.delete'),
				name: 'delete',
				func: (folder: IFolderItem) => this.actionsService.removeFolder(folder)
			},
			REMOVE_SUBFOLDER: {
				text: locale.getString('dashboard.menuDeleteSubfolder'),
				name: 'delete',
				func: (folder: IFolderItem) => this.actionsService.removeFolder(folder)
			},
			EDIT_PROPERTIES: {
				text: locale.getString('templates.editProperties'),
				name: 'editProperties',
				func: item => this.actionsService.editProperties(item)
			},
			EDIT_MAPPINGS: {
				text: locale.getString('templates.editMappings'),
				name: 'editMappings',
				func: item => this.actionsService.editMappings(item)
			},
			UPGRADE_TEMPLATE: {
				text: locale.getString('templates.upgradeTemplate'),
				name: 'upgradeTemplate',
				func: item => this.actionsService.upgradeTemplate(item)
			}
		};

		constructor(private scope: ICustomTemplatesScope) {
			super();
			this.actionsService = new CustomTemplateActions(this.scope);
			this.folderSvc = new FolderService(FolderTypes.DASHBOARD_TEMPLATES);
		}

		getContextMenu(item: ITreeItem): any[] {
			if (item.type === FolderTypes.DASHBOARD_TEMPLATES) {
				if (this.canManageTemplates())
					return folderContextMenuUtils.getFolderMenuOptions(item as IFolderItem,
						this.scope.customTemplates, this.OPTIONS, this.actionsService.moveToFolder);
				else return [];
			}

			return this.getTemplateMenuOptions(item);
		}

		private getTemplateMenuOptions = (template: CustomTemplate) => {
			let options: any[] = [ this.OPTIONS.CREATE_DASHBOARD ];

			if (this.canShareTemplates()) {
				let sharingOption = template.publicStatus === SharingStatus.PRIVATE
					? this.OPTIONS.SHARE_TEMPLATE
					: this.OPTIONS.UNSHARE_TEMPLATE;

				options.push(sharingOption);
			}

			if (this.canManageTemplates()) {
				if (betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES)) {
					options.push(this.OPTIONS.UPGRADE_TEMPLATE);
				}
				options.push(this.OPTIONS.EDIT_PROPERTIES, this.OPTIONS.EDIT_MAPPINGS);

				let moveToItems = this.folderSvc.getFoldersForMove(this.scope.customTemplates, template,
					(itemToMove, folderTo) => this.actionsService.moveToFolder(itemToMove, folderTo));
				if (moveToItems && moveToItems.length > 0) {
					options.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}
			}

			if (this.canDeleteTemplate(template)) {
				options.push(BaseContextMenuUtils.MENU_DIVIDER);
				options.push(this.OPTIONS.DELETE_TEMPLATE);
			}

			return options;
		};

		private canManageTemplates = () => authorization.hasManageTemplatePermission();

		private canShareTemplates = () => authorization.hasShareTemplatePermission();

		private canDeleteTemplate = (template: CustomTemplate) =>
			this.canManageTemplates() || this.isTemplateOwner(template);

		private isTemplateOwner = (template: CustomTemplate) => template.ownerName === security.getEmail();

		isVisibleObject(item: any): boolean {
			return true;
		}
	};
});
