import { Directive, ElementRef, HostListener, Input, OnDestroy } from '@angular/core';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
/**
 * Adds "has-scroll", "can-scroll-up" and "can-scroll-down" when scrolling is an option
 * (currently only for vertical case)
 */
// has angularjs sibling
@Directive({
	selector: '[detectScrollOptions]'
})
export class DetectScrollOptionsDirective implements OnDestroy {
	@Input() useOverlay: boolean;

	private readonly SCROLL_UP_CLASS = 'can-scroll-up';
	private readonly SCROLL_DOWN_CLASS = 'can-scroll-down';
	private readonly HAS_SCROLL_CLASS = 'has-scroll';

	private element: Element;
	private domElm: JQuery;

	private scrollActive = false;
	private topShadow;
	private bottomShadow;
	private resizeObserver;
	private contentHeightObserver;

	private clientHeight: number;
	private contentHeight: number;
	private checkScroll;
	private onScroll;

	constructor(el: ElementRef) {
		this.element = el.nativeElement;
		this.domElm = $(this.element as HTMLElement);
		this.checkScroll = _.throttle(this.checkScrollFn, 300);
		this.onScroll = _.throttle((() => {
			if (!this.scrollActive)
				return;
			this.updateScrollOptions();
		}), 300);

		if (this.useOverlay) {
			this.topShadow = document.createElement('div');
			this.bottomShadow = document.createElement('div');
			this.topShadow.classList.add('scroll-up-shadow');
			this.bottomShadow.classList.add('scroll-down-shadow');
			this.domElm.after(this.bottomShadow);
			this.domElm.after(this.topShadow);
		}

		this.resetHeights();
		this.addResizeHandler();
	}

	ngOnDestroy(): void {
		this.removeResizeHandler();
		if (this.topShadow)
			this.topShadow.remove();
		if (this.bottomShadow)
			this.bottomShadow.remove();
	}

	@HostListener('scroll')
	scrollListener() {
		this.onScroll();
	}

	private resetHeights = (): void => {
		this.clientHeight = this.element.clientHeight;
		this.contentHeight = this.element.firstElementChild?.clientHeight;
		if (this.contentHeight && !this.contentHeightObserver) {
			this.contentHeightObserver = ResizeHandlerUtils.addResizeHandler(this.element.firstElementChild, this.checkScroll);
		}
	};

	private checkScrollFn = (): void => {
		this.resetHeights();
		this.scrollActive = this.contentHeight > this.clientHeight;
		if (!this.scrollActive)
			this.domElm.removeClass(`${this.SCROLL_UP_CLASS} ${this.SCROLL_DOWN_CLASS} ${this.HAS_SCROLL_CLASS}`);
		else this.updateScrollOptions();
	};

	private updateScrollOptions = (): void => {
		let scrollTop = this.element.scrollTop;
		this.applyClass(this.HAS_SCROLL_CLASS, true);
		this.applyClass(this.SCROLL_UP_CLASS, scrollTop > 0);
		this.applyClass(this.SCROLL_DOWN_CLASS, Math.ceil(scrollTop + this.clientHeight) < this.contentHeight);
	};

	private applyClass = (clazz: string, condition: boolean): void => {
		if (condition)
			this.domElm.addClass(clazz);
		else this.domElm.removeClass(clazz);
	};

	private addResizeHandler = (): void => {
		this.resizeObserver = ResizeHandlerUtils.addResizeHandler(this.element, this.checkScroll);
	};

	private removeResizeHandler = (): void => {
		ResizeHandlerUtils.removeResizeHandler(this.resizeObserver);
		ResizeHandlerUtils.removeResizeHandler(this.contentHeightObserver);
	};
}
