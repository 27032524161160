import { ModalBindings } from '@cxstudio/common/modal-bindings';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { CbValidationService } from '@cxstudio/reports/providers/cb/services/cb-validation.service';

interface IWidgetSettingsModalParams {
	widget: Widget;
}

export interface IReportSettingsScope extends ISimpleScope {
	widget: Widget;
	props: WidgetProperties;
	visualProps: VisualProperties;
	updateWidget: (widget: Widget) => void;
}

export class ReportSettingsModalComponent extends ModalBindings<IWidgetSettingsModalParams> {

	widget: Widget;

	constructor(
		private filterManagementApiService: FilterManagementApiService,
		private cbValidationService: CbValidationService,
		private $scope: IReportSettingsScope,
	) {
		super();
	}

	$onInit(): void {
		this.initWidget(this.resolve.widget);
		this.$scope.updateWidget = this.initWidget;
	}

	private initWidget = (widget: Widget) => {
		this.widget = this.$scope.widget = widget;
		this.$scope.props = this.widget.properties;
		this.$scope.visualProps = this.widget.visualProperties;
	};

	submit = (): void => {
		let valid = this.cbValidationService.validateProperties(this.widget.properties, this.widget.visualProperties);
		if (valid) {
			this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(this.widget.properties.adhocFilter?.filterRules);

			this.close({$value: this.widget});
		}
	};
}

app.component('reportSettingsModal', {
	controller: ReportSettingsModalComponent,
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	template: `
<modal-header
	(cancel)="$ctrl.dismiss()"
	modal-title="{{'widget.editWidget' | i18n }}">
</modal-header>
<div id="settings" class="modal-body">
	<ng-include src="'partials/widgets/settings/cb/cb-settings-new.html'"></ng-include>
</div>
<save-modal-footer
	(save)="$ctrl.submit()"
	(cancel)="$ctrl.dismiss()"
	[save-text]="'common.ok' | i18n"
></save-modal-footer>`,
});
