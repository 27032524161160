import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';


@Component({
	selector: 'status-tab',
	templateUrl: './status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class StatusTabComponent implements OnInit {

	@Input() masterAccounts: MasterAccount[];

	constructor(
	) {}

	ngOnInit(): void {}
}

app.directive('statusTab', downgradeComponent({component: StatusTabComponent}));