import { AfterContentInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChildren, EventEmitter, Input, OnChanges, Output, QueryList } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { WizardTagMode } from '@app/modules/wizard/cx-wizard-tag/cx-wizard-tag.component';

@Component({
	selector: 'sidebar-wizard',
	templateUrl: './sidebar-wizard.component.html',
	styles: [`
		.wizard-nav-step.active {
			border: 2px solid var(--action-600);
			border-left-width: 12px;
			background: var(--white);
		}
		.wizard-nav-step:hover {
			background: var(--action-300);
		}
		.wizard-nav-step.invalid {
			border: 2px solid var(--danger-600);
			border-left-width: 12px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarWizardComponent implements OnChanges, AfterContentInit {

	@Input() activeStepIndex: number = 0;
	@Input() private mode: CxWizardMode = CxWizardMode.EDIT;
	@Input() cancelLabel: string = this.locale.getString('common.cancel');
	@Input() finishLabel: string = this.locale.getString('common.save');
	@Input() additionalButtonOneLabel: string;
	@Input() additionalButtonTwoLabel: string;
	@Input() additionalButtonOneEnabled: boolean = false;
	@Input() additionalButtonTwoEnabled: boolean = false;
	@Input() finishButtonEnabled: boolean = true;
	@Input() showSaveButton: boolean = true;
	@Input() showAdditionalOneButton: boolean = false;
	@Input() showAdditionalTwoButton: boolean = false;

	@Input() doneLabel: string = this.locale.getString('common.done');
	@Input() showDoneButton = false;

	@Output() private stepChange = new EventEmitter<number>();
	@Output() private cancel = new EventEmitter<void>();
	@Output() private finish = new EventEmitter<void>();
	@Output() private handleAdditionalButtonOne = new EventEmitter<void>();
	@Output() private handleAdditionalButtonTwo = new EventEmitter<void>();
	@Output() private done = new EventEmitter<void>();

	@ContentChildren(CxWizardStepComponent) steps: QueryList<CxWizardStepComponent>;

	constructor(
		private readonly changeDetectorRef: ChangeDetectorRef,
		protected locale: CxLocaleService,
	) { }

	ngOnChanges(changes: SimpleChanges<SidebarWizardComponent>): void {
		if (ChangeUtils.hasChange(changes.activeStepIndex)) {
			this.setActiveStep(changes.activeStepIndex.currentValue);
		}
	}

	ngAfterContentInit(): void {
		this.steps.forEach(step => step.stateChange.subscribe(() => this.changeDetectorRef.detectChanges()));
		this.setActiveStep(this.activeStepIndex);
	}

	onCancel(): void {
		this.cancel.emit();
	}

	onFinish(): void {
		this.finish.emit();
	}

	onButtonOne(): void {
		this.handleAdditionalButtonOne.emit();
	}

	onButtonTwo(): void {
		this.handleAdditionalButtonTwo.emit();
	}

	onDone(): void {
		this.done.emit();
	}

	back(): void {
		if (this.getActiveStep()?.back) {
			this.getActiveStep().back();
		} else {
			this.activeStepIndex = this.activeStepIndex - 1;
			this.setActiveStep(this.activeStepIndex);
		}
	}

	next(): void {
		if (this.getActiveStep()?.next) {
			this.getActiveStep().next();
		} else {
			this.activeStepIndex = this.activeStepIndex + 1;
			this.setActiveStep(this.activeStepIndex);
		}
	}

	activateStep(step: CxWizardStepComponent): void {
		this.setActiveStep(this.steps.toArray().indexOf(step));
	}

	isViewMode(): boolean {
		return this.mode === CxWizardMode.VIEW;
	}

	isFinishAllowed(): boolean {
		return this.finishButtonEnabled && this.steps.toArray().every(step => step.isValid());
	}

	detectChanges(): void {
		this.changeDetectorRef.detectChanges();
	}

	private isActiveStepValid(): boolean {
		return this.getActiveStep()?.isValid();
	}

	isBackAllowed(): boolean {
		return this.activeStepIndex > 0;
	}

	isNextAllowed(): boolean {
		return this.activeStepIndex < this.steps.length - 1 && this.isActiveStepValid();
	}

	private setActiveStep = (index: number): void => {
		this.steps.forEach(step => step.setActive(false));
		this.steps.toArray()[index].setActive(true);

		this.activeStepIndex = index;
		this.stepChange.emit(this.activeStepIndex);

		this.detectChanges();
	};

	private getActiveStep(): CxWizardStepComponent {
		return this.steps.toArray()[this.activeStepIndex];
	}

	// return true all steps other than the last one are marked as completed
	stepsBeforeLastComplete(): boolean {
		return this.steps.toArray().slice(0, -1).every((step: any) => step.tagType === WizardTagMode.COMPLETED);
	}

}
