import * as _ from 'underscore';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DocumentPlaybackIdentifier, PlaybackSettings } from './conversation-settings.class';
import { ConversationPlaybackSettingsService } from './conversation-playback-settings.service';

@Injectable({
	providedIn: 'root'
})
export class ConversationPlaybackProgressService {
	private readonly PLAYBACK_TRESHOLD_SEC: number = 5;

	constructor(
		private conversationPlaybackSettingsService: ConversationPlaybackSettingsService
	) {
	}

	getPlaybackSettings = (identifier: DocumentPlaybackIdentifier): PlaybackSettings => {
		return this.conversationPlaybackSettingsService.getSettings(identifier);
	};

	savePlaybackProgress = (identifier: DocumentPlaybackIdentifier, progress: number, duration: number): void => {
		if (!this.meetsThresholds(progress, duration)) {
			this.conversationPlaybackSettingsService.removeSettings(identifier);
			return;
		}

		this.conversationPlaybackSettingsService.saveSettings(identifier, { progress });
	};

	private meetsThresholds(progress: number, duration: number): boolean {
		// If the last saved time was 5 or fewer seconds from the end of the call, the next time they return to the document,
		// the call should start playing from the beginning (we can treat that as they have finished listening to the call)
		return progress > this.PLAYBACK_TRESHOLD_SEC && progress < duration - this.PLAYBACK_TRESHOLD_SEC;
	}
}

app.service('conversationPlaybackProgressService', downgradeInjectable(ConversationPlaybackProgressService));