import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { PredefinedMetricGrouping } from '@cxstudio/reports/groupings/predefined-metric-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import { DrillParsers } from '@cxstudio/reports/utils/contextMenu/drill/point-to-filter/point-to-filter';

@Injectable({
	providedIn: 'root'
})
export class PredefinedMetricToFilterService {
	constructor(
		private readonly locale: CxLocaleService
	) {}

	toFilter = (widget: Widget, point: DrillPoint, groupBy: PredefinedMetricGrouping): DrillFilter | null => {
		const identifier = GroupIdentifierHelper.getIdentifier(groupBy);

		const value = point && point[identifier];
		if (_.isUndefined(value)) return null;

		const metricName = groupBy.rawName;
		const subtype = groupBy.subtype;
		return {
			type: DrillType.METRIC_PREDEFINED,
			name: metricName,
			values: [value, subtype]
		};
	};

	toFilterTypes = (parsers: DrillParsers): void => {
		parsers[AnalyticMetricType.METRIC_PREDEFINED] = this;
	};

	getRuleString = (filter: DrillFilter, widget?: Widget): Promise<string | void> => {
		const name = this.locale.getString(`metrics.${filter.name}${filter.values[1]}`);
		const value = this.locale.getString(`metrics.${filter.name}_${filter.values[0]}`);

		return Promise.resolve(`${name}: ${value}`);
	};
}
