import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ITreeSelection, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { Model } from '@cxstudio/reports/entities/model';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';
import { IScorecardTreeNode, ScorecardModelContext } from '@cxstudio/projects/scorecards/scorecard-model-context';
import { CxLocaleService } from '@app/core';
import { IScoringTreeItem } from './scorecard-editor-wizard.component';

interface UsedTopic {
	nodeId: number;
	scorecardName: string;
}

@Injectable()
export class ScorecardEditorService {

	private modelContext: ScorecardModelContext;
	private modelContexts: {[modelId: string]: ScorecardModelContext} = {};
	private usedTopics: UsedTopic[];

	modelContextChange: Subject<ScorecardModelContext> = new Subject<ScorecardModelContext>();
	loadingChange: Subject<Promise<any>> = new BehaviorSubject<Promise<any>>(null);

	constructor(
		private locale: CxLocaleService
	) {}

	clear = (): void => {
		this.modelContexts = {};
		this.modelContext = undefined;
	};

	getModelContext = (): ScorecardModelContext => {
		return this.modelContext;
	};

	getModelContextChangeObservable = (): Observable<ScorecardModelContext> => {
		return this.modelContextChange.asObservable();
	};

	getLoadingChangeObservable = (): Observable<Promise<void>> => {
		return this.loadingChange.asObservable();
	};

	setLoading = (promise: Promise<any>): void => {
		this.loadingChange.next(promise);
	};

	populateModelContext = (model: Model): void => {
		const idString = model.id.toString();

		const existingModelContext = this.modelContexts[idString];
		if (existingModelContext) {
			this.modelContext = existingModelContext;
		} else {
			this.modelContext = new ScorecardModelContext();
			this.modelContexts[idString] = this.modelContext;
		}

		this.modelContext.model = model;
		if (!this.modelContext.rootNode) {
			this.modelContext.rootNode = {} as IScorecardTreeNode;
		}
		if (!this.modelContext.scorecardTopics) {
			this.modelContext.scorecardTopics = [];
		}
		this.modelContextChange.next(this.modelContext);
	};

	populateScorecardContext = (scorecard: Scorecard): void => {
		this.modelContext.scorecardTopics = scorecard.scorecardTopics;
		this.modelContext.threshold = scorecard.threshold;
	};

	populateScorecardFromContext = (scorecard: Scorecard): Scorecard => {
		if (!this.modelContext) return scorecard;
		scorecard.modelId = this.modelContext.model.id;
		scorecard.scorecardTopics = this.modelContext.scorecardTopics;
		if (!_.isUndefined(this.modelContext.threshold)) {
			scorecard.threshold = this.modelContext.threshold;
		}
		return scorecard;
	};

	populateTopicsInUse = (scorecards: Scorecard[]): void => {
		let usedTopics = _.chain(scorecards)
				.map(scorecard => {
					return scorecard.scorecardTopics
						.map(scorecardTopic => ({ scorecardName: scorecard.name, nodeId: scorecardTopic.nodeId }));

				})
				.flatten()
				.value();
		this.usedTopics = _.uniq(usedTopics, topic => topic.nodeId);
	};

	populateModelTree = (node: IScorecardTreeNode): void => {
		delete node.isInUsedSubtree;

		if (node && node.children) {
			_.each(node.children, child => {
				this.populateModelTree(child);
				child.parent = node;
			});
			node.hasUsedDescendants = _.any(node.children, this.isTopicInUse);
			if (node.level > 0 && node.hasUsedDescendants) {
				this.populateIsInUsedSubtree(node);
			}
		} else {
			delete node.hasUsedDescendants;
		}
	};

	private populateIsInUsedSubtree = (node: IScorecardTreeNode): void => {
		node.isInUsedSubtree = true;
		if (node.children) {
			node.children.forEach(this.populateIsInUsedSubtree);
		}
	};

	isTopicInUse = (node: IScorecardTreeNode): boolean => {
		if (node) {
			if (node.children) {
				return node.hasUsedDescendants;
			} else {
				return node.isInUsedSubtree || this.isNodeUsedDirectly(node);
			}
		} else {
			return false;
		}
	};

	isTopicInUsedSubtree = (node: IScorecardTreeNode): boolean => {
		return node.isInUsedSubtree;
	};

	private isNodeUsedDirectly = (node: IScorecardTreeNode): boolean => {
		let usedTopicIds = this.getUsedTopicIds();
		return _.contains(usedTopicIds, node.id);
	};

	getNodeTooltip = (node: IScorecardTreeNode): string => {
		if (node) {
			if (node.children && node.hasUsedDescendants) {
				return this.getUsedNodeTooltipByDescendants(node);
			} else {
				let usedTopic = _.findWhere(this.usedTopics, { nodeId: node.id });
				if (usedTopic) {
					return this.locale.getString('scorecards.topicInDirectUseTooltip', { scorecardName: usedTopic.scorecardName });
				} else if (node.isInUsedSubtree) {
					return this.getUsedNodeTooltipByClosestAncestor(node);
				}
			}
		}

		return '';
	};

	private getUsedNodeTooltipByDescendants = (node: IScorecardTreeNode): string => {
		let descendantsScorecardNames = this.aggregateDescendantsScorecardNames(node).join(', ');
		return this.locale.getString('scorecards.topicInUseByDescendants', { scorecardNames: descendantsScorecardNames });
	};

	private aggregateDescendantsScorecardNames = (node: IScorecardTreeNode, aggregator?: string[]): string[] => {
		aggregator = aggregator || [];

		if (node.children) {
			node.children.forEach(child => this.aggregateDescendantsScorecardNames(child, aggregator));
		} else {
			let usedTopic = _.findWhere(this.usedTopics, { nodeId: node.id });
			if (usedTopic && !aggregator.contains(usedTopic.scorecardName)) {
				aggregator.push(usedTopic.scorecardName);
			}
		}

		return aggregator;
	};

	private getUsedNodeTooltipByClosestAncestor = (node: IScorecardTreeNode): string => {
		let closestUsedAncestor = this.getClosestUsedAncestor(node);
		let siblingsScorecardNames = this.aggregateDescendantsScorecardNames(closestUsedAncestor).join(', ');
		return this.locale.getString('scorecards.topicInUseBySiblings', { scorecardNames: siblingsScorecardNames });
	};

	private getClosestUsedAncestor = (node: IScorecardTreeNode): IScorecardTreeNode => {
		if (node.parent) {
			return node.parent.hasUsedDescendants
				? node.parent
				: this.getClosestUsedAncestor(node.parent);
		} else {
			return null;
		}
	};

	private getUsedTopicIds = (): number[] => {
		return this.usedTopics.map(usedTopic => usedTopic.nodeId);
	};

	autoPopulateTotal = (): boolean => {
		let leaves = _.chain(this.modelContext.scoringEntries)
			.filter((entry: any) => !entry.children)
			.value();

		let notAutoFails = _.filter(leaves, (entry: any) => !entry.topic.autoFail);

		if (_.isEmpty(notAutoFails)) {
			this.modelContext.total = 100;
			this.modelContext.threshold = 100;
			return true;
		}
		return false;
	};

	enshureTopicsHaveWeight = (): void => {
		this.modelContext.scorecardTopics.forEach((topic: ScorecardTopic) => {
			topic.topicWeight = topic.topicWeight || 0;
		});
	};

	filterTopicsByScoringEntries = (topics: ScorecardTopic[]) => {
		return _.filter(topics, (topic) => {
			let selectedNode = _.findWhere(this.modelContext.scoringEntries, { id: topic.nodeId });
			return !_.isUndefined(selectedNode);
		});
	};

	updateScoringCriteria = (): void => {
		if (this.modelContext?.modelTree) {
			this.modelContext.scoringEntries = this.getScoringEntriesForNode(this.modelContext.modelTree, this.modelContext.selection, false);
			this.buildEntriesTree(this.modelContext.scoringEntries);
			this.addDisplayProperties(this.modelContext.scoringEntries);
			this.autoEnableWeightOnNodes(this.modelContext.scoringEntries);

			this.modelContextChange.next(this.modelContext);
		}
	};

	/**
	 * Converts flat structure into hierarchical
	 */
	 private buildEntriesTree(entries: IScorecardTreeNode[]): IScoringTreeItem[] {
		let entryMap: {[id: number]: IScoringTreeItem} = {};
		_.each(entries, entry => entryMap[entry.id] = {scoringEntry: entry});
		_.chain(entries)
			.filter(entry => !!entry.parentId)
			.each(childEntry => {
				let parent = entryMap[childEntry.parentId];
				if (!parent.children)
					parent.children = [];
				parent.children.push(entryMap[childEntry.id]);
			});
		return _.values(entryMap).filter(entry => !entry.scoringEntry.parentId); // only roots
	}

	private addDisplayProperties(scoringEntries): void {
		scoringEntries.map(entry => {
			entry.visible = true;
		});
	}

	private autoEnableWeightOnNodes(scoringEntries): void {
		for (let scoringEntry of scoringEntries) {
			this.autoEnableWeightOnNode(scoringEntry);
		}
	}

	private autoEnableWeightOnNode(scoringEntry): void {
		if (scoringEntry.children) {
			this.autoEnableWeightOnNodes(scoringEntry.children);
		} else if (scoringEntry.topic && _.isUndefined(scoringEntry.topic.autoFail)) {
			scoringEntry.topic.autoFail = false;
		}
	}

	/**
	 * This builds an array of nodes that should be displayed in the scoring page for the given node based on
	 * the selection strategy passed. This function is called recursively on the children of the given node as well.
	 * It's called with the root node everytime the scoring criteria is updated.
	 *
	 * Basically a node should be added to this list if it's:
	 * 		* directly added by selection. i.e. selection.nodes directly contains its id.
	 * 		* indirectly added by selection. i.e. selection.strategy is EVERYTHING, or selection.strategy is SUBSET
	 * 			and its parent is contined in selection.nodes.
	 * 		* it is not selected according to the 'selection' param but is a parent of node(s) that are.
	 *
	 * params:
	 * 		node: the node being examined.
	 * 		selection: the selection that was made on step 1 page of the editor.
	 * 		isAutoSelect: true when processing children of a node that is selected. false should be passed in in the first call.
	 */
	 private getScoringEntriesForNode = (node: IScorecardTreeNode, selection: ITreeSelection, isAutoSelect: boolean): IScorecardTreeNode[] => {
		delete node.topic;
		if (selection.strategy === TreeSelectionStrategy.NONE) {
			this.modelContext.scorecardTopics = [];
			return [];	// Nothing to select.
		}
		const entries = [];
		let shouldSelectNode = isAutoSelect || // its parent was selected
			(selection.strategy === TreeSelectionStrategy.EVERYTHING) || // the selection strategy is everything
			!!_.find(selection.nodes, { idPath: node.idPath }); // this node is one of the selected nodes

		this.modelContext.otherScorecardTopics = this.modelContext.otherScorecardTopics || [];

		let otherScorecardTopic = this.isTopicInUse(node);
		shouldSelectNode = shouldSelectNode && !otherScorecardTopic; //don't choose in use topics

		const children = node.children || [];
		let topicIndex = _.findIndex(this.modelContext.scorecardTopics, { nodeId: node.id });

		if (!shouldSelectNode && topicIndex >= 0) {
			// add in already used list for validation
			if (otherScorecardTopic && !_.findWhere(this.modelContext.otherScorecardTopics, { nodeId: node.id })) {
				this.modelContext.otherScorecardTopics.push(this.modelContext.scorecardTopics[topicIndex]);
			}

			// remove scoring for that node as it's not selected
			this.modelContext.scorecardTopics.splice(topicIndex, 1);
		} else if (shouldSelectNode && node.children && topicIndex >= 0) {
			// remove scoring for that node as it became non-leaf
			this.modelContext.scorecardTopics.splice(topicIndex, 1);
			topicIndex = -1;
		} else if (shouldSelectNode && !node.children && (topicIndex < 0)) {
			this.modelContext.scorecardTopics.push(new ScorecardTopic({ nodeId: node.id, topicWeight: 0, presence: true }));
			topicIndex = this.modelContext.scorecardTopics.length - 1;
		}
		children.forEach(child => {
			entries.pushAll(this.getScoringEntriesForNode(child, selection, shouldSelectNode));
		});
		if (shouldSelectNode || entries.length) { // if node is selected or any of its descendants are selected
			if (topicIndex >= 0) {
				const topic = this.modelContext.scorecardTopics[topicIndex];
				node.topic = topic;
			}
			entries.unshift(node);
		}
		return entries;
	};
}

app.service('scorecardEditorService', downgradeInjectable(ScorecardEditorService));
