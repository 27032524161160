import { Injectable, Inject } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { downgradeInjectable } from '@angular/upgrade/static';


@Injectable()
export class EngagorService {

	constructor(
		@Inject('security') private security: Security
	) {}

	isIntegrationEnabled = (): boolean => {
		return this.security.getCurrentMasterAccount().engagorEnabled;
	};
}

app.service('engagorService', downgradeInjectable(EngagorService));
