import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	EventEmitter,
	HostListener,
	Inject,
	Input,
	OnInit,
	Output
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import FileUploadStatus from '@app/modules/cx-form/file-upload/file-upload-status';
import { Security } from '@cxstudio/auth/security-service';
import MediaType, { getFileExtensionsForMediaType } from '@app/modules/cx-form/file-upload/media-type';
import { CxLocaleService } from '@app/core';
import { FilterFunction } from 'ng2-file-upload/file-upload/file-uploader.class';
import { TypeGuards } from '@app/util/typeguards.class';
import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';


export interface FileUploadFailureDetails {
	headers: {[key: string]: string};
	response: any;
}

@Component({
	selector: 'file-upload',
	templateUrl: './file-upload.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FileUploadComponent implements OnInit {

	private readonly maxFileSize = 10 * Math.pow(2, 20); // 10Mb

	@Input() previousFilename?: string;
	@Input() fileUploadUrl: string;
	@Input() acceptedMediaTypes: MediaType[] = [];
	@Input() uploadMetadata: { [key: string]: any };
	@Input() uploadOnSelect = true;
	@Input() uploadButtonLabel: string;
	@Input() customUploadFailedMessage: string;
	@Output() selectUpload = new EventEmitter<File>();
	@Output() successfulUpload = new EventEmitter<any>();
	@Output() erroneousUpload = new EventEmitter<FileUploadFailureDetails>();
	@Output() removedUpload = new EventEmitter<void>();

	@Output() multipleErrors = new EventEmitter<DetailedValidationError[]>();

	// Model linked to <input type="file" />
	selectedFile?: string;

	uploadedFile?: File;
	fileUploadStatus?: FileUploadStatus;
	fileUploader: FileUploader;
	fileUploadError?: string;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
	) { }

	ngOnInit(): void {
		if (this.previousFilename) {
			this.uploadedFile = { name: this.previousFilename } as File;
			this.fileUploadStatus = FileUploadStatus.SUCCESS;
		}

		const fileUploaderFilters: FilterFunction[] = [];

		if (this.acceptedMediaTypes?.length > 0) {
			const acceptedFileExtensions = this.buildExtensionsFromAcceptedMediaTypes();

			fileUploaderFilters.push(
				{
					name: 'accepted-file-extensions',
					fn: (item, options) => {
						const fileName = item?.name;

						if (!fileName || acceptedFileExtensions.length === 0) {
							return true;
						}

						return !!acceptedFileExtensions.find(fileExtension => fileName.endsWith(`.${fileExtension}`));
					}
				}
			);
		}

		this.fileUploader = new FileUploader({
			url: this.fileUploadUrl,
			itemAlias: 'file',
			filters: fileUploaderFilters,
			headers: [{ name: 'ma-id', value: this.security.getMasterAccountId().toString() }],
		});

		this.fileUploader.onSuccessItem = this.handleSuccessfulUpload;

		if (this.uploadMetadata) {
			this.fileUploader.onBuildItemForm = this.addUploadMetadataToFormRequest;
		}

		this.fileUploader.onErrorItem = this.handleFailedUpload;
		this.fileUploader.onWhenAddingFileFailed = this.handleInvalidFile;
	}

	uploadFile = (event): void => {
		const files = event.target.files;

		if (files?.length === 0) {
			return; //cancel in file explorer
		}

		this.uploadedFile = files[0];
		this.selectUpload.emit(this.uploadedFile);
		if (this.uploadOnSelect) {
			this.fileUploadStatus = FileUploadStatus.LOADING;
			this.fileUploader.addToQueue(files);
			this.fileUploader.uploadAll();
		} else {
			this.fileUploadStatus = FileUploadStatus.SELECTED;
		}
	};

	uploadSelectedFile(): void {
		if (this.uploadedFile) {
			this.fileUploadStatus = FileUploadStatus.LOADING;
			this.fileUploader.addToQueue([this.uploadedFile]);
			this.fileUploader.uploadAll();
		}
	}

	fileUploadIsSelected = (): boolean => {
		return this.fileUploadStatus === FileUploadStatus.SELECTED;
	};

	fileUploadIsLoading = (): boolean => {
		return this.fileUploadStatus === FileUploadStatus.LOADING;
	};

	fileUploadIsSuccessful = (): boolean => {
		return this.fileUploadStatus === FileUploadStatus.SUCCESS;
	};

	fileUploadHasErrors = (): boolean => {
		return this.fileUploadStatus === FileUploadStatus.ERROR;
	};

	removeSelectedFile = (): void => {
		delete this.selectedFile;
		delete this.uploadedFile;
		delete this.fileUploadError;
		delete this.fileUploadStatus;

		this.removedUpload.emit();
		this.multipleErrors.emit([]);
	};

	public getAcceptedFileExtensions = (): string => {
		return this.buildExtensionsFromAcceptedMediaTypes().map(fileExtension => `*.${fileExtension}`).join(', ');
	};

	private buildExtensionsFromAcceptedMediaTypes = (): string[] => {
		return this.acceptedMediaTypes.reduce(
			(fileExtensions: string[], mediaType: MediaType) => {
				return [
					...fileExtensions,
					...getFileExtensionsForMediaType(mediaType)
				];
			},
			[]
		);
	};

	private handleSuccessfulUpload = (item, response, status, headers): void => {
		delete this.selectedFile;
		delete this.fileUploadError;
		this.multipleErrors.emit([]);

		this.fileUploadStatus = FileUploadStatus.SUCCESS;

		this.successfulUpload.emit(JSON.parse(response));

		this.ref.detectChanges();
	};

	private addUploadMetadataToFormRequest = (fileItem, form): void => {
		for (const key in this.uploadMetadata) {
			if (this.uploadMetadata[key] !== undefined) {
				form.append(key, this.uploadMetadata[key]);
			}
		}
	};

	private handleFailedUpload = (fileItem, response, status, headers): void => {
		delete this.selectedFile;

		this.fileUploadStatus = FileUploadStatus.ERROR;

		if (this.customUploadFailedMessage) {
			this.fileUploadError = this.customUploadFailedMessage;
		} else {
			this.fileUploadError = response ? JSON.parse(response) : this.locale.getString('common.uploadFailed');
		}

		// if we have multiple errors, emit an event and let the parent component handle it
		if (TypeGuards.isArray(this.fileUploadError)) {
			this.multipleErrors.emit(JSON.parse(response));
			delete this.fileUploadError;
		}

		this.erroneousUpload.emit({headers, response: JSON.parse(response)});

		this.ref.detectChanges();
	};

	private handleInvalidFile = (item, filter, options) => {
		delete this.selectedFile;

		this.fileUploadStatus = FileUploadStatus.ERROR;
		this.fileUploadError = item.size <= this.maxFileSize
			? this.locale.getString('common.unsupportedFileFormat')
			: this.locale.getString('organization.exceedMaxSize');

		this.erroneousUpload.emit();

		this.ref.detectChanges();
	};

	public getUploadButtonLabel() {
		return this.uploadButtonLabel? this.uploadButtonLabel : this.locale.getString('userAdministration.fileUploadButton');
	}

	// Added to prevent closing of the entire wizard when clicking "cancel" in the file selection window.
	// See https://stackoverflow.com/questions/77069579/cancelling-file-picker-is-causing-stepper-dialog-to-close
	@HostListener('cancel', ['$event'])
	onInputCancel (event: Event): void {
		event.stopImmediatePropagation();
	}

}
