<div class="d-flex justify-between">
	<input-with-default class="lane-name"
		[disabled]="options.isNameDisabled"
		[defaultValue]="getDefaultName()"
		[(ngModel)]="laneItem.name"
		(ngModelChange)="onValueChange()">
	</input-with-default>
	<div class="lane-threshold d-flex align-items-center mh-8">
		<ng-container *ngIf="isValueItem()">
			<span>{{getValue()}}</span>
		</ng-container>
		<ng-container *ngIf="!isValueItem()">
			<ng-container *ngIf="!isFirst">
				<span>{{getLeftConditionText()}}</span>
				<div *ngIf="isLeftInclusive()">
					<input class="w-120 ml-8"
						[ngModel]="laneItem.leftThreshold"
						(ngModelChange)="onThresholdChange($event, true)"
						[limitedDecimal]="options.decimals">
					<span class="q-icon-warning text-danger ml-4"
						*ngIf="laneItem.leftThresholdInvalid"
						attr.aria-label="{{'common.error'|i18n}}"></span>
				</div>
			</ng-container>
			<ng-container *ngIf="!isLast">
				<span class="ml-4">{{getRightConditionText()}}</span>
				<div *ngIf="isRightInclusive()">
					<input class="w-120 ml-8"
						[ngModel]="laneItem.rightThreshold"
						(ngModelChange)="onThresholdChange($event, false)"
						[limitedDecimal]="options.decimals">
					<span class="q-icon-warning text-danger ml-4"
						*ngIf="laneItem.rightThresholdInvalid"
						attr.aria-label="{{'common.error'|i18n}}"></span>
				</div>
			</ng-container>
		</ng-container>
	</div>
	<color-input
		class="lane-color w-max-160"
		[disabled]="!options.canChangeColor"
		[(ngModel)]="laneItem.color"
		(ngModelChange)="onValueChange()">
	</color-input>
</div>
