import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { ContentProvider } from '@app/modules/system-administration/content-provider/content-provider';
import { ContentProviderAccount } from '@app/modules/system-administration/content-provider/content-provider-account';
import { PagedResponse } from '@cxstudio/paged-response';

@Injectable({
	providedIn: 'root'
})
export default class ContentProviderManagementApiService {
	constructor(
		private readonly cxHttp: CxHttpService,
	) {}

	getAllContentProviders = (): Promise<ContentProvider[]> =>
		this.cxHttp.get('rest/system-admin/content-providers/list');

	getPagedCpList = (queryParams): Promise<PagedResponse<ContentProvider>> => {
		let params = new HttpParams({fromObject: queryParams});
		return this.cxHttp.get('rest/system-admin/content-providers/paged', { params });
	};


	getCpAccounts = (contentProviderId: number|string): Promise<{data: ContentProviderAccount[]}> =>
		this.cxHttp.get(`rest/system-admin/content-providers/${contentProviderId}/accounts`);

	syncAccountNames = (contentProviderId: number): Promise<void> =>
		this.cxHttp.put(`rest/system-admin/content-providers/${contentProviderId}/sync-accounts`);

	getContentProvider = (contentProviderId: number|string): Promise<ContentProvider> =>
		this.cxHttp.get(`rest/system-admin/content-providers/${contentProviderId}`);

	addContentProvider = (cpData): Promise<void> =>
		this.cxHttp.post('rest/system-admin/content-providers/register', cpData);

	updateContentProvider = (cpData): Promise<void> =>
		this.cxHttp.post('rest/system-admin/content-providers/update', cpData);

	upgradeContentProvider = (cpData): Promise<void> =>
		this.cxHttp.post('rest/system-admin/content-providers/upgrade', cpData);

	deleteContentProvider = (contentProviderId: number|string): Promise<void> =>
		this.cxHttp.delete(`rest/system-admin/content-providers/unregister/${contentProviderId}`);
}

app.service('contentProviderManagementApiService', downgradeInjectable(ContentProviderManagementApiService));
