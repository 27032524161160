import * as _ from 'underscore';
import { Component, Input, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { CxLocaleService } from '@app/core';
import { SentenceScorecardTopic } from '@cxstudio/reports/preview/preview-sentence-class';

@Component({
	selector: 'sentence-scorecards',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ng-container *ngFor="let scorecardId of scorecardTopics | keys">
			<div *ngFor="let topic of scorecardTopics[scorecardId] | orderBy:'-weight'"
				[ngClass]="'scorecard-' + scorecardId"
				class="icon-highlight icon-highlight-small feedback-preview-icon scorecard-sentence-icon mr-4"
				[style.color]="getTopicColor(topic)"
				[title]="getTopicTitle(topic)">
				<i [ngClass]="getTopicIcon(topic)"></i>
			</div>
		</ng-container>`,
})
export class SentenceScorecardsComponent implements OnInit {

	@Input() scorecardTopics: {[scorecardId: number]: SentenceScorecardTopic[]};

	constructor(private locale: CxLocaleService) {}

	ngOnInit(): void {
	}

	getTopicTitle = (topic: SentenceScorecardTopic) => {
		return topic.rebutted
			? this.locale.getString('docExplorer.rebuttedResultTopic', {topic: topic.name})
			: topic.name;
	};

	getTopicColor = (topic: SentenceScorecardTopic) => ScorecardDocExplorerUtils.getTopicColor(topic);

	getTopicIcon = (topic: SentenceScorecardTopic) => ScorecardDocExplorerUtils.getTopicIcon(topic);
}
