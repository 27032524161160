import { DriversItem } from '../entities/drivers-item';
import { DriverResourcesContainer } from '@cxstudio/drivers/editor/driver-resources-container';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

export class DriverOutcomeComponent implements ng.IComponentController {

	driversItem: DriversItem;
	resourceContainer: DriverResourcesContainer;
	projectSelection: IProjectSelection;
	driversList: any[];
	driversSettingsForm: any;
	onChange: () => void;

	targetAttributes: any[];
	loaded: boolean;

	constructor(
		private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService,
	) {

	}

	$onInit = () => {
		this.resourceContainer.get().then(resources => {
			this.targetAttributes = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.filterItemsDefault())
				.withModels(resources.models)
				.withAttributes(resources.attributes, MetricFilters.DRIVER_ATTRIBUTES)
				.withWordAttributes(resources.wordAttributes)
				.build();
			this.loaded = true;
		});
	};
}

app.component('driverOutcome', {
	bindings: {
		driversSettingsForm: '<',
		driversItem: '<driver',
		resourceContainer: '<resources',
		projectSelection: '<',
		driversList: '<',
		onChange: '&'
	},
	controller: DriverOutcomeComponent,
	template: `
<collapsing-panel>
	<panel-heading>
		<b>{{::'drivers.outcome'|i18n}}</b>
		<cb-inline-help right>
			<help-body>
				<p>{{::'drivers.outcomeTooltipText'|i18n}}</p>
			</help-body>
		</cb-inline-help>
	</panel-heading>
	<panel-body>
		<div class="form-group" ng-class="{'has-error': $ctrl.driversSettingsForm.name.$invalid}">
			<label for="name">{{::'drivers.name'|i18n}}</label>
			<input type="text"
				required
				unique-name="{property: 'displayName', items: $ctrl.driversList, initialItem: $ctrl.driversItem}"
				maxlength=100
				name="name"
				class="edit-title w-100-percent"
				ng-model="$ctrl.driversItem.displayName">
			<span class="help-block"
				ng-show="$ctrl.driversSettingsForm.name.$error.required && $ctrl.driversSettingsForm.name.$dirty">
				{{::'drivers.nameRequiredError'|i18n}}
			</span>
			<span class="help-block"
				ng-show="$ctrl.driversSettingsForm.name.$error.unique && $ctrl.driversSettingsForm.name.$dirty">
				{{::'drivers.nameNotUnique'|i18n}}
			</span>
		</div>

		<div class="form-group drivers-target-selection">
			<label>{{::'drivers.outcomeLabel'|i18n}}</label>
			<filter-builder
				ng-if="$ctrl.loaded"
				[filterable-fields]="$ctrl.targetAttributes"
				[rule-set]="$ctrl.driversItem.target.filters.filterRules"
				[max-rules]="5"
				(filter-change)="$ctrl.onChange()"
				[project-properties]=$ctrl.projectSelection>
			</filter-builder>
		</div>
	</panel-body>
</collapsing-panel>`
});
