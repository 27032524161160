import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { WorkspaceAccess } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceProjectsApi {

	constructor(
		private cxHttp: CxHttpService
	) {}

	getProjects = (workspaceId: number): Promise<Project[]> => {
		return this.cxHttp.get(`rest/workspaces/${workspaceId}/projects`, {local: true});
	};

	getProjectsAccess = (workspaceId: number, targetUserId: number): Promise<WorkspaceAccess> => {
		return this.cxHttp.get(`rest/workspaces/${workspaceId}/user/${targetUserId}/access`, {local: true});
	};

	updateProjectsAccess = (workspaceId: number, targetUserId: number, access: WorkspaceAccess): Promise<void> => {
		return this.cxHttp.post(`rest/workspaces/${workspaceId}/user/${targetUserId}/access`, access);
	};
}

app.service('workspaceProjectsApi', downgradeInjectable(WorkspaceProjectsApi));
