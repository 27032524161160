<div class="bg-white d-flex flex-direction-column w-100-percent h-100-percent overflow-hidden"
	[ngBusy]="loading">
	<div class="d-flex flex-direction-row w-100-percent align-items-end mt-24 mh-24">
		<asset-visibility-tabs [(ngModel)]="visibilityTab"
			[withHidden]="resourceType !== ObjectType.SCORECARD">
		</asset-visibility-tabs>
		<workspace-project-selector
			[(value)]="project"
			[labelPosition]="LabelPosition.SELECT_PROMPT"
			[inline]="true"
			(workspaceChange)="workspaceChanged($event)"
			(valueChange)="workspaceProjectChanged($event)"
			(loading)="loading = $event"
			[allProjects]="false"
			[hideInternalProjects]="true"
			[withPermissions]="true"
			[externalErrors]="true"
			(errorsChange)="errors.messages = $event"
		></workspace-project-selector>
		<label class="font-bold mb-0 d-flex flex-direction-column mr-8">
			{{'common.object'|i18n}}
			<resource-type-selector
				[(ngModel)]="resourceType"
				[disabled]="editMode">
			</resource-type-selector>
		</label>
		<label class="font-bold mb-0 d-flex flex-direction-column mr-8">
			{{'common.find'|i18n}} {{getResourceTypeLabel() || ''}}
			<input name="input12345"
				type="search"
				class="br-dash-search-bar w-100-percent"
				aria-autocomplete="list"
				autocomplete="off"
				(keydown.escape)="searchText = ''"
				[(ngModel)]="searchText">
		</label>
		<ng-container *ngIf="resourceType !== ObjectType.SCORECARD">
			<ng-container *ngIf="!editMode && visibilityTab === ProjectTabType.AVAILABLE">
				<button *ngIf="canEdit()"
					id="btn-edit-projects"
					class="btn btn-primary mr-8"
					type="button"
					[hidden]="editMode"
					(click)="editMode = true"
					[i18n]="'administration.edit'">
				</button>
			</ng-container>
			<ng-container *ngIf="editMode && visibilityTab === ProjectTabType.AVAILABLE">
				<button
					id="btn-save-projects"
					class="btn btn-primary mr-8"
					type="button"
					(click)="saveChanges()"
					[i18n]="'administration.save'">
				</button>

				<button
					id="btn-cancel-projects"
					class="btn btn-secondary mr-8"
					type="button"
					(click)="cancelChanges()"
					[i18n]="'administration.cancel'">
				</button>
			</ng-container>

			<button
				id="btn-export-projects"
				class="btn btn-secondary mr-8"
				type="button"
				[hidden]="editMode"
				(click)="exportData()"
				[i18n]="'administration.exportToExcel'">
			</button>
		</ng-container>
		<ng-container *ngIf="resourceType === ObjectType.SCORECARD">
			<button *ngIf="canEdit()"
				data-testid="new-scorecard-button"
				class="btn btn-primary mr-8"
				type="button"
				(click)="newScorecard()"
				[i18n]="'scorecards.newScorecard'">
			</button>
		</ng-container>
	</div>

	<project-selection-error
		[errors]="errors"
		class="flex-direction-row">
	</project-selection-error>

	<div class="flex-fill w-100-percent"
		[ngSwitch]="resourceType">
		<ng-container *ngSwitchCase="ObjectType.ATTRIBUTE">

			<attributes-table
				[project]="project"
				[accountProject]="accountProject"
				[visibility]="visibilityTab"
				[searchText]="searchText"
				[editMode]="editMode">
			</attributes-table>
		</ng-container>
		<ng-container *ngSwitchCase="ObjectType.MODEL">
			<models-table
				[project]="project"
				[accountProject]="accountProject"
				[visibility]="visibilityTab"
				[searchText]="searchText"
				[editMode]="editMode">
			</models-table>
		</ng-container>
		<ng-container *ngSwitchCase="ObjectType.SCORECARD">
			<scorecards-table
				[project]="project"
				[accountProject]="accountProject"
				[searchText]="searchText">
			</scorecards-table>
		</ng-container>
	</div>
</div>
