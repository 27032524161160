import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';

export class MultiselectUtils {

	static optionSelected = (item: AttributeValueOption): boolean => {
		if (!item.children) {
			return item.selected;
		}

		return MultiselectUtils.hasSomeChildrenWithSelection(item);
	};

	static optionNotSelected = (item: AttributeValueOption): boolean => { 
		if (!item.children) {
			return !item.selected;
		}

		if (MultiselectUtils.hasNoSelectedChildren(item)) {
			return true;
		}

		if (MultiselectUtils.hasAllChildrenSelected(item)) {
			return false;
		}

		return MultiselectUtils.hasSomeChildrenWithSelection(item);
	};

	private static hasSomeChildrenWithSelection = (item: AttributeValueOption) => {
		return _.some(item.children, (childItem) => childItem.selected);
	};

	private static hasNoSelectedChildren = (item: AttributeValueOption) => {
		return _.every(item.children, (childItem) => !childItem.selected);
	};

	private static hasAllChildrenSelected = (item: AttributeValueOption) => {
		return _.every(item.children, (childItem) => childItem.selected);
	};
}