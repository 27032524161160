
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IGridColumn } from './grid-column';
import { DropdownClass } from '@cxstudio/common/dropdown-orientation-utils.service';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';
import { GridDefinitionFactory } from '@cxstudio/grids/grid-definition-factory.service';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { ChangeUtils } from '@app/util/change-utils';

enum GridContextColumnType {
	ITEM = 'ITEM',
	GROUP_LABEL = 'GROUP_LABEL'
}

interface GridContextColumnOption {
	name: string;
	text: string;
	type: GridContextColumnType;
}

export interface GridContextColumnItemOption extends GridContextColumnOption {
	requireAttrStats: boolean;
	default: boolean;
	groupKey: string;
	selected: boolean;
	object: IGridColumn;
}

export class GridContextMenuComponent {
	readonly DEFAULT_GROUP_KEY = 'DEFAULT_GROUP_KEY';

	gridType: GridTypes;
	gridMode: GridMode;
	gridColumns: GridContextColumnItemOption[];
	reinitTrigger: number;
	maxColumns?: number;
	dropdownClass = DropdownClass.DROPDOWN_LEFT;
	onColumnsChanged?: (change: { $columns: GridContextColumnItemOption[] }) => void;

	private contextMenuOptions: any[] = [];
	private columnsMenuOption;
	private getMenuItems: () => any[];

	hasDefaultColumns: boolean;
	columnsFilterText: string;
	menuOpen: boolean;
	focusedOptionNumber: number;
	focusedOptionElement: HTMLElement;

	constructor(
		private $scope: ng.IScope,
		private locale: ILocale,
		private $q: ng.IQService,
		private userPropertiesApiService: UserPropertiesApiService,
		private $element: ng.IAugmentedJQuery,
		private gridDefinitionFactory: GridDefinitionFactory,
		private $timeout: ng.ITimeoutService
	) { }

	$onInit = () => {
		this.initContextMenu().then(() => this.notifyGridColumns());
		this.$scope.$on('resetColumnSelection', () => {
			this.columnsFilterText = '';
			this.initContextMenu().then(() => this.restoreDefaultGridColumns());
		});
	};

	$onChanges = (changes) => {
		if (ChangeUtils.hasChange(changes.reinitTrigger)) {
			this.initContextMenu().then(() => this.notifyGridColumns());
		}
	};

	private initContextMenu = (): ng.IPromise<any> => {
		return this.getGridColumns(this.gridDefinitionFactory.getDefinition(this.gridType), this.gridType).then(columns => {
			this.gridColumns = columns;
			this.hasDefaultColumns = this.gridColumns.filter(column => column.default).length > 0;
			this.columnsMenuOption = {
				text: this.locale.getString('administration.gridColumns'),
				name: 'grid_columns',
				items: this.buildColumnOptions(this.gridColumns)
			};

			this.replaceOption(this.columnsMenuOption);
			this.updateContextMenuItems();
		});
	};

	private replaceOption = (option): void => {
		let existingOption = _.findWhere(this.contextMenuOptions, { name: option.name });
		if (existingOption) {
			existingOption.items = option.items;
		} else {
			this.contextMenuOptions.push(option);
		}
	};

	private buildColumnOptions = (columns: GridContextColumnItemOption[]): GridContextColumnOption[] => {
		this.populateDefaultGroup(columns);

		let options: GridContextColumnOption[] = [];
		let groups = _.groupBy(columns, 'groupKey');
		Object.keys(groups).forEach(group => {
			if (group !== this.DEFAULT_GROUP_KEY) {
				options.push({
					name: group,
					text: this.locale.getString(group),
					type: GridContextColumnType.GROUP_LABEL
				});
			}
			options.pushAll(groups[group]);
		});

		return options;
	};

	private populateDefaultGroup = (columns: GridContextColumnItemOption[]) => {
		columns.forEach(column => {
			column.groupKey = column.groupKey || this.DEFAULT_GROUP_KEY;
		});
	};

	private getGridColumns(gridDefinition: IGridDefinition<any>, gridType: GridTypes): ng.IPromise<any> {
		let deferred = this.$q.defer();

		this.userPropertiesApiService.getGridColumns(gridType).then((savedColumns) => {
			(gridDefinition.init(this.gridMode, this.$scope.$parent.$ctrl || this.$scope.$parent) as PromiseLike<IGridColumn[]>)
			.then((allGridColumns: IGridColumn[]) => {
				let optionalColumns = this.getOptionalGridColumns(allGridColumns);

				optionalColumns.forEach((column) => {
					let columnSavedState = savedColumns[column.name];
					if (!_.isUndefined(columnSavedState)) {
						column.selected = columnSavedState;
					}
				});

				deferred.resolve(optionalColumns);
			});
		});

		return deferred.promise;
	}

	private saveGridColumns(gridType: GridTypes, columns: any[]): void {
		let columnSelectionMap = {};

		columns.forEach((column) => {
			columnSelectionMap[column.name] = column.selected;
		});

		this.userPropertiesApiService.updateGridColumns(gridType, columnSelectionMap);
	}

	private getOptionalGridColumns(columns: any[]): GridContextColumnItemOption[] {
		return _.chain(columns)
			.filter(item => item.optional)
			.map(item => {
				return {
					name: item.persistedName || item.field,
					text: item.name,
					requireAttrStats: item.requireAttrStats,
					default: item.default,
					groupKey: item.groupKey,
					// by default, if "selected" is not specified, select the column
					selected: _.isUndefined(item.selected),
					type: GridContextColumnType.ITEM,
					object: item,
				} as GridContextColumnItemOption;
			})
			.value();
	}

	isRegularOption = (option): boolean => {
		return !option.type;
	};

	isSwitchOption = (option): boolean => {
		return option.type === 'switch';
	};

	restoreDefaultGridColumns = (): void => {
		this.gridColumns.forEach(gridColumn => {
			gridColumn.selected = gridColumn.default;
		});
		this.saveGridColumns(this.gridType, this.gridColumns);
		this.notifyGridColumns();
	};

	selectGridColumn = (item: GridContextColumnItemOption) => {
		if (!this.isLimitReached(item)) {
			item.selected = !item.selected;
			this.saveGridColumns(this.gridType, this.gridColumns);
			this.notifyGridColumns();
		}
	};

	isLimitReached = (item: GridContextColumnItemOption): boolean => {
		return this.maxColumns && !item.selected && this.getSelectedColumnsCount() >= this.maxColumns;
	};

	private getSelectedColumnsCount = (): number => {
		return this.gridColumns.filter(column => column.selected).length;
	};

	onKeydownSelect = (event: KeyboardEvent, item) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.selectGridColumn(item);
			event.stopPropagation();
		}
	};

	notifyGridColumns = () => {
		if (this.onColumnsChanged) {
			this.onColumnsChanged({ $columns: this.gridColumns });
		}
	};

	private updateContextMenuItems = () => {
		if (this.getMenuItems) {
			let options = [this.columnsMenuOption];
			options.pushAll(this.getMenuItems());
			this.contextMenuOptions = options;
		}
	};

	columnOptionFilter = (option: GridContextColumnItemOption): boolean => {
		return option.type === GridContextColumnType.GROUP_LABEL
			|| !this.columnsFilterText
			|| option.text.toLowerCase().contains(this.columnsFilterText.toLowerCase());
	};

	optionClass = (item: any): string => {
		let classes = [item.name];
		if (item.classes) {
			classes.pushAll(item.classes);
		}
		return classes.join(' ');
	};

	menuItemClick = (item: any) => {
		if (item.func) {
			item.func();
			this.updateContextMenuItems();
		}
	};

	isLeftDropdown = (): boolean => {
		return this.dropdownClass === DropdownClass.DROPDOWN_LEFT;
	};

	onMenuToggleKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.$timeout(() => {
				this.$element.find('ul :focusable').first().trigger('focus');
				this.focusedOptionNumber = 0;
				this.focusedOptionElement = this.$element.find('ul :focusable').get(0) as HTMLElement;
			});
		}
	};

	onMenuKeydown = (event: KeyboardEvent): void => {
		let focusChanged = false;
		if (KeyboardUtils.isEventKey(event, Key.TAB) || KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.stopPropagation();
			event.preventDefault();
			this.menuOpen = false;
			focusChanged = true;
			this.focusedOptionNumber = 0;
			this.$timeout(() => {
				this.$element.find(':focusable').first().trigger('focus');
			});
		}

		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			return;
		}


		let menuOptions = this.$element.find('ul :not(.kb-no-focus):focusable');
		if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			event.stopPropagation();
			event.preventDefault();

			if (!_.isNumber(this.focusedOptionNumber)) {
				focusChanged = true;
				this.focusedOptionNumber = 0;
			} else {
				focusChanged = true;
				this.focusedOptionNumber = (this.focusedOptionNumber === menuOptions.length - 1) ?
					menuOptions.length - 1 :
					this.focusedOptionNumber + 1;
			}
		} else if (KeyboardUtils.isEventKey(event, Key.UP)) {
			event.stopPropagation();
			event.preventDefault();

			// menu items may have disappeared after leaving menu
			let currentIndex = menuOptions.index(this.focusedOptionElement);

			focusChanged = true;
			this.focusedOptionNumber = (currentIndex > 0) ?
				currentIndex - 1 :
				0;
		}

		if (!focusChanged) return;

		(menuOptions.get(this.focusedOptionNumber) as HTMLElement).focus();
		this.focusedOptionElement = menuOptions.get(this.focusedOptionNumber) as HTMLElement;
	};
}

app.component('gridContextMenu', {
	bindings: {
		gridType: '<',
		reinitTrigger: '<',
		gridMode: '<?',
		gridColumns: '=?',
		getMenuItems: '<?',
		onColumnsChanged: '&?',
		maxColumns: '<?'
	},
	controller: GridContextMenuComponent,
	templateUrl: 'partials/context-menu/grid-context-menu.component.html'
});
