import { Component, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { MasterAccountPropertiesApiService } from '@app/modules/account-administration/api/master-account-properties-api.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';


@Component({
	selector: 'domain-restrictions',
	template: `
	<div class="panel panel-default link-sharing-settings">
		<div class="panel-heading">{{"mAccount.propRestrictMembership"|i18n}}</div>
		<div class="panel-body clearfix">
			<div class="d-flex align-items-center mb-16 br-domain-switch">
				<cb-toggle [(ngModel)]="isEnabled" (ngModelChange)="switchMembershipDomainStatus($event)">
				</cb-toggle>
			</div>
			<div *ngIf="isEnabled">
				<div class="row">
					<div class="col-sm-6">
						<div class="form-group">
							<label for="in-email-domain">{{"mAccount.propRestrictMembershipToDomains"|i18n}}</label>
							<div class="input-group">
								<input class="br-domain-name border-r-0" size="40" type="text" required
									[maxLength]="60" id="in-email-domain"
									[(ngModel)]="membershipDomain"
									(ngModelChange)="onDomainNameChange()"
								/>
								<button id="btn-domain-save" type="button"
										class="btn btn-primary"
										(click)="addToMembershipSelectedDomains(membershipDomain)"
										[disabled]="!isDomainValid(membershipDomain)">
									{{"mAccount.propRestrictSharingAddDomainBtn"|i18n}}</button>
							</div>
							<cb-notice *ngIf="hasValidErrors" class="d-inline-block mt-8 mr-2" type="danger" [dismissable] = "false">
									<span>{{"mAccount.propRestrictMembershipDomainError"|i18n}} </span>
							</cb-notice>
						</div>
						<div class="mt-16">
							<ul class="pl-8">
								<li *ngFor="let domain of defaultAllowedDomains">{{domain}}</li>
								<li *ngFor="let domain of allowedDomains" class="d-flex align-items-center">
									<span>{{domain}}</span>
									<a data-testid="btn-remove-domain"
										class="btn-icon ml-4 q-icon q-icon-delete"
										href="javascript:void(0)"
										(click)="deleteFromMembershipSelectedDomains(domain)"
										title="{{'common.remove'|i18n}}"
										attr.aria-label="{{'common.remove'|i18n}}">
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>`
})
export class DomainRestrictionsComponent {

	readonly defaultAllowedDomains = ['clarabridge.com', 'qualtrics.com'];
	@Input() isEnabled = false;
	@Input() allowedDomains: string[] = [];
	@Input() loading = false;
	@Output() loadingComplete = new EventEmitter<any>();
	membershipDomain: string = '';
	hasValidErrors: boolean = false;
	MIN_TLD_LEN = 2;
	MAX_TLD_LEN = 20;

	constructor(
		private readonly domainsProcessingService: DomainsProcessingService,
		private readonly masterAccountApiService: MasterAccountPropertiesApiService,
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService
	) {
	}

	switchMembershipDomainStatus(enabled: boolean): void {
		this.masterAccountApiService.updateProperty('membershipDomainRestricted', enabled)
			.then(() => {
				let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
				this.toastService.addToast(this.locale.getString(key, [this.locale.getString('mAccount.propRestrictMembership')]), AlertLevel.CONFIRM);
				this.loadingComplete.emit();
			});
	}

	addToMembershipSelectedDomains(domainName: string): void {
		if (this.isDomainValid(domainName)) {
			this.masterAccountApiService.addTrustedDomain(domainName).then(() => {
				this.membershipDomain = '';
				this.loading = false;
				this.allowedDomains.push(domainName);
			});
		}
	}

	isDomainValid(domain: string): boolean {
		return domain && !this.containsValidationErrors()
			&& !this.domainsProcessingService.isDomainAllowed(this.defaultAllowedDomains, this.allowedDomains, domain);
	}

	deleteFromMembershipSelectedDomains(domainName: string): void {
		this.masterAccountApiService.removeTrustedDomain(domainName)
			.then(() => this.loadingComplete.emit());
		let idx = this.allowedDomains.indexOf(domainName);
		if (idx !== -1) {
			this.allowedDomains.splice(idx, 1);
		}
	}

	onDomainNameChange = (): void => {
		this.hasValidErrors = this.containsValidationErrors();
	};

	// basic validation, but may need to create reusable - 20220512
	// identical validation is done in the backend
	containsValidationErrors = (): boolean => {
		if (_.isEmpty(this.membershipDomain)) {
			return true;
		}
		// hypen, char, dot
		return !(new RegExp(
			`^(?!-)[A-Za-z0-9-]+([\\-\\.]{1}[a-z0-9]+)*\\.[A-Za-z]{${this.MIN_TLD_LEN},${this.MAX_TLD_LEN}}$`)
			.test(this.membershipDomain));
	};
}


app.directive('domainRestrictions',
	downgradeComponent({ component: DomainRestrictionsComponent }));
