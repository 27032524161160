import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { FeatureToggleBaseComponent } from '@app/modules/account-administration/properties/feature-toggle-base';
import { ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';

@Component({
	selector: 'master-account-feature-toggle',
	template: `
	<simple-feature-toggle
		[(featureEnabled)]="featureEnabled"
		(featureEnabledChange)="toggleFeature($event)"
		[label]="label">
	</simple-feature-toggle>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterAccountFeatureToggleComponent extends FeatureToggleBaseComponent {

	@Input() feature: MasterAccountProperty;
	@Input() label: string;

	constructor(
		toastService: ToastService,
		locale: CxLocaleService,
		@Inject('maPropertiesService') maPropertiesService: MAPropertiesService,
	) {
		super(maPropertiesService, toastService, locale);
	}
}

app.directive('masterAccountFeatureToggle',
	downgradeComponent({component: MasterAccountFeatureToggleComponent}));
