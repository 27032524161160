import {
	AfterViewInit,
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	ElementRef,
	Input,
	OnDestroy,
	OnInit,
	ViewChild
} from '@angular/core';
import { ITranslationDisplayData } from '@app/modules/translation/translation-api.service';
import { TranslationCacheService } from '@app/modules/translation/translation-cache.service';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'translated-text',
	template: `
		<div #element>
			<div *ngIf="loading" class="d-flex justify-center">
				<span class="q-icon q-icon-spinner rotate-infinite"></span>
			</div>
			<div *ngIf="!!translateData">
				<div *ngIf="showTitle"><strong>{{'docExplorer.translatedText' | i18n}}:</strong></div>
				<span>{{translateData.text}}</span>
			</div>
			<div *ngIf="error">
				<p class="italic" [i18n]="'docExplorer.translationFailed'"></p>
			</div>
		</div>
`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslatedTextComponent implements OnInit, OnDestroy, AfterViewInit {

	@Input() cacheKey: string;
	@Input() text: string;
	@Input() showTitle: boolean = true;

	@ViewChild('element') translatedTextElement: ElementRef<HTMLElement>;

	loading: boolean;
	translateData: ITranslationDisplayData;
	error: boolean;
	private hasBeenTranslated = false;
	private intersectionObserver: IntersectionObserver;
	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly translationCache: TranslationCacheService
	) {}

	ngOnInit(): void {
		this.loading = true;
	}

	ngAfterViewInit() {
		this.intersectionObserver = new IntersectionObserver((entries, observer) => {
			entries.forEach(entry => {
				if (entry.target === this.translatedTextElement.nativeElement) {
					if (entry.isIntersecting && !this.hasBeenTranslated) {
						this.queueTranslation();
					}
				}
			});
		});
		this.intersectionObserver.observe(this.translatedTextElement.nativeElement);
	}


	queueTranslation(): void {
		this.translationCache.queueTranslation(this.cacheKey, this.text).then(result => {
			this.translateData = result;
			this.loading = false;
			this.hasBeenTranslated = true;
			this.ref.markForCheck();
			this.translationCache.updateLastTranslateLanguage(result.from);
		}, () => {
			this.error = true;
			this.loading = false;
			this.ref.markForCheck();
		});
	}

	ngOnDestroy(): void {
		this.translationCache.cancelTranslation(this.cacheKey);
		this.intersectionObserver.unobserve(this.translatedTextElement.nativeElement);
	}

}

app.directive('translatedText', downgradeComponent({component: TranslatedTextComponent}));
