import { IShareEntityType } from '@cxstudio/sharing/sharing-service.service';

export class DiscussionMentionsUtils {

	static replaceMentionTags = (message: string) => {
		let outer = $(`<div>${message}</div>`);
		/* eslint-disable no-invalid-this */
		outer.find('input').replaceWith(function(...args): Element {
			let tag = $(this);
			return `<a title="${tag.attr('title')}" class="discussion-comment-mention">${tag.attr('value')}</a>` as unknown as Element;
		});
		/* eslint-enable no-invalid-this */
		return outer.html();
	};

	static extractMentions = (message: string) => {
		let html = $(`<div>${message}</div>`);
		let result = [];
		html.find('input').each((i, item) => {
			let mention = {
				id: parseInt($(item).attr('data-id'), 10),
				type: $(item).attr('data-type')
			};
			result.push(mention);
		});
		return result;
	};


	static generateElement = (item): string => {
		return `<input type="button"
			role="textbox"
			data-id="${item._id}"
			data-type="${item.type}"
			title="${item._hover}"
			disabled="disabled"
			value="${item._displayName}">`;
	};


	static getDisplayName = (item): string => {
		let firstName = (item.firstName && item.firstName.length > 0 && item.firstName !== 'null') ? item.firstName : undefined;
		let lastName = (item.lastName && item.lastName.length > 0 && item.lastName !== 'null') ? item.lastName : undefined;
		if (firstName || lastName) {
			return (firstName ? firstName + ' ' : '') + (lastName ? lastName : '');
		} else {
			return item.userEmail || 'null';
		}
	};


	static processUsers = (userEntities: any[], allSharedEntities: {[key: string]: any}): any[] => {
		return _.chain(userEntities).forEach((user) => {
			if (allSharedEntities[`user${user.entity.userEmail}`]) {
				return;
			}
			user._search = `${user.entity.firstName} ${user.entity.lastName}(${user.entity.userEmail})`;
			user._displayName = DiscussionMentionsUtils.getDisplayName(user.entity);
			user._hover = user.entity.userEmail;
			user._id = user.entity.userId;
			allSharedEntities[`user${user.entity.userEmail}`] = user;
		}).filter(user => user._id).value();
	};

	static processGroups = (groupEntities: any[], allSharedEntities: {[key: string]: any}) => {
		let res = [];
		_.forEach(groupEntities, (group) => {
			group._search = group.entity.groupName;
			group._displayName = group.entity.groupName;
			group._hover = group.entity.groupName;
			group._id = group.entity.groupId;
			allSharedEntities[`group${group.entity.groupName}`] = group;
			res.push(group);
			// put all group members into result, filtering already existing
			_.forEach(group.entity.users, (user) => {
				if (!allSharedEntities[`user${user.userEmail}`]) {
					let entity = {
						type: IShareEntityType.USER,
						entity: user,
						_id: user.userId,
						_search: `${user.firstName} ${user.lastName}(${user.userEmail})`,
						_displayName: DiscussionMentionsUtils.getDisplayName(user),
						_hover: user.userEmail
					};

					allSharedEntities[`user${user.userEmail}`] = entity;
					res.push(entity);
				}
			});
		});
		return res;
	};

}
