import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';

@Injectable({
	providedIn: 'root'
})
export class ErrorMessageProcessingService {

	constructor(
		private readonly locale: CxLocaleService) {
	}

	processGeneralReportError = (message: string, widgetSettings?) => {
		if (message?.contains('Resource is not found')) {
			return this.locale.getString('widget.contentProviderUnavailable');
		}

		if (message?.contains('Connection timed out')) {
			return this.locale.getString('widget.contentProviderTimeout');
		}

		if (message?.contains('Analytics server is not reachable')) {
			return message.replace('400 response: ', '');
		}

		if (message?.contains('Analytics report failure: ')) {
			return message.replace('400 response: Analytics report failure: ', '').replace(/<br \/>/g, '\n');
		}

		if (message?.contains('No data')) {
			return this.locale.getString('widget.noData');
		}

		if (message?.contains('No access to organization hierarchy node')) {
			return this.locale.getString('widget.noData');
		}

		if (message?.contains('Organization Hierarchy has not been activated')) {
			return this.locale.getString('widget.orgHierarchyGroupingUnavailable');
		}

		if (message?.contains('applied filters unavailable')) {
			let appliedFiltersWithErrorNames = widgetSettings.properties.appliedFilters.filters
				.filter(f => f.error)
				.map(f => f.name);
			let filterString = appliedFiltersWithErrorNames.join(', ');
			return this.locale.getString('widget.appliedFiltersUnavailable',
				{owner: widgetSettings.properties.runAs, filters: filterString});
		}

		if (message?.contains('Drivers is not found')) {
			let driversGroup = _.find(widgetSettings.properties.selectedAttributes, AnalyticMetricTypes.isDrivers);
			return this.locale.getString('widget.driversNotFound',
				{driversName: driversGroup.displayName});
		}

		return this.locale.getString('error.reportError') + ':\n' +
			(message ? message.replace('400 response:', '')
				: this.locale.getString('error.unknown'));
	};

	getAdditionalMessageClass = (message: string) => {
		return message === this.locale.getString('widget.noData')
			? 'no-data-message'
			: '';
	};
}

app.service('errorMessageProcessing', ErrorMessageProcessingService);
