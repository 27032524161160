import * as _ from 'underscore';
import { Component, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OAuthAuthorizedGrantTypes } from '../oauth-authorized-grant-type.factory';
import { OAuthAuthorityRoles } from '../oauth-authority-roles.factory';
import { OauthClientDetailsApplicationKind } from '../oauth-client-details-application-kind';
import { OAuthClientDetailsEditDialogComponent } from '../oauth-client-details-edit-dialog.component';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'oauth-link-client-details-edit-dialog',
	templateUrl: '../oauth-client-details-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OAuthLinkClientDetailsEditDialogComponent extends OAuthClientDetailsEditDialogComponent {
	supportDesignerScope = false;

	constructor(
		protected modal: NgbActiveModal,
		protected ref: ChangeDetectorRef,
		protected oauthAuthorizedGrantTypes: OAuthAuthorizedGrantTypes,
		protected oauthAuthorityRoles: OAuthAuthorityRoles,
		locale: CxLocaleService
	) {
		super(modal, ref, oauthAuthorizedGrantTypes, oauthAuthorityRoles, locale, OauthClientDetailsApplicationKind.CB_LINK);
	}

	protected init(): void {
		this.oauthScopes = _.map(this.clientDetails.scope, scope => scope.name);
		this.initGrantTypes();
	}

	save = () => {
		this.saveCommon();
		this.modal.close(this.clientDetails);
	};

	disableSave = () => {
		return this.disableSaveCommon();
	};

}
