<div>
	<h2>{{label}}</h2>

	<h3>{{'widget.minimum'|i18n}}</h3>
	<div class="form-group form-inline">
		<cb-radio-group
			[inline]="true"
			[options]="minRadioOptions"
			[disabled]="locked"
			(ngModelChange)="changeMinVal($event)"
			[(ngModel)]="autoMinVal">
		</cb-radio-group>

		<input type="text"
			   attr.aria-label="{{'widget.minimum'|i18n}}"
			   class="br-axis-limit-min adv-chart-settings-field ml-16"
			   [(ngModel)]="minFixedVal"
			   [disabled]="locked || visualProps[axisAutoMin]"
			   (blur)="validate(false)">
	</div>

	<h3>{{'widget.maximum'|i18n}}</h3>
	<div class="form-group form-inline">
		<cb-radio-group
			[inline]="true"
			[options]="maxRadioOptions"
			[disabled]="locked"
			(ngModelChange)="changeMaxVal($event)"
			[(ngModel)]="autoMaxVal">
		</cb-radio-group>

		<input type="text"
			   attr.aria-label="{{'widget.maximum'|i18n}}"
			   class="br-axis-limit-max adv-chart-settings-field ml-16"
			   [(ngModel)]="maxFixedVal"
			   [disabled]="locked || visualProps[axisAutoMax]"
			   (blur)="validate(true)">
	</div>
	<div *ngIf="prefix==='secondary'" class="adv-chart-settings-block">
		<checkbox-button
			class="br-axis-limit-align"
			button-class="btn-sm pl-0"
			[(ngModel)]="visualProps.alignTicks"
			[disabled]="locked"
			label="{{'widget.tickAlign'|i18n}}">
		</checkbox-button>
	</div>
</div>
