import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export class DashboardTemplatesApiService {

	readonly BASE_URL = 'rest/dashboard-templates';

	constructor(private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private treeService: TreeService) {}

	getTemplates = (): ng.IHttpPromise<ITreeItem[]> => {
		return this.$http.get(`${this.BASE_URL}`);
	};

	getSystemTemplates = (): ng.IHttpPromise<ITreeItem[]> => {
		return this.$http.get(`${this.BASE_URL}/system`);
	};

	getTemplate = (id: number): ng.IPromise<CustomTemplate> =>
		this.$http.get(`${this.BASE_URL}/${id}`).then(this.httpHandlers.success);

	shareTemplate = (id: number): ng.IHttpPromise<void> =>
		this.$http.put(`${this.BASE_URL}/${id}/share`, null);

	unshareTemplate = (id: number): ng.IHttpPromise<void> =>
		this.$http.put(`${this.BASE_URL}/${id}/unshare`, null);

	deleteTemplate = (id: number): ng.IHttpPromise<void> =>
		this.$http.delete(`${this.BASE_URL}/${id}`);

	upgradeTemplate = (id: number): ng.IHttpPromise<void> =>
		this.$http.post(`${this.BASE_URL}/${id}/upgrade`, null);

	createDashboardFromTemplate = (requestData: any): ng.IHttpPromise<Dashboard> =>
		this.$http.post(`${this.BASE_URL}/dashboard`, angular.toJson(this.treeService.copyItem(requestData)));

	createTemplateFromDashboard = (dashboardId: number, requestData): ng.IHttpPromise<CustomTemplate> =>
		this.$http.post(`${this.BASE_URL}/dashboard/${dashboardId}`,
			angular.toJson(this.treeService.copyItem(requestData)));

	updateDashboardTemplate = (templateId: number, requestData): ng.IHttpPromise<CustomTemplate> => {
		return this.$http.put(`${this.BASE_URL}/${templateId}`, requestData);
	};

	updateTemplateMappings = (templateId: number, requestData): ng.IHttpPromise<CustomTemplate> => {
		return this.$http.put(`${this.BASE_URL}/mappings/${templateId}`, requestData);
	};

	exportTemplates(): ng.IHttpPromise<string> {
		return this.$http.get(`${this.BASE_URL}/export-all`);
	}

	exportTemplate(templateId: number): ng.IHttpPromise<string> {
		return this.$http.get(`${this.BASE_URL}/${templateId}/export`);
	}

	getImportTemplatesUrl(): string {
		return `${this.BASE_URL}/import-all`;
	}
}

app.service('dashboardTemplatesApiService', DashboardTemplatesApiService);
