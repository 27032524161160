import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CacheOptions } from '@cxstudio/common/cache-options';
import MetricsApiService from '@app/modules/metric/services/metrics-api.service';
import WorkspaceMetricsApiService from '@app/modules/metric/services/workspace-metrics-api.service';
import { AccountOrWorkspace, AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ColorConstantsService, ColorInfo } from '@cxstudio/reports/utils/color/color-constants';
import { ApplicationThemeScope } from '@cxstudio/header/application-theme-scope';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { MetricCalculation } from '@cxstudio/metrics/entities/metric-calculation';

@Injectable({
	providedIn: 'root'
})
export class MetricsService {
	constructor(
		private readonly metricsApi: MetricsApiService,
		private readonly workspaceMetricsApi: WorkspaceMetricsApiService,
		private readonly applicationThemeService: ApplicationThemeService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	getMetrics(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<Metric[]> {
		return ProjectAssetsHelper.getAssetsForProject<Metric[]>(
			project,
			(accountProject) => this.metricsApi.getMetrics(accountProject, cache),
			(workspaceProject) => this.workspaceMetricsApi.getMetrics(workspaceProject, cache),
			(projectId) => []
		);
	}

	getMetricsForWorkspace(workspace: AccountOrWorkspace, cache = CacheOptions.CACHED): Promise<Metric[]> {
		return WorkspaceTransitionUtils.isWorkspace(workspace)
			? this.workspaceMetricsApi.getMetricsForWorkspace(workspace, cache)
			: this.metricsApi.getMetricsForAccount(workspace, cache);
	}

	getPredefinedMetrics = (project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<Metric[]> => {
		return ProjectAssetsHelper.getAssetsForProject<Metric[]>(
			project,
			(accountProject) => this.metricsApi.getPredefinedMetrics(accountProject, cache),
			(workspaceProject) => this.workspaceMetricsApi.getPredefinedMetrics(workspaceProject, cache),
			(projectId) => []
		);
	};

	getPredefinedMetricsForWorkspace = (workspace: AccountOrWorkspace, cache = CacheOptions.CACHED): Promise<Metric[]> => {
		return WorkspaceTransitionUtils.isWorkspace(workspace)
			? this.workspaceMetricsApi.getPredefinedMetricsForWorkspace(workspace, cache)
			: this.metricsApi.getPredefinedMetricsForAccount(workspace, cache);
	};

	getDynamicPredefinedMetrics = (project: AccountOrWorkspaceProject, isApplicationScope: boolean): Promise<Metric[]> => {
		return this.getPredefinedMetrics(project)
			.then(response => this.adjustPredefinedMetricsColor(response, isApplicationScope));
	};

	getPredefinedMetricDefaultsForMasterAccount = (
		isApplicationScope: boolean, cache = CacheOptions.CACHED
	): Promise<Metric[]> => {
		return this.metricsApi.getPredefinedMetricDefaultsForMasterAccount(cache)
			.then(response => this.adjustPredefinedMetricsColor(response, isApplicationScope));
	};

	private adjustPredefinedMetricsColor = (metrics: Metric[], isApplicationScope: boolean): Metric[] => {
		const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
		let themeScope = isApplicationScope ? ApplicationThemeScope.APPLICATION : ApplicationThemeScope.DASHBOARD_CONTAINER;
		if (this.applicationThemeService.isDarkMode(themeScope)) {
			let darkModeColorInfos = ColorConstantsService.getDarkModeColorInfos(isMlSentimentEnabled);
			_.each(metrics, (metric) => {
				const colorInfo = darkModeColorInfos[metric.name];
				let colors = colorInfo.thresholdColors;
				if (colors && !this.isCustomSentimentColors(metric, isMlSentimentEnabled)) {
					metric.definition.colorPalette = cloneDeep(colors);
					metric.definition.calculation.colorPalette = cloneDeep(colors);
				}

				if (colorInfo.fallbackColor && colorInfo.fallbackDisplayName) {
					this.updateCalculationFallbackValues(metric.definition.calculation, colorInfo);
				}
			});
		} else {
			let defaultSentimentColor = ColorConstantsService.getDefaultSentimentColorInfo(isMlSentimentEnabled);
			_.each(metrics, (metric) => {
				if (metric.name === PredefinedMetricConstants.SENTIMENT) {
					if (defaultSentimentColor.fallbackColor && defaultSentimentColor.fallbackDisplayName) {
						this.updateCalculationFallbackValues(metric.definition.calculation, defaultSentimentColor);
					}
				}
			});
		}
		return metrics;
	};

	private updateCalculationFallbackValues = (calculation: MetricCalculation, colorInfo: ColorInfo) => {
		// If user has a saved metric before adding support for fallbackColor/fallbackDisplayName,
		// then add it now.
		calculation.fallbackColor = colorInfo.fallbackColor;
		calculation.fallbackDisplayName = colorInfo.fallbackDisplayName;

	};

	private isCustomSentimentColors = (metric: Metric, isMlSentimentEnabled: boolean): boolean => {
		let defaultSentimentColors = ColorConstantsService.getDefaultSentimentColorInfo(isMlSentimentEnabled).thresholdColors;
		const areMetricColorsDifferent = !_.isEqual(metric.definition.colorPalette, defaultSentimentColors);
		const areMetricCalcColorsDifferent = !_.isEqual(metric.definition.colorPalette, defaultSentimentColors);
		return metric.name === PredefinedMetricConstants.SENTIMENT && metric.id > 0
			&& (areMetricColorsDifferent
				|| areMetricCalcColorsDifferent);
	};
}

app.service('metricsService', downgradeInjectable(MetricsService));
