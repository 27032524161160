import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IPagingSearch, PagingSearchState } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';

export class MultiselectFooterController implements ng.IController {

	pagingSearch: IPagingSearch;
	filterText: string;

	constructor(
		private locale: ILocale,
	) {}

	$onInit(): void {

	}

	loadMore = (): void => {
		this.pagingSearch.loadMore(this.filterText);
	};

	showLoadMore = (): boolean => {
		return this.pagingSearch.searchState !== PagingSearchState.FIRST_SEARCH;
	};

	getLoadMoreText = (): string => {
		if (this.pagingSearch.searchState === PagingSearchState.FIRST_LOAD_MORE && this.pagingSearch.emptyResult)
			return this.locale.getString('common.refresh');
		else return this.locale.getString('common.loadMore');
	};

	canLoadMore = (): boolean => {
		return this.pagingSearch.searchState !== PagingSearchState.NO_MORE_DATA
			&& !this.pagingSearch.firstSearchingPromise
			&& !this.pagingSearch.pagingSearchingPromise;
	};

	getUpdatedText = (): string => {
		if (this.pagingSearch.firstSearchingPromise
				|| this.pagingSearch.pagingSearchingPromise) {
			return '';
		} else if (this.pagingSearch.searchState === PagingSearchState.NO_MORE_DATA) {
			if (this.pagingSearch.emptyResult) {
				return this.locale.getString('filter.noMoreData');
			} else {
				return this.getUpdatedTextByNewItems();
			}
		} else if (this.pagingSearch.searchState !== PagingSearchState.FIRST_LOAD_MORE) {
			return this.getUpdatedTextByNewItems();
		} else {
			return '';
		}
	};

	private getUpdatedTextByNewItems = (): string => {
		if (this.pagingSearch.hasDottedItems) {
			return this.locale.getString('filter.updatedLabel');
		} else {
			return this.locale.getString('filter.loadedLabel');
		}
	};
}

app.component('oldMultiselectFooter', {
	controller: MultiselectFooterController,
	templateUrl: 'partials/components/multiselect/old-multiselect-footer.component.html',
	bindings: {
		pagingSearch: '<',
		filterText: '<'
	}
});
