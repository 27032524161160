import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'edit-widget-button',
	template: `
	<button (click)="edit()"
		class="q-icon q-icon-edit widget-header-menu btn btn-icon p-8 no-border"
		title="{{'common.edit'|i18n}}"
		attr.aria-label="{{'common.edit'|i18n}}">
	</button>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EditWidgetButtonComponent {

	@Input() widget: Widget;
	@Input() widgetActions: IWidgetActions;

	edit(): void {
		this.widgetActions.emit(WidgetAction.OPEN_SETTINGS);
	}

}

app.directive('editWidgetButton', downgradeComponent({component: EditWidgetButtonComponent}));
