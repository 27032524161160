import { Component, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { LabelsApiService } from '@cxstudio/services/data-services/labels-api.service';
import { Label } from '@cxstudio/dashboards/entity/label';
import { downgradeComponent } from '@angular/upgrade/static';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core';

export interface IBulkUpdateLabelsParams {
	itemsName: string;
}

@Component({
	selector: 'bulk-update-labels-modal-component',
	template: `
		<div class="form-horizontal">
			<modal-header
				(cancel)="cancel()"
				[modalTitle]="modalHeaderText">
			</modal-header>


			<div class="modal-body">
				<form class="form-horizontal" name="editLabelsDialog" role="form">
					<div class="mb-16">
						<span [i18n]="'common.editLabelsReplace'"
							  [i18nParams]="{objects: itemsName}"></span>
					</div>
					<div class="panel panel-spacing-only mb-0"></div>
					<div class="form-group mb-0">
						<label class="col-sm-3 control-label help-label">
							{{'common.labels' | i18n}}
							<cb-inline-help right>
								<help-body>{{'dashboard.labelHelp' | i18n}}</help-body>
							</cb-inline-help>
						</label>
						<div class="col-sm-9">
							<labels-input #labelsForm
								[labels]="labels"
								[loadLabels]="loadLabels"
								(onLabelAdded)="labelAdded($event)"
								(onLabelRemoved)="labelRemoved($event)">
							</labels-input>
						</div>
					</div>
				</form>
			</div>

			<save-modal-footer [isDisabled]="isSaveDisabled()"
							   [saveText]="saveText" [cancelText]="cancelText" (save)="save()" (cancel)="cancel()">
			</save-modal-footer>
		</div>
	`
})
export class BulkUpdateLabelsModalComponent implements OnInit {
	labels: any[];
	itemsName: string;
	message: string;
	modalHeaderText: string;
	saveText: string;
	cancelText: string;
	@Input() input: any;
	@ViewChild('labelsForm') labelsForm;
	constructor(
		private readonly activeModal: NgbActiveModal,
		private readonly locale: CxLocaleService,
		@Inject('labelsApiService') private readonly labelsApiService: LabelsApiService,
	) {}

	ngOnInit(): void {
		this.labels = [];
		this.itemsName = this.input.itemsName;
		this.modalHeaderText = this.locale.getString('common.editLabels');
		this.saveText = this.locale.getString('administration.save');
		this.cancelText = this.locale.getString('administration.cancel');
	}

	save = () => {
		this.activeModal.close(this.labels);
	};

	cancel = () => {
		this.activeModal.dismiss();
	};

	labelAdded = (label: string): void => {
		this.labels.push(label);
	};

	labelRemoved = (label: string): void => {
		this.labels = _.without(this.labels, label);
	};

	loadLabels = (query: string): ng.IPromise<Label[]> => {
		return this.labelsApiService.getLabels(query);
	};

	isSaveDisabled = (): boolean => {
		return this.labelsForm?.tagsInput?.invalidTag || false;
	};

}

app.directive('bulkUpdateLabelsModalComponent',
	downgradeComponent({component: BulkUpdateLabelsModalComponent}));
