import * as _ from 'underscore';
import * as moment from 'moment';

import ILocale from '@cxstudio/interfaces/locale-interface';
import { StudioAlert } from '../entities/studio-alert';
import { Security } from '@cxstudio/auth/security-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { NameService } from '@cxstudio/common/name-service';
import { AlertingApiService } from '../api/alerting-api.service';
import AlertEditDialogResult from '../entities/alert-edit-dialog-result';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { IAlertPredefinedSettings } from '../entities/alert-predefined-settings';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { UibTab } from '@cxstudio/common/uib-tab';
import { MetricAlertEditorTab } from './metric-alert-editor-tab';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { EngagorApiService, EngagorAssignees } from '@cxstudio/services/data-services/engagor-api.service';
import { AlertAutofillUtils } from '@app/modules/alert/alert-autofill-utils';
import { CasePriority } from '@cxstudio/alert-subscription-templates/types/case-priority.enum';
import IAlertSubscriptionTemplate, { AlertSubscriptionTemplateType } from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import AlertSubscriptionTemplatesApi from '@cxstudio/alert-subscription-templates/alert-subscription-templates-api.service';
import { FilterValidationMessages } from '@cxstudio/report-filters/constants/filter-validation-messages.constant';
import { Assignee } from '@cxstudio/alert-subscription-templates/types/assignee';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { TimezoneService } from '@app/shared/services/timezone.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { ScheduleUtilsService } from '@app/modules/dashboard/schedule/schedule-utils.service';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';
import { ObjectSharingConstants } from '@app/modules/asset-management/object-sharing-constants';

export interface DashboardSharedEntities {
	GROUP: any[];
	META_GROUP: any[];
	USER: any[];
}

export interface PermissionValidationResult {
	close: boolean;
	share?: boolean;
	entitiesWithLackOfAccess?: any[];
}

export class MetricAlertEditorComponent implements ng.IComponentController {

	private close: (result?: { $value: AlertEditDialogResult }) => void;
	private dismiss: (result?: any) => void;
	private resolve: {
		alert: any;
		projectSelection: IProjectSelection;
		predefinedSettings: IAlertPredefinedSettings;
		allAlerts: StudioAlert[];
		enabledAlertLimitReached: boolean;
	};

	activeTabIndex: string;
	tabs: UibTab[];
	engagorTopics: EngagorTopic[];
	caseCreation: any;
	notificationSettingsValid: boolean = true;

	alert: StudioAlert;
	allAlerts: StudioAlert[];
	alertExpiration: string;
	predefinedSettings: IAlertPredefinedSettings;
	caseCreationTabLoading?: any;
	engagorAssignees?: EngagorAssignees;
	caseAssignee: Assignee;

	ui: { currentStep: number; filterRulesError: any[]; calculationError: boolean; notificationSettingsError: boolean}
		= { currentStep: 0, filterRulesError: null, calculationError: false, notificationSettingsError: false};

	/** Array for entities with the changed access */
	private changedEntities: any[] = [];
	/** Array for previously shared entities */
	private sharedEntities: any[] = [];


	private searchingAlertEditors: boolean = false;
	private modalTitle: string;
	private alertProjectProps: IProjectSelection;
	private datepicker;
	private weekDaysOptions;
	private recurrence;
	private time;
	private zoneOffsetOptions;
	private dateFormat;
	private filtersValid;
	enabledAlertLimitReached: boolean;
	initialLinkedDashboardId: number;

	changeTracker: {
		updates: {
			changedEntities?: any[];
			sharedEntities?: any[];
		};
	} = { updates: {}};

	private groupingValidator: {validate?: (grouping: any) => ng.IPromise<boolean>};

	inboxTemplates: IAlertSubscriptionTemplate[];

	constructor(
		private locale: ILocale,
		private nameService: NameService,
		private security: Security,
		private alertingApiService: AlertingApiService,
		private scheduleUtilsService: ScheduleUtilsService,
		private timezoneService: TimezoneService,
		private $scope: ISimpleScope,
		private $filter: ng.IFilterService,
		private dateService,
		private dashboardApiService: DashboardApiService,
		private dashboardListService: DashboardListService,
		private filterValidationService,
		private globalNotificationService: GlobalNotificationService,
		private organizationApiService: OrganizationApiService,
		private $q: ng.IQService,
		private betaFeaturesService: BetaFeaturesService,
		private engagorApiService: EngagorApiService,
		private alertSubscriptionTemplatesApi: AlertSubscriptionTemplatesApi,
		private filterManagementApiService: FilterManagementApiService,
		private downgradeDialogService: DowngradeDialogService,
	) {}

	private populateExistingAlertSettings(): ng.IPromise<void> {
		this.alert = angular.copy(this.resolve.alert);
		this.populateGeneralAlertSettings();

		return this.updateLinkedDashboard(this.alert).then(() => {
			this.initialLinkedDashboardId = this.alert.notification ? this.alert.notification.linkDashboard : null;
			this.modalTitle = this.locale.getString('alert.editAlertHeading', {name: this.resolve.alert.name});

			this.alert.selectedOwner = {
				userId: this.alert.ownerId,
				userEmail: this.alert.ownerName
			};
		});
	}

	private updateLinkedDashboard(alert: StudioAlert): ng.IPromise<void> {
		return this.alertingApiService.getStudioAlert(alert.id).then(alertWithActualLinkedDashboard => {
			let currentLinkDashboard = alert.notification.linkDashboard;
			let actualLinkDashboard = alertWithActualLinkedDashboard.notification.linkDashboard;
			if (currentLinkDashboard !== actualLinkDashboard) {
				alert.notification.linkDashboard = actualLinkDashboard;
				alert.notification.linkDashboardName = '';
			}
		});
	}

	private populateNewAlertSettings(): void {
		this.modalTitle = this.locale.getString('alert.newAlertHeading');

		this.alert = {
			name: this.nameService.uniqueName('Alert', this.allAlerts, 'name'),
			ownerId: this.security.getUser().userId,
			ownerName: this.security.getUser().userEmail,
			enabled: !this.enabledAlertLimitReached,
			contentProviderId: this.resolve.projectSelection.contentProviderId,
			accountId: this.resolve.projectSelection.accountId,
			projectId: this.resolve.projectSelection.projectId,
			startDate: moment().startOf('day').add(1, 'day').add(5, 'minutes').toDate(),
			timezoneOffset: String(moment().utcOffset()),
			cronExpression: '0 5 0 ? * 1',
			caseSettings: {
				createCase: false,
				multiCases: false,
				priority: CasePriority.LOW,
				title: this.locale.getString('alert.defaultEmailBody'),
				description: this.locale.getString('alert.defaultCaseCreateBody')
			}
		};
		this.populateGeneralAlertSettings();

		if (!_.isUndefined(this.resolve.predefinedSettings)) {
			this.applyPredefinedSettings(this.resolve.predefinedSettings);
		}

		this.alert.endDate = this.scheduleUtilsService.getScheduleExpirationDate(this.alert.startDate);
		this.alert.selectedOwner = this.security.getUser();
	}

	private populateGeneralAlertSettings(): void {
		this.dateFormat = DateTimeFormat.DEFAULT_DATE;
		this.zoneOffsetOptions = this.timezoneService.getUTCOffsets();
		this.time = this.scheduleUtilsService.getDefaultTimeObject(this.alert.startDate, this.alert.timezoneOffset);
		let showampm = this.security.loggedUser && this.security.loggedUser.dateFormat === 'us';
		this.scheduleUtilsService.setAmPmHour(this.time, showampm);
		if (this.alert.cronExpression) {
			let result = this.scheduleUtilsService.getScheduleInitialState(this.alert.cronExpression);
			this.recurrence = result.recurrence;
			this.weekDaysOptions = result.weekDaysOptions;
		} else {
			this.recurrence = {day: 1, week: 1, month: 1, dayOfMonth: 1};
			this.weekDaysOptions = this.scheduleUtilsService.getWeekDaysOptions();
		}
		this.updateAlertExpirationDate();

		this.alertProjectProps = {
			contentProviderId: this.alert.contentProviderId,
			accountId: this.alert.accountId,
			projectId: this.alert.projectId
		};
	}

	private applyPredefinedSettings = (predefinedSettings: IAlertPredefinedSettings): void => {
		if (!_.isUndefined(predefinedSettings.alertName)) {
			let predefinedAlertName = this.resolve.predefinedSettings.alertName;
			this.alert.name = this.nameService.uniqueName(predefinedAlertName, this.allAlerts, 'name');
		}

		this.alert.trigger = {};

		if (!_.isUndefined(predefinedSettings.calculation)) {
			this.alert.trigger['calculation'] = predefinedSettings.calculation;
		}

		if (!_.isUndefined(predefinedSettings.filter)) {
			this.alert.trigger['filterRules'] = predefinedSettings.filter;
		}
	};

	private updateAlertExpirationDate(): void {
		let dateFormat = this.dateService.getUserDateFormat(DateTimeFormat.BASIC_DATE);
		this.alertExpiration = this.$filter('date')(this.alert.endDate, dateFormat);
	}

	$onInit = () => {
		this.$scope.$on('processCron', this.processCron);
		this.$scope.$watch('$ctrl.alert.startDate', (newValue: any, oldValue: any) => {
			// fix for bug in angular date picker. Do not allow it to turn date into a string.
			if (oldValue && newValue && oldValue.getTime() === newValue.getTime()) return;

			if (_.isString(newValue)) {
				this.alert.startDate = oldValue;
			}

			if (!this.alert.trigger || this.alert.trigger.scheduleType !== 'once') {
				this.alert.endDate = this.scheduleUtilsService.getScheduleExpirationDate(this.alert.startDate);
				this.updateAlertExpirationDate();
			}

			this.processCron();
		});

		this.$scope.$watch(this.validateFilter, (validationResult) => {
			this.ui.filterRulesError = validationResult === FilterValidationMessages.VALID
				? null
				: validationResult;
			this.filtersValid = validationResult === null;
		});

		this.allAlerts = (this.resolve && this.resolve.allAlerts)
			? this.resolve.allAlerts
			: [];

		this.enabledAlertLimitReached = this.resolve.enabledAlertLimitReached;

		if (this.isNewAlert()) {
			this.populateNewAlertSettings();
		} else {
			this.populateExistingAlertSettings();
		}

		this.groupingValidator = {};

		this.initTabs();
	};

	testAlert = () => {
		this.validateAndPopulateErrors();
		if (this.ui.filterRulesError !== null || this.ui.notificationSettingsError || this.ui.calculationError) {
			return;
		}
		this.alertingApiService.testAlert(this.alert).then(() => {
			this.globalNotificationService.addSuccessNotification(this.locale.getString('alert.testAlertSent'));
		});
	};

	save = () => {
		this.validateAndPopulateErrors();
		if (this.ui.filterRulesError !== null || this.ui.notificationSettingsError || this.ui.calculationError) {
			return;
		}
		let alertSettings = this.convertSettingsForBackend(this.alert);

		let linkedDashboardId = alertSettings.notification ? alertSettings.notification.linkDashboard : null;
		let linkedDashboardChanged = linkedDashboardId && linkedDashboardId !== this.initialLinkedDashboardId;
		let linkedDashboardHasPublicStatus = this.isLinkedDashboardSharedForPublic(linkedDashboardId);

		let shareResults = $.extend({changedEntities: [], sharedEntities: []}, this.changeTracker.updates);
		let changedSubscribers = [];
		changedSubscribers.pushAll(shareResults.changedEntities);
		changedSubscribers.pushAll(
			shareResults.sharedEntities.filter(
				entity => (entity.action === ShareAction.UPDATE) || (entity.action === ShareAction.DELETE)));

		changedSubscribers
			.filter(entity => entity.action === ShareAction.DELETE)
			.forEach(entity => delete entity.permission);

		if (!linkedDashboardHasPublicStatus && this.hasCurrentUserShareAccess(linkedDashboardId)
			&& (!changedSubscribers.isEmpty() || linkedDashboardChanged)) {
			this.validateLinkedDashboardPermissions(changedSubscribers, linkedDashboardId).then(result => {
				if (result.close) {
					this.closeDialog(alertSettings, changedSubscribers, result.share, result.entitiesWithLackOfAccess);
				}
			});
		} else {
			this.closeDialog(alertSettings, changedSubscribers);
		}
	};

	private isLinkedDashboardSharedForPublic(linkedDashboardId: number): boolean {
		let dashboards: any[] = this.dashboardListService.getCurrentDashboardsList();
		let linkedDashboard;
		if (linkedDashboardId && dashboards) {
			linkedDashboard = _.find(dashboards, dashboard => {
				return dashboard.id === linkedDashboardId;
			});
		}
		return linkedDashboard && linkedDashboard.sharingStatus === SharingStatus.PUBLIC;
	}

	private hasCurrentUserShareAccess(linkedDashboardId: number): boolean {
		let hasShareAccess: boolean = false;
		let currentUserDashboards: any[] = this.dashboardListService.getCurrentDashboardsList();
		let linkedDashboard: any = _.findWhere(currentUserDashboards, {id: linkedDashboardId});
		if (linkedDashboard) {
			let currentUserId: number = this.security.getUser().userId;
			hasShareAccess = linkedDashboard.ownerId === currentUserId
				|| (!linkedDashboard.properties.preventDashboardEditorsShare && linkedDashboard.permissions.EDIT);
		}
		return hasShareAccess;
	}

	private validateLinkedDashboardPermissions = (changedAlertSubscribers: any[],
			linkedDashboardId: number): ng.IPromise<PermissionValidationResult> => {
		return this.dashboardApiService.getDashboardUsers(linkedDashboardId).then(response => {
			let alertSharedItems = this.sharedEntities;
			let dashboardSharedItems = response.shareEntities;
			let entitiesWithLackOfAccess: any[] = this.getLackOfAccessEntities(alertSharedItems, dashboardSharedItems, changedAlertSubscribers);
			if (entitiesWithLackOfAccess && !entitiesWithLackOfAccess.isEmpty()) {
				let accessToLinkedDashboardHeader = this.locale.getString('alert.accessToLinkedDashboardHeader');
				let accessToLinkedDashboardMessage = this.locale.getString('alert.accessToLinkedDashboardMessage',
					{name: this.alert.notification.linkDashboardName});
				return this.getPersonalizationMessage().then(personalizationMessage => {
					accessToLinkedDashboardMessage += personalizationMessage;

					return PromiseUtils.old(this
						.downgradeDialogService
						.showUnsavedChangesDialog(
							accessToLinkedDashboardHeader,
							accessToLinkedDashboardMessage,
							false,
							false,
							'common.saveAndShare',
							'common.save'
						)).then(
							share => {
								return this.createPermissionValidationResult(true, share, entitiesWithLackOfAccess);
							}
						)
					;
				});
			} else {
				return this.createPermissionValidationResult(true);
			}
		});
	};

	private getPersonalizationMessage = (): ng.IPromise<string> => {
		let grouping = this.alert.trigger.grouping;
		let isMetricAlertPersonalized = AnalyticMetricTypes.isHierarchyModel(grouping);
		let linkedDashboardId = this.alert.notification?.linkDashboard;
		let linkedDashboard: any = linkedDashboardId
			&& _.findWhere(this.dashboardListService.getCurrentDashboardsList(), {id: linkedDashboardId});
		let isLinkedDashboardPersonalized = linkedDashboard?.properties?.allowPersonalization;
		if (isMetricAlertPersonalized && !isLinkedDashboardPersonalized) {
			return this.$q.when('<br>' + this.locale.getString('alert.metricAlertPersonalizedDashboardNot',
				{organizationName: grouping.displayName}));
		} else if (!isMetricAlertPersonalized && isLinkedDashboardPersonalized) {
			let hierarchyId = linkedDashboard.properties.hierarchyId;
			return this.organizationApiService.getOrgHierarchy(hierarchyId).then(response => {
				let orgHierarchy = response.data;
				return '<br>' + this.locale.getString('alert.dashboardPersonalizedMetricAlertNot', {organizationName: orgHierarchy.name});
			});
		}
		return this.$q.when('');
	};

	private createPermissionValidationResult(close: boolean, share?: boolean, entitiesWithLackOfAccess?: any[]): PermissionValidationResult {
		return {
			close,
			share,
			entitiesWithLackOfAccess
		};
	}

	private getLackOfAccessEntities(alertSharedItems: any, dashboardSharedItems: DashboardSharedEntities,
			changedAlertSubscribers?: any[]): any {
		let allAlertItems: any[] = angular.copy(alertSharedItems);
		if (changedAlertSubscribers) {
			let newSubscribers = _.filter(changedAlertSubscribers, (newSubscriber) => {
				return newSubscriber.action === ShareAction.ADD;
			});
			allAlertItems.pushAll(newSubscribers);
		}
		// currently we don't support sharing for metaGroup(i.e. CB_USERS)
		return  _.reject(allAlertItems, alertItem => {
			if (alertItem.type === 'user') {
				return _.find(dashboardSharedItems.USER, user => {
					return user.entity.userId === alertItem.entity.userId;
				});
			} else if (alertItem.type === 'group') {
				return _.find(dashboardSharedItems.GROUP, group => {
					return group.entity.groupId === alertItem.entity.groupId;
				});
			}
		});
	}

	private closeDialog = (alert: StudioAlert, changedSubscribers: any[], share?: boolean, entitiesWithLackOfAccess?: any[],
		retainEditPermission?: boolean): void => {
		let result: AlertEditDialogResult = {
			alert,
			changedSubscribers,
			retainEditPermission,
			share,
			entitiesWithLackOfAccess
		};
		this.close({$value: result});
	};

	cancel = () => {
		this.dismiss({$value: 'cancel'});
	};

	canToggleSteps = () => !this.isNewAlert();

	isNewAlert = (): boolean => !(this.resolve && this.resolve.alert);

	private convertSettingsForBackend(alertSettings: StudioAlert): StudioAlert {
		let alert = angular.copy(alertSettings);
		this.transformStartDateTimeZone(alert);
		this.transformEndDateTimeZone(alert);
		if (alert.trigger?.filterRules) {
			this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(alert.trigger.filterRules);
		}
		return alert;
	}

	private transformStartDateTimeZone(alertSettings: StudioAlert): void {
		if (_.isUndefined(alertSettings) || _.isUndefined(alertSettings.timezoneOffset)) {
			return;
		}
		alertSettings.clientTimezoneName = moment.tz.guess();
		alertSettings.startDate = this.transformDateTimezone(alertSettings.startDate, parseInt(alertSettings.timezoneOffset, 10));
	}

	private transformEndDateTimeZone(alertSettings: StudioAlert): void {
		if (_.isUndefined(alertSettings) || _.isUndefined(alertSettings.timezoneOffset)) {
			return;
		}
		alertSettings.endDate = this.transformDateTimezone(alertSettings.endDate, parseInt(alertSettings.timezoneOffset, 10));
	}

	processCron = () => {
		let cronProcessingResult = this.scheduleUtilsService.processCron(
			this.alert.startDate,
			this.alert.endDate,
			this.alert.trigger.scheduleType,
			undefined,
			this.time,
			this.recurrence,
			DateTimeFormat.BASIC_DATE,
			this.weekDaysOptions,
			false);
		this.alert.cronExpression = cronProcessingResult.cronExpression;
	};

	openDate = ($event) => {
		$event.preventDefault();
		$event.stopPropagation();

		if (!this.datepicker) {
			this.datepicker = {opened: true};
		} else {
			this.datepicker.opened = !this.datepicker.opened;
		}
	};

	updateTimezone = () => {
		this.time.timezoneOffset = this.alert.timezoneOffset;
		this.processCron();
	};

	showRenewButton = (): boolean => {
		let currentDate = this.scheduleUtilsService.getScheduleExpirationDate(new Date());
		return !this.isNewAlert() && moment(currentDate).diff(this.alert.endDate, 'days') > 1;
	};

	expired = () => this.scheduleUtilsService.isExpired(this.alert.endDate);

	renewExpiration = (): void => {
		this.alert.endDate = this.scheduleUtilsService.getScheduleExpirationDate(new Date());
		this.updateAlertExpirationDate();
		this.alert.enabled = true;
	};

	private transformDateTimezone = (date: Date, timezoneOffset: number): Date =>
		new Date(date.getTime() + ((moment(date).utcOffset() - timezoneOffset) * 60000));

	private validateFilter = (): any => {
		if (!this.alert || !this.alert.trigger) {
			return null;
		}
		let tempRules = this.alert.trigger.filterRules.filter((filterRule) => {
			return filterRule.type !== FilterRuleType.empty;
		});
		if (tempRules.length) {
			let validationResult = this.filterValidationService.getValidationResult({filterRules: tempRules});
			return FilterValidationMessages.VALID === validationResult
				? null
				: validationResult;
		} else {
			return null;
		}
	};

	validateAndPopulateErrors = (): void => {
		let validationResult = this.validateFilter();
		this.ui.filterRulesError = validationResult === FilterValidationMessages.VALID
			? null
			: validationResult;
		this.ui.notificationSettingsError = this.validateNotification();
	};

	private validateNotification = (): boolean => {
		let linkedDashboard = this.alert.notification?.linkDashboard;
		if (this.alert.notification && !linkedDashboard) {
			let message = [this.alert.notification.message];
			if (this.alert.notification.notifyByEmail) {
				message.pushAll([this.alert.notification.emailBody, this.alert.notification.emailSubject]);
			}

			return _.some(message, this.containLinkedDashboardPlaceHolder);
		}
		return false;
	};

	private containLinkedDashboardPlaceHolder = (message: string): boolean => {
		return message && message.indexOf('{alertDashboard}') > -1;
	};

	isAlertGeneralValid = (): boolean => this.alert.name && this.alert.name.length > 0 && this.alert.selectedOwner;

	isAlertValid = (): boolean => {
		return this.alert
			&& this.validateCaseSettings()
			&& this.alert.trigger
			&& this.isAlertGeneralValid()
			&& !isEmpty(this.alert.trigger.conditions[0].threshold)
			&& !isEmpty(this.alert.trigger.minVolume)
			&& this.filtersValid
			&& this.notificationSettingsValid;
	};

	getAutofillText = (item: {label: string}): string => {
		return `{${item.label}}`;
	};

	getAutofillOptions = () => {
		return AlertAutofillUtils.getAutofillOptions(this.alert);
	};

	private validateCaseSettings = (): boolean => {
		let caseSettings = this.alert.caseSettings;

		if (!caseSettings || !caseSettings.createCase) {
			if (this.caseCreation) {
				this.caseCreation.$setValidity('caseTopicId', true);
				this.caseCreation.$setValidity('caseTitle', true);
				this.caseCreation.$setValidity('casePriority', true);
			}

			return true;
		} else if (this.caseCreation) {
			let validTopicSelected = caseSettings.topicId > 0;
			let validTitleEntered = !!caseSettings.title;
			let validPrioritySelected = !!caseSettings.priority;

			this.caseCreation.$setValidity('caseTopicId', validTopicSelected);
			this.caseCreation.$setValidity('caseTitle', validTitleEntered);
			this.caseCreation.$setValidity('casePriority', validPrioritySelected);

			return this.caseCreation.$valid;
		}
	};

	private initTabs = (): void => {
		this.activeTabIndex = MetricAlertEditorTab.DEFINITION;
		this.tabs = [
			{ index: MetricAlertEditorTab.DEFINITION,
				heading: this.locale.getString('alert.definitionTabName'),
				template: 'partials/alerts/metric-alert-editor-definition-tab.html' },
			{ index: MetricAlertEditorTab.NOTIFICATIONS,
				heading: this.locale.getString('alert.notificationsTabName'),
				template: 'partials/alerts/metric-alert-editor-notifications-tab.html' }
		];

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_ALERT_CASES)
				&& this.isEngagorIntegrationEnabled()) {
			this.tabs.push({
				index: MetricAlertEditorTab.CASE_CREATION,
				heading: this.locale.getString('alert.caseCreationTabName'),
				template: 'partials/alerts/metric-alert-editor-case-creation-tab.html'
			});

			this.initCaseCreationTabData();
		}
	};

	isEngagorIntegrationEnabled = (): boolean => {
		return this.security.getCurrentMasterAccount().engagorEnabled;
	};

	getAssignee = (): Assignee => {
		let assigneeFromTemplate = this.getAssigneeFromTemplate();
		return assigneeFromTemplate ? assigneeFromTemplate : this.alert.caseSettings.assigneeObject;
	};

	getAssigneeFromTemplate = (): Assignee => {
		let appliedTemplate = this.getAppliedTemplate();
		return appliedTemplate?.caseSettings?.assigneeObject;
	};

	getAppliedTemplate = (): IAlertSubscriptionTemplate => {
		let appliedTemplateId = this.alert.caseSettings.inboxTemplateId;
		return appliedTemplateId && _.find(this.inboxTemplates, {id: appliedTemplateId});
	};

	setAssignee = (assignee: Assignee): void => {
		this.alert.caseSettings.assigneeObject = assignee;
	};

	clearAssignee = (): void => {
		this.alert.caseSettings.assigneeObject = null;
	};

	private initCaseCreationTabData = (): void => {
		let topicsPromise = this.engagorApiService.getTopics();

		let assigneesPromise = this.engagorApiService.getAssignees();

		let subscriptionTemplatesPromise = this.alertSubscriptionTemplatesApi.getEnabledTemplatesForCurrentUser(
			this.alert.contentProviderId, this.alert.accountId, this.alert.projectId);

		this.caseCreationTabLoading = this.$q.all([topicsPromise, assigneesPromise, subscriptionTemplatesPromise]);

		this.caseCreationTabLoading.then(result => {
			this.engagorTopics = result[0];
			this.engagorAssignees = result[1];

			this.inboxTemplates = result[2].filter(template => template.type === AlertSubscriptionTemplateType.METRIC);
			let selected = this.inboxTemplates.find(template => template.id === this.alert.caseSettings.inboxTemplateId);
			if (selected) {
				this.selectInboxTemplate(selected);
			}
		});
	};

	createCaseEnabled = (): boolean => {
		let caseSettings = this.alert.caseSettings;
		return caseSettings?.createCase;
	};

	setNotificationSettingsValidity = (valid: boolean) => {
		this.notificationSettingsValid = valid;
	};

	highlightTab(tab: UibTab): boolean {
		if (this.activeTabIndex === tab.index) {
			return false;
		}

		switch (tab.index) {
			case MetricAlertEditorTab.NOTIFICATIONS:
				return !this.notificationSettingsValid;
			case MetricAlertEditorTab.CASE_CREATION:
				return !this.validateCaseSettings();
			case MetricAlertEditorTab.DEFINITION:
				return !this.filtersValid;
		}
	}

	hasInboxTemplate = (): boolean => {
		return !!this.alert.caseSettings.inboxTemplateId;
	};

	hasInboxTemplates = (): boolean => {
		return !!this.inboxTemplates?.length;
	};

	selectInboxTemplate = (template: IAlertSubscriptionTemplate): void => {
		if (!template) {
			delete this.alert.caseSettings.inboxTemplateId;
			delete this.alert.caseSettings.assigneeObject;
			return;
		}
		this.alert.caseSettings.inboxTemplateId = template.id;
		this.alert.caseSettings.topicId = template.topicId;
		this.alert.caseSettings.title = template.caseSettings.customTitle;
		this.alert.caseSettings.priority = template.caseSettings.priority;
		this.alert.caseSettings.description = template.caseSettings.caseBody;

		let assignedFromTemplate = template.caseSettings.assigneeObject;
		if (assignedFromTemplate) {
			this.alert.caseSettings.assigneeObject = assignedFromTemplate;
		}
	};

	shouldShowEmailWarning = (): boolean => {
		let totalUsers = 0;
		this.changedEntities.forEach(entity => {
			if (entity.type === 'group') {
				totalUsers += entity.usersCount ?? entity.entity?.usersCount ?? 0;
			} else {
				totalUsers++;
			}
		});
		return this.alert?.notification?.notifyByEmail && totalUsers > ObjectSharingConstants.EMAIL_USER_LIMIT;
	};
}

app.component('metricAlertEditor', {
	bindings: {
		close: '&',
		dismiss: '&',
		resolve: '<'
	},
	controller: MetricAlertEditorComponent,
	templateUrl: 'partials/alerts/metric-alert-editor-modal.html'
});
