import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NarrativeSettingEntry } from './narrative-settings-list.component';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';

export interface INarrativeSettingsModalInput {
	entry: NarrativeSettingEntry;
	attributes: IReportAttribute[];
}

@Component({
	selector: 'narrative-settings-modal',
	template: `
	<modal-header
		(cancel)="cancel()"
		[modalTitle]="'appearance.editNarrative'|i18n">
	</modal-header>
	<div class="modal-body">
		<div class="row">
			<div class="col-sm-12 form-group mb-16">
				<label>{{'appearance.narrativeTitle'|i18n}}</label>
				<input
					class="d-block w-100-percent" id='narrativeTitle'
					[(ngModel)]="input.entry.name"
					[maxlength]="TITLE_LIMIT"
					(ngModelChange)=nameCheckDebounce()/>
				<label class='danger' *ngIf=nameError>{{'appearance.nameError'|i18n}}</label>
			</div>
		</div>
		<div class="row">
			<div class="col-sm-12 form-group">
				<label>{{'appearance.selectAttribute'|i18n}}</label>
				<simple-dropdown
					displayField="displayName"
					searchable="true"
					[options]="input.attributes"
					valueField="name"
					sortField="displayName"
					[(value)]="input.entry.attributeName"
					(onChange)="selectAttribute($event)">
				</simple-dropdown>
			</div>
		</div>
	</div>
	<save-modal-footer
		(save)="save()"
		(cancel)="cancel()"
		[saveText]="'common.save' | i18n"
		[isDisabled]="hasNoSelection()"
	></save-modal-footer>`
})
export class  NarrativeSettingsModalComponent implements OnInit {
	readonly TITLE_LIMIT: number = 150;
	@Input() input: INarrativeSettingsModalInput;
	nameError: boolean;
	nameCheckPending: boolean;
	originalName: string;

	constructor(
		private readonly narrativeSettingsApi: NarrativeSettingsApi,
		private readonly modal: NgbActiveModal,
	) {
		this.nameCheck = _.debounce(this.nameCheck, 1000);
	}

	ngOnInit(): void {
		this.originalName = this.input.entry.name;
		this.nameError = false;
	}

	hasNoSelection(): boolean {
		return _.isEmpty(this.input.entry.attributeName)
			|| _.isEmpty(this.input.entry.name)
			|| this.nameError
			|| this.nameCheckPending;
	}

	selectAttribute(attribute: IReportAttribute ): void {
		this.input.entry.attributeName = attribute.name;
		this.input.entry.attributeDisplayName = attribute.displayName;
	}

	save(): void {
		this.modal.close(this.input.entry);
	}

	cancel(): void {
		this.modal.dismiss();
	}

	nameCheckDebounce(): void {
		this.nameCheckPending = true;
		this.nameCheck();
	}

	nameCheck(): void {
		let name = this.input.entry.name;
		if (name && name !== this.originalName) {
			this.narrativeSettingsApi.narrativeSettingsNameCheck(this.input.entry, name).then(response => {
				this.nameCheckPending = false;
				this.nameError = response;
			}, (reason) => {
				this.nameCheckPending = false;
				this.nameError = true;
			});
		} else {
			this.nameCheckPending = false;
			this.nameError = false;
		}
	}
}
