import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Inject, Injectable } from '@angular/core';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { MasterAccountDefaultPermissions } from '@app/modules/account-administration/license-permissions/master-account-default-permissions.class';
import { LicenseType } from '@cxstudio/common/license-types';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ExternalRedirectDomains } from '@cxstudio/master-accounts/master-account-properties.class';

@Injectable()
export class MasterAccountPropertiesApiService {
	readonly BASE_URL = 'rest/master_account_properties';

	constructor(
		@Inject('$http') private $http: ng.IHttpService,
		@Inject('httpHandlers') private httpHandlers: HttpHandlers,
		@Inject('cachedHttpService') private cachedHttpService: CachedHttpService
	) {}

	getDefaultPermissions = (): Promise<MasterAccountDefaultPermissions> => {
		return this.processResponse(this.cachedHttpService.cache(Caches.PERMISSIONS)
			.get(`${this.BASE_URL}/permissions`, {cache: CacheOptions.CACHED}));
	};

	getSystemDefaultPermissions = (): Promise<MasterAccountDefaultPermissions> => {
		return this.processResponse(this.cachedHttpService.cache(Caches.PERMISSIONS)
			.get(`${this.BASE_URL}/permissions/system/default`, {cache: CacheOptions.CACHED}));
	};

	updateDefaultPermissions = (typeId: LicenseType, permissions: any[]): Promise<void> => {
		return this.processResponse(this.$http.put(`${this.BASE_URL}/permissions/${typeId}`, permissions))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.PERMISSIONS));
	};

	restoreDefaultPermissions = (typeId: LicenseType): Promise<any[]> => {
		return this.processResponse(this.$http.put(`${this.BASE_URL}/permissions/restore/${typeId}`, null))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.PERMISSIONS));
	};

	private processResponse(httpPromise: ng.IHttpPromise<any>): Promise<any> {
		return Promise.resolve(httpPromise.then(this.httpHandlers.success));
	}

	updateProperty = (propertyName: string, propertyValue: any): Promise<void> => {
		let property = { propertyName, propertyValue };
		return this.processResponse(this.$http.put('rest/master_account/update-property', property)).then(this.httpHandlers.success);
	};

	getExternalRedirectDomains = (): Promise<ExternalRedirectDomains> => {
		return this.processResponse(
			this.$http.get('rest/master_account/properties/external-redirect-domains')
		);
	};

	addTrustedExternalRedirectDomain = (domain: string) => {
		return this.$http.post(`rest/master_account/properties/external-redirect-domains`, domain);
	};

	removeTrustedExternalRedirectDomain = (domain: string) => {
		return this.$http.delete(`rest/master_account/properties/external-redirect-domains/${encodeURIComponent(domain)}`);
	};

	addTrustedDomain = (domain: string) => {
		return this.$http.post(`rest/master_account/membership-domains`, domain);
	};

	removeTrustedDomain = (domain: string) => {
		return this.$http.delete(`rest/master_account/membership-domains/${encodeURIComponent(domain)}`);
	};
}

app.service('masterAccountPropertiesApiService', downgradeInjectable(MasterAccountPropertiesApiService));
