import { Injector } from '@angular/core';

export const RouteParamsProvider = {
	deps: ['$injector'],
	provide: '$routeParams',
	// eslint-disable-next-line import/no-deprecated
	useFactory: (injector: Injector) => injector.get('$routeParams')
};

export interface IRouteParams {
	[key: string]: string;
}
