import { downgradeInjectable } from '@angular/upgrade/static';
import { AdminModelsApiService } from '@app/modules/project/model/admin-models-api.service';
import { Injectable } from '@angular/core';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { IAdminModel } from '@app/modules/project/model/admin-model';
import { WorkspaceModelManagementApiService } from '@app/modules/project/model/workspace-model-management-api.service';

@Injectable({ providedIn: 'root' })
export class ModelManagementService {

	constructor(
		private readonly adminModelsApiService: AdminModelsApiService,
		private readonly workspaceModelManagementApiService: WorkspaceModelManagementApiService,
	) {}

	getAdminModels(project: AccountOrWorkspaceProject, filters: ObjectListFilter[]): AssetPromise<IAdminModel[]> {
		return ProjectAssetsHelper.getAssetsForProject<IAdminModel[]>(
			project,
			(accountProject) => this.adminModelsApiService.getAdminModels(accountProject, filters),
			(workspaceProject) => this.workspaceModelManagementApiService.getAdminModels(workspaceProject, filters),
			() => []
		);
	}

	requestModelsReport = (
		project: AccountOrWorkspaceProject, withHidden: boolean, filters: ObjectListFilter[], modelOrder: number[]
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.adminModelsApiService.requestModelsReport(
				accountProject, withHidden, filters, modelOrder),
			(workspaceProject) => this.workspaceModelManagementApiService.requestModelsReport(
				workspaceProject, withHidden, filters, modelOrder),
			() => []
		);
	};
}

app.service('modelManagementService', downgradeInjectable(ModelManagementService));
