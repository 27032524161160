export class GlobalNotification {
	content: string;
	type: GlobalNotificationType;

	constructor(content: string, type: GlobalNotificationType) {
		this.content = content;
		this.type = type;
	}

	isError = (): boolean => {
		return GlobalNotificationType.ERROR === this.type;
	};

	getReportMsg = (): string => {
		return '';
	};
}

export enum GlobalNotificationType {
	SUCCESS = 'success',
	WARNING = 'warning',
	ERROR = 'error'
}
