import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { FilterMetricDefinition } from '@app/modules/metric/definition/filter-metric-definition';
import { MetricProperties } from '@app/modules/metric/entities/metric-properties';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { Security } from '@cxstudio/auth/security-service';
import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterValidationService } from '@cxstudio/report-filters/filter-validation-service.service';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import * as cloneDeep from 'lodash.clonedeep';
import * as _ from 'underscore';
import { IMetricAssets } from '../editor/metric-assets.interface';
import { AttributeCalculationsOptionsService } from './custom-math/attribute-calculations-options.service';

@Component({
	selector: 'filter-metric-definition',
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
	templateUrl: './filter-metric-definition.component.html'
})
export class FilterMetricDefinitionComponent implements OnInit {
	@Input() definition: FilterMetricDefinition;
	@Output() definitionChange = new EventEmitter<FilterMetricDefinition>();
	@Output() formatChange = new EventEmitter<NumberFormatSettings>();
	@Output() validityChange = new EventEmitter<boolean>();

	@Input() project: AccountOrWorkspaceProject;
	@Input() assets: IMetricAssets;
	@Input() properties: MetricProperties;

	volume;
	parentPercent;
	percentOfTotal;
	ui: {
		calculations;
		calculationOperators: any[];
		filterOptions;
		aggrregationLevels: ListOption<string>[];
	};
	calculation;
	filterRules: any[];

	constructor(
		private locale: CxLocaleService,
		@Inject('security') private security: Security,
		@Inject('metricConstants') private metricConstants: MetricConstants,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private optionsTemplatesService: OptionsTemplatesService,
		@Inject('filterManagementApiService') private filterManagementApiService: FilterManagementApiService,
		@Inject('filterValidationService') private filterValidationService: FilterValidationService,
		private attributeCalculationsOptions: AttributeCalculationsOptionsService
	) {}

	ngOnInit(): void {
		let constants = this.metricConstants.get();
		this.volume = { ...constants.VOLUME };
		this.ui = {
			calculations: this.getCalculations(),
			calculationOperators: this.attributeCalculationsOptions.get(),
			filterOptions: this.getFilterOptions(),
			aggrregationLevels: [
				{value: 'group', name: this.locale.getString('metrics.aggrLevelGroup')},
				{value: 'parent', name: this.locale.getString('metrics.aggrLevelParent')},
				{value: 'total', name: this.locale.getString('metrics.aggrLevelTotal')}
			]
		};
		//to hide/show when aggr level changed
		this.parentPercent = this.findCalculation(constants.PARENT_PERCENT.name);
		this.percentOfTotal = this.findCalculation(constants.PERCENT_OF_TOTAL.name);
		if (!this.definition.calculationName) {
			this.selectCalculationNode(this.parentPercent);
		}
		this.calculation = this.findCalculation();
		this.definition.aggrLevel = this.definition.aggrLevel || this.ui.aggrregationLevels[0].value;
		this.filterRules = cloneDeep(this.definition.rule.filterRules);
		this.checkMetricForDeletedFilter();
	}

	private findCalculation(name?: string) {
		name = name || this.definition.calculationName;
		return SearchableHierarchyUtils.findMetricNameInHierarchy(this.ui.calculations, name);
	}

	getCalculations() {
		let attributes: any[] = this.assets.attributes;
		let metrics = this.assets.metrics;

		return this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withTemplate(this.optionsTemplatesService.getCalculationMetricsForMetricEdit())
			.withStandardMetrics(this.metricConstants.getStandardCalculations())
			.withPredefinedMetrics(this.assets.predefinedMetrics)
			.withAttributes(attributes, MetricFilters.NOT_DATE)
			.withMetrics(this.getVisibleMetrics(metrics), this.project.projectId)
			.build();
	}

	getFilterOptions() {
		let attributes: any[] = this.assets.attributes;
		let models: any[] = this.assets.models;
		let filters: any[] = this.assets.filters;
		let scorecardFilters: any[] = this.assets.scorecardFilters;
		return this.optionsBuilderProvider.getBuilder()
			.withTemplate(this.optionsTemplatesService.filterItemsFilterMetric(this.assets.projectTimezone))
			.withModels(models)
			.withAttributes(attributes, MetricFilters.NOT_DOCUMENT_DATE)
			.withFilters(filters)
			.withScorecardFilters(scorecardFilters)
			.build();
	}


	private getVisibleMetrics(metrics: any[]): any[] {
		let selectedMetricName;
		if (this.definition.calculationOperator === ReportAssetType.METRIC_STUDIO)
			selectedMetricName = this.definition.calculationName;
		return _.filter(metrics, (metricItem) => {
			return (!metricItem.hide || selectedMetricName === metricItem.name)
				&& metricItem.type !== ReportAssetType.METRIC_PREDEFINED;
		});
	}

	private checkMetricForDeletedFilter() {
		let filters = this.assets.filters;
		let deletedFilter = false;
		if (this.definition.rule?.filterRules && this.security.isCurrentUser(this.properties.ownerName)) {
			this.definition.rule.filterRules.forEach((rule) => {
				if (FilterRuleTypes.isSavedFilterRule(rule)
					&& !FilterRuleTypes.isAnalyzeFilter(rule)
					&& !_.find(filters, {id: rule.filterId})) {
					deletedFilter = true;
				}
			});
			if (deletedFilter) {
				this.filterRules = [];
				this.changeHandler();
			}
		}
	}

	changeHandler = (): void => {
		if (this.filterRules) {
			this.definition.rule.filterRules = cloneDeep(this.filterRules).filter((filter) => {
				return filter.type !== FilterTypes.EMPTY;
			});
		}
		this.filterManagementApiService.convertModelTreeSelectionRulesForBackend(this.definition.rule.filterRules);
		this.definitionChange.emit(this.definition);
		this.validityChange.emit(this.isFilterValid());
	};


	isFilterValid = (): boolean => {
		let filterRules = this.definition.rule.filterRules;
		return this.filterValidationService.validateFilterRules({ filterRules }) === true
					|| filterRules.isEmpty();
	};

	selectCalculationNode = (node, skipFormat?: boolean): void => {
		this.calculation = node;
		this.definition.calculationName = node.name;
		this.definition.calculationOperator = this.resetCalculationOperator(node);

		if (!skipFormat) {
			this.updateMetricFormat(node);
		}
		this.changeHandler();
	};

	private updateMetricFormat = (calculation): void => {
		let format = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(calculation);
		this.formatChange.emit(format);
	};

	resetCalculationOperator = (calculation): string => {
		return calculation.standardMetric ? 'sys'
			: (AnalyticMetricTypes.isStudioMetric(calculation) ? calculation.type
				: this.resetCalculationOperatorForAttribute(calculation));
	};

	resetCalculationOperatorForAttribute = (attribute): string => {
		return MetricFilters.CALCULATION(attribute) ? this.attributeCalculationsOptions.AVG.operator
			: this.attributeCalculationsOptions.COUNT_DISTINCT.operator;
	};

	showOperatorOptions = (): boolean => {
		if (!isEmpty(this.calculation)) {
			return this.calculation.type && MetricFilters.CALCULATION(this.calculation);
		}
		return false;
	};

	aggregationLevelChangeHandler = (): void => {
		let skipFormat = true;
		if (this.definition.aggrLevel === 'group') {
			this.parentPercent.hidden = false;
			this.percentOfTotal.hidden = false;
		} else {
			this.parentPercent.hidden = true;
			this.percentOfTotal.hidden = true;

			if (this.definition.calculationName === this.parentPercent.name
				|| this.definition.calculationName === this.percentOfTotal.name) {
				this.selectCalculationNode(this.volume, skipFormat);
			}
		}
		this.changeHandler();
	};
}
