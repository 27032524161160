<fieldset>
	<label class="mr-8">{{label}}</label>
	<div class="btn-group">
		<button
			type="button"
			class="btn br-property-switcher-enable-property {{enableClass}}"
			[ngClass]="{ 'btn-selected' : visualProperties[property] === true}"
			(click)="visualProperties[property] = true; onChangeInternal();"
			[title]="enableText"
			[attr.aria-pressed]="visualProperties[property] === true"
			[attr.aria-label]="enableText + ' ' + label"
			tabindex="0"
		>
			{{enableText}}
		</button>
		<button
			type="button"
			class="btn br-property-switcher-disable-property {{disableClass}}"
			[ngClass]="{ 'btn-selected' : !visualProperties[property]}"
			(click)="visualProperties[property] = false; onChangeInternal();"
			[title]="disableText"
			[attr.aria-pressed]="!visualProperties[property]"
			[attr.aria-label]="disableText + ' ' + label"
			tabindex="0"
		>
			{{disableText}}
		</button>
	</div>
</fieldset>
