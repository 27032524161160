import { HttpMethod } from './http-method.enum';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { AssetParametersFactory } from '@app/modules/asset-management/access/asset-parameters/asset-parameters-factory';
import { HttpRequestConfig } from '@cxstudio/common/http-request-config';
import { ObjectUtils } from '@app/util/object-utils';

/*
 Default $http implementation returns first recieved response if { cache : true } and never invalidates it.
 This wrapper invalidates cache if { cache : false }.

 This implementation also makes sure to reuse the same promise for *identical parallel cached* requests.
*/
export default class CachedHttpFactory {

	cache: ng.ICacheObject;

	constructor(globalCache: ng.ICacheObject,
		private $http: ng.IHttpService,
		private $httpParamSerializer: ng.IHttpParamSerializer,
		private $q: ng.IQService) {
		this.cache = globalCache;
	}

	get = (url: string, config: HttpRequestConfig): ng.IPromise<any> => {
		return this.query(url, {}, config, HttpMethod.GET);
	};

	post = (url: string, payload: any, config: HttpRequestConfig): ng.IPromise<any> => {
		return this.query(url, payload, config, HttpMethod.POST);
	};

	private query = (url: string, payload: any, config: HttpRequestConfig, method: HttpMethod) => {
		let cacheEntry = this.buildCacheEntry(url, payload, config, method);
		let cacheValue = this.cache.get(cacheEntry) as any;

		if (config.cache === CacheOptions.NOT_CACHED
			|| cacheValue === undefined) {
			// prevent $http caching
			config.cache = CacheOptions.NOT_CACHED;

			let deferred = this.$q.defer();
			let httpPromise: ng.IPromise<any>;
			config.params = AssetParametersFactory.processRequestParameters(config);
			if (method === HttpMethod.GET) {
				httpPromise = this.$http.get(url, config);
			} else {
				httpPromise = this.$http.post(url, payload, config);
			}
			httpPromise.then((response) => {
				cacheValue = ObjectUtils.copy(response);
				this.cache.put(cacheEntry, cacheValue);
				deferred.resolve(response);
			}, (reason) => {
				this.cache.remove(cacheEntry);
				deferred.reject(reason);
			});

			let asyncCacheValue = deferred.promise;
			this.cache.put(cacheEntry, asyncCacheValue);
			return asyncCacheValue;
		} else {
			if (_.isFunction(cacheValue.then)) {
				return cacheValue.then((response) => {
					return ObjectUtils.copy(response);
				});
			} else {
				return this.$q.when(ObjectUtils.copy(cacheValue));
			}
		}
	};

	private buildCacheEntry = (url: string, payload: any, config: HttpRequestConfig, method: HttpMethod): string => {
		let paramsPostfix: string;
		let serializedParams = this.serializeParams(config, payload);
		paramsPostfix = serializedParams ? '?' + serializedParams : '';
		let cacheEntry = url + paramsPostfix;
		return cacheEntry;
	};

	private serializeParams(config: HttpRequestConfig, payload?: any): string {
		return this.$httpParamSerializer(AssetParametersFactory.processCacheParameters(config, payload));
	}

	invalidate = (): void => {
		this.cache.removeAll();
	};
}
