import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';


@Component({
	selector: 'hierarchy-tree-selector',
	templateUrl: './hierarchy-tree-selector.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class HierarchyTreeSelectorComponent implements OnInit {
	@Input() personalization: PersonalizationState;
	@Input() showOrganizationContext: boolean;
	@Output() searchClear = new EventEmitter<void>();
	@Output() nodeChange = new EventEmitter<IHierarchyNode>();

	constructor(
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
	}

	clearHierarchySearch = (): void => {
		this.personalization.hierarchySearchText = '';
		this.searchClear.emit();
	};

	onSearchChange = (search: string): void => {
		this.personalization.hierarchySearchText = search || '';
		if (this.personalization.hierarchyLoaded) {
			this.personalization.hierarchyDropdownSearch();
		}
	};

	getHierarchyPath = (): string | undefined => {
		if (!this.showOrganizationContext) return;
		
		let path = `${this.personalization.currentHierarchyNode.type}: ${this.personalization.currentHierarchyNode.name}`;

		if (this.personalization.hierarchyPath) {
			path = this.compileHierarchyPath();
		}

		return `${this.locale.getString('common.organization')}: ${this.personalization.currentHierarchyName} / ${path}`;
	};

	private compileHierarchyPath(): string | undefined {
		if (!this.showOrganizationContext) return;

		return this.personalization.hierarchyPath
			.map((pathItem) => {
				if (!(pathItem.type && pathItem.name)) {
					let match: any = _.find(this.personalization.rawHierarchy, {id: pathItem.id});
					if (match) {
						pathItem.type = match.type;
						pathItem.name = match.name;
					}
				}
				return `${pathItem.type}: ${pathItem.name}`;
			}).reduce((str, nextNode) => {
				return `${str} / ${nextNode}`;
			});
	}

	isMultipleRootNodes = (): boolean => {
		// if they're showing context, there can't (currently) be multiple root nodes
		if (this.showOrganizationContext) return false;

		return this.personalization.getHighestLevelVisibleChildren().length > 1;
	};

	onNodeClick = (node: IHierarchyNode): void => {
		this.nodeChange.emit(node);
	};

	selectPreviousExplicitNode = (): void => {
		let node = this.personalization.selectPreviousExplicitNode();
		this.nodeChange.emit(node);
	};

	selectNextExplicitNode = (): void => {
		let node = this.personalization.selectNextExplicitNode();
		this.nodeChange.emit(node);
	};
}

app.directive('hierarchyTreeSelector', downgradeComponent({component: HierarchyTreeSelectorComponent}));