import { PreviewColumn } from '@cxstudio/reports/preview/preview-predefined-columns';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { Model } from '@cxstudio/reports/entities/model';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { ANPreviewExportColumn, PreviewExportFormatter } from '@cxstudio/reports/preview/export/an-preview-export-column.class';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { SentenceMetadataService } from '@cxstudio/reports/preview/sentence-metadata.service';
import { PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';

import * as _ from 'underscore';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { PreviewSentence } from '../preview-sentence-class';
import { FavoriteType, FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { FeedbackUtils } from '@app/modules/widget-container/feedback-utils.service';
import { DateTimeFormat } from '@cxstudio/services/date-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { CapitalizationService } from '@cxstudio/services/capitalization.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { PreviewExportReferenceContainer } from '@app/modules/widget-container/widget-menu/export/preview/preview-export-reference-container';

// eslint-disable-next-line prefer-arrow-callback
app.factory('ANPreviewExportHelper', function(
	dateFilterService: DateFilterService,
	locale: ILocale,
	GroupColorType,
	formatterBuilderUtils: FormatterBuilderUtilsService,
	capitalization: CapitalizationService,
	sentenceMetadataService: SentenceMetadataService,
	previewPredefinedColumns: PreviewPredefinedColumns,
	betaFeaturesService: BetaFeaturesService,
) {
	return class ANPreviewExportHelper {
		widget: PreviewWidget;
		utils: any;
		data: any[];
		columns: ANPreviewExportColumn[];
		urlPattern: RegExp;
		projectTimezone: any;
		allOptions: any;
		hiddenObjects: AttributeGrouping[];

		constructor(widget: PreviewWidget, data, utils, projectTimezone, allOptions, hiddenObjects?) {
			this.widget = widget;
			this.data = data.data ? data.data : data;
			this.utils = utils;
			this.columns = [];
			this.urlPattern = new RegExp('^(http(s?)|www\\.)\\S*\\b');
			this.projectTimezone = projectTimezone;
			this.allOptions = allOptions;
			this.hiddenObjects = hiddenObjects || [];
		}

		getColumns = (isDocExplorerExport?: boolean): string[] => {
			if (this.widget.properties.previewMode !== PreviewMode.DOCUMENT) {
				return this.getNonDocumentExportColumns();
			} else {
				return this.getDocExplorerExportColumns(isDocExplorerExport);
			}
		};

		getRawColumns = (isDocExplorerExport?: boolean): ANPreviewExportColumn[] => {
			// First we need to call the getColumns method to populate the columns
			if (_.isEmpty(this.columns)) {
				this.getColumns(isDocExplorerExport);
			}

			return this.columns;
		};

		private processFavoriteAttributes = (favoriteAttributes: FavoriteId[]): any => {
			if (this.allOptions && this.allOptions.hiddenModelsAndAttributes) {
				favoriteAttributes = _.filter(favoriteAttributes, (favoriteAttribute: FavoriteId) => {
					return !_.find(this.allOptions.hiddenModelsAndAttributes, this.hiddenItemMatcher(favoriteAttribute));
				});
			}
			let models = [];
			let attributes = [];
			if (this.allOptions && this.allOptions.options) {
				_.each(favoriteAttributes, (favoriteAttribute: FavoriteId) => {
					if (favoriteAttribute.type === FavoriteType.SCORECARDS) {
						return;
					}
					if (favoriteAttribute.type === FavoriteType.MODEL) {
						models.push(this.processFavoriteModel(favoriteAttribute));
					} else {
						attributes.push(this.processFavoriteAttribute(favoriteAttribute));
					}
				});
			}
			models = _.sortBy(models, 'displayName');
			attributes = _.sortBy(attributes, 'displayName');

			return models.concat(attributes);
		};

		// use only for "hiddenModelsAndAttributes" array
		private hiddenItemMatcher(target: FavoriteId): (hiddenItem: IReportAttribute | Model) => boolean {
			return (hiddenItem: IReportAttribute | Model): boolean => {
				if (target.type === FavoriteType.SCORECARDS) {
					return false;
				} else if (target.type === FavoriteType.MODEL) {
					return hiddenItem.id === target.modelId;
				} else {
					return hiddenItem.name.toUpperCase() === target.name.toUpperCase();
				}
			};
		}

		private processFavoriteAttribute = (favoriteItem: FavoriteId): any => {
			let attributeFound = _.find(this.allOptions.options.attributes, (attribute: any) => {
				return attribute.name.toUpperCase() === favoriteItem.name.toUpperCase();
			});
			return attributeFound || favoriteItem;
		};

		private processFavoriteModel = (favoriteItem: FavoriteId): any => {
			let modelFound = _.find(this.allOptions.options.models, (model: any) => {
				return model.id === favoriteItem.modelId;
			});
			if (modelFound) {
				//model does not have a type and displayName field. which is needed in the later process
				modelFound.type = FavoriteType.MODEL;
				modelFound.displayName = modelFound.name;
			}
			return modelFound || favoriteItem;
		};

		private getDocExplorerExportColumns = (isDocExplorerExport: boolean): string[] => {
			let props = this.widget.properties;
			let selectedAttributes = props.selectedAttributes || [];
			let widgetColumns = [];
			let favoriteAttributes = angular.copy(props.exportAttributes);
			if (this.widget.visualProperties.contextPaneEnabled) {
				if (isDocExplorerExport) {
					favoriteAttributes = this.processFavoriteAttributes(favoriteAttributes);
					widgetColumns.pushAll(favoriteAttributes);
				} else {
					if (selectedAttributes.length > 0) {
						favoriteAttributes = _.filter(favoriteAttributes, (favoriteAttribute: FavoriteId) => {
							let attributeSelected = !!_.find(selectedAttributes, this.selectedAttributeMatcher(favoriteAttribute));
							let notHidden = !_.find(this.hiddenObjects, this.selectedAttributeMatcher(favoriteAttribute));
							return attributeSelected && notHidden;
						});
						favoriteAttributes = this.processFavoriteAttributes(favoriteAttributes);
						widgetColumns.pushAll(favoriteAttributes);
					}
				}
				if (!_.isEmpty(props.narrativeAttributes)) {
					let narrativeAttributes = this.processFavoriteAttributes([...props.narrativeAttributes]);
					widgetColumns.pushAll(narrativeAttributes);
				}
				let scorecards = this.data[0]?.scorecards;
				if (scorecards && this.widget.visualProperties.sections?.[FavoriteType.SCORECARDS]?.visible !== false) {
					let hiddenAttributeNames = _.chain(this.hiddenObjects)
						.filter((obj: any) => !obj.model)
						.map((attr) => attr.name)
						.uniq()
						.value();

					let scorecardColumns = _.chain(scorecards)
						.values()
						.filter((sc) => {
							return !hiddenAttributeNames.contains(sc.attribute);
						}).filter(sc => {
							return isDocExplorerExport || !!_.findWhere(selectedAttributes, {name: sc.name});
						}).map((scorecard) => {
							return { name: scorecard.attribute, displayName: scorecard.name };
						})
						.value();
					widgetColumns.pushAll(scorecardColumns);
				}
			}


			widgetColumns = _.uniq(widgetColumns, false, (column  => column.name));

			this.generateTextColumns(this.widget);
			this.populateColumnNamesAndFields(widgetColumns);

			this.columns = _.uniq(this.columns, false, (column => column.name));
			return _.map(this.columns, column => column.name);
		};

		private showEnrichmentColumns = (): boolean => {
			return this.widget.properties.documentExplorer || !this.allOptions.statsMode || this.allOptions.showEnrichment;
		};

		// use only for "selectedAttributes" array
		private selectedAttributeMatcher(target: FavoriteId): (attr: AttributeGrouping) => boolean {
			return (selectedAttribute: AttributeGrouping): boolean => {
				if (target.type === FavoriteType.SCORECARDS) {
					return false;
				} else if (target.type === FavoriteType.MODEL) {
					let modelId = target.modelId + '';
					return selectedAttribute.type === ReportAssetType.TOPICS && selectedAttribute.name === modelId;
				} else {
					return selectedAttribute.name.toUpperCase() === target.name.toUpperCase();
				}
			};
		}

		private getNonDocumentExportColumns = (): string[] => {
			let visualProps = this.widget ? this.widget.visualProperties : {} as VisualProperties;
			let widgetColumns = visualProps.columns || [];

			if (visualProps.visualization === 'PREVIEW-BUBBLES') {
				widgetColumns = _.chain(widgetColumns)
					.reject(column => column.name === PreviewColumn.SENTENCE)
					.sortBy('name')
					.value();
				widgetColumns.unshift({name: PreviewColumn.SENTENCE});
			}

			this.populateColumnNamesAndFields(widgetColumns);
			return _.map(this.columns, column => column.name);
		};

		populateColumnNamesAndFields = (columns: Array<TableColumn<any>>) => {
			columns.forEach((column: TableColumn<any>) => {
				if (column.name === PreviewColumn.SENTENCE) {
					this.generateTextColumns(this.widget);
				} else {
					this.generateAttributeColumn(column);
				}
			});
		};

		addDocumentColumns = (widget: PreviewWidget) => {
			if (widget.visualProperties.sentencePaneEnabled) {
				this.addSentenceColumns();
				if (widget.visualProperties.sentimentHighlightingEnabled && this.showEnrichmentColumns()) {
					this.columns.pushAll(previewPredefinedColumns.getPredefinedMetricNames().map(this.buildPredefinedMetricColumn));
				}
			}
			const field = 'documentText';
			const idField = 'documentId';
			let columnFormatter: PreviewExportFormatter;

			if (betaFeaturesService.isFeatureEnabled(BetaFeature.IMPROVED_FEEDBACK_EXPORT)) {
				const referenceContainer = new PreviewExportReferenceContainer(field, idField, !this.allOptions.statsMode);
				const baseFormat = this.getTextFormatFunc(this.allOptions.statsMode);
				columnFormatter = (value, index, row) => baseFormat(referenceContainer.getTextOrReference(row, index));
			} else {
				columnFormatter = this.getTextFormatFunc(this.allOptions.statsMode);
			}
			this.columns.pushAll([
				new ANPreviewExportColumn(locale.getString('preview.document'), field, columnFormatter),
				new ANPreviewExportColumn(locale.getString('widget.exportDocumentId'), idField, this.getNoFormatFunc())
			]);
			this.columns = _.uniq(this.columns, false, (column => column.name));
		};

		addVerbatimColumns = (widget: PreviewWidget) => {
			if (widget.visualProperties.sentencePaneEnabled) {
				this.addSentenceColumns();
				if (widget.visualProperties.sentimentHighlightingEnabled && this.showEnrichmentColumns()) {
					this.columns.pushAll(previewPredefinedColumns.getPredefinedMetricNames().map(this.buildPredefinedMetricColumn));
				}
			}
			let field = 'verbatim';
			const idField = 'verbatimId';

			if ((this.allOptions?.allWidgets || this.allOptions?.statsMode)
					&& widget.properties.previewMode === PreviewMode.VERBATIM) {
				field = 'text';
			}

			let columnFormatter: PreviewExportFormatter;
			if (betaFeaturesService.isFeatureEnabled(BetaFeature.IMPROVED_FEEDBACK_EXPORT) && field === 'verbatim') {
				const referenceContainer = new PreviewExportReferenceContainer(field, idField, !this.allOptions.statsMode);
				const baseFormat = this.getTextFormatFunc(this.allOptions.statsMode);
				columnFormatter = (value, index, row) => baseFormat(referenceContainer.getTextOrReference(row, index));
			} else {
				columnFormatter = this.getTextFormatFunc(this.allOptions.statsMode);
			}
			this.columns.pushAll([
				new ANPreviewExportColumn(locale.getString('preview.verbatim'), field, columnFormatter),
				new ANPreviewExportColumn(locale.getString('widget.verbatimId'), idField, this.getNoFormatFunc())
			]);
		};

		addPaneContextColumns = () => {
			this.columns.pushAll([
				new ANPreviewExportColumn(locale.getString('widget.date'), 'documentDate', this.getDateTimeFormatFunc()),
				new ANPreviewExportColumn(locale.getString('attributes.source'), PreviewColumn.SOURCE, this.getNoFormatFunc())
			]);
		};

		addSentenceColumns = () => {
			this.columns.pushAll([
				new ANPreviewExportColumn(locale.getString('preview.sentence'), 'text', this.getTextFormatFunc(this.allOptions.statsMode)),
				new ANPreviewExportColumn(locale.getString('widget.exportSentenceId'), 'id', this.getNoFormatFunc())]);
			this.columns = _.uniq(this.columns, false, (column => column.name));
		};

		addSentencesWithContextColumns = () => {
			this.columns.pushAll([
				new ANPreviewExportColumn(locale.getString('preview.sentencesWithContext'), 'text', this.getTextFormatFunc(this.allOptions.statsMode)),
				new ANPreviewExportColumn(locale.getString('widget.exportSentenceId'), 'id', this.getNoFormatFunc())]);
			this.columns = _.uniq(this.columns, false, (column => column.name));
		};

		generateTextColumns = (widget: PreviewWidget) => {
			// PreviewMode.DOCUMENT and isVerbatimMode true are for Verbatim View displayed as Pane
			let isVerbatimMode: boolean = FeedbackUtils.isSingleVerbatimMode(widget);

			switch (widget.properties.previewMode) {
				case(PreviewMode.SENTENCES): {
					this.addSentenceColumns();
					break;
				}
				case(PreviewMode.SENTENCES_WITH_CONTEXT): {
					this.addSentencesWithContextColumns();
					break;
				}
				case(PreviewMode.VERBATIM): {
					this.addVerbatimColumns(widget);
					break;
				}
				case(PreviewMode.DOCUMENT): {
					if (isVerbatimMode) {
						this.addVerbatimColumns(widget);
					} else {
						this.addDocumentColumns(widget);
					}
					this.addPaneContextColumns();
					break;
				}
			}
		};

		generateAttributeColumn = (column: TableColumn<any>) => {
			let previewColumn: ANPreviewExportColumn;
			switch (column.name) {
				case('topics'): {
					previewColumn = new ANPreviewExportColumn(locale.getString('preview.topics'), 'topics', this.getJoinTopics());
					break;
				}
				case('documentDate'):
				case('_doc_date'): {
					previewColumn = new ANPreviewExportColumn(locale.getString('widget.date'), 'documentDate', this.getDateTimeFormatFunc());
					break;
				}
				default: {
					//verify attribute is not hidden before adding it to columns list
					if (this.isPredefinedMetricColumn(column.name)) {
						previewColumn = this.buildPredefinedMetricColumn(column.name);
						break;
					}

					let topLevelField = PreviewSentence.TOP_LEVEL_ATTRIBUTES[column.name.toUpperCase()];
					if (topLevelField) {
						previewColumn = new ANPreviewExportColumn(
							column.displayName, topLevelField, this.getTextFormatFunc(this.allOptions.statsMode));
						break;
					}

					let formatter: PreviewExportFormatter;
					let displayName = column.displayName;
					if (column.type === 'model') {
						formatter = this.getJoinTopics();
					} else if (column.type === ReportAssetType.NUMBER) {
						formatter = this.getNumberFormatFunc();
					} else if (column.type === ReportAssetType.DATE) {
						formatter = this.getDateFormatFunc();
					} else if (column.capitalization) {
						formatter = capitalization.getWrappedFormatter(column.capitalization);
					} else {
						formatter = this.getNoFormatFunc();
					}
					previewColumn = new ANPreviewExportColumn(displayName, column.name, formatter);
				}
			}
			this.columns.push(previewColumn);
		};

		getDataRow = (row: PreviewSentence, index: number): any[] =>  {
			let dataRow = [];

			this.columns.forEach( (column: ANPreviewExportColumn) => {
				if (this.isPredefinedMetricColumn(column.field)) {
					//special processing for sentiment and entricment metrics
					dataRow.push( column.formatFunc(row));
				} else if (row.smService === 'twitter' && column.field ===  'text') {
					//there is no localization for single widget export too, where response came from AN
					dataRow.push('Twitter data cannot be exported');
				} else {
					let value = row[column.field];

					if (!value && column.field === PreviewColumn.SOURCE && row.attributes) {
						value = row.attributes._id_source;
					}

					if (!value && column.field === PreviewColumn.VERBATIM_TYPE) {
						value = row.verbatimType;
					}

					if (!value && row.attributes) {
						//inside attributes object, the field name is lower case
						value = row.attributes[column.field.toLowerCase()];
					}

					if (!value && row.models) {
						value = row.models[column.field];
					}

					if (!value && row.scorecards) {
						value = row.scorecards[column.field]?.score;
						value = value === -1 ? '' : value;
					}

					if (!value) {
						value = '';
					}

					if (column.name === locale.getString('preview.sentencesWithContext')) {
						value = this.getContext(row);
					}

					dataRow.push(column.formatFunc(value, index, row));

				}
			});
			return dataRow;
		};

		getContext = (row: PreviewSentence): string => {
			let context = [];
			let sentenceContext = row.sentenceContext || {};
			let prevSentence = sentenceContext.previous?.text ;
			if (prevSentence) {
				context.push(prevSentence);
			}

			context.push(row.text);

			let nextSentence = sentenceContext.next?.text;
			if (nextSentence) {
				context.push(nextSentence);
			}

			return context.join(' ');
		};

		getNumberFormatFunc = (): PreviewExportFormatter => {
			return (value: any): string => {
				return formatterBuilderUtils.formatNumberAsString(value);
			};
		};

		getNoFormatFunc = (): PreviewExportFormatter => {
			return (value: any): any =>  {
				return value;
			};
		};

		getTextFormatFunc = (statsMode: boolean): PreviewExportFormatter => {
			if (statsMode) {
				return (value: any): any =>  {
					return value;
				};
			}
			return this.utils.escapeComma;
		};

		getJoinTopics = (): PreviewExportFormatter => {
			return (topics: any[]): string => {
				if (!topics) {
					return '';
				}

				// In some cases the call to reportData returns an array of strings, in some cases an array of objects
				return (typeof topics[0] === 'string')
					? this.utils.escapeComma(topics.join(','))
					: this.utils.escapeComma(topics.map(topic => topic.name).join(','));
			};
		};

		getSentimentText = (row: PreviewSentence): string => {
			const isMlSentimentEnabled = betaFeaturesService.isFeatureEnabled(
				BetaFeature.MACHINE_LEARNING_SENTIMENT);
			const middleSentimentTranslationKey = isMlSentimentEnabled
				? 'preview.mixed'
				: 'preview.neutral';
			switch (row.sentiment) {
				case 0: return locale.getString('preview.stronglyNegative');
				case 1: return locale.getString('preview.negative');
				case 2: return locale.getString(middleSentimentTranslationKey);
				case 3: return locale.getString('preview.positive');
				case 4: return locale.getString('preview.stronglyPositive');
			}
		};


		getDateTimeFormatFunc = (): PreviewExportFormatter => {
			return (value: string): string => {
				return dateFilterService.formatDocDatePoint(value, this.projectTimezone, false, DateTimeFormat.BASIC_DATE_TIME);
			};
		};

		getDateFormatFunc = (): PreviewExportFormatter => {
			return (value: string): string => {
				return dateFilterService.formatEsDate(value);
			};
		};

		isPredefinedMetricColumn = (name: string): boolean => {
			return _.contains([PreviewColumn.EMOTION, PreviewColumn.EFFORT, PreviewColumn.SENTIMENT], name);
		};

		predefinedMetricFormatFunc = (hasValue: (row: PreviewSentence) => boolean,
			textFunc: (row: PreviewSentence) => string,
			getValue: (row: PreviewSentence) => any): PreviewExportFormatter => {

			return (row: PreviewSentence): string => {
				if (hasValue(row)) {
					let name = textFunc(row);
					let value = getValue(row);
					return `${name} (${value})`;
				} else {
					return '';
				}
			};
		};

		buildPredefinedMetricColumn = (name: string): ANPreviewExportColumn => {

			let formatFunc: PreviewExportFormatter = () => '';
			let metric = _.findWhere(this.allOptions.options.predefinedMetrics, {name});

			switch (name) {
				case 'sentiment':
					formatFunc = this.predefinedMetricFormatFunc(
						(row) => !_.isUndefined(row.dScore),
						this.getSentimentText,
						(row) => row.dScore);
					break;
				case 'emotion':
					if (metric) {
						let categoryFunction = GroupColorType.getAllMetricColorTypes().EMOTION.getCategoryFunction(metric, true);
						let textFunction = (row) => categoryFunction(row, sentenceMetadataService.getEmotion);

						formatFunc = this.predefinedMetricFormatFunc(
							sentenceMetadataService.hasEmotion,
							(row) => textFunction(row).name,
							sentenceMetadataService.getEmotion);
					}
					break;
				case 'easeScore':
					if (metric) {
						let textFunction = GroupColorType.getAllMetricColorTypes().EASE_5.getCategoryFunction(metric, true);
						formatFunc = this.predefinedMetricFormatFunc(
							sentenceMetadataService.hasEaseScore,
							(row) => textFunction(row).name,
							(row) => row.easeScore);
					}
					break;
				default:
					//do nothing
			}
			return new ANPreviewExportColumn(locale.getString('metrics.' + name), name, formatFunc);
		};
	};
});
