import { Schedule } from './schedule';
import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardJobRun } from './dashboard-job-run';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class ScheduleApiService {
	constructor(
		private cxHttp: CxHttpService) {
	}

	getDashboardSchedule = (dashboardId: number) => {
		return this.cxHttp.get(`rest/scheduler/${dashboardId}`);
	};

	getSchedules = (): Promise<Schedule[]> => {
		return this.cxHttp.get('rest/scheduler/all');
	};

	getSchedulesForUser = (): Promise<Schedule[]> => {
		return this.cxHttp.get('rest/scheduler');
	};

	hasDashboardSchedules = (): Promise<boolean> => {
		return this.cxHttp.get('rest/scheduler/available');
	};

	getActiveSchedulesForUser = () => {
		return this.cxHttp.get('rest/scheduler/active');
	};

	deleteSchedule = (dashboardId: number, key: string, group: string) => {
		return this.cxHttp.delete(`rest/scheduler/dashboardId/${dashboardId}/key/${key}/group/${group}`);
	};

	updateSchedule = (dashboardId: number, scheduleSettings: any) => {
		return this.cxHttp.put(`rest/scheduler/${dashboardId}`, scheduleSettings);
	};

	renewSchedule = (key: string, group: string, date: Date): Promise<any> => {
		return this.cxHttp.put(`rest/scheduler/renew/key/${key}/group/${group}`, date);
	};

	disableSchedule = (key: string, group: string): Promise<any> => {
		return this.cxHttp.put(`rest/scheduler/key/${key}/group/${group}/disable`, {});
	};

	enableSchedule = (key: string, group: string): Promise<any> => {
		return this.cxHttp.put(`rest/scheduler/key/${key}/group/${group}/enable`, {});
	};

	getScheduleRuns = (key: string, group: string): Promise<DashboardJobRun[]> => {
		return this.cxHttp.get(`rest/scheduler/key/${key}/group/${group}/runs`);
	};
}
