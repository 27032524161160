import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { SelectionControllerBase } from '@app/modules/item-grid/selection/selection-controller-base';
import { SelectionUtils } from '@app/modules/item-grid/selection/selection-utils.class';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { Unit } from '@app/modules/units/unit';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { ProjectFilterData } from '@cxstudio/angular-filters/project-filter-data';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { IDriversContextMenuScope, IDriversContextMenuUtils } from '@cxstudio/common/context-menu-utils/drivers-context-menu-utils';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { DriversDefinition } from '@cxstudio/drivers/entities/drivers-definition';
import { IDriversFolder } from '@cxstudio/drivers/entities/drivers-folder';
import { DriversItem, DriversStatus } from '@cxstudio/drivers/entities/drivers-item';
import { DriversTarget } from '@cxstudio/drivers/entities/drivers-target';
import { DriversTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';
import { DriversErrorUtils } from '@cxstudio/drivers/utils/drivers-error-utils.service';
import { DriversUtils } from '@cxstudio/drivers/utils/drivers-utils.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Interval } from '@cxstudio/interval/interval.service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import * as moment from 'moment';
import * as _ from 'underscore';
import { IDriversActionsScope, IDriversActionsService } from './drivers-actions-service';
import ManageDriversService from './manage-drivers.service';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';


const DRIVERS_INTERVAL_NAME = 'drivers';
const DRIVERS_INTERVAL = 5000;

export class DriversManagementComponent implements ng.IComponentController,
	IDriversActionsScope, IDriversContextMenuScope, SelectionControllerBase<DriversTreeItem> {
	contextMenuUtils: IDriversContextMenuUtils;
	selectionUtils: GenericSelectUtils<DriversTreeItem>;
	private lastChecked;
	actionsService: IDriversActionsService;
	currentProjects: Project[];

	props: Partial<IProjectSelection> = {};
	gridType: GridTypes = GridTypes.DRIVERS;
	gridNameField: string = 'displayName';
	gridOptions: SlickgridOptions;
	errors: ProjectAssetsErrors = {};
	loading: ProjectAssetsLoading = {};

	ui;
	driversList: DriversTreeItem[] = [];
	driversTemplates: DriversTreeItem[] = [];

	// shows on item gride tree
	visibleDriversList: DriversTreeItem[] = [];
	lastChange;

	// workspace
	isWorkspaceEnabled: boolean;
	project: Partial<AccountOrWorkspaceProject> = {};
	projectsAmount: OptionsAmount;
	currentWorkspaceProjects: AccountOrWorkspaceProject[];

	constructor(
		private $scope: ng.IScope,
		private interval: Interval,
		private security: Security,
		private authorization: Authorization,
		private locale: ILocale,
		private cbDialogService: CBDialogService,
		private gridUtils: GridUtilsService,
		private driversApi: DriversApi,
		private contextMenuTree: any,
		private DriversContextMenuUtils,
		private DriversActionsService,
		private manageDriversService: ManageDriversService,
		private $q: ng.IQService,
		private driversUtils: DriversUtils,
		private $location: ng.ILocationService,
		private redirectService,
		private $filter: ng.IFilterService,
		private $routeParams,
		private betaFeaturesService: BetaFeaturesService,
		private downgradeDialogService: DowngradeDialogService
	) {
	}

	$onInit = () => {
		// restrict this page to non-mobile
		this.security.preventMobileAccess();
		this.handleQueryParams();

		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.ui = {
			hideDrivers: true
		};

		this.gridOptions = {
			onClick: this.onClick
		};

		this.selectionUtils = SelectionUtils.createDriversSelectionUtils(this);
		this.contextMenuUtils = new this.DriversContextMenuUtils(this);
		this.actionsService = new this.DriversActionsService(this);
		this.initEvents();

		this.$scope.$watchCollection(() => this.driversList, this.updateVisibleDriversList);
		this.$scope.$watchCollection(() => this.driversTemplates, this.updateVisibleDriversList);
	};

	private initEvents(): void {
		this.$scope.$on('projects:loaded', (event, projects) => {
			this.currentProjects = projects;
		});

		this.interval.registerInterval(DRIVERS_INTERVAL_NAME, this.refreshProcessingDrivers, DRIVERS_INTERVAL, true);

		this.$scope.$on('$destroy', () => {
			this.interval.stopIntervalWithName(DRIVERS_INTERVAL_NAME, true);
		});
	}

	private onClick = (event, object): void => {
		if (event.ctrlKey && this.selectionUtils.isSupportedType(object)) {
			this.lastChecked = object;
			this.selectionUtils.handleCtrlClick(object);
			this.$scope.$apply();
			return;
		}

		let target = $(event.target);
		// single click only on title
		if (this.gridUtils.isNameClick(event)) {
			//check for can edit
			if (object.status === DriversStatus.defined || object.status === DriversStatus.reportable) {
				if (this.manageDriversService.canEdit(object)) {
					this.actionsService.editDrivers(object);
				} else {
					this.actionsService.viewDrivers(object);
				}
			}
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'drivers', 360);
		} else if (this.gridUtils.isBulkCheckbox(event)) {
			this.selectionUtils.handleCheckboxClick(object, this.lastChecked, event.shiftKey);
			this.lastChecked = object;
			this.$scope.$apply();
		} else if (target.hasClass('drivers-preview')) {
			this.getDriversModel(object);
		} else if (target.hasClass('drivers-execute')) {
			this.executeDrivers(object);
		} else if (target.hasClass('drivers-edit')) {
			this.actionsService.editDrivers(object);
		} else if (target.hasClass('q-icon-warning') && object.errorDetails) {
			let errorMessage = object.errorDetails;
			if (DriversErrorUtils.isErrorStatus(errorMessage))
				errorMessage = this.locale.getString('drivers.error_' + DriversErrorUtils.getErrorKey(errorMessage));
			this.cbDialogService.notify(this.locale.getString('common.error'), object.errorDetails);
		}
	};

	getProjectId(): number {
		return this.props.projectId;
	}

	getSearchFilter(): string {
		return this.ui.searchDrivers;
	}

	getVisibleItems(): DriversTreeItem[] {
		return this.visibleDriversList;
	}

	isShowHidden(): boolean {
		return !this.ui.hideDrivers;
	}

	isSelectionSupported(item: DriversTreeItem): boolean {
		return item.type === DriversType.drivers;
	}

	private clearDrivers = ( ): void => {
		this.driversList = [];
		this.driversTemplates = [];
	};

	accountChanged = (newProps?: IProjectSelection): void => {
		if (newProps) {
			this.props = newProps;
			this.project = newProps;
		}

		if (!ValueUtils.isSelected(this.props.accountId)) {
			this.clearDrivers();
		}
	};

	reloadDrivers = (): void => {
		if (this.isWorkspaceEnabled) {
			this.workspaceProjectChanged();
		} else {
			this.projectChanged();
		}
	};

	projectChanged = (newProps?: IProjectSelection): void => {
		if (newProps) {
			this.props = newProps;
			this.project = newProps;
		}

		if (ValueUtils.isNotSelected(this.props.accountId) || this.errors.tooManyProjects) {
			this.clearDrivers();
			return;
		}

		let driversPromise = PromiseUtils.old(
			this.driversApi.getDrivers(this.props.contentProviderId, this.props.accountId, this.props.projectId));
		let driversTemplatePromise = PromiseUtils.old(
			this.driversApi.getDriversTemplates(this.props.contentProviderId, this.props.accountId, this.props.projectId));

		this.loading.promise = this.$q.all([driversPromise, driversTemplatePromise]);
		this.loading.promise.then((driversAndTemplates) => {
			this.driversList = this.transformDrivers(driversAndTemplates[0]);
			this.driversTemplates = this.transformTemplates(driversAndTemplates[1]);
			this.updateVisibleDriversList();
		});
	};

	workspaceProjectsLoaded = (currentWorkspaceProjects: AccountOrWorkspaceProject[]) => {
		this.currentWorkspaceProjects = currentWorkspaceProjects;
	};

	workspaceChanged = (workspace: Unit) => {
		this.cleanProjectErrors();
		this.projectsAmount = undefined;
		if (workspace) {
			this.props.contentProviderId = workspace.contentProviderId;
			this.props.accountId = workspace.accountId;
			this.clearDrivers();
			this.visibleDriversList = [] as DriversTreeItem[];
		} else {
			this.props.contentProviderId = -1;
			this.props.accountId = -1;
			this.clearDrivers();
			this.visibleDriversList = [] as DriversTreeItem[];
		}
	};

	workspaceProjectChanged = (newProject?: WorkspaceProjectData) => {
		this.cleanProjectErrors();
		let projectSelectedCheck = WorkspaceProjectUtils.isProjectSelected(this.project as WorkspaceProject);

		// selected some project and need to input
		if (newProject) {
			this.project = newProject;
			this.props.projectId = newProject.projectId;
			this.props.projectName = newProject.projectName;
		}

		if (this.props.projectId === null || this.props.projectId === -1) {
			this.props.projectId = undefined;
		}

		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.clearAndRemoveAll();

		if (!_.isEmpty(this.project) && projectSelectedCheck) {
			this.reloadDriverList();
		} else if (this.checkManyProjectError(newProject)) {
			this.errors.tooManyProjects = true;
			return;
		} else {
			this.reloadDriverList();
		}
	};

	private clearAndRemoveAll = (): void => {
		this.selectionUtils.clearSelections();
		this.driversList.removeAll();
		this.driversTemplates.removeAll();
	};

	private checkManyProjectError = (newProject: WorkspaceProjectData): boolean => {
		if ( this.projectsAmount === OptionsAmount.MANY_OPTIONS ) {
			return true;
		}

		return false;
	};

	private reloadDriverList = (): ng.IPromise<void> => {
		let driversPromise = PromiseUtils.old(
			this.driversApi.getDrivers(this.props.contentProviderId, this.props.accountId, this.props.projectId));
		let driversTemplatePromise = PromiseUtils.old(
			this.driversApi.getDriversTemplates(this.props.contentProviderId, this.props.accountId, this.props.projectId));

		this.loading.promise = this.$q.all([driversPromise, driversTemplatePromise]).then((driversAndTemplates) => {
			this.driversList = this.transformDrivers(driversAndTemplates[0]);
			this.driversTemplates = this.transformTemplates(driversAndTemplates[1]);
			this.updateVisibleDriversList();
		});
		return this.loading.promise;
	};

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	};

	projectsLoaded = (amount: OptionsAmount): void => {
		this.projectsAmount = amount;
		this.workspaceProjectChanged();
	};

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	};

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	};

	private generateDriversTemplate = (): DriversItem => {
		let newDriverTemplate = new DriversItem();
		newDriverTemplate.type = DriversType.drivers_template;
		newDriverTemplate.displayName = this.locale.getString('drivers.driverDefaultName');
		newDriverTemplate.sharingStatus = SharingStatus.PUBLIC;
		newDriverTemplate.ownerName = '';
		newDriverTemplate.id = -1;
		newDriverTemplate.level = 0;
		newDriverTemplate.definition = new DriversDefinition();
		newDriverTemplate.target = new DriversTarget();

		let defaultDefinition = {
			attributes: [],
			models: [],
			dateRange: {
				type: FilterRuleType.dateRange
			},
			timezone: {
				name: moment.tz.guess(),
				offset: new Date().getTimezoneOffset()
			},
			additionalFilters: {
				type: 'AND',
				filterRules: []
			},
			includeSentiment: true,
			includeEaseScore: true,
			includeTopicNames: false
		};
		let defaultTarget = {
			filter: {
				type: FilterRuleType.empty
			}
		};
		_.extend(newDriverTemplate, _.pick(this.props, ['contentProviderId', 'accountId', 'projectId']));
		_.extend(newDriverTemplate.definition, defaultDefinition);
		_.extend(newDriverTemplate.target, defaultTarget);

		let project = this.isWorkspaceEnabled
			? _.findWhere(this.currentWorkspaceProjects, {projectId: newDriverTemplate.projectId})
			: _.findWhere(this.currentProjects, {projectId: newDriverTemplate.projectId} as any);

		if (project) {
			if (this.isWorkspaceEnabled) {
				project = project as IProjectSelection;
				newDriverTemplate.projectName = project.projectName;
			} else {
				project = project as Project;
				newDriverTemplate.projectName = project.name;
			}
		}

		return newDriverTemplate;
	};

	createDrivers = (folder?: IFolderItem): void => {
		this.actionsService.createDrivers(folder);
	};

	refreshGrid = (items?: any): void => {
		this.lastChange = [].concat(items);
	};

	private transformDrivers = (drivers: DriversTreeItem[]): DriversTreeItem[] => {
		let flatTree = [];
		_.chain(drivers)
			.filter(item => item.type === DriversType.folder)
			.each((item: IDriversFolder) => {
				item._collapsed = true;
			});
		flatTree.pushAll(this.gridUtils.processItemsTree(drivers, true, 'displayName'));
		flatTree.forEach((treeItem) => {
			if (treeItem.hide) {
				TaggingHelper.tag(treeItem, TaggingHelper.tags.HIDDEN);
			}
		});
		return flatTree;
	};

	private transformTemplates = (templates: DriversTreeItem[]): DriversTreeItem[] => {
		return _.map(this.transformDrivers(templates), template => {
			return _.extend(template, {
				displayName: this.locale.getString('drivers.driverDefaultName'),
				sharingStatus: SharingStatus.PUBLIC,
				level: 0
			});
		});
	};

	private getDriversModel = (driversItem: DriversItem): void => {
		this.downgradeDialogService.openViewDriversModal({driversItem: ObjectUtils.copy(driversItem)})
			.result.then((updateResult: Partial<DriversItem>) => {
				if (updateResult) {
					_.extend(driversItem, updateResult);
					this.loading.promise = PromiseUtils.old(this.driversApi.updateNonDatasetFields(driversItem));
				}
			}, _.noop
		);
	};

	private executeDrivers = (drivers): void => {
		this.loading.promise = this.runSpotCheckAndExecuteIfSuccessful(drivers).then(() => {
			drivers.status = DriversStatus.pending;
			this.refreshGrid(drivers);
			this.refreshProcessingDrivers();
		});
	};

	runSpotCheckAndExecuteIfSuccessful(driver: DriversItem): any  {
		return this.driversUtils.driverHasDatasetErrors(driver).then((hasErrors) => {
			if (hasErrors) {
				return this.actionsService.editDrivers(driver, true);
			} else {
				return this.driversApi.executeDrivers(driver.id);
			}
		});
	}

	private refreshProcessingDrivers = (): void => {
		let statuses = [DriversStatus.pending, DriversStatus.preparing, DriversStatus.training];
		let processingIds = _.chain(this.driversList)
			.filter(item => item.type === DriversType.drivers)
			.filter((item: DriversItem) => _.contains(statuses, item.status))
			.map(item => item.id)
			.value();

		if (!_.isEmpty(processingIds)) {
			this.driversApi.reloadDriversStatus(processingIds as any).then((refreshedItems) => {
				let itemsToRefresh = [];
				_.each(refreshedItems, (refreshedItem: any) => {
					let original = _.findWhere(this.driversList, {id: refreshedItem.id} as any);
					refreshedItem.errorDetails = refreshedItem.errorDetails || ''; // to clear if nothing
					_.extend(original, refreshedItem);
					itemsToRefresh.push(original);
				});
				this.refreshGrid(itemsToRefresh);
			});
		}
	};

	private handleQueryParams = (): void => {
		if (this.$routeParams.driverId) {
			let driverId = parseInt(this.$routeParams.driverId, 10);
			this.driversApi.getDriverById(driverId).then((driver) => {
				if (driver && driver.masterAccountId !== this.security.getMasterAccountId()) {
					this.redirectService.saveCurrentMA({accountId: driver.masterAccountId});
					return;
				}
				if (this.$routeParams.edit) {
					this.actionsService.editDrivers(driver);
					this.$location.url('drivers');
				} else if (!this.security.restrictPage(this.authorization.hasDriversManagementAccess)) {
					this.handleNotificationDialogs(driver);
				}
			});
		} else {
			this.security.restrictPage(this.authorization.hasDriversManagementAccess);
		}
	};

	private handleNotificationDialogs = (driver: DriversItem): void => {
		if (driver && driver.status === DriversStatus.reportable) {
			this.getDriversModel(driver);
		}
		this.$location.url('drivers');
	};

	createFolder = (): void => {
		if (_.isUndefined(this.props.contentProviderId)) {
			this.errors.noProjectSelected = true;
			return;
		} else this.errors.noProjectSelected = false;
		this.actionsService.createFolder(null);
	};

	private updateVisibleDriversList = (): void => {
		let projectFilter: ng.IFilterFilter = this.$filter('projectFilter');
		_.chain(this.driversList)
			.filter(item => item.type === DriversType.folder)
			.each((item: IDriversFolder) => {
				item.folderProjectId = this.props.projectId;
			});

		let projectFilterData: ProjectFilterData = {
			singleProject: this.checkIfSingleProject(),
			projectId: this.props.projectId
		};
		this.visibleDriversList = projectFilter(this.driversList, projectFilterData);
		if (this.props.projectId && this.security.has('manage_drivers'))
			this.visibleDriversList.push(this.getDriversTemplate(this.props.projectId));

		this.refreshGrid();
	};

	checkIfSingleProject = (): boolean => {
		return this.isWorkspaceEnabled
			? this.currentWorkspaceProjects && this.currentWorkspaceProjects.length === 1
			: this.currentProjects && this.currentProjects.length === 1;
	};

	getDriversTemplate(projectId: number): DriversItem {
		let template: DriversItem = _.findWhere(this.driversTemplates, {
			type: DriversType.drivers_template,
			projectId: this.props.projectId
		} as DriversItem) as DriversItem;
		if (!template) {
			template = this.generateDriversTemplate();
			template.projectId = projectId;
		}
		return template;
	}
}

app.component('driversManagement', {
	controller: DriversManagementComponent,
	templateUrl: 'partials/drivers/drivers-management.component.html'
});
