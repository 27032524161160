import { Security } from '@cxstudio/auth/security-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import IDashboardWidgets from '@cxstudio/dashboards/widgets/dashboard-widgets.factory';
import LinkedFilterExport from '@cxstudio/dashboards/widgets/linked-filter-export';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { PreviewExportService } from '@app/modules/widget-container/widget-menu/export/preview-export.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { SelectorWidgetNavigationType } from '@app/modules/widget-settings/selector-widget/selector-widget-navigation-type.enum';
import { CustomFilterService } from '@cxstudio/services/custom-filter-service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';
import * as moment from 'moment';
import { PromiseUtils } from '@app/util/promise-utils';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';

export interface DashboardExportParams {
	fileTitle: string;
	width: number;
	height: number;
	dashboardName: string;
	userEmail: string;
	clientTimezone: number;
	clientTimezoneName: string;
	locale: string;
	headerSelector: string;
	hierarchyNodeId: number;
	appliedFiltersArray: string;
	widgetLinkingParams: {[key: number]: LinkedFilterExport[]};
	widgetPaginationParams: {[key: number]: number};
	widgetSentenceSelectionParams: {[key: number]: number};
	widgetTextFilters: {[key: number]: string};
	tabId: number;
	tabSnapshotId: number;
}

export interface DashboardExportConfig {
	pixelPerUnit: number;
	pdfPageBreakEnabled: boolean;
	pdfExportPageHeight: number;
	dashboardHeight: number;
	pageHeight: number;
	pageWidth: number;
	newPageBreak: boolean;
}

export class DashboardExportService {

	private readonly GRIDSTER_COLUMNS = 24;
	private readonly GRIDSTER_MARGIN = 20;
	private readonly PAGES_PER_CALL = 5;
	private readonly CHROME_PDF_HEIGHT_LIMIT = 16384;

	constructor(
		private readonly $log: ng.ILogService,
		private readonly $q: ng.IQService,
		private readonly $timeout: ng.ITimeoutService,
		private readonly $window: ng.IWindowService,
		private readonly exportUtils: ExportUtils,
		private readonly cxDialogService: CxDialogService,
		private readonly dateService: DateService,
		private readonly locale: ILocale,
		private readonly security: Security,
		private readonly exportApiService: ExportApiService,
		private readonly currentWidgets: ICurrentWidgets,
		private readonly customFilterService: CustomFilterService,
		private readonly dashboardApiService: DashboardApiService,
		private readonly widgetApiService: WidgetApiService,
		private readonly previewExportService: PreviewExportService,
		private readonly reportProcessingService: ReportProcessingService,
	) {}


	exportDashboard = (dashboardName: string, dashboardContainer: IDashboardWidgets,
		exporting: {active: boolean}, options) => {
		let widgetsCache = dashboardContainer.getWidgetsCache();
		let widgets = dashboardContainer.getWidgets();

		let widgetPromises = [];
		let titles = [];
		let loading = false;
		DashboardUtils.sortWidgets(widgets);
		let dashboardId;
		_.each(widgets, widget => {
			dashboardId = widget.dashboardId;
			if (widgetsCache.isLoading(widget.id))
				loading = true;
			let selectedSentenceId = dashboardContainer.getSelectedSentence(widget.id);
			let promise = null;
			if (widgetsCache.getData(widget.id)) {
				if (this.canExportWidgetData(widget)) {
					let page = dashboardContainer.getPagination(widget.id) || 1;
					promise = this.getCBExportDataPromise(widget, widgetsCache.getData(widget.id),
						widgetsCache.getColumns(widget.id), options, page, selectedSentenceId);
				}
			}


			if (promise) {
				widgetPromises.push(promise.catch(response => this.$log.warn('Errors in report.', response)));
				titles.push(widget.displayName);
			}
		});
		if (loading) {
			this.cxDialogService.notify(this.locale.getString('common.error'), this.locale.getString('dashboard.cantExportLoading'));
		} else if (widgetPromises.length === 0) {
			this.cxDialogService.notify(this.locale.getString('common.error'), this.locale.getString('dashboard.nothingToExport'));
		} else {
			this.$q.all(widgetPromises).then((results) => {
				let widgetsDataToExport = [];
				for (let i = 0; i < results.length; i++) {
					widgetsDataToExport.push({
						title: titles[i],
						data: results[i] ? results[i] : this.locale.getString('dashboard.reportUnavailable')});
				}
				if (exporting) {
					exporting.active = true; // to avoid alert in edit mode

					this.$timeout(() => {
						exporting.active = false;
					}, 500);
				}

				this.exportApiService.getDashboardExcelExport(dashboardId, dashboardName, widgetsDataToExport).then((result) => {
					this.exportUtils.exportXLSX(result.filename, result.data);
				});
			});
		}
	};

	private canExportWidgetData(widget): boolean {
		return widget.type === WidgetDisplayType.CB
			&& widget.visualProperties.visualization !== SelectorWidgetNavigationType.SELECTOR_SEARCH
			&& widget.properties.widgetType !== WidgetType.NETWORK;
	}

	private exportPDFInternal(dashboard: Dashboard, widgets?: Widget[], viewOptions?): ng.IPromise<any> {
		if (!widgets) { // export from dashboards page
			return PromiseUtils.old(this.widgetApiService.getDashboardWidgets(dashboard.id, true).then((dashboardWidgets) => {
				return {
					dashboardId: dashboard.id,
					params: this.getExportParams(dashboard, dashboardWidgets)
				};
			}));
		}
		let params = this.getExportParams(dashboard, widgets);
		if (!params) {
			return this.$q.when({});
		}

		let personalization = viewOptions.personalization;
		let dashboardHistory = viewOptions.dashboardHistory;
		let linkedFilters = viewOptions.linkedFilters;
		let pagination = viewOptions.pagination;

		let hierarchyNode = personalization.currentHierarchyNode;
		if (hierarchyNode && hierarchyNode.id) {
			params.hierarchyNodeId = personalization.currentHierarchyNode.id;
		}

		let hasDrillToDashboardFilter = dashboardHistory.hasDrillToDashboardFilter();
		let hasUnsavedAppliedFilter = dashboardHistory.isViewFilterChanged() && dashboardHistory.isAppliedFiltersUnsaved();
		let hasTextFilter = !!dashboardHistory.getTextFilter();
		if (hasUnsavedAppliedFilter || hasDrillToDashboardFilter || hasTextFilter) {
			params.appliedFiltersArray = JSON.stringify(dashboardHistory.getAppliedFilters());
		}

		if (linkedFilters) {
			params.widgetLinkingParams = linkedFilters;
		}

		if (pagination) {
			params.widgetPaginationParams = pagination;
		}

		if (viewOptions.selectedSentences) {
			params.widgetSentenceSelectionParams = viewOptions.selectedSentences;
		}

		if (viewOptions.textFilters) {
			params.widgetTextFilters = viewOptions.textFilters;
		}

		if (viewOptions.bookId) {
			params.tabId = dashboard.id;
		}

		return this.dashboardApiService.saveDashboardVersion(dashboard.id, {
			name: dashboard.name,
			widgets,
		}).then((response) => {
			let versionId = response.data;
			if (!viewOptions.bookId) {
				return {
					versionId,
					dashboardId: dashboard.id,
					params
				};
			} else {
				params.tabSnapshotId = versionId;
				return {
					dashboardId: viewOptions.bookId,
					params
				};
			}
		});
	}

	exportPDF = (dashboard: Dashboard, widgets?: Widget[], viewOptions?: any): ng.IPromise<void> => {
		return this.exportPDFInternal(dashboard, widgets, viewOptions).then((config) => {
			if (!config.params) {
				return;
			}
			return this.exportApiService.requestDashboardPdf(config.dashboardId, config.params, config.versionId).then(() => {
				this.cxDialogService.notify(this.locale.getString('dashboard.exportPDF'),
					this.locale.getString('dashboard.exportPDFMessage'));
			});
		});
	};

	exportPDFDownload = (dashboard: Dashboard, widgets?: Widget[], viewOptions?: any): ng.IPromise<void> => {
		return this.exportPDFInternal(dashboard, widgets, viewOptions).then((config) => {
			if (!config.params) {
				return;
			}
			this.cxDialogService.notify(this.locale.getString('dashboard.exportPDF'),
				this.locale.getString('dashboard.exportPDFDownloadMessage'), {keyboard: true});
			return this.exportApiService.downloadDashboardPdf(config.dashboardId, config.versionId, config.params).then((response) => {
				this.exportUtils.exportPDF(config.params.fileTitle + '.pdf', response.data);
				let versionToRemove = config.versionId || config.params.tabSnapshotId;
				if (!_.isUndefined(versionToRemove)) {
					return this.dashboardApiService.removeDashboardVersion(versionToRemove);
				}
				return;
			});
		});

	};

	getExportParams = (dashboard, widgets): Partial<DashboardExportParams> => {
		widgets = widgets || [];
		let config = this.getExportConfig(widgets);

		if (!this.isPrintToPdfHeightValid(config)) {
			return;
		}

		return {
			fileTitle: `${dashboard.name}_${this.dateService.format(new Date(), DateTimeFormat.DASH_DATE)}`,
			width: config.pageWidth,
			height: config.pageHeight,
			dashboardName: dashboard.name,
			userEmail: this.security.getEmail(),
			clientTimezone: new Date().getTimezoneOffset(),
			clientTimezoneName: moment.tz.guess(),
			locale: this.locale.getLocale(),
			headerSelector: '.pdf-header'
		};
	};

	private isPrintToPdfHeightValid(config: DashboardExportConfig): boolean {
		if (config.pageHeight >= this.CHROME_PDF_HEIGHT_LIMIT) {
			this.cxDialogService.notify(this.locale.getString('common.error'),
				this.locale.getString('dashboard.pdfPageTooBig', { pageHeight: config.pageHeight }));
			return false;
		}
		return true;
	}

	getExportConfig = (widgets, verticalToolbarOffset?): DashboardExportConfig => {
		let dashboardHeightUnits = this.getDashboardHeightUnits(widgets);
		let pageBreakHeightUnits = this.getPageBreakHeightUnits(widgets);

		let pixelsPerUnit = this.getPixelsPerUnit(verticalToolbarOffset);

		let pageHeight = this.getPageHeight(pixelsPerUnit, dashboardHeightUnits, pageBreakHeightUnits);
		let pageWidth = this.getPageWidth(pixelsPerUnit);

		return {
			pixelPerUnit: pixelsPerUnit,
			pdfPageBreakEnabled: pageBreakHeightUnits > 0,
			pdfExportPageHeight: pageBreakHeightUnits,
			dashboardHeight: dashboardHeightUnits,
			pageHeight,
			pageWidth,
			newPageBreak: this.isNewPageBreak(widgets)
		};
	};

	private getPageWidth(pixelsPerUnit: number): number {
		return pixelsPerUnit * this.GRIDSTER_COLUMNS * 2 + this.GRIDSTER_MARGIN;
	}

	private getPageHeight(pixelsPerUnit: number, dashboardHeightUnits: number, pageBreakHeightUnits: number): number {
		if (pageBreakHeightUnits > 0) {
			return pageBreakHeightUnits * pixelsPerUnit;
		}

		if (dashboardHeightUnits > 0) {
			return dashboardHeightUnits * pixelsPerUnit;
		}

		return 0;
	}

	private getPageBreakHeightUnits(widgets: Widget[]): number {
		let pageBreakWidget = _.findWhere(widgets, { name: WidgetType.PAGE_BREAK });
		return pageBreakWidget ? pageBreakWidget.posY : 0;
	}

	private isNewPageBreak(widgets: Widget[]): boolean {
		let pageBreakWidget = _.findWhere(widgets, { name: WidgetType.PAGE_BREAK });
		return pageBreakWidget && pageBreakWidget.properties.pageBreakV2;
	}

	private getPixelsPerUnit(verticalToolbarOffset: number): number {
		// same calculation as in angular-gridster
		let gridsterWidth = this.getScreenWidth(verticalToolbarOffset) - this.GRIDSTER_MARGIN;
		return Math.round(gridsterWidth / this.GRIDSTER_COLUMNS / 2);
	}

	private getScreenWidth(verticalToolbarOffset: number): number {
		let gridsterElementWidth = this.exportUtils.getGridsterClientWidth() || this.$window.innerWidth;
		return verticalToolbarOffset
			? gridsterElementWidth - verticalToolbarOffset
			: gridsterElementWidth;
	}

	private getDashboardHeightUnits(widgets: Widget[]): number {
		// same calculations in PDFExportAbstractTask.java
		return widgets.map((widget) => {
			return widget.posY + widget.height;
		}).reduce((left, right) => {
			return Math.max(left, right);
		}, 0);
	}

	checkWidgetsForIntersection = (exportConfig: DashboardExportConfig, widgets: Widget[],
		considerPageBreakHeight: boolean = false, updateWidgets: boolean = false): boolean => {
		if (!exportConfig.pdfPageBreakEnabled) {
			if (updateWidgets) {
				widgets.forEach(widget => {
					widget.intersectPageBreak = false;
				});
			}

			return false;
		}

		let pageHeight = exportConfig.pdfExportPageHeight;
		let dashboardHeight = exportConfig.dashboardHeight;

		let hasSomeIntersection = false;

		let fastForward = isFalse(updateWidgets);

		for (let widget of widgets) {
			if (widget.name === WidgetType.PAGE_BREAK) {
				continue;
			}

			// Skip widgets under main page break widget
			if (widget.posY + widget.height <= pageHeight) {
				if (updateWidgets) {
					widget.intersectPageBreak = false;
				}

				continue;
			}

			// only widget with height larger than page height can have intersection for new page break
			let hasIntersection = exportConfig.newPageBreak
				? widget.height > pageHeight
				: this.checkIntersection(pageHeight, dashboardHeight, widget.posY, widget.posY + widget.height, considerPageBreakHeight);

			if (updateWidgets) {
				widget.intersectPageBreak = hasIntersection;
			}

			if (fastForward && hasIntersection) {
				return hasIntersection;
			}

			hasSomeIntersection = hasSomeIntersection || hasIntersection;
		}

		return hasSomeIntersection;
	};

	private checkIntersection(pageHeight: number, dashboardHeight: number,
		widgetStart: number, widgetEnd: number, considerPageBreakHeight: boolean): boolean {
		if (dashboardHeight === 0) {
			return false;
		}

		// In EDIT mode page break height is 1, in VIEW mode is 0
		let pageBreakHeight = considerPageBreakHeight ? 1 : 0;
		let delimiterCount = (dashboardHeight - pageBreakHeight) / pageHeight;

		for (let index = 0; index < delimiterCount; index++) {
			let delimiterStart = (index * pageHeight) + pageBreakHeight;
			let delimiterEnd = ((index + 1) * pageHeight) + pageBreakHeight;

			if (delimiterStart >= widgetEnd || delimiterEnd <= widgetStart) {
				continue;
			}

			if (widgetStart < delimiterStart || widgetEnd > delimiterEnd) {
				return true;
			}
		}

		return false;
	}

	private getCBExportDataPromise(widget: Widget, data: ReportDataObject,
		widgetColumns, options, page: number, selectedSentenceId: number): ng.IPromise<any> {
		if (!data)
			return null;
		let props = widget.properties;
		let dataCopy = angular.copy(data);

		if (props.previewMode === PreviewMode.DOCUMENT) {
			if (widget.visualProperties.sentencePaneEnabled) {
				this.cropDataToPage(dataCopy, page, props.itemsPerPage);
				return this.populateSentenceDocumentText(widget, dataCopy).then(() => {
					return this.processExportData(widget, dataCopy, widgetColumns, options);
				});
			} else {
				let datum = _.findWhere(dataCopy.data, {id: selectedSentenceId});
				dataCopy.data = datum ? [datum] : [];
				return this.populateSingleItemText(widget, dataCopy).then(() => {
					return this.processExportData(widget, dataCopy, widgetColumns, options);
				});
			}
		} else {
			return this.processExportData(widget, dataCopy, widgetColumns, options);
		}
	}

	private processExportData(widget: Widget, data: ReportDataObject,
		widgetColumns, options?: {hasChanges: boolean; allWidgets: boolean}): ng.IPromise<any> {
		return this.exportUtils.getCBExportOptions(widget, data).then((allOptions) => {
			if (options?.hasChanges) allOptions.hasChanges = true;
			if (options?.allWidgets) allOptions.allWidgets = true;
			return PromiseUtils.old(this.reportProcessingService.getWidgetFiltersMetadata(widget,
				this.currentWidgets.getDashboardHistory(widget.containerId))).then(widgetMetadata =>
					this.exportUtils.getWidgetDataForExport(widget, data, allOptions, widgetColumns, widgetMetadata));
		});
	}

	private cropDataToPage(data: ReportDataObject, page: number, itemsPerPage: number): void {
		if (data.data.length > itemsPerPage) {
			data.data.splice(0, itemsPerPage * ((page - 1) % this.PAGES_PER_CALL)); //removing items before current page
			data.data.splice(itemsPerPage);
		}
	}

	private populateSentenceDocumentText(widget: Widget, data: ReportDataObject): ng.IPromise<void> {
		if (!data.data || !data.data.length) {
			return this.$q.resolve();
		}
		let sentenceIds = data.data.map((sentence) => {
			return sentence.id;
		});
		let copiedWidget = angular.copy(widget);
		let rule = {
			type: FilterRuleType.raw,
			query: `_id_sentence: (${sentenceIds.join(' OR ')})`
		};
		copiedWidget.properties.adhocFilter.filterRules.push(rule);
		copiedWidget.properties.exportAttributes = [];

		let filterProvider = this.currentWidgets.getDashboardHistory(copiedWidget.containerId);
		let postProcessSettings = this.customFilterService.postprocessWidgetProperties(copiedWidget, filterProvider);

		return postProcessSettings.then((widgetSettings) => {
			return PromiseUtils.old(this.previewExportService.getPreviewExport(widgetSettings)).then((results) => {
				data.data.forEach((item) => {
					let result = _.findWhere(results.data, {id: item.id});
					if (this.isVerbatimMode(copiedWidget)) {
						item.verbatim = result && result.verbatim;
					} else {
						item.documentText = result && result.documentText;
					}
				});
			});
		});
	}

	private populateSingleItemText(widget: Widget, data: ReportDataObject): ng.IPromise<any> {
		if (!data.data || !data.data.length) {
			return this.$q.resolve();
		}
		let copiedWidget = angular.copy(widget);
		let query = this.isVerbatimMode(copiedWidget)
			? `_id_verbatim:${data.data[0].verbatimId}`
			: `_id_document:${data.data[0].documentId}`;

		let rule = {
			type: FilterRuleType.raw,
			query
		};

		copiedWidget.properties.adhocFilter.filterRules.push(rule);
		copiedWidget.properties.exportAttributes = [];

		let filterProvider = this.currentWidgets.getDashboardHistory(copiedWidget.containerId);
		let postProcessSettings = this.customFilterService.postprocessWidgetProperties(copiedWidget, filterProvider);

		return postProcessSettings.then((widgetSettings) => {
			return PromiseUtils.old(this.previewExportService.getPreviewExport(widgetSettings)).then((results) => {
				let result = results.data && results.data[0];
				if (this.isVerbatimMode(copiedWidget)) {
					data.data[0].verbatim = result && result.verbatim;
				} else {
					data.data[0].documentText = result && result.documentText;
				}
			});
		});
	}

	private isVerbatimMode(widget: PreviewWidget): boolean {
		let prefs = widget.visualProperties && widget.visualProperties.preferences;
		return prefs && prefs.settings && prefs.settings.singleVerbatimMode;
	}

	getClarabridgeLogo = () => {
		return `${location.protocol}//${location.host}/dashboard/img/QualtricsXM-Logo-padded.png`;
	};
}
app.service('dashboardExportService', DashboardExportService);
