import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { GridApi, MenuItemDef, RowNode } from 'ag-grid-enterprise';
import { AgGridDashboardBulkActionsService } from '../actions/ag-grid-dashboard-bulk-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { MoveToOptionService } from '../options/move-to-option.service';
import { ShowHideOptionService } from '../options/show-hide-option.service';
import { CommonOptionsProviderService } from './common-options-provider.service';


@Injectable({
	providedIn: 'root'
})
export class BulkMenuOptionsProviderService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly commonOptionsProviderService: CommonOptionsProviderService,
		private readonly agGridDashboardBulkActionsService: AgGridDashboardBulkActionsService,
		private readonly moveToOptionService: MoveToOptionService,
		private readonly showHideOptionService: ShowHideOptionService,
		private readonly dashboardGridHelperService: DashboardGridHelperService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
	) {}

	getOptions(gridApi: GridApi, filterManager: TableFilterManager): (string | MenuItemDef)[] {
		let items: (string | MenuItemDef)[] = [];

		let selectedObjects: Dashboard[] = gridApi.getSelectedRows();
		let selectedNodes: RowNode[] = gridApi.getSelectedNodes() as RowNode[];

		let moveToSubMenu = this.moveToOptionService.getBulkSubMenu(selectedObjects);
		if (moveToSubMenu && moveToSubMenu.length > 0) {
			items.push(this.moveToOptionService.get(moveToSubMenu));
		}

		if (this.dashboardService.hasSharePermission()) {
			items.push(this.getBulkShare(selectedObjects, selectedNodes));
		}

		if (this.dashboardGridHelperService.isLabelsEditAllowed(selectedObjects)) {
			items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
			items.push(this.getBulkEditLabels(selectedObjects, selectedNodes));
		}

		items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);

		let bulkShowHideSubMenu: (string | MenuItemDef)[] = this.showHideOptionService.getBulkSubmenu(gridApi, filterManager);
		items.push(this.showHideOptionService.get(bulkShowHideSubMenu));

		if (this.dashboardGridHelperService.isOwner(selectedObjects)) {
			items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
			items.push(this.getBulkRemoveOption(selectedObjects));

			if (this.dashboardGridHelperService.hasNoFolders(selectedObjects)) {
				items.push(this.commonOptionsProviderService.geTransferOption(selectedObjects));
			}
		}

		return items;
	}

	private getBulkShare(selectedObjects: Dashboard[], selectedNodes: RowNode[]): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.share'),
			action: () => this.agGridDashboardBulkActionsService.share(selectedObjects, selectedNodes)
		};
	}

	private getBulkEditLabels(selectedObjects: Dashboard[], selectedNodes: RowNode[]): MenuItemDef {
		return {
			name: this.locale.getString('common.editLabels'),
			action: () => this.agGridDashboardBulkActionsService.editLabels(selectedObjects, selectedNodes)
		};
	}

	private getBulkRemoveOption(selectedObjects: Dashboard[]): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.deleteSelected'),
			action: () => this.agGridDashboardBulkActionsService.remove(selectedObjects)
		};
	}

}
