import { LogoSettingsServiceClass } from '@app/modules/account-administration/appearance/logo-settings/logo-settings.service';

// workaround until we find a replacement for nv-file-select
export class LogoUploadButtonComponent implements ng.IComponentController {
	uploader: any;

	constructor(
		private readonly logoSettingsService: LogoSettingsServiceClass
	) {}

	$onInit(): void {
		this.uploader = this.logoSettingsService.initUploader();
	}

	selectFile = () => {
		setTimeout(() => { // bypassing click to file selector
			angular.element('input#upload-logo').trigger('click');
		}, 0);
	};
}

app.component('logoUploadButton', {
		controller: LogoUploadButtonComponent,
		template: `
	<div>
		<button
			btn-loading="uploading"
			class="btn btn-icon btn-secondary btn-branding btn-labeled p-8"
			ng-click="$ctrl.selectFile()"
			title="{{'appearance.uploadLogo'|i18n}}">
			<span class="q-icon q-icon-upload"></span>
		</button>
		<input class="hide"
			id="upload-logo"
			type="file"
			nv-file-select
			uploader="$ctrl.uploader"/>
	</div>
`});

