import { Component, OnInit, ChangeDetectionStrategy, Inject, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ObjectUtils } from '@app/util/object-utils';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { Security } from '@cxstudio/auth/security-service';
import { DefaultBrandingColors } from '@cxstudio/master-accounts/default-branding-colors.enum';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { LogoSettingsServiceClass } from '../logo-settings/logo-settings.service';
import { CustomizableColor } from './customizable-color.enum';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { Observable } from 'rxjs/internal/Observable';

@Component({
	selector: 'custom-branding',
	templateUrl: './custom-branding.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class CustomBrandingComponent implements OnInit {

	readonly USE_MORE_ACCESSIBLE_COLOR_DEFAULT = true;

	brandingSubject: BehaviorSubject<{[key: string]: string}>;
	branding$: Observable<{[key: string]: string}>;

	colorPickerFixed: boolean = false;
	colorsNameMap: Array<{name: string; displayName: string}>;
	brandingColors: {[key: string]: string};

	brandError: boolean;
	brandErrorMsg: string;
	brandColorError: boolean;
	brandingSubmissionSuccess: boolean;

	useMoreAccessibleColor: boolean;
	loading: boolean;

	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef,
		private logoSettings: LogoSettingsServiceClass,
		@Inject('security') private security: Security,
		private applicationThemeService: ApplicationThemeService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils
	) {}

	ngOnInit(): void {
		this.initBranding();

		let currentMasterAccount: CurrentMasterAccount = this.security.getCurrentMasterAccount();
		this.brandingColors = ObjectUtils.copy(currentMasterAccount.brandingColors);
		this.brandingSubject = new BehaviorSubject(this.brandingColors);
		this.branding$ = this.brandingSubject.asObservable();

		this.useMoreAccessibleColor = !!currentMasterAccount.useMoreAccessibleColor;
	}

	initBranding = () => {
		this.brandError = false;
		this.brandErrorMsg = '';
		this.brandColorError = false;
		let colorsOrder = [ CustomizableColor.HEADER_BACKGROUND, CustomizableColor.PRIMARY_BACKGROUND, CustomizableColor.ACTION];

		this.colorsNameMap = colorsOrder.map((num) => {
			return {
				name: `COLOR_${num}`,
				displayName: this.locale.getString(`colors.color${num}`)
			};
		});
	};

	resetToDefault = () => {
		this.useMoreAccessibleColor = this.USE_MORE_ACCESSIBLE_COLOR_DEFAULT;
		this.brandingColors = ObjectUtils.copy(DefaultBrandingColors);
		this.brandingSubject.next(this.brandingColors);
		this.logoSettings.deleteInPreviewMode();
		this.initBranding();
	};

	areColorsValid = (): boolean => {
		return _.all(this.brandingColors, this.colorUtils.isValidHexCode);
	};

	hasChanges = (): boolean => {
		return this.hasColorsChanges() || this.hasUseMoreAccessibleColorChange() || this.logoSettings.previewLogoSelected ||
			this.logoSettings.deleteOnSave;
	};

	hasColorsChanges = (): boolean => {
		return !_.isEqual(this.brandingColors, this.security.getCurrentMasterAccount().brandingColors);
	};

	hasUseMoreAccessibleColorChange = (): boolean => {
		return this.useMoreAccessibleColor !== !!this.security.getCurrentMasterAccount().useMoreAccessibleColor;
	};

	cancelChanges = () => {
		this.cancelUseMoreAccessibleColorChange();
		this.cancelColorChanges();
		this.logoSettings.cancelLogoChanges();
		this.initBranding();
	};

	cancelUseMoreAccessibleColorChange = (): void => {
		this.useMoreAccessibleColor = this.security.getCurrentMasterAccount().useMoreAccessibleColor;
	};

	cancelColorChanges = () => {
		this.brandingColors = ObjectUtils.copy(this.security.getCurrentMasterAccount().brandingColors);
		this.brandingSubject.next(this.brandingColors);
	};

	saveChanges = () => {

		let promises = [];

		this.preprocessApplicationThemeChanges();

		let updateBrandingColors: boolean = false;
		if (this.hasColorsChanges() && this.brandColorError === false) {
			updateBrandingColors = true;
			promises.push(this.masterAccountApiService.updateBrandingColors(this.brandingColors));
		}

		let updateUseMoreAccessibleColor: boolean = false;
		if (this.hasUseMoreAccessibleColorChange()) {
			updateUseMoreAccessibleColor = true;
			promises.push(this.masterAccountApiService.updateProperty('useMoreAccessibleColor', this.useMoreAccessibleColor));
		}

		if (this.logoSettings.hasPendingLogoChanges()) {
			promises.push(this.logoSettings.updateLogo());
		}

		if (!_.isEmpty(promises)) {
			this.loading = true;
			Promise.all(promises).then(() => {
				this.postprocessApplicationThemeChanges(updateBrandingColors, updateUseMoreAccessibleColor);
			});
		}
	};

	private preprocessApplicationThemeChanges(): void {
		if (this.hasColorsChanges() || this.hasUseMoreAccessibleColorChange()) {
			this.brandingSubmissionSuccess = false;
		}

		if (this.hasColorsChanges()) {
			this.brandColorError = !this.colorsValid();
		}
	}

	private postprocessApplicationThemeChanges(updateBrandingColors: boolean, updateUseMoreAccessibleColor: boolean): void {
		this.brandingSubmissionSuccess = true;
		this.loading = false;
		let currentMasterAccount: CurrentMasterAccount = this.security.getCurrentMasterAccount();
		if (updateBrandingColors) {
			currentMasterAccount.brandingColors = ObjectUtils.copy(this.brandingColors);
		}
		if (updateUseMoreAccessibleColor) {
			currentMasterAccount.useMoreAccessibleColor = this.useMoreAccessibleColor;
		}
		this.ref.detectChanges();

		const FORCE_RELOAD = true;
		this.applicationThemeService.applySelectedThemes(FORCE_RELOAD);
	}


	fixColorPicker = () => {
		// fix colorpickers
		if (!this.colorPickerFixed) {
			$('.colorpicker.dropdown').appendTo($('.ma-management-screen>.tab-content'));
			this.colorPickerFixed = true;
		}
		this.brandingSubmissionSuccess = false;
	};

	private colorsValid = (): boolean => {
		for (let color of this.colorsNameMap) {
			if (this.brandingColors[color.name].length === 0)
				return false;
		}
		return true;
	};

	updateColor(name: string) {
		if (name !== 'COLOR_1') {
			this.brandingSubject.next(this.brandingColors);
			return;
		}

		this.applicationThemeService.getContrastColor(this.brandingColors[name])
			.then((val: {contrast: string}) => {
				this.brandingColors.COLOR_6 = val.contrast;
				this.brandingSubject.next(this.brandingColors);
			});
	}
}

app.directive('customBranding', downgradeComponent({component: CustomBrandingComponent }));
