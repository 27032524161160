import { Injectable, Inject } from '@angular/core';
import { CxHttpService } from '@app/core';
import { Security } from '@cxstudio/auth/security-service';
import { KafkaState } from '@app/modules/system-administration/status/listener-status/kafka-state';

@Injectable({
	providedIn: 'root'
})
export class KafkaStatusApi {
	constructor(
		private cxHttp: CxHttpService,
		@Inject('security') private security: Security
	) {}

	getData = (): Promise<KafkaState[]> => {
		return this.cxHttp.get('rest/system-admin/kafka/status');
	};
}