import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CxHttpService } from '@app/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { Phrase } from '../explorer-automated-summaries/automated-summaries-utils.class';

type CoachingTipsResponse = {
	tips: string;
};

export type CoachingTipsRequestBody = {
	phrases: Phrase[];
	language?: string;
	sourceType?: string;
	projectId: string;
	uniqueDocumentIdentifier: string;
};

@Injectable({
	providedIn: 'root'
})
export class CoachingAssistantApiService {
	constructor(
		private readonly http: CxHttpService,
		@Inject('urlService') private readonly urlService: UrlService
	) { }

	generateCoachingTips = (body: CoachingTipsRequestBody): Observable<CoachingTipsResponse> => {
		return from(
			this.http.post<CoachingTipsResponse>(
				this.urlService.getAPIUrl(`rest/tips`),
				body
			)
		);
	};
}
