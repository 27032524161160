import { SharingStatus } from '@cxstudio/common/sharing-status';
import { IShareUiConfig } from '@cxstudio/sharing/generic-share.component';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';

export class DriverSharingComponent implements ng.IComponentController {
	items: any[];

	resolve: {
		items: any[];
	};

	getPermissionName: any = {
		edit : (): string => 'manage_drivers',
		shareView : (): string => 'manage_drivers',
		shareEdit : (): string => 'manage_drivers',
		defaultSharePermission: (): string => PermissionType.VIEW
	};

	uiConfig: Partial<IShareUiConfig> = {
		hideAllowEditorsToShare: true
	};

	constructor(
		private driversApi: DriversApi,
	) {}

	$onInit = () => {
		this.items = this.resolve.items;
	};

	getShareStatus = (): SharingStatus => this.items[0].sharingStatus;

	getSharedUsersAndGroups = (items): any => this.driversApi.getSharedUsersAndGroups(items[0].id);

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.MANAGE_DRIVERS;
}

app.component('driverSharing', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
	},
	controller: DriverSharingComponent,
	template: `
<generic-share
	object-type-name="{{::'object.driver'|i18n}}"
	resolve="::$ctrl.resolve"
	close="::$ctrl.close()"
	dismiss="::$ctrl.dismiss()"
	get-permission-name="::$ctrl.getPermissionName"
	get-shared-users-and-groups="$ctrl.getSharedUsersAndGroups"
	get-share-status="$ctrl.getShareStatus"
	name-property="displayName"
	owner="::$ctrl.items[0].ownerName"
	ui-config="::$ctrl.uiConfig"
	asset-edit-permission-type="$ctrl.getAssetEditPermissionType()">
</generic-share>`
});
