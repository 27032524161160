import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { ISelectableTreeItem } from '@cxstudio/common/entities/selectable-tree-item';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
	selector: 'side-by-side-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex stack-medium-and-smaller">
			<div class="selection-deselection-list available-items">
				<div class="d-flex align-items-center h-32 ph-8 mv-8">
					<p class="available-items-label">{{getAvailableItemsLabel()}}</p>
				</div>
				<searchable-tree
					[hierarchyList]="currentAvailableTree"
					[ngDisabled]="disabled"
					class="available-items"
					[placeholder]="'mobile.find'|i18n"
					[displayProperty]="'displayName'"
					(onNodeClick)="selectItem($event)"
					(onHierarchyChange)="updateAvailableHierarchy($event)"
					[showNotRecommendedPrompt]="false"
					[limitedWidth]="true"
				></searchable-tree>
			</div>

			<div class="selection-deselection-list selected-items">
				<div class="d-flex align-items-center h-32 ph-8 mv-8">
					<div class="selected-item-label">{{getSelectedItemsLabel()}}</div>
				</div>
				<searchable-tree
					[hierarchyList]="currentSelectedTree"
					[ngDisabled]="disabled"
					class="selected-items"
					[placeholder]="'mobile.find'|i18n"
					[displayProperty]="'displayName'"
					(onNodeClick)="deselectItem($event)"
					(onHierarchyChange)="updateSelectedHierarchy($event)"
					[showNotRecommendedPrompt]="false"
					[limitedWidth]="true"
				></searchable-tree>
			</div>
		</div>
	`
})

export class SideBySideSelectorComponent implements OnInit {

	@Input() availableTree: TreeNode[];
	@Input() selectedTree: TreeNode[];
	@Input() availableLabel: string;
	@Input() selectedLabel: string;
	@Input() disabled = false;
	@Input() hiddenAssets: TreeNode[];
	@Output() onChange: EventEmitter<ISelectableTreeItem> = new EventEmitter<ISelectableTreeItem>();
	currentAvailableTree: TreeNode[];
	currentSelectedTree: TreeNode[];
	currentHiddenAssets: TreeNode[];

	ngOnInit() {
		this.currentAvailableTree = this.availableTree;
		this.currentSelectedTree = this.selectedTree;
		this.currentHiddenAssets = this.hiddenAssets;
		this.populateSelectedItemsFromAvailable();
	}

	private populateSelectedItemsFromAvailable = (): void => {
		this.getPreselectedItems()
			.forEach(item => {
				this.moveItemToSelected(item);
				this.updateTrees();
			});
	};

	private getPreselectedItems = (): ISelectableTreeItem[] => {
		return HierarchyUtils.findItems(this.getAvailableHierarchy(), (item: ISelectableTreeItem) => item.selected);
	};

	getAvailableItemsLabel = (): string => {
		return this.availableLabel || '';
	};

	getSelectedItemsLabel = (): string => {
		return this.selectedLabel || '';
	};

	selectItem = (item: any): void => {
		if (this.isFolder(item.node)) {
			return;
		}
		this.moveItemToSelected(item.node as ISelectableTreeItem);
		(item.node as ISelectableTreeItem).selected = true;
		this.updateTrees();
		this.onChange.emit(item.node as ISelectableTreeItem);
	};

	deselectItem = (item: any): void => {
		if (this.isFolder(item.node)) {
			return;
		}
		HierarchyUtils.addToHierarchy(this.getAvailableHierarchy(), item.node);
		this.removeFromHierarchy(this.getSelectedHierarchy(), item.node, 'assetId');
		(item.node as ISelectableTreeItem).selected = false;
		this.updateTrees();
		this.onChange.emit(item.node as ISelectableTreeItem);
	};

	private isFolder = (item: TreeNode): boolean => {
		return !!item.children;
	};
	private moveItemToSelected = (item: ISelectableTreeItem): void => {
		HierarchyUtils.addToHierarchy(this.getSelectedHierarchy(), item);
		this.removeFromHierarchy(this.getAvailableHierarchy(), item, 'assetId');
	};

	private getAvailableHierarchy = () => {
		return { children: this.availableTree } as ISelectableTreeItem;
	};

	private getSelectedHierarchy = () => {
		return { children: this.selectedTree } as ISelectableTreeItem;
	};

	private removeFromHierarchy = (hierarchy: any, item: any, property: any): void  => {
		if (!hierarchy.children) {
				return;
		}
		for (let child of hierarchy.children) {
			if (child[property] === item[property]) {
				const index = hierarchy.children.findIndex(x => x[property] === item[property]);
				if (index !== -1) {
					hierarchy.children.splice(index, 1);
				}
				return;
			} else {
				this.removeFromHierarchy(child, item, property);
			}
		}
	};

	updateSelectedHierarchy(hierarchy: TreeNode[]): void{
		this.selectedTree = hierarchy;
		this.currentSelectedTree = cloneDeep(this.selectedTree);
	}

	updateAvailableHierarchy(hierarchy: TreeNode[]): void{
		this.availableTree = hierarchy;
		this.currentAvailableTree = cloneDeep(this.availableTree);
	}

	private updateTrees(): void {
		this.currentAvailableTree = cloneDeep(this.availableTree);
		this.currentSelectedTree = cloneDeep(this.selectedTree);
	}


}
