import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { CxLocaleService } from '@app/core';
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class DateUtilsService {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('dateService') private readonly dateService: DateService,
	) {
	}

	private readonly QUERY_DATE_FORMAT = 'YYYY-MM-DD';

	static isToDateOption(value: string): boolean {
		return value.indexOf('to_date') > 0;
	}

	static convertToDateToSameLast(value: string): string {
		if (this.isToDateOption(value)) {
			return `SAME_LAST_${value.substring(0, value.indexOf('_to_date')).toUpperCase()}`;
		} else {
			return value;
		}
	}

	formatDateRange = (range: IDateRange, noPrefix?: boolean): string => {
		if (range && range.from && range.to) {
			const fromDate: string = this.dateService.format(range.from, DateTimeFormat.BASIC_DATE);
			const toDate: string = this.dateService.format(range.to, DateTimeFormat.BASIC_DATE);

			if (fromDate === toDate) {
				return fromDate;
			}

			if (noPrefix) {
				return `${fromDate} ${this.locale.getString('reportFilters.to')} ${toDate}`.toUpperCase();
			}

			return this.locale.getString('reportFilters.fromTo', [fromDate, toDate]).toUpperCase();
		}

		return '';
	};

	formatDateRangeQuery = (range: IDateRange): string => {
		if (range && range.from && range.to) {
			const from: string = moment(range.from).format(this.QUERY_DATE_FORMAT);
			const to: string = moment(range.to).format(this.QUERY_DATE_FORMAT);
			return `[${from},${to}]`; // backend uses this format
		}

		return '';
	};
}
app.service('dateUtils', downgradeInjectable(DateUtilsService));
