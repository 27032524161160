import * as _ from 'underscore';
import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, QueryList, ViewChildren } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { NgbDropdown, NgbDropdownItem, NgbDropdownToggle } from '@ng-bootstrap/ng-bootstrap';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { TokenSuggestion } from '../custom-math-suggestion.class';
import { CustomMathSuggestionsService } from '../custom-math-suggestions.service';

export interface SubmenuClickEvent {
	suggestion: TokenSuggestion;
	numericAggregationSuggestion: TokenSuggestion;
}

@Component({
	selector: 'custom-math-search-list',
	templateUrl: './custom-math-search-list.component.html'
})
export class CustomMathSearchListComponent implements OnInit {
	@Input() suggestions: TokenSuggestion[];
	@Input() displayField: string;
	@Input() selectPrompt: string;
	@Input() tooltipField?: string;

	@Output() selectSuggestion = new EventEmitter<TokenSuggestion>();

	// aggregations submenu
	@Input() hasSubmenuArrow: (suggestion: TokenSuggestion) => boolean;
	@Output() onSubmenuSuggestionSelection = new EventEmitter<SubmenuClickEvent>();

	@ViewChild('listItems', {static: false}) listItems: ElementRef;
	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;
	@ViewChild(NgbDropdownToggle, {static: false}) private ngDropdownToggle: NgbDropdownToggle;
	@ViewChildren(NgbDropdownItem) private ngDropdownItems: QueryList<NgbDropdownItem>;

	searchFilterText: string;

	submenuOpenedFromSuggestion: TokenSuggestion;

	constructor(
		private locale: CxLocaleService,
		private customMathSuggestionsService: CustomMathSuggestionsService,
		private elementRef: ElementRef
	) {}

	ngOnInit(): void {
		this.displayField = this.displayField || 'name';

		this.tooltipField = this.tooltipField || this.displayField;
		this.selectPrompt = this.selectPrompt || this.locale.getString('common.selectPrompt');
	}

	getSuggestionClasses(suggestion: TokenSuggestion): string[] {
		return this.customMathSuggestionsService.getSuggestionClasses(suggestion.tokenType, this.showSubmenuArrow(suggestion));
	}

	getSuggestionIcon(suggestion: TokenSuggestion): string {
		return this.customMathSuggestionsService.getIconClass(suggestion);
	}

	onOpenChange = (open: boolean): void => {
		if (!open) {
			this.resetAttributeSuggestion();
		}
	};

	showSubmenuArrow(suggestion: TokenSuggestion): boolean {
		return this.hasSubmenuArrow ? this.hasSubmenuArrow(suggestion) : false;
	}

	handleSuggestionClick(suggestion: TokenSuggestion): void {
		if (this.showSubmenuArrow(suggestion)) {
			if (this.isOpenClick(suggestion)) {
				this.saveAttributeSuggestion(suggestion);
			} else {
				this.resetAttributeSuggestion();
			}
		} else {
			this.selectSuggestion.emit(suggestion);
			this.ngDropdown.close();
		}
	}

	private isOpenClick(suggestion: TokenSuggestion): boolean {
		return this.submenuOpenedFromSuggestion === undefined || this.submenuOpenedFromSuggestion !== suggestion;
	}

	private saveAttributeSuggestion(suggestion: TokenSuggestion): void {
		this.submenuOpenedFromSuggestion = suggestion;
	}

	resetAttributeSuggestion(): void {
		this.submenuOpenedFromSuggestion = undefined;
	}

	showAggregationsSubmenu(suggestion: TokenSuggestion): boolean {
		return suggestion === this.submenuOpenedFromSuggestion;
	}

	onSubmenuItemClick(numericAggregationSuggestion: TokenSuggestion): void {
		this.onSubmenuSuggestionSelection.emit({suggestion: this.submenuOpenedFromSuggestion, numericAggregationSuggestion});
		this.ngDropdown.close();
	}

	getSuggestionTitle = (suggestion: TokenSuggestion): string => {
		return suggestion[this.tooltipField] || '';
	};

	searchFilter = (suggestion: TokenSuggestion): boolean => {
		if (this.searchFilterText) {
			let text = suggestion[this.displayField];
			return text && text.toLowerCase().indexOf(this.searchFilterText.toLowerCase()) > -1;
		}
		return true;
	};

	getFilteredSuggestions = (suggestions: TokenSuggestion[]): TokenSuggestion[] => {
		return _.chain(suggestions)
			.filter(this.searchFilter)
			.value();
	};

	onDropdownKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) && !this.ngDropdown.isOpen()) {
			KeyboardUtils.intercept(event);
			this.ngDropdown.open();
			setTimeout(() => {
				if (this.searchInput) {
					this.searchInput.nativeElement.focus();
				} else {
					let firstDropdownItem = this.ngDropdownItems.first?.elementRef.nativeElement;
					if (firstDropdownItem) {
						firstDropdownItem.focus();
					}
				}
			});
		}
	};

	onDropdownItemKeydown = (event: any, suggestion: TokenSuggestion): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)
			|| KeyboardUtils.isEventKey(event, Key.SPACE)
			|| KeyboardUtils.isEventKey(event, Key.RIGHT)) {
				KeyboardUtils.intercept(event);
				this.handleSuggestionClick(suggestion);
				if (this.showSubmenuArrow(suggestion)) {
					setTimeout(() => {
						$(this.elementRef.nativeElement).find('.aggregations-submenu > li.active .math-aggregation').trigger('focus');
					});
				}
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			KeyboardUtils.intercept(event);
			this.closeAndFocusDropdown();
		}
	};

	onSearchKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			KeyboardUtils.intercept(event);
			this.closeAndFocusDropdown();
		}
	};

	private closeAndFocusDropdown = (): void => {
		this.ngDropdown.close();
		this.resetAttributeSuggestion();
		let dropdownToggle: HTMLElement = this.ngDropdownToggle.nativeElement;
		dropdownToggle.focus();
	};

	private isFirstItemFocused = (): boolean => {
		return $(this.listItems.nativeElement).find(':focusable').index(document.activeElement) === 0;
	};

	handleKeyboardNavigation = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)	&& this.isFirstItemFocused()) {
			KeyboardUtils.intercept(event);
			this.closeAndFocusDropdown();
		} else {
			KeyboardUtils.handleUpDownNavigation(event, this.listItems.nativeElement);
		}
	};

	onSuggestionsDropdownScroll(event: any): void {
		event.stopPropagation();
		this.resetAttributeSuggestion();
	}

}