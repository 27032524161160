import * as uuid from 'uuid';
import { catchError, map } from 'rxjs/operators';
import { Observable, of, Subscription } from 'rxjs';
import { Component, ChangeDetectionStrategy, Input, OnDestroy, ChangeDetectorRef, OnChanges, ViewChild, OnInit } from '@angular/core';

import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { Clipboard } from '@angular/cdk/clipboard';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { CoachingAssistantApiService, CoachingTipsRequestBody } from '@app/modules/document-explorer/context-pane/explorer-coaching-assistant/coaching-assistant-api.service';
import { NgbAccordion } from '@ng-bootstrap/ng-bootstrap';
import { AutomatedSummariesUtils } from '../explorer-automated-summaries/automated-summaries-utils.class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { LocalStorageService } from 'angular-2-local-storage';

export interface SavedTipsState {
	tips: string;
	feedbackGiven: boolean;
}

const PANEL_ID = 'coachingAssistantPanel';

@Component({
	selector: 'explorer-coaching-assistant',
	templateUrl: './explorer-coaching-assistant.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		::ng-deep .coaching-assistant-panel .ng-busy > *,
		::ng-deep .coaching-assistant-panel .ng-busy > ng-component > *,
		::ng-deep .coaching-assistant-panel .ng-busy {
			position: relative !important;
		}
		::ng-deep .coaching-assistant-panel lib-ng-busy {
			display: flex !important;
			position: absolute !important;
		}
	`]
})

export class ExplorerCoachingAssistantComponent implements OnDestroy, OnChanges, OnInit {
	@Input() document: ConversationDocument;
	@Input() project: ProjectIdentifier;
	@ViewChild('acc') acc?: NgbAccordion;

	coachingTips$: Observable<string>;
	isLoading: boolean = false;
	feedbackGiven: boolean = false;
	coachingTipsSub: Subscription;
	storedTips: string;
	isAutomatedSummariesEnabled: boolean;


	constructor(
		private readonly coachingAssistantApiService: CoachingAssistantApiService,
		private readonly clipboard: Clipboard,
		private readonly dialogService: CxDialogService,
		private readonly ref: ChangeDetectorRef,
		private readonly betaFeaturesService: BetaFeaturesService,
		private localStorageService: LocalStorageService
	) {}

	ngOnInit(): void {
		this.isAutomatedSummariesEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_SUMMARIES);
		this.loadSavedTipsState();
	}

	ngOnDestroy(): void {
		if (this.coachingTipsSub) {
			this.coachingTipsSub.unsubscribe();
		}
	}

	ngOnChanges(changes: SimpleChanges<ExplorerCoachingAssistantComponent>): void {
		if (ChangeUtils.hasChange(changes.document)) {
			this.coachingTipsSub?.unsubscribe();
			this.isLoading = false;
			this.acc?.collapse(PANEL_ID);
			this.loadSavedTipsState();
		}
	}

	generateCoachingTips(): void {
		this.requestCoachingTips();
		this.trackGenerationEvent(AmplitudeEvent.COACHING_ASSISTANT_GENERATE);
	}

	regenerateCoachingTips(): void {
		this.requestCoachingTips();
		this.trackGenerationEvent(AmplitudeEvent.COACHING_ASSISTANT_REGENERATE);
	}

	requestCoachingTips(): void {
		if (!this.document || !this.project) return;
		this.isLoading = true;

		const body = this.generateCoachingTipsRequestBody();

		this.coachingTips$ = this.coachingAssistantApiService
			.generateCoachingTips(body)
			.pipe(
				map(({ tips }) => {
					this.isLoading = false;
					this.storedTips = tips;
					this.feedbackGiven = false;
					this.saveTipsState();
					return tips;
				}),
				catchError((err) => {
					this.isLoading = false;
					this.loadSavedTipsState();

					if (this.acc && !this.storedTips) {
						this.acc.collapse(PANEL_ID);
					}

					return of(this.storedTips);
				})
			);

		this.coachingTipsSub = this.coachingTips$.subscribe();
	}

	generateCoachingTipsRequestBody(): CoachingTipsRequestBody {
		const conversationPhrases = AutomatedSummariesUtils.getConversationPhrases(this.document);
		const conversationMetadata: CoachingTipsRequestBody = {
			language: this.document.sentences[0]?.attributes?._languagedetected?.[0] || 'en',
			sourceType: this.document.sentences[0]?.attributes?.cb_interaction_type?.[0] || 'voice',
			phrases: conversationPhrases,
			uniqueDocumentIdentifier: uuid.v4(),
			projectId: this.project.projectId.toString(),
		};
		return conversationMetadata;
	}

	trackGenerationEvent(eventName: AmplitudeEvent) {
		AmplitudeAnalyticsService.trackEvent(
			eventName,
			{ documentId: this.document.id },
			{
				naturalId: this.document.natural_id,
				projectId: this.project.projectId
			}
		);
	}

	sendCoachingFeedback(feedbackRating: number): void {
		this.feedbackGiven = true;
		this.saveTipsState();

		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.COACHING_ASSISTANT_RATING,
			{ documentId: this.document.id },
			{
				rating: feedbackRating,
				naturalId: this.document.natural_id,
				projectId: this.project.projectId
			}
		);
	}

	copyCoachingTips(): void {
		this.coachingTips$.subscribe((tips) => {
			this.clipboard.copy(tips);
		});
	}

	getTipsStorageKey(): string {
		const projectId = this.project.projectId;
		const documentId = this.document.id;
		return `coaching-tips:${projectId}:${documentId}`;
	}

	saveTipsState(): void {
		const state: SavedTipsState = {
			tips: this.storedTips,
			feedbackGiven: this.feedbackGiven
		};
		this.localStorageService.set(this.getTipsStorageKey(), state);
	}

	loadSavedTipsState(): void {
		const savedTips = this.localStorageService.get<SavedTipsState>(this.getTipsStorageKey());
		if (savedTips) {
			this.coachingTips$ = of(savedTips.tips);
			this.storedTips = savedTips.tips;
			this.feedbackGiven = savedTips.feedbackGiven;
		}
		else {
			this.coachingTips$ = null;
			this.storedTips = undefined;
			this.feedbackGiven = false;
		}
	}
}
