import { Component, ChangeDetectionStrategy, OnInit, Input, Inject, ViewChild, ChangeDetectorRef } from '@angular/core';
import { AdminContentProvider } from '@app/modules/system-administration/content-provider/admin-content-provider';
import { CxLocaleService } from '@app/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { RequestConfigTimeout } from '@cxstudio/common/content-provider-timeouts/request-config-timeout';
import { RequestMethodTimeout } from '@cxstudio/common/content-provider-timeouts/request-method-timeout';
import { RequestDomainTimeout } from '@cxstudio/common/content-provider-timeouts/request-domain-timeout';
import { NgForm } from '@angular/forms';
import { ContentProviderApi, ContentProviderOAuthSettingsResponse } from '@app/modules/system-administration/content-provider/content-provider.api.service';
import { BrokerType } from '@app/modules/system-administration/content-provider/content-provider-server-context';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';

export enum ContentProviderDialogAction {
	ADD = 'add',
	EDIT = 'edit'
}

@Component({
	selector: 'content-provider-edit-dialog',
	templateUrl: './content-provider-edit-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentProviderEditDialogComponent implements OnInit {

	@Input() cpData: AdminContentProvider;
	@Input() action: ContentProviderDialogAction;

	@ViewChild('cpDialog', {static: false}) cpDialog: NgForm;


	cp: AdminContentProvider;

	loading: any;

	cpTypeOptions: string[];
	requestConfigTimeouts: string[];
	requestMethodTimeouts: string[];
	requestDomainTimeouts: string[];

	versionError: string = '';
	showErrorsForCP: string[] = [];
	timeoutErrors: string[] = [];
	authErrors: string[] = [];

	ddaFormValid: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private contentProviderApi: ContentProviderApi,
		private readonly contentProviderManagementApiService: ContentProviderManagementApiService,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService,
	) {}

	ngOnInit(): void {
		this.cpTypeOptions = [ 'CB', 'MEDIA' ];
		this.requestConfigTimeouts = Object.keys(RequestConfigTimeout);
		this.requestMethodTimeouts = Object.keys(RequestMethodTimeout);
		this.requestDomainTimeouts = Object.keys(RequestDomainTimeout);

		this.cp = Object.assign({}, this.cpData);
		_.defaults(this.cp, this.getDefaults());

		if (this.action === ContentProviderDialogAction.EDIT) {
			if (this.cp.username) {
				// do not set fake password if there is no username
				this.cp.password = 'password';
			}
			this.versionError = '';

			this.loading = this.contentProviderApi.getContentProviderServerContext(this.cp.id).then((resp) => {
				if (resp.serverVersion) {
					if (this.cp.cpVersion !== resp.serverVersion) {
						this.cp.cpVersion = resp.serverVersion;
						let cpData = Object.assign({}, this.cp);
						delete cpData.password;
						cpData.id = this.cp.id;
						this.contentProviderManagementApiService.upgradeContentProvider(cpData).then(_.noop, (errorResp) => {
							this.versionError = errorResp.data.Error;
						});
					}
				}
				this.cp.apiVersion = resp.apiVersion;
				this.cp.featureVersion = resp.featureVersion;
				if (resp.messagingConfig?.implementation === BrokerType.KAFKA) {
					this.cp.kafkaBroker = resp.messagingConfig.connectionInfo['kafka.bootstrap.servers'];
				}
				this.validate();
			}, (error) => {
				this.versionError = error;
				this.validate();
			});
		}

	}

	private getDefaults(): AdminContentProvider {
		return {
			name: '',
			username: '',
			password: '',
			endPoint: '',
			type: 'CB',
			alertListeners: [],
			timeouts: {
				configTimeouts: {},
				methodTimeouts: {},
				domainTimeouts: {}
			},
			authSettings: {},
			ddaSettings: {}
		};
	}

	validate = () => {
		if (this.cp.authSettings.enabled && (this.cp.authSettings.scope === undefined
			|| this.cp.authSettings.scope.length === 0)) {
			this.cpDialog.form.controls['tags'].setErrors({noScopes: true});
		} else {
			this.cpDialog.form.controls['tags'].setErrors(undefined);
		}

	};

	save = () => {
		if (!this.validateTimeouts()) {
			return;
		}

		let cpData = Object.assign({}, this.cp);
		if (this.action === ContentProviderDialogAction.EDIT) {
			if (!this.cpDialog.form.controls.password.dirty) {
				delete cpData.password;
			}
			cpData.id = this.cp.id;
		}
		this.showErrorsForCP = [];
		this.contentProviderOptionsService.validateCP(cpData, this.action).then((data) => {
			if (data.valid) {
				this.modal.close(cpData);
			} else {
				let errorMessages = this.getErrorMessages(data);
				this.showErrorsForCP.pushAll(errorMessages);
				this.ref.markForCheck();
			}
		});
	};

	cancel = (): void => {
		this.modal.dismiss();
	};

	private getErrorMessages(data: string[]): string[] {
		let errorMessages = [];
		data.forEach((errorMsg) => {
			if (errorMsg.contains('\n')) {
				errorMessages.pushAll(errorMsg.split('\n'));
			} else {
				errorMessages.push(errorMsg);
			}
		});
		return errorMessages;
	}

	importSettings = () => {
		this.loading = this.contentProviderApi.getContentProviderOAuthSettings(this.cp.id).then(
			(response: ContentProviderOAuthSettingsResponse) => {
				this.cp.authSettings.clientId = response.clientId;
				this.cp.authSettings.redirectUri = response.redirectUri;
				this.cp.authSettings.scope = response.scope;
		}, (error) => {
			this.cxDialogService.notify(
				this.locale.getString('common.warning'),
				this.locale.getString('administration.contentProviderImportSettingsError', {errorMessage: error.data}),
				{keyboard: true});
		});
	};

	private validateTimeouts(): boolean {
		this.timeoutErrors = [];
		this.validateTimeoutsNamed(this.requestConfigTimeouts);
		this.validateTimeoutsNamed(this.requestMethodTimeouts);
		this.validateTimeoutsNamed(this.requestDomainTimeouts);

		return this.timeoutErrors.length === 0;
	}

	private validateTimeoutsNamed(timeouts): void {
		timeouts.forEach((timeout) => {
			this.validateTimeout(timeout);
		});
	}

	private validateTimeout(timeout): void {
		let timeoutInputValidator = this.cpDialog.form.controls[timeout];
		if (timeoutInputValidator && timeoutInputValidator.invalid) {
			this.timeoutErrors.push(this.locale.getString('administration.invalidTimeoutMessage', { timeout }));
		}
	}

	updateDdaFormValidity(valid: boolean): void {
		this.ddaFormValid = valid;
	}
}
