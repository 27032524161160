import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ISettingsEditor } from '@app/modules/asset-management/entities/settings-editor.interface';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalBindings } from '@app/modules/modal/modal-bindings';

export interface IConfigurationDialogInput {
	modalSettings: {
		defaults: boolean;
		itemName: string;
		tabs: IConfigurationTab[];
	};
}

export interface IConfigurationTab {
	key: string;
	name?: string;
	editor: ISettingsEditor<any>;
}

@Component({
	selector: 'configuration-modal',
	templateUrl: './configuration-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationModalComponent extends ModalBindings<IConfigurationDialogInput> implements OnInit {

	modalTitle: string;
	tabs: IConfigurationTab[];

	constructor(
		private readonly locale: CxLocaleService,
		modal: NgbActiveModal,
	) {
		super(modal);
	}

	ngOnInit(): void {
		let modalSettings = this.input.modalSettings;

		this.tabs = modalSettings.tabs;
		let titleKey = modalSettings.defaults
			? 'administration.defaultSettingsModalTitle'
			: 'widget.settingsModalTitle';
		this.modalTitle = this.locale.getString(titleKey, {itemName: modalSettings.itemName});
	}

	submit = (): void => {
		let result = {};

		_.each(this.tabs, tab => {
			result[tab.key] = tab.editor.getSettings();
		});

		super.close(result);
	};
}
