import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { SamlIdentityProvider } from '@app/modules/system-administration/saml-settings/entities/saml-identity-provider';
import { SamlIdentityProvidersComponent } from '@app/modules/system-administration/saml-settings/saml-identity-providers/saml-identity-providers.component';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable()
export class SamlIdentityProvidersGridDefinition implements IGridDefinition<SamlIdentityProvidersComponent> {

	constructor(
		private locale: CxLocaleService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<span class="q-icon-layer"></span>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 40,
			width: 60,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'aliasName',
			name: this.locale.getString('administration.aliasName'),
			field: 'aliasName',
			sortable: false,
			minWidth: 50,
			width: 80,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.IDENTITY_PROVIDERS),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'entityId',
			name: this.locale.getString('administration.entityId'),
			field: 'entityId',
			sortable: false,
			minWidth: 160,
			width: 700,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.IDENTITY_PROVIDERS),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'linkedMasterAccounts',
			name: this.locale.getString('administration.linkedMasterAccounts'),
			field: 'linkedMasterAccounts',
			sortable: false,
			minWidth: 100,
			width: 200,
			cssClass: 'br-master-accounts',
			searchable: true,
			formatter: this.gridFormatterService.ArrayToStringFormatter,
			defaultSortColumn: SortDirection.ASC
		}, {
			id: 'metadataFilename',
			name: this.locale.getString('administration.metadataFile'),
			field: 'metadataFilename',
			sortable: false,
			minWidth: 80,
			width: 100,
			cssClass: 'br-metadataFile',
			searchable: false,
			defaultSortColumn: SortDirection.ASC,
			formatter: this.getDownloadFileFormatter((identityProvider: SamlIdentityProvider) => identityProvider.metadataFilename)
		}, {
			id: 'createdDate',
			name: this.locale.getString('dashboard.createdDate'),
			field: 'createdDate',
			sortable: false,
			optional: true,
			minWidth: 50,
			width: 80,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}, {
			id: 'lastModifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'lastModifiedDate',
			sortable: false,
			optional: true,
			minWidth: 50,
			width: 80,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}];

		return Promise.resolve(columns);
	};

	getDownloadFileFormatter = (fileNameResolver: any): (...args: any) => string => {
		return (row, cell, value, columnDef, dataContext) => {
			return `<a href='javascript:void(0)'>${fileNameResolver(dataContext)}</a>`;
		};
	};

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: SamlIdentityProvider) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.entityId);
		return getSwitch(value);
	};
}

app.service('samlIdentityProvidersGridDefinition', downgradeInjectable(SamlIdentityProvidersGridDefinition));
