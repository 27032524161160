import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { DocumentExplorerPageParams } from '@app/modules/document-explorer/document-explorer-page/document-explorer-page-params';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { FilterRuleType } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { DocumentLinkService } from '@cxstudio/reports/document-explorer/document-link.service';
import { IFilterRule, IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { PreviewService } from '@cxstudio/reports/preview/preview-service';
import { FullPageDocumentExplorerService, FULL_PAGE_CONTAINER_ID } from './full-page-document-explorer.service';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { Breadcrumb } from '@app/modules/breadcrumb/breadcrumbs.component';

@Component({
	selector: 'document-explorer-page',
	template: `
<document-explorer-container *ngIf="widget"
	[ngBusy]="loading"
	class="d-flex h-100-percent w-100-percent"
	[isFullPage]="true"
	[header]="header"
	[widget]="widget">
</document-explorer-container>
<ng-template #header let-parts>
	<h2 class="header-color-2 header-shadow d-flex pv-8 ph-16 m-0 border-b-1 border-b-solid border-gray-300">
		<breadcrumbs [breadcrumbs]="getBreadcrumbs()" class="align-self-center"></breadcrumbs>
		<div class="ml-auto d-flex align-items-center">
			<ng-container *ngTemplateOutlet="parts.menu"></ng-container>
		</div>
	</h2>
</ng-template>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerPageComponent implements OnInit, OnDestroy {

	private readonly INTERACTIONS_PATH: string = '/interactions';

	widget: DocumentExplorerWidget;
	loading: Promise<any>;
	breadcrumbs: Breadcrumb[];

	constructor(
		private readonly cxLocation: CxLocationService,
		private readonly fullPageExplorerService: FullPageDocumentExplorerService,
		private readonly locale: CxLocaleService,
		@Inject('previewService') private readonly previewService: PreviewService,
		@Inject('security') private readonly security: Security,
		@Inject('documentLinkService') private readonly documentLinkService: DocumentLinkService,
		@Inject('currentWidgets') private readonly currentWidgets: ICurrentWidgets,
		private readonly projectAccessService: ProjectAccessService,
		private readonly ref: ChangeDetectorRef,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly sessionPreferences: SessionPreferencesService,

	) { }

	ngOnInit(): void {
		let urlBased = this.processUrlParams();
		if (!urlBased) {
			this.widget = this.fullPageExplorerService.getCurrentWidget();
			this.currentWidgets.getDashboardContainer(FULL_PAGE_CONTAINER_ID)
				.setDashboardHistory(this.fullPageExplorerService.getDashboardFilters());

			this.breadcrumbs = [
				{
					display: this.fullPageExplorerService.getReturnLabel(),
					href: this.fullPageExplorerService.getReturnPath()
				}
			];

			this.checkSecurityAndSetFull();
		}
	}

	ngOnDestroy(): void {
		this.fullPageExplorerService.clear();
	}

	checkSecurityAndSetFull = (): void => {
		this.security.restrictPage(() => {
			return this.widget;
		}, this.INTERACTIONS_PATH);

		this.fullPageExplorerService.setFullPage(true);
	};

	private processUrlParams(): boolean {
		let pageParams: DocumentExplorerPageParams;

		if (this.cxLocation.search().documentLink) {
			this.loading = PromiseUtils.wrap(this.documentLinkService.decodeDocumentLink(this.cxLocation.search().documentLink))
				.then(decodedLink => {
					const project = {
						contentProviderId: decodedLink.link.contentProviderId,
						accountId: decodedLink.link.accountId,
						projectId: decodedLink.link.projectId
					};

					pageParams = new DocumentExplorerPageParams(
						project,
						decodedLink.link.documents
					);

					this.widget = this.generateDocExplorerWidget(pageParams, decodedLink.encodedLink);
					this.setDocumentExplorerName(pageParams);
					this.setBreadcrumbs([
						{
							display: this.locale.getString('interactionExplorer.title'),
							href: this.INTERACTIONS_PATH
						}
					]).then(() => {
						this.ref.detectChanges();
						this.checkSecurityAndSetFull();
					});
				}, _.noop);

			return true;
		}

		pageParams = DocumentExplorerPageParams.fromSearchParams(this.cxLocation.search());

		if (!pageParams) {
			return false;
		}

		this.widget = this.generateDocExplorerWidget(pageParams);
		this.setDocumentExplorerName(pageParams);
		this.setBreadcrumbs(
			!pageParams.redirectTo ? [] : [
				{
					display: this.locale.getString('interactionExplorer.title'),
					href: pageParams.redirectTo
				}
			]
		).then(() => {
			this.checkSecurityAndSetFull();
		});

		return true;
	}

	private generateDocExplorerWidget(params: DocumentExplorerPageParams, encodedDescriptor?: string): DocumentExplorerWidget {
		let widgetSettings = this.previewService.createDocumentExplorerSettings() as DocumentExplorerWidget;
		widgetSettings.selectivePreview = true;
		widgetSettings.properties.encodedDescriptor = encodedDescriptor;

		widgetSettings.properties.contentProviderId = params.contentProviderId;
		widgetSettings.properties.accountId = params.accountId;
		widgetSettings.properties.project = params.projectId;

		let filterValues = [].concat(params.documentIds).map(id => ({text: id + ''} as IFilterRuleValue));
		widgetSettings.properties.adhocFilter = {
			type: FilterTypes.AND,
			filterRules: [{
				attributeDisplayName: this.locale.getString('widget.exportDocumentId'),
				attributeName: '_id_document',
				displayName: 'Document Id',
				type: FilterRuleType.stringEquality,
				matchMode: FilterMatchModeValue.IS,
				values: filterValues
			} as IFilterRule]
		};
		return widgetSettings;
	}

	private setDocumentExplorerName(pageParams: DocumentExplorerPageParams) {
		if (pageParams.documentIds?.length === 1) {
			this.widget.documentExplorerName = this.locale.getString(
				'widget.documentIdentifier',
				{ documentIdentifier: pageParams.documentIds[0] }
			);
		} else {
			this.widget.documentExplorerName = this.locale.getString('widget.docExplorer');
		}
	}

	private async setBreadcrumbs(breadcrumbs: Breadcrumb[]): Promise<void> {
		if (breadcrumbs.find((crumb) => crumb.href === this.INTERACTIONS_PATH)) {
			const project = this.reportAssetUtilsService.getWidgetProject(this.widget);

			const hasAccess = await this.projectAccessService.hasProjectAccess(project);
			if (!hasAccess) {
				this.breadcrumbs = [];

				return;
			}

			this.sessionPreferences.setRecentProject(this.security.getMasterAccountId(), project);
		}

		this.breadcrumbs = breadcrumbs;
	}

	public getBreadcrumbs() {
		return [
			...this.breadcrumbs,
			{ display: this.widget.documentExplorerDrillPointDisplayName ?? this.widget.documentExplorerName }
		];
	}
}

app.directive('documentExplorerPage', downgradeComponent({component: DocumentExplorerPageComponent}));
