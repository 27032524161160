import * as _ from 'underscore';

import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Label } from '@cxstudio/dashboards/entity/label';


export class LabelsApiService {
	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers) {  }

	getLabels = (query: string): ng.IPromise<Label[]> => {
		return this.$http.get('rest/labels', {
			params: { query },
			cache: false
		}).then(this.httpHandlers.success);
	};

	getAllLabels = (): ng.IPromise<Label[]> => {
		return this.$http.get('rest/labels/all', {
			cache: false
		}).then(this.httpHandlers.success);
	};

}

app.service('labelsApiService', LabelsApiService);
