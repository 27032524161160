import ILocale from '@cxstudio/interfaces/locale-interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { IDrillToHelper, IDrillTargetType, ILimitedSupportDrillTargetType } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { AnalyticDrillUtils } from './analytic-drill-utils';
import { DrillWidgetType } from './drill-widget-type';
import { IDrillMenuOptionGroup } from '../drill-menu-option';

app.factory('DrillTo', (locale: ILocale, analyticDrillUtils: AnalyticDrillUtils) => {

	return class implements IDrillToHelper {

		BAR: ILimitedSupportDrillTargetType;
		COLUMN: ILimitedSupportDrillTargetType;
		LINE: ILimitedSupportDrillTargetType;
		DOT: ILimitedSupportDrillTargetType;
		CLOUD: ILimitedSupportDrillTargetType;
		TABLE: ILimitedSupportDrillTargetType;
		HEATMAP: ILimitedSupportDrillTargetType;
		METRIC: ILimitedSupportDrillTargetType;
		PIE: ILimitedSupportDrillTargetType;
		SCATTER: ILimitedSupportDrillTargetType;
		AN_PREVIEW: IDrillTargetType;
		AN_DOC_EXPLORER: IDrillTargetType;
		FULL_PAGE_DOC_EXPLORER: IDrillTargetType;
		RECOLOR: IDrillTargetType;
		METRIC_ALERT: IDrillTargetType;
		KEY_TERMS: ILimitedSupportDrillTargetType;
		enabledWidgets: IDrillTargetType[];

		constructor(
			private readonly widget,
			private readonly menuActions,
			private readonly defaultColor
		) {
			this.BAR = {
				obj: 'bar',
				text: locale.getString('widget.bar'),
				type: WidgetType.BAR,
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.COLUMN = {
				obj: 'column',
				text: locale.getString('widget.column'),
				type: WidgetType.BAR,
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.LINE = {
				obj: 'line',
				text: locale.getString('widget.line'),
				type: WidgetType.LINE,
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.DOT = {
				obj: 'dot',
				text: locale.getString('widget.dot'),
				type: WidgetType.LINE,
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.CLOUD = {
				obj: 'cloud',
				text: locale.getString('widget.cloud'),
				type: WidgetType.CLOUD,
				supportedTypes: [DrillWidgetType.DEFAULT],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.TABLE = {
				obj: 'table',
				text: locale.getString('widget.table'),
				type: WidgetType.TABLE,
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.HEATMAP = {
				obj: 'heatmap',
				text: locale.getString('widget.heatmap'),
				type: WidgetType.HEATMAP,
				supportedTypes: [DrillWidgetType.DEFAULT],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.METRIC = {
				obj: 'metric',
				text: locale.getString('widget.metric'),
				type: WidgetType.METRIC,
				supportedTypes: [DrillWidgetType.NUMERIC],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.PIE = {
				obj: 'pie',
				text: locale.getString('widget.pie'),
				type: WidgetType.PIE,
				supportedTypes: [DrillWidgetType.DEFAULT],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.SCATTER = {
				obj: 'scatter',
				text: locale.getString('widget.scatter'),
				type: WidgetType.SCATTER,
				supportedTypes: [DrillWidgetType.DEFAULT],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.enabledWidgets = [
				this.BAR,
				this.COLUMN,
				this.LINE,
				this.DOT,
				this.CLOUD,
				this.TABLE,
				this.HEATMAP,
				this.METRIC,
				this.PIE,
				this.SCATTER
			];


			this.AN_PREVIEW = {
				obj: 'an_preview',
				name: 'an_feedback',
				type: WidgetType.PREVIEW,
				text: locale.getString('widget.feedbackPreview'),
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};

			this.AN_DOC_EXPLORER = {
				obj: 'an_doc_explorer',
				name: 'an_doc_explorer',
				text: locale.getString('widget.docExplorer'),
				func: this.menuActions.openAnDocumentExplorer(this.drillToFunction)
			};

			this.FULL_PAGE_DOC_EXPLORER = {
				obj: 'full_page_doc_explorer',
				name: 'full_page_doc_explorer',
				text: locale.getString('widget.openFullPage'),
				func: this.menuActions.openAnDocumentExplorer(this.drillToFunction, true)
			};

			this.RECOLOR = {
				obj: 'recolor',
				name: 'recolor',
				group: IDrillMenuOptionGroup.recolor,
				text: locale.getString('widget.recolor'),
				func: this.menuActions.applyRecolor,
				levels: 2 // color picker is wide, so reserve space like 2 drill columns
			};

			this.METRIC_ALERT = {
				obj: 'metricAlert',
				name: 'metricAlert',
				group: IDrillMenuOptionGroup.alert,
				text: locale.getString('widget.alertMe')
			};

			this.KEY_TERMS = {
				obj: 'key_terms',
				name: 'key_terms',
				group: IDrillMenuOptionGroup.drill,
				text: locale.getString('widget.keyTerms'),
				supportedTypes: [DrillWidgetType.DEFAULT, DrillWidgetType.TREND],
				func: this.menuActions.applyDrillWidget(this.drillToFunction)
			};
		}

		drillToFunction = (point, drillTo) => {
			let includeLinkedFilters = true;
			return analyticDrillUtils.createDrillWidget(this.widget, point, drillTo, includeLinkedFilters, this.defaultColor);
		};

		getDrillTo = (type, defaultWidgetType) => {
			let drillToOptions = _.filter(this.enabledWidgets, (widgetType) => {
				if (_.isArray(type)) // drill to numeric attribute supports both default and numeric widgets
					return !_.isEmpty(_.intersection((widgetType as ILimitedSupportDrillTargetType).supportedTypes, type));
				else return _.contains((widgetType as ILimitedSupportDrillTargetType).supportedTypes, type);
			});
			let defaultType = _.findWhere(drillToOptions, {type: defaultWidgetType}) || drillToOptions[0];
			if (defaultType)
				defaultType.default = true;
			return drillToOptions;
		};

		getDrillToANPreview = () => this.AN_PREVIEW;

		getDrillToAnDocExplorer = () => this.AN_DOC_EXPLORER;

		getDrillToRecolor = () => this.RECOLOR;

		getDrillToFullPageDocExplorer = () => this.FULL_PAGE_DOC_EXPLORER;

		getDrillToKeyTerms = () => this.KEY_TERMS;

		getDrillToMetricAlert = (disabled, callback) => {
			let option = {...this.METRIC_ALERT, ...{func: callback}};

			return disabled ?
				{...option,
					...{
						disabled: true,
						label: locale.getString('widget.alertMeDisabled')
					}
				} :
				option;
		};
	};
});
