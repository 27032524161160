import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';

import { ObjectListSort } from './object-list-sort';
import { ListOption } from '../forms/list-option';
import { CdkDragDrop } from '@angular/cdk/drag-drop';


@Component({
	selector: 'sort-lanes',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div *ngIf="lanes">
			<div cdkDropList
			[cdkDropListData]="lanes"
			(cdkDropListDropped)="onDrop($event)">
				<div *ngFor="let lane of lanes; let index = index"
					cdkDrag
					[cdkDragDisabled]="!draggingAllowed()"
					class="filter-statement objects-sort-statement w-100-percent d-flex align-items-center p-relative hide-drag-preview">

					<cb-drag-handle *ngIf="draggingAllowed()" cdkDragHandle></cb-drag-handle>

					<sort-lane
						class="flex-fill ml-4"
						[sort]="lane"
						[columns]="getAvailableColumns(index)"
						(sortChange)="onSortChange(index, $event)"
					></sort-lane>

					<div class="filter-tools">
						<button
							class="btn btn-icon"
							(click)="removeLane(index)">
							<span aria-hidden="true" class="q-icon q-icon-minus"></span>
						</button>
						<button
							class="btn btn-icon ml-0"
							[disabled]="!canAddLane()"
							(click)="addLane(index)">
							<span aria-hidden="true" class="q-icon q-icon-add"></span>
						</button>
					</div>
				</div>
			</div>
		</div>
	`
})
export class SortLanesComponent implements OnInit {

	readonly MAX_LANES = 3;

	_columns: Array<ListOption<string>>;
	@Input() defaultSort: ObjectListSort;
	@Input() maxLanes?: number;
	@Output() sortsChange = new EventEmitter<ObjectListSort[]>();
	@Output() forceApply = new EventEmitter<void>();

	lanes: ObjectListSort[] = [];

	constructor(
	) {}

	ngOnInit(): void {
		if (!this.maxLanes)
			this.maxLanes = this.MAX_LANES;
		this.addDefaultSort();
		if (this.maxLanes > 1) {
			this.addLane(0);
		}
		this.onChange();
	}

	@Input() set columns(columns: Array<ListOption<string>>) {
		if (columns && columns.length) {
			this._columns = columns;

			if (this.lanes && this.lanes.length) {
				this.lanes = this.lanes.filter(lane => _.findWhere(columns, { value: lane.field }));

				if (this.lanes.length === 0) {
					this.addDefaultSort();
				}
				this.onChange();
				this.forceApply.emit();
			}
		}
	}

	private addDefaultSort = (): void => {
		this.lanes.push(Object.assign({}, this.defaultSort));
	};

	getAvailableColumns = (index: number): Array<ListOption<string>> => {
		let selectedColumnNames = this.lanes
			.filter((lane, i) => index !== i)
			.map((lane, i) => lane.field);

		return this._columns.filter(column => !selectedColumnNames.contains(column.value));
	};

	private onChange = (): void => {
		this.sortsChange.emit(this.lanes);
	};

	canAddLane = (): boolean => {
		return this.lanes.length < this.maxLanes;
	};

	addLane = (index: number): void => {
		this.lanes.insert(index + 1, {} as ObjectListSort);
		this.onChange();
	};

	removeLane = (index: number): void => {
		this.lanes.removeAt(index);
		if (this.lanes.length === 0) {
			this.addDefaultSort();
		}
		this.onChange();
	};

	onSortChange = (index: number, sort: ObjectListSort): void => {
		this.lanes[index] = sort;
		this.onChange();
	};

	onDrop = (event: CdkDragDrop<ObjectListSort[]>) => {
		event.container.data.move(event.previousIndex, event.currentIndex);
		this.onChange();
	};

	draggingAllowed = (): boolean => {
		return this.lanes.length > 1;
	};

}
