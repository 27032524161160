import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DatePeriod } from '@cxstudio/reports/entities/date-period';

import * as _ from 'underscore';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';

export class DatePeriodUtils {

	constructor(
		private DateRange: any
	) {

	}
	initializePeriods = (props: WidgetProperties, periods: DatePeriod[], force?: boolean, callback?: () => void) => {
		_.each(periods, period => {
			if (force || _.isUndefined(props[period.field])) {
				let periodDefaultMode: DateFilterMode = period.defaultMode || this.DateRange.defaultDateRange.value;
				props[period.field] = {
					dateFilterMode: periodDefaultMode
				};
			}
			let periodProperty = props[period.field];

			period.dateFilterMode = periodProperty.dateFilterMode;
			if (periodProperty.from || periodProperty.to) {
				period.dateFilterRange = {
					from: periodProperty.from,
					to: periodProperty.to
				};
			}
			this.setDateModeSelection(props, period, callback);
		});
	};

	private setDateModeSelection = (props: WidgetProperties, period: DatePeriod, callback?: () => void) => {
		let periodProperty = props[period.field];
		period.dateModeSelection = (dateFilterMode, dateFilterRange, dateDisplayName) => {
			if (dateDisplayName) {
				periodProperty.dateDisplayName = dateDisplayName;
			}
			periodProperty.dateFilterMode = dateFilterMode;
			if (dateFilterRange) {
				periodProperty.from =  dateFilterRange.from;
				periodProperty.to = dateFilterRange.to;
			}
			if (callback)
				callback();
		};
	};

	processDateFilters = (filters: IFilter[], selectedFilters?: string[]): UIOption<string>[] => {
		return _.chain(filters)
			.filter(filter => {
				return !filter.hide || _.contains(selectedFilters, 'customFilter:' + filter.id);
			}).map(filter => {
				return {
					value: 'customFilter:' + filter.id,
					displayName: filter.name
				};
			}).value();
	};
}

app.service('datePeriodUtils', DatePeriodUtils);
