import { Component, OnInit, ChangeDetectionStrategy, Inject, Input } from '@angular/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { AssetEditDialogService } from '../services/asset-edit-dialog.service';
import { RedirectService } from '@cxstudio/services/redirect-service';

export enum AssetType {
	METRIC = 'metric',
	FILTER = 'filter',
	DRIVER = 'driver'
}

/**
 * This component was created only for reason of verification of the assetEditDialogService.
 */
@Component({
	selector: 'asset-editor',
	template: `<div class="w-100-percent h-100-percent" [ngBusy]="loading"></div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetEditorComponent implements OnInit {
	@Input() assetId: number;
	@Input() assetType: AssetType;

	loading: Promise<any>;

	constructor(
		private dialogService: CxDialogService,
		private assetEditDialogService: AssetEditDialogService,
		@Inject('redirectService') private redirectService: RedirectService,
	) { }

	ngOnInit(): void {
		this.openEditDialog(this.assetId, this.assetType)
			.then(() => this.redirectService.goToDashboardList());
	}

	private openEditDialog(assetId: number, assetType: AssetType): Promise<any> {
		if (assetType === AssetType.METRIC) {
			return this.assetEditDialogService.openEditMetricDialog(assetId);
		} else if (assetType === AssetType.FILTER) {
			return this.assetEditDialogService.openEditFilterDialog(assetId);
		} else if (assetType === AssetType.DRIVER) {
			return this.assetEditDialogService.openEditDriverDialog(assetId);
		} else {
			return this.openWarningDialog(assetType);
		}
	}

	private openWarningDialog(assetType: AssetType): Promise<any> {
		return this.dialogService.warning('Edit asset', 'Invalid asset type:' + assetType).result;
	}
}

app.directive('assetEditor', downgradeComponent({component: AssetEditorComponent}));
