import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { UpgradeApiService, IUpgradeComponent, IUpgradeState} from '../upgrade-api.service';

@Component({
	selector: 'upgrade-tab',
	templateUrl: './upgrade-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class UpgradeTabComponent implements OnInit {

	readonly PREVIOUS_SESSIONS_DISPLAY_LIMIT = 10;
	readonly DEFAULT_RELOAD_TIMEOUT = 5000;

	upgrade: {
		componentSearchVersion: string;
		components: any[];
		sessions: any[];
		firstUpgrade: boolean;
		upgradingNow: boolean;
		timer;
	};

	promises: {
		loadingState;
		loadingComponents;
	};

	ui: {
		componentSearchEnabled: boolean;
		searchButtonEnabled: boolean;
		upgradeButtonDisplayed: boolean;
		upgradeButtonEnabled: boolean;
	};

	constructor(
		private readonly upgradeApiService: UpgradeApiService,
		private readonly cxDialogService: CxDialogService,
		private readonly locale: CxLocaleService,
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.upgrade = {
			componentSearchVersion: '',
			components: [],
			sessions: [],
			firstUpgrade: false,
			upgradingNow: false,
			timer: null
		};

		this.promises = {
			loadingState: null,
			loadingComponents: null
		};

		this.ui = {
			componentSearchEnabled: true,
			searchButtonEnabled: false,
			upgradeButtonDisplayed: false,
			upgradeButtonEnabled: false
		};

		this.reloadPage();
	}

	cleanVersionData = () => {
		this.upgrade.components = [];
		this.ui.upgradeButtonDisplayed = false;
	};

	hasComponents = () => {
		return this.upgrade.components && this.upgrade.components.length > 0;
	};

	isUpgradedState = () => {
		return !this.upgrade.upgradingNow && !this.upgrade.firstUpgrade 
				&& !this.hasComponents() && !this.isLoadingComponents();
	};

	isDisabledState = () => {
		return this.ui.upgradeButtonDisplayed && this.ui.upgradeButtonEnabled && this.upgrade.upgradingNow;
	};

	hasSessions = () => {
		return this.upgrade.sessions && this.upgrade.sessions.length > 0;
	};

	isLoadingState = () => {
		return !!this.promises.loadingState;
	};

	isLoadingComponents = () => {
		return !!this.promises.loadingComponents;
	};

	loadComponents = () => {
		this.upgrade.components = [];
		this.promises.loadingComponents = this.upgradeApiService
			.getUnexecutedComponents(this.upgrade.componentSearchVersion)
			.then((components: IUpgradeComponent[]) => {
				components.forEach((component : IUpgradeComponent) => {
					if (component.optional) {
						component.forced = false;
						component.skipped = false;
					}
				});

				this.upgrade.components = components;
				this.ui.upgradeButtonDisplayed = components.length > 0;
				delete this.promises.loadingComponents;
				this.ref.markForCheck();
			});
	};

	private generateUpgradeWarningText = (list, type) => {
		let text = '';
		if (list && list.length) {
			text = text + this.locale.getString(type === 'Forced' ?
				'upgrade.upgradeWarningForced' :
				'upgrade.upgradeWarningSkip', {list: list.join(', ')});
		}
		return text;
	};

	private showOptionalConfirmDialog = (forced, skipped) => {
		let text = [];
		if (forced.length) {
			text.push(this.generateUpgradeWarningText(forced, 'Forced'));
		}
		if (skipped.length) {
			text.push(this.generateUpgradeWarningText(skipped, 'Skip'));
		}
		let totalText = text.join(' <br> <br> ');
		return this.cxDialogService.warningWithConfirm(
			this.locale.getString('upgrade.warningDialogTitle'),
			totalText,
			this.locale.getString('upgrade.performUpgrade'),
			this.locale.getString('upgrade.cancel')
		);
	};

	performUpgrade = () => {
		let forcedComponents = this.getOptionalComponentFullNames((component) => component.forced);
		let skippedComponents = this.getOptionalComponentFullNames((component) => component.skipped);
		let upgradePayload = { forcedComponents, skippedComponents };
		let dialogResult = (forcedComponents.length || skippedComponents.length ) ?
			this.showOptionalConfirmDialog(forcedComponents, skippedComponents).result : Promise.resolve();

		dialogResult.then(() => {
			this.runUpgrade(upgradePayload);
		});
	};

	private runUpgrade = (payload) => {
		this.upgrade.components = [];
		this.upgradeApiService.upgradeFromVersionPayloaded(
			this.upgrade.componentSearchVersion,
			payload)
			.then(this.reloadPage); // do one final update after session is complete

		this.reloadPage(); // start updating immediately after session starts
	};

	private getOptionalComponentFullNames = (filter) => {
		return this.upgrade.components.filter((component) => {
			return component.optional && filter(component);
		}).map((filteredOptionalComponent) => filteredOptionalComponent.fullName);
	};

	skipComponentForNow = (component: any): void => {
		component.forced = false;
		component.skipped = false;
	};

	skipComponent = (component: any): void => {
		component.forced = false;
		component.skipped = true;
	};

	forceComponent = (component: any): void => {
		component.forced = true;
		component.skipped = false;
	};

	reloadPage = (): void => {
		this.reloadState();
	};

	canPerformUpgrade = (): boolean => {
		let runnableComponents = this.upgrade.components.filter((component) => {
			return !component.optional
				|| component.forced || component.skipped;
		});

		return this.ui.upgradeButtonDisplayed && this.ui.upgradeButtonEnabled
			&& runnableComponents.length > 0;
	};

	private reloadState = () => {
		this.promises.loadingState = this.upgradeApiService.getState(this.PREVIOUS_SESSIONS_DISPLAY_LIMIT)
			.then((state: IUpgradeState) => {
				let hasCurrentUpgradeVersion = this.isDefinedAndNotNull(state.currentUpgradeVersion);
				let hasCurrentUpgradeSession = this.isDefinedAndNotNull(state.currentUpgradeSessionId);

				this.upgrade.componentSearchVersion = hasCurrentUpgradeVersion
					? state.currentUpgradeVersion
					: state.applicationVersion;
				this.ui.componentSearchEnabled = !hasCurrentUpgradeVersion;
				this.ui.upgradeButtonEnabled = !hasCurrentUpgradeSession;
				this.ui.searchButtonEnabled = !hasCurrentUpgradeSession;

				this.upgrade.firstUpgrade = !hasCurrentUpgradeVersion
					&& !hasCurrentUpgradeSession;
				this.upgrade.upgradingNow = hasCurrentUpgradeSession;

				if (!hasCurrentUpgradeSession) {
					this.loadComponents();
				}

				if (hasCurrentUpgradeSession) {
					this.startUpdateInterval();
				} else {
					this.cancelUpdateInterval();
				}

				this.upgrade.sessions = state.upgradeSessions;
				delete this.promises.loadingState;
			}, () => {
				this.cancelUpdateInterval();
			});
		return this.promises.loadingState;
	};

	private startUpdateInterval = () => {
		if (!this.upgrade.timer) {
			this.upgrade.timer = setInterval(this.reloadState, this.DEFAULT_RELOAD_TIMEOUT);
		}
	};

	private cancelUpdateInterval = () => {
		if (this.upgrade.timer) {
			clearInterval(this.upgrade.timer);
			this.upgrade.timer = null;
		}
	};

	private isDefinedAndNotNull = (value) => {
		return !(_.isUndefined(value) || value === null);
	};

}

app.directive('upgradeTab', downgradeComponent({component: UpgradeTabComponent}));
