import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { IContextMenuItem, ContextMenuItemType, ContextMenuListItem, ContextMenuTemplateItem, ContextMenuTextItem } from './context-menu-item';

@Component({
	selector: 'kebab-menu',
	templateUrl: './kebab-menu.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class KebabMenuComponent {
	@Input() label: string;
	@Input() menuItems: IContextMenuItem[];

	@Output() menuClosed = new EventEmitter<UIEvent>();

	@ViewChild('ngDropdownToggle', {static: false}) dropdownToggle: ElementRef;
	@ViewChild('ngDropdown', {static: false}) dropdown: ElementRef;
	@ViewChild(NgbDropdown, {static: false}) private ngDropdown: NgbDropdown;

	private readonly SUBMENU_OPTION_CLASS = 'submenu-option';
	private readonly SUBMENU_CLASS = 'has-submenu';

	isDivider = (menuItem: IContextMenuItem): boolean => {
		return menuItem.type === ContextMenuItemType.DIVIDER;
	};

	isText = (menuItem: IContextMenuItem): menuItem is ContextMenuTextItem => {
		return menuItem.type === ContextMenuItemType.TEXT;
	};

	isSubmenu = (menuItem: IContextMenuItem): menuItem is ContextMenuListItem => {
		return menuItem.type === ContextMenuItemType.SUBMENU;
	};

	hasSubitems = (menuItem: IContextMenuItem): menuItem is ContextMenuListItem => {
		return this.isSubmenu(menuItem) && !!menuItem.items;
	};

	isTemplate = (menuItem: IContextMenuItem): menuItem is ContextMenuTemplateItem => {
		return menuItem.type === ContextMenuItemType.TEMPLATE;
	};

	isOpen = (): boolean => {
		return this.ngDropdown?.isOpen();
	};

	getAriaLabel = (): string => {
		return this.label;
	};

	onItemClick = (menuItem: IContextMenuItem, event: UIEvent): void => {
		if (this.hasSubitems(menuItem)) {
			menuItem.open = !menuItem.open;
			return;
		}
		if (this.isText(menuItem) && menuItem.action) {
			menuItem.action();
			this.closeOnClick(event);
		}
		if (!event.cancelBubble) {
			this.closeOnClick(event);
		}
	};

	closeOnClick = (event: UIEvent): void => {
		event.preventDefault();
		event.stopPropagation();
		this.ngDropdown.close();
		this.menuClosed.emit(event);
	};

	private getFocusableMenuItems(): JQuery {
		return $(this.dropdown.nativeElement).find('ul :focusable');
	}

	private focusDropdownOnTab = (event: JQuery.KeyDownEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.TAB) || KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			let focusableItems = this.getFocusableMenuItems();
			if (focusableItems.index($(document.activeElement as HTMLElement)) === -1) {
				event.preventDefault();
				event.stopPropagation();
				focusableItems.first().trigger('focus');
			}
		}
	};

	onMenuToggle = (open: boolean): void => {
		if (open) {
			$(window).on('keydown', this.focusDropdownOnTab);
		} else {
			$(window).off('keydown', this.focusDropdownOnTab);
			this.menuItems.forEach((item) => {
				if (this.isSubmenu(item) && item.open) {
					item.open = false;
				}
			});
		}
	};

	onMenuToggleKeydown = (event: KeyboardEvent): void => {
		setTimeout(() => {
			this.getFocusableMenuItems().first().trigger('focus');
		});
	};

	// CB-8922 - close on tab, close submenu on left arrow, open submenu on right arrow
	onMenuKeydown = (menuItem: IContextMenuItem, event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE) ||
				KeyboardUtils.isEventKey(event, Key.TAB) ||
				KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			event.preventDefault();
			this.ngDropdown.close();
			this.dropdownToggle.nativeElement.focus();
			return;
		}

		if (_.contains([Key.DOWN, Key.UP, Key.PAGEDOWN, Key.PAGEUP, Key.LEFT, Key.RIGHT, Key.ENTER], event.key)) {
			event.stopPropagation();
			event.preventDefault();

			let focusableItems = this.getFocusableMenuItems();
			let focusedMenuItemIndex = focusableItems.index($(document.activeElement as HTMLElement));

			if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
				this.onItemClick(menuItem, event);
				if (this.hasSubitems(menuItem) && menuItem.open) {
					this.focusOnSubmenu(event);
				}
				return;
			}

			if (KeyboardUtils.isEventKey(event, Key.RIGHT)
					&& this.hasSubitems(menuItem)
					&& !menuItem.open
					&& focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_CLASS)) {
				menuItem.open = true;
				this.focusOnSubmenu(event);
				return;
			}

			if (KeyboardUtils.isEventKey(event, Key.LEFT) && this.hasSubitems(menuItem) && menuItem.open) {
				if (focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_OPTION_CLASS)) {
					let submenuToggle = focusableItems.eq(focusedMenuItemIndex).closest('.dropdown-submenu').find(':focusable').first();
					submenuToggle.trigger('focus');
					menuItem.open = false;
				} else if (focusableItems[focusedMenuItemIndex].classList.contains(this.SUBMENU_CLASS)) {
					menuItem.open = false;
				}
				return;
			}

			if (KeyboardUtils.isEventKey(event, Key.PAGEDOWN))
				focusedMenuItemIndex = focusableItems.length - 1;

			if (KeyboardUtils.isEventKey(event, Key.PAGEUP))
				focusedMenuItemIndex = 0;

			if (KeyboardUtils.isEventKey(event, Key.DOWN) && focusedMenuItemIndex < focusableItems.length - 1) {
				focusedMenuItemIndex++;
			}

			if (KeyboardUtils.isEventKey(event, Key.UP) && focusedMenuItemIndex > 0) {
				focusedMenuItemIndex--;
			}

			(focusableItems.get(focusedMenuItemIndex) as HTMLElement).focus();
		}
	};


	private focusOnSubmenu = (event: KeyboardEvent): void => {
		setTimeout(() => {
			let submenuParent = (event.target as HTMLElement).classList.contains('dropdown-submenu') ?
				$(event.target) :
				$(event.target).closest('.dropdown-submenu');
			submenuParent.find('.dropdown-menu :focusable').first().trigger('focus');
		});
	};
}
