import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';

export interface RestTokenValue {
	token: string;
}

@Injectable()
export class TokensApiService {

	constructor(private cxHttp: CxHttpService) { }

	generateHierarchyApiToken = (): Promise<RestTokenValue> => {
		return this.cxHttp.post('rest/token/hierarchy-api', {});
	};

	revokeHierarchyApiTokens = (): Promise<void> => {
		return this.cxHttp.delete('rest/token/hierarchy-api');
	};

}
