import { Unit } from '@app/modules/units/unit';
import { ProjectAccessLevelUtils } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-utils.class';
import { ProjectAccessLevelValue } from '@app/modules/user-administration/editor/workspaces-projects-access/project-access-level-value.enum';
import { WorkspaceAccess } from '@app/modules/user-administration/editor/workspaces-projects-access/workspace-access';
import { LicenseLevel } from '@cxstudio/common/license-levels';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';

import * as cloneDeep from 'lodash.clonedeep';

export class WorkspaceAccessEditorState {
	private workspace: Unit;
	private license: LicenseTypeItem;
	private editorAccess: WorkspaceAccess;
	private targetState: WorkspaceAccess;
	private changed: boolean;
	private error: string;

	private readonly analyzeAccessOptions = [
		ProjectAccessLevelValue.MANAGER, ProjectAccessLevelValue.VIEWER, ProjectAccessLevelValue.NONE
	];
	private readonly cxStudioAccessOptions = [
		ProjectAccessLevelValue.VIEWER, ProjectAccessLevelValue.NONE
	];
	private readonly disabledAccessOptions = [
		ProjectAccessLevelValue.MANAGER, ProjectAccessLevelValue.VIEWER, ProjectAccessLevelValue.CUSTOM
	];

	constructor(
		workspace: Unit,
		editorAccess: WorkspaceAccess,
		storedState: WorkspaceAccess,
		license: LicenseTypeItem,
		copy: boolean = false,
		error?: string,
	) {
		this.workspace = workspace;
		this.license = license;
		if (editorAccess && storedState) {
			this.editorAccess = cloneDeep(editorAccess);
			this.targetState = cloneDeep(editorAccess);
			this.resetAccountAccessLevelToDefault(!this.isAdminLevelLicense());
			this.mergeStoredState(storedState);

			if (copy && !this.isEmptyAccess(storedState)) {
				this.changed = true;
			}
		} else {
			this.editorAccess = this.emptyAccess();
			this.targetState = this.emptyAccess();
			this.error = error;
		}
	}

	private emptyAccess(): WorkspaceAccess {
		return {
			workspaceId: this.workspace.id,
			accountAdmin: false,
			liteUser: false,
			projects: []
		};
	}

	private isEmptyAccess(access: WorkspaceAccess): boolean {
		let empty = this.emptyAccess();
		return access.accountAdmin === empty.accountAdmin
			&& _.isEqual(access.projects, empty.projects);
	}

	setLicense(license: LicenseTypeItem): void {
		this.license = license;
		if (license.licenseLevel === LicenseLevel.ADMIN) {
			this.resetAccessLevelToFull();
		} else if (license.licenseLevel === LicenseLevel.EDITOR) {
			this.resetAccessLevelToLite();
		} else {
			this.resetToNoAccess();
		}
	}

	getWorkspace(): Unit {
		return this.workspace;
	}

	getTargetState(): WorkspaceAccess {
		return this.targetState;
	}

	setTargetState(targetState: WorkspaceAccess): void {
		this.targetState = targetState;
		this.changed = true;
	}

	isChanged(): boolean {
		return this.changed;
	}

	private mergeStoredState = (storedState: WorkspaceAccess): void => {
		if (!this.targetState.liteUser) {
			this.targetState.accountAdmin = storedState.accountAdmin;
		}
		this.targetState.projects.forEach((targetStateProject: ProjectAccess) => {
			let storedProject: ProjectAccess = _.find(storedState.projects, (item) => {
				return item.project.id === targetStateProject.project.id;
			});
			if (storedProject) {
				targetStateProject.accessLevel = storedProject.accessLevel;
			}
		});
	};

	private resetAccountAccessLevelToDefault = (forLite: boolean): void => {
		this.targetState.accountAdmin = false;
		this.targetState.liteUser = forLite;
		this.setAll(ProjectAccessLevelValue.NONE);
	};

	private resetAccessLevelToLite = (): void => {
		this.targetState.accountAdmin = false;
		this.targetState.liteUser = true;
		this.targetState.projects.forEach((project: ProjectAccess) => {
			if (project.accessLevel === ProjectAccessLevelValue.MANAGER) {
				project.accessLevel = ProjectAccessLevelValue.VIEWER;
			}
		});
		this.changed = true;
	};

	private resetAccessLevelToFull = (): void => {
		this.targetState.liteUser = false;
		this.changed = true;
	};

	private resetToNoAccess = (): void => {
		this.targetState.accountAdmin = false;
		this.targetState.liteUser = true;
		this.setAll(ProjectAccessLevelValue.NONE);
		this.changed = true;
	};

	isEditorAdmin(): boolean {
		return this.editorAccess.accountAdmin;
	}

	markAll(newLevel: ProjectAccessLevelValue): void {
		if (this.targetState.accountAdmin) {
			return;
		}

		_.each(this.targetState.projects, (projectData) => {
			let editorLevel = this.getEditorProjectLevel(projectData.project.id);
			let currentLevel = projectData.accessLevel;
			if (this.editorHasOption(editorLevel, currentLevel)
					&& this.editorHasOption(editorLevel, newLevel)) {
				projectData.accessLevel = newLevel;
			}
		});
		this.changed = true;
	}

	setAll(level: ProjectAccessLevelValue): void {
		_.each(this.targetState.projects,
			(projectData) => projectData.accessLevel = level);
	}

	private getEditorProjectLevel(projectId: number): ProjectAccessLevelValue {
		let projectData = this.getProjectData(this.editorAccess, projectId);
		return projectData.accessLevel;
	}

	private getProjectData(workspaceAccess: WorkspaceAccess, projectId: number): ProjectAccess {
		let projectData: ProjectAccess = _.find(workspaceAccess.projects, (item: ProjectAccess) => {
			return item.project.id === projectId;
		});
		return projectData;
	}

	getAccessOptions(projectData: ProjectAccess): ProjectAccessLevelValue[] {
		if (this.isDisabledOption(projectData.project.id, projectData.accessLevel)) {
			return this.getDisabledAccessValues();
		} else {
			return this.getActiveAccessValues(projectData.project.id);
		}
	}

	isDisabledOption(projectId: number, accessLevel: ProjectAccessLevelValue): boolean {
		if (!this.isAdminLevelLicense()
				&& accessLevel === ProjectAccessLevelValue.MANAGER) {
			return true;
		}
		let editorLevel = this.getEditorProjectLevel(projectId);
		return !this.editorHasOption(editorLevel, accessLevel);
	}

	getActiveAccessValues(projectId: number): ProjectAccessLevelValue[] {
		let options = this.cxStudioAccessOptions;
		if (this.isAdminLevelLicense()) {
			options = this.analyzeAccessOptions;
		}
		let editorLevel = this.getEditorProjectLevel(projectId);
		options = _.filter(options, (option) => {
			return this.editorHasOption(editorLevel, option);
		});
		return options;
	}

	getDisabledAccessValues(): ProjectAccessLevelValue[] {
		let options = this.disabledAccessOptions;
		return options;
	}

	isAdminLevelLicense(): boolean {
		return this.license && this.license.licenseLevel === LicenseLevel.ADMIN;
	}

	private editorHasOption(editorLevel: ProjectAccessLevelValue, targetLevel: ProjectAccessLevelValue): boolean {
		if (targetLevel === ProjectAccessLevelValue.CUSTOM) return false;
		return ProjectAccessLevelUtils.isLevelAvailableForEditor(editorLevel, targetLevel);
	}

	getError(): string {
		return this.error;
	}
}
