import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { SidebarService } from '@app/modules/layout/sidebar.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { Security } from '@cxstudio/auth/security-service';

export class PrimaryHeaderComponent implements ng.IComponentController {

	isMobile: boolean;
	currentMasterAccount: CurrentMasterAccount;
	getCBLogo = this.dashboardExportService.getClarabridgeLogo;
	applicationSuiteMenuEnabled: boolean;
	reArrangeTopPanelControls: boolean;

	constructor(
		private dashboardExportService,
		private $rootScope: ng.IRootScopeService,
		private security: Security,
		private readonly sidebarService: SidebarService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {}

	$onInit = () => {
		this.isMobile = this.$rootScope.isMobile;
		this.currentMasterAccount = this.security.getCurrentMasterAccount();
		this.applicationSuiteMenuEnabled = this.isApplicationSuiteMenuEnabled();
		this.reArrangeTopPanelControls = this.isReArrangeTopPanelControls();
	};

	private isApplicationSuiteMenuEnabled = (): boolean => {
		let betaEnabled: boolean = this.betaFeaturesService.isFeatureEnabled(BetaFeature.APPLICATION_SUITE_MENU);
		return betaEnabled && (this.security.isQualtricsIntegrationEnabled()
			|| this.security.getCurrentMasterAccount().engagorEnabled);
	};

	private isReArrangeTopPanelControls = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.APPLICATION_SUITE_MENU);
	};

	getMasterAccounts = () => {
		return this.security.getMasterAccounts();
	};

	showLogo = (): boolean => this.currentMasterAccount.customLogoEnabled && this.currentMasterAccount.customBrandingEnabled;

	openAndFocusOnSidebarOnEnter = (event): void => {
		if ((KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE) ) && !this.sidebarService.showSidebar) {
			event.preventDefault();
			event.stopPropagation();
			this.sidebarService.toggleSidebar(true);
		}
	};

	canSwitchAccount = (): boolean => {
		return !this.security.isImpersonateMode();
	};
}

app.component('primaryHeader', {
	controller: PrimaryHeaderComponent,
	template: `
<cb-header
	[button-label]="'common.clarabridgeNavigation' | i18n"
	[sidebar-shown]="$ctrl.sidebarService.showSidebar"
	(sidebar-toggle)="$ctrl.sidebarService.toggleSidebar()"
	(sidebar-toggle-keydown)="$ctrl.openAndFocusOnSidebarOnEnter($event)">

	<cb-logo ng-if="$ctrl.showLogo()">
		<div class="br-ma-logo br-link-home d-flex">
			<span class="br-logo d-flex align-items-center"
				title="{{::$ctrl.currentMasterAccount.accountName}}"></span>
		</div>
	</cb-logo>

	<cb-app-name class="d-flex mr-16">
		<a href="#/dashboards"
			class="br-link-home h2 d-flex align-items-center header-link"
			ng-if="::!$ctrl.isMobile">
			{{ 'header.appName' | i18n }}
		</a>
		<span ng-if="::$ctrl.isMobile" class="ml-16">{{::$ctrl.currentMasterAccount.accountName}}</span>
	</cb-app-name>

	<div ng-if="pdf" style="display:none;">
		<!-- cache image for pdf -->
		<img ng-src="{{::($ctrl.maUrl + $ctrl.currentMasterAccount.accountId)}}">
		<img ng-src="{{$ctrl.getCBLogo()}}">
	</div>

	<command-palette
		class="search d-flex w-max-20-vw flex-fill align-items-center command-palette">
	</command-palette>


	<div class="br-header-right d-flex align-items-center" ng-if="$ctrl.reArrangeTopPanelControls">
		<account-switcher ng-if="$ctrl.canSwitchAccount()"
			class="z-index-header-dropdown border-gray-300"
			master-accounts="$ctrl.getMasterAccounts()">
		</account-switcher>

		<space-dropdown></space-dropdown>

		<notifications
			class="z-index-header-dropdown"
			master-accounts="$ctrl.getMasterAccounts()"
			open-document-explorer="$ctrl.openDocumentExplorer">
		</notifications>

		<user-menu class="z-index-header-dropdown"></user-menu>

		<suite-applications ng-if="$ctrl.applicationSuiteMenuEnabled" class="z-index-header-dropdown"></suite-applications>

	</div>

	<div class="br-header-right d-flex align-items-center" ng-if="!$ctrl.reArrangeTopPanelControls">

		<account-switcher ng-if="$ctrl.canSwitchAccount()"
			class="z-index-header-dropdown"
			master-accounts="$ctrl.getMasterAccounts()">
		</account-switcher>

		<space-dropdown></space-dropdown>

		<user-menu class="z-index-header-dropdown"></user-menu>

		<notifications
			class="z-index-header-dropdown"
			master-accounts="$ctrl.getMasterAccounts()"
			open-document-explorer="$ctrl.openDocumentExplorer">
		</notifications>
	</div>

</cb-header>

`
});
