import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { VisualizationResolverService } from '@app/modules/widget-visualizations/visualization-resolver.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportRun } from '@cxstudio/reports/entities/report-run.interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { RealDataPreviewService } from './real-data-preview.service';
import { RequestSourceType } from './request-source-type';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { WidgetEditingPreviewMode, WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { AppliedFilters, AppliedFiltersFactory } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { ReportProcessingService } from '@app/modules/reporting/report-processing.service';

enum RealDataViewMode {
	CHART = 'CHART',
	INVALID_PROPERTIES = 'INVALID_PROPERTIES',
	NEED_UPDATE = 'NEED_UPDATE',
}

@Component({
	selector: 'real-data-preview',
	templateUrl: './real-data-preview.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class RealDataPreviewComponent extends SelfCleaningComponent implements OnInit, OnChanges, OnDestroy {

	@Input() widget: Widget;
	@Input() utils: WidgetUtils;
	@Input() trigger: number;
	// checkbox value
	@Input() showTotalCount: boolean;
	// used in a template value
	private uiShowTotalCount: boolean;

	@Output() hasChangesChange = new EventEmitter<boolean>();
	@Output() updateDataLoading = new EventEmitter<Promise<any>>();
	@Output() updateChart = new EventEmitter<void>();

	hasChanges: boolean;
	hasTabChanges: boolean;
	dataLoading: Promise<any>;
	data: any;

	total: string;

	appliedVisualization: WidgetVisualization;

	RealDataViewMode = RealDataViewMode;

	widgetWidth = 600; // defaults
	widgetHeight = 400;

	appliedFilters: AppliedFilters;

	constructor(
		private ref: ChangeDetectorRef,
		private readonly realDataPreviewService: RealDataPreviewService,
		private readonly visualizationResolverService: VisualizationResolverService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly reportProcessingService: ReportProcessingService,
		@Inject('security') private security: Security,
		@Inject('reportNumberFormatUtils') private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
		@Inject('appliedFiltersFactory') private readonly appliedFiltersFactory: AppliedFiltersFactory,
	) {
		super();
	}

	ngOnInit(): void {
		this.uiShowTotalCount = this.showTotalCount;
		this.updateAppliedVisualization();

		this.addSubscription(this.realDataPreviewService.getPreviewChangeObserver().subscribe(newValue => {
			this.hasChanges = newValue;

			if (this.isAutoPreview() && this.canRunUpdate()) {
				this.runReport();
			}
			this.ref.markForCheck();
		}));

		this.addSubscription(this.realDataPreviewService.getTabModifiedObserver().subscribe(newValue => {
			this.hasTabChanges = newValue;
			this.runReportOnTabChange();
			this.ref.markForCheck();
		}));

		this.addSubscription(this.realDataPreviewService.getReportRunTriggerObserver().subscribe(() => {
			this.runReport();
		}));

		if (!_.isUndefined(this.widget.id) && !_.isUndefined(this.utils)) {
			this.runReport();
		}
		this.initializeAppliedFilters();
	}

	getWidgetDimensions() {
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WIDGET_EDIT_OVERHAUL)
			&& this.widget.properties.widgetType === WidgetType.CLOUD) {
			this.widgetHeight = this.widget.height * (window.innerHeight / 24);
			this.widgetWidth = this.widget.width * (window.innerWidth / 24);
			return {
				width: this.widgetWidth + 'px',
				height: this.widgetHeight + 'px'
			};
		} else if (this.realDataPreviewService.showRealDataPreview(this.widget.properties.widgetType)) {
			// not in sidebar, so just fit container
			return {
				width: '',
				height: ''
			};
		}
		return {
			width: this.widgetWidth + 'px',
			height: this.widgetHeight + 'px'
		};
	}


	ngOnChanges(changes: SimpleChanges<RealDataPreviewComponent>): void {
		if (!this.hasChanges && this.uiShowTotalCount !== this.showTotalCount) {
			this.uiShowTotalCount = this.showTotalCount;
		}
		if (ChangeUtils.hasChange(changes.trigger)) {
			if (!this.hasChanges)
				this.updateAppliedVisualization();
		}

		if (!_.isUndefined(this.widget.id) && ChangeUtils.hasChange(changes.utils) && _.isUndefined(changes.utils.previousValue)) {
			this.runReport();
		}
	}

	runReportOnTabChange() {
		if (!this.hasValidProperties() && !this.hasChanges) {
			return;
		}

		if (this.hasTabChanges) {
			this.runReport();
			this.realDataPreviewService.setPreviewHasChanges(false);
			this.realDataPreviewService.setTabModified(false);
		}
	}

	// On preview click()
	runReport() {
		if (!this.hasValidProperties()) {
			return;
		}
		let reportRun: ReportRun = { widget: this.widget, utils: this.utils };

		this.updateAppliedVisualization();
		const requestType = this.isAutoPreview() ?
			RequestSourceType.PREVIEWAUTO :
			RequestSourceType.PREVIEW;
		this.dataLoading = this.realDataPreviewService.getData(reportRun, requestType);
		this.updateDataLoading.emit(this.dataLoading);

		this.dataLoading.then(data => {
			this.data = data;
			this.total = this.data.total?.volume ? this.reportNumberFormatUtils.commaFormat(this.data.total.volume) : '0';

			this.updateDataLoading.emit(this.dataLoading);
			this.realDataPreviewService.setPrevWidgetProps(this.widget.properties);
			this.realDataPreviewService.setPreviewHasChanges(false);
			this.updateChart.emit();
		});
	}

	private updateAppliedVisualization(): void {
		this.appliedVisualization = this.widget.visualProperties.visualization as WidgetVisualization;
	}

	isNgWidgetContent = (widget: Widget): boolean => {
		return this.visualizationResolverService.isNgWidgetContent(widget);
	};

	isLegacyWidgetContent = (widget: Widget): boolean => {
		return !!this.getVisualization() && !this.isNgWidgetContent(widget);
	};

	showTotal(): boolean {
		return this.uiShowTotalCount && !!this.total;
	}

	showViewWrapper(widget: Widget): boolean {
		return this.isLegacyWidgetContent(widget) && this.utils
			&& (!!this.data || this.widget.properties.widgetType === WidgetType.SELECTOR);
	}

	isCalculationOnlyWidget(): boolean {
		return this.widget.properties.widgetType === WidgetType.METRIC;
	}

	hasValidProperties(): boolean {
		if (this.isCalculationOnlyWidget())
			return !_.isEmpty(this.widget.properties.selectedMetrics);
		let nonNullGroupings = _.filter(this.widget.properties.selectedAttributes, attr => !!attr);
		return !_.isEmpty(nonNullGroupings);
	}

	canRunUpdate(): boolean {
		return (this.hasChanges || !this.hasData()) && this.hasValidProperties();
	}

	isAutoPreview(): boolean {
		let currentMasterAccount: CurrentMasterAccount = this.security.getCurrentMasterAccount();
		let widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings = currentMasterAccount.widgetEditingPreviewsSettings;

		return widgetEditingPreviewsSettings?.widgetEditingPreviewMode === WidgetEditingPreviewMode.AUTO;
	}

	getVisualization(): WidgetVisualization {
		return this.hasChanges ? this.appliedVisualization : this.widget.visualProperties.visualization as WidgetVisualization;
	}

	initializeAppliedFilters(): void {
		this.reportProcessingService.getWidgetFiltersMetadata(this.widget, null).then(metaData => {
			this.appliedFilters = this.appliedFiltersFactory.getAppliedFilters(metaData, true, false);
		}, () => {});
	}

	private hasData(): boolean {
		return !!this.data;
	}

	getCurrentView(): RealDataViewMode {
		if (!this.hasValidProperties()) {
			return RealDataViewMode.INVALID_PROPERTIES;
		} else if (!this.hasData()) {
			return RealDataViewMode.NEED_UPDATE;
		} else if (this.hasValidProperties()) {
			return RealDataViewMode.CHART;
		} else {
			return null;
		}
	}

	hasWidgetHeader(): boolean {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WIDGET_EDIT_OVERHAUL)
			&& this.widget.properties.widgetType === WidgetType.CLOUD;
	}
}

app.directive('realDataPreview', downgradeComponent({component: RealDataPreviewComponent}));
