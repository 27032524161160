import { Component, Inject, Input, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { EmbeddedWidgetUtils } from '@app/modules/account-administration/embedded-widget/embedded-widget-utils.service';
import { RandomUtils } from '@app/util/random-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetEmbedConfig } from '@cxstudio/dashboards/widgets/widget-embed-config.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ToastService, AlertLevel } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'external-embedding-settings',
	templateUrl: './external-embedding-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ExternalEmbeddingSettingsComponent implements OnInit {
	@Input() private widget: Widget;
	@Input() embedConfig: WidgetEmbedConfig;

	constructor(
		private locale: CxLocaleService,
		private toastService: ToastService,
		private embeddedWidgetUtils: EmbeddedWidgetUtils,
		@Inject('security') private security: Security,
	) {}

	ngOnInit(): void {
		this.initIdentifier();
	}

	hasEmbedEnterprisePermission(): boolean {
		return this.security.has('embed_widgets_enterprise');
	}

	getEmbedWidgetCode(): string {
		return this.embeddedWidgetUtils.getEmbedWidgetCode(this.widget, this.embedConfig.identifier);
	}

	private initIdentifier(): void {
		if (!this.embedConfig.identifier)
			this.embedConfig.identifier = RandomUtils.randomString();
	}

	showEnterpriseViewersOption(): boolean {
		return this.widget.name !== WidgetType.PREVIEW
				&& this.hasEmbedEnterprisePermission();
	}

	onCopySuccess(): void {
		this.toastService.addToast(this.locale.getString('common.copied'), AlertLevel.SUCCESS);
	}
}
