import { ChangeDetectionStrategy, Component, Input, OnInit, Inject, HostListener, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { IModalComponent } from '@app/modules/dialog/modal-component.interface';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { Unit } from '@app/modules/units/unit';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { HierarchyComponentInput } from '../../hierarchy-component-input';


@Component({
	templateUrl: './hierarchy-filters-project-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyFiltersProjectSelectorComponent
	implements OnInit, IModalComponent<HierarchyComponentInput> {

	@Input() input: HierarchyComponentInput;

	useWorkspaces: boolean;
	value: IProjectSelection;
	projectSelection: IProjectPreselection;
	projectsLoading: Promise<any>;
	contentProviderErrors: string[];

	constructor(
		@Inject('exportUtils') private exportUtils: ExportUtils,
		private organizationApiService: OrganizationApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private modal: NgbActiveModal,
	) { }

	ngOnInit(): void {
		this.projectSelection = { cbContentProvider: -1, cbAccount: -1, project: -1 };
		this.useWorkspaces = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	}

	onProjectSelectionChange(projectSelection: IProjectPreselection): void {
		this.projectSelection = projectSelection;
		this.checkProjectSelection();
	}

	onProjectsLoading(loadingPromise: Promise<any>): void {
		this.projectsLoading = loadingPromise;
	}

	onCpErrorsChange(errors: string[]): void {
		this.contentProviderErrors = errors;
	}

	onWorkspaceChange(workspace: Unit): void {
		if (workspace) {
			this.projectSelection.cbContentProvider = workspace.contentProviderId;
			this.projectSelection.cbAccount = workspace.accountId;
		} else {
			this.projectSelection.cbContentProvider = -1;
			this.projectSelection.cbAccount = -1;
		}
	}

	onProjectChange(workspaceProject: WorkspaceProjectData): void {
		this.projectSelection.project = workspaceProject.projectId;
		this.projectSelection.projectName = workspaceProject.projectName;
		this.checkProjectSelection();
	}

	private checkProjectSelection(): void {
		if (this.projectSelection.project > -1) {
			this.value = {
				contentProviderId: this.projectSelection.cbContentProvider,
				accountId: this.projectSelection.cbAccount,
				projectId: this.projectSelection.project,
				projectName: this.projectSelection.projectName
			};
		} else {
			this.value = undefined;
		}
	}

	cancel = (): void => {
		this.modal.dismiss();
	};

	downloadTemplate = (): void => {
		this.downloadTemplateFile();
	};

	downloadEnabled = (): boolean => {
		return !!this.value?.projectId;
	};

	private downloadTemplateFile(): void {
		const seletedProject = { id: this.value.projectId, name: this.value.projectName } as any;
		const cpId = this.value.contentProviderId;
		this.projectsLoading = this.organizationApiService.getOrganizationFiltersTemplateForProject(this.input.hierarchy.id, seletedProject, cpId)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
				this.modal.dismiss();
			}) as any;
	}

}
