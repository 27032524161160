import { Inject, Component, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import Browser from '@cxstudio/common/browser.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';


@Component({
	selector: 'copy-text',
	template: `
	<li *ngIf="showOption()">
		<a class="option _t-copy-text"
			role="menuitem"
			(click)="copyText($event)"
			href="javascript:void(0)">{{'common.copyText'|i18n}}</a>
	</li>
	<li *ngIf="showOption()" class="divider" role="separator"></li>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CopyTextComponent {

	constructor(
		private locale: CxLocaleService,
		@Inject('browser') private browser: Browser,
		private clipboardService: ClipboardService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService
	) { }

	copyText($event): void {
		let target = $event.delegateTarget || $event.currentTarget;
		let text = window.getSelection ?
			window.getSelection().toString() :
			document.getSelection().toString();

		this.clipboardService.copyToClipboard(text, target);
		this.showSuccessNotification();
	}

	showOption(): boolean {
		let isChrome = this.browser.getBrowser() === 'chrome';
		let hasSelection = this.clipboardService.hasSelectionInWidget();
		return isChrome && hasSelection;
	}

	showSuccessNotification(): void {
		this.globalNotificationService.addSuccessNotification(this.locale.getString('common.copied'));
	}
}
app.directive('copyText', downgradeComponent({ component: CopyTextComponent }));
