import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { DashboardRun, DashboardRunService } from './dashboard-run.service';


/**
 * DO NOT MERGE DashboardRunHelperService and DashboardRunService
 * Use it as decorator for the main service and for commons methods
 * See the details in DashboardRunService
 * */
@Injectable()
export class DashboardRunHelperService {

	constructor(
		@Inject('dashboardApiService') private dashboardApiService: DashboardApiService,
		private dashboardRunService: DashboardRunService
	) {}

	setRunStatus(dashboardId: number, runStatus: DashboardRunStatus): void {
		if (dashboardId) {
			let dashboardRun: DashboardRun = this.dashboardRunService.getDashboardRun(dashboardId);
			if (dashboardRun && !dashboardRun.runStatus) {
				dashboardRun.runStatus = runStatus;
				dashboardRun.initialPromise.then(() => this.dashboardApiService.setDashboardRunStatus(dashboardRun));
			}
		}
	}

	getRunStatus(dashboardId: number): DashboardRunStatus {
		return dashboardId && this.dashboardRunService.getDashboardRun(dashboardId)?.runStatus || null;
	}

	// WIDGET POPULATION
	markFirstPassWidgets(widgets: Widget[]): void {
		_.each(widgets, widget => {
			widget.firstPass = true;
		});
	}

	populateDashboardRunTimestamp = (widgets: Widget[], dashboardRunTimestamp?: string): string => {
		_.each(widgets, (widget: Widget) => {
			widget.dashboardRunTimestamp = dashboardRunTimestamp
				? dashboardRunTimestamp
				: this.dashboardRunService.getDashboardRun(widget.dashboardId).timestamp;
		});

		return dashboardRunTimestamp;
	};

	// DEPARTURE EVENTS
	dashboardLeaveEvent(nextAbsoluteUrl: string, dashboardId: number): boolean {
		let dashboardPath = `#/home/${dashboardId}`;
		return nextAbsoluteUrl.indexOf(dashboardPath) === -1;

	}

	bookTabLeaveEvent(nextAbsoluteUrl: string, bookId: number, dashboardId: number): boolean {
		let dashboardPath = `#/dashboard/${bookId}?tab=${dashboardId}`;
		return nextAbsoluteUrl.indexOf(dashboardPath) === -1;
	}

}

app.service('dashboardRunHelperService', downgradeInjectable(DashboardRunHelperService));
