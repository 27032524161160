import { NgModule } from '@angular/core';
import { SharedModule } from '@app/shared/shared.module';
import { WidgetSettingsModule } from '@app/modules/widget-settings/widget-settings.module';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { UserAdministrationModule } from '@app/modules/user-administration/user-administration.module';
import { HeaderModule, ToastModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MetricModule } from '@app/modules/metric/metric.module';
import { SystemAdministrationModule } from '@app/modules/system-administration/system-administration.module';
import { HierarchyModule } from '@app/modules/hierarchy/hierarchy.module';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';

// temporary module to use some ng9 components in ng1 code, e.g. opening dialog with ng9 component

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		WidgetSettingsModule,
		UserAdministrationModule,
		HeaderModule,
		ToastModule,
		MetricModule,
		SystemAdministrationModule,
		HierarchyModule,
	],
	providers: [
		DowngradeDialogService,
		DowngradeToastService,
	],

})
export class DowngradeUtilsModule {}
