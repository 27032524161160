
export class MetricBandsService {

	constructor() {
	}

	removeOutersFromThresholdsAndRules = (thresholds: any[], rules: any[]): void => {
		if (thresholds.length === 4 && rules.length === 4) {
			thresholds.removeAt(3);
			thresholds.removeAt(0);
			rules.removeAt(3);
			rules.removeAt(0);
		} else throw new Error('invalid thresholds data');
	};

	removeOuterThresholds = (thresholds: any[]): any[] => {
		if (thresholds.length === 4) {
			thresholds.removeAt(3);
			thresholds.removeAt(0);
			return thresholds;
		} else throw new Error('invalid thresholds data');
	};

	removeInnerBands = (bands: any[]): void => {
		if (bands.length === 5) {
			bands.removeAt(3);
			bands.removeAt(1);
		} else throw new Error('invalid bands data');
	};

	removeOuterBands = (bands: any[]): void => {
		if (bands.length === 5) {
			bands.removeAt(4);
			bands.removeAt(0);
		} else throw new Error('invalid bands data');
	};

}

app.service('metricBandsService', MetricBandsService);