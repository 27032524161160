import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

export interface CalculationOption {
	operator: string;
	displayName: string;
	operatorPrefix: string;
	keyword: string;
}

@Injectable({
	providedIn: 'root'
})
export class AttributeCalculationsOptionsService {
	readonly AVG: CalculationOption;
	readonly COUNT: CalculationOption;
	readonly MAX: CalculationOption;
	readonly MIN: CalculationOption;
	readonly SUM: CalculationOption;
	readonly STANDARD_DEVIATION: CalculationOption;
	readonly VARIANCE: CalculationOption;
	readonly SUM_OF_SQUARES: CalculationOption;
	readonly COUNT_DISTINCT: CalculationOption;

	private options: CalculationOption[];

	constructor(
		private locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService
	) {
		this.AVG = {
			operator: 'avg',
			displayName: this.locale.getString('common.average').toLowerCase(),
			keyword: 'average',
			operatorPrefix: this.locale.getString('metrics.averagePrefix')
		};
		this.COUNT = {
			operator: 'count',
			displayName: this.locale.getString('common.count').toLowerCase(),
			keyword: 'count',
			operatorPrefix: this.locale.getString('metrics.countPrefix')
		};
		this.MAX = {
			operator: 'max',
			displayName: this.locale.getString('common.max').toLowerCase(),
			keyword: 'max',
			operatorPrefix: this.locale.getString('metrics.maxPrefix')
		};
		this.MIN = {
			operator: 'min',
			displayName: this.locale.getString('common.min').toLowerCase(),
			keyword: 'min',
			operatorPrefix: this.locale.getString('metrics.minPrefix')
		};
		this.SUM = {
			operator: 'sum',
			displayName: this.locale.getString('common.sum').toLowerCase(),
			keyword: 'sum',
			operatorPrefix: this.locale.getString('metrics.sumPrefix')
		};
		this.COUNT_DISTINCT = {
			operator: 'distinct',
			displayName: this.locale.getString('common.countDistinct').toLowerCase(),
			keyword: 'count distinct',
			operatorPrefix: this.locale.getString('metrics.countDistinctPrefix')
		};
		this.STANDARD_DEVIATION = {
			operator: 'stdev',
			displayName: this.locale.getString('common.stdev').toLowerCase(),
			keyword: 'standard deviation',
			operatorPrefix: this.locale.getString('metrics.stdevPrefix')
		};
		this.VARIANCE = {
			operator: 'variance',
			displayName: this.locale.getString('common.variance').toLowerCase(),
			keyword: 'variance',
			operatorPrefix: this.locale.getString('metrics.variancePrefix')
		};
		this.SUM_OF_SQUARES = {
			operator: 'sumsquares',
			displayName: this.locale.getString('common.sumOfSquares').toLowerCase(),
			keyword: 'sum of squares',
			operatorPrefix: this.locale.getString('metrics.sumOfSquaresPrefix')
		};

		this.options = [
			this.AVG, this.COUNT, this.COUNT_DISTINCT, this.MAX, this.MIN, this.SUM,
			this.STANDARD_DEVIATION, this.VARIANCE, this.SUM_OF_SQUARES
		];
	}

	get = (): CalculationOption[] => {
		const countDistinctEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT);
		return _.filter(this.options, option => countDistinctEnabled || option !== this.COUNT_DISTINCT);
	};

	findType = (operator: string): CalculationOption => {
		return _.findWhere(this.options, {operator});
	};

	findKeyword = (keyword: string): CalculationOption => {
		return _.findWhere(this.options, {keyword});
	};

	find = (attribute): CalculationOption => {
		return this.findType(attribute.operator);
	};

	isSupportTextAttributes = (keyword: string): boolean => {
		return this.COUNT_DISTINCT.keyword === keyword;
	};
}
	