import { CxHttpService } from '@app/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { NarrativeSettingEntry } from '@app/modules/account-administration/automated-narrative/narrative-settings-list.component';
import { Injectable } from '@angular/core';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountId, ContentProviderId } from '@cxstudio/generic-types';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({ providedIn: 'root' })
export class NarrativeSettingsApi {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly http: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		private readonly httpHandlers: CxHttpHandlers,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.NARRATIVES);
	}

	getAccountNarrativeSettings = (contentProviderId: ContentProviderId, accountId: AccountId): Promise<NarrativeSettingEntry[]> => {
		const config = { cache: CacheOptions.CACHED };
		return this.cachedHttp
			.get(`rest/narrative-settings/cp/${contentProviderId}/account/${accountId}`, config)
			.then((settings: NarrativeSettingEntry[]) => {
				if (!settings || settings.length === 0) {
					return [];
				}
				return settings;
			});
	};

	getNarrativeSettings = (project: AccountOrWorkspaceProject): Promise<NarrativeSettingEntry[]> => {
		const config = { cache: CacheOptions.CACHED };
		const url = this.getProjectBaseUrl(project);
		return this.cachedHttp
			.get(url, config)
			.then((settings: NarrativeSettingEntry[]) => {
				if (!settings || settings.length === 0) {
					return [];
				}
				return settings;
			});
	};

	private getProjectBaseUrl(project: AccountOrWorkspaceProject): string {
		return WorkspaceTransitionUtils.isWorkspaceProject(project)
			? `rest/narrative-settings/ws/${project.workspaceId}/project/${project.projectId}`
			: `rest/narrative-settings/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	}

	saveNarrativeSettings = (settings: NarrativeSettingEntry): Promise<void> => {
		return this.http.post('rest/narrative-settings', settings)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.NARRATIVES))
			.then(_.noop);
	};

	updateNarrativeSettings = (settings: NarrativeSettingEntry): Promise<void> => {
		return this.http.put('rest/narrative-settings/' + settings.id, settings)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.NARRATIVES))
			.then(_.noop);
	};

	deleteNarrativeSettings = (settings: NarrativeSettingEntry): Promise<void> => {
		return this.http.delete('rest/narrative-settings/' + settings.id)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.NARRATIVES))
			.then(_.noop);
	};

	narrativeSettingsNameCheck = (projectSelection: IProjectSelection, name: string): Promise<boolean> => {
		let projectIdentifier = {
			contentProviderId: projectSelection.contentProviderId,
			accountId: projectSelection.accountId,
			projectId: projectSelection.projectId
		} as IProjectSelection;
		return this.http.post('rest/narrative-settings/name-check', { projectIdentifier, name });
	};
}

app.service('narrativeSettingsApi', downgradeInjectable(NarrativeSettingsApi));
