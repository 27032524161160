import { AnalyticFeedbackSelectionBase } from '@cxstudio/reports/preview/analytic-feedback-selection-base.class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { CuratedItem, CuratedItemType } from '@cxstudio/reports/preview/curated-item';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';

import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewVerbatim } from '@cxstudio/reports/document-explorer/preview-verbatim';
import { Security } from '@cxstudio/auth/security-service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { CxLocaleService } from '@app/core';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';

export class ExplorerAnalyticFeedbackSelectionClass extends AnalyticFeedbackSelectionBase {

	private hasProjectAccess: boolean = false;

	constructor(
		private security: Security,
		locale: CxLocaleService,
		private widget: Widget,
		private preferences: DocViewPreferences,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly projectAccessService: ProjectAccessService,
		widgetsEditService?: WidgetsEditService,
	) {
		super(locale as any, widgetsEditService, () => {});
		const project = this.reportAssetUtilsService.getWidgetProject(widget);
		this.projectAccessService.hasProjectAccess(project).then((resp) => {
			this.hasProjectAccess = resp;
		});
	}

	protected getCuratedItemType = (): CuratedItemType => {
		return this.preferences.settings && this.preferences.settings.singleVerbatimMode
			? CuratedItemType.VERBATIM
			: CuratedItemType.DOCUMENT;
	};

	protected getWidget = (): Widget => {
		return this.widget;
	};

	protected convertToCuratedItem = (previewItem: PreviewSentence | PreviewVerbatim | PreviewDocument): CuratedItem => {
		let curatedItemType = this.getCuratedItemType();
		let curatedItem: CuratedItem = { type: curatedItemType };

		if (curatedItemType === CuratedItemType.DOCUMENT) {
			let document = previewItem as PreviewDocument;
			curatedItem.documentId = document.id;
		} else {
			let verbatim = previewItem as PreviewVerbatim;
			curatedItem.verbatimId = verbatim.id;
			curatedItem.documentId = verbatim.documentId;
		}

		return curatedItem;
	};

	protected hasCuratingPermissions = (): boolean => {
		return this.hasProjectAccess && (this.security.has('edit_dashboard') && this.security.has('create_dashboard'));
	};

	isFeedbackSharingAvailable = (): boolean => true;

	protected populateProcessedResult = (): void => {
		this.processedResult = {
			excluding: false,
			items: []
		};
	};

}
// eslint-disable-next-line prefer-arrow-callback
app.factory('ExplorerAnalyticFeedbackSelection', function() { return ExplorerAnalyticFeedbackSelectionClass; });
