import { ModalBindings } from '@cxstudio/common/modal-bindings';
import ILocale from '@cxstudio/interfaces/locale-interface';
import Widget, { WidgetDisplayMode } from '@cxstudio/dashboards/widgets/widget';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { WidgetBuilderService } from '@app/modules/widget-settings/services/widget-builder.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { DualChartWidget } from '@app/modules/widget-settings/entities/widgets/dual-chart-widget.class';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { TimePrimaryGroupsService } from '@cxstudio/reports/attributes/time-primary-groups.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { WidgetEvent, DrillFilterEvent } from '@app/core/cx-event.enum';
import { ModelsService } from '@app/modules/project/model/models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { GlobalOtherExplorerOptions } from '@app/modules/project/global-other-explorer-modal/global-other-explorer-modal.component';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';
export class GlobalOtherExplorerComponent extends ModalBindings<GlobalOtherExplorerOptions> {

	dashboardData: Partial<IDashboardData>;
	modalTitle: string;
	dateRange: DateFilter;
	widgetMode = WidgetDisplayMode.ADHOC;
	widgets: Widget[];
	private baseWidgets: Widget[];
	dateRangeOptions: any[];
	timeGroup: any;
	timeGroupingOptions: any[];
	tableGroup: string;
	tableGroupOptions: any[];
	loadingPromise;
	loaded;

	hoverState: {[widgetId: number]: boolean} = {};

	readonly METRIC_WIDGET_INDEX = 0;
	readonly CHART_WIDGET_INDEX = 1;
	readonly TABLE_WIDGET_INDEX = 2;
	readonly TABLE_WIDGET = -3;

	constructor(
		private $scope: ISimpleScope,
		private $q: ng.IQService,
		private locale: ILocale,
		private DateRange,
		private currentWidgets: ICurrentWidgets,
		private currentObjects: CurrentObjectsService,
		private DashboardHistory,
		private widgetBuilderService: WidgetBuilderService,
		private timePrimaryGroups: TimePrimaryGroupsService,
		private metricConstants: MetricConstants,
		private colorPalettes: ColorPalettes,
		private readonly modelsService: ModelsService,
		private readonly widgetVisibilityMonitor: WidgetVisibilityMonitorService
	) {
		super();
	}

	$onInit(): void {
		this.modalTitle = this.resolve.modelIdentifier.modelName + ' ' + this.locale.getString('administration.globalOtherExplorer');
		let colorsPromise = this.colorPalettes.getDefaultPalette();
		let modelTreePromise = PromiseUtils.old(this.modelsService.getModelTree(
			this.resolve.modelIdentifier.project, this.resolve.modelIdentifier.modelId));

		this.widgetVisibilityMonitor.reset();

		this.loadingPromise = this.$q.all([colorsPromise, modelTreePromise]).then((result) => {
			let colorPallete = result[0];
			let modelTree = result[1];

			return PromiseUtils.old(this.modelsService.getModelNode(this.resolve.modelIdentifier.project, modelTree.root.id))
				.then((rootNodeDetails) => {
					let hasRootNode = !!rootNodeDetails.hasRules;
					let widgets = this.widgetBuilderService.getGlobalOtherExplorerWidgets(
						this.resolve.modelIdentifier, modelTree, hasRootNode);

					this.initWidgets(widgets, colorPallete);
					this.loaded = true;
				});
		});
	}

	$onDestroy(): void {
		this.widgetVisibilityMonitor.disconnect();
	}

	private initWidgets = (widgets: Widget[], colorPallete) => {
		this.widgets = widgets;

		this.dateRangeOptions = [
			this.DateRange.options.LAST_24_HOURS,
			this.DateRange.options.LAST_3_DAYS,
			this.DateRange.options.LAST_7_DAYS,
			this.DateRange.options.LAST_30_DAYS,
			this.DateRange.options.LAST_90_DAYS,
			this.DateRange.options.LAST_180_DAYS
		];

		this.dateRange = {
			dateFilterMode: this.DateRange.options.LAST_90_DAYS.value,
			dateDisplayName: this.locale.getString('dateRange.last90d')
		} as DateFilter;

		this.timeGroupingOptions = [{
			name: this.locale.getString('schedule.weekly'),
			value: this.timePrimaryGroups.WEEK
		}, {
			name: this.locale.getString('schedule.monthly'),
			value: this.timePrimaryGroups.MONTH
		}];

		this.timeGroup = this.timePrimaryGroups.WEEK;

		this.setChartWidgetColors(colorPallete);

		let dashboardHistory = new this.DashboardHistory();
		this.currentWidgets.setWidgets('adhocGlobalOther', widgets, dashboardHistory);

		this.dashboardData = {};
		this.dashboardData.dashboardHistory = dashboardHistory;
		this.dashboardData.dashboard = new Dashboard(-1, 'globalOtherExplorer');
		this.dashboardData.dashboard.permissions = {};
		this.dashboardData.dashboard.properties = new DashboardProperties();
		this.currentObjects.setEditMode(false);

		this.tableGroupOptions = this.getTableGroupOptions();
		this.tableGroup = this.tableGroupOptions[0].name;

		this.baseWidgets = angular.copy(this.widgets);
	};

	private setChartWidgetColors = (palette: WidgetColorPalette): void => {
		let chartWidget = this.widgets[this.CHART_WIDGET_INDEX] as DualChartWidget;
		if (palette.colors.length > 1) {
			chartWidget.withColors(palette.colors[0], palette.colors[1]);
		}
	};

	private getTableGroupOptions = (): any[] => {
		let constants = this.metricConstants.get();
		let options = [constants.WORDS, constants.LC, constants.HASHTAG];
		options.forEach(option => _.extend(option, { size: 50 }));
		return options;
	};

	onMouseOver = (widget: Widget) => {
		_.each(widget.linkedWidgets, id => {
			this.hoverState[id] = true;
		});
	};

	onMouseOut = (widget: Widget) => {
		_.each(widget.linkedWidgets, id => {
			this.hoverState[id] = false;
		});
	};

	dateOptionsFilter = (filter) => {
		return !this.DateRange.isCustomDateRange(filter.value)
			&& !!_.findWhere(this.dateRangeOptions, {value: filter.value});
	};

	selectDateFilter = (dateFilterMode): void => {
		if (this.dateRange.dateFilterMode === dateFilterMode) {
			return;
		}
		let range: any = _.findWhere(this.dateRangeOptions, {value: dateFilterMode});
		this.dateRange.dateFilterMode = dateFilterMode;
		this.dateRange.dateDisplayName = range.displayName;
		_.each([this.METRIC_WIDGET_INDEX, this.CHART_WIDGET_INDEX, this.TABLE_WIDGET_INDEX], this.reloadWidget);
	};

	onTimeGroupingChange = (): void => {
		this.reloadWidget(this.CHART_WIDGET_INDEX);
		this.$scope.$broadcast(DrillFilterEvent.DRILL_FILTER_SET, this.widgets[this.CHART_WIDGET_INDEX], null);
		this.$scope.$broadcast(WidgetEvent.RELOAD, [this.TABLE_WIDGET]);
	};

	onTableGroupChange = (): void => {
		this.reloadWidget(this.TABLE_WIDGET_INDEX);
	};

	private reloadWidget = (index: number): void => {
		let widget = angular.copy(this.baseWidgets[index]);
		widget.properties.dateRangeP1 = this.dateRange;
		if (index === this.CHART_WIDGET_INDEX) {
			let grouping = this.widgetBuilderService.getTimeGrouping(this.timeGroup);
			let chartWidget = widget as DualChartWidget;
			chartWidget.withTimeGrouping(grouping);
		} else if (index === this.TABLE_WIDGET_INDEX) {
			let grouping = _.findWhere(this.tableGroupOptions, { name: this.tableGroup });
			widget.properties.selectedAttributes = [grouping];
		}
		this.widgets[index] = widget;
	};

	isTableDrilled = (): boolean => {
		let tableWidget = this.widgets[this.TABLE_WIDGET_INDEX];
		return !!tableWidget?.drillPath;
	};
}

app.component('globalOtherExplorer', {
	controller: GlobalOtherExplorerComponent,
	templateUrl: 'partials/projects/global-other-explorer.component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
});
