import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { Inject, Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { PersonalizationStateProvider } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state-provider.service';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { HomePageWidgetConstants } from '@app/modules/home-page/home-page-common/home-page-widget-constants';
import { BehaviorSubject, Observable } from 'rxjs';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { Caches } from '@cxstudio/common/caches';


@Injectable({
	providedIn: 'root'
})
export class UserHomePageService {
	private homePageSubject: BehaviorSubject<HomePage>;
	private homePage$: Observable<HomePage>;
	private cachedHttp: CxCachedHttp;

	constructor(
		private homePageApiService: HomePageApiService,
		private personalizationStateProvider: PersonalizationStateProvider,
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private sessionPreferences: SessionPreferencesService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		@Inject('security') private security: Security,
	) {
		this.cachedHttp = this.cxCachedHttpService.cache(Caches.ORGANIZATIONS);
	}

	changeHomePage = (homePage: HomePage): void => {
		this.sessionPreferences.set('homePageId', homePage.id);
		this.homePageSubject.next(homePage);
		this.cachedHttp.invalidate();
	};

	getHomePage = (): Observable<HomePage> => {
		this.ensureHomePage();
		return this.homePage$;
	};

	private ensureHomePage(): void {
		if (!this.homePageSubject) {
			this.homePageSubject = new BehaviorSubject<HomePage>(this.security.getContext().homePage);
			this.homePage$ = this.homePageSubject.asObservable();
		}
	}

	getHomePageName = (): string => {
		this.ensureHomePage();
		return this.homePageSubject.getValue().name;
	};

	getHomePagePersonalization = (homePage: HomePage): Promise<PersonalizationState> => {
		if (!this.hasPersonalization(homePage)) return Promise.reject();

		let hierarchyId = homePage.reportProperties.hierarchyId;
		let showOrganizationContext = homePage.reportProperties.showOrganizationContext;
		let personalization = this.personalizationStateProvider.getInstance(
			hierarchyId, showOrganizationContext, HomePageWidgetConstants.CONTAINER_ID);
		return personalization.init().then(() => {
			return personalization;
		}, (errorStatus: HierarchyLoadStatus) => {
			if (errorStatus === HierarchyLoadStatus.DEACTIVATED) {
				this.cxDialogService.warning(this.locale.getString('common.error'),
					this.locale.getString('widget.hierarchyDeactivated'));
			} else if (errorStatus === HierarchyLoadStatus.NO_ACCESS) {
				this.cxDialogService.warning(this.locale.getString('common.error'),
					this.locale.getString('widget.hierarchyNoAccess'));
			}
			return Promise.reject();
		});
	};

	hasPersonalization = (homePage: HomePage): boolean => {
		if (!homePage) return false;
		let hierarchySelected = homePage.reportProperties.hierarchyId > 0;
		let hasReports = homePage.quickInsights.enabled || homePage.widgetsConfig.enabled;
		return hasReports && hierarchySelected;
	};

	getUserHomePages = (): Promise<HomePage[]> => {
		return this.homePageApiService.getUserHomePages();
	};

	getQuickInsightsWidgets = (homePageId: number): Promise<Widget[]> => {
		return this.homePageApiService.getQuickInsightsWidgets(homePageId);
	};

	getCustomWidgets = (homePageId: number): Promise<Widget[]> => {
		return this.homePageApiService.getCustomWidgets(homePageId);
	};

	getSearchAttributes = (homePageId: number): Promise<IReportAttribute[]> => {
		return this.homePageApiService.getSearchAttributes(homePageId);
	};
}
