import * as _ from 'underscore';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { SampleDataAPIServiceClass } from '@cxstudio/services/data-services/sample-data-api.service';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { IWidgetSettingsConfig, IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { TopicReportGrouping } from '@cxstudio/reports/entities/topic-report-grouping';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { CBSettingsService, IWidgetSettingsScope } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { BubbleConfigurationService } from '@app/modules/widget-settings/bubble-configuration.service';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { KeyMetricListTypes } from '../definitions/key-metric-list-types.constant';
import { RealDataPreviewWidget } from '../definitions/real-data-preview-widget.class';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ChangeUtils } from '@app/util/change-utils';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';

export class CbNetworkVisualizationComponent extends RealDataPreviewWidget {
	widgetType: WidgetType;

	readonly DEFAULTS: Partial<VisualProperties> = {
		visualization: VisualizationType.NETWORK,
		color: this.calculationColorService.SENTIMENT5,
		size: 'volume',
		showLegend: false,
		showNodeNames: true,
		showAnimation: true,
	};

	readonly DEFAULT_NODE_SIZE = 10;
	readonly DEFAULT_MIN_CO_OCCURRENCE = 10;

	readonly CO_OCCURRENCE_LEVEL_OPTIONS: Array<UIOption<boolean>> = [
		{value: false, displayName: this.locale.getString('widget.sentence')},
		{value: true, displayName: this.locale.getString('widget.interaction')}
	];
	readonly CO_OCCURRENCE_METRICS: Array<UIOption<StandardMetricName>> = [
		{value: StandardMetricName.VOLUME, displayName: this.locale.getString('widget.volumeCondition')},
		{value: StandardMetricName.PERCENT_OF_TOTAL, displayName: this.locale.getString('widget.percentTotalCondition')}
	];

	readonly SENTENCE_LEVEL_COLOR_TYPES = ['palette', 'provider', 'solid', 'group', 'calculation'];
	readonly DOCUMENT_LEVEL_COLOR_TYPES = ['palette', 'provider', 'solid'];

	loaded = false;
	settings: IWidgetSettings;
	allowedColorTypes: string[] = this.SENTENCE_LEVEL_COLOR_TYPES;
	constants;
	listTypes = KeyMetricListTypes;

	staticData: any;
	visualProps: VisualProperties;
	props: WidgetProperties;
	ui;
	widget: Widget;
	options: any;
	defaultCustomColor: string;
	utils: WidgetUtils;

	defaultColor: Partial<IColorSelectorPalette>;

	applyVisualChanges: () => void;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	clearErrors: () => void;
	sizeMetricsFilter: (metric) => boolean;
	updateCustomDateFilters: (dateFilters) => void;
	populateCurrentHierarchyCalculations: (metrics, grouping, calculations, models) => void;
	initializePeriods: () => void;
	reloadCommonSettings: (config) => ng.IPromise<IWidgetSettings>;
	updateChartSettings: (refreshType?: PreviewChartRefreshType) => void;
	showRealDataPreview: () => boolean;
	updateDataLoading: (dataLoading: Promise<any>) => void;

	constructor(
		protected $scope,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly metricConstants: MetricConstants,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private $controller,
		private locale: ILocale,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private sampleDataApiService: SampleDataAPIServiceClass,
		private cbSettingsService: CBSettingsService,
		private bubbleConfigurationService: BubbleConfigurationService,
		private cbDialogService: CBDialogService,
		private betaFeaturesService: BetaFeaturesService,
		private calculationColorService: CalculationColorService,
		private hierarchySettingsService: HierarchySettingsService,
		private currentWidgets: ICurrentWidgets


	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit = () => {
		this.initScopeProps();
		super.$onInit();

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this,
			selectionConfiguration: {
				multiAttributeSelection: false
			},
			customCallback: {}
		});

		this.constants = this.metricConstants.get();

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.staticData.ready = false;

		this.setDefaults();

		this.ui = this.ui || {};
		delete this.ui.periods;

		this.initProps();
		this.initialize();
	};

	$onChanges = (changes) => {
		if (ChangeUtils.hasChange(changes.utils)) {
			this.utils = changes.utils.currentValue;
		}
	};

	private initScopeProps(): void {
		this.$scope.widget = this.widget;
		this.$scope.widgetType = this.props.widgetType;
		this.$scope.props = this.props;
		this.$scope.visualProps = this.visualProps;
		this.$scope.options = this.options;
		this.$scope.staticData = this.staticData;
		this.$scope.updateChartSettings = this.updateChartSettings;
	}

	$on = (event: string, handler: () => void): () => void => {
		return this.$scope.$on(event, handler);
	};

	reloadSettings = (event?, callback?: (result: boolean) => void): void => {
		this.initProps();

		let config: IWidgetSettingsConfig = {
			withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
		};

		this.reloadCommonSettings(config).then(result => {
			this.clearErrors();
			this.cbSettingsService.initializeCommonSettings(this, result);

			if (this.loaded) {
				this.resetSelections();
			}
			this.loaded = true;

			this.settings = result;

			this.options.networkGroupings = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withModels(result.models)
				.withAttributes(this.options.attributes, this.networkAttributeFilter)
				.withWordAttributes(result.wordAttributes)
				.withOrgHierarchyModels(result.models, result.hierarchyModels)
				.build();

			// filter groups for hierarchy
			const personalizationState = this.currentWidgets.getPersonalization(this.$scope.widget?.containerId);
			this.options.networkGroupings = this.hierarchySettingsService.filterSelectedAttributesForGrouping(
				this.options.networkGroupings,
				personalizationState.isHierarchyEnabled(),
				personalizationState.getHierarchyId()
			);

			this.cbSettingsService.initDefaultProperties(this.options.networkGroupings);

			this.reloadMetricsAndReferences();

			this.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
			this.options.studioMetrics = _.filter(result.metrics, (metric: any) => metric.projectId === this.props.project);

			this.updateCustomDateFilters(result.dateFilters);
			this.applyVisualChanges();
			callback?.(true);
		});
	};

	private initProps = (): void => {
		if (_.isEmpty(this.props.selectedAttributes)) {
			this.props.selectedAttributes = [undefined];
		}

		if (!this.visualProps.attributeSelections) {
			this.visualProps.attributeSelections = {};
		}
		if (!this.props.selectedMetrics) {
			this.props.selectedMetrics = [];
			this.selectSize(this.constants.VOLUME);
		}

		this.ui.periods = [{
			field: 'dateRangeP1',
			name: 'period_1_'
		}];

		this.initializePeriods();
	};

	private reloadMetricsAndReferences = (): void => {
		this.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(_.union(this.metricConstants.getStandardCalculations(), [this.constants.CONSTANT_SIZE]))
			.withPredefinedMetrics(this.settings.predefinedMetrics)
			.withAttributes(this.options.attributes, MetricFilters.CALCULATION)
			.withMetrics(this.settings.metrics, this.props.project)
			.withScorecardMetrics(this.settings.scorecardMetrics)
			.withDocumentLevelOnly(this.props.documentLevelOnly)
			.filterAvailableOptions(this.sizeMetricsFilter)
			.build();

		this.populateCurrentHierarchyCalculations(this.options.additionalMetrics, this.settings.hierarchyGrouping,
			this.settings.organizationCalculations, this.settings.hierarchyModels);

		this.options.cogSortByMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
			.withStandardMetrics(this.metricConstants.getStandardCalculations())
			.withPredefinedMetrics(this.settings.predefinedMetrics)
			.withAttributes(this.options.attributes, MetricFilters.CALCULATION)
			.withMetrics(this.settings.metrics, this.props.project)
			.withDocumentLevelOnly(this.props.documentLevelOnly)
			.build();

		this.populateCurrentHierarchyCalculations(this.options.cogSortByMetrics, this.settings.hierarchyGrouping,
			this.settings.organizationCalculations, this.settings.hierarchyModels);

		this.allowedColorTypes = this.props.documentLevelOnly ? this.DOCUMENT_LEVEL_COLOR_TYPES : this.SENTENCE_LEVEL_COLOR_TYPES;

		if (this.loaded && this.props.documentLevelOnly) {
			this.removeSentenceLevelReferences();
		}

	};

	private removeSentenceLevelReferences = (): void => {
		let grouping = this.props.selectedAttributes[0];
		if (!SearchableHierarchyUtils.findMetricNameInHierarchy(this.options.cogSortByMetrics, grouping.sortBy)) {
			grouping.sortBy = 'volume';
		}
		let thresholdMetricName = grouping.displayThreshold?.metricName;
		if (thresholdMetricName && !SearchableHierarchyUtils.findMetricNameInHierarchy(this.options.cogSortByMetrics, thresholdMetricName)) {
			grouping.displayThreshold = {};
		}
		let nodeSize = this.props.selectedMetrics[0];
		if (!SearchableHierarchyUtils.findMetricInHierarchy(this.options.additionalMetrics, nodeSize)) {
			this.selectSize(this.constants.VOLUME);
		}
		if (this.calculationColorService.isCalculationColor(this.visualProps.color)) {
			this.props.selectedMetrics.splice(1);
			this.visualProps.color = 'custom';
		}
	};

	private initialize = (): void => {
		this.addLoadingPromise(this.sampleDataApiService.getNetworkData().then((resp) => {
			let data = resp.data;

			this.staticData.data = data;
			this.staticData.totalCount = 157;
			this.ui.staticData.networkData = data.networkData;

			this.applyVisualChanges();
		}));

	};

	private setDefaults = (): void => {
		for (let key in this.DEFAULTS) {
			if (_.isUndefined(this.visualProps[key]) || this.widget.created)
				this.visualProps[key] = this.DEFAULTS[key];
		}

		if (_.isUndefined(this.props.minCoOccurrence)) {
			this.props.minCoOccurrence = this.DEFAULT_MIN_CO_OCCURRENCE;
		}
		if (_.isUndefined(this.props.documentLevelOnly)) {
			this.props.documentLevelOnly = false;
		}
		if (_.isUndefined(this.props.minCoOccurrenceMetric)) {
			this.props.minCoOccurrenceMetric = StandardMetricName.VOLUME;
		}
	};

	private resetSelections = (): void => {
		this.props.selectedMetrics = [];
		this.props.selectedAttributes = [undefined];
		this.props.documentLevelOnly = false;
		this.visualProps.color = this.DEFAULTS.color;
		this.selectSize(this.constants.VOLUME);

		this.applyVisualChanges();
	};

	private networkAttributeFilter = (option): boolean => {
		return option.derivedFromCategory || option.multiValue;
	};

	selectSize = (node): void => {
		if (node.name || node.name === '') {
			this.props.selectedMetrics[0] = node;
			this.visualProps.showLabels = false;
		} else {
			this.props.selectedMetrics.removeAt(0);
		}
		this.visualProps.size = node.name;

		this.applyVisualChanges();
	};

	configureNodeSize = (): void => {
		let map = {
			scale: 'scale'
		};

		this.bubbleConfigurationService.configure(map, this.visualProps).then((result) => {
			$.extend(this.visualProps, result);
			this.applyVisualChanges();
		});
	};

	onGroupingChange = (grouping): void => {
		grouping.size = this.DEFAULT_NODE_SIZE;
		if (grouping.model) {
			grouping.selectedLevel = TopicReportGrouping.LEAF_LEVEL;
			grouping.type = ReportAssetType.TOPIC_LEAF;
		} else if (this.props.documentLevelOnly) {
			this.props.documentLevelOnly = false;
			this.onCoOccurrenceLevelChange(false);
		}
	};

	isModelGrouping = (grouping): boolean => AnalyticMetricTypes.isTopics(grouping);

	onCoOccurrenceLevelChange = (value: boolean): void => {
		if (value === true) {
			let confirmation = this.cbDialogService.confirm(
				this.locale.getString('common.warning'),
				this.locale.getString('widget.interactionWarning'),
				this.locale.getString('common.ok'),
				this.locale.getString('common.cancel'));

			confirmation.result.then(
				this.updateCoOccurenceLevel,
				() => this.props.documentLevelOnly = false);
		} else if (value === false) {
			this.updateCoOccurenceLevel();
		}
	};

	private updateCoOccurenceLevel = (): void => {
		this.reloadMetricsAndReferences();
		if (this.showRealDataPreview()) {
			this.realDataPreviewService.checkChangesForPreview(this.widget);
		}
	};

	coOccurenceMetricChanged = (): void => {
		if (this.props.minCoOccurrenceMetric === StandardMetricName.PERCENT_OF_TOTAL && this.props.minCoOccurrence > 100) {
			this.props.minCoOccurrence = 100;
		}
		this.updateChartSettings();
	};

	getWidgetDefaultColor = (): Partial<IColorSelectorPalette> => {
		return this.defaultColor;
	};

	onColorInputChange = (): void => {
		if (!this.realDataPreviewService.hasPreviewChanges()) {
			this.applyVisualChanges();
		}
	};

}

app.component('cbNetworkVisualization', {
	bindings: {
		props: '<',
		loadingPromise: '<',
		addLoadingPromise: '<',
		visualProps: '=',
		widget: '<',
		ui: '<',
		updateChartSettings: '<',
		showRealDataPreview: '<',
		updateDataLoading: '<',
		realDataLoading: '<',
		clearErrors: '<',
		staticData: '<',
		options: '<',
		updateAutoTitle: '<',
		utils: '<',
		redrawTrigger: '<',
		dashboardFiltersApplied: '<',
		intersectionObserver: '<',
		defaultColor: '<',
	},
	controller: CbNetworkVisualizationComponent,
	templateUrl: 'partials/widgets/settings/cb/components/cb-network-visualization.component.html'
});
