export class TableServiceUtils {
	static postRenderMobile = (element) => {
		setTimeout(() => {
			let sum = 0;
			element.find('.grid-canvas>.slick-row:not(.new-row)')
				// eslint-disable-next-line prefer-arrow-callback
				.each(function(): void {
					let jqueryThis = this;  // eslint-disable-line no-invalid-this, @typescript-eslint/no-this-alias
					sum += $(jqueryThis).height() + 21;
				});
			element.parents('.br-widget-content').height(sum + 30);
		}, 100);
	};

	static postRenderRegular = (element) => {
		setTimeout(() => {
			element.parents('.br-widget-content').height('');
		}, 100);
	};
}
