import { Component, ChangeDetectionStrategy, Input, Output, EventEmitter } from '@angular/core';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CxLocaleService } from '@app/core';

export enum WidgetMovementOptions {
	PUSH_WHILE_DRAGGING = 'pushWhileDragging',
	PUSH_ON_DROP = 'pushWidgetsOnDrop'
}

@Component({
	selector: 'widget-movement-options',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<div class="normal-line-height ph-16">
			<cb-radio-group
				[options]="options"
				[ngModel]="widgetMovementOption"
				(ngModelChange)="updateOption($event)">
			</cb-radio-group>
	</div>`,
	styles: [
		`
			:host ::ng-deep .radio-wrapper span {
				white-space: nowrap !important;
				text-transform: capitalize !important;
			}

		`
	]
})

export class WidgetMovementOptionsComponent {

	@Input() widgetMovementOption: any;
	@Output() onWidgetMovementChange = new EventEmitter<any>();
	options: UIOption<string>[] = [
		{
			value: WidgetMovementOptions.PUSH_WHILE_DRAGGING,
			displayName: this.locale.getString('home.pushWhileDragging'),
			htmlId: 'pushWhileDragging'
		},
		{
			value: WidgetMovementOptions.PUSH_ON_DROP,
			displayName: this.locale.getString('home.pushWidgetsOnDrop'),
			htmlId: 'pushOnDrop'
		}
	];

	constructor(private readonly locale: CxLocaleService) { }

	updateOption = ($event: string): void => {
		this.onWidgetMovementChange.emit($event);
	};

}
