import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';


@Component({
	selector: 'dashboard-rating-tooltip',
	template: `
<div *ngIf="dashboard" id="dash-rating" role="tooltip" class="br-dash-tooltip"
	(mouseenter)="onHover.emit()"
	(mouseleave)="onHide.emit()">

	<div class="_t-rating-selector d-flex m-0 p-8 text-default"
		(mouseleave)="resetRating()">
		<div *ngFor="let star of [1,2,3,4,5]"
			class="cursor-pointer mr-4"
			(click)="updateRating()">
			<i class="br-rating-star p-relative"
				[class.q-icon-star]="rating >= star"
				[class.q-icon-star-outline]="rating < star - 0.5"
				[class.q-icon-star-half-outline]="rating >= star - 0.5 && rating < star">
				<div class="half-star left"
					(mouseover)="rating = star - 0.5">
				</div>
				<div class="half-star right"
					(mouseover)="rating = star">
				</div>
			</i>
		</div>
		<span *ngIf="rating" class="ml-16">{{rating | number:'1.1-1'}}/5</span>
		<span *ngIf="!rating" class="ml-16">&nbsp;&nbsp;-/5</span>
	</div>
	<div class="br-arrow"></div>
</div>
<div class="hover-keeper"></div>`, // used to ignore mouseLeave event
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		.hover-keeper {
			position: absolute;
			top: -20px;
			left: -50px;
			width: 100px;
			height: 60px;
			z-index: 1000;
		}
		.half-star {
			position: absolute;
			top: 0;
			height: 100%;
			width: 50%;
		}
		.half-star.left {
			left: 0;
		}
		.half-star.right {
			right: 0;
		}
	`]
})

export class DashboardRatingTooltipComponent implements OnInit {
	@Input() dashboard: Dashboard;
	@Output() onHover = new EventEmitter<void>();
	@Output() onHide = new EventEmitter<void>();
	@Output() onUpdate = new EventEmitter<number>();

	rating: number;

	ngOnInit(): void {
		this.resetRating();
	}

	updateRating(): void {
		this.onUpdate.emit(Math.round(this.rating * 2));
	}

	resetRating(): void {
		this.rating = this.dashboard.userRating / 2;
	}
}
