import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { DashboardId } from '@cxstudio/generic-types';

export class DashboardHistoryApiService {
	constructor(
		private readonly $http
	) {}

	getDashboardVersions = (dashboardId: DashboardId) =>
		this.$http.get(`rest/dashboard/${dashboardId}/versions`).then(HttpHandlers.success);

	getWidgets = (dashboardId: DashboardId, versionId: number) =>
		this.$http.get(`rest/widgets/${dashboardId}/history/${versionId}`).then(HttpHandlers.success);

	revertWidgets = (dashboardId: DashboardId, versionId: number) =>
		this.$http.post(`rest/widgets/${dashboardId}/history/${versionId}`).then(HttpHandlers.success);
}

app.service('dashboardHistoryApiService', DashboardHistoryApiService);
