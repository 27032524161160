import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NarrativeSettingEntry, NarrativeSettingsListComponent } from './narrative-settings-list.component';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable()
export class NarrativeSettingsGridDefinition implements IGridDefinition<NarrativeSettingsListComponent> {

	constructor(
		private locale: CxLocaleService, 
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [ {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: true,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'name',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.AUTOMATED_NARRATIVE),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'projectName',
			name: this.locale.getString('common.project'),
			field: 'projectName',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.AUTOMATED_NARRATIVE),
			isObjectNameColumn: true
		}, {
			id: 'attributeDisplayName',
			name: this.locale.getString('dashboard.attributeName'),
			field: 'attributeDisplayName',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 150,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.AUTOMATED_NARRATIVE),
			isObjectNameColumn: true
		}, {
			id: 'modifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}];

		return Promise.resolve(columns);
	};

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: NarrativeSettingEntry) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.name);
		return getSwitch(value);
	};
}

app.service('narrativeSettingsGridDefinition', downgradeInjectable(NarrativeSettingsGridDefinition));