import * as _ from 'underscore';

import { ModalBindings } from '@cxstudio/common/modal-bindings';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { AssetComponent } from '@cxstudio/asset-management/components/asset-component';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import IProjectsProvider from '@cxstudio/common/projects-provider-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Security } from '@cxstudio/auth/security-service';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';

import { Pagination } from '@app/shared/components/pagination/pagination';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { ComponentTypesProvider } from '@app/modules/asset-management/services/components-types-provider';
import { BookComponentsSharingUtil } from '@app/modules/asset-management/services/book-components-sharing-util';
import { ComponentsColumnsProvider } from '@app/modules/asset-management/services/columns-providers/components-columns-provider';
import { DashboardComponentsApiService } from '@app/modules/dashboard/services/assets/dashboard-components-api.service';
import { DashboardComponentsSharingUtil } from '@app/modules/dashboard/services/assets/dashboard-components-sharing-utils';
import { DashboardWidgetsProjectsProvider } from '@app/modules/dashboard/services/assets/dashboard-widgets-projects-provider.service';
import { Book, BookDashboardTab, BookTabType, IBookTab } from '@cxstudio/dashboards/entity/book';


interface IComponentsDialogParams {
	asset: ProjectAsset;
	projectSelection: IProjectSelection;
	dashboards?: any[];
	book?: Book;
}

interface IBookComponent {
	status: string;
	name: string;
	owner?: string;
	lastRun?: string;
}

export class ComponentsModalController extends ModalBindings<IComponentsDialogParams> {
	loading: any;
	preselectedProject: Partial<FavoriteProperties>;
	projectsProvider: IProjectsProvider;
	props: IProjectSelection;
	hideProjectSelector: boolean;
	hasComponentsForSharing: boolean;

	componentTypes: Array<AssetObjectType<ProjectAsset, AssetComponent>>;
	componentType: AssetObjectType<ProjectAsset, AssetComponent>;

	pagination: Pagination;
	columns: Array<TableColumn<any>>;
	rows: any[];

	constructor(
		private locale: ILocale,
		private componentTypesProvider: ComponentTypesProvider,
		private componentsColumnsProvider: ComponentsColumnsProvider,
		private cbDialogService: CBDialogService,
		private dashboardComponentsApiService: DashboardComponentsApiService,
		private $uibModal: ng.ui.bootstrap.IModalService,
		private security: Security,
		private dashboardComponentsSharingUtil: DashboardComponentsSharingUtil,
		private dashboardWidgetsProjectsProvider: DashboardWidgetsProjectsProvider,
	) {
		super();
	}

	$onInit(): void {
		this.loading = {};
		this.props = {} as IProjectSelection;
		this.hideProjectSelector = this.isBook();
		if (!this.hideProjectSelector) {
			this.preselectedProject = {
				favoriteCP: this.resolve.projectSelection.contentProviderId,
				favoriteAccount: this.resolve.projectSelection.accountId,
				favoriteProject: this.resolve.projectSelection.projectId
			};

			this.projectsProvider = this.dashboardWidgetsProjectsProvider.getInstance(this.resolve.asset);
		}

		this.pagination = new Pagination(20);
		this.initComponentTypes();
	}

	private initComponentTypes = (): void => {
		this.componentTypes = this.componentTypesProvider.componentTypes;
		this.componentType = this.componentTypes[0];
		this.reload();
	};

	getTitle = (): string => {
		return this.isDashboard()
			? this.locale.getString('dashboard.dashboardComponentsTitle', {dashboard: this.resolve.asset.name})
			: this.locale.getString('dashboard.bookComponentsTitle', {book: this.resolve.asset.name});
	};

	componentTypeChanged = (): void => {
		this.pagination.currentPage = 1;
		this.reload();
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reload();
	};

	reload = (): void => {
		this.reloadComponents();
		this.reloadComponentStatistics();
	};

	private reloadComponents = (): void => {
		if (this.isDashboard()) {
			this.reloadDashboardComponents();
		} else if (this.isBook()) {
			this.reloadBookComponents();
		}
	};

	private reloadComponentStatistics = (): void => {
		if (this.hasPermissionToShareComponents()) {
			if (this.isDashboard()) {
				this.loading.promise = this.dashboardComponentsSharingUtil.getSharableDashboardComponents(this.getAssetWithProject()).then(result => {
					this.hasComponentsForSharing = !_.isEmpty(result.metrics) || !_.isEmpty(result.filters);
				});
			} else if (this.isBook()) {
				this.hasComponentsForSharing = BookComponentsSharingUtil.getSharableBookDashboards(this.resolve.dashboards, this.resolve.book.tabs)
					.length > 0;
			}
		}
	};

	private reloadDashboardComponents = (): void => {
		if (!this.props.projectId) return;
		this.loading.promise = this.componentType.handler(
			this.getAssetWithProject(),
			this.pagination
		);
		this.loading.promise.then((response) => {
			this.rows = response.data;
			this.columns = this.componentsColumnsProvider.getColumns(this.componentType.type);
			this.pagination.totalItems = response.totalCount;
		});
	};

	private reloadBookComponents = (): void => {
		let bookTabs: IBookTab[] = this.resolve.book.tabs;

		let components = bookTabs
			.filter(tab => tab.type === BookTabType.DASHBOARD)
			.map(tab => {
				let tabProps = tab.properties as BookDashboardTab;
				let dashboard = _.findWhere(this.resolve.dashboards, {id: tabProps.dashboardId});
				let componentData: IBookComponent = {} as IBookComponent;
				if (dashboard) {
					componentData.owner = dashboard.ownerName;
					componentData.lastRun = dashboard.useDate;
					componentData.status = dashboard.sharingStatus;
					componentData.name = dashboard.name;
				} else {
					componentData.name = tab.name;
					componentData.status = this.locale.getString('dashboard.notSharedBookComponent');
				}
				return componentData;
			});

		this.columns = this.componentsColumnsProvider.getBookColumns();
		this.rows = _.sortBy(components, (component) => {
			return component.name;
		});
	};

	projectChanged = (newProps: any): void => {
		if (this.hideProjectSelector)
			return;
		if (newProps)
			this.props = newProps;
		if (!this.props.projectId) {
			this.clear();
		} else {
			this.reload();
		}
	};

	hasNote = (): boolean => {
		return !_.isUndefined( this.componentType.note);
	};

	getNote = (): string => {
		return this.componentType.note;
	};

	export = (): void => {
		if (this.isDashboard()) {
			this.exportDashboardComponents();
		}

		if (this.isBook()) {
			this.exportBookComponents();
		}
	};

	private exportDashboardComponents = (): void => {
		this.componentTypesProvider.exportHandler.export(this.resolve.asset).then(response => {
			let dialogMessage = this.locale.getString('administration.exportToExcelMessage');
			let dialogTitle = this.locale.getString('common.exportComponentsTitle',
				{type: this.componentTypesProvider.title, object: this.resolve.asset.name});

			this.cbDialogService.notify(dialogTitle, dialogMessage);
		});
	};

	private exportBookComponents = (): void => {
		let exportData = {
			bookId: this.resolve.asset.assetId,
			bookName: this.resolve.asset.name,
			rowData: this.rows
		};

		this.dashboardComponentsApiService.exportBookComponents(exportData).then(response => {
			let dialogMessage = this.locale.getString('administration.exportToExcelMessage');
			let dialogTitle = this.locale.getString('common.exportComponentsTitle',
				{type: this.locale.getString('object.book'), object: this.resolve.asset.name});

			this.cbDialogService.notify(dialogTitle, dialogMessage);
		});
	};

	private getAssetWithProject = (): ProjectAsset => {
		let projectAsset: ProjectAsset = angular.copy(this.resolve.asset);
		projectAsset.contentProviderId = this.props.contentProviderId;
		projectAsset.projectId = this.props.projectId;
		return projectAsset;
	};

	private clear(): void {
		this.rows = [];
	}

	private isDashboard(): boolean {
		return this.resolve.asset.type === 'dashboard';
	}

	private isBook(): boolean {
		return this.resolve.asset.type === 'dashboardSet';
	}

	shareComponents = (): void => {
		if (this.isDashboard()) {
			this.openShareComponentsDialog('dashboardShareComponentsModal', {
				dashboard: () => this.resolve.dashboards[0]
			});
		} else if (this.isBook()) {
			this.openShareComponentsDialog('bookShareComponentsModal', {
				book: () => this.resolve.book,
				dashboards: () => this.resolve.dashboards
			});
		}
	};

	private openShareComponentsDialog = (component: string, resolveExtension: any): void => {
		let resolve = {
			asset: () => {
				return {
					assetId: this.resolve.asset.assetId,
					name: this.resolve.asset.name,
					type: this.resolve.asset.type
				};
			},
			projectSelection: () => {
				return {
					contentProviderId: this.props.contentProviderId,
					accountId: this.props.accountId,
					projectId: this.props.projectId
				};
			},
			dashboard: () => this.resolve.dashboards[0]
		};

		resolve = _.extend(resolve, resolveExtension);

		this.$uibModal.open({
			component,
			resolve,
			backdrop: 'static',
			size: 'md'
		});
	};

	hasPermissionToShareComponents = (): boolean => {
		if (this.isDashboard()) {
			return this.hasFilterSharePermission() || this.hasMetricSharePermission();
		} else if (this.isBook()) {
			return this.hasDashboardSharePermission();
		} else {
			return false;
		}
	};

	showShareComponents = (): boolean => {
		return this.hasPermissionToShareComponents() && this.hasComponentsForSharing;
	};

	private hasMetricSharePermission(): boolean {
		return this.security.has('share_metric') || this.security.has('share_edit_metric');
	}

	private hasFilterSharePermission(): boolean {
		return this.security.has('share_filter') || this.security.has('share_edit_filter');
	}

	private hasDashboardSharePermission(): boolean {
		return this.security.has('share_view') || this.security.has('share_edit');
	}

}

app.component('componentsModal', {
	controller: ComponentsModalController,
	templateUrl: 'partials/asset-management/components-modal-component.html',
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
});
