import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { SentencePredefinedFilter } from '@app/modules/document-explorer/sentence-predefined-filter';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { PredefinedMetricIcons } from '@app/util/predefined-metric-icons';

interface ISortButton {
	value: string;
	icon: string;
	title: string;
	isDisabled: () => boolean;
}

@Component({
	selector: 'document-explorer-sort',
	template: `
<button *ngFor="let sortButton of sortButtons"
	class="btn btn-secondary"
	[disabled]="sortButton.isDisabled()"
	(click)="sort.emit(sortButton.value)"
	[class.btn-selected]="sortBy === sortButton.value"
	[attr.aria-label]="sortButton.title"
	[attr.aria-sort]="getAriaSort(sortButton.value)"
	[title]="sortButton.title">
	<i class="q-icon" [ngClass]="sortButton.icon"></i>
	<i class="ml-4 text-0_875rem q-icon"
		[ngClass]="isSortAscending(sortButton.value) ? 'q-icon-sort-up' : 'q-icon-sort-down'">
	</i>
</button>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerSortComponent extends SelfCleaningComponent implements OnInit {

	@Input() sentimentFilters: SentencePredefinedFilter;
	@Input() easeScoreFilters: SentencePredefinedFilter;

	@Input() sortBy: string;
	@Input() sortDirection: AnSortDirection;

	@Output() sort = new EventEmitter<string>();

	sortButtons: ISortButton[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly betaFeaturesService: BetaFeaturesService,
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.sentimentFilters.getChangeObserver().subscribe(() => this.ref.markForCheck()));
		this.addSubscription(this.easeScoreFilters.getChangeObserver().subscribe(() => this.ref.markForCheck()));
		const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
		const sentimentSortValue = isMlSentimentEnabled ? PreviewSortAttributes.ML_SENTIMENT : PreviewSortAttributes.SENTIMENT;
		this.sortButtons = [{
			value: sentimentSortValue,
			icon: PredefinedMetricIcons.getSentimentSymbolIcon(isMlSentimentEnabled),
			title: this.locale.getString('docExplorer.sortBySentiment'),
			isDisabled: () => this.sentimentFilters.isEmpty(),
		}, {
			value: PreviewSortAttributes.EFFORT,
			icon: 'q-icon-effort-positive',
			title: this.locale.getString('docExplorer.sortByEase'),
			isDisabled: () => this.easeScoreFilters.isEmpty(),
		}, {
			value: PreviewSortAttributes.DOC_DATE,
			icon: 'q-icon-calendar',
			title: this.locale.getString('docExplorer.sortByDate'),
			isDisabled: () => false,
		}];
	}

	getAriaSort(sortBy: string): string {
		if (this.isSortedBy(sortBy, AnSortDirection.ASC)) {
			return 'ascending';
		} else if (this.isSortedBy(sortBy, AnSortDirection.DESC)) {
			return 'descending';
		} else {
			return 'none';
		}
	}

	isSortAscending(sortBy: string): boolean {
		return this.isSortedBy(sortBy, AnSortDirection.ASC);
	}

	private isSortedBy(sortBy: string, direction: AnSortDirection): boolean {
		return this.sortBy === sortBy && this.sortDirection === direction;
	}

}
