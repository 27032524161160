import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { NumberFormatSettings, TextAttributeGroupingSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { AttributeSettingsApiService } from '@app/modules/project/attribute/attribute-settings-api.service';
import { WorkspaceAttributeSettingsApiService } from '@app/modules/project/attribute/workspace-attribute-settings-api.service';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { NumericAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/numeric-attribute-data';
import { ProjectSettingsData } from '@cxstudio/asset-management/configuration/settings-data/project-settings-data';
import { TextAttributeSettings } from '@cxstudio/asset-management/configuration/settings-data/text-attribute-data';
import { CacheOptions } from '@cxstudio/common/cache-options';


export interface ProjectAttributeSettingsMap {
	textAttributes: {[attributeId: number]: TextAttributeGroupingSettings};
	numericAttributes: NumericFormats;
}
export interface NumericFormats {
	[attributeId: number]: NumberFormatSettings;
}

@Injectable({
	providedIn: 'root'
})
export class AttributeSettingsService {
	constructor(
		private readonly attributeSettingsApiService: AttributeSettingsApiService,
		private readonly workspaceAttributeSettingsApiService: WorkspaceAttributeSettingsApiService,
	) {}

	getTextAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeId: number, cache: CacheOptions
	): AssetPromise<TextAttributeSettings> => {
		return ProjectAssetsHelper.getAssetsForProject<TextAttributeSettings>(
			project,
			(accountProject) => this.attributeSettingsApiService.getTextAttributeSettings(
				accountProject, attributeId, cache),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.getTextAttributeSettings(
				workspaceProject, attributeId, cache),
			() => ({ grouping: {} } as TextAttributeSettings)
		);
	};

	saveTextAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeId: number, settings: TextAttributeSettings
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.attributeSettingsApiService.saveTextAttributeSettings(accountProject, attributeId, settings),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.saveTextAttributeSettings(
				workspaceProject, attributeId, settings),
			() => {}
		);
	};

	getNumericAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeId: number, cache: CacheOptions
	): AssetPromise<NumericAttributeSettings> => {
		return ProjectAssetsHelper.getAssetsForProject<NumericAttributeSettings>(
			project,
			(accountProject) => this.attributeSettingsApiService.getNumericAttributeSettings(
				accountProject, attributeId, cache),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.getNumericAttributeSettings(
				workspaceProject, attributeId, cache),
			() => ({ grouping: {}, calculation: {} } as NumericAttributeSettings)
		);
	};

	saveNumericAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeId: number, settings: NumericAttributeSettings
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.attributeSettingsApiService.saveNumericAttributeSettings(accountProject, attributeId, settings),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.saveNumericAttributeSettings(
				workspaceProject, attributeId, settings),
			() => {}
		);
	};

	getWordAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeName: string, cache: CacheOptions
	): AssetPromise<TextAttributeSettings> => {
		return ProjectAssetsHelper.getAssetsForProject<TextAttributeSettings>(
			project,
			(accountProject) => this.attributeSettingsApiService.getWordAttributeSettings(
				accountProject, attributeName, cache),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.getWordAttributeSettings(
				workspaceProject, attributeName, cache),
			() => ({ grouping: {} } as TextAttributeSettings)
		);
	};

	saveWordAttributeSettings = (
		project: AccountOrWorkspaceProject, attributeName: string, settings: TextAttributeSettings
	): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.attributeSettingsApiService.saveWordAttributeSettings(accountProject, attributeName, settings),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.saveWordAttributeSettings(
				workspaceProject, attributeName, settings),
			() => {}
		);
	};

	getProjectSettings(project: AccountOrWorkspaceProject): AssetPromise<ProjectAttributeSettingsMap> {
		let settingsPromise = ProjectAssetsHelper.getAssetsForProject<ProjectSettingsData>(
			project,
			(accountProject) => this.attributeSettingsApiService.getProjectSettings(
				accountProject, CacheOptions.CACHED),
			(workspaceProject) => this.workspaceAttributeSettingsApiService.getProjectSettings(
				workspaceProject, CacheOptions.CACHED),
			() => ({ textAttributesSettings: [], numericAttributesSettings: [] })
		);

		return settingsPromise.then(projectSettings => {
			let result: ProjectAttributeSettingsMap = {
				textAttributes: {},
				numericAttributes: {}
			};
			for (let attributeData of projectSettings.textAttributesSettings) {
				result.textAttributes[attributeData.attributeId] = attributeData.settings.grouping;
			}
			for (let attributeData of projectSettings.numericAttributesSettings) {
				result.numericAttributes[attributeData.attributeId] = attributeData.settings.calculation;
			}
			return result;
		}) as AssetPromise<ProjectAttributeSettingsMap>;
	}
}

app.service('attributeSettingsService', downgradeInjectable(AttributeSettingsService));
