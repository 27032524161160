// currently only one type of discussion exists
export enum DiscussionTypes {
	DASHBOARD = 'dashboard'
}

export class DiscussionService {
	readonly DEFAULT_LAZY_LOAD_LIMIT = 20;

	constructor(
		private readonly $http: ng.IHttpService
	) {}

	private getComments(type: DiscussionTypes, objectId: number, params): ng.IPromise<string[]> {
		const requestParams = {
			params,
			local: true // ignore errors
		};

		return this.$http.get(`rest/discussion/${type}/${objectId}/comments`, requestParams).
			then((resp: {data: string[]}) => resp.data, reason => reason.data);
	}

	loadNewComments = (type: DiscussionTypes, objectId: number, fromTime: string) => {
		if (!fromTime) {
			fromTime = new Date().toISOString();
		}
		const params = { fromTime };
		return this.getComments(type, objectId, params);
	};

	loadPreviousComments = (type: DiscussionTypes, objectId: number, toTime, limit: number) => {
		if (!limit) {
			limit = this.DEFAULT_LAZY_LOAD_LIMIT;
		}
		if (!toTime) {
			toTime = new Date().toISOString();
		}

		const params = { limit, toTime };
		return this.getComments(type, objectId, params);
	};

	saveNewComment = (type: DiscussionTypes, objectId: number, comment: string): ng.IPromise<any> => {
		return this.$http.post(`rest/discussion/${type}/${objectId}/comments`, comment).
			then(resp => {
				return resp.data;
			}, err => err.data);
	};

	toggleLikeButton = (type: DiscussionTypes, objectId: number, commentId: number): ng.IPromise<any> => {
		return this.$http.put(`rest/discussion/${type}/${objectId}/comments/${commentId}/toggle_like_button`, undefined).
			then(resp => resp.data, err => err.data);
	};

	deleteComment = (type: DiscussionTypes, objectId: number, commentId: number): ng.IPromise<any> => {
		return this.$http.delete(`rest/discussion/${type}/${objectId}/comments/${commentId}`).
			then(resp => resp.data, err => err.data);
	};
}

/**
 * Service for discussion directive
 */
app.service('discussionService', DiscussionService);
