import { downgradeComponent } from '@angular/upgrade/static';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { QualtricsIconsId } from '@discover/unified-icons/src/types/qualtrics-icons';

export interface IMatch {
	model: {
		isOwner: boolean;
		shared: boolean;
		action: string;
		iconType: string;
	};
	label: string;
}

@Component({
	selector: 'asset-share-invite',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex align-items-center justify-between">
			<div class="text-ellipsis">
				<icon *ngIf="getIcon()" name="{{ getIcon() }}" className="user-icon-spacing mr-8 w-1_5em"></icon>
				<span [innerHTML]="match.label | highlightMatch:query"></span>
			</div>

			<span class="ml-32">{{ getStatusLabel() }}</span>
		</div>
	`
})
export class AssetShareInviteComponent {
	@Input() match: IMatch;
	@Input() query: string;

	constructor(private readonly locale: CxLocaleService) { }

	getStatusLabel = (): string => {
		if (!this.match.model.shared) return '';

		if (this.match.model.isOwner) {
			return this.locale.getString('common.owner');
		}

		if (this.match.model.action === ShareAction.ADD) {
			return this.locale.getString('common.added');
		}

		return this.locale.getString('common.shared');
	};

	getIcon = (): QualtricsIconsId | undefined => {
		const iconType = this.match?.model?.iconType;

		if (iconType === 'user') return 'user';
		if (iconType === 'group') return 'users';
		if (iconType === 'organizationGroup') return 'flow';
		if (iconType === 'cbUsers') return 'qualtrics-xm';
	};
}

app.directive('assetShareInvite', downgradeComponent({ component: AssetShareInviteComponent }));
