import { MasterAccountWorkspaceStatus } from './master-account-workspace-status';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceStatusApi {
	constructor(private cxHttp: CxHttpService) {}

	getWorspaceStatus = (): Promise<MasterAccountWorkspaceStatus[]> => {
		let url = 'rest/system-admin/status/workspaces';
		return this.cxHttp.get(url);
	};

	export = (search: IntegrationSatusSearch): Promise<void> => {
		let url = 'rest/system-admin/status/workspaces/export';
		return this.cxHttp.post(url, search);
	};
}
