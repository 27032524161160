import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { Security } from '@cxstudio/auth/security-service';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DateService } from '@cxstudio/services/date-service.service';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { downgradeComponent } from '@angular/upgrade/static';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { TimezoneService } from '@app/shared/services/timezone.service';

@Component({
	selector: 'pdf-header',
	templateUrl: './pdf-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfHeaderComponent extends SelfCleaningComponent implements OnInit {
	@Input() dashboard: Dashboard;
	@Input() personalization: PersonalizationState;
	user: User;

	constructor(
		private readonly ref: ChangeDetectorRef,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		@Inject('dashboardExportService') private readonly dashboardExportService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('dateService') private readonly dateService: DateService,
		private readonly timezoneService: TimezoneService,
		@Inject('dashboardFiltersService') private readonly dashboardFiltersService: DashboardFiltersService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardFilterLabels') public dashboardFilterLabels: DashboardFilterLabelsService,
		private readonly locale: CxLocaleService
	) {
		super();
	}

	ngOnInit(): void {
		this.user = this.security.getUser();
		this.addSubscription(this.personalization.getChangeObserver().subscribe(() => this.ref.markForCheck()));
	}

	generatePDFLogo = (): string => {
		if (this.security.getCurrentMasterAccount().customBrandingEnabled
			&& this.security.getCurrentMasterAccount().customLogoEnabled) {
			return this.generateMasterAccImageUrl();
		}

		// at least in dev environment, full path seems to be required
		return this.dashboardExportService.getClarabridgeLogo();
	};

	generateMasterAccImageUrl = (): string => {
		return `${this.urlService.getCXStudioEndpoint()}/master_account/logo/${this.security.getMasterAccountId()}`;
	};

	getCurrentDate = (): string => {
		let currDate = new Date();

		let clientTimezone = isEmpty(this.$rootScope.clientTimezone)
			? currDate.getTimezoneOffset()
			: this.$rootScope.clientTimezone;


		const MILLIS_IN_MINUTE = 60000;
		let clientDate = new Date(currDate.getTime() + ((currDate.getTimezoneOffset() - clientTimezone) * MILLIS_IN_MINUTE));
		let dateLabel = this.dateService.format(clientDate);
		return `${dateLabel} ${this.timezoneService.offsetToString(clientTimezone * -1, true)}`;
	};

	getOrganizationLevel = (): string => {
		return this.personalization.currentHierarchyNode.name;
	};

	isDashboardFilterSelected = (filter): boolean => {
		return (isFalse(filter.isOrgHierarchy) && this.dashboardFilterLabels.hasValue(filter))
			|| (this.dashboardFiltersService.isText(filter) && !filter.selectedAttributeValue);
	};

	isPdfExportWithFiltersChangeInViewMode = (): boolean => {
		return !!this.$rootScope.appliedFiltersArray;
	};

	getDashboardFilters = (): DashboardFilter[] => {
		if (isEmpty(this.dashboard) || isEmpty(this.dashboard.appliedFiltersArray)) {
			return [];
		}

		return this.dashboard.appliedFiltersArray;
	};

	getDashboardFilterName = (filter: DashboardFilter): string => {
		if (filter.isDrillToDashboardFilter) {
			return this.locale.getString('reportFilters.drilledFilter');
		}
		return this.dashboardFiltersService.getFilterDisplayName(filter.selectedAttribute);
	};

	getSelectedDashboardFilters = () => {
		return this.getDashboardFilters().filter(this.isDashboardFilterSelected);
	};

	trackByFilterIndex = (index: number, filter: DashboardFilter) => {
		return index;
	};
}

app.directive('pdfHeader', downgradeComponent({ component: PdfHeaderComponent }));
