import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { ITableColumnFormatter, TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { MasterAccountPredictiveStatus } from './master-account-predictive-status';

@Injectable({
	providedIn: 'root'
})
export class PredictiveStatusTableColumnsService {

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		let providerFormatter: ITableColumnFormatter<MasterAccountPredictiveStatus> = (item) => {
			let provider = item.provider || 'default';
			return this.locale.getString(`mAccount.${provider}Provider`);
		};
		return [{
			name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.3
		},
		{
			name: 'provider',
			displayName: this.locale.getString('mAccount.trainingService'),
			formatter: providerFormatter,
			width: 0.3
		}];
	};
}
