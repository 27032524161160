import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ValueMetricDefinition } from '@app/modules/metric/definition/value-metric-definition';
import { ControlContainer, NgForm } from '@angular/forms';

@Component({
	selector: 'value-metric-definition',
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
	template: `
	<fieldset ngModelGroup="valueMetricDefinition">
		<label for="defaultValue" class="mandatory">{{'metrics.defaultValue'|i18n}}</label>
		<div class="mb-16">
			<input id="defaultValue" type="number"
				required
				name="defaultValue"
				[(ngModel)]="definition.defaultValue"
				(ngModelChange)="changeHandler()">
		</div>
	</fieldset>
	`
})
export class ValueMetricDefinitionComponent implements OnInit {
	@Input() definition: ValueMetricDefinition;
	@Output() definitionChange = new EventEmitter<ValueMetricDefinition>();

	constructor() {}

	ngOnInit(): void {
		if (_.isUndefined(this.definition.defaultValue)) {
			this.definition.defaultValue = 42;
		}
	}

	changeHandler = (): void => {
		this.definitionChange.emit(this.definition);
	};
}
