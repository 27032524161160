import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject,
	Input, Output, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AttributeValueOption, MultiselectComponent } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { PagingMultiselectComponent } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-multiselect.component';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { QuickInsightsSearchService } from '@app/modules/home-page/quick-insights/quick-insights-search/quick-insights-search.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'quick-insights-search',
	templateUrl: './quick-insights-search.component.html',
	changeDetection: ChangeDetectionStrategy.Default,
})
export class QuickInsightsSearchComponent implements OnInit, AfterViewInit {
	@Input() homePage: HomePage;
	@Input() searchValue: AttributeValueOption;
	@Input() focusOnInit: boolean;
	@Output() search = new EventEmitter<AttributeValueOption>();

	searchText: string;

	searchAttributes: IReportAttribute[];
	selection: {
		multiValues: AttributeValueOption[];
	};

	chips: AttributeValueOption[];
	specialOptions: AttributeValueOption[];

	@ViewChild(PagingMultiselectComponent, {static: false}) attributeValuesSearcher: PagingMultiselectComponent;
	parent: MultiselectComponent;

	constructor(
		private locale: CxLocaleService,
		private elementRef: ElementRef,
		@Inject('security') private security: Security,
		private quickInsightsSearchService: QuickInsightsSearchService,
		private userHomePageService: UserHomePageService,
		private homePageApi: HomePageApiService,
	) {}

	ngOnInit(): void {
		if (this.focusOnInit) {
			this.elementRef.nativeElement.querySelector('input').focus();
		}
		this.selection = {
			multiValues: []
		};
		this.specialOptions = [];
		this.userHomePageService.getSearchAttributes(this.homePage.id).then(result => {
			this.searchAttributes = result;
			this.updateSpecialOptions();
		});

		this.chips = this.getChips();
		if (this.searchValue && !this.isAttributeSearch()) {
			this.searchText = this.searchValue.displayName;
		}
	}

	ngAfterViewInit(): void {
		this.parent =  this.attributeValuesSearcher.multiselect;
	}

	private getTextSearchOption = (): AttributeValueOption => {
		return this.quickInsightsSearchService.getTextSearchOption(this.searchText);
	};

	private updateSpecialOptions = (): void => {
		if (!_.isEmpty(this.searchAttributes)) this.specialOptions = [this.getTextSearchOption()];
	};

	getWelcomeText(): string {
		let hour = new Date().getHours();
		if (hour >= 22 || hour < 6)
			return this.locale.getString('homePage.quickInsightsWelcomeNight');
		let greeting;
		if (hour < 12)
			greeting = this.locale.getString('homePage.quickInsightsGreetingMorning');
		else if (hour < 17)
			greeting = this.locale.getString('homePage.quickInsightsGreetingAfternoonn');
		else
			greeting = this.locale.getString('homePage.quickInsightsGreetingEvening');
		if (!!this.security.getUser().firstName)
			greeting += ` ${this.security.getUser().firstName}`;
		return this.locale.getString('homePage.quickInsightsWelcomeToday', {greeting});
	}

	clearHandler = (): void => {
		this.searchText = null;
		this.searchValue = null;
		this.search.emit(this.searchValue);
	};

	onSearchTextChange = (text: string) => {
		this.searchText = text;
		this.updateSpecialOptions();
	};

	searchHandler = (): void => {
		if (!this.isAttributeSearch()) {
			if (!this.searchText) {
				this.searchValue = null;
			} else {
				this.searchValue = this.getTextSearchOption();
			}
		}
		this.search.emit(this.searchValue);
		if (!!this.searchValue) {
			this.homePageApi.logSearchEvent(this.homePage.id, this.searchValue.displayName);
		}
	};

	onSelectionChange = () => {
		this.searchValue = this.selection.multiValues.pop();
		if (!this.searchValue || this.quickInsightsSearchService.isAttributeSearch(this.searchValue)) {
			this.searchText = null;
		}
		this.chips = this.getChips();
		this.search.emit(this.searchValue);
	};

	isAttributeSearch = (): boolean => {
		return !_.isEmpty(this.getChips());
	};

	getChips = (): AttributeValueOption[] => {
		return  this.searchValue && this.quickInsightsSearchService.isAttributeSearch(this.searchValue)
			? [this.searchValue]
			: [];
	};

	removeChip = (chip, $event): void => {
		$event.stopPropagation();
		this.parent.clickItem(chip);
	};

	showClear = (): boolean => {
		return !!this.searchText || !!this.searchValue;
	};
}
