import { Inject, Injectable } from '@angular/core';
import * as moment from 'moment';
import { FormatDataType } from '@cxstudio/reports/formatting/format-data-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { Alignment } from '@cxstudio/reports/formatting/alignment.enum';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { CxLocaleService } from '@app/core';
import { MetricMultipliersService } from './metric-multipliers.service';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';

export interface DecimalInteger {
	integer: string;
	decimal?: string;
}

@Injectable({
	providedIn: 'root'
})
export class FormatterBuilderUtilsService {

	readonly TRAILING_ZERO_REGEX = /^-?\d+\.0+$/; // 23456.00
	readonly EXPONENT_REGEX = /^-?\d+(\.\d+)?[eE][\+-]?\d+$/; // 1.23E4 or 1.23E-4

	constructor(
		private readonly locale: CxLocaleService,
		private readonly MetricMultipliers: MetricMultipliersService,
		@Inject('dateService') private readonly dateService: DateService
	) { }

	formatDecimals(value: number, decimals: Decimals): string {
		let decimal = parseInt(decimals as any, 10);
		if (isNaN(decimal))
			decimal = 1;
		return Number(value).toFixed(decimal);
	}

	formatDataType(value: number, dataType: FormatDataType): number {
		return FormatDataType.PERCENT === dataType ? value * 100 : value;
	}

	formatDataTypeText(value, dataType: FormatDataType): string {
		return FormatDataType.PERCENT === dataType ? value + '%' : value;
	}

	formatTruncationValue(value: number, truncation: Truncation): number {
		if (Truncation.THOUSANDS === truncation) {
			return value / 1000;
		} else if (Truncation.MILLIONS === truncation) {
			return value / 1000000;
		} else {
			return value;
		}
	}

	formatTruncationText(value: string, truncation: Truncation): string {
		if (Truncation.THOUSANDS === truncation) {
			return value + this.locale.getString('widget.thousandSymbol');
		} else if (Truncation.MILLIONS === truncation) {
			return value + this.locale.getString('widget.millionSymbol');
		}

		return value;
	}

	multiplyValue(value: number, multiplier: MetricMultiplierType): number {
		if (!multiplier)
			return value;

		return this.MetricMultipliers.findByName(multiplier).apply(value);
	}

	getThousandsSeparator(thousandsChar?: string): string {
		if (thousandsChar === undefined) {
			return this.locale.getString('widget.delimiter');
		} else {
			return thousandsChar;
		}
	}

	getDecimalSeparator(decimalChar?: string): string {
		if (!decimalChar) {
			return this.locale.getString('widget.decimalPoint');
		} else {
			return decimalChar;
		}
	}

	formatAlignment(value: string, alignment: Alignment): string {
		if (Alignment.RIGHT === alignment) {
			return `<span class = 'slick-align-right'>${value}</span>`;
		}
		return value;
	}

	formatAny(value: any, thousandsChar?: string): string {
		let stringValue = value?.toString();
		if (!stringValue) {
			return '0';
		}
		return stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, this.getThousandsSeparator(thousandsChar));
	}

	formatDecimalSeparator(stringValue: string, decimalChar: string): string {
		if (stringValue === '') {
			return '0';
		}

		let split = this.splitDecimalAndInteger(stringValue);
		return split.integer + (split.decimal ? this.getDecimalSeparator(decimalChar) + split.decimal : '');
	}

	splitDecimalAndInteger(value: string): DecimalInteger {
		let pattern = /\./g;
		let lastIndex;

		if (value.indexOf('.') === -1)
			return { integer: value };

		// we have to support that possibility that multiple decimal/period characters might exist
		// because the user can customize the thousands character
		while (pattern.exec(value) !== null) {
			lastIndex = pattern.lastIndex;
		}

		return {
			integer: value.slice(0, lastIndex - 1),
			decimal: value.slice(lastIndex)
		};
	}

	isNA(value: any): boolean {
		return value === 'NaN' || value === null || value === undefined;
	}

	formatNumberAsString = (value: number | string): string => {
		if (_.isUndefined(value))
			return value;
		if (!_.isString(value))
			return '' + value;
		if (this.TRAILING_ZERO_REGEX.test(value))
			return value.replace(/\.?0+$/, '');
		if (this.EXPONENT_REGEX.test(value))
			return Number(value).toString(); // converts to plain number from e-7 to e+20
		return value;
	};

	formatNumber(value: string): string {
		return this.formatNumberAsString(value);
	}

	formatDate(value: string): string {
		let momentDate = moment(value, 'YYYYMMDD');
		return this.dateService.format(momentDate.toDate(), DateTimeFormat.BASIC_DATE);
	}
}

app.service('formatterBuilderUtils', FormatterBuilderUtilsService);
