import * as _ from 'underscore';
import { SizingStrategy } from './sizing-strategy.class';
import { ConversationChartOptions } from '../conversation-chart-options.class';
import { ConversationDataPoint } from '../conversation-data-point.class';

export class BasicSizing extends SizingStrategy {

	minHeight: number;
	maxHeight: number;
	protected pixelsPerUnit: number;

	constructor(chartHeight: number, config: ConversationChartOptions) {
		super(chartHeight, config);

		this.minHeight = this.getHeightLimit(this.config.basicChart.minBubbleHeight, this.chartHeight);
		this.maxHeight = this.getHeightLimit(this.config.basicChart.maxBubbleHeight, this.chartHeight);
	}

	initialize(dataSet: ConversationDataPoint[]): void {
		this.pixelsPerUnit = this.getPixelsPerUnit(dataSet);
		this.processDataset(dataSet);
	}

	private getPixelsPerUnit(dataSet: ConversationDataPoint[]): number {
		let totalDuration = 0;
		let totalSpacing = 0;
		_.each(dataSet, (dataPoint, index) => {
			totalDuration += dataPoint.duration;
			if (index > 0 && !this.config.sameClusterPredicate(dataPoint, dataSet[index - 1])) {
				totalSpacing += this.config.basicChart.bubbleSpacing;
			}
		});
		let availableHeight = this.chartHeight - totalSpacing;
		let pixelsPerUnit = availableHeight / totalDuration;

		let biggestBlock: ConversationDataPoint = this.getBiggestBlock(dataSet);
		let biggestBlockHeight = biggestBlock.duration * pixelsPerUnit;
		if (biggestBlockHeight > this.maxHeight)
			pixelsPerUnit *= this.maxHeight / biggestBlockHeight;
		return pixelsPerUnit;
	}

	protected getBiggestBlock(dataSet: ConversationDataPoint[]): ConversationDataPoint {
		return _.max(dataSet, (data) => data.duration) as ConversationDataPoint;
	}

	private processDataset(dataSet: ConversationDataPoint[]): void {
		let currentY = 0;
		_.each(dataSet, (dataPoint, index) => {
			if (index > 0 && !this.config.sameClusterPredicate(dataPoint, dataSet[index - 1]))
				currentY += this.config.basicChart.bubbleSpacing;
			dataPoint.yPosition = currentY;
			dataPoint.height = this.getDatapointHeight(dataPoint);
			currentY += dataPoint.height;
		});
	}

	getPointFromTimestamp = (timestamp: number, dataSet: ConversationDataPoint[]): ConversationDataPoint => {
		return _.filter(dataSet,
			(data) => (data.timestamp <= timestamp) && (data.endTimestamp > timestamp)
		).last();
	};

	protected getDatapointHeight(dataPoint: ConversationDataPoint): number {
		if (!dataPoint) return 0;

		let calculatedHeight = dataPoint.duration * this.pixelsPerUnit;
		return calculatedHeight > this.config.basicChart.minBubbleHeight ?
			calculatedHeight :
			this.config.basicChart.minBubbleHeight;
	}

	private getHeightLimit = (heightLimitVal: string | number, height: number): number => {
		// pixels in number form
		if (typeof heightLimitVal !== 'string') return heightLimitVal;

		let numberValue = Number(/[0-9\.]+/.exec(heightLimitVal)[0]);
		// ex: '20%'
		if (heightLimitVal.indexOf('%')) return (numberValue / 100) * height;

		// probably something like '20px'
		return numberValue;
	};
}
