import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { Security } from '@cxstudio/auth/security-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { QuickFilter, QuickFilterService } from '@app/modules/reports/filters/quick-filter.service';
import { AppliedFilters } from '@cxstudio/reports/utils/applied-filters-factory.service';
import { FilterParsingService } from '@cxstudio/services/filter-parsing-service';
import * as _ from 'underscore';
import { WidgetFiltersMetadata } from '@app/modules/reporting/widget-filters-metadata';
import { DropdownDirection } from '@app/shared/menu/dropdown-direction.type';

const FILTER_DROPDOWN_FOCUSABLE = `.filters-dropdown-menu :focusable`;

export class FilterAppliedDropdownController implements ng.IController {
	//bindings
	appliedFilters: AppliedFilters;
	isDocExplorer: boolean;
	showQuickFilter: boolean;
	widget?: Widget; // undnefined in doc explorer
	noDashboard: boolean;
	compact: boolean;
	customAriaLabel?: string;

	predefinedFilterButtons: QuickFilter[];
	previewQuickFilter: QuickFilter;

	filterDropdownOpen: boolean;
	dropdownDirection: DropdownDirection;
	textFilter: string;
	textFilterChange: (params: {$event: string}) => void;

	constructor(
		private $scope: ISimpleScope,
		private locale: ILocale,
		private filterParsingService: FilterParsingService,
		private quickFilterService: QuickFilterService,
		private security: Security
	) { }

	$onInit(): void {
		// for tracking dropdown open/closed state
		this.initScopeFunctions();

		this.$scope.$watch('$ctrl.appliedFilters', (newValue, oldValue) => {
			if (newValue !== oldValue)
				this.initScopeFunctions();
		});

	}

	private populatePredefinedFilterOptions = (): void => {
		this.predefinedFilterButtons = this.quickFilterService.getQuickFilters(this.widget);
		let appliedFilter = this.quickFilterService.getAppliedFilter(this.widget);
		if (appliedFilter) {
			this.previewQuickFilter = appliedFilter;
		}
		this.showQuickFilter = this.predefinedFilterButtons.length > 1;
		if (!_.isUndefined(this.previewQuickFilter)) {
			this.previewQuickFilter = _.findWhere(this.predefinedFilterButtons,
				{type: this.previewQuickFilter.type, value: this.previewQuickFilter.value});
		}
		if (_.isUndefined(this.previewQuickFilter)) {
			this.previewQuickFilter = this.predefinedFilterButtons[0];
			this.updateWidget();
		}
	};

	handleMenuKbNavigation = (event: KeyboardEvent): void => {
		let focusableElements  = $(FILTER_DROPDOWN_FOCUSABLE);
		let activeElement = document.activeElement;
		if (KeyboardUtils.isEventKey(event, Key.TAB) || KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			KeyboardUtils.cycleNavigation(event, focusableElements, activeElement);
		}
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			$(`${this.getWidgetSelector()} .preview-filter-indicator :focusable`).first().trigger('focus');
			this.filterDropdownOpen = false;
		}
	};

	updatePredefinedFilter = (): void => {
		this.updateWidget();
		this.$scope.$emit('widgetRefreshEvent');
		this.filterDropdownOpen = false;
	};

	private updateWidget = (): void => {
		this.widget.properties.quickFilter = this.previewQuickFilter;
	};

	getRuleString = (rule): string => {
		return this.filterParsingService.getRuleString(rule);
	};

	applyTextFilter = (text: string): void => {
		this.textFilter = text;
		if (this.textFilterChange)
			this.textFilterChange({$event: text});
		if (text.length > 0) {
			this.filterDropdownOpen = false;
			$(FILTER_DROPDOWN_FOCUSABLE).first().trigger('focus');
		} else {
			$(`.input-group :focusable`).first().trigger('focus');
		}
		this.$scope.$emit('widgetRefreshEvent');
	};

	getWidgetTextFilterInDocExplorer = (): string => {
		return `${this.locale.getString('docExplorer.searchFilter')}: ${this.textFilter}`;
	};

	showWidgetTextFilterInDocExplorer = (): boolean => {
		return this.isDocExplorer && !isEmpty(this.textFilter);
	};

	isCompactMode = (): boolean => {
		return this.security.loggedUser.isEnterpriseUser;
	};

	showPersonalization = (): boolean => {
		return this.appliedFilters.personalizationApplied() && !(this.isCompactMode() && this.appliedFilters.ignorePersonalization());
	};

	openMenuKb = (event: KeyboardEvent): void => {
		if (!KeyboardUtils.isEventKey(event, Key.ENTER)) {
			return;
		}
		this.focusOnFirstItem();
	};

	focusOnFirstItem = (): void => {
		setTimeout(() => {
			$(FILTER_DROPDOWN_FOCUSABLE).first().trigger('focus');
			let watcher = this.$scope.$watch(() => this.filterDropdownOpen, (newVal, oldVal) => {
				if (newVal !== oldVal && !newVal) {
					$(`${this.getWidgetSelector()} .preview-filter-indicator :focusable`).first().trigger('focus');
					watcher();
				}
			});
		});
	};

	getAriaLabel = (): string => {
		return this.customAriaLabel || this.getStandardAriaLabel();
	};

	private getStandardAriaLabel = (): string => {
		return this.widget?.displayName
			?  this.locale.getString('widget.filtersAriaLabel', { title: this.widget.displayName })
			:  this.locale.getString('preview.filtersApplied');
	};

	private getWidgetSelector(): string {
		if (this.widget)
			return `#widget-${this.widget.id}`;
		else return '.an-document-explorer';
	}

	closeMenuOnEsc = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			this.filterDropdownOpen = false;
		}
	};

	private initScopeFunctions = (): void => {
		if (this.widget)
			this.populatePredefinedFilterOptions();
	};

	getWidgetMetadata = (): WidgetFiltersMetadata => {
		return this.appliedFilters.getWidgetMetadata();
	};
}

app.component('filterAppliedDropdown', {
	controller: FilterAppliedDropdownController,
	templateUrl: 'partials/components/filter-applied-dropdown-component.html',
	bindings: {
		appliedFilters: '=',
		dropdownContainer: '<',
		isDocExplorer: '<',
		showQuickFilter: '<',
		widget: '=',
		noDashboard: '<',
		customAriaLabel: '<?',
		textFilter: '<',
		textFilterChange: '&',
	}
});
