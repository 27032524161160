import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { CxLocaleService } from '@app/core';
import { WidgetTemplate } from '@cxstudio/dashboards/widgets/widget-template.class';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ObjectUtils } from '@app/util/object-utils';


export enum WidgetIcons {
	standard = 'q-icon-chart-5',
	trend = 'q-icon-chart-3',
	stacked = 'q-icon-chart-3',
	video = 'q-icon-video',
	text = 'q-icon-text',
	button = 'q-icon-button',
	preview = 'q-icon-eye',
	image = 'q-icon-photo',
	label = 'q-icon-tag',
	cb_single_metric = 'q-icon-chart-10',
	cb_an_table = 'q-icon-chart-9',
	cb_an_metric = 'q-icon-chart-10',
	gauge = 'q-icon-chart-gauge',
	cb_an_line = 'q-icon-chart-8',
	cb_an_bubble = 'q-icon-chart-12',
	radar = 'q-icon-chart-radar',
	polar = 'q-icon-chart-polar',
	page_break = 'q-icon-page-break',
	qualtrics = 'q-icon-qualtrics-xm',
	cb_an_bar = 'q-icon-chart-5',
	cb_an_heatmap = 'q-icon-chart-11',
	cb_an_heatmap_horizontal = 'q-icon-chart-17',
	cb_an_cloud = 'q-icon-cloud',
	cb_an_pie = 'q-icon-chart-13',
	cb_an_scatter = 'q-icon-chart-16',
	cb_an_preview = 'q-icon-eye',
	cb_an_selector = 'q-icon-widget-selector',
	cb_an_network = 'q-icon-chart-19',
	cb_object_viewer = 'q-icon-models',
	cb_an_model_viewer = 'q-icon-models',
	cb_an_map = 'q-icon-globe',
	column = 'q-icon-chart-5',
	dot = 'q-icon-chart-12',
	scatter = 'q-icon-chart-16',
	table = 'q-icon-chart-9',
	line = 'q-icon-chart-8',
	bar = 'q-icon-chart-5 icon-rotate-90',
	heatmap = 'q-icon-chart-11',
	cloud = 'q-icon-cloud',
	pie = 'q-icon-chart-13',
	key_terms = 'q-icon-chart-9',
	pareto = 'q-icon-chart-pareto'
}

enum WidgetTemplateGroup {
	SIMPLE,
	OTHER,
	CONTENT,
	PAGE_BREAK,
	QUALTRICS,
}


@Injectable({
	providedIn: 'root'
})
export class WidgetTemplatesService {
	templates = [];

	constructor(
		private widgetApiService: WidgetApiService,
		private locale: CxLocaleService
	) { }

	getWidgets = (useCachedTemplates?: boolean): Promise<WidgetTemplate[]> => {
		if (this.templates.length && useCachedTemplates) {
			return Promise.resolve(ObjectUtils.copy(this.templates));
		} else {
			return this.getWidgetsFromApi().then(templates => {
				let templateGroups = _.groupBy(templates, this.getTemplateGroup);
				this.templates = _.flatten([
					_.sortBy(templateGroups[WidgetTemplateGroup.SIMPLE], DashboardUtils.orderReports),
					_.sortBy(templateGroups[WidgetTemplateGroup.OTHER], DashboardUtils.orderReports),
					_.sortBy(templateGroups[WidgetTemplateGroup.CONTENT], DashboardUtils.orderReports),
					_.sortBy(templateGroups[WidgetTemplateGroup.PAGE_BREAK], DashboardUtils.orderReports),
					_.sortBy(templateGroups[WidgetTemplateGroup.QUALTRICS], DashboardUtils.orderReports)
				]);
				return ObjectUtils.copy(this.templates);
			});
		}
	};

	private getWidgetsFromApi = (): Promise<WidgetTemplate[]> => {
		return this.widgetApiService.getWidgetTemplates().then(data => {
			return data.map(template => {
				let report = template as WidgetTemplate;
				let displayName = this.locale.getString(`widget.${template.name}`);
				report.displayName = displayName;
				report.class = WidgetIcons[template.name];
				return report;
			});
		});
	};

	private getTemplateGroup = (template: WidgetTemplate): WidgetTemplateGroup => {
		switch (template.name) {
			case WidgetType.BAR:
			case WidgetType.LINE:
			case WidgetType.TABLE:
			case WidgetType.CLOUD:
			case WidgetType.PIE:
			case WidgetType.SCATTER:
			case WidgetType.HEATMAP: return WidgetTemplateGroup.SIMPLE;
			case WidgetType.SELECTOR:
			case WidgetType.IMAGE:
			case WidgetType.TEXT:
			case WidgetType.LABEL:
			case WidgetType.BUTTON:
			case WidgetType.VIDEO: return WidgetTemplateGroup.CONTENT;
			case WidgetType.PAGE_BREAK: return WidgetTemplateGroup.PAGE_BREAK;
			case WidgetType.QUALTRICS: return WidgetTemplateGroup.QUALTRICS;
			default: return WidgetTemplateGroup.OTHER;
		}
	};
}

app.service('widgetTemplatesService', downgradeInjectable(WidgetTemplatesService));

