import * as _ from 'underscore';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, Inject, ChangeDetectionStrategy} from '@angular/core';
import { CxWizardMode } from '@app/modules/wizard/cx-wizard-mode';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';

export interface DataOverviewWizardOutput {
	projectSelection: IProjectSelection;
	grouping: ReportGrouping;
	calculation: ReportCalculation;
	additionalMetrics: ReportCalculation[];

	// List of loaded assets on second step
	widgetAssets: IWidgetSettings;
}

@Component({
	selector: 'data-overview-dialog',
	templateUrl: './data-overview-wizard.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DataOverviewWizardWizardComponent {
	loading: Promise<any>;

	mode: CxWizardMode = CxWizardMode.EDIT;
	currentStep: number = 0;

	projectSelection: IProjectSelection = {
		contentProviderId: -1,
		accountId: -1,
		projectId: -1
	};

	widgetAssets: IWidgetSettings;
	grouping: ReportGrouping;
	calculation: ReportCalculation;
	additionalMetrics: ReportCalculation[] = [
		this.metricConstants.get().SENTIMENT, this.metricConstants.get().EFFORT
	];
	fixedAdditionalMetrics: ReportCalculation[] = [
		this.metricConstants.get().SENTIMENT, this.metricConstants.get().EFFORT
	];

	constructor(
		private modal: NgbActiveModal,
		@Inject('metricConstants') private metricConstants: MetricConstants,
	) {
	}

	widgetAssetsLoaded = (widgetAssets: IWidgetSettings) => {
		this.widgetAssets = widgetAssets;
	};

	save = (): void => {
		this.modal.close({
			projectSelection: this.projectSelection,
			grouping: this.grouping,
			calculation: this.calculation,
			additionalMetrics: this.additionalMetrics.filter(metric => !!metric.name),
			widgetAssets: this.widgetAssets
		} as DataOverviewWizardOutput);
	};

	cancel = (): void => {
		this.modal.dismiss();
	};
}
