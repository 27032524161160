import { Input, Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'intelligent-scoring-button',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<button class="btn btn-icon intelligent-scoring-toggle no-border"
		[class.enabled]="isRebuttalModeEnabled()"
		[ngClass]="isRebuttalModeEnabled() ? 'q-icon-delete' : 'q-icon-scorecard'"
		(click)="toggleRebuttalMode()"
		attr.aria-label="{{getRebuttalModeToggleTooltip()}}"
		[ngbTooltip]="getRebuttalModeToggleTooltip()"
		[placement]="tooltipPlacement"
		tooltipClass="intelligent-scoring-mode-tooltip">
	</button>
	`
})
export class IntelligentScoringButtonComponent implements OnInit {
	@Output() stateChange = new EventEmitter<boolean>();
	@Input() tooltipPlacement: string = 'bottom';

	private rebuttalModeEnabled = false;

	constructor(
		private locale: CxLocaleService
	) { }

	ngOnInit(): void {
	}

	toggleRebuttalMode = () => {
		this.rebuttalModeEnabled = !this.rebuttalModeEnabled;
		this.stateChange.emit(this.rebuttalModeEnabled);
	};

	getRebuttalModeToggleTooltip = (): string => {
		if (this.isRebuttalModeEnabled()) {
			return this.locale.getString('widget.exit');
		}
		return this.locale.getString('scorecards.rebutScorecardsMode');
	};

	isRebuttalModeEnabled = (): boolean => {
		return this.rebuttalModeEnabled;
	};
}

app.directive('intelligentScoringButton', downgradeComponent({component: IntelligentScoringButtonComponent}));
