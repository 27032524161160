<modal-header
	(cancel)="cancel()"
	[modalTitle]="'administration.userDetails'|i18n">
</modal-header>

<div class="modal-body" [ngBusy]="loading">
	<div class="tab-content">
		<form #userDialog="ngForm" name="userDialog" (submit)=save() role="form">
			<ul ngbNav #nav="ngbNav" class="nav-tabs" [(activeId)]="activeTabIndex">
				<li [ngbNavItem]="0">
					<a ngbNavLink class="br-edit-user-properties-tab" [i18n]="'administration.properties'"></a>
					<ng-template ngbNavContent>
						<div class="form-group stack-md" ng-required show-errors>
							<div class="row d-flex">
								<label class="d-flex align-items-center mb-0 text-right justify-end control-label col-md-3" [i18n]="'administration.firstName'"></label>
								<div class="col-md-9">
									<input
										class="form-control"
										[(ngModel)]=user.firstName
										trim
										name="firstName"
										required
										minlength="1"
										maxlength="40"
										[disabled]="isUserSyncControlDisabled()">
								</div>
							</div>
							<div class="row">
								<div class="col-md-offset-3 col-md-9">
									<span
										class="help-block text-danger"
										*ngIf="userDialog?.controls.firstName?.invalid"
										[i18n]="'administration.firstNameInvalid'">
									</span>
									<span
										class="help-block text-danger"
										*ngIf="isFirstNameInvalid()"
										[i18n]="'administration.firstNameInvalidValue'">
									</span>
								</div>
							</div>
						</div>
						<div class="form-group stack-md" ng-required show-errors>
							<div class="row d-flex">
									<label class="d-flex align-items-center mb-0 text-right justify-end control-label col-md-3" [i18n]="'administration.lastName'"></label>
									<div class="col-md-9">
										<input
											class="form-control"
											[(ngModel)]=user.lastName
											trim
											name="lastName"
											required
											minlength="1"
											maxlength="40"
											[disabled]="isUserSyncControlDisabled()">
								</div>
							</div>
							<div class="row">
								<div class="col-md-offset-3 col-md-9">
									<span
										class="help-block text-danger"
										*ngIf="userDialog?.controls.lastName?.invalid"
										[i18n]="'administration.lastNameInvalid'">
									</span>
									<span
										class="help-block text-danger"
										*ngIf="isLastNameInvalid()"
										[i18n]="'administration.lastNameInvalidValue'">
									</span>
								</div>
							</div>
						</div>

						<div *ngIf="isNeedPasswordControls()" class="form-group stack-md">
							<div class="row">
								<label class="control-label col-md-3 text-right justify-end d-flex align-items-center mb-0" [i18n]="'administration.resetPassword'"></label>
								<div class="col-md-9">
									<input
										class="checkbox _t-force-registration"
										name="forceRegistration"
										[(ngModel)]="user.forceRegistration"
										type="checkbox"
										(change)="onPasswordResetChange(user.forceRegistration)">
								</div>
							</div>
						</div>

						<div *ngIf="isNeedPasswordControls()" class="form-group stack-md">
							<div class="row">
								<div class="col-md-8">
									<div class="d-flex align-items-center">
										<button id="btn-user-show-pwd-ctrl"
											class="btn btn-secondary btn-flex mr-8"
											type="button"
											[disabled]="user.forceRegistration"
											(click)="togglePasswordControls()">
												<span>{{getPasswordControlLabel()}}</span>
										</button>
									</div>
								</div>
							</div>
						</div>

						<div *ngIf="showPasswordControls" class="rounded-grouping clearfix">
							<div class="form-group stack-md" show-errors>
								<div class="row d-flex">
									<label class="d-flex align-items-center text-right justify-end mb-0 control-label col-md-3" [i18n]="'administration.password'"></label>
									<div class="col-md-9">
										<input
											type="password"
											[required]="!user.forceRegistration && isNeedPasswordControls()"
											[minlength]="passwordPolicy.minLength"
											name="password"
											class="form-control"
											id="password"
											[(ngModel)]=user.password
											[disabled]="user.forceRegistration"
											[pattern]="pwdPattern">
									</div>
								</div>
								<div class="row">
									<div class="col-md-9 col-offset-md-3">
										<span
											class="help-block text-danger"
											*ngIf="!userDialog?.controls.password?.pristine && (userDialog?.controls.password?.errors?.minlength
												|| userDialog?.controls.password?.errors?.required)"
											[i18n]="'administration.passwordInvalid'"
											[i18nParams]="{pwdLength: passwordPolicy.minLength}">
										</span>
										<span
											class="help-block text-danger"
											*ngIf="!userDialog?.controls.password?.pristine && userDialog?.controls.password?.errors?.pattern"
											[i18n]="'administration.specialCharsRequired'">
										</span>
									</div>
								</div>
							</div>
							<div *ngIf="showPasswordControls" class="form-group stack-md" show-errors>
								<div class="row d-flex">
									<label class="d-flex align-items-center text-right justify-end mb-0 control-label col-md-3" [i18n]="'administration.confirmPassword'"></label>
									<div class="col-md-9">
										<input
											type="password"
											[required]="!user.forceRegistration && isNeedPasswordControls()"
											[minlength]="passwordPolicy.minLength"
											name="confirmPassword"
											class="form-control"
											[(ngModel)]=user.confirmPassword
											[pwCheck]="user.password"
											[disabled]="user.forceRegistration">
									</div>
								</div>
								<div class="row">
									<div class="col-md-9 col-offset-md-3">
										<span class="help-block text-danger"
											*ngIf="!userDialog?.controls.confirmPassword?.pristine && userDialog?.controls.confirmPassword?.errors?.pwmatch"
											[i18n]="'administration.passwordNotMatch'">
										</span>
									</div>
								</div>
							</div>
						</div>

						<div class="form-group stack-md" show-errors>
							<div class="row d-flex">
								<label class="control-label col-md-3 text-right justify-end d-flex align-items-center mb-0" [i18n]="'administration.email'"></label>
								<div class="col-md-9">
									<input
										minlength="5"
										type="email"
										name="email"
										required
										class="form-control col-md-9"
										[(ngModel)]=user.email
										[disabled]="true">
								</div>
							</div>
							<div class="row">
								<div class="col-md-9 col-md-offset-3">
									<span
										class="help-block text-danger"
										*ngIf="!userDialog?.controls.email?.pristine && userDialog?.controls.email?.invalid"
										[i18n]="'administration.emailInvalid'">
									</span>
									<span
										class="help-block text-danger"
										*ngIf="usersemailexist"
										[i18n]="'administration.usersemailexist'">
									</span>
								</div>
							</div>
						</div>

						<div class="form-group stack-md">
							<div class="row">
								<label class="control-label col-md-3 text-right justify-end d-flex align-items-center mb-0" [i18n]="'administration.location'"></label>
								<div class="col-md-9">
									<search-list
										[dropdown]="true"
										fullWidth="true"
										matchSize="true"
										[listOptions]="availableCountries"
										[data]="user.country"
										(onNodeSelection)="onCountryChange($event.node)"
										displayField="name"
										sortField="name"
										selectPrompt="{{'administration.locationNotDetected'|i18n}}">
									</search-list>
								</div>
							</div>
						</div>

						<div *ngIf="isNeedAuthUniqueId() && !isInternalUser()" class="form-group stack-md" show-errors>
							<div class="row d-flex">
								<label class="control-label col-md-3 align-items-center d-flex text-right justify-end mb-0" [i18n]="'administration.idpUniqueId'"></label>
								<div class="col-md-9">
									<input
										name="authUniqueId"
										class="form-control col-md-9"
										(blur)="validateUniqueId()"
										(ngModelChange)="validateUniqueId()"
										[(ngModel)]="user.authUniqueId"
										[required]="isNeedAuthUniqueId() && !isInternalUser()"
										minlength="1">
								</div>
							</div>
							<div class="row">
								<div class="col-md-9 col-md-offset-3">
									<span
										class="help-block text-danger"
										*ngIf="!userDialog?.controls.authUniqueId?.pristine && !isUniqueIdValid()"
										[i18n]="'administration.uniqueIdInvalid'">
									</span>

									<span
										id="uniqueIdExistError"
										class="help-block text-danger"
										*ngIf="!userDialog?.controls.authUniqueId?.pristine && userDialog?.controls.authUniqueId?.errors?.uniqueIdExists"
										[i18n]="'administration.uniqueIdExists'">
									</span>
								</div>
							</div>
						</div>

						<div *ngIf="isNeedXmAccountId()" class="form-group stack-md" show-errors>
							<div class="row d-flex">
								<label class="control-label col-md-3 align-items-center d-flex text-right justify-end mb-0" [i18n]="'administration.xmAccountId'"></label>
								<div class="col-md-9">
									<input
										name="xmAccountId"
										class="form-control col-md-9"
										(ngModelChange)="validateXmAccountId($event)"
										[disabled]="!isXmAccountIdEnabled() || isUserSyncControlDisabled()"
										[(ngModel)]="user.xmAccountId"
										autocomplete="off"
										minlength="1">
								</div>
							</div>
							<div class="row">
								<div class="col-md-9 col-md-offset-3">
									<span
										id="xmAccountIdExistError"
										class="help-block text-danger"
										*ngIf="!isXmAccountIdValid()"
										[i18n]="'administration.xmAccountIdExists'">
									</span>
								</div>
							</div>
						</div>

						<div class="form-group stack-md" *ngIf="isCurrentUserAnyAdmin()">
							<div class="row d-flex">
								<label class="d-flex align-items-center mb-0 text-right justify-end control-label col-md-3" [i18n]="'administration.tags'"></label>
								<div class="col-md-9">
									<tags-input
										class="w-100-percent no-horiz-resize user-tags-input"
										name="tags"
										ngDefaultControl
										[disabled]="disableTagsInput()"
										[maxTags]="5"
										[minLength]="1"
										[addOnBlur]="true"
										[addOnEnter]="true"
										[customValidation]="verifyTagAllowed"
										[tagCustomClasses]="tagCustomClasses"
										[tagsSource]="getTagsSuggestion"
										[(ngModel)]="user.tags"
										(onTagAdded)="setTagClass($event)">
									</tags-input>
								</div>
							</div>
						</div>

						<div class="form-group stack-md" [ngClass]="{'has-error': licenseLimitExceeded()}">
							<div class="row d-flex">
								<label class="d-flex align-items-center mb-0 text-right justify-end control-label col-md-3" [i18n]="'administration.licenseType'"></label>
								<div class="col-md-9">
									<select class="br-license-type-selector form-control"
										name="licenseType"
										[(ngModel)]="user.licenseTypeId"
										(ngModelChange)="handleLicenseChange()">
										<option
											*ngFor="let licenseType of assignableLicenseTypes"
											[ngValue]="licenseType.licenseTypeId">
											{{licenseType.licenseTypeName + getCountLabel(licenseType.licenseTypeId)}}
										</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form-group stack-md">
							<div class="row d-flex">
								<p class="col-md-9 col-md-offset-3" *ngIf="licenseLimitExceeded()">{{getLicenseExceededMessage()}}</p>
							</div>
						</div>

						<div *ngIf="isCurrentUserInternal()" class="form-group stack-md" [ngClass]="{'has-error': invalidDefaultMA() || missingUserDefaultMA()}">
							<div class="row d-flex">
								<label class="control-label col-md-3 text-right justify-end d-flex align-items-center mb-0" [i18n]="'administration.defaultMA'"></label>
								<div class="col-md-9">
									<select class="br-default-ma-selector form-control"
										name="defaultMasterAccountId"
										[(ngModel)]="user.defaultMasterAccountId"
										[disabled]="!isCurrentUserAnyAdmin()"
										(ngModelChange)="defaultMasterAccountChanged($event)">
										<option *ngFor="let ma of user.linkedMasterAccounts" [ngValue]="ma.accountId">{{ma.accountName}}</option>
									</select>
								</div>
							</div>
						</div>

						<div class="form-group stack-md">
							<div class="row d-flex">
								<p class="col-md-9 col-md-offset-3" *ngIf="invalidDefaultMA()" [i18n]="'mAccount.invalidDefaultMA'"></p>
							</div>
						</div>

						<user-custom-field
							*ngIf="showCustomField()"
							[name]="options.customFieldName"
							[value]="user.customField"
							(updateName)="updateCustomFieldName($event)"
							(updateValue)="updateCustomFieldValue($event)"
						></user-custom-field>

						<div *ngIf="isCurrentUserAnyAdmin() && isInternalUser()"
							class="mb-24 br-system-permissions">
							<admin-org-permissions
								[user]="user"
								(platformApiCallback)="grantPlatformApiUser()"
							></admin-org-permissions>
						</div>
						<div *ngIf="!isInternalUser() && hasCustomerPermissions()"
							class="mb-24 br-system-permissions">
							<customer-permissions
								[user]="user"
								[licenseType]="user.licenseTypeId"
								(customerAdminChange)="customerAdminChangedCallback($event)"
							></customer-permissions>
						</div>

						<div class="d-flex align-items-center justify-center">
							<button *ngIf="!isWorkspaceEnabled()"
								id="btn-user-linkcp"
								class="btn btn-secondary btn-flex mr-8"
								type="button"
								[disabled]="!userDialog?.valid || !isCPLinkingAllowed() || !user.defaultMasterAccountId"
								(click)="linkToCP()"
								[i18n]="'administration.linkUserToCP'">
							</button>

							<button *ngIf="isShowImpersonateUserButton()"
								id="btn-user-impersonate"
								class="btn btn-secondary btn-flex mr-8"
								type="button"
								(click)="impersonateUser()"
								[i18n]="'administration.impersonateUser'">
							</button>

							<button *ngIf="isAccountOwnerOrAnyAdmin()"
								id="btn-user-linkcp"
								class="btn btn-secondary btn-flex mr-8"
								type="button"
								(click)="clearLastApplicationState()"
								[i18n]="'administration.clearLastApplicationState'">
							</button>
						</div>
					</ng-template>
				</li>

				<li [ngbNavItem]="1">
					<a ngbNavLink class="br-edit-user-permissions-tab" [i18n]="'administration.permissions'"></a>
					<ng-template ngbNavContent>
						<div class="clearfix">
							<permissions
								[prefix]="'user'"
								[permissions]=options.permissions
								[selectedPermissions]=user.permissions
								[license]=selectedLicense
								[user]=user
								(onChange)="permissionsChangedCallback($event)">
							</permissions>
						</div>
					</ng-template>
				</li>


				<li [ngbNavItem]="2">
					<a ngbNavLink class="br-edit-user-calculated-permissions-tab" [i18n]="'administration.derivedPermissions'"></a>
					<ng-template ngbNavContent>
						<div class="clearfix">
							<permissions
								[prefix]="'derived'"
								[permissions]=options.permissions
								[selectedPermissions]=user.calculatedPermissions
								[calculated]=true>
							</permissions>
						</div>
					</ng-template>
				</li>

				<li [ngbNavItem]="3" *ngIf="hasLinkedCP() && !isWorkspaceEnabled()" [hidden]="!isCPLinkingAllowed()">
					<a ngbNavLink [i18n]="'administration.dataAccess'"></a>
					<ng-template ngbNavContent>
						<div [ngBusy]="loadingCurrentUserAccountsIntersection">
							<div *ngIf="showProjectAccessComponent">
								<projects-access
									[user]="user"
									[license]="selectedLicense"
									[licenses]="licenseTypes"
									[allowDisabled]="false"
									[contentProvidersWithAccounts]="contentProvidersWithAccounts"
									(changeProjectAccess)="changeProjectsAccess($event)">
								</projects-access>
							</div>
						</div>
					</ng-template>
				</li>

				<li [ngbNavItem]="3" *ngIf="isWorkspaceEnabled()">
					<a ngbNavLink class="br-edit-user-data-access-tab" [i18n]="'administration.dataAccess'"></a>
					<ng-template ngbNavContent>
						<div>
							<workspaces-projects-access
								[user]="user"
								[license]="selectedLicense"
								(accessChange)=updateWorkspacesAccess($event)>
							</workspaces-projects-access>
						</div>
					</ng-template>
				</li>

				<li [ngbNavItem]="4" *ngIf="hasManageGroups()">
					<a ngbNavLink class="_t-groups-tab" [i18n]="'administration.groups'"></a>
					<ng-template ngbNavContent>
						<div class="row">
							<select-items
								[topDisplayText]="'administration.editGroupMembership'|i18n"
								[objectAvailableDescriptor]="'administration.groupsAvailable'|i18n"
								[objectSelectedDescriptor]="'administration.groupsSelected'|i18n"
								[itemsField]="'groups'"
								[orderByField]="'groupName'"
								[itemsFieldIdentifier]="'groupId'"
								[outerObject]="user">
							</select-items>
						</div>
					</ng-template>
				</li>
			 </ul>

			 <div [ngbNavOutlet]="nav"></div>
		</form>
	</div>
</div>

<save-modal-footer
	(save)="save()"
	(cancel)="cancel()"
	[isDisabled]="disableSave()">
</save-modal-footer>
