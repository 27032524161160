import { AppPage } from '@app/util/app-page';
import { IConfig } from '@cxstudio/config';

declare let CONFIG: IConfig;

export class EnvironmentService {
	readonly VERSION_UPDATE_INTERVAL = 60000;
	// eslint-disable-next-line max-len
	readonly POPUP_PROPERTIES = 'directories=0,titlebar=0,toolbar=0,location=0,status=0,menubar=0,scrollbars=0,resizable=0,height=600,width=540';
	readonly LOGIN_POPUP_NAME = 'studio-auth';
	readonly LOGIN_POPUP_EMBED_NAME = 'studio-auth-embed';

	private currentApplicationVersion: string = null;
	private loginWindowInstance: Window;
	private appInitialized: boolean;

	constructor(
		private $window: ng.IWindowService,
		private $http: ng.IHttpService,
		$interval: ng.IIntervalService,
		private $location: ng.ILocationService,
		private $rootScope: ng.IRootScopeService,
		private $injector,
		private $log: ng.ILogService
	) {
		if (!this.isUnderUnitTest())
			$interval(this.updateCurrentApplicationVersion, this.VERSION_UPDATE_INTERVAL);
	}

	isUnderTest = () => {
		let lowercasedUserAgent = this.getUserAgent();
		return !lowercasedUserAgent
			|| lowercasedUserAgent.toLowerCase().contains('protractor')
			|| this.isCypress();
	};

	private getUserAgent(): string {
		let nav = this.$window.navigator;
		return nav.userAgent;
	}

	isCypress = (): boolean => {
		return this.getUserAgent().toLowerCase().contains('cypress');
	};

	isUnderUnitTest = () => {
		return (window as any).unitTest;
	};

	isPopup(): boolean {
		return !!this.$window.opener;
	}

	isPopupLogin(): boolean {
		return this.isPopup() && window.name === this.LOGIN_POPUP_EMBED_NAME;
	}

	getCurrentApplicationVersion = () => {
		return this.$http.get('commonRest/application/version', {
			cache: false,
			ignoreConnectionErrors: true
		} as ng.IRequestShortcutConfig).then((response: any) => {
			return response.data.version;
		});
	};

	isApplicationOutdated = () => {
		return this.currentApplicationVersion && CONFIG.apiVersion !== this.currentApplicationVersion;
	};

	disregardChangesAndRequireNewLogin = (): void => {
		delete this.$window.onbeforeunload;
		if (!this.$injector.get('externalAuthService').tryExpireExternalSession()) {
			this.$location.path(AppPage.LOGIN);
		}
	};

	disregardChangesAndRefresh = () => {
		delete this.$window.onbeforeunload;
		this.hardRefresh();
	};

	hardRefresh = () => {
		this.$window.location.reload();
	};

	private updateCurrentApplicationVersion = () => {
		return this.getCurrentApplicationVersion()
			.then(version => {
				this.currentApplicationVersion = version;
				if (this.isApplicationOutdated())
					this.$rootScope.$broadcast('applicationOutdatedEvent');
			});
	};

	canRunApplication = (): boolean => {
		return this.areCookiesEnabled() && this.isStorageEnabled();
	};

	isApplicationInitialized = (): boolean => {
		return this.appInitialized;
	};

	setApplicationInitialized = (initialized: boolean) => {
		this.appInitialized = initialized;
	};

	isIframe = (): boolean => {
		if (this.isCypress()) return false;

		try {
			return this.$window.self !== this.$window.top;
		} catch (e) {
			return true;
		}
	};

	private areCookiesEnabled(): boolean {
		return !!this.$window.navigator.cookieEnabled;
	}

	private isStorageEnabled(): boolean {
		try {
			return !!this.$window.sessionStorage;
		} catch (e) {
			return false;
		}
	}

	getFrameLocationHost(): string {
		try {
			if (!this.isIframe())
				return document.location.href;

			if (document.location.ancestorOrigins?.length > 0) {
				// this works only in chrome and contains all parents in reverse order
				// (0 - self.parent, 1 - self.parent.parent, ...)
				return document.location.ancestorOrigins[0]; // first parent
			}
			if (document.referrer) {
				return document.referrer;
			}
			return '';
		} catch (e) {
			this.$log.warn('Cannot get parent url:', e);
		}
	}

	isPdfExport = (): boolean => {
		return !_.isUndefined(this.$rootScope.pdfToken)
			&& !_.isUndefined(this.$rootScope.pdf)
			&& !_.isUndefined(this.$rootScope.clientTimezone);
	};

	hasPdfToken = (): boolean => {
		return !_.isUndefined(this.getPdfToken());
	};

	getPdfToken = (): string => {
		return this.$rootScope.pdfToken;
	};

	openStudioLoginPopup(): void {
		this.openPopupLogin(this.$window.location.href, this.LOGIN_POPUP_EMBED_NAME, () => {
			// studio auth popup just passes 'ready', see login.component
			this.$location.path(this.$rootScope.redirect);
		});
	}

	openStudioLoginPopupWithRedirect(redirectUrl: string, callback?: (event) => void): void {
		this.openPopupLogin(redirectUrl, this.LOGIN_POPUP_EMBED_NAME, callback);
	}

	openOAuthLoginPopup(redirectUrl: string, callback?: (event) => void): void {
		this.openPopupLogin(redirectUrl, this.LOGIN_POPUP_NAME, callback);
	}

	openPopupLogin(url: string, windowName: string, callback: (event) => void): void {
		if (!this.loginWindowInstance || this.loginWindowInstance.closed) {
			this.loginWindowInstance = this.$window.open(url, windowName, this.POPUP_PROPERTIES);
		}

		this.loginWindowInstance.focus();

		const removeListener = (): void => {
			this.$window.removeEventListener('message', messageCallback);
		};

		const messageCallback = (event): void => {
			if (event.origin === this.$window.location.origin) {
				callback(event);
			} else {
				this.$log.warn(`Invalid popup event origin: ${event.origin}. Ignoring event.`);
			}

			delete this.loginWindowInstance;
			removeListener();
		};

		// add the listener for receiving a message from the popup
		this.$window.addEventListener('message', messageCallback);
	}

	isMobile = (): boolean =>  this.$rootScope.isMobile;

	isTablet = (): boolean =>  this.$rootScope.isTablet;

	getRootFontSize = (): number => parseInt(getComputedStyle(document.documentElement).fontSize, 10);

}

app.service('environmentService', EnvironmentService);
