
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { TokensApiService } from '@app/modules/tokens/tokens-api.service';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';

@Component({
	selector: 'hierarchy-api-settings',
	templateUrl: './hierarchy-api-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyApiSettingsComponent {

	loading: Promise<any>;

	constructor(
		private clipboardService: ClipboardService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		private locale: CxLocaleService,
		private tokensApiService: TokensApiService
	) {
	}

	generateToken = ($event): void => {
		let target = $event.delegateTarget || $event.currentTarget;

		this.loading = this.tokensApiService.generateHierarchyApiToken().then(tokenObject => {
			this.clipboardService.copyToClipboard(tokenObject.token, target);
			this.globalNotificationService.addSuccessNotification(this.locale.getString('mAccount.hierarchyApiGenerateTokenSuccess'));
		});
	};

	revokeTokens = (): void => {
		this.loading = this.tokensApiService.revokeHierarchyApiTokens().then(() => {
			this.globalNotificationService.addSuccessNotification(this.locale.getString('mAccount.hierarchyApiRevokeTokensSuccess'));
		});
	};

}

app.directive('hierarchyApiSettings', downgradeComponent({component: HierarchyApiSettingsComponent}));
