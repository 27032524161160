import { Inject, Injectable } from '@angular/core';
import * as _ from 'underscore';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { CxLocaleService } from '@app/core';
import { UserBulkUpdateTableComponent } from '@app/modules/user-bulk/user-bulk-update-table/user-bulk-update-table.component';

@Injectable({
	providedIn: 'root'
})
export default class UserBulkUpdateGridDefinitionService implements IGridDefinition<UserBulkUpdateTableComponent> {

	updateType: string = 'administration.update';
	identifierSeq: number = 0;
	MAX: number = 100;

	constructor(
		protected locale: CxLocaleService,
		@Inject('gridFormatterService') protected gridFormatterService: GridFormatter,
	) {
	}

	getNextSequence = (): number => {
		if (this.identifierSeq === this.MAX) {
			this.identifierSeq = 0;
		} else {
			this.identifierSeq += 1;
		}

		return this.identifierSeq;
	};

	init = (): Promise<IGridColumn[]> => {
		//radio group name need to unique
		let identifer = `bulkUpdate_${this.getNextSequence()}_row_`;
		let columns: IGridColumn[] = [
			{
				id: 'name',
				name: this.locale.getString(this.updateType),
				field: 'displayName',
				sortable: true,
				minWidth: 280,
				width: 450,
				cssClass: 'cell-title',
				searchable: true,
				defaultSortColumn: SortDirection.ASC,
				formatter: this.gridFormatterService.getNameFormatter()
			}, {
				id: BulkUpdateValue.ADD,
				name: this.locale.getString('common.add'),
				field: BulkUpdateValue.ADD,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.ADD,
				selectAll: false,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(false)
			}, {
				id: BulkUpdateValue.REMOVE,
				name: this.locale.getString('common.remove'),
				field: BulkUpdateValue.REMOVE,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.REMOVE,
				selectAll: false,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(false)
			}, {
				id: BulkUpdateValue.NONE,
				name: this.locale.getString('group.noChange'),
				field: BulkUpdateValue.NONE,
				sortable: true,
				minWidth: 80,
				width: 150,
				cssClass: BulkUpdateValue.NONE,
				selectAll: true,
				resizable: false,
				formatter: this.getUpdateRadioFormatter(identifer),
				header: this.getHeader(true)
			}];
		return Promise.resolve(columns);
	};

	protected getHeader = (selected) => {
		return {
			buttons: [{
				cssClass: this.getButtonCSS(selected),
				command: 'select-all',
				tooltip: this.locale.getString('common.selectAll'),
				commandHandler: (command, button, column: IGridColumn, grid) => {
					let newValue = !column.selectAll;
					column.selectAll = newValue;
					button.cssClass = this.getButtonCSS(newValue);
					if (newValue) {
						let columns = grid.getColumns();
						_.chain(columns)
							.filter((col: IGridColumn) => col.id !== column.id && !_.isUndefined(col.selectAll))
							.each((col: IGridColumn) => {
								col.selectAll = false;
								if (col.header && col.header.buttons) {
									col.header.buttons[0].cssClass = this.getButtonCSS(false);
								}
							});
						grid.setColumns(columns);
					}
				}
			}]
		};
	};

	getButtonCSS(selectAll: boolean): string {
		return 'action-color '
			+ (selectAll ? 'q-icon-check' : 'q-icon-circle');
	}

	protected getUpdateRadioFormatter = (identifer: string) => {
		return (row, cell, value, columnDef, dataContext) => {
			let checked = (columnDef.field === BulkUpdateValue.NONE && !dataContext.updateValue)
				|| dataContext.updateValue === columnDef.field;
			let disabled = dataContext.disableAdd && columnDef.field === BulkUpdateValue.ADD;
			return this.gridFormatterService.getRadioFormatter(checked, disabled, identifer + row);
		};
	};
}
