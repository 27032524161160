import { BrowserInfo } from '@app/util/browser-info';
import { LicenseManager } from 'ag-grid-enterprise';
import { RouteService } from './services/route-service';
import { URLEvent } from '@app/core/cx-event.enum';
import { ConfigService } from '@app/core/config.service';
import { HighchartsConfig } from '@cxstudio/reports/visualizations/highcharts-config.service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { AppRoute } from '@cxstudio/route/app-route';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { AppLoadingService } from '@cxstudio/app-loading';

app.config(($routeProvider, $compileProvider, $controllerProvider,
	$httpProvider, $logProvider, uibDatepickerConfig,
	$locationProvider, $qProvider) => {

	// 1.5->1.7
	$routeProvider.eagerInstantiationEnabled(false);

	$routeProvider.when('/home', {
		templateUrl: 'partials/home.html',
		reloadOnSearch: false
	}).when('/home/:dashboardId', {
		templateUrl: 'partials/home.html',
		reloadOnSearch: false
	}).when('/preview/:dashboardId', {
		templateUrl: 'partials/home.html',
		reloadOnSearch: false,
		preview: true
	}).when('/request-access/:objectType/:objectId', {
		template(params): string { // replace this with ng8 route once switched to new router
			return `<request-access-page ng-if="loaded" object-type="${params.objectType}"object-id="${params.objectId}"></request-access-page>`;
		},
		allowEmbedding: true
	}).when('/grant-access/:objectType/:objectId/user/:userId', {
		template(params): string {
			return `
				<grant-access-page
					ng-if="loaded"
					object-type="${params.objectType}"
					object-id="${params.objectId}"
					user-id="${params.userId}">
				</grant-access-page>`;
		}
	}).when('/dashboard/:dashboardId', {
		template: '<book-page ng-if="::loaded" class="pt-0 w-100-percent h-100-percent"></book-page>',
		reloadOnSearch: false
	}).when('/asset-editor/:assetType/:assetId', {
		template(params): string {
			return `<asset-editor ng-if="::loaded" asset-id="${params.assetId}" asset-type="${params.assetType}"></asset-editor>`;
		}
	}).when('/books/new', {
		template: '<book-edit-page ng-if="::loaded"></book-editor>',
	}).when('/books/:bookId/editor', {
		template: '<book-edit-page ng-if="::loaded"></book-editor>',
	}).when('/widget/:dashboardId/:identifier', { // deprecated, kept to support existing links
		redirectTo: '/embed/dashboard/:dashboardId/widget/:identifier',
		allowEmbedding: true,
	}).when('/embed/dashboard/:dashboardId/widget/:identifier', {
		template: '<embedded-page ng-if="loaded" type="WIDGET"></embedded-page>',
		allowEmbedding: true,
		embedded: true
	}).when('/embed/dashboard/:dashboardId', {
		template: '<embedded-page ng-if="loaded" type="DASHBOARD"></embedded-page>',
		allowEmbedding: true,
		embedded: true,
		forcedLightTheme: true
	}).when('/embedded-error', {
		template: '<embedded-error-page></embedded-error-page>',
		allowEmbedding: true,
		public: true
	}).when('/error', {
		template: '<error-page></error-page>',
		allowEmbedding: true,
		public: true
	}).when('/login', {
		template: '<login class="pt-0"></login>',
		pageTitleKey: 'signIn',
		allowEmbedding: true
	}).when('/login/:authToken', {
		template: '<login class="pt-0"></login>',
		pageTitleKey: 'signIn'
	}).when('/user-group-management', {
		templateUrl: 'partials/users.html',
		reloadOnSearch: false,
		pageTitleKey: 'userAndGroupManagement'
	}).when('/user-group-management/:action/:userId/:maId', {
		templateUrl: 'partials/users.html',
		pageTitleKey: 'userAndGroupManagement'
	}).when('/user-upload', {
		template: '<user-upload-page ng-if="loaded" class="pt-0"></user-upload-page>',
		pageTitleKey: 'userAndGroupManagement'
	}).when('/user-bulk-update', {
		template: '<user-upload-page ng-if="loaded" [bulk-update]="true" class="pt-0"></user-upload-page>',
		pageTitleKey: 'userAndGroupManagement'
	}).when('/user-preferences', { // it is not used in application directly, but required from browser
		template: () => {
			return '<user-preferences-page></user-preferences-page>';
		}
	}).when('/preferred-email', {
		template: '<user-preferences-page></user-preferences-page>'
	}).when('/system', {
		templateUrl: 'partials/system-administration.html',
		pageTitleKey: 'systemAdministration'
	}).when('/dashboards', {
		templateUrl: 'partials/dashboards.html'
	}).when('/quick-insights', {
		templateUrl: 'partials/quick-insights.html',
		reloadOnSearch: false
	}).when('/interactions', {
		templateUrl: 'partials/interaction-explorer/interaction-explorer.html',
		pageTitleKey: 'interactionExplorer',
		reloadOnSearch: false
	}).when('/document-explorer', {
		template: '<document-explorer-page ng-if="loaded" class="pt-0 overflow-hidden"></document-explorer-page>',
		pageTitleKey: 'documentExplorer',
		allowEmbedding: true
	}).when('/filters', {
		templateUrl: 'partials/filters/filters.html',
		reloadOnSearch: false,
		pageTitleKey: 'filters'
	}).when('/metrics', {
		templateUrl: 'partials/metrics/metrics.html',
		reloadOnSearch: false,
		pageTitleKey: 'metrics'
	}).when('/unified-templates', {
		template: '<unified-templates-management ng-if="loaded" class="pt-0"></unified-templates-management>',
		reloadOnSearch: false,
		pageTitleKey: 'unified-templates'
	}).when('/drivers', {
		templateUrl: 'partials/drivers/drivers.html',
		pageTitleKey: 'drivers'
	}).when('/alerts', {
		templateUrl: 'partials/alerts/alerts.html',
		pageTitleKey: 'alerts',
		reloadOnSearch: false
	}).when('/alerts/metric/new', {
		template: '<alert-wizard-page ng-if="loaded" class="pt-0"></alert-wizard-page>',
		pageTitleKey: 'alerts',
	}).when('/alerts/metric/:alertId', {
		template: '<alert-wizard-page ng-if="loaded" class="pt-0"></alert-wizard-page>',
		pageTitleKey: 'alerts',
	}).when('/hierarchy/edit/:hierarchyId', {
		template: '<hierarchy-upload-wizard-page ng-if="loaded" class="pt-0"></hierarchy-upload-wizard-page>',
		pageTitleKey: 'orgHierarchy',
	}).when('/notifications/snooze', {
		template: '<snooze-notifications class="pt-0"></snooze-notifications>',
		pageTitleKey: 'alerts',
		reloadOnSearch: false,
		public: true
	}).when('/alert_templates', {
		templateUrl: 'partials/alert-subscription-templates/alert-subscription-templates.html',
		pageTitleKey: 'alertSubscriptionTemplates'
	}).when('/recycle_bin', {
		template: '<archive-page ng-if="loaded" class="pt-0"></archive-page>',
		pageTitleKey: 'archive',
		reloadOnSearch: false
	}).when('/projects', {
		template: '<project-assets-page ng-if="loaded"></project-assets-page>',
		pageTitleKey: 'projects',
		reloadOnSearch: false
	}).when('/sampled_audits', {
		templateUrl: 'partials/sampled-audits/sampled-audits.html',
		pageTitleKey: 'sampledAudits',
		reloadOnSearch: false
	}).when('/schedules', {
		templateUrl: 'partials/schedules/schedules.html',
		pageTitleKey: 'dashboardSchedules'
	}).when('/master_account', {
		templateUrl: 'partials/master-accounts/edit.html'
	}).when('/master-account-properties', {
		templateUrl: 'partials/master-accounts/master-account-tabs.html',
		pageTitleKey: 'accountSettings',
		reloadOnSearch: false
	}).when('/templates', {
		template: '<dashboard-templates-page ng-if="loaded" class="pt-0"></dashboard-templates-page>',
		pageTitleKey: 'dashboardTemplates'
	}).when('/redirect-error', {
		template: '<redirect-error-page></redirect-error-page>',
		pageTitleKey: 'error'
	}).when('/engage', {
		template: '<error-page></error-page>',
		pageTitleKey: 'error'
	}).when('/external-auth', {
		template: '<external-auth></external-auth>',
		pageTitleKey: 'signIn',
		allowEmbedding: true,
		embedded: true
	}).when('/auth-callback', {
		template: '<auth-callback redirect="true"></auth-callback>',
		pageTitleKey: 'signIn'
	}).when('/in-app-callback', {
		template: '<auth-callback></auth-callback>',
		pageTitleKey: 'signIn'
	}).otherwise({
		redirectTo(routeParams, path, search): string {
			if (search && search.jwt) {
				if (search.redirect) {
					return `/dashboards?jwt=${search.jwt}&redirect=${search.redirect}`;
				}
				return `/dashboards?jwt=${search.jwt}`;
			} else {
				return '/dashboards';
			}
		}
	});

	(app as any).compileProvider = $compileProvider; // to allow adding directives in runtime
	(app as any).controllerProvider = $controllerProvider; // to register widget controllers in runtime

	// request
	$httpProvider.interceptors.push('httpInterceptor'); // translate rest url, add headers
	$httpProvider.interceptors.push('cacheInterceptor'); // add params to avoid caching
	$httpProvider.interceptors.push('localeInterceptor'); // return cached locale files instead of calling backend

	// response, executed in reverse order
	$httpProvider.interceptors.push('errorInterceptor');

	// for 1.5 -> 1.7 upgrade
	$locationProvider.hashPrefix('');
	$qProvider.errorOnUnhandledRejections(false);

	$logProvider.debugEnabled(CONFIG.log); // disable debug for production
	$compileProvider.aHrefSanitizationWhitelist(/^\s*(https?|file|data):/);
	$compileProvider.debugInfoEnabled(CONFIG.test);

	uibDatepickerConfig.showWeeks = false;
});
angular.module('ui.bootstrap.tooltip').config($provide => {
	function isUnderE2ETest(): boolean {
		let nav = window.navigator;
		let lowercasedUserAgent = nav.userAgent;
		return !lowercasedUserAgent || lowercasedUserAgent.contains('protractor');
	}

	if (isUnderE2ETest()) {
		$provide.decorator('$timeout', ['$interval', '$delegate', ($interval, $delegate) => {
			let newTimeout = (fn, delay, invokeApply, pass) => {
				// there are many calls with 0 timeout in ui-bootstrap, which causes hanging of e2e
				// in some cases (e.g. opening filters popup)
				// replacing 0 delays with interval should be safe for protractor

				if (delay === 0) {
					let promise = $interval(fn, delay, 1, invokeApply, pass);
					promise._interval = true;
					return promise;
				} else {
					return $delegate(fn, delay, invokeApply, pass);
				}
			};
			(newTimeout as any).cancel = (promise) => {
				if (promise && promise._interval)
					return $interval.cancel(promise);
				else return $delegate.cancel(promise);
			};
			return newTimeout;
		}]);
	}
});

app.run((
	$rootScope: ng.IRootScopeService,
	routeService: RouteService,
	$templateCache,
	highchartsConfig: HighchartsConfig,
	configService: ConfigService,
	$location: ng.ILocationService,
	globalUnloadService: GlobalUnloadService,
	environmentService: EnvironmentService,
	appLoading: AppLoadingService,
) => {
	$rootScope.$on(URLEvent.SCOPE_LOCATION_CHANGE_START, (event, newUrl: string) => {
		if (newUrl !== $location.absUrl()
			&& !environmentService.isUnderUnitTest() // unit tests start executing infinitely due to this location change
			&& !environmentService.isUnderTest()) { // e2e for Drill to Dashboard doesn't work after location change because of missing mocks
			window.location.href = $location.absUrl(); // absUrl returns decoded url
			window.location.reload();
		} else {
			globalUnloadService.onLocationChange(event);
		}
	});

	$rootScope.$on('$routeChangeStart', (event, next: AppRoute) => {
		if (!next || !next.$$route) {
			return;
		}

		// If the config already loaded, we do not need to call loadServerConfig method
		if (configService.isConfigLoaded()) {
			routeService.onRouteChange(next);
		} else {
			configService.loadServerConfig().then(() => {
				routeService.onRouteChange(next);
			}, () => {
				appLoading.showAppError();
			});
		}
	});

	let dualDefinitionTemplate = 'partials/widgets/settings/cb/visualizations/' +
			'cb-analytic-dual-visual-settings.html';
	let dualDefinition = $templateCache.get(dualDefinitionTemplate);
	$templateCache.put(`${dualDefinitionTemplate}?type=bar`, dualDefinition);
	$templateCache.put(`${dualDefinitionTemplate}?type=line`, dualDefinition);

	$rootScope.isTablet = BrowserInfo.isTabletOrMobile();
	highchartsConfig.initDefaultOptions();


	LicenseManager.setLicenseKey(CONFIG.agGridKey);


	/* eslint-disable no-invalid-this */
	String.prototype.includes = String.prototype.includes || function(): boolean {
		return String.prototype.indexOf.apply(this, arguments) !== -1;
	};
	/* eslint-enable no-invalid-this */
});

app.factory('cgBusyDefaults', ($rootScope) => {
	if (!$rootScope.cgBusyMessage)
		$rootScope.cgBusyMessage = 'Please Wait...';
	return {
		message: {
			toString: () => $rootScope.cgBusyMessage
		},
		templateUrl: 'partials/custom/cg-busy-template.html'
	};
});

app.config((tagsInputConfigProvider) => {
	tagsInputConfigProvider.setDefaults('tagsInput', {
		placeholder: ''
	});
});

app.value('isEdge', BrowserInfo.isEdge());
app.value('isTablet', BrowserInfo.isTabletOrMobile(navigator.userAgent || navigator.vendor || (window as any).opera));
