import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { Model } from '@cxstudio/reports/entities/model';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { IgnoredDashboardFilterTag } from '@cxstudio/dashboards/dashboard-filters/ignored-dashboard-filter-tag';
import * as _ from 'underscore';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';


export class IgnoredDashboardFilterService {

	constructor(
		private locale: ILocale,
		private DateRange) {
	}

	buildTags(attributes: IReportAttribute[], models: Model[]): IgnoredDashboardFilterTag[] {
		let allTags: IgnoredDashboardFilterTag[] = [];
		allTags = allTags.concat(this.getDefaultDashboardFilterTags());
		allTags = allTags.concat(this.getDashboardFilterAttributeTags(attributes));
		allTags = allTags.concat(this.getDashboardFilterModelTags(models));

		return allTags;
	}

	buildDocumentLevelOnlyTags(): IgnoredDashboardFilterTag[] {
		return [this.getDateRangeFilterTag()];
	}

	hasTag(tags: IgnoredDashboardFilterTag[], matcher: any): boolean {
		tags = tags || [];

		let tagMatchers: any[] = tags.map(tag => tag.attributeMatcher);
		for (let tagMatcher of tagMatchers) {
			if (!_.isEmpty(tagMatcher) && _.isMatch(matcher, tagMatcher)) {
				return true;
			}
		}

		return false;
	}

	hasDateTag(tags: IgnoredDashboardFilterTag[]): boolean {
		return this.hasTag(tags, { filterType: DashboardFilterTypes.DATE_RANGE });
	}

	private getDateRangeFilterTag(): IgnoredDashboardFilterTag {
		return new IgnoredDashboardFilterTag(
			this.locale.getString('filter.dateRange'),
			{
				filterType: DashboardFilterTypes.DATE_RANGE
			});
	}

	private getDefaultDashboardFilterTags(): IgnoredDashboardFilterTag[] {
		let dateRangeTag = this.getDateRangeFilterTag();

		let savedFiltersTag = new IgnoredDashboardFilterTag(
			this.locale.getString('filter.savedFilters'),
			{
				filterType: DashboardFilterTypes.SAVED_FILTER
			});

		return [dateRangeTag, savedFiltersTag];
	}

	getDashboardFilterAttributeTags(attributes: IReportAttribute[]): IgnoredDashboardFilterTag[] {
		attributes = attributes || [];

		return attributes.map(attribute => {
			return new IgnoredDashboardFilterTag(
				this.locale.getString('widget.attribute') + ': ' + attribute.displayName,
				{
					filterType: DashboardFilterTypes.ATTRIBUTE,
					id: attribute.id
				}
			);
		});
	}

	private getDashboardFilterModelTags(models: Model[]): IgnoredDashboardFilterTag[] {
		models = models || [];

		return models.map(model => {
			return new IgnoredDashboardFilterTag(
				this.locale.getString('widget.model') + ': ' + model.name,
				{
					filterType: DashboardFilterTypes.TOPIC,
					id: model.id
				}
			);
		});
	}

	isIgnoredDashboardFilter(properties: WidgetProperties, dashboardProperties: DashboardProperties,
			dashboardFilter: DashboardFilter): boolean {
		let ignoredDashboardFilters = properties.ignoredDashboardFilters || [];

		if (DashboardFilterSelection.isText(dashboardFilter.selectedAttribute) && properties.ignoreDashboardTextFilter) {
			return true;
		}

		if (DashboardFilterSelection.isDateRange(dashboardFilter?.selectedAttribute)) {
			// admin project supports some dashboard date ranges
			if (InternalProjectTypes.isStudioAdminProject(properties.project)) {
				let supportedDateRange = this.isSameProject(properties, dashboardProperties) || this.isAdminProjectDateFilter(dashboardFilter);
				if (!supportedDateRange) {
					return true;
				}
			}
			return this.hasDateTag(properties.ignoredDashboardFilters);
		}

		if (!this.isSameProject(properties, dashboardProperties)) {
			return true;
		}

		if (properties.documentLevelOnly && dashboardFilter.selectedAttribute?.objectType === AttributeObjectType.SENTENCE) {
			return true;
		}

		if (DashboardFilterSelection.isSavedFilter(dashboardFilter.selectedAttribute)) {
			return this.hasTag(ignoredDashboardFilters,
				{ filterType: DashboardFilterTypes.SAVED_FILTER });
		}

		return dashboardFilter.selectedAttribute
			&& this.hasTag(ignoredDashboardFilters, dashboardFilter.selectedAttribute);
	}

	private isSameProject(properties: WidgetProperties, dashboardProperties: DashboardProperties): boolean {
		return ProjectIdentifier.isEqual(
			ProjectIdentifier.fromWidgetProperties(properties),
			ProjectIdentifier.fromDashboardProperties(dashboardProperties));
	}

	private isAdminProjectDateFilter(filter: any): boolean {
		let filterValue = filter?.selectedAttributeValue?.dateFilterMode;
		return !DateRangeUtils.isCustomDateFilterMode(filterValue)
			&& _.some(this.DateRange.adminProjectOptions, (option: any) => option.value === filterValue);
	}

}

app.service('ignoredDashboardFilterService', IgnoredDashboardFilterService);
