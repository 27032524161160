export class TaggingHelper {

	static tags = {
		HIDDEN: 'hiddenItem',
		INACTIVE: 'inactive',
		// below are used only in book editor
		BOOK_DISALLOWED: 'bookDisallowed',
		CONNECTED: 'selected',
		DASHBOARD_ID: (id) => `dashboard-${id}` // used for scrolling to dashboard
	};

	static tag = (item, tag) => {
		if (TaggingHelper.hasTag(item, tag)) {
			return;
		}

		item.tags = item.tags || [];
		item.tags.push(tag);
	};

	static untag = (item, tag) => {
		if (!item.tags || !TaggingHelper.hasTag(item, tag)) {
			return;
		}

		let index = item.tags.indexOf(tag);
		item.tags.splice(index, 1);
	};

	static hasTag = (item, tag) => {
		return item.tags && item.tags.indexOf(tag) !== -1;
	};
}
