import { Injectable } from '@angular/core';
import { HighchartsClosureUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';

@Injectable({
	providedIn: 'root'
})
export class LegendLinkingUtilsService {

	constructor() { }

	hideFromLegend = (item) => {
		item.showInLegend = false;
	};

	createLegendSeriesLink = (linkedSeries: any[] | any) => {
		return HighchartsClosureUtils.closureWrapper((scope) => {
			if (!(typeof linkedSeries === 'object' && linkedSeries.length))
				linkedSeries = [linkedSeries];

			linkedSeries.map((oneSeries) => {
				this.linkSeriesAction(scope.chart as unknown as Highcharts.Chart, oneSeries, (eachSeries) => {
					eachSeries.setVisible(!eachSeries.visible);
				});
			});

			return (linkedSeries.length === 1);
		});
	};

	private linkSeriesAction(chart: Highcharts.Chart, originalSeries, action: (series) => void) {
		chart.series.filter((series) => {
			if (!series.options) return false;
			if (!series.options.id || !originalSeries.id) return false;

			return series.options.id === originalSeries.id
				&& (series.options as any).isPrimary === originalSeries.isPrimary;
		}).forEach(action);
	}
}

