import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { OnInit, Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { IntegrationStatusService } from '@app/modules/system-administration/status/integration-status-service';
import { ObjectUtils } from '@app/util/object-utils';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';


@Component({
	selector: 'integration-status',
	templateUrl: './integration-status-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IntegrationStatusComponent implements OnInit {
	@Input() sortField: string;
	@Input() service: IntegrationStatusService<any>;

	statusPromise: Promise<any>;
	columns: Array<TableColumn<any>>;
	rows: any[];
	filteredRows: any[];
	search: IntegrationSatusSearch;

	constructor(
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
	) {
	}

	ngOnInit(): void {
		this.search = {};
		this.columns = this.service.getColumns();
		this.reload();
	}

	reload = (): Promise<any> => {
		this.statusPromise = this.service.getData().then((response) => {
			this.rows = response;
			this.filteredRows = this.sortedRows(ObjectUtils.copy(this.rows));
		});
		return this.statusPromise;
	};

	updateFilter = (): void => {
		let rows = this.rows;
		let searchByName = this.search.masterAccountName;
		if (searchByName) {
			rows = _.filter(this.rows, (row: any) => {
				return row.masterAccountName.toLowerCase().includes(searchByName.toLowerCase());
			});
		}
		this.filteredRows = this.sortedRows(rows);
	};

	exportData(): void {
		const page = this.locale.getString(this.service.getPageKey());
		this.cxDialogService.notify(
			this.locale.getString('administration.requestStatusExport', { page }),
			this.locale.getString('administration.exportToExcelMessage')
		).result.then(() => {
			this.statusPromise = this.service.exportData(this.search);
		}, _.noop);
	}

	private sortedRows(rows: any[]): any[] {
		if (!this.sortField)
			return rows;
		return _.sortBy(rows, item => {
			return item && item[this.sortField] && item[this.sortField].toLowerCase();
		});
	}
}

app.directive('integrationStatus', downgradeComponent({component: IntegrationStatusComponent}));
