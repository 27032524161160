import { Component, OnInit, ChangeDetectionStrategy, Input, EventEmitter, ChangeDetectorRef, Inject,
	OnChanges, Output, TemplateRef, ViewChild } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { MetricsService } from '@app/modules/metric/services/metrics.service';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { DashboardProperties } from '@cxstudio/dashboards/entity/dashboard-properties';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { ColorFunctionService } from '@cxstudio/reports/coloring/color-function.service';
import { ColorPaletteNames } from '@cxstudio/reports/coloring/color-palette-constants.service';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { IColorParent, IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { ColorUtils, PaletteType } from '@cxstudio/reports/utils/color-utils.service';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import { MetricUtils } from '@cxstudio/reports/utils/metric-utils.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';

interface ReportColorSelectorAssets {
	studioMetrics: Metric[];
	predefinedMetrics: Metric[];
	palettes: WidgetColorPalette[];
	providerColors: string[];
}

@Component({
	selector: 'report-color-selector',
	templateUrl: './report-color-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportColorSelectorComponent implements OnInit, OnChanges {
	@Input() project: WorkspaceProject;
	@Input() documentLevelOnly: boolean;
	@Input() selectedAttributes: AttributeGrouping[];
	@Input() selectedMetrics: ReportCalculation[];
	@Input() dropdownClass: string;
	@Input() dropdownDisabled: boolean;
	@Input() field: string;
	@Input() ignoredGroup: any;
	@Input() filter: PaletteType[];
	@Input() parent: IColorParent;
	@Input() skipObjectBasedColor: boolean;
	@Input() customFilter: (filter: IColorSelectorPalette) => boolean;

	@Input() selection: string;
	@Output() selectionChange = new EventEmitter<string>();

	@Input() customColor: string;
	@Output() customColorChange = new EventEmitter<string>();

	@Output() calculationChange = new EventEmitter<ReportCalculation>();

	private defaultColor: IColorSelectorPalette;
	private colorTypes: IColorSelectorPalette[];

	validPaletteOptions: IColorSelectorPalette[];
	selectedPalette: IColorSelectorPalette;
	customColorField: string;

	loading: Promise<any>;
	disabled: boolean;

	// ASSETS
	studioMetrics: Metric[];
	predefinedMetrics: Metric[];
	palettes: WidgetColorPalette[];
	providerColors: string[];

	@ViewChild('paletteTemplate') paletteTemplate: TemplateRef<any>;
	@ViewChild('selectedPaletteTemplate') selectedPaletteTemplate: TemplateRef<any>;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
		private readonly metricsService: MetricsService,
		private readonly projectContextService: ProjectContextService,
		private readonly reportSettingsService: ReportSettingsService,
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
		@Inject('metricUtils') private readonly metricUtils: MetricUtils,
		@Inject('calculationColorService')
			private readonly calculationColorService: CalculationColorService,
		@Inject('groupColorService') private readonly groupColorService: GroupColorService,
		@Inject('colorPalettes') private colorPalettes: ColorPalettes,
		@Inject('colorFunctionService') private readonly colorFunctionService: ColorFunctionService,
		@Inject('colorUtils') private readonly colorUtils: ColorUtils,
		private readonly applicationThemeService: ApplicationThemeService
	) {}

	ngOnInit(): void {
		if (!this.field) {
			this.field = 'color';
		}
		this.customColorField = ReportConstants.customColorFields[this.field];

		let noFilter = () => true;
		this.customFilter = this.customFilter || noFilter;

		this.reloadAssets();
	}

	ngOnChanges(changes: SimpleChanges<ReportColorSelectorComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.reloadAssets();
		}

		if (ChangeUtils.hasChange(changes.filter)
			|| ChangeUtils.hasChange(changes.ignoredGroup)
			|| ChangeUtils.hasChange(changes.selection)
			|| ChangeUtils.hasChange(changes.selectedAttributes)
			|| ChangeUtils.hasChange(changes.selectedMetrics)) {
				this.updateColorOptions(true, false);
		}

		// NOT IMPLEMENTED: for Dual Chart
		// check watchers with isDualChart check in color-selector.component
	}

	reloadAssets(): void {
		this.disabled = true;
		this.loading = this.loadAssets().then(assets => {
			this.studioMetrics = assets.studioMetrics;
			this.predefinedMetrics = assets.predefinedMetrics;
			this.palettes = assets.palettes;
			this.providerColors = assets.providerColors;

			this.initDefaultColor();
			this.updateColorOptions(true, false);
			this.disabled = false;
		});
	}

	private loadAssets(): Promise<ReportColorSelectorAssets> {
		let metricsPromise = this.metricsService.getMetrics(this.project, CacheOptions.CACHED);
		let predefinedMetricsPromise = this.metricsService.getDynamicPredefinedMetrics(this.project, true);
		let palettesPromise = PromiseUtils.wrap(this.colorPalettes.getWidgetPalettes());
		let providerColorsPromise = this.projectContextService.getDesignerPalette(this.project);

		return Promise.all([
			metricsPromise,
			predefinedMetricsPromise,
			palettesPromise,
			providerColorsPromise
		]).then((result: any[]): ReportColorSelectorAssets => {
			return {
				studioMetrics: result[0],
				predefinedMetrics: result[1],
				palettes: result[2],
				providerColors: result[3],
			};
		});
	}

	private initDefaultColor(): void {
		let dashboardProperties: DashboardProperties = this.widgetsEditService.getDashboard().properties;

		let colorObject: Partial<IColorSelectorPalette> = this.colorUtils.getDashboardDefaultColor(dashboardProperties);
		if (!colorObject) {
			colorObject = {
				name: _.findWhere(this.palettes, {defaultPalette: true}).name
			};
		}

		if (colorObject.name !== ColorPaletteNames.CUSTOM) {
			const colors = this.colorFunctionService.getBuilder()
				.withPalettes(this.palettes, this.providerColors)
				.buildColorArray(colorObject.name);
			colorObject.customColor = colors[0];
		}

		if (!colorObject.customColor) {
			colorObject.customColor = ColorUtilsHelper.DEFAULT_CUSTOM_COLOR;
		}

		this.defaultColor = colorObject as IColorSelectorPalette;
	}

	getColorDisplayName = (name: string) => {
		let color = _.find(this.colorTypes, {name});
		return color ? color.displayName : null;
	};

	showColorInput = (): boolean => {
		return this.selection === ColorPaletteNames.CUSTOM;
	};

	private getColorArray = (colorName: string, custom: string): string[] => {
		return this.getColorArrayInternal(colorName, custom, this.parent);
	};

	private getColorArrayInternal(colorName: string, custom: string, parent?: IColorParent): string[] {
		let metricColorArray = this.colorFunctionService.getBuilder()
			.withMetrics(_.union(this.predefinedMetrics || [], this.studioMetrics || []))
			.withPalettes(this.palettes, this.providerColors)
			.buildColorArray(colorName);
		if (metricColorArray)
			return metricColorArray;
		let parentArray = parent ? this.getColorArrayInternal(parent.name, parent.customColor) : undefined;
		return this.colorUtils.getColorArray(colorName, custom, parentArray);
	}

	private colorFilter = (types: PaletteType[]): (item: IColorSelectorPalette) => boolean => { // gets types from arguments
		return (item: IColorSelectorPalette) => {
			let matches = _.contains(types, item.type);

			if (matches && item.name === ColorPaletteNames.PROVIDER_PALETTE && !this.providerColors)
				return false;

			return matches;
		};
	};

	private updateColorOptions = (newValue, oldValue): void => {
		if (newValue === oldValue || !this.palettes) {
			return;
		}

		let isDarkMode = this.applicationThemeService.isShowingDarkTheme();

		let colors: IColorSelectorPalette[] = ObjectUtils.copy(this.colorUtils.getColorTypes());

		colors = _.filter(colors, color => color.type !== PaletteType.PALETTE);
		let selectedColor = this.colorUtils.resolvePalette(this.palettes, this.selection);
		let visiblePalettes = _.chain(this.palettes)
			.filter(palette => !palette.deleted && !palette.designerPalette)
			.filter(palette => palette.enabled || selectedColor?.name === palette.name)
			.map(palette => {
				return {
					id: palette.id,
					name: palette.name,
					displayName: palette.displayName,
					type: PaletteType.PALETTE,
					order: 10,
					colorPalette: isDarkMode && palette.darkModeColors || palette.colors
				} as IColorSelectorPalette;
			}).value();
		colors.pushAll(visiblePalettes);

		let designerPalette = this.getDesignerPalette();
		if (designerPalette)
			colors.push(designerPalette);

		if (!this.skipObjectBasedColor && !this.documentLevelOnly) {
			colors.pushAll(this.getCalculationColors());
		}

		if (this.selectedAttributes && !this.selectedAttributes.isEmpty()) {
			if (!this.skipObjectBasedColor) {
				let groupColors = this.groupColorService.getGroupColorOptions(this.getApplicableGroupings());
				colors = this.removeColorsByRawName(colors, _.map(groupColors, 'rawName'));
				colors.pushAll(groupColors);
			}
		}

		colors.sort(this.colorUtils.getSortFunction());

		let allowedColorTypes = ObjectUtils.copy(this.filter);
		// we can add folder type to everything, because folders should always be allowed
		allowedColorTypes.push(PaletteType.FOLDER);

		this.colorTypes = colors;
		this.validPaletteOptions = this.getAllowedPalettes(this.colorTypes, allowedColorTypes);
		this.validPaletteOptions.forEach(this.addGetColorFunction);

		// support defunct "solid" color, just in case
		if (this.selection === PaletteType.SOLID) {
			this.selection = ColorPaletteNames.CUSTOM;
			this.selectionChange.emit(ColorPaletteNames.CUSTOM);
			this.setCustomColor(ColorUtilsHelper.DEFAULT_CUSTOM_COLOR);
		}

		let selected = _.findWhere(colors, { name: selectedColor ? selectedColor.name : this.selection });

		if (!selected) {
			// look through folders
			selected = _.chain(colors)
				.filter({type: PaletteType.FOLDER} as any)
				.pluck('children')
				.flatten()
				.findWhere({name: this.selection})
				.value();
		}
		if (!selected) {
			this.setCustomColor(this.colorUtils.getDefaultCustomColor(this.defaultColor));
			this.selectPalette(this.getDefaultSelection(colors));
		} else if (selected.name === ColorPaletteNames.CUSTOM && _.isUndefined(this.getCustomColor())) {
			selected.customColor = this.colorUtils.getDefaultCustomColor(this.defaultColor);
			this.setCustomColor(selected.customColor);
			this.setSelectedPalette(selected);
		} else {
			this.setSelectedPalette(selected);

		}
	};

	private getAllowedPalettes(palettes, allowedColorTypes: PaletteType[]): IColorSelectorPalette[] {
		return palettes.filter(palette => {
			if (palette.children) {
				// recurse over children
				palette.children = this.getAllowedPalettes(palette.children, allowedColorTypes);
				return true;
			}

			return !palette.designerPalette
				&& this.colorFilter(allowedColorTypes)(palette) // %%PROVIDER_PALETTE remove once provider's palette is moved to studio
				&& this.customFilter(palette);
		});
	}

	private getDesignerPalette(): IColorSelectorPalette {
		let palette = _.findWhere(this.palettes, {designerPalette: true});
		if (_.isEmpty(this.providerColors) || !palette)
			return null;
		return {
			id: palette.id,
			name: palette.name,
			displayName: palette.displayName,
			type: PaletteType.PROVIDER,
			order: 12,
			colorPalette: this.providerColors
		} as IColorSelectorPalette;
	}

	private getDefaultSelection(colors: IColorSelectorPalette[]): IColorSelectorPalette {
		let defaultMAPaletteName = _.findWhere(this.palettes, {defaultPalette: true}).name;
		let defaultSelection = _.findWhere(colors, {name: this.getDefaultPaletteName(defaultMAPaletteName)});
		if (!defaultSelection)
			defaultSelection = _.findWhere(colors, {name: ColorPaletteNames.PALETTE_1})
				|| _.findWhere(colors, {name: defaultMAPaletteName});
		return defaultSelection;
	}

	private getDefaultPaletteName(defaultMAPaletteName: string): string {
		let paletteName = this.colorUtils.getDefaultPalette(this.palettes, this.defaultColor, this.field);
		return paletteName || defaultMAPaletteName;
	}

	private getCustomColor(): string {
		return this.customColor;
	}

	setCustomColor(customColor: string): void {
		this.customColor = customColor;
		this.customColorChange.emit(customColor);
	}

	private addGetColorFunction = (palette: IColorSelectorPalette): void => {
		palette.getColors = () => {
			if (palette.type === 'folder') {
				return [];
			}

			if (palette.colorPalette) {
				return palette.colorPalette;
			}

			return this.getColorArray(palette.name, this.getCustomColor()
				|| (this.defaultColor && this.defaultColor.customColor));
		};
	};

	private getCalculationColors(): IColorSelectorPalette[] {

		let validColorableMetrics = this.studioMetrics
			? _.filter(this.studioMetrics, this.colorUtils.isColorableMetric)
			: this.selectedMetrics;

		let colorMetricDefinitions = this.calculationColorService.getCalculationColorOptions(
			validColorableMetrics as ReportCalculation[]);

		let predefinedColors = this.getPredefinedCalculationColors();
		predefinedColors.forEach(this.addGetColorFunction);
		colorMetricDefinitions.forEach(this.addGetColorFunction);

		let result = [];
		if (this.field !== 'popColor' && this.field !== 'secondaryPopColor') {
			let metricColorFolder = {
				type: PaletteType.FOLDER,
				displayName: this.locale.getString('preview.colByMetric'),
				children: colorMetricDefinitions,
				order: 1000 // always at the bottom
			};
			if (colorMetricDefinitions.length) result.push(metricColorFolder);

			result.pushAll(predefinedColors);
		}
		return result;
	}

	private getPredefinedCalculationColors(): IColorSelectorPalette[] {
		let predefinedMetrics =
			this.predefinedMetrics && this.metricUtils.toPredefinedGroupBy(this.predefinedMetrics);
		let predefinedColors: any[] = this.calculationColorService.getPredefinedColorOptions(predefinedMetrics);
		if (this.ignoredGroup) {
			_.each(predefinedColors, option => {
				if (option.rawName === this.ignoredGroup.rawName) {
					// group is without "_calculation."" prefix
					option.disabled = !option.name.contains(this.ignoredGroup.name);
				}
			});
		}
		return predefinedColors;
	}

	private getApplicableGroupings(): any[] {
		let groupingFilter;
		if (this.ignoredGroup) {
			// CSI-8708 do not show stacked grouping colors for non-stacked secondary axis
			groupingFilter = (item: AttributeGrouping) => {
				return item && item.name !== this.ignoredGroup.name;
			};
		}
		return _.filter(this.selectedAttributes, groupingFilter);
	}

	private removeColorsByRawName(colors: IColorSelectorPalette[], rawNames: string[]): IColorSelectorPalette[] {
		// removes calculation colors which should be replaced with grouping
		let filterFunc = item => {
			return !_.contains(rawNames, item.rawName);
		};
		let result = _.filter(colors, filterFunc);
		let folders = _.filter(result, {type: PaletteType.FOLDER} as any);
		_.each(folders, (folder: any) => folder.children = _.filter(folder.children, filterFunc));
		return result;
	}

	selectPalette = (palette: IColorSelectorPalette) => {
		if (!palette || palette.disabled) {
			return;
		}

		this.selectionChange.emit(palette.name);
		this.setSelectedPalette(palette);
		if (palette.name === 'custom') {
			this.setCustomColor(this.getCustomColor() || this.colorUtils.getDefaultCustomColor(this.defaultColor));
		}

		this.changeColor();
	};

	private setSelectedPalette(palette: IColorSelectorPalette): void {
		this.selectedPalette = palette;
		this.ref.detectChanges();
	}

	private changeColor = () => {
		let metrics = []
			.concat(this.studioMetrics || [])
			.concat(this.predefinedMetrics || []);

		let metricsForColoring: ReportCalculation = this.colorUtils.getColoringMetric(this.selection, metrics);

		if (metricsForColoring) {
			this.reportSettingsService
				.getCalculationSettingsByProject(this.project, metricsForColoring)
				.then((customSettings) => {
					let defaultSettings = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(
						metricsForColoring as CalculationWithFormat,
						this.studioMetrics
					);

					_.extend(metricsForColoring, defaultSettings, customSettings);
					this.calculationChange.emit(metricsForColoring);
			});
		} else {
			this.calculationChange.emit(metricsForColoring);
		}
	};

}
