import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IAccountSelection } from '@app/modules/account/account-selection.interface';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export default class MetricsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.METRICS);
	}

	getMetrics = (project: IProjectSelection, cache = CacheOptions.CACHED, local = false): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBaseProjectPath(project) + '/metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	};

	getMetricsForAccount = (account: IAccountSelection,
		cache = CacheOptions.CACHED, local = false): Promise<Metric[]> => {

		let options: CxHttpRequestOptions<HttpParams> = { cache, local };
		let url = this.getBaseAccountPath(account) + '/metrics';
		return this.cachedHttp.get(url, options) as Promise<Metric[]>;
	};

	getPredefinedMetrics = (project: IProjectSelection, cache = CacheOptions.CACHED): AssetPromisePlatformProject<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache };
		let url = this.getBaseProjectPath(project) + '/predefined-metrics';
		return this.cachedHttp.get(url, options) as AssetPromisePlatformProject<Metric[]>;
	};

	getPredefinedMetricsForAccount = (account: IAccountSelection, cache = CacheOptions.CACHED): Promise<Metric[]> => {
		let options: CxHttpRequestOptions<HttpParams> = { cache };
		let url = this.getBaseAccountPath(account) + '/predefined-metrics';
		return this.cachedHttp.get(url, options) as Promise<Metric[]>;
	};

	getPredefinedMetricDefaultsForMasterAccount = (cache = CacheOptions.CACHED): Promise<Metric[]> => {
		return this.cachedHttp.get(`rest/resources/predefined-metrics/defaults`, {cache});
	};

	private getBaseProjectPath = (project: IProjectSelection): string => {
		return `rest/resources/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`;
	};

	private getBaseAccountPath = (account: IAccountSelection): string => {
		return `rest/resources/cp/${account.contentProviderId}/account/${account.accountId}`;
	};
}
