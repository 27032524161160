import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { CxHttpRequestOptions } from '@app/core/cx-http-request-options';

@Injectable()
export class UserSearchAPI {

	private static readonly SUGGEST_LIMIT = 20;

	constructor(
		private cxHttp: CxHttpService
	) {}

	getGroupsSuggestion = (search: string): Promise<string[]> => {
		const options = this.getRequestOptions(search);
		return this.cxHttp.get('rest/users/filters/suggestions/groups', options);
	};

	getCustomFieldsSuggestion = (search: string): Promise<string[]> => {
		const options = this.getRequestOptions(search);
		return this.cxHttp.get('rest/users/filters/suggestions/custom-fields', options);
	};

	getTagsSuggestion = (search: string): Promise<string[]> => {
		const options = this.getRequestOptions(search);
		return this.cxHttp.get('rest/users/filters/suggestions/tags', options);
	};

	private getRequestOptions = (search: string): CxHttpRequestOptions<HttpParams> => {
		return {
			params: new HttpParams({fromObject: {
				search,
				limit: UserSearchAPI.SUGGEST_LIMIT.toString()
			}})
		};
	};
}
