import ILocale from '@cxstudio/interfaces/locale-interface';
import { DocumentDisplayedItem, DocumentDisplayedItemType } from './document-displayed-item.interface';
import { ModalBindings } from '@cxstudio/common/modal-bindings';
import DocumentDisplayedItemsDialogContent from './document-displayed-items-dialog-content';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { ISearchableHierarchyItem } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import DocumentDisplayedItemsSelection from './document-displayed-items-selection';

export default class DocumentDisplayedItemsDialogComponent extends ModalBindings<DocumentDisplayedItemsDialogContent> {

	private static MAX_AVAILABLE_MODELS: number = 3;
	private static MAX_AVAILABLE_ATTRIBUTES: number = 5;

	onSelectionChange: (selection: { $selection: DocumentDisplayedItemsSelection }) => void;

	private availableItemsTree;
	private selectedTree;

	constructor(
		private locale: ILocale,
		private optionsTemplatesService: OptionsTemplatesService
	) {
		super();
	}

	$onInit(): void {
		this.initialize();
	}

	private initialize = (): void => {
		this.selectedTree = this.optionsTemplatesService.getMobileDocumentDisplayedItemsSelectedTemplate();
		this.populateSelectedItemsFromAvailable();
	};

	private commitChanges = (): void => {
		if (this.onSelectionChange) {
			this.onSelectionChange({ $selection: { items: this.getSelectedItems() }});
		}
	};

	private populateSelectedItemsFromAvailable = (): void => {
		this.getPreselectedItems()
			.forEach(item => this.selectItem(item, true));
		this.ensureDisabledItems();
	};

	private getPreselectedItems = (): DocumentDisplayedItem[] => {
		return HierarchyUtils.findItems(this.getAvailableHierarchy(), (item: any) => item.selected);
	};

	private getSelectedItems = (): DocumentDisplayedItem[] => {
		return HierarchyUtils.findItems(this.getSelectedHierarchy(), (item: any) => !item.children);
	};

	private getAvailableItems = (): DocumentDisplayedItem[] => {
		return HierarchyUtils.findItems(this.getAvailableHierarchy(), (item: any) => !item.children);
	};

	private getSelectedAttributes = (): DocumentDisplayedItem[] => {
		return HierarchyUtils.findItems(this.getSelectedHierarchy(), this.isAttributeItem) as any[];
	};

	private getSelectedModels = (): DocumentDisplayedItem[] => {
		return HierarchyUtils.findItems(this.getSelectedHierarchy(), this.isModelItem) as any[];
	};

	selectItem = (item: DocumentDisplayedItem | any, ignoreLimits?: boolean): void => {
		ignoreLimits = !!ignoreLimits;

		if (this.isFolder(item) || (this.isSelectedLimitReached(item) && !ignoreLimits))
			return;

		HierarchyUtils.addToHierarchy(this.getSelectedHierarchy(), item);
		HierarchyUtils.removeFromHierarchy(this.getAvailableHierarchy(), item);

		if (!ignoreLimits) {
			this.ensureDisabledItems();
		}

		this.commitChanges();
	};

	private ensureDisabledItems = (): void => {
		if (this.isAttributeLimitReached()) {
			this.disableAttributeItems();
		}

		if (this.isModelLimitReached()) {
			this.disableModelItems();
		}
	};

	isLimitReached = (): boolean => {
		return this.isAttributeLimitReached() || this.isModelLimitReached();
	};

	isLimitExceeded = (): boolean => {
		return this.isAttributeLimitExceeded() || this.isModelLimitExceeded();
	};

	deselectItem = (item: DocumentDisplayedItem | any): void => {
		if (this.isFolder(item))
			return;

		HierarchyUtils.addToHierarchy(this.getAvailableHierarchy(), item, (node: any) => node.originalItem);
		HierarchyUtils.removeFromHierarchy(this.getSelectedHierarchy(), item);

		if (!this.isAttributeLimitReached()) {
			this.enableAttributeItems();
		}
		if (!this.isModelLimitReached()) {
			this.enableModelItems();
		}

		this.ensureDisabledItems();

		this.commitChanges();
	};

	private disableAttributeItems = (): void => {
		HierarchyUtils.applyRecursively(this.getAvailableHierarchy(),
			(item: ISearchableHierarchyItem) => item._notRecommended = true, this.isAttributeItem);
	};

	private disableModelItems = (): void => {
		HierarchyUtils.applyRecursively(this.getAvailableHierarchy(),
			(item: ISearchableHierarchyItem) => item._notRecommended = true, this.isModelItem);
	};

	private enableAttributeItems = (): void => {
		HierarchyUtils.applyRecursively(this.getAvailableHierarchy(),
			(item: ISearchableHierarchyItem) => item._notRecommended = false, this.isAttributeItem);
	};

	private enableModelItems = (): void => {
		HierarchyUtils.applyRecursively(this.getAvailableHierarchy(),
			(item: ISearchableHierarchyItem) => item._notRecommended = false, this.isModelItem);
	};

	private isSelectedLimitReached = (item: ISearchableHierarchyItem): boolean => {
		if (this.isAttributeItem(item) && this.isAttributeLimitReached()) {
			return true;
		} else if (this.isModelItem(item) && this.isModelLimitReached()) {
			return true;
		} else {
			return false;
		}
	};

	private isAttributeLimitReached = (): boolean => {
		return this.getSelectedAttributesCount() >= DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_ATTRIBUTES;
	};

	private isModelLimitReached = (): boolean => {
		return this.getSelectedModelsCount() >= DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_MODELS;
	};

	private isAttributeLimitExceeded = (): boolean => {
		return this.getSelectedAttributesCount() > DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_ATTRIBUTES;
	};

	private isModelLimitExceeded = (): boolean => {
		return this.getSelectedModelsCount() > DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_MODELS;
	};

	private isModelItem = (item: ISearchableHierarchyItem): boolean => {
		return item.type === DocumentDisplayedItemType.MODEL;
	};

	private isAttributeItem = (item: ISearchableHierarchyItem): boolean => {
		return item.type === DocumentDisplayedItemType.ATTRIBUTE;
	};

	private isFolder = (item): boolean => {
		return !!item.children;
	};

	deselectAllItems = (): void => {
		this.getSelectedItems()
			.forEach(this.deselectItem);

		this.commitChanges();
	};

	private getAvailableHierarchy = () => {
		return { children: this.availableItemsTree } as ISearchableHierarchyItem;
	};

	private getSelectedHierarchy = () => {
		return { children: this.selectedTree } as ISearchableHierarchyItem;
	};

	getAvailableItemsLabel = (): string => {
		let count = this.getAvailableItems().length;
		return this.locale.getString('mobile.attributesAndModelsAvailable', { count });
	};

	getSelectedModelsLabel = (): string => {
		let count = this.getSelectedModelsCount();
		let maxCount = DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_MODELS;
		return this.locale.getString('mobile.topicModelsSelected', { count, maxCount });
	};

	getSelectedAttributesLabel = (): string => {
		let count = this.getSelectedAttributesCount();
		let maxCount = DocumentDisplayedItemsDialogComponent.MAX_AVAILABLE_ATTRIBUTES;
		return this.locale.getString('mobile.attributesSelected', { count, maxCount });
	};

	private getSelectedModelsCount = (): number => {
		return this.getSelectedModels().length;
	};

	private getSelectedAttributesCount = (): number => {
		return this.getSelectedAttributes().length;
	};

}

app.component('documentDisplayedItems', {
	bindings: {
		availableItemsTree: '<',
		onSelectionChange: '&?'
	},
	controller: DocumentDisplayedItemsDialogComponent,
	templateUrl: 'partials/mobile/document-displayed-items.component.html'
});
