import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';

export type ConversationClusterPredicate = (d1: ConversationDataPoint, d2: ConversationDataPoint) => boolean;
export class ConversationChartUtils {

	isSameChannel = (sentence1: ConversationDataPoint, sentence2: ConversationDataPoint): boolean => {
		return (sentence1.channel === sentence2.channel);	
	};

	isSameChannelAndTimestamp = (sentence1: ConversationDataPoint, sentence2: ConversationDataPoint): boolean => {
		return this.isSameChannel(sentence1, sentence2) && sentence1.timestamp === sentence2.timestamp;
	};

	private isSameClusterAsNext(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return (index < (dataSet.length - 1)) && predicate(dataSet[index], dataSet[index + 1]);
	}

	private isSameClusterAsPrevious(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return (index > 0) && predicate(dataSet[index], dataSet[index - 1]);
	}

	isWrappedBySameCluster(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return this.isSameClusterAsPrevious(dataSet, index, predicate) &&
			this.isSameClusterAsNext(dataSet, index, predicate);
	}

	isStartOfCluster(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return !this.isSameClusterAsPrevious(dataSet, index, predicate) &&
			this.isSameClusterAsNext(dataSet, index, predicate);
	}

	isEndOfCluster(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return this.isSameClusterAsPrevious(dataSet, index, predicate) &&
			!this.isSameClusterAsNext(dataSet, index, predicate);
	}

	isPartOfCluster(dataSet: any[], index: number, predicate: ConversationClusterPredicate): boolean {
		return this.isSameClusterAsPrevious(dataSet, index, predicate) 
			|| this.isSameClusterAsNext(dataSet, index, predicate);
	}
}