import * as _ from 'underscore';

export class DriversErrorUtils {

	private static readonly EXPECTED_AN_ERRORS = [
		'Target occurs in all records',
		'Target occurs in no selected records',
		'Target occurs only once',
		'Not enough data'
	];
	static readonly ERROR_STATUS_PREFIX = '__status:';

	static isExpectedError = (message: string): boolean => {
		return DriversErrorUtils.isErrorStatus(message) || DriversErrorUtils.isAnalyticDatasetError(message);
	};

	static isAnalyticDatasetError = (message: string): boolean => {
		return message && !!_.find(DriversErrorUtils.EXPECTED_AN_ERRORS, errorPart => message.contains(errorPart));
	};

	static isErrorStatus = (message: string): boolean => {
		return message && message.startsWith(DriversErrorUtils.ERROR_STATUS_PREFIX);
	};

	static getErrorKey = (message: string): string => {
		return message && message.replace(DriversErrorUtils.ERROR_STATUS_PREFIX, '');
	};

}
