import * as _ from 'underscore';
import { User } from '@cxstudio/user-administration/users/entities/user';


export class UserSelectorController implements ng.IController {

	selectedUser: User;
	pinnedUsers: User[];
	usersProvider: (params: {emailSearch: string}) => ng.IPromise<User[]>;
	onUserChange: (params: any) => void;

	latestUsersPromise: ng.IPromise<any>;
	isLatestPromiseActive: boolean;
	users: User[];

	constructor() { }

	$onInit(): void {
		this.pinnedUsers = this.pinnedUsers || [];
		if (this.selectedUser) {
			this.setUsers([this.selectedUser]);
		}
		this.reloadList();
	}

	onSearch = (query: string): void => {
		this.reloadList(query);
	};

	onChange = (user: User): void => {
		this.onUserChange({
			selectedUser: user
		});
	};

	private reloadList(emailSearch?: string): void {
		let currentUsersPromise: ng.IPromise<any> = this.latestUsersPromise = this.usersProvider({ emailSearch });
		this.users = [];
		this.isLatestPromiseActive = true;
		currentUsersPromise.then(data => {
			if (currentUsersPromise === this.latestUsersPromise) {
				this.setUsers(data);
				this.isLatestPromiseActive = false;
			}
		});
	}

	private setUsers(users: User[]): void {
		let usersToSet = [].concat(this.pinnedUsers).concat(users);
		this.users = _.uniq(usersToSet, false, user => user.userId);
	}
}

app.component('userSelector', {
	controller: UserSelectorController,
	templateUrl: 'partials/components/user-selector-component.html',
	bindings: {
		selectedUser: '<',
		pinnedUsers: '<?',
		usersProvider: '&',
		onUserChange: '&'
	}
});
