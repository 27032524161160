import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import ContentProviderManagementApiService from '@app/modules/system-administration/platform/content-provider/content-provider-management-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { ContentProviderEditDialogComponent } from '@app/modules/system-administration/content-provider/content-provider-edit-dialog.component';
import { Pagination } from '@app/shared/components/pagination/pagination';

@Component({
	selector: 'sys-admin-content-providers',
	templateUrl: './sys-admin-content-providers.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class SysAdminContentProvidersComponent implements OnInit {
	loading: Promise<any>;

	cpQuery: string;
	selectedWidgets: any[];
	pagedCps;
	pagination: Pagination;

	getContentProvider;

	constructor(
		private locale: CxLocaleService,
		private contentProviderManagementApiService: ContentProviderManagementApiService,
		private cxDialogService: CxDialogService,
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.pagination = new Pagination(10);
		this.cpQuery = '';

		this.getContentProvider = this.contentProviderManagementApiService.getContentProvider;

		this.selectedWidgets = [];
		this.loading = this.reloadPagedCpList();
		
	}


	reloadPagedCpList = () => {
		let params: any = {};
		params.isPaginated = this.pagination.isPaginated;
		params.pageSize = this.pagination.pageSize;
		params.currentPage = this.pagination.currentPage;
		params.filterText = this.cpQuery;
		params.filterField = '';

		return this.contentProviderManagementApiService.getPagedCpList(params).then((resp) => {
			let result = resp;
			this.pagination.totalItems = result.totalCount;
			this.pagedCps = result.data;
			this.ref.markForCheck();
		});
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reloadPagedCpList();
	};

	onSearchChange = (): void => {
		this.pagination = new Pagination(10);
		this.reloadPagedCpList();	
	};
	/*
	 * Data manipulations
	 */

	registerContentProvider = (cpItem) => {
		this.contentProviderManagementApiService.addContentProvider(cpItem).then(this.reloadPagedCpList);
	};

	editContentProvider = (cpItem) => {
		this.contentProviderManagementApiService.updateContentProvider(cpItem).then(this.reloadPagedCpList);
	};

	deleteContentProvider = (cpId) => {
		this.contentProviderManagementApiService.deleteContentProvider(cpId).then(this.reloadPagedCpList);
	};

	/*
	 * Modals
	 */
	openContentProviderDialog = (cp, action) => {
		let modal = this.cxDialogService.openDialog(ContentProviderEditDialogComponent);
		modal.componentInstance.cpData = cp;
		modal.componentInstance.action = action;
		return modal;
	};

	/*
	 * UI interactions
	 */
	addCPFunction = () => {
		let cp = {
			data: {}
		};
		cp.data = {
			name: '',
			endPoint: '',
			description: '',
			type: 'CB',
			version: ''
		};
		this.editAddCPFunction(cp, 'add');
	};

	editCpFunction = (cpId) => {
		this.getContentProvider(cpId).then(cp => this.editAddCPFunction(cp, 'edit'));
	};


	editAddCPFunction = (cp, action) => {
		let dlg = this.openContentProviderDialog(cp, action);
		dlg.result.then((cpItem) => {
			if (action === 'add') {
				this.registerContentProvider(cpItem);
			} else if (action === 'edit') {
				this.editContentProvider(cpItem);
			}
		}, () => {});
	};

	deleteCpFunction = (cpId) => {
		let dlg = this.cxDialogService.warningWithConfirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('administration.deleteThisCP')
		);
		dlg.result.then(() => {
			this.deleteContentProvider(cpId);
		}).catch(() => {});
	};
}