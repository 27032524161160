import { ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';

export class ReportScopeUtils {

	constructor() {}

	emitContextMenuHandling = (reportScope, element, rightClickOnly?: boolean) => {
		if (!rightClickOnly) {
			$(element).bind('click.chartEvents', e => this.onClick(reportScope, e));
		}
		$(element).bind('contextmenu', e => this.onContextMenu(reportScope, e));

		reportScope.$on('$destroy', () => {
			// CXS-1361: when switching from chart to table, click handler remains active
			$(element).off('click.chartEvents');
			$(element).off('contextmenu');
		});
	};

	onClick = (reportScope, event): void => {
		let point = this.getProcessedPoint(reportScope);
		reportScope.handleClick(point, event);
	};

	onContextMenu = (reportScope, event): void => {
		event.preventDefault();
		let point = this.getProcessedPoint(reportScope);
		if (!point) {
			reportScope.handleRightClick(event);
		} else {
			reportScope.handleClick(point, event, true);
		}
	};

	getProcessedPoint = (reportScope: ReportDefinitionScope) => {
		if (reportScope.selectedPoint && !reportScope.chart?.cancelClick) {
			let point = angular.copy(reportScope.selectedPoint);
			point._element = reportScope.selectedPoint._element;
			point.isWeekDescription = reportScope.dataObject.metadata.isWeekDescription;
			//add information about dateRangeP1
			point.P1StartDate = reportScope.dataObject.metadata.P1StartDate;
			point.P1EndDate = reportScope.dataObject.metadata.P1EndDate;
			point.dateRangeP2 = reportScope.dataObject.metadata.dateRangeP2;
			return point;
		}
		return null;
	};
}

app.service('reportScopeUtils', ReportScopeUtils);
