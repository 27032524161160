import { ChangeDetectionStrategy, Component, forwardRef, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DateRange } from '@app/modules/filter/entities/date-range';
import { DateRangeResolveContext } from '@app/modules/filter/entities/date-range-resolve-context';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CaseEvent, CaseVisualization } from '@app/modules/widget-settings/case-viz-settings/case-visualization';
import { PromiseUtils } from '@app/util/promise-utils';
import EngagorCase from '@cxstudio/engagor/engagor-case';
import { EngagorCaseUtils } from '@cxstudio/engagor/engagor-case-utils';
import EngagorTopic from '@cxstudio/engagor/engagor-topic';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { EngagorApiService } from '@cxstudio/services/data-services/engagor-api.service';
import * as moment from 'moment-timezone';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';


export class CaseVisualizationConfig {
	enabled: boolean;
	selectedCases: CaseVisualization[];
}

@Component({
	selector: 'case-viz-settings',
	templateUrl: './case-viz-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	styles: [`
	.case-viz-id { width:208px; }
	.case-viz-name { width:126px; }
	.case-viz-events { width: 196px; }
	.case-viz-date { width: 76px; }
	.case-viz-color { width: 120px; }
	input[type="search"]#typeahead-template::-webkit-search-cancel-button {-webkit-appearance: searchfield-cancel-button;}
	ngb-typeahead-window.dropdown-menu { max-height: 500px !important; overflow-y: auto;}
	checkbox-dropdown .chicklet { width: 100%; }
`],
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => CaseVizSettingsComponent), multi: true}
	]
})
export class CaseVizSettingsComponent implements OnInit, ControlValueAccessor {
	@Input() project: AccountOrWorkspaceProject;
	@Input() widgetDateRange: DateFilter;

	config: CaseVisualizationConfig = {enabled: false, selectedCases: []} as CaseVisualizationConfig;

	betaEnabled: boolean;
	showCaseViz: boolean;
	limitWarningVisible: boolean = false;
	cases: EngagorCase[];
	availableEvents: Array<{displayName: string; value: CaseEvent}>;
	dateRange: DateRange;
	loadingPromise: ng.IPromise<any>;
	private topics: EngagorTopic[];

	readonly CASE_LIMIT = 10;

	constructor(
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly applicationThemeService: ApplicationThemeService,
		@Inject('engagorApiService') private readonly engagorApiService: EngagorApiService,
		private readonly filtersService: FiltersService,
		private readonly locale: CxLocaleService
	) { }

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: CaseVisualizationConfig) => void = _.noop;

	writeValue(obj: CaseVisualizationConfig): void {
		if (!_.isEqual(obj, this.config)) {
			this.config = obj;
			this.onUpdate();
		}
	}

	registerOnChange(fn: (val: CaseVisualizationConfig) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}

	ngOnInit(): void {
		this.betaEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.CASE_VIZ_ON_TRENDS);
		if (!this.betaEnabled) {
			return;
		}

		this.loadTopics();
		this.availableEvents = Object.keys(CaseEvent).map((key: keyof typeof CaseEvent) => ({
			displayName: this.getEventDisplayName(key),
			value: CaseEvent[key]
		}));

		const dateRangeData: DateRangeResolveContext = {
			dateRangeFilter: this.widgetDateRange,
			timezoneName: moment.tz.guess()
		};
		//default range till we resolve widget range
		this.dateRange = {
			startDate: moment().add(-1, 'month').format(),
			endDate: moment().format()
		};
		this.filtersService.resolveDateRange(this.project, dateRangeData).then((resolved) => {
			this.dateRange = resolved;
		});
	}

	updateEvents(tgtCase: CaseVisualization, events: CaseEvent[]): void {
		tgtCase.events = events;
		this.onUpdate();
	}

	addCase(): void {
		if (!this.isCaseLimitReached()) {
			this.config.selectedCases.push(new CaseVisualization(
				{
					color: this.applicationThemeService.getActionColor(),
					date: {}
				} as CaseVisualization));
			this.onUpdate();
		} else {
			this.limitWarningVisible = true;
		}
	}

	setCase(selectedCase: CaseVisualization, caseOption: EngagorCase): void {
		if (!caseOption) return;
		selectedCase.id = caseOption.id;
		selectedCase.topicId = caseOption.topic.id;
		selectedCase.sourceId = caseOption.source.id;
		selectedCase.title = caseOption.message.title;
		selectedCase.displayName = caseOption.message.title;
		selectedCase.date.from = caseOption.date.added * 1000;
		let closeAction = EngagorCaseUtils.getCloseAction(caseOption);
		if (closeAction) {
			selectedCase.date.to = closeAction.date.added * 1000;
		} else {
			selectedCase.date.to = null; // NOT undefined
		}
	}

	removeCase(index: number): void {
		this.config.selectedCases.removeAt(index);
		this.onUpdate();
	}

	isCaseLimitReached(): boolean {
		return this.config.selectedCases.length >= this.CASE_LIMIT;
	}

	showCaseLimitWarning(): boolean {
		return this.limitWarningVisible && this.isCaseLimitReached();
	}

	loadTopics(): void {
		this.loadingPromise = this.engagorApiService.getTopics().then(topics => this.topics = topics);
	}

	getCaseTitle(): (selectedCase: CaseVisualization | EngagorCase) => string {
		return (selectedCase: CaseVisualization | EngagorCase): string => {
			if (selectedCase['source']) {
				return this.getCaseOptionTitle(selectedCase as EngagorCase);
			}
			selectedCase =  selectedCase as CaseVisualization;
			if (!selectedCase.title) {
				return '';
			}
			return `#${selectedCase.sourceId}: ${selectedCase.title}`;
		};
	}

	getCaseOptionTitle(caseOption: EngagorCase): string {
		return `#${caseOption.source.id}: ${caseOption.message.title}`;
	}

	onFocus(e: Event): void {
		e.stopPropagation();
		setTimeout(() => {
			const inputEvent: Event = new Event('input');
			e.target.dispatchEvent(inputEvent);
		}, 0);
	}

	loadEngagorCases = (text$: Observable<string>): Observable<EngagorCase[]> =>
		text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(query =>
				PromiseUtils.wrap(this.engagorApiService.getCasesByQuery(this.topics, this.dateRange, query)).then((cases) => {
					return this.filterBySelectedCases(cases);
				})
			)
		);

	private filterBySelectedCases(cases: EngagorCase[]): EngagorCase[] {
		return _.filter(cases, (caseOption: EngagorCase) =>
			!_.findWhere(this.config.selectedCases, { id: caseOption.id }));
	}

	private getEventDisplayName(key: keyof typeof CaseEvent): string {
		return this.locale.getString(`cases.event_${key as string}`);
	}

	onUpdate(): void {
		this.onChangeCallback(this.config);
	}
}

app.directive('caseVizSettings', downgradeComponent({component: CaseVizSettingsComponent}));
