import { Component, OnInit, ChangeDetectionStrategy, Inject, Input, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { Security } from '@cxstudio/auth/security-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { IDateRange } from '@cxstudio/reports/entities/date-period';
import { ExportApiService } from '@cxstudio/services/data-services/export-api-service.service';
import * as moment from 'moment';
import { LogQueryOptionsInput } from './entities/log-query-options';
import SecurityAuditlog from './entities/security-auditlog';
import { SecurityAuditExportComponent } from './security-audit-export/security-audit-export.component';
import SecurityAuditSort from './entities/security-audit-sort';
import ExportServiceResponse from './entities/ExportServiceResponse';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { SecurityAuditDetailsComponent, SecurityAuditDetailsInput } from './security-audit-details/security-audit-details.component';

@Component({
	selector: 'security-audit',
	templateUrl: './security-audit.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SecurityAuditComponent implements OnInit {
	@Input() isTabActive: (id: string) => boolean;

	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.SECURITY_AUDIT;
	gridNameField = 'performerTimestamp';
	searchFilterBy: string;
	logQueryBy: string;
	searchFilter: string;
	currentFilter: string;
	currentFilterBy: string;
	logFilter: string;
	sortField: string;
	sortDir: string;
	loading: ng.IPromise<any>;
	logQuery: any;
	logEntries: SecurityAuditlog[];
	dateRangeQuery: IDateRange;
	currentMasterAccountName: string;
	pagination: Pagination;
	changedItems: any[];
	logQueryOptions: LogQueryOptionsInput;

	searchOptions: Array<{ value: string; name: string }> =
		[
			{
				value: '$',
				name: this.locale.getString('mAccount.searchEverything')
			},
			{
				value: 'userName',
				name: this.locale.getString('mAccount.searchSubject')
			},
			{
				value: 'actionDate',
				name: this.locale.getString('mAccount.searchDate')
			},
			{
				value: 'objectName',
				name: this.locale.getString('mAccount.searchObject')
			},
			{
				value: 'action',
				name: this.locale.getString('mAccount.searchAction')
			},
			{
				value: 'apiCall',
				name: this.locale.getString('mAccount.searchApiCall')
			},
			{
				value: 'details',
				name: this.locale.getString('mAccount.details')
			}
		];


	constructor(
		@Inject('exportApiService') private exportApiService: ExportApiService,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private ref: ChangeDetectorRef,
		@Inject('security') private security: Security,
		private gridUtils: GridUtilsService
	) {
	}

	ngOnInit(): void {
		this.pagination = new Pagination(25);
		this.logQueryOptions = new LogQueryOptionsInput();
		this.gridOptions = {
			onSort: this.onSortChangeEvent,
			disableInitialSort: true,
			onClick: (event, row) => this.onItemClick(event, row)
		};

		this.searchFilterBy = this.logQueryBy = '$';
		this.searchFilter = this.logFilter = '';
		this.logQuery = {};

		this.sortField = 'id';
		this.sortDir = 'DESC';

		this.dateRangeQuery = {
			from: moment().startOf('day').format(),
			to: moment().endOf('day').format()
		};
		this.currentMasterAccountName = this.security.getCurrentMasterAccount().accountName;
		this.reloadGrid();
	}

	private onItemClick(event, object: any): void {
		if (this.gridUtils.isElementClick(event, 'auditRecordDetails')) {
			this.displayDetailsPanel(object);
		}
	}

	private displayDetailsPanel = (auditLogItem: any): void => {
		if (_.isUndefined(auditLogItem.details)) {
			return;
		}
		let input: SecurityAuditDetailsInput = { details: auditLogItem.details, eventName: auditLogItem.action };
		let options: ModalOptions = { size: ModalSize.MEDIUM };

		this.cxDialogService.openDialog(SecurityAuditDetailsComponent, input, options);
	};


	private onSortChangeEvent = (event: Event, object: SecurityAuditSort): void => {
		this.sortField = object.sortField;
		this.sortDir = object.sortDir;
		this.reloadGrid();
		this.ref.markForCheck();
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reloadGrid();
	};

	createQueryParameters = (isPaginated) => {
		let params: any = {};
		params.isPaginated = isPaginated;
		params.currentPage = this.pagination.currentPage;
		if (this.isDateFilter()) {
			params.startDate = this.dateRangeQuery.from;
			params.endDate = this.dateRangeQuery.to;
		} else {
			params.filterText = this.currentFilter;
		}
		params.filterField = this.currentFilterBy;
		params.sortField = this.sortField;
		params.sortDirection = this.sortDir;
		return { params };
	};

	reloadGrid = (): ng.IPromise<any> => {
		let queryParameters = this.createQueryParameters(true);
		this.loading = this.exportApiService.getLogs(queryParameters).then(resp => {
			let result = resp.data as unknown as ExportServiceResponse;
			this.pagination.totalItems = result.totalCount;
			this.logEntries = result.data;
			this.refreshGrid();
			this.ref.markForCheck();
		});
		return this.loading;
	};

	private refreshGrid(): void {
		this.changedItems = this.logEntries;
		this.ref.markForCheck();
	}

	onLogQueryByChange = () => {
		this.searchFilterBy = this.logQueryBy;
		this.searchFilter = this.logFilter = '';
	};

	filter = () => {
		this.currentFilter = this.searchFilter;
		this.currentFilterBy = this.searchFilterBy;
		this.reloadGrid();
	};

	isDateFilter = (): boolean => {
		return this.searchFilterBy === 'actionDate';
	};

	showExportDialog = (): Promise<any> => {
		this.logQueryOptions.query = this.logQuery;
		this.logQueryOptions.logQueryBy = this.logQueryBy;
		this.logQueryOptions.dateRange = this.dateRangeQuery;
		return this.cxDialogService.openDialog(SecurityAuditExportComponent, this.logQueryOptions, { class: 'br-export-dialog' }).result;
	};
}

app.directive('securityAudit', downgradeComponent({ component: SecurityAuditComponent }));
