import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { RowNode } from 'ag-grid-enterprise';
import { BulkUpdateLabelsModalComponent, IBulkUpdateLabelsParams } from '../../bulk-update-labels-modal.component';
import { DashboardListService } from '../../dashboard-list.service';

@Injectable({
	providedIn: 'root'
})
export class AgGridDashboardBulkActionsService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		private readonly toastService: ToastService,
		private readonly cxDialogService: CxDialogService,
		@Inject('dashboardApiService') private readonly dashboardApiService: DashboardApiService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
	) {}

	share(selectedObjects: Dashboard[], selectedNodes: RowNode[]): void {
		this.dashboardService.shareDashboardsBulk(selectedObjects).then((changedDashboards) => {
			this.updateGrid(changedDashboards, selectedNodes);
		});
	}

	editLabels(selectedObjects: Dashboard[], selectedNodes: RowNode[]): void {
		let ids = _.pluck(selectedObjects, 'id');
		let bulkUpdateLabelsParams: IBulkUpdateLabelsParams = { itemsName: this.locale.getString('dashboard.dashboardsAndBooks') };
		this.cxDialogService.openDialog(BulkUpdateLabelsModalComponent, bulkUpdateLabelsParams).result.then(labels => {
			let updateLabelsEntity = new BulkUpdateLabelsEntity(labels, ids);
			this.dashboardApiService.updateLabels(updateLabelsEntity).then(updateLabelsPromise => {
				const updatedObjects = updateLabelsPromise.data;
				const msg = ' ' + updatedObjects.length + ' ' + this.locale.getString('dashboard.dashboards');
				this.toastService.addToast(`${this.locale.getString('filter.updatedLabel')} ${msg}.`, AlertLevel.CONFIRM);
				this.updateGrid(updatedObjects, selectedNodes);
			}).catch(() => _.noop());
		}).catch(() => _.noop());
	}

	remove(selectedObjects: Dashboard[]): void {
		this.dashboardService.removeDashboardsBulk(selectedObjects)
			.then(() => this.dashboardListService.removeDashboards(selectedObjects));
	}

	private updateGrid(changedDashboards: Dashboard[], selectedNodes: RowNode[]): void {
		_.each(changedDashboards, changedDashboard => {
			let node = _.find(selectedNodes, selectedNode => {
				return selectedNode.data.id === changedDashboard.id;
			});
			node.setData(changedDashboard);
		});
	}

	moveTo(selectedObjects: Dashboard[], folder: IFolderItem): void {
		let movePromises: Promise<Dashboard>[] = [];

		_.map(selectedObjects, selectedObject => {
			movePromises.push(PromiseUtils.wrap(this.dashboardService.moveDashboard(selectedObject, folder)));
		});

		this.dashboardListService.setLoading(Promise.all(movePromises))
			.then(() => this.dashboardListService.updateDashboards([folder as ITreeItem].concat(selectedObjects)));
	}
}
