import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { LocalEventBus } from '@app/core/local-event-bus.service';
import { WidgetUpdateAction } from '@app/modules/dashboard-actions/undo/dashboard-change-actions/widget-update-action';
import { SimpleWidgetBaseComponent } from '@app/modules/widget-container/simple-widgets/simple-widget-base';
import { BulkWidgetAction } from '@app/modules/widget-container/widget-menu/bulk-widget-menu/bulk-widget-menu.component';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { ContentWidgetProperties } from '@cxstudio/reports/entities/content-widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { ReportDataPostprocessor } from '@cxstudio/reports/report-data-postprocessor';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import * as cloneDeep from 'lodash.clonedeep';

@Component({
	selector: 'content-widget',
	templateUrl: './content-widget.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LocalEventBus],
})
export class ContentWidgetComponent extends SimpleWidgetBaseComponent implements OnInit {

	@Input() widget: ContentWidget;
	@Input() widgetActions: IWidgetActions;
	@Input() dashboardData: IDashboardData;
	@Input() layout: LayoutHelper;
	@Output() bulkAction = new EventEmitter<BulkWidgetAction>();

	utils: WidgetUtils;
	processedProperties: ContentWidgetProperties;
	trigger: boolean;

	WidgetAction = WidgetAction;

	constructor(
		private readonly ref: ChangeDetectorRef,
		readonly localEventBus: LocalEventBus,
		private readonly locale: CxLocaleService,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		@Inject('reportDataPostprocessor') private readonly reportDataPostprocessor: ReportDataPostprocessor,
		private readonly currentObjects: CurrentObjectsService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
	) {
		super(localEventBus);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.addSubscription(this.currentObjects.getEditModeChange().subscribe(() => this.ref.markForCheck()));
	}

	refresh(): void {
		this.redrawWidget();
	}

	toggleIgnoreImageRatio(): void {
		this.widget.properties.ignoreRatio = !this.widget.properties.ignoreRatio;
		this.redrawWidget();
	}

	isMobile = (): boolean => {
		return this.$rootScope.isMobile;
	};

	getCaptionText(): string {
		let caption;
		let widget = this.widget;
		let props = widget.properties;
		if (props.widgetType === WidgetType.IMAGE) {
			caption = widget.description || props.altText || this.locale.getString('widget.imageCaption');
		} else if (props.widgetType === WidgetType.TEXT) {
			caption = this.toPlainText(props.text) || this.locale.getString('widget.textCaption');
		}
		return caption;
	}

	private toPlainText(html: string): string {
		if (!html) {
			return '';
		}
		let element = $(document.createElement('div')).append(html);
		element.find('*').each((i, el) => el.append(' '));
		return element.text().replace(/\s\s+/g, ' ').trim();
	}

	private getDashboardHistory(): IDashboardHistoryInstance {
		return this.dashboardData?.dashboardHistory;
	}

	protected redrawWidget(): void {
		this.utils = {
			widgetId: this.widget.id,
			widgetType: this.widget.properties.widgetType
		} as WidgetUtils;
		this.processedProperties = cloneDeep(this.widget.properties);
		if (this.widget.properties.widgetType === WidgetType.TEXT) {
			let personalization = this.getDashboardHistory().getPersonalization();
			if (personalization) {
				this.processedProperties = this.reportDataPostprocessor.applyHierarchyEnrichments(
					this.processedProperties, personalization);
			}
		}
		this.trigger = !this.trigger;
		this.ref.markForCheck();
	}

	isEditMode(): boolean {
		return this.currentObjects.isEditMode();
	}

	editAltText(): void {
		let dialogData = {
			header: this.locale.getString('common.altText'),
			template: 'partials/widgets/settings/content/alt-text-dialog.html',
			okBtnName: this.locale.getString('common.save'),
			cancelBtnName: this.locale.getString('common.cancel'),
			altTextTitle: this.widget.properties.altText,
			altTextDescription: this.widget.description
		};
		// TODO convert to ng
		this.cbDialogService.showDialog(dialogData).result.then((result) => {
			let changeAction = WidgetUpdateAction.wrapChange(() => this.widget, () => {
				this.widget.properties.altText = result.altTextTitle;
				this.widget.description = result.altTextDescription;
			});
			this.widgetActions.emit(WidgetAction.UPDATE, changeAction);
			this.redrawWidget();
		});
	}
}

app.directive('contentWidget', downgradeComponent({component: ContentWidgetComponent}));
