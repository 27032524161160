import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { QuickInsightsComponent } from '@app/modules/home-page/quick-insights/quick-insights/quick-insights.component';
import { HighlightMatchPipe } from '@app/shared/pipes/highlight-match.pipe';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';

@Injectable()
export class QuickInsightsDashboardGridDefinition implements IGridDefinition<QuickInsightsComponent> {

	constructor(
		private locale: CxLocaleService,
		private highlightMatch: HighlightMatchPipe,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter,
		@Inject('commonGridColumns') private commonGridColumns: CommonGridColumns,
		private gridUtils: GridUtilsService,
	) {}

	init = (gridMode: GridMode, component: QuickInsightsComponent): Promise<IGridColumn[]> => {
		let columns = [
			this.commonGridColumns.getLabelsColumn(this.locale.getString('object.dashboardOrBook')),
			{
				id: 'name',
				name: this.locale.getString('common.name'),
				field: 'name',
				minWidth: 240,
				width: 660,
				headerCssClass: 'cell-dashboard-title',
				cssClass: 'cell-title',
				formatter: (row, cell, value, columnDef, dataContext) => {
					value = this.gridUtils.replaceHTML(value);
					let highlightedName = this.highlightMatch.transform(value, component.currentSearch.displayName);
					return this.gridFormatterService.getName(highlightedName, dataContext, GridTypes.QUICK_INSIGHTS_DASHBOARD);
				},
				isObjectNameColumn: true
			},
			{
				id: 'created',
				name: this.locale.getString('dashboard.modifiedDate'),
				field: 'modifiedDate',
				minWidth: 100,
				width: 240,
				cssClass: 'cell-date',
				formatter: this.gridFormatterService.DateFormatter
			},
			{
				id: 'ownerName',
				name: this.locale.getString('common.owner'),
				field: 'ownerName',
				minWidth: 80,
				width: 260,
				cssClass: 'cell-owner-name',
				formatter: this.gridFormatterService.getAuthorFormatter(gridMode)
			},
			{
				id: 'sharingStatus',
				name: this.locale.getString('dashboard.status'),
				field: 'sharingStatus',
				minWidth: 60,
				width: 100,
				headerCssClass: 'text-center',
				formatter: this.gridFormatterService.StatusFormatter,
				cssClass: 'cell-status text-center'
			}
		];

		return Promise.resolve(columns);
	};

}

app.service('quickInsightsDashboardGridDefinition', downgradeInjectable(QuickInsightsDashboardGridDefinition));
