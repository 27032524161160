import { Injectable } from '@angular/core';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ColumnConditionOption } from '@app/shared/components/filter/filter-condition-option';
import { CxLocaleService } from '@app/core';
import { FilterCondition } from '@app/shared/components/filter/filter-condition';

@Injectable({providedIn: 'root'})
export class FilterConditionsService {

	private conditions: ColumnConditionOption[];

	constructor(
		private locale: CxLocaleService
	) {
		this.conditions = [{
			name: this.locale.getString('common.contains'),
			value: FilterCondition.CONTAINS,
			columnType: ColumnFilterType.TEXT
		}, {
			name: this.locale.getString('common.is'),
			value: FilterCondition.IS,
			columnType: ColumnFilterType.LIST
		}, {
			name: this.locale.getString('common.is'),
			value: FilterCondition.IS,
			columnType: ColumnFilterType.FLAG
		}, {
			name: this.locale.getString('common.isNot'),
			value: FilterCondition.IS_NOT,
			columnType: ColumnFilterType.LIST
		}, {
			name: this.locale.getString('common.after'),
			value: FilterCondition.AFTER,
			columnType: ColumnFilterType.DATE
		}, {
			name: this.locale.getString('common.before'),
			value: FilterCondition.BEFORE,
			columnType: ColumnFilterType.DATE
		}, {
			name: this.locale.getString('common.between'),
			value: FilterCondition.BETWEEN,
			columnType: ColumnFilterType.DATE
		}];
	}

	getConditions = (columnType: ColumnFilterType): ColumnConditionOption[] => {
		return this.conditions.filter(condition => condition.columnType === columnType);
	};
}
