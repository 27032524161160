export class ReportDataPostprocessor {
	constructor(
		public $sanitize
	) {}

	applyHierarchyEnrichments = (dataObject: any, personalization: any): any => {
		if (!personalization) {
			return;
		}

		personalization.getEnrichments().forEach((enrichment) => {
			let parameterName = this.$sanitize(enrichment.propertyName)
				.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');

			let pattern = '{' + parameterName + '}';
			let replacement = enrichment.propertyValue;

			dataObject.text = (dataObject.text || '').replace(new RegExp(pattern, 'g'), replacement);
		});

		return dataObject;
	};
}
app.service('reportDataPostprocessor', ReportDataPostprocessor);