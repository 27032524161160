import { downgradeComponent } from '@angular/upgrade/static';
import {
	AccountSwitcherComponent,
	PushButtonsComponent,
	SortDirectionComponent,
	ToastComponent,
	HeaderComponent,
	RadioGroupComponent,
	ToggleComponent,
	InlineHelpComponent,
	ExpandingInputComponent
} from '@discover/unified-angular-components/dist/unified-angular-components';

app.directive('cbAccountSwitcher', downgradeComponent({component: AccountSwitcherComponent}));
app.directive('cbPushButtons', downgradeComponent({component: PushButtonsComponent}));
app.directive('cbSortDirection', downgradeComponent({component: SortDirectionComponent}));
app.directive('cbToast', downgradeComponent({component: ToastComponent}));
app.directive('cbHeader', downgradeComponent({component: HeaderComponent}));
app.directive('cbRadioGroup', downgradeComponent({component: RadioGroupComponent}));
app.directive('cbToggle', downgradeComponent({component: ToggleComponent}));
app.directive('cbInlineHelp', downgradeComponent({component: InlineHelpComponent}));
app.directive('cbExpandingInput', downgradeComponent({component: ExpandingInputComponent}));
