import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FilterRuleType, FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { IWidgetFilterHistory, WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { AdhocFilter, IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import * as _ from 'underscore';
import { FilterEmptyObject } from '@cxstudio/report-filters/constants/filter-empty-object.constant';
import IFilter from '@cxstudio/report-filters/entity/filter';


const COMPLEX_WIDGET_FILTER_FIELDS: string[] = ['appliedFilters', 'adhocFilter'];
const SIMPLE_WIDGET_FILTER_FIELDS: string[] = ['drillFilters', 'groupingFilters', 'ignoredDashboardFilters'];
const DATE_RANGE_FILTER_FIELDS: string[] = ['dateRangeP1', 'dateRangeP2', 'useHistoricPeriod'];
const PROJECT_FILTER_FIELDS: string[] = ['contentProviderId', 'accountId', 'project'];

export class FilterUtils {

	constructor() {}


	filterMatcher = (selectedFilters: any[]): (f: any) => boolean => {
		return (filter: any): boolean => {
			switch (filter.type) {
			case FilterTypes.CXANALYZE: return !!_.findWhere(selectedFilters, {filterId: filter.filterId});
			case FilterTypes.CXSTUDIO: return !!_.findWhere(selectedFilters, {id: filter.id});
			}
		};
	};

	// helper methods to boost performance for attribute statistics requests
	hasAdditionalWidgetFilters = (oldHistory: IWidgetFilterHistory, newHistory: IWidgetFilterHistory): boolean => {
		if (!oldHistory || !newHistory) {
			return false;
		}
		return newHistory.appliedFilters.filters.length > oldHistory.appliedFilters.filters.length
			|| newHistory.adhocFilter.filterRules.length > oldHistory.adhocFilter.filterRules.length;
	};

	isWidgetFiltersChanged = (oldHistory: IWidgetFilterHistory, newHistory: IWidgetFilterHistory): boolean => {
		if (!oldHistory || !newHistory) {
			return true;
		}
		let oldProject = _.pick(oldHistory, PROJECT_FILTER_FIELDS);
		let newProject = _.pick(newHistory, PROJECT_FILTER_FIELDS);
		let oldDateRange = _.pick(oldHistory, DATE_RANGE_FILTER_FIELDS);
		let newDateRange = _.pick(newHistory, DATE_RANGE_FILTER_FIELDS);
		let oldSimple = _.pick(oldHistory, SIMPLE_WIDGET_FILTER_FIELDS);
		let newSimple = _.pick(newHistory, SIMPLE_WIDGET_FILTER_FIELDS);
		if (!_.isEqual(oldProject, newProject) || !_.isEqual(oldDateRange, newDateRange) || !_.isEqual(oldSimple, newSimple)) {
			return true;
		}
		let oldComplex = _.pick(oldHistory, COMPLEX_WIDGET_FILTER_FIELDS);
		let newComplex = _.pick(newHistory, COMPLEX_WIDGET_FILTER_FIELDS);
		if (this.isComplexFiltersScopeNarrowed(oldComplex.appliedFilters.filters, newComplex.appliedFilters.filters, false)) {
			return true;
		}
		if (this.isComplexFiltersScopeNarrowed(oldComplex.adhocFilter.filterRules, newComplex.adhocFilter.filterRules, true)) {
			return true;
		}
		return false;
	};

	getWidgetFilterFields = (): string[] => {
		return COMPLEX_WIDGET_FILTER_FIELDS.concat(SIMPLE_WIDGET_FILTER_FIELDS, DATE_RANGE_FILTER_FIELDS, PROJECT_FILTER_FIELDS);
	};

	isComplexFiltersScopeNarrowed = (oldFilters: any[], newFilters: any[], isAdhoc: boolean): boolean => {
		if (newFilters.length < oldFilters.length) {
			return true;
		}
		return _.some(oldFilters, (oldFilter) => {
			return !_.find(newFilters, (newFilter) => {
				return isAdhoc
					? this.isAdhocFilterEqual(oldFilter, newFilter)
					: this.isAppliedFilterEqual(oldFilter, newFilter);
			});
		});
	};

	isAppliedFilterEqual = (oldFilter: any, newFilter: any): boolean => {
		if (newFilter.type !== oldFilter.type) {
			return false;
		}
		if (oldFilter.type === FilterTypes.PREDEFINED) {
			return newFilter.name === oldFilter.name;
		} else {
			return newFilter.filterId === oldFilter.filterId;
		}
	};

	isAdhocFilterEqual = (oldFilterRule: IFilterRule, newFilterRule: IFilterRule): boolean => {
		if (newFilterRule.type !== oldFilterRule.type) {
			return false;
		}
		switch (oldFilterRule.type) {
			case FilterRuleType.topicEquality:
				return newFilterRule.topicId === oldFilterRule.topicId
					&& this.isDefinitionEqual(oldFilterRule, newFilterRule);
			case FilterRuleType.numericRange:
			case FilterRuleType.numericOpenRange:
			case FilterRuleType.numericEquality:
			case FilterRuleType.stringEquality:
			case FilterRuleType.stringEqualityLC:
				return newFilterRule.attributeName === oldFilterRule.attributeName
					&& this.isDefinitionEqual(oldFilterRule, newFilterRule);
			case FilterRuleType.esQueryRule:
				return _.isEqual(newFilterRule.esQueryObject, oldFilterRule.esQueryObject);
			case FilterRuleType.text:
				return true;
			default:
				return false;
		}
	};

	isDefinitionEqual = (oldFilterRule: any, newFilterRule: any): boolean => {
		if (FilterRuleTypes.isTopicRule(oldFilterRule)) {
			if (!_.isUndefined(oldFilterRule.modelMatch)) {
				return newFilterRule.modelMatch === oldFilterRule.modelMatch;
			} else {
				return newFilterRule.matchMode === oldFilterRule.matchMode
					&& _.isEqual(newFilterRule.values, oldFilterRule.values);
			}
		} else {
			if (!_.isUndefined(oldFilterRule.existMatch)) {
				return newFilterRule.existMatch === oldFilterRule.existMatch;
			} else {
				let isValueEqual = false;
				if (!_.isUndefined(oldFilterRule.value))
					isValueEqual = _.isEqual(newFilterRule.value, oldFilterRule.value);
				if (!_.isUndefined(oldFilterRule.values))
					isValueEqual = _.isEqual(newFilterRule.values, oldFilterRule.values);
				if (!_.isUndefined(oldFilterRule.from) && !_.isUndefined(oldFilterRule.to))
					isValueEqual = _.isEqual(newFilterRule.from, oldFilterRule.from)
						&& _.isEqual(newFilterRule.to, oldFilterRule.to);
				return newFilterRule.matchMode === oldFilterRule.matchMode && isValueEqual;
			}
		}
	};

	isComplexFiltersApplied = (props: WidgetProperties): boolean => {
		return (props.appliedFilters.filters.length > 0
			&& _.some(props.appliedFilters.filters || [], filter => filter.type !== FilterEmptyObject.FILTER.type))
			|| (props.adhocFilter.filterRules.length > 0
			&& _.some(props.adhocFilter.filterRules || [], rule => rule.type !== FilterEmptyObject.RULE.type));
	};

	filtersEqual = (filter1: IFilter, filter2: IFilter) => {
		if (_.isUndefined(filter1) || _.isUndefined(filter2)) {
			return _.isUndefined(filter1) && _.isUndefined(filter2);
		}
		let idFunction = (filter) => {
			switch (filter.type) {
			case FilterTypes.CXANALYZE: return filter.filterId;
			case FilterTypes.CXSTUDIO: return filter.id;
			case FilterTypes.PREDEFINED: return filter.value;
			}
			return filter;
		};
		return filter1.type === filter2.type && idFunction(filter1) === idFunction(filter2);
	};

}

app.service('filterUtils', FilterUtils);
