import * as _ from 'underscore';

export class MobileDashboardListComponent implements ng.IComponentController {

	isCurrentUserSelected;

	constructor(private dateService, private security, private locale, private dashboardService) {
	}

	$onInit = () => {
		// required for typescript validation
	};

	formatDate = (value): string => {
		if (!value)
			return;
		let date = new Date(value);

		return this.dateService.format(date);
	};

	formatAuthorName = (value): string => {
		if (this.isCurrentUserSelected() && this.security.isCurrentUser(value)) {
			return this.locale.getString('dashboard.me');
		}
		return value;
	};
}

app.component('mobileDashboardList', {
	bindings: {
		dashboards: '<',
		changeDashboard: '<',
		isCurrentUserSelected: '<',
		dashboardBurgerClick: '<',
		showDashboardRating: '<',
		search: '<',
		changeFavoriteForDashboard: '<'
	},
	controller: MobileDashboardListComponent,
	templateUrl: 'partials/dashboards/mobile-dashboard-list.component.html'
});
