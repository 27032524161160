import { Injectable } from '@angular/core';
import { ScorecardTopicInDocView } from '@cxstudio/projects/scorecards/entities/scorecard-in-doc-view';
import { ScorecardTopicResult } from '@cxstudio/projects/scorecards/entities/scorecard-topic-result.enum';

export enum ScorecardSortCategory {
	CRITERIA = 'criteria',
	RESULT = 'result'
}

export enum ScorecardCriteriaSortOrder {
	CRITERIA_NAME_ASCENDING = 'criteria_name_ascending',
	CRITERIA_NAME_DESCENDING = 'criteria_name_descending',
	CRITERIA_WEIGHT_ASCENDING = 'criteria_weight_ascending',
	CRITERIA_WEIGHT_DESCENDING = 'criteria_weight_descending',
	RESULT_FAILED = 'result_failed',
	RESULT_PASSED = 'result_passed',
	RESULT_REBUTTALS = 'result_rebuttals',
	NONE = 'none'
}

export enum ScorecardCriteriaFilter {
	CRITERIA_AUTO_FAILED = 'criteria_auto_failed',
	CRITERIA_WEIGHTED = 'criteria_weighted',
	RESULT_FAILED = 'result_failed',
	RESULT_PASSED = 'result_passed',
	RESULT_REBUTTALS = 'result_rebuttals',
	RESULT_IGNORED = 'result_ignored',
	RESULT_CONSIDERED = 'result_considered',
	NONE = 'none'
}

@Injectable()
export class ScorecardCriteriaSorting {

	readonly CRITERIA_NAME;

	private sortAlgorithms: {[key: string]: (scorecardTopics: ScorecardTopicInDocView[]) => ScorecardTopicInDocView[]} = {};
	private filterAlgorithms: {[key: string]: (scorecardTopic: ScorecardTopicInDocView) => boolean} = {};

	constructor() {
		this.initSortAlgorithms();
		this.initFilterAlgorithms();
	}

	private initSortAlgorithms = (): void => {
		this.sortAlgorithms[ScorecardCriteriaSortOrder.NONE] = (scorecardTopics) => scorecardTopics;
		this.sortAlgorithms[ScorecardCriteriaSortOrder.CRITERIA_NAME_ASCENDING] = this.caseInsensitiveNameSort;
		this.sortAlgorithms[ScorecardCriteriaSortOrder.CRITERIA_NAME_DESCENDING] =
			(scorecardTopics) => this.caseInsensitiveNameSort(scorecardTopics).reverse();
		this.sortAlgorithms[ScorecardCriteriaSortOrder.CRITERIA_WEIGHT_ASCENDING] = this.weightSort;
		this.sortAlgorithms[ScorecardCriteriaSortOrder.CRITERIA_WEIGHT_DESCENDING] =
			(scorecardTopics) => this.weightSort(scorecardTopics, true);

		this.sortAlgorithms[ScorecardCriteriaSortOrder.RESULT_FAILED] = (scorecardTopics) => {
			let preferredOrder = [ScorecardTopicResult.AUTO_FAIL, ScorecardTopicResult.FAILED,
				ScorecardTopicResult.PASSED, ScorecardTopicResult.NOT_APPLICABLE];
			return scorecardTopics.sort((scorecard1, scorecard2) =>
				preferredOrder.indexOf(scorecard1.result) - preferredOrder.indexOf(scorecard2.result));
		};

		this.sortAlgorithms[ScorecardCriteriaSortOrder.RESULT_PASSED] = (scorecardTopics) => {
			let preferredOrder = [ScorecardTopicResult.PASSED, ScorecardTopicResult.FAILED,
				ScorecardTopicResult.AUTO_FAIL, ScorecardTopicResult.NOT_APPLICABLE];
			return scorecardTopics.sort((scorecard1, scorecard2) =>
				preferredOrder.indexOf(scorecard1.result) - preferredOrder.indexOf(scorecard2.result));
		};

		this.sortAlgorithms[ScorecardCriteriaSortOrder.RESULT_REBUTTALS] = (scorecardTopics) => {
			let rebutted: ScorecardTopicInDocView[] = _.chain(scorecardTopics).filter(scorecard => scorecard.rebutted).value();
			rebutted = this.caseInsensitiveNameSort(rebutted);
			let notRebutted: ScorecardTopicInDocView[] = _.chain(scorecardTopics).filter(scorecard => !scorecard.rebutted).value();
			notRebutted = this.caseInsensitiveNameSort(notRebutted);

			return [...rebutted, ...notRebutted];
		};
	};

	private initFilterAlgorithms = (): void => {
		this.filterAlgorithms[ScorecardCriteriaFilter.NONE] = (topic) => true;
		this.filterAlgorithms[ScorecardCriteriaFilter.CRITERIA_AUTO_FAILED] = (topic) => topic.autoFail;
		this.filterAlgorithms[ScorecardCriteriaFilter.CRITERIA_WEIGHTED] = (topic) => !topic.autoFail;

		this.filterAlgorithms[ScorecardCriteriaFilter.RESULT_FAILED]
			= (topic) => topic.result === ScorecardTopicResult.AUTO_FAIL || topic.result === ScorecardTopicResult.FAILED;

		this.filterAlgorithms[ScorecardCriteriaFilter.RESULT_PASSED]
			= (topic) => topic.result === ScorecardTopicResult.PASSED;

		this.filterAlgorithms[ScorecardCriteriaFilter.RESULT_REBUTTALS]
			= (topic) => topic.rebutted;

		this.filterAlgorithms[ScorecardCriteriaFilter.RESULT_IGNORED]
			= (topic) => topic.result === ScorecardTopicResult.NOT_APPLICABLE;

		this.filterAlgorithms[ScorecardCriteriaFilter.RESULT_CONSIDERED]
			= (topic) => topic.result !== ScorecardTopicResult.NOT_APPLICABLE;
	};

	private weightSort = (scorecardTopics: ScorecardTopicInDocView[], decending = false): ScorecardTopicInDocView[] => {
		// autofails come first if they exist
		let autoFails = this.caseInsensitiveNameSort(_.filter(scorecardTopics, topic => topic.result === ScorecardTopicResult.AUTO_FAIL));
		let others = _.filter(scorecardTopics, topic => topic.result !== ScorecardTopicResult.AUTO_FAIL);
		let multiplier = decending ? -1 : 1;

		others = others.sort((a, b) => {
			// if weight is the same, sort them alphabetically
			if (a.weight === b.weight)
				return a.name.localeCompare(b.name, undefined, { sensitivity: 'base'});

			return (a.weight - b.weight) * multiplier;
		});
		return [...autoFails, ...others];
	};

	private caseInsensitiveNameSort(scorecardTopics: ScorecardTopicInDocView[]): ScorecardTopicInDocView[] {
		return scorecardTopics.sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: 'base'}));
	}

	sortAndFilter(scorecardTopics: ScorecardTopicInDocView[], order = ScorecardCriteriaSortOrder.NONE,
			filter = ScorecardCriteriaFilter.NONE): ScorecardTopicInDocView[] {
		let topics = _.filter(scorecardTopics, this.filterAlgorithms[filter]);
		return this.sortAlgorithms[order](topics);
	}
}
