import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { MetricManagementController } from '@cxstudio/metrics/metric-management.component';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IRowFormatter, GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

export default class MetricGridDefinition implements IGridDefinition<MetricManagementController> {

	constructor(
		private $rootScope: ng.IRootScopeService,
		private $q: ng.IQService,
		private $compile: ng.ICompileService,
		private locale: ILocale,
		private gridFormatterService: GridFormatter,
		private contextMenuTree,
		private commonGridColumns: CommonGridColumns
	) {
	}

	init = (gridMode: GridMode, controller: MetricManagementController): angular.IPromise<IGridColumn[]> => {
		let columns: IGridColumn[] = [
		{
			id: 'checkbox',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-checkbox text-center',
			name: '<span></span>',
			cssClass: 'cell-checkbox text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.SelectedRowFormatter,
			resizable: false
			// no name property, as we'll add the checkbox in the html
		},  {
			id: 'hamburger',
			field: 'name',
			sortable: false,
			minWidth: 40,
			width: 40,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger no-border-if-folder',
			formatter: this.metricHamburgerFormatter,
			onClick: (event, object) => {
				let showHide = {
					name: 'hideToggle',
					text: controller.ui.hideMetrics ? this.locale.getString('dashboard.showAllHidden') : this.locale.getString('dashboard.hideAllHidden'),
					func: () => { controller.ui.hideMetrics = !controller.ui.hideMetrics; },
					classes: [controller.ui.hideMetrics ? 'items-hidden' : 'items-visible']
				};

				this.contextMenuTree.show(event, object, [showHide], 'header-menu', 360);
			}
		},
		this.commonGridColumns.getLabelsColumn(this.locale.getString('object.metric')),
		{
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			defaultSortColumn: SortDirection.ASC,
			field: 'displayName',
			sortable: true,
			minWidth: 280,
			width: 450,
			headerCssClass: 'cell-metric-title',
			cssClass: 'cell-title',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.METRIC),
			isObjectNameColumn: true
		}, {
			id: 'created',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			formatter: this.gridFormatterService.DateFormatter
		},
		{
			id: 'description',
			name: this.locale.getString('common.description'),
			field: 'description',
			sortable: true,
			optional: true,
			minWidth: 280,
			width: 380,
			formatter: this.gridFormatterService.TextFormatter
		},
		{
			id: 'ownerName',
			name: this.locale.getString('common.owner'),
			field: 'ownerName',
			sortable: true,
			optional: true,
			minWidth: 80,
			width: 230,
			formatter: this.gridFormatterService.getAuthorFormatter()
		}, {
			id: 'calculation',
			name: this.locale.getString('common.type'),
			field: 'typeDisplayName',
			sortable: true,
			optional: true,
			minWidth: 180,
			width: 180,
			searchable: true,
			cssClass: 'cell-calculation'
		}, {
			id: 'definition',
			name: this.locale.getString('metrics.definition'),
			field: 'definition',
			minWidth: 250,
			width: 250,
			cssClass: 'cell-definition',
			sortable: false,
			optional: true,
			formatter: (row, cell, value, columnDef, dataContext) => {
				if (!value || value.filterType
					|| dataContext.definition.type === MetricType.CUSTOM_MATH
					|| dataContext.definition.type === MetricType.FILTER
					|| dataContext.definition.type === MetricType.VARIABLE
					|| dataContext.type === GridContext.METRIC_PREDEFINED) {
					return;
				}

				let scope = this.$rootScope.$new();
				angular.extend(scope, value);
				let element = '<metric-spectrum top-boundary=\'topThreshold\' ' +
							'bottom-boundary=\'bottomThreshold\' ' +
							'minimum=\'min\' maximum=\'max\' ' +
							'top-color=\'topColor\' bottom-color=\'bottomColor\' ' +
							'other-color=\'otherColor\' middle-color=\'middleColor\' ' +
							'></metric-spectrum>';
				let el = this.$compile(element)(scope);
				return el[0].innerHTML;
			}
		}, {
			id: 'sharingStatus',
			name: this.locale.getString('dashboard.status'),
			field: 'sharingStatus',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 120,
			formatter: this.MetricStatusFormatter,
			cssClass: 'cell-status text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'project',
			name: this.locale.getString('metrics.project'),
			field: 'projectName',
			sortable: true,
			minWidth: 80,
			width: 200,
			formatter: (row, cell, value, columnDef, dataContext) => {
				if (dataContext.type === GridContext.METRIC_PREDEFINED) {
					return this.locale.getString('metrics.allProjects');
				}
				return value;
			}
		}];
		return this.$q.when(columns);
	};

	metricHamburgerFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext) => {
		if (this.ignoreHamburger(dataContext))
			return '';
		return this.gridFormatterService.HamburgerFormatter(row, cell, value, columnDef, dataContext);
	};

	MetricStatusFormatter: IRowFormatter = (row, cell, value, columnDef, dataContext): string => {
		if (dataContext.type === ReportAssetType.SCORECARD) {
			return this.gridFormatterService.StatusScorecardDisabledFormatter(row, cell, value, columnDef, dataContext);
		} else {
			return this.gridFormatterService.StatusFormatter(row, cell, value, columnDef, dataContext);
		}
	};

	private ignoreHamburger(metric): boolean {
		return metric.type === FolderTypes.METRIC
			&& metric.generatedFolderType === GeneratedFolderType.SYSTEM;
	}
}

app.service('metricGridDefinition', MetricGridDefinition);
