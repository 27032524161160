
import IAlertSubscriptionTemplate from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { HttpRequestConfig } from '@cxstudio/common/http-request-config';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '../projects/project-selection.interface';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { Security } from '@cxstudio/auth/security-service';

const SUBSCRIPTION_TEMPLATES_BASE_URL = 'rest/alert-subscription-templates';

export default class AlertSubscriptionTemplatesApi {

	constructor(
		private $http: ng.IHttpService,
		private httpHandlers: HttpHandlers,
		private readonly cachedHttpService: CachedHttpService,
		private security: Security,
		private $q: ng.IQService
	) {}

	createTemplate = (template: IAlertSubscriptionTemplate): angular.IPromise<IAlertSubscriptionTemplate> => {
		return this.$http.post(SUBSCRIPTION_TEMPLATES_BASE_URL, template)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SUBSCRIPTION_TEMPLATES));
	};

	updateTemplate = (template: IAlertSubscriptionTemplate, updateCases: boolean): angular.IPromise<IAlertSubscriptionTemplate> => {
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/${template.id}?updateCases=${updateCases}`;
		return this.$http.put(url, template)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SUBSCRIPTION_TEMPLATES));
	};

	getEnabledTemplatesForCurrentUser = (contentProviderId: number, accountId: number, projectId: number) => {
		const project = { contentProviderId, accountId, projectId };
		return this.getEnabledTemplates(project, null, null);
	};

	/**
	 * For viewers
	 * If documentDescriptor is provided, it uses cp+account+project+runAs from the descriptor
	 */
	getEnabledTemplates = (
		project: IProjectSelection, workspaceProject: WorkspaceProject, assetParameters: AssetParameters
	): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		let requestAssetParams = new RequestAssetParameters(assetParameters, project, workspaceProject);
		let config: HttpRequestConfig = {assetParams: requestAssetParams};
		return this.cachedHttpService.cache(Caches.SUBSCRIPTION_TEMPLATES)
			.get(SUBSCRIPTION_TEMPLATES_BASE_URL, config)
			.then(this.httpHandlers.success);
	};

	getXflowSubscriptions = (
		project: IProjectSelection, workspaceProject: WorkspaceProject, assetParameters: AssetParameters
	): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		if (!this.security.isTicketingEnabled()) {
			return this.$q.when([]);
		}
		let requestAssetParams = new RequestAssetParameters(assetParameters, project, workspaceProject);
		let config: HttpRequestConfig = {assetParams: requestAssetParams};
		return this.cachedHttpService.cache(Caches.SUBSCRIPTION_TEMPLATES)
			.get(`${SUBSCRIPTION_TEMPLATES_BASE_URL}/xflow`, config)
			.then(this.httpHandlers.success);
	};

	/**
	 * Only for management page
	 */
	getAllTemplates = (project: IProjectSelection): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		if (!ValueUtils.isSelected(project.projectId)) {
			return this.getAllTemplatesProjects(project);
		}
		// eslint-disable-next-line max-len
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}/all`;
		return this.$http.get(url).then(this.httpHandlers.success);
	};

	getAllTemplatesProjects = (project: IProjectSelection): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/cp/${project.contentProviderId}/account/${project.accountId}/all-projects`;
		return this.$http.get(url).then(this.httpHandlers.success);
	};

	getWorkspaceTemplates = (project: WorkspaceProject): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		if (!WorkspaceProjectUtils.isProjectSelected(project)) {
			return this.getWorkspaceTemplatesAllProjects(project);
		}
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/ws/${project.workspaceId}/project/${project.projectId}/all`;
		return this.$http.get(url).then(this.httpHandlers.success);
	};

	getWorkspaceTemplatesAllProjects = (project: WorkspaceProject): angular.IPromise<IAlertSubscriptionTemplate[]> => {
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/ws/${project.workspaceId}/all-projects`;
		return this.$http.get(url).then(this.httpHandlers.success);
	};

	deleteTemplate = (template: IAlertSubscriptionTemplate): angular.IPromise<IAlertSubscriptionTemplate> => {
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/${template.id}`;
		return this.$http.delete(url)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SUBSCRIPTION_TEMPLATES));
	};

	toggleEnabledFlag = (template: IAlertSubscriptionTemplate, enabledFlag: boolean): angular.IPromise<IAlertSubscriptionTemplate> => {
		const url = `${SUBSCRIPTION_TEMPLATES_BASE_URL}/${template.id}/enabled/${enabledFlag}`;
		return this.$http.put(url, template)
			.then(this.httpHandlers.success)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.SUBSCRIPTION_TEMPLATES));
	};
}

app.service('alertSubscriptionTemplatesApi', AlertSubscriptionTemplatesApi);
