import * as _ from 'underscore';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { IFolderApi } from '@cxstudio/common/folders/folder-api.interface';



export class FolderApiService implements IFolderApi<any> {
	constructor(private $http, private treeService, private httpHandlers: HttpHandlers) { }

	createFolder = (folderData): PromiseLike<any> =>
		this.$http.post(`rest/dashboard/folder`, angular.toJson(folderData)).then(this.httpHandlers.success);

	deleteFolder = (folderId: number): PromiseLike<any> =>
		this.$http.delete(`rest/dashboard/folder/${folderId}`).then(this.httpHandlers.success);

	// takes entire folder object as argument, so it can match signature of filter folder API method
	moveToFolder = (folder, targetId: number): PromiseLike<void> =>
		this.$http.post(`rest/dashboard/folder/move/${folder.id}/${targetId}`).then(this.httpHandlers.success);

	updateFolder = (folderId: number, folderData): PromiseLike<any> =>
		this.$http.put(`rest/dashboard/folder/${folderId}`, angular.toJson(this.treeService.copyItem(folderData)))
			.then(this.httpHandlers.success);
}

app.service('folderApiService', FolderApiService);
