import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { IScorecardFilter } from '@app/modules/scorecards/entities/scorecard-filter';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceScorecardFiltersManagementApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.FILTERS);
	}

	getFilters = (
		project: WorkspaceProject, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<IScorecardFilter[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project) + '/scorecard-filters';
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<IScorecardFilter[]>;
	};

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/manage/ws/${project.workspaceId}/project/${project.projectId}`;
	};
}
