import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter,
	Inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DashboardRunService } from '@app/modules/dashboard/dashboard-run.service';
import { ReportExportUtilsService } from '@app/modules/widget-container/widget-menu/export/report-export-utils.service';
import { TimeToRunFormatter } from '@app/modules/widget-container/widget-stats/time-to-run/time-to-run-formatter.service';
import { SimpleTableData } from '@app/shared/components/simple-table/simple-table.component';
import { ChangeUtils } from '@app/util/change-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticsCacheOptions } from '@cxstudio/reports/analytics-cache-options.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { SimpleTableDataBuilder } from '@cxstudio/reports/stats-mode/simple-table-data-builder';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';
import { CsvTableDecomposer } from '@cxstudio/reports/utils/csv-table-decomposer';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CurrentSentencesUtils } from './current-sentences-table/current-sentences-utils.service';
import * as cloneDeep from 'lodash.clonedeep';
import { FeedbackUtils } from '../feedback-utils.service';
import { AgeService } from '@app/modules/utils/dates/age.service';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';
import { StatsModeService } from '@app/modules/reports/utils/stats-mode.service';
import { ObjectUtils } from '@app/util/object-utils';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { SortUtils } from '@app/shared/util/sort-utils';

@Component({
	selector: 'widget-stats',
	templateUrl: './widget-stats.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetStatsComponent implements OnInit, OnChanges {

	@Input() showStats: boolean;
	@Input() showEnrichment: boolean;
	@Input() showDescription: boolean;
	@Input() widget: Widget;
	@Input() dashboard: Dashboard;
	@Input() qid: string;
	@Input() data: ReportDataObject;
	@Input() utils: WidgetUtils;
	@Output() exit = new EventEmitter<void>();

	@Input() documentManager: IDocumentPreviewerControls;

	currentSentencesTableData = {} as SimpleTableData;
	widgetTableData = {} as SimpleTableData;

	statsTableConfig: any[];
	selectedMetrics: Metric[];

	loading: Promise<any>;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly timeToRunFormatter: TimeToRunFormatter,
		private readonly reportExportUtils: ReportExportUtilsService,
		private ageService: AgeService,
		@Inject('widgetTimingService') private readonly widgetTimingService: WidgetTimingService,
		@Inject('analyticsCacheOptions') private readonly analyticsCacheOptions: AnalyticsCacheOptions,
		@Inject('dashboardRunService') private readonly dashboardRunService: DashboardRunService,
		@Inject('globalNotificationService') private readonly globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		private readonly statsModeService: StatsModeService,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils,
	) {}


	ngOnChanges(changes: SimpleChanges): void {
		if (ChangeUtils.hasChange(changes.data)) {
			this.updateStats();
			this.updateTablesData();
			this.setSelectedMetrics();
		}
	}

	ngOnInit(): void {
		this.updateStats();
		this.updateTablesData();
		this.setSelectedMetrics();
	}

	private getMetadataValue(key: string): string {
		return this.data?.metadata ? this.data.metadata[key] : '';
	}

	private updateStats(): void {

		const widgetTimings = this.widgetTimingService.getTimingData(this.widget);
		const metricsCount = Number(this.getMetadataValue('metricsCount')) - Number(this.getMetadataValue('specialMetricsCount'));

		this.statsTableConfig = [{
			rows: [
				{ name: this.locale.getString('dashboard.dashboardName'), value: this.dashboard.name },
				{ name: this.locale.getString('widget.widgetName'), value: this.widget.displayName },
				{ name: this.locale.getString('widget.widgetOwner'), value: this.widget.properties.runAs }
			]
		}, {
			rows: [
				{
					name: this.locale.getString('widget.statsModeTimeToRun'),
					value: widgetTimings.getTotal(),
					type: 'timeToRun',
					clipboardFormatter: this.timeToRunFormatter.clipboardFormatter,
					data: cloneDeep(widgetTimings)
				},
				{ name: this.locale.getString('widget.statsModeUniqueMetrics'), value: metricsCount },
				{ name: this.locale.getString('widget.statsModeRequestId'), value: this.qid },
				{ name: this.locale.getString('widget.statsModePerformanceId'), value: this.dashboardRunService.getDashboardRunRequestId() },
				{ name: this.locale.getString('widget.statsModeCache'),
					value: this.analyticsCacheOptions.byApiValue(this.getMetadataValue('cache')).displayName }
			]
		}, {
			rows: [
				{ name: this.locale.getString('widget.totalCount'), value: this.getMetadataValue('totalCount') }
			]
		}];

		this.updateSnapshotTimestampInTable();
	}

	getStatsClipboardText(): string | undefined {
		if (_.isUndefined(this.statsTableConfig) || _.isEmpty(this.widgetTableData)) {
			return;
		}
		const statsTextExport = this.getClipboardStatsMetadata();
		const reportExport = new SimpleTableDataBuilder()
			.fromWellFormedData(this.widgetTableData)
			.exportFullTable();

		const newLine = '\r\n';
		return statsTextExport + newLine + newLine + reportExport;
	}

	private getClipboardStatsMetadata(): string {
		let data = {};
		this.statsTableConfig.forEach((group) => {
			group.rows.forEach((row) => {
				const textValue = row.textFormatter
					? row.textFormatter(row.value, row.data)
					: row.value;
				const value = row.clipboardFormatter
					? row.clipboardFormatter(row.value, row.data)
					: textValue;
				data[row.name] = value;
			});
		});
		return JSON.stringify(data, null, 4);
	}

	showSuccessNotification(): void {
		this.globalNotificationService.addSuccessNotification(this.locale.getString('common.copied'));
	}

	showStatsExportDialog(): void {
		this.cbDialogService.showStatsExportDialog(this.getStatsClipboardText());
	}

	private updateTablesData(): void {
		let promises: Promise<any>[];

		promises = [this.initAndRequestWidgetTableData()];


		if (this.displayCurrentSentencesTableData()) {
			let currentSentences: ReportDataObject = CurrentSentencesUtils.getCurrentSentencesAsReportData(this.widget, this.documentManager);
			promises.push(this.initAndRequestCurrentSentencesTableData(currentSentences));
		}

		this.loading = Promise.all(promises).then(result => {
			this.updateWidgetTableData(result[0]);

			if (this.displayCurrentSentencesTableData()) {
				this.updateCurrentSentencesTableData(result[1]);
			}
		});
	}

	initAndRequestWidgetTableData(): Promise<SimpleTableData> {
		this.widgetTableData = {header: [], body: []} as SimpleTableData;
		return this.getWidgetTableData(this.widget, this.data);
	}

	initAndRequestCurrentSentencesTableData(currentSentences: ReportDataObject): Promise<SimpleTableData> {
		this.currentSentencesTableData = {header: [], body: []} as SimpleTableData;
		return this.getWidgetTableData(this.widget, currentSentences);
	}

	private updateWidgetTableData(tableData: SimpleTableData): void {
		if (tableData) {
			tableData.additionalInfo = this.statsModeService.getTableDataAdditionalInfo(this.widget, this.data);
			this.widgetTableData = tableData;
			this.ref.markForCheck();
		}
	}

	updateCurrentSentencesTableData(tableData: SimpleTableData): void {
		if (tableData) {
			this.currentSentencesTableData = tableData;
			this.ref.markForCheck();
		}
	}

	private getWidgetTableData(widget: Widget, dataObject: ReportDataObject): Promise<SimpleTableData | undefined> {
		let widgetSettings = ObjectUtils.copy(widget);
		let data = this.reportExportUtils.prepareWidgetData(dataObject, widgetSettings);
		let exportOptions = {
			statsMode: this.showStats || this.showDescription,
			showEnrichment: this.showEnrichment
		};
		return PromiseUtils.wrap(this.reportExportUtils.prepareExportAssets(widgetSettings, data, exportOptions)
			.then(allOptions => {
				const columnOptions = this.utils ? this.utils.allColumns : undefined;
				return this.exportUtils.getWidgetTableData(widgetSettings, data, allOptions, columnOptions).then((csv) => {
					if (csv !== '\"\"') return CsvTableDecomposer.decompose(csv);
					else return undefined;
				});
			}));
	}

	private updateSnapshotTimestampInTable(): void {
		if (FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard)) {
			let tableRow = {
				rows: [
					{name: this.locale.getString('widget.snapshotTime'), value: this.getCacheAge()},
				]
			};
			this.statsTableConfig.push(tableRow);
		}
	}

	getCacheAge = (): string => {
		if (!this.widget.responsiveState?.reportTimestamp) {
			return '';
		}
		return this.ageService.getAsOfAgoText(this.widget.responsiveState?.reportTimestamp);
	};

	getTableHeader(): string {
		return this.displayCurrentSentencesTableData()
			? this.locale.getString('widget.resultsView')
			: this.locale.getString('widget.tableView');
	}

	getDashboardOwner(): string {
		return this.dashboard.ownerName;
	}

	displayCurrentSentencesTableData(): boolean {
		// Verbatim View displayed as Pane has PreviewMode.DOCUMENT as well as Document View
		return FeedbackUtils.isFeedbackWidget(this.widget) && FeedbackUtils.isDocumentMode(this.widget);
	}

	displayTableData(): boolean {
		return !(
			FeedbackUtils.isFeedbackWidget(this.widget)
			&& FeedbackUtils.isDocumentMode(this.widget)
			&& !FeedbackUtils.isSentencesPaneEnabled(this.widget)
		);
	}
	/* Function to filter the custom metrics used in the widget that have description */
	setSelectedMetrics(): void {
		const selectedCustomMetrics = this.widget.properties?.selectedMetrics?.filter(
			metric => AnalyticMetricTypes.isCustom(metric)
		) || [];
		const availableMetrics = this.utils.metrics;
		const selectedMetricIds = selectedCustomMetrics.map(metric => metric.id);
		this.selectedMetrics = availableMetrics?.filter(metric =>
			selectedMetricIds.includes(metric.id) && metric.description?.length > 0
		).sort((a: Metric, b: Metric) =>
			SortUtils.compareAlphanumerically(a.displayName, b.displayName)
		) || [];
	}

}

app.directive('widgetStats', downgradeComponent({component: WidgetStatsComponent}));
