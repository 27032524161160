import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DashboardEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { IDashboardFilter } from '@app/modules/dashboard/dashboard-filter';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { FiltersToggle } from '@app/modules/dashboard/dashboard-filters-panel/dashboard-filters-panel.component';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { DateRangeUtils } from '@app/modules/utils/dates/date-range-utils.class';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';




@Component({
	selector: 'dashboard-view-filters',
	templateUrl: './dashboard-view-filters.component.html',
	styles: [`
		.dashboard-view-filters {
			width: 0;
			position: absolute;
			top: 40px;
			left: 8px;
		}
	`],
	changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardViewFiltersComponent extends SelfCleaningComponent implements OnInit {
	@Input() toggle: FiltersToggle;
	@Input() dashboard: Dashboard;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() personalization: PersonalizationState;
	@Input() embedded: boolean;
	@Input() truncate: boolean;
	@Input() isEmbeddedInternally = false;

	filtersPanel: {
		applyFilters: () => void;
		projectTimezone: string;
		addFilterChange: (type, currentFilter) => void;
		projectDateFilters: {
			dateFilters: UIOption<string>[];
		};
		projectAttributesFiltered: any[];
	};

	@ViewChild(NgbPopover, {static: false}) private popover: NgbPopover;

	constructor(
		private locale: CxLocaleService,
		private reportProjectContextService: ReportProjectContextService,
		private reportAttributesService: ReportAttributesService,
		private reportModelsService: ReportModelsService,
		private reportFiltersService: ReportFiltersService,
		private eventBus: GlobalEventBus,
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('datePeriodUtils') private datePeriodUtils: DatePeriodUtils,
	) {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.personalization.getPostInitObserver().subscribe((state) => {
			if (!state) {
				this.dashboardHistory.applyFilterUpdates(this.dashboard.appliedFiltersArray);
				this.initFilters();
			}
		}));
	}

	changePopupState(open: boolean) {
		if (open) {
			this.popover.open();
		} else {
			this.popover.close();
		}
	}

	private initFilters(): void {
		this.filtersPanel = {} as any;

		if (this.$rootScope.appliedFiltersArray)
			this.dashboard.appliedFiltersArray = this.$rootScope.appliedFiltersArray;
		this.dashboardHistory.applyFilterUpdates(this.dashboard.appliedFiltersArray);


		this.filtersPanel.applyFilters = () => {
			this.dashboardHistory.setAppliedFilters(this.dashboard.appliedFiltersArray);
			this.dashboard.properties.isAttributeFiltersApplied = true;
			this.eventBus.broadcast(DashboardEvent.REFRESH, this.dashboard.id);
		};

		this.filtersPanel.projectTimezone = null; // local

		this.filtersPanel.addFilterChange = (type, currFilter) => {
			this.dashboardHistory.addViewFilterChange({ type, filter: currFilter });
		};

		if (this.isProjectSelected(this.dashboard.properties)) {
			this.updateProjectTimezone();
			this.updateDateRangeFilters();
			this.reloadDashboardFilterOptions();
		}
	}

	private isProjectSelected(props): boolean {
		let projectId = props && props.project;
		return !isNaN(projectId) && (projectId > 0 || InternalProjectTypes.isAdminProject(projectId));
	}

	private updateProjectTimezone(): void {
		if (!this.dashboard || !this.dashboard.properties) {
			this.filtersPanel.projectTimezone = null;
			return;
		}
		this.reportProjectContextService.getDashboardProjectTimezone(this.dashboard).then((timezone) => {
			this.filtersPanel.projectTimezone = timezone;
		});
	}

	private updateDateRangeFilters(): void {
		this.filtersPanel.projectDateFilters = {} as any;
		if (!this.dashboard || !this.dashboard.properties) {
			return;
		}
		let dateFilter = _.chain(this.dashboardFiltersService
			.getDashboardDateFilter(this.dashboard.appliedFiltersArray));
		let selectedDateFilter = dateFilter.result('selectedAttributeValue')
			.result('dateFilterMode')
			.value() as any;

		this.reportFiltersService.getDashboardDateFilters(this.dashboard)
			.then((data) => {
				this.filtersPanel.projectDateFilters.dateFilters =
					this.datePeriodUtils.processDateFilters(data, [selectedDateFilter]);

				if (DateRangeUtils.isCustomDateFilterMode(selectedDateFilter)) {
					let filterId = DateRangeUtils.getCustomDateFilterId(selectedDateFilter);
					let foundDateFilter = _.findWhere(data, {id: filterId});
					if (foundDateFilter) {
						let updates = (dateFilter.value() as any).selectedAttributeValue;
						(updates as DateFilter).dateDisplayName = foundDateFilter.name;
						this.dashboardHistory.
							applyFilterUpdates(this.dashboard.appliedFiltersArray);
					}
				}
			});
	}

	private reloadDashboardFilterOptions(): void {
		this.filtersPanel.projectAttributesFiltered = [];

		let attributesPromise = this.reportAttributesService.getDashboardAttributesOrEmpty(this.dashboard);
		let modelsPromise = this.reportModelsService.getDashboardModels(this.dashboard);

		PromiseUtils.all([attributesPromise, modelsPromise]).then((responses) => {
			let attributes = responses[0];
			let models = responses[1];

			this.filtersPanel.projectAttributesFiltered.splice(0);
			this.getDashboardFilterGroups(attributes, models).forEach((group) => {
				this.filtersPanel.projectAttributesFiltered.push(group);
			});
		});
	}

	private getDashboardFilterGroups(attributes, models): any[] {
		let result = [];

		if (!this.hasFilter('dateFilter'))
			result.push(this.getDateFilter());

		if (!this.hasFilter(DashboardFilterTypes.TEXT)) {
			result.push(this.getTextFilter());
		}

		let savedFiltersGroup = this.getSavedFiltersGroup();
		if (savedFiltersGroup !== null)
			result.push(savedFiltersGroup);

		let modelsGroup = this.getProjectModelsGroup(models);
		if (modelsGroup !== null)
			result.push(modelsGroup);

		let attributesGroup = this.getProjectAttributesGroup(attributes);
		if (attributesGroup !== null)
			result.push(attributesGroup);

		return result;
	}

	private getTextFilter(): IDashboardFilter {
		return {
			displayName: this.locale.getString('filter.textFilter'),
			name: DashboardFilterTypes.TEXT,
			filterType: DashboardFilterTypes.TEXT
		};
	}

	private getSavedFiltersGroup(): IDashboardFilter {
		if (this.hasFilter(DashboardFilterTypes.SAVED_FILTER)) return null;

		return {
			displayName: this.locale.getString('filter.savedFilters'),
			name: DashboardFilterTypes.SAVED_FILTER,
			filterType: DashboardFilterTypes.SAVED_FILTER
		};
	}

	private getProjectModelsGroup(models): IDashboardFilter {
		if (!models || models.length === 0) return null;

		let modelsChildren = models.map(model => {
			return {
				id: model.id,
				name: model.name,
				hide: model.hide,
				displayName: model.name,
				filterType: DashboardFilterTypes.TOPIC
			};
		});

		return {
			displayName: this.locale.getString('widget.topics'),
			name: 'topics',
			children: modelsChildren
		};
	}

	private getDateFilter(): IDashboardFilter {
		return {
			displayName: this.locale.getString('filter.dateRange'),
			name: 'dateFilter',
			filterType: DashboardFilterTypes.DATE_RANGE
		};
	}

	private getProjectAttributesGroup(attributes): IDashboardFilter {
		if (!attributes || attributes.length === 0) return null;
		let attributesGroupChildren = [];

		attributes.filter(MetricFilters.NOT_DATE).forEach((attribute) => {
			attribute.filterType = DashboardFilterTypes.ATTRIBUTE;
			if (!this.hasFilter(attribute.name)) {
				attributesGroupChildren.push(attribute);
			}
		});

		return {
			displayName: this.locale.getString('widget.groupAttributes'),
			name: 'attributes',
			children: attributesGroupChildren
		};
	}

	private hasFilter(filterName): boolean {
		let applied = _.filter(this.dashboard.appliedFiltersArray, filter => {
				return filter.selectedAttribute && filter.selectedAttribute.name === filterName;
		});
		return applied.length > 0;
	}
}

app.directive('dashboardViewFilters', downgradeComponent({ component: DashboardViewFiltersComponent }));
