import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { PasswordPolicy } from '@app/modules/account-administration/password-policy/entities/password-policy';
import { CharacterSets, PasswordState } from '@app/modules/user/new-password-form/password-state';

export enum PasswordError {
	EMPTY,
	MIN_LENGTH,
	EQUAL_TO_EMAIL,
	FORBIDDEN_WORD,
	INVALID_CHARS,
	CONSECUTIVE_CHARS
}

@Injectable({
	providedIn: 'root'
})
export class PasswordValidatorService {
	public static readonly LOWERCASE_CHAR_SET = 'a-z';
	public static readonly UPPERCASE_CHAR_SET = 'A-Z';
	public static readonly NUMBER_SET = '0-9';
	public static readonly SPECIAL_CHAR_SET = '!~<>,;:_=?*+#.\\"&§%°()\\|\\[\\]\\-\\$\\^\\@\\/';

	//private static readonly ALLOWED_CHARS_PATTERN = new RegExp(`${PasswordValidatorService.LOWERCASE_CHAR_SET}`) /^[A-Za-z0-9!~<>,;:_=?*+#.\"&§%°()\|\[\]\-\$\^\@\/]+$/;
	private static readonly CONSECUTIVE_CHARS_PATTERN = /^(?!.*(.)\1{2,}).+$/;

	constructor(
		private readonly locale: CxLocaleService
	) {}

	validate(password: string, policy: PasswordPolicy, email: string): string[] {
		let errors: string[] = [];
		if (_.isEmpty(password)) {
			errors.push(this.locale.getString('administration.passwordInvalid', {pwdLength: policy.minLength}));
			return errors;
		}
		if (password.length < policy.minLength) {
			errors.push(this.locale.getString('administration.passwordInvalid', {pwdLength: policy.minLength}));
		}
		errors.pushAll(this.validateForErrors(password, email));
		return errors;
	}

	getPasswordState(password: string, policy: PasswordPolicy): PasswordState {
		const characterSets: CharacterSets = {
			lowercase: this.hasCharacterSet(password, PasswordValidatorService.LOWERCASE_CHAR_SET),
			uppercase: this.hasCharacterSet(password, PasswordValidatorService.UPPERCASE_CHAR_SET),
			// eslint-disable-next-line id-blacklist
			number: this.hasCharacterSet(password, PasswordValidatorService.NUMBER_SET),
			special: this.hasCharacterSet(password, PasswordValidatorService.SPECIAL_CHAR_SET),
		};
		const numberOfSets = this.getNumberOfCharacterSets(characterSets);
		return {
			...characterSets,
			acceptedLength: password && password.length >= policy.minLength,
			extraLength: password && password.length >= policy.minLength * 1.5,
			numberOfSets
		};
	}

	private getNumberOfCharacterSets(sets: CharacterSets): number {
		return _.chain(sets)
			.pairs()
			.map((pair) => pair[1] ? 1 : 0)
			.reduce((memo, val) => memo + val, 0)
			.value();
	}

	validateForErrors(password: string, email: string): string[] {
		let errors: string[] = [];
		if (password === 'password') {
			errors.push(this.locale.getString('login.passwordMatchWordError', {forbiddenWord: 'password'}));
		}
		if (password === email) {
			errors.push(this.locale.getString('login.passwordMatchEmailError'));
		}
		if (!this.isAllowedCharactersOnly(password)) {
			errors.push(this.locale.getString('login.passwordInvalidCharsError'));
		}
		if (!this.isConsecutiveCharactersValid(password)) {
			errors.push(this.locale.getString('login.passwordConsecutiveCharsError'));
		}
		return errors;
	}

	private hasCharacterSet = (password: string, set: string): boolean => {
		if (!password) {
			return false;
		}
		const pattern = `[${set}]+`;
		return new RegExp(pattern).test(password);
	};

	private isAllowedCharactersOnly(password: string): boolean {
		const lowerChars = PasswordValidatorService.LOWERCASE_CHAR_SET;
		const upperChars = PasswordValidatorService.UPPERCASE_CHAR_SET;
		const numbers = PasswordValidatorService.NUMBER_SET;
		const specialChars = PasswordValidatorService.SPECIAL_CHAR_SET;
		const pattern = `^[${lowerChars}${upperChars}${numbers}${specialChars}]+$`;
		return new RegExp(pattern).test(password);
	}

	private isConsecutiveCharactersValid(password: string): boolean {
		return PasswordValidatorService.CONSECUTIVE_CHARS_PATTERN.test(password);
	}
}
