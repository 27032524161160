import { Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import {
	RequestAccessReason,
	RequestAccessService
} from '@app/modules/access-management/services/request-access-service.service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { EnvironmentService } from '@cxstudio/services/environment-service';

@Component({
	selector: 'request-access-page',
	templateUrl: './request-access-page.component.html'
})
export class RequestAccessPageComponent implements OnInit {
	@Input() objectType: ObjectType;
	@Input() objectId: number;
	loading: Promise<any>;
	requestAccessMessageToSend: string = '';
	requestAccessHeaderMessage: string = '';
	requestedAccessOfOwnerMessage: string = '';
	showRequestMessageForm: boolean = true;
	showRequestMessageSend: boolean = false;
	showRedirectToHome: boolean = false;

	constructor(
		@Inject('$location') private $location: ng.ILocationService,
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('environmentService') private environmentService: EnvironmentService,
		private requestAccessService: RequestAccessService,
	) {}

	ngOnInit(): void {
		this.requestAccessHeaderMessage = this.requestAccessService.getDialogTitle(this.objectType);
		this.requestedAccessOfOwnerMessage = this.requestAccessService.getRequestedAccessOfOwnerMessage(this.objectType);
		this.showRedirectToHome = !this.environmentService.isIframe();
	}

	requestAccess(): void {
		this.loading = this.requestAccessService.handleRequestAccess(this.objectType, this.objectId, this.requestAccessMessageToSend)
			.then(() => {
				this.showRequestMessageForm = false;
				this.showRequestMessageSend = true;
			}, reason => {
				if (reason === RequestAccessReason.alreadyShared) {
					this.processAlreadyShared();
					return;
				}
			});
	}

	close(): void {
		this.redirectToHome();
	}

	private redirectToHome = () => {
		this.redirectService.goToDashboardList();
	};

	private processAlreadyShared(): void {
		let path;
		let search: any = {};
		switch (this.objectType) {
			case ObjectType.DASHBOARD: path = `/home/${this.objectId}`; break;
			case ObjectType.METRIC: path = `/metrics`; search = {metricId: this.objectId}; break;
			case ObjectType.FILTER: path = `/filters`; search = {filterId: this.objectId}; break;
			default: path = '/dashboards'; break;
		}
		this.$location.path(path).search(search);
	}
}

app.directive('requestAccessPage', downgradeComponent({component: RequestAccessPageComponent}));
