import { ScheduleIdentity } from './schedule-identity';
import { JobStatus } from './job-status';
import { DashboardJobRun } from './dashboard-job-run';
import { ScheduleApiService } from './schedule-api-service';
import * as moment from 'moment';
import { Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DateService } from '@cxstudio/services/date-service.service';
import { downgradeComponent } from '@angular/upgrade/static';
@Component({
	selector: 'schedule-runs',
	template: `
	<div [ngBusy]="loading">
		<h3>{{dashboardName}}</h3>
		<p>{{scheduleName}}</p>
		<div class="form-group schedule-runs-table mt-16">
			<div class="clearfix schedule-row" *ngFor="let job of jobRuns | orderBy:'fireTime'">
				<div class="col-xs-6">
					{{ formatJobTime(job.fireTime) }}
				</div>
				<div class="col-xs-6 schedule-job-status" [ngClass]="job.jobStatus">
					{{ formatJobStatus(job.jobStatus) }}
				</div>
			</div>
		</div>
	</div>
	`
})
export class ScheduleRunsComponent implements OnInit {

	@Input() identity: ScheduleIdentity;
	@Input() dashboardName: string;
	@Input() scheduleName: string;

	jobRuns: DashboardJobRun[];
	loading: Promise<any>;

	constructor(
		@Inject('dateService') private readonly dateService: DateService,
		private readonly scheduleApiService: ScheduleApiService,
		private readonly locale: CxLocaleService) {
	}

	ngOnInit(): void {
		this.jobRuns = [];
		let key = this.identity.key;
		let group = this.identity.group;
		this.loading = this.scheduleApiService.getScheduleRuns(key, group).then((runs) => {
			this.jobRuns = runs;
		});
	}

	formatJobTime = (isoDate: string): string => {
		return this.dateService.format(moment(isoDate).toDate());
	};

	formatJobStatus = (status: JobStatus): string => {
		if (!status)
			return '';
		return this.locale.getString(`schedule.status_${status.toLowerCase()}`);
	};
}

app.directive('scheduleRuns', downgradeComponent({component: ScheduleRunsComponent}));
