import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { OrgHierarchyUIStatus } from '@cxstudio/organizations/hierarchy-publication-state';

@Component({
	selector: 'organization-table-status-tag',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="border-radius-4 text-sm-1 border-solid border-2" [ngClass]="getContainerClasses()">
			<div class="d-flex ph-8 pv-4 align-items-center overflow-hidden justify-center">
				<p class="p-0 m-0 overflow-hidden no-wrap font-semibold">{{text}}</p>
				<span *ngIf="showRefresh()"
					class="q-icon q-icon-refresh cursor-pointer"
					[title]="'organization.refreshHierarchies' | i18n"></span>
			</div>
		</div>
	`
})

export class OrganizationTableStatusTagComponent implements OnInit, OnChanges {
	@Input() type: OrgHierarchyUIStatus | string;

	background: string;
	color: string;
	border: string;

	text: string;

	constructor(
		private locale: CxLocaleService,
	) { }

	ngOnChanges(changes: SimpleChanges): void {
		this.process();
	}

	ngOnInit(): void {
		this.process();
	}

	getContainerClasses(): string[] {
		return [this.border, this.background, this.color];
	}

	private setSuccessStyles(): void {
		this.background = 'bg-success-200';
		this.color = 'text-success-700';
		this.border = 'border-success-700';
	}

	private process(): void {
		switch (this.type) {
			case OrgHierarchyUIStatus.NOT_READY:
				this.background = 'bg-gray-200';
				this.color = 'text-gray-1000';
				this.border = 'border-gray-1000';
				this.text = this.locale.getString('organization.not_ready');
				break;
			case OrgHierarchyUIStatus.READY_UNPUBLISHED:
				this.setSuccessStyles();
				this.text = this.locale.getString('organization.ready_unpublished');
				break;
			case OrgHierarchyUIStatus.READY_PUBLISHED:
				this.setSuccessStyles();
				this.text = this.locale.getString('organization.ready_published');
				break;
			case OrgHierarchyUIStatus.READY:
				this.setSuccessStyles();
				this.text = this.locale.getString('organization.ready');
				break;
			case OrgHierarchyUIStatus.PROCESSING:
				this.background = 'bg-blue-200';
				this.color = 'text-blue-800';
				this.border = 'border-blue-800';
				this.text = this.locale.getString('organization.processing');
				break;
			default:
				this.background = 'bg-gray-200';
				this.color = 'text-gray-1000';
				this.border = 'border-gray-1000';
				this.text = this.locale.getString('organization.not_ready');
		}
	}

	showRefresh(): boolean {
		return this.type === OrgHierarchyUIStatus.PROCESSING;
	}

}

app.directive('organizationTableStatusTag',
	downgradeComponent({component: OrganizationTableStatusTagComponent}));

