import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild, OnInit, Inject } from '@angular/core';
import { NgForm } from '@angular/forms';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { IProjectPreselection } from '@cxstudio/projects/project-preselection.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';
import { Security } from '@cxstudio/auth/security-service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';

@Component({
	selector: 'home-quick-insights-settings',
	templateUrl: './home-quick-insights-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HomeQuickInsightsComponent implements OnInit {
	@Input() homePage: HomePage;
	@Output() onChange = new EventEmitter<void>();
	@ViewChild('quickInsightsPropertiesForm', {static: false}) propertiesForm: NgForm;

	properties: any;

	contentProviderErrors: string[];

	projectsLoading: Promise<any>;

	searchingOwnerCandidates: boolean;

	isWorkspaceEnabled: boolean;
	originalProjectSelection: IProjectSelection;
	originalWorkspaceProject: WorkspaceProject;
	projectChanged: boolean;

	constructor(
		private homePageApi: HomePageApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('security') private security: Security,
	) { }

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		let quickInsights = this.homePage.quickInsights;
		this.properties = {
			cbContentProvider: quickInsights.contentProviderId,
			cbAccount: quickInsights.accountId,
			project: quickInsights.projectId
		};

		this.originalProjectSelection = this.getProjectSelection();
		this.originalWorkspaceProject = {...quickInsights.workspaceProject};
	}

	onProjectSelectionChange(projectSelection: IProjectPreselection): void {
		this.properties.cbContentProvider = projectSelection.cbContentProvider;
		this.properties.cbAccount = projectSelection.cbAccount;
		this.properties.project = projectSelection.project;

		this.homePage.quickInsights = _.extend({}, this.homePage.quickInsights, {
			contentProviderId: this.properties.cbContentProvider,
			accountId: this.properties.cbAccount,
			projectId: this.properties.project,
		});

		this.projectChanged = ProjectIdentifier.isProjectSelected(this.homePage.quickInsights)
			&& !_.isEqual(this.originalProjectSelection, this.getProjectSelection());
		this.onChange.emit();
	}

	private getProjectSelection(): IProjectSelection {
		const fields = ['contentProviderId', 'accountId', 'projectId'];
		return _.pick(this.homePage.quickInsights, fields) as IProjectSelection;
	}

	onCpErrorsChange(errors: string[]) {
		this.contentProviderErrors = errors;
	}

	onProjectsLoading(loadingPromise: Promise<any>) {
		this.projectsLoading = loadingPromise;
	}

	onProjectChange = (): void => {
		this.projectChanged = this.isProjectSelected()
			&& !_.isEqual(this.originalWorkspaceProject, this.homePage.quickInsights.workspaceProject);
		this.onChange.emit();
	};

	isProjectSelected(): boolean {
		if (this.isWorkspaceEnabled) {
			return WorkspaceProjectUtils.isProjectSelected(this.homePage.quickInsights.workspaceProject);
		} else {
			return ProjectIdentifier.isProjectSelected(this.homePage.quickInsights);
		}
	}


	onInheritChange() {
		if (this.homePage.quickInsights.inheritProjectSetting) {
			this.clearSettings();
		} else {
			this.changeOwner(this.security.getEmail());
		}
	}

	changeOwner(owner: string): void {
		if (!owner)
			return;
		this.homePage.quickInsights = _.extend({}, this.homePage.quickInsights, {owner});
		this.onChange.emit();
	}

	ownerCandidates = (text$: Observable<string>) => {
		return text$.pipe(
			debounceTime(300),
			distinctUntilChanged(),
			switchMap(term => {
				this.searchingOwnerCandidates = true;
				return this.homePageApi.getOwnerCandidates(term, this.homePage.quickInsights).then(candidates => {
					this.searchingOwnerCandidates = false;
					return candidates;
				});
			})
		);
	};

	clearSettings(): void {
		this.homePage.quickInsights.owner = '';
		this.onProjectSelectionChange({cbContentProvider: -1, cbAccount: -1, project: -1});
	}

	clearText(): void {
		setTimeout(() => {
			if (!this.homePage.quickInsights.owner) {
				this.propertiesForm.form.controls.owner.setValue('');
			}
		}, 100);
	}
}
