import { Component, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocationService } from '@app/core/cx-location.service';
import { BypassModalBlockHelper } from './bypass-modal-block-helper.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';


@Component({
	selector: 'bypass-block',
	template: `<div id="bypass-block" *ngIf="showBypassBlock()"
					(click)="skipToMainContent($event)"
					(keydown.enter)="skipToMainContent($event)">
					<a class="p-8 m-8" href="javascript:void(0)">{{'common.skipToMainContent'|i18n}}</a>
				</div>`
})
export class BypassBlockComponent {

	constructor(
		private readonly cxLocation: CxLocationService,
		@Inject('environmentService') private readonly environmentService: EnvironmentService,
	) {}

	showBypassBlock(): boolean {
		return this.environmentService.isApplicationInitialized()
			&& !BypassModalBlockHelper.showBypassModalBlock()
			&& !this.cxLocation.path().match(/\/login\/|\/login$/);
	}

	skipToMainContent(event: UIEvent): void {
		event.stopPropagation();
		event.preventDefault();
		setTimeout(() => $('#main-content').first().trigger('focus'));
	}

}

app.directive('bypassBlock', downgradeComponent({component: BypassBlockComponent}));
