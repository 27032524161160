import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IReportModel } from '@app/modules/project/model/report-model';
import ProjectGeographies from '@cxstudio/attribute-geography/project-geographies';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DatePeriod } from '@cxstudio/reports/entities/date-period';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { OrderableList } from '@cxstudio/services/orderable-list.class';
import { AnyGrouping } from '@cxstudio/reports/entities/any-grouping';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { GroupingSortOrder } from '@cxstudio/common/an-sort-direction';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';

export interface IWidgetSettingsOptions {
	groupingOptions: any[];
	attributes: IReportAttribute[];
	models: IReportModel[];
	availableAttributes: AttributeGrouping[];
	standardMetrics: any[];
	studioMetrics: any[];
	predefinedMetrics: any[];
	primaryGroupingOptions: any[];
	additionalMetrics: any[];
	sortMetrics: any[];
	cogSortByMetrics: any[];
	sizeMetrics: any[];
	calculationSeries: any[];
	extendedAttributes: any[];
	stackedAttributes: any[];
	projectGeographies: ProjectGeographies;
	studioPalettes: WidgetColorPalette[];
	providerColors: string[];
}

export interface IWidgetSettingsUI {
	//filterItems?: any;
	attributes?: IReportAttribute[];
	models?: IReportModel[];
	listTypes?: typeof KeyMetricListTypes;
	popSelectionEnabled?: boolean;
	dualVisualizations?: any[];
	secondaryGroupVisible?: boolean;
	periods?: DatePeriod[];
	periodOptions?: any;
	showGroupingsError?: boolean;
	showCalculationsError?: boolean;
	heatmapVisualizations?: any[];
	showSortOptions?: boolean;
}

export interface IWidgetSettingsScope {
	staticData: any;
	visualProps: VisualProperties;
	props: WidgetProperties;
	widget: Widget;
	options: IWidgetSettingsOptions;
	ui: IWidgetSettingsUI;
	attributeList?: OrderableList;
	metricList?: OrderableList;

	applyVisualChanges: () => void;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	clearErrors?: () => void;
	sizeMetricsFilter?: (metric) => boolean;
	updateCustomDateFilters?: (dateFilters) => void;
	populateCurrentHierarchyCalculations: (metrics, grouping, calculations, models?) => void;
	initializePeriods: () => void;
	reloadCommonSettings: (config) => ng.IPromise<IWidgetSettings>;
	configure?: (attribute: AttributeGrouping, type: KeyMetricListTypes) => ng.IPromise<void>;
	conversionListener?: (params: {$event: ReportWidget}) => void;
	initAttributesSelectionPanel?: () => void;
	processSelections?: () => void;
	$on: (event: string, handler: () => void) => () => void;
}

export class CBSettingsService {

	constructor(
		private DefaultMetricConfig,
	) {}

	initializeCommonSettingsOrFail = () => {
		throw new Error('deprecated');
	};

	initializeCommonSettings = ($scope: IWidgetSettingsScope, settings: IWidgetSettings): void => {
		this.initializeAttributes($scope, settings);
		this.initializeModels($scope, settings);
		this.initializeUI($scope, settings);
		this.initializePalettes($scope, settings);
	};

	private initializeAttributes($scope: IWidgetSettingsScope, settings: IWidgetSettings): void {
		$scope.options.attributes = settings.attributes;
	}

	private initializeModels($scope: IWidgetSettingsScope, settings: IWidgetSettings): void {
		$scope.options.models = settings.models;
	}

	private initializeUI($scope: IWidgetSettingsScope, settings: IWidgetSettings): void {
		let attributes = $scope.options.attributes;
		let models = $scope.options.models;

		$scope.ui = $scope.ui || {};

		$scope.ui.attributes = attributes;
		$scope.ui.models = models;
	}


	private initializePalettes($scope: IWidgetSettingsScope, settings: IWidgetSettings): void {
		$scope.options.studioPalettes = settings.studioPalettes;
		$scope.options.providerColors = settings.providerColors;
	}

	initDefaultProperties = (attributes: any[], customOverwrites?: any) => {
		customOverwrites = customOverwrites || {};
		attributes.forEach((attr: AnyGrouping & INode) => {
			if (attr.children) {
				this.initDefaultProperties(attr.children, customOverwrites);
			} else {
				$.extend(attr, this.DefaultMetricConfig);
				if (AnalyticMetricTypes.isTime(attr)) {
					attr.sortOrder = GroupingSortOrder.ASC;
					attr.emptyPeriodType = EmptyPeriodType.SHOW_ALL;
				}

				if (AnalyticMetricTypes.isEffortGroup(attr) || AnalyticMetricTypes.isSentimentGroup(attr)) {
					attr.nullInclude = true;
				}

				if (AnalyticMetricTypes.isTopics(attr)) {
					attr.inheritTopics = false;
				}

				$.extend(attr, customOverwrites);
			}
		});
	};
}

app.service('cbSettingsService', CBSettingsService);
