import { SecondaryTrackConfig } from '@cxstudio/conversation/shapes/secondary-track-config.class';
import { ConversationEnrichment } from '@cxstudio/reports/document-explorer/conversations/conversation-enrichments.class';
import { SingleLaneEnrichmentTypes } from '@cxstudio/conversation/conversation-chart-options.class';
import { SecondaryTrackRendererType } from '@cxstudio/conversation/secondary-track-renderer-type.enum';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ScorecardDocExplorerUtils } from '@app/modules/document-explorer/context-pane/scorecard-doc-explorer-utils.class';
import { SentenceScorecardTopic } from '@cxstudio/reports/preview/preview-sentence-class';
import { EnrichmentIcon, EnrichmentIconType } from '@cxstudio/reports/preview/enrichment-icon.class';

export class ScorecardTrack extends SecondaryTrackConfig {

	static hasScorecard = (dataPoint: ConversationDataPoint, scorecardId: number): boolean => {
		return !_.isEmpty(dataPoint.scorecardTopics?.[scorecardId]);
	};

	static generateScorecardEnrichment(headerName: string, scorecardId: number,
			onClick: (event: Event) => void): ConversationEnrichment {
		return {
			iconType: SecondaryTrackRendererType.SCORECARD,
			enrichmentType: SingleLaneEnrichmentTypes.SCORECARD,
			hasValue: data => ScorecardTrack.hasScorecard(data, scorecardId),
			getColorArray: () => ScorecardDocExplorerUtils.getColorArray(),
			tooltipFormatter: data => _.chain(data.scorecardTopics[scorecardId])
				.sortBy(topic => -ScorecardDocExplorerUtils.getTopicWeight(topic))
				.map(topic => ScorecardTrack.getTooltipItem(topic))
				.value()
				.join(''),
			getColorIndex: () => -1,
			getName: () => headerName,
			getHeaderIcon: () => 'q-icon-scorecard',
			getValue: data => data.scorecardTopics[scorecardId],
			getWarning: (data) => {
				let topics: SentenceScorecardTopic[] = data.scorecardTopics?.[scorecardId];
				if (topics && topics.findIndex((elem) => elem.rebutted) !== -1) {
					return 'docExplorer.rebuttedResult';
				}
			},
			onClick,
		};
	}

	private static getTooltipItem(topic: SentenceScorecardTopic): string {
		let fillColor = ScorecardDocExplorerUtils.getTopicColor(topic);
		let text = topic.name;
		let icon = ScorecardDocExplorerUtils.getTopicIcon(topic);

		return new EnrichmentIcon()
				.withDisplayName(text)
				.withTitle(text)
				.withIcon(icon)
				.withColor(fillColor)
				.as(EnrichmentIconType.PILL);
	}

	dataFilter = data => this.enrichment?.hasValue(data);

	getPointClass = (data) => {
		return data.isFiltered ? 'filtered' : '';
	};
}
