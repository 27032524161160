import { BetaFeatureTracker } from '@app/modules/context/beta-features/beta-feature-tracker-class';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';

@Injectable({providedIn: 'root'})
export class SystemAdministrationBetaFeatureApiService {

	constructor(
		private http: CxHttpService,
	) {}

	makeBetaFeatureGloballyUnavailable = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.performBetaFeatureAction(betaFeature, 'unavailable-globally');
	};

	disableBetaFeatureGlobally = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.performBetaFeatureAction(betaFeature, 'disabled-globally');
	};

	enableBetaFeatureGlobally = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.performBetaFeatureAction(betaFeature, 'enabled-globally');
	};

	disableBetaFeatureInternally = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.performBetaFeatureAction(betaFeature, 'disabled-internally');
	};

	enableBetaFeatureInternally = (betaFeature: BetaFeatureTracker): Promise<void> => {
		return this.performBetaFeatureAction(betaFeature, 'enabled-internally');
	};

	private performBetaFeatureAction = (betaFeature: BetaFeatureTracker, state: string): Promise<void> => {
		return this.http.put(`rest/system-admin/beta-feature/${betaFeature.id.toUpperCase()}/state/${state}`, {});
	};

}
