import { ICellEditorComp, ICellEditorParams } from 'ag-grid-enterprise';

export class NoEditorToggle implements ICellEditorComp {
	private value;

	init(params: ICellEditorParams) {
		//just change value to opposite and return
		this.value = !params.value;
		params.node.setDataValue(params.column, this.value);
		params.stopEditing();
	}

	getValue() {
		return this.value;
	}

	getGui(): HTMLElement {
		return undefined;
	}
}
