import { Component, Inject, ChangeDetectionStrategy, Input } from '@angular/core';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';

@Component({
	selector: 'dashboard-zoom',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `<div class="pv-4 dropdown-item no-hover font-semibold">{{'dashboard.zoom'|i18n}}</div>
		<div class="pv-4 btn-group w-100-percent dropdown-item no-hover clearfix">
			<button *ngFor="let zoomLevel of ['', 75, 50]"
				attr.data-testid="zoom-button-{{zoomLevel > 0 ? zoomLevel : 100}}"
				class="btn btn-secondary btn-sm"
				[ngClass]="{'btn-selected': selectedZoomLevel === zoomLevel}"
				tabindex="0"
				(click)="changeZoomLevel($event, zoomLevel);">
				{{zoomLevel || 100}}%
			</button>
		</div>`
})

export class DashboardZoomComponent {

	@Input() selectedZoomLevel: any;

	constructor(
		@Inject('layoutHelperService') private layoutHelperService: LayoutHelper
	) { }

	changeZoomLevel = (event: any, zoomLevel: any): void => {
		this.layoutHelperService.changeZoomLevel(zoomLevel);
	};

}
