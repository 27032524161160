import { Security } from '@cxstudio/auth/security-service';
import { AssetPermission } from '../asset-permission';

export interface IManageableAsset {
	id?: number;
	ownerId: number;
	permissionLevel: AssetPermission;
	reshareAllowed?: boolean;
}

export abstract class AbstractManageAssetService {

	protected abstract hasCreatePermission: () => boolean;
	protected abstract hasSharePermission: () => boolean;

	constructor(
		protected security: Security
	) {}

	canShare = (asset: IManageableAsset): boolean => {
		if (!this.hasSharePermission()) return false;
		if (this.isNew(asset)) return true;

		if (this.isOwner(asset)) {
			return true;
		} else {
			return this.hasCreatePermission()
				&& this.canReshareAsset(asset);
		}
	};

	isOwner = (asset: IManageableAsset): boolean => {
		return asset && asset.ownerId === this.security.getUser().userId;
	};

	private isNew = (asset: IManageableAsset): boolean => {
		return !asset || !asset.id;
	};

	private canEditAsset = (asset: IManageableAsset): boolean => {
		return asset && asset.permissionLevel === AssetPermission.EDIT;
	};

	private canReshareAsset = (asset: IManageableAsset): boolean => {
		return this.canEditAsset(asset) && asset.reshareAllowed;
	};
}
