import * as cloneDeep from 'lodash.clonedeep';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import ProjectSettingsService from '@cxstudio/services/data-services/project-settings.service';
import MetricSettingsService from '@cxstudio/metrics/metric-settings.service';
import { MetricTreeItem } from '@cxstudio/metrics/metric-tree-item';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { Inject, Injectable } from '@angular/core';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';


export interface ISortByOptions {
	standardMetrics: any[];
	predefinedMetrics: MetricTreeItem[];
	attributes: IReportAttribute[];
	metrics: MetricTreeItem[];
}

@Injectable({
	providedIn: 'root'
})
export class SortByOptionsService {
	constructor(
		@Inject('reportMetricService') private reportMetricService: ReportMetricService,
		@Inject('projectSettingsService') private projectSettingsService: ProjectSettingsService,
		@Inject('metricSettingsService')  private metricSettingsService: MetricSettingsService
	) {}

	getOptions = (project: AccountOrWorkspaceProject, cache?: CacheOptions): Promise<ISortByOptions> => {
		let projectOptionsPromise = PromiseUtils.wrap(this.projectSettingsService.getSettings(project, cache));
		let metricOptionsPromise = PromiseUtils.wrap(this.metricSettingsService.getSettings(project));

		let resultPromise = Promise.all([
			projectOptionsPromise,
			metricOptionsPromise
		]).then((result: any[]): ISortByOptions => {
			let standardMetrics = this.reportMetricService.getStandardMetrics();
			return {
				standardMetrics: cloneDeep(standardMetrics),
				predefinedMetrics: result[1].predefinedMetrics,
				attributes: result[0].attributes,
				metrics: result[1].metrics
			};
		});
		return resultPromise;
	};
}

app.service('sortByOptionsService', downgradeInjectable(SortByOptionsService));
