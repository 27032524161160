import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable, Inject } from '@angular/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { Logger } from '@cxstudio/services/logger.service';
import { Security } from '@cxstudio/auth/security-service';
import { SessionDialogComponent } from '@app/modules/authentication/session-dialog/session-dialog.component';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';


@Injectable({
	providedIn: 'root'
})
export class SessionDialogService {
	instance: NgbModalRef = null;

	constructor(
		@Inject('security') private security: Security,
		@Inject('logger') private logger: Logger,
		private cxDialogService: CxDialogService,
		) {
	}

	showSessionExpiredDialog = (): NgbModalRef => {
		if (!this.instance) {

			this.logger.logInternalSessionExpired();

			this.instance = this.cxDialogService.openDialog(SessionDialogComponent,
				{ username: this.security.getEmail() }, { class: 'system', keyboard: true, size: ModalSize.SMALL });

			this.instance.result.then(() => {
				this.instance = null; // clean on close
			}, () => {
				this.instance = null; // clean on close or dismiss
			});
		}
		return this.instance;
	};
}

app.service('sessionDialogService', downgradeInjectable(SessionDialogService));
