import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { MetricCustomFormatUtilsService } from '@app/modules/widget-visualizations/formatters/metric-custom-format-utils.service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import { HeatmapVisualizationsService } from '../constants/heatmap-visualizations.service';
import { MetricConstants } from '../constants/metric-constants.service';
import { KeyMetricListTypes } from './key-metric-list-types.constant';
import { IMapAlikeCommonSettings } from './map-alike-common-settings.factory';
import { RealDataPreviewWidget } from './real-data-preview-widget.class';

export class HeatmapDefinitionCtrl extends RealDataPreviewWidget {
	widgetType: WidgetType;
	constants;
	heatmapCommonMethods: IMapAlikeCommonSettings;

	constructor(
		protected readonly $scope,
		protected readonly metricConstants: MetricConstants,
		protected readonly realDataPreviewService: RealDataPreviewService,
		protected readonly reportSettingsService: ReportSettingsService,
		protected readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		protected readonly metricCustomFormatUtils: MetricCustomFormatUtilsService,
		protected readonly colorUtils: ColorUtils,
		private readonly $controller,
		private readonly HeatmapVisualizations: HeatmapVisualizationsService,
		private readonly MapAlikeCommonSettings,
		private readonly betaFeaturesService: BetaFeaturesService
	) {
		super(
			$scope,
			realDataPreviewService,
			metricConstants,
			reportSettingsService,
			defaultDataFormatterBuilder,
			metricCustomFormatUtils,
			colorUtils
		);
	}

	$onInit(): void {
		super.$onInit();
		this.widgetType = this.$scope.props.widgetType;

		this.$controller('AnalyticDefinitionSelectionController', {
			$scope: this.$scope,
			selectionConfiguration: {
				multiAttributeSelection: true
			},
			customCallback: {}
		});

		this.heatmapCommonMethods = new this.MapAlikeCommonSettings(this.$scope, WidgetVisualization.HEATMAP);

		this.$scope.$on('clearSettings', this.heatmapCommonMethods.initProps);
		this.$scope.$on('reloadSettings', this.heatmapCommonMethods.reloadSettings);

		this.$scope.staticData.ready = false;

		this.$scope.ui = this.$scope.ui || {};

		this.$scope.listTypes = KeyMetricListTypes;

		this.$scope.changePrimaryChartType = this.changePrimaryChartType;
		this.$scope.selectBoxSize = this.selectBoxSize;
		this.$scope.removeMetric = this.removeMetric;
		this.$scope.showNormalizeGroupsButton = this.showNormalizeGroupsButton;
		this.$scope.isForceNormalization = this.isForceNormalization;
		this.$scope.setCalculationProperty = this.setCalculationPropertyInternal;

		this.heatmapCommonMethods.initProps();
		this.heatmapCommonMethods.initialize();
	}

	setCalculationPropertyInternal = (name: string, node): any => {
		this.setCalculationProperty(name, node).then(() => {
			this.processAttributesAndMetrics();
			this.checkPreviewDataAndApplyVisualChanges(PreviewChartRefreshType.DATA_REQUEST);
		});
	};

	private processAttributesAndMetrics(): void {
		this.heatmapCommonMethods.processAttributesAndMetrics(['size', 'color', 'sortBy']);
		if (!this.$scope.showNormalizeGroupsButton()) {
			this.$scope.props.normalizeGroups = undefined;
		} else if (this.isForceNormalization()) {
			this.$scope.props.normalizeGroups = this.getForcedNormalizeGroupsValue();
		}
	}

	changePrimaryChartType = (subChartType): void => {
		let chartType = this.HeatmapVisualizations.get(subChartType);
		chartType.apply(this.$scope.visualProps);
		this.$scope.ui.showSortOptions = this.HeatmapVisualizations.isSortable(subChartType);
		if (!this.HeatmapVisualizations.isSortable(subChartType) &&
			this.$scope.visualProps?.attributeSelections?.sortBy) {
			delete this.$scope.visualProps.attributeSelections.sortBy;
		}

		this.processAttributesAndMetrics();
		this.checkPreviewDataAndApplyVisualChanges(PreviewChartRefreshType.UI_ONLY);
	};

	selectBoxSize = (node): void => {
		this.$scope.selectMetric(node).then(() => {
			this.heatmapCommonMethods.checkDisableLabels(node);
			this.checkPreviewDataAndApplyVisualChanges(PreviewChartRefreshType.DATA_REQUEST);
		});
	};

	removeMetric = (): void => {
		this.$scope.selectMetric(this.constants.VOLUME).then(() => {
			this.processAttributesAndMetrics();
			this.checkPreviewDataAndApplyVisualChanges();
		});
	};

	showNormalizeGroupsButton = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.VALUE_NORMALIZATION)
			&& this.$scope.props.selectedAttributes.length > 1
			&& !_.isUndefined(this.$scope.props.selectedAttributes[1])
			&& AnalyticMetricTypes.hasCustomGroups(this.$scope.props.selectedAttributes);
	};

	isForceNormalization = () => {
		return this.isForceDisabledNormalization() || this.isForceEnabledNormalization();
	};

	getForcedNormalizeGroupsValue = () => {
		if (this.isForceDisabledNormalization()) {
			return false;
		}
		return true;
	};

	isForceEnabledNormalization = () => {
		return AnalyticMetricTypes.isForcedGroupNormalization(this.$scope.props.selectedAttributes);
	};

	isForceDisabledNormalization = () => {
		return AnalyticMetricTypes.isForcedDisabledGroupNormalization(this.$scope.props.selectedAttributes);
	};
}
app.controller('CBHeatmapChartCtrl', HeatmapDefinitionCtrl);
