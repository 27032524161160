import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';
import { MasterAccountQualtricsStatus } from './master-account-qualtrics-status';

@Injectable({
	providedIn: 'root'
})
export class QualtricsStatusApiService {
	constructor( private cxHttp: CxHttpService ) {}

	getQualtricsStatuses = (): Promise<MasterAccountQualtricsStatus[]> => {
		let url = 'rest/system-admin/status/qualtrics';
		return this.cxHttp.get(url);
	};

	export = (search: IntegrationSatusSearch): Promise<void> => {
		let url = 'rest/system-admin/status/qualtrics/export';
		return this.cxHttp.post(url, search);
	};
}
