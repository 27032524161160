import {
	ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output
} from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { LocalEventBus } from '@app/core/local-event-bus.service';
import { SimpleWidgetBaseComponent } from '@app/modules/widget-container/simple-widgets/simple-widget-base';
import { BulkWidgetAction } from '@app/modules/widget-container/widget-menu/bulk-widget-menu/bulk-widget-menu.component';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { ExternalWidget } from '@cxstudio/dashboards/widgets/widget';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { Security } from '@cxstudio/auth/security-service';

@Component({
	selector: 'external-widget',
	templateUrl: './external-widget.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [LocalEventBus],
})
export class ExternalWidgetComponent extends SimpleWidgetBaseComponent implements OnInit {

	@Input() widget: ExternalWidget;
	@Input() widgetActions: IWidgetActions;
	@Input() layout: LayoutHelper;
	@Input() dashboardData: IDashboardData;
	@Output() bulkAction = new EventEmitter<BulkWidgetAction>();

	utils: WidgetUtils;
	trigger: boolean;

	WidgetAction = WidgetAction;

	constructor(
		readonly localEventBus: LocalEventBus,
		private readonly ref: ChangeDetectorRef,
		private readonly currentObjects: CurrentObjectsService,
		@Inject('security') private security: Security,
	) {
		super(localEventBus);
	}

	ngOnInit(): void {
		super.ngOnInit();
		this.addSubscription(this.currentObjects.getEditModeChange().subscribe(() => this.ref.markForCheck()));
	}

	protected redrawWidget(): void {
		this.utils = {
			widgetId: this.widget.id,
			widgetType: this.widget.properties.widgetType
		} as WidgetUtils;
		this.trigger = !this.trigger;
		this.ref.markForCheck();
	}

	edit(): void {
		if (this.editable()) {
			this.widgetActions.emit(WidgetAction.OPEN_SETTINGS);
		}
	}

	refresh(): void {
		if (this.editable()) {
			this.redrawWidget();
		}
	}

	duplicate(): void {
		if (this.editable()) {
			this.widgetActions.emit(WidgetAction.COPY);
		}
	}

	editMode(): boolean {
		return this.currentObjects.isEditMode();
	}

	editable(): boolean {
		return this.security.isQualtricsWidgetEmbeddingEnabled();
	}
}

app.directive('externalWidget', downgradeComponent({component: ExternalWidgetComponent}));
