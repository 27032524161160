import { MasterAccountPropertiesApiService } from '@app/modules/account-administration/api/master-account-properties-api.service';
import { MasterAccountDefaultPermissions } from '@app/modules/account-administration/license-permissions/master-account-default-permissions.class';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { BrowserInfo } from '@app/util/browser-info';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';
import Group from './groups/Group';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { RouteService } from '@cxstudio/services/route-service';

export class UserManagementComponent implements ng.IComponentController {
	readonly DASHBOARD_LINK = '/dashboards';
	groups: Group[];
	promises: any = {};
	selectedUsers = [];
	selectedGroups = [];
	tabs: any[] = [];
	permissions;
	masterAccountDefaultPermissions: MasterAccountDefaultPermissions;
	licenseTypes;
	activeTabIndex: number;

	constructor(
		private readonly $scope: ISimpleScope,
		private readonly $log: ng.ILogService,
		private readonly $location: ng.ILocationService,
		private readonly security: Security,
		private readonly authorization: Authorization,
		private readonly locale: ILocale,
		private readonly usersGroupsApiService,
		private readonly licenseApiService: LicenseApiService,
		private readonly masterAccountPropertiesApiService: MasterAccountPropertiesApiService,
		private readonly $routeParams,
		private readonly cbDialogService: CBDialogService,
		private readonly redirectService: RedirectService,
		private readonly routeService: RouteService,
		private readonly impersonateUserService: ImpersonateUserService
	) { }

	$onInit = () => {
		if (BrowserInfo.isTabletOrMobile()) {
			this.$location.path(this.DASHBOARD_LINK);
			return;
		}

		// if we're exiting impersonation mode, we don't care if the user being impersonated has access
		// bc the user doing the impersonation has access
		if (!this.impersonateUserService.isExiting()) {
			this.security.restrictPage(this.authorization.hasUserAdministrationAccess);
		}
		this.checkMasterAccountAccess();

		if (this.security.has('manage_users')) {
			this.tabs.push({
				heading: this.locale.getString('administration.users'),
				active: true,
				template: 'partials/user-administration/users/user-tab.html',
				class: 'br-user-management-users-tab',
				id: 'users'
			});
		}

		if (this.security.has('manage_groups') || this.security.isUserGroupOwner()) {
			this.tabs.push({
				heading: this.locale.getString('administration.groups'),
				active: !this.security.has('manage_users'),
				template: 'partials/user-administration/groups/group-tab.html',
				class: 'br-user-management-groups-tab',
				id: 'groups'
			});
		}

		if (this.hasCbEmployeesTab()) {
			this.tabs.push({
				heading: this.locale.getString('administration.externalUsers'),
				active: false,
				template: 'partials/user-administration/users/external-user-tab.html',
				class: 'br-user-management-users-tab br-external-users',
				id: 'external-users'
			});
		}

		this.processSelectedTab();
		this.$scope.$on('$routeUpdate', (event) => this.processSelectedTab());

		this.reloadPermissionList();
		this.reloadLicenseTypesList();
		this.checkRouteForExtendAccess();
	};

	selectTab = (selectedTab: any): void => {
		if (!this.$location.search().maId)
			this.$location.search({ tab: selectedTab.id });
	};

	reloadGroupList = () => {
		this.promises.loadingGroups = this.usersGroupsApiService.getMasterAccountGroups()
			.then((response) => {
				let data = response.data;
				this.$log.debug('groups :', data);
				this.groups = data;
			});
	};

	reloadPermissionList = () => {
		this.promises.loadingPermissions = this.usersGroupsApiService.getPermissions().then((data) => {
			this.$log.debug('permissions :', data);
			this.permissions = data;
		});

		this.masterAccountPropertiesApiService.getDefaultPermissions().then((data) => {
			this.masterAccountDefaultPermissions = data;
		});
	};

	reloadLicenseTypesList = () => {
		this.licenseApiService.getLicenseTypes().then((result) => {
			this.$log.debug('License Types :', result);
			this.licenseTypes = result;
		});
	};

	checkMasterAccountAccess = () => {
		const maIdParam = this.$location.search().maId
			? this.$location.search().maId
			: this.$routeParams.maId;
		if (!maIdParam) {
			return;
		}

		const targetMasterAccountId = parseInt(maIdParam, 10);
		if (!this.security.hasMasterAccountAccess(targetMasterAccountId)) {
			this.cbDialogService.notify(this.locale.getString('common.warning'), this.locale.getString('administration.noAccountAccess'));
			this.$location.path(this.DASHBOARD_LINK);
			return;
		}

		if (targetMasterAccountId !== this.security.getMasterAccountId()) {
			//need to redirect to master account passed in request
			this.redirectService.saveCurrentMA({accountId: targetMasterAccountId});
		}

	};

	checkRouteForExtendAccess = () => {
		if (this.$routeParams.action === 'extendAccess' && this.$routeParams.maId && this.$routeParams.userId) {
			let targetMasterAccountId = parseInt(this.$routeParams.maId, 10);
			if (targetMasterAccountId === this.security.getMasterAccountId() && !this.security.has('account_owner')) {
				this.cbDialogService.notify(this.locale.getString('common.warning'), this.locale.getString('administration.nonAccountOwnerAccess'));
				this.$location.path(this.DASHBOARD_LINK);
			}
		}
	};


	private processSelectedTab = (): void => {
		if (this.tabs.length > 0) {
			this.routeService.handleSelectedTab(this.tabs, (selectedTab, tabIndex) => {
				this.activeTabIndex = tabIndex;
				this.tabs[this.activeTabIndex].active = true;
			}, () => {
				this.activeTabIndex = 0;
				this.tabs[this.activeTabIndex].active = true;
			});
		}
	};

	private hasCbEmployeesTab = (): boolean => {
		return !this.security.isAdminOrgAccount()
			&& (this.security.isAnyAdmin() || this.security.has('account_owner'));
	};
}

app.component('userManagement', {
	controller: UserManagementComponent,
	template: `
<page-content-header [title]="'header.userAdministrationOption' | i18n" class="mb-24"></page-content-header>
<div class="col-xs-12">
	<div class="p-24 br-list-box tab-content">
		<uib-tabset active="$ctrl.activeTabIndex">
			<uib-tab
				ng-repeat="tab in $ctrl.tabs"
				heading="{{ tab.heading }}"
				active="tab.active"
				class="{{ tab.class }}"
				select="$ctrl.selectTab(tab)">
				<div ng-include="tab.template" class="row"></div>
			</uib-tab>
		</uib-tabset>
	</div>
</div>
`
});
