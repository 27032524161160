import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import * as _ from 'underscore';
import IAlertSubscriptionTemplate
	from '@cxstudio/alert-subscription-templates/types/alert-subscription-template';
import { CxLocaleService } from '@app/core';
import { ModelsService } from '@app/modules/project/model/models.service';
import { SimpleTopicSelection } from '@app/shared/components/tree-selection/simple-topic-selection';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { AssetPromise } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class ASTInclusionListUtilsService {
	constructor(
		private locale: CxLocaleService,
		private modelsService: ModelsService,
	) {
	}

	isPastItemLimit = (allItems, available, selected, itemLimit): boolean => {
		const selectedCount = (allItems || []).filter(item => item.selected).length;
		const highlightedCount = available.length;
		return (selectedCount + highlightedCount) > itemLimit;
	};

	availableAttributesLabelCallback = (allItems, available, selected, itemLimit): string => {
		return this.locale.getString('alertTemplates.availableAttributesText', {maxAttributes: itemLimit});
	};

	selectedAttributesLabelCallback = (allItems, available, selected, included): string => {
		return this.locale.getString('alertTemplates.selectedAttributesText', {attributes: included.length});
	};

	availableModelsLabelCallback = (allItems, available, selected, itemLimit): string => {
		return this.locale.getString('alertTemplates.availableModelsText', {maxModels: itemLimit});
	};

	selectedModelsLabelCallback = (allItems, available, selected, included): string => {
		return this.locale.getString('alertTemplates.selectedModelsText', {models: included.length});
	};

	getCurrentModelTree = (modelId: number, props: IProjectSelection, selectedModel?: any): Promise<SimpleTopicSelection> => {
		const promise: AssetPromise<ModelTree> = this.modelsService.getModelTree(props, modelId);

		return promise.then((modelTree: ModelTree) => {
			// show all nodes as not selected for newly created ASTs by default
			const topicSelection = new SimpleTopicSelection(modelTree.root, []);

			if (selectedModel) {
				// show all nodes as selected for existing ASTs
				const selectedIds: any[] = selectedModel.nodeIds === undefined ? topicSelection.getAllNodeIds() : [...selectedModel.nodeIds];
				topicSelection.setSelectedIds(selectedIds);
			}

			return topicSelection;
		});
	};

	getProjectSelection = (template: Partial<IAlertSubscriptionTemplate>): IProjectSelection => {
		return {
			contentProviderId: template.contentProviderId,
			accountId: template.accountId,
			projectId: template.projectId
		} as IProjectSelection;
	};
}

app.service('astInclusionListUtils', downgradeInjectable(ASTInclusionListUtilsService));
