import { Security } from '@cxstudio/auth/security-service';
import { CBDialogService } from './cb-dialog-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { RedirectService } from './redirect-service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { AppLoadingService } from '@cxstudio/app-loading';

/**
 * Service for impersonate user
 */

export class ImpersonateUserService {
	private isExitingImpersonateMode: boolean;

	constructor(
		private readonly cbDialogService: CBDialogService,
		private readonly locale: ILocale,
		private readonly $location: ng.ILocationService,
		private readonly security: Security,
		private readonly securityApiService,
		private readonly $window: ng.IWindowService,
		private readonly maPropertiesService: MAPropertiesService,
		private readonly redirectService: RedirectService,
		private readonly $rootScope: ng.IRootScopeService,
		private readonly appLoading: AppLoadingService) { }

	isImpersonateActive = (): boolean => this.security.isImpersonateMode();

	canImpersonateUser = (targetUser): boolean => {
		let email = targetUser.email || targetUser.userEmail;
		let isSelfProfile: boolean = this.security.isCurrentUser(email);
		let isImpersonateActive: boolean = this.isImpersonateActive();
		let isAccountOwnerAllowed: boolean = this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.ACCOUNT_OWNER_MANAGE_OBJECTS);
		let userHasPermissions: boolean = this.security.isAnyAdmin()
			|| (isAccountOwnerAllowed && this.security.has('account_owner'));

		return !isSelfProfile && !isImpersonateActive && userHasPermissions;
	};

	impersonateUser = (impersonatedUser): void => {
		// user list uses different property than dialog
		let email: string = impersonatedUser.email || impersonatedUser.userEmail;

		let name = (impersonatedUser.firstName && impersonatedUser.lastName)
			? `${impersonatedUser.firstName} ${impersonatedUser.lastName}`
			: email;


		// when this modal is converted to angular, some css in user-management.scss can go away
		let dlg = this.cbDialogService.confirm(
			this.locale.getString('administration.impersonateConfirmTitle'),
			this.locale.getString('administration.impersonateConfirmText', { name }),
			this.locale.getString('administration.impersonateConfirmYes'),
			this.locale.getString('administration.impersonateConfirmNo'), 'impersonate-modal');

		dlg.result.then(() => {
			this.appLoading.showAppSpinner();
			this.securityApiService.beginImpersonation(email).then((response) => {
				if (response.data.token) {
					this.security.setToken(response.data.token);
				}

				this.redirectService.goToDashboardList();
				this.$rootScope.$on('$locationChangeSuccess', () => {
					this.$window.location.reload();
				});
			});
		});
	};

	impersonateUserOff = (newPath: string = '/user-group-management'): void => {
		let dlg = this.cbDialogService.confirm(
			this.locale.getString('administration.impersonateOffConfirmTitle'),
			this.locale.getString('administration.impersonateOffConfirmText'),
			this.locale.getString('administration.impersonateConfirmYes'),
			this.locale.getString('administration.impersonateConfirmNo')
		);

		dlg.result.then(() => {
			this.isExitingImpersonateMode = true;
			this.appLoading.showAppSpinner();
			this.securityApiService.endImpersonation().then((response) => {
				if (response.data.token) {
					this.security.setToken(response.data.token);
				}

				if (newPath !== this.$location.path()) {
					this.$location.path(newPath);
					this.$rootScope.$on('$locationChangeSuccess', () => {
						this.completeImpersonationExit();
					});
				} else {
					this.completeImpersonationExit();
				}
			});
		});
	};

	private completeImpersonationExit(): void {
		this.$window.location.reload();

		// isExitingImpersonateMode doesnt need to be reset bc reload will clear it automatically
		// manual reset leads back to race condition
	}

	isExiting(): boolean {
		return this.isExitingImpersonateMode;
	}
}

app.service('impersonateUserService', ImpersonateUserService);
