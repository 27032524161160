import { Inject, Injectable } from '@angular/core';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { PeerReportType } from '@cxstudio/reports/providers/cb/constants/peer-report-types';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { DrillHelperService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { CxLocaleService } from '@app/core';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { PromiseUtils } from '@app/util/promise-utils';
import { IPromise } from 'angular';

@Injectable({
	providedIn: 'root'
})
export class HierarchyChildrenDrillService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly drillToHelper: DrillHelperService,
		@Inject('hierarchyService') private readonly hierarchyService: HierarchyService,
		@Inject('dashboardPersonalizationProvider') private readonly dashboardPersonalizationProvider: DashboardPersonalizationProvider
	) {}

	getOptions = (params: IDrillParams, point: DrillPoint, grouping: ReportGrouping): IDrillMenuOption => {
		return {
			group: IDrillMenuOptionGroup.drill,
			priority: 30,
			text: this.locale.getString('widget.children'),
			items: this.getDrillItems(params, point, grouping),
			name: 'children',
			searchBox: false,
			levels: 1,
			selected: true
		};
	};

	private readonly getDrillItems = (params: IDrillParams, point: DrillPoint, grouping: ReportGrouping) => {
		return (): IPromise<IDrillMenuOption[]> => {
			const drillToHelper = this.drillToHelper.create(params);
			const group = GroupIdentifierHelper.getGroup(grouping);

			const level = point[group.identifier + '_hierarchyNodeLevel'];

			const groupBy = {
				selectedLevel: level + 1,
				type: grouping.type,
				name: grouping.name,
				displayName: grouping.displayName,
				metricType: grouping.metricType,
				peerReportType: undefined
			};

			if (this.isPeerReport(params, grouping)) {
				groupBy.peerReportType = PeerReportType.INHERITED_REPORT;
			}

			point.groupBy = groupBy;
			return PromiseUtils.old(
				Promise.resolve(
					drillToHelper.getDrillTo(
						DrillWidgetType.DEFAULT,
						params.widget.properties.widgetType
					) as IDrillMenuOption[]
				)
			);
		};
	};

	private readonly isPeerReport = (params: IDrillParams, grouping: ReportGrouping): boolean => {
		const dashboardProperties = params.dashboardProperties;

		const dashboard = { properties: dashboardProperties };
		const personalization = this.dashboardPersonalizationProvider.getInstance(dashboard, null);
		return this.hierarchyService.isPeerReportGrouping(personalization, grouping);
	};
}

app.service('hierarchyChildrenDrill', downgradeInjectable(HierarchyChildrenDrillService));

