import * as cloneDeep from 'lodash.clonedeep';

import { Component, Inject, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { HomePage } from '@app/modules/home-page/home-page-common/entities/home-page';
import { HomePageApiService } from '@app/modules/home-page/home-page-management/home-page-api.service';
import { HomePageListActions } from '@app/modules/home-page/home-page-management/home-page-list-actions.service';
import { HomePageListMenu } from '@app/modules/home-page/home-page-management/home-page-list-menu.service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { NameService } from '@cxstudio/common/name-service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import { WidgetsLayout } from '@app/modules/home-page/home-page-common/entities/widgets-layout';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { DashboardListTab } from '@app/modules/dashboard-list/classes/dashboard-list-tab.class';
import { RecentDashboardsTab } from '@app/modules/dashboard-list/classes/recent-dashboards-tab.class';
import { FavoriteDashboardsTab } from '@app/modules/dashboard-list/classes/favorite-dashboards-tab.class';

@Component({
	selector: 'home-page-management',
	templateUrl: './home-page-management.component.html',
	providers: [HomePageListActions, HomePageListMenu],
})
export class HomePageManagementComponent implements OnInit {
	readonly DEFAULT_TABS: DashboardListTab[] = [
		{ pinned: true, name: RecentDashboardsTab.TAB_NAME },
		{ pinned: true, name: FavoriteDashboardsTab.TAB_NAME },
	];
	gridOptions: SlickgridOptions;
	gridType = GridTypes.HOME_PAGES;

	changedItems: any[];
	loading: Promise<any>;
	nameSearch: string;
	homePages: HomePage[];

	selectedHomePage: HomePage;

	constructor(
		private homePageApi: HomePageApiService,
		private actionsService: HomePageListActions,
		private contextMenuUtils: HomePageListMenu,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		@Inject('security') private security: Security,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: ContextMenuTree,
		@Inject('nameService') private nameService: NameService,
	) { }

	ngOnInit(): void {
		this.gridOptions = {
			onClick: (event, row) => this.onItemClick(event, row)
		};
		this.reloadHomePages();
		this.actionsService.onChange.subscribe(() => this.reloadHomePages());
	}

	private reloadHomePages(): void {
		this.loading = this.homePageApi.getHomePages().then(homePages => {
			this.homePages = homePages;
			_.each(this.homePages, (homePage, index) => {
				if (homePage.default) {
					homePage.sharingStatus = SharingStatus.PUBLIC;
				} else {
					homePage.rank = index;
				}
				if (!homePage.reportProperties.owner) homePage.reportProperties.owner = this.security.getEmail();
			});
			this.refreshGrid();
		});
	}

	updateLimitReached(): void {
		const ACTIVE_HOME_PAGES_LIMIT = 10;

		let countByActivePages = _.countBy(this.homePages, homePage => {
			return (homePage.enabled && !homePage.default) ? 'active' : 'inactive';
		});

		_.each(this.homePages, homePage => {
			if (!homePage.default) {
				homePage.activeLimitReached = countByActivePages['active'] >= ACTIVE_HOME_PAGES_LIMIT;
			}
		});
	}

	private refreshGrid(): void {
		this.updateLimitReached();
		this.changedItems = this.homePages;
	}

	createHomePage(): void {
		let homePage: HomePage = {
			name: this.nameService.uniqueName(this.locale.getString('homePage.homePageName'), this.homePages, 'name'),
			carousel: {
				tabs: cloneDeep(this.DEFAULT_TABS),
				enabled: true,
			},
			quickInsights: {
				enabled: true,
				owner: this.security.getEmail(),
				inheritProjectSetting: true
			},
			reportProperties: {
				owner: this.security.getEmail()
			},
			widgetsConfig: {
				enabled: false,
				layout: WidgetsLayout.TWO,
				widgets: []
			}
		} as HomePage;
		this.editHomePage(homePage);
	}

	editHomePage(homePage: HomePage) {
		this.loading = this.getFullHomePage(homePage).then(fullHomePage => {
			this.selectedHomePage = fullHomePage;
		});
	}

	private getFullHomePage(homePage: HomePage): Promise<HomePage> {
		let copy = cloneDeep(homePage) as HomePage;
		if (homePage.id) {
			return this.homePageApi.getConfiguredWidgets(homePage.id).then(widgets => {
				copy.widgetsConfig.widgets = widgets;
				return copy;
			});
		} else {
			return Promise.resolve(copy);
		}
	}

	private onItemClick(event, object: HomePage): void {
		if (this.gridUtils.isNameClick(event)) {
			this.editHomePage(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object, this), 'home-pages', 360);
		} else if (this.gridUtils.isToggleClick(event) && !object.default && !this.toggleActivationForbidden(object)) {
			this.actionsService.toggle(object);
		}
	}

	private toggleActivationForbidden(object: HomePage): boolean {
		return object.activeLimitReached && !object.enabled;
	}

	isEditing(): boolean {
		return !!this.selectedHomePage;
	}

	cancelEdit(): void {
		this.selectedHomePage = null;
	}

	saveHomePage(homePage: HomePage): void {
		if (!homePage.id) {
			this.actionsService.create(homePage);
		} else {
			this.actionsService.update(homePage);
		}
		this.cancelEdit();
	}
}

app.directive('homePageManagement', downgradeComponent({component: HomePageManagementComponent}));
