import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, Inject } from '@angular/core';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { CxLocaleService } from '@app/core';
import { FormattedTableColumnsOauthClientsService } from '@app/shared/components/table/formatted-table-columns-oauth-clients.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { QualtricsApiProviderEditDialogComponent, QualtricsApiProviderEditDialogInput } from './qualtrics-api-provider-edit-dialog.component';
import { QualtricsApiProvider } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-provider';
import { QualtricsApiProvidersApiService } from '@app/modules/system-administration/oauth/qualtrics-api-provider/qualtrics-api-providers-api.service';
import { ObjectUtils } from '@app/util/object-utils';

@Component({
	selector: 'qualtrics-api-providers',
	templateUrl: './qualtrics-api-providers.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class QualtricsApiProvidersComponent implements OnInit {
	loadingPromise: Promise<any>;
	apiProviders: QualtricsApiProvider[];
	rows: QualtricsApiProvider[];
	columns: Array<TableColumn<QualtricsApiProvider>>;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly formattedTableColumnsOauthClientsService: FormattedTableColumnsOauthClientsService,
		private readonly qualtricsApiProvidersApiService: QualtricsApiProvidersApiService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		@Inject('tableFormattersService') private readonly tableFormattersService: TableFormattersService
	) {
	}

	ngOnInit(): void {
		this.reloadApiProviders();
		this.columns = this.getColumns();
	}

	reloadApiProviders = () => {
		this.loadingPromise = this.qualtricsApiProvidersApiService.getApiProviders()
			.then(result => {
				this.rows = this.getRows(result);
				this.ref.markForCheck();
			});
	};

	getColumns = (): Array<TableColumn<QualtricsApiProvider>> => {
		let columns = [];
		columns.push(this.formattedTableColumnsOauthClientsService.getHamburgerColumn(this.onMenuClick));
		columns.push({
			name: 'aliasName',
			displayName: this.locale.getString('administration.oauthAliasName'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'linkedMasterAccounts',
			displayName: this.locale.getString('administration.oauthLinkedMasterAccounts'),
			formatter: (provider: QualtricsApiProvider) => provider.linkedMasterAccounts
				? provider.linkedMasterAccounts.map(ma => ma.accountName).join(', ')
				: '',
			width: 0.3
		});
		columns.push({
			name: 'clientId',
			displayName: this.locale.getString('administration.oauthClientId'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.6
		});
		return columns;
	};

	getRows = (clientDetailsList: QualtricsApiProvider[]): QualtricsApiProvider[] => {
		return _.sortBy(clientDetailsList, 'aiasName');
	};

	addProvider = (): void => {
		this.loadingPromise = this.openApiProviderEditDialog({} as QualtricsApiProvider)
			.then((clientDetailsData) => this.qualtricsApiProvidersApiService.createApiProvider(clientDetailsData))
			.then(() => this.reloadApiProviders());
	};

	editProvider = (apiProviderTableItem: QualtricsApiProvider): void => {
		this.loadingPromise = this.openApiProviderEditDialog(apiProviderTableItem)
			.then((clientDetailsData) => this.qualtricsApiProvidersApiService.updateApiProvider(clientDetailsData))
			.then(() => this.reloadApiProviders());
	};

	removeProvider = (apiProviderTableItem: QualtricsApiProvider): void => {
		this.loadingPromise = this.qualtricsApiProvidersApiService.removeApiProvider(apiProviderTableItem)
			.then(() => this.reloadApiProviders());
	};

	protected openApiProviderEditDialog(apiProvider: QualtricsApiProvider): Promise<any> {
		const input: QualtricsApiProviderEditDialogInput = { apiProvider };
		let options: ModalOptions = { size: ModalSize.LARGE };
		return this.cxDialogService.openDialog(QualtricsApiProviderEditDialogComponent, input, options).result;
	}

	protected onMenuClick = (object: QualtricsApiProvider, event: any): void => {
		this.contextMenuTree.showObjectListMenu(event, object, this.getContextMenu(object), 'qualtrics-api-providers', 360);
	};

	private getContextMenu(object: QualtricsApiProvider): Array<ContextMenuItem<QualtricsApiProvider>> {
		const OPTIONS = this.getOptions();

		let menuOptions: Array<ContextMenuItem<QualtricsApiProvider>> = [
			OPTIONS.EDIT
		];
		let deleteOption = OPTIONS.DELETE;
		if (!_.isEmpty(object.linkedMasterAccounts)) {
			deleteOption.disabled = true;
			deleteOption.disabledMessage = this.locale.getString('administration.providerUsedInMasterAccount');
		}
		menuOptions.push(deleteOption);

		return menuOptions;
	}

	private getOptions(): {[name: string]: ContextMenuItem<QualtricsApiProvider>} {
		return {
			EDIT: {
				text: this.locale.getString('common.edit'),
				name: 'edit',
				func: (provider: QualtricsApiProvider) => this.editProvider(provider)
			},
			DELETE: {
				name: 'delete',
				text: this.locale.getString('common.delete'),
				func: (provider: QualtricsApiProvider) => this.removeProvider(provider)
			}
		};
	}
}
