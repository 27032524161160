import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';

export class TemplateCommonService {
	creatingDashboard: ng.IPromise<any>;
	constructor(
		private dashboardListService: DashboardListService,
		private dashboardService: DashboardService,
		private $location: ng.ILocationService,
		private readonly downgradeDialogService: DowngradeDialogService,
	) {}

	// hide the modal button. only for blank template, as of now.
	hideTemplateModalButton = (template: { id: number | string }) => {
		return ( template.id === 1000 );
	};

	createDashboard = (template: { id?: number | string; name?: string }) => {
		if (!template || !template.id) return;

		let selectedFolder: any;
		let selectedFolderId = this.$location.search().selectedFolderId;
		if (selectedFolderId) {
			selectedFolder = _.findWhere(this.dashboardListService.getCurrentDashboardsList(), {id: parseInt(selectedFolderId, 10)});
		}

		let dashboardName = template.id === 1000 ? undefined : template.name;
		this.creatingDashboard = this.dashboardService.createDashboard(dashboardName, template.id, selectedFolder).then(
			dashboard => {
				this.$location.path(`/home/${dashboard.id}`).search('edit', true);
			},
			_.noop
		);

		return this.creatingDashboard;
	};

	openTemplateModal = (template) => {
		this.downgradeDialogService.openTemplateModalDialog(template).result
			.then(this.createDashboard)
			.catch(() => {});
	};
}
app.service('templateCommonService', TemplateCommonService);
