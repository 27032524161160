import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { CxFormUtils } from '@app/modules/cx-form/utils/form-utils';
import { ListOption } from '@app/shared/components/forms/list-option';
import { LicenseTypeItem } from '@cxstudio/user-administration/users/entities/license-type-item';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';


@Component({
	selector: 'master-account-licensing',
	templateUrl: './master-account-licensing.component.html',
	styles: [`.rounded-grouping-label { top: -20px; }`],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterAccountLicensingComponent extends SelfCleaningComponent {

	@Input() licenses: LicenseTypeItem[];

	licensing: UntypedFormGroup;

	contractTimezones: ListOption<string>[];

	constructor(private locale: CxLocaleService,
		readonly formUtils: CxFormUtils,
		private fb: UntypedFormBuilder,
		) {
		super();
		this.contractTimezones = [
			{ value: 'EST', name: this.locale.getString('administration.timezoneEST') },
			{ value: 'GMT', name: this.locale.getString('administration.timezoneGMT') }
		];
		this.licensing = this.fb.group({
			licensingEnabled: [ true ],
			licenseEmails: [ true ],
			contractTimezone: [ 'EST'],
			contractCredits: [{ value: 0, disabled: true }],
			contractLicenses: this.fb.group({
				1: [{ value: 0, disabled: true }],
				2: [{ value: 0, disabled: true }],
				4: [{ value: 0, disabled: true }],
				5: [{ value: 0, disabled: true }]
			})
		});

		this.addSubscription(this.licensing.get('licensingEnabled').valueChanges.subscribe((value) => {
			if (value) {
				this.formUtils.enableFields(this.licenseEmails, this.contractTimezone);
			} else {
				this.formUtils.disableFields(this.licenseEmails, this.contractTimezone);
			}
		}));
	}

	getGroup(): UntypedFormGroup {
		return this.licensing;
	}

	get licenseEmails(): UntypedFormControl {
		return this.licensing.controls.licenseEmails as UntypedFormControl;
	}

	get contractTimezone(): UntypedFormControl {
		return this.licensing.controls.contractTimezone as UntypedFormControl;
	}
}
