import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import WorkspaceModelsApiService from '@app/modules/project/model/workspace-models-api.service';
import ModelsApiService from './models-api.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { ModelUtils } from '@app/modules/project/model/model-utils';

@Injectable({
	providedIn: 'root'
})
export class ModelsService {
	constructor(
		private modelsApi: ModelsApiService,
		private workspaceModelsApi: WorkspaceModelsApiService,
	) {}

	getModels(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IReportModel[]> {
		return this.getModelsFromApi(project, false, cache);
	}

	getModelsWithHierarchies(project: AccountOrWorkspaceProject, cache = CacheOptions.CACHED): AssetPromise<IReportModel[]> {
		return this.getModelsFromApi(project, true, cache);
	}

	private getModelsFromApi(
		project: AccountOrWorkspaceProject, includeHierarchies: boolean, cache: CacheOptions
	): AssetPromise<IReportModel[]> {
		return ProjectAssetsHelper.getAssetsForProject<IReportModel[]>(
			project,
			(accountProject) => this.modelsApi.getModels(accountProject, includeHierarchies, cache),
			(workspaceProject) => this.workspaceModelsApi.getModels(workspaceProject, includeHierarchies, cache),
			(projectId) => []
		);
	}

	getModelTree(
		project: AccountOrWorkspaceProject,
		modelId: number,
	): AssetPromise<ModelTree> {
		return ProjectAssetsHelper.getAssetsForProject<ModelTree>(
			project,
			(accountProject) => this.modelsApi.getModelTree(accountProject, modelId),
			(workspaceProject) => this.workspaceModelsApi.getModelTree(workspaceProject, modelId),
			(projectId) => undefined
		).then(model => ModelUtils.populateNodesPath(model)) as AssetPromise<ModelTree>;
	}

	getModelNode = (
		project: AccountOrWorkspaceProject,
		nodeId: number,
	): AssetPromise<ModelTreeNode> => {
		return ProjectAssetsHelper.getAssetsForProject<ModelTreeNode>(
			project,
			(accountProject) => this.modelsApi.getModelNode(accountProject, nodeId),
			(workspaceProject) => this.workspaceModelsApi.getModelNode(workspaceProject, nodeId),
			(projectId) => undefined
		);
	};
}

app.service('modelsService', downgradeInjectable(ModelsService));
