
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';


interface Tab {
	index: string;
	heading: string;
	class: string;
	template: string;
}

export class SystemAdministrationComponent implements ng.IComponentController {

	componentUi: {
		tabs: Tab[];
		activeTab?: string;
	} =  {tabs: []};

	masterAccounts;
	visibleAccounts;
	loadingMasterAccounts: ng.IPromise<any>;

	constructor(
		private locale: ILocale,
		private security: Security,
		private authorization: Authorization,
		private systemAdminApiService: SystemAdminApiService,
		private $log: ng.ILogService
	) { }

	$onInit = () => {
		this.populateTabs();

		this.security.restrictPage(this.authorization.hasSystemAccess);
		this.reloadMasterAccountList();
	};

	private populateTabs(): void {
		let tabs: Tab[] = [{
			index: 'master-accounts',
			heading: this.locale.getString('administration.masterAccountsTab'),
			class: 'br-master-accounts-tab',
			template: 'partials/system-administration/master-accounts-tab.html'
		}, {
			index: 'external-applications',
			heading: this.locale.getString('administration.externalApplications'),
			class: 'br-external-applications-tab',
			template: 'partials/system-administration/external-applications.html'
		}, {
			index: 'platform',
			heading: this.locale.getString('administration.platformTab'),
			class: 'br-platform-tab',
			template: 'partials/system-administration/platform-tab.html'
		}, {
			index: 'users',
			heading: this.locale.getString('administration.users'),
			class: 'br-users-tab',
			template: 'partials/system-administration/admin-user-tab.html'
		}, {
			index: 'status',
			heading: this.locale.getString('administration.statusTab'),
			class: 'br-listener-status-tab',
			template: 'partials/system-administration/status/status-tab.html'
		}, {
			index: 'upgrade',
			heading: this.locale.getString('upgrade.applicationUpgrade'),
			class: 'br-upgrade-tab',
			template: 'partials/system-administration/upgrade.html'
		}, {
			index: 'reports',
			heading: this.locale.getString('administration.reports'),
			class: 'br-reports-tab',
			template: 'partials/system-administration/reports/reports-tab.html'
		}, {
			index: 'beta-features',
			heading: this.locale.getString('administration.betaFeatures'),
			class: 'br-beta-features-tab',
			template: 'partials/system-administration/system-administration-beta-features.html'
		}, {
			index: 'oauth',
			heading: this.locale.getString('administration.oauthSettingsTab'),
			class: 'br-listener-oauth-settings-tab',
			template: 'partials/system-administration/oauth/oauth-settings-tab.html'
		}, {
			index: 'saml',
			heading: this.locale.getString('administration.samlSettingsTab'),
			class: 'br-listener-saml-settings-tab',
			template: 'partials/system-administration/saml/saml-settings-tab.html'
		}];

		this.componentUi.tabs = tabs;
		this.componentUi.activeTab = this.componentUi.tabs[0].index;
	}

	reloadMasterAccountList = (): void => {
		this.loadingMasterAccounts = this.systemAdminApiService.getAllMasterAccounts()
			.then(response => {
				this.$log.debug('Master Accounts :', response.data);
				this.masterAccounts = response.data;
				this.masterAccounts.map(this.security.applyAccountTagRestrictions);
				this.visibleAccounts = this.masterAccounts.filter(masterAccount => !masterAccount.restricted);
			});
	};
}

app.component('systemAdministration', {
	controller: SystemAdministrationComponent,
	template: `
<page-content-header [title]="'administration.systemAdministrationTitle' | i18n" class="mb-24"></page-content-header>
<div class="col-xs-12" >
	<div class="br-list-box tab-content" cg-busy="$ctrl.loadingMasterAccounts">
		<uib-tabset active="$ctrl.componentUi.activeTab">
			<uib-tab ng-repeat="tab in $ctrl.componentUi.tabs" index="tab.index">
				<uib-tab-heading ng-class="tab.class">
						<span>{{ tab.heading }}</span>
				</uib-tab-heading>
				<div ng-if="$ctrl.componentUi.activeTab === tab.index" ng-include="tab.template"></div>
			</uib-tab>
		</uib-tabset>
	</div>
</div>`
});
