import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class CxHttpHandlers {
	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {}

	invalidateCacheHandler = (cacheName: Caches): <T>(response: T) => T => {
		return <T> (response: T): T => {
			this.cxCachedHttpService.cache(cacheName).invalidate();
			return response;
		};
	};
}
