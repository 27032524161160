import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { ReportFiltersApiService } from '@app/modules/filter/services/report-filters-api.service';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { PredefinedFiltersService } from '@cxstudio/metrics/predefined/predefined-filters.service';

@Injectable({
	providedIn: 'root'
})
export class ReportFiltersService {
	constructor(
		private readonly reportFiltersApi: ReportFiltersApiService,
		private readonly projectAssetsHelper: ProjectAssetsHelper,
		private readonly filtersService: FiltersService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		@Inject('predefinedFiltersService') private readonly predefinedFiltersService: PredefinedFiltersService,
	) {}

	getDashboardFiltersWithFolders(dashboard: Dashboard): Promise<IFilter[]> {
		return this.filtersService.getFiltersWithFolders(this.reportAssetUtilsService.getDashboardProject(dashboard));
	}

	getDashboardPlatformFilters(dashboard: Dashboard): Promise<IPlatformFilter[]> {
		return this.projectAssetsHelper.getDashboardProjectData<IPlatformFilter[]>(
			dashboard,
			(project) => this.filtersService.getPlatformFilters(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				viewParams => this.reportFiltersApi.getPlatformFilters(viewParams),
				() => [])
		);
	}

	getWidgetPlatformFilters(widget: Widget): Promise<IPlatformFilter[]> {
		return this.projectAssetsHelper.getWidgetProjectData<IPlatformFilter[]>(
			widget,
			(project) => this.filtersService.getPlatformFilters(project),
			(requestParams) => ProjectAssetsHelper.getViewModeProjectData(
				requestParams,
				(viewParams) => this.reportFiltersApi.getPlatformFilters(viewParams),
				() => [])
		);
	}

	getDashboardStudioFilters(dashboard: Dashboard): Promise<IFilter[]> {
		return this.projectAssetsHelper.getDashboardProjectData<IFilter[]>(
			dashboard,
			(project) => this.filtersService.getStudioFilters(project),
			(requestParams) => this.reportFiltersApi.getStudioFilters(requestParams)
		);
	}

	//check specially studio admin filters
	getWidgetStudioFilters(widget: Widget): Promise<IFilter[]> {
		return this.projectAssetsHelper.getWidgetProjectData<IFilter[]>(
			widget,
			(project) => this.filtersService.getStudioFilters(project),
			(requestParams) => this.reportFiltersApi.getStudioFilters(requestParams)
		);
	}

	getDashboardDateFilters = (dashboard: Dashboard): Promise<IFilter[]> => {
		let project = this.reportAssetUtilsService.getDashboardProject(dashboard);
		return this.filtersService.getStudioDateFilters(project);
	};

	getWidgetDateFilters = (widget: Widget): Promise<IFilter[]> => {
		let project = this.reportAssetUtilsService.getWidgetProject(widget);
		return this.filtersService.getStudioDateFilters(project);
	};

	getWidgetPropertiesDateFilters = (widgetProperties: WidgetProperties): Promise<IFilter[]> => {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(widgetProperties);
		return this.filtersService.getStudioDateFilters(project);
	};
}

app.service('reportFiltersService', downgradeInjectable(ReportFiltersService));
