import { ReferenceLinesLabelsUtils } from './reference-lines-labels-utils.service';
import ReferenceLine from './reference-line';
import PlotLineAxis from '../plot-lines/plot-line-axis';
import { ReferenceLineIndex } from './reference-line-index.constants';
import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class MetricReferenceLinesUtils {

	constructor(
		private readonly referenceLinesLabelsUtils: ReferenceLinesLabelsUtils
	) {}

	buildMetricReferenceLine = (refLine: ReferenceLine, value: number): Highcharts.AxisPlotLinesOptions => {
		return this.transformReferenceLine(refLine, null, value);
	};

	buildMetricReferenceLabelLine = (refLine: ReferenceLine, isHorizontalBar: boolean,
			isScatter: boolean, value: number): Highcharts.AxisPlotLinesOptions => {
		let labelLine: ReferenceLine = this.referenceLinesLabelsUtils.createLabelLine(refLine) as ReferenceLine;
		let label: Highcharts.AxisPlotLinesLabelOptions = this.referenceLinesLabelsUtils.getMetricRefLineLabel(
			refLine, isHorizontalBar, isScatter);
		return this.transformReferenceLine(labelLine, label, value);
	};

	private transformReferenceLine(refLine: ReferenceLine, label: Highcharts.AxisPlotLinesLabelOptions,
		newRefLineValue: number): Highcharts.AxisPlotLinesOptions {

		return {
			label,
			color: refLine.color,
			dashStyle: refLine.type || (refLine as any).selectedBaseLineStyle.type, // old format
			value: newRefLineValue,
			width: refLine.width || (refLine as any).selectedPlotLineWidthNumber,
			zIndex: this.getReferenceLineIndex(refLine.labelLine)
		};
	}

	private getReferenceLineIndex = (labelLine: boolean): number => {
		if (labelLine) {
			return ReferenceLineIndex.METRIC_LINE_LABEL;
		}
		return ReferenceLineIndex.METRIC_LINE;
	};

	getAxis = (chartOptions: Highcharts.Options, axisType: PlotLineAxis, isScatter: boolean): Highcharts.AxisOptions => {
		if (axisType === PlotLineAxis.primary)
			return chartOptions.yAxis[0];
		else return (isScatter ? chartOptions.xAxis : chartOptions.yAxis[1]);
	};

}
