const SENTIMENT = [
	'q-icon-thumb-down',
	'q-icon-thumb-down',
	'q-icon-minus-2',
	'q-icon-thumb-up',
	'q-icon-thumb-up'
];

const ML_SENTIMENT = [
	'q-icon-sentiment-v2-very-negative',
	'q-icon-sentiment-v2-negative',
	'q-icon-sentiment-v2-mixed',
	'q-icon-sentiment-v2-positive',
	'q-icon-sentiment-v2-very-positive'
];

const STRONGLY_NEGATIVE_SENTIMENT_INDEX = 0;
const MIDDLE_SENTIMENT_INDEX = 2;
const STRONGLY_POSITIVE_SENTIMENT_INDEX = 4;

export class PredefinedMetricIcons {
	static readonly NUMERIC_BREAKDOWN = {
		emotion: 'q-icon-intensity'
	};

	static readonly EASE_SCORE = [
		'q-icon-effort-negative', //very hard
		'q-icon-effort-negative', //hard
		'q-icon-effort', //neutral
		'q-icon-effort-positive', //easy
		'q-icon-effort-positive' //very easy
	];

	static getStronglyNegativeSentimentIcon(isMlSentimentEnabled: boolean): string {
		const sentimentIcons = PredefinedMetricIcons.getSentimentIcons(isMlSentimentEnabled);
		return sentimentIcons[STRONGLY_NEGATIVE_SENTIMENT_INDEX];
	}

	static getMiddleSentimentIcon(isMlSentimentEnabled: boolean): string {
		const sentimentIcons = PredefinedMetricIcons.getSentimentIcons(isMlSentimentEnabled);
		return sentimentIcons[MIDDLE_SENTIMENT_INDEX];
	}

	static getStronglyPositiveSentimentIcon(isMlSentimentEnabled: boolean): string {
		const sentimentIcons = PredefinedMetricIcons.getSentimentIcons(isMlSentimentEnabled);
		return sentimentIcons[STRONGLY_POSITIVE_SENTIMENT_INDEX];
	}

	/**
	 * Returns the icon used to represent sentiment in Studio
	 */
	static getSentimentSymbolIcon(isMlSentimentEnabled: boolean): string {
		return PredefinedMetricIcons.getStronglyPositiveSentimentIcon(isMlSentimentEnabled);
	}

	static getSentimentIcons(isMlSentimentEnabled?: boolean): string[] {
		if (isMlSentimentEnabled) {
			return ML_SENTIMENT;
		} else {
			return SENTIMENT;
		}
	}
}
