import { CxLocaleService } from '@app/core/cx-locale.service';
import * as cloneDeep from 'lodash.clonedeep';
import { Inject, Injectable } from '@angular/core';


export enum OAuthAuthorityRole {
	DESIGNER_ADMIN = 'DESIGNER_ADMIN',
	ROLE_AUTH_ADMIN = 'ROLE_AUTH_ADMIN'
}

export interface OAuthAuthority {
	role: OAuthAuthorityRole;
	displayName: string;
}

@Injectable({
	providedIn: 'root'
})
export class OAuthAuthorityRoles {
	roles: OAuthAuthority[] = [];

	constructor(locale: CxLocaleService) {
		this.roles = [{
			role: OAuthAuthorityRole.DESIGNER_ADMIN,
			displayName: locale.getString('administration.oauthDesignerAdmin')
		}, {
			role: OAuthAuthorityRole.ROLE_AUTH_ADMIN,
			displayName: locale.getString('administration.oauthAdminRole')
		}];
	}

	getRoles = () => {
		return cloneDeep(this.roles);
	};

	get = (role: OAuthAuthorityRole): OAuthAuthority => {
		return _.findWhere(this.roles, { role });
	};
}
