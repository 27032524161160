import { Component, OnInit, ChangeDetectionStrategy, Inject, Output, EventEmitter, SimpleChanges, OnChanges, OnDestroy } from '@angular/core';
import { Model } from '@cxstudio/reports/entities/model';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { ScorecardModelContext, IScorecardTreeNode } from '@cxstudio/projects/scorecards/scorecard-model-context';
import { ITreeSelection, TreeSelectionBuilder, TreeSelectionStrategy } from '@app/shared/components/tree-selection/tree-selection';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ScorecardEditorService } from '@app/modules/project/scorecard/editor/scorecard-editor.service';
import { Subscription } from 'rxjs';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { CxWizardComponent } from '@app/modules/wizard/cx-wizard/cx-wizard.component';

@Component({
	selector: 'scorecard-nodes-step',
	templateUrl: './scorecard-nodes-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorecardNodesStepComponent implements OnInit, OnChanges, OnDestroy {

	@Input() isNew: boolean;
	@Input() props: IProjectSelection;
	@Input() scorecard: Scorecard;
	@Input() models: Model[];
	@Input() validationDeferred: JQueryDeferred<void>;
	@Input() driverTrigger: boolean;

	@Output() onLoading = new EventEmitter<Promise<void>>();

	private otherScorecardsPromise;
	private topicPromise;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly scorecardEditorService: ScorecardEditorService,
		private readonly wizardStep: CxWizardStepComponent,
		private readonly wizard: CxWizardComponent,
		@Inject('scorecardsApiService') private readonly scorecardsApiService: ScorecardsApiService
	) {}

	loading;
	loaded: boolean;
	modelContext: ScorecardModelContext;

	selectEverythingText: string;
	selectNoneText: string;

	private otherScorecards: Scorecard[];
	private modelContext$: Subscription;


	errors: {
		nameExistsError: boolean;
		alreadyUsedTopicsErrorProcessed: boolean;
		topicsAlreadyUsedError: string;
		nameIsBlankError: boolean;
		noModelsError: boolean;
		noTopicSelectedError: boolean;
	};

	ngOnInit(): void {
		this.selectEverythingText = this.locale.getString('common.selectAll');
		this.selectNoneText = this.locale.getString('common.deselectAll');

		this.errors = {
			nameExistsError: false,
			alreadyUsedTopicsErrorProcessed: false,
			topicsAlreadyUsedError: undefined,
			nameIsBlankError: false,
			noModelsError: false,
			noTopicSelectedError: false
		};

		this.modelContext = this.scorecardEditorService.getModelContext();
		this.modelContext$ = this.scorecardEditorService.getModelContextChangeObservable()
				.subscribe((context) => this.modelContext = context);

		this.loading = this.loadStep();
		this.scorecardEditorService.setLoading(this.loading);
	}

	private loadStep = () => {
		let promise = this.scorecard.modelId
			? this.loadTopics()
			: this.loadOtherScorecards();

		return promise.then(() => {
			this.loaded = true;
			this.validate();
		});
	};

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.validationDeferred?.previousValue !== changes.validationDeferred?.currentValue) {
			this.loadOtherScorecards().then(() => {
				this.scorecardEditorService.populateModelTree(this.modelContext.modelTree);
				this.validate();
				this.validationDeferred.resolve();
			}, () => this.validationDeferred.reject());
		}
	}

	ngOnDestroy(): void {
		if (this.modelContext$) this.modelContext$.unsubscribe();
	}

	changeHandler = (): void => {
		let valid = !this.errors.nameIsBlankError
			&& !this.errors.noModelsError
			&& !this.errors.noTopicSelectedError
			&& !this.errors.nameExistsError;

		this.wizardStep.setValid(valid);
	};

	private validateName = (): void => {
		this.errors.nameIsBlankError = !this.scorecard.name;
		this.errors.nameExistsError = !!this.otherScorecards.find(item => (item.name === this.scorecard.name));
	};

	private validateModels = (): void => {
		this.errors.noModelsError = _.size(this.models) === 0;
	};

	private validateTopics = (): void => {
		this.errors.noTopicSelectedError = !this.modelContext || !this.modelContext.selection
			|| _.isEmpty(this.modelContext.scorecardTopics)
			|| (this.modelContext.selection.strategy === TreeSelectionStrategy.NONE);
		this.checkForExistingTopics();
	};

	private checkForExistingTopics = (): void => {
		if (!this.errors.alreadyUsedTopicsErrorProcessed && this.modelContext?.otherScorecardTopics) {
			let errorTopics = this.modelContext?.otherScorecardTopics;

			if (errorTopics.length) {
				this.errors.topicsAlreadyUsedError = this.getTopicsAlreadyUsedError(errorTopics);
				this.wizard.activateStep(this.wizardStep);
			}

			this.errors.alreadyUsedTopicsErrorProcessed = true;
		}
	};

	validate = (): void => {
		this.validateName();
		this.validateModels();
		this.validateTopics();
		this.changeHandler();
	};

	private getTopicsAlreadyUsedError = (entries: any[]): string => {
		if (isEmpty(entries)) {
			return null;
		}
		let topics = _.map(entries, (entry) => entry.nodeName).join(', ');
		return this.locale.getString('scorecards.topicsAlreadyUsedError', { topics });
	};

	nameChangeHandler = (): void => {
		this.validateName();
		this.changeHandler();
	};

	isModelDisabled = (model: Model): boolean => {
		return !model.classified;
	};

	getModelDisabledTooltip = (): string => {
		return this.locale.getString('scorecards.modelIsNotClassified');
	};

	modelChangeHandler  = (model: Model): void => {
		this.scorecard.modelId = model.id;
		this.scorecardEditorService.populateModelContext(model);

		this.topicPromise = null;
		this.otherScorecardsPromise = null;
		if (this.modelContext) {
			this.modelContext.otherScorecardTopics = null;
		}

		this.changeHandler();
	};

	onSelectionChanged = (treeSelection: ITreeSelection): void => {
		this.modelContext.selection = treeSelection;
		this.scorecardEditorService.updateScoringCriteria();
		this.validateTopics();
		this.changeHandler();
	};

	loadOtherScorecards = (): ng.IPromise<void> => {
		if (!this.otherScorecardsPromise) {
			this.otherScorecardsPromise = (this.scorecardsApiService.getScorecards(
				this.props.contentProviderId, this.props.accountId, this.props.projectId
			) as unknown as Promise<Scorecard[]>).then(result => {
				return _.filter(result, item => (item.id !== this.scorecard.id));
			}).then(otherScorecards => {
				this.otherScorecards = otherScorecards;
				this.scorecardEditorService.populateTopicsInUse(otherScorecards);
			});
		}

		return this.otherScorecardsPromise;
	};

	itemClassFormatter = (scoringEntry: ScorecardTopic): string => {
		return this.modelContext.driverModel && this.modelContext.driverModel[scoringEntry.idPath]
			? 'q-icon-warning'
			: '';
	};

	convertNodes = (node: any, children: any): any => {
		return (children || []).map(child => {
			return this.toNodeObject(child);
		});
	};

	private toNodeObject = (node: any): any => {
		return {
			id: node.id,
			name: node.name,
			path: node.path,
			idPath: node.idPath,
			modelId: node.modelId
		};
	};

	loadTopics = (): ng.IPromise<any> => {
		if (!this.topicPromise) {
			this.topicPromise = this.loadOtherScorecards()
				.then(() => this.scorecardsApiService.getModelTree(
					this.props.contentProviderId, this.props.accountId, this.props.projectId, this.scorecard.modelId))
				.then(result => {
					if (isEmpty(result)) {
						return [];
					}
					this.modelContext.modelTree = result.root;
					this.modelContext.selection = this.modelContext.selection || this.getSelectionFromScorecard();
					this.scorecardEditorService.populateModelTree(this.modelContext.modelTree);
					this.scorecardEditorService.updateScoringCriteria();
					this.validateTopics();
					this.changeHandler();
					return result.root.children;
			});
		}

		return this.topicPromise;
	};

	getNodeTooltip = (node: IScorecardTreeNode): string => {
		return this.scorecardEditorService.getNodeTooltip(node);
	};

	isTopicInUsedSubtree = (node: any): boolean => {
		return this.scorecardEditorService.isTopicInUsedSubtree(node);
	};

	private getSelectionFromScorecard = (): any => {
		if (this.isNew) {
			return TreeSelectionBuilder.everything();
		} else {
			const selectedNodes = [];
			this.findMatchingNodes(this.modelContext.scorecardTopics, this.modelContext.modelTree, selectedNodes);
			return TreeSelectionBuilder.subset(selectedNodes);
		}
	};

	private findMatchingNodes = (scorecardTopics: ScorecardTopic[], node: any, accumulator: any[]): any => {
		if (!node) {
			return;
		}
		const matchesId = _.some(scorecardTopics, (topic: ScorecardTopic) => {
			return topic.nodeId === node.id;
		});
		if (matchesId) {
			return accumulator.push(this.toNodeObject(node));
		}

		return _.forEach(node.children, (child: any) => {
			return this.findMatchingNodes(scorecardTopics, child, accumulator);
		});
	};
}

app.directive('scorecardNodesStep', downgradeComponent({component: ScorecardNodesStepComponent}));
