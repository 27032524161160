import ILocale from '@cxstudio/interfaces/locale-interface';

export class NameService {

	constructor(private locale: ILocale) {}

	/* Generates new unique name, based on baseName. */	

	uniqueName = (baseName: string, items: any[], compareField: string, 
		withBraces: boolean = false, includeNumber: boolean = false): string => {
		let regexp: RegExp = withBraces
			? new RegExp(this.escapeName(baseName) + '\\(([0-9]+)\\)')
			: new RegExp(this.escapeName(baseName) + '\\s?([0-9]+)');

		let analyzeResult = this.analyzeArray(baseName, items, compareField, regexp);
		if (!analyzeResult.baseNameExists && !includeNumber) {
			return baseName;
		}
		let uniqueNumber: number = this.findFirstUniqueNumber(analyzeResult);

		if (withBraces) {
			return `${baseName}(${uniqueNumber})`;
		} else {
			let space = baseName.endsWith('#') ? '' : ' ';
			return baseName + space + uniqueNumber;
		}
	};

	copyName = (baseName: string, items: any[], compareField: string, 
		withBraces: boolean = false, includeNumber: boolean = false): string => {
		let newName = this.locale.getString('common.copyOf', [baseName]);
		return this.uniqueName(newName, items, compareField, withBraces, includeNumber);
	};

	private analyzeArray = (baseName: string, items: any[], compareField: string, regexp: RegExp) => {
		let foundNumbers = {};
		let maxFoundNumber = null;
		let baseNameExists = false;
		for (let oneItem of items) {
			let itemName = oneItem[compareField];
			if (itemName === baseName) {
				baseNameExists = true;
			}
			let match = regexp.exec(itemName);
			if (match !== null) {
				let existingNumber = parseInt(match[1], 10);
				foundNumbers[existingNumber.toString()] = true;
				if (maxFoundNumber === null || existingNumber > maxFoundNumber) {
					maxFoundNumber = existingNumber;
				}
			}
		}
		return { foundNumbers, maxFoundNumber, baseNameExists };
	};

	private findFirstUniqueNumber = (analyzeResult): number => {
		for (let i = 1; i <= analyzeResult.maxFoundNumber + 1; i++) {
			if (analyzeResult.foundNumbers[i.toString()] !== true) {
				return i;
			}
		}
		return 1;
	};
	
	private escapeName = (text) => {
		return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
	};
}


/**
 * Service for generating or checking names
 */
app.service('nameService', NameService);
