import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import { StudioAlert, MAX_ACTIVE_ALERTS } from '@cxstudio/alerts/entities/studio-alert';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import AlertEditDialogResult from './entities/alert-edit-dialog-result';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { IAlertPredefinedSettings } from './entities/alert-predefined-settings';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { ScheduleUtilsService } from '@app/modules/dashboard/schedule/schedule-utils.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { HtmlUtils } from '@app/shared/util/html-utils.class';


export interface IStudioAlertActionsServiceScope {
	projectSelection: IProjectSelection;
	predefinedSettings?: IAlertPredefinedSettings;
	alertList: StudioAlert[];
	gridLoading?: ng.IPromise<any>;

	refreshGrid?(alerts: StudioAlert|StudioAlert[]): void;
}

// currently we don't support sharing for metaGroup(i.e. CB_USERS)
export interface ShareData {
	create: any[];
	message: string;
	sendEmail: boolean;
	user?: any[];
	group?: any[];
}

export interface IStudioAlertActionsService {
	toggleState(alert: StudioAlert): void;
	createAlert(): void;
	editAlert(alert: StudioAlert): void;
	deleteAlert(alert: StudioAlert): void;
	duplicateAlert(alert: StudioAlert): void;
	bulkDeleteAlerts(alerts: StudioAlert[]): void;
	bulkEnable(alerts: StudioAlert[]): void;
	bulkDisable(alerts: StudioAlert[]): void;
	adjustAlertsDates(alerts: StudioAlert[]): void;
	emailOptOutsModal(alert: StudioAlert): void;
	getRemainingEnabledAlertCount(): number;
	isEnabledAlertLimitReached(): boolean;
	openAlertEditor(alert?: StudioAlert): IModalInstanceService;
	transferMetricAlerts(selectedAlerts: any[], projectIdentifier: ProjectIdentifier): Promise<void>;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('StudioAlertActionsService', function(
	$uibModal: ng.ui.bootstrap.IModalService,
	$q: ng.IQService,
	$location: ng.ILocationService,
	betaFeaturesService: BetaFeaturesService,
	locale: ILocale,
	gridUpdateService: GridUpdateService,
	treeService: TreeService,
	alertingApiService: AlertingApiService,
	globalNotificationService: GlobalNotificationService,
	scheduleUtilsService: ScheduleUtilsService,
	security: Security,
	dashboardApiService: DashboardApiService,
	transferApiService: TransferApiService,
	downgradeDialogService: DowngradeDialogService
) {
	return class implements IStudioAlertActionsService {
		private scope: IStudioAlertActionsServiceScope;

		constructor(scope: IStudioAlertActionsServiceScope) {
			this.scope = scope;
		}

		toggleState(alert: StudioAlert): void {
			this.scope.gridLoading = alertingApiService.updateAlertState(alert.id, !alert.enabled).then(updatedAlert => {
				_.extend(alert, updatedAlert);
				this.adjustAlertsDates([alert]);

				globalNotificationService.addItemSavedNotification(alert.name);

				// need to refresh every disabled alert in case we have hit the max enablement
				let disabledAlerts = _.filter(this.scope.alertList, oneAlert => !oneAlert.enabled);
				this.scope.refreshGrid(disabledAlerts.concat(alert));
			});
		}

		isEnabledAlertLimitReached = (ownerName?: string): boolean => {
			return this.getRemainingEnabledAlertCount(ownerName) < 1;
		};

		getRemainingEnabledAlertCount = (ownerName?: string): number => {
			let currentUser = security.getUser() && security.getUser().userEmail;
			ownerName = ownerName || currentUser; // if no user is provided, just use the current user

			return MAX_ACTIVE_ALERTS - this.getEnabledAlertsCount(ownerName);
		};

		private getEnabledAlertsCount = (ownerName: string): number => {
			let userEnabledAlertsCount: any = _.chain(this.scope.alertList)
					.filter((oneAlert) => oneAlert.ownerName === ownerName)
					.countBy('enabled')
					.value();

			return userEnabledAlertsCount.true || 0;
		};

		createAlert = () => {
			if (betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_METRIC_ALERT_WIZARD)) {
				$location.url('/alerts/metric/new').search(this.scope.projectSelection);
				return;
			}
			let modal = this.openAlertEditor();
			modal.result.then((dialogResult: AlertEditDialogResult) => {
				if (this.isEnabledAlertLimitReached()) {
					dialogResult.alert.enabled = false;
				}

				this.scope.gridLoading = alertingApiService.createAlert(dialogResult.alert).then(createdAlert =>  {
					this.shareAlert(createdAlert.id, dialogResult.changedSubscribers).then(newStatus => {
						createdAlert.status = newStatus;
						globalNotificationService.addCreatedNotification(createdAlert.name);
						treeService.addItem(this.scope.alertList, createdAlert);
						if (dialogResult.share) {
							let shareData: ShareData = this.getShareData(dialogResult.entitiesWithLackOfAccess);
							dashboardApiService.shareDashboard(dialogResult.alert.notification.linkDashboard, shareData);
						}
						this.adjustAlertsDates([createdAlert]);
						if (this.scope.refreshGrid) {
							this.scope.refreshGrid(createdAlert);
						}
					});
				});
			});
		};

		editAlert = (alert: StudioAlert) => {
			if (betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_METRIC_ALERT_WIZARD)) {
				$location.url(`/alerts/metric/${alert.id}`);
				return;
			}
			let modal = this.openAlertEditor(alert);
			modal.result.then((dialogResult: AlertEditDialogResult) =>  {
				let modifiedAlert = dialogResult.alert;
				let retainEditPermission = dialogResult.retainEditPermission || false;
				this.scope.gridLoading = alertingApiService.updateAlert(modifiedAlert).then(updatedAlert =>  {
					modifiedAlert.endDate = updatedAlert.endDate;
					this.shareAlert(updatedAlert.id, dialogResult.changedSubscribers).then(newStatus => {
						modifiedAlert.status = newStatus;
						globalNotificationService.addItemSavedNotification(updatedAlert.name);

						// perform owner change at last to avoid permission error
						let isOwnerChanged: boolean = alert.ownerId !== modifiedAlert.ownerId;
						this.changeAlertOwner(isOwnerChanged, modifiedAlert, retainEditPermission).then((resultAlert) => {
							if (isOwnerChanged && !retainEditPermission) {
								this.scope.alertList = _.without(this.scope.alertList, _.findWhere(this.scope.alertList, {
									id: alert.id
								}));
								return;
							}
							_.extend(alert, resultAlert);

							if (dialogResult.share) {
								let shareData: ShareData = this.getShareData(dialogResult.entitiesWithLackOfAccess);
								dashboardApiService.shareDashboard(dialogResult.alert.notification.linkDashboard, shareData);
								this.adjustAlertsDates([alert]);
								this.scope.refreshGrid(alert);
							} else {
								this.adjustAlertsDates([alert]);
								this.scope.refreshGrid(alert);
							}
						});
					});
				});
			});
		};

		private getShareData(entitiesWithLackOfAccess: any[]): ShareData {
			entitiesWithLackOfAccess.forEach(entity => entity.permission = AssetPermission.VIEW);
			return {
				create: [],
				message: '',
				sendEmail: false,
				user: _.where(entitiesWithLackOfAccess, {type: 'user'}),
				group: _.where(entitiesWithLackOfAccess, {type: 'group'})
			};
		}

		private shareAlert = (id: number, subscribers: any[]): ng.IPromise<SharingStatus> => {
			let accessChange = _.groupBy(subscribers, 'type');
			return alertingApiService.shareAlert(id, accessChange);
		};

		private changeAlertOwner = (isOwnerChanged: boolean, alert: StudioAlert, retainEditPermission: boolean): ng.IPromise<any> => {
			return isOwnerChanged ? alertingApiService.changeAlertOwner(alert.id, alert.ownerId, retainEditPermission) : $q.when(alert);
		};

		duplicateAlert(alert: StudioAlert): void {
			this.scope.gridLoading = PromiseUtils.old(gridUpdateService.copy(alert, this.scope.alertList,
					alertingApiService.duplicateAlert, 'name')).then(newAlert => {
				globalNotificationService.addCreatedNotification(newAlert.name);

				// add alert to UI
				this.adjustAlertsDates([newAlert]);
				this.scope.refreshGrid(newAlert);
			});
		}

		deleteAlert = (alert: StudioAlert): void => {
			let confirmationText = {
				title: locale.getString('alert.deletionConfirmHeader'),
				warning: locale.getString('alert.deletionConfirmText', {name: HtmlUtils.escapeHtml(alert.name)})
			};

			this.scope.gridLoading = PromiseUtils.old(gridUpdateService.delete([alert], this.scope.alertList,
					this.deleteAlertCallback, confirmationText))
					.then(() => {
						globalNotificationService.addDeletedNotification(alert.name);

						// remove alert from UI
						this.scope.refreshGrid((alert as any).parent);
					}, () => {});
		};

		private deleteAlertCallback = (alerts: StudioAlert[]): ng.IPromise<any> => {
			return alertingApiService.deleteAlert(alerts[0].id);
		};

		openAlertEditor(alert?: StudioAlert): IModalInstanceService {
			let projectId = alert?.projectId || this.scope.projectSelection.projectId;
			return $uibModal.open({
				component: 'metricAlertEditor',
				windowClass: 'modal-md',
				backdrop: 'static',
				resolve: {
					alert: () => alert,
					allAlerts: () => _.filter(this.scope.alertList, item => item.projectId === projectId),
					projectSelection: this.scope.projectSelection,
					predefinedSettings: this.scope.predefinedSettings,
					enabledAlertLimitReached: this.isEnabledAlertLimitReached()
				}
			});
		}

		bulkEnable = (alerts: StudioAlert[]): void => {
			this.scope.gridLoading = alertingApiService.bulkEnableAlerts(_.pluck(alerts, 'id')).then((updatedAlerts) => {
				updatedAlerts.forEach(alert => {
					treeService.updateItem(this.scope.alertList, alert);
				});

				this.adjustAlertsDates(updatedAlerts);
				this.scope.refreshGrid(updatedAlerts);
			});
		};

		bulkDisable = (alerts: StudioAlert[]): void => {
			this.scope.gridLoading = alertingApiService.bulkDisableAlerts(_.pluck(alerts, 'id')).then((updatedAlerts) => {
				updatedAlerts.forEach(alert => {
					treeService.updateItem(this.scope.alertList, alert);
				});

				this.adjustAlertsDates(updatedAlerts);
				this.scope.refreshGrid(updatedAlerts);
			});
		};

		bulkDeleteAlerts = (alerts: StudioAlert[]): void => {
			this.scope.gridLoading = alertingApiService.bulkDeleteAlerts(_.pluck(alerts, 'id')).then(() => {
				alerts.forEach(alert => {
					treeService.deleteItem(this.scope.alertList, alert);
				});

				this.scope.refreshGrid(alerts);
			});
		};

		adjustAlertsDates = (studioAlerts: StudioAlert[]): void => {
			studioAlerts.forEach(alertSettings => {
				let startDate = alertSettings.startDate;
				let endDate = alertSettings.endDate;
				let timezoneOffset = alertSettings.timezoneOffset;

				alertSettings.startDate = scheduleUtilsService.adjustDate(startDate, timezoneOffset);
				alertSettings.endDate = scheduleUtilsService.adjustDate(endDate, timezoneOffset);
			});
		};

		emailOptOutsModal = (alert: StudioAlert): Promise<void> => {
			return downgradeDialogService
				.openOptOutsModal(alert)
				.then(
					(recipients) => {
						alertingApiService
							.removeFromBlacklist(alert.id, recipients)
							.then((updatedAlert) => {
								this.adjustAlertsDates([updatedAlert]);
								this.scope.refreshGrid(updatedAlert);
							}
						);
					}
				).catch(() => {})
			;
		};

		transferMetricAlerts(selectedAlerts: any[], projectIdentifier: ProjectIdentifier): Promise<void> {
			return downgradeDialogService.openBulkTransferDialog({
				selectedObjects: selectedAlerts,
				mode: TransferGroup.METRIC_ALERTS,
				projectIdentifier
			}).result.then(result => {
				if (result !== undefined) {
					transferApiService.makeTransfer(result);

					if (!result.retainEditPermission) {
						this.removeTransferredMetricAlerts(selectedAlerts);
					} else {
						this.updateTransferredMetricAlerts(result, selectedAlerts);
					}
					this.scope.refreshGrid(this.scope.alertList);
				}
			});
		}

		private removeTransferredMetricAlerts = (selectedAlerts) => {
			let transferredMetricAlertIds = _.pluck(selectedAlerts, 'id');
			this.scope.alertList = _.reject(this.scope.alertList, alert => {
				return _.contains(transferredMetricAlertIds, alert.id);
			});
		};

		private updateTransferredMetricAlerts = (result, selectedAlerts) => {
			let masterAccountItems = result.transferItems;
			selectedAlerts.forEach(selectedAlert => {
				let currentMasterAccountFilters: any[] = masterAccountItems[selectedAlert.masterAccountId][TransferGroup.METRIC_ALERTS];
				let newOwnerId = _.findWhere(currentMasterAccountFilters, {id: selectedAlert.id}).selectedCandidateId;
				selectedAlert.ownerId = newOwnerId;
				selectedAlert.status = 'SHARED';
				selectedAlert.permissionLevel = 'EDIT';
			});
		};
	};
});
