import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';

import * as _ from 'underscore';
import { WidgetProperties } from '../entities/widget-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { DrillWidgetType } from '@cxstudio/reports/utils/contextMenu/drill/drill-widget-type';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import VisualProperties from '../entities/visual-properties';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';



export class PreviewService {

	constructor(
		private $q: ng.IQService,
		private locale: ILocale,
		private security: Security) {}

	changeColumnNames = (options: VisualProperties, attributes: IReportAttribute[]) => {
		if (options && options.columns && attributes) {
			_.each(options.columns, (column: TableColumn<PreviewSentence>) => {
				let attr = _.find(attributes, (attribute) =>
					attribute.name === column.name && attribute.type === column.type);
				if (attr)
					column.displayName = attr.displayName;
			});
		}
	};

	createDocumentExplorerSettings = (): Widget => {
		let widget = {} as DocumentExplorerWidget;
		widget.name = WidgetType.PREVIEW;
		widget.drillDepth = 1;
		widget.type = WidgetDisplayType.CB;
		widget.documentExplorerName = this.locale.getString('widget.docExplorer');
		widget.parentWidget = {};

		widget.properties = {} as WidgetProperties;
		widget.properties.runAs = this.security.getEmail();
		widget.properties.page = {lookAheadLimit: 100, start: 0};
		widget.properties.itemsPerPage = 20;
		widget.properties.widgetType = WidgetType.PREVIEW;
		widget.properties.objectName = DrillWidgetType.SELECTIVE_PREVIEW;
		widget.properties.snippets = false;
		widget.properties.documentExplorer = true;
		widget.properties.previewMode = PreviewMode.DOCUMENT;
		widget.visualProperties = {} as VisualProperties;
		return widget;
	};

	isSelectivePreview = (widget: Widget) => {
		return widget.properties && widget.properties.objectName === DrillWidgetType.SELECTIVE_PREVIEW;
	};

}

app.service('previewService', PreviewService);
