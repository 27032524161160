import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { downgradeInjectable } from '@angular/upgrade/static';

export interface IUnsavedChangesDialogInput {
	headerText?: string;
	bodyText?: string;
	hideRollbackOption?: boolean;
	disableSave?: boolean;
	saveText?: string;
	dontSaveText?: string;
	cancelText?: string;
}

@Component({
	selector: 'unsaved-changes-dialog',
	templateUrl: './unsaved-changes-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UnsavedChangesDialogComponent extends ModalBindings<IUnsavedChangesDialogInput> implements OnInit {
	headerText?: string;
	bodyText?: string;
	hideRollbackOption?: boolean;
	disableSave?: boolean;
	saveText?: string;
	dontSaveText?: string;
	cancelText?: string;

	@Input() input: IUnsavedChangesDialogInput;

	constructor(modal: NgbActiveModal) {
		super(modal);
	}

	ngOnInit(): void {
		this.headerText = this.input?.headerText ?? 'dashboard.unsavedChangesHeader';
		this.bodyText = this.input?.bodyText ?? 'dashboard.unsavedChanges';
		this.hideRollbackOption = this.input?.hideRollbackOption ?? false;
		this.disableSave = this.input?.disableSave ?? false;
		this.saveText = this.input?.saveText ?? 'administration.save';
		this.dontSaveText = this.input?.dontSaveText ?? 'administration.dontsave';
		this.cancelText = this.input?.cancelText ?? 'administration.cancel';
	}

	cancel = (): void => {
		this.dismiss();
	};

	save = (): void => {
		this.close(true);
	};

	dontSave = (): void => {
		this.close(false);
	};
}
