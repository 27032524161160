import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { UserEditFormData } from '../editor/user-edit-form-data';
import { LicenseService } from '../license.service';

@Injectable({
	providedIn: 'root'
})
export class UserCreateWizardHelperService {

	constructor(
		@Inject('security') private readonly security: Security,
		private readonly licenseService: LicenseService
	) {}

	licenseLimitExceeded = (licenseInfo: MasterAccountContractInfo, user: UserEditFormData): boolean => {
		if (!this.isNeedToCountLicenses(licenseInfo, user)) {
			return false;
		}

		return this.licenseService.licenseLimitExceeded(licenseInfo, user.licenseTypeId);
	};

	isNeedToCountLicenses = (licenseInfo: MasterAccountContractInfo, user: UserEditFormData): boolean => {
		return this.licenseService.isNeedToCountLicenses(licenseInfo, user, this.security.getMasterAccountId());
	};

	isAccountOwner = (): boolean => {
		return this.security.has('account_owner');
	};
}
