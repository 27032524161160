import { NarrativeSettingEntry } from '@app/modules/account-administration/automated-narrative/narrative-settings-list.component';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { WidgetDocViewPreferences } from '@app/modules/document-explorer/preferences/widget-doc-view-preference.provider';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { EngagorService } from '@app/modules/system-administration/master-account/integration/engagor.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import ScorecardsApiService from '@cxstudio/projects/scorecards/scorecards-api-service';
import { DocExplorerFavorites, IPreviewWidgetFavorites } from '@app/modules/document-explorer/context-pane/doc-explorer-favorites.class';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import ExplorerSectionGroup from '@cxstudio/reports/document-explorer/explorer-section-group';
import { FavoriteAttribute, FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { DatePeriod, DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { Model } from '@cxstudio/reports/entities/model';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';
import { PreviewVisualProperties } from '@cxstudio/reports/entities/preview-visual-properties';
import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { IPeriodOptions } from '@cxstudio/reports/entities/report-interfaces';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { PreviewColumn, PreviewPredefinedColumns } from '@cxstudio/reports/preview/preview-predefined-columns';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { AttributeLoader } from '@cxstudio/services/attribute-loader.service';
import * as uib from 'angular-ui-bootstrap';
import * as _ from 'underscore';
import { MetricConstants } from '../constants/metric-constants.service';
import WidgetSettingsService, { IBaseWidgetSettings } from '../services/widget-settings.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';
import { PreviewTableColumn } from '@cxstudio/reports/preview/preview-table-column';
import { PreviewWidget } from '@cxstudio/reports/entities/preview-widget.class';
import { WidgetContentEvent, WidgetContentEventType } from '@app/modules/widget-visualizations/visualization-component.interface';
import { FeedbackDataGeneratorService } from '@app/modules/widget-settings/feedback-widget/feedback-data-generator.service';
import { Security } from '@cxstudio/auth/security-service';
import { AnalyticPreviewDefaultsInitializer } from '@cxstudio/reports/preview/analytic-preview-defaults-initializer';
import { PreviewDemo } from '@app/modules/document-explorer/preview-demo.class';
import { QualtricsIconsId } from '@discover/unified-icons/src/types/qualtrics-icons';

interface UIOptions {
	periods: DatePeriod[];
	periodOptions: IPeriodOptions;
	projectTimezone: string;

	staticData: any;
	previewModes: IButtonOption[];
	visualizations: IButtonOption[];
	sortOptions: ISortOption[];

	availableAttributes: any[];
	bubbleAttributes: AttributeGrouping[];
	availableModels: Model[];
	docAvailableModels: Model[];
	accountLvlHiddenModelsAndAttrs: any[];
	docViewAttributesAndModels: any[];
	contextOffAttributesAndModels: any[];
	narrativeEnabled: boolean;
}

interface IButtonOption {
	label: string;
	value: string;
	disabled?: boolean;
}

interface ISortOption extends IButtonOption {
	icon?: QualtricsIconsId;
}


export class AnalyticPreviewSettingsController {

	PAGES_PER_CALL = 5;

	widget: PreviewWidget;
	props: PreviewWidgetProperties;
	visualProps: PreviewVisualProperties;
	dashboardFiltersApplied: boolean; // TODO: get rid of this, but it's tied very hard to dashboard
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>; // TODO: get rid of this
	utils: WidgetUtils;
	updateAutoTitle: () => void;
	documentPreviewDemo: IDocumentPreviewerControls;
	redrawTrigger: number;
	updateChartSettings: () => void;

	// internal
	ui: UIOptions;
	sentenceColumn: TableColumn<PreviewSentence>;
	allAttributes: AttributeGrouping[];
	constants: any;
	selectedModels: any;
	preferences: DocViewPreferences;
	widgetFavorites: IPreviewWidgetFavorites;
	loadingAttributes: boolean;

	isIntelligentScoringEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING);
	isNarrativeBetaEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.AUTOMATED_NARRATIVES);

	readonly MAX_TABLE_COLUMNS = 15;
	readonly TUNING_SUGGESTIONS_ENRICHMENTS = [
		PreviewColumn.SENTIMENT,
		PreviewColumn.EFFORT,
		PreviewColumn.EMOTION,
		PreviewColumn.TOPICS
	];

	private handleTypeChange: any = {};

	constructor(
		private $scope: ISimpleScope,
		private locale: ILocale,
		private datePeriodUtils: DatePeriodUtils,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly assetAccessApiService: AssetAccessApiService,
		private widgetSettingsService: WidgetSettingsService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private previewPredefinedColumns: PreviewPredefinedColumns,
		private feedbackDataGenerator: FeedbackDataGeneratorService,
		private reportSettingsService: ReportSettingsService,
		private engagorService: EngagorService,
		private security: Security,
		private readonly narrativeSettingsApi: NarrativeSettingsApi,
		private $uibModal: uib.IModalService,
		private attributeLoader: AttributeLoader,
		private analyticPreviewDefaultsInitializer: AnalyticPreviewDefaultsInitializer,
		private optionsTemplatesService: OptionsTemplatesService,
		private $q: ng.IQService,
		private metricConstants: MetricConstants,
		private betaFeaturesService: BetaFeaturesService,
		private scorecardsApiService: ScorecardsApiService
	) {}

	$onInit(): void {
		this.ui = {} as UIOptions;
		this.ui.staticData = {
			data: this.feedbackDataGenerator.getPreviewDemoData(),
			ready: false
		};
		this.constants = this.metricConstants.get();
		this.selectedModels = {};

		this.initDefaults();

		this.preferences = new WidgetDocViewPreferences({ properties: this.props, visualProperties: this.visualProps } as Widget);
		this.widgetFavorites = new DocExplorerFavorites(this.preferences);

		/*
			For searchability:
			preview.sentences, preview,sentencesWithContext, preview.document, preview.verbatim
		*/
		this.ui.previewModes = _.map([
			PreviewMode.SENTENCES,
			PreviewMode.SENTENCES_WITH_CONTEXT,
			PreviewMode.VERBATIM,
			PreviewMode.DOCUMENT
		], mode => {
			return {
				value: mode,
				label: this.locale.getString(`preview.${mode}`)
			};
		});

		this.ui.visualizations = [
			{value: WidgetVisualization.PREVIEW_BUBBLES, label: this.locale.getString('preview.bubble')},
			{value: WidgetVisualization.PREVIEW_TABLE, label: this.locale.getString('preview.table')}
		];

		const isMlSentimentEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT);
		const sentimentSortIcon = isMlSentimentEnabled ? 'sentiment-v2-very-positive' : 'thumb-up';

		this.ui.sortOptions = [{
			value: PreviewSortAttributes.SENTIMENT,
			icon: sentimentSortIcon,
			label: this.locale.getString('docExplorer.sortBySentiment')
		}, {
			value: PreviewSortAttributes.EFFORT,
			icon: 'effort-positive',
			label: this.locale.getString('docExplorer.sortByEase')
		}, {
			value: PreviewSortAttributes.DOC_DATE,
			icon: 'calendar',
			label: this.locale.getString('docExplorer.sortByDate')
		}];

		this.ui.periods = [new DatePeriod(DatePeriodField.PERIOD1, DatePeriodName.PERIOD1)];
		this.ui.periodOptions = {};


		this.widgetSettingsService.initializePeriods(this.props, this.ui.periods, false);

		this.$scope.$on('clearSettings', () => this.initDefaults(true));
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.handleTypeChange[WidgetVisualization.PREVIEW_TABLE] = (lastVizType: string): void => {
			let topicColumn = _.findWhere(this.visualProps.columns, { name: PreviewColumn.TOPICS});
			if (topicColumn) {
				topicColumn.disabled = false;
			}
			this.visualProps.sentencePaneEnabled = false;

			this.processSelectedItems();
		};

		this.handleTypeChange[WidgetVisualization.PREVIEW_BUBBLES] = (lastVizType: string): void => {
			this.visualProps.columns = _.filter(this.visualProps.columns, (attribute: any) => !this.isModel(attribute));
			this.processSelectedItems();
		};

		let changeToDocView = (vizType: WidgetVisualization): ((lastVizType: string) => void) => {
			return (lastVizType: string): void => {
				this.keepSentenceTopics();
				let list = this.getAttributeListFromVisualization(lastVizType);
				this.removeAttribute(this.previewPredefinedColumns.get(PreviewColumn.TOPICS), list);
				this.visualProps.visualization = vizType;
				this.processSelectedItems();
				this.visualProps.preferences.settings.singleVerbatimMode = vizType === WidgetVisualization.VERBATIM_PREVIEW;
			};
		};
		this.handleTypeChange[WidgetVisualization.DOCUMENT_PREVIEW] = changeToDocView(WidgetVisualization.DOCUMENT_PREVIEW);
		this.handleTypeChange[WidgetVisualization.VERBATIM_PREVIEW] = changeToDocView(WidgetVisualization.VERBATIM_PREVIEW);
	}

	private updateSelectedScorecards = (): void => {
		let disabled = !this.contextPaneEnabled();
		this.visualProps.columns.forEach((column) => {
			if (column.type === ReportAssetType.SCORECARDS && column.disabled !== disabled) {
				column.disabled = disabled;
				if (disabled) {
					this.props.selectedAttributes.remove(_.findWhere(this.props.selectedAttributes, { name: column.name }));
				} else {
					let scorecard = this.findRecursive(this.ui.docViewAttributesAndModels, { name: column.name });
					if (!_.findWhere(this.props.selectedAttributes, {name: scorecard.name}))
						this.props.selectedAttributes.push(scorecard);
				}
			}
		});
	};

	private initDefaults(hardReset: boolean = false): void {
		this.analyticPreviewDefaultsInitializer.initializeWidget(this.props, this.visualProps);
		this.initDefaultColumns(hardReset);
		this.visualProps.preferences = this.visualProps.preferences || {
			settings : {
				paneCollapsedState : {
					worldAwarenessCollapsed: false,
					attrCollapsed: false,
					topicsCollapsed: false,
					favoriteAttributesCollapsed: false
				},
				singleVerbatimMode: false,
				showEmptyAttributes: false,
				showSentiment: true,
			}
		};
		//if not set, then set to true
		this.visualProps.showSampleSize = this.visualProps.showSampleSize !== false;
	}

	private initDefaultColumns(hardReset: boolean = false): void {
		this.visualProps.columns = (this.visualProps.columns && !hardReset)
			? this.visualProps.columns
			: [];
		if (_.isEmpty(this.visualProps.columns) && !this.loadingAttributes) {
			// don't call this again if it's already in progress
			this.resetDefaultColumns();
		}
	}

	private resetDefaultColumns(): void {
		this.loadingAttributes = true;
		let columnPromises = this.previewPredefinedColumns.getPredefinedMetricColumns().map(this.addAttribute);
		columnPromises.push(this.addAttribute(this.previewPredefinedColumns.get(PreviewColumn.SOURCE)));
		columnPromises.push(this.addAttribute(this.previewPredefinedColumns.get(PreviewColumn.SENTENCE)));
		columnPromises.push(this.addAttribute(this.previewPredefinedColumns.get(PreviewColumn.DOC_DATE)));
		this.$q.all(columnPromises). then(() => {
			this.loadingAttributes = false;
		});
	}

	isTable = (vizType: string = this.visualProps.visualization): boolean => {
		return !this.isExplorerView() && (vizType === WidgetVisualization.PREVIEW_TABLE);
	};

	isBubbles = (vizType: string = this.visualProps.visualization): boolean => {
		return !this.isExplorerView() && (vizType === WidgetVisualization.PREVIEW_BUBBLES);
	};

	isPaneView = (): boolean => {
		return this.isDocumentPaneView() || this.isVerbatimPaneView();
	};

	isDocumentPaneView = (): boolean => {
		return this.props.previewMode === PreviewMode.DOCUMENT
			&& this.visualProps.visualization === WidgetVisualization.DOCUMENT_PREVIEW;
	};

	isVerbatimView = (): boolean => {
		return this.props.previewMode === PreviewMode.VERBATIM;
	};

	isVerbatimPaneView = (): boolean => {
		return this.props.previewMode === PreviewMode.DOCUMENT
			&& this.visualProps.visualization === WidgetVisualization.VERBATIM_PREVIEW;
	};

	isSentenceView = (): boolean => {
		return this.props.previewMode === PreviewMode.SENTENCES || this.props.previewMode === PreviewMode.SENTENCES_WITH_CONTEXT;
	};

	applyVisualChanges = (): void => {
		if (this.props && !this.props.isCustomTitle) {
			this.updateAutoTitle();
		}
		this.updateChartSettings();
	};

	reloadSettings = (event: ng.IAngularEvent, callback: (ready: boolean, error?: string) => void) => {
		this.initDefaults();
		let endpointPromise = this.addLoadingPromise(this.widgetSettingsService.getBaseWidgetSettings(this.props));
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(this.props);
		let hiddenAssetsPromise = this.addLoadingPromise(PromiseUtils.old(
			this.assetAccessApiService.getDocExplorerHiddenAssets(project)));

		let availableScorecardsPromise = this.isIntelligentScoringEnabled
			? this.scorecardsApiService.getActiveScorecards(
				new ProjectIdentifier(this.props.contentProviderId, this.props.accountId, this.props.project))
			: this.$q.resolve([] as Scorecard[]);

		let availableNarratives = this.isNarrativeBetaEnabled
			? PromiseUtils.old(this.narrativeSettingsApi.getNarrativeSettings(project))
			: this.$q.resolve([] as NarrativeSettingEntry[]);

		this.$q.all([endpointPromise, hiddenAssetsPromise, availableScorecardsPromise, availableNarratives]).then(responses => {
			let settings = responses[0] as IBaseWidgetSettings;
			this.ui.accountLvlHiddenModelsAndAttrs = responses[1];
			let availableScorecards = responses[2];
			this.ui.narrativeEnabled = _.some(responses[3], entry => !!entry.enabled);

			this.ui.availableAttributes = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.previewItems())
				.withAttributes(settings.attributes, this.attributeFilter)
				.withWordAttributes(settings.wordAttributes)
				.build();
			this.ui.availableModels = _.filter(settings.models, this.modelFilter);
			this.ui.availableModels = this.filterAcctLevelHiddenModels(this.ui.availableModels);
			this.ui.docAvailableModels = _.filter(this.ui.availableModels, this.isNotHidden);

			this.attributeLoader.processModels(this.ui.docAvailableModels);

			let showScorecards = this.isIntelligentScoringEnabled;

			this.ui.docViewAttributesAndModels = this.optionsBuilderProvider.getBuilder()
				.withTemplate(this.optionsTemplatesService.documentPreviewItems(showScorecards))
				.withModels(this.ui.docAvailableModels)
				.withAttributes(settings.attributes, this.attributeFilter)
				.withWordAttributes(settings.wordAttributes)
				.withScorecards(availableScorecards, settings.attributes)
				.build();

			this.ui.availableAttributes.insertAll(0, _.union(
				this.previewPredefinedColumns.getPredefinedMetricColumns(), [
				this.previewPredefinedColumns.get(PreviewColumn.SOURCE),
				this.previewPredefinedColumns.get(PreviewColumn.DOC_DATE),
				this.previewPredefinedColumns.get(PreviewColumn.TOPICS)
			]));

			this.ui.docViewAttributesAndModels.insertAll(0, _.union(
				this.previewPredefinedColumns.getPredefinedMetricColumns(), [
				this.previewPredefinedColumns.get(PreviewColumn.SOURCE),
				this.previewPredefinedColumns.get(PreviewColumn.DOC_DATE)
			]));

			this.ui.bubbleAttributes = angular.copy(this.ui.availableAttributes);
			_.each(this.ui.bubbleAttributes, (option) => {
				if (option.type !== ReportAssetType.SYS || option.name === PreviewColumn.TOPICS) {
					option._disabled = true;
				}
			});

			this.ui.contextOffAttributesAndModels = angular.copy(this.ui.docViewAttributesAndModels);
			_.each(this.ui.contextOffAttributesAndModels, (option) => {
				if (option.name !== PreviewColumn.TOPICS) {
					option._disabled = true;
				}
			});

			this.hideColumns(settings);
			this.processSelectedItems();
			this.processCurrentMode(this.props.previewMode);
			this.updateSelectedScorecards();

			let selectedDateFilter = this.props.dateRangeP1.dateFilterMode;
			this.ui.periodOptions.dateFilters =
				this.datePeriodUtils.processDateFilters(settings.dateFilters, [selectedDateFilter]);

			this.ui.staticData.ready = true;

			this.documentPreviewDemo = new PreviewDemo(this.ui.staticData.data, this.ui.narrativeEnabled);
			this.applyVisualChanges();
			callback(true);
		});
	};

	private hideColumns = (settings: any): void => {
		let columns = this.visualProps.columns;

		if (!columns) {
			return;
		}

		this.visualProps.columns = _.filter(columns, (columnItem) => {
			if (this.isModel(columnItem)) {
				let model: Model = _.findWhere(settings.models, {id: parseInt(columnItem.name, 10)});
				if (model) {
					return !model.hide;
				}
			} else {
				let attribute: any = _.findWhere(settings.attributes, {name: columnItem.name});
				if (attribute) {
					return !attribute.hide;
				}
			}

			return true;
		});
	};

	//when swtich sentence/document view, option.hide will be reset,
	//so need to filter out personal hidden objects before load it to option
	private attributeFilter = (item): boolean => this.isNotHidden(item) && MetricFilters.GROUP_FILTER(item);

	private modelFilter = (model): boolean => this.isNotHidden(model) || this.isModelSelected(model);

	private isNotHidden = (hideableObj): boolean => !(hideableObj.hidden || hideableObj.hide);



	private processSelectedItems = (): void => {
		let list = this.getAttributeListFromVisualization();
		_.each(list, this.processSelectedItemsHierarchy);

		this.props.selectedAttributes = this.props.selectedAttributes.filter((attribute) => {
			return _.find(this.visualProps.columns, {name: attribute.name, type: attribute.type});
		});
	};

	private processSelectedItemsHierarchy = (item): void => {
		if (item.children)
			_.each(item.children, this.processSelectedItemsHierarchy);
		else {
			if (_.findWhere(this.visualProps.columns, {name: item.name, type: item.type})) {
				item.hide = true;
				this.feedbackDataGenerator.addFieldToData(this.ui.staticData.data, item);
			} else {
				item.hide = false;
			}
		}
	};

	configureColumn = (column: TableColumn<PreviewSentence>): void =>  {
		let attribute = _.findWhere(this.props?.selectedAttributes, {name: column.name});
		if (column.type === 'TEXT' && column.urlType === undefined && attribute) {
			angular.extend(column, this.getColumn(attribute));
		}
		this.configurationModal(column).result.then((updatedColumn: any) => {
			angular.extend(column, updatedColumn);
			this.extendAdditionalProperties(attribute, updatedColumn);
			if (column.name === PreviewColumn.SENTENCE) {
				this.visualProps.sentimentHighlightingEnabled = updatedColumn.sentimentHighlightingEnabled;
				this.visualProps.showEmptyAttributes = updatedColumn.showEmptyAttributes;
			}
			this.applyVisualChanges();
		});
	};

	private extendAdditionalProperties(dest, source): void {
		if (!dest || !source) {
			return;
		}
		let replicateProperties = ['capitalization',
			'urlType', 'tableRowHeight', 'scale',
			'verticalAlign', 'horizontalAlign'
		];

		for (let property of replicateProperties) {
			dest[property] = source[property];
		}
	}

	private configurationModal = (configItem: any): uib.IModalServiceInstance => {
		if (configItem.name === PreviewColumn.SENTENCE) {
			configItem.sentimentHighlightingEnabled = this.visualProps.sentimentHighlightingEnabled;
			configItem.showEmptyAttributes = this.visualProps.showEmptyAttributes;
		}

		return this.$uibModal.open({
			templateUrl: 'partials/widgets/settings/cb/definitions/metric-configuration-modal.html',
			controller: 'widgetMetricConfigController',
			backdrop: 'static',
			resolve: {
				modalSettings: () => {
					return {
						configItem,
						isFeedbackColumn: true,
						isTable: this.isTable(),
						isVerbatimView: this.isVerbatimView(),
						isWidgetAssetConfig: false
					};
				}
			}
		});
	};


	private isTableMaxColumns = (): boolean => {
		return  this.isTable() && (this.visualProps.columns.length >= this.MAX_TABLE_COLUMNS);
	};
	//
	private isModel = (attribute) => {
		return attribute.model || attribute.type === PreviewColumn.TOPICS;
	};

	keepDocumentTopics = () => {
		let models = _.filter(this.props.selectedAttributes, (attribute: any): boolean => this.isModel(attribute));

		this.props.selectedAttributes = _.filter(this.props.selectedAttributes, (attribute: any): boolean => {
			if (this.isModel(attribute)) {
				attribute.hide = false;
			}
			return !this.isModel(attribute);
		});

		this.visualProps.columns = _.filter(this.visualProps.columns, (column): boolean => {
			return column.type === PreviewDataType.SYS
				|| !!_.findWhere(this.props.selectedAttributes, {name: column.name});
		});

		if (!_.isEmpty(models)) {
			this.props.includeTopics = true;
			let topics = this.previewPredefinedColumns.get(PreviewColumn.TOPICS);
			this.visualProps.columns.push(this.getColumn(topics));
			this.props.selectedModels = _.map(models, (model) => parseInt(model.name, 10));
		}
	};

	keepSentenceTopics = () => {
		if (this.props.includeTopics && !_.isEmpty(this.props.selectedModels)) {
			let models = _.chain(this.props.selectedModels)
				.map((modelId) => this.findRecursive(this.ui.docViewAttributesAndModels, { model: true, name: modelId + '' }))
				.filter((model) => !!model).value();
			this.props.selectedAttributes.pushAll(models);
			this.visualProps.columns.pushAll(models);
		}
	};

	addAttribute = (item: AttributeGrouping): ng.IPromise<any> => {
		let attributeList: AttributeGrouping[] = this.getAttributeListFromVisualization();
		let promise = this.$q.resolve(angular.copy(item));

		if ((item as any).children || this.isTableMaxColumns()) {
			return this.$q.resolve();
		}
		if (!this.isAttributeSelected(item)) {
			if (item.type === 'TEXT' && item.urlType === undefined) {
				promise = PromiseUtils.old(this.reportSettingsService.populateGroupingProjectDefaults(this.props, item));
			}
			promise.then((enrichedItem) => {
				if (enrichedItem.type !== ReportAssetType.SYS)
					this.props.selectedAttributes.push(enrichedItem);

				this.visualProps.columns.push(this.getColumn(enrichedItem));
				this.setHidden(enrichedItem.name, enrichedItem.type, true, attributeList);

				if (enrichedItem.name === PreviewColumn.TOPICS)
					this.props.includeTopics = true;
				this.feedbackDataGenerator.addFieldToData(this.ui.staticData.data, enrichedItem);
			});
			this.addLoadingPromise(promise);
		}
		return promise.then(() => this.applyVisualChanges());
	};

	reorderAttributes = (movedItem: any, newIndex: number): void => {
		let itemIndex = _.indexOf(this.visualProps.columns, movedItem);

		if (itemIndex > -1)
			(this.visualProps.columns as []).move(itemIndex, newIndex);
		this.applyVisualChanges();
	};

	private setHidden(name: string, type: string,
		hidden: boolean, attributeList: AttributeGrouping[] = this.ui.availableAttributes
	): void {
		let item = this.findRecursive(attributeList, {name, type});
		if (item) {
			item.hide = hidden;
		}
	}

	private findRecursive(array: any[], predicate: any): any {
		if (_.isEmpty(array))
			return null;
		let attr = _.findWhere(array, predicate);
		if (attr)
			return attr;
		for (let item of array) {
			attr = this.findRecursive(item.children, predicate);
			if (attr)
				return attr;
		}
		return null;
	}

	private getAttributeListFromVisualization = (vizType: string = this.visualProps.visualization) => {
		if (this.isBubbles(vizType)) {
			return this.ui.bubbleAttributes;
		} else if (this.isTable(vizType)) {
			return this.ui.availableAttributes;
		} else if (!this.contextPaneEnabled()) {
			return this.ui.contextOffAttributesAndModels;
		}

		return this.ui.docViewAttributesAndModels;
	};

	removeAttribute = (
		column: PreviewTableColumn | AttributeGrouping,
		attributeList = this.getAttributeListFromVisualization()
	): void => {
		this.removeAttributeInternal(column.name, column.type, attributeList);
	};

	removeAttributeInternal = (name: string, type: string, attributeList = this.ui.availableAttributes): void => {
		this.props.selectedAttributes.remove(_.findWhere(this.props.selectedAttributes, {name}));
		this.visualProps.columns.remove(_.findWhere(this.visualProps.columns, {name}));
		if (this.isTuningSuggestionsDisabled()) {
			this.visualProps.tuningSuggestionsEnabled = false;
		}
		if (name === PreviewColumn.TOPICS)
			this.props.includeTopics = false;

		this.setHidden(name, type, false, attributeList);
		this.applyVisualChanges();
	};

	private getColumn(item: AttributeGrouping): TableColumn<PreviewSentence> {
		return {
			name: item.name,
			displayName: item.displayName,
			type: item.type,
			isConfigurable: item.isConfigurable,
			capitalization: item.capitalization,
			disabled: item._disabled,
			urlType: item.urlType,
			tableRowHeight: item.tableRowHeight
		};
	}

	isAttributeSelected = (item: AttributeGrouping): boolean => {
		return !!_.findWhere(this.visualProps.columns, {name: item.name, type: item.type});
	};
	toggleAttribute = (item: AttributeGrouping, attributeList = this.ui.availableAttributes): void => {
		if (this.isAttributeSelected(item))
			this.removeAttributeInternal(item.name, item.type, attributeList);
		else this.addAttribute(item);
	};

	toggleContextPane = (): void => {
		this.processSelectedItems();
		this.applyVisualChanges();
	};

	updateBubbleColumnsCount = (count: number): void => {
		this.visualProps.bubbleColumns = count;
		this.applyVisualChanges();
	};

	setVisualization = (viz: WidgetVisualization): void => {
		let lastViz = this.visualProps.visualization;
		if (this.isVerbatimPaneView() && WidgetVisualization.VERBATIM_PREVIEW !== viz) {
			this.visualProps.visualization = viz;
			this.selectPreviewMode(PreviewMode.VERBATIM);
		}

		this.visualProps.visualization = viz;
		this.handleTypeChange[viz](lastViz);
		this.applyVisualChanges();
	};

	setVerbatimPaneView = (): void => {
		this.selectPreviewMode(PreviewMode.DOCUMENT);
		this.visualProps.visualization = WidgetVisualization.VERBATIM_PREVIEW;
		this.visualProps.preferences.settings.singleVerbatimMode = true;
		this.updateSentenceColumnDisplayName(PreviewMode.VERBATIM);
	};

	contextPaneEnabled = (): boolean => {
		return this.isExplorerView() && this.visualProps.contextPaneEnabled;
	};

	selectedMode = (mode: PreviewMode): boolean => {
		return this.isVerbatimPaneView() ? PreviewMode.VERBATIM === mode : this.props.previewMode === mode;
	};

	toggleFavorite = (item: TableColumn<PreviewSentence>, type: FavoriteType): void => {
		let favorite = new FavoriteAttribute(item.name, type);
		this.widgetFavorites.toggleFavorite(favorite, type);
	};

	isFavorited = (item: TableColumn<PreviewSentence>, type: FavoriteType): boolean => {
		let favorite = new FavoriteAttribute(item.name, type);
		return this.widgetFavorites.isItemFavorite(favorite, type);
	};

	toggleSectionVisibility = (item: ExplorerSectionGroup): void => {
		let section = this.visualProps.sections[item.key];
		if (!section) {
			this.visualProps.sections[item.key] = item;
		}
		this.applyVisualChanges();
	};

	selectPreviewMode = (mode: PreviewMode): void => {
		let oldMode = this.props.previewMode;

		if (this.isVerbatimPaneView() && PreviewMode.VERBATIM === mode) {
			return;
		}

		if (mode === PreviewMode.DOCUMENT) {
			this.handleTypeChange[WidgetVisualization.DOCUMENT_PREVIEW](this.visualProps.visualization);
		}

		if (mode === PreviewMode.VERBATIM && this.isDocumentPaneView()) {
			this.setVisualization(WidgetVisualization.VERBATIM_PREVIEW);
			mode = PreviewMode.DOCUMENT;
		} else if (oldMode === PreviewMode.DOCUMENT && mode !== PreviewMode.DOCUMENT) {
			this.props.previewMode = mode;
			this.keepDocumentTopics();
			this.setVisualization(WidgetVisualization.PREVIEW_TABLE);
			// if too many items are selected from document view, we have to pare it down
			if (this.visualProps.columns.length > this.MAX_TABLE_COLUMNS) {
				this.visualProps.columns = this.visualProps.columns.slice(0, (this.MAX_TABLE_COLUMNS - 1));
			}
		}
		this.props.previewMode = mode;

		if (oldMode !== mode) {
			this.processCurrentMode(mode);
		}
		if (this.isTuningSuggestionsDisabled()) {
			this.visualProps.tuningSuggestionsEnabled = false;
		}
		this.updateSelectedScorecards();
		this.applyVisualChanges();
	};

	private updateSentenceColumnDisplayName(mode: PreviewMode): void {
		let sentenceColumn = _.findWhere(this.visualProps.columns, {name: PreviewColumn.SENTENCE});
		sentenceColumn.displayName = this.isVerbatimPaneView()
			? this.locale.getString('preview.verbatim')
			: this.locale.getString(`preview.${mode}`);
	}

	private processCurrentMode(mode: PreviewMode): void {
		if (mode === PreviewMode.VERBATIM) {
			this.previewPredefinedColumns.getPredefinedMetricNames()
				.forEach(name => this.setAttributeAvailability(name, false));
			this.setAttributeAvailability(PreviewColumn.VERBATIM_TYPE, true);
			this.setAttributeAvailability(PreviewColumn.SOURCE, true);
			this.setAttributeAvailability(PreviewColumn.DOC_DATE, true);
			if (this.props.page.sortMetric.displayName !== PreviewSortAttributes.DOC_DATE) {
				this.props.page.sortMetric.displayName = PreviewSortAttributes.DOC_DATE;
				this.props.page.sortMetric.direction = AnSortDirection.DESC;
			}
			this.ui.sortOptions[0].disabled = true;
			this.ui.sortOptions[1].disabled = true;
		} else if (mode === PreviewMode.DOCUMENT) {
			this.previewPredefinedColumns.getPredefinedMetricNames()
				.forEach(name => this.setAttributeAvailability(name, false));
			this.setAttributeAvailability(PreviewColumn.VERBATIM_TYPE, false);
			this.setAttributeAvailability(PreviewColumn.SOURCE, false);
			this.setAttributeAvailability(PreviewColumn.DOC_DATE, false);
		} else {
			this.previewPredefinedColumns.getPredefinedMetricNames()
				.forEach(name => this.setAttributeAvailability(name, true));
			this.setAttributeAvailability(PreviewColumn.VERBATIM_TYPE, true);
			this.setAttributeAvailability(PreviewColumn.SOURCE, true);
			this.setAttributeAvailability(PreviewColumn.DOC_DATE, true);
			this.ui.sortOptions[0].disabled = false;
			this.ui.sortOptions[1].disabled = false;
		}
		this.updateSentenceColumnDisplayName(mode);
	}

	private setAttributeAvailability(name: string, available: boolean): void {
		let tableAttr = this.findRecursive(this.ui.availableAttributes, {name});
		let bubbleAttr = this.findRecursive(this.ui.bubbleAttributes, {name});
		let paneAttr = this.findRecursive(this.ui.docViewAttributesAndModels, {name});
		let contexOffAttr = this.findRecursive(this.ui.contextOffAttributesAndModels, {name});
		let selectedAttr = _.findWhere(this.visualProps.columns, {name});
		if (tableAttr) tableAttr._disabled = !available;
		if (bubbleAttr) bubbleAttr._disabled = !available;
		if (paneAttr) paneAttr._disabled = !available;
		if (contexOffAttr) contexOffAttr._disabled = !available;
		if (selectedAttr) selectedAttr.disabled = !available;
	}

	getSidePanesVisibleCount = () => {
		return _.countBy([this.visualProps.sentencePaneEnabled, this.visualProps.contextPaneEnabled]).true || 0;
	};

	private isModelSelected(model: any): boolean {
		return _.contains(this.props.selectedModels, model.id);
	}

	selectModel = (model: any): void => {
		let isSelected = this.isModelSelected(model);
		if (isSelected) {
			this.props.selectedModels.remove(model.id);
		} else {
			this.props.selectedModels.push(model.id);
		}
	};

	// remove attributes already disallowed at the account level
	filterAcctLevelHiddenModels = (allAttributesAndModels): Model[] => {
		return _.filter(allAttributesAndModels, (attr) => {
			return !_.find(this.ui.accountLvlHiddenModelsAndAttrs, {id: attr.id});
		});
	};

	isExplorerView = (): boolean => this.props.previewMode === PreviewMode.DOCUMENT;

	isPreview = (): boolean => !this.isExplorerView();

	hasCaseCreation = (): boolean => {
		return this.isDocumentPaneView()
			&& (this.engagorService.isIntegrationEnabled() || this.security.isTicketingEnabled());
	};

	hasTuningSuggestions = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.PRODUCT_FEEDBACK);
	};

	isTuningSuggestionsDisabled = (): boolean => !this.tuningSuggestionsSupported() || this.noTunableEnrichments();

	private tuningSuggestionsSupported = (): boolean => this.isPaneView() || this.isSentenceView();

	private noTunableEnrichments = (): boolean => {
		if (this.isPaneView()) {
			return false;
		}
		let availableColumns = _.chain(this.visualProps.columns)
			.filter(column => !column.disabled)
			.map(column => column.name)
			.value();
		return _.intersection(availableColumns, this.TUNING_SUGGESTIONS_ENRICHMENTS).isEmpty();
	};

	getTuningSuggestionsTitle = (): string => {
		if (!this.tuningSuggestionsSupported()) {
			return this.locale.getString('preview.tuningSuggestionsNotSupported');
		}
		if (this.noTunableEnrichments()) {
			return this.locale.getString('preview.noTunableEnrichments');
		}
		return '';
	};

	handleTableResizeEvent = (event: WidgetContentEvent) => {
		if (event.type === WidgetContentEventType.RESIZE_TABLE_COLUMNS) {
			const resizedColumns = event.args[0] as TableColumn<PreviewSentence>[];
			_.each(this.visualProps.columns, (column, i) => {
				column.width = resizedColumns[i].width;
			});
		}
	};
}


app.component('analyticPreviewSettings', {
	controller: AnalyticPreviewSettingsController,
	templateUrl: 'partials/widgets/settings/cb/components/analytic-preview-settings.html',
	bindings: {
		widget: '<',
		props: '<',
		visualProps: '<',
		utils: '<',
		updateAutoTitle: '&',
		updateChartSettings: '<',
		redrawTrigger: '<',
		notRecommendedItems: '<?',
		dashboardFiltersApplied: '<',
		loadingPromise: '<',
		addLoadingPromise: '<',
		intersectionObserver: '<'
	}
});
