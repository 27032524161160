import { CxLocaleService } from '@app/core';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { Observable, Subject } from 'rxjs';
import * as _ from 'underscore';


export type SentencePredefinedFilterStatistics = {[key in PredefinedFilterValue]?: number};

export class SentencePredefinedFilter {

	private readonly emptyStatistics: SentencePredefinedFilterStatistics = {
		All_Sent: 0,
		Neg_Sent: 0,
		Neutral_Sent: 0,
		Positive_Sent: 0,
		Mixed_ML_Sent: 0
	};

	private statistics: SentencePredefinedFilterStatistics;

	private changeSubject = new Subject<void>();

	constructor(private locale: CxLocaleService) {
		this.statistics = {};
	}

	getChangeObserver(): Observable<void> {
		return this.changeSubject;
	}

	private formatNumber(num: number): string {
		if (num >= 1000000000) {
			return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
		}

		if (num >= 1000000) {
			return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
		}

		if (num >= 1000) {
			return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
		}

		return num + '';
	}

	getFormattedCount(field: PredefinedFilterValue): string {
		if (this.statistics && !_.isUndefined(this.statistics[field])) {
			return this.formatNumber(this.statistics[field]);
		}

		return this.locale.getString('widget.na');
	}

	getCount(field: PredefinedFilterValue): number {
		return this.statistics[field] || 0;
	}

	resetStatistics = () => {
		this.statistics = {};
		this.changeSubject.next();
	};

	updateStatistics = (stats: SentencePredefinedFilterStatistics) => {
		if (!stats) {
			this.resetStatistics();
		} else if (_.isArray(stats)) {
			// if empty, show zeros
			_.extend(this.statistics, this.emptyStatistics);
			if (stats.length) {
				_.extend(this.statistics, stats[0]);
			}
			this.changeSubject.next();
		} else {
			_.extend(this.statistics, this.emptyStatistics);
			_.extend(this.statistics, stats);
			this.changeSubject.next();
		}
	};


	isEmpty = (): boolean => {
		return this.statistics && this.statistics[PredefinedFilterValue.ALL] === 0;
	};

}
