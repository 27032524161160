import { Injectable, Inject, EventEmitter } from '@angular/core';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { CxLocaleService } from '@app/core';
import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { NarrativeSettingEntry } from './narrative-settings-list.component';
import { NarrativeSettingsModalComponent, INarrativeSettingsModalInput } from './narrative-settings-modal.component';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { NarrativeSettingsApi } from '@app/modules/account-administration/automated-narrative/narrative-settings-api.service';

@Injectable()
export class NarrativeSettingsListActions {

	onChange = new EventEmitter<void>();

	constructor(
		private cxDialogService: CxDialogService,
		private readonly narrativeSettingsApi: NarrativeSettingsApi,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService,
		private locale: CxLocaleService,
		private attributesService: AttributesService
	) {

	}

	edit(settingsEntry: NarrativeSettingEntry): void {
		this.attributesService.getAttributes(settingsEntry)
			.then((attributes) => {
				let input = {
					entry: settingsEntry,
					attributes: _.filter(attributes, attribute => attribute.type === AttributeType.TEXT)
				} as INarrativeSettingsModalInput;
				this.cxDialogService.openDialog(NarrativeSettingsModalComponent, input, {size: ModalSize.SMALL}).result
					.then((entry) => {
						if (entry.id) {
							this.updateSettings(entry);
						} else {
							this.saveSettings(entry);
						}
					})
					.catch(() => {});
			});
	}

	private saveSettings = (settingsEntry: NarrativeSettingEntry): void => {
		this.narrativeSettingsApi.saveNarrativeSettings(settingsEntry).then(() => {
			this.globalNotificationService.addSuccessNotification(this.locale.getString('appearance.narrativeSettingsSavedToast'));
			this.onChange.emit();
		});
	};

	private updateSettings = (settingsEntry: NarrativeSettingEntry, customMessage?: string): void => {
		this.narrativeSettingsApi.updateNarrativeSettings(settingsEntry).then(() => {
			this.globalNotificationService.addSuccessNotification(customMessage || this.locale.getString('appearance.narrativeSettingsSavedToast'));
			this.onChange.emit();
		});
	};

	delete(settings: NarrativeSettingEntry): void {
		this.cbDialogService.confirm(this.locale.getString('administration.delete'),
			this.locale.getString('appearance.deleteNarrativeWarning', {name: settings.name})).result
			.then((result) => {
				this.narrativeSettingsApi.deleteNarrativeSettings(settings).then(() => {
					this.globalNotificationService.addSuccessNotification(this.locale.getString('common.itemDeleted', {name: settings.name}));
					this.onChange.emit();
				});
			});
	}

	toggle(settings: NarrativeSettingEntry): void {
		settings.enabled = !settings.enabled;
		let confirmationMessage = settings.enabled ?
			this.locale.getString('common.itemEnabled', {itemName: settings.name}) :
			this.locale.getString('common.itemDisabled', {itemName: settings.name});

		return this.updateSettings(settings, confirmationMessage);
	}
}
