import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import Group from '@cxstudio/user-administration/groups/Group';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import * as _ from 'underscore';

@Injectable({
	providedIn: 'root'
})
export class UsersGroupsLogicService {
	constructor(
		private locale: CxLocaleService
	) {}


	findAddedGroups(initialGroups: Group[], resultGroups: Group[]): Group[] {
		let returnGroups = [];
		if (!initialGroups || !initialGroups.length) {
			return returnGroups;
		}
		initialGroups.forEach((group: Group) => {
			if (!group.selected) {
				//only care about unselected groups that become selected
				let groupInResult = _.findWhere(resultGroups, {groupId: group.groupId});
				if (groupInResult && groupInResult.selected) {
					returnGroups.push(groupInResult);
				}
			}
		});
		return returnGroups;
	}

	findRemovedGroups(initialGroups: Group[], resultGroups: Group[]): Group[] {
		let returnGroups = [];

		if (!initialGroups || !initialGroups.length) {
			return returnGroups;
		}
		initialGroups.forEach((group: Group) => {
			if (group.selected) {
				//only care about selected groups that become unselected
				let groupInResult = _.findWhere(resultGroups, {groupId: group.groupId});
				if (groupInResult && !groupInResult.selected) {
					returnGroups.push(groupInResult);
				}
			}
		});
		return returnGroups;
	}

	processGroupsForPersonalizedHierarchy = (groups) => {
		let newList = [];
		_.each(groups, (group: any) => {
			if (group.entity.groupType === GroupType.WHOLE_HIERARCHY) {
				group.isOrganizationGroup = true;
				group.iconType = 'organizationGroup';
				group.displayName = this.locale.getString('dashboard.orgLabel') + ': ' + group._name;
				group.entity.displayName = group.displayName;
				newList.push(group);
			} else {
				group.entity.displayName = group.displayName;
				newList.push(group);
			}
		});
		return newList;
	};

	isUnsafeName(name: string): boolean {
		const regExp = /^[|;@+=:]/;
		if (!name) {
			return false;
		}
		return regExp.test(name);
	}
}

app.service('usersGroupsService', downgradeInjectable(UsersGroupsLogicService));
