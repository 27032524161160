import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import * as isNil from 'lodash.isnil';

@Injectable({
	providedIn: 'root'
})
export class TimezoneService {

	readonly UtcOffsets = [
		'-12:00',
		'-11:00',
		'-10:00',
		'-09:30',
		'-09:00',
		'-08:00',
		'-07:00',
		'-06:00',
		'-05:00',
		'-04:00',
		'-03:30',
		'-03:00',
		'-02:00',
		'-01:00',
		'±00:00',
		'+01:00',
		'+02:00',
		'+03:00',
		'+03:30',
		'+04:00',
		'+04:30',
		'+05:00',
		'+05:30',
		'+05:45',
		'+06:00',
		'+06:30',
		'+07:00',
		'+08:00',
		'+08:30',
		'+08:45',
		'+09:00',
		'+09:30',
		'+10:00',
		'+10:30',
		'+11:00',
		'+12:00',
		'+12:45',
		'+13:00',
		'+14:00'
	];

	constructor(
		private readonly locale: CxLocaleService
	) { }

	getTimezoneOffset = () => {
		let date = new Date();
		let offset = -1 * date.getTimezoneOffset();
		return this.convertMinutesToOffset(offset);
	};

	// this can probably be refactored to reuse the convertMinutesToOffset method below
	offsetToString = (offset, usingUTC: boolean = false, unit: number = 1): string => {

		if (isNil(offset))
			return;


		let minutes = parseFloat(offset) * unit;
		let prefix = usingUTC ? this.locale.getString('common.UTC') : this.locale.getString('common.GMT');

		let template = prefix + '<%=plusMinus%><%=hh%>' + (usingUTC ? ':' : '') + '<%=mm%>';
		return this.convertMinutesToOffset(minutes, template);
	};

	// take a number of minutes and convert it to +/-HMM
	convertMinutesToOffset = (minutes: number, template?: string): string => {

		let _template = template || '<%=plusMinus%><%=hh%><%=mm%>';
		let absMinutes = Math.abs(minutes);
		let settings = {
			plusMinus: minutes > -1 ? '+' : '-',
			hh: String('0' + Math.floor(absMinutes / 60)).right(2),
			mm: String('0' + absMinutes % 60).right(2)
		};

		return _.template(_template)(settings);
	};

	convertOffsetStringToMinutes = (offsetString: string): number => {
		let plusMinus = offsetString[0];
		let minutes = Number(offsetString.right(2));
		let hours = Number(offsetString.slice(0, offsetString.length - 3).substring(1));
		let totalMinutes = (hours * 60) + minutes;

		return (plusMinus === '-' ? -1 : 1) * totalMinutes;
	};

	getUTCOffsets = (): Array<{value: string; displayName: string}> => {
		let UTC = this.locale.getString('common.UTC');

		return this.UtcOffsets.map((offset) => {
			return {
				value: String(this.convertOffsetStringToMinutes(offset)),
				displayName: `${UTC}${offset}`
			};
		});
	};
}


app.service('timezoneService', downgradeInjectable(TimezoneService));
