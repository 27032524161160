
import * as _ from 'underscore';
import { DocExplorerFavoriteUtils } from '@app/modules/document-explorer/context-pane/doc-explorer-favorite-utils.class';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { FavoriteAttribute, FavoriteId, FavoriteType } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { AmplitudeDocumentType } from '../document-type-utils.class';

export interface IPreviewWidgetFavorites {
	isItemFavorite: (favoriteItem: FavoriteAttribute, type: FavoriteType) => boolean;
	toggleFavorite: (favoriteItem: FavoriteAttribute, type: FavoriteType, documentId?: number, documentType?: AmplitudeDocumentType) => void;
	update: (attributes: any[], worldAwareness: any[], models: any[]) => void;
	sync: () => void; //need to sync with changes outside, e.g preview widget update favorites items
	getFavoriteScorecardId: () => number;
	toggleFavoriteScorecard: (item: FavoriteAttribute) => void; // make sure only one favorite scorecard
	favoriteAttributesDisplay: FavoriteAttribute[];
	favoriteAttributes: FavoriteId[];
}
export class DocExplorerFavorites implements IPreviewWidgetFavorites {
	favoriteAttributesDisplay: FavoriteAttribute[] = [];
	favoriteAttributes: FavoriteId[];

	private preferences: DocViewPreferences;
	private contentProviderId: number;
	private accountId: number;
	private projectId: number;

	constructor(preferences, contentProviderId?: number, accountId?: number, projectId?: number) {
		this.preferences = preferences;
		this.contentProviderId = contentProviderId;
		this.accountId = accountId;
		this.projectId = projectId;

		this.sync();
	}

	private getFavoriteItemIndex = (favoriteItem: FavoriteAttribute, type: FavoriteType): number => {
		let findItem = DocExplorerFavoriteUtils.getFavoriteId(favoriteItem, type);
		return _.findIndex(this.favoriteAttributes, findItem);
	};

	getFavoriteScorecardId = (): number => {
		let scorecard = _.findWhere(this.favoriteAttributes, {type: FavoriteType.SCORECARDS});
		return scorecard ? scorecard.scorecardId : null;
	};

	toggleFavoriteScorecard = (item: FavoriteAttribute): void => {
		let scorecard = _.findWhere(this.favoriteAttributes, {type: FavoriteType.SCORECARDS});
		if (scorecard) {
			if (scorecard.scorecardId === item.scorecardId) {
				this.favoriteAttributes = _.without(this.favoriteAttributes, scorecard);
			} else {
				scorecard.scorecardId = item.scorecardId;
			}
		} else {
			this.favoriteAttributes.push(DocExplorerFavoriteUtils.getFavoriteId(item, FavoriteType.SCORECARDS));
		}

		if (this.preferences) {
			this.preferences.updateSavedFavorites(this.contentProviderId, this.accountId, this.projectId,
				this.favoriteAttributes);
		}
	};

	isItemFavorite = (favoriteItem: FavoriteAttribute, type: FavoriteType): boolean => {
		return this.getFavoriteItemIndex(favoriteItem, type) > -1;
	};

	toggleFavorite = (favoriteItem: FavoriteAttribute, type: FavoriteType, documentId?: number,
		documentType?: AmplitudeDocumentType): void => {
		if (!this.isItemFavorite(favoriteItem, type)) {
			favoriteItem.favoriteType = type;
			this.favoriteAttributes = this.favoriteAttributes || [];

			// store only the parts we need
			let pushItem = DocExplorerFavoriteUtils.getFavoriteId(favoriteItem, type);

			this.favoriteAttributes = this.favoriteAttributes.concat([pushItem]);
			this.favoriteAttributesDisplay = this.favoriteAttributesDisplay.concat([favoriteItem]);

			const { source } = AmplitudeEventUtils.getBaseDocumentViewEvent({} as CbDocument, true);
			const tab = AmplitudeEventUtils.getAmplitudeTabName(type);

			AmplitudeAnalyticsService.trackEvent(
				AmplitudeEvent.DOCEXPLORER_KEYINFO_ADD,
				{ documentId },
				{ source, tab, documentType }
			);
		} else {
			let attrIndex = this.getFavoriteItemIndex(favoriteItem, type);
			this.favoriteAttributes = _.reject(this.favoriteAttributes, (item, index) => index === attrIndex);

			let itemToRemove = DocExplorerFavoriteUtils.getFavoriteId(favoriteItem, type);
			let favoriteAttributeToRemove = _.find(this.favoriteAttributesDisplay, (attr) => {
				return _.isEqual(DocExplorerFavoriteUtils.getFavoriteId(attr, attr.favoriteType), itemToRemove);
			});
			this.favoriteAttributesDisplay = _.without(this.favoriteAttributesDisplay, favoriteAttributeToRemove);
		}

		if (this.preferences) {
			this.preferences.updateSavedFavorites(this.contentProviderId, this.accountId, this.projectId,
				this.favoriteAttributes);
		}
	};

	update = (attributes, worldAwareness, models) => {
		this.favoriteAttributesDisplay = [];

		_.map(this.favoriteAttributes, (attr: FavoriteId) => {
			let itemAvailableForDisplay: FavoriteAttribute;

			if (!_.isUndefined(attr.modelId)) {
				itemAvailableForDisplay = _.find(models, {modelId: attr.modelId});
				if (itemAvailableForDisplay)
					itemAvailableForDisplay.favoriteType = FavoriteType.MODEL;
			} else {
				itemAvailableForDisplay = _.find(attributes, (attribute: any): boolean => {
					return attribute.name.toLowerCase()  === attr.name;
				});

				if (itemAvailableForDisplay) {
					itemAvailableForDisplay.favoriteType = FavoriteType.ATTRIBUTE;
				} else {
					itemAvailableForDisplay = _.find(worldAwareness, (attribute: any): boolean => {
						return attribute.name.toLowerCase()  === attr.name;
					});
					if (itemAvailableForDisplay)
						itemAvailableForDisplay.favoriteType = FavoriteType.WORLD_AWARENESS;
				}
			}

			if (itemAvailableForDisplay) {
				this.favoriteAttributesDisplay.push(itemAvailableForDisplay);
			}
		});
	};

	sync = (): void => {
		if (this.preferences) {
			this.favoriteAttributes =
				this.preferences.getSavedFavorites(this.contentProviderId, this.accountId, this.projectId) || [];
			this.favoriteAttributes.forEach((attr) => {
				if (attr && attr.name) {
					attr.name = attr.name.toLowerCase();
				}
			});
		}
	};
}
