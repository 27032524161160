import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';

@Injectable()
export class FormattedTableColumnsService {

	constructor(private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService) {

	}

	getValidateErrorColumns(): Array<TableColumn<any>> {
		let columns = new Array<TableColumn<any>>();
		columns.push({
			name: 'errorRow',
			displayName: this.locale.getString('common.row'),
			formatter: this.formatRowNumber,
			width: 0.15
		});
		columns.push({
			name: 'errorMessage',
			displayName: this.locale.getString('common.error'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.85
		});
		return columns;
	}

	private formatRowNumber = (errorMessageObject: any): string => {
		return errorMessageObject.errorRow > 0
			? errorMessageObject.errorRow
			: 'N/A';
	};

}
app.service('formattedTableColumnsService', downgradeInjectable(FormattedTableColumnsService));
