import { HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { PromiseQueue, SequentialPromiseQueue } from '@app/modules/dashboard-edit/promise-queue';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import Widget, { IWidgetBox } from '@cxstudio/dashboards/widgets/widget';
import { WidgetTemplateDTO } from '@cxstudio/dashboards/widgets/widget-template.class';

@Injectable({
	providedIn: 'root'
})
export class WidgetApiService {

	private sequentialQueue: PromiseQueue;

	constructor(
		private http: CxHttpService,
		private cachedHttpService: CxCachedHttpService,
		@Inject('$rootScope') private $rootScope: ng.IRootScopeService,
	) {
		this.sequentialQueue = new SequentialPromiseQueue();
	}

	getDashboardWidgets = (dashboardId: number, isPdfExport?: boolean): Promise<Widget[]> => {
		let options;
		if (this.$rootScope.pdfToken || isPdfExport) {
			options = {
				headers: {
					pdf: true
				}
			};
		}
		return this.http.get(`rest/widgets/${dashboardId}`, options);
	};

	getWidgetTemplates = (): Promise<WidgetTemplateDTO[]> => {
		return this.cachedHttpService.cache(Caches.WIDGET_TEMPLATES).get('rest/widgets/templates', { cache: CacheOptions.CACHED });
	};

	saveWidgets = (dashboardId: number, widgetsData: Widget[]): Promise<void> => {
		return this.sequentialQueue.execute(() => this.http.put(`rest/widgets/save/${dashboardId}`, widgetsData));
	};

	saveWidgetsTempState = (dashboardId: number, widgetsData: Widget[]): Promise<number> => {
		return this.sequentialQueue.execute(() =>
			this.http.post(`rest/widgets/${dashboardId}/history`, widgetsData));
	};

	restoreWidgetsTempState = (dashboardId: number, versionId: number): Promise<Widget[]> => {
		return this.sequentialQueue.execute(() =>
			this.http.delete(`rest/widgets/${dashboardId}/history/${versionId}`));
	};

	updateWidget = (dashboardId: number, widget: Widget): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.put(`rest/widgets/${dashboardId}/${widget.id}`, widget));
	};

	addWidget = (dashboardId: number, widget: Widget): Promise<number> => {
		return this.sequentialQueue.execute(() =>
			this.http.post(`rest/widgets/${dashboardId}/`, widget));
	};

	addWidgetsBulk = (dashboardId: number, widgets: Widget[]): Promise<number[]> => {
		return this.sequentialQueue.execute(() =>
			this.http.post(`rest/widgets/${dashboardId}/bulk-create`, widgets));
	};

	deleteWidget = (dashboardId: number, widget: Widget): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.delete(`rest/widgets/${dashboardId}/${widget.id}`));
	};

	deleteWidgetsBulk = (dashboardId: number, widgetIds: number[]): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.post(`rest/widgets/${dashboardId}/bulk-delete`, widgetIds));
	};

	updateLinking = (dashboardId: number, widgetsLinkingMap: {[widgetId: number]: number[]}): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.post(`rest/widgets/${dashboardId}/linking`, widgetsLinkingMap));
	};

	updateLayout = (dashboardId: number, widgetLayoutData: {[widgetId: number]: IWidgetBox}): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.put(`rest/widgets/${dashboardId}/layout`, widgetLayoutData));
	};

	copyWidgets = (targetDashboardId: number, widgets: Widget[]): Promise<Widget[]> => {
		return this.http.post(`rest/widgets/copy/${targetDashboardId}/`, widgets);
	};

	checkDashboardHasWidgets = (
		dashboardId: number, widgetTypes: string[], projectIds: number[]
	): Promise<{hasWidgetTypes: boolean; hasProjects: boolean}> => {
		if (_.isEmpty(widgetTypes) && _.isEmpty(projectIds)) {
			return Promise.resolve({hasWidgetTypes: false, hasProjects: false});
		}
		let projectIdsParam = _.map(projectIds, id => id.toString());
		return this.http.get(`rest/widgets/dashboard/${dashboardId}/contains`, {
			params: new HttpParams({fromObject: { type: widgetTypes, project: projectIdsParam }})
		});
	};

	updateWidgetOwnership = (dashboardId: number, widgetId: number, newOwnerEmail: string): Promise<void> => {
		return this.sequentialQueue.execute(() =>
			this.http.put(`rest/widgets/ownership/${dashboardId}/${widgetId}`, newOwnerEmail));
	};
}

app.service('widgetApiService', downgradeInjectable(WidgetApiService));

