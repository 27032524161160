import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DocumentExplorerEvents, Node, PillsNavigation } from '@app/modules/document-explorer/events/document-explorer-events';
import { DocViewPaneSettings } from '@app/modules/document-explorer/preferences/doc-view-pane-settings';
import { SentencePreviewActionEvent } from '@app/modules/document-explorer/sentence-preview/sentence-preview.component';
import { SentenceScrollHelper } from '@app/modules/document-explorer/sentence-scroll-helper';
import { PillsUtils } from '@app/modules/pills/pills-utils';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ResizeHandlerUtils } from '@app/shared/util/resize-handler-utils.class';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { CssClasses } from '@app/util/css-classes';
import { RandomUtils } from '@app/util/random-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { ConversationStyleUtils } from '@cxstudio/conversation/conversation-style-utils.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { TopicRemoveEvent } from '@cxstudio/reports/document-explorer/components/primary-pane-component';
import { IMetricFormatters } from '@cxstudio/reports/document-explorer/conversations/conversation.component';
import { SuggestionMenu } from '@cxstudio/reports/document-explorer/conversations/suggestion-menu.service';
import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { PreviewDocument } from '@cxstudio/reports/document-explorer/preview-document';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Pill } from '@app/modules/pills/pill';
import { CSSUtils } from '@app/util/css-utils.class';

@Component({
	selector: 'feedback-verbatim',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div id="{{styleId}}" [ngClass]="visibilityClasses">
		<p class="mb-8">
			<b>{{(type ? type : '').underscoreToSpace().toLowerCase().capitalizeFirstLetter() }}:</b>
		</p>
		<feedback-sentences
			[sentences]="sentences"
			[deselectedModelsIds]="deselectedModelsIds"
			[showEnrichment]="showSentiment"
			[sentimentColorFunc]="sentimentColorFunc"
			[selectedSentence]="selectedSentence"
			[auditMode]="auditMode"
			[auditModeModels]="auditModeModels"
			[formatters]="formatters"
			[showTopics]="uiOptions?.showTopics"
			[expandTopics]="uiOptions?.expandTopics"
			[leafOnly]="uiOptions?.leafOnly"
			[messageWidth]="messageWidth"
			[highlightTrigger]="highlightTrigger"
			(tuneEnrichment)="onTuneEnrichment($event.pill, $event.sentence, $event.event)"
			(sentenceClick)="openSuggestionMenu($event)"
			(removeTopic)="onRemoveTopic($event)"
			[predefinedMetrics]="predefinedMetrics">
		</feedback-sentences>
		<translated-text *ngIf="translate"
			class="d-flex mt-8"
			[cacheKey]="uniqueId"
			[text]="getVerbatimText()">
		</translated-text>
		<div #selectedNodeStyle></div>
	</div>
	`
})
export class FeedbackVerbatimComponent extends SelfCleaningComponent implements OnInit, AfterViewInit, OnChanges {
	@Input() uniqueId: string;
	@Input() document: PreviewDocument;
	@Input() sentences: PreviewSentence[];
	@Input() type: string;
	@Input() selectedSentence: number;
	@Input() selectedScorecard: number;
	@Input() showSentiment: boolean;
	@Input() auditMode: boolean;
	@Input() auditModeModels: IReportModel[];
	@Input() translate: boolean;
	@Input() sentimentColorFunc;
	@Input() formatters: IMetricFormatters;
	@Input() uiOptions: DocViewPaneSettings;
	@Input() actionColor: string;
	@Input() selectedModelsFilter: (modelId: number) => boolean;
	@Input() documentManager: IDocumentPreviewerControls;
	@Input() predefinedMetrics: Metric[];

	@ViewChild('selectedNodeStyle', {static: false}) selectedNodeStyle: ElementRef;
	@ViewChild('modelVisibilityStyle', {static: false}) modelVisibilityStyle: ElementRef;

	messageWidth: number;
	highlightTrigger: number = 0;

	styleId: string;
	visibilityClasses: CssClasses;

	private element: JQuery;

	private readonly debouncedResize = new Subject<void>();

	deselectedModelsIds: number[];
	private sentenceScrollHelper: SentenceScrollHelper;

	constructor(
		private readonly elementRef: ElementRef,
		private readonly ref: ChangeDetectorRef,
		@Inject('suggestionMenu') private readonly suggestionMenu: SuggestionMenu,
	) {
		super();
	}

	ngOnInit(): void {
		this.element = $(this.elementRef.nativeElement);
		this.styleId = `s${RandomUtils.randomString()}`; // used as id in html, so must begin with a letter
		this.updateWidth();
		if (!_.isEmpty(this.document.scorecards)) {
			this.element.find('#scorecard-style').remove();
			let css = ConversationStyleUtils.getScorecardVisibilityStyles(this.document.scorecards);
			let styleElement = `<style id="scorecard-style" type="text/css">${css}</style>`;
			this.element.append(styleElement);
		}
		this.updateScrollHelper();
		this.updateVisibilityClasses();
		let events: DocumentExplorerEvents = this.documentManager.events;

		this.addSubscription(events.getChangeSettingsObservable().subscribe(this.settingsChangeHandler));
		this.addSubscription(events.getHighlightModelObservable().subscribe(this.processModelHighlight));
		this.addSubscription(events.getHighlightWorldAwarenessObservable().subscribe(this.processWorldAwarenessHighlight));
		this.addSubscription(events.getClearHighlightingObservable().subscribe(this.processClearHighlight));
		this.addSubscription(events.getRedrawTopicsObservable().subscribe(this.processModelVisibility));

		this.deselectedModelsIds = PillsUtils.getDeselectedModelsIds(this.documentManager.availableModels, this.selectedModelsFilter);
	}

	ngAfterViewInit(): void {
		this.updateWidth();
		this.addSubscription(this.debouncedResize.pipe(debounceTime(150)).subscribe(() => this.updateWidth()));
		let widthElement = this.elementRef.nativeElement.firstElementChild;
		this.addResizeObserver(ResizeHandlerUtils.addResizeHandler(widthElement, () => this.debouncedResize.next()));
	}

	ngOnChanges(changes: SimpleChanges<FeedbackVerbatimComponent>): void {
		if (ChangeUtils.hasChange(changes.document)) {
			this.updateScrollHelper();
		}
		if (ChangeUtils.hasChange(changes.selectedScorecard)) {
			this.updateVisibilityClasses();
		}
		if (ChangeUtils.hasChange(changes.showSentiment)) {
			this.updateVisibilityClasses();
		}
	}

	private readonly updateScrollHelper = (): void => {
		this.sentenceScrollHelper = new SentenceScrollHelper(this.document.sentences, this.documentManager.widget?.id);
	};

	updateVisibilityClasses = (): void => {
		let result = {
			'use-pills': true,
			'hide-sentiment-formatting': !this.showSentiment,
			'hide-topics': this.uiOptions && !this.uiOptions.showTopics,
		};

		if (this.selectedScorecard)
			result[`show-scorecard-${this.selectedScorecard}`] = this.showSentiment;
		this.visibilityClasses = result;
		this.ref.markForCheck();
	};

	settingsChangeHandler = (): void => {
		this.updateVisibilityClasses();
	};

	updateWidth = (): void => {
		this.messageWidth = Math.floor(this.element.outerWidth());
		this.ref.markForCheck();
	};

	processModelVisibility = (): void => {
		this.deselectedModelsIds = PillsUtils.getDeselectedModelsIds(this.documentManager.availableModels, this.selectedModelsFilter);
		this.ref.detectChanges();
	};

	processModelHighlight = ($event: PillsNavigation): void => {
		const node = $event.node;
		this.highLightTopics(node);
		this.refreshHighlighting();
		this.populateSelectedNodeStyle(node);
		const targetSentence = this.sentenceScrollHelper.getNextTopicSentence(node, $event.direction);
		this.sentenceScrollHelper.scrollToSentence(targetSentence);
		if (this.documentManager.state?.highlightingTopicResolve) {
			this.documentManager.state.highlightingTopicResolve();
		}
	};

	processWorldAwarenessHighlight = ($event: PillsNavigation): void => {
		const node = $event.node;
		this.refreshHighlighting();
		this.populateSelectedNodeStyle(node);
		const targetSentence = this.sentenceScrollHelper.getNextWorldAwarenessSentence(node, $event.direction);
		this.sentenceScrollHelper.scrollToSentence(targetSentence);
	};

	processClearHighlight = (): void => {
		this.refreshHighlighting();
		this.clearSelectedNodeStyle();
	};

	private readonly clearSelectedNodeStyle = (): void => {
		this.selectedNodeStyle.nativeElement.replaceChildren();
		this.ref.detectChanges();
	};

	private readonly populateSelectedNodeStyle = (node: Node): void => {
		if (node) {
			let nodeIdentifierClass: string = this.getNodeIdentifierClass(node);
			let styleTag = this.getHighlightStyle(nodeIdentifierClass);
			this.selectedNodeStyle.nativeElement.replaceChildren(styleTag);
		} else {
			this.selectedNodeStyle.nativeElement.replaceChildren();
		}
		this.ref.detectChanges();
	};

	private readonly getNodeIdentifierClass = (node: Node): string => {
		return node.id ? `topic-${node.id}` : `enrichment-${node.name}`;
	};

	private readonly getHighlightStyle = (nodeIdentifierClass: string): HTMLStyleElement => {
		return CSSUtils.getStyleTag(`#${this.styleId} .${nodeIdentifierClass} {
					border-color: ${this.actionColor} !important;
				}`);
	};

	highLightTopics = (node: Node): void => {
		_.chain(this.document.sentences)
			.map((dataItem: PreviewSentence): void => {
				let topic = _.findWhere(dataItem.sentenceTopics, {id: node.id});
				if (topic)
					topic.selected = true;
			});
	};

	onRemoveTopic = (event: TopicRemoveEvent): void => {
		this.suggestionMenu.suggestTopicRemoval(event, this.documentManager);
	};

	openSuggestionMenu = ({event, sentence}: SentencePreviewActionEvent): void => {
		if (this.auditMode && event)
			this.suggestionMenu.openSuggestionMenuFromDocumentPane(event, sentence, this.documentManager, this.uiOptions?.leafOnly)
				.then(() => this.refreshHighlighting());
	};

	onTuneEnrichment = (pill: Pill, sentence: PreviewSentence, event: MouseEvent) => {
		if (this.auditMode && event) {
			this.suggestionMenu.openSuggestionMenuFromDocumentPane(
				event,
				sentence,
				this.documentManager,
				this.uiOptions?.leafOnly,
				pill
			).then(() => {
				this.refreshHighlighting();
			});
		}
	};

	private refreshHighlighting() {
		this.highlightTrigger++;
		this.ref.markForCheck();
	}

	getVerbatimText(): string {
		return _.map(this.sentences, sentence => sentence.text).join(' ');
	}
}

app.directive('feedbackVerbatim', downgradeComponent({component: FeedbackVerbatimComponent}));
