import * as _ from 'underscore';
import { Security } from '@cxstudio/auth/security-service';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import HierarchySettingsService, { IHierarchySettings } from './hierarchy-settings.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import MetricSettingsService, { IMetricSettings } from '@cxstudio/metrics/metric-settings.service';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import ScorecardMetricsService from '@cxstudio/projects/scorecards/scorecard-metrics.service';
import { ScorecardMetric } from '@cxstudio/projects/scorecards/entities/scorecard-metric';
import { GeographyApiService } from '@cxstudio/attribute-geography/geography-api.service';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import EventType from '@cxstudio/services/event/event-type.enum';
import { DatePeriodUtils } from '@cxstudio/reports/utils/analytic/date-period-utils.service';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import { DatePeriod } from '@cxstudio/reports/entities/date-period';
import { WidgetColorPalette } from '@cxstudio/reports/coloring/entities/widget-color-palette';
import { ColorPalettes } from '@cxstudio/reports/coloring/color-palettes.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';

export interface IBaseWidgetSettings extends IProjectSettings {
	dateFilters: IFilter[];
}

export interface IWidgetSettings extends IBaseWidgetSettings, IMetricSettings, IHierarchySettings {
	drivers: DriversItem[];
	scorecardMetrics: ScorecardMetric[];
	scorecards?: Scorecard[];
	withDocumentLevelOnly?: boolean;
	studioPalettes?: WidgetColorPalette[];
	providerColors?: string[];
}

export interface IWidgetSettingsConfig {
	withDrivers?: boolean;
	withScorecardMetrics?: boolean;
	withPalettes?: boolean;
}

export default class WidgetSettingsService {

	constructor(
		private $q: ng.IQService,
		private security: Security,
		private driversApi: DriversApi,
		private projectSettingsService: ProjectSettingsService,
		private metricSettingsService: MetricSettingsService,
		private scorecardMetricsService: ScorecardMetricsService,
		private hierarchySettingsService: HierarchySettingsService,
		private geographyApiService: GeographyApiService,
		private datePeriodUtils: DatePeriodUtils,
		private eventEmitterService: EventEmitterService,
		private reportAssetUtilsService: ReportAssetUtilsService,
		private readonly reportFiltersService: ReportFiltersService,
		private colorPalettes: ColorPalettes,
	) {}

	getBaseWidgetSettings = (props: WidgetProperties): ng.IPromise<IBaseWidgetSettings> => {
		let project = this.reportAssetUtilsService.getWidgetPropertiesProject(props);
		let projectSettingsPromise = this.projectSettingsService.getSettings(project);
		let dateFiltersPromise = PromiseUtils.old(this.reportFiltersService.getWidgetPropertiesDateFilters(props));
		let projectGeographiesPromise = props.widgetType === WidgetType.MAP
			? this.geographyApiService.getProjectGeographies(props.contentProviderId, props.accountId, props.project, props.runAs)
			: this.$q.when({ modelGeographies: [], attributeGeographies: [] });
		return this.$q.all([
			projectSettingsPromise,
			dateFiltersPromise,
			projectGeographiesPromise
		]).then((result: any[]): IBaseWidgetSettings => {
			return {
				attributes: result[0].attributes,
				wordAttributes: result[0].wordAttributes,
				models: result[0].models,
				projectContext: result[0].projectContext,
				dateFilters: result[1],
				projectGeographies: result[2]
			};
		});
	};

	getWidgetSettings = (props: WidgetProperties, config?: IWidgetSettingsConfig): ng.IPromise<IWidgetSettings> => {
		config = _.extend({
			withPalettes: true
		} as IWidgetSettingsConfig, config);

		let project = new ProjectIdentifier(props.contentProviderId, props.accountId, props.project);
		let baseSettingsPromise = this.getBaseWidgetSettings(props);
		let metricSettingsPromise = this.metricSettingsService.getSettings(project);
		let hierarchySettingsPromise = this.hierarchySettingsService.getWidgetSettings(props);

		let driversPromise = config.withDrivers && this.security.has('manage_drivers')
			? PromiseUtils.old(this.driversApi.getReportableDrivers(props.contentProviderId, props.accountId))
			: this.$q.when([]);

		let scorecardMetricsPromise = config.withScorecardMetrics
			? this.scorecardMetricsService.getFullScorecardMetrics(props.contentProviderId, props.accountId, props.project)
			: this.$q.when([]);
		let studioPalettesPromise = config.withPalettes ? this.colorPalettes.getWidgetPalettes() : this.$q.when([]);
		let designerColorsPromise = config.withPalettes ? this.colorPalettes.getWidgetDesignerPalette(props) : this.$q.when([]);

		let resultPromise = this.$q.all([
			baseSettingsPromise,
			metricSettingsPromise,
			hierarchySettingsPromise,
			driversPromise,
			scorecardMetricsPromise,
			studioPalettesPromise,
			designerColorsPromise,
		]).then((result: any[]): IWidgetSettings => {
			return {
				attributes: result[0].attributes,
				wordAttributes:  result[0].wordAttributes,
				models: result[0].models,
				projectContext: result[0].projectContext,
				dateFilters: result[0].dateFilters,
				projectGeographies: result[0].projectGeographies,
				predefinedMetrics: result[1].predefinedMetrics,
				metrics: result[1].metrics,
				hierarchyModels: result[2].hierarchyModels,
				hierarchyGrouping: result[2].hierarchyGrouping,
				organizationCalculations: result[2].organizationCalculations,
				drivers: result[3],
				scorecardMetrics: result[4],
				studioPalettes: result[5],
				providerColors: result[6],
			};
		});
		return resultPromise;
	};

	initializePeriods = (props: WidgetProperties, periods: DatePeriod[], force = false): void => {
		this.datePeriodUtils.initializePeriods(props, periods, force, () => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
			this.eventEmitterService.emit(EventType.UPDATE_DATE_RANGE);
		});
	};
}

app.service('widgetSettingsService', WidgetSettingsService);
