
import * as _ from 'underscore';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '../../projects/project-selection.interface';

export class CaseApiService {

	constructor(
		private $http: ng.IHttpService,
	) {}

	getCaseAttributes = (project: IProjectSelection): ng.IHttpPromise<any[]> => {
		return this.$http.get(`rest/cases/attributes/cp/${project.contentProviderId}/account/${project.accountId}/project/${project.projectId}`);
	};
	//Create this api
	getWorkspaceCaseAttributes = (workspaceProject: WorkspaceProject): ng.IHttpPromise<any[]> => {
		return this.$http.get(`rest/cases/attributes/ws/${workspaceProject.workspaceId}/project/${workspaceProject.projectId}`);
	};

	updateCaseAttributes = (contentProviderId: number, accountId: number, projectId: number,
		selectedAttributes: any[]): ng.IHttpPromise<void> => {
		return this.$http.post(`rest/cases/attributes/cp/${contentProviderId}/account/${accountId}/project/${projectId}`,
			angular.toJson(selectedAttributes));
	};

}

app.service('caseApiService', CaseApiService);
