import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeatureTracker, FeatureType, DistributionType } from '@app/modules/context/beta-features/beta-feature-tracker-class';
import { SystemAdministrationBetaFeatureApiService } from './system-administration-beta-feature-api-service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';

import * as _ from 'underscore';
import { downgradeComponent } from '@angular/upgrade/static';

interface FeatureGroup {
	name: string;
	features: BetaFeatureTracker[];
	type: FeatureType;
}

@Component({
	selector: 'admin-beta-features',
	templateUrl: './admin-beta-features.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdminBetaFeaturesComponent implements OnInit {
	@Input() loading: Promise<any>;
	featureGroups: FeatureGroup[];

	constructor(
		private cxDialogService: CxDialogService,
		private locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService,
		private systemAdministrationBetaFeatureApiService: SystemAdministrationBetaFeatureApiService,
		private toastService: ToastService,
	) { }

	ngOnInit(): void {
		this.featureGroups = [
			this.getFeatureGroup(FeatureType.BETA_FEATURE, 'administration.beta'),
			this.getFeatureGroup(FeatureType.CONTROLLED_RELEASE, 'administration.controlledRelease'),
			this.getFeatureGroup(FeatureType.DEV_TEST, 'administration.spike')
		];
	}

	private getFeatureGroup = (type: FeatureType, labelKey: string): FeatureGroup => {
		return {
			name: this.locale.getString(labelKey),
			features: BetaFeature.getGroup(type),
			type
		};
	};

	makeBetaFeatureGloballyUnavailable = (betaFeature: BetaFeatureTracker): void => {
		this.changeFeatureState(betaFeature,
				'administration.makeBetaFeatureGloballyUnavailablePrompt',
				'administration.makeBetaFeatureGloballyUnavailableSuccess',
				this.systemAdministrationBetaFeatureApiService.makeBetaFeatureGloballyUnavailable)
			.then(() => {
				let betaFeatureState = this.betaFeaturesService.getFeature(betaFeature);
				betaFeatureState.enabled = false;
				betaFeatureState.switchOn = false;
			});
	};

	disableBetaFeatureGlobally = (betaFeature: BetaFeatureTracker): void => {
		this.changeFeatureState(betaFeature,
				'administration.disableBetaFeatureGloballyPrompt',
				'administration.disableBetaFeatureGloballySuccess',
				this.systemAdministrationBetaFeatureApiService.disableBetaFeatureGlobally)
			.then(() => {
				let betaFeatureState = this.betaFeaturesService.getFeature(betaFeature);
				betaFeatureState.enabled = true;
				betaFeatureState.switchOn = false;
			});
	};

	enableBetaFeatureGlobally = (betaFeature: BetaFeatureTracker): void => {
		this.changeFeatureState(betaFeature,
				'administration.enableBetaFeatureGloballyPrompt',
				'administration.enableBetaFeatureGloballySuccess',
				this.systemAdministrationBetaFeatureApiService.enableBetaFeatureGlobally)
			.then(() => {
				let betaFeatureState = this.betaFeaturesService.getFeature(betaFeature);
				betaFeatureState.enabled = true;
				betaFeatureState.switchOn = true;
			});
	};

	disableInternally = (betaFeature: BetaFeatureTracker): void => {
		this.changeFeatureState(betaFeature,
				'administration.disableBetaFeatureInternallyPrompt',
				'administration.disableBetaFeatureInternallySuccess',
				this.systemAdministrationBetaFeatureApiService.disableBetaFeatureInternally);
	};

	enableInternally = (betaFeature: BetaFeatureTracker): void => {
		this.changeFeatureState(betaFeature,
				'administration.enableBetaFeatureInternallyPrompt',
				'administration.enableBetaFeatureInternallySuccess',
				this.systemAdministrationBetaFeatureApiService.enableBetaFeatureInternally);
	};

	private changeFeatureState = (betaFeature: BetaFeatureTracker, promptKey: string, confirmKey: string,
			action: (betaFeature: BetaFeatureTracker) => Promise<void>): Promise<void> => {
		let featureName = this.getFeatureName(betaFeature);
		let updatePromise = this.cxDialogService
			.regularWithConfirm(featureName, this.locale.getString(promptKey, { betaFeature: featureName }))
			.result
			.then(() => action(betaFeature))
			.then(() => this.toastService
				.addToast(this.locale.getString(confirmKey, { betaFeature: featureName }), AlertLevel.SUCCESS))
			.then(() => this.betaFeaturesService.refreshUserFeatureState(betaFeature), _.noop);
		this.loading = updatePromise;
		return updatePromise;
	};

	getFeatureName(betaFeature: BetaFeatureTracker): string {
		return this.locale.getString(`betaFeatureNames.${betaFeature.id}`);
	}

	hasMasterAccountDistribution = (betaFeature: BetaFeatureTracker): boolean => {
		return betaFeature.distribution.contains(DistributionType.MASTER_ACCOUNT);
	};

	hasInternalDistribution = (betaFeature: BetaFeatureTracker): boolean => {
		return betaFeature.distribution.contains(DistributionType.INTERNAL);
	};

	canEnableGlobally = (betaFeature: BetaFeatureTracker, featureGroup: FeatureGroup): boolean => {
		return this.hasMasterAccountDistribution(betaFeature) && (featureGroup.type !== FeatureType.DEV_TEST);
	};
}

app.directive('adminBetaFeatures', downgradeComponent({component: AdminBetaFeaturesComponent}));
