import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { AccountOrWorkspace, AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectAccessApiService } from '@app/modules/project/access/project-access-api.service';
import { AssetPromise, AssetPromisePlatformProject, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Security } from '@cxstudio/auth/security-service';
import { IAccountSelection } from '@app/modules/account/account-selection.interface';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { ProjectAccessLevelItems } from '@cxstudio/user-administration/users/project-access/project-access-levels';
import { AccountIdentifier } from '@app/modules/account/account-identifier';

@Injectable({ providedIn: 'root' })
export class ProjectAccessService {

	constructor(
		private readonly projectAccessApiService: ProjectAccessApiService,
		@Inject('ProjectAccessLevels') private readonly ProjectAccessLevels: ProjectAccessLevelItems,
		@Inject('contentProviderOptionsService') private readonly contentProviderOptionsService: ContentProviderOptionsService,
		@Inject('security') private readonly security: Security,
	) {}

	hasProjectAccess(project: AccountOrWorkspaceProject): AssetPromise<boolean> {
		return ProjectAssetsHelper.getAssetsForProject<boolean>(
			project,
			(accountProject) => this.hasOldProjectAccess(accountProject),
			(workspaceProject) => this.projectAccessApiService.hasProjectAccess(workspaceProject),
			(projectId) => false
		);
	}

	hasAdminProjectAccess(projectIdentifier: AccountOrWorkspaceProject): Promise<boolean> {
		let accountIdentifier = new AccountIdentifier(projectIdentifier.contentProviderId, projectIdentifier.accountId);
		return this.getProjectsAccessLevel(accountIdentifier).then(projectsAccess => {
			let projectAccessData = _.find(projectsAccess, (projectAccess) => {
				return projectAccess.project.id === projectIdentifier.projectId;
			});

			return projectAccessData?.accessLevel === this.ProjectAccessLevels.MANAGER.value;
		});
	}

	private hasOldProjectAccess(project: IProjectSelection): AssetPromisePlatformProject<boolean> {
		return this.contentProviderOptionsService.hasProjectDataAccess(
			project.contentProviderId, project.accountId, project.projectId,
			this.security.getUser().userId, CacheOptions.CACHED) as AssetPromisePlatformProject<boolean>;
	}

	getProjectsAccessLevel = (
		workspace: AccountOrWorkspace
	): Promise<ProjectAccess[]> => {
		return WorkspaceTransitionUtils.isWorkspace(workspace)
			? this.projectAccessApiService.getProjectsAccessLevel(workspace)
			: this.getOldProjectsAccess(workspace);
	};

	private getOldProjectsAccess(account: IAccountSelection): AssetPromisePlatformProject<ProjectAccess[]> {
		return this.contentProviderOptionsService.getProjectsAccessLevel(
			account.contentProviderId,
			account.accountId,
			this.security.loggedUser.user.userId,
			undefined,
		).then(result => result?.data?.data[0]?.projects || []) as AssetPromisePlatformProject<ProjectAccess[]>;
	}

}

app.service('projectAccessService', downgradeInjectable(ProjectAccessService));
