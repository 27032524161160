import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { UnifiedTemplate } from '@app/modules/unified-templates/common-templates/dto/unified-template';
import { DashboardTemplate } from '@app/modules/unified-templates/unified-templates-management/dashboard-templates-management/dashboard-templates-management.component';
import { DashboardTemplateTypes } from '@cxstudio/dashboard-templates/dashboard-template-types';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';

export class TypeGuards {
	static isString = (value: unknown): value is string => {
		return value !== undefined && value !== null && value.constructor === String;
	};

	static isNumber = (value: unknown): value is number => {
		return value !== undefined && value !== null && value.constructor === Number && !isNaN(value);
	};

	// eslint-disable-next-line @typescript-eslint/ban-types
	static isObject = (value: unknown): value is object => {
		return value !== undefined && value !== null && typeof value === 'object';
	};

	static isArray = <T = unknown>(value: unknown): value is Array<T> => {
		return Array.isArray(value);
	};

	static isHTMLElement = (value: unknown): value is HTMLElement => {
		return value instanceof HTMLElement;
	};

	static isLegacyDashboardTemplate = (value: unknown): value is DashboardTemplate => {
		return (value as any)?.type &&
			[DashboardTemplateTypes.CUSTOM, DashboardTemplateTypes.SYSTEM].contains((value as any).type) &&
			!TypeGuards.isUnifiedDashboardTemplate(value);
	};

	static isUnifiedDashboardTemplate = (value: unknown): value is UnifiedTemplate => {
		return (value as any)?.assetType === TemplateAssetType.DASHBOARD;
	};

	static isDashboard = (value: unknown): value is Dashboard => {
		return (value as any)?.type === ObjectType.DASHBOARD;
	};

	static isLinkElement = (value: HTMLElement): value is HTMLLinkElement => {
		return value && value instanceof HTMLLinkElement;
	};
}
