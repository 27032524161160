import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { EmailStatus } from './entities/email-status';
import EmailRecipient from './entities/email-recipient';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';

export interface StatusData {
	value: EmailStatus;
	label: string;
	class: string;
}

@Injectable({
	providedIn: 'root'
})
export class EmailsTableColumnsService {
	
	private statuses: {[key: string]: StatusData};

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {
		this.statuses = _.indexBy(this.getStatuses(), 'value');
	}

	getStatuses = (): StatusData[] => {
		return [{
			value: EmailStatus.QUEUED,
			label: this.locale.getString('administration.stateQueued'),
			class: 'alert-warning'
		}, {
			value: EmailStatus.PROCESSED,
			label: this.locale.getString('administration.stateProcessed'),
			class: 'alert-success'
		}, {
			value: EmailStatus.FAILED,
			label: this.locale.getString('administration.stateFailed'),
			class: 'alert-danger'
		}, {
			value: EmailStatus.SKIPPED,
			label: this.locale.getString('administration.stateSkipped'),
			class: 'alert-info'
		}, {
			value: EmailStatus.DELETED,
			label: this.locale.getString('administration.stateDeleted'),
			class: 'alert-info'
		}];
	};

	getColumns = (): Array<TableColumn<any>> => {
		let columns = [];
		columns.push({
			name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'date',
			path: 'state.date',
			displayName: this.locale.getString('administration.date'),
			formatter: this.tableFormattersService.dateFormatter,
			width: 0.1
		});
		columns.push({
			name: 'recipients',
			displayName: this.locale.getString('administration.recipients'),
			formatter: this.recipientsFormatter,
			width: 0.2
		});
		columns.push({
			name: 'subject',
			displayName: this.locale.getString('administration.subject'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.2
		});
		columns.push({
			name: 'cluster-node',
			path: 'state.node',
			displayName: this.locale.getString('common.node'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'version',
			path: 'state.version',
			displayName: this.locale.getString('administration.version'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		});
		columns.push({
			name: 'status',
			path: 'state.status',
			displayName: this.locale.getString('administration.status'),
			formatter: this.statusFormatter,
			width: 0.1
		});
		columns.push({
			name: 'message',
			path: 'state.message',
			displayName: this.locale.getString('administration.details'),
			formatter: this.tableFormattersService.longTextFormatter,
			width: 0.1
		});
		return columns;
	};

	private recipientsFormatter = (object: any, path: string): string => {
		let recipients: EmailRecipient[] = this.tableFormattersService.getFieldByPath(object, path);
		let strings = _.map(recipients, recipient => recipient.type + ' ' + recipient.address);
		return strings.join('<br>');
	};

	private statusFormatter = (object: any, path: string): string => {
		let status: EmailStatus = this.tableFormattersService.getFieldByPath(object, path);
		return `<span class='alert ${this.statuses[status].class} p-8 mb-0'><b>${this.statuses[status].label}</b></span>`;
	};
}

app.service('emailsTableColumnsService', EmailsTableColumnsService);