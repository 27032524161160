
export class SampleDataAPIServiceClass {
	constructor(private $http) { }

	getHierarchyData = (specificDataset?: string) => {
		if (specificDataset) {
			return this.$http.get(`data/hierarchy_data_${specificDataset}.json`);
		} else {
			return this.$http.get('data/hierarchy_data.json');
		}
	};

	getCloudData = () => this.$http.get('data/hierarchy_data_cloud.json');

	getNetworkData = () => this.$http.get('data/network_data.json');
}

app.service('sampleDataApiService', SampleDataAPIServiceClass);
