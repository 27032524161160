import { ChangeDetectionStrategy, Component, Inject, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

export interface ScreeningSettings {
	feedbackScreeningEnabled: boolean;
}

@Component({
	selector: 'screening-settings-panel',
	templateUrl: './screening-settings-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ScreeningSettingsPanelComponent implements OnInit {
	feedbackScreeningLabel: string = this.locale.getString('mAccount.feedbackScreening');

	@Input() screeningSettings: ScreeningSettings;

	constructor(
		private toastService: ToastService,
		private locale: CxLocaleService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
	) {
	}

	ngOnInit(): void {
		this.screeningSettings = {
			feedbackScreeningEnabled: false
		};
	}

	changeSettings = (enabled: boolean): void => {
		this.screeningSettings.feedbackScreeningEnabled = enabled;
		this.masterAccountApiService.updateScreeningSettings(this.screeningSettings).then(() => {
			let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
			this.toastService.addToast(this.locale.getString(key,
				this.locale.getString('mAccount.contentScreeningFeatureName')), AlertLevel.CONFIRM);
		});
	};
}

app.directive('screeningSettingsPanel',
	downgradeComponent({component: ScreeningSettingsPanelComponent}));
