import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { DocumentLink } from '@cxstudio/reports/entities/document-link';

@Injectable({
	providedIn: 'root'
})
export default class MobileStudioApiService {
	constructor(
		private readonly http: CxHttpService
	) {
	}

	decodeMobileFeedbackById = (encryptedId: string): Promise<{ link: DocumentLink; encodedLink: string }> => {
		const httpParams = new HttpParams();
		httpParams.append('id', encryptedId);

		return this.http.get('rest/mobile/feedback/decode', {params: httpParams});
	};
}

app.service('mobileStudioApiService', downgradeInjectable(MobileStudioApiService));
