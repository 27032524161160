import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import * as orderBy from 'lodash.orderby';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { AttributeValueOption } from '@app/modules/filter-builder/attribute/multiselect/multiselect.component';
import { MultiselectUtils } from '@app/modules/filter-builder/attribute/multiselect/multiselect-utils';

interface EventParams {
	$item: AttributeValueOption;
	$itemType: string;
}

enum SpecialOrder {
	SPECIAL_FIRST = 'asc',
	SPECIAL_LAST = 'desc',
}

@Component({
	selector: 'multiselect-options',
	templateUrl: './multiselect-options.component.html'
})
export class MultiselectOptionsComponent implements OnInit, OnChanges {
	@Input() itemDisabled: (item: AttributeValueOption) => boolean;
	@Input() allOptions: AttributeValueOption[];
	@Input() filterText: string;
	@Input() isPagingSearch: boolean;
	@Input() showSelected: boolean;
	@Input() showAttributeName: boolean;
	@Input('id') optionsId: string;
	@Input() optimized?: boolean;
	@Input() highlightContains: boolean;
	@Output() onClick = new EventEmitter<EventParams>();
	@Output() onFocusMove = new EventEmitter<void>();

	readonly PREFIX = 'item_';
	readonly PREFIX_SELECTED = 'selected_';
	readonly SpecialOrder = SpecialOrder;
	readonly VIRTUAL_SCROLL_THRESHOLD = 200;

	filteredOptions: any[];
	dividerIndex: number = -1;

	constructor() { }

	ngOnInit(): void {
		this.populateFilteredOptions();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.allOptions) {
			this.populateFilteredOptions();
		}
	}

	getTooltip = (item: AttributeValueOption): string => {
		let prefix = this.showAttributeName ? item.attributeDisplayName + ': ' : '';
		return prefix + item.displayName;
	};

	getOptionCssClasses = (option): string[] => {
		let classes = [];
		if (this.itemDisabled(option)) classes.push('disabled');
		if (option.children && option.children.length) {
			classes.push('parent');
		} else {
			if (this.selectedOptionsFilter(option)) classes.push('action-background');
		}
		if (option.css) classes.push(option.css);
		return classes;
	};

	selectedOptionsFilter = (item) => MultiselectUtils.optionSelected(item);

	unselectedOptionsFilter = (item) => MultiselectUtils.optionNotSelected(item);

	hasSelectedOptions = (): boolean => {
		return !this.allOptions.filter(this.selectedOptionsFilter).isEmpty();
	};

	getFilteredOptions = (items: any[], optionsFilter, specialOrder: SpecialOrder) => {
		let filtered = items.filter(optionsFilter);
		return orderBy(filtered, 'special', specialOrder); // options should be in expected order
	};

	isVirtualScrollActive = (): boolean => this.optimized && this.allOptions.length > this.VIRTUAL_SCROLL_THRESHOLD;

	populateFilteredOptions = () => {
		if (this.isVirtualScrollActive()) {
			let selectedOptions = this.getFilteredOptions(this.allOptions, this.selectedOptionsFilter, SpecialOrder.SPECIAL_FIRST);
			let unselectedOptions = this.getFilteredOptions(this.allOptions, this.unselectedOptionsFilter, SpecialOrder.SPECIAL_FIRST);
			this.filteredOptions = _.isEmpty(selectedOptions)
				? [...unselectedOptions]
				: [...selectedOptions, { isDivider: true }, ...unselectedOptions];
			this.dividerIndex = _.isEmpty(selectedOptions) ? -1 : selectedOptions.length;
		}
	};

	showOption = (item: any, index: number, selected: boolean): boolean => {
		if (!item.children) {
			return !!item.selected === selected;
		}
		return selected ? index < this.dividerIndex : index > this.dividerIndex;
	};

	highlightText = (displayName: string): string => {
		if (this.isPagingSearch) {
			return `${this.highlightValue(displayName)}`;
		} else {
			return displayName;
		}
	};

	private highlightValue(value: string): string {
		if (_.isEmpty(this.filterText))
			return value;
		let regex = this.highlightContains
			? new RegExp(this.filterText.escapeRegExp(), 'ig')
			: new RegExp('^' + this.filterText.escapeRegExp(), 'ig');
		return value.replace(regex, match => `<b>${match}</b>`);
	}

	onItemClick = (item: AttributeValueOption, itemType?: string): void => {
		this.onClick.emit({$item: item, $itemType: itemType});
		this.populateFilteredOptions();
		let lastSelected = _.filter(this.allOptions, this.selectedOptionsFilter).length - 1;
		$(`#${this.optionsId} #${this.PREFIX_SELECTED + lastSelected}`).trigger('focus');
	};

	private isLast = (selected: boolean, index: number): boolean => {
		let selectedCount =  _.filter(this.allOptions, this.selectedOptionsFilter).length;
		let unselectedCount =  _.filter(this.allOptions, this.unselectedOptionsFilter).length;
		if (selected && unselectedCount === 0 && index === selectedCount - 1) {
			return true;
		}
		if (!selected && index === unselectedCount - 1) {
			return true;
		}
		return false;
	};

	onItemKeydown = (event: any, item: AttributeValueOption, itemType?: string): void => {
		let selected = itemType === 'selected';
		let prefix = selected ? this.PREFIX_SELECTED : this.PREFIX;
		let id = document.activeElement.id;
		let index = parseInt(id.substr(prefix.length), 10);
		let direction;

		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE)) {
			this.onItemClick(item, itemType);
			setTimeout(() => {
				let nextItem = $(`#${this.optionsId} #${id}:visible`);
				if (nextItem.length > 0) {
					nextItem.trigger('focus');
				} else {
					if (index > 0) {
						$(`#${this.optionsId} #${prefix + (index - 1)}`).trigger('focus');
					} else {
						$(`#${this.optionsId} #${selected ? this.PREFIX : this.PREFIX_SELECTED}0`).trigger('focus');
					}
				}
			});
		}

		if (KeyboardUtils.isEventKey(event, Key.TAB) && this.isLast(selected, index)) {
			this.onFocusMove.emit();
			return;
		}
		if (KeyboardUtils.isEventKey(event, Key.DOWN)) {
			direction = 1;
		}
		if (KeyboardUtils.isEventKey(event, Key.UP)) {
			direction = -1;
		}
		if (direction !== undefined) {
			event.preventDefault();
			let nextItem = $(`#${this.optionsId} #${prefix + (index + direction)}:visible`);
			if (nextItem.length > 0) {
				nextItem.trigger('focus');
			} else {
				if (selected && direction > 0) {
					$(`#${this.optionsId} #${this.PREFIX}0`).trigger('focus');
				}
				if (!selected && direction < 0) {
					let lastSelected = _.filter(this.allOptions, this.selectedOptionsFilter).length - 1;
					$(`#${this.optionsId} #${this.PREFIX_SELECTED + lastSelected}`).trigger('focus');
				}
			}
		}
	};
}

app.directive('multiselectOptions', downgradeComponent({component: MultiselectOptionsComponent}));
