import { Component, OnInit, Output, EventEmitter, Input, Inject, ChangeDetectionStrategy } from '@angular/core';
import { FavoriteType, FavoriteAttribute, FavoriteId } from '@cxstudio/reports/document-explorer/favorite-attribute';
import { AttributeValues } from '@app/modules/project/attribute/attribute-values';
import { EnrichmentAttributesService } from '@cxstudio/reports/document-explorer/enrichment-attributes.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ConversationDocument } from '@cxstudio/reports/document-explorer/conversations/conversation-document.class';
import { DocExplorerFavoriteUtils } from '@app/modules/document-explorer/context-pane/doc-explorer-favorite-utils.class';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { RedirectInterceptService } from '@app/modules/redirect-intercept/redirect-intercept.service';

@Component({
	selector: 'explorer-attribute',
	templateUrl: './explorer-attribute.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class ExplorerAttributeComponent implements OnInit {
	private readonly TYPE = FavoriteType.ATTRIBUTE;

	@Input() attribute: AttributeValues & FavoriteAttribute;
	@Input() favoriteAttributes: FavoriteId[];
	@Input() disableFavorites: boolean;
	@Input() favoritesPanel: boolean;
	@Input() document: ConversationDocument;
	@Input() widget: Widget;
	@Output() onToggleFavorite = new EventEmitter<{attribute: FavoriteAttribute; type: FavoriteType}>();

	favoriteId: FavoriteId;

	constructor(
		@Inject('enrichmentAttributesService') private enrichmentAttributesService: EnrichmentAttributesService,
		@Inject('urlService') private urlService: UrlService,
		@Inject('contentProviderOptionsService') private contentProviderOptionsService: ContentProviderOptionsService,
		private readonly redirectInterceptService: RedirectInterceptService
	) {}

	ngOnInit(): void {
		this.favoriteId = DocExplorerFavoriteUtils.getFavoriteId(this.attribute, this.TYPE);
	}

	formatValue = (): string => {
		return this.enrichmentAttributesService.formatAttributeValue(this.attribute.value, this.attribute.type,
			this.attribute.settings);
	};

	getLinkAndRedirect(attribute: AttributeValues, event: MouseEvent): void {
		if (this.urlService.isUrl(attribute.value)) {
			let settings = {
				contentProviderId: this.widget.properties.contentProviderId,
				accountId: this.widget.properties.accountId,
				projectId: this.widget.properties.project,
				runAs: this.widget.properties.runAs,
				documentId: this.document.id,
				attributeName: attribute.name
			};
			event.stopPropagation();
			event.preventDefault();

			this.contentProviderOptionsService.getCaseSensitiveUrlValue(settings).then( (response) => {
				let sensitiveUrl = response.sensitiveUrl;
				if (sensitiveUrl && sensitiveUrl.length) {
					this.redirectInterceptService.processUrl(sensitiveUrl);
				} else {
					this.redirectInterceptService.processUrl(attribute.value);
				}

			});
		}
		return;

	}

	toggleFavorite = () => {
		this.onToggleFavorite.emit({attribute: this.attribute, type: this.TYPE});
	};
}
