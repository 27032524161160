import { FilterManagementApiService } from '@cxstudio/report-filters/api/filter-management-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { ISharePermissionNameGetter } from '@cxstudio/sharing/generic-share.component';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { PermissionType } from '@app/core/authorization/all-permissions.service';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';

export class FilterSharingComponent implements ng.IComponentController {
	items: any[];

	resolve: {
		items: any[];
	};

	getPermissionName: ISharePermissionNameGetter = {
		edit: (): string => MasterAccountPermissionAction.CREATE_FILTER,
		shareView: (): string => 'share_filter',
		shareEdit: (): string => 'share_edit_filter',
		defaultSharePermission: (): string => this.security.has('share_filter') ? PermissionType.VIEW : PermissionType.EDIT
	};

	constructor(
		private filterManagementApiService: FilterManagementApiService,
		private security: Security,
	) {}

	$onInit = () => {
		this.items = this.resolve.items;
	};

	getSharedUsersAndGroups = (items): ng.IPromise<any[]> => this.filterManagementApiService.getSharedUsersAndGroups(items[0].id);

	getShareStatus = (): string => this.items[0].sharingStatus;

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.CREATE_FILTER;
}

app.component('filterSharing', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
	},
	controller: FilterSharingComponent,
	template: `
<generic-share
	object-type-name="{{::'object.filter'|i18n}}"
	resolve="::$ctrl.resolve"
	close="::$ctrl.close()"
	dismiss="::$ctrl.dismiss()"
	get-permission-name="::$ctrl.getPermissionName"
	get-shared-users-and-groups="$ctrl.getSharedUsersAndGroups"
	get-share-status="$ctrl.getShareStatus"
	owner="::$ctrl.items[0].ownerName"
	asset-edit-permission-type="$ctrl.getAssetEditPermissionType()">
</generic-share>`
});
