import { DragDropModule } from '@angular/cdk/drag-drop';
import { NgModule } from '@angular/core';
import { BetaFeaturesGridDefinition } from '@app/modules/account-administration/beta-features-tab/beta-features-grid-definition.service';
import { ConversationSettingsModule } from '@app/modules/account-administration/conversation-settings/conversation-settings.module';
import { ProjectSelectionDialogComponent } from '@app/modules/account-administration/project-selection-dialog/project-selection-dialog.component';
import { AccountOwnersToggleComponent } from '@app/modules/account-administration/properties/account-owners-toggle/account-owners-toggle.component';
import { MasterAccountFeatureToggleComponent } from '@app/modules/account-administration/properties/master-account-feature-toggle/master-account-feature-toggle.component';
import { SimpleFeatureToggleComponent } from '@app/modules/account-administration/properties/simple-feature-toggle/simple-feature-toggle.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ItemGridModule } from '@app/modules/item-grid/item-grid.module';
import { UserAdministrationModule } from '@app/modules/user-administration/user-administration.module';
import { WidgetSettingsModule } from '@app/modules/widget-settings/widget-settings.module';
import { SharedModule } from '@app/shared/shared.module';
import { UnifiedAngularComponentsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { NgPipesModule } from 'ngx-pipes';
import { TokensModule } from '../tokens/tokens.module';
import { MasterAccountPropertiesApiService } from './api/master-account-properties-api.service';
import { BetaFeaturesTabComponent } from './beta-features-tab/beta-features-tab.component';
import { EmbeddedWidgetGridDefinition } from './embedded-widget/embedded-widget-grid-definition.service';
import { EmbeddedWidgetManagementComponent } from './embedded-widget/embedded-widget-management.component';
import { LicensingPermissionsComponent } from './license-permissions/licensing-permissions.component';
import { LicensingTabComponent } from './license-permissions/licensing-tab.component';
import { PasswordPolicyComponent } from './password-policy/password-policy.component';
import { HierarchyApiSettingsComponent } from './properties/hierarchy-api-settings/hierarchy-api-settings.component';
import { QuickTranslateToggleComponent } from './properties/quick-translate-toggle/quick-translate-toggle.component';
import { SecurityAuditDetailsComponent } from './security-audit/security-audit-details/security-audit-details.component';
import { SecurityAuditExportComponent } from './security-audit/security-audit-export/security-audit-export.component';
import { SecurityAuditGridDefinitionService } from './security-audit/security-audit-table/security-audit-grid-definition.service';
import { SecurityAuditComponent } from './security-audit/security-audit.component';
import { TopicsTuningSuggestionsComponent } from './tuning-suggestions-tab/topics-tuning-suggestions.component';
import { TuningSuggestionsSettingsComponent } from './tuning-suggestions-tab/tuning-suggestions-settings.component';
import { WidgetCacheSettingsComponent } from './settings/widget-cache-settings/widget-cache-settings.component';
import { AccountOverviewComponent } from './account-overview/account-overview.component';
import { ReportProjectSelectionDialogComponent } from './report-project-selection-dialog/report-project-selection-dialog.component';
import { DomainRestrictionsComponent } from '@app/modules/account-administration/domain-restrictions/domain-restrictions.component';
import { DomainsProcessingService } from '@app/modules/account-administration/api/domains-processing.service';
import { LinkSharingSettingsComponent } from '@app/modules/account-administration/link-sharing-settings/link-sharing-settings.component';
import { MasterAccountLinkSharingService } from '@app/modules/account-administration/api/master-account-link-sharing.service';
import { ScreeningSettingsPanelComponent } from '@app/modules/account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { UnitsModule } from '../units/units.module';
import { TagsService } from './properties/tags.service';
import { WidgetEditingPreviewsSettingsPanelComponent } from './properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { PackageSolutionsModule } from './packaged-solutions-tab/package-solutions.module';
import { AppearanceModule } from './appearance/appearance.module';
import { QualtricsEmbeddingSettingsPanelComponent } from './properties/qualtrics-embedding-panel/qualtrics-embedding-settings-panel.component';
import { RedirectRestrictionsComponent } from './redirect-restrictions/redirect-restrictions.component';
import {
	AutomatedNarrativeModule
} from '@app/modules/account-administration/automated-narrative/automated-narrative.module';
import { ConsumptionTrackerModule } from '../consumption-tracker/consumption-tracker.module';
import { TopicConversionsModule } from '@app/modules/account-administration/topic-conversions/topic-conversions.module';
import { SmtpSettingsComponent } from './settings/smtp-settings/smtp-settings.component';

@NgModule({
	imports: [
		SharedModule,
		DialogModule,
		DragDropModule,
		WidgetSettingsModule,
		UserAdministrationModule,
		ItemGridModule,
		TokensModule,
		NgPipesModule,
		UnifiedAngularComponentsModule,
		ConversationSettingsModule,
		PackageSolutionsModule,
		UnitsModule,
		AutomatedNarrativeModule,
		AppearanceModule,
		ConsumptionTrackerModule
	],
	exports: [
		BetaFeaturesTabComponent,
		LicensingTabComponent,
		EmbeddedWidgetManagementComponent,
		TuningSuggestionsSettingsComponent,
		SecurityAuditComponent,
		WidgetCacheSettingsComponent,
		DomainRestrictionsComponent,
		LinkSharingSettingsComponent,
		AutomatedNarrativeModule,
		AppearanceModule,
		TopicConversionsModule
	],
	declarations: [
		HierarchyApiSettingsComponent,
		LicensingPermissionsComponent,
		LicensingTabComponent,
		LinkSharingSettingsComponent,
		EmbeddedWidgetManagementComponent,
		TuningSuggestionsSettingsComponent,
		TopicsTuningSuggestionsComponent,
		SecurityAuditComponent,
		SecurityAuditExportComponent,
		SecurityAuditDetailsComponent,
		ProjectSelectionDialogComponent,
		SimpleFeatureToggleComponent,
		MasterAccountFeatureToggleComponent,
		QuickTranslateToggleComponent,
		AccountOwnersToggleComponent,
		BetaFeaturesTabComponent,
		WidgetCacheSettingsComponent,
		PasswordPolicyComponent,
		AccountOverviewComponent,
		ReportProjectSelectionDialogComponent,
		DomainRestrictionsComponent,
		ScreeningSettingsPanelComponent,
		WidgetEditingPreviewsSettingsPanelComponent,
		QualtricsEmbeddingSettingsPanelComponent,
		RedirectRestrictionsComponent,
		SmtpSettingsComponent
	],
	providers: [
		MasterAccountPropertiesApiService,
		MasterAccountLinkSharingService,
		EmbeddedWidgetGridDefinition,
		SecurityAuditComponent,
		SecurityAuditGridDefinitionService,
		BetaFeaturesGridDefinition,
		DomainsProcessingService,
		TagsService
	],
})
export class AccountAdministrationModule { }
