import { PredefinedMetricRanges } from '@app/modules/metric/services/predefined-metric-ranges';
import { HighchartsUtilsService } from '@app/modules/widget-visualizations/highcharts/highcharts-utils.service';
import { FilterMetricDefinition } from '@cxstudio/metrics/entities/filter-metric-definition.class';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { Injectable } from '@angular/core';

export interface IMetricBounds {
	min: number;
	max: number;
}

@Injectable({
	providedIn: 'root'
})
export class MetricBoundsUtilsService {
	constructor(
		private readonly highchartsUtils: HighchartsUtilsService,
	) {}

	getMetricBounds(props: WidgetProperties, visualProps: VisualProperties, value: number): IMetricBounds {
		const bounds: IMetricBounds = this.highchartsUtils.getAxisScale('y', visualProps);
		const metric = props.selectedMetrics[0];
		bounds.min = this.firstNonNull(bounds.min, this.getMetricMin(metric, value));
		bounds.max = this.firstNonNull(bounds.max, this.getMetricMax(metric, value));
		return bounds;
	}

	private firstNonNull(...values: number[]): number {
		return _.find(values, value => value !== null && value !== undefined);
	}

	private getMetricMin(metric: ReportCalculation, value: number) {
		if (PredefinedMetricRanges.isKnownMetric(metric.name)) {
			return PredefinedMetricRanges.getMetricMin(metric.name, metric.definition);
		}
		if (AnalyticMetricTypes.isStudioMetric(metric) && metric.definition) {
			if (metric.definition.type === MetricType.FILTER) {
				const definition = metric.definition as FilterMetricDefinition;
				if (PredefinedMetricRanges.isKnownMetric(definition.calculationName)) {
					return PredefinedMetricRanges.getMetricMin(definition.calculationName, definition.calculation);
				}
				return this.getDefaultMin(value);
			}
			if (metric.definition.type === MetricType.BOTTOM_BOX || metric.definition.type === MetricType.TOP_BOX) {
				return 0;
			}
			if (metric.definition.type === MetricType.SATISFACTION) {
				return -1;
			}
		}
		return this.getDefaultMin(value);
	}

	private getDefaultMin(value: number): number {
		return value < 0 ? -this.getNextOrderOfMagnitude(value) : 0;
	}

	private getMetricMax(metric: ReportCalculation, value: number) {
		if (PredefinedMetricRanges.isKnownMetric(metric.name)) {
			return PredefinedMetricRanges.getMetricMax(metric.name, metric.definition);
		}
		if (AnalyticMetricTypes.isStudioMetric(metric) && metric.definition) {
			if (metric.definition.type === MetricType.FILTER) {
				const definition = metric.definition as FilterMetricDefinition;
				if (PredefinedMetricRanges.isKnownMetric(definition.calculationName)) {
					return PredefinedMetricRanges.getMetricMax(definition.calculationName, definition.calculation);
				}
				return this.getDefaultMax(value);
			}
			if (metric.definition.type === MetricType.BOTTOM_BOX
					|| metric.definition.type === MetricType.TOP_BOX
					|| metric.definition.type === MetricType.SATISFACTION) {
				return 1;
			}

		}
		return this.getDefaultMax(value);
	}

	private getDefaultMax(value: number): number {
		return this.getNextOrderOfMagnitude(value);
	}

	private getNextOrderOfMagnitude(value: number): number {
		const absValue = Math.abs(value);
		const orderOfMagnitude = Math.pow(10, Math.floor(Math.log10(absValue)));
		return orderOfMagnitude * 10;
	}
}
