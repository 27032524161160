import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface ContentWidgetBuilder extends Widget {}
class ContentWidgetBuilder implements Widget {
	constructor() {
		this.type = WidgetDisplayType.CONTENT;
	}

	withId(id: number): ContentWidgetBuilder {
		this.id = id;
		return this;
	}

	withDisplayName(displayName: string): ContentWidgetBuilder {
		this.displayName = displayName;
		return this;
	}

	withRunAs(runAs: string): ContentWidgetBuilder {
		this.properties.runAs = runAs;
		return this;
	}

	withVisualProperties(visualProperties: VisualProperties): ContentWidgetBuilder {
		_.extend(this.visualProperties, visualProperties);
		return this;
	}
}

export default ContentWidgetBuilder;
