import * as moment from 'moment';
import { Injectable, Inject } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { MasterAccountsTableComponent } from '@app/modules/system-administration/master-account/master-accounts-table/master-accounts-table.component';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { MasterAccountExpirationMode } from '@app/modules/system-administration/master-account/entities/master-account-expiration-mode';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';

@Injectable()
export class MasterAccountsGridDefinition implements IGridDefinition<MasterAccountsTableComponent> {

	constructor(
		private locale: CxLocaleService,
		@Inject('dateService') private dateService: DateService,
		@Inject('gridFormatterService') private gridFormatterService: GridFormatter) {}

	init = (): Promise<IGridColumn[]> => {

		let columns = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<span class="q-icon-layer"></span>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.hamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: false,
			minWidth: 30,
			width: 30,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'masterAccountName',
			name: this.locale.getString('administration.masterAccountName'),
			field: 'accountName',
			sortable: false,
			minWidth: 100,
			width: 120,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.MASTER_ACCOUNTS),
			isObjectNameColumn: true
		}, {
			id: 'masterAccountId',
			name: this.locale.getString('administration.masterAccountId'),
			field: 'accountId',
			sortable: false,
			minWidth: 50,
			width: 60,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.NoFormat
		}, {
			id: 'masterAccountExpiration',
			name: this.locale.getString('administration.masterAccountExpiration'),
			field: 'expires',
			sortable: false,
			minWidth: 100,
			width: 120,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.expirationFormatter
		}, {
			id: 'adminOrganization',
			name: this.locale.getString('administration.adminOrganization'),
			field: 'isAdminOrg',
			sortable: false,
			minWidth: 100,
			width: 120,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.YesNoFormatter
		}];

		return Promise.resolve(columns);
	};

	private hamburgerFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: MasterAccount) => {
		let disabled = dataContext.restricted;
		return disabled ? this.gridFormatterService.getDisabledHamburger(dataContext, null) :
			this.gridFormatterService.getHamburger(dataContext, null);
	};

	private toggleFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, dataContext: MasterAccount) => {
		let getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.accountName);
		let disabledCheck = dataContext.restricted;

		return getSwitch(value, disabledCheck);
	};

	private expirationFormatter: IRowFormatter = (row, cell, value: boolean, columnDef, masterAccount: MasterAccount) => {
		if (!masterAccount.isAdminOrg) return '';

		if (masterAccount.expirationSettings?.expirationMode === MasterAccountExpirationMode.NEVER_EXPIRE) {
			return this.locale.getString('common.neverExpire');
		}

		if (moment().isAfter(masterAccount.expirationSettings?.expirationDate)) {
			return this.locale.getString('common.expired');
		}

		let date = new Date(masterAccount.expirationSettings?.expirationDate);
		return this.dateService.format(date, DateTimeFormat.BASIC_DATE);
	};
}

app.service('masterAccountsGridDefinition', downgradeInjectable(MasterAccountsGridDefinition));
