import { ElementRef } from '@angular/core';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { MetricCssVariables } from './metric-css-variables.class';
export abstract class MetricWidgetSizer {

	defaults = {
		labelFontSize: 14,
		labelSpacing: 8,
		deltaSpacing: 8,
		margin: 16,
		marginReduced: 8,
		fontLetterWidthRatio: .525,			// ratio of letter width to letter height
		targetNumberOfDigits: 12,		// target number of characters, excluding comma/decimal
		separatorCharacterWidthRatio: .1,	// ratio of comma/decimal width to letter height
		targetNumberOfSeparators: 1
	};

	constructor() {
		this.defaults = $.extend(this.defaults, (window as any).METRIC_DEFAULTS);
	}

	abstract getAdditionalCssVars(widget: ElementRef, mainMetricSize: number): Partial<MetricCssVariables>;
	abstract isPeriodTextFit(widget: ElementRef, cssVars: MetricCssVariables, visualProps?: VisualProperties): boolean;
	abstract isFitHorizontal(widget: ElementRef, cssVars: MetricCssVariables): boolean;
	abstract isFitVertical(widget: ElementRef, cssVars: MetricCssVariables,
		visualProps?: VisualProperties, isHistoricValueHidden?: boolean): boolean;

	protected getWidgetDimensions = (widget: ElementRef): { height: number; width: number} => {
		return {
			height: widget.nativeElement.offsetHeight,
			width: widget.nativeElement.offsetWidth
		};
	};
}
