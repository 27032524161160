import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PreviewTableColumn } from '@cxstudio/reports/preview/preview-table-column';
import { PreviewDataType } from '@cxstudio/reports/entities/preview-data-type';

export enum PreviewColumn {
	FEEDBACK_SELECTION = 'feedback_selection',
	SENTIMENT = 'sentiment',
	EFFORT = 'easeScore',
	EMOTION = 'emotion',
	SOURCE = 'smService',
	DOC_DATE = 'documentDate',
	TOPICS = 'topics',
	VERBATIM_TYPE = '_verbatimtype',
	SENTENCE = 'sentence'
}

export class PreviewPredefinedColumns {
	private displayNames: any;

	readonly NON_FORMATTABLE_COLUMNS = [
		PreviewColumn.SENTIMENT,
		PreviewColumn.EFFORT,
		PreviewColumn.EMOTION,
		PreviewColumn.DOC_DATE
	];

	constructor(private locale: ILocale) {
		let nameMap = {};
		nameMap[PreviewColumn.FEEDBACK_SELECTION] = 'preview.selected';
		nameMap[PreviewColumn.SENTIMENT] = 'widget.sentiment';
		nameMap[PreviewColumn.EFFORT] = 'metrics.easeScore';
		nameMap[PreviewColumn.EMOTION] = 'metrics.emotion';
		nameMap[PreviewColumn.SOURCE] = 'preview.source';
		nameMap[PreviewColumn.DOC_DATE] = 'widget.document_date';
		nameMap[PreviewColumn.TOPICS] = 'widget.topics';
		nameMap[PreviewColumn.SENTENCE] = 'preview.sentences';

		this.displayNames = nameMap;
	}

	// formatting options do not apply to some default columns
	private isConfigurable = (name: PreviewColumn): boolean => {
		return this.NON_FORMATTABLE_COLUMNS.indexOf(name) === -1;
	};

	isEnrichmentColumn = (column: PreviewTableColumn): boolean => {
		if (column.type !== PreviewDataType.SYS) return false;
		const name = column.name;
		return name === PreviewColumn.SENTIMENT
			|| name === PreviewColumn.EFFORT
			|| name === PreviewColumn.EMOTION;
	};

	isSentimentColumn = (column: PreviewTableColumn): boolean => {
		return column.type === PreviewDataType.SYS && column.name === PreviewColumn.SENTIMENT;
	};

	isEffortColumn = (column: PreviewTableColumn): boolean => {
		return column.type === PreviewDataType.SYS && column.name === PreviewColumn.EFFORT;
	};

	isEmotionColumn = (column: PreviewTableColumn): boolean => {
		return column.type === PreviewDataType.SYS && column.name === PreviewColumn.EMOTION;
	};

	get = (name: PreviewColumn, singleVerbatimMode?: boolean): AttributeGrouping => {
		let displayName;
		if (singleVerbatimMode !== undefined && name === PreviewColumn.SENTENCE) {
			displayName = singleVerbatimMode === true
				? this.locale.getString('preview.verbatim')
				: this.locale.getString('preview.document');
		} else {
			displayName = this.locale.getString(this.displayNames[name]);
		}
		return {
			name,
			displayName,
			type: ReportAssetType.SYS,
			isConfigurable: this.isConfigurable(name)
		} as AttributeGrouping;
	};

	getPredefinedMetricColumns = (): AttributeGrouping[] => {
		return this.getPredefinedMetricNames().map(name => this.get(name));
	};

	getPredefinedMetricNames = (): PreviewColumn[] => {
		return [PreviewColumn.SENTIMENT, PreviewColumn.EFFORT, PreviewColumn.EMOTION];
	};
}

app.service('previewPredefinedColumns', PreviewPredefinedColumns);

