import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { ListOption } from '@app/shared/components/forms/list-option';
import { CxLocaleService } from '@app/core';
import { ObjectListSort } from '@app/shared/components/sort/object-list-sort';
import { ObjectSortDirection } from '@app/shared/components/sort/object-sort-direction';

@Component({
	selector: 'external-application-sort',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<object-list-sort
			[maxLanes]="1"
			[columns]="columns"
			[defaultSort]="defaultSort"
			(apply)="applySorts($event)"
		></object-list-sort>
	`
})
export class ExternalApplicationSortComponent implements OnInit {

	@Output() apply = new EventEmitter<ObjectListSort[]>();
	columns: Array<ListOption<string>>;
	defaultSort: ObjectListSort;

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.defaultSort = { field: 'name', direction: ObjectSortDirection.ASC };
		this.columns = [
			{ name: this.locale.getString('administration.application'), value: 'name' },
			{ name: this.locale.getString('common.type'), value: 'type'}
		];
	}

	applySorts = (sorts: ObjectListSort[]): void => {
		this.apply.emit(sorts);
	};
}

app.directive('externalApplicationSort', downgradeComponent({component: ExternalApplicationSortComponent}));