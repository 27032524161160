import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService, UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

export enum WidgetEditingPreviewMode {
	AUTO = 'AUTO',
	MANUAL = 'MANUAL',
	OFF = 'OFF'
}

export interface WidgetEditingPreviewsSettings {
	widgetEditingPreviewMode: WidgetEditingPreviewMode;
}

@Component({
	selector: 'widget-editing-previews-settings-panel',
	templateUrl: './widget-editing-previews-settings-panel.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})

export class WidgetEditingPreviewsSettingsPanelComponent {
	previewModeOptions: UIOption<string>[] = [{
			value: WidgetEditingPreviewMode.AUTO,
			displayName: this.locale.getString('mAccount.widgetEditingAutoPreviewsDescription'),
			htmlId: 'previewAuto'
	}, {
			value: WidgetEditingPreviewMode.MANUAL,
			displayName: this.locale.getString('mAccount.widgetEditingManualPreviewsDescription'),
			htmlId: 'previewManual'
	}, {
			value: WidgetEditingPreviewMode.OFF,
			displayName: this.locale.getString('mAccount.widgetEditingOffPreviewsDescription'),
			htmlId: 'previewOff'
	}];

	@Input() previewsSettings: WidgetEditingPreviewsSettings;

	constructor(
		private toastService: ToastService,
		private locale: CxLocaleService,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
	) {
	}

	changePreviewMode = (previewMode: WidgetEditingPreviewMode): void => {
		if (previewMode === this.previewsSettings.widgetEditingPreviewMode) return;

		this.previewsSettings.widgetEditingPreviewMode = previewMode;

		this.masterAccountApiService.updateWidgetEditingPreviewsSettings(this.previewsSettings).then(() => {
			this.toastService.addToast(
				this.locale.getString('mAccount.widgetPreviewModeChanged', previewMode), AlertLevel.CONFIRM);
		});
	};
}

app.directive('widgetEditingPreviewsSettingsPanel',
	downgradeComponent({component: WidgetEditingPreviewsSettingsPanelComponent}));
