import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Inject, Input, OnChanges, OnDestroy, Output, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Key } from '@app/shared/util/keyboard-utils.class';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetKeyboardUtils } from '@cxstudio/reports/widget-utils/widget-keyboard-utils';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ContentWidgetKeyboardNavigationUtils } from '../content-widget-kb-navigation-utils.service';

@Component({
	selector: 'widget-menu',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
	<div class="dropdown br-widget-menu gridster-no-drag hide-in-special-mode"
		ngbDropdown
		cx-keyboard-scroll
		autofitHorizontally
		[focusedIndex]="focusedIndex"
		(selectionChanged)="changeSelection($event)"
		display="static"
		(openChange)="onToggle($event)">
		<button class="q-icon q-icon-dots widget-header-menu btn btn-icon p-8 no-border"
			title="{{getTitle()}}"
			attr.aria-label="{{getMenuAriaLabel()}}"
			[attr.aria-expanded]="dropdown.isOpen()"
			aria-haspopup="true"
			#toggle
			ngbDropdownToggle
			(keydown.enter)="openWidgetMenuKb($event)"
			(blur)="onButtonBlur($event)"
		></button>
		<menu ngbDropdownMenu
			scroll-list
			#menuElement
			class="dropdown-menu widget-dropdown-menu"
			(click)="closeDropdown()"
			(mousedown)="$event.stopPropagation()"
			[hidden]="!dropdown.isOpen()"
			(keydown.escape)="closeDropdown(true)"
			(keydown.enter)="kbToggle = true"
			(keydown)="closeOnTab($event)"
			title="">
			<div *ngIf="(dropdown.isOpen() || undefined)" attr.aria-labelledby="widget-header-{{widget.id}}">
				<ng-content></ng-content>
			</div>
		</menu>
	</div>`,
styles: [`
.bottom-right .dropdown-menu {
	left: auto;
	right: 0;
}`]})
export class WidgetMenuComponent implements OnDestroy, OnChanges {

	@Input() widget: Widget;
	@Input() position: {left: string; top: string};
	@Input() forceClose: boolean;
	@Input() title: string;
	@Output() onClose = new EventEmitter<void>();
	@Output() onOpen = new EventEmitter<void>();


	@ViewChild(NgbDropdown, {static: true}) dropdown: NgbDropdown;
	@ViewChild('menuElement', {static: true}) menuElement: ElementRef;
	@ViewChild('toggle', {static: true}) toggleElement: ElementRef;
	focusedIndex = 0;
	placement: string;

	readonly RIGHTCLICK_EVENT_HANDLER = 'mousedown.widgetMenu';

	kbToggle: boolean;
	menuOpenTimeout;
	menuCloseTimeout;
	activeClassTimeout;
	toggleWatcher: (dropdownOpen: boolean) => void;

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('eventEmitterService') private readonly eventEmitterService: EventEmitterService,
	) {}

	ngOnDestroy(): void {
		this.cleanUp();
	}

	ngOnChanges(changes: SimpleChanges<WidgetMenuComponent>): void {
		if (ChangeUtils.hasChange(changes.position)) {
			// menu opened on right click
			this.dropdown.open();
			clearTimeout(this.menuCloseTimeout);
			$(this.menuElement.nativeElement).css({ transform: `translate(${this.position.left}, ${this.position.top})` });
			$(document).on(this.RIGHTCLICK_EVENT_HANDLER, () => {
				// slight delay to allow click on a menu option to process before closing
				this.menuCloseTimeout = setTimeout(() => this.closeDropdown(), 100);
				$(document).off(this.RIGHTCLICK_EVENT_HANDLER);
			});
		}

		if (changes.forceClose?.currentValue) {
			this.closeDropdown();
		}
	}

	onButtonBlur(event: FocusEvent): void {
		if (ContentWidgetKeyboardNavigationUtils.isContentNotPageBreakWidget(this.widget)) {
			this.eventEmitterService.emit(
				ContentWidgetKeyboardNavigationUtils.generateEventType(this.widget),
				ContentWidgetKeyboardNavigationUtils.showHeaderOnMenuButtonBlur(event)
			);
		}
	}

	onToggle(dropdownOpen: boolean): void {
		let parentWidget = $(`#widget-${this.widget.id}`);
		if (!parentWidget?.length) {
			return;
		}

		this.focusedIndex = 0;
		this.toggleKeyboardFocus(dropdownOpen);

		if (dropdownOpen) {
			this.onOpen.emit();
			// if a different menu on same widget is opening, need to make sure we re-add after removal fires
			this.activeClassTimeout = setTimeout(() => parentWidget.addClass('widget:active'), 1);
		} else {
			parentWidget.removeClass('widget:active');
			this.closeDropdown(this.kbToggle);
		}

		if (this.toggleWatcher) {
			this.toggleWatcher(dropdownOpen);
		}
		this.kbToggle = false;
	}

	toggleKeyboardFocus(dropdownOpen: boolean = false): void {
		if (dropdownOpen) {
			$(document).on('keydown', WidgetKeyboardUtils.widgetMenuTabHandler);
		} else {
			$(document).off('keydown', WidgetKeyboardUtils.widgetMenuTabHandler);
		}
	}

	openWidgetMenuKb(event: KeyboardEvent): void {
		if (event.key === Key.ENTER) {
			this.menuOpenTimeout = setTimeout(() => {
				$('.widget-dropdown-menu :focusable').first().trigger('focus');
				clearTimeout(this.menuOpenTimeout);
			});
		}
	}

	changeSelection = (selectionIndex: number): void => {
		this.focusedIndex = selectionIndex;
	};

	closeOnTab(event: KeyboardEvent): void {
		if (event.key === Key.TAB) {
			this.closeDropdown(true);
		}
	}

	private focusMenuButton = (): void => {
		let header = $(`#widget-${this.widget.id}.br-widget-header`).first();
		header.addClass('visible').trigger('focus');
		this.toggleElement.nativeElement.focus();
		header.removeClass('visible');
	};

	private cleanUp(): void {
		// clean up events
		$(document).off('keydown', WidgetKeyboardUtils.widgetMenuTabHandler);
		$(document).off(this.RIGHTCLICK_EVENT_HANDLER);

		// clean up timers
		clearTimeout(this.menuOpenTimeout);
		clearTimeout(this.menuCloseTimeout);
		clearTimeout(this.activeClassTimeout);
	}

	closeDropdown(focus?: boolean): void {
		this.dropdown.close();
		this.cleanUp();
		if (focus) {
			this.focusMenuButton();
		}
		this.onClose.emit();
	}

	getTitle(): string {
		return this.title || this.locale.getString('widget.widgetActions');
	}

	getMenuAriaLabel(): string {
		let defaultAriaLabel: string = this.getDefaultMenuAriaLabel();
		return this.title ? `${this.title} ${defaultAriaLabel}` : defaultAriaLabel;

	}

	private getDefaultMenuAriaLabel(): string {
		let widgetTitle = this.widget.displayName;
		let template = this.widget.name === WidgetType.PREVIEW
			? 'preview.hamburgerMenuAriaLabel'
			: 'widget.menuAriaLabel';
		return this.locale.getString(template, { widgetTitle });
	}
}

app.directive('widgetMenu', downgradeComponent({ component: WidgetMenuComponent }));
