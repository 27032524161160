import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { ProjectSelectionDialogComponent } from '@app/modules/account-administration/project-selection-dialog/project-selection-dialog.component';
import { OptOutsModalComponent } from '@app/modules/alert/opt-outs-modal/opt-outs-modal.component';
import { RenameModalComponent, RenameModalInput } from '@app/modules/asset-management/rename-modal/rename-modal.component';
import { BulkTransferDialogComponent } from '@app/modules/dashboard-list/bulk-transfer-dialog.component';
import { BulkUpdateLabelsModalComponent, IBulkUpdateLabelsParams } from '@app/modules/dashboard-list/bulk-update-labels-modal.component';
import {
	DashboardChangeOwnerDialogComponent,
	IDashboardOwnerChangeParams
} from '@app/modules/dashboard/dashboard-change-owner-dialog/dashboard-change-owner-dialog.component';
import { TemplateModalComponent, TemplateModalInput } from '@app/modules/dashboard/template-modal/template-modal.component';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { IDocumentExplorerInput } from '@app/modules/document-explorer/document-explorer-modal/document-explorer-modal.component';
import { DocumentExplorerStatsData, DocumentExplorerStatsModalComponent } from '@app/modules/document-explorer/document-explorer-stats-modal/document-explorer-stats-modal.component';
import { FilterCreationWizardComponent, FilterCreationWizardInput } from '@app/modules/filter-builder/filter-creation-wizard/filter-creation-wizard.component';
import { UserPreferencesDialogComponent } from '@app/modules/header/user-properties/user-preferences-dialog.component';
import { HierarchyPublishingDialogComponent } from '@app/modules/hierarchy/hierarchy-publishing-dialog.component';
import { GlobalOtherExplorerModalComponent, GlobalOtherExplorerOptions } from '@app/modules/project/global-other-explorer-modal/global-other-explorer-modal.component';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { TemplateCreateDialogComponent, TemplateCreationData } from '@app/modules/unified-templates/common-templates/template/template-create-dialog/template-create-dialog.component';
import { SideBySideSelectorModalComponent, SideBySideSelectorModel } from '@app/modules/user-administration/groups/side-by-side-selector-modal.component';
import { BulkUserRemoveDialogComponent, IBulkUserRemoveDialogParams } from '@app/modules/user-administration/remove/bulk-user-remove-dialog.component';
import { BulkPermissionsComponent, BulkPermissionsUpdate } from '@app/modules/user-bulk/bulk-permissions/bulk-permissions.component';
import { BulkDataAccessUpdate, UserBulkDataAccessComponent } from '@app/modules/user-bulk/user-bulk-data-access/user-bulk-data-access.component';
import {
	AdvancedChartSettingsComponent,
	IAdvancedChartSettings
} from '@app/modules/widget-settings/advanced-chart-settings/advanced-chart-settings.component';
import {
	AdvancedCloudSettingsComponent,
	IAdvancedCloudSettingsInput
} from '@app/modules/widget-settings/advanced-cloud-settings/advanced-cloud-settings.component';
import { IRecolorManageDialogParams } from '@app/modules/widget-visualizations/recolor-manage-dialog/recolor-manage-dialog-params';
import { RecolorManageDialogComponent } from '@app/modules/widget-visualizations/recolor-manage-dialog/recolor-manage-dialog.component';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { ProjectAsset } from '@cxstudio/asset-management/project-asset';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { CloudVisualProperties } from '@cxstudio/dashboards/widgets/type-definitions/cloud-visual-properties';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import IFilter from '@cxstudio/report-filters/entity/filter';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DependenciesDialogInput, DependenciesModalComponent } from '../asset-management/dependencies-modal/dependencies-modal-component';
import { DocumentExplorerDialogService } from '../document-explorer/document-explorer-dialog.service';
import { DriversResultsDialogComponent, DriversResultsModalInput } from '../drivers/drivers-results-dialog/drivers-results-dialog.component';
import { AsyncTaskStatusModalComponent } from '../user-administration/async-task-status-modal/async-task-status-modal.component';
import { EditUserModalComponent, IEditUserModalInput } from '../user-administration/edit-user-modal/edit-user-modal.component';
import { UserCreateWizardComponent, UserCreateWizardInput } from '../user-administration/user-create/user-create-wizard.component';
import { BulkCustomFieldComponent, BulkCustomFieldEntry } from '../user-bulk/bulk-custom-field/bulk-custom-field.component';
import { HierarchyDeltaPublishingDialogComponent } from '../hierarchy/hierarchy-delta-publishing-dialog.component';
import { NewOrganizationDialogComponent } from '../hierarchy/new-organization-dialog.component';
import {
	OrganizationStructureWizardUploadModalComponent, OrganizationStructureWizardUploadDialogInput
} from '@app/modules/hierarchy/structure/upload/organization-structure-wizard-upload-modal.component';
import {
	OrganizationFiltersWizardUploadModalComponent, OrganizationFiltersWizardUploadDialogInput
} from '@app/modules/hierarchy/filters/upload/organization-filters-wizard-upload-modal.component';
import {
	OrganizationEnrichmentWizardUploadDialogInput, OrganizationEnrichmentWizardUploadModalComponent
} from '@app/modules/hierarchy/enrichment/upload/organization-enrichment-wizard-upload-modal.component';


/**
 * This service should not be used in NG code. Change to `cxDialogService.openDialog(...)` when converted
 * */
@Injectable()
export class DowngradeDialogService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly cxDialogService: CxDialogService,
		private readonly documentExplorerDialogService: DocumentExplorerDialogService
	) { }

	openBulkUserRemoveDialog(input: IBulkUserRemoveDialogParams): NgbModalRef {
		return this.cxDialogService.openDialog(BulkUserRemoveDialogComponent, input);
	}

	openBulkPermissionsDialog(bodyText: string): Promise<BulkPermissionsUpdate> {
		return this.cxDialogService.openDialog(BulkPermissionsComponent, {bodyText}, {
			size: ModalSize.MEDIUM
		}).result;
	}

	openBulkDataAccessDialog(): Promise<BulkDataAccessUpdate> {
		return this.cxDialogService.openDialog(UserBulkDataAccessComponent, undefined, {
			size: ModalSize.MEDIUM
		}).result;
	}

	openUserPreferencesDialog(): NgbModalRef {
		return this.cxDialogService.openDialog(UserPreferencesDialogComponent, undefined, { keyboard: false });
	}

	openHierarchyPublishingDialog = (): NgbModalRef => {
		return this.cxDialogService.openDialog(HierarchyPublishingDialogComponent);
	};

	openHierarchyDeltaPublishingDialog = (): NgbModalRef => {
		return this.cxDialogService.openDialog(HierarchyDeltaPublishingDialogComponent);
	};

	openFilterCreationDialog = (input: FilterCreationWizardInput): NgbModalRef => {
		return this.cxDialogService.openDialog(
			FilterCreationWizardComponent,
			input,
			{
				size: ModalSize.EXTRA_LARGE,
				class: 'modal-xl br-rename-modal br-create-filter-modal'
			}
		);
	};

	openTemplateModalDialog = (input: TemplateModalInput): NgbModalRef => {
		return this.cxDialogService.openDialog(TemplateModalComponent,
			input,
			{size: ModalSize.MEDIUM});
	};

	openRenameModalDialog = (input: RenameModalInput): NgbModalRef => {
		return this.cxDialogService.openDialog(RenameModalComponent,
			input,
			{size: ModalSize.MEDIUM});
	};

	openProjectSelectionDialog = (): NgbModalRef => {
		return this.cxDialogService.openDialog(ProjectSelectionDialogComponent);
	};

	openBulkTransferDialog = (resolve: any): NgbModalRef => {
		return this.cxDialogService.openDialog(BulkTransferDialogComponent, resolve);
	};

	openSideBySideSelectorModal = (model: SideBySideSelectorModel): NgbModalRef => {
		return this.cxDialogService.openDialog(SideBySideSelectorModalComponent, model);
	};

	openUserCreateDialog = (resolve: UserCreateWizardInput): NgbModalRef => {
		return this.cxDialogService.openDialog(UserCreateWizardComponent, resolve, {class: 'user-create-wizard'});
	};

	openUserEditDialog = (resolve: IEditUserModalInput): NgbModalRef => {
		return this.cxDialogService.openDialog(EditUserModalComponent, resolve, { class: 'modal-fix' });
	};

	openDependenciesModal = (input: DependenciesDialogInput<StudioAsset> | DependenciesDialogInput<ProjectAsset>): NgbModalRef => {
		return this.cxDialogService.openDialog(DependenciesModalComponent, input, { size: ModalSize.LARGE });
	};

	openOrganizationStructureWizardUploadModal = (input: OrganizationStructureWizardUploadDialogInput): NgbModalRef => {
		return this.cxDialogService.openDialog(OrganizationStructureWizardUploadModalComponent, input, {
			size: ModalSize.MEDIUM,
			class: 'br-organization-structure-dialog'
		});
	};

	openOrganizationFiltersWizardUploadModal = (input: OrganizationFiltersWizardUploadDialogInput): NgbModalRef => {
		return this.cxDialogService.openDialog(OrganizationFiltersWizardUploadModalComponent, input, {
			size: ModalSize.MEDIUM,
			class: 'br-organization-filters-dialog'
		});
	};

	openOrganizationEnrichmentWizardUploadModal = (input: OrganizationEnrichmentWizardUploadDialogInput): NgbModalRef => {
		return this.cxDialogService.openDialog(OrganizationEnrichmentWizardUploadModalComponent, input, {
			size: ModalSize.MEDIUM,
			class: 'br-organization-enrichment-dialog org-enrichment-wizard-upload-z-index-1040'
		});
	};

	openBulkUpdateLabelsModal = (input: IBulkUpdateLabelsParams): NgbModalRef => {
		return this.cxDialogService.openDialog(BulkUpdateLabelsModalComponent, input);
	};

	openBulkCustomFieldModal = (input: BulkCustomFieldEntry): NgbModalRef => {
		return this.cxDialogService.openDialog(BulkCustomFieldComponent, input);
	};

	openDocumentExplorerModal = (input: IDocumentExplorerInput, fullscreen?: boolean): NgbModalRef => {
		return this.documentExplorerDialogService.open(input, fullscreen);
	};

	openGlobalOtherModal = (input: GlobalOtherExplorerOptions): void => {
		this.cxDialogService.openDialog(GlobalOtherExplorerModalComponent, input, {
			container: '#modal-container',
			size: ModalSize.LARGE,
			keyboard: false,
		});
	};

	openViewDriversModal = (input: DriversResultsModalInput): NgbModalRef => {
		return this.cxDialogService.openDialog(DriversResultsDialogComponent, input, {
			container: '#modal-container'
		});
	};

	openDocumentExplorerStats = (input: DocumentExplorerStatsData): NgbModalRef => {
		return this.cxDialogService.openDialog(DocumentExplorerStatsModalComponent, input, {
			class: 'br-stats-dialog',
		});
	};

	openAdvancedChartSettingsModal = (input: IAdvancedChartSettings): Promise<VisualProperties> => {
		let options: ModalOptions = { class: 'cb-advanced-chart-settings modal-xl'};
		return this.cxDialogService.openDialog(AdvancedChartSettingsComponent, input,
			options).result as Promise<VisualProperties>;
	};

	openDashboardChangeOwnerModal = (input: IDashboardOwnerChangeParams): Promise<boolean> => {
		return this.cxDialogService.openDialog(DashboardChangeOwnerDialogComponent, input, {
			class: 'br-custom-dialog',
		}).result as Promise<boolean>;
	};

	openAdvancedCloudSettingsModal = (input: IAdvancedCloudSettingsInput): Promise<CloudVisualProperties> => {
		return this.cxDialogService.openDialog(AdvancedCloudSettingsComponent, input, {
			class: 'cb-advanced-chart-settings modal-md',
		}).result as Promise<CloudVisualProperties>;
	};

	openDashboardTemplateCreationModal = (dashboard: Dashboard) => {
		let modalData: TemplateCreationData = {
			type: TemplateAssetType.DASHBOARD,
			assetId: dashboard.id,
			titleLabel: this.locale.getString('templates.newAssetTemplateTitle', {
				templateType: this.locale.getString('object.dashboard')
			}),
			showProperties: true,
			showMappings: true,
		};
		return this.cxDialogService.openDialog(TemplateCreateDialogComponent, modalData, {size: ModalSize.MEDIUM}).result;
	};

	openMetricTemplateCreationModal = (metric: Metric) => {
		let modalData: TemplateCreationData = {
			type: TemplateAssetType.METRIC,
			assetId: metric.id,
			titleLabel: this.locale.getString('templates.newAssetTemplateTitle', {
				templateType: this.locale.getString('object.metric')
			}),
			showProperties: true,
			showMappings: true,
		};
		return this.cxDialogService.openDialog(TemplateCreateDialogComponent, modalData, {size: ModalSize.MEDIUM}).result;
	};

	openFilterTemplateCreationModal = (filter: IFilter) => {
		let modalData: TemplateCreationData = {
			type: TemplateAssetType.FILTER,
			assetId: filter.id,
			titleLabel: this.locale.getString('templates.newAssetTemplateTitle', {
				templateType: this.locale.getString('object.filter')
			}),
			showProperties: true,
			showMappings: true,
		};
		return this.cxDialogService.openDialog(TemplateCreateDialogComponent, modalData, {size: ModalSize.MEDIUM}).result;
	};

	showUnsavedChangesDialog = (
		headerText?: string,
		bodyText?: string,
		hideRollbackOption?: boolean,
		disableSave?: boolean,
		saveText?: string,
		dontSaveText?: string,
		cancelText?: string
	): Promise<boolean> => {
		return this
			.cxDialogService
			.showUnsavedChangesDialog(
				headerText,
				bodyText,
				hideRollbackOption,
				disableSave,
				saveText,
				dontSaveText,
				cancelText
			)
		;
	};

	showUnsavedChangesDialogAndResolve = (
		confirmCallback: () => void,
		dontSaveCallback: () => void,
		headerText: string,
		bodyText: string,
		disableSave: boolean
	): Promise<void> => {
		return this
			.cxDialogService
			.showUnsavedChangesDialogAndResolve(
				confirmCallback,
				dontSaveCallback,
				headerText,
				bodyText,
				disableSave
			)
		;
	};

	openOptOutsModal = (alert: StudioAlert): Promise<string[]> => {
		return this
			.cxDialogService
			.openDialog(
				OptOutsModalComponent,
				{
					alert
				},
				{
					size: ModalSize.MEDIUM,
				}
			)
			.result
		;
	};

	showRecolorManageDialog(input: IRecolorManageDialogParams): NgbModalRef {
		return this.cxDialogService.openDialog(RecolorManageDialogComponent, input, {
			class: 'recolor-manage-dialog',
			size: ModalSize.MEDIUM
		});
	}

	showAsyncTaskStatusModal(taskId: string): NgbModalRef {
		return this.cxDialogService.openDialog(
			AsyncTaskStatusModalComponent,
			{ taskId }
		);
	}

	openNewHierarchyDialog = () => {
		return this.cxDialogService.openDialog(NewOrganizationDialogComponent, undefined, {size: ModalSize.MEDIUM}).result;
	};
}

app.service('downgradeDialogService', downgradeInjectable(DowngradeDialogService));

