import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import {DriversType} from '@cxstudio/drivers/entities/drivers-type';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import {
	ColorPalette
} from '@app/modules/account-administration/appearance/color-palettes/color-palette';

export class FieldComparer {

	private static isFolder = (item: any): boolean => {
		return item && /.*folder.*/i.test(item.type);
	};

	private static isDashboardFolder = (item: any): boolean  => {
		return item && item.type === 'folder';
	};

	static getPositionRank(value: any, gridType: GridTypes): number {
		//return rank, higher on the top
		if (!value) {
			return 0;
		} else if (this.isDateFolder(value)) {
			return 100;
		} else if (this.isAttributeDefaultFolder(value)) {
			return value.positionRank || 90;
		} else if (this.isSpecialFolder(value) || this.isDriversTemplate(value)) {
			return 90;
		} else if (this.isEffortDetectedFilter(value)) {
			return 20;
		} else if (this.isPredefinedMetric(value) || this.isPredefinedFilter(value) || this.isSystemPalette(value, gridType)) {
			return 10;
		} else {
			return 0;
		}
	}

	private static isSpecialFolder(value: any): boolean {
		return this.isFilterFolder(value)
				|| this.isMetricFolder(value)
				|| this.isScorecardFolder(value);
	}

	private static isPredefinedMetric(value: any): boolean {
		return GridContext.METRIC_PREDEFINED === value.type;
	}

	private static isPredefinedFilter(value: any): boolean {
		return FilterTypes.PREDEFINED === value.type;
	}

	private static isSystemPalette(value: ColorPalette, gridType: GridTypes): boolean {
		return gridType === GridTypes.PALETTES && value.system;
	}

	private static isEffortDetectedFilter(value: any): boolean {
		return 'easeScore' === value.subtype && 'All_Sent' === value.value;
	}

	private static isDateFolder(value: any): boolean {
		return FieldComparer.isFolder(value) && FilterTypes.CXDATE === value.id;
	}

	private static isScorecardFolder(value: any): boolean {
		return FieldComparer.isFolder(value) && FilterTypes.SCORECARD === value.id;
	}

	private static isDriversTemplate(value: any): boolean {
		return value && value.type === DriversType.drivers_template;
	}

	private static isAttributeDefaultFolder(value: any): boolean {
		return value && value.type === FolderTypes.ATTRIBUTE;
	}

	private static isFilterFolder(value: any): boolean {
		return value && value.type === FolderTypes.FILTER;
	}

	private static isMetricFolder(value: any): boolean {
		return value && value.type === FolderTypes.METRIC;
	}

	static getComparer(field: any, sortAsc: boolean, nameField: any, gridType: GridTypes): any {

		let compareInternal = (a: any, b: any) => {
			let multiplier = sortAsc ? -1 : 1;
			if (a === undefined || b === undefined) {
				return a === undefined ? -multiplier : multiplier;
			}
			let valueA = a[field];
			let valueB = b[field];

			// case insensitive for strings
			if (_.isString(valueA)) {
				valueA = valueA.toLowerCase();
			}

			if (_.isString(valueB)) {
				valueB = valueB.toLowerCase();
			}

			if ((valueA === undefined) !== (valueB === undefined)) {
				return valueA === undefined ? -multiplier : multiplier;
			}

			// if both are undefined or equal
			if ((valueA === undefined || valueA === valueB) && field !== nameField) {
				valueA = a[nameField].toLowerCase();
				valueB = b[nameField].toLowerCase();

				// if names are also equal - sort by id to be consistent
				if (valueA === valueB) {
					valueA = a.id;
					valueB = b.id;
				}

				return compareValues(valueA, valueB, multiplier);
			}

			// if equal and field is name - sort by id
			if ((valueA === undefined || valueA === valueB) && field === nameField) {
				return compareValues(a.id, b.id, multiplier);
			}

			return compareValues(valueA, valueB, -1);
		};

		let compareValues = (valueA: any, valueB: any, multiplier: number): number => {
			if (valueA > valueB) {
				return -multiplier;
			} else if (valueA < valueB) {
				return multiplier;
			} else {
				return 0;
			}
		};

		let fieldComparer = (a: any, b: any)  => {
			let multiplier = sortAsc ? -1 : 1; // to avoid reverse order for subfolders
			// both are in folders: need to find common parent
			// put all A parents into array(index = level)
			let mapA = [];
			let parent = a;
			while (parent) {
				mapA[parent.level || 0] = parent;
				parent = parent.parent;
			}
			// put all B parents into array(index = level)
			let mapB = [];
			parent = b;
			while (parent) {
				mapB[parent.level || 0] = parent;
				parent = parent.parent;
			}
			// starting from level=0, check their parents.
			// If equal - move on and check next, else - check their parents(or themselves) on that level
			let i;
			let min = Math.min(a.level + 1, b.level + 1);
			for (i = 0; i < min; i++) {
				if (mapA[i] === mapB[i])
					continue;
				break;
			}
			if (i === min && a.level !== b.level) { // ignore direction for subfolders/inner dashboards
				return i === a.level + 1 ? multiplier : -multiplier;
			}

			//if === compare internal, otherwise return higher rank one
			if (this.getPositionRank(mapA[i], gridType) !== this.getPositionRank(mapB[i], gridType)) {
				return this.getPositionRank(mapA[i], gridType) > this.getPositionRank(mapB[i], gridType) ? multiplier : -multiplier;
			}

			// only one is dashboard and not sort on name
			let dashboardFolder = this.isDashboardFolder(mapA[i]) || this.isDashboardFolder(mapB[i]);
			if ((!this.isFolder(mapA[i]) !== !this.isFolder(mapB[i])) && (field !== nameField || dashboardFolder)) {
				return !this.isFolder(mapA[i]) ? -multiplier : multiplier;
			}

			return compareInternal(mapA[i], mapB[i]);
		};
		return fieldComparer;
	}

	static getUpdateValueFieldComparer(field, sortAsc: boolean): any {
		return (a: any, b: any)  => {
			if (a === undefined || b === undefined) {
				return a === undefined ? -1 : 1;
			}
			let valueA = a.updateValue === field || (!a.updateValue && field === BulkUpdateValue.NONE);
			let valueB = b.updateValue === field || (!b.updateValue && field === BulkUpdateValue.NONE);

			if (valueA === valueB) {// if both are undefined or equal
				valueA = a.id;
				valueB = b.id;
			}
			return valueA > valueB ? -1 : 1; // if equal, sort by id
		};
	}
}
