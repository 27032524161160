import { MetricManagementApiService } from '@cxstudio/metrics/api/metric-management-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { ISharePermissionNameGetter } from '@cxstudio/sharing/generic-share.component';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { PermissionType } from '@app/core/authorization/all-permissions.service';

export class MetricSharingComponent implements ng.IComponentController {
	items: any[];

	resolve: {
		items: any[];
	};

	getPermissionName: ISharePermissionNameGetter = {
		edit: (): string => 'create_metric',
		shareView: (): string => 'share_metric',
		shareEdit: (): string => 'share_edit_metric',
		defaultSharePermission: (): string => this.security.has('share_metric') ? PermissionType.VIEW : PermissionType.EDIT
	};

	constructor(
		private metricApiService: MetricManagementApiService,
		private security: Security,
	) {}

	$onInit = () => {
		this.items = this.resolve.items;
	};

	getSharedUsersAndGroups = (items): ng.IPromise<any[]> => this.metricApiService.getSharedUsersAndGroups(items[0].id);

	getShareStatus = (): string => this.items[0].sharingStatus;

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.CREATE_METRIC;
}

app.component('metricSharing', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&',
	},
	controller: MetricSharingComponent,
	template: `
<generic-share
	object-type-name="{{::'object.metric'|i18n}}"
	resolve="::$ctrl.resolve"
	close="::$ctrl.close()"
	dismiss="::$ctrl.dismiss()"
	get-permission-name="::$ctrl.getPermissionName"
	get-shared-users-and-groups="$ctrl.getSharedUsersAndGroups"
	get-share-status="$ctrl.getShareStatus"
	name-property='displayName'
	owner="::$ctrl.items[0].ownerName"
	asset-edit-permission-type="$ctrl.getAssetEditPermissionType()">
</generic-share>`
});
