import { NgModule } from '@angular/core';
import { ContentWidgetSettingsComponent } from '@app/modules/widget-settings/content-widget/content-widget-settings/content-widget-settings.component';
import { ContentImageSettingsComponent } from '@app/modules/widget-settings/content-widget/image/content-image-settings/content-image-settings.component';
import { ImageGalleryComponent } from '@app/modules/widget-settings/content-widget/image/image-gallery/image-gallery.component';
import { BackgroundOptionsComponent } from '@app/modules/widget-settings/content-widget/text/background-options.component';
import { BorderOptionsComponent } from '@app/modules/widget-settings/content-widget/text/border-options.component';
import { CbTextEditorComponent } from '@app/modules/widget-settings/content-widget/text/cb-text-editor/cb-text-editor.component';
import { ImageSettingsModalComponent } from '@app/modules/widget-settings/content-widget/text/cb-text-editor/image-settings-modal.component';
import { ContentTextSettingsComponent } from '@app/modules/widget-settings/content-widget/text/content-text-settings/content-text-settings.component';
import { MarginOptionsComponent } from '@app/modules/widget-settings/content-widget/text/margin-options/margin-options.component';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';
import { ContentVideoSettingsComponent } from '@app/modules/widget-settings/content-widget/video/content-video-settings/content-video-settings.component';
import { SharedModule } from '@app/shared/shared.module';
import { NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { ImageGalleryDialogComponent } from './text/image-gallery-dialog/image-gallery-dialog.component';
import { ContentButtonSettingsComponent } from './button/content-button-settings/content-button-settings.component';
import { PushButtonsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CommonSettingsModule } from '@app/modules/widget-settings/common-settings/common-settings.module';
import { WidgetVisualizationsModule } from '@app/modules/widget-visualizations/widget-visualizations.module';

@NgModule({
	imports: [
		SharedModule,
		NgbProgressbarModule,
		DialogModule,
		PushButtonsModule,
		CommonSettingsModule,
		WidgetVisualizationsModule,
	],
	declarations: [
		CbTextEditorComponent,
		BorderOptionsComponent,
		BackgroundOptionsComponent,
		ImageSettingsModalComponent,
		MarginOptionsComponent,
		ImageGalleryComponent,
		ContentImageSettingsComponent,
		ContentTextSettingsComponent,
		ContentVideoSettingsComponent,
		ContentWidgetSettingsComponent,
		ImageGalleryDialogComponent,
		ContentButtonSettingsComponent,
	],
	providers: [
		TextWidgetUtilsService,
	]
})
export class ContentWidgetSettingsModule { }
