import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { DashboardTroubleshootingModalComponent, DashboardTroubleshootingModalInput } from '@app/modules/dashboard-actions/dashboard-troubleshooting-modal/dashboard-troubleshooting-modal.component';
import { WidgetMovementOptions } from '@app/modules/dashboard-actions/dashboard-widget-options.component';
import { FullscreenService } from '@app/modules/dashboard-actions/fullscreen-button/fullscreen.service';
import { VersionsHeaderService } from '@app/modules/dashboard/dashboard-versions/versions-header.service';
import { DashboardViewService } from '@app/modules/dashboard/dashboard-view/dashboard-view.service';
import { CxDialogService, ModalOptions, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardSaveState } from '@cxstudio/dashboards/dashboard-save-status.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { DashboardPropsService } from '@cxstudio/services/dashboard-props.service';
import { DrillToDashboardService } from '@cxstudio/services/drill-to-dashboard.service';
import { IActionsMenuItem } from '@app/modules/dashboard-actions/actions-menu/actions-menu.component';
import { DashboardAccessService } from '@app/modules/dashboard/dashboard-access.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { PromiseUtils } from '@app/util/promise-utils';
import { SpotCheckModalKeyboardUtils } from './spot-check/spot-check-modal-keyboard-utils';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';

@Component({
	selector: 'dashboard-actions',
	templateUrl: './dashboard-actions.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardActionsComponent extends SelfCleaningComponent implements OnInit {

	@Input() dashboard: Dashboard;
	@Input() editMode: boolean;
	@Input() tablet: boolean;
	@Input() mobile: boolean;
	@Input() hierarchyAccessDenied: boolean;
	@Input() dashboardMenu?: IActionsMenuItem<Dashboard>[];
	@Input() isEditedFromBook: () => boolean;
	@Input() save: () => Promise<void>;
	@Output() returnToBook = new EventEmitter<void>();
	@Output() cancel = new EventEmitter<void>();
	@Output() edit = new EventEmitter<void>();

	@ViewChild('spotCheckBox') spotCheckBox: NgbDropdown;

	exportUrl: string = `${this.urlService.getCXStudioEndpoint()}/export/dashboard/excel`;
	layout = this.layoutHelperService;
	widgetMovementOption = WidgetMovementOptions.PUSH_ON_DROP;
	dashboardProps = this.dashboardPropsService;
	savePromise: Promise<void>;
	isFullscreen: () => boolean = () => this.Fullscreen.isFullscreen;

	constructor(
		private readonly ref: ChangeDetectorRef,
		@Inject('drillToDashboardService') private readonly drillToDashboardService: DrillToDashboardService,
		private readonly locale: CxLocaleService,
		private readonly $location: CxLocationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		public Fullscreen: FullscreenService,
		@Inject('dashboardPropsService') private readonly dashboardPropsService: DashboardPropsService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('layoutHelperService') private readonly layoutHelperService: LayoutHelper,
		@Inject('widgetsEditService') private readonly widgetsEditService: WidgetsEditService,
		@Inject('dashboardSaveState') public dashboardSaveState: DashboardSaveState,
		private readonly versionsHeaderService: VersionsHeaderService,
		private readonly dashboardViewService: DashboardViewService,
		private readonly dialogService: CxDialogService,
		private readonly dashboardAccessService: DashboardAccessService,
		private readonly currentObjects: CurrentObjectsService
	) {
		super();
	}

	ngOnInit(): void {
		// required for typescript validation
		this.initMovementOptions();
		this.addSubscription(this.versionsHeaderService.getObservable().subscribe(() => {
			this.ref.markForCheck();
		}));
		this.addSubscription(this.currentObjects.getSnapshotViewChange().subscribe(() => {
			this.ref.markForCheck();
		}));
	}

	getReturnButtonLabel = (): string => this.drillToDashboardService.isFromBook() ?
		this.locale.getString('dashboard.returnToBook') :
		this.locale.getString('dashboard.returnToDashboard');

	processReturnButton = (): void => {
		this.$location.url(this.drillToDashboardService.getSourceUrl());
	};

	showReturnButton = (): string => this.dashboard
		&& this.drillToDashboardService.hasFilters(this.dashboard.id)
		&& this.drillToDashboardService.getSourceUrl();

	shareClick = (): void => {
		if (this.savePromise) {
			return;
		}

		if (this.editMode) {
			this.savePromise = this.save();
			this.savePromise.then(() => {
				this.shareDashboard();
			}, _.noop).finally(() => this.savePromise = undefined);
		} else {
			this.shareDashboard();
		}
	};

	private shareDashboard = (): void => {
		let sharedDashboardPromise = this.dashboardService.shareDashboard(this.dashboard,
			this.editMode, this.dashboardSaveState.versionId);

		sharedDashboardPromise.then(data => {
			if (data) {
				this.dashboard = data[0];
			}
		});
	};

	dashboardActionsAllowed = (): boolean => {
		return !this.versionsHeaderService.isEnabled() && !this.isFullscreen() && !this.isPreviewAsMode();
	};

	isPreviewAsMode = (): boolean => {
		return this.dashboardViewService.isPreviewAsMode();
	};

	getEditDashboardLabel = (): string => {
		return this.locale.getString('dashboard.editAriaLabel', { name: this.dashboard?.name });
	};

	getShareDashboardLabel = (): string => {
		return this.locale.getString('dashboard.shareAriaLabel', { name: this.dashboard?.name });
	};

	isFullscreenAllowed(): boolean {
		return !this.editMode && !this.versionsHeaderService.isEnabled() && !this.tablet && !this.isPreviewAsMode();
	}

	isEditingAllowed = (): boolean =>
		!this.versionsHeaderService.isEnabled()
		&& !this.hierarchyAccessDenied
		&& this.dashboard
		&& !this.drillToDashboardService.hasFilters(this.dashboard.id);

	initMovementOptions = (): void => {
		if (this.layout.pushOnDrop) {
			this.widgetMovementOption = WidgetMovementOptions.PUSH_ON_DROP;
		} else {
			this.widgetMovementOption = WidgetMovementOptions.PUSH_WHILE_DRAGGING;
		}
	};

	updateMovementOption = (option: any) => {
		this.widgetMovementOption = option;
		if (this.widgetMovementOption === WidgetMovementOptions.PUSH_WHILE_DRAGGING) {
			this.layout.pushOnDrop = false;
		}

		if (this.widgetMovementOption === WidgetMovementOptions.PUSH_ON_DROP) {
			this.layout.pushOnDrop = true;
		}
	};

	isSaveDisabled = (): boolean => {
		return !this.dashboardProps.current.name
			|| this.dashboardSaveState.personalizationInitializing
			|| this.widgetsEditService.isSaving();
	};

	openTroubleshootingGuidesModal(): void {
		const widgetsUsage = this.widgetsEditService.getReportWidgetsUsage();
		const input: DashboardTroubleshootingModalInput = { widgetsUsage };
		const options: ModalOptions = { size: ModalSize.MEDIUM };
		this.dialogService.openDialog(DashboardTroubleshootingModalComponent, input, options);
	}

	saveAndReturn(): void {
		this.save().then(() => this.returnToBook.emit());
	}

	canEditDashboard(): boolean {
		return this.dashboardAccessService.canEditDashboard(this.dashboard);
	}

	canShareDashboard(): boolean {
		return this.dashboardAccessService.canShare(this.dashboard);
	}

	// keyboard navigation

	onSpotCheckDialogOpenViaKeyboard(event: KeyboardEvent): void {
		event.stopPropagation();

		PromiseUtils.tick().then(() => SpotCheckModalKeyboardUtils.focusCloseButton());
	}

	onSpotCheckDialogClose(viaKeyboard?: boolean): void {
		this.spotCheckBox.close();

		if (viaKeyboard) {
			SpotCheckModalKeyboardUtils.focusSpotCheckToggle();
		}
	}

	processSpotCheckModalKeyboard(event: KeyboardEvent): void {
		SpotCheckModalKeyboardUtils.processSpotCheckModalKeyboard(event);
	}

	canToggleSnapshotView(): boolean {
		return FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard)
			&& this.canEditDashboard()
			&& !this.editMode
			&& !this.versionsHeaderService.isEnabled()
			&& !this.isPreviewAsMode();
	}

	hasSnapshot(): boolean {
		return (
			FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard)
			&& FrontlineDashboardUtils.isSnapshotView(this.dashboard)
		);
	}

	isSnapshotView(): boolean {
		return this.currentObjects.isSnapshotView() && FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard);
	}

	onSnapshotViewChange(value: boolean): void {
		this.currentObjects.setSnapshotView(value);
	}

	getNoSnapshotDataTooltip(): string | undefined {
		if (this.hasSnapshot()) {
			return;
		}

		return this.locale.getString('dashboard.dashboardNoSnapshotData');
	}
}

app.directive('dashboardActions', downgradeComponent({ component: DashboardActionsComponent }));
