import { PreviewWidgetProperties } from '@cxstudio/reports/entities/preview-widget-properties';
import { PreviewSentence } from '@cxstudio/reports/preview/preview-sentence-class';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { PreviewMode } from '@cxstudio/reports/entities/preview-mode';

export class FeedbackTranslationUtils {

	static getSentenceUniqueId = (sentence: PreviewSentence, props: PreviewWidgetProperties): string => {
		let projectPrefix = ProjectIdentifier.toStringKey(ProjectIdentifier.fromWidgetProperties(props));
		return `${projectPrefix}_${sentence.verbatimId}_${sentence.id}_${props.previewMode}`;
	};

	static getSentenceTranslationText = (sentence: PreviewSentence, props: PreviewWidgetProperties): string => {
		if (props.previewMode === PreviewMode.SENTENCES_WITH_CONTEXT) {
			let prevSentence = sentence.sentenceContext?.previous?.text;
			let nextSentence = sentence.sentenceContext?.next?.text;
			return `${prevSentence || ''} ${sentence.text} ${nextSentence}`.trim();
		}
		return sentence.text;
	};
}
