import ChartType from '@cxstudio/reports/entities/chart-type';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';

export class BubbleUtils {

	static readonly DEFAULT_BUBBLE_SIZE = 5;
	static readonly SIZE_OPTIONS = 10;

	private static isConstantSizeScatterChart = (options: VisualProperties) => {
		return ((options.visualization === VisualizationType.BUBBLE) && (options.size === ''));
	};

	private static isPrimarySeriesConstantSize = (options: VisualProperties) => {
		return ((options.subChartType === ChartType.BUBBLE) 
			&& (options.visualization === VisualizationType.DUAL) && (options.size === ''));
	};

	private static isSecondarySeriesConstantSize = (options: VisualProperties) => {
		return ((options.secondaryChartType === VisualizationType.BUBBLE) && (options.secondarySize === ''));
	};

	private static scalePercentage = (percentageString: string, scale: number) => {
		let numberStr = /[0-9\.]+/.exec(percentageString) as any;
		return Number(numberStr * scale) + '%';
	};

	private static scale = (chartOptions: Highcharts.Options, scaleValue: number) => {
		let retVal = {} as any;

		scaleValue = scaleValue || BubbleUtils.DEFAULT_BUBBLE_SIZE;
		let scalar = scaleValue / BubbleUtils.SIZE_OPTIONS;

		retVal.maxSize = BubbleUtils.scalePercentage(chartOptions.plotOptions.bubble.maxSize as string, scalar);
		retVal.minSize = BubbleUtils.scalePercentage(chartOptions.plotOptions.bubble.minSize as string, scalar);

		return retVal;
	};

	static adjustScale = (chartOptions: Highcharts.Options, options: VisualProperties) => {
		let sizes;
		let applySizes = series => $.extend(series, sizes);


		if (BubbleUtils.isPrimarySeriesConstantSize(options)) {
			sizes = BubbleUtils.scale(chartOptions, options.scale);
			chartOptions.series.filter((series: any) => series.isPrimary).forEach(applySizes);
		}

		if (BubbleUtils.isSecondarySeriesConstantSize(options)) {
			sizes = BubbleUtils.scale(chartOptions, options.secondaryScale);
			chartOptions.series.filter((series: any) => !series.isPrimary).forEach(applySizes);
		}

		if (BubbleUtils.isConstantSizeScatterChart(options)) {
			sizes = BubbleUtils.scale(chartOptions, options.scale);
			$.extend(chartOptions.plotOptions.bubble, sizes);
		}
	};
}