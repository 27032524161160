import { Component, OnInit, Input, Output, EventEmitter, Inject } from '@angular/core';
import * as moment from 'moment-timezone';
import { CxLocaleService } from '@app/core';
import { Comment } from '@app/shared/components/discussions/cb-discussion-comment-list.component';
import { AgeService } from '@app/modules/utils/dates/age.service';

@Component({
	selector: 'cb-discussion-comment',
	template: `
<div class="mb-16 discussion-meta">
	<cb-avatar
		class="discussion-avatar"
		attr.id="{{'commentId_' + comment.id}}"
		[user]="comment.owner"
		[size]="'40'">
	</cb-avatar>
	<div class="font-bold">{{comment.owner.firstName}} {{comment.owner.lastName}}</div>
	<i class="discussion-date mr-16">{{getItemAge(comment.createTime)|lowercase}}</i>
	<span
		class="discussion-options action-color cursor-pointer _t-comment-like"
		(click)="onToggleLike.emit({comment: comment})">
		{{likeDislikeText(comment)}} {{(comment.likes.length>0)?"(" + comment.likes.length + ")":""}}</span>
	<span
		class="discussion-options action-color cursor-pointer" *ngIf="canDelete"
		(click)="onDeleteComment.emit({comment: comment})">&nbsp;&nbsp;&bull;&nbsp;&nbsp;{{'comments.delete'|i18n}}</span>
</div>
<p class="w-100-percent discussion-comment" [innerHTML]="comment.content"></p>`
})

export class CbDiscussionCommentComponent implements OnInit {
	@Input() comment: Comment;
	@Input() canDelete: boolean;
	@Output() onToggleLike = new EventEmitter<any>();
	@Output() onDeleteComment = new EventEmitter<any>();

	constructor(
		private locale: CxLocaleService,
		@Inject('ageService') private ageService: AgeService
	) {}

	ngOnInit(): void {}

	getItemAge = (commentDate): string => {
		let untilNow = moment().diff(moment(commentDate), 'seconds');

		return this.ageService.getAgeText(untilNow);
	};

	likeDislikeText = (comment: Comment): string => {
		return comment.isLiked ?
			this.locale.getString('comments.unlike') :
			this.locale.getString('comments.like');
	};
}
