import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { TopicTranslationConfirmationDialogComponent } from '@app/modules/account-administration/topic-conversions/topic-translation/topic-translation-confirmation-dialog/topic-translation-confirmation-dialog.component';
import TranslatableLanguage, {
	getTranslatableLanguages
} from '@app/modules/account-administration/topic-conversions/topic-translation/translatable-language';
import {
	TopicTranslationService
} from '@app/modules/account-administration/topic-conversions/topic-translation/topic-translation.service';
import { FileUploadComponent } from '@app/modules/cx-form/file-upload/file-upload.component';
import { CxLocaleService } from '@app/core';

export type SelectableLanguage = TranslatableLanguage & { selected: boolean };

@Component({
	selector: 'topic-translation',
	templateUrl: './topic-translation.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopicTranslationComponent implements OnInit {
	@ViewChild(FileUploadComponent, { static: false })
	private fileUploadComponent: FileUploadComponent;

	public readonly MediaType = MediaType;
	public uploadedTopicModelFile?: string;
	public selectableLanguages: SelectableLanguage[];
	public successMessage?: string;

	constructor(
		private readonly cxDialogService: CxDialogService,
		private readonly topicTranslationService: TopicTranslationService,
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService
	) {
	}

	ngOnInit(): void {
		this.selectableLanguages = getTranslatableLanguages().map(
			translatableLanguage => {
				return {
					...translatableLanguage,
					selected: false
				};
			}
		);
	}

	public getFileUploadUrl(): string {
		return this.topicTranslationService.getUploadUrl();
	}

	public handleSuccessfulUpload(response: { uploadedTopicModelFile: string }): void {
		this.uploadedTopicModelFile = response.uploadedTopicModelFile;
		this.successMessage = undefined;
	}

	public handleRemovedUpload(): void {
		this.uploadedTopicModelFile = undefined;
		this.selectableLanguages = this.selectableLanguages.map(
			selectedLanguage => {
				return {
					...selectedLanguage,
					selected: false
				};
			}
		);

		this.successMessage = undefined;
	}

	public toggleSelectableLanguage(language: SelectableLanguage) {
		this.selectableLanguages = this.selectableLanguages.map(
			(selectedLanguage) => {
				if (selectedLanguage.locale === language.locale) {
					selectedLanguage.selected = !selectedLanguage.selected;
				}

				return selectedLanguage;
			}
		);
	}

	public isSubmitDisabled(): boolean {
		if (!this.uploadedTopicModelFile) {
			return true;
		}

		return this.getSelectedLanguages().length === 0;
	}

	public async submit(): Promise<void> {
		if (!this.uploadedTopicModelFile) {
			return;
		}


		const selectedLanguages = this.getSelectedLanguages();

		try {
			// Strip unique identifier `{ULID}/filename` from uploaded topic model
			const formattedTopicModelFile = this.uploadedTopicModelFile.replace(/^\w+\//, '');

			const addSelectedLanguageToTopicModelFile = (selectedLanguage) => {
				return formattedTopicModelFile.replace(
					/(\.[\w_-]+)$/i,
					`_${selectedLanguage.locale}$1`
				);
			};

			await this.cxDialogService.openDialog(
				TopicTranslationConfirmationDialogComponent,
				{
					uploadedTopicModelFile: formattedTopicModelFile,
					convertedTopicModelFiles: selectedLanguages.map(addSelectedLanguageToTopicModelFile),
				}
			).result;
		} catch (error) {
			return;
		}

		await this.topicTranslationService.startTranslation(this.uploadedTopicModelFile, selectedLanguages);

		this.fileUploadComponent.removeSelectedFile();
		this.successMessage = this.locale.getString(
			'topicConversions.topicTranslationOperationTriggered',
			null,
			'Operation Triggered'
		);

		this.ref.markForCheck();
	}

	private getSelectedLanguages(): SelectableLanguage[] {
		return this.selectableLanguages.filter(selectedLanguage => selectedLanguage.selected);
	}
}
