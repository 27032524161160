import { PeriodOverPeriodMetric } from './period-over-period-metric';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';
import ILocale from '@cxstudio/interfaces/locale-interface';

export class HistoricalMetric extends PeriodOverPeriodMetric {
	constructor(private locale: ILocale) {
		super();
	}
	
	getType = (): PeriodOverPeriodMetricType => {
		return PeriodOverPeriodMetricType.HISTORICAL;
	};
	getNamePrefix(): string {
		return 'period_2_';
	}
	getDisplayName = (parentMetricDisplayName: string): string => {
		return this.locale.getString('widget.historic') + ' ' + parentMetricDisplayName;
	};
}

app.service('historicalMetric', HistoricalMetric);