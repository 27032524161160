import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { DashboardViewService } from '@app/modules/dashboard/dashboard-view/dashboard-view.service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { CxLocationService } from '@app/core/cx-location.service';


@Component({
	selector: 'preview-as-bar',
	template: `
	<aside id="preview-bar" class="preview-as-header d-flex justify-between align-items-center pv-8 ph-16">
		<button
			class="btn btn-text"
			type="button"
			id="back-to-share"
			(click)="returnToShare()">
			<span class="q-icon q-icon-angle-left mr-8" aria-hidden="true"></span>
			<span [i18n]="'header.backToShare'"></span>
		</button>

		<p class="mb-0 text-white">
			<span class="q-icon q-icon-eye mr-8" aria-hidden="true"></span>
			<span *ngIf=fullName [i18n]="'header.previewAsBarTitle'" [i18nParams]="{username: fullName}"></span>
			<span *ngIf=!fullName [i18n]="'header.previewAsBarError'"></span>
		</p>

		<button
			class="btn btn-text"
			id="exit-preview-as"
			type="button"
			(click)="exitPreview()">
			<span [i18n]="'header.exitPreviewAs'"></span>
			<span class="q-icon q-icon-delete ml-8" aria-hidden="true"></span>
		</button>
	</aside>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PreviewAsBarComponent implements OnInit {

	fullName: string;

	constructor(
		private readonly dashboardViewService: DashboardViewService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		private readonly cxLocation: CxLocationService,
	) {}

	ngOnInit(): void {
		let user = this.dashboardViewService.getViewAsUser();
		this.fullName = user?.fullName || user?.email;
	}

	returnToShare(): void {
		let dashboardId = this.dashboardViewService.getDashboardId();
		let data = this.dashboardViewService.exitViewAsMode();
		this.redirectToDashboard(dashboardId);
		if (!data) {
			return;
		}
		this.dashboardService.restoreShareDashboard(data);
	}

	exitPreview(): void {
		let dashboardId = this.dashboardViewService.getDashboardId();
		this.dashboardViewService.exitViewAsMode();
		this.redirectToDashboard(dashboardId);
	}

	private redirectToDashboard(dashboardId: number) {
		if (dashboardId) {
			this.cxLocation.path(`/home/${dashboardId}`);
		} else {
			this.cxLocation.path(`/dashboards`);
		}

	}
}

app.directive('previewAsBar', downgradeComponent({component: PreviewAsBarComponent}));
