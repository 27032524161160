import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { IWidgetActions, WidgetAction } from '@cxstudio/home/widgets-edit.service';

@Component({
	selector: 'legacy-widget',
	template: `
<div class="w-100-percent h-100-percent br-legacy-widget-message">
	<div class="d-flex flex-direction-column h-100-percent justify-center align-center">
		<div>{{'widget.legacyWidgetError' | i18n}}</div>
		<button *ngIf="editMode"
			(click)="delete()"
			class="btn btn-primary mt-16 hide-pdf">
				{{ 'common.delete' | i18n }}
		</button>
	</div>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegacyWidgetComponent {

	@Input() widgetActions: IWidgetActions;
	@Input() editMode: boolean;

	delete(): void {
		return this.widgetActions.emit(WidgetAction.DELETE);
	}
}

app.directive('legacyWidget', downgradeComponent({component: LegacyWidgetComponent}));
