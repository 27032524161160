export class OverlayUtils {
	static readonly overlayClass = 'cxs-element-overlay';
	private static addToElement = (element: HTMLElement) => {
		if (OverlayUtils.overlayExists(element)) return;
		element.append(OverlayUtils.createOverlay());
	};

	static addToWidgets = () => {
		let widgetElements = document.querySelectorAll('.br-widget-box');
		widgetElements.forEach((widgetElement) => {
			OverlayUtils.addToElement(widgetElement as HTMLElement);
		});
	};

	private static createOverlay(): HTMLElement {
		const overlayDiv = document.createElement('div');
		overlayDiv.classList.add(OverlayUtils.overlayClass);
		return overlayDiv;
	}	

	private static overlayExists(element: HTMLElement): boolean {
		return element.querySelectorAll(`.${OverlayUtils.overlayClass}`).length > 0;
	}
}
