import { ChartAccessibilityService } from '@app/modules/widget-container/chart-accessibility.service';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { ColorConstants } from '../color/color-constants';
import { HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';

export default class HighchartsAccessibilityUtils {

	readonly DEFAULT_OUTLINE_WIDTH = 1;
	readonly DEFAULT_BUBBLE_OUTLINE_WIDTH = 2;

	constructor(
		private chartAccessibilityService: ChartAccessibilityService,
		private applicationThemeService: ApplicationThemeService
	) {
	}

	pickLightOrDarkColor<T>(lightOption: T, darkOption: T): T {
		let darkMode = this.applicationThemeService.isShowingDashboardDarkTheme();
		return darkMode ?
			darkOption :
			lightOption;
	}

	getReportBorderColor = (): string => {
		return this.pickLightOrDarkColor<string>(ColorConstants.GRAY_600, ColorConstants.WHITE);
	};

	applyBorder = (chart): void => {
		if (this.chartAccessibilityService.isPatternFillEnabled()) {

			let outlineColor = this.getReportBorderColor();

			for (let serie of chart.series) {
				let serieType = serie.type || chart.chart.type;

				this.ensureChartProperties(chart, serie, serieType);

				serie[serieType].borderWidth = serie[serieType].borderWidth || this.DEFAULT_OUTLINE_WIDTH;
				serie[serieType].borderColor = outlineColor;

				if (serie.marker) {
					let lineWidth = serie.marker.lineWidth || this.DEFAULT_OUTLINE_WIDTH;
					serie.marker.lineWidth = serieType === 'bubble' ? this.DEFAULT_BUBBLE_OUTLINE_WIDTH : lineWidth;
					serie.marker.lineColor = outlineColor;
				}

				chart.plotOptions[serieType].borderWidth = serie[serieType].borderWidth || this.DEFAULT_OUTLINE_WIDTH;
				chart.plotOptions[serieType].borderColor = outlineColor;
			}
		}
	};

	private ensureChartProperties = (chart, serie, serieType): void => {
		if (!serie[serieType])
			serie[serieType] = {};

		if (!chart.plotOptions)
			chart.plotOptions = {};

		if (!chart.plotOptions[serieType])
			chart.plotOptions[serieType] = {};
	};

	// execute after chart rendering
	applyLegendBorder = (chart): void => {
		if (this.chartAccessibilityService.isPatternFillEnabled()) {
			let outlineColor = this.getReportBorderColor();

			let legendSeries = this.getLegendSeries(chart);
			_.each(legendSeries, oneSeries => {
				if (oneSeries.legendSymbol) {
					$(oneSeries.legendSymbol.element).attr('stroke-width', this.DEFAULT_OUTLINE_WIDTH);
					$(oneSeries.legendSymbol.element).attr('stroke', outlineColor);
				}
			});
		}
	};

	private getLegendSeries = (chart): any[] => {
		let chartType = chart.userOptions?.chart?.type;
		if (chartType === 'pie') {
			let legendSeries = _.filter(chart.series, (oneSeries: any) => !!oneSeries.userOptions?.showInLegend);
			return _.flatten(_.map(legendSeries, oneSeries => oneSeries.data));
		}
		return _.filter(chart.series, (oneSeries: any) => !!oneSeries.legendSymbol);
	};

	generateAriaLabel(point: Partial<HighchartsFunctionScope>): string {
		// use default formatter
		if (!point.rawValue) {
			return;
		}

		let formattedValue = point.rawValue;
		if (!Number.isNaN(formattedValue) && !Number.isInteger(formattedValue)) {
			let roundedValue = Math.round(formattedValue * 100) / 100;
			formattedValue = parseFloat(roundedValue.toFixed(2));
		}

		return `${point.index}. ${point.name}, ${formattedValue}`;
	}

}

app.service('highchartsAccessibilityUtils', HighchartsAccessibilityUtils);
