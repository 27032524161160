import { NgModule } from '@angular/core';

import { SharedModule } from '@app/shared/shared.module';
import { LabelsInputComponent } from '@app/modules/asset-management/labels/labels-input.component';
import { NumberFormatComponent } from '@app/modules/asset-management/number-format/number-format.component';
import { NumberFormatPreviewComponent } from '@app/modules/asset-management/number-format/number-format-preview.component';
import { SimpleNumberFormatPreviewComponent } from './simple-number-format-preview/simple-number-format-preview.component';
import { FormsModule } from '@angular/forms';
import { UniqueNameDirective } from '@app/modules/asset-management/validation/unique-name.directive';
import { RenameModalComponent } from '@app/modules/asset-management/rename-modal/rename-modal.component';
import { DialogModule } from '@app/modules/dialog/dialog.module';
import { UnifiedAngularComponentsModule } from '@discover/unified-angular-components/dist/unified-angular-components';
import { ComponentTypesProvider } from './services/components-types-provider';
import { DependenciesColumnsProvider } from '@app/modules/asset-management/services/columns-providers/dependencies-columns-provider.service';
import { ComponentsColumnsProvider } from '@app/modules/asset-management/services/columns-providers/components-columns-provider';
import { DashboardModule } from '../dashboard/dashboard.module';
import { DependenciesModalComponent } from './dependencies-modal/dependencies-modal-component';
import { ConfigurationModalComponent } from './configuration-modal/configuration-modal.component';
import { ConfigurationSettingsComponent } from './configuration-modal/configuration-settings.component';
import { AssetEditorComponent } from './asset-editor/asset-editor.component';
import { EmbedModalComponent } from './embed-modal/embed-modal.component';
import { CalculationTypeSelectorComponent } from './calculation-type-selector/calculation-type-selector.component';

@NgModule({
	imports: [
		SharedModule,
		FormsModule,
		DialogModule,
		UnifiedAngularComponentsModule,
		DashboardModule
	],
	exports: [
		LabelsInputComponent,
		NumberFormatComponent,
		NumberFormatPreviewComponent,
		UniqueNameDirective,
		SimpleNumberFormatPreviewComponent,
		DependenciesModalComponent,
		ConfigurationModalComponent,
		EmbedModalComponent,
		CalculationTypeSelectorComponent,
	],
	declarations: [
		LabelsInputComponent,
		NumberFormatComponent,
		NumberFormatPreviewComponent,
		SimpleNumberFormatPreviewComponent,
		RenameModalComponent,
		UniqueNameDirective,
		DependenciesModalComponent,
		ConfigurationModalComponent,
		ConfigurationSettingsComponent,
		AssetEditorComponent,
		EmbedModalComponent,
		CalculationTypeSelectorComponent,
	],
	providers: [
		ComponentTypesProvider,
		DependenciesColumnsProvider,
		ComponentsColumnsProvider,
	]
})
export class AssetManagementModule {}
