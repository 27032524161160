import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { EnrichmentIcon, EnrichmentIconType } from '@cxstudio/reports/preview/enrichment-icon.class';

type StringFunction = (data: ConversationDataPoint) => string;

export class ConversationTooltipUtils {
	static iconTooltipFormatter = (colorFn: StringFunction, textFn: StringFunction,
			iconFn: StringFunction): StringFunction => {
		return (data: ConversationDataPoint) => {
			let fillColor = colorFn(data);
			let text = textFn(data);
			let icon = iconFn(data);
			const renderType = EnrichmentIconType.PILL;

			return new EnrichmentIcon()
					.withDisplayName(text)
					.withTitle(text)
					.withIcon(icon)
					.withColor(fillColor)
					.as(renderType);
		};
	};

	static simpleEnrichmentText(text: string, textClass: string): string {
		return new EnrichmentIcon()
			.withClasses(textClass)
			.withDisplayName(text)
			.as(EnrichmentIconType.TEXT);
	}

}
