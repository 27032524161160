import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { DashboardFilterSelection } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-selection';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';



@Component({
	selector: 'applied-dashboard-filters-list',
	template: `
		<span class="font-bold" *ngIf="filterLabel.length > 0">{{filterLabel}}:</span>
		<span class="text-ellipsis" [ngClass]="embedded && truncate ? 'max-width-2' : ''">
				<span class="filter-label" *ngIf="hasHierarchy">{{hierarchyLabel}} </span>
				<!-- "/" separator is added through css in :before pseudo-element -->
				<ng-container *ngFor="let df of appliedFiltersWithValue">
					<ng-template #tipContent>
						<div [innerHTML]="getAttrFilterTooltip(df)"></div>
					</ng-template>

					<span
						class="filter-label"
						[ngbTooltip]="tipContent"
						placement="bottom"
						container="body"
						tooltipClass="filter-tooltip-wrap">
						{{getFilterLabel(df)}}
					</span>
				</ng-container>
		</span>
	`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppliedDashboardFiltersListComponent {
	@Input() embedded = false;
	@Input() truncate = false;
	@Input() hasHierarchy = false;
	@Input() hierarchyLabel: string;
	@Input() appliedFiltersWithValue: DashboardFilter[];
	@Input() projectTimezone: string;
	@Input() filterLabel = '';
	constructor(
		@Inject('dateFilterService') private dateFilterService: DateFilterService,
		@Inject('dashboardFilterLabels') private dashboardFilterLabels: DashboardFilterLabelsService,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService
	) { }

	getAttrFilterTooltip = (dashboardFilter: DashboardFilter): string => {
		if (this.isDateRange(dashboardFilter.selectedAttribute)
			&& dashboardFilter.selectedAttributeValue.dateFilterMode === DateFilterMode.CUSTOM) {

			return this.dateFilterService
				.formatDateFilter(dashboardFilter.selectedAttributeValue.dateFilterRange, this.projectTimezone);
		}

		return this.dashboardFilterLabels.getFilterTooltip(dashboardFilter);
	};

	getFilterType = (filter: DashboardFilterSelection) => {
		return this.dashboardFiltersService.getFilterType(filter);
	};

	isDateRange = (attr): boolean => {
		let type = this.getFilterType(attr);
		return type === DashboardFilterTypes.DATE_RANGE;
	};

	getFilterLabel(df: DashboardFilter): string {
		return this.dashboardFilterLabels.getDashboardHeaderFilterLabel(df);
	}

}
