import { Inject, Injectable } from '@angular/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DocExplorerHelperService } from '@cxstudio/reports/document-explorer/doc-explorer-helper.service';	
import { downgradeInjectable } from '@angular/upgrade/static';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';

@Injectable({
	providedIn: 'root'
})
export class AlertPreviewService {
	readonly ALERT_PREVIEW_OBJECT_NAME = 'alertPreview';

	constructor(
		@Inject('docExplorerHelperService') private docExplorerHelperService: DocExplorerHelperService
	) {}

	isAlertPreviewWidget = (widget: Widget): boolean => {
		return widget.properties?.objectName === this.ALERT_PREVIEW_OBJECT_NAME;
	};
	
	createDocumentExplorerSettings = (alert: StudioAlert): Widget => {
		let widget: Widget = this.docExplorerHelperService.createDocumentExplorerSettings();
		widget.properties.objectName = this.ALERT_PREVIEW_OBJECT_NAME;
		widget.properties.drillFilters = [{
			type: DrillType.ALERT_DOCUMENT,
			name: alert.id + '',
			values: []
		}];
		return widget;
	};

}

app.service('alertPreviewService', downgradeInjectable(AlertPreviewService));
