import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { PredefinedMetricValue } from '@cxstudio/metrics/predefined/predefined-metric-value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import * as _ from 'underscore';
import IFilter from '@cxstudio/report-filters/entity/filter';


interface IPredefinedFilter extends IFilter {
	subtype?: string; // metric raw name
	value: PredefinedMetricValue;
}

export class PredefinedFiltersService {

	private filterValues: PredefinedMetricValue[] = [
		PredefinedMetricValue.NEGATIVE,
		PredefinedMetricValue.NEUTRAL,
		PredefinedMetricValue.POSITIVE
	];

	private sentimentFilters = {
		POSITIVE: { displayName: 'Positive Sentiment (predefined)', name: PredefinedMetricValue.POSITIVE},
		NEUTRAL: { displayName: 'Neutral Sentiment (predefined)', name: PredefinedMetricValue.NEUTRAL},
		NEGATIVE: { displayName: 'Negative Sentiment (predefined)', name: PredefinedMetricValue.NEGATIVE}
	};
	private sentimentFilterMap = _.values(this.sentimentFilters);

	constructor(
		private locale: ILocale,
		private betaFeaturesService: BetaFeaturesService
	) {
	}

	getAllFilters = (): IPredefinedFilter[] => {
		let predefinedMetrics = this.getEnabledPredefinedMetrics();
		let filters = _.chain(predefinedMetrics).map(metric =>
				_.map(this.filterValues, value => this.getFilterObject(metric, value))
			).flatten().value();

		filters = _.union(filters, [
			this.getFilterObject(PredefinedMetricConstants.EASE_SCORE, 'All_Sent' as PredefinedMetricValue),
			this.getFilterObject(PredefinedMetricConstants.EMOTION, 'All_Sent' as PredefinedMetricValue)
		]);

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT)) {
			filters.push(
				this.getFilterObject(PredefinedMetricConstants.SENTIMENT, 'All_Sent' as PredefinedMetricValue)
			);
		}

		return filters;
	};

	removePredefinedAnalyzeFilters = (filters: IFilter[]): IFilter[] => {
		return _.filter(filters, filter => {
			return !_.findWhere(this.sentimentFilterMap, {displayName: filter.name});
		});
	};

	withStudioPredefinedFilters = (filters: IFilter[]): IFilter[] => {
		filters = _.filter(filters, filter => !this.isPredefinedAnalyzeFilter(filter));
		return _.union(filters, this.getAllFilters());
	};

	isPredefinedAnalyzeFilter = (filter: IFilter): boolean => {
		return (filter.type === FilterTypes.CMP || filter.type === FilterTypes.CXANALYZE)
			&& !!_.findWhere(this.sentimentFilterMap, {displayName: filter.name});
	};

	private getFilterObject(name: string, value: PredefinedMetricValue): IPredefinedFilter {
		let filterLabelOverride: PredefinedMetricValue;
		if (
			this.betaFeaturesService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT) &&
			name === PredefinedMetricConstants.SENTIMENT &&
			value === PredefinedMetricValue.NEUTRAL
		) {
			filterLabelOverride = PredefinedMetricValue.MIXED;
		}
		return {
			name: this.getFilterName(name, filterLabelOverride ?? value),
			type: FilterTypes.PREDEFINED,
			subtype: name,
			value
		} as IPredefinedFilter;
	}

	private getFilterName(base: string, value: PredefinedMetricValue): string {
		let name = this.locale.getString(`metrics.${base}_filter_${value}`);
		let predefinedLabel = this.locale.getString('widget.predefined');
		return `${name} (${predefinedLabel})`;
	}

	private getEnabledPredefinedMetrics(): PredefinedMetricConstants[] {
		return [PredefinedMetricConstants.SENTIMENT, PredefinedMetricConstants.EASE_SCORE, PredefinedMetricConstants.EMOTION];
	}

}

app.service('predefinedFiltersService', PredefinedFiltersService);
