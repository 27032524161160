import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { PropertyValue } from '@app/modules/project/state-changes/property-changes';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { Caches } from '@cxstudio/common/caches';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CxHttpHandlers } from '@app/core/http/cx-http-handlers.service';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { AttributesStatsResponse } from '@app/modules/project/attribute/attributes-stats-response';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceAttributeManagementApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxHttp: CxHttpService,
		private readonly cxCachedHttpService: CxCachedHttpService,
		private readonly httpHandlers: CxHttpHandlers
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.ATTRIBUTES);
	}

	getAdminAttributes = (
		project: WorkspaceProject, filters: ObjectListFilter[], cache = CacheOptions.NOT_CACHED
	): AssetPromisePlatformProject<IAdminAttribute[]> => {
		let config = { cache };
		let url = this.getBasePath(project);
		return this.cachedHttp.post(url, { filters }, config) as AssetPromisePlatformProject<IAdminAttribute[]>;
	};

	getAttributesStats = (
		project: WorkspaceProject, filters: any[], cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<AttributesStatsResponse> => {
		let params = {
			attributeFilters: filters
		} as any;
		if (!cache) {
			params.forceUpdate = true;
		}
		let config = { cache, local: true };
		let url = this.getBasePath(project) + '/stats';
		return this.cachedHttp.post(url, params, config) as AssetPromisePlatformProject<AttributesStatsResponse>;
	};

	requestAttributesReport = (
		project: WorkspaceProject, withHidden: boolean, filters: ObjectListFilter[], attributeOrder: number[]
	): AssetPromisePlatformProject<void> => {
		let url = this.getBasePath(project) + '/xls';
		return this.cxHttp.post(url, { filters, withHidden, attributeOrder }) as AssetPromisePlatformProject<void>;
	};

	saveAttributesSettings = (
		project: WorkspaceProject, useInClarabridgeSearch: PropertyValue<boolean>[]
	): AssetPromisePlatformProject<void> => {
		let url = this.getBasePath(project) + '/settings';
		return this.cxHttp.post(url, { useInClarabridgeSearch })
			.then(this.httpHandlers.invalidateCacheHandler(Caches.ATTRIBUTES)) as AssetPromisePlatformProject<void>;
	};

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/manage/ws/${project.workspaceId}/project/${project.projectId}/attributes`;
	};
}
