import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'pagination',
	templateUrl: './pagination.component.html',
	encapsulation: ViewEncapsulation.None,
	styles: [`
		.pagination-container.disabled-last {
			.pagination > li:nth-last-child(1) > a { display: none;	}
			.pagination > li:nth-last-child(3){	display: none;	}

			ngb-pagination { display: flex; }
		}
	`]
})

export class PaginationComponent extends SelfCleaningComponent implements OnInit {

	@Input() pagination: Pagination;
	@Input() pullRight: boolean = false;
	@Input() removeBoundary: boolean = false;
	@Input() compact: boolean = false;
	@Output() paginationChange = new EventEmitter<Pagination>();

	 // max number of items beyond which we disable the go-to-last-page button, to prevent dashboards from crashing due to loading too many sentences at once -- jira: DISC-29394
	@Input() disableLastPageThreshold: number = 5000;

	MAX_PAGES = 1000; // hard cap on the number of PAGES we will allow, to prevent issues with ngb-pagination -- jira: STUDIO-265

	constructor() {
		super();
	}

	ngOnInit(): void {
		this.addSubscription(this.pagination.getChangeObserver().subscribe(loading => {
			this.pageChanged();
		}));
	}

	pageChanged(): void {
		this.paginationChange.emit(this.pagination);
	}

	isRemoveBoundary(): boolean {
		return this.isPageLimitReached() || this.removeBoundary;
	}

	getCollectionSize(): number {
		return Math.min(this.pagination.totalItems, this.MAX_PAGES * this.pagination.pageSize);
	}

	isPageLimitReached(): boolean {
		return this.pagination.totalItems >= this.disableLastPageThreshold;
	}
}

app.directive('pagination', downgradeComponent({component: PaginationComponent}));
