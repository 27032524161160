import { OnChanges } from '@angular/core';
import { Inject, EventEmitter, Output, Input, Component,
	OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { SelectableItem } from '@app/shared/components/select-items/select-items.component';
import { Security } from '@cxstudio/auth/security-service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { UserApiService } from '@cxstudio/services/data-services/user-api-service';
import { AccessExpirationOption } from '@cxstudio/user-administration/users/access-expiration/access-expiration-option.service';
import { AccessExpirationState } from '@cxstudio/user-administration/users/access-expiration/access-expiration-state.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { IUserColumn } from '@cxstudio/user-administration/users/users.component';
import * as moment from 'moment';
import { RestUserData } from '../entities/rest-user-data';

interface IMenuOption {
	text: string;
	name: string;
	func: (user: User) => void;
}

export interface SelectableUser extends User, SelectableItem {}
@Component({
	selector: 'user-table',
	templateUrl: './user-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserTableComponent implements OnInit, OnChanges {

	@Input() userData: SelectableUser[];
	@Input() selectedUsers: SelectableUser[];
	@Input() userColumns: IUserColumn[];
	@Input() showAccessExpiration: boolean;
	@Input() showCustomField: boolean;
	@Input() showSelectedOnly: boolean;
	@Input() trigger: boolean;
	@Output() onMenuClick = new EventEmitter<{$event: any; $user: SelectableUser}>();
	@Output() onChange = new EventEmitter<void>();
	@Output() selectionToggle = new EventEmitter<SelectableUser>();

	adminMenuOptions: IMenuOption[];
	nonAdminMenuOptions: IMenuOption[];
	liteAdminByAccountOwner: IMenuOption[];
	visibleUserData: SelectableUser[];
	options: any;
	states: any;

	constructor(
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		@Inject('security') private readonly security: Security,
		private readonly locale: CxLocaleService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		private readonly cxDialogService: CxDialogService,
		@Inject('userApiService') private readonly userApiService: UserApiService,
		@Inject('accessExpirationState') private readonly accessExpirationState: AccessExpirationState,
		@Inject('accessExpirationOption') private readonly accessExpirationOption: AccessExpirationOption
	) {}

	ngOnInit(): void {
		this.options = this.accessExpirationOption.values();
		this.states = this.accessExpirationState.values();
		this.adminMenuOptions = [
			{text: this.options.NOW.menuText, name: 'revokeAccessNow', func: this.revokeFunction(this.options.NOW)},
			{text: this.options.DAYS_1.menuText, name: 'revokeAccess1days', func: this.revokeFunction(this.options.DAYS_1)},
			{text: this.options.DAYS_3.menuText, name: 'revokeAccess3days', func: this.revokeFunction(this.options.DAYS_3)},
			{text: this.options.DAYS_7.menuText, name: 'revokeAccess7days', func: this.revokeFunction(this.options.DAYS_7)}
		];
		this.nonAdminMenuOptions = [
			{text: this.options.NOW.menuText, name: 'revokeAccessNow', func: this.revokeFunction(this.options.NOW)},
			{text: this.options.DAYS_7.menuText, name: 'revokeAccess7days', func: this.revokeFunction(this.options.DAYS_7)},
			{text: this.options.DAYS_30.menuText, name: 'revokeAccess30days', func: this.revokeFunction(this.options.DAYS_30)},
			{text: this.options.DAYS_90.menuText, name: 'revokeAccess90days', func: this.revokeFunction(this.options.DAYS_90)}
		];
		this.liteAdminByAccountOwner = [
			{text: this.options.NOW.menuText, name: 'revokeAccessNow', func: this.revokeFunction(this.options.NOW)},
			{text: this.options.DAYS_1.menuText, name: 'revokeAccess1days', func: this.revokeFunction(this.options.DAYS_1)},
			{text: this.options.DAYS_3.menuText, name: 'revokeAccess3days', func: this.revokeFunction(this.options.DAYS_3)},
			{text: this.options.DAYS_7.menuText, name: 'revokeAccess7days', func: this.revokeFunction(this.options.DAYS_7)},
			{text: this.options.DAYS_30.menuText, name: 'revokeAccess30days', func: this.revokeFunction(this.options.DAYS_30)},
			{text: this.options.DAYS_90.menuText, name: 'revokeAccess90days', func: this.revokeFunction(this.options.DAYS_90)}
		];

		this.checkUserData();
	}

	ngOnChanges(): void {
		this.checkUserData();
	}

	onSearchChange = (): void => {
		this.onChange.emit();
	};

	getAccessExpirationMessage = (user: User): string => {
		let expirationState = this.accessExpirationState.findState(user.accessExpirationState);
		if (!_.isUndefined(expirationState)) {
			return expirationState.message(user as RestUserData);
		}
	};

	showHamburgerMenu = (): boolean => {
		return this.security.has('manage_users');
	};

	menuOptions = (user: User): IMenuOption[] => {
		if (user.liteAdmin) {
			return this.security.isAccountOwner()
				? this.liteAdminByAccountOwner
				: this.adminMenuOptions;
		}

		return this.nonAdminMenuOptions;
	};

	showUserExpirationMenu = (event, user) => {
		this.contextMenuTree.showObjectListMenu(event, user, this.menuOptions(user), 'users', 360);
	};

	userCanExpire = (user: User) => {
		return user.accessExpirationState !== this.states.NEVER_EXPIRE.value;
	};

	isExpiringUserInList = (users): boolean => {
		if (!users || !users.length) {
			return false;
		}

		return !!_.find(users, this.userCanExpire);
	};

	private revokeFunction(expiration): (user: User) => void {
		return (user) => {
			this.revokeAccess(user, expiration);
		};
	}

	private revokeAccess(user: User, expiration): void {
		let confirmationDialog = this.cxDialogService.regularWithConfirm(expiration.dialogTitle, expiration.dialogConfirm,
			this.locale.getString('common.yes'), this.locale.getString('common.no'));

		confirmationDialog.result.then(() => {
			if (expiration.name === this.options.NOW.name) {
				this.expireNow(user);
			} else {
				this.extendUserAccess(user, expiration.period);
			}
		});
	}

	private expireNow(user): void {
		let expirationDate = moment().toDate();
		this.changeExpirationDate(user, expirationDate);
	}

	private extendUserAccess(user, period): void {
		let expirationDate;
		if (moment().isBefore(moment(user.accessExpirationDate))) {
		//user not yet expired
			expirationDate = moment(user.accessExpirationDate).add(period, 'days').toDate();
		} else {
			expirationDate = moment().add(period, 'days').toDate();
		}
		this.changeExpirationDate(user, expirationDate);
	}

	private changeExpirationDate(user, expirationDate): void {
		this.userApiService.updateAccessExpirationDate(user.userId, expirationDate).then(() => {
			this.$rootScope.$broadcast('reloadUserList');
		});
	}

	private checkUserData = () => {
		if (this.showSelectedOnly) {
			this.visibleUserData = this.selectedUsers;
		} else {
			this.visibleUserData = this.userData;
		}

		_.map(this.userData, (user) => {
			if (_.find(this.selectedUsers, selectedUser => selectedUser.userEmail === user.userEmail)) {
				user.selected = true;
			} else {
				delete user.selected;
			}
		});
	};

	toggleSelection(user: SelectableUser): void {
		user.selected = !user.selected;
		this.selectionToggle.emit(user);
	}
}

app.directive('userTable', downgradeComponent({ component: UserTableComponent }));
