import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';
import { AssetType } from '@app/modules/access-management/groups/asset-type';
import { IHiddenAssetGroupsInfo } from '@app/modules/access-management/groups/hidden-asset-groups-info';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { ColDef } from 'ag-grid-enterprise';

@Component({
	selector: 'hidden-assets-table',
	template: `
<default-objects-table
	tableName="projectHiddenAssets"
	class="w-100-percent h-100-percent overflow-hidden"
	[ngBusy]="loading"
	[columnDefs]="columnDefs"
	[rowData]="items"
	[withFolders]="false"
	[quickFilterText]="searchText"
></default-objects-table>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HiddenAssetsTableComponent implements OnInit, OnChanges {
	@Input() project: WorkspaceProject;
	@Input() resourceType: AssetType;
	@Input() searchText: string;

	loading: Promise<unknown>;
	columnDefs: ColDef[];
	items: IHiddenAssetGroupsInfo[];

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private assetAccessApiService: AssetAccessApiService,
		private objectListColumns: ObjectListColumnsService,
	) {}

	ngOnInit(): void {
		this.items = [];
		this.columnDefs = [
			this.objectListColumns.textColumn('displayName', this.locale.getString('common.name')),
			this.objectListColumns.textColumn('groupsWithUser', this.locale.getString('administration.myGroups')),
			this.objectListColumns.textColumn('groupsWithoutUser', this.locale.getString('administration.otherGroups')),
		];
		this.reload();
	}

	ngOnChanges(changes: SimpleChanges<HiddenAssetsTableComponent>): void {
		if (ChangeUtils.hasChange(changes.project)) {
			this.reload();
		}
	}

	reload(): void {
		if (!WorkspaceTransitionUtils.isProjectSelected(this.project))
			return;
		this.loading = this.assetAccessApiService.getHiddenAssetGroupsInfo(this.project).then((result) => {
			this.items = result.filter(asset => asset.type === this.resourceType);
			this.ref.markForCheck();
		});
	}


}
