import { Component, OnInit, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { Security } from '@cxstudio/auth/security-service';
import { DashboardExportEvent } from '@app/core/cx-event.enum';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { IActionsMenuItem } from '@app/modules/dashboard-actions/actions-menu/actions-menu.component';
import { MenuUtils } from '@app/modules/dashboard-actions/actions-menu/menu-utils';
import { CxLocaleService } from '@app/core';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { LayoutHelper } from '@cxstudio/dashboards/layout-helper.service';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterLabelsService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-labels.service';
import { FiltersToggle } from '@app/modules/dashboard/dashboard-filters-panel/dashboard-filters-panel.component';
import { ReportProjectContextService } from '@app/modules/project/context/report-project-context.service';

@Component({
	selector: 'embedded-dashboard-header',
	templateUrl: './embedded-dashboard-header.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
		.header-index {
			z-index: 10;
		}
	`]
})
export class EmbeddedDashboardHeaderComponent extends SelfCleaningComponent implements OnInit {
	@Input() containerId: string;
	@Input() dashboard: Dashboard;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() personalization: PersonalizationState;
	@Input() isEmbeddedInternally: boolean;
	@Input() truncate: boolean;

	menuItems: IActionsMenuItem<Dashboard>[] = [];
	projectTimezone: string;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly eventBus: GlobalEventBus,
		private readonly locale: CxLocaleService,
		private readonly reportProjectContextService: ReportProjectContextService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject('layoutHelperService') public readonly layout: LayoutHelper,
		@Inject('dashboardFilterLabels') private dashboardFilterLabels: DashboardFilterLabelsService,

	) {
		super();
	}

	ngOnInit(): void {
		this.menuItems = this.getMenuItems(this.dashboard);
		this.updateTimezone(this.dashboard);
	}

	private getMenuItems(dashboardItem: Dashboard): IActionsMenuItem<Dashboard>[] {
		let menu: IActionsMenuItem<Dashboard>[] = [];

		if (!dashboardItem) {
			return [];
		}

		menu.push({
			text: this.locale.getString('widget.embedViewInDiscover'),
			name: 'openInDiscover',
			onClick: () => this.openInDiscover(),
		});
		menu.push(MenuUtils.getDivider());

		menu.push({
			text: this.locale.getString('dashboard.refresh'),
			name: 'refresh',
			onClick: (dashboard) => this.dashboardService.hardRefreshDashboard(dashboard.id),
		});
		menu.push(MenuUtils.getDivider());

		if (this.security.has('export_widget_data')) {
			menu.push({
				text: this.locale.getString('dashboard.exportWidgetData'),
				name: 'export_all',
				onClick: () => this.exportDashboardData(),
			});
		}
		menu.push({
			text: this.locale.getString('dashboard.exportPDF'),
			name: 'export_pdf_menu',
			onClick: MenuUtils.ignoreClick,
			items: [{
				text: this.locale.getString('dashboard.downloadPdf'),
				name: 'export_pdf',
				onClick: () => this.exportDashboardPDF()
			}, {
				text: this.locale.getString('dashboard.ReceiveByEmail'),
				name: 'export_pdf_download',
				onClick: () => this.exportDashboardPDFEmail()
			}],
		});
		menu.push(MenuUtils.getDivider());

		menu.push({
			component: true,
			name: 'zoom',
			onClick: MenuUtils.ignoreClick,
		});
		menu.push(MenuUtils.getDivider());

		if (this.security.getCurrentMasterAccount().darkModeAvailable) {
			menu.push({
				component: true,
				name: 'dark-mode-toggle',
				onClick: MenuUtils.ignoreClick,
			});
		}
		menu.push({
			component: true,
			name: 'experimental-ui',
			onClick: MenuUtils.ignoreClick,
		});
		menu.push({
			component: true,
			name: 'pattern-fills',
			onClick: MenuUtils.ignoreClick,
		});

		return menu;
	}

	private exportDashboardPDF(): void {
		this.eventBus.broadcast(DashboardExportEvent.PDF_DOWNLOAD);
	}

	private exportDashboardPDFEmail(): void {
		this.eventBus.broadcast(DashboardExportEvent.PDF_EMAIL);
	}

	private exportDashboardData(): void {
		this.eventBus.broadcast(DashboardExportEvent.XLS);
	}

	private openInDiscover(): void {
		const url = this.urlService.getDashboardUrl(this.dashboard.id);
		window.open(url, '_blank');
	}

	updateTimezone(dashboard: Dashboard): void {
		this.reportProjectContextService.getDashboardProjectTimezone(dashboard).then(timezone => {
			this.projectTimezone = timezone;
			this.ref.detectChanges();
		});
	}
	getAppliedDashboardFilters = (): DashboardFilter[] => {
		return this.dashboardHistory.getAppliedFilters();
	};
	getAppliedFiltersWithValue = (): DashboardFilter[] => {
		return this.getAppliedDashboardFilters().filter(filter => this.dashboardFilterLabels.hasValue(filter));
	};

	hasHierarchy = (): boolean => {
		return Boolean(this.personalization?.isHierarchyEnabled() && this.personalization.currentHierarchyNode?.name);
	};

	getHierarchyLabel = () => {
		if (this.hasHierarchy()) {
			let personalization = this.personalization;
			return `${personalization.currentHierarchyName}: ${personalization.currentHierarchyNode.name}`;
		}
		return '';
	};

	getToggleType = (): FiltersToggle => {
		return this.isEmbeddedInternally ? FiltersToggle.BUTTON: FiltersToggle.ICON;
	};

	getFilterLabel = (): string => {
		return this.locale.getString('common.filters');
	};

}
