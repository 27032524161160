import { Inject, Injectable } from '@angular/core';
import { GeographyOptionsService } from '@cxstudio/attribute-geography/geography-options.service';
import { PointSelectionUtils } from '@cxstudio/reports/utils/analytic/point-selection-utils.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import GeographyReportData from '@cxstudio/attribute-geography/geography-report-data';

@Injectable({
	providedIn: 'root'
})
export class StatsModeService {
	constructor(
		@Inject('geographyOptionsService') private readonly geographyOptionsService: GeographyOptionsService,
		private readonly cxLocaleService: CxLocaleService,
		@Inject('pointSelectionUtils') private readonly pointSelectionUtils: PointSelectionUtils
	) {}

	public getTableDataAdditionalInfo = (widget: Widget, dataObject: GeographyReportData): string => {
		if (this.isMissingGeographiesMapWidget(widget, dataObject)) {
			const boundaryField = dataObject.metadata.boundaryField;
			const boundaryTypeDisplayName = this.geographyOptionsService.getOptionDropdownName(boundaryField);
			const boundaryFieldDisplayName = this.geographyOptionsService.getBoundaryFieldDisplayName(boundaryField);

			return this.cxLocaleService.getString('widget.missingGeographiesMessage', {
				boundaryType: boundaryTypeDisplayName,
				boundaryField: boundaryFieldDisplayName
			});
		}
	};

	public hasAdditionalTableInfo = (widget: Widget, dataObject: any, rootWidget: Widget): boolean => {
		return this.isMissingGeographiesMapWidget(widget, dataObject)
			|| this.isFilteringOnInteractionNetworkWidget(widget, rootWidget);
	};

	public getAdditionalInfoHover = (widget: Widget, dataObject: any, rootWidget: Widget): string => {
		if (this.isMissingGeographiesMapWidget(widget, dataObject)) {
			return this.cxLocaleService.getString('widget.geographyAdditionalInfoHover');
		} else if (this.isFilteringOnInteractionNetworkWidget(widget, rootWidget)) {
			return this.cxLocaleService.getString('widget.networkAdditionalInfoHover');
		}
		return '';
	};

	private isMissingGeographiesMapWidget = (widget: Widget, dataObject: GeographyReportData): boolean => {
		return widget.name === WidgetType.MAP && dataObject?.metadata?.hasMissingGeographies;
	};

	public isFilteringOnInteractionNetworkWidget = (widget: Widget, rootWidget: Widget): boolean => {
		return (this.isInteractionNetworkWidget(widget) && !!this.pointSelectionUtils.getSelectedPoint(widget.containerId, widget.id))
			|| (!!widget.drillPath && this.isInteractionNetworkWidget(rootWidget));
	};

	private isInteractionNetworkWidget = (widget: Widget): boolean => {
		return widget.name === WidgetType.NETWORK && !!widget.properties?.documentLevelOnly;
	};
}

app.service('statsModeService', downgradeInjectable(StatsModeService));
