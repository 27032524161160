import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DocumentSharingApiService } from '@cxstudio/services/data-services/document-sharing-api-service';
import { PromiseUtils } from '@app/util/promise-utils';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Injectable()
export class DocumentLinkCopyService {
	constructor(
		@Inject('documentSharingApiService') private documentSharingApiService: DocumentSharingApiService,
		private toastService: ToastService,
		private clipboardService: ClipboardService,
		private locale: CxLocaleService
	) { }

	copyDocumentsLink(event, documentIds: number | number[],
			projectIdentifier: IProjectSelection,
			workspaceProject: WorkspaceProject,
			runAs?: string,
			encodedLink?: string): Promise<void> {
		let documentIDsArray: number[] = [].concat(documentIds); // force to array
		let target = event.delegateTarget || event.currentTarget;
		let project = projectIdentifier.contentProviderId ? projectIdentifier : null;
		return PromiseUtils.wrap(this.documentSharingApiService.generateDocumentsLink(
				documentIDsArray, project, workspaceProject, runAs, encodedLink)
			.then((response) => {
				let link = response.data.documentUrl;
				this.clipboardService.copyToClipboard(link, target);
				this.toastService.addToast(
					this.locale.getString('preview.multiDocumentsLinkCopied', { count: documentIDsArray.length}),
					AlertLevel.CONFIRM);
			}));
	}
}

app.service('documentLinkCopyService', downgradeInjectable(DocumentLinkCopyService));
