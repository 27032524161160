import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';

@Component({
	selector: 'dark-mode-settings',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<section class="p-32 d-flex align-items-center">
	<div class="mr-16">
		<label class="control-label mb-0">{{'appearance.allowToEnableDarkThemeLabel'|i18n}}</label>
		<cb-inline-help>
			<help-body [i18n]="'appearance.allowToEnableDarkThemeHelp'">
			</help-body>
		</cb-inline-help>
	</div>
	<cb-toggle
		[(ngModel)]="darkModeAvailable"
		(ngModelChange)="updateDarkMode(darkModeAvailable)"
		data-testid="dark-mode-switch"
	></cb-toggle>
</section>
`,
})
export class DarkModeSettingsComponent implements OnInit {
	darkModeAvailable: boolean;

	constructor(
		@Inject('security') private security: Security,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService
	) { }

	ngOnInit(): void {
		this.darkModeAvailable = this.security.getCurrentMasterAccount().darkModeAvailable;
	}

	updateDarkMode = (enabled: boolean): void => {
		this.masterAccountApiService.updateProperty('darkModeAvailable', enabled).then(() => {
			this.security.getCurrentMasterAccount().darkModeAvailable = this.darkModeAvailable;
		});
	};
}

app.directive('darkModeSettings', downgradeComponent({component: DarkModeSettingsComponent}));
