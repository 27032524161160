export class BubbleLegendProcessor {

	private static inAreaFilter(reportDefinition, point): boolean {
		return (point.x >= reportDefinition.options.zoom.xAxis.min && point.x <=
			reportDefinition.options
				.zoom.xAxis.max && point.y >= reportDefinition.options.zoom.yAxis.min &&
			point.y <=
			reportDefinition.options.zoom.yAxis.max);
	}

	static hideOuterLegends = (reportDefinition, visibleArea): void => {
		if (!visibleArea || !reportDefinition.chart.series) {
			return;
		}

		let filterFunction = (serie) => {
			for (let data of serie.data) {
				if (BubbleLegendProcessor.inAreaFilter(reportDefinition, data)) {
					return false;
				}
			}

			return true;
		};

		let seriesState = {}; // true/false = visible/hidden
		reportDefinition.chart.series.forEach((serie) => {
			seriesState[serie.options.id] = seriesState[serie.options.id] || !filterFunction(serie);
			// if any of related items visible, do not hide serie's "root" (element, which is shown as legend)
			seriesState[serie.options.linkedTo] = seriesState[serie.options.linkedTo] ||
				seriesState[serie.options.id];
		});

		let hideSeries = _.filter(reportDefinition.chart.series, (serie) => {
			// hide only "root" items, which are not linked from anyone.
			return !seriesState[serie.options.id] && serie.options.linkedTo ===
				undefined;
		});

		if (!hideSeries) {
			return;
		}
		hideSeries.forEach((serie) => serie.setVisible(false, false));
		reportDefinition.chart.redraw();
	};

	static resetHiddenLegends = (reportDefinition, excludeSeries) => {
		if (!excludeSeries) {
			reportDefinition.chart.series.forEach((serie) => {
				serie.setVisible(true, false);
			});
		}

		let resetHiddenFilterFunction = (serie) => {
			if (serie.visible) {
				return false;
			}
			let id = reportDefinition.utils.hasObjectColor
				? serie.options.category.id
				: serie.options.id;

			return excludeSeries.indexOf(id) === -1;
		};
		let showSeries = _.filter(reportDefinition.chart.series,
			resetHiddenFilterFunction);
		if (!showSeries) {
			return;
		}
		showSeries.forEach((serie) => {
			serie.setVisible(true, false);
		});
		reportDefinition.chart.redraw();
	};

	static hideSavedLegends = (reportDefinition): void => {
		if (reportDefinition.options.legends) {
			let hidden = reportDefinition.options.legends.hideLegends;
			reportDefinition.chart.series.forEach((serie) => {
				let id = reportDefinition.utils.hasObjectColor
					? serie.options.category.id
					: serie.options.id;
				if (hidden.indexOf(id) > -1) {
					serie.setVisible(false, false);
				}
			});
			reportDefinition.chart.redraw();
		}
	};
}
