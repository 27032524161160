import { Inject, Injectable } from '@angular/core';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { AttributeExpressionItem } from '@cxstudio/metrics/custom-math/attribute-expression-item.class';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { MathAggregation } from '../tokenizer/custom-math-tokenizer.service';
import { AttributeCalculationsOptionsService, CalculationOption } from '../attribute-calculations-options.service';


@Injectable({providedIn: 'root'})
export class AttributeAdapter implements CustomMathExpressionAdapter {

	constructor(
		private attributeCalculationsOptions: AttributeCalculationsOptionsService
	) {}

	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let attribute = token.value ? this.findAttributeByName(token.value, assets) : undefined;

		let aggregationType: TextTokenType;
		let attributeType: TextTokenType;
		if (attribute === undefined && this.isCountDistinctAggregation(token)) {
			aggregationType = TextTokenType.GENERIC_AGGREGATION;
			attributeType = TextTokenType.GENERIC_ATTRIBUTE;
		} else {
			let text: boolean = attribute?.type === AttributeType.TEXT
				&& this.attributeCalculationsOptions.isSupportTextAttributes(token.subtype);

			aggregationType = text ? TextTokenType.TEXT_AGGREGATION : TextTokenType.NUMERIC_AGGREGATION;
			attributeType = text ? TextTokenType.TEXT_ATTRIBUTE : TextTokenType.NUMERIC_ATTRIBUTE;
		}

		let textToken: TextToken = CustomMathAdapterUtils.getItemNameToken(token, attributeType, !attribute);
		if (!attribute && textToken) {
			textToken.errors = [CustomMathErrorType.MISSED_REFERENCE];
		}
		if (aggregationType === TextTokenType.NUMERIC_AGGREGATION && attribute?.type === AttributeType.TEXT) {
			textToken.errors = [CustomMathErrorType.ATTRIBUTE_MISMATCH];
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: CustomMathAdapterUtils.getSegmentTextTokens(token.subtype, aggregationType, textToken)
		};

		let aggregation: CalculationOption = this.attributeCalculationsOptions.findKeyword(token.subtype);
		if (attribute) {
			let displayName = `${aggregation.operator}[${attribute.displayName}]`;
			segment.expression = new AttributeExpressionItem(attribute.name, aggregation.operator, displayName);
		} else {
			segment.expression = new AttributeExpressionItem(undefined, aggregation.operator, token.value);
		}

		return segment;
	}

	private isCountDistinctAggregation(token): boolean {
		return token.subtype === MathAggregation.COUNT_DISTINCT;
	}

	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let attributeExpression = expression as AttributeExpressionItem;
		let aggregation = this.attributeCalculationsOptions.findType(attributeExpression.operator);
		let attribute = this.findAttributeByIdentifier(attributeExpression.name, assets);
		let displayName = CustomMathAdapterUtils.getCalculationName(attributeExpression.displayName);
		if (attribute) {
			displayName = attribute.displayName;
		}
		displayName = CustomMathAdapterUtils.escapeSpecialChars(displayName);
		return `${aggregation.keyword}[${displayName}]`;
	}

	private findAttributeByName = (value: string, assets: CustomMathAssets): IReportAttribute => {
		return _.find(assets.attributes, (attribute) => attribute.displayName === value);
	};

	private findAttributeByIdentifier = (name: string, assets: CustomMathAssets): IReportAttribute => {
		return _.find(assets.attributes, (attribute) => attribute.name === name);
	};
}
