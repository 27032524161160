import { Security } from '@cxstudio/auth/security-service';
import * as _ from 'underscore';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { WidgetTypeFilters } from '@cxstudio/home/widget-type-filters.class';
import { WidgetTemplate } from '@cxstudio/dashboards/widgets/widget-template.class';

export class DashboardWidgetLimiter {

	constructor(
		private security: Security,
	) {}

	getReportWidgetsLimit(): number {
		return this.security.getCurrentMasterAccount().maxReportingWidgets;
	}

	getContentWidgetsLimit(): number {
		return this.security.getCurrentMasterAccount().maxNonReportingWidgets;
	}

	canCreateWidget(widget: Widget, widgets: Widget[]): boolean {
		if (WidgetTypeFilters.isReportWidget(widget))
			return !this.isReportWidgetsLimitReached(widgets);
		if (WidgetTypeFilters.isContentWidget(widget))
			return !this.isContentWidgetsLimitReached(widgets);
		if (WidgetTypeFilters.isPageBreak(widget))
			return !this.hasPageBreak(widgets);
		return true;
	}

	canCreateWidgets(newWidgets: Widget[], currentWidgets: Widget[]): boolean {
		let anyFailure = !!_.find(newWidgets, widget => !this.canCreateWidget(widget, currentWidgets));
		return !anyFailure;
	}

	private isReportWidgetsLimitReached(widgets: Widget[]): boolean {
		return this.getReportWidgetsCount(widgets) >= this.getReportWidgetsLimit();
	}

	getReportWidgetsCount(widgets: Widget[]): number {
		return _.filter(widgets, WidgetTypeFilters.isReportWidget).length;
	}

	private isContentWidgetsLimitReached(widgets: Widget[]): boolean {
		return _.filter(widgets, WidgetTypeFilters.isContentWidget).length >= this.getContentWidgetsLimit();
	}

	private hasPageBreak = (widgets: Widget[]) => {
		return !!_.find(widgets, WidgetTypeFilters.isPageBreak);
	};

	isShowWidgetTemplate = (widget: Widget | WidgetTemplate, widgets: Widget[]) => {
		if (WidgetTypeFilters.isPageBreak(widget)) {
			return !this.hasPageBreak(widgets);
		}
		return true;
	};

	private getWidgetCounts(widgets: Widget[]): {report: number; content: number; pageBreak: number} {
		let result = {
			report: 0,
			content: 0,
			pageBreak: 0
		};
		_.each(widgets, widget => {
			if (WidgetTypeFilters.isReportWidget(widget))
				result.report++;
			if (WidgetTypeFilters.isContentWidget(widget))
				result.content++;
			if (WidgetTypeFilters.isPageBreak(widget))
				result.pageBreak++;
		});
		return result;
	}

	hasCapacityForWidgets(selectedWidgets: Widget[], allWidgets: Widget[]): boolean {
		let currentCounts = this.getWidgetCounts(allWidgets);
		let requiredCounts = this.getWidgetCounts(selectedWidgets);

		let totalCounts = {
			report: currentCounts.report + requiredCounts.report,
			content: currentCounts.content + requiredCounts.content,
			pageBreak: currentCounts.pageBreak + requiredCounts.pageBreak
		};

		return totalCounts.report <= this.getReportWidgetsLimit()
			&& totalCounts.content <= this.getContentWidgetsLimit()
			&& totalCounts.pageBreak <= 1;
	}


}

app.service('dashboardWidgetLimiter', DashboardWidgetLimiter);
