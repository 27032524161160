import { Component, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'indirect-share-table',
	templateUrl: './indirect-share-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class IndirectShareTableComponent {

	@Input() sharedEntities;

	constructor(
		private locale: CxLocaleService,
	) { }

	getShareText = (item) => {
		if (item && item.numBooks > 1) {
			return this.locale.getString('dashboard.numBooks', {numBooks: item.numBooks});
		} else {
			if (item && item.bookList) {
				return item.bookList[0].name.ellipsis(40);
			}
		}
	};

	showPopover(item, popover: NgbPopover): void {
		popover.open({item});
	}

	hidePopover(popover: NgbPopover): void {
		popover.close();
	}
}

app.directive('indirectShareTable', downgradeComponent({component: IndirectShareTableComponent}));
