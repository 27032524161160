import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';

type WidgetTypeNames = {
	[key in WidgetType]: number;
};

export class DashboardUtils {
	private static reportOrder: Partial<WidgetTypeNames> = {
		cb_an_bar: 1,
		cb_an_line: 2,
		cb_an_table: 3,
		cb_an_cloud: 4,
		cb_an_pie: 6,
		cb_an_scatter: 7,
		cb_an_heatmap: 8,

		cb_an_preview: 10,
		cb_an_metric: 11,
		cb_an_map: 12,
		cb_an_network: 13,
		cb_object_viewer: 14,
		cb_an_model_viewer: 15,
		cb_an_scorecard: 20,

		cb_an_selector: 200,
		text: 210,
		label: 211,
		button: 220,
		image: 230,
		video: 240,

		qualtrics: 300,
	};

	static orderReports = (report: any): number => {
		return DashboardUtils.reportOrder[report.name];
	};

	static isDashboardPreventedFromSharing = (item: Dashboard): boolean => {
		return item && item.properties
			&& (isTrue(item.properties.preventDashboardEditorsShare)
			|| item.properties.preventDashboardEditorsShare === undefined);
	};

	static isStudioAdminDashboard = (item: Dashboard): boolean => {
		return InternalProjectTypes.isStudioAdminProject(item?.properties?.project);
	};

	static sortWidgets(widgets: Widget[]): void {
		widgets.sort((a, b) => {
			return a.posY === b.posY ? (a.posX - b.posX) : (a.posY - b.posY);
		});
	}

	static getDashboardFiltersCount(dashboard: Dashboard): number {
		if (!dashboard || !dashboard.appliedFiltersArray) {
			return 0;
		}
		return DashboardUtils.getNonHierarchyFiltersCount(dashboard.appliedFiltersArray);
	}

	static getNonHierarchyFiltersCount(dashboardFilters: DashboardFilter[]): number {
		if (!dashboardFilters) {
			return 0;
		}
		return dashboardFilters.filter(filter => filter && !filter.isOrgHierarchy).length;
	}
}
