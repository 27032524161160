import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { CommonSlickTableDefinitionService } from '@cxstudio/reports/visualizations/definitions/slick/common-slick-table-definition.service';
import { ReportUtils, ITableReportScope } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';

interface ICbDefinitionTableScope extends ITableReportScope {
	$$postDigest;

	options: VisualProperties;
	dataObject;
	demo: boolean;
	trigger: boolean;
	utils: WidgetUtils;
	exportingImage: boolean;
	columns_all;
	data;
}

app.directive('cbDefinitionTable', (
	tableService,
	commonSlickTableDefinition: CommonSlickTableDefinitionService,
	reportUtils: ReportUtils,
	elementUtils: ElementUtils,
	gridUtils: GridUtilsService,
	locale: ILocale,
	$rootScope: ng.IRootScopeService,
	$compile: ng.ICompileService
) => {
	return {
		restrict: 'A',
		template: '<div></div>',
		replace: true,
		scope: {
			options: '<',
			dataObject: '<data',
			demo: '<',
			trigger: '=',
			utils: '<',
			exportingImage: '<'
		},
		link: ((scope: ICbDefinitionTableScope, element: ng.IAugmentedJQuery) => {
			if (!tableService.processIfNoData(element, scope.dataObject, undefined, 'definition_table', scope.utils)) {
				return;
			}

			let tableOptions = commonSlickTableDefinition.getTableOptions();
			scope.columns_all = commonSlickTableDefinition.getColumns(scope);
			let data = scope.data = scope.dataObject.data;
			let dataView = new Slick.Data.DataView();

			dataView.getItemMetadata = metadata(dataView.getItemMetadata);

			function metadata(originalFn): (row) => any {
				return (row) => {
					let meta = originalFn(row) || {};
					meta.cssClasses = meta.cssClasses || '';
					meta.cssClasses += ' ignore-odd';
					let item = dataView.getItem(row);
					if (!item.leaf) {
						meta.cssClasses += ' non-score';
					}

					return meta;
				};
			}

			let buildTableElement = () => {
				return reportUtils.buildReportElement({
					left: '0px',
					right: '0px',
					bottom: '0px',
					top: '0px'
				});
			};

			let tableElement = buildTableElement();
			elementUtils.composeWithDefaultFooter(element, {
				body: tableElement
			});

			let initialColumns;

			function createTable(extCols?): void {
				if (!_.isUndefined(extCols)) {
					scope.grid = new Slick.Grid(tableElement, dataView, tableService.wrapColumns(
						extCols), tableOptions);
				} else {
					let columns = scope.columns_all;
					scope.grid = new Slick.Grid(tableElement, dataView, tableService.wrapColumns(
						columns), tableOptions);
					initialColumns = angular.copy(columns);
				}
				scope.grid.setOptions({autoHeight: scope.exportingImage});
				scope.$on('$destroy', scope.grid.destroy);

				scope.grid.registerPlugin(new Slick.AutoTooltips({
					enableForHeaderCells: false
				}));

				let tooltips = {
					present: locale.getString('scorecards.widgetDesiredResultTooltip'),
					weight: locale.getString('scorecards.widgetWeightTooltip'),
					weightN: locale.getString('scorecards.widgetWeightNTooltip')
				};

				scope.grid.onHeaderCellRendered.subscribe((e, args: {node; column}) => {
					let tooltip = tooltips[args.column.id];
					if (!scope.options.showTooltips || !tooltip) return;
					let newScope = $rootScope.$new();
					let html = `
						<cb-inline-help container="body">
							<help-body>${tooltip}</help-body>
						</cb-inline-help>`;
					let help = $compile(html)(newScope);
					$(args.node).append(help);
				});

				tableService.registerCommonHandlers(dataView, scope.options, scope.grid,
					scope.utils.widgetType, element);
				tableService.registerColumnsResizeHandler(scope.options, scope.grid);
				tableService.registerGridSorter(dataView, scope.options, scope.grid,
					scope.utils.widgetType, element);

				setTableColumns(initialColumns);

				if (!scope.demo) {
					scope.grid.onClick.subscribe((e, args) => {
						e.preventDefault();
						let cell = scope.grid.getCellFromEvent(e);

						if (gridUtils.isElementClick(e, 'toggle')) {
							let row = dataView.getItem(args.row);
							row._collapsed = !row._collapsed;
							dataView.updateItem(row.id, row);
						}
					});
				}

				if (!_.isUndefined(scope.options.sortBy)) {
					let dir = scope.options.direction ? scope.options.direction === 'ASC' :
						false;
					scope.grid.setSortColumn(scope.options.sortBy, dir);
					tableService.gridSingleSorter(scope.options.sortBy, dir, scope.grid, data);
				}

				function setTableColumns(columns): void {
					scope.grid.setColumns(columns);
					scope.grid.invalidate();
					scope.grid.render();
				}

				function collapsedFilter(item): boolean {
					if (item.parent) {
						let parent = item.parent;
						while (parent) {
							if (parent._collapsed) {
								return false;
							}
							parent = parent.parent;
						}
					}
					return true;
				}

				let flatTree = gridUtils.processItemsTree(data, true, 'nodeName');
				dataView.setItems(flatTree);
				dataView.setFilter(collapsedFilter);
				scope.grid.invalidate();

				scope.grid.render(() => {
					scope.$$postDigest(() => {
						reportUtils.handleWidgetRenderedEvent(scope.utils.widgetId, scope.utils.widgetType,
							scope.utils.containerId);
					});
				});
			}

			createTable();

			reportUtils.initResizeHandler(scope, element, reportUtils.tableResizeHandler(scope, element));

			scope.$watch('trigger', (newValue, oldValue) => {
				if (newValue === oldValue) return;
				if (_.isUndefined(scope.dataObject.data)
					|| scope.dataObject.data.length === 0) {
					return;
				}
				scope.columns_all = commonSlickTableDefinition.getColumns(scope);
				dataView.setItems(scope.dataObject.data);
				scope.grid.setColumns(scope.columns_all);
				scope.grid.invalidate();
				scope.grid.render();
			});
		}) as any
	};
});
