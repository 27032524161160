import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import {
	GridFormatter,
	IRowFormatter
} from '@cxstudio/grids/grid-formatter-service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ColorPalette } from '@app/modules/account-administration/appearance/color-palettes/color-palette';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { CxLocaleService } from '@app/core';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';

@Injectable({
	providedIn: 'root'
})
export default class ColorPaletteGridDefinition implements IGridDefinition {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter
	) {}

	init = (): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [ {
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: true,
			minWidth: 80,
			width: 80,
			searchable: false,
			formatter: this.toggleFormatter,
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('appearance.paletteName'),
			field: 'displayName',
			sortable: true,
			minWidth: 120,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.PALETTES),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'type',
			name: this.locale.getString('common.type'),
			field: 'system',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 150,
			formatter: this.typeFormatter
		}, {
			id: 'defaultPalette',
			name: this.locale.getString('appearance.accountDefault'),
			field: 'defaultPalette',
			sortable: true,
			optional: true,
			minWidth: 60,
			width: 150,
			formatter: this.accountDefaultFormatter
		}, {
			id: 'preview',
			name: this.locale.getString('appearance.palettePreview'),
			field: 'colors',
			sortable: false,
			minWidth: 250,
			width: 400,
			cssClass: 'cell-palette-preview',
			formatter: this.previewFormatter
		}, {
			id: 'modifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'modifiedDate',
			sortable: true,
			optional: true,
			minWidth: 100,
			width: 280,
			cssClass: 'cell-date',
			searchable: false,
			formatter: this.gridFormatterService.DateFormatter
		}];

		return Promise.resolve(columns);
	};

	private typeFormatter: IRowFormatter = (_row, _cell, isSystemPalette: boolean): string => {
		return isSystemPalette ? this.locale.getString('common.system') : this.locale.getString('common.custom');
	};

	private toggleFormatter: IRowFormatter = (_row, _cell, isEnabled: boolean, _columnDef, dataContext: ColorPalette) => {
		const disabled: boolean = dataContext.defaultPalette;
		const checked: boolean = isEnabled;
		const getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.displayName);

		return getSwitch(checked, disabled, this.locale.getString('appearance.disableDefaultPalette'));
	};

	private previewFormatter: IRowFormatter = (_row, _cell, colors: string[]): string => {
		const colorItems: string[] = colors.map((color: string): string => {
			const sanitizedColor = `background-color: ${ColorUtilsHelper.sanitizeCSSColor(color)}`;
			return `<div class="color-swatch" style="${sanitizedColor}"></div>`;
		});

		return `<div class="color-swatch-bar clearfix">${colorItems.join('')}</div>`;
	};

	private accountDefaultFormatter: IRowFormatter = (
		_row,
		_cell,
		isDefaultPalette: boolean,
		_columnDef,
		dataContext: ColorPalette
	): string => {
		const label: string = this.locale.getString('appearance.useAsDefault');
		let tooltip = '';

		if (dataContext.designerPalette) {
			tooltip = this.locale.getString('appearance.defaultDesignerPalette');

			return `<a disabled title="${tooltip}">${label}</a>`;
		}

		if (isDefaultPalette) {
			return this.locale.getString('appearance.defaultPalette');
		}

		if (dataContext.enabled) {
			return `<a class="btn-link action-color make-default">${label}</a>`;
		}

		tooltip = this.locale.getString('appearance.disableDefaultPaletteCell');

		return `<a disabled title="${tooltip}">${label}</a>`;
	};
}

app.service('colorPaletteGridDefinition', downgradeInjectable(ColorPaletteGridDefinition));
