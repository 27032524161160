import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { CalendarSettingsEntry, CalendarType, WeekIdentifier, OverlappingYears } from '../calendar-settings.entity';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { CalendarFormatContextMenu } from './calendar-format-context-menu.service';
import { CalendarFormatListActions } from './calendar-format-list-actions.service';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { CalendarFormatApi } from './calendar-format-api-service';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import * as cloneDeep from 'lodash.clonedeep';
@Component({
	selector: 'calendar-format',
	templateUrl: './calendar-format.component.html',
	providers: [CalendarFormatContextMenu, CalendarFormatListActions],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalendarFormatComponent implements OnInit {

	loading: Promise<any>;
	calendarSettingsArray: CalendarSettingsEntry[] = [];
	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.CALENDAR_FORMAT;
	contextMenuUtils: CalendarFormatContextMenu;
	actionsService: CalendarFormatListActions;
	projects?: Project[] = [];
	workspaceProject: WorkspaceProject;
	changedItems: CalendarSettingsEntry[];

	constructor(
		private locale: CxLocaleService,
		private calendarFormatContextMenu: CalendarFormatContextMenu,
		private calendarFormatListActions: CalendarFormatListActions,
		private gridUtils: GridUtilsService,
		@Inject('contextMenuTree') private contextMenuTree: any,
		private calendarFormatApi: CalendarFormatApi,
		private ref: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		this.gridOptions = {
			onClick: this.onClick
		};
		this.contextMenuUtils = this.calendarFormatContextMenu;
		this.actionsService = this.calendarFormatListActions;
		this.actionsService.onChange.subscribe(this.loadCalendarEntries);
	}

	private onClick = (event, object: CalendarSettingsEntry): void => {
		if (this.gridUtils.isNameClick(event)) {
			this.actionsService.edit(object);
		} else if (this.gridUtils.isMenuClick(event)) {
			this.contextMenuTree.showObjectListMenu(event, object, this.contextMenuUtils.getContextMenu(object), 'calendarformat', 360);
		} else if (this.gridUtils.isToggleClick(event)) {
			this.actionsService.toggle(object);
		}
	};

	onProjectsLoading(loadingPromise: Promise<any>): void {
		this.loading = loadingPromise;
	}

	projectsLoaded = (projects: Project[]) => {
		this.projects = projects;
		this.loadCalendarEntries();
	};

	private loadCalendarEntries = () => {
		this.processAllProjects();
		this.loadExistingCalendarSettings();
	};

	private processAllProjects = () => {
		this.calendarSettingsArray = this.projects
		.filter(project => !_.isUndefined(project.id))
		.map((project) => {
			return {
				id: project.id, // for itemgrid unique id
				projectId: project.id, // for comparing
				projectName: project.name,
				enabled: false,
				workspaceProject: this.workspaceProject,
				name: project.name,
				type: CalendarType.USA,
				weekIdentifier: WeekIdentifier.WEEK_OF,
				overlappingYears: OverlappingYears.FOLLOWING_YEAR,
				modifiedDate: null
			} as CalendarSettingsEntry;
		});
	};

	onProjectChange = (workspaceProject: WorkspaceProjectData): void => {
		this.workspaceProject = workspaceProject;
		this.loadCalendarEntries();
	};

	loadExistingCalendarSettings = (): void => {
		this.loading = this.calendarFormatApi.getCalendarFormatForProjects(this.workspaceProject.workspaceId)
			.then((calendarFormatSettings: CalendarSettingsEntry[]) => {
				this.calendarSettingsArray = this.processSettingsAndMerge(calendarFormatSettings);
				this.changedItems = calendarFormatSettings;
				this.ref.detectChanges();
			});
	};

	private processSettingsAndMerge = (calendarFormatSettings: CalendarSettingsEntry[] ) => {
		let calendarSettingsArrayMerged = cloneDeep(this.calendarSettingsArray);

		calendarSettingsArrayMerged =  calendarSettingsArrayMerged.map(defProject =>
			calendarFormatSettings.find(custProject => {
				if (custProject.projectId === defProject.projectId) {
					custProject['id'] = custProject.projectId; // add to properly replace id
					return custProject;
				}
			}) || defProject);

			// filter
			if (!WorkspaceProjectUtils.isProjectSelected(this.workspaceProject)) {
				return calendarSettingsArrayMerged;
			} else {
				return calendarSettingsArrayMerged
					.filter(entity => {
						return entity.projectId === this.workspaceProject.projectId;
					});
			}
	};
}

app.directive('calendarFormat', downgradeComponent({component: CalendarFormatComponent}));
