import * as _ from 'underscore';
import { Security } from '@cxstudio/auth/security-service';

export class ShareModificationUtilities implements ng.IComponentController {

	constructor(private security: Security) {
	}

	$onInit = () => {
		// required for typescript validation
	};

	isInnerUser = (entity): boolean => entity.type === 'inner-user';

	canChangePermission = (entity, itemOwner: string, isNewAdd: boolean): boolean => {
		if (entity.permission === 'OWN') return false;	// can never modify owner
		if (this.currentUserIsItemOwner(itemOwner)) return true;	// owner can modify anyone else
		if (isNewAdd) return true; // new additions can always be modified
		if (!itemOwner) return true; // no owner - anyone can modify
		// current user cannot modify their own access
		let isCurrentUsersEntity = (entity.type === 'user') && (!this.isNotCurrentUser(entity));

		return !isCurrentUsersEntity;
	};

	// may be needed if we want to prevent group modifications
	isCurrentUserInGroup = (entity): boolean => {
		if (entity.type !== 'group' || !entity._children) return false;

		return entity._children.length && _.find(entity._children, {displayName: this.security.getEmail()});
	};

	currentUserIsItemOwner = (itemOwner: string): boolean => this.security.getEmail() === itemOwner;

	isNotCurrentUser = (item): boolean => item.displayName !== this.security.getEmail();
}

app.service('shareModificationUtils', ShareModificationUtilities);
