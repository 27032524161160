import * as _ from 'underscore';
import { INode, SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { EventEmitterService } from '@cxstudio/services/event/event-emitter.service';
import EventType from '@cxstudio/services/event/event-type.enum';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { Input, EventEmitter, Output, OnInit, Component, Inject, OnChanges, SimpleChanges,
	ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList, TemplateRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { TreeNodeComponent } from '@app/shared/components/forms/tree/tree-node.component';
import { Observable, Subject } from 'rxjs';
import { TreeFocusMoveDirection, FocusTarget, ITreeFocusMoveParams, IFocusMoveParams, FocusMoveDirection, TreeFocusUtils } from '@app/shared/components/forms/tree/focus';
import { RandomUtils } from '@app/util/random-utils.class';


export interface INodeParams {
	node: TreeNode;
	$event?: UIEvent;
}

export interface SearchModel {
	hierarchySearch: string;
}

@Component({
	selector: 'searchable-tree',
	templateUrl: './searchable-tree.component.html'
})
export class SearchableTreeComponent implements OnInit, AfterViewInit, OnChanges {

	readonly ATTRIBUTE_STATS_TIMEOUT = 1000;

	@Input() selectedItem: TreeNode;
	@Input() itemTemplate: TemplateRef<any>;
	@Input() hierarchyList: TreeNode[];
	@Input() displayProperty: string;
	@Input() forcedOrder: boolean;
	@Input() placeholder: string;
	@Input('ngDisabled') componentDisabled: boolean;
	@Input() hideSearch: boolean;
	@Input() autoFocus: boolean;
	@Input() searchLabel: string;

	@Input() showNotRecommendedPrompt: boolean;
	@Input() externalModel: {hierarchySearch: string}; //?
	@Input() noResultsText: string;
	@Input() limitedWidth: boolean;
	@Input() disabledItems: TreeNode[];
	@Input() notRecommendedItems: {
		models: any;
		attributes: any;
	};
	@Input() folderClickIgnore: boolean;
	@Input() hierarchyName?: string;

	@Input() isChildLoaded: (node: INode) => boolean;
	@Input() isVisibleOverrided: (node: INode) => boolean;
	@Input() customValidation: (node: TreeNode) => boolean;

	@Input() showNodeCheckbox: (node: TreeNode) => boolean;
	@Input() nodeIsChecked: (node: TreeNode) => boolean;
	@Input() nodeIsMarked: (node: TreeNode) => boolean;
	@Input() getNodeTooltip: (node: TreeNode) => string;
	@Input() nodeIsHighlighted: (node: TreeNode) => boolean;
	@Input() nodeCheckboxDisabled: (node: TreeNode) => boolean;
	@Input() nodeIndeterminate: (node: TreeNode) => boolean;
	@Input() optionClassFormatter: (item) => string;

	@Output() onNodeClick = new EventEmitter<INodeParams>();
	@Output() onFolderExpand = new EventEmitter<INodeParams>();
	@Output() onHierarchyChange = new EventEmitter<TreeNode[]>();

	@Input() setFocus: Observable<FocusTarget>;
	@Output() onFocusMove = new EventEmitter<IFocusMoveParams>();
	setChildFocusSubject: Subject<FocusTarget> = new Subject<FocusTarget>();

	//scrollTimeout: number;
	keyupTimeout: number;
	search: SearchModel;

	showCheckboxes: boolean;
	styleId: string;

	private nodeFocusSubjects: {[nodeId: number]: Subject<FocusTarget>} = {};

	@ViewChild('searchInput', {static: false}) searchInput: ElementRef;
	@ViewChildren(TreeNodeComponent) treeNodes: QueryList<TreeNodeComponent>;

	constructor(
		private locale: CxLocaleService,
		@Inject('eventEmitterService') private eventEmitterService: EventEmitterService
	) {}

	ngOnInit(): void {
		//this.scrollTimeout = null;
		this.keyupTimeout = null;

		if (this.showNotRecommendedPrompt === undefined) {
			this.showNotRecommendedPrompt = true;
		}

		// if externalModel is provided, use that value for searching instead...
		if (this.externalModel) {
			this.search = this.externalModel;
		} else {
			this.search = {hierarchySearch: ''};
		}

		this.showCheckboxes = this.showNodeCheckbox
			&& !_.isUndefined(this.nodeIsChecked)
			&& !_.isUndefined(this.onNodeClick);

		this.isChildLoaded = this.isChildLoaded ? this.isChildLoaded : () => true;
		this.nodeIndeterminate = this.nodeIndeterminate ? this.nodeIndeterminate : () => false;
		this.nodeIsMarked = this.nodeIsMarked ? this.nodeIsMarked : () => false;
		this.styleId = `s-${RandomUtils.randomString()}`;

		// for table and feedback
		//this.$element.find('div>.searchable-hierarchy-list').on('scroll', this.onScroll);
		// for other widgets
		//this.$element.find('div>.searchable-hierarchy-list>ul').on('scroll', this.onScroll);
	}

	ngAfterViewInit(): void {
		if (this.autoFocus) {
			setTimeout(() => { this.searchInput.nativeElement.focus(); }, 0);
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.search && changes.search.currentValue !== changes.search.previousValue) {
			this.searchChangeHandler();
		}
	}

	searchChangeHandler = (): void => {
		this.searchUpdated();
		this.updateAttributeStats();
	};

	searchUpdated = (): void => {
		if (this.search.hierarchySearch.trim().length < 1) {
			this.hierarchyList.forEach(node => SearchableHierarchyUtils.collapseFolder(node));
		} else {
			this.hierarchyList.forEach((node) => {
				if (this.isChildLoaded(node) && !node._disabled) {
					SearchableHierarchyUtils.expandFolders(node);
				}
			});
		}
	};

	nodeClickHandler = ($event: INodeParams): void => {
		this.onNodeClick.emit($event);
	};

	folderExpandHandler = ($event: INodeParams): void => {
		this.onFolderExpand.emit($event);
		this.onHierarchyChange.emit(this.hierarchyList);
	};
	folderCollapseHandler = ($event: INodeParams): void => {
		this.onHierarchyChange.emit(this.hierarchyList);
	};

	getNoResultsText = (): string => {
		return this.noResultsText ? this.noResultsText : this.locale.getString('common.noMatchedItems');
	};

	private updateAttributeStats = (): void => {
		clearTimeout(this.keyupTimeout);
		this.keyupTimeout = setTimeout(() => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
		}, this.ATTRIBUTE_STATS_TIMEOUT) as any;
	};

	/*
	onScroll = () => {
		this.$timeout.cancel(this.scrollTimeout);
		this.scrollTimeout = this.$timeout(() => {
			this.eventEmitterService.emit(EventType.UPDATE_RECOMMEND_ITEMS);
		}, this.ATTRIBUTE_STATS_TIMEOUT);
	}
	*/

	isDisabled = (node: TreeNode): boolean => {
		let name = this.selectedItem && this.selectedItem.name;
		if (name !== node.name && this.disabledItems && this.disabledItems.length) {
			return !!_.findWhere(this.disabledItems, {name: node.name});
		}
		return false;
	};

	isSelected = (node: TreeNode): boolean => {
		if (node && this.selectedItem) {
			if (node.name && this.selectedItem.name && !node.id && !this.selectedItem.id) {
				return node.name === this.selectedItem.name && node.type === this.selectedItem.type;
			} else {
				return node === this.selectedItem;
			}
		}
		return false;
	};

	isNotRecommended = (node: TreeNode): boolean => {
		if (_.isEmpty(this.notRecommendedItems)) {
			return false;
		}

		if (!this.isModel(node) && !this.isAttribute(node)) {
			return false;
		}

		return this.isModel(node)
			? this.notRecommendedItems.models[node.name]
			: this.notRecommendedItems.attributes[node.name.toLowerCase()];
	};

	isModel = (item: any): boolean => {
		return !!item.model;
	};

	isAttribute = (item: TreeNode): boolean => {
		return item.metricType === AnalyticMetricType.ATTRIBUTE;
	};

	//checkbox related methods
	nodeCheckboxDisabledFn = (node: TreeNode): boolean => this.nodeCheckboxDisabled ? this.nodeCheckboxDisabled(node) : false;
	isNodeCheckboxDisabled = (node: TreeNode): boolean => {
		return this.showCheckboxes && this.nodeCheckboxDisabledFn(node);
	};

	hasSearch = (): boolean => {
		return !this.hideSearch && !this.externalModel;
	};

	moveFocusBack = ($event: UIEvent): void => {
		this.onFocusMove.emit({$event, direction: FocusMoveDirection.BACKWARD});
	};

	focusMoveHandler = ($event: ITreeFocusMoveParams): void => {
		let nodes = this.treeNodes.map(treeNode => treeNode.node);
		let currentIndex = nodes.indexOf($event.node);
		let offset = TreeFocusUtils.isDown($event.direction) ? 1 : -1;
		let targetIndex = currentIndex + offset;
		if (TreeFocusUtils.isTab($event.direction)) {
			if (targetIndex !== -1 && targetIndex !== this.treeNodes.length) {
				return;
			}
			if ($event.direction === TreeFocusMoveDirection.BACKWARD && this.hasSearch()) {
				return;
			}
			let direction = $event.direction === TreeFocusMoveDirection.FORWARD
				? FocusMoveDirection.FORWARD
				: FocusMoveDirection.BACKWARD;
			this.onFocusMove.emit({$event: $event.$event, direction});
			return;
		}
		let targetNode = this.treeNodes.toArray()[currentIndex + offset];
		if (targetNode) {
			let focusTarget = TreeFocusUtils.isDown($event.direction) ? FocusTarget.FIRST : FocusTarget.LAST;
			this.nodeFocusSubjects[targetNode.node.id].next(focusTarget);
		}
	};

	getSetNodeFocusSubject = (node: TreeNode): Subject<FocusTarget> => {
		if (!this.nodeFocusSubjects[node.id]) {
			this.nodeFocusSubjects[node.id] = new Subject<FocusTarget>();
		}
		return this.nodeFocusSubjects[node.id];
	};
}

app.directive('searchableTree', downgradeComponent({component: SearchableTreeComponent}));
