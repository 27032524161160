import { IRequestShortcutConfig } from 'angular';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DocumentMetadata } from '@cxstudio/reports/document-explorer/document-metadata';
import { DocumentLink } from '@cxstudio/reports/entities/document-link';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

export interface DecodedLink {
	link: DocumentLink;
	encodedLink: string;
}

export class DocumentSharingApiService {

	constructor(
		private $http: ng.IHttpService
	) {
	}

	activateDocumentLink = (encodedDocumentLink: string): ng.IHttpPromise<any> => {
		return this.$http.put('rest/document_sharing/activate', null, {
			params: {
				link: encodedDocumentLink
			}
		});
	};

	verifyDocumentLink = (encodedDocumentLink: string): ng.IHttpPromise<any> => {
		return this.$http.get('rest/document_sharing/verify', {
			params: {
				link: encodedDocumentLink
			},
			local: true,
			cache: true
		} as IRequestShortcutConfig);
	};

	generateDocumentsLink = (documentIds: number[],
			projectIdentifier: IProjectSelection,
			workspaceProject: WorkspaceProject,
			runAsUser: string,
			encodedDescriptor: string): ng.IHttpPromise<DocumentMetadata> => {
		return this.$http.post('rest/document_sharing/generate-link', {
			projectIdentifier,
			workspaceProject,
			documentIds,
			runAsUser,
			encodedDescriptor
		}, {
			local: true
		} as IRequestShortcutConfig);
	};

	decodeDocumentLinkById = (linkId: string): ng.IHttpPromise<DecodedLink> => {
		return this.$http.post('rest/document_sharing/decode', linkId, {
			local: true,
			cache: true
		} as IRequestShortcutConfig);
	};
}

app.service('documentSharingApiService', DocumentSharingApiService);
