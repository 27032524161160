import { Component, OnInit, OnDestroy, ChangeDetectionStrategy, Input, Inject, Output, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { DashboardEvent, DashboardExportEvent, URLEvent } from '@app/core/cx-event.enum';
import { CxLocationService } from '@app/core/cx-location.service';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DashboardRunHelperService } from '@app/modules/dashboard/dashboard-run-helper.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { PersonalizationState } from '@app/modules/hierarchy/hierarchy-tree-selector/personalization-state.class';
import { PromiseUtils } from '@app/util/promise-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { DashboardRunStatus } from '@cxstudio/dashboards/constants/dashboard-run-status';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardPersonalizationProvider } from '@cxstudio/dashboards/dashboard-filters/dashboard-personalization-provider.service';
import { IDashboardHistoryInstance } from '@cxstudio/dashboards/dashboard-history.factory';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { DashboardExportService } from '@cxstudio/reports/utils/export/dashboard-export-service.service';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { AmplitudeAnalyticsService } from '@app/modules/analytics/amplitude/amplitude-analytics.service';
import { AmplitudeEvent } from '@app/modules/analytics/amplitude/amplitude-event';
import { AmplitudeGroupsUtils } from '@app/modules/analytics/amplitude/amplitude-groups-utils';
import { WidgetVisibilityMonitorService } from '@app/modules/dashboard/widget-visibility-monitor.service';
import { AmplitudeEventUtils } from '@app/modules/analytics/amplitude/amplitude-event-utils';
import { FrontlineDashboardUtils } from '@app/modules/dashboard/services/utils/frontline-dashboard-utils';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';

@Component({
	selector: 'book-dashboard-view',
	templateUrl: './book-dashboard-view.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class BookDashboardViewComponent extends SelfCleaningComponent implements OnInit, OnDestroy {
	@Input() index: number;
	@Input() active: boolean;
	@Input() dashboard: Dashboard;
	@Input() dashboardHistory: IDashboardHistoryInstance;
	@Input() widgets: Widget[];

	@Output() editDashboard = new EventEmitter<Dashboard>();

	loading: Promise<any>;
	loaded: boolean;
	firstLoad: boolean = true; //default to ture, only be able set to false if beta feature is on

	bookId: number;
	containerId: string;
	personalization: PersonalizationState;
	pdfExport: boolean;
	headerShadow: boolean;

	constructor(
		private locale: CxLocaleService,
		@Inject('dashboardService') private dashboardService: DashboardService,
		@Inject('dashboardFiltersService') private dashboardFiltersService: DashboardFiltersService,
		@Inject('environmentService') private environmentService: EnvironmentService,
		@Inject('dashboardPersonalizationProvider') private dashboardPersonalizationProvider: DashboardPersonalizationProvider,
		@Inject('errorDialogService') private errorDialogService: ErrorDialogService,
		private location: CxLocationService,
		@Inject('dashboardExportService') private dashboardExportService: DashboardExportService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
		private dashboardRunHelperService: DashboardRunHelperService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		private cxDialogService: CxDialogService,
		private eventBus: GlobalEventBus,
		private widgetVisibilityMonitor: WidgetVisibilityMonitorService,
		private readonly toastService: ToastService
	) {
		super();
	 }

	ngOnInit(): void {
		this.bookId = +this.$routeParams.dashboardId;
		this.containerId = this.getContainerId();
		this.pdfExport = this.environmentService.isPdfExport();
		this.headerShadow = false;

		if (this.dashboardHistory.isInitialized()) {
			this.firstLoad = false;
		}
		this.personalization = this.dashboardPersonalizationProvider.getInstance(this.dashboard, this.containerId);
		if (this.firstLoad) {
			this.dashboardHistory.init(this.dashboard);
			this.loading = this.personalization.init()
				.then(this.postPersonalizationInit, this.postPersonalizationInit);
		} else {
			this.postPersonalizationInit(this.personalization.hierarchyLoadStatus);
		}

		this.widgetVisibilityMonitor.reset();
		AmplitudeAnalyticsService.trackEvent(AmplitudeEvent.BOOK_VIEW, AmplitudeGroupsUtils.bookGroup(this.bookId));
		AmplitudeAnalyticsService.trackEvent(
			AmplitudeEvent.DASHBOARD_VIEW,
			AmplitudeGroupsUtils.dashboardGroup(this.dashboard.id),
			AmplitudeEventUtils.dashboardViewData(this.dashboard, this.widgets, 'book')
		);

		if (
			FrontlineDashboardUtils.isFrontlineDashboard(this.dashboard)
			&& !FrontlineDashboardUtils.isSnapshotView(this.dashboard)
			&& !this.canEditDashboard()
		) {
			this.toastService.addToast(
				this.locale.getString('dashboard.dashboardNoPublishedReport'),
				AlertLevel.WARNING
			);
		}
	}

	ngOnDestroy(): void {
		this.widgetVisibilityMonitor.disconnect();
	}

	private postPersonalizationInit = (status: HierarchyLoadStatus) => {
		this.loaded = true;
		if (HierarchyLoadStatus.DEACTIVATED === status) {
			this.errorDialogService.error(this.locale.getString('widget.hierarchyDeactivated'));
		} else if (HierarchyLoadStatus.NO_ACCESS === status) {
			this.errorDialogService.error(this.locale.getString('widget.hierarchyNoAccess'));
		}
		this.dashboardHistory.setPersonalization(this.personalization);
		this.initEventHandlers();
	};

	private initEventHandlers(): void {
		this.addListener(this.eventBus.subscribe(DashboardEvent.CREATE_FROM_WIDGETS, (event, newDashboardName, newWidgets) => {
			if (!this.active)
				return;
			this.dashboard.appliedFiltersArray = this.dashboardHistory.getPersistentFilters();
			this.loading = PromiseUtils.wrap(this.dashboardService
				.createPredefinedDashboard(newWidgets, newDashboardName, this.dashboard))
				.then((data) => this.location.path('/home/' + data.id));
		}));

		this.addListener(this.eventBus.subscribe(DashboardExportEvent.PDF_EMAIL, () => {
			if (!this.active)
				return;
			this.dashboardExportService.exportPDF(this.dashboard, this.widgets, this.getDashboardViewOptions());
		}));

		this.addListener(this.eventBus.subscribe(DashboardExportEvent.PDF_DOWNLOAD, () => {
			if (!this.active)
				return;
			let exportConfig = this.dashboardExportService.getExportConfig(this.widgets);
			let hasIntersection = this.dashboardExportService.checkWidgetsForIntersection(exportConfig, this.widgets);

			let performExport = () => {
				this.loading = PromiseUtils.wrap(this.dashboardExportService.exportPDFDownload(
					this.dashboard, this.widgets, this.getDashboardViewOptions()));
			};
			if (!hasIntersection) {
				performExport();
				return;
			}

			let confirmation = this.cxDialogService.regularWithConfirm(this.locale.getString('dashboard.exportData'),
				this.locale.getString('dashboard.hasIntersectionWithPageBreak'),
				this.locale.getString('common.continue'), this.locale.getString('common.cancel'));

			return confirmation.result.then(performExport);
		}));

		this.addListener(this.eventBus.subscribe(DashboardExportEvent.XLS, () => {
			if (!this.active)
				return;
			let options = {
				allWidgets: true,
				hasChanges: this.dashboardHistory.isViewFilterChanged()
			};
			this.dashboardExportService.exportDashboard(this.dashboard.name,
				this.currentWidgets.getDashboardContainer(this.containerId),
				null, options);
		}));

		this.addListener(this.eventBus.subscribe(URLEvent.SCOPE_LOCATION_CHANGE_START, (event, nextAbsoluteUrl) => {
			if (this.dashboardRunHelperService.bookTabLeaveEvent(nextAbsoluteUrl, this.bookId, this.dashboard.id)) {
				this.dashboardRunHelperService.setRunStatus(this.dashboard?.id, DashboardRunStatus.INTERRUPTED_DEPARTURE);
			}
		}));
	}

	private getDashboardViewOptions(): any {
		return _.extend({
			personalization: this.personalization,
			dashboardHistory: this.dashboardHistory,
			bookId: this.bookId
		}, this.currentWidgets.getExportParams(this.containerId));
	}

	private getContainerId = (): string => {
		return `${this.dashboard.id}_${this.index}`;
	};

	canEditDashboard = () => {
		return this.dashboardService.canEditDashboard(this.dashboard);
	};

	showFilters = () => {
		return this.isDashboardFilterApplied();
	};

	showHeaderPanel = () => {
		return this.canEditDashboard() || this.showFilters();
	};

	isPdfExport = () => {
		return this.environmentService.isPdfExport();
	};

	showTopPanel = () => {
		return this.showHeaderPanel();
	};

	private isDashboardFilterApplied(): boolean {
		return this.dashboard &&
			this.dashboardFiltersService.canChangeFilters(
				this.dashboard.appliedFiltersArray,
				this.personalization.isHierarchySelectable()
			);
	}
}
