import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { MasterAccountProperties } from '@cxstudio/master-accounts/master-account-properties.class';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { SMTPServerSettings } from '@cxstudio/system-administration/master-accounts/integrations/smtp-server-settings';
import { MasterAccountProperty } from './master-account-property.enum';
import { LinkSharingSettings } from './settings-components/link-sharing-settings';
import { WidgetCacheSettings } from '@app/modules/account-administration/settings/widget-cache-settings/widget-cache-settings.component';
import { ScreeningSettings } from '@app/modules/account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { QualtricsEmbeddingSettings } from '@app/modules/account-administration/properties/qualtrics-embedding-panel/qualtrics-embedding-settings-panel.component';

export interface IAccountFeature {
	identifier: MasterAccountProperty;
	name: string;
}

export class MasterAccountPropertiesComponent implements ng.IComponentController {
	masterAccountFeatures: IAccountFeature[];

	requiresPasswordControls: boolean = this.security.requiresPasswordControlsForMA();
	loading: boolean;

	smtpSettings: SMTPServerSettings;
	membershipDomainStatus: boolean;
	membershipSelectedDomains: string[];
	externalRedirectDomainStatus: boolean;
	externalRedirectSelectedDomains: string[];
	defaultDashboardOptimization: boolean;
	linkSharingSettings: LinkSharingSettings;
	widgetCacheSettings: WidgetCacheSettings;
	screeningSettings: ScreeningSettings;
	widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings;
	qualtricsEmbeddingSettings: QualtricsEmbeddingSettings;

	qualtricsEmbeddingEnabled: boolean;

	constructor(
		private $scope: ISimpleScope,
		private security: Security,
		private locale: ILocale,
		private masterAccountApiService: MasterAccountApiService,
		private $timeout: ng.ITimeoutService,
		private betaFeaturesService: BetaFeaturesService,
	) {}

	$onInit = () => {
		if (!this.security.has('manage_settings'))
			return;

		this.masterAccountFeatures = [
			{identifier: MasterAccountProperty.DASHBOARD_DISCUSSIONS, name: this.locale.getString('mAccount.propDashDiscussion')},
			{identifier: MasterAccountProperty.QUALTRICS_SITE_INTERCEPT, name: this.locale.getString('mAccount.siteIntercept')},
			this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_TABLES)
				? null
				: {identifier: MasterAccountProperty.DASHBOARD_RATING, name: this.locale.getString('mAccount.dashboardRating')},
			{identifier: MasterAccountProperty.DASHBOARD_TEMPLATES, name: this.locale.getString('mAccount.dashboardTemplates')},
			{identifier: MasterAccountProperty.DEFAULT_DASHBOARD_OPTIMIZATION, name: this.locale.getString('mAccount.defaultDashboardOptimization')},
			{identifier: MasterAccountProperty.IDEAS_FORUM, name: this.locale.getString('mAccount.ideasForum')},
			{identifier: MasterAccountProperty.EXPORT_USERS_AND_GROUPS, name: this.locale.getString('mAccount.exportUsersAndGroups')},
		].filter(item => !!item);

		this.masterAccountApiService.getMasterAccountProperties().then(properties => {
			this.initCustomSMTP(properties);
			this.initMembership(properties);
			this.initExternalRedirect(properties);
			this.initDashboardOptimization(properties);
			this.initLinkSharing(properties);
			this.initWidgetCacheSettings(properties);
			this.initScreeningSettings(properties);
			this.initWidgetEditingPreviewsSettings(properties);
			this.initQualtricsEmbeddingSettings(properties);

			this.$timeout(() => this.$scope.$broadcast('ma:loadSettings'));
		});

		this.qualtricsEmbeddingEnabled = this.security.isQualtricsApiIntegrationEnabled()
			&& (this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_DASHBOARDS)
				|| this.betaFeaturesService.isFeatureEnabled(BetaFeature.QUALTRICS_WIDGETS));
	};

	private initCustomSMTP = (result: MasterAccountProperties) => {
		this.smtpSettings = result.smtpServerSettings;
	};

	private initMembership = (result: MasterAccountProperties) => {
		this.membershipDomainStatus = result.membershipDomainRestricted;
		this.membershipSelectedDomains = result.membershipDomainNames ? result.membershipDomainNames : [];
	};

	private initExternalRedirect = (result: MasterAccountProperties) => {
		this.externalRedirectDomainStatus = result.externalRedirectDomainRestricted;
		this.externalRedirectSelectedDomains = result.externalRedirectDomainNames ? result.externalRedirectDomainNames : [];
	};

	private initDashboardOptimization = (result: MasterAccountProperties) => {
		this.defaultDashboardOptimization
			= !_.isUndefined(result.defaultDashboardOptimization) ? result.defaultDashboardOptimization : false;
	};

	private initLinkSharing = (result: MasterAccountProperties) => {
		this.linkSharingSettings = result.linkSharingSettings;
	};

	private initWidgetCacheSettings = (result: MasterAccountProperties) => {
		this.widgetCacheSettings = result.widgetCacheSettings;
	};

	private initScreeningSettings = (result: MasterAccountProperties) => {
		this.screeningSettings = result.screeningSettings;
	};

	private initWidgetEditingPreviewsSettings = (result: MasterAccountProperties): void => {
		this.widgetEditingPreviewsSettings = result.widgetEditingPreviewsSettings;
	};

	private initQualtricsEmbeddingSettings = (result: MasterAccountProperties): void => {
		this.qualtricsEmbeddingSettings = result.qualtricsEmbeddingSettings;
	};

	loadingComplete = () => {
		this.loading = false;
	};
}

app.component('masterAccountProperties', {
	controller: MasterAccountPropertiesComponent,
	templateUrl: 'partials/master-accounts/master-account-properties.component.html'
});
