import { CxHttpService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';


@Injectable()
export class OAuthClientDetailsApiService {
	constructor(
		private cxHttp: CxHttpService
	) {}

	getInternalClientDetailsList = (): Promise<OAuthClientDetails[]> => {
		return this.cxHttp.get('rest/oauth/client-details/list/internal');
	};

	getStudioClientDetailsList = (): Promise<OAuthClientDetails[]> => {
		return this.cxHttp.get('rest/oauth/client-details/list/studio');
	};

	getDesignerClientDetailsList = (): Promise<OAuthClientDetails[]> => {
		return this.cxHttp.get('rest/oauth/client-details/list/designer');
	};

	getSuiteClientDetailsList = (): Promise<OAuthClientDetails[]> => {
		return this.cxHttp.get('rest/oauth/client-details/list/suite');
	};

	getLinkClientDetailsList = (): Promise<OAuthClientDetails[]> => {
		return this.cxHttp.get('rest/oauth/client-details/list/link');
	};

	createClientDetails = (clientDetails: OAuthClientDetails): Promise<void> => {
		return this.cxHttp.post('rest/oauth/client-details', clientDetails);
	};

	updateClientDetails = (clientId: string, clientDetails: OAuthClientDetails): Promise<void> => {
		return this.cxHttp.put(`rest/oauth/client-details/${clientId}`, clientDetails);
	};

	removeClientDetails = (clientId: string): Promise<void> => {
		return this.cxHttp.delete(`rest/oauth/client-details/${clientId}`);
	};

	disableClient = (clientId: string): Promise<void> => {
		return this.cxHttp.post(`rest/oauth/client-details/${clientId}/disable`);
	};

	enableClient = (clientId: string): Promise<void> => {
		return this.cxHttp.post(`rest/oauth/client-details/${clientId}/enable`);
	};
}

app.service('oauthClientDetailsApiService', downgradeInjectable(OAuthClientDetailsApiService));
