import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter, ChangeDetectorRef, Inject, OnDestroy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { Unit } from '@app/modules/units/unit';
import { UserWorkspacesService } from '@app/modules/units/workspace-project-selector/user-workspaces.service';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ProjectSelectorData } from '@app/shared/components/project-selector/project-selector-data';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { ProjectAccess } from '@cxstudio/user-administration/users/project-access/project-access-class';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { Security } from '@cxstudio/auth/security-service';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { WorkspaceProjectUtils } from '../workspace-project/workspace-project-utils.class';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { Subscription, Observable } from 'rxjs';
import { LabelPosition } from '@app/modules/units/workspace-project-selector/label-position';

export interface ProjectsReadyEvent {
	optionsAmount: OptionsAmount;
	projects: Project[];
}

@Component({
	selector: 'workspace-project-selector',
	templateUrl: './workspace-project-selector.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class WorkspaceProjectSelectorComponent implements OnInit, OnDestroy {
	private static readonly PROJECT_SEPARATOR = '----------------------------------';
	//inputs
	//help {workspace: string, project: string}
	@Input() value: WorkspaceProject;
	@Output() valueChange = new EventEmitter<WorkspaceProjectData>();
	@Output() workspaceChange = new EventEmitter<Unit>(); //temp to handle widget assets
	@Input() labelPosition: LabelPosition;
	@Input() customProjectPromptLabel: string;
	@Input() help: boolean;
	@Input() inline: boolean;
	@Input() allProjects: boolean;
	@Input() hideInternalProjects: boolean;
	@Input() internalProjectsOnly: boolean;
	@Input() externalErrors: boolean;
	@Input() withPermissions: boolean;
	@Input() clear: Observable<void>;
	@Input() labelBold = false;

	@Output() loading = new EventEmitter<Promise<void>>();
	@Output() ready = new EventEmitter<Promise<void>>();
	@Output() errorsChange = new EventEmitter<string[]>();
	@Output() projectsReady = new EventEmitter<ProjectsReadyEvent>();
	@Output() workspaceProjectsLoaded = new EventEmitter<AccountOrWorkspaceProject[]>();

	workspace: ProjectSelectorData<Unit>;
	project: ProjectSelectorData<Project>;
	errors: string[];
	private initialSelection: WorkspaceProject;

	private eventsSubscription$: Subscription;

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly userWorkspacesService: UserWorkspacesService,
		private readonly projectAccessService: ProjectAccessService,
		@Inject('security') private security: Security,
		private readonly sessionPreferences: SessionPreferencesService
	) { }

	ngOnInit(): void {
		this.errors = [];

		if (this.clear) {
			this.eventsSubscription$ = this.clear.subscribe(this.onClear);
		}

		if (_.isEmpty(this.value)) {
			this.initialSelection = this.resolveInitialSelection();

			if (this.initialSelection) {
				this.value = this.initialSelection;
			}
		}

		this.workspace = new ProjectSelectorData('id', this.value?.workspaceId);
		this.workspace.onChange.subscribe(this.onWorkspaceChange);
		this.workspace.invalidSelection.subscribe(this.onInvalidWorkspaceSelection);
		this.project = new ProjectSelectorData('id', this.value?.projectId, 'name');
		this.project.onChange.subscribe(this.onProjectChange);

		this.loading.emit(this.loadWorkspaces()
			.then(() => {
				this.ready.emit();
				this.initialSelection = undefined;
			}));
	}

	ngOnDestroy(): void {
		if (this.eventsSubscription$) {
			this.eventsSubscription$.unsubscribe();
		}
	}

	private resolveInitialSelection(): WorkspaceProject | undefined {
		let recentProject = this.sessionPreferences.getRecentWorkspaceProject(this.security.getMasterAccountId());
		if (recentProject) {
			return recentProject;
		}

		let favoriteProps = this.security.getFavoriteProperties() || {} as FavoriteProperties;
		if (this.hasFavoriteSelection(favoriteProps)) {
			return favoriteProps.workspaceProject;
		}
	}

	private hasFavoriteSelection(props: FavoriteProperties): boolean {
		return !isEmpty(props)
			&& WorkspaceProjectUtils.isWorkspaceSelected(props.workspaceProject?.workspaceId)
			&& props.workspaceProject.workspaceId !== -1;
	}

	getWrapperClasses(): string {
		if (this.inline) {
			return 'flex-direction-row';
		}
		return 'flex-direction-column';
	}

	getGroupClasses(): string[] {
		let classes = [];
		if (this.inline) {
			classes.push('mr-16');
		} else {
			classes.push('mb-16');
		}
		if (this.labelPosition === LabelPosition.LEFT) {
			classes.push('d-flex flex-direction-row');
		} else {
			classes.push('flex-direction-column');
		}
		return classes;
	}

	getLabelClasses(): string[] {
		let classes = this.labelBold ? ['font-semibold'] : [];
		if (this.help) {
			classes.push('help-label');
		}
		if (this.labelPosition === LabelPosition.LEFT) {
			classes.push('w-25-percent');
			classes.push('pr-8');
		}
		return classes;
	}

	getSelectorClasses(): string {
		if (this.labelPosition === LabelPosition.LEFT) {
			return 'w-75-percent';
		}
		return 'w-100-percent';
	}

	hasLabel(): boolean {
		return this.labelPosition !== LabelPosition.SELECT_PROMPT;
	}

	isLeft(): boolean {
		return this.labelPosition === LabelPosition.LEFT;
	}

	onChange(): void {
		const projectData = this.project.getSelectedObject();
		let emitData = {
			workspaceId: this.workspace.getSelection(),
			projectId: this.project.getSelection(),
			projectName: projectData?.name,
			accessLevel: projectData?.accessLevel
		};
		this.valueChange.emit(emitData);
		this.storeProjectSelection(emitData);
	}

	private storeProjectSelection = (workspaceData: WorkspaceProjectData): void => {
		this.sessionPreferences.setRecentProject(this.security.getMasterAccountId(), workspaceData);
	};

	loadWorkspaces(): Promise<void> {
		return this.userWorkspacesService.getUserWorkspaces()
			.then(units => {
				units.forEach(unit => unit.name = unit.name || '');
				this.workspace.setOptions(units);
				if (this.workspace.hasOptions()) {
					if (this.workspace.isSelected()) {
						if (this.initialSelection) {
							this.onWorkspacePreselected();
						}
						return this.loadProjects();
					}
				} else {
					this.project.clear();
				}
				this.ref.detectChanges();
			}, error => {
				this.setError(error);
				this.ref.detectChanges();
			});
	}

	onWorkspaceChange = (): void => {
		this.workspaceChange.emit(this.workspace.getSelectedObject());
		this.project.clear();
		this.onChange();
		this.resetErrors();
		if (this.workspace.isSelected()) {
			this.loading.emit(this.loadProjects());
		}
	};

	onWorkspacePreselected = () => {
		this.workspaceChange.emit(this.workspace.getSelectedObject());
		this.resetErrors();
	};

	onWorkspaceSelected = (): void => {
		this.workspaceChange.emit(this.workspace.getSelectedObject());
		this.onChange();
		this.resetErrors();
		if (this.workspace.isSelected()) {
			this.loading.emit(this.loadProjects());
		}
	};

	onInvalidWorkspaceSelection = (): void => {
		const error = this.locale.getString('contentProvider.workspaceNotAccessible');
		this.setError(error);
	};

	loadProjects(): Promise<void> {
		return this.userWorkspacesService.getWorkspaceProjects(this.workspace.getSelection())
			.then(responseProjects => {
				let projects = [];
				const internalProjects = this.hideInternalProjects
					? []
					: this.userWorkspacesService.getInternalProjects();
				const workspaceProjects = this.internalProjectsOnly ? [] : responseProjects;
				if (!internalProjects.isEmpty()) {
					projects.pushAll(internalProjects);
					if (!workspaceProjects.isEmpty()) {
						projects.push({
							projectId: 0,
							name: WorkspaceProjectSelectorComponent.PROJECT_SEPARATOR,
							disabled: true
						});
					}
				}
				let accessPromise = this.withPermissions && !workspaceProjects.isEmpty()
					? this.loadProjectAccess(workspaceProjects)
					: Promise.resolve();
				return accessPromise.then(() => {
					projects.pushAll(workspaceProjects);
					this.project.setOptions(projects);
					if (projects.isEmpty()) {
						const error = this.locale.getString('common.noProjects');
						this.setError(error);
					} else if (this.initialSelection) {
						this.onProjectChange();
					}

					this.workspaceProjectsLoaded.emit(projects);
					this.ref.detectChanges();
				});
			}, error => {
				this.project.clear();
				this.setError(error);
				this.ref.detectChanges();
			})
			.then(() => this.projectsReady.emit({
				optionsAmount: this.project.getOptionsAmount(),
				projects: this.project.getOptions()
			}));
	}

	private loadProjectAccess = (projects: Project[]): Promise<void> => {
		return this.projectAccessService.getProjectsAccessLevel(this.workspace.getSelection())
			.then((projectsAccess) => this.populateProjectAccess(projects, projectsAccess));
	};

	private populateProjectAccess = (projects: Project[], projectAccess: ProjectAccess[]): void => {
		_.each(projects, (project: Project) => {
			let matchProject: ProjectAccess = _.find(projectAccess, (p: ProjectAccess) => {
				return p.project.id === project.id;
			});
			project.accessLevel = matchProject && matchProject.accessLevel;
		});
	};

	onProjectChange = (): void => {
		this.onChange();
	};

	private resetErrors(): void {
		this.errors = [];
		this.onErrorsChange();
	}

	private setError(error: string): void {
		this.errors.push(error);
		this.onErrorsChange();
	}

	private onErrorsChange = (): void => {
		this.errorsChange.emit(this.errors);
	};

	private onClear = ():void => {
		this.workspace.clearSelection();
		this.project.clearSelection();
	};

	getWorkspacePrompt = (): string => {
		return this.labelPosition === LabelPosition.SELECT_PROMPT
			? this.locale.getString('common.selectWorkspace')
			: this.locale.getString('common.selectPrompt');
	};

	getProjectPrompt = (): string => {
		if (!this.project.hasOptions()) {
			return this.locale.getString('common.noProjects');
		}
		if (this.allProjects) {
			return this.locale.getString('common.allProjects');
		}
		return this.labelPosition === LabelPosition.SELECT_PROMPT
			? this.locale.getString('common.selectProject')
			: this.locale.getString('common.selectPrompt');
	};
}

app.directive('workspaceProjectSelector', downgradeComponent({component: WorkspaceProjectSelectorComponent}));
