import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { Security } from '@cxstudio/auth/security-service';
import DashboardPermissionUtils from '@cxstudio/common/context-menu-utils/dashboard-permission-utils';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { GridApi, MenuItemDef, RowNode } from 'ag-grid-enterprise';
import { AgGridDashboardActionsService } from '../actions/ag-grid-dashboard-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { MoveToOptionService } from '../options/move-to-option.service';
import { ShowHideOptionService } from '../options/show-hide-option.service';
import { CommonOptionsProviderService } from './common-options-provider.service';

@Injectable({
	providedIn: 'root'
})
export class DashboardMenuOptionsProviderService {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly commonOptionsProviderService: CommonOptionsProviderService,
		private readonly agGridDashboardActionsService: AgGridDashboardActionsService,
		private readonly moveToOptionService: MoveToOptionService,
		private readonly showHideOptionService: ShowHideOptionService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('security') private readonly security: Security,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('dashboardPermissionUtils') private readonly dashboardPermissionUtils: DashboardPermissionUtils,
		@Inject('environmentService') private environmentService: EnvironmentService,
	) {}

	getOptions(gridApi: GridApi, node: RowNode, filterManager: TableFilterManager): (string | MenuItemDef)[] {
		let dashboard: Dashboard = node.data;
		let isTablet: boolean = this.environmentService.isTablet();

		let items: (string | MenuItemDef)[] = [];

		items.push(this.commonOptionsProviderService.getDashboardViewOption(dashboard));

		if (this.security.has('edit_dashboard') && dashboard.permissions.EDIT) {
			if (!isTablet) {
				items.push(this.getDashboardEditOption(dashboard));
			}
			if (this.security.isAdminOrgUser()) {
				items.push(this.getDashboardEditPropertiesOption(node));
			}
			items.push(this.getDashboardRenameOption(node));
		}

		if (this.dashboardService.canShare(dashboard)) {
			items.push(this.commonOptionsProviderService.getDashboardShareOption(node));
		}

		items.push(this.getDashboardCopyLinkOption(dashboard));

		items.push(this.getDashboardExportOption(dashboard));

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATE_FROM_DASHBOARD)
				&& this.security.isAdminOrgUser()
				&& this.security.has(MasterAccountPermissionAction.CREATE_DASHBOARD)
				&& this.security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES)
				&& !(this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES))) {
			items.push(this.getDashboardSaveAsTemplateOption(dashboard));
		}

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES)
				&& this.security.isAdminOrgUser()
				&& this.security.has(MasterAccountPermissionAction.CREATE_DASHBOARD)
				&& this.security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES)) {
			items.push(this.getDashboardSaveAsTemplateBetaOption(dashboard));
		}

		items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);

		let moveToSubmenuOptions = this.moveToOptionService.getSubMenu(dashboard);
		if (moveToSubmenuOptions && moveToSubmenuOptions.length > 0) {
			items.push(this.moveToOptionService.get(moveToSubmenuOptions));
		}

		if (this.security.has('create_dashboard') && !isTablet) {
			items.push(this.commonOptionsProviderService.getDashboardCopyOption(dashboard));
		}

		if (dashboard.permissions.EDIT && this.security.has('manage_projects')) {
			items.push(this.getDashboardComponentsOption(dashboard));
		}

		if (this.dashboardPermissionUtils.canScheduleDashboard(dashboard)) {
			items.push(this.getDashboardScheduleOption(dashboard));
		}

		items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);

		let showHideSubMenuOptions = this.showHideOptionService.getSubmenu(gridApi, dashboard, filterManager);
		items.push(this.showHideOptionService.get(showHideSubMenuOptions));

		items.push(this.commonOptionsProviderService.getDashboardFavoriteOption(node));

		if (dashboard.permissions.OWN) {
			items.push(DashboardGridHelperService.MENU_OPTIONS_SEPARATOR);
			items.push(this.commonOptionsProviderService.getDashboardRemoveOption(dashboard));
			items.push(this.commonOptionsProviderService.geTransferOption([dashboard]));
		}

		return items;
	}

	private getDashboardEditOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('common.edit'),
			action: () => this.agGridDashboardActionsService.edit(dashboard)
		};
	}

	private getDashboardEditPropertiesOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.editProps'),
			action: () => this.agGridDashboardActionsService.editProperties(node)
		};
	}

	private getDashboardRenameOption(node: RowNode): MenuItemDef {
		return {
			name: this.locale.getString('common.rename'),
			action: () => this.agGridDashboardActionsService.rename(node)
		};
	}

	private getDashboardCopyLinkOption(dashboard: any): MenuItemDef {
		return {
			name: this.locale.getString('common.copyLink'),
			action: () => this.agGridDashboardActionsService.copyLink(dashboard)
		};
	}

	private getDashboardExportOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.exportPDF'),
			subMenu: [
				{
					name: this.locale.getString('dashboard.downloadPdf'),
					action: () => this.agGridDashboardActionsService.exportPdfDownload(dashboard)
				},
				{
					name: this.locale.getString('dashboard.ReceiveByEmail'),
					action: () => this.agGridDashboardActionsService.exportPdfSend(dashboard)
				},
			]
		};
	}

	private getDashboardSaveAsTemplateOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('templates.menuSaveAsTemplate'),
			action: () => this.agGridDashboardActionsService.saveAsTemplate(dashboard)
		};
	}

	private getDashboardSaveAsTemplateBetaOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('templates.menuSaveAsTemplate'),
			action: () => this.agGridDashboardActionsService.saveAsTemplateBeta(dashboard)
		};
	}

	private getDashboardComponentsOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.dashboardComponents'),
			action: () => this.agGridDashboardActionsService.showComponents(dashboard)
		};
	}

	private getDashboardScheduleOption(dashboard: Dashboard): MenuItemDef {
		return {
			name: this.locale.getString('schedule.schedule'),
			action: () => this.agGridDashboardActionsService.schedule(dashboard)
		};
	}

}
