import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import Widget from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { IDrillMenuOption, IDrillMenuOptionGroup } from '@cxstudio/reports/utils/contextMenu/drill-menu-option';
import { HierarchyTreeDrillPoint } from '@cxstudio/reports/visualizations/definitions/d3/renderers/hierarchy-tree-renderer';
import { downgradeInjectable } from '@angular/upgrade/static';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { DrillFilterEvent } from '@app/core/cx-event.enum';
@Injectable({
	providedIn: 'root'
})
export class AdditionalDrill {

	constructor(
		private readonly locale: CxLocaleService,
		private readonly globalEventBus: GlobalEventBus
	) { }

	getNonReportingOptions = (widget: Widget, point: DrillPoint): IDrillMenuOption[] => {
		const options = [];
		if (widget.linkedWidgets && point.supportUseAsFilter) {
			options.push({
				group: IDrillMenuOptionGroup.action,
				text: this.locale.getString('widget.useAsFilter'),
				name: 'useAsFilter',
				func: () => this.globalEventBus.broadcast(DrillFilterEvent.DRILL_FILTER_SET, widget, point)
			});
		}
		if (widget.properties.widgetType === WidgetType.HEATMAP && (point as any).drillAction) {
			options.push(this.getHeatmapDrillDown(point));
		}
		if (widget.properties.widgetType === WidgetType.MODEL_VIEWER) {
			options.pushAll(this.getHierarchyTreeOptions(point as HierarchyTreeDrillPoint));
		}
		return options;
	};

	private getHeatmapDrillDown(point): IDrillMenuOption {
		return {
			group: IDrillMenuOptionGroup.action,
			text: this.locale.getString('widget.drillDown'),
			name: 'drilldown',
			func: point.drillAction
		};
	}

	private getHierarchyTreeOptions(point: HierarchyTreeDrillPoint): IDrillMenuOption[] {
		return [{
			group: IDrillMenuOptionGroup.action,
			text: this.locale.getString('common.expand'),
			name: 'expand',
			func: point.expand
		}, {
			group: IDrillMenuOptionGroup.action,
			text: this.locale.getString('common.collapse'),
			name: 'collapse',
			func: point.collapse
		}].filter(option => !!option.func);
	}
}

app.service('additionalDrill', downgradeInjectable(AdditionalDrill));
