import { MasterAccountPredictiveStatus } from './master-account-predictive-status';
import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { IntegrationSatusSearch } from '@app/modules/system-administration/status/integration-status/integration-status-search';

@Injectable({
	providedIn: 'root'
})
export class PredictiveStatusApi {
	constructor(private cxHttp: CxHttpService) {}

	getPredictiveStatuses = (): Promise<MasterAccountPredictiveStatus[]> => {
		let url = 'rest/system-admin/status/predictive';
		return this.cxHttp.get(url);
	};

	export = (search: IntegrationSatusSearch): Promise<void> => {
		let url = 'rest/system-admin/status/predictive/export';
		return this.cxHttp.post(url, search);
	};
}
