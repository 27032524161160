import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { MetadataPreloaderService } from '@app/modules/dashboard/metadata-preloader.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { NestedObjectsPipe } from '@app/modules/object-list/utilities/nested-objects.pipe';
import { PromiseUtils } from '@app/util/promise-utils';
import { IFolderItem, ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { DashboardFoldersState } from '@cxstudio/dashboards/dashboard-folders-state.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { RowDataTransaction } from 'ag-grid-enterprise';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DashboardScheduleService } from '../dashboard/services/scheduling/dashboard-schedule.service';
import { AgGridNested } from '@app/modules/object-list/types/ag-grid-nested.interface';

type IDashboardFolder = Partial<IFolderItem>;

@Injectable({
	providedIn: 'root'
})
export class DashboardListService {

	private dashboardList: Dashboard[];
	private loadingPromise: Promise<Dashboard[]>;

	private readonly dashboardListChangeSubject: BehaviorSubject<Dashboard[]>;

	// used for old slick grid table
	private readonly dashboardChangesSubjectOld = new Subject<ITreeItem[]>();

	private readonly dashboardChangesSubject = new Subject<RowDataTransaction>();
	private readonly loadingSubject = new Subject<boolean>();

	constructor(
		private readonly locale: CxLocaleService,
		private readonly metadataPreloaderService: MetadataPreloaderService,
		private readonly nestPipe: NestedObjectsPipe<Dashboard>,
		private readonly dashboardScheduleService: DashboardScheduleService,
		@Inject('dashboardApiService') private readonly dashboardApiService: DashboardApiService,
		private readonly gridUtils: GridUtilsService,
		@Inject('dashboardFoldersState') private readonly dashboardFoldersState: DashboardFoldersState,
		@Inject('dashboardFiltersService') private readonly dashboardFiltersService: DashboardFiltersService
	) {
		this.dashboardList = [];
		this.dashboardListChangeSubject = new BehaviorSubject<Dashboard[]>(this.dashboardList);
	}

	getCurrentDashboardsList(): Dashboard[] {
		return this.dashboardList;
	}

	getDashboards(): Observable<Dashboard[]> {
		return this.dashboardListChangeSubject;
	}

	getLoadingChange(): Observable<boolean> {
		return this.loadingSubject;
	}

	setLoading<T>(promise: ng.IPromise<T>): ng.IPromise<T>;
	setLoading<T>(promise: Promise<T>): Promise<T>;
	setLoading(promise: any): any {
		this.loadingSubject.next(true);
		PromiseUtils.wrap(promise).finally(() => this.loadingSubject.next(false));
		return promise;
	}

	updateDashboardsOld(dashboards?: ITreeItem[]): void {
		if (dashboards) {
			if (!_.isArray(dashboards))
				throw new Error('Argument should be an array');
			this.dashboardChangesSubjectOld.next(dashboards);
		} else this.dashboardListChangeSubject.next(this.dashboardList);
	}

	updateDashboards(dashboards?: ITreeItem[]): void {
		this.dashboardList = this.processTreeStructure(this.dashboardList);
		if (!dashboards) {
			this.dashboardListChangeSubject.next(this.dashboardList);
		} else {
			this.dashboardChangesSubject.next({update: dashboards});
		}
	}

	addDashboards(dashboards?: ITreeItem[]): void {
		this.dashboardList.pushAll(dashboards as Dashboard[]);
		this.dashboardList = this.processTreeStructure(this.dashboardList);
		this.dashboardListChangeSubject.next(this.dashboardList);
	}

	removeDashboards(dashboards: ITreeItem[], refreshAll = false): void {
		let deletedIds = _.pluck(dashboards, 'id');
		this.dashboardList = _.filter(this.dashboardList, dashboard => !_.contains(deletedIds, dashboard.id));
		this.dashboardList = this.processTreeStructure(this.dashboardList);
		if (refreshAll) {
			this.dashboardListChangeSubject.next(this.dashboardList);
		} else {
			this.dashboardChangesSubject.next({remove: dashboards});
		}
	}

	// old logic
	getDashboardChangeOld(): Observable<ITreeItem[]> {
		return this.dashboardChangesSubjectOld;
	}

	getDashboardChange(): Observable<RowDataTransaction> {
		return this.dashboardChangesSubject;
	}

	reloadDashboardsOld(): Promise<Dashboard[]> {
		if (this.loadingPromise) {
			return this.loadingPromise;
		}

		this.loadingPromise = PromiseUtils.wrap(this.dashboardApiService.getAllDashboards()).then((dashboards) => {
			return this.processDashboards(dashboards, true);
		}).then(dashboards => {
			delete this.loadingPromise;
			this.dashboardList = dashboards;
			this.dashboardListChangeSubject.next(this.dashboardList);
			this.metadataPreloaderService.startPreloading(dashboards);
			return dashboards;
		});

		return this.loadingPromise;
	}

	getDashboardsForUser(userId: number): Promise<Dashboard[]> {
		return PromiseUtils.wrap(this.dashboardApiService.getAllDashboards(userId)).then((dashboards) => {
			return this.processDashboards(dashboards, false);
		});
	}

	processDashboards(dashboards: any[], withSchedules: boolean): Dashboard[] {
		let feedbackFolder = this.getFeedbackFolder();
		let result = [feedbackFolder].concat(dashboards);

		let flatTree = this.gridUtils.processItemsTree(result);

		flatTree.forEach((treeItem) => {
			if (treeItem.type === DashboardType.FOLDER) {
				let folderState = this.dashboardFoldersState.getFolderState(treeItem.id);
				(treeItem as any)._collapsed = folderState ? folderState.collapsed : true;
			}

			if (treeItem === feedbackFolder && _.isEmpty(treeItem.children)) {
				treeItem.hide = true;
			}

			if (treeItem.hide) {
				TaggingHelper.tag(treeItem, TaggingHelper.tags.HIDDEN);
			}
		});

		let isOwner = _.find(flatTree, (dashboard) => {
			return dashboard.permissions && dashboard.permissions.OWN;
		});
		if (withSchedules && isOwner) {
			this.dashboardScheduleService.getDashboardSchedules(dashboards).then((changes) => {
				this.dashboardChangesSubjectOld.next(changes);
			});
		}
		flatTree.forEach(this.dashboardFiltersService.addHierarchyFilter); // move this to dashboard viewing
		return flatTree;
	}

	reloadDashboards(withSchedules: boolean = true): Promise<Dashboard[]> {
		if (this.loadingPromise) {
			return this.loadingPromise;
		}

		this.loadingPromise = PromiseUtils.wrap(this.dashboardApiService.getAllDashboards()).then((dashboards) => {
			let feedbackFolder = this.getFeedbackFolder();
			let result = [feedbackFolder].concat(dashboards) as any[] as Dashboard[];

			if (!_.find(dashboards, dashboard => TreeListTransformUtils.getParentKey(dashboard) === DashboardType.FEEDBACK_FOLDER)) {
				feedbackFolder.hide = true;
			}

			return {result, dashboards};
		}).then(({result, dashboards}) => {
			this.dashboardList = this.processTreeStructure(result);
			delete this.loadingPromise;
			this.dashboardListChangeSubject.next(this.dashboardList);

			let isOwner = _.find(dashboards, (dashboard) => {
				return dashboard.permissions && dashboard.permissions.OWN;
			});

			if (withSchedules && isOwner) {
				this.getDashboardSchedules(dashboards);
			}

			return this.dashboardList;
		});
		this.setLoading(this.loadingPromise);
		return this.loadingPromise;
	}

	private getSortKey(item: Dashboard): string {
		const prefix = item.type === DashboardType.FOLDER ? '0' : '1';
		return `${prefix}_${item.name?.toLowerCase()}`;
	}

	private processTreeStructure(list: Dashboard[]): Dashboard[] & AgGridNested[] {
		list.sort((a, b) => this.getSortKey(a).localeCompare(this.getSortKey(b)));
		return this.nestPipe.transform(list);
	}

	private getDashboardSchedules(rawDashboardList: Dashboard[]) {
		this.dashboardScheduleService.getDashboardSchedules(rawDashboardList).then(() => {
			this.dashboardListChangeSubject.next(this.dashboardList);
		});
	}

	private getFeedbackFolder(): IDashboardFolder {
		return {
			name: this.locale.getString('dashboard.feedbackFolder'),
			id: DashboardType.FEEDBACK_FOLDER as any,
			description: '',
			type: DashboardType.FOLDER
		};
	}

}

app.service('dashboardListService', downgradeInjectable(DashboardListService));
