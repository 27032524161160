import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'submenu-flyout',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<div
	class="d-flex flex-direction-column h-100-percent h-max-100-percent"
	[ngClass]="nestedMenuClass()">
	<ng-content></ng-content>
</div>`
})
export class SubmenuFlyoutComponent {
	@Input() level: number;
	@Input() option;

	nestedMenuClass = () => {
		let classes = [];
		classes.push(`menu-level-${this.level}`);
		classes.push(`br-${this.option.name}-menu-level-${this.level}`);
		if (this.option.searchBox) {
			classes.push('dropdown-submenu-dynamic');
		}
		return classes.join(' ');
	};
}

app.directive('submenuFlyout', downgradeComponent({component: SubmenuFlyoutComponent}));
