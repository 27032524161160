export enum StandardMetricName {
	VOLUME = 'volume',
	PERCENT_OF_TOTAL = 'percentOfVolume',
	CUMULATIVE_PERCENT_TOTAL = 'cumulativePercentTotal',
	PARENT_PERCENT = 'parentPercent',
	SENTIMENT = 'sentiment',
	ALPHANUMERIC = 'alphanumeric',
	MODEL_ORDER = '_modelOrder',
	STRENGTH = '_strength',
	CONSTANT_SIZE = '',
	NONE = '',
	POP = '_pop',
}
