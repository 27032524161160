import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { QualtricsApiProvider } from './qualtrics-api-provider';

@Injectable({ providedIn: 'root' })
export class QualtricsApiProvidersApiService {
	private readonly API_PATH: string = 'rest/qualtrics-api/providers';

	constructor(
		private readonly cxHttp: CxHttpService) {
	}

	getApiProviders = (): Promise<QualtricsApiProvider[]> => {
		return this.cxHttp.get(this.API_PATH);
	};

	createApiProvider = (apiProvider: QualtricsApiProvider): Promise<void> => {
		return this.cxHttp.post(this.API_PATH, apiProvider);
	};

	updateApiProvider = (apiProvider: QualtricsApiProvider): Promise<void> => {
		return this.cxHttp.put(`${this.API_PATH}/${apiProvider.id}`, apiProvider);
	};

	removeApiProvider = (apiProvider: QualtricsApiProvider): Promise<void> => {
		return this.cxHttp.delete(`${this.API_PATH}/${apiProvider.id}`);
	};
}
