import * as _ from 'underscore';

import { IProjectSelection } from './project-selection.interface';
import { ProjectIdentifier } from './project-identifier';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import SimpleGroupingMobileScreenSettings from '@cxstudio/mobile/simple-grouping-mobile-screen-settings.interface';
import ILocale from '@cxstudio/interfaces/locale-interface';
import MobileAppSettingsListModel from '@cxstudio/mobile/mobile-app-settings-list-model.interface';
import { IShareEntity } from '@cxstudio/sharing/sharing-service.service';
import MobileScreenSettings from '@cxstudio/mobile/mobile-screen-settings.interface';
import { MobileScreenType } from '@cxstudio/mobile/mobile-screen-type.enum';

export default class MobileUtils {

	readonly CLARABRIDGE_DOMAIN = '@clarabridge.com';
	readonly QUALTRICS_DOMAIN = '@qualtrics.com';

	constructor(private locale: ILocale) {}

	matchesSettings = (settings: MobileAppSettings, projectSelection: IProjectSelection | ProjectIdentifier): boolean => {
		return _.isMatch(settings, {
			contentProviderId: projectSelection.contentProviderId,
			accountId: projectSelection.accountId,
			projectId: projectSelection.projectId
		});
	};

	isSimpleGroupingScreenConfigured = (simpleGroupingScreen: SimpleGroupingMobileScreenSettings): boolean => {
		return this.getSimpleGroupingScreenConfigurationErrors(simpleGroupingScreen).length === 0;
	};

	getSimpleGroupingScreenConfigurationErrors = (simpleGroupingScreen: SimpleGroupingMobileScreenSettings): string[] => {
		const errors = [];

		if (!simpleGroupingScreen.grouping || !simpleGroupingScreen.grouping.name) {
			errors.push(this.locale.getString('mobile.errorGroupingRequired'));
		}

		if (!simpleGroupingScreen.groupingCalculation || !simpleGroupingScreen.groupingCalculation.name) {
			errors.push(this.locale.getString('mobile.errorGroupingCalculationRequired'));
		}

		return errors;
	};

	isOwnerChanged = (originalSettings: MobileAppSettings, currentSettings: MobileAppSettings): boolean => {
		return originalSettings.id && currentSettings.submitterEmail && currentSettings.submitterEmail !== originalSettings.submitterEmail;
	};

	isIllegalAccessProvided = (mobileApp: MobileAppSettings | MobileAppSettingsListModel, dialogData: any): boolean => {
		let sharedEntities: IShareEntity[] = dialogData.sharedEntities || [];
		let illegalSharedEntities = sharedEntities
			.filter(shareEntity => shareEntity.type === 'user'
				&& shareEntity.permission !== 'REMOVE'
				&& !this.isAllowedToViewApp(mobileApp, shareEntity.entity.userEmail));

		return illegalSharedEntities.length > 0;
	};

	isAllowedToViewApp = (mobileApp: MobileAppSettings | MobileAppSettingsListModel, email: string): boolean => {
		return this.isInternalAppUser(email) || !mobileApp.internal;
	};

	/**
	 * Note, that this definition of internal app users only includes users of Clarabridge domain,
	 * which is different from regular "internal users" definition elsewhere is the app.
	 */
	isInternalAppUser = (email: string): boolean => {
		return email.toLowerCase().endsWith(this.CLARABRIDGE_DOMAIN) ||  email.toLowerCase().endsWith(this.QUALTRICS_DOMAIN);
	};

	isThirdScreenConfigured = (settings: MobileAppSettings): boolean => {
		const thirdScreen = _.find(settings.screens, (screen: MobileScreenSettings) => {
			return screen.optional && screen.type === MobileScreenType.SIMPLE_GROUPING;
		});

		return this.isSimpleGroupingScreenConfigured(thirdScreen as SimpleGroupingMobileScreenSettings);
	};
}

app.service('mobileUtils', MobileUtils);
