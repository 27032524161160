import * as _ from 'underscore';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Key, KeyboardUtils, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { RandomUtils } from '@app/util/random-utils.class';


//This directive is used for the hierarchies for dashboard filters
export class SearchableHierarchyForAttrsController implements ng.IController {

	onNodeClick: (node) => void;
	onSearch: (params: {query: string}) => void;
	hierarchyList: any[];
	isLoading: boolean;
	displayProperty: string;
	placeholder: string;
	searchLabel: string;
	selectedItem: any;
	id: string;
	styleId: string;

	models: any; // TODO this cannot work as it's not binded into component, but it's still used in code

	constructor(
		private locale: ILocale,
		private $timeout: ng.ITimeoutService
	) {}

	$onInit(): void {
		this.styleId = `s-${RandomUtils.randomString()}`;
	}

	searchUpdated = () => {
		if (this.onSearch) {
			this.onSearch({query: this.models.hierarchySearch.trim()});
		}
		if (this.models.hierarchySearch.trim().length < 1) {
			this.hierarchyList.forEach(SearchableHierarchyUtils.collapseFolder);
		} else {
			this.hierarchyList.forEach(SearchableHierarchyUtils.expandFolders);
		}
	};

	triggerClick = (item) => {
		this.onNodeClick({node: item});
	};

	isVisible = (item) => {
		return !item.hidden && !item.hide;
	};

	getNoItemsExistMessage = () => {
		let message;
		if (this.isLoading) {
			message = this.locale.getString('common.itemsLoading');
		} else {
			message = this.locale.getString('common.noMatchedItems');
		}
		return message;
	};

	isEmptyFolder = (item) => SearchableHierarchyUtils.isEmptyFolder(item);
	isFolder = (item) => SearchableHierarchyUtils.isFolder(item);
	showItem = (item) => SearchableHierarchyUtils.showItem(item);
	toggleParent = (item, $event) => SearchableHierarchyUtils.toggleParent(item, $event);

	onSearchableHierarchyForAttrsKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeAndFocusOnDropdown();
			});
		}
	};

	onSearchKeydown = (event: KeyboardEvent): void => {
		if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeAndFocusOnDropdown();
			});
		}
	};

	private closeAndFocusOnDropdown = (): void => {
		let selectFromHierarchyDropdownButton: JQuery = $('#select-from-hierarchy-button');
		selectFromHierarchyDropdownButton.trigger('click');
		selectFromHierarchyDropdownButton.trigger('focus');
	};

	onHierarchyItemKeydown = (event: any, item: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER) || KeyboardUtils.isEventKey(event, Key.SPACE)) {
			event.preventDefault();
			event.stopPropagation();
			if (item.children?.length || this.isFolder(item)) {
				this.toggleParent(item, event);
			} else {
				this.triggerClick(item);
				this.$timeout(() => {
					this.focusOnNewDropdown(item.filterType);
				});
			}
		} else {
			KeyboardUtils.handleUpDownNavigation(event, `#${this.id} .searchable-hierarchy-root`);
		}
	};

	private focusOnNewDropdown = (filterType: string): void => {
		if (filterType === 'topic') {
			let topicMultiselectDropdown = $('#dashboard-filters-popup topic-multiselect .dropdown-toggle').last();
			topicMultiselectDropdown.trigger('focus');
		} else if (filterType === 'attribute' || filterType === 'savedFilter') {
			let multiselectDropdown = $('#dashboard-filters-popup #multiselect-dropdown-button').last();
			multiselectDropdown.trigger('focus');
		} else {
			// Date Range
			let dateRangeDropdown = $('.search-list-selected-option.d-block').last();
			dateRangeDropdown.trigger('focus');
		}
	};

	getHierarchyItemTabIndex = (sectionId: number, index: number): number => {
		let hierarchyItemCheckbox = $(`#section_${sectionId}_node_${index}`);
		return hierarchyItemCheckbox.length > 0 ? -1 : 0;
	};

}

app.component('searchableHierarchyForAttrs', {
	controller: SearchableHierarchyForAttrsController,
	templateUrl: 'partials/custom/searchable-hierarchy-for-attrs.html',
	bindings: {
		onNodeClick: '&',
		onSearch: '&',
		hierarchyList: '=',
		isLoading: '=',
		displayProperty: '@',
		placeholder: '@',
		searchLabel: '@',
		selectedItem: '=?',
		id: '@'
	},
});
