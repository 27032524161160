import * as _ from 'underscore';
import { WidgetTimingService } from '@cxstudio/reports/timing/widget-timing.service';
import { ElementUtils } from '@cxstudio/reports/utils/visualization/element-utils.service';
import { DashboardOptimization } from '@cxstudio/dashboards/optimization/dashboard-optimization.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportDefinitionScope } from '@cxstudio/reports/entities/report-definition';
import { HighchartsVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-visualization.interface';
import { Chart } from 'highcharts';
import { DateInterval } from '@app/modules/plot-lines/reference-lines/point-metadata-utils';
import { XRangeData } from '@app/modules/widget-visualizations/highcharts/highcharts-dual-with-cases/highcharts-case-utils.service';
import { TableServiceUtils } from '../table-service-utils';


type IChangeListener = (newValue?, oldValue?) => void;

interface VisualizationComponentScope extends ISimpleScope {
	chart?: any;
	lastSize?: string;
	resizeObserver?: any;
}

export interface ITableReportScope extends ISimpleScope {
	grid;
	$parent: {
		widget: Widget;
	};
}

export class ReportUtils {

	private loadingWidgets: string[];

	constructor(
		private widgetTimingService: WidgetTimingService,
		private $timeout: ng.ITimeoutService,
		private $rootScope: ng.IRootScopeService,
		private elementUtils: ElementUtils,
		private dashboardOptimization: DashboardOptimization,
		private $window: ng.IWindowService,
	) {
		this.loadingWidgets = [];
	}

	buildReportElement = (position: {left; right; top; bottom}, cssPosition?: string, additionalCss?: string): ng.IAugmentedJQuery => {
		cssPosition = cssPosition || 'absolute';
		additionalCss = additionalCss || '';

		return angular.element(
			`<div style="overflow:auto;overflow-x:hidden;position:${cssPosition};z-index:1;
				left:${position.left};right:${position.right};bottom:${position.bottom};top:${position.top};${additionalCss}">
			</div>`);
	};

	elementSizeWatcher = (element: Element): () => string => {
		return this.dashboardOptimization.wrapSupplier(() => this.elementUtils.sizeOf(element));
	};

	private buildTableResizeHandler = (renderFunction: IChangeListener): IChangeListener => {
		return (newValue, oldValue) => {
			if (newValue === oldValue || newValue === '0x0')
				return;
			renderFunction();
		};
	};

	handlerWithDelay = (handler: IChangeListener): IChangeListener => {
		let timer;
		return (newValue, oldValue) => {
			if (newValue === oldValue || newValue === '0x0')
				return;
			this.$timeout.cancel(timer);
			timer = this.$timeout(() => {
				this.$timeout.cancel(timer);
				handler(newValue, oldValue);
			}, 500);
		};
	};

	tableResizeHandler = (reportDefinition: ITableReportScope, element: ng.IAugmentedJQuery): IChangeListener => {
		return this.buildTableResizeHandler(() => {
			this.rerenderGrid(reportDefinition, element);
		});
	};

	private rerenderGrid = (reportDefinition: ITableReportScope, element: ng.IAugmentedJQuery): void => {
		let grid = reportDefinition.grid;
		let mobile = this.$rootScope.isMobile;

		grid.resizeCanvas();
		grid.invalidate();
		grid.render();

		if (mobile) {
			TableServiceUtils.postRenderMobile(element);
		} else {
			TableServiceUtils.postRenderRegular(element);
		}
	};

	chartResizeHandler = (reportDefinition: HighchartsVisualization): IChangeListener => {
		return (newValue, oldValue) => {
			this.handleChartResize(reportDefinition.chart, newValue, oldValue);
		};
	};

	handleChartResize(chart: Chart, newSize: string, oldSize: string): void {
		if (!newSize || newSize === oldSize)
			return;
		let size = newSize.split('x');
		if (!chart || size[0] === '0')
			return;
		chart.setSize(parseInt(size[0], 10), parseInt(size[1], 10));
	}

	handleCasesContainerResize(casesContainerElement: HTMLElement, dualContainerElement: HTMLElement, topChartElement: HTMLElement,
		chartOptions: Highcharts.Options, dateRanges: DateInterval[]): void {
			const SCROLL_WIDTH: number = 16;

			let casesLinesAmount: number = this.calculateXrangeLinesAmount(chartOptions, dateRanges);
			if (casesLinesAmount > 0) {
				let requiredHeightForCasesContainer: number = this.calculateRequiredHeightForCasesContainer(casesLinesAmount);

				let topChartHeight: number = $(topChartElement).height();
				if (topChartHeight >= requiredHeightForCasesContainer) {
					$(casesContainerElement).height('100%');
					$(dualContainerElement).width('100%');
				} else {
					$(casesContainerElement).height(requiredHeightForCasesContainer);
					$(dualContainerElement).width(`calc(100% - ${SCROLL_WIDTH}px)`);
				}
			}
	}

	// distance between xrange bars can not be set using hicharts API so to get dataLabelTopOffset = 8px we need to hardcode chartOffset
	private calculateRequiredHeightForCasesContainer(casesLinesAmount: number): number {
		const xrangeBarHeight: number = 20;

		const dataLabelTopOffset: number = 8; // according to CB-18726
		const dataLabelSpanHeight: number = 13;
		const dataLabelBottomOffset: number =  3.5;

		const chartOffset: number = 36; // offset to get dataLabelTopOffset 8px

		return chartOffset * 2 + xrangeBarHeight +
			(xrangeBarHeight + dataLabelBottomOffset + dataLabelSpanHeight + dataLabelTopOffset) * (casesLinesAmount - 1);
	}

	private calculateXrangeLinesAmount(chartOptions: Highcharts.Options, dateRanges: DateInterval[]): number {
		return _.chain(chartOptions.series)
			.filter(serie => serie.type === 'xrange'
				&& (serie.data as unknown as XRangeData).x !== dateRanges.length
				&& (serie.data as unknown as XRangeData).x2 !== -1)
			.map((serie: any) => serie.data[0].y)
			.uniq()
			.value()
			.length;
	}

	chartTriggerHandler = (scope: ReportDefinitionScope, renderFn: () => void): IChangeListener => {
		return (newValue, oldValue) => {
			if (newValue === oldValue) return;
			if (_.isUndefined(scope.dataObject.data) || scope.dataObject.data.length === 0) {
				return;
			}
			renderFn();
		};
	};

	handleWidgetRenderedEvent = (widgetId: number, widgetType: string, containerId: string) => {
		this.widgetTimingService.finishRendering(widgetId, containerId);
		// sometimes widgets redraw after completion, so ignoring such cases
		if (!_.isEmpty(this.loadingWidgets)) {
			this.loadingWidgets.remove(widgetType + '_' + widgetId);

			if (this.loadingWidgets.length === 0) {
				this.$timeout(() => { //  in case of any unaccounted rendering case
					this.$rootScope.$broadcast('dashboardLoadingFinishedEvent');
				}, 1000);
			}
		}
	};

	registerWidgetsRendering = (widgets: Widget[]): void => {
		this.loadingWidgets = [];
		widgets.forEach((widget) => this.registerWidgetRendering(widget.id, widget.properties.widgetType));
	};

	registerWidgetRendering = (widgetId: number, widgetType: string): void => {
		if (widgetType === 'page_break') {
			return;
		}

		if (!this.loadingWidgets.contains(widgetType + '_' + widgetId)) {
			this.loadingWidgets.push(widgetType + '_' + widgetId);
		}
	};

	initDestroyListener = (scope: VisualizationComponentScope) => {
		scope.$on('$destroy', () => {
			(scope as ng.IScope).$$watchers = [];
			if (scope.resizeObserver) {
				scope.resizeObserver.disconnect();
				delete scope.resizeObserver;
			}
		});
	};

	initResizeHandler = (scope: VisualizationComponentScope, element: ng.IAugmentedJQuery,
		callback: (dimensions: string, prevDimensions: string) => void) => {
		if (this.$window.ResizeObserver) {
			scope.resizeObserver = new this.$window.ResizeObserver(() => {
				let currentSize = this.elementUtils.sizeOf(element[0]);
				callback(currentSize, scope.lastSize);
				scope.lastSize = currentSize;
			});
			scope.resizeObserver.observe(element[0]);
		} else {
			scope.$watch(
				this.elementSizeWatcher(element[0]),
				callback
			);
		}
	};

	clearLoadingWidgets(): void {
		this.loadingWidgets = [];
	}

}

app.service('reportUtils', ReportUtils);
