import { Component, ChangeDetectionStrategy, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectListIdentifier } from '@cxstudio/projects/project-list-identifier';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { Unit } from '@app/modules/units/unit';
import { UnitsApi } from '@app/modules/units/units.api.service';
import { UserWorkspacesService } from '@app/modules/units/workspace-project-selector/user-workspaces.service';
import { IAutoselectProviders } from '@cxstudio/services/autoselect-providers.factory';

@Component({
	selector: 'report-project-selection-dialog',
	templateUrl: './report-project-selection-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReportProjectSelectionDialogComponent implements OnInit {
	private autoselectProcess: IAutoselectProviders;
	loading: any;
	props: any;
	contentProviders: any[];
	accounts: any[];
	projects: any[];
	loadingAccounts: boolean;
	loadingProjects: boolean;
	selectedProjects: any[];

	isWorkspaceEnabled: boolean;
	workspaces: Unit[];
	selectedWorkspace: Unit;
	selectedWorkspaceId: number;

	constructor(
		private readonly unitsApi: UnitsApi,
		private readonly ref: ChangeDetectorRef,
		private readonly modal: NgbActiveModal,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly userWorkspacesService: UserWorkspacesService,
		@Inject('AutoselectProviders') private readonly AutoselectProviders
	) { }

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.loading = {};
		this.props = this.props || {};
		this.contentProviders = this.contentProviders || [];
		this.accounts = this.accounts || [];
		this.projects = this.projects || [];
		this.selectedProjects = [];

		if (this.isWorkspaceEnabled) {
			this.loadWorkspaces();
		} else {
			this.autoselectProcess = new this.AutoselectProviders(this);
			this.autoselectProcess.init();
			this.autoselectProcess.onSelectProject(() => {
				this.updateSelectedProjects(true, this.props.project);
			});
		}
	}

	private loadWorkspaces(): void {
		this.loading = this.unitsApi.getCurrentMasterAccountUnits()
			.then(units => {
				this.workspaces = units;
				this.preselectWorkspace();
				this.ref.detectChanges();
			});
	}

	private preselectWorkspace(): void {
		if (_.isEmpty(this.workspaces)) {
			return;
		}

		this.switchWorkspace(this.workspaces[0].id);
	}

	private loadProjects(): Promise<void> {
		return this.userWorkspacesService.getWorkspaceProjects(this.selectedWorkspaceId)
			.then(workspaceProjects => {
				this.projects = workspaceProjects;

				if (this.projects?.length === 1) {
					this.updateSelectedProjects(true, this.projects[0]);
				}

				this.ref.detectChanges();
			});
	}

	continue = () => {
		const projectIdentifier = this.isWorkspaceEnabled
			? new ProjectListIdentifier(
				this.selectedWorkspace.contentProviderId, this.selectedWorkspace.accountId, this.selectedProjects)
			: new ProjectListIdentifier(this.props.contentProviderId, this.props.accountId, this.selectedProjects);
		this.modal.close(projectIdentifier);
	};

	cancel = () => {
		this.modal.dismiss('cancel');
	};

	onSelectProvider = (): void => {
		this.selectedProjects = [];
		this.autoselectProcess.reloadAccounts(this.props.contentProviderId);
	};

	onSelectAccount = (): void => {
		this.selectedProjects = [];
		this.autoselectProcess.reloadProjects(this.props.contentProviderId, this.props.accountId);
	};

	isValidId = (id: any): boolean => {
		return id !== null && id >= 0;
	};

	isContentProviderSelected = (): boolean => {
		return this.isValidId(this.props.contentProviderId);
	};

	isAccountSelected = (): boolean => {
		return this.isValidId(this.props.accountId);
	};

	isProjectSelected = (): boolean => {
		return !_.isEmpty(this.projects) && !_.isEmpty(this.selectedProjects);
	};

	isWorkspaceSelected = (): boolean => {
		return this.selectedWorkspaceId > 0;
	};

	shouldHideAccountSelection = (): boolean => {
		return !this.isContentProviderSelected() ||
			isEmpty(this.accounts) ||
			this.loadingAccounts ||
			this.accounts.length === 1;
	};

	shouldHideProjectSelection = (): boolean => {
		let contentProviderOrWorkspaceNotSelected = this.isWorkspaceEnabled
			? !this.isWorkspaceSelected()
			: !this.isContentProviderSelected() || !this.isAccountSelected();

		return contentProviderOrWorkspaceNotSelected
			|| isEmpty(this.projects)
			|| this.loadingProjects
			|| this.projects.length === 1;
	};

	hasNoAccounts = (): boolean => {
		return this.isContentProviderSelected() &&
			!this.loadingAccounts &&
			isEmpty(this.accounts);
	};

	hasNoProjects = (): boolean => {
		let contentProviderOrWorkspaceSelected = this.isWorkspaceEnabled
			? this.isWorkspaceSelected()
			: this.isContentProviderSelected() && this.isAccountSelected();

		return contentProviderOrWorkspaceSelected
			&& isEmpty(this.projects)
			&& !this.loadingProjects
			&& !this.loading;
	};

	updateSelectedProjects(projectSelected: boolean, project: any): void {
		let projectId = project.projectId || project.id;

		if (projectSelected) this.selectedProjects.push(projectId);
		else this.selectedProjects.remove(projectId);
	}

	switchWorkspace(workspaceId: number): void {
		this.selectedWorkspaceId = workspaceId;
		this.selectedWorkspace = _.findWhere(this.workspaces, { id: workspaceId });
		this.selectedProjects = [];
		this.loading = this.loadProjects();
	}
}
