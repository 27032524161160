import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { StatsTableEntity } from '@app/modules/widget-container/widget-stats/stats-table-entity';
import { StatsTableRowGroup } from '@app/modules/widget-container/widget-stats/stats-table-entity-group';

@Component({
	selector: 'stats-table',
	template: `
<table class="stats-table kb-focusable" [ngClass]="tableClass" tabindex="0">
	<tbody *ngFor="let group of tableData">
		<tr *ngFor="let row of group.rows">
			<th>{{getRowName(row)}}</th>
			<td class="break-word pb-8">
				<ng-container [ngSwitch]="row.type">
					<time-to-run *ngSwitchCase="'timeToRun'"
						[widgetTiming]="row.data">
					</time-to-run>
					<span *ngSwitchDefault>{{getRowValue(row)}}</span>
				</ng-container>
			</td>
		</tr>
	</tbody>
</table>`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	styles: [`
stats-table { font-size: var(--font-size-0_875rem); }
.stats-table {
	th { width: 160px; }
	th, td {
		vertical-align: top;
	}
	tbody tr:last-child th {
		padding-bottom: 10px;
	}
}

table th {
	color: var(--default-text-color);
}
`]
})
export class StatsTableComponent {

	@Input() tableData: StatsTableRowGroup[];
	@Input() tableClass: string;

	getRowName = (row: StatsTableEntity): string => {
		return `${row.name}${NBSP}: `;
	};

	getRowValue = (row: StatsTableEntity): string => {
		return row.textFormatter ? row.textFormatter(row.value) : row.value;
	};
}

// special non-breaking space before the colon to prevent just colon from wrapping to new line
export const NBSP = ' ';
