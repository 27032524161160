import { Injectable } from '@angular/core';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ConversationSettings } from './conversation-settings.class';
import { LocallyStoredItems } from '@app/core/storage/locally-stored-items';

@Injectable()
export class ConversationSettingsService {
	private readonly SETTINGS_TTL_SEC: number = 30 * 24 * 60 * 60; // 30 days
	private readonly STORAGE_NAME: string = LocallyStoredItems.conversation.storageName;

	constructor(
		private sessionPreferencesService: SessionPreferencesService
	) {
	}

	getSettings(): ConversationSettings {
		return this.sessionPreferencesService.get(this.STORAGE_NAME);
	}

	saveSettings(settings: ConversationSettings): void {
		settings.ttl = Date.now() + (this.SETTINGS_TTL_SEC * 1000);
		this.sessionPreferencesService.set(this.STORAGE_NAME, settings);
	}
}
app.service('conversationSettingsService', downgradeInjectable(ConversationSettingsService));