import * as _ from 'underscore';
import * as uib from 'angular-ui-bootstrap';

import ILocale from '@cxstudio/interfaces/locale-interface';
import { DriversItem, DriversStatus } from '@cxstudio/drivers/entities/drivers-item';
import {CBDialogService} from '@cxstudio/services/cb-dialog-service';
import {Project} from '@cxstudio/user-administration/users/project-access/project-class';
import {DriversType} from '@cxstudio/drivers/entities/drivers-type';
import { SharingParams, SharingService } from '@cxstudio/sharing/sharing-service.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { Security } from '@cxstudio/auth/security-service';
import { IDriversFolder } from '@cxstudio/drivers/entities/drivers-folder';
import { DriversTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { DriversFolderApi } from '@cxstudio/drivers/drivers-folder-api.service';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { GridUpdateService } from '@app/modules/object-list/utilities/grid-update.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { TransferGroup } from '@app/modules/user-administration/transfer/transfer-group';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { GenericSelectUtils } from '@app/modules/item-grid/selection/generic-selection-utils.factory';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { NameService } from '@cxstudio/common/name-service';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';

export interface IDriversActionsScope {
	props?: any;
	currentProjects?: Project[];
	loading?: ProjectAssetsLoading;
	driversList?: DriversTreeItem[];
	errors: any;
	selectionUtils: GenericSelectUtils<DriversTreeItem>;
	getDriversTemplate(projectId: number): DriversItem;
	refreshGrid(items?: any): void;
	reloadDrivers(): void;
}

export interface IDriversActionsService {
	refreshDrivers(driver: DriversItem): void;
	editDrivers(driver: DriversItem, openSpotCheckDialog?: boolean): void;
	viewDrivers(driver: DriversItem): void;
	copyDrivers(driver: DriversItem): void;
	shareDrivers(driver: DriversItem[]): void;
	deleteDrivers(driver: DriversItem): void;
	bulkDeleteDrivers(driversConfigs: DriversItem[]): void;
	createDrivers(folder?: IFolderItem): void;
	bulkTransferDrivers(driversConfigs: DriversItem[], projectIdentifier: ProjectIdentifier): Promise<void>;

	renameFolder(folder: IDriversFolder): void;
	removeFolder(folder: IDriversFolder): void;
	createFolder(parent?: IDriversFolder): void;
	moveToFolder(item: DriversTreeItem, folderTo: IDriversFolder): void;

	toggleHide(item: DriversItem): void;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('DriversActionsService', function(
	locale: ILocale,
	$uibModal: uib.IModalService,
	driversApi: DriversApi,
	cbDialogService: CBDialogService,
	nameService: NameService,
	sharingService: SharingService,
	FolderService,
	security: Security,
	securityApiService: SecurityApiService,
	globalNotificationService: GlobalNotificationService,
	driversFolderApi: DriversFolderApi,
	treeService: TreeService,
	gridUpdateService: GridUpdateService,
	transferApiService: TransferApiService,
	cachedHttpService: CachedHttpService,
	downgradeDialogService: DowngradeDialogService
) {
	return class DriversActionsService implements IDriversActionsService {
		private folderSvc: any;
		constructor(private scope: IDriversActionsScope) {
			this.folderSvc = new FolderService(FolderTypes.DRIVERS);
		}

		refreshDrivers(driver: DriversItem): void {
			driversApi.refreshDrivers(driver.id).then(() => {
				driver.status = DriversStatus.training;
				this.scope.refreshGrid(driver);
			});
		}

		editDrivers(driver: DriversItem, openSpotCheckDialog?: boolean): void {
			this.driversModal(driver, false, openSpotCheckDialog).then((dialogResult) => {
				let apiFunction = dialogResult.datasetChanged
					? driversApi.updateDrivers
					: driversApi.updateNonDatasetFields;

				if (driver.type === DriversType.drivers_template) {
					apiFunction = driver.id === -1
						? driversApi.createDriversTemplate
						: driversApi.updateDriversTemplate;
				}
				return this.dialogCallbackFunction(apiFunction)(dialogResult);
			});
		}

		viewDrivers(driver: DriversItem): void {
			this.driversModal(driver, true);
		}

		copyDrivers(driver: DriversItem): void {
			let baseName = driver.displayName + ' - ' + locale.getString('dashboard.copyName');
			let driverName = nameService.uniqueName(baseName, this.scope.driversList, 'displayName');
			this.scope.loading.promise = PromiseUtils.old(driversApi.copyDrivers(driverName, driver.id))
				.then(() => this.scope.reloadDrivers());
		}

		shareDrivers(drivers: DriversItem[]): ng.IPromise<void> {
			const isBulk = drivers.length > 1;
			let modalTitle = isBulk
				? locale.getString('drivers.bulkShareTitle')
				: locale.getString('drivers.shareTitle', {name: drivers[0].displayName});

			let shareFunc = (items, shareData) => PromiseUtils.old(driversApi.shareDrivers(items, shareData));
			let getAssetFunc = (id) => PromiseUtils.old(driversApi.getDriverById(id));
			let params: SharingParams = {
				modalTitle,
				share: shareFunc,
				getAsset: getAssetFunc,
				refreshGrid: this.scope.refreshGrid,
				component: 'driverSharing',
				objectTypePlural: 'drivers',
			};

			return sharingService.shareWithDialog(drivers, params);
		}

		deleteDrivers(driver: DriversItem): void {
			let dialog = cbDialogService.danger(
				locale.getString('drivers.deleteDriversTitle', {name: driver.displayName}),
				locale.getString('drivers.deleteDriversText', {name: driver.displayName}));

			dialog.result.then(() => {
				this.scope.loading.promise = PromiseUtils.old(driversApi.deleteDrivers(driver.id))
					.then(() => this.scope.reloadDrivers(), () => this.scope.reloadDrivers());
			});
		}

		bulkDeleteDrivers(driversConfigs: DriversTreeItem[]): void {
			let confirmationText = {
				title: locale.getString('drivers.bulkDeleteDriversTitle'),
				warning: locale.getString('drivers.bulkDeleteDriversText', {count: driversConfigs.length})
			};

			let deleteCallback = (configs) => PromiseUtils.old(driversApi.deleteDriversConfigs(configs));
			gridUpdateService.delete(driversConfigs, this.scope.driversList, deleteCallback, confirmationText).then(() => {
				driversConfigs.forEach((driversConfig) => {
					if (driversConfig.parent) {
						driversConfig.parent.selected = false;
					}
					globalNotificationService.addDeletedNotification(driversConfig.displayName);
				});

				this.scope.refreshGrid(driversConfigs);
			}, () => {});
		}

		createDrivers(folder?: IFolderItem): void {
			if (!this.validProjectSelection()) {
				this.scope.errors.noProjectSelected = true;
				return;
			}

			let name = nameService.uniqueName(locale.getString('drivers.driversName'), this.scope.driversList, 'displayName');
			let driversTemplate = this.scope.getDriversTemplate(this.scope.props.projectId);
			let newItem: DriversItem = angular.copy(driversTemplate);
			delete newItem.id;
			newItem.displayName = name;
			newItem.type = DriversType.drivers;
			if (folder) {
				newItem.parentId = folder.id as number;
			}

			_.extend(newItem, _.pick(this.scope.props, ['contentProviderId', 'accountId', 'projectId']));
			this.addProjectNameToDriver(newItem, this.scope.currentProjects);

			this.driversModal(newItem).then(this.dialogCallbackFunction(driversApi.createDrivers));
		}
		private addProjectNameToDriver = (driver: DriversItem, projects: Project[]): void => {
			let project: Project = _.findWhere(projects, {projectId: driver.projectId} as any);
			if (project) {
				driver.projectName = project.name;
			}
		};

		private driversModal = (driver: DriversItem, viewMode?: boolean, openSpotCheck?: boolean): any => {
			return $uibModal.open({
				component: 'driversEditor',
				windowClass: 'modal-md br-drivers-dialog',
				backdrop: 'static',
				resolve: {
					driversObject: () => {
						return driver;
					},
					itemList: () => {
						let others = _.filter(this.scope.driversList, {projectId: driver.projectId} as any);
						if (driver.id)
							others = _.reject(others, {id: driver.id} as any);
						return others;
					},
					viewMode: () => viewMode,
					openSpotCheck: () => openSpotCheck || false
				}
			}).result;
		};

		private dialogCallbackFunction = (apiFunction): any => {
			return (dialogResult: any): any => {
				let driversObject = dialogResult.data;
				let needRun = dialogResult.needRun;
				let promise = apiFunction(driversObject, dialogResult.models);
				if (needRun) {
					promise = promise.then((updated) => {
						this.scope.reloadDrivers(); // refresh in case of execution failure
						return driversApi.executeDrivers(updated.id);
					});
				}

				this.scope.loading.promise = promise;

				promise.then(() => {
					this.scope.reloadDrivers();
				});
			};
		};

		private validProjectSelection = (): any => {
			return ValueUtils.isSelected(this.scope.props.contentProviderId)
				&& ValueUtils.isSelected(this.scope.props.accountId)
				&& ValueUtils.isSelected(this.scope.props.projectId);
		};

		bulkTransferDrivers(driversConfigs: DriversItem[], projectIdentifier: ProjectIdentifier): Promise<void> {
			return downgradeDialogService.openBulkTransferDialog({
				selectedObjects: driversConfigs,
				mode: TransferGroup.DRIVERS,
				projectIdentifier
			}).result.then(result => {
				this.scope.loading.promise = transferApiService.makeTransfer(result) as unknown as ng.IPromise<void>;

				if (!result.retainEditPermission) {
					this.removeTransferredDrivers(driversConfigs);
				} else {
					this.updateTransferredDrivers(result, driversConfigs);
				}
				this.scope.refreshGrid(this.scope.driversList);
			});
		}

		private removeTransferredDrivers = (transferredDrivers: DriversTreeItem[]) => {
			let transferredDriversIds = _.pluck(transferredDrivers, 'id');
			this.scope.driversList = _.reject(this.scope.driversList, driversConfig => {
				return _.contains(transferredDriversIds, driversConfig.id);
			});
		};

		private updateTransferredDrivers = (result, transferredDrivers: DriversItem[]) => {
			let masterAccountItems = result.transferItems;
			transferredDrivers.forEach(driversConfig => {
				let currentMasterAccountFilters: any[] = masterAccountItems[driversConfig.masterAccountId][TransferGroup.DRIVERS];
				let newOwnerId = _.findWhere(currentMasterAccountFilters, {id: driversConfig.id}).selectedCandidateId;
				driversConfig.ownerId = newOwnerId;
				driversConfig.sharingStatus = SharingStatus.SHARED;
				driversConfig.permissionLevel = 'EDIT';
			});
		};

		renameFolder(folder: IDriversFolder): void {
			this.folderSvc.renameFolder(folder, this.scope.driversList).then(() => {
				folder.displayName = folder.name;
				this.scope.refreshGrid(folder);
			}).catch(_.noop);
		}

		removeFolder(folder: IDriversFolder): void {
			this.folderSvc.removeFolder(folder, this.scope.driversList)
				.then((children) => this.scope.refreshGrid(children))
				.then(() => globalNotificationService.deleteFolderNotification(folder.name));
		}

		createFolder(parent: IDriversFolder): void {
			this.folderSvc.createAccountFolder(parent,
				this.scope.driversList,
				this.scope.props.contentProviderId,
				this.scope.props.accountId)
			.then((created) => {
				this.scope.refreshGrid([parent, created]);
			});
		}

		moveToFolder(item: DriversTreeItem, folderTo: IDriversFolder): void {
			if (!folderTo) {
				return;
			}

			let parent = item.parent;
			driversFolderApi.moveToFolder(item, folderTo.id).then(() => {
				if (!folderTo.id || folderTo.id === 0)
					folderTo = null;

				treeService.moveItem(this.scope.driversList, item, folderTo);

				let refreshItems = [item, folderTo, parent];
				refreshItems = refreshItems.concat(this.traverseFolderChain(parent as DriversTreeItem));
				refreshItems = refreshItems.concat(this.traverseFolderChain(item));

				this.scope.refreshGrid(refreshItems);
			}).then(() => globalNotificationService.addItemMovedNotification(item.displayName, folderTo));
		}

		private traverseFolderChain = (item: DriversTreeItem): any[] => {
			let levels = [];
			let currentLevel = item ? item.parent : undefined;
			while (currentLevel) {
				levels.push(currentLevel);
				currentLevel = currentLevel.parent;
			}
			return levels;
		};

		toggleHide(item: DriversItem): void {
			item.hide = !item.hide;
			let key = item.id.toString();
			securityApiService.hideObjectForUser(HiddenItemType.DRIVERS, item.hide, key, item.displayName)
				.then(() => globalNotificationService.addVisibilityChangedNotification(item.displayName, item.hide));
			if (item.hide) {
				//if it is being hidden, add tag to grey it out
				TaggingHelper.tag(item, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenDrivers[key] = true;
			} else {
				TaggingHelper.untag(item, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenDrivers[key] = false;
			}

			cachedHttpService.cache(Caches.DRIVERS).invalidate();
			this.scope.selectionUtils.clearSelections();
		}
	};
});
