import { TableColumn } from '@cxstudio/reports/entities/table-column';
import * as _ from 'underscore';

class ResizableTableController implements ng.IComponentController {

	columns: Array<TableColumn<any>>;
	rows: any[];
	onResize: (columns: Array<TableColumn<any>>) => void;
	options: any;

	constructor(
		private $element: JQueryStatic
	) {}

	$onInit(): void {
		this.options = {
			onResizeEnded: this.onResizeEnded
		};
	}

	onResizeEnded = (columnElement: any): void => {
		this.recalculateWidths();
		if (this.onResize)
			this.onResize(this.columns);
	};

	recalculateWidths = (): void => {
		let widths = [];
		$(this.$element).find('th.resizable-header').each((index, elem) => {
			widths[index] = $(elem).outerWidth();
		});
		let tableWidth = $(this.$element).width();
		_.each(this.columns, (column, index) => {
			column.width = widths[index] / tableWidth;
		});
	};
}

app.component('resizableTable', {
	bindings: {
		columns: '<',
		rows: '<',
		onResize: '<',
		onRowClick: '&'
	},
	controller: ResizableTableController,
	templateUrl: 'partials/widgets/components/resizable-table.html',
});
