import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Book } from '@cxstudio/dashboards/entity/book';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { Security } from '@cxstudio/auth/security-service';

import * as _ from 'underscore';

@Injectable({
	providedIn: 'root'
})
export class BookListHelperService {

	constructor(@Inject('security') private security: Security) {}

	static isBookAllowedItem = (book: Book, item: Dashboard, currentLogin: string): boolean => {
		if (item.type === DashboardType.BOOK) return false;
		if (item.type === DashboardType.FOLDER) return false;
		let dashboard: Dashboard = item;

		// old dashboards without the property should be prevented by default
		let dashboardPreventedFromSharing: boolean = dashboard.properties
			&& (isTrue(dashboard.properties.preventDashboardEditorsShare)
				|| dashboard.properties.preventDashboardEditorsShare === undefined);

		/**
		 * this follows requirement hell from series of tickets,
		 * carefully make sure it passes all tests from dashboardListCtrlSpec.js
		 */
		let owningBook: boolean = currentLogin === book.ownerName;
		let canEditBook: boolean = book.permissions && book.permissions.EDIT;
		let owningDashboard: boolean = currentLogin === dashboard.ownerName;
		let canEditDashboard: boolean = dashboard.permissions && dashboard.permissions.EDIT;

		let canAddOwnedDashboardInOwnedBook = true;
		let canAddOwnedDashboardInSharedBook = !dashboardPreventedFromSharing;
		let canAddOwnedDashboard: boolean = (owningBook && canAddOwnedDashboardInOwnedBook)
			|| (canEditBook && canAddOwnedDashboardInSharedBook);

		let canAddSharedDashboardInAnyAvailableBook: boolean = !dashboardPreventedFromSharing;
		let canAddSharedDashboard: boolean = (canEditBook || owningBook) && canAddSharedDashboardInAnyAvailableBook;

		return (owningDashboard && canAddOwnedDashboard)
			|| (canEditDashboard && canAddSharedDashboard);
	};

	markBookDisallowedDashboards = (book: Book, dashboards: Dashboard[]): Dashboard[] => {
		let itemsToRefresh = [];
		_.forEach(dashboards, (dashboard) => {
			if (dashboard.type !== DashboardType.FOLDER
				&& !BookListHelperService.isBookAllowedItem(book, dashboard as Dashboard, this.security.getEmail())) {
				TaggingHelper.tag(dashboard, TaggingHelper.tags.BOOK_DISALLOWED);
				itemsToRefresh.push(dashboard);
			}
		});

		return itemsToRefresh;
	};

	markConnectedDashboard = (dashboardId: number, dashboards: Dashboard[], callback) => {
		let connectedTag = TaggingHelper.tags.CONNECTED;
		let itemsToRefresh = [];
		_.each(dashboards, dashboard => {
			if (TaggingHelper.hasTag(dashboard, connectedTag)) {
				TaggingHelper.untag(dashboard, connectedTag);
				TaggingHelper.untag(dashboard, TaggingHelper.tags.DASHBOARD_ID(dashboard.id));
				itemsToRefresh.push(dashboard);
			}
			if (dashboard.id === dashboardId) {
				TaggingHelper.tag(dashboard, connectedTag);
				TaggingHelper.tag(dashboard, TaggingHelper.tags.DASHBOARD_ID(dashboardId));
				itemsToRefresh.push(dashboard);
			}
		});
		if (!_.isEmpty(itemsToRefresh))
			callback(itemsToRefresh);
	};

}

app.service('bookListHelperService', downgradeInjectable(BookListHelperService));
