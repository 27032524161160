import { DatePointMode } from '@cxstudio/reports/settings/date-point-constants';
import { DatePeriodConstantsService, DatePeriodType } from '@app/modules/utils/dates/date-period-constants.service';
import { IDateRuleDefinition } from '@cxstudio/reports/entities/adhoc-filter.class';
import * as moment from 'moment';

export class DateFilterUtils {

	constructor(private datePeriodConstants: DatePeriodConstantsService) {}

	getDateRulePeriodLengthInYears = (ruleDefinition: IDateRuleDefinition): number => {
		return ruleDefinition.fromMode === DatePointMode.CUSTOM && ruleDefinition.toMode === DatePointMode.CUSTOM
			? this.getDifferenceForCustom(ruleDefinition)
			: this.getDifference(ruleDefinition);
	};

	private getDifferenceForCustom(ruleDefinition: IDateRuleDefinition): number {
		let days = moment(ruleDefinition.toDate).diff(moment(ruleDefinition.fromDate), 'days');
		return days * this.datePeriodConstants.getPeriod(DatePeriodType.DAY).cost;
	}

	private getDifference(ruleDefinition: IDateRuleDefinition): number {
		let fromPeriod = this.datePeriodConstants.getPeriod(ruleDefinition.fromPeriod);
		let toPeriod = this.datePeriodConstants.getPeriod(ruleDefinition.toPeriod);

		if (fromPeriod.index > toPeriod.index) {
			return this.getDifferenceForPreviousPeriod(ruleDefinition);
		}

		let from = this.convertToYears(ruleDefinition.fromPeriod, ruleDefinition.fromAmount);
		let toAmount = ruleDefinition.toMode === DatePointMode.TODAY ? 0 : ruleDefinition.toAmount;
		let to = this.convertToYears(ruleDefinition.toPeriod, toAmount);
		return from - to;
	}

	private getDifferenceForPreviousPeriod(ruleDefinition: IDateRuleDefinition): number {
		let fromAmount = ruleDefinition.fromAmount === 0 ? 1 : ruleDefinition.fromAmount;
		let toAmount = ruleDefinition.toAmount === 0 ? 1 : ruleDefinition.toAmount;
		return this.convertToYears(ruleDefinition.fromPeriod, fromAmount) - this.convertToYears(ruleDefinition.toPeriod, toAmount);
	}

	private convertToYears(period: DatePeriodType, amount: number): number {
		let unit = this.datePeriodConstants.getPeriod(period).cost;
		return amount * unit;
	}

}

app.service('dateFilterUtils', DateFilterUtils);
