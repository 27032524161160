
import * as uib from 'angular-ui-bootstrap';
import ExternalApplicationType from '@cxstudio/system-administration/external-applications/external-application-type';
import ExternalApplication from '@cxstudio/system-administration/external-applications/external-application';
import EngageApplication from './engage-application';
import PasswordProtectedApplication from './password-protected-application';
import OAuthClientDetails from '@app/modules/system-administration/oauth/oauth-client-details';
import { ExternalApplicationTypeUtils } from './external-application-type-utils';
import {
	OAuthClientDetailsApiService
} from '@app/modules/system-administration/oauth/service/oauth-client-details-api.service';
import { PromiseUtils } from '@app/util/promise-utils';

interface RegionOption {
	name: string;
}

class ExternalApplicationEditDialogController {

	loading: ng.IPromise<any>;

	applicationTypes: {value: string; name: string}[];
	engagorRegions: RegionOption[];
	suiteClients: OAuthClientDetails[];

	constructor(
			private $scope: ng.IScope,
			private application: ExternalApplication,
			private $uibModalInstance: uib.IModalInstanceService,
			private oauthClientDetailsApiService: OAuthClientDetailsApiService) {
		this.$scope.$ctrl = this;
	}

	$onInit = () => {
		this.$scope.application = this.application;

		this.application = angular.copy(this.application);

		this.cleanupPasswordApplication();
		this.initApplicationTypes();
		this.initEngagorRegions();
		this.loading = this.loadSuiteApplications();
	};

	private cleanupPasswordApplication = (): void => {
		if (this.isPasswordProtectedApplication()) {
			let application = this.application as PasswordProtectedApplication;
			application.password = 'Password';
		}
	};

	private initApplicationTypes = (): void => {
		this.applicationTypes = ExternalApplicationTypeUtils.getTypeOptions();
	};

	private initEngagorRegions = (): void => {
		this.engagorRegions = [
			{ name: 'EU' },
			{ name: 'US' }
		];
	};

	private loadSuiteApplications = (): ng.IPromise<any> => {
		return PromiseUtils.old(this.oauthClientDetailsApiService.getSuiteClientDetailsList())
			.then((suiteClients: OAuthClientDetails[]) => {
				this.suiteClients = suiteClients;
			});
	};

	isSupportingSuiteClientSelection = (): boolean => {
		return this.application.type === ExternalApplicationType.CONNECTORS
			|| this.application.type === ExternalApplicationType.ENGAGE
			|| this.application.type === ExternalApplicationType.NARRATIVE;
	};

	isPasswordProtectedApplication = (): boolean => {
		let type = this.application.type;
		return type === ExternalApplicationType.NFS || type === ExternalApplicationType.VTT
			|| type === ExternalApplicationType.TUNING_SUGGESTION || type === ExternalApplicationType.NARRATIVE;
	};

	cancel = () => {
		this.$uibModalInstance.dismiss();
	};

	save = () => {
		if (!_.isUndefined(this.$scope.applicationDialog.password)
			&& !this.$scope.applicationDialog.password.$dirty) {
				let application = this.application as PasswordProtectedApplication;
				delete application.password;
		}
		this.$uibModalInstance.close(this.application);
	};

	disableSave = () => {
		return this.$scope.applicationDialog.$invalid;
	};

	setEngagorRegion = (region: RegionOption) => {
		let application = this.application as EngageApplication;
		application.region = region.name;
	};

	setSuiteClient = (client: OAuthClientDetails) => {
		this.application.suiteClientId = client ? client.clientId : null;
	};
}

app.controller('ExternalApplicationEditDialogController', ExternalApplicationEditDialogController);
