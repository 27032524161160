import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import { ISettingsGroup } from './settings-group.interface';
import { ObjectUtils } from '@app/util/object-utils';

export type AggregationSettings = {
	calculationType : CalculationFunction;
};

export class AggregationSettingsGroup implements ISettingsGroup<AggregationSettings> {
	settings: AggregationSettings;
	defaultSettings: AggregationSettings;

	constructor(source: AggregationSettings) {
		this.defaultSettings = {calculationType: source?.calculationType ?? CalculationFunction.AVG};

		this.settings = ObjectUtils.copy(this.defaultSettings);
	}

	postProcessSettings(): AggregationSettings {
		return this.settings;
	}

	resetDefaultSettings(): void {
		this.settings = this.defaultSettings;
	}
}
