import Widget from '@cxstudio/dashboards/widgets/widget';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { HierarchyEnrichmentValue, MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';

export class MetricComparisonUtils {

	static getComparisonDefaults(type: MetricComparisonType, enrichments?: HierarchyEnrichmentValue[]): MetricWidgetComparison {
		switch (type) {
			case MetricComparisonType.TIME: return this.getTimeComparisonDefaults();
			case MetricComparisonType.HIERARCHY_ENRICHMENT: return this.getHierarchyEnrichmentDefaults(enrichments);
			case MetricComparisonType.GOAL: return this.getGoalComparisonDefaults();
			default: return undefined;
		}
	}

	private static getTimeComparisonDefaults(): MetricWidgetComparison {
		let dateFilter: DateFilter = {
			dateFilterMode: HistoricDateFilterMode.PREVIOUS_PERIOD,
		};
		return {
			type: MetricComparisonType.TIME,
			calculation: PeriodOverPeriodMetricType.DELTA,
			value: dateFilter,
			format: {} as any,
		} as MetricWidgetComparison;
	}

	private static getHierarchyEnrichmentDefaults(enrichments: HierarchyEnrichmentValue[]): MetricWidgetComparison {
		return {
			type: MetricComparisonType.HIERARCHY_ENRICHMENT,
			calculation: PeriodOverPeriodMetricType.DELTA,
			value: enrichments[0],
			inheritFormatFromMetric: true,
			format: {
				truncation: Truncation.NONE,
				decimals: Decimals.NONE,
				conversion: MetricMultiplierType.NONE,
			}
		} as MetricWidgetComparison;
	}

	private static getGoalComparisonDefaults(): MetricWidgetComparison {
		return {
			type: MetricComparisonType.GOAL,
			calculation: PeriodOverPeriodMetricType.DELTA,
			value: {goal: 0},
			inheritFormatFromMetric: true,
			format: {
				truncation: Truncation.NONE,
				decimals: Decimals.NONE,
				conversion: MetricMultiplierType.NONE,
			}
		} as MetricWidgetComparison;
	}

	static getComparisonsFromProperties(props: MetricWidgetProperties, visualProps: VisualProperties): MetricWidgetComparison[] {
		if (!_.isEmpty(props.comparisons))
			return props.comparisons;
		if (!props.useHistoricPeriod)
			return [];
		let sourceMetric = props.selectedMetrics.length === 1 ? props.selectedMetrics[0] : undefined;
		return [{
			identifier: `comparison_c0_time`,
			type: MetricComparisonType.TIME,
			calculation: sourceMetric?.popField || PeriodOverPeriodMetricType.DELTA,
			value: props.dateRangeP2,
			label: visualProps.periodLabel.period_2_,
			format: {} as any,
		}];
	}

	static removeEnrichmentComparisons(widgets: Widget[]): void {
		_.chain(widgets)
			.filter(widget => widget.properties.widgetType === WidgetType.METRIC)
			.each(widget => {
				let properties: MetricWidgetProperties = widget.properties as MetricWidgetProperties;
				properties.comparisons = this.filterComparisons(properties.comparisons, MetricComparisonType.HIERARCHY_ENRICHMENT, false);
				if (properties.comparisons.length === 0) {
					delete widget.visualProperties.previousPeriod;
				}
			});
	}

	static getHierarchyEnrichmentComparison(comparisons: MetricWidgetComparison[]): MetricWidgetComparison {
		return _.first(
			this.filterComparisons(comparisons, MetricComparisonType.HIERARCHY_ENRICHMENT, true)
		);
	}

	private static filterComparisons = (
		comparisons: MetricWidgetComparison[], type: MetricComparisonType, present: boolean
	): MetricWidgetComparison[] => {
		return _.filter(comparisons, comparison => {
			return present
				? comparison.type === type
				: comparison.type !== type;
		});
	};

	static isHierarchyEnrichmentPropertyAvailable(
		availableEnrichments: HierarchyEnrichmentValue[], enrichment: HierarchyEnrichmentValue
	): boolean {
		return !!_.find(availableEnrichments, availableEnrichment => {
			return availableEnrichment.propertyName === enrichment.propertyName
				&& availableEnrichment.hierarchy.id === enrichment.hierarchy.id;
		});
	}
}
