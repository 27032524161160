import * as _ from 'underscore';
import { Component, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { OnInit } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ISelectableItem } from '@cxstudio/common/entities/selectable-item';

export interface SelectableItem extends ISelectableItem {
	id: number;
	displayName: string;
}

@Component({
	selector: 'select-items',
	templateUrl: './select-items.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SelectItemsComponent<T extends SelectableItem> implements OnInit {

	@Input() topDisplayText: string;
	@Input() objectAvailableDescriptor: string;
	@Input() objectSelectedDescriptor: string;
	@Input() orderByField: string;
	@Input() itemsField: string;
	@Input() itemsFieldIdentifier: string;
	@Input() outerObject: {[key: string]: T[]};

	itemsFilter: string;
	availableItems: T[];

	selectedItemFilter: string;
	selectedItems: T[];

	constructor(
		private locale: CxLocaleService
	) {}

	ngOnInit(): void {
		this.itemsFilter = '';
		this.selectedItemFilter = '';
	}

	selectAllItems(): void {
		let items: T[] = this.outerObject[this.itemsField];

		if (this.itemsFilter) {
			items = _.filter(items, item => item.displayName.contains(this.itemsFilter));
		}

		items.forEach((item: T) => item.selected = true);
	}

	deselectAllItems(): void {
		let items: T[] = this.outerObject[this.itemsField];

		if (this.selectedItemFilter) {
			items = _.filter(items, item => item.displayName.contains(this.selectedItemFilter));
		}

		items.forEach((item: T) => item.selected = false);
	}

	getSelectedItems(): T[] {
		if (!this.outerObject || !this.outerObject[this.itemsField]) {
			return [];
		}
		return this.outerObject[this.itemsField].filter(this.selectedItemsFilter) || [];
	}

	selectedItemsFilter(item: any): boolean {
		return item.selected;
	}

	allowSelectItems(): boolean {
		return this.getFilteredAvailableItems().length > 0;
	}

	getFilteredAvailableItems(): T[] {
		return _.filter(this.outerObject[this.itemsField], item => item.displayName.contains(this.itemsFilter) && !item.selected);
	}

	getSortedFilteredAvailableItems(): T[] {
		return _.sortBy(this.getFilteredAvailableItems(), this.orderByField);
	}

	getFilteredSelectedItems(): T[] {
		return _.filter(this.outerObject[this.itemsField], item => item.displayName.contains(this.selectedItemFilter) && item.selected);
	}

	getSortedFilteredSelectedItems(): T[] {
		return _.sortBy(this.getFilteredSelectedItems(), this.orderByField);
	}

	addButtonText(): string {
		return this.itemsFilter?.length
			? this.locale.getString('common.addAllMatches')
			: this.locale.getString('common.addAll');
	}

	removeButtonText(): string {
		return this.selectedItemFilter.length
			? this.locale.getString('common.removeAllMatches')
			: this.locale.getString('common.removeAll');
	}

	toggleSelections(toggledItemsIdentifiers: T[]): void {
		if (_.isEmpty(toggledItemsIdentifiers)) {
			return;
		}

		toggledItemsIdentifiers.forEach(toggledItemsIdentifier => {
			let toggledItem: T = _.find(this.outerObject[this.itemsField], item => {
				return item[this.itemsFieldIdentifier] === +toggledItemsIdentifier;
			});
			toggledItem.selected = !toggledItem.selected;
		});
	}

	allowDeselectItems(): boolean {
		if (!this.outerObject[this.itemsField]) {
			return false;
		}

		return this.getSelectedItems().length > 0;
	}

}

app.directive('selectItems', downgradeComponent({component: SelectItemsComponent}));
