import { AfterViewInit, Directive, ElementRef, HostListener } from '@angular/core';
import { KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { Key } from '../util/keyboard-utils.class';

@Directive({
	selector: '[ngbNav].nav-tabs'
})

export class TabKeyHandlerDirective implements AfterViewInit {

	constructor(
		readonly el: ElementRef<HTMLElement>,
	) { }

	ngAfterViewInit(): void {
		this.setCurrentTabFocusable();
	}

	/**
	 * Remove all tabs from focus order, except for currently open tab
	 */
	private setCurrentTabFocusable(tabElement?) {
		let currentTab = tabElement || this.el.nativeElement.querySelector('.nav-link.active');

		let tabs = this.el.nativeElement.querySelectorAll('.nav-link');

		$(tabs).attr({tabindex: '-1'});
		$(currentTab).attr({tabindex: 0});
	}

	// need to update tabindex on all tabs if one of them is clicked
	@HostListener('mousedown', ['$event'])
	onClick(event: MouseEvent): void {
		if (!$(event.target).hasClass('nav-link')) {
			return;
		}

		this.setCurrentTabFocusable(event.target);
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(event: KeyboardEvent): void {
		// only necessary if we're focused on tabs
		if (!$(event.target).hasClass('nav-link')) {
			return;
		}

		let tabs = this.el.nativeElement.querySelectorAll('.nav-link');
		if (event.key === Key.ENTER) {
			this.setCurrentTabFocusable(event.target);
		} else if (event.key === Key.HOME) {
			$(tabs[0]).trigger('focus');
			KeyboardUtils.intercept(event);
		} else if (event.key === Key.END) {
			$(tabs[tabs.length - 1]).trigger('focus');
			KeyboardUtils.intercept(event);
		} else if (_.contains([Key.LEFT, Key.RIGHT], event.key)) {
			let currentTab = document.activeElement;
			let tabIndex = _.indexOf(tabs, currentTab);
			let targetIndex = tabIndex + (event.key === Key.RIGHT ? 1 : -1);

			if (targetIndex < 0) {
				targetIndex = tabs.length - 1;
			}
			
			if (targetIndex >= tabs.length) {
				targetIndex = 0;
			}

			$(tabs[targetIndex]).trigger('focus');
		}
	}
}
