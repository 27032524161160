import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, OnInit, Input, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { SystemAdminApiService } from '@cxstudio/services/data-services/system-admin-api.service';
import MasterAccountDisableData from '@app/modules/system-administration/master-account/entities/master-account-disable-data';
import { PromiseUtils } from '@app/util/promise-utils';

export interface MasterAccountDisableDialogInput {
	masterAccount: MasterAccount;
}

export interface IExtendedUser extends User {
	linkedMasterAccounts;
	selectedDefaultMasterAccountId: number;
}

@Component({
	selector: 'master-account-disable-dialog',
	templateUrl: './master-account-disable-dialog.component.html'
})

export class MasterAccountDisableDialogComponent implements OnInit {
	@Input() input: MasterAccountDisableDialogInput;

	loading: Promise<any>;
	disableNote: string;

	users: IExtendedUser[];
	masterAccount: MasterAccount;
	masterAccountsMap = {};

	private disableOption: any;

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('systemAdminApiService') private systemAdminApiService: SystemAdminApiService,
	) {
	}

	ngOnInit(): void {
		this.masterAccount = cloneDeep(this.input.masterAccount);
		this.disableOption = {
			accountId: -1,
			accountName: this.locale.getString('common.disable').toUpperCase(),
			special: true
		};

		this.loading = PromiseUtils.wrap(this.systemAdminApiService.getAllMasterAccounts()).then(response => {
			for (let account of response.data) {
				this.masterAccountsMap[account.accountId] = account;
			}

			return this.reloadUserList();
		});
	}

	hasNoAssociatedUsers(): boolean {
		return _.isEmpty(this.users);
	}

	private populateDisableNote(): void {
		this.disableNote = this.hasNoAssociatedUsers()
			? this.locale.getString('mAccount.disableNoteSingle', { name: this.masterAccount.accountName })
			: this.locale.getString('mAccount.disableNote');
	}

	private reloadUserList(): Promise<void> {
		let properties = this.createQueryParameters();
		let selectedMasterAccountId = this.masterAccount.accountId;
		return PromiseUtils.wrap(this.systemAdminApiService.getAllUsersPaged(properties)).then((result) => {
			this.users = result.data.data.filter(user => Object.keys(user.masterAccountData).length > 1);

			this.users.forEach(user => {
				user.linkedMasterAccounts = _.chain(Object.keys(user.masterAccountData))
					.map((masterAccountId) => this.masterAccountsMap[masterAccountId])
					.filter(masterAccount => {
						if (!masterAccount || masterAccount.accountId === selectedMasterAccountId) return false;
						return user.systemAdmin || user.liteAdmin ? masterAccount.adminOrg : true;
					})
					.value();

				user.selectedDefaultMasterAccountId = this.disableOption.accountId;
				user.linkedMasterAccounts.unshift(this.disableOption);
			});
			this.populateDisableNote();
		});
	}

	private createQueryParameters = () => {
		return {
			params: {
				isPaginated: false,
				filterText: this.masterAccount.accountId,
				filterField: 'defaultMA',
				sortDirection: 'asc',
				sortField: 'userEmail',
				withTotal: true
		}};
	};

	finish(): void {
		let userDefaultMasterAccountMap = _.chain(this.users)
			.filter(user => user.selectedDefaultMasterAccountId > 0)
			.map(user => [user.userId, user.selectedDefaultMasterAccountId])
			.value();

		this.modal.close(new MasterAccountDisableData(this.masterAccount, _.object(userDefaultMasterAccountMap)));
	}

	cancel(): void {
		this.modal.dismiss();
	}
}
