import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { AppPage } from '@app/util/app-page';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';

export class RedirectService {

	constructor(
		private $window: ng.IWindowService,
		private urlService: UrlService,
		private $location: ng.ILocationService,
		private userPropertiesApiService: UserPropertiesApiService
	) {}

	getRedirectionUrl = (destination) => {
		return this.urlService.getAPIUrl(destination.url);
	};

	getRedirectionUrlWithParams = (destination, parameters) => {
		return this.getRedirectionUrl(destination) + '?' + $.param(parameters);
	};

	saveCurrentMA = (newMasterAccount, preserveResourceLink?: boolean) => {
		let properties = {
			last_master_account: newMasterAccount.accountId + ''
		};
		this.userPropertiesApiService.updateProperties(properties).then(() => {
			if (!preserveResourceLink && this.isOnMasterAccountSpecificResource()) {
				this.$window.open('/dashboard/', '_self');
			} else {
				this.$window.location.reload();
			}
		});
	};

	private isOnMasterAccountSpecificResource = (): boolean => {
		let isOnBook = !_.isUndefined(this.$location.search().tab);
		let isOnDashboard = this.$location.path().startsWith('/home');
		return isOnBook || isOnDashboard;
	};

	goToDashboardList = (): void => {
		this.$location.path('/dashboards');
		this.$location.search({});
	};

	goToDashboard = (dashboardId: string | number): void => {
		this.$location.path(`/home/${dashboardId}`);
	};

	goToBook = (bookId: string | number): void => {
		this.$location.path(`/dashboard/${bookId}`);
	};

	goToNewBook = (parentFolder?: number): void => {
		let params = {needRedirect: true} as any;
		if (!_.isUndefined(parentFolder))
			params.folder = parentFolder;
		this.$location.url('/books/new').search(params);
	};

	goToBookEditor = (bookId: number, redirectToView?: boolean): void => {
		this.$location.url(`/books/${bookId}/editor`);
		if (redirectToView)
			this.$location.search({needRedirect: true});
	};

	isOnDashboardList = (): boolean => {
		return this.$location.path().startsWith('/dashboards');
	};

	goToLogin = (): void => {
		this.$location.path(AppPage.LOGIN);
	};

	goToUserUpload = (taskId?: string): void => {
		this.$location.path('/user-upload').search({ taskId });
	};

	goToUserBulkUpdate = (taskId?: string): void => {
		this.$location.path('/user-bulk-update').search({ taskId });
	};

	goToUserManagement = (): void => {
		this.$location.path('/user-group-management');
	};

	goToRequestAccess = (objectType: ObjectType, objectId: number): void => {
		this.$location.path(`/request-access/${objectType}/${objectId}`);
	};
}

app.service('redirectService', RedirectService);
