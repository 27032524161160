import * as _ from 'underscore';

import { Input, EventEmitter, Output, OnInit, Component, ViewChild, AfterViewInit, ElementRef, ChangeDetectionStrategy } from '@angular/core';

import { downgradeComponent } from '@angular/upgrade/static';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { ChangeDetectorRef } from '@angular/core';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ILinkRestrictionNode } from './unified-link-visibility.component';
import { CxLocaleService } from '@app/core';

interface SelectFromTreeParams {
	node: INode;
}

@Component({
	selector: 'visibility-restriction-selector',
	templateUrl: './visibility-restriction-selector.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class VisibilityRestrictionSelectorComponent implements OnInit, AfterViewInit {

	@Input() hierarchyList: INode[];
	@Input() displayProperty: string;
	@Input() placeholder: string;
	@Input() searchPlaceholder: string;
	@Input() label: string;
	@Input() disabled: boolean;
	@Input() opened: boolean;
	@Input() folderClickIgnore: boolean;

	@Input() showNodeCheckbox: (node: INode) => boolean;
	@Input() nodeIsChecked: (node: INode) => boolean;
	@Input() displayName: string;
	@Input() nodeIsMarked:(node: ILinkRestrictionNode) => boolean;
	@Input() nodeCheckboxDisabled: (node: ILinkRestrictionNode) => boolean;

	@Output() onNodeClick = new EventEmitter<SelectFromTreeParams>();
	@Output() onClear = new EventEmitter<void>();

	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;
	@ViewChild('ngDropdownToggle', {static: false}) dropdownToggle: ElementRef;
	
	constructor(
		private locale: CxLocaleService,
		private ref: ChangeDetectorRef
	) {}

	ngOnInit(): void {
	
		if (!this.searchPlaceholder) {
			this.searchPlaceholder = this.locale.getString('common.find');
		}
		if (!this.displayProperty) {
			this.displayProperty = 'displayName';
		}
		if (!this.nodeIsChecked) {
			this.nodeIsChecked = () => false;
		}
		if (!this.showNodeCheckbox) {
			this.showNodeCheckbox = () => false;
		}
		if (!this.nodeCheckboxDisabled) {
			this.nodeCheckboxDisabled = () => false;
		}
	}

	ngAfterViewInit(): void {
		if (this.opened) {
			this.dropdown.open();
			this.ref.detectChanges();
		}
	}

	updateValue = (node: INode) => {
		this.onNodeClick.emit({node});
	};

	isOpen(): boolean {
		return this.dropdown?.isOpen();
	}

	closePopup = (): void => {
		this.dropdown.close();
		setTimeout(() => { this.dropdownToggle.nativeElement.focus(); }, 0);
	};
	
	getSelectedItem = (): ILinkRestrictionNode => {
		return undefined;
	};

}

