import { Component, OnInit, ViewChild } from '@angular/core';
import { OrganizationApiService } from './organization-api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core';
import { UntypedFormGroup } from '@angular/forms';
import { FormValidationService } from '@app/shared/services/form-validation.service';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { ListOption } from '@app/shared/components/forms/list-option';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';

export interface NewHierarchyProperties {
	hierarchyName?: string;
	hierarchyMode?: HierarchyMode;
}

@Component({
	selector: 'hierarchy-delta-publishing-dialog',
	template: `

	<modal-header
		[modalTitle]="getModalTitle()"
		(cancel)="cancel()">
	</modal-header>

	<div class="modal-body">
		<div class="tab-content">
			<form name="newHierarchyForm" class="form-horizontal" #newHierarchyForm="ngForm">
				<div class="row form-group">
					<label class="control-label col-md-4">{{'organization.orgNameLabel'|i18n}}</label>
					<div class="col-md-12">
						<input class="form-control" 
							name="organizationName"
							#organizationName="ngModel"
							[(ngModel)]="properties.hierarchyName"
							[maxLength]="150"
							[minLength]="1"
							[ngModelOptions]="{ debounce: 500 }"
							(ngModelChange)="validateOrganizationName()"
							focus-on-render
							required>
					</div>
				</div>
				<div class="row form-group">
					<label class="control-label col-md-4" for="">{{'organization.selectHierarchyType'|i18n}}
						<cb-inline-help>
							<help-body>{{'organization.selectHierarchyTypeHint'|i18n}}</help-body>
						</cb-inline-help>
					</label>
					<div class="col-md-12">
						<simple-dropdown
							[(value)]="properties.hierarchyMode"
							[options]="hierarchyModeOptions"
							[disableSort]="true">
						</simple-dropdown>
					</div>
				</div>
				<div class="row">
					<div class="col-md-12">
						<alert type="danger" *ngIf="hasErrors()">
							<p *ngIf="hasDuplicateName()">{{'organization.organizationexist'|i18n}}</p>
							<p *ngIf="hasForbiddenCharacters()">{{'organization.organizationNameForbiddenCharacters'|i18n}}</p>
							<p *ngIf="hasInvalidName()">{{'organization.organizationNameNotValid'|i18n}}</p>
						</alert>
					</div>
				</div>

			</form>
		</div>
	</div>

	<save-modal-footer
		(cancel)="cancel()"
		(save)="save()"
		[saveText]="'common.save' | i18n"
		[cancelText]="'common.cancel' | i18n"
		[isDisabled]="loading || hasErrors()">
	</save-modal-footer>
	`
})

export class NewOrganizationDialogComponent implements OnInit {

	private HIERARCHY_PARAMETER_DELIMITER: string = '>>';

	loading: Promise<any>;
	organizationNameExists: boolean;
	organizationNameForbiddenCharacters: boolean;

	private formValidation: FormValidationService;

	hierarchyModeOptions: ListOption<HierarchyMode>[];

	properties: NewHierarchyProperties;
	@ViewChild('newHierarchyForm', { static: true }) hierarchyForm: UntypedFormGroup;

	constructor(
		private organizationApiService: OrganizationApiService,
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
	) { }

	ngOnInit(): void {
		this.organizationNameExists = false;
		this.properties = { hierarchyName: '', hierarchyMode: HierarchyMode.CLASSIFICATION };
		this.formValidation = new FormValidationService(this.hierarchyForm);

		this.hierarchyModeOptions = [{
			name: this.locale.getString('organization.classicType'),
			value: HierarchyMode.CLASSIFICATION,
		}, {
			name: this.locale.getString('organization.dynamicFilteringType'),
			value: HierarchyMode.DYNAMIC_FILTERING,
		}];
	}

	getModalTitle(): string {
		return this.locale.getString('organization.addOrgHierarchy');
	}

	cancel(): void {
		this.modal.dismiss();
	}

	save(): void {
		this.modal.close(this.properties);
	}

	hasForbiddenCharacters(): boolean {
		return this.formValidation.hasError('organizationName', 'forbiddenCharacters');
	}

	hasInvalidName(): boolean {
		return this.formValidation.hasError('organizationName', 'invalidHierarchyName') ||
			this.formValidation.hasError('organizationName', 'required');
	}

	hasDuplicateName(): boolean {
		return this.formValidation.hasError('organizationName', 'organizationNameExists');
	}

	hasErrors(): boolean {
		return !this.hierarchyForm.valid;
	}

	validateOrganizationName(): void {
		if (this.properties.hierarchyName) {
			let hasForbiddenCharacters: boolean = this.properties.hierarchyName.contains(this.HIERARCHY_PARAMETER_DELIMITER);
			this.formValidation.updateControlErrors('organizationName', 'forbiddenCharacters', hasForbiddenCharacters);
			this.formValidation.updateControlErrors('organizationName', 'invalidHierarchyName', false);
			this.loading = this.organizationApiService.organizationExists({ name: this.properties.hierarchyName } as Hierarchy).then((response) => {
				this.formValidation.updateControlErrors('organizationName', 'organizationNameExists', response.data.exist);
				this.loading = null;
			});
		} else {
			this.formValidation.updateControlErrors('organizationName', 'invalidHierarchyName', true);
			this.formValidation.updateControlErrors('organizationName', 'organizationNameExists', false);
			this.loading = null;
		}
	}
}