import * as _ from 'underscore';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { Dashboard } from '../entity/dashboard';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { SharingService, IShareEntity, UsersAndGroups, IShareEntityType } from '@cxstudio/sharing/sharing-service.service';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { Security } from '@cxstudio/auth/security-service';
import { LicenseType } from '@cxstudio/common/license-types';
import { DashboardType } from '../entity/dashboard-type';
import { ShareUtilitiesService } from '@cxstudio/sharing/share-utilities.service';
import { IShareInviteUiBindings } from './share-invite.component';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { AllPermissionsService, PermissionType } from '@app/core/authorization/all-permissions.service';
import { LicenseService } from '@app/modules/user-administration/license.service';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import { ObjectSharingConstants } from '@app/modules/asset-management/object-sharing-constants';
import { UrlService } from '@cxstudio/common/url-service.service';
import { ObjectUtils } from '@app/util/object-utils';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

export interface IDashboardShareModalBindings {
	bulkMode: boolean;
	dashboards: Dashboard[];
	data;
	modalTitle: string;
	noCopyLink: boolean;
	previewAsFunction(modalInstance, data, item): void;
	saveFunction(modalInstance, data, isComponent: boolean): void;
}

export class DashboardSharingComponent implements ng.IComponentController {
	resolve;
	close;
	dismiss;
	cancel: () => void;

	dashboard: Dashboard;
	dashboards: Dashboard[];
	copyUrl: string;
	copyDashboardLink: string;
	showCopiedToast: boolean;
	defaultSharePermission: any;

	entityMap;
	organizationEntity: IShareEntity;
	allEntities: IShareEntity[] = [];
	sharedBookEntities: IShareEntity[] = [];
	countIndirectShares: number;
	allUsers: IShareEntity[];
	isBulkShare: boolean = false;
	showLicenseWarning = false;
	showPreview = false;

	inviteEntity;
	wasInitiallySharedToAll: boolean; // if it starts as shared to all when the modal is open

	countShares: string = '';
	countNewShares: string = '';
	modalTitle: string;
	loadingShares: boolean;

	invite: { dropdown: boolean; ignoreEnter: boolean; ignoreFocus: boolean } = {
		dropdown: false,
		ignoreEnter: false,
		ignoreFocus: false
	};

	permissions: any[];

	tableMaxHeight: number;
	data: Partial<{
		loading: { init; save };
		changedEntities: any[];
		addedEntities: any[];
		sharedEntities: any[];
		isSharedToAllUsers: boolean;
		notifyMessage: string;
		noIndirectShare: boolean;
		editorsAllowedToShare: boolean;
		allowSharingThroughEmbedding: boolean;
		sendEmail: boolean;
		dashboards: Dashboard[];
		saveFunction: (modalInstance, data, isComponent: boolean) => void;
	}>;
	noCopyLink: boolean;
	contractInfo;
	indirectSharesMessage: string;

	previewAsFunction: (modalInstance, data, item) => void;
	invalidEmail: boolean;
	showInvalidEmailAlert: boolean;
	userSearchLoading: boolean;
	shareToAllText: string;

	totalNewUserCount: number;

	ui: Partial<IShareInviteUiBindings> = {
		hideShareAll: false,
		showPreviewAsNotSupportError: false
	};

	private readonly PUBLIC: IShareEntity = ObjectUtils.copy(this.sharingService.PUBLIC);
	private readonly CB_USERS: IShareEntity = ObjectUtils.copy(this.sharingService.CB_USERS);

	constructor(
		private urlService: UrlService,
		private locale: ILocale,
		private $timeout: ng.ITimeoutService,
		private dashboardApiService: DashboardApiService,
		private sharingService: SharingService,
		private allPermissions: AllPermissionsService,
		private masterAccountApiService: MasterAccountApiService,
		private betaFeaturesService: BetaFeaturesService,
		private $window: ng.IWindowService,
		private $rootScope,
		private $scope: ISimpleScope,
		private security: Security,
		private licenseService: LicenseService,
		private shareUtilities: ShareUtilitiesService,
		private $q: ng.IQService) { }

	$onInit = () => {
		this.cancel = this.dismiss;
		this.isBulkShare = this.resolve.bulkMode;
		this.shareToAllText = this.isBulkShare ?
			this.locale.getString('common.manageShareToAll') :
			this.locale.getString('common.shareToAll');

		this.dashboards = this.resolve.dashboards;
		if (!this.isBulkShare) {
			this.dashboard = this.resolve.dashboards[0];
			this.copyUrl = this.urlService.getDashboardUrl(this.dashboard.id);
			this.copyDashboardLink = this.getCopyItemLinkMessage();
			this.noCopyLink = this.resolve.noCopyLink;
			this.showPreview = this.dashboard.type === DashboardType.DASHBOARD && !this.dashboard.createdByPinnedFeedback;
		}

		this.defaultSharePermission = this.security.has('share_view') ? PermissionType.VIEW : PermissionType.EDIT;

		this.modalTitle = this.resolve.modalTitle;
		this.previewAsFunction = this.resolve.previewAsFunction;

		this.inviteEntity = '';

		// items wrapped in data object are for sending back to API
		this.data = {
			loading: {
				init: null,
				save: false
			},
			dashboards: this.dashboards,
			sharedEntities: [],
			addedEntities: [],
			sendEmail: false,
			notifyMessage: '',
			editorsAllowedToShare: !DashboardUtils.isDashboardPreventedFromSharing(this.dashboard),
			allowSharingThroughEmbedding: this.dashboards[0].properties.allowSharingThroughEmbedding,
			saveFunction: this.resolve.saveFunction
		};
		this.data = _.extend(this.data, this.resolve.data);

		if (!this.isBulkShare) {
			this.loadPublicStatus();
		}

		this.permissions = this.allPermissions.getPermissions();
		this.tableMaxHeight = this.$rootScope.isMobile ? undefined : (this.$window.innerHeight - 520) / 2; // 520 - all other parts

		this.allEntities = [];
		this.contractInfo = {};

		this.loadContractInfo();
		if (!this.isBulkShare) {
			this.loadUsersAndGroups();
		}

		this.$scope.$watchCollection(() => this.data.sharedEntities, this.recalculateUsers);
		this.$scope.$watchCollection(() => this.data.addedEntities, this.recalculateUsers);

		this.PUBLIC.onRemove = () => {
			// if it's bulk share & change of permission, don't remove the public item from the list
			let isPermissionChange = this.isBulkShare;
			this.removeShareToAll(isPermissionChange);
		};

		if (!this.security.has('share_to_all')) {
			this.ui.hideShareAll = true;
		}
	};

	save = () => {
		if (this.data.saveFunction) {
			// repopulate changed entities array
			this.data.changedEntities = [].concat(this.data.addedEntities)
				.concat(_.filter(ObjectUtils.copy(this.data.sharedEntities), (entity) => entity.action === ShareAction.DELETE));

			this.data.changedEntities.map((entity) => {
				// remove "permission" is only used in UI
				if (entity.permission === PermissionType.REMOVE)
					delete entity.permission;
			});

			if (this.publicStatusRemoved()) {
				delete this.PUBLIC.permission;
				this.PUBLIC.action = ShareAction.DELETE;
				this.data.changedEntities.push(this.PUBLIC);
			}

			const IS_COMPONENT: boolean = true;
			this.data.saveFunction({close: this.close}, this.data, IS_COMPONENT);
		} else {
			this.close();
		}
	};

	getAddLabel = (): string => {
		let baseLabel = this.isBulkShare ? 'common.updateCount' : 'common.addCount';
		return this.locale.getString(baseLabel, { addCount: this.data.addedEntities.length });
	};

	getCopyUrl = (): string => this.copyUrl;

	private publicStatusRemoved = (): boolean => {
		return this.wasInitiallySharedToAll && !this.data.isSharedToAllUsers;
	};

	isEmbeddingAllowed = (): boolean => {
		return this.security.isQualtricsIntegrationEnabled()
			&& this.security.has('share_to_all')
			&& this.dashboard.permissions.OWN
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.SHARE_DASHBOARDS_EMBED);
	};

	copyFallback = () => {
		let ctrlKey = 'Ctrl';
		if (/Mac/i.test(navigator.userAgent)) {
			ctrlKey = '\u2318'; // mac control symbol
		}
		window.prompt(this.locale.getString('dashboard.copyDashboardLinkDialog', ctrlKey), this.copyUrl);
	};

	urlCopyClicked = () => {
		this.copyDashboardLink = this.locale.getString('dashboard.dashboardLinkCopied');
		this.showCopiedToast = true;
		this.$timeout(() => {
			this.copyDashboardLink = this.getCopyItemLinkMessage();
			this.showCopiedToast = false;
		}, 5000);
	};

	private getCopyItemLinkMessage(): string {
		return this.dashboard.type === 'dashboard' ?
			this.locale.getString('dashboard.copyDashboardLink') :
			this.locale.getString('dashboard.copyBookLink');
	}

	private loadContractInfo = (): void => {
		this.masterAccountApiService.getMasterAccountContractInfo().then((result) => {
			if (result && result.data) {
				this.contractInfo = result.data;
			}
		});
	};

	private processGroupsForPersonalizedHierarchy = (groups) => {
		return _.map(groups, (group: any) => {
			if (group.entity.groupType === GroupType.WHOLE_HIERARCHY) {
				group.isOrganizationGroup = true;
				group.iconType = 'organizationGroup';
				group.displayName = this.locale.getString('dashboard.orgLabel') + ': ' + group._name;
				group.entity.displayName = group.displayName;
				return group;
			} else {
				group.entity.displayName = group.displayName;
				return group;
			}
		});
	};

	private sortGroupsAndUsers = (userList, groupList, metaGroupList): IShareEntity[] => {
		let sortedList = _.union(userList, groupList, metaGroupList) as IShareEntity[];
		sortedList = _.sortBy(sortedList, (item: any) => {
			if (item.type === IShareEntityType.GROUP) {
				return item.sharedTo.groupName;
			} else {
				return item.sharedTo.userEmail;
			}
		});
		return sortedList;
	};

	private getPopulateBookOwners = (bookOwnerMap, isGroup) => {
		//This populates book owners in the books for display
		return (sharedBookEntity) => {
			let bookList = sharedBookEntity.bookList;
			let entityTooltip = [];
			bookList.forEach((book) => {
				if (bookOwnerMap[book.ownerId]) {
					book.ownerName = bookOwnerMap[book.ownerId];
				} else {
					//find owner in list of all users, add to owners map for future books
					let owner = _.find(this.allUsers, (userObject: any) => userObject.entity.userId === book.ownerId);
					if (owner) {
						book.ownerName = owner.entity.userEmail;
						bookOwnerMap[book.ownerId] = book.ownerName;
					}
				}
				entityTooltip.push({owner: book.ownerName, name: book.name});
			});
			if (isGroup) {
				if (_.isUndefined(sharedBookEntity.sharedTo.groupId)) {
					let metaGroupData;
					if (sharedBookEntity.sharedTo === this.CB_USERS.entity) {
						metaGroupData = this.CB_USERS;
					} else {
						metaGroupData = this.PUBLIC;
					}
					sharedBookEntity.type = metaGroupData.iconType;
					sharedBookEntity.displayName = metaGroupData.displayName;
				} else {
					if ((sharedBookEntity.sharedTo.groupId === -1) && (sharedBookEntity.sharedTo.groupName === 'Public')) {
						sharedBookEntity.type = this.PUBLIC.iconType;
						sharedBookEntity.displayName = this.PUBLIC.displayName;
					} else {
						sharedBookEntity.type = IShareEntityType.GROUP;
						sharedBookEntity.displayName = sharedBookEntity.sharedTo.groupName;
					}
				}
			} else {
				sharedBookEntity.type = IShareEntityType.USER;
				sharedBookEntity.displayName = sharedBookEntity.sharedTo.userEmail;
			}
			sharedBookEntity.numBooks = bookList.length;
			sharedBookEntity.tooltip = entityTooltip;
		};
	};

	private loadPublicStatus = (): void => {
		if (this.dashboard.sharingStatus === SharingStatus.PUBLIC) {
			this.PUBLIC.permission = 'VIEW';
			this.data.isSharedToAllUsers = true;
			this.wasInitiallySharedToAll = true;
		}
		this.recalculateUsers();
	};

	private populatePublicStatusToSharedEntities = (): void => {
		if (this.dashboard.sharingStatus === SharingStatus.PUBLIC && !_.findWhere(this.data.sharedEntities, { type: this.PUBLIC.type })) {
			this.data.sharedEntities.push(this.PUBLIC);
		}
	};

	togglePublicState = (): void => {
		// checkbox state has changed by the time we call this
		// so we need to know what the value WAS, not what it IS now
		let priorPublicState = !this.data.isSharedToAllUsers;

		if (priorPublicState) {
			this.removeShareToAll();
		} else {
			this.PUBLIC.permission = 'VIEW';
			if (this.wasInitiallySharedToAll) {
				// if they removed it but then added it back without ever submitting...
				this.data.sharedEntities.push(this.PUBLIC);
				delete this.PUBLIC.action;
			} else {
				this.data.addedEntities.push(this.PUBLIC);
				this.PUBLIC.action = ShareAction.ADD;
			}

			this.openAddPanelOnFirstAdd();
		}
	};

	private removeShareToAll = (isPermissionChange: boolean = false): void => {
		if (this.isBulkShare && isPermissionChange) return;

		this.data.isSharedToAllUsers = false;
		if (_.find(this.data.addedEntities, this.PUBLIC)) {
			this.data.addedEntities.remove(this.PUBLIC);
		} else {
			this.data.sharedEntities.remove(this.PUBLIC);
			this.PUBLIC.action = ShareAction.DELETE;
		}
	};

	private recalculateUsers = (): void => {
		this.countNewShares = this.sharingService.getNewCountMessage(this.data.addedEntities);
		this.countShares = this.sharingService.getCountMessage(this.data.sharedEntities,
			this.isBulkShare ? undefined : this.dashboard.ownerName);
		this.indirectSharesMessage = this.sharingService.getIndirectCountMessage(this.countIndirectShares);
		this.totalNewUserCount = this.getTotalUsersForNewShare();
	};

	getBulkShareHeading = (): string => {
		let dashboardCount = _.filter(this.dashboards, object => object.type === DashboardType.DASHBOARD).length;
		let bookCount = _.filter(this.dashboards, object => object.type === DashboardType.BOOK).length;

		if (dashboardCount && bookCount) {
			return this.locale.getString('dashboard.dashboardAndBookCount', {dashboardCount, bookCount});
		} else if (dashboardCount && !bookCount) {
			return this.locale.getString('dashboard.dashboardCount', {dashboardCount});
		} else if (bookCount && !dashboardCount) {
			return this.locale.getString('dashboard.bookCount', {dashboardCount, bookCount});
		}
	};

	isEntityOwner = (entity): boolean => !this.isBulkShare && entity.displayName === this.dashboard.ownerName.toLowerCase();

	private inviteEntityHasEditPermission =  (inviteEntity) =>
		inviteEntity.entity && inviteEntity.entity.assetEditPermissions.contains('edit_dashboard');

	onAddNew = (entity) => {
		this.openAddPanelOnFirstAdd();
	};

	private openAddPanelOnFirstAdd(): void {
		// on first addition, open added entities panel
		if (this.data.addedEntities.length === 1)  {
			this.ui.addPanelToggle++;
		}
	}

	// set all non-owners in target list to view permission
	setAllToView = (targetList: any[]) => {
		targetList = _.map(targetList, (entity) => {
			if (entity.permission !== PermissionType.OWN) {
				entity.permission = PermissionType.VIEW;
				entity.action = ShareAction.UPDATE;

				if (entity._children) {
					entity._children.map((entityChild) => delete entityChild.action);
				}
			}
			return entity;
		});
	};

	removeAll = (targetList: any[]) => {
		// remove share to all right away, if it exists
		this.removeShareToAll();

		targetList = _.map(targetList, (entity) => {
			if (entity.permission !== PermissionType.OWN) {
				entity.action = ShareAction.DELETE;
				entity.permission = PermissionType.REMOVE;

				// style any children (if it's a group) with the deleted style as well
				if (entity._children) {
					entity._children.map((entityChild) => entityChild.action = ShareAction.DELETE);
				}
			}
			return entity;
		});
	};

	private userCanShare = (): boolean => this.security.has('share_view') || this.security.has('share_edit');

	validateInvite = (entityName, entity?) => {
		let inviteValid = this.userCanShare() && ($.type(entityName) === 'string');

		this.showLicenseWarning = false;
		if (inviteValid && !this.shareUtilities.findExistingEntity(this.allEntities, entityName)
						&& !(this.inviteEntityHasEditPermission(this.inviteEntity))) {
			// only for user creation through sharing
			// we should not add EDIT permission in case of new user creation
			let limitExceeded = this.licenseLimitExceeded();
			inviteValid = !limitExceeded;
			this.showLicenseWarning = limitExceeded;
		}

		if (entity && entity.isOrganizationGroup) {
			inviteValid = true;
		}

		return inviteValid;
	};

	licenseLimitExceeded = (): boolean => {
		if (!this.contractInfo.licensingEnabled) {
			return false;
		}
		let licensePlacesTaken = this.countNewUsers();
		let licensePlacesAvailable = this.licenseService.getAvailableLicenses(
			this.contractInfo, LicenseType.CX_STUDIO);
		let basicLicensePlacesAvailable = this.licenseService.getAvailableLicenses(
			this.contractInfo, LicenseType.CX_STUDIO_BASIC);
		return (licensePlacesAvailable + basicLicensePlacesAvailable) <= licensePlacesTaken;
	};

	countNewUsers = (): number => {
		return _.filter(this.data.addedEntities, (entity) => entity.action === ShareAction.CREATE).length;
	};

	removeNewShare = (item) => {
		if (item === this.PUBLIC) {
			this.removeShareToAll();
			return;
		}
		delete item.shared;
		this.data.addedEntities.remove(item);
	};

	// is there at least one person already shared to that is not the owner?
	isNonOwnerShares = (): boolean => _.any(this.data.sharedEntities, entity => entity.permission !== PermissionType.OWN);

	// disable notifications if the only entities being added is the meta groups
	disableNotifications = (): boolean => {
		let disableNotifications = this.hasOnlyMetaGroupsChanges();
		if (disableNotifications) {
			this.data.sendEmail = false;
		}
		return disableNotifications;
	};

	setNotifyViaEmailTitle = (): string => {
		let title = this.locale.getString('dashboard.notifyViaEmail');
		if (this.hasMetaGroupsChanges() && !this.hasOnlyMetaGroupsChanges()) {
			let specialGroupsWarnings = this.getMetaGroupsEmailWarnings();
			title = this.locale.getString('dashboard.notifyViaEmailWithMetaGroups',
				{metaGroups: specialGroupsWarnings.join(', ')});
		}
		return title;
	};

	private hasMetaGroupsChanges = (): boolean => {
		let specialGroupsChanges = this.getMetaGroupsChanges();
		return specialGroupsChanges.length > 0;
	};

	private hasOnlyMetaGroupsChanges = (): boolean => {
		let specialGroupsChanges = this.getMetaGroupsChanges();
		return this.hasMetaGroupsChanges()
			&& this.data.addedEntities.length === specialGroupsChanges.length;
	};

	private getMetaGroupsChanges = (): any[] => {
		return this.data.addedEntities.filter((entity) => {
			return entity === this.PUBLIC
				|| entity === this.CB_USERS;
		});
	};

	private getMetaGroupsEmailWarnings = (): string[] => {
		let specialGroupsChanges = this.getMetaGroupsChanges();
		return specialGroupsChanges.map((specialGroup) => {
			if (specialGroup.entity === 'PUBLIC') {
				return this.locale.getString('dashboard.publicGroupNote');
			} else if (specialGroup.entity === 'CB_USERS') {
				return this.locale.getString('dashboard.cbUsersGroupNote');
			}
		});
	};

	showIndirectShare = (): boolean => {
		return this.dashboard && this.dashboard.type === 'dashboard'
			&& !this.isBulkShare && !this.data.noIndirectShare;
	};

	ownsDashboard = (): boolean => !this.isBulkShare && !!this.dashboard.permissions.OWN;

	private loadUsersAndGroups = (): void => {
		this.loadingShares = true;

		this.data.loading.init = this.dashboardApiService.getDashboardUsersWithRawGroups(this.dashboard.id)
			.then((dashboardEntities) => {
				let promises: any = {};

				let personalizedHierarchyId = this.dashboard.properties.hierarchyId;
				if (this.dashboard.properties.allowPersonalization && personalizedHierarchyId > 0) {
					promises.wholeOrganization = this.sharingService.getWholeOrganizationGroupSharingEntity(personalizedHierarchyId);
				}

				promises.usersAndGroups = this.sharingService.loadUsersAndGroups(dashboardEntities, this.getAssetEditPermissionType());

				return this.$q.all(promises).then((responses: any) => {
					let usersAndGroups = responses.usersAndGroups;
					if (responses.wholeOrganization) {
						this.organizationEntity = responses.wholeOrganization;
					}

					this.populateLoadedAvailableEntities(usersAndGroups);

					let sharingTableContent = this.sharingService.produceSharingTableContent(dashboardEntities, this.entityMap);
					if (!this.isBulkShare && !this.data.sharedEntities.length) {
						sharingTableContent.users.forEach((user) => this.data.sharedEntities.push(user));
						this.processGroupsForPersonalizedHierarchy(sharingTableContent.groups).forEach((group) => this.data.sharedEntities.push(group));
						sharingTableContent.metaGroups.forEach((metaGroup) => this.data.sharedEntities.push(metaGroup));
					}
					this.populatePublicStatusToSharedEntities();

					this.populateIndirectSharedEntities(sharingTableContent);
					this.recalculateUsers();
					this.loadingShares = false;

					// we only want to mark the shared items, not remove them from the list
					const FLAG_ONLY = true;
					this.sharingService.removeSharedEntities(this.allEntities, this.data.sharedEntities, FLAG_ONLY);
				});
			});
	};

	private populateIndirectSharedEntities = (sharingTableContent): void => {
		if (!isEmpty(sharingTableContent.sharedBookUsers)
				|| !isEmpty(sharingTableContent.sharedBookGroups)
				|| !isEmpty(sharingTableContent.sharedBookMetaGroups)) {
			let bookOwnerMap = {};
			sharingTableContent.sharedBookUsers.forEach(this.getPopulateBookOwners(bookOwnerMap, false));
			sharingTableContent.sharedBookGroups.forEach(this.getPopulateBookOwners(bookOwnerMap, true));
			sharingTableContent.sharedBookMetaGroups.forEach(this.getPopulateBookOwners(bookOwnerMap, true));

			//to filter out public group if it has no books listed
			let sharedGroupList = _.filter(sharingTableContent.sharedBookGroups, (bookEntity: any) => bookEntity.numBooks > 0);

			this.sharedBookEntities = this.sortGroupsAndUsers(
				sharingTableContent.sharedBookUsers, sharedGroupList, sharingTableContent.sharedBookMetaGroups);

			this.countIndirectShares = this.sharedBookEntities.length;
		}
	};

	// users are split by priority and each set is sorted in alphanumeric order on API; groups should be after users
	populateLoadedAvailableEntities = (usersAndGroups: UsersAndGroups): IShareEntity[] => {
		if (!usersAndGroups) return;

		this.entityMap = {};
		this.allEntities = [];

		this.allUsers = usersAndGroups.users ?? [];
		this.allUsers.forEach(this.addAvailableEntity);

		let groups = usersAndGroups.groups ?? [];
		if (this.organizationEntity) {
			if (!_.find(groups, group => this.isDashboardHierarchyGroup(group))) {
				groups = groups.concat(this.organizationEntity);
			}
		}
		this.processGroupsForPersonalizedHierarchy(groups).concat(this.CB_USERS)
			.sort((left, right) => left.displayName.localeCompare(right.displayName))
			.forEach(this.addAvailableEntity);

		this.allEntities = _.filter(this.allEntities, (item) => {
			return item.type !== IShareEntityType.GROUP
				|| item.entity.groupType !== GroupType.WHOLE_HIERARCHY
				|| (!this.isBulkShare
					&& item.entity.hierarchyEntries[0].hierarchyId === this.dashboard.properties.hierarchyId);
		});

		return this.allEntities;
	};

	private isDashboardHierarchyGroup(item: IShareEntity): boolean {
		return item.entity.groupType === GroupType.WHOLE_HIERARCHY
			&& item.entity.hierarchyEntries[0].hierarchyId === this.dashboard.properties.hierarchyId;
	}

	private addAvailableEntity = (entity: IShareEntity): void => {
		let entityKey = entity.type + entity._name;

		this.allEntities.push(entity);
		this.entityMap[entityKey] = entity;
	};

	getPreviouslySharedHeading = (): string => {
		let removeNum: number = _.filter(this.data.sharedEntities, (entity) => entity.action === ShareAction.DELETE).length;
		let langObject = {
			sharedNum: this.data.sharedEntities.length,
			removeNum
		};

		return (removeNum > 0) ?
			this.locale.getString('common.previouslySharedAndRemove', langObject) :
			this.locale.getString('common.previouslyShared', langObject);
	};

	disableShareButton = (): boolean => {
		return (this.shouldShowEmailWarning()
			|| (this.isBulkShare && this.data.addedEntities.length < 1) || this.data.loading.save)
			&& !this.data.allowSharingThroughEmbedding;
	};

	disableCancelButton = (): boolean => this.data.loading.save || this.loadingShares;

	getAssetEditPermissionType = (): AssetEditPermissionAction => AssetEditPermissionAction.EDIT_DASHBOARD;

	onPreviewAs = (item: IShareEntity): void => {
		if (this.previewAsFunction) {
			this.previewAsFunction({close: this.close}, this.data, item);
		} else {
			this.ui.showPreviewAsNotSupportError = true;
		}
	};

	getTotalUsersForNewShare = (): number => {
		let totalNewUsers = 0;
		this.data.addedEntities.forEach(entity => {
			if (entity.type === IShareEntityType.GROUP) {
				totalNewUsers += entity.usersCount ?? entity.entity?.usersCount ?? 0;
			} else {
				totalNewUsers++;
			}
		});
		return totalNewUsers;
	};

	shouldShowEmailWarning = (): boolean => this.data.sendEmail && this.totalNewUserCount > ObjectSharingConstants.EMAIL_USER_LIMIT;

	getDashboardSharingEmailWarning = (): string => {
		return this.locale.getString('sharing.emailBatchStrictWarning', {
			// eslint-disable-next-line id-blacklist
			number: ObjectSharingConstants.EMAIL_USER_LIMIT
		});
	};
}

app.component('dashboardSharing', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	controller: DashboardSharingComponent,
	templateUrl: 'partials/dashboards/dashboard-sharing.component.html'
});
