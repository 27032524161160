
export class HighchartsZoomUtil {
	static applyZoom = (chart, options) => {
		if (options.zoom) {
			chart.xAxis[0].zoom(options.zoom.xAxis.min, options.zoom
				.xAxis.max);
			chart.yAxis[0].zoom(options.zoom.yAxis.min, options.zoom
				.yAxis.max);

			if (!chart.resetZoomButton) {
				chart.showResetZoom();
			}
		}
	};
}

