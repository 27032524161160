import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { VerbatimAlertsListController } from '@cxstudio/alerts/verbatim-alerts-list.component';
import { GridFormatter, IRowFormatter } from '@cxstudio/grids/grid-formatter-service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { DesignerAlertType } from '@cxstudio/alerts/designer/designer-alert-type';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { DateService } from '@cxstudio/services/date-service.service';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export default class AlertGridDefinition implements IGridDefinition<VerbatimAlertsListController> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('dateService') private readonly dateService: DateService
	) {}

	init = (_gridMode: GridMode, controller: VerbatimAlertsListController): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.getAlertHamburgerFormatter(controller),
			resizable: false
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'name',
			name: this.locale.getString('common.name'),
			field: 'alertName',
			sortable: true,
			minWidth: 280,
			width: 350,
			cssClass: 'cell-name',
			searchable: true,
			formatter: this.gridFormatterService.getNameFormatter(GridTypes.ALERT),
			defaultSortColumn: SortDirection.ASC,
			isObjectNameColumn: true
		}, {
			id: 'enabled',
			name: this.locale.getString('common.enabled'),
			field: 'enabled',
			sortable: true,
			minWidth: 100,
			width: 120,
			searchable: false,
			formatter: this.getToggleSwitchFormatter(controller),
			cssClass: 'text-center',
			headerCssClass: 'text-center'
		}, {
			id: 'createDate',
			name: this.locale.getString('alert.createDate'),
			field: 'createDate',
			sortable: true,
			optional: true,
			minWidth: 200,
			width: 200,
			cssClass: 'cell-creation-date',
			searchable: false,
			formatter: (_row, _cell, value) => {
				if (!value || value.filterType) {
					return;
				}

				const date = new Date(value);

				return this.dateService.format(date);
			}
		},
			{
				id: 'owner',
				name: this.locale.getString('common.owner'),
				field: 'creatorEmail',
				sortable: true,
				optional: true,
				minWidth: 250,
				width: 250,
				cssClass: 'cell-owner',
				searchable: true,
				rowClassFunction: (data) => !controller.canManageAlert(data) ? 'disabled' : ''
			},
			{
				id: 'subscriptions',
				name: this.locale.getString('alert.subscriptions'),
				field: 'subscriptionsCount',
				sortable: true,
				optional: true,
				minWidth: 80,
				width: 100,
				cssClass: 'cell-subscriptions text-center',
				searchable: false,
				headerCssClass: 'text-center'
			},
			{
				id: 'type',
				name: this.locale.getString('alert.type'),
				field: 'type',
				sortable: true,
				optional: true,
				minWidth: 150,
				width: 150,
				cssClass: 'cell-type',
				searchable: true,
				formatter: (_row, _cell, value) => {
					switch (value) {
						case DesignerAlertType.NEW_VERBATIM: return this.locale.getString('alert.newVerbatim');
						case DesignerAlertType.SCORECARD: return this.locale.getString('alert.scorecard');
						default: return '';
					}
				}
			}, {
				id: 'model',
				name: this.locale.getString('administration.alertModel'),
				field: 'modelName',
				sortable: true,
				optional: true,
				cssClass: 'cell-model',
				minWidth: 180,
				width: 180,
				searchable: true,
				formatter: (_row, _cell, value, _column, context) => {
					switch (context.type) {
						case DesignerAlertType.NEW_VERBATIM:
							return value || this.locale.getString('alert.projectWide');
						default: return value;
					}
				}
			}];

		return Promise.resolve(columns);
	};

	getToggleSwitchFormatter(controller: VerbatimAlertsListController): IRowFormatter {
		return (_row, _cell, value, _columnDef, dataContext) => {
			const disabled = !controller.canManageAlert(dataContext);
			const checked = value;
			const getSwitch = this.gridFormatterService.getLabeledToggleSwitchGenerator(dataContext.alertName);
			return getSwitch(checked, disabled);
		};
	}

	getAlertHamburgerFormatter(controller: VerbatimAlertsListController): IRowFormatter {
		return (_row, _cell, _value, _columnDef, dataContext): string => {
			return controller.canManageAlert(dataContext) ? this.gridFormatterService.getHamburger(dataContext) : '';
		};
	}
}

app.service('alertGridDefinition', downgradeInjectable(AlertGridDefinition));
