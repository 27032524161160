import { PeriodOverPeriodMetric } from './period-over-period-metric';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import { Metric } from '@cxstudio/metrics/entities/metric.class';

export abstract class StatisticalMetric extends PeriodOverPeriodMetric {

	constructor() {
		super();
	}

	isSupported = (metric: ReportCalculation, metricList: Metric[]): boolean => {
		return metric.metricType === AnalyticMetricType.ATTRIBUTE
			&& metric.type === ReportAssetType.NUMBER 
			&& this.getCalculationFunction(metric) === CalculationFunction.AVG;
	};

	private getCalculationFunction = (metric: ReportCalculation): CalculationFunction => {
		return metric.calculationType ? metric.calculationType : CalculationFunction.AVG;
	};
}