import { HighchartsFunctionScope } from '@app/modules/widget-visualizations/highcharts/highcharts-closure-utils.class';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { StackType } from '@cxstudio/reports/providers/cb/constants/stack-types';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { IValueFormatter } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';

interface IPatternOptions {
	groupName: string;
	seriesName?: string;
	color: string;
	stackedName?: string;
	value: number;
	label?: string;
	volume?: number;
	percentage?: string;
}

type IPointFormatter = (point: Partial<HighchartsFunctionScope>) => string;

export class HighchartsTooltipFormatterUtil {

	private readonly stackedPattern =
		'<span style="font-size:10px">{groupName}</span><br/>' +
		'<span style="color:{color}">\u25CF</span> {stackedName}:{value}<br/>' +
		'{label}:{volume}';

	private readonly hundredPercentStackedPattern =
		'<span style="font-size:10px">{groupName}</span><br/>' +
		'<span style="color:{color}">\u25CF</span> {stackedName}:{value}<br/>' +
		'{label}:{volume} ({percentage}%)';

	private readonly defaultPattern =
		'<span style="font-size:10px">{groupName}</span><br/>' +
		'<span style="color:{color}">\u25CF</span> {seriesName}:{value}<br/>';

	constructor(
		private locale: ILocale,
		private reportNumberFormatUtils: ReportNumberFormatUtils,
	) {}

	getStackedTooltipFormatter(formatProperties: {stackedGroup?: ReportGrouping; metricFormatter?: IValueFormatter}): IPointFormatter {
		return (point) => {
			let series = point.series as any;
			let isHundredPercent =
				formatProperties.stackedGroup.stackType === StackType.HUNDRED_PERCENT;
			let pattern = isHundredPercent
				? this.hundredPercentStackedPattern
				: this.stackedPattern;
			let volume = series.userOptions.metric === StandardMetricName.VOLUME
				? formatProperties.metricFormatter(point.object.volume)
				: this.reportNumberFormatUtils.formatAny(point.object.volume);

			let data: IPatternOptions = {
				groupName: this.getPointDisplayName(formatProperties, point),
				color: series.color,
				stackedName: series.name,
				value: formatProperties
					.metricFormatter(point.object[series.userOptions.metric]),
				label: this.locale.getString('widget.volume'),
				volume
			} as any;

			if (isHundredPercent) {
				data.percentage = point.percentage.toFixed(0);
			}

			return pattern.doFormat(data);
		};
	}

	getDefaultTooltipFormatter(formatProperties): IPointFormatter {
		return (point) => {
			let data: IPatternOptions = {
				groupName: this.getPointDisplayName(formatProperties, point),
				seriesName: point.series.name,
				color: point.color ? point.color : point.series.color,
				value: formatProperties.metricFormatter(point.y)
			};

			return this.defaultPattern.doFormat(data);
		};
	}

	private getPointDisplayName(formatProperties, point): string {
		if (formatProperties.timeGroup) {
			let groupId = formatProperties.timeGroup.identifier;
			let metadata = point.object['_metadata_' + groupId];
			return metadata && metadata.displayName || point.object._name;
		}

		return point.name;
	}

}

app.service('highchartsTooltipFormatterUtil', HighchartsTooltipFormatterUtil);
