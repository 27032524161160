import * as _ from 'underscore';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ReportCalculation } from '../calculations/report-calculation';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IWidgetSettingsConfig } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { CBSettingsService, IWidgetSettingsScope } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { AttributeObjectType } from '@app/modules/project/attribute/attribute-object-type';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OrderableList } from '@cxstudio/services/orderable-list.class';
import { ReportSettingsService } from '@app/modules/project/settings/report-settings.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { IPropsWithGrouping, SelectedReportGroupings } from '@app/modules/widget-settings/services/selected-report-groupings';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import HierarchySettingsService from '@cxstudio/reports/providers/cb/services/hierarchy-settings.service';
import { AnalyticsDefinitionUtils } from '@cxstudio/reports/utils/analytic/analytics-definition-utils.service';

// once everything using this controller is converted to component and not using scope,
// we can replace this with ISimpleScope instead, but until then we need to be able to add to scope :(
type Supplier = () => any;
export interface IMultiSelectScope {
	$watchCollection: (expression: string | Supplier, callback: (newVal, oldVal) => void) => void;
	$watch: (expression: string | Supplier, callback: (newVal, oldVal) => void) => void;
	props: WidgetProperties;
}

export class AnalyticDefinitionMultiselectionController implements ng.IController {

	withAttributeSelection = this.multiSelectionConfiguration.withAttributeSelection;
	withMetricSelection = this.multiSelectionConfiguration.withMetricSelection;
	withDocumentLevelOnly = !!this.multiSelectionConfiguration.withDocumentLevelOnly;
	maxCalculations = this.multiSelectionConfiguration.max.calculations;
	maxGroupings = this.multiSelectionConfiguration.max.groupings;
	maxPoPGrouping = this.multiSelectionConfiguration.max.popGroupings;
	calculationCountingFilter = this.multiSelectionConfiguration.calculationCountingFilter;
	attributeCountingFilter = this.multiSelectionConfiguration.attributeCountingFilter;
	selectedReportGroupings = new SelectedReportGroupings(this.$scope.props as IPropsWithGrouping);

	matchSelectedAttributes;
	addAttribute;
	removeAttribute;
	matchSelectedMetrics;
	addMetric;
	onMetricUpdate;
	removeMetric;
	getSelectedMetricsLength;
	addHistoric;
	addDelta;
	addPercentChange;
	addPValue;
	addSignificance;
	showHistoricForMetric;
	isNeedPercentChange;
	showPercentChangeForMetric;
	showDeltaForMetric;
	showPValueForMetric;
	showSignificanceForMetric;
	getMetricDisplayNameWithPeriodPrefix;
	hasSettings;
	isPoPMetric;
	supportStatisticalMetrics = this.periodOverPeriodMetricService.isStatisticalMetricsSupported;

	private addMetricUnder;


	constructor(
		private $scope: IWidgetSettingsScope & ng.IScope,
		/*required*/ private multiSelectionConfiguration,
		private cbSettingsService: CBSettingsService,
		private groupIdentifierHelper,
		private $controller,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private locale: ILocale,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private adminProjectsService: AdminProjectsService,
		private numberFormatHelper,
		private reportSettingsService: ReportSettingsService,
		private $rootScope: ng.IRootScopeService,
		private $q: ng.IQService,
		private betaFeaturesService: BetaFeaturesService,
		private optionsTemplatesService: OptionsTemplatesService,
		private metricConstants: MetricConstants,
		private hierarchySettingsService: HierarchySettingsService
	) {
		this.$controller('AnalyticDefinitionCommonCtrl', { $scope });

		this.$scope.initLists = this.initLists;
		this.$scope.changePop = this.changePop;
		this.$scope.reloadSettings = this.reloadSettings;
		this.$scope.reorderList = this.reorderList;

		this.$scope.listsInitialized = false;


		this.$scope.$watch('ui.showGroupingsError', (newValue, oldValue) => {
			if (newValue === oldValue) return;

			let groupingsWarningI18 = (multiSelectionConfiguration.warnings && multiSelectionConfiguration.warnings.groupingsLimit) ?
				multiSelectionConfiguration.warnings.groupingsLimit : 'widget.groupingsLimitError';

			this.$scope.groupingsError = this.locale.getString(groupingsWarningI18, {
				limit: this.$scope.props.useHistoricPeriod ? this.maxPoPGrouping : this.maxGroupings
			});
		});

		if (this.withAttributeSelection) {
			this.initWithAttributeSelection();
		}
		if (this.withMetricSelection) {
			this.initWithMetricSelection();
		}
	}


	$onInit = () => { };

	initLists = (): void => {
		this.$scope.props.selectedAttributes = this.$scope.props.selectedAttributes || [];
		this.$scope.props.selectedMetrics = this.$scope.props.selectedMetrics || [];

		if (this.withAttributeSelection) {
			this.$scope.attributeList = new OrderableList(
				this.$scope.props.selectedAttributes, {
					min: 0,
					max: this.$scope.props.useHistoricPeriod ? this.maxPoPGrouping : this.maxGroupings
				}, this.attributeCountingFilter);
		}
		if (this.withMetricSelection) {
			this.$scope.metricList = new OrderableList(this.$scope.props.selectedMetrics, {
				min: 0,
				max: this.maxCalculations
			}, this.calculationCountingFilter);
		}

		this.disableOptionsBasedOnSelection();
		if (this.withMetricSelection) this.$scope.matchSelectedMetrics();
		if (this.withAttributeSelection) {
			this.$scope.matchSelectedAttributes();
			if (this.$scope.props.primaryTimeGrouping) {
				this.$scope.setPrimaryTimeGrouping(this.$scope.props.primaryTimeGrouping);
			}
		}
		this.$scope.listsInitialized = true;
		this.$scope.filteredAvailableAttributesTable = this.filterSelectedAttributesForGrouping(
			this.$scope.options.availableAttributes,
			this.$scope.dashboardFilters);
	};

	private disableOptionsBasedOnSelection = (): void => {
		let selectedItems = [];
		if (this.withAttributeSelection) {
			selectedItems = selectedItems.concat(this.$scope.props.selectedAttributes);
		}
		if (this.withMetricSelection) {
			selectedItems = selectedItems.concat(this.$scope.props.selectedMetrics);
		}
		let selectionOptions = this.getSelectionOptions();
		selectedItems.forEach((item) => {
			AnalyticsDefinitionUtils.disableAllowedMultiSelection(selectionOptions, item);
		});
	};

	private getSelectionOptions = (): any[] => {
		if (!this.$scope.options) return;
		return _.union(this.$scope.options.availableAttributes,
			this.$scope.options.additionalMetrics);
	};

	// if personalization is applied, filter out dynamic filtering org hierarchies
	filterSelectedAttributesForGrouping = (items: any[], filters: any): any[] => {
		const isPersonalizationEnabled = filters?.personalization?.isHierarchyEnabled();
		const appliedOrgHierarchyId = filters?.personalization?.getHierarchyId();
		return this.hierarchySettingsService.filterSelectedAttributesForGrouping(items, isPersonalizationEnabled, appliedOrgHierarchyId);
	};

	changePop = (pop: boolean): void => {
		this.$scope.props.useHistoricPeriod = pop;
		this.$scope.ui.showGroupingsError = false;
		if (pop) {
			if (this.withAttributeSelection && this.$scope.attributeList) {
				let attributesToRemove = this.$scope.attributeList.list
					.slice(this.maxPoPGrouping, this.$scope.attributeList.list.length);
				_.each(attributesToRemove, (attribute) => {
					this.$scope.removeAttribute(attribute);
				});

				this.$scope.attributeList.setBound({
					min: 0,
					max: this.maxPoPGrouping
				});
			}
			this.$scope.ui.periodOptions.hideMissDate = true;
		} else {
			if (this.withMetricSelection && this.$scope.metricList) {
				let popMetrics = _.filter(this.$scope.metricList.list, (metric: any) => metric && metric.isPopMetric);
				_.each(popMetrics, (metric) => {
					this.$scope.metricList.removeItem(metric);
					this.removeDisplayThresholds(metric);
				});
			}

			if (this.withAttributeSelection && this.$scope.attributeList) {
				this.$scope.attributeList.setBound({
					min: 0,
					max: this.maxGroupings
				});
			}
			this.$scope.ui.periodOptions.hideMissDate = false;
		}

		if (this.$scope.onPopChanged) {
			this.$scope.onPopChanged(pop);
		}
	};

	reloadSettings = (event, callback): void => {

		let config: IWidgetSettingsConfig = {withScorecardMetrics: this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)};

		this.$scope.reloadCommonSettings(config).then((result) => {
			this.$scope.clearErrors();
			result.withDocumentLevelOnly = this.withDocumentLevelOnly;
			this.cbSettingsService.initializeCommonSettings(this.$scope, result);

			if (this.withMetricSelection) {
				this.$scope.options.studioMetrics = result.metrics;
				this.$scope.options.standardMetrics = _.filter(this.metricConstants.getStandardCalculations(this.$scope.props.project),
					this.multiSelectionConfiguration.calculationFilter);
				this.$scope.options.additionalMetrics = this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
					.withStandardMetrics(this.$scope.options.standardMetrics)
					.withPredefinedMetrics(result.predefinedMetrics)
					.withAttributes(this.$scope.options.attributes, MetricFilters.CALCULATION)
					.withMetrics(result.metrics, this.$scope.props.project)
					.withDocumentLevelOnly(this.withDocumentLevelOnly)
					.withScorecardMetrics(result.scorecardMetrics)
					.build();
				this.$scope.options.cogSortByMetrics = angular.copy(this.$scope.options.additionalMetrics);
				this.$scope.options.predefinedMetrics = angular.copy(result.predefinedMetrics);
				this.$scope.populateCurrentHierarchyCalculations(this.$scope.options.additionalMetrics, result.hierarchyGrouping,
					result.organizationCalculations, result.hierarchyModels);
				this.$scope.processSelections();
			}

			if (this.withAttributeSelection) {
				this.$scope.options.availableAttributes = this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
					.withModels(result.models)
					.withTime(result.attributes)
					.withAttributes(result.attributes, MetricFilters.GROUP_FILTER)
					.withWordAttributes(result.wordAttributes)
					.withMetrics(result.metrics, this.$scope.props.project)
					.withOrgHierarchyModels(result.models, result.hierarchyModels)
					.withPredefinedGroups(angular.copy(result.predefinedMetrics))
					.withDocumentLevelOnly(this.withDocumentLevelOnly)
					.build();
				this.cbSettingsService.initDefaultProperties(this.$scope.options.availableAttributes);
			}

			if (!this.$scope.props.isCustomTitle) {
				this.$scope.updateAutoTitle();
			}

			this.$scope.ui.periodOptions.hideMissDate = Boolean(this.$scope.props.useHistoricPeriod);
			this.$scope.updateCustomDateFilters(result.dateFilters);

			this.$rootScope.$broadcast('date-filters:reload');

			this.$scope.initLists();
			callback(true);
		});
	};

	private initWithAttributeSelection = (): void => {
		this.matchSelectedAttributes = () => {
			let i = 0;

			while (this.$scope.props.selectedAttributes.length && i < this.$scope.props.selectedAttributes.length) {
				let attribute = this.$scope.props.selectedAttributes[i];
				let matchingAttribute;
				if (AnalyticMetricTypes.isStudioMetric(attribute)) {
					let array = this.$scope.options.studioMetrics || [];
					matchingAttribute = SearchableHierarchyUtils.deepSearchById(array, attribute.id);
				} else if (AnalyticMetricTypes.isPredefinedGroup(attribute) || AnalyticMetricTypes.isTime(attribute)) {
					matchingAttribute = SearchableHierarchyUtils.deepSearchByName(
						this.$scope.options.availableAttributes, attribute.name);
				} else if (AnalyticMetricTypes.isTopics(attribute)) {
					matchingAttribute = SearchableHierarchyUtils.deepSearchByPredicate(
						this.$scope.options.availableAttributes, this.topicFilter(attribute.name));
				} else if (AnalyticMetricTypes.isClarabridge(attribute)
					|| AnalyticMetricTypes.isHierarchyModel(attribute)) {
					matchingAttribute = SearchableHierarchyUtils.deepSearchByNameAndType(
						this.$scope.options.availableAttributes, attribute.name, attribute.type);
				} else {
					matchingAttribute = SearchableHierarchyUtils.deepSearchById(
						this.$scope.options.attributes, attribute.id);
				}

				if (matchingAttribute) {
					let selectedAttribute = angular.copy(matchingAttribute);
					this.extendAdditionalProperties(selectedAttribute, this.$scope.props.selectedAttributes[i]);
					this.$scope.props.selectedAttributes[i] = selectedAttribute;
					if (!AnalyticMetricTypes.isTopics(matchingAttribute)) {
						matchingAttribute.hidden = true;
					}
					i++;
				} else {
					let removedItem = this.$scope.props.selectedAttributes[i];
					this.$scope.removeAttribute(removedItem);
				}
			}
		};

		this.addAttribute = (attribute) => {
			if (attribute.children) return;
			if (!AnalyticMetricTypes.isTopics(attribute)) {
				attribute.hidden = true;
			}
			let promise = PromiseUtils.old(this.reportSettingsService.populateGroupingProjectDefaults(this.$scope.props, attribute));
			promise.then((projectDefaultsEnrichedItem) => {
				if (this.$scope.attributeList.addItem(projectDefaultsEnrichedItem)) {
					this.$scope.ui.showGroupingsError = false;

					if (AnalyticMetricTypes.isTime(attribute)) {
						this.$scope.setPrimaryTimeGrouping(attribute);
					}

					let selectionOptions = this.getSelectionOptions();
					AnalyticsDefinitionUtils.disableAllowedMultiSelection(selectionOptions, attribute);

					this.$scope.processDynamicCalculations(attribute);
					this.selectedReportGroupings.populateTopicFields();
					this.groupIdentifierHelper.populateIdentifier(this.$scope.props.selectedAttributes);
				} else {
					attribute.hidden = false;
					this.$scope.ui.showGroupingsError = true;
				}
			});

			this.$scope.addLoadingPromise(promise);
		};

		this.removeAttribute = (item): void => {
			this.$scope.attributeList.removeItem(item);
			this.makeAttributeAvailable(item);

			this.$scope.ui.showGroupingsError = false;

			if (AnalyticMetricTypes.isTime(item)) {
				delete this.$scope.props.primaryTimeGrouping;
				this.$scope.ui.popSelectionEnabled = true;
				this.$scope.ui.periodOptions.historic.filter = undefined;
				delete this.$scope.groupingDataPointsError;
			}

			this.$scope.checkAndRemoveCalculations();

			let selectionOptions = this.getSelectionOptions();
			AnalyticsDefinitionUtils.enableAllowedMultiSelection(selectionOptions, item);
			this.disableOptionsBasedOnSelection();

			if (this.multiSelectionConfiguration.removeAttributeCallback) {
				this.multiSelectionConfiguration.removeAttributeCallback(item);
			}
			this.selectedReportGroupings.populateTopicFields(item);
			this.groupIdentifierHelper.populateIdentifier(this.$scope.props.selectedAttributes);
		};

		this.$scope.matchSelectedAttributes = this.matchSelectedAttributes;
		this.$scope.addAttribute = this.addAttribute;
		this.$scope.removeAttribute = this.removeAttribute;
	};

	private makeAttributeAvailable = (item): void => {
		let availableAttribute = SearchableHierarchyUtils
			.deepSearchByNameAndType(this.$scope.options.availableAttributes, item.name, item.type);

		if (availableAttribute) {
			availableAttribute.hidden = false;
		}
	};

	private removeDisplayThresholds = (targetMetric): void => {
		_.each(this.$scope.props.selectedAttributes, (attribute: AttributeGrouping) => {
			if (attribute.displayThreshold && attribute.displayThreshold.metricName === targetMetric.name) {
				attribute.displayThreshold = {};
			}
		});
	};

	private getSelectedMetric = (metric): any => {
		let metricId = metric.isPopMetric ? metric.parentMetricId : metric.id;
		let metricName = metric.isPopMetric ? metric.parentMetricName : metric.name;

		if (metric.type === ReportAssetType.METRIC_STUDIO) {
			return SearchableHierarchyUtils.deepSearchById(this.$scope.options.studioMetrics, metricId);
		}

		if (AnalyticMetricTypes.isPredefinedGroup(metric)
			|| AnalyticMetricTypes.isNlpStudioMetric(metric)
			|| metric.standardMetric
			|| AnalyticMetricTypes.isScorecardMetric(metric)) {
			return SearchableHierarchyUtils.deepSearchByName(this.$scope.options.additionalMetrics, metricName);
		}

		if (AnalyticMetricTypes.isHierarchyEnrichmentProperty(metric)) {
			let hierarchyCalculations = HierarchyUtils.findGroup(this.$scope.options.additionalMetrics, OptionsConstant.HIERARCHY_CALCULATIONS);
			return SearchableHierarchyUtils.searchByName((hierarchyCalculations && hierarchyCalculations.children), metricName);
		}

		return SearchableHierarchyUtils.deepSearchById(this.$scope.options.attributes, metricId);
	};

	private initWithMetricSelection = (): void => {
		this.matchSelectedMetrics = () => {
			for (let m = this.$scope.props.selectedMetrics.length - 1; m >= 0; m--) {
				let metric = this.$scope.props.selectedMetrics[m];
				let matchItem = this.getSelectedMetric(metric);

				if (matchItem) {
					if (metric.isPopMetric) {
						metric.displayName = this.periodOverPeriodMetricService.getActualPeriodOverPeriodMetricDisplayName(
							metric, matchItem.displayName);
						metric.parentMetricDisplayName = matchItem.displayName;
					} else {
						this.extendAdditionalProperties(matchItem, metric);
						this.$scope.props.selectedMetrics[m] = matchItem;
						matchItem.hidden = true;
					}
				} else {
					this.$scope.props.selectedMetrics.remove(metric);
				}
			}
		};

		this.addMetric = (item): ng.IPromise<void> => {
			if (item.children)
				return this.$q.when();

			let promise = PromiseUtils.old(this.reportSettingsService.getCalculationSettings(this.$scope.props, item)).then((settings) => {
				_.extend(item, settings);
				if (this.$scope.metricList.contains(item) || this.$scope.metricList.addItem(item)) {
					item.hidden = true;

					// if item has default format, set this flag to true
					// otherwise leave it unset, in case default format is added later
					if (this.numberFormatHelper.itemHasDefaultFormat(item)) {
						item.useDefaultFormat = true;
					}

					this.$scope.ui.showCalculationsError = false;

					let selectionOptions = this.getSelectionOptions();
					AnalyticsDefinitionUtils.disableAllowedMultiSelection(selectionOptions, item);
					this.$scope.processSelections();
				} else {
					this.$scope.ui.showCalculationsError = true;
				}
			});

			this.$scope.addLoadingPromise(promise);
			return promise;
		};

		this.addMetricUnder = (parentMetric: ReportCalculation, metricType: PeriodOverPeriodMetricType) => {
			let metric = this.periodOverPeriodMetricService.createMetric(parentMetric, metricType);
			if (this.$scope.metricList.isPresent(metric)) {
				return;
			}
			if (!metric.children) {
				if (this.$scope.metricList.addItemUnder(metric, parentMetric, true)) {
					metric.hidden = true;
					this.$scope.ui.showCalculationsError = false;
				} else {
					this.$scope.ui.showCalculationsError = true;
				}
			}
		};

		this.onMetricUpdate = (metric) => {
			if (!this.supportStatisticalMetrics(metric)) {
				this.cleanupStatisticalChildren(metric);
			}
		};

		this.removeMetric = (item, skipPopCleanup) => {
			this.$scope.metricList.removeItem(item);
			this.makeMetricAvailable(item);

			if (!skipPopCleanup) {
				this.cleanupPopChildren(item);
			}
			this.$scope.ui.showCalculationsError = false;

			let selectionOptions = this.getSelectionOptions();
			AnalyticsDefinitionUtils.enableAllowedMultiSelection(selectionOptions, item);
			this.disableOptionsBasedOnSelection();
			this.$scope.processSelections();
			this.removeDisplayThresholds(item);
		};

		this.getSelectedMetricsLength = () => {
			return _.filter(this.$scope.props.selectedMetrics, (selectedMetric: any) => !selectedMetric.isPopMetric).length;
		};

		this.isPoPMetric = (metric): boolean => metric.isPopMetric;

		this.addHistoric = (metric) => {
			if (this.$scope.showHistoricForMetric(metric)) {
				this.addMetricUnder(metric, PeriodOverPeriodMetricType.HISTORICAL);
			}
		};

		this.addDelta = (metric) => {
			if (this.$scope.showDeltaForMetric(metric)) {
				this.addMetricUnder(metric, PeriodOverPeriodMetricType.DELTA);
			}
		};

		this.addPercentChange = (metric) => {
			if (this.$scope.showPercentChangeForMetric(metric)) {
				this.addMetricUnder(metric, PeriodOverPeriodMetricType.PERCENT_CHANGE);
			}
		};

		this.addPValue = (metric) => {
			if (this.showPValueForMetric(metric)) {
				this.addMetricUnder(metric, PeriodOverPeriodMetricType.P_VALUE);
			}
		};

		this.addSignificance = (metric) => {
			if (this.showSignificanceForMetric(metric)) {
				this.addMetricUnder(metric, PeriodOverPeriodMetricType.SIGNIFICANCE);
			}
		};

		this.showHistoricForMetric = (metric) => {
			if (!this.$scope.metricList || !this.$scope.props.useHistoricPeriod) {
				return false;
			}
			return !this.$scope.isPoPMetric(metric) && !this.$scope.metricList.isPresentByName(
				this.periodOverPeriodMetricService.getName(metric, PeriodOverPeriodMetricType.HISTORICAL));
		};

		this.isNeedPercentChange = (metric, metricList): boolean =>
			this.periodOverPeriodMetricService.getPeriodOverPeriodMetricByType(PeriodOverPeriodMetricType.PERCENT_CHANGE)
				?.isSupported(metric, metricList);

		this.showPercentChangeForMetric = (metric) => {
			if (!this.$scope.metricList || !this.$scope.props.useHistoricPeriod
				|| !this.$scope.isNeedPercentChange(metric)) {
				return false;
			}
			return !this.$scope.isPoPMetric(metric) && !this.$scope.metricList.isPresentByName(
				this.periodOverPeriodMetricService.getName(metric, PeriodOverPeriodMetricType.PERCENT_CHANGE));
		};

		this.showDeltaForMetric = (metric) => {
			if (!this.$scope.metricList || !this.$scope.props.useHistoricPeriod) {
				return false;
			}
			return !this.$scope.isPoPMetric(metric) && !this.$scope.metricList.isPresentByName(
				this.periodOverPeriodMetricService.getName(metric, PeriodOverPeriodMetricType.DELTA));
		};

		this.showPValueForMetric = (metric) => {
			if (!this.$scope.metricList || !this.$scope.props.useHistoricPeriod
				|| !this.supportStatisticalMetrics(metric)) {
				return false;
			}
			return !this.$scope.isPoPMetric(metric) && !this.$scope.metricList.isPresentByName(
				this.periodOverPeriodMetricService.getName(metric, PeriodOverPeriodMetricType.P_VALUE));
		};

		this.showSignificanceForMetric = (metric) => {
			if (!this.$scope.metricList || !this.$scope.props.useHistoricPeriod
				|| !this.supportStatisticalMetrics(metric)) {
				return false;
			}
			return !this.$scope.isPoPMetric(metric) && !this.$scope.metricList.isPresentByName(
				this.periodOverPeriodMetricService.getName(metric, PeriodOverPeriodMetricType.SIGNIFICANCE));
		};

		this.getMetricDisplayNameWithPeriodPrefix = (metric): string => {
			if (!this.$scope.props.useHistoricPeriod) {
				return metric.displayName;
			} else {
				return this.periodOverPeriodMetricService.getMetricDisplayNameWithPeriodPrefix(metric, this.$scope.visualProps);
			}
		};

		this.hasSettings = (metric: ReportCalculation): boolean => {
			let type: PeriodOverPeriodMetricType = this.periodOverPeriodMetricService.getPeriodOverPeriodMetric(metric)?.getType();
			return type !== PeriodOverPeriodMetricType.SIGNIFICANCE;
		};

		this.$scope.getSelectedMetricsLength = this.getSelectedMetricsLength;
		this.$scope.addHistoric = this.addHistoric;
		this.$scope.addDelta = this.addDelta;
		this.$scope.addPercentChange = this.addPercentChange;
		this.$scope.addPValue = this.addPValue;
		this.$scope.addSignificance = this.addSignificance;
		this.$scope.isPoPMetric = this.isPoPMetric;
		this.$scope.supportStatisticalMetrics = this.supportStatisticalMetrics;
		this.$scope.showHistoricForMetric = this.showHistoricForMetric;
		this.$scope.isNeedPercentChange = this.isNeedPercentChange;
		this.$scope.showPercentChangeForMetric = this.showPercentChangeForMetric;
		this.$scope.showDeltaForMetric = this.showDeltaForMetric;
		this.$scope.showPValueForMetric = this.showPValueForMetric;
		this.$scope.showSignificanceForMetric = this.showSignificanceForMetric;
		this.$scope.getMetricDisplayNameWithPeriodPrefix = this.getMetricDisplayNameWithPeriodPrefix;
		this.$scope.matchSelectedMetrics = this.matchSelectedMetrics;
		this.$scope.addMetric = this.addMetric;
		this.$scope.onMetricUpdate = this.onMetricUpdate;
		this.$scope.removeMetric = this.removeMetric;
		this.$scope.hasSettings = this.hasSettings;
		this.$scope.filterSelectedAttributesForGrouping = this.filterSelectedAttributesForGrouping;
	};

	private makeMetricAvailable = (item): void => {
		let availableMetric = SearchableHierarchyUtils
			.deepSearchByNameAndType(this.$scope.options.additionalMetrics, item.name, item.type);

		if (availableMetric) {
			availableMetric.hidden = false;
		}
	};

	private cleanupPopChildren = (item): void => {
		this.getPoPChildren(item).forEach((metric) => {
			this.$scope.removeMetric(metric, true);
			this.$scope.removeSelectedMetric(metric);
		});
	};

	private cleanupStatisticalChildren = (item): void => {
		this.getPoPChildren(item)
			.filter(metric => this.isStatisticalMetric(metric))
			.forEach((metric) => {
				this.$scope.removeMetric(metric, true);
				this.$scope.removeSelectedMetric(metric);
			});
	};

	private isStatisticalMetric = (metric): boolean => {
		let popMetric = this.periodOverPeriodMetricService.getPeriodOverPeriodMetric(metric);
		let type = popMetric ? popMetric.getType() : undefined;
		return type === PeriodOverPeriodMetricType.P_VALUE || type === PeriodOverPeriodMetricType.SIGNIFICANCE;
	};

	private getPoPChildren = (item): any[] => {
		if (!this.$scope.props.useHistoricPeriod || item.isPopMetric) return [];
		return _.filter(this.$scope.metricList.list, (metric: any) => metric.parentMetricName === item.name);
	};

	private topicFilter = (name): (item: any) => boolean => {
		return (item) => item.name === name && AnalyticMetricTypes.isTopics(item);
	};

	private extendAdditionalProperties(dest, source): void {
		let replicateProperties = ['size', 'sortBy', 'sortOrder', 'nullInclude', 'updateOnParentFilter',
			'truncation', 'decimals', 'dataType', 'calculationType', 'inheritTopics', 'selectedLevel',
			'selectedNodes', 'minDocCount', 'colorDirection', 'hideArrow', 'type',
			'wordsFilteringMode', 'wordsList', 'usePercentChange', 'popField', 'capitalization', 'thousandsDelimiter',
			'decimalDelimiter', 'prefix', 'suffix', 'conversion', 'customFormatting', 'useDefaultFormat',
			'peerReportType', 'checkedInclusionNodes', 'identifier', 'showLevel', 'subtopicsOnly',
			'urlType', 'tableRowHeight', 'threshold', 'displayThreshold', 'emptyPeriodType', 'scale',
			'verticalAlign', 'horizontalAlign', 'inheritAttributeValues'
		];

		for (let property of replicateProperties) {
			dest[property] = source[property];
		}
	}

	private isMoveValid = (list, item1, item2): boolean => {
		let listType = (list === this.$scope.props.selectedAttributes) ? this.$scope.attributeList : this.$scope.metricList;

		return listType.canBeMoved(item1) && listType.canBeMoved(item2);
	};

	reorderList = (list, movedItem, newIndex) => {
		let itemIndex = _.indexOf(list, movedItem);

		if (itemIndex > -1 && this.isMoveValid(list, movedItem, list[newIndex]))
			list.move(itemIndex, newIndex);

		this.groupIdentifierHelper.populateIdentifier(this.$scope.props.selectedAttributes);
	};

}


app.controller('AnalyticDefinitionMultiSelectionController', AnalyticDefinitionMultiselectionController);
