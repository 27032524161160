import { Component, OnInit, Inject, ChangeDetectorRef, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { Model } from '@cxstudio/reports/entities/model';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { IProjectSummary } from '@app/modules/access-management/groups/project-summary';
import Group from '@cxstudio/user-administration/groups/Group';
import { Project } from '@cxstudio/user-administration/users/project-access/project-class';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { downgradeComponent } from '@angular/upgrade/static';
import { SideBySideSelectorModalComponent, SideBySideSelectorModel } from '@app/modules/user-administration/groups/side-by-side-selector-modal.component';
import { AssetAccessOptionsBuilderProvider } from '../asset-access-options-builder-provider.service';
import { ISelectableAsset } from '../selectable-asset';
import { PromiseUtils } from '@app/util/promise-utils';
import { IAssetAccess } from '../asset-access';
import { AssetAccessApiService } from '../../api/asset-access-api.service';
import { ModificationDelta } from '../modification-delta';
import { IAsset } from '../asset';
import { AssetType } from '../asset-type';
import { IReportModel } from '@app/modules/project/model/report-model';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ObjectUtils } from '@app/util/object-utils';
import { Unit } from '@app/modules/units/unit';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AccountOrWorkspace } from '@app/modules/units/workspace-project/workspace-project';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Component({
	selector: 'assets-access',
	template: `
	<div class="mb-16">
		<project-selector *ngIf="!isWorkspaceEnabled"
			(loading)="onProjectsLoading($event)"
			(errorsChange)="onCpErrorsChange($event)"
			[hideProject]="true"
			(projectSelectionChange)="onProjectSelectionChange($event)"
		></project-selector>
		<workspace-selector *ngIf="isWorkspaceEnabled"
			(workspaceChange)="onWorkspaceChange($event)"
			(loading)="onProjectsLoading($event)"
		></workspace-selector>
	</div>
	<div [ngBusy]="loading">
		<formatted-table
			[rows]="projects"
			[columns]="columns"
			[scrollable]="true"
		></formatted-table>
	</div>
	`,
})
export class AssetsAccessComponent implements OnInit {
	@Input() viewOnly: boolean;
	@Input() assetsAccess: IAssetAccess[];
	@Input() group: Group;
	loading: Promise<any>;
	errors: string[];
	columns: Array<TableColumn<any>>;

	projects: IProjectSummary[];
	workspace: AccountOrWorkspace;
	isWorkspaceEnabled: boolean;

	constructor(
		private readonly locale: CxLocaleService,
		private ref: ChangeDetectorRef,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
		private readonly assetAccessApiService: AssetAccessApiService,
		private readonly cxDialogService: CxDialogService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('projectSettingsService') private readonly projectSettingsService: ProjectSettingsService,
		private readonly assetAccessOptionsBuilderProvider: AssetAccessOptionsBuilderProvider
	) {}

	ngOnInit(): void {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.errors = [];
		this.columns = [{
			name: 'edit',
			formatter: this.editActionFormatter,
			action: (project) => this.editSelection(project),
			width: 0.1
		}, {
			name: 'name',
			path: 'project.name',
			displayName: this.locale.getString('common.project'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.5
		}, {
			name: 'hiddenModelsCount',
			displayName: this.locale.getString('administration.modelAccess'),
			formatter: this.hiddenCountFormatter,
			width: 0.2
		}, {
			name: 'hiddenAttributesCount',
			displayName: this.locale.getString('administration.attributeAccess'),
			formatter: this.hiddenCountFormatter,
			width: 0.2
		}];
		this.workspace = this.isWorkspaceEnabled
			? -1
			: {
				contentProviderId: -1,
				accountId: -1
			};
	}

	onProjectSelectionChange = (selected: any): void => {
		this.projects = [];
		this.workspace = {
			contentProviderId: selected.cbContentProvider,
			accountId: selected.cbAccount
		};
		this.reloadData();
	};

	onWorkspaceChange = (workspace: Unit): void => {
		this.projects = [];
		this.workspace = workspace.id;
		this.reloadData();
	};

	onCpErrorsChange = (errors: string[]) => {
		this.errors = errors;
	};

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading = loadingPromise;
		this.ref.markForCheck();
	};

	private reloadData = (): void => {
		if (WorkspaceTransitionUtils.isWorkspaceSelected(this.workspace)) {
			this.loading = this.assetAccessApiService.getWorkspaceGroupAssetAccessSummary(this.workspace, this.group.groupId)
				.then(result => this.projects = result);
		}
	};

	private editSelection = (projectSummary: IProjectSummary): void => {
		let projectIdentifier = WorkspaceTransitionUtils.getProject(this.workspace, projectSummary.project.id);
		let assetsPromise = PromiseUtils.wrap(this.projectSettingsService.getSettings(projectIdentifier));
		let selectedPromise = this.group.groupId
			? this.assetAccessApiService.getGroupAssetAccess(projectIdentifier, this.group.groupId)
			: Promise.resolve(this.getHiddenAssets(projectIdentifier));

		this.loading = Promise.all([assetsPromise, selectedPromise]).then(
			(data: any[]) => this.openEditSelectionDialog(projectSummary.project, data[0], data[1]));
	};

	private getHiddenAssets = (project: AccountOrWorkspaceProject): IAsset[] => {
		let assetAccess = this.assetsAccess.find(item => item.project.projectId === project.projectId);
		if (!assetAccess) {
			let emptyContainer = {
				project,
				hiddenAssets: []
			};
			this.assetsAccess.push(emptyContainer);
			return [];
		}
		return ObjectUtils.copy(assetAccess.hiddenAssets);
	};

	private openEditSelectionDialog = (project: Project, projectSettings: IProjectSettings, hiddenAssets: IAsset[]): void => {
		let selectedModelIds = this.getSelectedIds(hiddenAssets, AssetType.MODEL);
		let selectedAttributeIds = this.getSelectedIds(hiddenAssets, AssetType.ATTRIBUTE);
		let optionsBuilder = this.assetAccessOptionsBuilderProvider.getInstance();
		let applicableModels = projectSettings.models.filter(this.isModelSupported);
		let applicableAttributes = projectSettings.attributes.filter(this.isAttributeSupported);
		let itemsTree = optionsBuilder
			.withModels(applicableModels, selectedModelIds)
			.withAttributes(applicableAttributes, selectedAttributeIds)
			.build();

		let selectedItemsTree = optionsBuilder.getTemplate();
		if (this.viewOnly) {
			selectedItemsTree.forEach(item => (item as any)._autoexpanded = true);
		}

		let model = {
			description: this.locale.getString('administration.cpAssetsAccessModalDescription'),
			availableTree: itemsTree as unknown as TreeNode[],
			selectedTree: selectedItemsTree as unknown as TreeNode[],
			hiddenAssets: hiddenAssets as unknown as TreeNode[],
			modalTitle: this.locale.getString('administration.cpAssetsAccessModalTitle', {projectName: project.name}),
			availableLabel: this.locale.getString('administration.findAvailable'),
			selectedLabel: this.locale.getString('administration.findHidden'),
			disabled: this.viewOnly
		} as SideBySideSelectorModel;
		let inapplicableAssets = this.getInapplicableAssets(hiddenAssets, applicableModels, applicableAttributes);
		let confirmPromise = inapplicableAssets?.length
			? this.cxDialogService.notify(this.locale.getString('common.warning'),
				this.locale.getString('administration.findInapplicableAssets')).result
			: Promise.resolve();
		confirmPromise.then(() => this.openSideBySideSelectorModal(model).result
				.then((res) => this.updateAssetsAccess(project.id, res, inapplicableAssets)));
	};

	private updateAssetsAccess(projectId: number, res: ModificationDelta<ISelectableAsset>, inapplicableAssets: IAsset[]): void {
		if (this.viewOnly || _.isUndefined(res)) {
			return;
		}
		let changes = res;
		let projectIdentifier = WorkspaceTransitionUtils.getProject(this.workspace, projectId);
		if (this.group.groupId) {
			changes.removed.pushAll(inapplicableAssets as ISelectableAsset[]);
			this.assetAccessApiService.updateGroupAssetAccess(projectIdentifier, this.group.groupId, changes)
				.then(this.reloadData);
		} else {
			this.updateAssetsAccessForNewGroup(projectId, changes);
		}
	}

	private getInapplicableAssets(hiddenAssets: IAsset[], models: IReportModel[], attributes: IReportAttribute[]): IAsset[] {
		return _.filter(hiddenAssets, asset => !this.isAssetApplicable(asset, models, attributes));
	}

	private isAssetApplicable(asset: IAsset, models: IReportModel[], attributes: IReportAttribute[]): boolean {
		let assets = asset.assetType === AssetType.MODEL ? models : attributes;
		return !!_.findWhere(assets, {id: asset.assetId});
	}

	private openSideBySideSelectorModal = (model: SideBySideSelectorModel): NgbModalRef => {
		return this.cxDialogService.openDialog(SideBySideSelectorModalComponent, model);
	};

	private updateAssetsAccessForNewGroup = (projectId: number, changes: ModificationDelta<ISelectableAsset>): void => {
		let assetAccess = this.assetsAccess.find(item => item.project.projectId === projectId);
		assetAccess.hiddenAssets.pushAll(changes.added);
		assetAccess.hiddenAssets = _.reject(assetAccess.hiddenAssets, asset =>
			!!_.find(changes.removed, item => this.isAssetsEqual(item, asset)));
		let hiddenAssetMap = _.groupBy(assetAccess.hiddenAssets, 'assetType');

		let projects = ObjectUtils.copy(this.projects);
		let index = _.findIndex(this.projects, summary => summary.project.id === projectId);
		projects[index].hiddenAttributesCount = hiddenAssetMap[AssetType.ATTRIBUTE] && hiddenAssetMap[AssetType.ATTRIBUTE].length || 0;
		projects[index].hiddenModelsCount = hiddenAssetMap[AssetType.MODEL] && hiddenAssetMap[AssetType.MODEL].length || 0;
		this.projects = projects;
	};

	private isAssetsEqual = (asset1: IAsset, asset2: IAsset): boolean => {
		return asset1.assetType === asset2.assetType
			&& asset1.assetId === asset2.assetId;
	};

	private isAttributeSupported = (attribute: IReportAttribute): boolean => {
		let attributeName = attribute.name.toLowerCase();
		return attributeName !== 'natural_id'
			&& attributeName !== 'parent_natural_id'
			&& !attributeName.startsWith('_')	//it includes doc date
			&& !attributeName.startsWith('cb_');
	};

	private isModelSupported = (model: Model): boolean => {
		return !model.hidden;
	};

	private getSelectedIds = (hiddenAssets: IAsset[], type: AssetType): number[] => {
		return hiddenAssets
			.filter(item => item.assetType === type)
			.map(item => item.assetId);
	};

	private editActionFormatter = (): string => {
		let text = this.locale.getString('common.edit');
		return `<a>${text}</a>` ;
	};

	private hiddenCountFormatter = (object: any, path: string): string => {
		let hidden: number = this.tableFormattersService.getFieldByPath(object, path);
		return this.locale.getString('administration.hiddenCount', {count: hidden});
	};

}

app.directive('assetsAccess', downgradeComponent({component: AssetsAccessComponent}));
