import { TextFilterService } from '@app/modules/filter-builder/text-filter.service';
import { CombinedFiltersService } from '@app/modules/filter/services/combined-filters.service';
import { IInteractionActionsService, InteractionActionsService } from '@app/modules/interaction-explorer/interaction-actions.service';
import { InteractionApiService } from '@app/modules/interaction-explorer/interaction-api.service';
import { InteractionExplorerColumnItemOption } from '@app/modules/interaction-explorer/interaction-explorer-grid-definition.service';
import { InteractionPermissions } from '@app/modules/interaction-explorer/interaction-permissions';
import { InteractionExplorerSettings, InteractionSettingsService } from '@app/modules/interaction-explorer/interaction-settings.service';
import { PaginatedSelectionController } from '@app/modules/item-grid/selection/paginated-selection-controller';
import { PaginatedSelectionUtils } from '@app/modules/item-grid/selection/paginated-selection-utils.factory';
import { SelectionUtils } from '@app/modules/item-grid/selection/selection-utils.class';
import { ProfanityDisguiseService } from '@app/modules/profanity/profanity-disguise.service';
import { AttributeSettingsService } from '@app/modules/project/attribute/attribute-settings.service';
import { ProjectSettingsMap } from '@app/modules/project/settings/report-settings.service';
import { PromiseUtils, Deferred } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import { ProjectAssetsErrors, ProjectAssetsLoading } from '@app/modules/units/project-selection-error/project-selection-error.component';
import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { FilterRuleType, FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { IFilterActionsService } from '@cxstudio/report-filters/filter-actions-service';
import { HardcodedFilterItem, HardcodedFilterType, MixedFilter, MixedFilterItem, MixedFilterRuleEntity, MixedItemType } from '@cxstudio/report-filters/filter-builder/mixed-filter';
import { IDocumentWidget } from '@cxstudio/reports/document-explorer/document-cache.service';
import { DocumentTextMode } from '@cxstudio/reports/document-text-mode.enum';
import { AdhocFilter, IDateRuleDefinition, IFilterRule, IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { CbDocument } from '@cxstudio/reports/entities/cb-document.class';
import { DateFilter } from '@cxstudio/reports/entities/date-filter';
import { DateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { DrillType } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { DrillFilter } from '@cxstudio/reports/utils/contextMenu/drill/drill-filter';
import ProjectSettingsService, { IProjectSettings } from '@cxstudio/services/data-services/project-settings.service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import * as moment from 'moment';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { Unit } from '@app/modules/units/unit';
import { OptionsAmount } from '@app/shared/components/project-selector/options-amount';
import { WorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { DocumentLinkService } from '@cxstudio/reports/document-explorer/document-link.service';
import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { ReportPaginationService } from '@app/modules/widget-visualizations/report-pagination.service';
import { PreviewSortAttributes } from '@cxstudio/reports/entities/preview-sort-attributes';


export class InteractionExplorer implements PaginatedSelectionController<CbDocument> {

	static REQUESTED_DOCUMENTS = 48;

	gridOptions: SlickgridOptions;
	gridType: GridTypes = GridTypes.INTERACTION_EXPLORER;
	actionsService: IFilterActionsService;

	loading: ProjectAssetsLoading = {};
	errors: ProjectAssetsErrors = {};
	projectSelection: IProjectSelection;

	isWorkspaceEnabled: boolean;
	project: AccountOrWorkspaceProject;
	projectsAmount: OptionsAmount;

	allDocuments: CbDocument[] = [];
	allDocumentsMap: {[documentId: number]: CbDocument} = {};
	currentPageDocuments: CbDocument[];
	lastChange;

	pagination: Pagination;
	contextMenuUtils;
	interactionActions: IInteractionActionsService;
	permissions: InteractionPermissions;

	selectionUtils: PaginatedSelectionUtils<CbDocument>;
	private lastChecked: CbDocument;
	tableMenuOptions: Array<ContextMenuItem<CbDocument>>;

	interactionExplorerColumns: InteractionExplorerColumnItemOption[];
	private projectUIInitialized: boolean = false;
	private projectAssetInitialized: boolean = false;
	showGrid: boolean = false;

	private projectAssetsPromise: ng.IPromise<IProjectSettings>;
	projectAssets: IProjectSettings;
	private attributeSettingsPromise: ng.IPromise<ProjectSettingsMap>;
	private savedFiltersPromise: ng.IPromise<any>;
	private docsPromise: Deferred<any>;
	savedFilters;

	filter: MixedFilter;
	textFilter: string;

	widget: IDocumentWidget;

	settings: InteractionExplorerSettings;

	documentsUpdateIsInProgress: boolean;

	constructor(
		private locale: ILocale,
		private security: Security,
		private reportDataApiService,
		private filterRuleService: FilterRuleService,
		private globalNotificationService: GlobalNotificationService,
		private gridUtils: GridUtilsService,
		private interactionsContextMenuUtils,
		private contextMenuTree: ContextMenuTree,
		private interactionActionsService: InteractionActionsService,
		private interactionApiService: InteractionApiService,
		private $q: ng.IQService,
		private $scope: ng.IScope,
		private projectSettingsService: ProjectSettingsService,
		private readonly attributeSettingsService: AttributeSettingsService,
		private textFilterService: TextFilterService,
		private interactionSettingsService: InteractionSettingsService,
		private readonly combinedFiltersService: CombinedFiltersService,
		private profanityDisguiseService: ProfanityDisguiseService,
		private readonly $location: ng.ILocationService,
		private readonly $timeout: ng.ITimeoutService,
		private betaFeaturesService: BetaFeaturesService,
		private readonly documentLinkService: DocumentLinkService,
	) { }

	$onInit = () => {
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
		this.project = {} as AccountOrWorkspaceProject;
		this.projectSelection = {} as IProjectSelection;

		this.security.preventMobileAccess();
		this.resetPagination();

		this.gridOptions = {
			onClick: this.onClick,
			autoHeight: true,
			disableInitialSort: true
		};
		this.settings = this.interactionSettingsService.getSettings();

		this.selectionUtils = SelectionUtils.createInteractionsSelectionUtils(this);
		this.contextMenuUtils = new this.interactionsContextMenuUtils(this);
		this.interactionActions = this.interactionActionsService.initialize(this);
	};

	private resetPagination = (): void => {
		this.pagination = new Pagination(InteractionExplorer.REQUESTED_DOCUMENTS);
	};

	showNoCpOrProjectsError = (): boolean => {
		return this.errors.noContentProviders || this.errors.noProjects;
	};

	onAccountChanged = (newProps?: IProjectSelection): void => {
		this.currentPageDocuments = [];
		this.refreshGrid(this.currentPageDocuments);
		this.applyProjectSelection(newProps);
		this.refreshInteractionExplorer();
	};

	onProjectChanged = (newProps: IProjectSelection): void => {
		this.applyProjectSelection(newProps);
		this.refreshInteractionExplorer();
	};

	workspaceChanged = (workspace: Unit) => {
		this.cleanProjectErrors();
		if (workspace) {
			this.projectSelection.contentProviderId = workspace.contentProviderId;
			this.projectSelection.accountId = workspace.accountId;
		} else {
			this.projectSelection.contentProviderId = -1;
			this.projectSelection.accountId = -1;
		}
	};

	workspaceProjectChanged = (newProject?: WorkspaceProjectData) => {
		this.cleanProjectErrors();
		this.currentPageDocuments = [];
		this.refreshGrid(this.currentPageDocuments);
		// selected some project and need to input
		if (newProject) {
			this.project = newProject;
			this.projectSelection.projectId = newProject.projectId;
			this.projectSelection.projectName = newProject.projectName;
		}

		if (this.projectSelection.projectId === null || this.projectSelection.projectId === -1) {
			this.projectSelection.projectId = undefined;
		}

		if (!this.projectsAmount) {
			//projects not yet loaded
			return;
		}

		this.refreshInteractionExplorer();
	};

	private cleanProjectErrors = (): void => {
		this.errors.noProjectSelected = false;
		this.errors.noProjectAttributes = false;
		this.errors.tooManyProjects = false;
	};

	projectsLoaded = (amount: OptionsAmount): void => {
		this.projectsAmount = amount;
		this.workspaceProjectChanged();
	};

	onProjectsLoading = (loadingPromise: Promise<any>) => {
		this.loading.promise = PromiseUtils.old(loadingPromise);
	};

	errorsChanged = (errors: string[]): void => {
		this.errors.messages = errors;
	};

	onInitialLandingSelectionCompleted = (newProps: IProjectSelection): void => {
		this.applyProjectSelection(newProps);
	};

	private refreshInteractionExplorer = (): ng.IPromise<void> => {
		if (this.isProjectSelected()) {
			if (this.projectUIInitialized) {
				this.resetGrid();
				this.resetUISelections();
			}

			return this.refreshInteractionExplorerAssets()
				.then(() => {
					this.refreshInteractionExplorerDocuments();
				});
		} else {
			return this.$q.when();
		}
	};

	private resetUISelections = (): void => {
		this.$scope.$broadcast('resetColumnSelection');
		this.interactionSettingsService.resetSettings();
		this.settings = this.interactionSettingsService.getSettings();
		this.resetFilter();
	};

	private applyProjectSelection = (props: IProjectSelection): void => {
		if (props) {
			this.projectSelection = props;
			this.project = props;
		}
	};

	private refreshInteractionExplorerDocuments = (): void => {
		if (this.projectUIInitialized && this.projectAssetInitialized) {
			this.resetInteractionControls();
			this.loading.promise = PromiseUtils.old(this.updateDocuments(false).then(() => {}, () => {}));
		}
	};

	private resetInteractionControls = (): void => {
		this.populateTableMenuOptions();
		this.resetExistingDocuments();
		this.resetPagination();
	};

	private refreshInteractionExplorerAssets = (): ng.IPromise<any> => {
		this.projectAssetInitialized = false;
		this.projectAssetsPromise = this.projectSettingsService.getSettings(this.projectSelection)
			.then(assets => {
				this.projectAssets = assets;
				return assets;
			});
		this.attributeSettingsPromise = PromiseUtils.old(this.attributeSettingsService.getProjectSettings(this.projectSelection));
		this.savedFiltersPromise = PromiseUtils.old(this.combinedFiltersService.getProjectFilters(this.projectSelection))
			.then(savedFilters => {
				this.savedFilters = savedFilters;
				return savedFilters;
			});

		this.loading.promise = this.$q.all([
			this.updateInteractionsPermissions(),
			this.projectAssetsPromise,
			this.attributeSettingsPromise,
			this.savedFiltersPromise,
			this.initializeFilterRules(),
		]).then(() => {
			this.projectAssetInitialized = true;
		});
		return this.loading.promise;
	};

	private resetExistingDocuments = (): void => {
		this.allDocuments = [];
		this.allDocumentsMap = {};
	};

	private resetGrid = (): void => {
		this.showGrid = false;
	};

	private populateTableMenuOptions = (): void => {
		this.tableMenuOptions = this.contextMenuUtils.getContextMenu(true);
	};

	pageChanged = (): void => {
		this.$location.search({page: this.pagination.currentPage});
		this.updateDocuments(true).then(() => {}, () => {});
	};

	updateDocuments = (showSpinner: boolean): Promise<void> => {
		if (this.isProjectSelected()) {
			let urlPageNumber = parseInt(this.$location.search().page, 10);
			if (!isNaN(urlPageNumber)) {
				// using such approach as pagination resets the page if we set it on init
				this.pagination.currentPage = urlPageNumber;
			}
			this.documentsUpdateIsInProgress = true;
			let promise = this.getDocuments().then(documents => {
				this.profanityDisguiseService.maskProfanityInDocuments(documents);
				this.currentPageDocuments = documents;
				this.documentsUpdateIsInProgress = false;
				this.refreshGrid(documents);
			});

			if (showSpinner) {
				this.loading.promise = PromiseUtils.old(promise);
			}

			return promise;
		} else {
			return Promise.resolve();
		}
	};

	isSearchDisabled(): boolean {
		return !this.projectUIInitialized || !this.isProjectSelected() || this.documentsUpdateIsInProgress;
	}

	private updateInteractionsPermissions = (): ng.IPromise<void> => {
		if (this.isProjectSelected()) {
			let promise = this.interactionApiService.getInteractionPermissions(
				this.projectSelection.contentProviderId, this.projectSelection.accountId, this.projectSelection.projectId)
				.then(permissions => this.permissions = permissions);
			return (promise as unknown) as ng.IPromise<void>;
		} else {
			this.permissions = {
				deleteMultipleDocuments: false,
				deleteSingleDocument: false
			};
			return this.$q.when();
		}
	};

	isProjectSelected = (): boolean => {
		if (this.isWorkspaceEnabled) {
			let workspaceProject = this.project as WorkspaceProject;
			return WorkspaceProjectUtils.isProjectSelected(workspaceProject);
		} else {
			return ProjectIdentifier.isProjectSelected(this.projectSelection);
		}
	};

	refreshGrid = (items): void => {
		this.lastChange = [].concat(items);
	};

	private onClick = (event, document: CbDocument): void => {
		if (event.ctrlKey && this.selectionUtils.isSupportedType(document)) {
			this.lastChecked = document;
			this.selectionUtils.handleCtrlClick(document);
			this.$scope.$apply();
			return;
		}

		if (this.gridUtils.isMenuClick(event)) {
			let multipleDocuments = this.selectionUtils.areMultipleItemsSelected();
			this.contextMenuTree.showObjectListMenu(event, document, this.contextMenuUtils.getContextMenu(multipleDocuments), 'interactions');
		} else if (this.gridUtils.isBulkCheckbox(event)) {
			this.selectionUtils.handleCheckboxClick(document, this.lastChecked, event.shiftKey);
			this.lastChecked = document;
			this.refreshGrid(this.currentPageDocuments);
			this.$scope.$apply();
		} else if (this.isPreviewTextClick(event)) {
			this.interactionActions.openDocuments([ document ]);
		}
	};

	private isPreviewTextClick = (event): boolean => {
		return this.gridUtils.isElementClick(event, 'preview-text');
	};

	private getDocuments = (): Promise<any> => {
		if (this.docsPromise) {
			this.docsPromise.reject();
		}
		let deferred = PromiseUtils.defer<any>();
		this.docsPromise = deferred;
		let contentProviderId: number = this.projectSelection.contentProviderId;
		let accountId: number = this.projectSelection.accountId;
		let projectId: number = this.projectSelection.projectId;

		let widget: IDocumentWidget = {} as IDocumentWidget;

		widget.name = 'document';
		widget.properties = {
			textMode: DocumentTextMode.MINIMAL,
			contentProviderId,
			contentProviderName: this.projectSelection.contentProviderName,
			accountId,
			accountName: this.projectSelection.accountName,
			project: projectId,
			projectName: this.projectSelection.projectName,
			runAs: this.security.getUser().userEmail,
			widgetType: WidgetType.DOCUMENT_EXPLORER,
			timezoneOffset: new Date().getTimezoneOffset(),
			timezoneName: moment.tz.guess(),
			includeSentenceAttributes: true,
			audioDocument: false,
			analyticFeedbackPreviewDocument: false,
			documentLevelOnly: false,
			page: {
				lookAheadLimit: this.pagination.pageSize,
				sortMetric: {
					direction: AnSortDirection.DESC,
					displayName: PreviewSortAttributes.DOC_DATE
				},
				start: this.pagination.getPageStart()
			},
			dateRangeP1: this.getDateRangeFilter(),
			adhocFilter: this.getAdhocFilter(),
			appliedFilters: {
				type: FilterTypes.AND,
				filters: this.getFilterItems(MixedItemType.SAVED_FILTER)
			},
			drillFilters: [],
			selectedAttributes: this.interactionExplorerColumns
				.filter(column => column.selected && column.object?.type === ObjectType.ATTRIBUTE)
				.map(column => new AttributeGrouping(column.object.platformName)),
			selectedModels: this.interactionExplorerColumns
				.filter(column => column.selected && column.object?.type === ObjectType.MODEL)
				.map(column => parseInt(column.object.platformName, 10))
		};

		this.widget = widget;

		this.interactionApiService
			.getDocumentsSubmittedForDeletion(contentProviderId, accountId, projectId).then(documentsSubmittedForDeletionIds => {
				if (documentsSubmittedForDeletionIds.length > 0) {
					let documentsDrillFilter: DrillFilter = this.buildDocumentsDrillFilter(documentsSubmittedForDeletionIds);
					widget.properties.drillFilters.push(documentsDrillFilter);
				}

				this.getAnDocumentData(widget).then(response => {
					deferred.resolve(response);
				}, (error) => {
					deferred.reject(error);
				});
		});
		return deferred.promise;
	};

	private getAnDocumentData(widget: IDocumentWidget): any {
		return this.reportDataApiService.getAnDocumentData(widget)
		.then(result => {
			let data: any[] = result.data;

			if (data.length === this.pagination.pageSize) {
				this.enableNextPage();
			} else {
				this.disableNextPage();
			}

			this.populateProjectId(data);
			data = this.matchAlreadyShownDocuments(data);

			return data;
		}, err => {
			this.currentPageDocuments = [];
			this.refreshGrid(this.currentPageDocuments);

			this.globalNotificationService.addSuccessNotification(this.locale.getString('interactionExplorer.couldNotLoadDocuments'));
		});
	}

	buildDocumentsDrillFilter(documentsSubmittedForDeletionIds: number[]): DrillFilter {
		let documentsFilter: string = this.buildDocumentsFilter(documentsSubmittedForDeletionIds);
		return this.buildDrillFilter(documentsFilter);
	}

	private buildDocumentsFilter(documentsSubmittedForDeletionIds: number[]): string {
		let filter = documentsSubmittedForDeletionIds
			.map(documentId => `(_id_document:${documentId})`)
			.join(' OR ');

		filter = `NOT (${filter})`;

		return filter;
	}

	private buildDrillFilter(filterString: string): DrillFilter {
		return {
			type: DrillType.CUSTOM_GROUP,
			values: [
				this.locale.getString('preview.feedbackSelection'),
				filterString
			]
		} as DrillFilter;
	}

	private getAdhocFilter = (): AdhocFilter => {
		let rules = this.getFilterItems(MixedItemType.FILTER_RULE) as IFilterRule[];
		rules = rules.filter(rule => !FilterRuleTypes.isDocumentDateAttributeRule(rule))
			.map(rule => rule.type === FilterRuleType.text
				? this.textFilterService.getFilterRule(rule.value)
				: rule);
		rules.pushAll(this.getHardcodedFilters());
		return {
			type: FilterTypes.AND,
			filterRules: rules
		};
	};

	private getHardcodedFilters(): IFilterRule[] {
		return _.chain(this.filter?.items || [])
			.filter(item => item.type === MixedItemType.HARDCODED_FILTER)
			.map(item => item.entity as HardcodedFilterItem)
			.map(item => this.convertHardcodedFilter(item))
			.value();
	}

	private convertHardcodedFilter(item: HardcodedFilterItem): IFilterRule {
		switch (item.type) {
			case HardcodedFilterType.SKIP_RECENT:
				let now = moment();
				let tenMinutesBefore = now.add(-10, 'minutes').endOf('minute'); // round to minute
				let query = `cb_date_created_utc:[0 TO ${tenMinutesBefore.toDate().getTime()}]`;
				return {
					type: FilterRuleType.esQueryRule,
					esQueryObject: {
						keyword: query
					},
				};

			case HardcodedFilterType.SHARED_DOCUMENTS:
				return {
					attributeDisplayName: this.locale.getString('widget.exportDocumentId'),
					attributeName: '_id_document',
					displayName: 'Document Id',
					type: FilterRuleType.stringEquality,
					matchMode: FilterMatchModeValue.IS,
					values: item.values as IFilterRuleValue[]
				};
			default: throw new Error(`Unknown hardcoded filter: ${item.type}`);

		}
	}

	private getDateRangeFilter(): DateFilter {
		let dateRule = _.find(this.getFilterItems(MixedItemType.FILTER_RULE), FilterRuleTypes.isDocumentDateAttributeRule) as IDateRuleDefinition;
		if (dateRule) {
			return {
				dateFilterMode: dateRule.dateFilterMode as DateFilterMode,
				from: dateRule.fromDate,
				to: dateRule.toDate
			};
		}
		return undefined;
	}

	private getFilterItems = (type: MixedItemType): MixedFilterRuleEntity[] => {
		if (this.filter) {
			return this.filter.items
				.filter(item => item.type === type)
				.map(item => item.entity);
		} else {
			return [];
		}
	};

	private populateProjectId = (documents: any[]): void => {
		let projectId = this.getProjectId();
		documents.forEach(document => document.projectId = projectId);
	};

	private matchAlreadyShownDocuments = (documents: CbDocument[]): CbDocument[] => {
		return documents.map(document => {
			let existingDocument = this.allDocumentsMap[document.id];

			if (!existingDocument) {
				this.allDocumentsMap[document.id] = document;
				this.allDocuments.push(document);
				return document;
			} else {
				return existingDocument;
			}
		});
	};

	private enableNextPage = (): void => {
		this.pagination.totalItems = Math.max(this.pagination.totalItems, this.pagination.currentPage * this.pagination.pageSize + 1);
	};

	private disableNextPage = (): void => {
		this.pagination.totalItems = Math.max(this.pagination.totalItems, this.pagination.currentPage * this.pagination.pageSize);
	};

	getItemsFromAllPages(): CbDocument[] {
		return this.allDocuments;
	}

	getCurrentPageItems(): CbDocument[] {
		return this.currentPageDocuments;
	}

	isSelectionSupported(item: CbDocument): boolean {
		return this.isProjectSelected();
	}

	getProjectId(): number {
		return this.projectSelection.projectId;
	}

	getSelectedDocuments = (): CbDocument[] => {
		return this.selectionUtils.getAllSelectedObjects();
	};

	getSelectedCount = (): number => {
		return this.selectionUtils.getAllSelectedCount();
	};

	isItemSelected(item: CbDocument): boolean {
		return (item as any).selected;
	}

	getUtils(): PaginatedSelectionUtils<CbDocument> {
		return this.selectionUtils;
	}

	getProjectAssets = (): Promise<IProjectSettings> => {
		return Promise.resolve(this.projectAssetsPromise as any);
	};

	getAttributeSettings = (): Promise<ProjectSettingsMap> => {
		return Promise.resolve(this.attributeSettingsPromise as any);
	};

	clearAllSelections(): void {
		this.selectionUtils.clearAllSelections();
	}

	onColumnsChanged = (columns: InteractionExplorerColumnItemOption[]): void => {
		this.interactionExplorerColumns = columns;

		this.projectUIInitialized = true;
		this.showGrid = true;
		this.refreshInteractionExplorerDocuments();
	};

	applyFilter = (filter: MixedFilter, refreshDocuments: boolean): void => {
		this.filter = filter;
		this.updateTextFilter();

		this.saveInteractionsFilter(filter);
		if (refreshDocuments) {
			this.resetUrlPageNumber();
			this.refreshInteractionExplorerDocuments();
		}
	};

	private resetUrlPageNumber(): void {
		this.$location.search({page: 1});
	}

	applyTextFilter = (): void => {
		if (this.isSearchDisabled()) {
			return;
		}

		let filter = this.filter;
		let textRule = this.getTextFilterRule();
		if (this.textFilter) {
			if (!textRule) {
				let entity: IFilterRule = this.filterRuleService.generateNewRule({
					type: FilterRuleType.text,
					name: FilterRuleTypes.TEXT_FILTER_WITH_INPUT}
				);
				textRule = {
					type: MixedItemType.FILTER_RULE,
					entity,
					skipLimit: true
				};
				filter.items.push(textRule);
			}
			//textRule.entity.values = [this.textFilter];
			(textRule.entity as IFilterRule).value = this.textFilter;
		} else if (textRule) {
			filter.items.remove(textRule);
		}
		this.applyFilter(filter, true);
	};

	updateTextFilter = (): void => {
		let textRule = this.getTextFilterRule();
		if (textRule) {
			//this.textFilter = textRule.entity.values[0];
			this.textFilter = (textRule.entity as IFilterRule).value;
		} else {
			this.textFilter = null;
		}
	};

	private getTextFilterRule = (): MixedFilterItem | undefined => {
		return this.filter.items.find(item =>
			item.type === MixedItemType.FILTER_RULE && item.entity?.type === FilterRuleType.text);
	};

	private saveInteractionsFilter = (filter: MixedFilter): void => {
		this.interactionApiService.saveFilter(this.projectSelection, filter);
	};

	resetFilter = (): void => {
		this.filter = this.getDefaultFilter();
		if (this.$location.search().documentLink) {
			this.$location.search({});
		}
	};

	private initializeFilterRules = (): ng.IPromise<any> => {
		let projectFilter: MixedFilter;
		if (this.$location.search().documentLink) {
			return this.documentLinkService.decodeDocumentLink(this.$location.search().documentLink)
				.then(decodedLink => {
					this.filter = this.getSharedDocumentFilter(decodedLink.link.documents);
					this.textFilter = null;
				});
		} else if (this.isProjectSelected()) {
			projectFilter = this.interactionApiService.getFilter(this.projectSelection);
			if (projectFilter && !_.isEmpty(projectFilter.items)) {
				projectFilter.items[0].entity.displayName = this.locale.getString('widget.document_date');
			}
			this.filter = projectFilter || this.getDefaultFilter();
			this.updateTextFilter();
		}
		return this.$q.when();
	};

	private getDefaultFilter = (): MixedFilter => {
		return {
			items: [{
				type: MixedItemType.FILTER_RULE,
				entity: {
					type: FilterRuleType.dateRange,
					name: ClarabridgeMetricName.DOCUMENT_DATE,
					displayName: this.locale.getString('widget.document_date'),
					dateFilterMode: DateFilterMode.LAST_30_DAYS,
				} as IDateRuleDefinition,
				pinned: true,
			}, {
				type: MixedItemType.HARDCODED_FILTER,
				entity: {
					type: HardcodedFilterType.SKIP_RECENT,
					displayName: this.locale.getString('interactionExplorer.excludeRecentDocuments')
				} as HardcodedFilterItem,
				pinned: true,
			}, {
				type: MixedItemType.FILTER_RULE,
				entity: {
					type: FilterRuleType.stringEquality,
					attributeName: '_verbatimtype',
					displayName: _.findWhere(this.projectAssets?.attributes, {name: '_verbatimtype'})?.displayName,
					matchMode: FilterMatchModeValue.IS_NOT,
					values: [{text: 'no_verbatim_text'}],
				},
			}]
		};
	};

	private getSharedDocumentFilter(documentIds: number[]): MixedFilter {
		let filterValues = [].concat(documentIds).map(id => ({text: id + ''} as IFilterRuleValue));
		return {
			items: [{
				type: MixedItemType.HARDCODED_FILTER,
				entity: {
					type: HardcodedFilterType.SHARED_DOCUMENTS,
					displayName: this.locale.getString('interactionExplorer.sharedDocumentFilter'),
					values: filterValues
				} as HardcodedFilterItem,
			}]
		};
	}

	isPageLimitReached(): boolean {
		return ReportPaginationService.isLimitReached(this.currentPageDocuments?.length);
	}
}

app.component('interactionExplorer', {
	controller: InteractionExplorer,
	templateUrl: 'partials/interaction-explorer/interaction-explorer.component.html'
});
