import { Injectable } from '@angular/core';
import { PredictionSettings } from '@cxstudio/system-administration/master-accounts/integrations/prediction-settings';
import { EngagorSettings } from '@cxstudio/system-administration/entities/engagor-settings.class';
import { ExternalAuthSettings } from '@cxstudio/system-administration/master-accounts/integrations/external-auth-settings';
import { VoiceSettings } from '@cxstudio/system-administration/master-accounts/integrations/voice-settings';
import { ConnectorsSettings } from '@cxstudio/system-administration/master-accounts/integrations/connectors-settings';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { TuningSuggestionIntegration } from '@cxstudio/system-administration/master-accounts/integrations/tuning-suggestion-integration';
import { NarrativeIntegration } from '@cxstudio/system-administration/master-accounts/integrations/narrative-integration';
import { QualtricsApiIntegration, QualtricsIntegration } from '@cxstudio/system-administration/master-accounts/integrations/qualtrics-integration';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { ObjectUtils } from '@app/util/object-utils';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { MasterAccountHierarchySettings } from '@app/modules/system-administration/master-account/entities/master-account-hierarchy-settings';

export interface MasterAccountFormData {
	accountId?: number;
	properties: MasterAccountAdminPropertiesFormData;
	licensing: MasterAccountLicensingFormData;
	betaFeatures: { [key: string]: boolean };
	integration: MasterAccountIntegrationFormData;
	hierarchySettings: MasterAccountHierarchySettings;
}

export interface MasterAccountAdminPropertiesFormData {
	accountName: string;
	owners: User[];
	tags: string[];
	maxReportingWidgets: number;
	maxNonReportingWidgets: number;
	maxParallelRequests: number;
	maxCustomerAdmins: number;
	useHsmHostname: boolean;
	isAdminOrg: boolean;
	adminOrgAllowed: boolean;
	dashboardScheduling: boolean;
}

export interface MasterAccountLicensingFormData {
	licensingEnabled: boolean;
	licenseEmails: boolean;
	contractTimezone: string;
	contractCredits: number;
	contractLicenses: any;
}

export interface MasterAccountIntegrationFormData {
	predictiveSettings;
	unitIds?: number[];
	connectorsSettings: ConnectorSettingsFormData;
	voiceSettings: VoiceSettings;
	externalAuthSettings: ExternalAuthSettings;
	predictionSettings: PredictionSettings;
	engagorSettings: EngagorSettingsFormData;
	tuningSuggestionIntegration: TuningSuggestionIntegration;
	narrativeIntegration: NarrativeIntegration;
	qualtricsIntegration: QualtricsIntegration;
	qualtricsApiIntegration: QualtricsApiIntegration;
}

export interface EngagorSettingsFormData extends EngagorSettings {
	engagorEnabled: boolean;
}

export interface ConnectorSettingsFormData extends ConnectorsSettings {
	connectorsEnabled: boolean;
}

@Injectable({
	providedIn: 'root'
})
export class MasterAccountFormDataConverter {

	constructor() {
		// This is intentional
	}

	from = (masterAccount: MasterAccount): MasterAccountFormData => {
		if (!masterAccount) {
			return {
				properties: {
					accountName: null,
				},
				licensing: {
					contractLicenses: {}
				},
				betaFeatures: _.object(_.map(BetaFeature.getDefaultBetaFeatures(), feature => [feature.id, true])),
				integration: {},
				hierarchySettings: {
					customSettingsEnabled: false
				}
			} as MasterAccountFormData;
		}

		let masterAccountForm = {
			accountId: masterAccount.accountId,
			properties: _.pick(masterAccount, ['accountName', 'owners',
				'tags', 'maxReportingWidgets', 'maxNonReportingWidgets', 'maxParallelRequests',
				'maxCustomerAdmins', 'useHsmHostname', 'isAdminOrg', 'dashboardScheduling']),
			licensing: _.pick(masterAccount, ['licensingEnabled', 'licenseEmails', 'contractTimezone',
				'contractCredits', 'contractLicenses']),
			betaFeatures: masterAccount.betaFeatures,
			integration: _.pick(masterAccount, ['predictiveSettings', 'unitIds', 'connectorsSettings',
				'voiceSettings', 'externalAuthSettings', 'predictionSettings', 'engagorSettings',
				'tuningSuggestionIntegration', 'narrativeIntegration', 'qualtricsIntegration', 'qualtricsApiIntegration']),
			hierarchySettings: ObjectUtils.copy(masterAccount.hierarchySettings),
		} as MasterAccountFormData;

		let masterAccountCopy = ObjectUtils.copy(masterAccountForm);
		masterAccountCopy.integration.engagorSettings.engagorEnabled = masterAccount.engagorEnabled;
		masterAccountCopy.integration.connectorsSettings.connectorsEnabled = masterAccount.connectorsEnabled;

		masterAccountCopy.integration.connectorsSettings.connectorsEnabled = masterAccount.connectorsEnabled;
		masterAccountCopy.integration.predictionSettings.provider
			= masterAccountCopy.integration.predictionSettings.provider || null;
		masterAccountCopy.integration.voiceSettings.transcriptionService
			= masterAccountCopy.integration.voiceSettings.transcriptionService || null;
		return masterAccountCopy;
	};

	to = (masterAccountForm: MasterAccountFormData): MasterAccount => {
		return _.extend({
			accountId: masterAccountForm.accountId,
			betaFeatures: masterAccountForm.betaFeatures,
			engagorEnabled: masterAccountForm.integration.engagorSettings?.engagorEnabled,
			connectorsEnabled: masterAccountForm.integration.connectorsSettings?.connectorsEnabled,
			hierarchySettings: masterAccountForm.hierarchySettings,
		}, masterAccountForm.properties, masterAccountForm.licensing, masterAccountForm.integration) as MasterAccount;
	};
}
