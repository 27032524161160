import Widget from '@cxstudio/dashboards/widgets/widget';
import { StatsModeService } from '@app/modules/reports/utils/stats-mode.service';
import { KeyboardUtils, Key } from '@app/shared/util/keyboard-utils.class';

export class AdditionalInfoToggleComponentController implements ng.IComponentController {

	readonly LABEL_UPDATE_THRESHOLD = 15_000;

	private widget: Widget;
	private data: any[];
	private rootWidget: Widget;
	private onOpenAdditionalInformation: () => void;

	constructor(
		private statsModeService: StatsModeService
	) {
	}

	$onInit(): void {
	}

	hasAdditionalInfo = (): boolean => {
		return this.widget && this.data && this.statsModeService.hasAdditionalTableInfo(this.widget, this.data, this.rootWidget);
	};

	getAdditionalInfoHover = (): string => {
		if (this.hasAdditionalInfo()) {
			return this.statsModeService.getAdditionalInfoHover(this.widget, this.data, this.rootWidget);
		}
		return '';
	};

	toggle = (): void => {
		if (this.onOpenAdditionalInformation && this.isToggleAllowed()) {
			this.onOpenAdditionalInformation();
		}
	};

	private isToggleAllowed = () => !this.statsModeService.isFilteringOnInteractionNetworkWidget(this.widget, this.rootWidget);

	toggleKb = (event: KeyboardEvent) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			this.toggle();
		}
	};

}

app.component('additionalInformationToggle', {
	bindings: {
		widget: '<',
		data: '<',
		rootWidget: '<',
		onOpenAdditionalInformation: '&'
	},
	controller: AdditionalInfoToggleComponentController,
	templateUrl: 'partials/widgets/additional-information-toggle.component.html'
});