import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { HtmlUtils } from '@app/shared/util/html-utils.class';



@Component({
	selector: 'widget-filters-errors',
	template: `
		<alert type="warning" *ngIf="showNoFiltersAvailableWarning">
			<span [i18n]="'reportFilters.noFiltersAvailable'"></span>
		</alert>
		<alert type="success" *ngIf="filterSaveSuccess">
			<span [innerHTML]="getLastSavedFilterText()"></span>
		</alert>

		<alert type="warning" *ngIf="filtersOverloadedSaved">
			<span [innerHTML]="getFiltersOverloadedSavedText()"></span>
		</alert>

		<alert type="info" *ngIf="filtersOverloaded">
			<span [innerHTML]="getFiltersOverloadedText()"></span>
		</alert>

	`
})

export class WidgetFiltersErrorsComponent {
	@Input() showNoFiltersAvailableWarning = false;
	@Input() filterSaveSuccess = false;
	@Input() lastSavedFilter = '';
	@Input() filtersOverloadedSaved = false;
	@Input() maxFilterLevels = 5;
	@Input() filtersOverloaded = false;

	constructor(private readonly locale: CxLocaleService) {
	}

	getLastSavedFilterText = () => this.locale.getString('reportFilters.filterSaveSuccess',
		{ filterName: HtmlUtils.escapeHtml(this.lastSavedFilter)});
	getFiltersOverloadedSavedText = () => this.locale.getString('reportFilters.filterCountWarning',
		{ filterName: HtmlUtils.escapeHtml(this.lastSavedFilter), filterMaxCount: this.maxFilterLevels });
	getFiltersOverloadedText = () => this.locale.getString('reportFilters.filterCountNote',
		{ filterMaxCount: this.maxFilterLevels });
}

app.directive('widgetFiltersErrors', downgradeComponent({component: WidgetFiltersErrorsComponent}));

