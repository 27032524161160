import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxHttpService } from '@app/core';
import { Unit } from './unit';

@Injectable({
	providedIn: 'root'
})
export class UnitsApi {

	constructor(
		private cxHttp: CxHttpService
	) {}

	getCurrentMasterAccountUnits = (): Promise<Unit[]> => {
		return this.cxHttp.get(`rest/units`);
	};

	getAllAvailableUnits = (): Promise<Unit[]> => {
		return this.cxHttp.get(`rest/units/available`);
	};

	getMasterAccountUnits = (masterAccountId: number): Promise<Unit[]> => {
		return this.cxHttp.get(`rest/units/master-account/${masterAccountId}`);
	};

}

app.service('unitsApi', downgradeInjectable(UnitsApi));
