import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';

@Component({
	selector: 'color-input',
	template: `
	<div class="d-flex h-2_5rem w-100-percent"
		ngbDropdown
		#ngDropdown="ngbDropdown"
		container="body"
		(click)="toggle($event)">
		<button type="button" class="input-group w-100-percent no-border no-background p-0 kb-focusable text-gray-900"
			[disabled]="disabled" ngbDropdownAnchor>
			<input
				required
				class="w-100-percent"
				placeholder="{{'common.enterColor'|i18n}}"
				[(ngModel)]="value"
				[disabled]="disabled"
				pattern="^#[A-Fa-f0-9]{6}$"
				(keyup.enter)="ngDropdown.toggle()"
				(ngModelChange)="onChangeCallback($event)">
			<div *ngIf="showSwatch" [style.background]="value" class="color-swatch h-2_5rem w-2_5rem"></div>
		</button>
		<div ngbDropdownMenu class="p-0 cursor-default">
			<responsive-color-picker *ngIf="ngDropdown.isOpen()"
				[(color)]="value"
				[clickLocation]="clickLocation"
				[pinnedPalette]="pinnedPalette"
				(colorChange)="onChangeCallback($event); ngDropdown.close()"
				(cancel)="ngDropdown.close()">
			</responsive-color-picker>
		</div>

	</div>`,
	styles: [`
		input:not(:last-child) { max-width: calc(100% - 32px); }
	`],
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ColorInputComponent), multi: true}
	]
})
export class ColorInputComponent implements ControlValueAccessor, OnInit {

	@Input() showSwatch = true;
	@Input() pinnedPalette?: string;
	disabled: boolean;
	value: string;
	clickLocation: {x: number; y: number};

	@ViewChild(NgbDropdown, {static: false}) private dropdown: NgbDropdown;

	private onTouchedCallback: (val: string) => void = _.noop;
	onChangeCallback: (val: string) => void = _.noop;

	constructor(
		private ref: ChangeDetectorRef,
		@Inject('widgetsEditService') private widgetsEditService: WidgetsEditService,
		private currentObjects: CurrentObjectsService
	) {}

	ngOnInit(): void {
		if (!this.pinnedPalette && this.currentObjects.isEditMode()) {
			this.pinnedPalette = this.widgetsEditService.getDashboard()?.properties.color;
		}
	}

	toggle(ev: MouseEvent) {
		if (!this.disabled) {
			let y = $(ev.target).offset().top + $(ev.target).outerHeight(); // bottom of the target element
			let x = $(ev.target).offset().left;
			this.clickLocation = {x, y };
			this.dropdown.toggle();
		}
	}

	writeValue(val: string): void {
		if (val !== this.value) {
			this.value = val;
			this.onChangeCallback(this.value);
		}
	}

	registerOnChange(fn: (val: string) => void): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: (val: string) => void): void {
		this.onTouchedCallback = fn;
	}

	setDisabledState?(isDisabled: boolean): void {
		this.disabled = isDisabled;
		this.ref.markForCheck();
	}
}

app.directive('colorInput', downgradeComponent({component: ColorInputComponent}));
