import { Component, OnInit, ChangeDetectionStrategy, EventEmitter, Output, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { FontDefinition, FontOptions } from '@cxstudio/common/font-options.class';

@Component({
	selector: 'font-selector',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => FontSelectorComponent), multi: true}
	],
	template: `
	<simple-dropdown
		valueField="name"
		ngDefaultControl
		[(value)]="value"
		(onChange)=onFontChange($event)
		[options]="options"
	></simple-dropdown>
	`
})
export class FontSelectorComponent implements OnInit, ControlValueAccessor {
	@Output() ngModelChange: EventEmitter<void> = new EventEmitter();
	value: string;
	options = FontOptions.getOptions();
	private onChangeCallback: (val: string) => void = _.noop;
	private onTouchedCallback: () => void = _.noop;
	
	constructor() {}

	ngOnInit(): void {}

	onFontChange = (value: FontDefinition): void => {
		setTimeout(_ => this.ngModelChange.emit());
		this.value = value.name;
		this.onChangeCallback(this.value);
		this.onTouchedCallback();
	};

	writeValue(value: any): void {
		if (value !== this.value) {
			this.value = value;
			this.onChangeCallback(this.value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}

	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}	
}

app.directive('fontSelector', downgradeComponent({component: FontSelectorComponent}));