import { Component, OnInit, Input, EventEmitter } from '@angular/core';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TreeNode } from '@app/shared/components/forms/tree/tree-node';
import { IAsset } from '@app/modules/access-management/groups/asset';
import { ModificationDelta } from '@app/modules/access-management/groups/modification-delta';
import { ISelectableAsset } from '@app/modules/access-management/groups/selectable-asset';

export interface SideBySideSelectorModel {
	availableTree: TreeNode[];
	selectedTree: TreeNode[];
	availableLabel: string;
	selectedLabel: string;
	disabled: boolean;
	hiddenAssets: TreeNode[];
	modalTitle?: string;
	description?: string;
}

@Component({
	selector: 'side-by-side-selector-modal',
	template: `
		<div class="form-horizontal">
			<modal-header
				[modalTitle]="modalTitle"
				(cancel)="cancel()">
			</modal-header>
			<div class="modal-body">
				{{description}}
				<side-by-side-selector
					[availableTree]="availableTree"
					[selectedTree]="selectedTree"
					[availableLabel]="availableLabel"
					[selectedLabel]="selectedLabel"
					[hiddenAssets]="hiddenAssets"
					[disabled]="disabled"
					(onChange)="onChange($event)"
				></side-by-side-selector>
			</div>
			<save-modal-footer (save)="continue()" (cancel)="cancel()">
			</save-modal-footer>
		</div>
	`
})
export class SideBySideSelectorModalComponent implements OnInit {

	availableTree: TreeNode[];
	selectedTree: TreeNode[];
	availableLabel: string;
	selectedLabel: string;
	disabled: boolean;
	hiddenAssets: TreeNode[];
	modalTitle: string;
	description = '';
	change = new EventEmitter<any>();
	changes: ModificationDelta<ISelectableAsset>;

	@Input() input: any;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly activeModal: NgbActiveModal,
	) {}

	ngOnInit(): void {
		this.availableTree = this.input?.availableTree;
		this.selectedTree = this.input?.selectedTree;
		this.availableLabel = this.input?.availableLabel;
		this.selectedLabel = this.input?.selectedLabel;
		this.disabled = this.input?.disabled;
		this.hiddenAssets = this.input?.hiddenAssets;
		this.modalTitle = this.input?.modalTitle;
		this.description = this.input?.description;
		this.changes = new ModificationDelta();
	}

	onChange($event: any): void {
		this.changeHandlerFn(this.changes, this.hiddenAssets as any, $event);
	}

	private changeHandlerFn = (changes: ModificationDelta<ISelectableAsset>, initial: IAsset[], asset: ISelectableAsset): void => {
		if (asset.selected) {
			changes.added.push(asset);
			changes.removed = _.reject(changes.removed, item => this.isAssetsEqual(asset, item));
		} else {
			if (_.any(initial, item => this.isAssetsEqual(asset, item))) {
				changes.removed.push(asset);
			}
			changes.added = _.reject(changes.added, item => this.isAssetsEqual(asset, item));
		}
	};

	private isAssetsEqual = (asset1: IAsset, asset2: IAsset): boolean => {
		return asset1.assetType === asset2.assetType
			&& asset1.assetId === asset2.assetId;
	};

	continue(): void {
		this.activeModal.close(this.changes);
	}

	cancel(): void {
		this.activeModal.close();
	}

}
