import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectionStrategy, OnDestroy } from '@angular/core';
import { IColorGrades, IColorGradeOptions } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { MetricColorGradesService } from '@app/modules/metric/services/metric-color-grades.service';
import { ColorMetricDefinition } from '@app/modules/metric/definition/color-metric-definition';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { MetricData } from '@app/modules/metric/entities/metric-data';
import { Observable, Subscription } from 'rxjs';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { AlertLevel } from '@discover/unified-angular-components/dist/unified-angular-components';

@Component({
	selector: 'metric-format-tab',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './metric-format-tab.component.html'
})
export class MetricFormatTabComponent implements OnInit, OnDestroy {
	@Input() format: NumberFormatSettings;
	@Output() formatChange = new EventEmitter<NumberFormatSettings>();
	@Input() metric: MetricData;
	@Input() defaultsRestored: Observable<void>;

	@Output() onValidityChange = new EventEmitter<any>();
	@Output() definitionChange = new EventEmitter<ColorMetricDefinition>();

	definition: ColorMetricDefinition;
	colorGrades: IColorGrades;
	options: Partial<IColorGradeOptions>;
	calculationEnabled: boolean;
	AlertLevel = AlertLevel;

	private defaultsRestoredSubscription$: Subscription;

	constructor(
		private metricColorGradesService: MetricColorGradesService
	) {}

	ngOnInit(): void {
		this.reloadConditionalFormatting();
		this.defaultsRestoredSubscription$ = this.defaultsRestored.subscribe(this.reloadConditionalFormatting);
	}

	ngOnDestroy(): void {
		this.defaultsRestoredSubscription$.unsubscribe();
	}

	private reloadConditionalFormatting = (): void => {
		this.definition = this.metric?.definition as ColorMetricDefinition;
		if (this.showConditionalFormatting()) {
			this.colorGrades = this.metricColorGradesService.getCalculationColorGrades(this.definition);
			this.options = this.metricColorGradesService.getCalculationOptions(this.definition.type);
			this.calculationEnabled = _.isUndefined(this.definition.calculation.calculationColorEnabled)
				? true
				: this.definition.calculation.calculationColorEnabled;
		}
	};

	showConditionalFormatting = (): boolean => !!this.definition?.calculation;

	isPredefinedMetric = (): boolean => {
		let type = this.metric?.definition?.type;
		return type === MetricType.SENTIMENT
			|| type === MetricType.EASE_SCORE
			|| type === MetricType.NUMERIC_BREAKDOWN;
	};

	changeHandler = (format: NumberFormatSettings): void => {
		this.format = format;
		this.formatChange.emit(Object.assign({}, format));
	};

	colorGradesChangeHandler = (colorGrades: IColorGrades): void => {
		this.metricColorGradesService.updateMetricCalculations(this.definition, colorGrades);
		this.definitionChange.emit(this.definition);
	};

	minMaxChangeHandler = (isMin: boolean, value: number): void => {
		if (isMin) {
			this.definition.calculation.min = value;
		} else {
			this.definition.calculation.max = value;
		}
		this.definitionChange.emit(this.definition);
	};

	toggleCalculation = (): void => {
		this.definition.calculation.calculationColorEnabled = this.calculationEnabled;
		this.definitionChange.emit(this.definition);
	};
}
