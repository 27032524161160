import ILocale from '@cxstudio/interfaces/locale-interface';
import { SharingStatus } from '@cxstudio/common/sharing-status';
import { VoiceSettings } from '@cxstudio/system-administration/master-accounts/integrations/voice-settings';


export default class TableFormattersService {

	constructor(
		private $filter,
		private dateService,
		private locale: ILocale
	) { }

	plainTextFormatter = (object: any, path: string): string => {
		return this.getFieldByPath(object, path);
	};

	longTextFormatter = (object: any, path: string): string => {
		let text: string = this.getFieldByPath(object, path);
		if (text) {
			let truncated = this.$filter('truncateName')(text, 80);
			return `<div title='${text}'>${truncated}</div>`;
		}
	};

	linkFormatter = (object: any, path: string): string => {
		let link: string = this.getFieldByPath(object, path);
		if (link) {
			return `<a href="${link}" target="_blank">${link}</a>`;
		}
	};

	dateFormatter = (object: any, path: string): string => {
		let value = this.getFieldByPath(object, path);
		if (!value) return;
		let date: Date = new Date(value);
		return this.dateService.format(date);
	};

	dateWithTZFormatter = (object: any, path: string): string => {
		let value = this.getFieldByPath(object, path);
		if (!value) return ' - ';
		let date = new Date(value);
		return this.dateService.formatWithTimezone(date);
	};

	statusFormatter = (object: any, field: string): string => {
		let sharedIcon = `<span class='br-shared q-icon-users' title='${this.locale.getString('dashboard.shared')}'></span>`;
		let privateIcon = `<span class='br-private q-icon-user' title='${this.locale.getString('dashboard.private')}'></span>`;
		let globalIcon = `<span class='br-public q-icon-globe' title='${this.locale.getString('dashboard.public')}'></span>`;

		let status = object[field];

		switch (status) {
			case SharingStatus.PUBLIC: return globalIcon;
			case SharingStatus.SHARED: return sharedIcon;
			case SharingStatus.PRIVATE: return privateIcon;
			default: return status;
		}
	};

	getFieldByPath(object: any, path: string): any {
		return path.split('.').reduce((prev, curr) => {
			return prev ? prev[curr] : undefined;
		}, object);
	}

	genericYesNoFormatter<T>(property: keyof T): (object: T) => string {
		return (object: T) => {
			return object[property]
				? this.locale.getString('common.yes')
				: '';
		};
	}
}


app.service('tableFormattersService', TableFormattersService);
