import * as _ from 'underscore';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { MasterAccountConnectorsStatus } from './master-account-connectors-status';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export class ConnectorsStatusTableColumnsService {
	private columns: Array<TableColumn<any>>;

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		return [{name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'applicationName',
			displayName: this.locale.getString('administration.application'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'userAccess',
			displayName: this.locale.getString('mAccount.connectorsUserAccess'),
			formatter: this.userAccessFormatter,
			width: 0.1
		}];
	};

	private userAccessFormatter = (object: MasterAccountConnectorsStatus): string => {
			return object.userAccess
				? this.locale.getString('mAccount.connectorsInternalUsersOnly')
				: '';
	};
}