
import { MetricWidgetComparison } from '@cxstudio/reports/entities/metric-widget-properties';
import { IDataObject } from '@cxstudio/reports/entities/report-interfaces';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { MetricConstants } from '../reports/providers/cb/constants/metric-constants.service';



export enum ComparisonValue {
	VALUE = 'value',
	DIFF = 'diff'
}

export enum MultiMetricDataType {
	METRIC = 'metric',
	COMPARISON = 'comparison'
}

export class MultiMetricDataProcessor {
	static processData(
		rawData: Array<IDataObject<any>>, selectedMetrics: ReportCalculation[], visualProps: VisualProperties, metricConstants: MetricConstants,
		comparisons?: MetricWidgetComparison[], metricDisplayNamePostfix?: string
	): any[] {
		const BUCKET = 0;

		let rawDataEntity: IDataObject<any>;
		if (!_.isEmpty(rawData)) {
			rawDataEntity = rawData[BUCKET];
		}

		let comparison = comparisons && comparisons[0];

		let result: any[] = MultiMetricDataProcessor.getDataFromSelectedMetrics(
			rawDataEntity, selectedMetrics, visualProps, comparison, metricDisplayNamePostfix);

		if (visualProps.visualization === WidgetVisualization.GAUGE && comparisons && !_.isEmpty(comparisons)) {
			let selectedMetricName: string = selectedMetrics[0].name;
			let comparisonsBasedData: any[] = MultiMetricDataProcessor.getDataFromComparisons(comparisons, rawDataEntity, selectedMetricName);

			result.pushAll(comparisonsBasedData);
		}
		result.forEach(d => {
			const translatedMetricName = metricConstants.getMetricByName(d.name)?.displayName;
			d.displayName = translatedMetricName ?? d.displayName;
		});

		return result;
	}

	private static getDataFromSelectedMetrics(
		rawDataEntity: IDataObject<any>, selectedMetrics: ReportCalculation[], visualProps: VisualProperties,
		comparison?: MetricWidgetComparison, metricDisplayNamePostfix?: string
	): any[] {

		return selectedMetrics.map((metric) => {
			let metricName: string = metric.name;
			let metricDisplayName: string = metric.displayName;

			let metricData = {
				type: MultiMetricDataType.METRIC,
				// metrics and attributes id can be the same so we use name
				id: metric.name,
				name: metricName,
				displayName: metricDisplayNamePostfix ? `${metricDisplayName} ${metricDisplayNamePostfix}` : metricDisplayName,
				value: rawDataEntity[metricName]
			} as any;

			// always undefined. display '-' instead of emptyness
			if (visualProps.visualization === WidgetVisualization.GAUGE) {
				metricData.diff = '-';
			}

			if (visualProps.previousPeriod || visualProps.trendArrow) {
				let delta;
				if (comparison) {
					let comparisonIdentifier: string = comparison.identifier;
					metricData.historicValue = MultiMetricDataProcessor.getComparisonValue(rawDataEntity, metricName, comparisonIdentifier);
					delta = MultiMetricDataProcessor.getComparisonDiff(rawDataEntity, metricName, comparisonIdentifier);
				} else {
					metricData.historicValue = rawDataEntity[`period_2_${metricName}`];
					metricData[metric.popField] = rawDataEntity[`${metric.popField}_${metricName}`];
					delta = rawDataEntity[`delta_${metricName}`];
				}
				if (delta || delta === 0) {
					metricData.delta = delta;
				}
			}
			return metricData;
		});
	}

	private static getDataFromComparisons(
		comparisons: MetricWidgetComparison[], rawDataEntity: IDataObject<any>, selectedMetricName: string
	): any[] {
		return comparisons.map(comparison => {
			return {
				type: MultiMetricDataType.COMPARISON,
				id: comparison.identifier,
				displayName: comparison.displayName,
				value: MultiMetricDataProcessor.getComparisonValue(rawDataEntity, selectedMetricName, comparison.identifier),
				diff: MultiMetricDataProcessor.getComparisonDiff(rawDataEntity, selectedMetricName, comparison.identifier),
				calculation: comparison.calculation
			};
		});
	}

	private static getComparisonValue(
		rawDataEntity: IDataObject<any>, metricName: string, comparisonIdentifier: string
	): number {
		return MultiMetricDataProcessor.getComparisonData(rawDataEntity, metricName, comparisonIdentifier, ComparisonValue.VALUE);
	}

	private static getComparisonDiff(
		rawDataEntity: IDataObject<any>, metricName: string, comparisonIdentifier: string
	): number {
		return MultiMetricDataProcessor.getComparisonData(rawDataEntity, metricName, comparisonIdentifier, ComparisonValue.DIFF);
	}

	private static getComparisonData(
		rawDataEntity: IDataObject<any>, metricName: string, comparisonIdentifier: string, comparisonValue: ComparisonValue
	): number {
		return rawDataEntity[`${metricName}_${comparisonIdentifier}_${comparisonValue}`];
	}
}
