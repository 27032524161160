import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '../cx-locale.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';

@Injectable({
	providedIn: 'root'
})
export class CxHeadersUtilService {

	constructor(
		@Inject('security') private security: Security,
		private locale: CxLocaleService
	) {}

	getCustomHeaders = (): { 'ma-id': number; 'Accept-Language': string; Offset: number } => {
		return {
			'ma-id': this.security.getMasterAccountId(),
			'Accept-Language': this.locale.getLocale(),
			Offset: new Date().getTimezoneOffset()
		};
	};
}

app.service('headersUtil', downgradeInjectable(CxHeadersUtilService));
