import { Inject, Injectable } from '@angular/core';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { AnalyticMetricTypes, AnalyticMetricType } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { IColorSelectorPalette } from '@cxstudio/reports/coloring/color-selector.component';
import ChartType from '@cxstudio/reports/entities/chart-type';
import { ColorTypes } from '@cxstudio/reports/entities/colortypes.enum';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { CommonInherentProperties } from '@cxstudio/reports/utils/contextMenu/drill/common-inherent-properties.class';
import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import * as cloneDeep from 'lodash.clonedeep';
import { CommonDrillService } from '../common-drill.service';
import { DefaultDrillProcessor } from './default-drill-processor.class';

@Injectable({
	providedIn: 'root'
})
export class DrillToBarProcessorService extends DefaultDrillProcessor {

	readonly INHERITED_VIS_PROPERTIES = [
		'attributeSelections',
		'primaryGroup',
		'subChartType',
		'size',
		'secondarySize',
		'scale',
		'secondaryScale'].concat(CommonInherentProperties.dualVisual);
	constants = this.metricConstants.get();

	constructor(
		private readonly commonDrill: CommonDrillService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants
	) {
		super();

		this.addInheritedProperties('primaryTimeGrouping');
		this.addInheritedVisualProperties(this.INHERITED_VIS_PROPERTIES);
	}

	drillTo(widget: Widget, drillToType, point: DrillPoint, defaultColor?: IColorSelectorPalette): Widget {
		//replace name and visual
		widget.name = WidgetType.BAR;
		widget.properties.widgetType = WidgetType.BAR;
		widget.visualProperties.subChartType = this.isDrillToColumn(drillToType) ? ChartType.COLUMN : ChartType.BAR;
		if (this.isDrillToColumn(drillToType) || this.isDrillToBar(drillToType)) { // bar+column is not supported
			this.verifyBarType(widget, drillToType);
		}
		widget.visualProperties.visualization = VisualizationType.DUAL;
		widget.displayName += ' - ' + point.groupBy.displayName;

		_.extend(point.groupBy, this.commonDrill.getPrimaryGroupSettingsExtension(widget, point.groupBy));

		//add selectedAttributes
		widget.properties.selectedAttributes = [point.groupBy];
		if (widget.visualProperties.attributeSelections) {
			widget.visualProperties.attributeSelections.primaryGroup = point.groupBy;
			widget.visualProperties.attributeSelections.secondaryGroup = undefined;
			widget.visualProperties.attributeSelections.stackedGroup = undefined;

		} else {
			widget.visualProperties.attributeSelections = {
				primaryGroup: point.groupBy
			};
		}

		if (widget.properties.dateRangeP1 && !widget.properties.dateRangeP2) {
			widget.properties.dateRangeP2 = cloneDeep(widget.properties.dateRangeP1);
		}
		widget.visualProperties.primaryGroup = point.groupBy.name;
		this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.PRIMARY, defaultColor);
		this.commonDrill.adjustColorProperty(widget.visualProperties, ColorTypes.SECONDARY, defaultColor);
		widget.visualProperties.pointColor = undefined;

		this.commonDrill.processSelectedMetric(point, widget, this.setCalculation);
		// process PoP when drilling from historical data point in table widget
		this.commonDrill.setPopForWidgetDrilledFromTableHistoricPoint(widget, point);
		this.commonDrill.processHierarchyMetrics(widget);

		this.intializeDefaultProperties(widget, point);


		let caseVisualizations = widget.visualProperties.caseVisualizations;

		widget = this.inheritPropertiesAndVisProps(widget);

		if (this.isDrillToColumn(drillToType) && AnalyticMetricTypes.isTime(widget.properties.selectedAttributes[0]) && caseVisualizations) {
			widget.visualProperties.caseVisualizations = caseVisualizations;
		}

		return widget;
	}

	private isDrillToColumn(drillToType): boolean {
		return drillToType === 'column';
	}

	private isDrillToBar(drillToType): boolean {
		return drillToType === 'bar';
	}

	private setCalculation(calculationMetric, widget): void {
		if (!calculationMetric)
			calculationMetric = this.constants.VOLUME;
		widget.visualProperties.attributeSelections.yAxis = calculationMetric;
		widget.visualProperties.yAxis = calculationMetric.name;
	}

	private setSecondaryCalculation(calculationMetric, widget): void {
		if (!calculationMetric)
			calculationMetric = this.constants.VOLUME;
		widget.visualProperties.attributeSelections.secondaryYAxis = calculationMetric;
		widget.visualProperties.secondaryYAxis = calculationMetric.name;
	}

	private verifyBarType(widget, drillToType): void {
		let secondaryType = widget.visualProperties.secondaryChartType;
		if (!secondaryType || [VisualizationType.BAR, VisualizationType.COLUMN].indexOf(secondaryType) === -1)
			return;
		if (secondaryType.toLowerCase() !== drillToType) {
			widget.visualProperties.secondaryChartType = drillToType && drillToType.toUpperCase();
		}
	}

	private intializeDefaultProperties(widget, point): void {
		if (!widget.visualProperties.attributeSelections.yAxis) {
			let calculationAttribute = this.commonDrill.getCalculationAttribute(widget, point.seriesType);
			widget.properties.selectedMetrics = [calculationAttribute];
			widget.visualProperties.attributeSelections.yAxis = calculationAttribute;
			widget.visualProperties.yAxis = calculationAttribute.name;
		}

		if (!AnalyticMetricTypes.isHierarchyModel(widget.properties.selectedAttributes[0])) {
			if (AnalyticMetricTypes.isHierarchyEnrichmentProperty(widget.visualProperties.attributeSelections.yAxis)) {
				this.setCalculation(this.constants.VOLUME, widget);
			}
			if (widget.visualProperties.attributeSelections.secondaryYAxis &&
				AnalyticMetricTypes.isHierarchyEnrichmentProperty(widget.visualProperties.attributeSelections.secondaryYAxis)) {
				this.setSecondaryCalculation(this.constants.VOLUME, widget);
			}
		}

		let groupBy = widget.properties.selectedAttributes[0];
		if (groupBy.metricType === AnalyticMetricType.TIME) {
			widget.properties.primaryTimeGrouping = widget.properties.selectedAttributes[0];
			groupBy.sortBy = 'time';
			groupBy.sortOrder = AnSortDirection.ASC;
		} else {
			delete widget.properties.primaryTimeGrouping;
		}
	}
}
