import { AttributeUrlType } from '@cxstudio/reports/providers/cb/constants/attribute-url-type.constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import * as _ from 'underscore';
import { CapitalizationType } from '@cxstudio/services/constants/capitalization-type.enum';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { AnalyticsDataFormattingService } from '@app/modules/widget-visualizations/utilities/analytics-data-formatting.service';
import { FormatterBuilderUtilsService } from '@app/modules/widget-visualizations/formatters/formatter-builder-utils.service';
import { CapitalizationUtils } from '@cxstudio/services/capitalization-utils.class';
import { URLSettings } from '@app/modules/asset-management/entities/settings.interfaces';


type ISlickFormatter = (row, cell, value, columnDef?, dataContext?) => string;

export class FormattersUtils {

	private satScoreDisplayValues;

	constructor(
		private analyticsDataFormatting: AnalyticsDataFormattingService,
		locale: ILocale,
		private formatterBuilderUtils: FormatterBuilderUtilsService,
	) {
		this.satScoreDisplayValues = {
			p: locale.getString('widget.mostLoyal'),
			d: locale.getString('widget.leastLoyal'),
			s: locale.getString('widget.neutral'),
			// eslint-disable-next-line id-blacklist
			undefined: locale.getString('widget.na')
		};
	}

	compositeFormatter = (formatters: ISlickFormatter[]): ISlickFormatter => {
		return (row, cell, value, columnDef, dataContext) => {
			_.each(formatters, formatter =>
				value = formatter(row, cell, value, columnDef, dataContext)
			);
			return value;
		};
	};

	buildTimeGroupingFormatter = (primaryTimeGrouping) => {
		return (row, cell, value) => {
			return this.analyticsDataFormatting.formatTimeLabel(value, primaryTimeGrouping.timeName);
		};
	};

	satScoreFormatter = (value?): string => {
		if (!value) {
			return this.satScoreDisplayValues.undefined;
		}
		return this.satScoreDisplayValues[value.toLowerCase()] || value;
	};

	buildSatScoreTableFormatter = (): ISlickFormatter => {
		return (row, cell, value) => {
			return this.satScoreFormatter(value);
		};
	};

	buildSatScoreFormatter = (): ISlickFormatter => {
		return this.satScoreFormatter;
	};

	buildPredefinedMetricTableFormatter = (attribute): ISlickFormatter => {
		let func = this.analyticsDataFormatting.buildPredefinedMetricFormatter(attribute);
		return (row, cell, value) => {
			return func(value);
		};
	};

	buildStudioMetricFormatter = (studioMetricDefinition): ISlickFormatter => {
		return (row, cell, value) => {
			return this.analyticsDataFormatting.formatStudioMetricLabel(value,
				studioMetricDefinition);
		};
	};

	escapeHtmlFormatter = (formatter): ISlickFormatter => {
		return (row, cell, value, columnDef, dataContext) => {
			let val = formatter ? formatter(row, cell, value, columnDef, dataContext) : value;
			return HtmlUtils.escapeHtml(val);
		};
	};

	addCapitalization = (capitalizationType: CapitalizationType, formatter): ISlickFormatter => {
		capitalizationType = capitalizationType || CapitalizationType.DEFAULT;
		let capsFunction = CapitalizationUtils.getWrappedFormatter(capitalizationType);

		return (row, cell, value, columnDef, dataContext) => {
			let val = formatter ? formatter(row, cell, value, columnDef, dataContext) : value;
			return capsFunction(val);
		};
	};

	buildUrlFormatter = (urlType: AttributeUrlType, attribute: Partial<URLSettings>): ISlickFormatter => {
		switch (urlType) {
			case AttributeUrlType.IMAGE: return this.imageFormatter(attribute);
			case AttributeUrlType.LINK: return this.linkFormatter();
			default: throw new Error(`Invalid url type: ${urlType}`);
		}
	};

	buildNumberGroupFormatter = (): ISlickFormatter => {
		return (row, cell, value, columnDef, dataContext) => {
			return this.formatterBuilderUtils.formatNumberAsString(value);
		};
	};

	private linkFormatter = (): ISlickFormatter => {
		return (row, cell, value) => {
			if (!value)
				return '';
			return `<a target="_blank" class="no-drill" href="${this.convertUrl(value)}">${HtmlUtils.escapeHtml(value)}</a>`;
		};
	};

	private imageFormatter = (attribute: Partial<URLSettings>): ISlickFormatter => {
		return (row, cell, value) => {
			if (!value)
				return '';

			let imageClasses = `image-${attribute.scale} image-${attribute.verticalAlign} image-${attribute.horizontalAlign}`;

			// fallback to link is controlled by css using "empty" attribute
			return `<div class="table-image-cell" style="height:${attribute.tableRowHeight}px">
				<img src="${this.convertUrl(value)}" onerror="this.setAttribute('empty', true)"/>
				<div class="table-image ${imageClasses}" style="background-image: url(${this.convertUrl(value)})"></div>
				<a target="_blank" class="no-drill" href="${this.convertUrl(value)}">${HtmlUtils.escapeHtml(value)}</a>
			</div>`;

		};
	};

	private convertUrl(url: string): string {
		let escapedUrl = String(url).replace(/"/g, '&quot;').replace(/[<>]/g, ''); // to put it into html safely

		if (escapedUrl.startsWith('data:'))
			return escapedUrl;

		if (!escapedUrl.startsWith('http'))
			escapedUrl = 'http://' + escapedUrl;
		return escapedUrl;
	}

}

app.service('formattersUtils', FormattersUtils);

