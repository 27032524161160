import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import Authorization from '@cxstudio/auth/authorization-service';
import { Security } from '@cxstudio/auth/security-service';
import { BrowserInfo } from '@app/util/browser-info';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { LicenseType } from '@cxstudio/common/license-types';

interface UITab {
	heading: string;
	active: boolean;
	template: string;
	class: string;
	id: string;
}

export class MasterAccountSettings {

	private showTab = {
		mAProperties: () => this.security.has('manage_settings'),
		securityAudit: () => this.security.has('conduct_security_audit'),
		organization: () => this.security.has('manage_organizations'),
		reports: () => this.security.has('manage_settings'),
		contracts: () => this.security.has('manage_settings'),
		mobileApplication: () => this.security.has('manage_settings'),
		appearance: () => this.security.has('manage_settings'),
		licensing: () => this.security.has('manage_settings'),
		homePage: () => this.security.has('manage_settings'),
		suggestions: () => this.security.has('manage_settings')
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.PRODUCT_FEEDBACK),
		packagedSolutions: () => this.security.has('manage_settings')
			&& this.betaFeaturesService.isFeatureEnabled(BetaFeature.PACKAGED_SOLUTIONS),
		topicConversions: () => {
			return (
				this.security.isAdminOrgUser()
				&& this.security.getLicenseTypeId() === LicenseType.ANALYZE
				&& this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.QUICK_TRANSLATE)
			);
		}
	};
	tabs: UITab[];
	activeTab: UITab;
	activeTabIndex: number;

	constructor(
		private $scope: ISimpleScope,
		private $location: ng.ILocationService,
		private security: Security,
		private authorization: Authorization,
		private locale: ILocale,
		private betaFeaturesService: BetaFeaturesService,
		private routeService,
		private redirectService: RedirectService,
		private maPropertiesService: MAPropertiesService) {
	}

	$onInit = () => {
		if (BrowserInfo.isTabletOrMobile()) {
			this.redirectService.goToDashboardList();
			return;
		}

		this.security.restrictPage(this.authorization.hasMasterAccountManagementAccess);

		this.initializeTabs();
	};

	selectTab = (selectedTab: UITab): void => {
		this.activeTab = selectedTab;
		this.$location.search({ tab: selectedTab.id });
	};

	isTabActive = (id: string): boolean => {
		return (this.activeTab && this.activeTab.id === id);
	};

	private initializeTabs = (): void => {
		this.tabs = [ ];

		if (this.showTab.mAProperties()) {
			this.tabs.push({
				heading: this.locale.getString('administration.maPropertiesTab'),
				active: true,
				template: 'partials/master-accounts/tabs/properties-tab.html',
				class: 'br-master-account-properties-tab',
				id: 'properties'
			});
		}

		if (this.showTab.securityAudit()) {
			this.tabs.push({
				heading: this.locale.getString('administration.securityAuditTab'),
				active: false,
				template: 'partials/master-accounts/tabs/actions-log-tab.html',
				class: 'br-master-account-audit-tab',
				id: 'audit'
			});
		}

		if (this.showTab.organization()) {
			this.tabs.push({
				heading: this.locale.getString('administration.orgTab'),
				active: false,
				template: 'partials/organizations/organization-tab.html',
				class: 'br-user-management-organization-tab',
				id: 'organization'
			});
		}

		if (this.showTab.licensing()) {
			this.tabs.push({
				heading: this.locale.getString('administration.licensingTab'),
				active: false,
				template: 'partials/master-accounts/tabs/licensing-tab.html',
				class: 'br-master-account-licensing-tab',
				id: 'licensing'
			});
		}

		if (this.showTab.reports()) {
			this.tabs.push({
				heading: this.locale.getString('administration.reports'),
				active: false,
				template: 'partials/master-accounts/tabs/master-account-reports-tab.html',
				class: 'br-reports-tab',
				id: 'reports'
			});
		}

		if (this.showTab.contracts()) {
			this.tabs.push({
				heading: this.locale.getString('administration.contractsTab'),
				active: false,
				template: 'partials/master-accounts/tabs/master-account-contracts-tab.html',
				class: 'cx-ma-contracts-tab',
				id: 'contracts'
			});
		}

		if (this.showTab.mobileApplication()) {
			this.tabs.push({
				heading: this.locale.getString('administration.mobileApplicationTab'),
				active: false,
				template: 'partials/master-accounts/tabs/mobile-application-tab.html',
				class: 'cx-mobile-application-tab',
				id: 'mobile-application'
			});
		}

		let betaFeatureEnabled = false;
		BetaFeature.values().forEach((item) => {
			let feature = this.betaFeaturesService.getFeature(item);
			betaFeatureEnabled = betaFeatureEnabled || feature.enabled;
		});

		if (betaFeatureEnabled && this.security.has('manage_settings')) {
			this.addBetaTab();
		}

		if (this.showTab.appearance()) {
			this.tabs.push({
				heading: this.locale.getString('administration.appearance'),
				active: false,
				template: 'partials/master-accounts/tabs/master-account-appearance-tab.html',
				class: 'cx-ma-appearance-tab',
				id: 'appearance'
			});
		}

		if (this.showTab.homePage()) {
			this.tabs.push({
				heading: this.locale.getString('administration.homePage'),
				active: false,
				template: 'partials/master-accounts/tabs/home-page-tab.html',
				class: 'cx-ma-home-page-tab',
				id: 'home-page'
			});
		}

		if (this.showTab.suggestions()) {
			this.tabs.push({
				heading: this.locale.getString('administration.tuningSuggestionsTab'),
				active: false,
				template: 'partials/master-accounts/tabs/tuning-suggestions-tab.html',
				class: 'cx-ma-tuning-suggestions-tab',
				id: 'tuning-suggestions'
			});
		}

		if (this.showTab.packagedSolutions()) {
			this.tabs.push({
				heading: this.locale.getString('administration.packagedSolutionsTab'),
				active: false,
				template: 'partials/master-accounts/tabs/packaged-solutions-tab.html',
				class: 'cx-ma-packaged-solutions-tab',
				id: 'packaged-solutions'
			});
		}

		if (this.showTab.topicConversions()) {
			this.tabs.push({
				heading: this.locale.getString('topicConversions.heading'),
				active: false,
				template: 'partials/master-accounts/tabs/topic-conversions-tab.html',
				class: 'cx-ma-topic-conversions-tab',
				id: 'topic-conversions'
			});
		}

		this.processSelectedTab();
		this.$scope.$on('$routeUpdate', (event) => this.processSelectedTab());
	};

	private processSelectedTab = (): void => {
		if (this.tabs.length > 0) {
			this.routeService.handleSelectedTab(this.tabs, (selectedTab, tabIndex) => {
				this.activeTabIndex = tabIndex;
				this.tabs[this.activeTabIndex].active = true;
			}, () => {
				this.activeTabIndex = 0;
				this.tabs[this.activeTabIndex].active = true;
			});
		}
	};

	// add beta tab
	addBetaTab = () => {
		this.tabs.push({
			heading: this.locale.getString('administration.betaTab'),
			active: false,
			template: 'partials/master-accounts/tabs/beta-features-tab.html',
			class: 'a br-beta-features-tab',
			id: 'beta-features'
		});
	};
}

app.component('masterAccountSettings', {
	controller: MasterAccountSettings,
	template: `
<page-content-header [title]="'mAccount.menuOption' | i18n" class="mb-24"></page-content-header>
<div class="col-xs-12 ma-management-screen {{$ctrl.activeTab.id}}">
	<div class="br-list-box tab-content overflow-hidden">
		<uib-tabset active="$ctrl.activeTabIndex">
			<uib-tab
				ng-repeat="tab in $ctrl.tabs"
				heading="{{ tab.heading }}"
				active="tab.active"
				class="{{ tab.class }}"
				select="$ctrl.selectTab(tab)">
				<div ng-if="$ctrl.activeTab === tab" ng-include="tab.template"></div>
			</uib-tab>
		</uib-tabset>
	</div>
</div>`
});
