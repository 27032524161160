import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class MapDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		let grouping =  selectedAttribute?.displayName;
		let background = this.visual.mapBackground?.displayName;
		if (grouping && background) {
			let total = this.utils.getTotal(this.visual, data);
			let palette = this.utils.getPaletteDescription('mapPalette', this.visual.color);
			return this.locale.getString('widgetDescription.mapDescription', {
				grouping,
				background,
				total,
				palette
			});
		}
		return '';
	};
	
}
