
import * as _ from 'underscore';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import { GroupType } from '@app/modules/user-administration/groups/group-type';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';

export class UsersGroupMembership implements ng.IComponentController {

	resolve;
	groups: any[];
	close: (result: {$value: any}) => void;
	dismiss: () => void;
	gridType = GridTypes.USERS_BULK_GROUPS;

	constructor(private readonly downgradeDialogService: DowngradeDialogService) {}

	$onInit(): void {
		this.groups = this.processGroups(this.resolve.groups, this.resolve.belongsToGroups);
	}

	private processGroups(groups, belongsToGroups): any[] {
		return _.chain(groups)
			.filter(this.hierarchyFilter)
			.each(group => {
				group.id = group.groupId;
				group.level = 0;
				group.displayName = group.groupName;
				if (_.findWhere(belongsToGroups, { groupId: group.groupId })) {
					group.disableAdd = true;
				}
			}).value();
	}

	save = (): void => {
		let res = _.groupBy(this.groups, (group) => {
			switch (group.updateValue) {
				case BulkUpdateValue.ADD:
					return 'added';
				case BulkUpdateValue.REMOVE:
					return 'removed';
				default:
					return 'none';
			}
		});
		this.close({$value: res});
	};

	cancel = (): void => {
		if (!this.isDataChanged()) {
			this.dismiss();

			return;
		}

		this
			.downgradeDialogService
			.showUnsavedChangesDialogAndResolve(
				this.save,
				this.dismiss,
				'group.unsavedChangesHeader',
				'group.unsavedChanges',
				false
			)
			.catch(() => {})
		;
	};

	private isDataChanged(): boolean {
		return _.some(this.groups, (group) => {
			return group.updateValue && group.updateValue !== BulkUpdateValue.NONE;
		});
	}

	private hierarchyFilter(item): boolean {
		return item.type !== GroupType.HIERARCHY && item.type !== GroupType.WHOLE_HIERARCHY;
	}

}

app.component('usersGroupMembership', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	controller: UsersGroupMembership,
	templateUrl: 'partials/user-administration/groups/users-group-membership.component.html'
});
