import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import PlotBandsOptions from '@app/modules/plot-lines/plot-bands/plot-bands-options';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import PlotBand from '../plot-band';

@Component({
	selector: 'plot-bands-configurer',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './plot-bands-configurer.component.html'
})
export class PlotBandsConfigurerComponent implements OnInit {
	@Input() visualProps: VisualProperties;
	@Input() axisNames: string[];

	plotBandLabels: string[];
	topPlotBands: PlotBand[];
	bottomPlotBands: PlotBand[];
	columnsCount: number;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		if (!this.visualProps.plotBands) {
			this.visualProps.plotBands = PlotBandsOptions.quadrantBands();
		}

		this.columnsCount = 2; // 2x2 grid
		this.plotBandLabels = this.getBandLabels();

		let half = Math.ceil(this.visualProps.plotBands.bands.length / 2);
		this.topPlotBands = this.visualProps.plotBands.bands.slice(0, half);
		this.bottomPlotBands = this.visualProps.plotBands.bands.slice(half);
	}

	onPlotBandsEnabledChange = (): void => {
		this.ref.detectChanges();
	};

	// adding for searchability:
	// widget.bandTopLeft, widget.BandTopRight, widget.bandBottomLeft, widget.bandBottomRight
	private getBandLabels(): string[] {
		let vertical = ['Top', 'Bottom'];
		let horizontal = ['Left', 'Right'];
		return _.chain(vertical)
			.map(v => _.map(horizontal, h => v + h))
			.flatten()
			.map(key => this.locale.getString('widget.band' + key))
			.value();
	}

	getAxisType = (index: number): PlotLineAxis => {
		return index === 0 ? PlotLineAxis.primary : PlotLineAxis.secondary;
	};

	getPlotBandIdentifierClass = (plotBandIndex: number): string => {
		return `plot-band-${plotBandIndex}`;
	};

}

app.directive('plotBandsConfigurer', downgradeComponent({component: PlotBandsConfigurerComponent}));
