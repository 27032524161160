import * as cloneDeep from 'lodash.clonedeep';
import * as _ from 'underscore';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { Injectable } from '@angular/core';
import { IScorecardFilter } from '@app/modules/scorecards/entities/scorecard-filter';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { AssetPromise, ProjectAssetsHelper } from '@app/modules/units/project-assets-helper.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { GeneratedFolderType } from '@cxstudio/report-filters/generated-folder-type';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { AccountOrWorkspaceProjectData } from '@app/modules/units/workspace-project/workspace-project-data';
import { ScorecardFiltersManagementApiService } from '@app/modules/scorecards/filters/scorecard-filters-management-api.service';
import { WorkspaceScorecardFiltersManagementApiService } from '@app/modules/scorecards/filters/workspace-scorecard-filters-management-api.service';

@Injectable({
	providedIn: 'root'
})
export class ScorecardFiltersManagementService {
	folderSvc: IFolderService;
	SCORECARD_FOLDER;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly scorecardFiltersManagementApi: ScorecardFiltersManagementApiService,
		private readonly workspaceScorecardFiltersManagementApi: WorkspaceScorecardFiltersManagementApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('FolderService') private readonly FolderService,
	) {
		this.folderSvc = new this.FolderService(FolderTypes.FILTER);

		this.SCORECARD_FOLDER = {
			name: this.locale.getString('filter.scorecardFilters'),
			id: FilterTypes.CXSCORECARD,
			description: '',
			type: FolderTypes.FILTER,
			level: 0,
			_collapsed: true,
			generatedFolderType: GeneratedFolderType.SYSTEM,
			children: []
		};
	}

	getScorecardFolders = (
		scorecardFilters: IScorecardFilter[], project: AccountOrWorkspaceProjectData, masterAccountId: number
	): any[] => {
		const projectData = WorkspaceTransitionUtils.getProjectData(project);
		let scorecardFolders = [];
		let rootScorecardFolder = cloneDeep(this.SCORECARD_FOLDER);

		scorecardFilters.forEach((filter: IScorecardFilter): void => {
			let scorecardFolder = _.findWhere(scorecardFolders, { id: 'scorecard_' + filter.scorecardId });
			if (!scorecardFolder) {
				scorecardFolder = this.folderSvc.getScorecardsFolder(
					filter.scorecardName, filter.scorecardId, FolderTypes.FILTER, rootScorecardFolder);
				_.extend(scorecardFolder, { masterAccountId }, projectData);
				scorecardFolders.push(scorecardFolder);
			}

			filter.parentId = scorecardFolder.id;
			filter.id = filter.uniqueId; // scorecard filter doens't have numuric id, use name as unique id
		});

		if (!isEmpty(scorecardFolders)) {
			scorecardFolders.push(rootScorecardFolder);
		}

		return scorecardFolders;
	};

	getScorecardFilters = (project: AccountOrWorkspaceProject): AssetPromise<IScorecardFilter[]> => {
		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)
				|| !WorkspaceTransitionUtils.isProjectSelected(project)) {
			return Promise.resolve([]) as AssetPromise<IScorecardFilter[]>;
		}
		return ProjectAssetsHelper.getAssetsForProject<IScorecardFilter[]>(
			project,
			(accountProject) => this.scorecardFiltersManagementApi.getFilters(accountProject),
			(workspaceProject) => this.workspaceScorecardFiltersManagementApi.getFilters(workspaceProject),
			(projectId) => []
		);
	};

	updateDisableState = (filter: IScorecardFilter, disabled: boolean): Promise<void> => {
		let scorecardFilter = _.omit(filter, 'id', 'parent', 'parentId', 'rule');
		return this.scorecardFiltersManagementApi.updateDisableState(
			scorecardFilter as IScorecardFilter, disabled);
	};
}

app.service('scorecardFiltersManagementService', downgradeInjectable(ScorecardFiltersManagementService));
