import { MetricManagementController } from '@cxstudio/metrics/metric-management.component';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import * as _ from 'underscore';
import { IMetricActionsService } from '@cxstudio/metrics/metric-actions-service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import { ScorecardMetric } from '../../projects/scorecards/entities/scorecard-metric';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import * as cloneDeep from 'lodash.clonedeep';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';
import ManageMetricService from '@app/modules/metric/services/manage-metric.service';

export interface IMetricContextMenuUtils {
	getContextMenu(item: any): any[];
	isVisibleObject(item: any): boolean;
}

// eslint-disable-next-line prefer-arrow-callback
app.factory('MetricContextMenuUtils', function(FolderService, MetricActionsService, locale,
	AnalyticMetricTypes, security, manageMetricService: ManageMetricService, downgradeToastService, betaFeaturesService: BetaFeaturesService
) {
	return class extends BaseContextMenuUtils implements IMetricContextMenuUtils {
		private scope: MetricManagementController;
		private folderSvc: any;
		private actionsService: IMetricActionsService;


		private readonly OPTIONS = {
			VIEW: {text: locale.getString('common.view'), name: 'view',
				func: (metric) => this.actionsService.viewMetric(metric)},

			EDIT: {text: locale.getString('common.edit'), name: 'edit',
				func: (metric) => this.actionsService.editMetric(metric)},

			EDIT_PREDEFINED: {text: locale.getString('common.edit'), name: 'edit',
				func: (metric) => this.actionsService.editPredefinedMetric(metric)},

			RENAME_FOLDER: {text: locale.getString('common.rename'), name: 'rename',
				func: (folder) => this.actionsService.renameFolder(folder)},

			RENAME_METRIC: {text: locale.getString('common.rename'), name: 'rename',
				func: (metric) => this.actionsService.renameMetric(metric)},

			MOVE: {text: locale.getString('dashboard.move'), name: 'move', searchBox: true, isExpandable: true },

			SHARE: {text: locale.getString('dashboard.share'), name: 'share',
				func: (metric) => this.actionsService.shareMetrics([metric])},

			CREATE_TEMPLATE: {
				text: locale.getString('templates.menuSaveAsTemplate'),
				name: 'createTemplate',
				func: (metric) => this.actionsService.saveMetricAsTemplate(metric)
			},

			EDIT_LABELS: {text: locale.getString('common.editLabels'), name: 'editLabels'},

			CREATE_METRIC: {text: locale.getString('metrics.createNewMetric'), name: 'create_metric_in_folder',
				func: (folder: any) => this.actionsService.createMetric(undefined, folder)},

			CREATE_SUBFOLDER: {text: locale.getString('dashboard.addSubfolder'), name: 'add_folder',
				func: (folder: any) => this.actionsService.createFolder(folder)},

			REMOVE_FOLDER: {text: locale.getString('common.delete'), name: 'delete',
				func: (metric) => this.actionsService.removeFolder(metric)},

			REMOVE_SUBFOLDER: {text: locale.getString('dashboard.menuDeleteSubfolder'), name: 'delete',
				func: (folder: any) => this.actionsService.removeFolder(folder)},

			REMOVE_METRIC: {text: locale.getString('common.delete'), name: 'delete',
				func: (metric) => this.actionsService.deleteMetric(metric)},

			BULK_REMOVE_METRIC: {text: locale.getString('metrics.bulkDelete'), name: 'bulkDelete'},

			COPY: {text: locale.getString('dashboard.copy'), name: 'copy',
				func: (metric) => this.actionsService.duplicateMetric(metric)},

			SHOW: {text: locale.getString('common.show'), name: 'show-item',
				func: (metric) => this.actionsService.toggleHide(metric), disabled: false},

			HIDE: {text: locale.getString('common.hide'), name: 'hide-item',
				func: (metric) => this.actionsService.toggleHide(metric), disabled: false},

			DEPENDENCIES: {text: locale.getString('common.dependencies'), name: 'dependencies',
				func: (filter: any) => this.actionsService.showDependencies(filter)},

			TRANSFER_SELECTED: {text: locale.getString('dashboard.transferOwnerWidgetConfirmOption'), name: 'transfer'},

			SHOW_HIDE: {text: locale.getString('common.showHide'), name: 'show_hide'},

			SHOW_HIDDEN: { text: locale.getString('dashboard.showAllHidden'), name: 'toggleHidden', func: () => {
				this.scope.ui.hideMetrics = false;
			}},

			HIDE_HIDDEN: { text: locale.getString('dashboard.hideAllHidden'), name: 'toggleHidden', func: () => {
				this.scope.ui.hideMetrics = true;
			}},

			ENABLE: {text: locale.getString('common.enable'), name: 'enable', func: (metric) => {
				this.actionsService.toggleScorecardMetricState(metric, false);
			}},

			DISABLE: {text: locale.getString('common.disable'), name: 'disable', func: (metric) => {
				this.actionsService.toggleScorecardMetricState(metric, true);
			}}
		};

		constructor(scope: MetricManagementController) {
			super();
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.METRIC);
			this.actionsService = new MetricActionsService(this.scope);
		}

		getContextMenu(item: any): any[] {
			if (this.scope.selectionUtils.multipleObjectsSelected()) {
				return this.getMultiSelectMenuOptions();
			}

			if (item.type === FolderTypes.METRIC) {
				return this.getFolderMenuOptions(item);
			}

			if (AnalyticMetricTypes.isPredefinedMetric(item)) {
				return this.getPredefinedMetricMenuOptions(item);
			}

			if (AnalyticMetricTypes.isScorecardMetric(item)) {
				let toggleOption = cloneDeep((item as ScorecardMetric).disabled
					? this.OPTIONS.ENABLE
					: this.OPTIONS.DISABLE
				);
				if (!security.has('manage_scorecards')) {
					toggleOption.disabled = true;
					toggleOption.disabledMessage = locale.getString('common.noPermission');
				}
				return [ this.OPTIONS.VIEW, toggleOption];
			}

			return this.getMetricMenuOptions(item);
		}

		isVisibleObject(item: any): boolean {
			return this.scope.selectionUtils.visibleObjFilter(item);
		}

		private getPredefinedMetricMenuOptions(item: any): any[] {
			return security.has(MasterAccountPermissionAction.CREATE_METRIC) && security.has('manage_settings')
				? [ this.OPTIONS.EDIT_PREDEFINED ]
				: [ this.OPTIONS.VIEW ];
		}

		private getMetricMenuOptions(item: any): any[] {
			let items: any[] = [];
			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.metricList, item, (metric, folderTo) => {
				this.actionsService.moveToFolder(metric, folderTo);
			});

			let canCreateMetric = security.has(MasterAccountPermissionAction.CREATE_METRIC);
			if (this.isOwner(item)) {
				if (canCreateMetric) {
					items.push(this.OPTIONS.EDIT);
					items.push(this.OPTIONS.RENAME_METRIC);
				} else {
					items.push(this.OPTIONS.VIEW);
				}

				if (manageMetricService.canShare(item)) {
					items.push(this.OPTIONS.SHARE);
				}

				if (betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_TEMPLATES)
						&& security.isAdminOrgUser()
						&& security.has(MasterAccountPermissionAction.CREATE_METRIC)
						&& security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES)
						// Filtered and value metrics will be addressed later
						&& item.definition.type !== MetricType.FILTER && item.definition.type !== MetricType.VARIABLE) {
					items.push(this.OPTIONS.CREATE_TEMPLATE);
				}

				if (moveToItems && moveToItems.length > 0) {
					items.push(MenuDivider, this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}

				if (canCreateMetric) {
					if (!moveToItems || !moveToItems.length) {
						items.push(MenuDivider);
					}
					items.push(this.OPTIONS.COPY);
				}

				let showHideOptions = this.getShowHideOptions(item);
				items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));
				items.push(this.OPTIONS.DEPENDENCIES);

				items.push(MenuDivider, this.OPTIONS.REMOVE_METRIC);
				this.addTransferringOption(items, [item]);
			} else {
				const canEditMetric = this.canEditMetric(item);
				if (canCreateMetric && canEditMetric) {
					items.push(this.OPTIONS.EDIT);
				} else {
					items.push(this.OPTIONS.VIEW);
				}

				if (moveToItems && moveToItems.length > 0) {
					items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
				}

				if (canCreateMetric) {
					items.push(this.OPTIONS.COPY);
				}

				if (manageMetricService.canShare(item)) {
					items.push(this.OPTIONS.SHARE);
				}
				let showHideOptions = this.getShowHideOptions(item);
				items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));
				if (canEditMetric)
					items.push(this.OPTIONS.DEPENDENCIES);
			}
			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		private canEditMetric = (metric: Metric): boolean => {
			return AssetPermission.EDIT === metric.permissionLevel;
		};

		private getShowHideOptions = (item: any | any[]) => {
			let showOption = angular.copy(this.OPTIONS.SHOW);
			let hideOption = angular.copy(this.OPTIONS.HIDE);
			let toggleVisibility = (this.scope.ui.hideMetrics) ?
				this.OPTIONS.SHOW_HIDDEN :
				this.OPTIONS.HIDE_HIDDEN;

			if (item.constructor === Array) {
				let selectedItems = item;
				hideOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(true, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
				showOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(false, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
			} else {
				if (item.hide) {
					hideOption.disabled = true;
					hideOption.func = _.noop;
				} else {
					showOption.disabled = true;
					showOption.func = _.noop;
				}
			}

			return [showOption, hideOption, MenuDivider, toggleVisibility];
		};

		private getMultiSelectMenuOptions(): any[] {
			let items: any[] = [];

			let selectedMetrics = _.filter(this.scope.metricList, (metric) =>
				this.scope.selectionUtils.isSupportedType(metric) && metric.selected
			) as Metric[];

			let moveToItems = _.uniq(_.flatten(_.map(selectedMetrics, (metric) => {
				let bulkFunction = this.wrapFunctionForBulkAction(
					(item, folderTo) => this.actionsService.moveToFolder(item, folderTo), selectedMetrics);

				return this.folderSvc.getFoldersForMove(this.scope.metricList, metric, bulkFunction);
			})), false, (moveItem) => moveItem.text);

			let isOwner = (metrics) => {
				return _.filter(metrics, this.isOwner).length === metrics.length;
			};

			if (manageMetricService.hasSharePermission()) {
				items.push(this.extend(this.OPTIONS.SHARE, {func: () => {
					this.actionsService.shareMetrics(selectedMetrics);
				}}));
			}

			if (_.filter(selectedMetrics, (metric: Metric) => {
				return metric.ownerId === security.loggedUser.user.userId || metric.permissionLevel === AssetPermission.EDIT;
			}).length === selectedMetrics.length) {
				let bulkEditLabels = () => {
					let ids = _.pluck(selectedMetrics, 'id');
					this.actionsService.bulkUpdateLabels().result.then((labels) => {
						let updateLabelsEntity = new BulkUpdateLabelsEntity(labels, ids);
						this.actionsService.updateLabelsRequest(updateLabelsEntity).then((updateLabelsPromise) => {
							const updatedMetrics = updateLabelsPromise.data;
							_.each(updatedMetrics, updatedMetric => {
								updatedMetric.selected = false;
							});
							const msg = updatedMetrics.length + ' ' + locale.getString('metrics.metrics');
							downgradeToastService.addToast(`${locale.getString('filter.updatedLabel')} ${msg}.`);
							this.scope.refreshGrid(updatedMetrics);
						}).catch(err => {
							_.noop();
						});
					}).catch(err => {
						_.noop();
					});
				};
				items.push(MenuDivider, this.extend(this.OPTIONS.EDIT_LABELS, {func: bulkEditLabels}));
			}

			if (moveToItems && moveToItems.length > 0) {
				items.push(MenuDivider, this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}

			let showHideOptions = this.getShowHideOptions(selectedMetrics);
			items.push(MenuDivider, this.extend(this.OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			if (isOwner(selectedMetrics)) {
				items.push(MenuDivider, this.extend(this.OPTIONS.BULK_REMOVE_METRIC, {func: () =>
					this.actionsService.bulkDeleteMetrics(selectedMetrics)
				}));

				this.addTransferringOption(items, selectedMetrics);
			}
			if (this.metricsSelectedAreScorecard(selectedMetrics)) {
				let enabledDisabledOptions = this.getEnabledDisabledBulkOptions(selectedMetrics);
				items = enabledDisabledOptions;
			}

			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		private metricsSelectedAreScorecard(selectedItems: Metric[]): boolean {
			return _.every(selectedItems, AnalyticMetricTypes.isScorecardMetric);
		}

		private getEnabledDisabledBulkOptions(selectedMetrics: Metric[]): any[] {
			let options = [];
			let enableOption = angular.copy(this.OPTIONS.ENABLE);
			let disableOption = angular.copy(this.OPTIONS.DISABLE);

			let enabledScorecardMetrics = _.filter(selectedMetrics, metric => !metric.disabled);
			let disabledScorecardMetrics = _.filter(selectedMetrics, metric => metric.disabled);



			enableOption.func = this.wrapFunctionForBulkAction(
				(item) => this.actionsService.toggleScorecardMetricState(item, false), selectedMetrics);


			disableOption.func = this.wrapFunctionForBulkAction(
				(item) => this.actionsService.toggleScorecardMetricState(item, true), selectedMetrics);

			if (enabledScorecardMetrics.length) {
				options.push(disableOption);
			}
			if (disabledScorecardMetrics.length) {
				options.push(enableOption);
			}

			return options;
		}

		private addTransferringOption(menuItems: any[], selectedItems: any[]): void {
			if (!WorkspaceTransitionUtils.isProjectSelected(this.scope.project)) {
				return;
			}

			menuItems.push(this.extend(this.OPTIONS.TRANSFER_SELECTED, {
				func: () => {
					this.actionsService.bulkTransferMetrics(selectedItems, this.scope.project).then(() => {
						this.scope.selectionUtils.clearSelections();
						this.scope.selectionUtils.refreshObjects(selectedItems);
					});
				}
			}));
		}

		private getFolderMenuOptions(item: any): any[] {
			let items = [];

			if (security.has(MasterAccountPermissionAction.CREATE_METRIC))
				items.push(this.OPTIONS.CREATE_METRIC);

			if (item.level < FolderContextMenuUtils.MAX_DEPTH - 1) {
				items.push(this.OPTIONS.CREATE_SUBFOLDER);
			}

			items.push(this.OPTIONS.RENAME_FOLDER);

			let moveToItems = this.folderSvc.getFoldersForMove(this.scope.metricList, item, (metric, folderTo) => {
				this.actionsService.moveToFolder(metric, folderTo);
			});

			if (moveToItems && moveToItems.length > 0 && !item.dateFilter) {
				items.push(this.extend(this.OPTIONS.MOVE, {items: moveToItems}));
			}

			items.push(MenuDivider);

			if (item.level > 0) {
				items.push(this.OPTIONS.REMOVE_SUBFOLDER);
			} else {
				items.push(this.OPTIONS.REMOVE_FOLDER);
			}

			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		private isOwner(metric: any): boolean {
			return manageMetricService.isOwner(metric);
		}
	};
});
