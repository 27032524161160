import { Component, OnInit, Input, Output, EventEmitter,
	ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { FilterConditionsService as FilterConditionService } from '@app/shared/components/filter/filter-condition.service';


@Component({
	selector: 'filter-lanes',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div *ngIf="lanes">
			<div *ngFor="let lane of lanes; let index = index"
				class="filter-statement objects-filter-statement w-100-percent d-flex align-items-center p-relative">

				<filter-lane
					class="flex-fill"
					[filter]="lane"
					[columns]="getAvailableColumns(index)"
					(filterChange)="onFilterChange(index, $event)"
				></filter-lane>

				<div class="filter-tools">
					<button
						class="btn btn-icon"
						(click)="removeLane(index)">
						<span aria-hidden="true" class="q-icon q-icon-minus"></span>
					</button>
					<button
						class="btn btn-icon ml-0"
						[disabled]="!canAddLane()"
						(click)="addLane(index)">
						<span aria-hidden="true" class="q-icon q-icon-add"></span>
					</button>
				</div>
			</div>
		</div>
	`
})
export class FilterLanesComponent implements OnInit, OnChanges {

	readonly MAX_LANES = 5;
	readonly EMPTY_FILTER = {pinned: false} as ObjectListFilter;

	@Input() maxLanes: number;
	@Input() columns: ColumnFilterOption[];
	@Input() pinnedColumns: ColumnFilterOption[];
	@Input() refreshTrigger: boolean;
	@Output() filtersChange = new EventEmitter<ObjectListFilter[]>();

	lanes: ObjectListFilter[] = [];

	constructor(
		private filterConditionService: FilterConditionService,
	) {}

	ngOnInit(): void {
		this.initFilterLanes();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.pinnedColumns?.currentValue) {
			this.pinnedColumns = changes.pinnedColumns?.currentValue;
		}
		this.lanes = [];
		this.initFilterLanes();
	}

	initFilterLanes(): void {
		if (this.pinnedColumns)
			this.pinnedColumns.forEach(pinnedColumn => {
				this.lanes.push(this.getPinnedFilter(pinnedColumn));
			});
		if (!this.maxLanes)
			this.maxLanes = this.MAX_LANES;
		this.addEmptyFilter();
		this.onChange();
	}

	private addEmptyFilter = (): void => {
		this.lanes.push(Object.assign({}, this.EMPTY_FILTER));
	};

	private getPinnedFilter = (column: ColumnFilterOption): ObjectListFilter => {
		let conditions = this.filterConditionService.getConditions(column.columnFilterType);
		return {
			field: column.value,
			condition: conditions[0].value,
			pinned: true
		};
	};

	getAvailableColumns = (index: number): ColumnFilterOption[] => {
		let filter = this.lanes[index];
		if (filter.pinned) {
			return [this.pinnedColumns[index]];
		} else {
			let selectedColumns = this.lanes
				.filter((lane, i) => index !== i)
				.map((lane, i) => lane.field);
			return this.columns.filter((group) => !selectedColumns.contains(group.value));
		}
	};

	private onChange = (): void => {
		this.filtersChange.emit(this.lanes);
	};

	canAddLane = (): boolean => {
		let pinned = this.pinnedColumns ? this.pinnedColumns.length : 0;
		return this.lanes.length < this.columns.length + pinned
			&& this.lanes.length < this.maxLanes;
	};

	addLane = (index): void => {
		this.lanes.insert(index + 1, {} as ObjectListFilter);
		this.onChange();
	};

	removeLane = (index: number): void => {
		let filter = this.lanes[index];
		if (filter.pinned) {
			filter.value = undefined;
		} else {
			this.lanes.removeAt(index);
			if (!this.hasRegularLanes()) {
				this.addEmptyFilter();
			}
		}
		this.onChange();
	};

	private hasRegularLanes = (): boolean => {
		let pinned = this.pinnedColumns ? this.pinnedColumns.length : 0;
		return this.lanes.length > pinned;
	};

	onFilterChange = (index: number, filter: ObjectListFilter): void => {
		this.lanes[index] = filter;
		this.onChange();
	};
}
