import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { RedirectService } from './redirect-service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { DashboardBookStateService } from '@cxstudio/dashboards/books/dashboard-book-state.service';
import { RedirectDestinationValues } from './redirect-destination';

declare let app: ng.IModule;

export class NavigationService {

	showSidebar: boolean;

	constructor(
		private readonly dashboardBookState: DashboardBookStateService,
		private readonly $location: ng.ILocationService,
		private readonly downgradeDialogService: DowngradeDialogService,
		private readonly redirectService: RedirectService,
	) {}

	showUserPreferences = (): Promise<any> => {
		let modal: NgbModalRef = this.downgradeDialogService.openUserPreferencesDialog();
		return modal.result;
	};

	changeDashboard = (dashboard: Dashboard, edit: boolean) => {
		this.dashboardBookState.resetEditedFromBook();
		if (dashboard.type === 'dashboard') {
			let dashboardPath = `/home/${dashboard.id}`;

			if (edit) {
				this.$location.path(dashboardPath).search('edit', true);
			} else {
				this.$location.path(dashboardPath);
			}
		} else if (dashboard.type === 'dashboardSet') {
			this.$location.path(`/dashboard/${dashboard.id}`).search({});
		}
	};

	getUserVoiceRedirectionURL = (): string =>
		this.redirectService.getRedirectionUrl(RedirectDestinationValues.USER_VOICE);

}

app.service('navigationService', NavigationService);
