import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

export interface QuickFilter {
	value: PredefinedFilterValue;
	text: string;
	type: PredefinedMetricConstants;
}

@Injectable({
	providedIn: 'root'
})
export class QuickFilterService {
	constructor(
		private locale: CxLocaleService,
		private readonly betaFeatureService: BetaFeaturesService,
	) {	}

	private getCommonFilters(): QuickFilter[] {
		return [{
			value: null,
			text: this.locale.getString('preview.allFeedback'),
			type: PredefinedMetricConstants.SENTIMENT,
		}];
	}

	private getSentimentFilters(): QuickFilter[] {
		const sentimentFilters = [
			this.createQuickFilter(PredefinedMetricConstants.SENTIMENT, PredefinedFilterValue.POSITIVE),
			this.createQuickFilter(PredefinedMetricConstants.SENTIMENT, PredefinedFilterValue.NEGATIVE),
			this.createQuickFilter(PredefinedMetricConstants.SENTIMENT, PredefinedFilterValue.ALL),
		];
		if (this.betaFeatureService.isFeatureEnabled(BetaFeature.MACHINE_LEARNING_SENTIMENT)) {
			// Legacy sentiment does not have mixed sentiment.
			sentimentFilters.push(
				this.createQuickFilter(PredefinedMetricConstants.SENTIMENT, PredefinedFilterValue.MIXED),
			);
		}

		return sentimentFilters;
	}

	private getEffortFilters(): QuickFilter[] {
		return [
			this.createQuickFilter(PredefinedMetricConstants.EASE_SCORE, PredefinedFilterValue.POSITIVE),
			this.createQuickFilter(PredefinedMetricConstants.EASE_SCORE, PredefinedFilterValue.NEGATIVE),
			this.createQuickFilter(PredefinedMetricConstants.EASE_SCORE, PredefinedFilterValue.ALL),
		];
	}

	private getEmotionFilters(): QuickFilter[] {
		return [
			this.createQuickFilter(PredefinedMetricConstants.EMOTION, PredefinedFilterValue.POSITIVE),
			this.createQuickFilter(PredefinedMetricConstants.EMOTION, PredefinedFilterValue.NEUTRAL),
			this.createQuickFilter(PredefinedMetricConstants.EMOTION, PredefinedFilterValue.NEGATIVE),
			this.createQuickFilter(PredefinedMetricConstants.EMOTION, PredefinedFilterValue.ALL),
		];
	}

	private createQuickFilter(type: PredefinedMetricConstants, value: PredefinedFilterValue): QuickFilter {
		return {
			type,
			value,
			text: this.locale.getString(`metrics.${type}_filter_${value}`)
		};
	}

	private hasMetricFilters(widget: Widget, column: PredefinedMetricConstants): boolean {
		if (!widget)
			return false;
		return this.hasColumn(widget.visualProperties.columns, column)
			|| widget.visualProperties.visualization === WidgetVisualization.DOCUMENT_PREVIEW
			|| widget.visualProperties.visualization === WidgetVisualization.VERBATIM_PREVIEW;
	}

	getQuickFilters = (widget: Widget): QuickFilter[] => {
		let result = this.getCommonFilters();
		if (this.hasMetricFilters(widget, PredefinedMetricConstants.SENTIMENT)) {
			result.pushAll(this.getSentimentFilters());
		}
		if (this.hasMetricFilters(widget, PredefinedMetricConstants.EASE_SCORE)) {
			result.pushAll(this.getEffortFilters());
		}
		if (this.hasMetricFilters(widget, PredefinedMetricConstants.EMOTION)) {
			result.pushAll(this.getEmotionFilters());
		}
		return result;
	};

	getAppliedFilter = (widget: Widget): QuickFilter => {
		if (!widget || !widget.properties.quickFilter) return;
		let filter = widget.properties.quickFilter;
		if (this.hasMetricFilters(widget, filter.type)) // in case we changed widget and filters are not applicable anymore
			return filter;
	};

	private hasColumn = (columns, columnName: string): boolean => {
		return !!_.findWhere(columns, {name: columnName});
	};
}

app.service('quickFilterService', downgradeInjectable(QuickFilterService));
