import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { FullPageDocumentExplorerService } from '@app/modules/document-explorer/document-explorer-page/full-page-document-explorer.service';
import { CHANGE_MA_STATUS } from '@cxstudio/common/url-service.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import MobileStudioApiService from '@app/modules/mobile/services/mobile-studio-api.service';
import { IFilterRuleValue } from '@cxstudio/reports/entities/adhoc-filter.class';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { DecodedLink, DocumentSharingApiService } from '@cxstudio/services/data-services/document-sharing-api-service';
import { RedirectService } from '@cxstudio/services/redirect-service';
import { IModalInstanceService } from 'angular-ui-bootstrap';
import * as _ from 'underscore';
import { DocumentLink } from '../entities/document-link';
import { DocExplorerHelperService } from './doc-explorer-helper.service';
import { DocumentCacheService } from './document-cache.service';

export class DocumentLinkService {

	currentDocumentDescriptor: string;
	private currentDialog: IModalInstanceService;

	constructor(
		private $location: ng.ILocationService,
		private documentCacheService: DocumentCacheService,
		private mobileStudioApiService: MobileStudioApiService,
		private docExplorerHelperService: DocExplorerHelperService,
		private documentSharingApiService: DocumentSharingApiService,
		private locale: ILocale,
		private cbDialogService: CBDialogService,
		private redirectService: RedirectService,
		private $q: ng.IQService,
		private $timeout: ng.ITimeoutService,
		private betaFeaturesService: BetaFeaturesService,
		private fullPageDocExplorerService: FullPageDocumentExplorerService,
	) {
	}

	processLocationAttributes = (): void => {
		// using delayed check to avoid running this when removing document link from url
		this.$timeout(() => {
			let attributes = this.$location.search();
			let selectedSentence = attributes.sentenceId ? parseInt(attributes.sentenceId, 10) : undefined;

			if (attributes.document) {
				this.openEncodedDocument(attributes.document, selectedSentence);
			} else if (attributes.mobileFeedback) {
				this.openEncodedMobileFeedback(attributes.mobileFeedback, selectedSentence);
			} else if (attributes.documentLink) {
				if (this.fullPageDocExplorerService.supportDocLinkPage()) {
					return;
				}

				this.fullPageDocExplorerService.goToDocExplorer(attributes);
			}
		});
	};

	private openEncodedDocument = (encodedDescriptor: string, selectedSentence: number): void => {
		let descriptorPromise = this.documentCacheService.decodeDocumentDescriptor(encodedDescriptor);
		this.openDocumentExplorer(descriptorPromise, encodedDescriptor, selectedSentence);
	};

	private openEncodedMobileFeedback = (encodedFeedbackId: string, selectedSentence: number): void => {
		this.mobileStudioApiService.decodeMobileFeedbackById(encodedFeedbackId)
			.then(decodedMobileLink => {
				this.openDocumentExplorer(this.$q.when(decodedMobileLink.link), decodedMobileLink.encodedLink, selectedSentence);
			}, this.parseErrorResponse);
	};

	decodeDocumentLink = (encodedFeedbackId: string): ng.IPromise<DecodedLink> => {
		return this.documentSharingApiService.decodeDocumentLinkById(encodedFeedbackId)
			.then(response => response.data);
	};

	private openDocumentExplorer = (linkPromise: ng.IPromise<DocumentLink>, encodedLink: string,
		selectedSentence: number): void => {
		if (this.isDocumentAlreadyOpened(encodedLink)) {
			return;
		} else if (this.isDifferentDocumentOpened()) {
			this.currentDialog.dismiss();
			this.openDocumentExplorerWithDocument(linkPromise, encodedLink);
		} else {
			this.openDocumentExplorerWithDocument(linkPromise, encodedLink, selectedSentence);
		}
	};

	private isDocumentAlreadyOpened = (encodedDescriptor: string): boolean => {
		return this.currentDocumentDescriptor === encodedDescriptor;
	};

	private isDifferentDocumentOpened = (): boolean => {
		return !!this.currentDialog;
	};

	private openDocumentExplorerWithDocument = (linkPromise: ng.IPromise<DocumentLink>, encodedLink: string,
			selectedSentence?: number): void => {
		linkPromise.then(decodedLink => {
			let documentIdFilterValues: IFilterRuleValue[] = _.map(decodedLink.documents, (document) => {
				return { text: document + '' };
			});

			let modal = this.docExplorerHelperService.generateSettingsAndOpenDocExplorer(
				decodedLink, documentIdFilterValues, null, null, encodedLink, selectedSentence
			);

			this.currentDialog = modal;
			this.currentDocumentDescriptor = encodedLink;

			modal.result.then(() => this.onCloseDocumentExplorer(), () => this.onCloseDocumentExplorer());
		}, this.parseErrorResponse);
	};

	private parseErrorResponse = (response): void => {
		if (this.isDifferentMasterAccountDocument(response)) {
			this.switchMasterAccount(response);
		}

		if (this.isLinkInvalid(response)) {
			let message = response.data === 'linkUnavailable'
				? this.locale.getString('docExplorer.linkUnavailable')
				: this.locale.getString('docExplorer.noAccess');
			this.cbDialogService.warning(this.locale.getString('common.error'), message);
		}
	};

	private onCloseDocumentExplorer = (): void => {
		this.clearStateAndReturnToPage();
	};

	private clearStateAndReturnToPage = (): void => {
		this.currentDialog = null;
		this.currentDocumentDescriptor = null;
		this.$location.url(this.$location.path());
	};

	private isDifferentMasterAccountDocument = (response: any): boolean => {
		return response.status === CHANGE_MA_STATUS;
	};

	private isLinkInvalid = (response: any): boolean => {
		return response.status === 403 && (response.data === 'linkUnavailable' || response.data === 'noAccess');
	};

	private switchMasterAccount = (response: any): void => {
		this.redirectService.saveCurrentMA({accountId: response.data});
	};

}

app.service('documentLinkService', DocumentLinkService);
