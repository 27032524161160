import { Component, OnInit, ChangeDetectionStrategy, ElementRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ModalBindings } from '@app/modules/modal/modal-bindings';
import { ClipboardService } from '@app/shared/services/clipboard.service';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export interface EmbedModalInput<EmbedObject extends {name: string}> {
	object: EmbedObject;
	objectType: string;
	embedLink: string;
	embedCode: string;
}

@Component({
	selector: 'embed-modal',
	templateUrl: './embed-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedModalComponent<EmbedObject extends {name: string}> extends ModalBindings<EmbedModalInput<EmbedObject>> implements OnInit {
	modalTitle: string;
	showEnhancedEmbed: boolean;
	embedCodeSimple: string;
	embedCodeWithIframe: string;

	constructor(
		modal: NgbActiveModal,
		private readonly locale: CxLocaleService,
		private readonly clipboardService: ClipboardService,
		private readonly toastService: ToastService,
		private readonly component: ElementRef
	) {
		super(modal);
	}

	ngOnInit(): void {
		this.modalTitle = this.locale.getString('common.embedModalTitle', {name: this.input.object.name, objectType: this.input.objectType});
		this.showEnhancedEmbed = true;

		this.embedCodeSimple = this.input.embedLink;
		this.embedCodeWithIframe = this.input.embedCode;
	}

	/**
	 * Copy to clipboard and show a toast
	 */
	copyToClipboard(): void {
		this.clipboardService.copyToClipboard(this.showEnhancedEmbed ?
			this.embedCodeWithIframe :
			this.embedCodeSimple, this.component.nativeElement);

		const confirmationText = this.showEnhancedEmbed ?
			this.locale.getString('common.codeCopiedToClipboard') :
			this.locale.getString('common.linkCopied');

		this.toastService.addToast(confirmationText, AlertLevel.SUCCESS);
	}

}
