import * as cloneDeep from 'lodash.clonedeep';
import { HierarchyLoadStatus } from '@cxstudio/organizations/hierarchy-load-status';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { HierarchyEnrichmentProperty, OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { IHierarchyNode, HierarchyMetadataEnrichment } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { AddNestedChildrenUtils } from '@app/modules/hierarchy/hierarchy-tree-selector/add-nested-children-utils';
import { Subject, ReplaySubject, Observable } from 'rxjs';
import { HierarchyEnrichmentValue, HierarchyIdentifier } from '@cxstudio/reports/entities/metric-widget-properties';

export interface PersonalizationState {
	currentHierarchyName: string;
	currentHierarchyPath: string;
	showHierarchyList: boolean;
	hierarchySearchText: string;
	hierarchyNodes: IHierarchyNode[];
	currentHierarchyNode: IHierarchyNode;
	hierarchyPath: IHierarchyNode[];
	rawHierarchy: any[];
	hierarchyLoaded: boolean;
	hierarchyLoadStatus: HierarchyLoadStatus;
	reportableEnrichments: HierarchyEnrichmentProperty[];

	hierarchyExpansion(node: IHierarchyNode): void;
	init(): Promise<HierarchyLoadStatus>;
	setHierarchyNode(node: IHierarchyNode): void;
	getHighestLevelVisibleChildren(): IHierarchyNode[];
	getHierarchyId(): number;
	getHierarchyName(): string;
	hasMultipleHierarchyPlacements(): boolean;
	selectPreviousExplicitNode(): IHierarchyNode;
	selectNextExplicitNode(): IHierarchyNode;
	loadEntireHierarchy(hierarchyId): Promise<void>;
	isHierarchyEnabled(): boolean;
	isHierarchySelectable(): boolean;
	hasEnrichments(): boolean;
	getEnrichments(): HierarchyMetadataEnrichment[];
	getComparisonEnrichments(): HierarchyEnrichmentValue[];
	getHierarchyNodes(): IHierarchyNode[];
	hierarchyDropdownSearch(nodeId?: number): void;
	getChangeObserver(): Observable<void>;
	getPostInitObserver(): Observable<HierarchyLoadStatus>;
}

export class ForbiddenPersonalizationState implements Partial<PersonalizationState> {
	hierarchyNodes: IHierarchyNode[];
	currentHierarchyNode: IHierarchyNode;
	hierarchyLoadStatus: HierarchyLoadStatus;

	private postInitSubject: Subject<HierarchyLoadStatus> = new ReplaySubject(1);

	constructor() {
		this.hierarchyLoadStatus = HierarchyLoadStatus.FORBIDDEN;
		this.currentHierarchyNode = {id: ReportConstants.NOT_VALID_NODE_ID} as IHierarchyNode;
		this.hierarchyNodes = [];
	}

	init(): Promise<HierarchyLoadStatus> {
		const status = HierarchyLoadStatus.FORBIDDEN;
		this.postInitSubject.next(status);
		return Promise.reject(status);
	}

	getChangeObserver() {
		return new Subject<void>();
	}

	getPostInitObserver() {
		return this.postInitSubject.asObservable();
	}
}

export class HierarchyPersonalizationState implements PersonalizationState {
	private explicitAccessNodes = [];
	private currentlySelectedExplicitAccessNode = null;
	private hierarchyLoading: Promise<void>;

	currentHierarchyName: string;
	currentHierarchyPath: string;
	showHierarchyList = false;
	hierarchySearchText = '';
	hierarchyNodes: IHierarchyNode[] = [];
	currentHierarchyNode = {} as IHierarchyNode;
	rawHierarchy = [];
	hierarchyPath: IHierarchyNode[];
	hierarchyLoaded = false;
	hierarchyLoadStatus: HierarchyLoadStatus;
	reportableEnrichments: HierarchyEnrichmentProperty[];

	private changeSubject: Subject<void> = new Subject();
	private postInitSubject: Subject<HierarchyLoadStatus> = new ReplaySubject(1);

	constructor(
		private hierarchyId: number,
		private showingContext: boolean,
		private preselectedNodeId: number,
		private hierarchyService: HierarchyService,
		private organizationApiService: OrganizationApiService,
		private viewAs?: string
	) {}

	hierarchyExpansion(node: IHierarchyNode): void {
		if (this.hierarchySearchText.length > 0) {
			this.hierarchyDropdownSearch(node.id);
		} else {
			let nodeIdsToExpand = [];
			nodeIdsToExpand.push(node.id);
			AddNestedChildrenUtils.transform(this.rawHierarchy, nodeIdsToExpand, this.hierarchyNodes);
		}
	}

	init(): Promise<HierarchyLoadStatus> {
		this.hierarchyLoaded = false;
		if (this.isHierarchyEnabled()) {
			let orgHierarchyId = this.getHierarchyId();
			return this.organizationApiService.getOrgHierarchyCached(orgHierarchyId).then((organizationHierarchy) => {
				this.reportableEnrichments = organizationHierarchy.reportableEnrichmentProperties;

				if (organizationHierarchy && organizationHierarchy.active === true) {
					this.currentHierarchyName = organizationHierarchy.name;
					return this.reloadHierarchyNodes();
				} else {
					this.currentHierarchyName = organizationHierarchy.name;
					return this.handleErrorCode(HierarchyLoadStatus.DEACTIVATED);
				}
			}, () => {
				return this.handleErrorCode(HierarchyLoadStatus.DELETED);
			});
		} else {
			this.hierarchyNodes = [];
			this.currentHierarchyNode = {} as IHierarchyNode;
		}
		return this.resolveHierarchyLoad();
	}

	setHierarchyNode = (node: IHierarchyNode) => {
		this.currentHierarchyNode = node;
		this.currentHierarchyPath = this.hierarchyService.formatPathForDisplay(node.fullPath);
		this.onCurrentHierarchyNodeChange();
	};

	getHighestLevelVisibleChildren = (): IHierarchyNode[] => {
		return this.hierarchyNodes[0].children;
	};

	getHierarchyId = (): number => {
		return this.hierarchyId;
	};

	getHierarchyName = (): string => {
		return this.currentHierarchyName;
	};

	hasMultipleHierarchyPlacements = (): boolean => {
		return this.explicitAccessNodes.length > 1;
	};

	selectPreviousExplicitNode = (): IHierarchyNode => {
		let currentExplicitNodeIndex = this.getCurrentExplicitNodeIndex();
		let previousExplicitNodeIndex = currentExplicitNodeIndex === 0
			? this.explicitAccessNodes.length - 1
			: currentExplicitNodeIndex - 1;

		return this.selectExplicitNode(this.explicitAccessNodes[previousExplicitNodeIndex], this.rawHierarchy);
	};

	selectNextExplicitNode = (): IHierarchyNode => {
		let currentExplicitNodeIndex = this.getCurrentExplicitNodeIndex();
		let nextExplicitNodeIndex = currentExplicitNodeIndex === this.explicitAccessNodes.length - 1
			? 0
			: currentExplicitNodeIndex + 1;

		return this.selectExplicitNode(this.explicitAccessNodes[nextExplicitNodeIndex], this.rawHierarchy);
	};

	private selectExplicitNode = (node: IHierarchyNode, rawHierarchy: IHierarchyNode[]): IHierarchyNode => {
		this.currentHierarchyNode = node;
		this.currentlySelectedExplicitAccessNode = node;

		rawHierarchy.forEach((rawHierarchyNode) => { rawHierarchyNode.disabled = false; });
		this.hierarchyNodes = this.hierarchyService.getFullHierarchyToPath(
			rawHierarchy, this.currentHierarchyNode, this.explicitAccessNodes);
		this.changeSubject.next();
		return node;
	};

	private getCurrentExplicitNodeIndex = (): number => {
		return _.findIndex(this.explicitAccessNodes, this.currentlySelectedExplicitAccessNode);
	};

	private reloadHierarchyNodes = (): Promise<HierarchyLoadStatus> => {
		this.rawHierarchy = [];
		this.currentHierarchyNode = {} as IHierarchyNode;
		this.hierarchyNodes = [];
		this.changeSubject.next();

		let orgHierarchyId = this.getHierarchyId();
		if (!orgHierarchyId || orgHierarchyId <= 0) {
			return this.handleErrorCode(HierarchyLoadStatus.DELETED);
		}


		if (this.preselectedNodeId) {
			return this.loadPreselectedNode(orgHierarchyId, this.preselectedNodeId);
		} else {
			if (this.showingContext) {
				return this.showFullOrganization(orgHierarchyId);
			} else {
				return this.loadUserHierarchyNodes(orgHierarchyId);
			}
		}
	};

	private loadPreselectedNode = (hierarchyId, nodeId): Promise<HierarchyLoadStatus> => {
		return this.organizationApiService.getHierarchyNode(hierarchyId, nodeId).then((response) => {
			let node = response.data;

			this.rawHierarchy.push(node);
			this.currentHierarchyNode = node;
			this.hierarchyNodes = [ node ];
			if (!node || node.id < 0) {
				return this.handleErrorCode(HierarchyLoadStatus.NO_ACCESS);
			} else {
				return this.resolveHierarchyLoad();
			}
		}, () => {
			return this.handleErrorCode(HierarchyLoadStatus.ERROR);
		});
	};

	private loadUserHierarchyNodes = (orgHierarchyId): Promise<HierarchyLoadStatus> => {
		let promise = !!this.viewAs
			? this.organizationApiService.getViewAsUserHierarchyNodes(orgHierarchyId, this.viewAs)
			: this.organizationApiService.getUserHierarchyNodes(orgHierarchyId);

		return promise.then((userNodes) => {
			if (!userNodes || !userNodes.length) {
				return this.handleErrorCode(HierarchyLoadStatus.NO_ACCESS);
			} else if (userNodes[0].id === -1) {
				this.currentHierarchyNode = userNodes[0];
				return this.resolveHierarchyLoad();
			} else {
				if (this.showingContext) {
					this.handleUserNodesForContext(userNodes);
				} else {
					this.handleUserNodes(orgHierarchyId, userNodes);
				}

				this.explicitAccessNodes = userNodes;

				return this.handleLastExplicitNodeSelection(userNodes, this.rawHierarchy);
			}
		}, () => {
			return this.handleErrorCode(HierarchyLoadStatus.ERROR);
		});
	};

	private handleUserNodesForContext = (userNodes) => {
		let firstAvailableNode = userNodes[0];

		this.hierarchyNodes = [];
		this.currentHierarchyNode = firstAvailableNode;

		userNodes.forEach((userNode) => {
			this.rawHierarchy.push(userNode);
			this.hierarchyNodes.push(cloneDeep(userNode));
		});
		this.changeSubject.next();
	};

	private handleUserNodes = (hierarchyId, userNodes) => {
		let firstAvailableNode = userNodes[0];
		/*
			Fake root node is needed to correctly display hierarchy tree in the case, when user is placed in *multiple unrelated* parts of hierarchy
			(e.g. in different branches of hierarchy tree).
		*/
		let fakeRootNode = { hierarchyId, id: 0, children: [] };

		this.currentHierarchyNode = firstAvailableNode;
		this.rawHierarchy.push(fakeRootNode);

		userNodes.forEach((userNode) => {
			fakeRootNode.children.push(userNode);
		});

		this.hierarchyNodes = [ cloneDeep(fakeRootNode) ];
		this.changeSubject.next();
	};

	/**
	 * If last explicit node selection is persisted and that node still exists and available for current user, select that node;
	 * otherwise select first explicit user node.
	 */
	 private handleLastExplicitNodeSelection = (userNodes, rawHierarchy) => {
		return this.organizationApiService.getExplicitNodeSelection(this.getHierarchyId()).then((hierarchyNodeSelection) => {
			let explicitNodeToSelect;

			if (hierarchyNodeSelection.nodeId) {
				explicitNodeToSelect = _.findWhere(userNodes, { id: hierarchyNodeSelection.nodeId });
			}

			if (!explicitNodeToSelect) {
				explicitNodeToSelect = userNodes[0];
			}

			this.selectExplicitNode(explicitNodeToSelect, rawHierarchy);
			return this.resolveHierarchyLoad();
		});
	};

	private showFullOrganization = (orgHierarchyId: number): Promise<HierarchyLoadStatus> => {
		let fullHierarchyCall = this.loadEntireHierarchy;
		let currentLevelCall = this.loadUserHierarchyNodes;
		// these calls have to be sequential to correctly populate hierarchy context
		return fullHierarchyCall(orgHierarchyId).then(() => {
			return currentLevelCall(orgHierarchyId);
		});
	};

	private onCurrentHierarchyNodeChange = (): void => {
		let selectedNode = this.currentHierarchyNode;
		if (selectedNode) {
			let explicitNode = this.findExplicitNodeByBranch(selectedNode.branchNodeIds);
			if (explicitNode) {
				this.organizationApiService.saveExplicitNodeSelection(this.getHierarchyId(), explicitNode.id);
			}
		}
	};

	private findExplicitNodeByBranch = (branchNodeIds): IHierarchyNode | null => {
		for (let explicitNode of this.explicitAccessNodes) {
			if (branchNodeIds.contains(explicitNode.id)) {
				return explicitNode;
			}
		}
		return null;
	};

	private handleErrorCode(errorCode: HierarchyLoadStatus): Promise<HierarchyLoadStatus> {
		this.hierarchyNodes = [];
		this.currentHierarchyNode = {id: ReportConstants.NOT_VALID_NODE_ID} as IHierarchyNode;
		this.hierarchyLoadStatus = errorCode;
		this.changeSubject.next();
		this.postInitSubject.next(errorCode);
		return Promise.reject(errorCode);
	}

	private resolveHierarchyLoad(): Promise<HierarchyLoadStatus> {
		this.hierarchyLoadStatus = null;
		this.changeSubject.next();
		this.postInitSubject.next();
		return Promise.resolve(undefined);
	}

	loadEntireHierarchy = (hierarchyId: number): Promise<void> => {
		if (this.hierarchyLoading)
			return this.hierarchyLoading;

		this.hierarchyLoading = this.organizationApiService.getHierarchyNodes(hierarchyId).then((hierarchyNodes) => {
			if (hierarchyNodes) {
				hierarchyNodes.forEach((node) => {
					this.rawHierarchy.push(node);
				});
			}

			AddNestedChildrenUtils.transform(
				this.rawHierarchy, this.hierarchyService.getAncestorIdsFromNode(this.currentHierarchyNode), this.hierarchyNodes);

			this.hierarchyNodes[0] = cloneDeep(this.rawHierarchy[0], );
			this.hierarchyLoaded = true;
			delete this.hierarchyLoading;
		});

		return this.hierarchyLoading;
	};

	isHierarchyEnabled = () => {
		return this.hierarchyId > 0;
	};

	isHierarchySelectable = () => {
		return this.isHierarchyEnabled()
			&& this.hierarchyNodes
			&& this.hierarchyNodes.length > 0;
	};

	hasEnrichments = (): boolean => {
		if (!this.currentHierarchyNode || !this.currentHierarchyNode.metadata) {
			return false;
		}

		let enrichments = this.currentHierarchyNode.metadata.enrichments;
		return enrichments && enrichments.length > 0;
	};

	getEnrichments = (): HierarchyMetadataEnrichment[] => {
		return this.hasEnrichments()
			? this.currentHierarchyNode.metadata.enrichments
			: [];
	};

	getHierarchyNodes = () => {
		if (this.showingContext) {
			return this.hierarchyService.getFullHierarchyToPath(this.rawHierarchy, this.currentHierarchyNode, this.explicitAccessNodes);
		}
		return [cloneDeep(this.rawHierarchy[0])];
	};

	hierarchyDropdownSearch = (nodeId?: number): void => {
		let newVal = this.hierarchySearchText;
		this.hierarchyNodes = this.getHierarchyNodes();

		// call $filter on rawhierarchy, this will return any nodes with vertical relation to search term (nodes we need to display in dropdown)
		let verticallyRelatedNodes = _.filter(this.rawHierarchy, (node) => {
			return this.checkNode(node, newVal);
		});

		//search this list to find names matching search term, this will return actual nodes that match
		let nodeMatches = _.filter(verticallyRelatedNodes, (node) => {
			return node.name.toLowerCase().indexOf(newVal.toLowerCase()) > -1;
		});

		//accumulate ancestors of this list of nodes, now we know which nodes need expansion
		let nodeMatchesAncestorIds = [];
		if (nodeMatches && nodeMatches.length && newVal && newVal.length > 1 ) {
			nodeMatchesAncestorIds = _.uniq(_.flatten(_.map(nodeMatches, (node) => {
				return this.hierarchyService.getAncestorIdsFromNode(node);
			})));
		} else {
			nodeMatchesAncestorIds = this.hierarchyService.getAncestorIdsFromNode(this.currentHierarchyNode);
		}
		if (nodeId) {
			//There is some node they are trying to expand, while some search term is entered
			let node = _.find(verticallyRelatedNodes, {id: nodeId} );
			let nodeAncestors = this.hierarchyService.getAncestorIdsFromNode(node);
			for (let nodeAncestor of nodeAncestors) {
				nodeMatchesAncestorIds.push(nodeAncestor);
			}
			nodeMatchesAncestorIds.push(nodeId);
		}
		//In the search, we only want to show nodes that match vertically.
		//We will call the filter with the list of nodes with vertical relation, list of ancestor node ids, and same root node
		AddNestedChildrenUtils.transform(verticallyRelatedNodes, nodeMatchesAncestorIds, this.hierarchyNodes);
		this.hierarchyNodes[0].expanded = true;
		this.changeSubject.next();
	};

	private checkNode = (node: IHierarchyNode, val: string): boolean => {
		let descendants = node.descendants;
		let lowerVal = val.toLowerCase();
		let index = _.findIndex(descendants, (descendant) => {
			return descendant && descendant.name.toLowerCase().contains(lowerVal);
		});
		return (node.fullPath && node.fullPath.toLowerCase().contains(lowerVal))
			|| index >= 0;
	};

	getChangeObserver() {
		return this.changeSubject.asObservable();
	}

	getPostInitObserver() {
		return this.postInitSubject.asObservable();
	}

	getComparisonEnrichments = (): HierarchyEnrichmentValue[] => {
		return this.hasReportableEnrichments()
			? this.getComparisonEnrichmentListOptions()
			: [];
	};

	private hasReportableEnrichments = (): boolean => {
		return this.reportableEnrichments && this.reportableEnrichments.length > 0;
	};

	private getComparisonEnrichmentListOptions = (): HierarchyEnrichmentValue[] => {
		const PROPERTY_NAME = 'propertyName';
		let propertiesNames: string[] = _.pluck(this.reportableEnrichments, PROPERTY_NAME);

		let hierarchy: HierarchyIdentifier = { id: this.getHierarchyId(), name: this.getHierarchyName() };

		return _.chain(this.currentHierarchyNode.metadata.enrichments)
			.filter(enrichment => propertiesNames.contains(enrichment.propertyName))
			.map(enrichment => {
				let propertyName: string = enrichment.propertyName;
				return {
					hierarchy,
					propertyName,
					displayName: `${hierarchy.name} > ${propertyName}`
				};
			})
			.value();
	};

}
