
import ILocale from '@cxstudio/interfaces/locale-interface';
import { TableRowHeights } from './table-row-heights.constant';
import { TableService } from '@cxstudio/services/table-service';

export class CommonSlickTableDefinitionService {
	constructor(private tableService: TableService, private locale: ILocale) { }

	getTableOptions = () => {		
		return {
			editable: false,
			enableAddRow: false,
			enableColumnReorder: true,
			enableCellNavigation: true,
			forceFitColumns: true,
			rowHeight: TableRowHeights.COMFORTABLE,
			asyncEditorLoading: false,
			multiColumnSort: true,
			enableAsyncPostRender: false,
			rowClasses: 'br-table-report-widget-row',
			localization: {
				getSortByColumnPrompt: (columnName: string) => {
					return this.locale.getString('common.clickToSortByColumnName', {columnName});
				},
				getColumnSortedTitle: (columnName: string, isAscending: boolean) => {
					return isAscending ?
						this.locale.getString('common.tableSortedByColumnNameAscending', {columnName}) :
						this.locale.getString('common.tableSortedByColumnNameDescending', {columnName});
				}
			}
		};
	};

	getColumns = (reportDefinition) => {
		let dataObject = reportDefinition.dataObject;
		return this.tableService.getColumns(dataObject, reportDefinition.utils);
	};
}

app.service('commonSlickTableDefinition', CommonSlickTableDefinitionService);
