import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { PopFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/pop-formatter-builder.service';
import { ObjectUtils } from '@app/util/object-utils';
import { MetricColorDirection } from '@cxstudio/metrics/entities/metric-color-direction.enum';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { MetricComparisonType, MetricWidgetComparison, MetricWidgetProperties } from '@cxstudio/reports/entities/metric-widget-properties';
import { ReportDataObject } from '@cxstudio/reports/entities/report-interfaces';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { Decimals } from '@cxstudio/reports/formatting/decimals.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { Truncation } from '@cxstudio/reports/formatting/truncation.enum';
import { CalculationWithFormat, ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { MetricWidgetPOPService } from '@cxstudio/reports/providers/cb/services/metric-widget-pop.service';
import { ReportNumberFormatUtils } from '@cxstudio/reports/utils/report-number-format-utils.service';
import { VerticalMetricChart } from '@cxstudio/reports/visualizations/definitions/svg/vertical-metric-chart.class';

export interface SimplePOPData {
	currentValue: number;
	lastValue: number;
	min: number;
	max: number;
}

export interface MetricWidgetVisualizationOptions {
	trendArrow: boolean;
	previousPeriod: boolean;
	goal: boolean;
	decimal: number;
	selectedMetrics: ReportCalculation[];
}

export interface MetricDisplayValue {
	prefix?: string;
	value: string;
	suffix?: string;
}


@Injectable({
	providedIn: 'root'
})
export class MetricWidgetRenderingService {

	readonly NORMAL_CHART_WIDTH = 60;
	readonly WIDE_CHART_WIDTH = 80;
	readonly CENTER = 45;
	readonly CENTER_WIDE = 65;
	readonly ARROW_WIDTH = 20;

	readonly LABEL_SPLIT_LIMIT = 14; // limit after which we will split a label for horizontal metric widget into 2 lines
	readonly SPLIT_LABEL_CROP_LENGTH = 24; // limit after which we will crop 2-line label

	constructor(
		@Inject('reportNumberFormatUtils') private readonly reportNumberFormatUtils: ReportNumberFormatUtils,
		@Inject('metricWidgetPOPService') private readonly metricWidgetPOPService: MetricWidgetPOPService,
		@Inject('popFormatterBuilderService') private readonly popFormatterBuilderService: PopFormatterBuilderService,
		@Inject('periodOverPeriodMetricService') private readonly periodOverPeriodMetricService: PeriodOverPeriodMetricService,
		private readonly defaultDataFormatterBuilder: DefaultDataFormatterBuilderService,
		private readonly locale: CxLocaleService,
	) { }

	preprocessSingleMetricOptions = (options: Partial<MetricWidgetVisualizationOptions>): void => {
		if (_.isString(options.decimal)) {
			options.decimal = parseInt(options.decimal, 10);
		}

		if (_.isString(options.trendArrow)) {
			options.trendArrow = options.trendArrow === 'true';
		}

		if (_.isString(options.previousPeriod)) {
			options.previousPeriod = options.previousPeriod === 'true';
		}
	};

	formatMainValue(value: number, originalMetric: ReportCalculation, studioMetrics?: Metric[]): string {
		return this.getDisplayMetricValueAsString(
			this.getMainMetricDisplayValue(value, originalMetric, studioMetrics));
	}

	getMainMetricDisplayValue(
		value: number, originalMetric: ReportCalculation, studioMetrics?: Metric[]
	): MetricDisplayValue {
		if (!originalMetric) {
			return { value: value + '' };
		}
		let metric = ObjectUtils.copy(originalMetric) as CalculationWithFormat;
		return this.formatMetricValue(value, metric, metric, null, studioMetrics);
	}

	private formatMetricValue(value: number, metric: CalculationWithFormat, format: CalculationWithFormat,
			popField: PeriodOverPeriodMetricType, studioMetrics: Metric[]): MetricDisplayValue {
		let formatter: IFormatBuilder;
		if (popField === PeriodOverPeriodMetricType.SIGNIFICANCE) {
			formatter = this.popFormatterBuilderService.getSignificanceBuilder();
		} else {
			formatter = this.reportNumberFormatUtils.getFormatterBuilder(metric);
		}
		let selectedMetric: Metric;
		if (metric.definition) {
			selectedMetric = _.find(studioMetrics, {id: metric.id});
		}

		let defaultFormat = this.defaultDataFormatterBuilder.getDefaultFormatterSettings(metric, studioMetrics);
		let resultingFormat = formatter.getTopLevelSettings(defaultFormat, format, selectedMetric?.format);

		const formattedValue = formatter.format(value, format, selectedMetric?.format,
			{ ignoreAlignment: true, ignoreAffixes: true });
		return {
			value: formattedValue,
			prefix: resultingFormat.prefix,
			suffix: resultingFormat.suffix
		};
	}

	private formatDiffValue(value: number, metric: CalculationWithFormat,
			popField: PeriodOverPeriodMetricType, studioMetrics?: Metric[]): string {
		if (!metric) {
			return value + '';
		}

		let format = ObjectUtils.copy(metric) as CalculationWithFormat;
		popField = popField || this.metricWidgetPOPService.getMetricPoPField(metric);

		format.alignment = null;
		this.adjustPoPFormat(popField, format);

		return this.getDisplayMetricValueAsString(
				this.formatMetricValue(value, metric, format, popField, studioMetrics));
	}

	private adjustPoPFormat(popField: PeriodOverPeriodMetricType, format: CalculationWithFormat): void {
		if (popField === PeriodOverPeriodMetricType.PERCENT_CHANGE) {
			format.prefix = '';
			format.suffix = '%';
			format.decimals = Decimals.ONE;
			format.conversion = MetricMultiplierType.TIMES_HUNDRED;
			format.truncation = Truncation.NONE;
			format.useDefaultFormat = false;
			format.customFormatting = true;
			delete format.dataType;
		}

		if (popField === PeriodOverPeriodMetricType.P_VALUE) {
			format.suffix = '';
			format.prefix = '';
			format.decimals = Decimals.THREE;
		}
	}

	getDisplayMetricValueAsString(value: MetricDisplayValue): string {
		return `${value.prefix || ''}${value.value}${value.suffix || ''}`;
	}

	formatNumericValue = (value: number, comparison: MetricWidgetComparison): MetricDisplayValue => {
		const formatter = this.reportNumberFormatUtils.getFormatterBuilder({} as CalculationWithFormat);
		const formattedValue = formatter.simpleFormat(value,
			{...comparison.format, useDefaultFormat: comparison.inheritFormatFromMetric} as CalculationWithFormat,
			{ ignoreAlignment: true, ignoreAffixes: true });

		return {
			value: formattedValue,
			prefix: comparison.format.prefix,
			suffix: comparison.format.suffix
		};
	};

	getChangeGraphWidth = (data: SimplePOPData, options): number => {
		return this.hasLongValues(data, options) ? this.WIDE_CHART_WIDTH : this.NORMAL_CHART_WIDTH;
	};

	private getFormattedValue(value, options): string {
		return this.formatMainValue(value, options.selectedMetrics);
	}

	private isValueLonger(data: SimplePOPData, options, targetLength: number): boolean {
		return _.chain([data.max, data.min, data.currentValue, data.lastValue])
			.filter(val => !_.isUndefined(val))
			.any(val => this.getFormattedValue(val, options).length > targetLength)
			.value();
	}

	hasLongValues = (data: SimplePOPData, options): boolean => {
		return this.isValueLonger(data, options, 4);
	};

	hasExtraLongValues = (data: SimplePOPData, options): boolean => {
		return this.isValueLonger(data, options, 9);
	};

	buildPath = (data: SimplePOPData, options: Partial<MetricWidgetVisualizationOptions>, element?: JQuery) => {
		if ((!data.currentValue && data.currentValue !== 0) || (!data.lastValue && data.lastValue !== 0)) {
			return '';
		}

		let minMax = this.createLevels(data);
		data.min = minMax.min;
		data.max = minMax.max;

		return VerticalMetricChart.getSVGPath(data, options, this.hasLongValues(data, options));
	};

	createLevels(data: SimplePOPData): { max: number; min: number } {
		return {
			max: this.createMaxLevel(data),
			min: this.createMinLevel(data)
		};
	}

	private createMaxLevel(data: SimplePOPData): number {
		let max: number = _.max([data.currentValue, data.lastValue]);
		if (max <= 0) {
			return 0;
		}

		let max10 = Math.pow(10, Math.ceil(Math.log10(max)));
		if (max <= max10 / 2) {
			max10 = max10 / 2;
		}

		return max10;
	}

	private createMinLevel(data: SimplePOPData): number {
		let min: number = _.min([data.currentValue, data.lastValue]);
		if (min >= 0) {
			return 0;
		}

		let min10 = Math.pow(10, Math.ceil(Math.log10(Math.abs(min))));
		if (min < 0) {
			min10 = -min10;
		}
		if (min >= min10 / 2) {
			min10 = min10 / 2;
		}

		return min10;
	}

	private hasComparisonDiffIndication(popField?: PeriodOverPeriodMetricType): boolean {
		return popField !== PeriodOverPeriodMetricType.P_VALUE && popField !== PeriodOverPeriodMetricType.SIGNIFICANCE;
	}

	getDirectionSymbol = (selectedMetrics: ReportCalculation[], currentValue: number, previousValue: number): string | undefined => {
		const popField = this.metricWidgetPOPService.getMetricPoPField(selectedMetrics[0]);
		return this.getComparisonDirectionSymbol(popField, currentValue, previousValue);
	};

	getComparisonDirectionSymbol = (popField: PeriodOverPeriodMetricType,
			currentValue: number, previousValue: number): string | undefined => {
		if (!this.hasComparisonDiffIndication(popField)) {
			return;
		}
		if (currentValue > previousValue) {
			return '▲';
		}
		if (currentValue < previousValue) {
			return '▼';
		}
	};

	getDirectionalColorClass = (selectedMetrics: ReportCalculation[], newValue: number, oldValue: number): string => {
		const metric = selectedMetrics[0];
		const calculation = this.metricWidgetPOPService.getMetricPoPField(selectedMetrics[0]);
		return this.getComparisonColorClass(metric.colorDirection, calculation, newValue, oldValue,
			metric.name === PredefinedMetricConstants.EASE_SCORE);
	};

	getComparisonColorClass(colorDirection: MetricColorDirection, popField: PeriodOverPeriodMetricType, newValue: number,
		oldValue: number, isEaseScore: boolean = false): string {

		let difference = newValue - oldValue;
		return this.getComparisonColorClassFromDiff(colorDirection, popField, difference, isEaseScore);

	}

	getComparisonColorClassFromDiff(colorDirection: MetricColorDirection, popField: PeriodOverPeriodMetricType,
		difference: number, isEaseScore: boolean = false): string {
			const defaultClass = 'neutral-change-color';
			const positiveClass = isEaseScore ? 'positive-effort-change-color' : 'positive-change-color';
			const negativeClass = isEaseScore ? 'negative-effort-change-color' : 'negative-change-color';
			if (!this.hasComparisonDiffIndication(popField) || colorDirection === MetricColorDirection.NONE) {
				return defaultClass;
			}
			const isDirect = isEaseScore ? true : colorDirection !== MetricColorDirection.REVERSED;

			if (isDirect) {
				return (difference > 0) ? positiveClass : negativeClass;
			} else {
				return (difference < 0) ? positiveClass : negativeClass;
			}
		}

	getValueLengthClass(data: any, options: any, selectedMetrics: ReportCalculation[]): string {
		let metricVisOptions = ObjectUtils.copy(options);
		metricVisOptions.selectedMetrics = ObjectUtils.copy(selectedMetrics);
		if (this.hasExtraLongValues(data, metricVisOptions)) {
			return 'with-extra-long-values';
		}

		return this.hasLongValues(data, metricVisOptions) ? 'with-long-values' : 'without-long-values';
	}

	cropLabel(label: string): string {
		const limit = 18;

		return label.length <= limit ?
			label :
			`${label.left(limit)}&hellip;`;
	}

	private getSplitPoint(label: string): number {
		let breakAfterCharacters: RegExp = /[-;,:]/;
		let breakBeforeCharacters: RegExp = /[ \(\[]/;
		let midpoint = Math.floor(label.length / 2);

		// if no good breakpoint exists, just split evenly in half
		if (!breakAfterCharacters.test(label) && !breakBeforeCharacters.test(label)) {
			return midpoint;
		}

		let distance = 0;
		while (midpoint - distance > 0 && midpoint + distance < label.length) {
			if (breakBeforeCharacters.test(label[midpoint + distance])) {
				return midpoint + distance;
			}
			if (breakBeforeCharacters.test(label[midpoint - distance])) {
				return midpoint - distance;
			}

			if (breakAfterCharacters.test(label[midpoint + distance])) {
				return midpoint + distance + 1;
			}
			if (breakAfterCharacters.test(label[midpoint - distance])) {
				return midpoint - distance + 1;
			}

			distance++;
		}

		// target character must be at beginning or end, so just split in the middle
		return midpoint;
	}

	splitLabel(label: string): string {
		label = label.trim();
		// for this to be true, the other label has to be length > 14
		if (label.length <= this.LABEL_SPLIT_LIMIT) {
			return `<tspan></tspan><tspan x="0">${label}</tspan>`;
		}

		let croppedLabel = label.substring(0, this.SPLIT_LABEL_CROP_LENGTH);
		const midpoint = this.getSplitPoint(croppedLabel);
		return `<tspan>${croppedLabel.substring(0, midpoint).trim()}</tspan>
			<tspan x="0" dy="1.2em">${croppedLabel.substring(midpoint).trim()}${label !== croppedLabel ? '&hellip;' : ''}</tspan>`;
	}

	getDifferenceString = (dataRow: any, props: MetricWidgetProperties, utils: WidgetUtils): string => {
		let comparison = props.comparisons && props.comparisons[0];
		let metric = props.selectedMetrics[0] as CalculationWithFormat;
		let metricName = metric.name;
		let currentValue = dataRow[metricName];
		let previousValue = comparison
			? dataRow[`${metricName}_${comparison.identifier}_value`]
			: dataRow[`${DatePeriodName.PERIOD2}${metricName}`];
		if (!_.isNumber(currentValue) || !_.isNumber(previousValue)) {
			return this.locale.getString('widget.na');
		}

		let difference;
		let popField;
		if (comparison) {
			popField = comparison.calculation;
			difference = dataRow[`${metricName}_${comparison.identifier}_diff`];
		} else {
			popField = popField || this.metricWidgetPOPService.getMetricPoPField(metric);
			difference = dataRow[`${popField}_${metricName}`];
		}

		if (popField === PeriodOverPeriodMetricType.PERCENT_CHANGE && this.isDivideByZero(previousValue)) {
			return this.handleDivideByZeroPercent(currentValue,
				() => this.formatDiffValue(0, metric, popField, utils.metrics));
		}
		return this.formatDiffValue(difference, metric, popField, utils.metrics);
	};

	getComparisonDiffString = (dataRow: any, metric: CalculationWithFormat, comparison: MetricWidgetComparison,
			utils: WidgetUtils): string => {
		let metricName = metric.name;
		let currentValue = dataRow[metricName];
		let previousValue = dataRow[`${metricName}_${comparison.identifier}_value`];
		if (!_.isNumber(previousValue)) {
			return this.locale.getString('widget.na');
		}

		let popField = comparison.calculation;
		let difference = dataRow[`${metricName}_${comparison.identifier}_diff`];

		if (popField === PeriodOverPeriodMetricType.PERCENT_CHANGE && this.isDivideByZero(previousValue)) {
			return this.handleDivideByZeroPercent(currentValue,
				() => this.formatComparisonDiffValue(0, metric, comparison, popField, utils.metrics));
		}
		return this.formatComparisonDiffValue(difference, metric, comparison, popField, utils.metrics);
	};

	private formatComparisonDiffValue(value: number, metric: CalculationWithFormat, comparison: MetricWidgetComparison,
		popField: PeriodOverPeriodMetricType, studioMetrics?: Metric[]): string {
		if (!metric) {
			return value + '';
		}

		let format = ObjectUtils.copy(_.isEmpty(comparison.format) ? metric : comparison.format) as CalculationWithFormat;
		popField = popField || this.metricWidgetPOPService.getMetricPoPField(metric);

		format.alignment = null;
		this.adjustPoPFormat(popField, format);

		return this.getDisplayMetricValueAsString(
				this.formatComparisonValue(value, metric, comparison, studioMetrics, popField));
	}

	getComparisonMetricDisplayValue = (
		value: number, metric: CalculationWithFormat, comparison: MetricWidgetComparison, metrics: Metric[]
	): MetricDisplayValue => {
		if (!value && value !== 0) {
			return { value: this.locale.getString('widget.na') };
		}

		return this.formatComparisonValue(value, metric, comparison, metrics, null);
	};

	private formatComparisonValue(value: number, metric: CalculationWithFormat, comparison: MetricWidgetComparison,
		metrics: Metric[], popField: PeriodOverPeriodMetricType): MetricDisplayValue {
		let comparisonType: MetricComparisonType = comparison?.type;
		// for TIME always use original metric formatting
		let useComparisonFormat = comparisonType !== MetricComparisonType.TIME && !comparison.inheritFormatFromMetric;
		return useComparisonFormat
			? this.formatNumericValue(value, comparison)
			: this.formatMetricValue(value, metric, metric, popField, metrics);

	}

	private isDivideByZero(previousValue: number): boolean {
		return previousValue === 0;
	}

	private handleDivideByZeroPercent(currentValue: number, formattedZero: () => string): string {
		const INFINITY = '∞';
		const NEG_INFINITY = '-∞';

		if (currentValue < 0) {
			return NEG_INFINITY;
		}
		if (currentValue > 0) {
			return INFINITY;
		}

		return formattedZero();
	}

	getComparisonLabel(comparison: MetricWidgetComparison, utils: WidgetUtils): string {
		if (comparison.label) {
			return comparison.label;
		}
		switch (comparison.type) {
			case MetricComparisonType.GOAL: return this.locale.getString('widget.goal');
			case MetricComparisonType.HIERARCHY_ENRICHMENT: return comparison.value.displayName;
			case MetricComparisonType.TIME: {
				let defaultName = utils.periodFormatter(comparison.value.dateFilterMode);
				return defaultName || this.locale.getString('widget.historic_period');
			}
			default: throw new Error(`Unsupported comparison: ${comparison.type}`);
		}
	}

	getDifferenceSymbol = (newValue: number, oldValue: number, props: MetricWidgetProperties) => {
		if (!_.isEmpty(props.comparisons)) {
			return this.getComparisonDirectionSymbol(props.comparisons[0]?.calculation, newValue, oldValue);
		} else {
			return this.getDirectionSymbol(props.selectedMetrics, newValue, oldValue);
		}
	};

	getValues(props: MetricWidgetProperties, options, dataObject: ReportDataObject) {
		let metric = props.selectedMetrics[0].name;

		let data: any = {};
		data.currentValue = dataObject.data[0][metric];
		if (isNaN(data.currentValue)) {
			data.currentValue = undefined;
		}

		let comparisonData = this.updateComparisonData(metric, options, dataObject, props);
		return $.extend(data, comparisonData);
	}

	private updateComparisonData(metric: string, options, dataObject: ReportDataObject,
			props: MetricWidgetProperties): {[key: string]: number} {
		let data: any = {};
		let differenceFields = [
				PeriodOverPeriodMetricType.DELTA,
				PeriodOverPeriodMetricType.PERCENT_CHANGE,
				PeriodOverPeriodMetricType.P_VALUE,
				PeriodOverPeriodMetricType.SIGNIFICANCE];
		if (options.previousPeriod || options.trendArrow) {
			let lastValueName = `period_2_${metric}`;
			let comparison = props.comparisons && props.comparisons[0];
			if (comparison) {
				lastValueName = `${metric}_${comparison.identifier}_value`;
				data[`${comparison.calculation}_${metric}`] = dataObject.data[0][`${metric}_${comparison.identifier}_diff`];
			} else {
				differenceFields.forEach((field) => {
					let responseField = `${field}_${metric}`;
					data[field] = dataObject.data[0][responseField];
				});
			}
			data.lastValue = dataObject.data[0][lastValueName];
			if (isNaN(data.lastValue)) {
				data.lastValue = undefined;
			}
		}
		return data;
	}

	getLabels(props, options, utils): {currentPeriodLabel: string; previousPeriodLabel: string} {
		// update labels
		let currentPeriodLabel;
		let previousPeriodLabel;

		currentPeriodLabel = this.periodOverPeriodMetricService.getPeriodLabel(1, options, props
			, utils.containerId) || this.locale.getString('widget.current_period');

		if (!_.isEmpty(props.comparisons)) {
			previousPeriodLabel = this.getComparisonLabel(props.comparisons[0], utils);
		} else {
			previousPeriodLabel = this.periodOverPeriodMetricService.getPeriodLabel(2, options,
				props, utils.containerId) || this.locale.getString('widget.historic_period');
		}

		return { currentPeriodLabel, previousPeriodLabel };
	}

	getAriaLabel(currentPeriodLabel: string, currentValue: number | string, hasPreviousPeriod: boolean,
		difference: number | string, previousPeriodLabel: string, lastValue: number | string): string {
		let label = `${currentPeriodLabel} ${currentValue}`;
		if (hasPreviousPeriod) {
			let changedText = this.locale.getString('singleMetric.changedBy', {amount: difference });
			label += `, ${changedText}, ${previousPeriodLabel} ${lastValue}`;
		}
		return label;
	}
}


app.service('metricWidgetRendering', downgradeInjectable(MetricWidgetRenderingService));
