<collapsing-panel>
	<panel-heading>
		{{'administration.scheduledJobs'|i18n}}
	</panel-heading>
	<panel-body>
		<div class="row">
			<div class="col-xs-12">
				<div class="form-group clearfix">
					<div class="pull-left">
						<refresh-trigger
							class="btn btn-secondary"
							[refreshFunction]="refreshScheduledJobs"
							refreshLimit="10">
						</refresh-trigger>
					</div>
					<simple-dropdown
						class="pull-right"
						[options]="scheduledJobs.groups"
						displayField="displayName"
						[(value)]="scheduledJobs.group"
						(valueChange)=refreshScheduledJobs()>
					</simple-dropdown>
				</div>

				<formatted-table
					[columns]="scheduledJobs.columns"
					[rows]="scheduledJobs.data">
				</formatted-table>
			</div>
		</div>

		<pagination
			[pagination]="scheduledJobs.pagination"
			(paginationChange)="scheduledJobsPageChanged($event)">
		</pagination>
	</panel-body>
</collapsing-panel>

<collapsing-panel>
	<panel-heading>
		{{'administration.completedJobs'|i18n}}
	</panel-heading>
	<panel-body>
		<div class="row">
			<div class="col-xs-12">
				<div class="form-group clearfix">
					<div class="pull-left">
						<refresh-trigger
							class="btn btn-secondary"
							[refreshFunction]="refreshCompletedJobs"
							refreshLimit="10">
						</refresh-trigger>
					</div>
					<simple-dropdown
						class="pull-right"
						[options]="completedJobs.groups"
						displayField="displayName"
						[(value)]="completedJobs.group"
						(valueChange)=refreshCompletedJobs()>
					</simple-dropdown>
				</div>

				<formatted-table
					[columns]="completedJobs.columns"
					[rows]="completedJobs.data">
				</formatted-table>
			</div>
		</div>
		<pagination
			[pagination]="completedJobs.pagination"
			(paginationChange)="completedJobsPageChanged($event)">
		</pagination>
	</panel-body>
</collapsing-panel>

<collapsing-panel>
	<panel-heading>
		{{'administration.optionalJobs'|i18n}}
	</panel-heading>
	<panel-body>
		<div class="row">
			<div class="col-xs-12">
				<div class="form-group clearfix">
					<formatted-table
						[columns]="optionalJobs.columns"
						[rows]="optionalJobs.data">
					</formatted-table>
				</div>
			</div>
		</div>
	</panel-body>
</collapsing-panel>
