import { ChangeDetectionStrategy, Component, Input, ViewChild, ElementRef, Inject } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { KeyboardSelector } from '@app/shared/util/keyboard-selector.enum';
import { DropdownOrientationUtils } from '@cxstudio/common/dropdown-orientation-utils.service';


@Component({
	selector: 'submenu',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
<section class="dropdown-submenu"
	#submenu>
	<a class="dropdown-item option"
		(click)="toggleSubmenu($event)"
		href="javascript:void(0)"
		role="menuitem"
		aria-haspopup="true">{{label}}...
	</a>
	<menu role="menu" class="dropdown-menu w-auto w-max-280"
		[ngClass]="{'dropdown-left':menuToLeft, 'dropdown-right': !menuToLeft}">
		<div [hidden]="!isOpened">
			<ng-content></ng-content>
		</div>
	</menu>
</section>
`,
})
export class SubmenuComponent {
	@Input() label: string;
	@Input() menuToRightOnly: boolean;

	@ViewChild('submenu', {static: false}) submenu: ElementRef;

	isOpened: boolean;
	menuToLeft: boolean;

	constructor(
		@Inject('dropdownOrientationUtils') private readonly dropdownOrientationUtils: DropdownOrientationUtils
	){}

	showSubmenu = (event: Event): void => {
		this.isOpened = true; // manually show/hide menu to support hiding delay

		if (!this.menuToRightOnly) {
			let eventLocation = Object.assign({}, event) as unknown as JQuery.Event;
			// keyboard events need a location attached to them to open menu in correct direction
			if (!eventLocation.clientX) {
				eventLocation.clientX = this.submenu.nativeElement.getBoundingClientRect().x;
			}
			let availableSpace = this.dropdownOrientationUtils.getAvailableSpace(eventLocation);
			// show to the left in 4th quarter of the screen, without "smart" calculation of available space
			this.menuToLeft = (availableSpace.right < window.innerWidth / 4);
		}
	};

	toggleSubmenu(event?: Event, forceState?: boolean): void {
		event.stopPropagation();
		if (forceState !== undefined) {
			this.isOpened = forceState;
		} else {
			this.isOpened = !this.isOpened;
		}

		if (this.isOpened) this.showSubmenu(event);
		if (!this.isOpened) {
			this.onCloseSubmenu();
		}
	}

	private onCloseSubmenu() {
		let target = this.submenu.nativeElement.querySelector(`a,input,[tabindex]:not(${KeyboardSelector.NEGATIVE_TAB_INDEX})`);
		target.focus();
	}
}

app.directive('submenu', downgradeComponent({ component: SubmenuComponent}));
