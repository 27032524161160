import { AfterViewInit, Inject, ViewChild, ChangeDetectorRef, TemplateRef, Component, OnInit,
	ChangeDetectionStrategy, Input } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import ProjectSettingsService from '@cxstudio/services/data-services/project-settings.service';
import { SubscriptionsService } from '@app/modules/user-administration/groups/alert-subscription/subscriptions.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import * as cloneDeep from 'lodash.clonedeep';
import { ModelsService } from '@app/modules/project/model/models.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ModelUtils } from '@app/modules/project/model/model-utils';

@Component({
	selector: 'alert-editor-modal',
	templateUrl: './alert-editor-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertEditorModalComponent implements AfterViewInit, OnInit {
	@Input() input;

	@ViewChild('step1', {static: false}) private step1;
	@ViewChild('step2', {static: false}) private step2;
	@ViewChild('step3', {static: false}) private step3;

	oldModelId: number;

	validation;
	isNew: boolean;
	alert;
	nodeIds;
	modalHeading: string;
	currentStep: number;
	steps: Array<{
		tabName: string;
		template?: TemplateRef<any>;
	}>;
	uniqueNameError: boolean;
	invalidNameError: boolean;
	props;
	modelNodes: any[];
	loading: any;
	attributes: any;
	filters: any;
	modelList: any;
	loaded: boolean;

	constructor(
		private readonly modalInstance: NgbActiveModal,
		private readonly locale: CxLocaleService,
		private readonly filtersService: FiltersService,
		private readonly subscriptionsService: SubscriptionsService,
		private readonly change: ChangeDetectorRef,
		@Inject('projectSettingsService') private readonly projectSettingsService: ProjectSettingsService,
		private readonly modelsService: ModelsService,
	) { }


	ngOnInit(): void {
		this.props = this.input.props;

		this.validation = this.validation || {};
		this.validation.attributeLimit = 3;

		this.isNew = !this.input.alert;
		this.alert = cloneDeep(this.input.alert) || this.newAlert();
		this.oldModelId = this.alert.modelId;
		this.alert.selectedFilters = [];
		this.alert.selectedAttributes = [];
		this.nodeIds = [];
		this.modalHeading = (this.alert) ?
			this.locale.getString('alert.editAlertHeading', {name: this.alert.alertName}) :
			this.locale.getString('alert.newAlertHeading');
		this.alert.modelName = (this.alert?.modelName) || this.locale.getString('alert.projectWide');
		this.loadSettings();

		this.currentStep = 0;

		this.steps = [
			{
				tabName: this.locale.getString('common.models'),
			},
			{
				tabName: this.locale.getString('common.attributes'),
			},
			{
				tabName: this.locale.getString('common.filters'),
			}
		];
	}

	ngAfterViewInit(): void {
		this.steps[0].template = this.step1;
		this.steps[1].template = this.step2;
		this.steps[2].template = this.step3;
	}

	private modelSelected(): boolean {
		return !this.isProjectWide() && this.nodeIds.length > 0;
	}

	private isProjectWide(): boolean {
		return !this.alert.modelId;
	}

	continue(): void {
		if (this.isLastStep()) {
			this.save();
		} else {
			this.currentStep++;
		}
	}

	back(): void {
		this.currentStep--;
	}

	save(): void {
		this.convertFromUISettings(this.alert);
		this.modalInstance.close(this.alert);
	}

	cancel(): void {
		this.modalInstance.dismiss();
	}

	getContinueButtonText(): string {
		return this.isLastStep() ? this.locale.getString('common.save') : this.locale.getString('common.next');
	}

	isLastStep(): boolean {
		return (!this.isNew || (this.isNew && (this.currentStep === this.steps?.length - 1)));
	}

	isSettingsValid(): boolean {
		let attributeCheck = this.alert && this.alert.selectedAttributes && !!this.alert.selectedAttributes.length;
		let modelCheck = this.modelSelected();
		let filterCheck = this.alert && this.alert.selectedFilters && !!this.alert.selectedFilters.length;
		let ruleCheck = attributeCheck || modelCheck || filterCheck;
		let nameCheck = this.alert.alertName?.length && !this.uniqueNameError && !this.invalidNameError;
		return nameCheck && (this.isNew ? (this.isLastStep() ? ruleCheck : true) : ruleCheck);
	}

	isAttributeLimitReached = (allItems, available): boolean => {
		let selectedItemsCount = allItems.filter(item => item.selected).length;
		let highlightedItemsCount = available.length;

		return (selectedItemsCount + highlightedItemsCount) > this.validation.attributeLimit;
	};

	updateSelectedAttributes(attributes): void {
		this.alert.selectedAttributes = attributes;
	}

	updateSelectedFilters(filters): void {
		this.alert.selectedFilters = filters;
	}

	private newAlert() {
		return {
			alertName: '',
			contentProviderId: this.props.contentProviderId,
			accountId: this.props.accountId,
			projectId: this.props.projectId,
			attributeIds: [],
			filterIds: [],
			type: 'NEW_VERBATIM',
			modelName: this.locale.getString('alert.projectWide'),
			nodeIds: []
		};
	}


	// remove non-CMP filters and filters from other projects
	private isAnalyzeFilter(item): boolean {
		return (item.type === FilterTypes.CXANALYZE);
	}

	private loadSettings() {
		let project = {
			contentProviderId: this.props.contentProviderId,
			accountId: this.alert.accountId,
			projectId: this.alert.projectId
		};
		let filtersPromise = this.filtersService.getPlatformFilters(project);

		let settingsPromise = this.projectSettingsService.getSettings({
			contentProviderId: this.props.contentProviderId,
			accountId: this.alert.accountId,
			projectId: this.alert.projectId
		}) as unknown as Promise<any>;

		this.loading = Promise.all([settingsPromise, filtersPromise]).then((response: any) => {
			this.attributes = cloneDeep(response[0].attributes);
			this.filters = cloneDeep(response[1]);
			this.filters.forEach(item => item.displayName = item.name);
			this.modelList = cloneDeep(AssetVisibilityUtils.getVisibleModels(response[0].models)) || [];

			this.loadModelNodes().then(() => {
				this.modelList.insert(0, {id: 0, name: this.locale.getString('alert.projectWide')});
				this.convertToUISettings(this.alert);
				delete this.loading;
			});
		});
	}


	loadModelNodes(): Promise<any> {
		if (isEmpty(this.alert.modelId) || this.alert.modelId === 0) {
			this.modelNodes = [];
			this.nodeIds = [];
			this.alert.modelName = '';
			this.oldModelId = undefined;
			return Promise.resolve();
		}

		if (this.oldModelId !== this.alert.modelId) {
			let model = _.findWhere(this.modelList, {id: this.alert.modelId});
			if (model) {
				this.nodeIds = [];
				this.alert.modelName = model.name;
				this.oldModelId = this.alert.modelId;
			}
		}

		return this.modelsService.getModelTree(
			new ProjectIdentifier(this.props.contentProviderId, this.alert.accountId, this.alert.projectId),
			this.alert.modelId,
		).then(modelTree => ModelUtils.populateNodesPath(modelTree))
		.then((result) => {
			this.modelNodes = result.root ? [result.root] : [];
			this.change.markForCheck();
		});
	}


	isNodeCheckable(): boolean { return true; }
	isNodeChecked = (passedNode: {node: any}): boolean => {
		return this.isNodeCheckedInternal(this.nodeIds, passedNode.node);
	};

	private isNodeCheckedInternal(list, node): boolean {
		return _.indexOf(list, node.id) >= 0;
	}

	handleNodeCheck = (node): void => {
		if (!this.isNodeCheckable()) return;

		let itemIndex = _.indexOf(this.nodeIds, node.id);

		if (itemIndex >= 0) {
			this.uncheckItem(node, itemIndex);
		} else {
			this.checkItem(node);
		}

		this.childCheck(node);
		this.parentCheck(node);
	};

	checkName(): void {
		if ( /[\"\'\\]/.test(this.alert.alertName) ) {
			this.invalidNameError = true;
			return;
		}

		this.invalidNameError = false;
		this.subscriptionsService.checkAlertName(this.props.contentProviderId, this.alert)
			.then(isUnique => this.uniqueNameError = !isUnique);
	}

	private checkItem(node): void {
		if (_.indexOf(this.nodeIds, node.id) < 0)
			this.nodeIds.push(node.id);
	}

	private uncheckItem(node, index?): void {
		index = index || _.indexOf(this.nodeIds, node.id);
		if (index >= 0) {
			this.nodeIds.removeAt(index);
		}
	}

	private childCheck(node): void {
		if (node.children?.length) {
			let state = this.isNodeChecked({node});
			for (let child of node.children) {
				if (state) {
					this.checkItem(child);
				} else {
					this.uncheckItem(child);
				}
				this.childCheck(child);
			}
		}
	}

	findInHierarchy(hierarchies, field, value): any {
		for (let hierarchy of hierarchies) {
			if (hierarchy[field] === value) {
				return hierarchy;
			} else if (hierarchy.children && hierarchy.children.length > 0) {
				let childMatch = this.findInHierarchy(hierarchy.children, field, value);
				if (childMatch) return childMatch;
			}
		}
	}

	private hasCheckedChild(parentNode): boolean {
		for (let node of parentNode.children) {
			if (this.isNodeChecked({node})) return true;
		}
		return false;
	}

	private hasUncheckedChildRecursive(parentNode): boolean {
		if (parentNode.children) {
			for (let node of parentNode.children) {
				if (!this.isNodeChecked({node})) return true;
				if (this.hasUncheckedChildRecursive(node)) return true;
			}
		}
		return false;
	}

	private hasCheckedParent(list, node): boolean {
		if (node.parentId) {
			let parentNode = this.findInHierarchy(this.modelNodes, 'id', node.parentId);
			return !parentNode ? false : (this.isNodeCheckedInternal(list, parentNode) || this.hasCheckedParent(list, parentNode));
		}

		return false;
	}

	private parentCheck(node): void {
		if (node.parentId) {
			let parentNode = this.findInHierarchy(this.modelNodes, 'id', node.parentId);

			if (parentNode) {
				if (this.hasCheckedChild(parentNode)) {
					this.checkItem(parentNode);
				} else {
					this.uncheckItem(parentNode);
				}

				this.parentCheck(parentNode);
			}
		}
	}

	private convertToUISettings(alert): void {
		if (alert?.filterIds?.length > 0) {
			alert.selectedFilters = alert.filterIds.map((id) => {
				let filter = _.findWhere(this.filters, {filterId: id});
				if (filter) {
					filter.selected = true;
				}
				return filter;
			}).filter(filter => !!filter);
		}

		if (alert.attributeIds?.length > 0) {
			alert.selectedAttributes = alert.attributeIds.map((id) => {
				let attr = _.findWhere(this.attributes, { id });
				if (attr) {
					attr.selected = true;
				}
				return attr;
			}).filter(attr => !!attr);
		}
		this.generateSelectedNodes(alert);
		this.loaded = true;
	}


	private convertFromUISettings(alert): void {
		alert.attributeIds = alert.selectedAttributes.map(attr => attr.id);
		alert.filterIds = alert.selectedFilters.map(filter => filter.filterId);
		if (!alert.modelId) {
			alert.nodeIds = [];
		}

		if (this.nodeIds.length === 0) {
			alert.modelId = undefined;
			alert.modelName = '';
			alert.nodeIds = [];
		} else {
			alert.nodeIds = this.removeRedundantNodeIds();
		}

		delete alert.selectedFilters;
		delete alert.selectedAttributes;
	}

	private generateSelectedNodes(alert): void {
		if (alert?.nodeIds && this.modelNodes?.length > 0) {
			alert.nodeIds.forEach((id) => {
				let node = this.findInHierarchy(this.modelNodes, 'id', id);
				if (node) {
					this.checkItem(node);
					this.childCheck(node);
					this.parentCheck(node);
				}
			});
		}
	}

	private removeRedundantNodeIds() {
		if (this.nodeIds && this.modelNodes?.length > 0) {
			let nodeIds = this.nodeIds.filter((id) => {
				let node = this.findInHierarchy(this.modelNodes, 'id', id);
				return node && !this.hasUncheckedChildRecursive(node);
			});
			return nodeIds.filter((id) => {
				let node = this.findInHierarchy(this.modelNodes, 'id', id);
				return !this.hasCheckedParent(nodeIds, node);
			});
		}
		return [];
	}
}
