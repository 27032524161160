import Listener from '@cxstudio/common/listener';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';

export enum ToolbarPosition {
	TOP = 'TOP',
	RIGHT = 'RIGHT',
	BOTTOM = 'BOTTOM',
	LEFT = 'LEFT'
}

export class AddWidgetToolbarPosition {
	static readonly POSITION_CLASSES: {[key in ToolbarPosition]: string[]} = {
		LEFT: ['vertical-toolbar', 'left-toolbar'],
		RIGHT: ['vertical-toolbar', 'right-toolbar'],
		TOP: ['horizontal-toolbar', 'top-toolbar'],
		BOTTOM: ['horizontal-toolbar', 'bottom-toolbar']
	};

	private position: ToolbarPosition;
	private positionListener: Listener;

	constructor(private sessionPreferences: SessionPreferencesService) {
		let preferences = sessionPreferences.get('dashboardEditor') || {};
		this.position = preferences.widgetsToolbarPosition;
		this.positionListener = new Listener();
	}

	registerListener = (listener: () => any): void => this.positionListener.addListener(listener);

	unregisterListener = (listener: () => any): void => this.positionListener.removeListener(listener);

	set = (position: ToolbarPosition): void => {
		this.position = position;
		this.sessionPreferences.setProperty('dashboardEditor', 'widgetsToolbarPosition', position);
		this.positionListener.invokeListeners();
	};

	get = (): ToolbarPosition => this.position;

	isCurrent = (position: ToolbarPosition): boolean => {
		return this.position === position;
	};

	isTop = (): boolean => {
		return this.position === ToolbarPosition.TOP;
	};

	isBottom = (): boolean => {
		return this.position === ToolbarPosition.BOTTOM;
	};

	isLeft = (): boolean => {
		return this.position === ToolbarPosition.LEFT;
	};

	isRight = (): boolean => {
		return this.position === ToolbarPosition.RIGHT;
	};

	isVertical = (): boolean => {
		return this.position === ToolbarPosition.LEFT
		|| this.position === ToolbarPosition.RIGHT;
	};

	isHorizontal = (): boolean => {
		return this.position === ToolbarPosition.TOP
		|| this.position === ToolbarPosition.BOTTOM;
	};

	getClasses = (): string[] => {
		return AddWidgetToolbarPosition.POSITION_CLASSES[this.position];
	};
}

app.service('addWidgetToolbarPosition', AddWidgetToolbarPosition);
