import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { EmptyPeriodType } from '@cxstudio/reports/providers/cb/definitions/empty-period-type';
import * as _ from 'underscore';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';


export class GroupingUtils {

	static isTimeGrouping = (group: AttributeGrouping): boolean => {
		if (!group)
			return false;
		return AnalyticMetricTypes.isTime(group);
	};

	static isDoNotShowEmptyPeriods = (group: AttributeGrouping): boolean => {
		return group
			&& GroupingUtils.isTimeGrouping(group)
			&& GroupingUtils.checkEmptyPeriodType(group, EmptyPeriodType.DO_NOT_SHOW);
	};

	static checkEmptyPeriodType = (group: AttributeGrouping, emptyPeriodType: EmptyPeriodType): boolean => {
		return group
			? group.emptyPeriodType === emptyPeriodType
			: false;
	};

	static isHashtag = (group: AttributeGrouping): boolean => {
		return group.name === ClarabridgeMetricName.HASHTAG;
	};
}
