
import * as uib from 'angular-ui-bootstrap';

import WizardController from '@cxstudio/wizard/wizard-controller';
import WizardStep from '@cxstudio/wizard/wizard-step';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';


class RemoveHierarchyWizardController extends WizardController {

	private static DELETING_GROUPS_STEP_INDEX: number = 1;
	private static FINISH_STEP_INDEX: number = 2;

	private deletingHierarchyGroups?: boolean = null;

	constructor(
		$scope: ng.IScope,
		$uibModalInstance: uib.IModalInstanceService,
		private organizationApiService: OrganizationApiService,
		private hierarchy: Hierarchy
	) {
		super([
			new WizardStep('partials/organizations/remove-hierarchy/remove-hierarchy-wizard-warning-step.html'),
			new WizardStep('partials/organizations/remove-hierarchy/remove-hierarchy-wizard-deleting-groups-step.html',
			() => $scope.hasChosenDeletingHierarchyGroups(),
			() => true),
			new WizardStep('partials/organizations/remove-hierarchy/remove-hierarchy-wizard-result-step.html')
		], $scope, $uibModalInstance);
	}

	$onInit = () => {
		this.$scope.hierarchyGroupsCountPromise = null;
		this.$scope.deleteHierarchyPromise = null;
		this.$scope.hierarchy = this.hierarchy;

		this.$scope.setDeletingHierarchyGroups = (deletingHierarchyGroups: boolean): void => {
			this.deletingHierarchyGroups = deletingHierarchyGroups;
		};

		this.$scope.isDeletingHierarchyGroups = (): boolean => {
			return this.deletingHierarchyGroups;
		};

		this.$scope.hasChosenDeletingHierarchyGroups = (): boolean => {
			return this.$scope.isDeletingHierarchyGroups() !== null;
		};

	};

	protected handleCurrentStep = (index: number): void => {
		if (index === RemoveHierarchyWizardController.DELETING_GROUPS_STEP_INDEX) {
			this.updateGroupDeletionState();
		} else if (index === RemoveHierarchyWizardController.FINISH_STEP_INDEX) {
			this.deleteHierarchy();
		}
	};

	private updateGroupDeletionState = (): void => {
		this.$scope.hierarchyGroupsCount = null;
		this.deletingHierarchyGroups = null;

		this.$scope.hierarchyGroupsCountPromise = this.organizationApiService.getHierarchyGroupsCount(this.hierarchy.id)
			.then((response) => {
				this.$scope.hierarchyGroupsCount = response.data;

				if (this.$scope.hierarchyGroupsCount === 0) {
					this.$scope.next();
				}
			});
	};

	private deleteHierarchy = (): void => {
		this.$scope.deleteHierarchyPromise = this.organizationApiService.removeOrgHierarchy(this.hierarchy.id, this.deletingHierarchyGroups)
			.then(this.$scope.finish);
	};

}

app.controller('RemoveHierarchyWizardController', RemoveHierarchyWizardController);
