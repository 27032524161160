import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { WidgetTemplate } from '@cxstudio/dashboards/widgets/widget-template.class';

export class WidgetTypeFilters {

	static isReportWidget = (widget: Widget | WidgetTemplate): boolean => {
		return (widget.type === WidgetDisplayType.CB);
	};

	static isContentWidget = (widget: Widget | WidgetTemplate): boolean => {
		return ((widget.type === WidgetDisplayType.CONTENT) && (widget.name !== WidgetType.PAGE_BREAK));
	};

	static isPageBreak = (widget: Widget | WidgetTemplate): boolean => {
		return ((widget.type === WidgetDisplayType.CONTENT) && (widget.name === WidgetType.PAGE_BREAK));
	};

	static isPreviewWidget = (widget: Widget | WidgetTemplate): boolean => {
		return (widget.name === WidgetType.PREVIEW);
	};

	static isQualtricsWidget = (widget: Widget | WidgetTemplate): boolean => {
		return (widget.name === WidgetType.QUALTRICS);
	};
}
