import { ContextMenuItem } from '@cxstudio/context-menu/context-menu-item';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import * as _ from 'underscore';

export interface IContextMenuUtils<T> {

	getContextMenu(item: T): Array<ContextMenuItem<T>>;
}

export abstract class BaseContextMenuUtils implements IContextMenuUtils<any> {
	static readonly MENU_DIVIDER = MenuDivider;

	// never allow list where first or last item is divider
	// or two consecutive items are dividers
	static enforceDividerRules(list: any[]): any {
		let dividerCheck: (item) => boolean = (listItem): boolean => {
			return listItem.isDivider;
		};

		return list.preventFirst(dividerCheck).preventLast(dividerCheck).preventConsecutive(dividerCheck);
	}

	abstract getContextMenu(item: any, controller?: any): any[];
	abstract isVisibleObject(item: any): boolean;

	protected wrapToggleFunction(state: boolean, toggleFunction: any): any {
		return (item) => {
			item.hide = !state;
			toggleFunction(item);
		};
	}

	// DON'T use this - it's potentially dangerous (due to making multiple simultaneous calls)
	// and not reliable (if user updates 200 objects and closes browser before all requests are sent,
	// it will cause partial success)
	// instead, create bulk REST endpoints
	protected wrapFunctionForBulkAction(origFunc: any, selectedObjects: any): any {
		return (item: any, folder: any): any => {
			selectedObjects.forEach((object: any) => {
				object.selected = false;
				if (object.parent) {
					object.parent.selected = false;
				}

				if (this.isVisibleObject(object)) {
					// we do not want to perform this function on any item that is selected but
					// no longer visible due to filtering or hiding
					origFunc(object, folder);
				}
			});
		};
	}

	protected isContainsHideAttribute = (state, selectedItems) => {
		return _.filter(selectedItems, (object: any) => {
			let hasHideAttribute = !!object.hide;
			return hasHideAttribute === state;
		}).length > 0;
	};

	extend(menuItem: any, settings: any): any {
		return angular.extend(angular.copy(menuItem), settings);
	}

	concatMenus(...lists: any[][]): any[] {
		return _.chain(lists)
			.filter(items => !_.isEmpty(items))
			.each((items: any[], index: number) => {
				if (index > 0)
					items.insert(0, BaseContextMenuUtils.MENU_DIVIDER);
			}).flatten()
			.value();
	}

	protected disabledOption(option: ContextMenuItem<any>, disabledMessage?: string): ContextMenuItem<any> {
		return angular.extend({}, option, {
			disabled: true,
			disabledMessage,
			func: _.noop
		});
	}
}
