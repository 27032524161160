import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { TranslationCacheService } from '@app/modules/translation/translation-cache.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';

@Component({
	selector: 'translate-label',
	template: `
	<span *ngIf="hasTranslationLanguage()"
		class="d-flex flex-direction-row nowrap overflow-hidden align-center"
		[title]="getLabel()">
		<span class="flex-fill text-ellipsis">{{getLabel()}}</span>
	</span>
	<span *ngIf="!hasTranslationLanguage()" class="flex-fixed q-icon q-icon-spinner rotate-infinite ml-4"></span>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TranslateLabelComponent extends SelfCleaningComponent implements OnInit {

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly translateCache: TranslationCacheService,
		private readonly locale: CxLocaleService,
	) {
		super();
	 }

	ngOnInit(): void {
		this.translateCache.updateLastTranslateLanguage(undefined);
		this.addSubscription(this.translateCache.getTranslateObserver().subscribe(() => this.ref.markForCheck()));
	}

	hasTranslationLanguage(): boolean {
		return !!this.translateCache.getLastTranslateLanguage();
	}

	getLabel(): string {
		return this.locale.getString('docExplorer.translatedFrom', {from: this.translateCache.getLastTranslateLanguage() || ''});
	}
}

app.directive('translateLabel', downgradeComponent({component: TranslateLabelComponent}));
