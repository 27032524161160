import * as _ from 'underscore';
import { ConversationChartOptions } from '../conversation-chart-options.class';
import { ConversationDataPoint } from '../conversation-data-point.class';
import { BasicSizing } from '@cxstudio/conversation/sizing/basic-sizing.class';

export class ChatSizing extends BasicSizing {

	readonly SILENCE_HEIGHT = 16;

	constructor(chartHeight: number, config: ConversationChartOptions) {
		super(chartHeight, config);
	}

	getPointFromTimestamp = (timestamp: number, dataSet: ConversationDataPoint[]): ConversationDataPoint => {
		return _.filter(dataSet, (data, index) => {
			let afterStart = data.timestamp <= timestamp;
			let nextItem = dataSet[index + 1];
			let beforeEnd = nextItem ? nextItem.timestamp > timestamp : true;
			return afterStart && beforeEnd;
		}).last();
	};

	protected getBiggestBlock(dataSet: ConversationDataPoint[]): ConversationDataPoint {
		return _.chain(dataSet)
			.filter(data => !data.isSilence)
			.max(data => data.duration)
			.value() as ConversationDataPoint;
	}

	protected getDatapointHeight(dataPoint: ConversationDataPoint): number {
		if (!dataPoint) return 0;
		return dataPoint.isSilence ? this.SILENCE_HEIGHT : super.getDatapointHeight(dataPoint);
	}
}
