import * as _ from 'underscore';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { Security } from '@cxstudio/auth/security-service';
import { Caches } from '@cxstudio/common/caches';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { BulkPermissionsUpdate } from '@app/modules/user-bulk/bulk-permissions/bulk-permissions.component';
import { GroupQueryParams } from '@cxstudio/user-administration/groups/group-query-params';
import { InternalUser } from '@cxstudio/user-administration/users/entities/internal-user';
import { LicenseApiService } from '@app/modules/user-administration/services/license-api.service';

export class UsersGroupsApiService {

	constructor(
		private $http: ng.IHttpService,
		private $q: ng.IQService,
		private security: Security,
		private httpHandlers: HttpHandlers,
		private cachedHttpService: CachedHttpService,
		private licenseApiService: LicenseApiService) {}

	getMasterAccountUsersPaged = (queryParams) => {
		//use queryFilters: [{filterField: xxx, filterText:xxx}] instead of filterText and filterField
		return this.$http.get('rest/users/paged', {params: queryParams})
			.then(this.httpHandlers.success)
			.then(userData => {
				userData.data = userData.data.map(this.licenseApiService.localizeLicenseTypeName);
				return userData;
			});
	};

	getAvailableInternalUsersForMasterAccount = (): ng.IPromise<InternalUser[]> => {
		return this.$http.get('rest/users/internal').then(this.httpHandlers.success);
	};

	getMasterAccountGroups = () => {
		let endpoint = (this.security.has('manage_groups')) ?
			'rest/groups' :
			'rest/groups/current-user';

		return this.$http.get(endpoint);
	};

	getGroupsForMasterAccounts = (masterAccountIds: number[])  => {
		return this.$http.post('rest/system_admin/groups/belonged-to-accounts', masterAccountIds);
	};

	removeUserFromNonDefaultMasterAccounts = (userIds: number[]) => {
		let bulkData = {
			users: userIds
		};
		return this.$http.put('rest/users/bulk/remove-from-multiple-ma', angular.toJson(bulkData));
	};

	getGroupMembership = (selectedUsers) => {
		return this.$http.post('rest/groups/belonged-to/',
			selectedUsers);
	};

	getPermissions = () => {
		return this.cachedHttpService.cache(Caches.PERMISSIONS).get('rest/permissions', {cache: true})
			.then(this.httpHandlers.success);
	};

	//Group Calls
	removeGroup = (groupId) => {
		return this.$http.delete('rest/groups/' + groupId);
	};

	editGroup = (groupData) => {
		return this.$http.put('rest/groups/' + groupData.groupId,
			angular.toJson(groupData));
	};

	addGroup = (groupData): ng.IHttpPromise<any> => {
		return this.$http.post('rest/groups', angular.toJson(groupData));
	};

	updateGroupSubscriptions = (groupId, subscriptionData) => {
		return this.$http.put('rest/alerts/subscriptions/group/' + groupId, angular.toJson(subscriptionData));
	};

	updateGroupPermissionsInBulk = (groupIds: number[], permissions: BulkPermissionsUpdate, params: GroupQueryParams): ng.IPromise<any> => {
		if (_.isEmpty(permissions.added) && _.isEmpty(permissions.removed)) {
			return this.$q.when();
		}

		let bulkData = {
			groupIds,
			added: permissions.added,
			removed: permissions.removed
		};

		return this.$http.put('rest/groups/bulk/permissions/update', angular.toJson(bulkData), { params });
	};

	removeGroupsInBulk = (groupIds, params): ng.IPromise<any> => {
		return this.$http.put('rest/groups/bulk/delete', groupIds, { params });
	};

	getFullGroup = (groupId) => {
		return this.$http.get('rest/groups/' + groupId);
	};

	isUniqueGroupName = (type, name) => {
		return this.$http.get('rest/groups/unique', { params: {
			type,
			name
		}}).then(this.httpHandlers.success);
	};

	searchGroupMemberCandidates = (groupId, search, limit) => {
		return this.$http.get('rest/groups/candidates/members', {
			params: {
				groupId,
				search,
				limit
			}
		}).then(this.httpHandlers.success);
	};

	searchNewGroupMemberCandidates = (search, limit) => {
		return this.$http.get('rest/groups/new-group/candidates/members', {
			params: {
				search,
				limit
			}
		}).then(this.httpHandlers.success);
	};

	searchGroupOwnerCandidates = (search, limit) => {
		return this.$http.get('rest/groups/candidates/owners', {
			params: {
				search,
				limit
			}
		}).then(this.httpHandlers.success);
	};

	countUsers = (accessChange, limit) => {
		return this.$http.post('rest/sharing/count', accessChange, { params: { limit } }).then(this.httpHandlers.success);
	};

}

app.service('usersGroupsApiService', UsersGroupsApiService);
