import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DashboardListPageComponent } from '@app/modules/dashboard-list/page/dashboard-list-page.component';
import { DowngradeToastService } from '@app/modules/downgrade-utils/downgrade-toast.service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { BaseContextMenuUtils } from '@cxstudio/common/context-menu-utils/base-context-menu-utils';
import { IDashboardActionsService } from '@cxstudio/dashboards/dashboard-list/dashboard-actions-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { FolderContextMenuUtils } from '@cxstudio/folders/folder-context-menu-utils.service';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { MenuDivider } from '@cxstudio/context-menu/drill-menu-option.component';
import * as _ from 'underscore';
import * as cloneDeep from 'lodash.clonedeep';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';

app.factory('DashboardContextMenuUtils', function(FolderService, security, locale, dashboardService: DashboardService,
	DashboardActionsService, dashboardPermissionUtils, dashboardListService: DashboardListService,
	downgradeToastService: DowngradeToastService,
	betaFeaturesService: BetaFeaturesService,
) {
	return class DashboardContextMenuUtils extends BaseContextMenuUtils {
		private scope: DashboardListPageComponent;
		private folderSvc: any;
		private actionsService: IDashboardActionsService;

		private DASHBOARD_OPTIONS = {
			VIEW: {
				text: locale.getString('common.view'),
				name: 'view',
				func: (dashboard) => {
					if (this.scope.isCurrentUserSelected()) {
						this.actionsService.viewDashboard(dashboard);
					}
				}
			},

			MOVE: {
				text: locale.getString('dashboard.move'),
				name: 'move',
				searchBox: true,
				isExpandable: true
			},

			EDIT: {
				text: locale.getString('common.edit'),
				name: 'edit',
				func: (dashboard) => {
					if (this.scope.isCurrentUserSelected()) {
						this.actionsService.editDashboard(dashboard);
					}
				}
			},

			COPY_LINK: {
				text: locale.getString('common.copyLink'),
				name: 'copy_link',
				func: (dashboard, object, model, event) => dashboardService.copyDashboardLink(dashboard, event),
			},

			EDIT_PROPERTIES: {
				text: locale.getString('dashboard.editProps'),
				name: 'edit_properties',
				func: (dashboard) => this.actionsService.editDashboardProperties(dashboard)
			},

			RENAME: {
				text: locale.getString('common.rename'),
				name: 'rename',
				func: (dashboard) => this.actionsService.renameDashboard(dashboard)
			},

			EXPORT: {
				text: locale.getString('dashboard.exportPDF'),
				name: 'export_pdf_menu'
			},

			EXPORT_DOWNLOAD: {
				text: locale.getString('dashboard.downloadPdf'),
				name: 'export_pdf_download',
				func: (dashboard) => this.actionsService.dashboardExportPdfDownload(dashboard),
				focusSubmenuOption: true
			},

			EXPORT_SEND: {
				text: locale.getString('dashboard.ReceiveByEmail'),
				name: 'export_pdf_send',
				func: (dashboard) => this.actionsService.dashboardExportPdfSend(dashboard),
				focusSubmenuOption: true
			},

			CREATE_TEMPLATE: {
				text: locale.getString('templates.menuSaveAsTemplate'),
				name: 'createTemplate',
				func: (dashboard) => this.actionsService.saveDashboardAsTemplate(dashboard)
			},

			CREATE_TEMPLATE_BETA: {
				text: locale.getString('templates.menuSaveAsTemplateBeta'),
				name: 'createTemplate',
				func: (dashboard) => this.actionsService.saveDashboardAsTemplateBeta(dashboard)
			},

			SHARE: {
				text: locale.getString('dashboard.share'),
				name: 'share',
				func: (dashboard) => this.actionsService.shareDashboard(dashboard)
			},

			COPY: {
				text: locale.getString('dashboard.copy'),
				name: 'copy',
				func: (dashboard) => this.actionsService.copyDashboard(dashboard)
			},

			SHOW_RATING: {
				text: locale.getString('dashboard.rate'),
				name: 'rate',
				func: (dashboard, object, model, event) => this.actionsService.showRatingDialog(event, dashboard)
			},

			OPEN_SCHEDULE: {
				text: locale.getString('schedule.schedule'),
				name: 'schedule',
				func: (dashboard) => this.actionsService.openScheduleDialog(dashboard)
			},

			REMOVE: {
				name: 'delete',
				func: (dashboard) => this.actionsService.removeDashboard(dashboard)
			},

			FAVORITE: {
				text: locale.getString('common.toggleFavorite'),
				name: 'favoriteDashboard',
				func: (dashboard) => this.scope.toggleFavoriteAndRefresh(dashboard)
			},

			EDIT_LABELS: {
				text: locale.getString('common.editLabels'),
				name: 'editLabels'
			},

			SHOW: {
				text: locale.getString('common.show'),
				name: 'showDashboard',
				func: (dashboard) => this.actionsService.toggleHide(dashboard),
				disabled: false,
				focusSubmenuOption: true
			},

			HIDE: {
				text: locale.getString('common.hide'),
				name: 'hideDashboard',
				func: (dashboard) => this.actionsService.toggleHide(dashboard), disabled: false,
				focusSubmenuOption: true
			},

			REMOVE_SELECTED: {
				text: locale.getString('dashboard.deleteSelected'),
				name: 'delete'
			},

			TRANSFER_SELECTED: {
				text: locale.getString('dashboard.transferOwnerWidgetConfirmOption'),
				name: 'transfer'
			},

			CREATE: {
				text: locale.getString('dashboard.createNewDashboard'),
				name: 'create_dashboard_in_folder',
				func: (folder) => this.actionsService.createDashboard(folder)
			},

			COMPONENTS: {
				text: locale.getString('dashboard.dashboardComponents'),
				name: 'components',
				func: (dashboard) => this.actionsService.showDashboardComponents(dashboard)
			},

			SHOW_HIDE: {
				text: locale.getString('common.showHide'),
				name: 'show_hide'
			},

			SHOW_HIDDEN: {
				text: locale.getString('dashboard.showAllHidden'),
				name: 'toggleHidden',
				classes: ['items-hidden'],
				func: () => { this.scope.ui.hideDashboards = false; },
				focusSubmenuOption: true
			},

			HIDE_HIDDEN: {
				text: locale.getString('dashboard.hideAllHidden'),
				name: 'toggleHidden',
				classes: ['items-visible'],
				func: () => { this.scope.ui.hideDashboards = true; },
				focusSubmenuOption: true
			},
		};

		private FOLDER_OPTIONS = {
			CREATE: {
				text: locale.getString('dashboard.addSubfolder'),
				name: 'add_folder',
				func: (folder) => { this.actionsService.createFolder(folder); }
			},

			RENAME: {
				text: locale.getString('common.rename'),
				name: 'rename',
				func: (folder) => this.actionsService.renameFolder(folder)
			},

			REMOVE: {
				name: 'delete',
				func: (folder) => this.actionsService.removeFolder(folder)
			}
		};

		private BOOK_OPTIONS = {
			EDIT: {
				text: locale.getString('common.edit'),
				name: 'edit',
				func: (dashboard) => { this.actionsService.enableTabsEditor(dashboard); }
			},

			CREATE: {
				text: locale.getString('dashboard.createNewBook'),
				name: 'create_book_in_folder',
				func: (folder) => this.actionsService.enableTabsEditor(undefined, folder)
			},

			RENAME: {
				text: locale.getString('common.rename'),
				name: 'rename',
				func: (book) => this.actionsService.renameBook(book)
			},

			COPY_LINK: {
				text: locale.getString('common.copyLink'),
				name: 'copy_link',
				func: (book, object, model, event) => dashboardService.copyBookLink(book, event),
			},

			COMPONENTS: {
				text: locale.getString('dashboard.bookComponents'),
				name: 'book_components',
				func: (book) => this.actionsService.showBookComponents(book)
			}
		};

		constructor(scope: DashboardListPageComponent) {
			super();
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.DASHBOARD);
			this.actionsService = new DashboardActionsService(this.scope);
		}

		getContextMenu(item: any): any[] {
			if (this.scope.selectionUtils.multipleObjectsSelected()) {
				return this.getMultiSelectMenuOptions();
			}

			if (item.type === GridContext.DASHBOARD) {
				return this.getSingleItemMenuOptions(item);
			}

			if (item.type === GridContext.DASHBOARD_SET) {
				return this.getBookMenuOptions(item);
			}

			return this.getFolderMenuOptions(item);
		}

		isVisibleObject(item: any): boolean {
			return this.scope.selectionUtils.visibleObjFilter(item);
		}

		private getSingleItemMenuOptions(item: any): any[] {
			let items: any[] = [this.DASHBOARD_OPTIONS.VIEW];

			if (security.has('edit_dashboard') && item.permissions.EDIT) {
				if (!this.scope.isTablet) {
					items.push(this.DASHBOARD_OPTIONS.EDIT);
				}
				if (security.isAdminOrgUser()) {
					items.push(this.DASHBOARD_OPTIONS.EDIT_PROPERTIES);
				}
				items.push(this.DASHBOARD_OPTIONS.RENAME);
			}

			if (dashboardService.canShare(item)) {
				items.push(this.DASHBOARD_OPTIONS.SHARE);
			}

			items.push(this.DASHBOARD_OPTIONS.COPY_LINK);

			items.push(this.extend(this.DASHBOARD_OPTIONS.EXPORT, {
				items: [ this.DASHBOARD_OPTIONS.EXPORT_DOWNLOAD, this.DASHBOARD_OPTIONS.EXPORT_SEND ]}));

			let moveToItems = this.folderSvc.getFoldersForMove(dashboardListService.getCurrentDashboardsList(), item, (dashboard, folder) => {
				this.actionsService.moveDashboard(dashboard, folder);
			});

			if (security.isAdminOrgUser() && this.scope.createTemplateFromDashboardEnabled) {
				items.push(this.DASHBOARD_OPTIONS.CREATE_TEMPLATE);
			}

			if (betaFeaturesService.isFeatureEnabled(BetaFeature.NEW_DASHBOARD_TEMPLATES)
					&& security.isAdminOrgUser()
					&& security.has(MasterAccountPermissionAction.CREATE_DASHBOARD)
					&& security.has(ApplicationPermissionAction.CREATE_INTERNAL_TEMPLATES)) {
				items.push(this.DASHBOARD_OPTIONS.CREATE_TEMPLATE_BETA);
			}

			items.push(MenuDivider);

			if (moveToItems && moveToItems.length > 0) {
				items.push(this.extend(this.DASHBOARD_OPTIONS.MOVE, {items: moveToItems}));
			}

			if (security.has('create_dashboard') && !this.scope.isTablet) {
				items.push(this.DASHBOARD_OPTIONS.COPY);
			}

			if (item.permissions.EDIT && security.has('manage_projects')) {
				items.push(this.DASHBOARD_OPTIONS.COMPONENTS);
			}

			if (!item.permissions.OWN && this.scope.showDashboardRating) {
				items.push(this.DASHBOARD_OPTIONS.SHOW_RATING);
			}

			if (dashboardPermissionUtils.canScheduleDashboard(item)) {
				items.push(this.DASHBOARD_OPTIONS.OPEN_SCHEDULE);
			}

			let showHideOptions = this.getShowHideOptions(item);
			items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			items.push(this.DASHBOARD_OPTIONS.FAVORITE);

			if (item.permissions.OWN) {
				items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.REMOVE, {text: this.getDeletionText(item)}));
				this.addTransferringOption(items, [item]);
			}

			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		getShowHideOptions = (item?: any | any[]) => {
			let showOption = cloneDeep(this.DASHBOARD_OPTIONS.SHOW);
			let hideOption = cloneDeep(this.DASHBOARD_OPTIONS.HIDE);
			let toggleVisibility = (this.scope.ui.hideDashboards) ?
				this.DASHBOARD_OPTIONS.SHOW_HIDDEN :
				this.DASHBOARD_OPTIONS.HIDE_HIDDEN;

			if (!item) {
				return [toggleVisibility];
			}
			if (item.constructor === Array) {
				let selectedItems = item;
				hideOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(true, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
				showOption.func =
					this.wrapFunctionForBulkAction(
						this.wrapToggleFunction(false, (oneItem) => this.actionsService.toggleHide(oneItem)), selectedItems);
			} else {
				if (item.hide) {
					hideOption.disabled = true;
					hideOption.func = _.noop;
				} else {
					showOption.disabled = true;
					showOption.func = _.noop;
				}
			}

			return [showOption, hideOption, MenuDivider, toggleVisibility];
		};

		private getMultiSelectMenuOptions(): any[] {
			let items: any[] = [];

			let selectedDashboards: any = _.filter(dashboardListService.getCurrentDashboardsList(), (dashboard) => {
				return this.scope.selectionUtils.isSupportedType(dashboard) && dashboard.selected;
			});

			let moveToItems = _.uniq(_.flatten(_.map(selectedDashboards, (dashboard) => {
				return this.folderSvc.getFoldersForMove(dashboardListService.getCurrentDashboardsList(), dashboard,
					this.wrapFunctionForBulkAction((object, folder) => {
						this.actionsService.moveDashboard(object, folder);
					}, selectedDashboards));

			})), false, (moveItem) => moveItem.text);

			if (moveToItems && moveToItems.length > 0) {
				items.push(this.extend(this.DASHBOARD_OPTIONS.MOVE, {items: moveToItems}));
			}

			if (dashboardService.hasSharePermission()) {
				let shareDashboardsBulk = () => {
					dashboardService.shareDashboardsBulk(selectedDashboards)
						.then((changedDashboards) => {
							dashboardListService.updateDashboardsOld(changedDashboards);
						});
				};
				items.push(this.extend(this.DASHBOARD_OPTIONS.SHARE, {func: shareDashboardsBulk}));
			}

			if (_.filter(selectedDashboards, (dashboard: Dashboard) => {
				return dashboard.ownerId === security.loggedUser.user.userId || dashboard.permissions.EDIT === true;
			}).length === selectedDashboards.length) {
				let bulkUpdateLabels = () => {
					let ids = _.pluck(selectedDashboards, 'id');
					this.actionsService.bulkUpdateLabels().result.then(labels => {
						let updateLabelsEntity = new BulkUpdateLabelsEntity(labels, ids);
						this.actionsService.updateLabelsRequest(updateLabelsEntity).then(updateLabelsPromise => {
							const updatedDashboards = updateLabelsPromise.data;
							_.each(updatedDashboards, updatedDashboard => {
								updatedDashboard.selected = false;
							});
							const msg = ' ' + updatedDashboards.length + ' ' + locale.getString('dashboard.dashboards');
							downgradeToastService.addToast(`${locale.getString('filter.updatedLabel')} ${msg}.`);
							dashboardListService.updateDashboardsOld(updatedDashboards);
						}).catch(err => {
							_.noop();
						});
					}).catch(() => {
						_.noop();
					});
				};
				items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.EDIT_LABELS, {func: bulkUpdateLabels}));
			}

			let showHideOptions = this.getShowHideOptions(selectedDashboards);
			items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			if (_.filter(selectedDashboards, (dashboard: any) => {
				return dashboard.ownerId === security.loggedUser.user.userId;
			}).length === selectedDashboards.length) {
				//If all dashboards selected are owned by current user
				let removeDashboardBulk = () => {
					dashboardService.removeDashboardsBulk(selectedDashboards)
						.then(() => this.scope.selectionUtils.clearSelections());
				};
				items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.REMOVE_SELECTED, {func: removeDashboardBulk}));

				if (_.filter(selectedDashboards, (dashboard: any) => {
					return dashboard.type === DashboardType.DASHBOARD || dashboard.type === DashboardType.BOOK;
				}).length === selectedDashboards.length) {
					this.addTransferringOption(items, selectedDashboards);
				}
			}

			return BaseContextMenuUtils.enforceDividerRules(items);
		}

		private addTransferringOption(menuItems: any[], selectedItems: any[]): void {
			menuItems.push(this.extend(this.DASHBOARD_OPTIONS.TRANSFER_SELECTED, {
				func: () => {
					dashboardService.transferDashboardsBulk(selectedItems).then(() => dashboardListService.reloadDashboardsOld());
				}
			}));
		}

		private getFolderMenuOptions(item: any): any[] {
			let items: any[] = [];

			if (security.has('create_dashboard') && !this.scope.isTablet) {
				items.push(this.DASHBOARD_OPTIONS.CREATE);
				items.push(this.BOOK_OPTIONS.CREATE);
			}

			if (item.level < FolderContextMenuUtils.MAX_DEPTH - 1) {
				items.push(this.FOLDER_OPTIONS.CREATE);
			}

			items.push(this.FOLDER_OPTIONS.RENAME);

			let moveToItems = this.folderSvc.getFoldersForMove(dashboardListService.getCurrentDashboardsList(), item, (folder, folderTo) => {
				this.actionsService.moveToFolder(folder, folderTo);
			});

			if (moveToItems && moveToItems.length > 0) {
				items.push(this.extend(this.DASHBOARD_OPTIONS.MOVE, {items: moveToItems}));
			}

			items.push(MenuDivider, this.extend(this.FOLDER_OPTIONS.REMOVE, {text: this.getDeletionText(item)}));

			return items;
		}

		private getBookMenuOptions(item: any): any[] {
			let items: any[] = [];

			items.push(this.DASHBOARD_OPTIONS.VIEW);

			if (security.has('edit_dashboard') && item.permissions.EDIT) {
				items.push(this.BOOK_OPTIONS.EDIT);
				items.push(this.BOOK_OPTIONS.RENAME);
			}

			items.push(this.BOOK_OPTIONS.COPY_LINK);

			if (dashboardService.canShare(item)) {
				items.push(this.DASHBOARD_OPTIONS.SHARE);
			}

			items.push(MenuDivider);

			let moveToItems = this.folderSvc.getFoldersForMove(dashboardListService.getCurrentDashboardsList(), item, (book, folder) => {
				this.actionsService.moveDashboard(book, folder);
			});

			if (moveToItems && moveToItems.length > 0) {
				items.push(this.extend(this.DASHBOARD_OPTIONS.MOVE, {items: moveToItems}));
			}

			if (security.has('create_dashboard') && !this.scope.isTablet) {
				items.push(this.DASHBOARD_OPTIONS.COPY);
			}

			if (item.permissions.EDIT) {
				items.push(this.BOOK_OPTIONS.COMPONENTS);
			}

			if (!item.permissions.OWN && this.scope.showDashboardRating) {
				items.push(this.DASHBOARD_OPTIONS.SHOW_RATING);
			}

			let showHideOptions = this.getShowHideOptions(item);
			items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.SHOW_HIDE, { items: showHideOptions }));

			items.push(this.DASHBOARD_OPTIONS.FAVORITE);

			if (item.permissions.OWN) {
				items.push(MenuDivider, this.extend(this.DASHBOARD_OPTIONS.REMOVE, {text: this.getDeletionText(item)}));
				this.addTransferringOption(items, [item]);
			}

			return items;
		}

		private getDeletionText(item: any): string {
			if (item.type === 'dashboard') return locale.getString('dashboard.menuDeleteDashboard');
			if (item.type === 'dashboardSet') return locale.getString('dashboard.menuDeleteBook');
			if (item.type === 'folder') {
				if (item.level > 0) return locale.getString('dashboard.menuDeleteSubfolder');
				return locale.getString('dashboard.menuDeleteFolder');
			}
			return locale.getString('common.delete');
		}
	};
});
