import * as _ from 'underscore';

import MobileAppSettingsListModel from './mobile-app-settings-list-model.interface';
import Listener from '@cxstudio/common/listener';
import { CBDialogService, ICustomDialogData } from '@cxstudio/services/cb-dialog-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { GlobalNotificationService } from '@cxstudio/common/global-notification/global-notification-service';
import MobileAppSettingsApiService from './mobile-app-settings-api.service';
import { Errors } from '@cxstudio/common/errors';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import MobileAppSettings from './mobile-app-settings.interface';
import { UniqueNameService } from '@cxstudio/common/unique-name-service';
import { MobileScreenType } from './mobile-screen-type.enum';
import SimpleGroupingMobileScreenSettings from './simple-grouping-mobile-screen-settings.interface';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { MobileAppSettingsSave } from './mobile-app-settings-save.interface';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import { ConfirmMobileAppOwnerChangeDialogComponent, ConfirmMobileAppOwnerChangeDialogOutput } from '@app/modules/mobile/confirm-mobile-app-owner-change-dialog.component';
import { MobileAppSettingsList } from './mobile-app-settings-list';
import { SharingService } from '@cxstudio/sharing/sharing-service.service';
import { MobileApplicationWidgetBuilderService } from '@app/modules/mobile/mobile-application-widget-builder.service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { MobileApplicationHelperService } from '@app/modules/mobile/mobile-application-helper.service';
import { ModelTree } from '@app/shared/components/tree-selection/model-tree';
import { PromiseUtils } from '@app/util/promise-utils';
import DashboardCreationOrigin from '@app/modules/dashboard/dashboard-creation-origin.enum';

export class MobileSettingsActionService {

	constructor(
		private cbDialogService: CBDialogService,
		private locale: ILocale,
		private $q: ng.IQService,
		private globalNotificationService: GlobalNotificationService,
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private uniqueNameService: UniqueNameService,
		private mobileUtils: MobileUtils,
		private confirmMobileAppOwnerChangeDialog: ConfirmMobileAppOwnerChangeDialogComponent,
		private sharingService: SharingService,
		private dashboardService,
		private mobileApplicationWidgetBuilderService: MobileApplicationWidgetBuilderService,
		private mobileApplicationHelperService: MobileApplicationHelperService
	) {
	}

	shareSettings = (settingsModel: MobileAppSettingsListModel): ng.IPromise<void> => {
		const saveListener = new Listener();
		const errors = new Errors();
		errors.setActive(false);

		const dialog = this.cbDialogService.showDialog({
			header: this.locale.getString('mobile.appAccess'),
			template: 'partials/mobile/mobile-app-sharing-dialog.html',
			controller: 'MobileAppSettingsSharingCtrl',
			windowClass: 'br-share-dialog',
			ignoreEscape: true,
			okBtnName: this.locale.getString('common.update'),
			cancelBtnName: this.locale.getString('common.cancel'),
			settingsModel,
			saveListener,
			errors,
			isSaveDisabled: () => errors.isActive() && errors.hasAny(),
			save: (modalInstance, modalData) => {
				errors.setActive(true);
				if (errors.hasAny()) {
					return this.$q.reject();
				}

				saveListener.invokeListeners();

				return this.processMobileAccessChange(settingsModel.id, modalData)
					.then(() => modalInstance.close())
					.then(() => this.globalNotificationService.addSavedNotification());
			},
			cancel: (modalInstance, modalData) => {
				saveListener.invokeListeners();
				this.confirmSharingCancel(modalData).then(modalInstance.dismiss);
			},
			items: [ settingsModel ]
		});

		return dialog.result;
	};

	viewSettings = (settingsList: MobileAppSettingsList, settingsModel: MobileAppSettingsListModel,
			widgetSettingsCache: CachedInvocation): ng.IPromise<void> => {
		let dialog = this.buildEditSettingsDialog(settingsList, settingsModel, widgetSettingsCache);
		dialog.isSaveDisabled = () => true;

		return this.cbDialogService.showDialog(dialog).result;
	};

	editSettings = (settingsList: MobileAppSettingsList, settingsModel: MobileAppSettingsListModel,
			widgetSettingsCache: CachedInvocation): ng.IPromise<void> => {
		const dialog = this.buildEditSettingsDialog(settingsList, settingsModel, widgetSettingsCache);
		return this.cbDialogService.showDialog(dialog).result;
	};

	private buildEditSettingsDialog = (settingsList: MobileAppSettingsList, settingsModel: MobileAppSettingsListModel,
			widgetSettingsCache: CachedInvocation): ICustomDialogData => {
		const dialog = this.buildSettingsDialogData(
			settingsList,
			settingsModel.name,
			(modalInstance, dialogData) => this.saveEditedSettings(settingsModel, modalInstance, dialogData),
			widgetSettingsCache);
		dialog.settingsModel = angular.copy(settingsModel);

		return dialog;
	};

	createSettings = (settingsList: MobileAppSettingsList, widgetSettingsCache: CachedInvocation): ng.IPromise<void> => {
		return this.mobileAppSettingsApiService.getNewSettings()
			.then((settings: MobileAppSettings) => {
				settings.name = this.uniqueNameService.resolveUniqueName(settingsList.items.map(item => item.name), settings.name);

				const dialogData = this.buildSettingsDialogData(
					settingsList,
					settings.name,
					this.saveNewSettings,
					widgetSettingsCache);
				dialogData.settings = settings;

				return this.cbDialogService.showDialog(dialogData).result;
			});
	};

	private saveNewSettings = (modalInstance, dialogData): ng.IPromise<any> => {
		let settings = this.postprocessSettings(angular.copy(dialogData.settings));

		return this.mobileAppSettingsApiService.createSettings(settings)
			.then((createdSettings) => this.processMobileAccessChange(createdSettings.id, dialogData))
			.then(() => modalInstance.close())
			.then(() => this.globalNotificationService
				.addSuccessNotification(this.locale.getString('mobile.notificationSavedApplication', { name: settings.name })));
	};

	private saveEditedSettings = (settingsModel: MobileAppSettingsListModel, modalInstance, dialogData): ng.IPromise<any> => {
		let settings = this.postprocessSettings(angular.copy(dialogData.settings));

		return this.prepareSettingsSave(settings, dialogData).then(settingsSave => {
			return this.mobileAppSettingsApiService.saveSettings(settingsSave)
				.then(() => this.canShare(settingsModel)
					? this.processMobileAccessChange(settings.id, dialogData)
					: this.$q.when()
				)
				.then(() => modalInstance.close())
				.then(() => this.globalNotificationService
					.addSuccessNotification(this.locale.getString('mobile.notificationSavedApplication', { name: settings.name })));
		});
	};

	private postprocessSettings = (settings: MobileAppSettings): MobileAppSettings => {
		settings.customFilter.filterRules = settings.customFilter.filterRules.filter(rule => rule.type !== 'EMPTY');
		settings.savedFilter.filters = settings.savedFilter.filters.filter(filter => filter.type !== 'EMPTY');

		this.hideOptionalScreens(settings);

		return settings;
	};

	private hideOptionalScreens = (settings: MobileAppSettings): void => {
		settings.screens
			.filter(screen => screen.optional && screen.hidden && screen.type === MobileScreenType.SIMPLE_GROUPING)
			.map(screen => (screen as SimpleGroupingMobileScreenSettings).grouping = null);
	};

	private prepareSettingsSave = (settings: MobileAppSettings, dialogData): ng.IPromise<MobileAppSettingsSave> => {
		let originalSettings: MobileAppSettings = dialogData.originalSettings;

		if (this.mobileUtils.isOwnerChanged(originalSettings, settings)) {
			return this.confirmOwnerChange(settings) as any;
		} else {
			let ownerOptions = { ownerChanged: false };
			return this.$q.when({ settings, ownerOptions });
		}
	};

	private confirmOwnerChange = (settings: MobileAppSettings) => {
		let dialogData = {
			submitterEmail: settings.submitterEmail
		};

		let modal = this.confirmMobileAppOwnerChangeDialog.open(dialogData);
		return modal.result.then((output: ConfirmMobileAppOwnerChangeDialogOutput) => {
			return { settings, ownerOptions: { ownerChanged: true, retainingEditPermission: output.retainingEditPermission } };
		});
	};

	private buildSettingsDialogData = (
			settingsList: MobileAppSettingsList,
			name: string,
			saveCallback: (modalInstance, dialogData) => ng.IPromise<any>,
			widgetSettingsCache: CachedInvocation): ICustomDialogData => {
		const saveListener = new Listener();
		const errors = new Errors();
		errors.setActive(false);

		return {
			header: name,
			template: 'partials/mobile/edit-dialog/mobile-app-settings-dialog.html',
			controller: 'MobileAppSettingsDialogCtrl',
			windowClass: 'modal-md',
			ignoreEscape: true,
			okBtnName: this.locale.getString('common.save'),
			cancelBtnName: this.locale.getString('common.cancel'),
			saveListener,
			widgetSettingsCache,
			errors,
			settingsList,
			isSaveDisabled: () => errors.isActive() && errors.hasAny(),
			save: (modalInstance, dialogData) => {
				errors.setActive(true);
				if (errors.hasAny()) {
					return this.$q.reject();
				}

				saveListener.invokeListeners();
				const savePromise = saveCallback(modalInstance, dialogData);
				dialogData.promises.settings.promise = savePromise;
				return savePromise;
			},
			cancel: (modalInstance, dialogData) => {
				saveListener.invokeListeners();

				const savePromise = this.confirmSettingsChanges(modalInstance, dialogData)
					.then(() => saveCallback(modalInstance, dialogData));
				dialogData.promises.settings.promise = savePromise;
				return savePromise;
			}
		};
	};

	private confirmSettingsChanges = (settingsDialog, settingsDialogData): ng.IPromise<any> => {
		if (this.isViewMode(settingsDialogData)) {
			settingsDialog.dismiss();
			return this.$q.reject();
		} else if (!this.hasChanges(settingsDialogData)) {
			settingsDialog.dismiss();
			return this.$q.reject();
		} else {
			return this.cbDialogService.showDialog({
				header: this.locale.getString('mobile.unsavedChangesHeader'),
				text: this.locale.getString('mobile.unsavedChangesText'),
				okBtnName: 'common.save',
				cancelBtnName: 'common.cancel',
				isSaveDisabled: () => settingsDialogData.isSaveDisabled(),
				customButtons: [{
					text: this.locale.getString('administration.dontsave'),
					cssClass: 'btn-secondary btn-dont-save',
					callback: (dialogInstance, modalData) => {
						dialogInstance.dismiss();
						settingsDialog.dismiss();
					}
				}]
			})
			.result;
		}
	};

	private isViewMode = (settingsDialogData): boolean => {
		const settingsModel = settingsDialogData.settingsModel;
		return settingsModel && settingsModel.accessLevel === AssetPermission.VIEW;
	};

	private hasChanges = (settingsDialogData): boolean => {
		let originalSettings: MobileAppSettings = settingsDialogData.originalSettings;
		let settings: MobileAppSettings = settingsDialogData.settings;
		let changedEntities = settingsDialogData.changedEntities || [];

		let sharingChanged = changedEntities.length;
		let settingsChanged = !_.isEqual(originalSettings, settings);

		return sharingChanged || settingsChanged;
	};

	private confirmSharingCancel = (modalData): ng.IPromise<any> => {
		if (!modalData.changedEntities.length) {
			return this.$q.when();
		} else {
			let confirmDialog = this.cbDialogService.confirm(
				this.locale.getString('mobile.unsavedChangesHeader'),
				this.locale.getString('mobile.unsavedChangesText'),
				this.locale.getString('administration.dontshare'),
				this.locale.getString('common.cancel'));

			return confirmDialog.result;
		}
	};

	private processMobileAccessChange = (settingsId: number, dialogData): ng.IPromise<any> => {
		let allEntities = this.sharingService.prepareEntities(dialogData);
		let accessChange = _.groupBy(allEntities, 'type'); // user and group

		let allowEditorsToShare = dialogData.items[0].reshareAllowed;
		let accessUpdate = {
			allowEditorsToShare,
			accessChange
		};
		return this.mobileAppSettingsApiService.saveMobileAppAccess(settingsId, accessUpdate);
	};

	createAsDashboard = (settingsModel: MobileAppSettingsListModel): ng.IPromise<void> => {
		return this.mobileAppSettingsApiService.getSettings(settingsModel.id).then((settings: MobileAppSettings) => {
			if (isEmpty(settings.contentProviderId) || isEmpty(settings.accountId) || isEmpty(settings.projectId)) {
				this.cbDialogService.warning(
					this.locale.getString('common.warning'),
					this.locale.getString('mobile.errorCreateAsDashboard'));
				return this.$q.resolve();
			}
			const dashboardName = this.locale.getString('mobile.dashboardNamePattern', { name: settings.name });
			const uniqueDashboardName = this.dashboardService.getUniqueDashboardName(dashboardName);

			let dashboard: Dashboard = {
				name: uniqueDashboardName,
				appliedFiltersArray: [],
				properties: {
					cbContentProvider: settings.contentProviderId,
					cbAccount: settings.accountId,
					project: settings.projectId,
					optimization: settings.optimizationEnabled
				},
			} as Dashboard;

			if (settings.hierarchyId) {
				dashboard.appliedFiltersArray.push({
					isOrgHierarchy: true
				});

				dashboard.properties.allowPersonalization = true;
				dashboard.properties.hierarchyId = settings.hierarchyId;
			}

			let modelsIds: number[] = this.mobileApplicationHelperService.getGroupingsModelsIds(settings);
			if (_.isEmpty(modelsIds)) {
				return this.getWidgetsAndCreatePredefinedDashboard(settings, uniqueDashboardName, dashboard);
			} else {
				let modelsPromise = PromiseUtils.old(this.mobileApplicationHelperService.requestModels(modelsIds, settings));
				return modelsPromise.then((models: ModelTree[]) => {
					return this.getWidgetsAndCreatePredefinedDashboard(settings, uniqueDashboardName, dashboard, models);
				});
			}
		});
	};

	private getWidgetsAndCreatePredefinedDashboard(
		settings: MobileAppSettings, uniqueDashboardName: string, dashboard: Dashboard, models?: ModelTree[]
	): ng.IPromise<void> {
		const mobileDashboardWidgets = this.mobileApplicationWidgetBuilderService.getWidgetsFromMobileSettings(settings, models);
		return this.dashboardService.createPredefinedDashboard(
			mobileDashboardWidgets, uniqueDashboardName, dashboard, { type: DashboardCreationOrigin.MOBILE, name: settings.name }
		).then(() => {
			this.globalNotificationService
				.addSuccessNotification(this.locale.getString('mobile.notificationDashboardCreated', { name: uniqueDashboardName }));
		});
	}

	isOwner = (settings: MobileAppSettingsListModel): boolean => {
		return settings.accessLevel === AssetPermission.OWN;
	};

	canEdit = (settings: MobileAppSettingsListModel): boolean => {
		return settings.accessLevel === AssetPermission.OWN || settings.accessLevel === AssetPermission.EDIT;
	};

	canShare = (settings: MobileAppSettingsListModel): boolean => {
		return settings.accessLevel === AssetPermission.OWN
			|| (settings.accessLevel === AssetPermission.EDIT && settings.allowEditorsToShare);
	};

}

app.service('mobileSettingsActionService', MobileSettingsActionService);
