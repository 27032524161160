import * as _ from 'underscore';
import { Context } from '@cxstudio/auth/context';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { LoginFlow } from '@cxstudio/auth/login-flow';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { CurrentMasterAccount } from '@cxstudio/auth/entities/current-master-account';
import { SessionFlow } from './session-flow';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { DefaultBrandingColors } from '@cxstudio/master-accounts/default-branding-colors.enum';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';
import { MasterAccountIdentity } from '@app/modules/system-administration/master-account/entities/master-account-identity';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { ApplicationPermissionAction } from '@app/modules/user-administration/permissions/application-permission-action';

export class Security {
	loggedUser: Context;

	externalOAuthSession: boolean;
	sessionFlow: SessionFlow;

	loginFlow: LoginFlow;
	redirectToAnalyzeRequested: any;
	redirectionInProgress: boolean;
	private pwdPattern: RegExp;
	private passCode: string;

	constructor(
		private $rootScope: ng.IRootScopeService,
		private $window: ng.IWindowService,
		private $location: ng.ILocationService,
		private $log: ng.ILogService,
		private $injector,
		private sessionPreferences: SessionPreferencesService,
	) {
		this.loggedUser = null;
		this.sessionFlow = null;
		this.loginFlow = null;
		this.redirectToAnalyzeRequested = null;

		//OWASP complex password regex
		// eslint-disable-next-line max-len
		this.pwdPattern = new RegExp(/^(?:(?=.*\d)(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[^A-Za-z0-9])(?=.*[a-z])|(?=.*[^A-Za-z0-9])(?=.*[A-Z])(?=.*[a-z])|(?=.*\d)(?=.*[A-Z])(?=.*[^A-Za-z0-9]))(?!.*(.)\1{2,})[A-Za-z0-9!~<>,;:_=?*+#.\"&§%°()\|\[\]\-\$\^\@\/]+$/);
	}

	getEmail = (): string => {
		return this.loggedUser && this.loggedUser.user ? this.loggedUser.user.userEmail : '';
	};

	/**
	 * Checks an email against the current user's email to see if they are case-insensitive match
	 */
	isCurrentUser = (email: string): boolean => {
		return email && this.getEmail().toLowerCase().trim() === email.toLowerCase().trim();
	};

	isLoggedIn = (): boolean => {
		return !(!this.loggedUser); // convert to boolean
	};

	setContext = (user: Context): void => {
		this.loggedUser = user;

		if (this.loggedUser && this.loggedUser.sessionFlow) {
			this.sessionFlow = this.loggedUser.sessionFlow;
		}

		this.$rootScope.$broadcast('user:updated', user);
	};

	getContext = (): Context => {
		return this.loggedUser;
	};

	getUser = (): User => {
		return (this.loggedUser && this.loggedUser.user)
			? this.loggedUser.user
			: null;
	};

	has = (permission: MasterAccountPermissionAction | ApplicationPermissionAction | string): boolean => {
		if (!this.loggedUser)
			return;
		return this.loggedUser.permissions && this.loggedUser.permissions.indexOf(permission) !== -1;
	};

	getMasterAccounts = (): MasterAccountIdentity[] => {
		return this.loggedUser ? this.loggedUser.masterAccounts : [];
	};

	hasMasterAccountAccess = (masterAccountId: number): boolean => {
		if (!this.loggedUser)
			return false;

		return !!(_.findWhere(this.loggedUser.masterAccounts, { accountId: masterAccountId }));
	};

	getMasterAccountId = (): number => {
		return (this.loggedUser && this.loggedUser.masterAccountId)
			? this.loggedUser.masterAccountId
			: -1;
	};

	getDefaultMasterAccountId = (): number => {
		return (this.loggedUser && this.loggedUser.user && this.loggedUser.user.defaultMasterAccountId)
			? this.loggedUser.user.defaultMasterAccountId
			: -1;
	};

	getLicenseType = (): string => {
		return (this.loggedUser && this.loggedUser.licenseType)
			? this.loggedUser.licenseType
			: null;
	};

	getLicenseTypeId = (): number => {
		return this.loggedUser && this.loggedUser.licenseTypeId;
	};

	setToken = (token): void => {
		if (token !== undefined) {
			this.$window.sessionStorage.token = token;
		} else {
			delete this.$window.sessionStorage.token;
		}
	};

	setEngageDashboardAccessToken = (token): void => {
		if (token !== undefined) {
			this.$window.sessionStorage.engageDashboardAccessToken = token;
		} else {
			delete this.$window.sessionStorage.engageDashboardAccessToken;
		}
	};

	setAccessToken = (accessToken): void => {
		if (accessToken !== undefined) {
			this.$window.sessionStorage.accessToken = accessToken;
		} else {
			delete this.$window.sessionStorage.accessToken;
		}
	};

	getToken = (): string => {
		return this.$window.sessionStorage.token;
	};

	getAccessToken = (): string => {
		return this.$window.sessionStorage.accessToken;
	};

	getEngageDashboardAccessToken = (): string => {
		return this.$window.sessionStorage.engageDashboardAccessToken;
	};

	setPassCode(code: string): void {
		this.passCode = code;
	}

	getPassCode(): string {
		return this.passCode;
	}

	hasPassCode(): boolean {
		return !!this.getPassCode();
	}

	isAuthenticated = (): boolean => !!(this.getToken() || this.getAccessToken());

	getSessionFlow = (): SessionFlow => {
		return this.sessionFlow || SessionFlow.REGULAR;
	};

	isUserGroupOwner = () => {
		return this.loggedUser !== null
			? this.loggedUser.isGroupOwner
			: undefined;
	};

	getPwdPattern = (): RegExp => {
		return this.pwdPattern;
	};

	getLastLoginDate = () => {
		return this.loggedUser && this.loggedUser.lastLoginDate;
	};

	setExternalOAuthSession = (externalOAuthSession): void => {
		this.externalOAuthSession = externalOAuthSession;
	};

	isExternalOAuthSession = (): boolean => {
		return this.externalOAuthSession;
	};

	isRedirectToAnalyzeRequested = (): boolean => {
		return this.redirectToAnalyzeRequested;
	};

	setRedirectToAnalyzeRequested = (redirectToAnalyzeRequested): void => {
		this.redirectToAnalyzeRequested = redirectToAnalyzeRequested;
	};

	setLoginFlow = (loginFlow: LoginFlow): void => {
		this.$log.log('Login flow is: ' + loginFlow);
		this.loginFlow = loginFlow;
	};

	getLoginFlow = (): LoginFlow => {
		return this.loginFlow;
	};

	cleanSession = (): void => {
		this.setContext(null);
		this.setToken(undefined);
		this.setAccessToken(undefined);
		this.setLoginFlow(null);
		this.sessionPreferences.clearSession();
	};

	setSamlForMAEnabled = (samlEnabled: boolean): void => {
		this.loggedUser.isSamlEnabled = samlEnabled;
	};

	isSamlForMAEnabled = (): boolean => {
		return this.loggedUser && this.loggedUser.isSamlEnabled;
	};

	isForcedExternalAuthentication = (): boolean => {
		return this.loggedUser && this.loggedUser.isForcedExternalAuthentication;
	};

	isForcedExternalAuthenticationForUser = (): boolean => {
		return this.loggedUser && this.loggedUser.isForcedExternalAuthenticationForUser;
	};

	isAuthByUniqueId = (): boolean => {
		return this.loggedUser && this.loggedUser.isAuthByUniqueId;
	};

	isQualtricsIntegrationEnabled = (): boolean => {
		return this.loggedUser?.qualtricsSettings?.integrationEnabled;
	};

	isTicketingEnabled = (): boolean => {
		return this.loggedUser && this.loggedUser.qualtricsSettings.integrationEnabled
			&& this.loggedUser.qualtricsSettings.ticketingEnabled;
	};

	isUserSyncingEnabled = (): boolean => {
		return this.loggedUser?.qualtricsSettings?.userSyncingEnabled;
	};

	isEnforceXmAuthenticationEnabled = (): boolean => {
		return this.loggedUser?.qualtricsSettings?.enforceXmAuthenticationEnabled;
	};

	isQualtricsApiIntegrationEnabled = (): boolean => {
		return this.loggedUser?.qualtricsSettings?.apiIntegrationEnabled;
	};

	getQualtricsAuthLandingPage = (): string => {
		return this.loggedUser?.qualtricsSettings?.authLandingPage;
	};

	isOAuthAuthentication = (): boolean => {
		return !!this.getAccessToken();
	};

	getDomain = (): string => {
		return !_.isUndefined(CONFIG.sso)
			? CONFIG.sso['organization.domain']
			: '';
	};

	isInternalEmail = (address): boolean => {
		return /@clarabridge\.com/i.test(address) ||
				/@epam\.com/i.test(address) ||
				/@marketmetrix\.com/i.test(address) ||
				/@engagor\.com/i.test(address) ||
				/@qualtrics\.com/i.test(address);
	};

	isQualtricsEmployee = (): boolean => {
		return this.loggedUser?.user?.userEmail && this.isInternalEmail(this.loggedUser.user.userEmail);
	};

	isAdminOrgUser = (): boolean => {
		return this.loggedUser.isAdminOrg;
	};

	isAdminOrgAccount = (): boolean => {
		return this.getCurrentMasterAccount().adminOrg;
	};

	isSysAdmin(): boolean {
		return this.has('system_administration');
	}

	isLiteAdmin = (): boolean => {
		return this.has('lite_admin');
	};

	isCustomerAdmin = (): boolean => {
		return this.has('customer_admin');
	};

	isAnyAdmin(): boolean {
		return this.isSysAdmin() || this.isLiteAdmin();
	}

	isAccountOwner = (): boolean => {
		return this.has('account_owner');
	};

	hasTags = (requiredTags): boolean => {
		return !_.difference(requiredTags, this.loggedUser.user.tags).length;
	};

	applyAccountTagRestrictions = (masterAccount): void => {
		if (!this.hasTags(masterAccount.tags))
			masterAccount.restricted = true;
	};

	restrictPage = (rulesFn?, failPath?: string): boolean => {
		failPath = failPath || '/dashboards';

		if (!rulesFn()) {
			this.$location.path(failPath);
			this.$location.search({});
			return true;
		}

		return false;
	};

	preventMobileAccess = (failPath?): void => {
		failPath = failPath || '/dashboards';
		this.restrictPage(() => {
			return !this.$rootScope.isMobile;
		});
	};

	getFavoriteProperties = (): FavoriteProperties => {
		if (this.loggedUser && this.loggedUser.properties) {
			return angular.copy(this.loggedUser.properties);
		}
		return null;
	};

	getCurrentMasterAccount = (): CurrentMasterAccount => {
		return this.loggedUser && this.loggedUser.currentMasterAccount || {} as CurrentMasterAccount;
	};

	// get the saved MA colors, even if branding is disabled
	getSavedMasterAccountColors = (): any => this.getCurrentMasterAccount().brandingColors;

	// get the colors to apply -- customized if branding is enabled, or defaults if not
	getActiveMasterAccountColors = (): any => {
		return this.getCurrentMasterAccount().customBrandingEnabled ?
			this.getCurrentMasterAccount().brandingColors :
			DefaultBrandingColors;
	};

	getHiddenObject = (type: HiddenItemType): any => {
		return this.loggedUser && this.loggedUser['hidden' + type.capitalize()];
	};

	isVoiceEnabled = (): boolean => {
		return this.loggedUser && this.loggedUser.currentMasterAccount
			&& this.loggedUser.currentMasterAccount.vociEnabled;
	};

	isImpersonateMode = (): boolean => {
		return isTrue(this.loggedUser && this.loggedUser.impersonateMode);
	};

	isQualtricsWidgetEmbeddingEnabled = (): boolean => {
		return this.getCurrentMasterAccount().qualtricsWidgetEmbeddingEnabled;
	};

	isQualtricsDashboardEmbeddingEnabled = (): boolean => {
		return this.getCurrentMasterAccount().qualtricsDashboardEmbeddingEnabled;
	};

	isEngagorIntegrationEnabled = (): boolean => {
		return this.getCurrentMasterAccount().engagorEnabled;
	};

	requiresPasswordControlsForMA = (): boolean => {
		return !this.isForcedExternalAuthentication();
	};
	requiresPasswordControlsForUser = (): boolean => {
		return !(this.isForcedExternalAuthenticationForUser());
	};

	updateLocation = (location: string): void => {
		this.$window.location.href = location;
		this.$window.location.reload(); // ensure that page is reloaded even if url hasn't changed
	};

	setRedirectionInProgress = (): void => {
		this.redirectionInProgress = true;
	};

	isRedirectionInProgress = (): boolean => {
		return this.redirectionInProgress;
	};

	getTranslationLanguage = (): string => {
		let context = this.loggedUser;
		let language = context.translateLanguage;
		if (!language)
			language = context.locale && context.locale.substring(0, 2);
		if (!language)
			language = 'en';
		return language;
	};

	setXmAccountId(xmAccountId: string): void {
		if (this.loggedUser?.user) {
			this.loggedUser.user.xmAccountId = xmAccountId;
		}
	}

	isDataIsolationEnabled(): boolean {
		return this.loggedUser.dataIsolationEnabled;
	}

	/**
	 * Will return XM brand Id when available, falling back to discover MA ID, prefxied to prevent collisions
	 */
	getUniquePreferredAccountId(): string {
		// TODO: figure out how to get brand ID here too
		return `QUIP_${this.getMasterAccountId()}`;
	}

	/**
	 * Returns xmAccountId if it exists, or else discover ID with a prefix on it to prevent collisions with XM ID
	 */
	getUniquePreferredUserId(): string {
		if (this.loggedUser?.user) {
			return this.loggedUser.user.xmAccountId ?? `QUIP_${this.loggedUser.user.userId}`;
		}
	}

	// need to use $injector to avoid circular reference, since these services inject security service
	logout = (): void => {
		let logoutCall = this.$injector.get('userSessionApiService').logout();

		logoutCall.then((response) => {
			let location = '';
			if (!_.isUndefined(response.data.samlLogoutUrl)) {
				location = response.data.samlLogoutUrl;
			} else if (response.data.returnTo) {
				location = response.data.returnTo;
			}

			this.$injector.get('singleLoginService').saveLogoutEvent();

			if (location === '') {
				this.$injector.get('logger').logInternalLogout();
			} else {
				this.$injector.get('logger').logExternalLogout(location);
			}

			this.cleanSession();
			this.updateLocation(location); // to clean all variables
		}, () => {
			this.$injector.get('logger').logInternalLogout();
			this.cleanSession();
			this.updateLocation(''); // to clean all variables
		});
	};
}

app.service('security', Security);
