import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ScheduleManagementComponent } from '@app/modules/schedules/schedule-management.component';
import { CxLocaleService } from '@app/core';

@Injectable({
	providedIn: 'root'
})
export default class ScheduleGridDefinition implements IGridDefinition<ScheduleManagementComponent> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter
	) {}

	init = (): Promise<IGridColumn[]> => {
		const columns: IGridColumn[] = [{
			id: 'hamburger',
			sortable: false,
			minWidth: 32,
			width: 32,
			headerCssClass: 'header-hamburger text-center',
			name: '<i class="q-icon-layer"></i>',
			cssClass: 'cell-hamburger text-center no-border-if-folder action-hover-text',
			formatter: this.gridFormatterService.HamburgerFormatter,
			resizable: false
		}, {
			id: 'spacer',
			sortable: false,
			minWidth: 16,
			width: 16,
			resizable: false,
			cssClass: 'no-border-if-folder',
			attributes: {
				'aria-hidden': true
			}
		}, {
			id: 'dashboardName',
			name: this.locale.getString('common.name'),
			field: 'dashboardName',
			sortable: true,
			minWidth: 200,
			width: 400,
			searchable: true,
			cssClass: 'cell-attribute',
			formatter: (_row: number, _cell: number, value, _columnDef: IGridColumn, dataContext): string | any => {
				if (dataContext.dashboardId === -1) {
					return this.locale.getString('dashboard.deletedDashboard', {id: value});
				}

				return value;
			},
			isObjectNameColumn: true
		}, {
			id: 'lastModifiedDate',
			name: this.locale.getString('dashboard.modifiedDate'),
			field: 'lastModifiedDate',
			sortable: true,
			minWidth: 100,
			width: 300,
			searchable: false,
			cssClass: 'cell-date',
			formatter: this.gridFormatterService.DateWithTZFormatter
		}, {
			id: 'previousRun',
			name: this.locale.getString('schedule.lastRun'),
			field: 'previousRun',
			sortable: true,
			minWidth: 100,
			width: 250,
			searchable: false,
			cssClass: 'cell-date',
			formatter: this.gridFormatterService.DateWithTZFormatter
		}, {
			id: 'nextRun',
			name: this.locale.getString('schedule.nextRun'),
			field: 'nextRun',
			sortable: true,
			minWidth: 100,
			width: 300,
			searchable: false,
			cssClass: 'cell-date',
			formatter: this.gridFormatterService.DateWithTZFormatter
		}, {
			id: 'ownerName',
			name: this.locale.getString('common.owner'),
			field: 'ownerName',
			sortable: true,
			minWidth: 200,
			width: 300,
			searchable: false,
			cssClass: 'cell-attribute',
			formatter: this.gridFormatterService.getAuthorFormatter()
		}];

		return Promise.resolve(columns);
	};
}

app.service('scheduleGridDefinition', downgradeInjectable(ScheduleGridDefinition));
