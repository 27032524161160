import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { Security } from '@cxstudio/auth/security-service';
import BulkUpdateLabelsEntity from '@cxstudio/bulk/bulk-update-labels-entity';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { NameService } from '@cxstudio/common/name-service';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { DashboardScheduleService } from '@app/modules/dashboard/services/scheduling/dashboard-schedule.service';
import { DashboardApiService } from '@cxstudio/services/data-services/dashboard-api.service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { Book } from '@cxstudio/dashboards/entity/book';
import { DashboardsGridScope } from '@app/modules/dashboard-list/page/dashboard-list-page.component';
import { IFolderService } from '@cxstudio/folders/folder-service.factory';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { AssetTemplateApiService } from '@app/modules/unified-templates/common-templates/asset-template-api.service';
import { PageTitleUtil } from '@app/core/page-title-util.class';

export interface IDashboardActionsService {
	viewDashboard(dashboard: any): void;
	moveDashboard(dashboard: any, folderTo: any): void;
	editDashboard(dashboard: any): void;
	editDashboardProperties(dashboard: any): ng.IPromise<any>;
	renameDashboard(dashboard: any): ng.IPromise<any>;
	dashboardExportPdfDownload(dashboard: any): void;
	dashboardExportPdfSend(dashboard: any): void;
	saveDashboardAsTemplate(dashboard: any): void;
	saveDashboardAsTemplateBeta(dashboard: Dashboard): void;
	shareDashboard(dashboard: any): ng.IPromise<any>;
	copyDashboard(dashboard: any): void;
	showRatingDialog(event: any, dashboard: any): void;
	openScheduleDialog(dashboard: any): ng.IPromise<any>;
	removeDashboard(dashboard: any): ng.IPromise<any>;
	toggleHide(dashboard: any): void;
	createDashboard(folder: any): void;
	createFolder(folder: any): void;
	renameFolder(folder: any): ng.IPromise<void>;
	moveToFolder(folder: any, folderTo: any): void;
	removeFolder(folder: any): ng.IPromise<void>;
	enableTabsEditor(dashboard?: any, folder?: IFolderItem): void;
	renameBook(book: any): ng.IPromise<any>;
	showDashboardComponents(dashboard: any): ng.IPromise<any>;
	showBookComponents(book: any): ng.IPromise<any>;
	bulkUpdateLabels(): NgbModalRef;
	updateLabelsRequest(updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any>;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('DashboardActionsService', function(
	$location: ng.ILocationService,
	locale: ILocale,
	nameService: NameService,
	dashboardService: DashboardService,
	dashboardExportService,
	dashboardTemplateService,
	dashboardScheduleService: DashboardScheduleService,
	securityApiService,
	security: Security,
	FolderService,
	navigationService: NavigationService,
	dashboardApiService: DashboardApiService,
	dashboardListService: DashboardListService,
	downgradeDialogService: DowngradeDialogService,
	assetTemplateApi: AssetTemplateApiService,
) {
	return class DashboardActionsService implements IDashboardActionsService {
		private scope: DashboardsGridScope;
		private folderSvc: IFolderService;

		constructor(scope: DashboardsGridScope) {
			this.scope = scope;
			this.folderSvc = new FolderService(FolderTypes.DASHBOARD);
		}

		viewDashboard(dashboard: any): void {
			// cancel tooltip on clickthrough
			clearTimeout(this.scope.tooltipTimer);
			navigationService.changeDashboard(dashboard, false);
		}

		moveDashboard(dashboard: any, folderTo: any): void {
			let parent = dashboard.parent; // update icon, if needed
			dashboardService.moveDashboard(dashboard, folderTo).then((dashboardInFolder: any) => {
				let refreshItems = [dashboardInFolder, dashboardInFolder.parent, parent];

				// refresh folders all the way up from old location and the new location
				refreshItems = refreshItems.concat(this.traverseFolderChain(parent));
				refreshItems = refreshItems.concat(this.traverseFolderChain(dashboardInFolder));
				refreshItems = refreshItems.concat(this.scope.refreshFolderHighlighting([dashboardInFolder.parent, parent]));

				dashboardListService.updateDashboardsOld(refreshItems);
			});
		}

		private traverseFolderChain = (item): any[] => {
			let levels = [];
			let currentLevel = item ? item.parent : undefined;
			while (currentLevel) {
				levels.push(currentLevel);
				currentLevel = currentLevel.parent;
			}
			return levels;
		};

		editDashboard(dashboard: any): void {
			navigationService.changeDashboard(dashboard, true);
		}

		editDashboardProperties(dashboard: Dashboard): ng.IPromise<any> {
			let oldParent = (dashboard as any).parent;
			return dashboardService.renameDashboard(dashboard, true).then((data) => {


				if (data && oldParent !== data.parent) {
					let folderTo = data.parent || {id: 0, name: '.'};
					data.parent = oldParent;
					// dashboard being moved
					this.moveDashboard(data, folderTo);

				} else {
					dashboardListService.updateDashboardsOld([dashboard]);
				}
			});
		}

		bulkUpdateLabels = (): NgbModalRef => {
			return downgradeDialogService.openBulkUpdateLabelsModal({
				itemsName: locale.getString('dashboard.dashboardsAndBooks')
			});
		};

		updateLabelsRequest = (updateLabelsEntity: BulkUpdateLabelsEntity): ng.IPromise<any> => {
			return dashboardApiService.updateLabels(updateLabelsEntity);
		};

		renameDashboard(dashboard: Dashboard): ng.IPromise<any> {
			return dashboardService.renameDashboard(dashboard).then(() => {
				dashboardListService.updateDashboardsOld([dashboard]);
			});
		}

		dashboardExportPdfDownload(dashboard: any): void {
			dashboardExportService.exportPDFDownload(dashboard);
		}

		dashboardExportPdfSend(dashboard: any): void {
			dashboardExportService.exportPDF(dashboard);
		}

		saveDashboardAsTemplate(dashboard: any): void {
			dashboardTemplateService.saveDashboardAsTemplate(dashboard);
		}

		saveDashboardAsTemplateBeta(dashboard: Dashboard): void {
			downgradeDialogService.openDashboardTemplateCreationModal(dashboard).then(result => {
				assetTemplateApi.createTemplate(TemplateAssetType.DASHBOARD, dashboard.id, result);
			}, _.noop);
		}

		shareDashboard(dashboard: Dashboard): ng.IPromise<any> {
			return dashboardService.shareDashboard(dashboard).then((changedDashboards) => {
				dashboardListService.updateDashboardsOld(changedDashboards);
			});
		}

		copyDashboard(dashboard: any): void {
			dashboardService.copyDashboard(dashboard).then((newDashboard) => {
				if (newDashboard.type === DashboardType.DASHBOARD) {
					navigationService.changeDashboard(newDashboard, true);
				}
			});
		}

		showRatingDialog(event: any, dashboard: any): void {
			this.scope.showRatingPopup(event, dashboard);
		}

		openScheduleDialog(dashboard: any): ng.IPromise<any> {
			return dashboardScheduleService.openScheduleDialog(dashboard).then((data) => {
				if (data) {
					dashboard.scheduleCount = data.filter((schedule: any) => {
						return schedule.settings.active;
					}).length;

					dashboardListService.updateDashboardsOld([dashboard]);
				}
			}, _.noop) as unknown as ng.IPromise<any>;
		}

		removeDashboard(dashboard: any): ng.IPromise<any> {
			return dashboardService.removeDashboard(dashboard).then(() => {
				this.scope.onMouseLeave(null, null, true);
				this.scope.clearSelections();
			});
		}

		toggleHide(dashboard: any): void {
			let key = dashboard.id.toString();
			dashboard.hide = !dashboard.hide;
			securityApiService.hideObjectForUser(HiddenItemType.DASHBOARDS, dashboard.hide, key, dashboard.name);

			if (dashboard.hide) {
				//if it is being hidden, add tag to grey it out
				TaggingHelper.tag(dashboard, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenDashboards[key] = true;
			} else {
				TaggingHelper.untag(dashboard, TaggingHelper.tags.HIDDEN);
				security.loggedUser.hiddenDashboards[key] = false;

			}

			this.scope.clearSelections();
		}

		createDashboard(folder: any): void {
			dashboardTemplateService.templatesEnabled().then((enabled) => {
				if (enabled) {
					if (folder) {
						$location.path('/templates').search({selectedFolderId: folder.id});
					} else {
						$location.path('/templates');
					}
					return;
				}

				this.scope.loading.create = true;

				dashboardService.createDashboard(undefined, undefined, folder).then((dashboard) => {
					this.scope.loading.create = false;
					navigationService.changeDashboard(dashboard, true);
				}, () => {
					this.scope.loading.create = false;
				});
			});
		}

		createFolder(folder: any): void { // folder is null when clicking button "Add folder"
			this.folderSvc.createFolder(folder, dashboardListService.getCurrentDashboardsList()).then((created) => {
				dashboardListService.updateDashboardsOld([folder, created]);
			});
		}

		renameFolder(folder: IFolderItem): ng.IPromise<void> {
			return this.folderSvc.renameFolder(folder, dashboardListService.getCurrentDashboardsList()).then(() => {
				folder.displayName = folder.name;
				dashboardListService.updateDashboardsOld([folder]);
			});
		}

		moveToFolder(folder: any, folderTo: any): void {
			let parent = folder.parent;
			this.folderSvc.moveToFolder(folder, folderTo, dashboardListService.getCurrentDashboardsList()).then(() => {
				if (!folderTo.id) {
					folderTo = null;
				}
				dashboardListService.updateDashboardsOld([folder, folderTo, parent].concat(folder.children));
				this.scope.loading.createFolder = false;
			});
		}

		removeFolder(folder: any): ng.IPromise<void> {
			return this.folderSvc.removeFolder(folder, dashboardListService.getCurrentDashboardsList())
				.then((children) => {
					dashboardListService.updateDashboardsOld(children);
				});
		}

		enableTabsEditor(dashboard: any, folder?: IFolderItem): void {
			if (!dashboard) {
				let dashboardRootName = locale.getString('dashboard.tabsName');
				let dashboardName = nameService.uniqueName(dashboardRootName, dashboardListService.getCurrentDashboardsList(), 'name');
				dashboard = {
					name: dashboardName,
					type: 'dashboardSet',
					description: '',
					properties: {},
					parentId: folder ? folder.id : undefined,
					tabs: [],
					ownerName: security.getEmail()
				};

				dashboard.tabs.push({
					name: locale.getString('dashboard.tab', {index: 1}), active: true
				});
				this.scope.tabsEditor.needRedirectToView = true;
			} else {
				this.scope.tabsEditor.needRedirectToView = false;
			}
			PageTitleUtil.setTitle(locale.getString('pageTitle.object', { objectName: dashboard.name }));
			this.scope.tabsEditor.dashboard = angular.copy(dashboard);
			this.scope.tabsEditor.enabled = true;
		}

		renameBook(book: Book): ng.IPromise<any> {
			return dashboardService.renameBook(book).then(() => {
				dashboardListService.updateDashboardsOld([book]);
			});
		}

		showDashboardComponents(dashboard: any): ng.IPromise<any> {
			return dashboardService.showDashboardComponents(dashboard);
		}

		showBookComponents(book: any): ng.IPromise<any> {
			return dashboardService.showBookComponents(book);
		}
	};
});
