import * as _ from 'underscore';
import { Component, Input, ChangeDetectionStrategy, Inject, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { TransferApiService } from '@app/modules/user-administration/transfer/transfer-api.service';
import { TransferCandidate } from '@app/modules/user-administration/transfer/transfer-candidate';
import { BulkTransferData } from '@app/modules/user-administration/transfer/bulk-transfer-data';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';

export interface IBulkUserRemoveDialogParams {
	queryParams: any;
	selectedUserIds: number[];
}

@Component({
	selector: 'bulk-user-remove-dialog',
	templateUrl: './bulk-user-remove-dialog.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})

export class BulkUserRemoveDialogComponent implements OnInit {
	@Input() input: IBulkUserRemoveDialogParams;

	loading: Promise<void>;
	masterAccountOwners: any[];
	masterAccountCustomerAdmins: any[];

	manageEverything: boolean = false;
	powerCandidates: TransferCandidate[] = [];
	selectedPowerCandidateId: number;

	private deleteOption: TransferCandidate;

	selectedUserIds: number[];

	constructor(
		private modal: NgbActiveModal,
		private locale: CxLocaleService,
		@Inject('security') private readonly security: Security,
		@Inject('transferApiService') private readonly transferApiService: TransferApiService,
		private readonly userModificationService: UserModificationApiService,
		@Inject('masterAccountApiService') private readonly masterAccountApiService: MasterAccountApiService
	) {}

	ngOnInit(): void {
		this.deleteOption = {id: -1, email: this.locale.getString('common.delete').toUpperCase(), special: true};
		this.selectedUserIds = this.input.selectedUserIds;

		this.init();
	}

	init(): void {
		const getMasterAccountOwnersPromise = this.masterAccountApiService.getMasterAccountOwners() as unknown as Promise<any>;
		const getCustomerAdminsPromise = this.masterAccountApiService.getMasterAccountCustomerAdmins() as unknown as Promise<any>;

		this.loading = Promise.all([getMasterAccountOwnersPromise, getCustomerAdminsPromise]).then(
			(responces) => {
				this.masterAccountOwners = responces[0];
				this.masterAccountCustomerAdmins = responces[1].data;
				this.initOptions();
			},
			(errorResp: any) => {
				this.closeDialog();
			}
		);
	}

	private initOptions = (): void => {
		this.addUsersToPowerCandidates(this.masterAccountOwners);
		this.addUsersToPowerCandidates(this.masterAccountCustomerAdmins);

		// Add initiating user
		const currentUser = this.security.getUser();
		this.addUsersToPowerCandidates([currentUser]);
		this.selectedPowerCandidateId = currentUser.userId;

		// Add DELETE option
		this.powerCandidates.unshift(this.deleteOption);
	};

	private addUsersToPowerCandidates = (userList: any[]): void => {
		userList.forEach((userItem) => {
			const existingCandidate = _.findWhere(this.powerCandidates, { id: userItem.userId });
			const userInTheRemoveList = _.contains(this.selectedUserIds, userItem.userId);

			if (!existingCandidate && !userInTheRemoveList) {
				this.powerCandidates.push({
					id: userItem.userId,
					email: userItem.userEmail
				});
			}
		});
	};

	private makeTransfer(): Promise<void> {
		const transferData = new BulkTransferData();
		transferData.candidateId = this.selectedPowerCandidateId;
		transferData.userIds = this.selectedUserIds;
		transferData.transferWidgetsInUnownedDashboards = true;
		transferData.retainEditPermission = false;

		return this.transferApiService.makeBulkManageUsersTransfer(transferData) as unknown as Promise<void>;
	}

	private closeDialog(): void {
		this.modal.close();
	}

	cancel = () => {
		this.modal.dismiss('cancel');
	};

	save = () => {
		this.loading = this.makeTransfer().then(() => {
			const candidate = (
				this.selectedPowerCandidateId >= 0
				&& _.find(this.powerCandidates, { id: this.selectedPowerCandidateId })?.email
				|| null
			);

			return this.userModificationService.removeFromMA(this.selectedUserIds, this.input.queryParams, candidate)
				.then(() => {
					this.modal.close();
				}) as unknown as Promise<void>;
		});
	};
}
