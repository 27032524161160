import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
	selector: 'cx-wizard-step',
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './cx-wizard-step.component.html'
})
export class CxWizardStepComponent implements OnInit {

	@Input()
	private header: string;
	@Input() tagType: string;

	@Input() skipped: boolean;
	
	@Input() hasCustomNext: boolean;
	@Input() hasCustomBack: boolean;

	@Output() customNext = new EventEmitter<void>();
	@Output() customBack = new EventEmitter<void>();

	@Output() stateChange = new EventEmitter<void>();

	private loading: boolean;
	private last: boolean;
	private confirm: boolean;

	next?: () => void;
	back?: () => void;

	active = false;
	valid = true;

	constructor(
		private changeDetectorRef: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.initCustomButtonsHandling();
	}

	private initCustomButtonsHandling(): void {
		if (this.hasCustomNext) {
			this.next = () => this.customNext.emit();
		}

		if (this.hasCustomBack) {
			this.back = () => this.customBack.emit();
		}
	}

	setActive = (active: boolean): void => {
		this.active = active;
		this.detectChanges();
	};

	getHeader = (): string => {
		return this.header;
	};

	isValid = (): boolean => {
		return this.valid;
	};

	markAsConfirmationStep() {
		this.confirm = true;
	}

	isConfirmationStep(): boolean {
		return this.confirm;
	}

	isLoaded = (): boolean => {
		return !this.loading;
	};

	setLoaded = (loaded: boolean): void => {
		this.loading = !loaded;
		this.detectChanges();
		this.stateChange.emit();
	};

	setValid = (valid: boolean): void => {
		this.valid = valid;

		this.detectChanges();
		// detect changes on wizard component, so steps buttons states are reevaluated
		this.stateChange.emit();
	};

	isMarkedAsLast = (): boolean => {
		return this.last;
	};

	markAsLast = (last: boolean): void => {
		this.last = last;
	};

	private detectChanges = (): void => {
		this.changeDetectorRef.detectChanges();
	};

	setTag = (tagType: string): void => {
		this.tagType = tagType;
		this.detectChanges();
		this.stateChange.emit();
	};

}
