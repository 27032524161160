import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { ProjectId } from '@cxstudio/generic-types';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { FilterMatchModes, IFilterMatchMode } from './constants/filter-match-modes.service';
import { FilterRuleType, FilterRuleTypes } from './constants/filter-rule-type.value';

export class FilterCreatorService {

	matchOptions: {[key: string]: IFilterMatchMode[]} = {};

	nlpMatchOptions = [
		this.filterMatchModes.definitions.IS,
		this.filterMatchModes.definitions.IS_NOT
	];

	thresholdOptions = [
		this.filterMatchModes.definitions.BETWEEN,
		this.filterMatchModes.definitions.NOT_BETWEEN,
		this.filterMatchModes.definitions.GTE,
		this.filterMatchModes.definitions.LTE
	];

	dashboardTopicOptions = [
		this.filterMatchModes.definitions.IS,
		this.filterMatchModes.definitions.CATEGORIZED
	];

	textOptions = [
		this.filterMatchModes.definitions.IS
	];

	readonly NLP_TOKENS = ['_mtoken', '_lc', '_hashtag'];

	constructor(
		private filterMatchModes: FilterMatchModes,
		private filterRuleService: FilterRuleService,
	) {

		this.matchOptions[FilterRuleType.stringEquality] = [
			this.filterMatchModes.definitions.IS,
			this.filterMatchModes.definitions.IS_NOT,
			this.filterMatchModes.definitions.EXIST,
			this.filterMatchModes.definitions.NOT_EXIST
		];

		this.matchOptions[FilterRuleType.topicEquality] = [
			this.filterMatchModes.definitions.IS,
			this.filterMatchModes.definitions.IS_NOT,
			this.filterMatchModes.definitions.CATEGORIZED
		];

		this.matchOptions[FilterRuleType.numericRange] = [
			this.filterMatchModes.definitions.IS,
			this.filterMatchModes.definitions.IS_NOT,
			this.filterMatchModes.definitions.BETWEEN,
			this.filterMatchModes.definitions.NOT_BETWEEN,
			this.filterMatchModes.definitions.GTE,
			this.filterMatchModes.definitions.LTE,
			this.filterMatchModes.definitions.EXIST,
			this.filterMatchModes.definitions.NOT_EXIST
		];

		this.matchOptions[FilterRuleType.numericOpenRange] = [
			this.filterMatchModes.definitions.IS,
			this.filterMatchModes.definitions.IS_NOT,
			this.filterMatchModes.definitions.BETWEEN,
			this.filterMatchModes.definitions.NOT_BETWEEN,
			this.filterMatchModes.definitions.GTE,
			this.filterMatchModes.definitions.LTE,
			this.filterMatchModes.definitions.EXIST,
			this.filterMatchModes.definitions.NOT_EXIST
		];

		this.matchOptions[FilterRuleType.numericEquality] = [
			this.filterMatchModes.definitions.IS,
			this.filterMatchModes.definitions.IS_NOT,
			this.filterMatchModes.definitions.BETWEEN,
			this.filterMatchModes.definitions.NOT_BETWEEN,
			this.filterMatchModes.definitions.GTE,
			this.filterMatchModes.definitions.LTE,
			this.filterMatchModes.definitions.EXIST,
			this.filterMatchModes.definitions.NOT_EXIST
		];
	}

	// moving match options into this function creates an infinite digest loop.......so please don't do it :)
	getMatchModes = (rule, attributeName): IFilterMatchMode[] => {
		if (rule.type && this.isNlpAttribute(attributeName)) {
			return this.nlpMatchOptions;
		} else if (rule.type && rule.isThreshold) {
			return this.thresholdOptions;
		} else {
			return this.matchOptions[rule.type] || [];
		}
	};

	private isNlpAttribute = (attributeName): boolean => {
		return _.contains(this.NLP_TOKENS, attributeName);
	};

	getMatchModeOptions = (rule: IFilterRule, attributeName: string, isDashboard: boolean) => {
		if (!rule || !rule.type || rule.type === FilterRuleType.empty) return;
		if (isDashboard && rule.type === FilterRuleType.topicEquality) return this.dashboardTopicOptions;
		if (rule.type === FilterRuleType.text) return this.textOptions;
		return this.getMatchModes(rule, attributeName);
	};

	getAppliedMatchMode = (rule: IFilterRule) => {
		if (rule && rule.matchMode) {
			return this.filterMatchModes.valueOf(rule);
		}
		return rule;
	};

	onMatchModeSelection = (mode: IFilterMatchMode, priorRule: IFilterRule) => {
		if (this.filterMatchModes.isModelMatch(priorRule)) {
			priorRule.modelMatch = true;
		}
		let newRule = this.filterRuleService.generateNewRule(undefined, priorRule, mode);
		angular.copy(newRule, priorRule);
	};

	showMatchMode = (rule: IFilterRule): boolean => {
		return rule.type !== FilterRuleType.empty
			&& rule.type !== FilterRuleType.dateRange
			&& rule.type !== FilterRuleType.savedFilter
			&& rule.type !== FilterRuleType.predefined
			&& rule.type !== FilterRuleType.scorecardFilter
			&& rule.type !== FilterRuleType.esQueryRule
			&& !this.isNoInputText(rule);
	};

	private isNoInputText = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.isText(rule) && !FilterRuleTypes.isTextWithInput(rule);
	};

	showModelSelect = (rule: IFilterRule): boolean => {
		return FilterRuleTypes.isTopicRule(rule) && (this.filterMatchModes.valueOf(rule) !== this.filterMatchModes.definitions.CATEGORIZED);
	};

	showMultiselect = (rule: IFilterRule, selectedProjectId: ProjectId): boolean => {
		return rule && (rule.type === FilterRuleType.stringEquality
			|| rule.type === FilterRuleType.stringEqualityLC
			|| rule.type === FilterRuleType.numericEquality)
			&& !rule.existMatch;
	};

	isAdminProjectAttribute = (rule: IFilterRule, selectedProjectId: ProjectId): boolean => {
		return rule?.type === FilterRuleType.stringEquality
			&& InternalProjectTypes.isStudioAdminProject(selectedProjectId);
	};

	isExistMatch = (rule: IFilterRule): boolean => {
		return rule?.existMatch;
	};
}

app.service('filterCreatorService', FilterCreatorService);
