import { Inject, Injectable } from '@angular/core';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { ObjectType } from '../asset-management/entities/object-type';
import { UrlService } from '@cxstudio/common/url-service.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxDialogService } from '../dialog/cx-dialog.service';
import { EmbedModalComponent } from '../asset-management/embed-modal/embed-modal.component';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

@Injectable({
	providedIn: 'root'
})
export class EmbedDashboardService {

	constructor(
		private readonly cxDialogService: CxDialogService,
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject(Window) private window: Window
	) { }

	/**
	 * Open a modal to provide iframe code or url for embedding a dashboard
	 */
	openEmbedDashboardModal = (dashboard: Dashboard): void => {
		const input = {
			object: dashboard,
			objectType: ObjectType.DASHBOARD,
			embedCode: this.getDashboardEmbedCode(dashboard),
			embedLink: this.urlService.getDashboardEmbedLink(dashboard),
		};

		this.cxDialogService.openDialog(EmbedModalComponent, input);
	};

	getDashboardEmbedCode = (dashboard: Dashboard): string => {
		const link = this.urlService.getDashboardEmbedLink(dashboard);

		return `
			<iframe
				width="${this.window.innerWidth}"
				height="${this.window.innerHeight}"
				src="${link}"
				title="${HtmlUtils.escapeHtml(dashboard.name)}">
			</iframe>`;
	};
}

app.service('embedDashboard', downgradeInjectable(EmbedDashboardService));
