import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';
import { Grouping } from './widget-description-utils';

export class PieDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let selections = this.visual.attributeSelections;
		let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
		let grouping = new Grouping(selectedAttribute?.displayName,
			this.utils.getGroupingType(selectedAttribute?.type));
		let chartName = this.locale.getString('widgetDescription.pie');
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('piePalette', this.visual.color);
		let description = this.locale.getString('widgetDescription.pieDescription', {
			chartName,
			calculation: selections?.size?.displayName,
			grouping,
			total,
			palette
		});
		let topItems = this.utils.getTopItems(data, selectedAttribute, selections?.size?.name);
		if (topItems.length >= 2) {
			let topSlices = this.locale.getString('widgetDescription.topSlices', {
				slice1: topItems[0],
				slice2: topItems[1]
			});
			description = this.utils.joinMessageParts([description, topSlices]);
		}
		return description;
	};
	
}
