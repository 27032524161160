import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { BulkLicensingUpdateDialogOutput } from '@app/modules/user-bulk/bulk-licensing/bulk-licensing.component';
import { LicenseService } from '@app/modules/user-administration/license.service';
import { PromiseUtils } from '@app/util/promise-utils';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { UserBulkActionMode } from '@cxstudio/user-administration/users/entities/user-bulk-action-mode';
import { UserSelectionMethod } from '@cxstudio/user-administration/users/entities/user-selection-method.enum';
import { UserRemoveModes } from '@cxstudio/user-administration/users/removal/user-remove-modes';
import { UsersComponent } from '@cxstudio/user-administration/users/users.component';
import * as _ from 'underscore';
import { UserRemoveModalService } from '@cxstudio/user-administration/users/removal/user-remove-modal.service';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';
import { UserModificationApiService } from '@app/modules/user-bulk/user-modification/user-modification-api.service';
import { BulkCustomFieldEntry } from '@app/modules/user-bulk/bulk-custom-field/bulk-custom-field.component';

export interface IUserActionsService {
	updateCustomField(users: User[]): unknown;
	updatePermissions(users: User[]): void;
	openRemoveDialog(users: User[], removeMode: UserRemoveModes): void;
	updateGroupMembership(users: User[]): void;
	updateDataAccess(users: User[]): void;
	resetPassword(users: User[]): void;
	updateLicenseTypes(users: User[]): void;
}

// eslint-disable-next-line prefer-arrow-callback
app.service('UserActionsService', function(
	cbDialogService: CBDialogService,
	locale: ILocale,
	$uibModal: ng.ui.bootstrap.IModalService,
	usersGroupsApiService: UsersGroupsApiService,
	userModificationService: UserModificationApiService,
	userRemoveModalService: UserRemoveModalService,
	downgradeDialogService: DowngradeDialogService,
	$q: ng.IQService,
	bulkLicensingUpdateDialog,
	licenseService: LicenseService,
) {
	return class UserActionsService implements IUserActionsService {
		private scope: UsersComponent;
		constructor(scope: UsersComponent) {
			this.scope = scope;
		}

		updatePermissions(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);

			this.scope.promises.loadingUsers = PromiseUtils.old(downgradeDialogService.openBulkPermissionsDialog(
				locale.getString('permission.bulkUserUpdatePermissionsBody'))).then(
					result => userModificationService.callBulkPermissionsUpdateAPI(selectedUserIds, result,
						this.buildUserSelectionQueryParameters()),
					_.noop
				).then(() => this.finishAction());
		}

		finishAction = (): void => {
			if (this.scope.bulkActionMode !== UserBulkActionMode.ASYNC) {
				this.scope.reloadPagedUserList();
			}
			this.scope.selectedUsers.removeAll();
		};

		finishActionNotify = (): void => {
			if (this.scope.bulkActionMode === UserBulkActionMode.ASYNC) {
				let userCount = this.scope.selection.method === UserSelectionMethod.ALL_MATCHING_FILTERS
					? this.scope.selection.totalItems
					: this.scope.selectedUsers.length;
				cbDialogService.notify(
					locale.getString('administration.bulkUserUpdatesTitle'),
					locale.getString('administration.bulkUserUpdatesMessage', { count: userCount }));
			} else {
				this.scope.reloadPagedUserList();
			}
			this.scope.selectedUsers.removeAll();
		};

		buildUserSelectionQueryParameters = (): any => {
			let queryParameters = {
				method: this.scope.selection.method,
				async: this.scope.bulkActionMode === UserBulkActionMode.ASYNC
					|| UserSelectionMethod.ALL_MATCHING_FILTERS === this.scope.selection.method
			};
			queryParameters = _.extend(queryParameters, this.scope.createQueryParameters());
			return queryParameters;
		};

		openRemoveDialog(users: User[], removeMode: UserRemoveModes): void {
			let modalPromise: ng.IPromise<any>;

			if (!this.isBulk()) {
				// only single user selected, so call different modal
				modalPromise = userRemoveModalService.showUserRemoveModal(users[0], removeMode, false).result;
			} else {
				//for 1+ user removal, use async mode
				this.scope.bulkActionMode = UserBulkActionMode.ASYNC;

				modalPromise = downgradeDialogService.openBulkUserRemoveDialog({
					queryParams: this.scope.buildUserSelectionQueryParameters(),
					selectedUserIds: this.getSelectedUserIds(users),
				}).result as any;
			}
			modalPromise.then(this.finishActionNotify, () => {});
		}

		updateGroupMembership(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);

			const membersPromise = usersGroupsApiService.getGroupMembership(selectedUserIds);
			const groupsPromise = usersGroupsApiService.getMasterAccountGroups();

			this.scope.promises.loadingUsers =
				$q.all([membersPromise, groupsPromise]).then((responses) => {
				let bulkGroupModal = $uibModal.open({
					component: 'usersGroupMembership',
					windowClass: 'modal-md',
					backdrop: 'static',
					keyboard: false,
					resolve: {
						groups: () => {
							return responses[1].data;
						},
						belongsToGroups: () => {
							return responses[0].data;
						}
					}
				});

				return bulkGroupModal.result.then((updatedGroups) => {
					return userModificationService.callBulkGroupUpdateAPI(
						selectedUserIds,
						updatedGroups,
						this.scope.buildUserSelectionQueryParameters()
					);
				}).then(this.finishAction);
			});
		}

		updateDataAccess(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);

			this.scope.promises.loadingUsers = downgradeDialogService.openBulkDataAccessDialog()
				.then(updatedDataAccess => {
					return userModificationService.callBulkDataAccessUpdateAPI(
						selectedUserIds,
						updatedDataAccess,
						this.scope.buildUserSelectionQueryParameters()
					);
				})
				.then(this.finishActionNotify, _.noop);
		}

		resetPassword(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);

			let resetPasswordModal = cbDialogService.confirm(
				locale.getString('common.pleaseConfirm'),
				locale.getString('administration.bulkPasswordResetNote')
			);

			resetPasswordModal.result.then(() => {
				// Reset password in bulk
				userModificationService.resetPassword(
					selectedUserIds,
					this.scope.buildUserSelectionQueryParameters()
				).then(this.finishActionNotify);
			});
		}

		updateLicenseTypes(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);

			const availableLicenses = this.scope.licenseInfo.licensingEnabled
				? licenseService.getAvailableBulkLicenseTypes(this.scope.licenseTypes,
					this.scope.availableLicenses, this.scope.selectedLicenseMap, selectedUserIds.length)
				: licenseService.getAvailableBulkLicenseTypesNoContract(this.scope.licenseTypes);

			const dialogData = {
				availableLicenses,
				selectedCount: selectedUserIds.length
			};

			const bulkLicensingModal = bulkLicensingUpdateDialog.open(dialogData);

			this.scope.promises.loadingUsers = bulkLicensingModal.result
				.then((modalOutput: BulkLicensingUpdateDialogOutput) => {
					return userModificationService.updateLicenseTypeBulk(
						selectedUserIds,
						modalOutput.selectedLicense,
						modalOutput.inheritPermissions
					);
				})
				.then(this.finishActionNotify);
		}

		updateCustomField(users: User[]): void {
			const selectedUserIds = this.getSelectedUserIds(users);
			const dialogData = { labelName: this.scope.getCustomFieldName() } as BulkCustomFieldEntry;
			const bulkCustomFieldUpdateModal = downgradeDialogService.openBulkCustomFieldModal(dialogData);

			this.scope.promises.loadingUsers = bulkCustomFieldUpdateModal.result
				.then((newFieldValue: string) => {
					return userModificationService.updateCustomFieldBulk(
						selectedUserIds,
						newFieldValue,
						this.scope.buildUserSelectionQueryParameters()
					);
				})
				.then(this.finishActionNotify);
		}

		private isBulk(): boolean {
			return _.size(this.scope.selectedUsers) > 1;
		}

		private getSelectedUserIds(passedUsers?: User[]): number[] {
			//if two or more users selected, use those users. if only one selected, use whichever one's menu was clicked
			const selectedUsers = this.isBulk()
				? this.scope.selectedUsers
				: passedUsers;

			return selectedUsers.map(user => user.userId);
		}
	};
});
