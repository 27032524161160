import { Inject, Injectable } from '@angular/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { HttpHandlers } from '@cxstudio/common/http-handlers';

@Injectable({
	providedIn: 'root'
})
export class CxCachedHttpService {

	constructor(
		@Inject('cachedHttpService') private readonly cachedHttpService: CachedHttpService,
		@Inject('httpHandlers') private readonly httpHandlers: HttpHandlers,
	) {}

	cache = (cacheName: Caches): CxCachedHttp => {
		let cache = this.cachedHttpService.cache(cacheName);
		return new CxCachedHttp(cache, this.httpHandlers);
	};
}
