import { TextTokenType } from '../adapter/formula-segment';


export class CustomMathUtils {

	static ORGANIZATION_HIERARCHY_FORMULA_DELIMETER = '>>';

	static isAggregation = (tokenType: string): boolean => {
		return tokenType === TextTokenType.NUMERIC_AGGREGATION
			|| tokenType === TextTokenType.TEXT_AGGREGATION
			|| tokenType === TextTokenType.GENERIC_AGGREGATION;
	};

}
