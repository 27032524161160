import { Injectable, Inject } from '@angular/core';
import GridsterConfigurer from '@cxstudio/home/gridster-configurer';
import { HtmlUtils } from '@app/shared/util/html-utils.class';
import { UrlService } from '@cxstudio/common/url-service.service';

interface IEmbeddedWidget {
	width: number;
	height: number;
	dashboardId: number;
	displayName: string;
}

@Injectable({
	providedIn: 'root'
})
export class EmbeddedWidgetUtils {
	constructor(		
		@Inject('urlService') private readonly urlService: UrlService,
		@Inject(Window) private readonly window: Window,
	) {
	}

	getEmbedWidgetCode(widget: IEmbeddedWidget, identifier: string): string {
		let link = this.urlService.getEmbedWidgetUrl(widget.dashboardId, identifier);
		let size = this.getWidgetSize(widget);
		return `<iframe width="${size.width}" height="${size.height}" src="${link}"`
			+ ` title="${HtmlUtils.escapeHtml(widget.displayName)}"></iframe>`;
	}

	private getWidgetSize(widget: IEmbeddedWidget): { width: number; height: number } {
		let unitSize = this.window.innerWidth / GridsterConfigurer.GRIDSTER_COLUMNS; // don't need to be precise, so ignoring margins
		return {
			width: Math.round(widget.width * unitSize),
			height: Math.round(widget.height / 2 * unitSize) // height unit = 1/2 width unit
		};
	}
}
