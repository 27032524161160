import * as cloneDeep from 'lodash.clonedeep';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import PlotLineType from '@app/modules/plot-lines/plot-lines/plot-line-type';
import { Input } from '@angular/core';
import { Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import ReferenceLine from '@app/modules/plot-lines/reference-lines/reference-line';
import PlotLineAxis from '@app/modules/plot-lines/plot-lines/plot-line-axis';
import PlotLineBuilder from '@app/modules/plot-lines/plot-lines/plot-line-builder';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { ReferenceLineType } from './reference-line-type';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ListOption } from '@app/shared/components/forms/list-option';

interface IAxisMetric {
	name: string;
	customFormatting: any;
	format: any;
}

@Component({
	selector: 'reference-line-builder',
	templateUrl: './reference-line-builder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ReferenceLineBuilderComponent implements OnInit {
	readonly PRIMARY_AXIS = 'primary';
	readonly SECONDARY_AXIS = 'secondary';

	@Input() plotLine: ReferenceLine;
	@Input() calculationOptions: INode[];
	@Input() index: number;
	@Input() showSecondary: boolean;
	@Input() showDuplicate: (line: ReferenceLine, index: number) => boolean;
	@Input() axisNames: {
		primaryName?: string;
		secondaryName?: string;
	};
	@Input() axisSettings: {
		secondaryYAxis: IAxisMetric;
		yAxis: IAxisMetric;
	};
	@Input() allMetrics: IAxisMetric[];

	@Output() addLine = new EventEmitter<ReferenceLine>();
	@Output() removeLine = new EventEmitter<void>();
	@Output() updateLine = new EventEmitter<ReferenceLine>();

	edit: boolean;
	axisOptions: ListOption<string>[];
	referenceLineOptions: ListOption<string>[];
	isDropdownOpen: boolean;
	tempPlotLine: ReferenceLine;

	constructor(
		private readonly locale: CxLocaleService,
		private betaFeaturesService: BetaFeaturesService,
	) { }

	ngOnInit(): void {
		this.edit = false;

		this.axisNames = this.axisNames || {};
		this.axisOptions = [
			{ value: PlotLineAxis.primary, name: this.axisNames.primaryName || this.locale.getString('widget.primary')},
			{ value: PlotLineAxis.secondary, name: this.axisNames.secondaryName || this.locale.getString('widget.secondary')}
		];

		this.referenceLineOptions = [
			{ value: ReferenceLineType.DYNAMIC, name: this.locale.getString('common.dynamic')},
			{ value: ReferenceLineType.VALUE, name: this.locale.getString('common.value')}
		];

		if (_.isUndefined(this.plotLine) || (_.isUndefined(this.plotLine.value) && _.isUndefined(this.plotLine.dynamic))) {
			this.initializeDefaultPlotLine();
		} else {
			if (!this.plotLine.type && this.plotLine.selectedBaseLineStyle) {
				this.plotLine.type = this.plotLine.selectedBaseLineStyle.type;
				delete this.plotLine.selectedBaseLineStyle;
				delete this.plotLine.selectedType;
			}

			if (!this.plotLine.width && this.plotLine.selectedPlotLineWidthNumber) {
				this.plotLine.width = Number(this.plotLine.selectedPlotLineWidthNumber);
				delete this.plotLine.selectedPlotLineWidthNumber;
			}
		}
	}

	addPlotLine = (): void => {
		this.addLine.emit(cloneDeep(this.plotLine));
	};

	isDynamicReferenceLine = (): boolean => {
		return this.plotLine.referenceLineType === ReferenceLineType.DYNAMIC;
	};

	onDynamicChange = (node: ReportCalculation) => {
		this.plotLine.dynamic = node;
	};

	isValueReferenceLine = (): boolean => {
		return this.plotLine.referenceLineType === ReferenceLineType.VALUE;
	};

	setReferenceLineType = (type: ReferenceLineType): void => {
		if (type === ReferenceLineType.VALUE) {
			delete this.plotLine.dynamic;
		}
		this.plotLine.referenceLineType = type;
	};

	updatePlotLine = (): void => {
		this.edit = false;
		this.updateLine.emit(this.plotLine);
	};

	removePlotLine = (): void => {
		this.removeLine.emit();
	};

	editPlotLine = (): void => {
		this.edit = true;
		this.tempPlotLine = cloneDeep(this.getLineObject());
	};

	clearChanges = (): void => {
		this.edit = false;
		this.plotLine = cloneDeep(this.tempPlotLine);
		this.updateLine.emit(this.plotLine);
		this.isDropdownOpen = false;
	};

	private getLineObject(): ReferenceLine {
		return this.plotLine;
	}

	isBeingEdited = (): boolean => {
		return this.edit;
	};

	isCreationRow = (): boolean => {
		return this.index === 0;
	};

	showAddButton = (): boolean => {
		return this.isCreationRow();
	};

	disableAddSaveButton = (): boolean => {
		return this.isReferenceLinePopulated()
			|| this.showDuplicate(this.plotLine, this.index);
	};

	isReferenceLinePopulated = (): boolean => {
		return !(!isEmpty(this.plotLine.value) && String(this.plotLine.value).length) && !this.plotLine.dynamic;
	};

	isDynamicReferenceBetaEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.DYNAMIC_REFERENCE_LINES);
	};

	showDuplicateErrorMessage = (): boolean => {
		return this.showDuplicate(this.plotLine, this.index) && (this.edit || this.index === 0);
	};

	showEditButton = (): boolean => {
		return this.index > 0 && !this.edit;
	};

	showRemoveButton = (): boolean => {
		return this.index > 0 && !this.edit;
	};

	//update and edit button should never be shown together
	showUpdateButton = (): boolean => {
		return this.index > 0 && this.edit;
	};

	showClearButton = (): boolean => {
		return this.index > 0 && this.edit;
	};

	private initializeDefaultPlotLine(): void {
		let defaultType = PlotLineType.Dot;
		let defaultWidth = 1;

		this.plotLine = {} as ReferenceLine;
		this.plotLine.type = defaultType;
		this.plotLine.width = defaultWidth;
		this.plotLine.color = '#868898';
		this.plotLine.label = '';
		this.plotLine.value = null;
		this.plotLine.axis = PlotLineAxis.primary;
		this.plotLine.lineStyle = PlotLineBuilder.getLineStyle(defaultType, defaultWidth);
		this.plotLine.referenceLineType = ReferenceLineType.VALUE;

		this.updateLine.emit(this.plotLine);

	}

	getReferenceLineFormat = () => {
		let targetAxisMetric = this.plotLine.axis === this.SECONDARY_AXIS
			? this.axisSettings.secondaryYAxis
			: this.axisSettings.yAxis;

		let matchedMetric = _.find(this.allMetrics, {name: targetAxisMetric.name});
		if (matchedMetric && !targetAxisMetric.customFormatting) {
			return matchedMetric.format;
		}

		return targetAxisMetric;
	};

	showFormatPreview = (): boolean => {
		return this.getReferenceLineFormat() && !isEmpty(this.plotLine.value);
	};

}

app.directive('referenceLineBuilder', downgradeComponent({component: ReferenceLineBuilderComponent}));
