import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy, OnInit } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';

@Component({
	selector: 'alert',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<ngb-alert #alert *ngIf="!closed" [ngClass]="{'w-100-percent': fullWidth, 'd-flex-inline': !closed }"
			[type]="type" [dismissible]="dismissable" (close)="closeAlert()">
			<span *ngIf="!hideIcon" class="alert-icon" [ngClass]="iconClasses" aria-hidden="true"></span>
			<span>{{text}}</span>
			<span class="transclude-content"><ng-content></ng-content></span>
		</ngb-alert>
	`
})
export class AlertComponent implements OnInit {
	@Input() text: string;
	@Input() type: string;
	@Input() dismissable: boolean;
	@Input() hideIcon: boolean;
	@Input() customIcon: string;
	@Input() fullWidth: boolean;

	@Output() dismiss = new EventEmitter<string>();

	icon: string;
	closed: boolean;
	ngModel: ng.INgModelController;
	iconClasses: string[];

	constructor() { }

	closeAlert = () => {
		this.closed = true;
		this.dismiss.emit(this.text);
	};

	ngOnInit(): void {
		this.type = this.type.toLowerCase();
		this.dismissable = !_.isUndefined(this.dismissable) ? this.dismissable : true;

		switch (this.type) {
		case 'info':
			this.icon = 'q-icon-info';
			break;
		case 'success':
			this.icon = 'q-icon-check';
			break;
		case 'warning':
			this.icon = 'q-icon-warning';
			break;
		case 'danger':
			this.icon = 'q-icon-accessdenied';
			break;
		}

		if (this.customIcon) {
			this.icon = this.customIcon;
		}

		this.iconClasses = [ this.icon , !this.hideIcon ? 'd-flex-inline' : ''];
	}

}

app.directive('alert', downgradeComponent({component: AlertComponent}));
