import { Inject, Injectable, Type } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { TemplateAssetType } from '@app/modules/unified-templates/common-templates/dto/template-asset-type';
import { MetricCreationPropertiesComponent } from '@app/modules/unified-templates/metric-templates/metric-creation-properties/metric-creation-properties.component';
import { SupportWorkspaceProject } from '@app/modules/units/workspace-project/support-workspace';
import { MasterAccountPermissionAction } from '@app/modules/user-administration/permissions/master-account-permission-action';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { Security } from '@cxstudio/auth/security-service';
import { Caches } from '@cxstudio/common/caches';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { IAssetPropertiesComponent } from '@app/modules/unified-templates/common-templates/asset/create-from-template-dialog/asset-properties-component.interface';
import { PlaceholderUsage, PlaceholderUsageType, MetricUsageMetadata, FilterRuleUsageMetadata,
	DateFilterRuleUsageMetadata, WidgetUsageMetadata }
	from '@app/modules/unified-templates/common-templates/dto/template-placeholder-metadata';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterCreationPropertiesComponent } from '@app/modules/unified-templates/filter-templates/filter-creation-properties/filter-creation-properties.component';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardCreationPropertiesComponent } from '@app/modules/unified-templates/dashboard-templates/dashboard-creation-properties/dashboard-creation-properties.component';
import { UnifiedTemplateTreeItem } from './dto/unified-template';
import { DashboardTemplate } from '../unified-templates-management/dashboard-templates-management/dashboard-templates-management.component';
import Authorization from '@cxstudio/auth/authorization-service';

@Injectable({
	providedIn: 'root'
})
export class AssetSpecificUtilsService {

	constructor(
		private locale: CxLocaleService,
		private toastService: ToastService,
		private cachedHttpService: CxCachedHttpService,
		@Inject('security') private security: Security,
		@Inject('authorization') private readonly authorization: Authorization,
		@Inject('filterMatchModes') private filterMatchModes: FilterMatchModes,
		@Inject('DateRange') private readonly DateRange,
	) { }

	getTemplatePropertiesClass(assetType: TemplateAssetType): Type<IAssetPropertiesComponent<SupportWorkspaceProject>> {
		switch (assetType) {
			case TemplateAssetType.DASHBOARD: return DashboardCreationPropertiesComponent;
			case TemplateAssetType.METRIC: return MetricCreationPropertiesComponent;
			case TemplateAssetType.FILTER: return FilterCreationPropertiesComponent;
		}
	}

	getTemplateReplacementLabel(assetType: TemplateAssetType): string {
		switch (assetType) {
			case TemplateAssetType.METRIC: return this.locale.getString('templates.metricReplacementLabel');
			case TemplateAssetType.FILTER: return this.locale.getString('templates.filterReplacementLabel');
		}
	}

	onCreationSuccess(assetType: TemplateAssetType, asset: any): void {
		switch (assetType) {
			case TemplateAssetType.DASHBOARD: {
				let dashboard = asset as Dashboard;
				this.toastService.addToast(this.locale.getString('templates.successNotification', {
					name: dashboard.name,
					type: this.locale.getString('object.dashboard').toLowerCase(),
				}), AlertLevel.SUCCESS);
				return;
			}
			case TemplateAssetType.METRIC: {
				let metric = asset as Metric;
				this.toastService.addToast(this.locale.getString('templates.successNotification', {
					name: metric.displayName,
					type: this.locale.getString('object.metric').toLowerCase(),
				}), AlertLevel.SUCCESS);
				this.cachedHttpService.cache(Caches.METRICS).invalidate();
				return;
			}
			case TemplateAssetType.FILTER: {
				let filter = asset as IFilter;
				this.toastService.addToast(this.locale.getString('templates.successNotification', {
					name: filter.name,
					type: this.locale.getString('object.filter').toLowerCase(),
				}), AlertLevel.SUCCESS);
				this.cachedHttpService.cache(Caches.FILTERS).invalidate();
				return;
			}
		}
	}

	hasCreationPermission(assetType: TemplateAssetType): boolean {
		switch (assetType) {
			case TemplateAssetType.DASHBOARD: return this.security.has(MasterAccountPermissionAction.CREATE_DASHBOARD);
			case TemplateAssetType.METRIC: return this.security.has(MasterAccountPermissionAction.CREATE_METRIC);
			case TemplateAssetType.FILTER: return this.security.has(MasterAccountPermissionAction.CREATE_FILTER);
		}
	}

	getUsageDisplayName(usage: PlaceholderUsage): string {
		switch (usage.type) {
			case PlaceholderUsageType.WIDGET:
				return (usage.usageMetadata as WidgetUsageMetadata).widgetName;
			case PlaceholderUsageType.MATH_COMPONENT:
				return (usage.usageMetadata as MetricUsageMetadata).operator;
			case PlaceholderUsageType.DASHBOARD_FILTER:
			case PlaceholderUsageType.FILTER_RULE:
				return this.getFilterCondition(usage.usageMetadata as FilterRuleUsageMetadata);
			case PlaceholderUsageType.DATE_FILTER_RULE:
				return this.DateRange.valueOf((usage.usageMetadata as DateFilterRuleUsageMetadata).dateFilterMode).displayName;
		}
	}

	getAssetCreateLabel(assetType: TemplateAssetType): string {
		switch (assetType) {
			case TemplateAssetType.METRIC:
				return this.locale.getString('metrics.createNewMetric');
			default:
				return this.locale.getString('templates.createFromTemplate');
		}
	}

	private getFilterCondition(metadata: FilterRuleUsageMetadata): string {
		return this.filterMatchModes.getMatchMode(metadata.type, metadata.exists,
			metadata.matchMode).displayName;
	}

	canShareTemplate(template: DashboardTemplate | UnifiedTemplateTreeItem): boolean {
		return this.authorization.hasManageTemplatePermission()
			|| (this.authorization.hasShareTemplatePermission() && this.security.isCurrentUser(template.ownerName));
	}
}
