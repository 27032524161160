import * as _ from 'underscore';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ValueUtils } from '@cxstudio/reports/utils/value-utils.service';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { DashboardUtils } from '@app/modules/dashboard/services/utils/dashboard-utils.class';
import Authorization from '@cxstudio/auth/authorization-service';
import { AdminProjectsService } from '@cxstudio/internal-projects/admin-projects-service.service';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceProjectUtils } from '@app/modules/units/workspace-project/workspace-project-utils.class';
import { Unit } from '@app/modules/units/unit';
import { WidgetDescriptionService } from '@app/modules/widget-container/widget-description/widget-description.service';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';

export class WidgetEndpointComponent implements ng.IComponentController {
	readonly CHARACTERS_LEFT_AMOUNT = 1000;

	contentProviders = [];
	accounts = [];
	projects = [];

	errorOnAccounts: boolean = false;
	accountsLoaded: boolean = false;
	errorOnProjects: boolean = false;
	projectsLoaded: boolean = false;
	hasInternalProjects: any = false;
	currentSettings: string;

	props: Partial<WidgetProperties>;
	widget: Partial<Widget>;

	loading;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	clearErrors: () => void;
	pushError: (error) => void;

	constructor(
		private $scope: ISimpleScope,
		private $log,
		private errorDialogService: ErrorDialogService,
		private contentProviderOptionsService: ContentProviderOptionsService,
		private adminProjectsService: AdminProjectsService,
		private locale: ILocale,
		private dashboardService,
		private authorization: Authorization,
		private readonly betaFeaturesService: BetaFeaturesService,
		private widgetDescriptionService: WidgetDescriptionService,
	) { }

	$onInit = () => {
		if (!this.isWorkspaceEnabled()) {
			this.reloadContentProviders();
			this.$scope.$watch(() => this.props.contentProviderId, this.refreshContentProvider);
			this.$scope.$watch(() => this.props.accountId, this.refreshAccount);
			this.$scope.$watch(() => this.props.project, this.refreshProject);
		}

		this.$scope.$watch(() => this.widget.displayName, this.updateAltText);

		if (!this.widget.embedConfig) {
			this.widget.embedConfig = {
				enabled: false,
				showDashboardLink: true
			};
		}

		//set existing widget autoDescription to true when description is empty, avoid large upgrade script
		if (this.props.autoDescription === undefined) {
			this.props.autoDescription = !this.widget.description;
		}

		//only update if description is empty, otherwise use the description from settings
		if (!this.widget.description && this.props.autoDescription) {
			this.updateAutoDescription(true);
		}
	};

	updateAutoDescription = (autoDescription: boolean) => {
		if (autoDescription) {
			this.widgetDescriptionService.generateDescription(this.widget as Widget).then((description) => {
				this.widget.description = description;
			});
		}
	};

	isWorkspaceEnabled = (): boolean => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);
	};

	updateAltText = () => {
		if (this.isChartWidget() && this.props.altTextFromTitle) {
			this.props.altText = this.widget.displayName;
		}
	};

	isChartWidget = () => {
		return ReportConstants.isChartWidget(this.props.widgetType);
	};

	isStudioAdminDashboard = () => {
		return DashboardUtils.isStudioAdminDashboard(this.dashboardService.getDashboardById(this.widget.dashboardId));
	};

	private clearAccounts = () => {
		this.accounts = [];
		this.props.accountId = -1;
		delete this.props.accountName;
	};

	private clearProjects = () => {
		this.projects = [];
		this.props.project = -1;
		delete this.props.projectName;
	};

	private refreshContentProvider = (newValue, oldValue?) => {
		if (newValue === oldValue)
			return;
		if (ValueUtils.isNotSelected(newValue)) {
			this.clearAccounts();
			delete this.props.contentProviderName;
			this.$scope.$emit('contentProviderLoaded', null);
		} else {
			this.validateCPandReloadAccounts();
			let cp = _.findWhere(this.contentProviders, {id: newValue});
			this.props.contentProviderName = cp && cp.name;
			this.$scope.$emit('contentProviderLoaded', cp);
		}
	};

	private refreshAccount = (newValue, oldValue?) => {
		if (newValue === oldValue)
			return;
		if (ValueUtils.isNotSelected(newValue)) {
			this.clearProjects();
			delete this.props.accountName;
			this.$scope.$emit('accountLoaded', null);
		} else {
			this.reloadProjects();
			let account = _.findWhere(this.accounts, {accountId: newValue});
			this.props.accountName = account && account.accountName;
			this.$scope.$emit('accountLoaded', account);
		}
	};

	private refreshProject = (newValue, oldValue?) => {
		if (newValue === oldValue)
			return;
		if (ValueUtils.isNotSelected(newValue)) {
			delete this.props.projectName;
			this.$scope.$emit('projectLoaded', null);
		} else {
			let project = _.findWhere(this.projects, {projectId: newValue});
			this.props.projectName = project && project.name;
			let needReset = !ValueUtils.isNotSelected(oldValue);
			this.$scope.$emit('projectLoaded', project, needReset);
		}
	};

	onProjectInit = (): void => {
		this.$scope.$emit('workspaceProjectUpdate', this.props.workspaceProject);
	};

	onProjectChange = (workspaceProject: WorkspaceProject): void => {
		let needReset = WorkspaceProjectUtils.isProjectSelected(this.props.workspaceProject);
		this.props.workspaceProject = workspaceProject;
		this.props.project = workspaceProject.projectId;
		this.$scope.$emit('workspaceProjectUpdate', workspaceProject, needReset);
	};

	onWorkspaceChange = (workspace: Unit): void => {
		if (workspace) {
			this.props.contentProviderId = workspace.contentProviderId;
			this.props.accountId = workspace.accountId;
		} else {
			this.props.contentProviderId = -1;
			this.props.accountId = -1;
		}
	};

	private reloadContentProviders = () => {
		this.addLoadingPromise(this.contentProviderOptionsService.getContentProviders()
			.then((contentProviders) => {
				let oldValue = this.props.contentProviderId;
				this.contentProviders = (contentProviders.data || []);
				if (contentProviders.data && contentProviders.data.length > 0 ) {
					if (ValueUtils.isNotSelected(this.props.contentProviderId)) {
						if (this.contentProviders.length === 1) {
							this.props.contentProviderId = this.contentProviders[0].id;
						} else {
							this.props.contentProviderId = -1;
						}
					} else {
						if (!_.findWhere(this.contentProviders, {id: this.props.contentProviderId})) {
							this.props.contentProviderId = -1;
							this.pushError(this.locale.getString('widget.noContentProviderAccess'));
						}
					}
				} else {
					this.props.contentProviderId = -1;
					this.pushError(this.locale.getString('widget.noContentProviders'));
				}
				if (oldValue === this.props.contentProviderId) // when !==, watcher will handle it
					this.refreshContentProvider(this.props.contentProviderId);
			}));
	};

	private reloadAccounts = () => {
		this.$log.debug('Reloading accounts');
		if (ValueUtils.isNotSelected(this.props.contentProviderId)) {
			this.clearAccounts();
			return;
		}

		this.addLoadingPromise(this.contentProviderOptionsService.getUserAccounts(this.props.contentProviderId, {}).then((accounts) => {
			let oldValue = this.props.accountId;
			this.clearErrors();
			this.accounts = accounts.data;
			if (accounts.data && accounts.data.length > 0) {
				if (ValueUtils.isNotSelected(this.props.accountId)) {
					if (this.accounts.length === 1) {
						this.props.accountId = this.accounts[0].accountId;
					} else {
						this.props.accountId = -1;
					}
				} else {
					if (!_.findWhere(this.accounts, {accountId: this.props.accountId})) {
						this.props.accountId = -1;
						this.pushError(this.locale.getString('widget.noAccountAccess'));
					}
				}
			} else {
				this.props.accountId = -1;
				this.pushError(this.locale.getString('widget.noAccounts'));
			}
			if (oldValue === this.props.accountId)
				this.refreshAccount(this.props.accountId);
			this.accountsLoaded = true;
		}, (error) => {
			this.clearErrors();
			if (this.currentSettings === 'endpoint') {
				this.pushError(error);
			} else {
				this.errorDialogService.error(error);
			}
			this.refreshAccount(null);
			this.errorOnAccounts = true;
			this.accountsLoaded = true;
		}));
	};

	private reloadProjects = () => {
		this.$log.debug('Reloading projects');
		if (ValueUtils.isNotSelected(this.props.accountId)) {
			this.props.project = -1;
			return;
		}

		let props = {
			contentProviderId: this.props.contentProviderId,
			accountId: this.props.accountId,
		};

		this.addLoadingPromise(this.contentProviderOptionsService.getOptions(this.props.contentProviderId, 'projects', props, true)
			.then((response) => {
				let oldValue = this.props.project;
				this.clearErrors();

				this.projects = response.data || [];
				this.processAdminProjects(this.projects);

				this.$log.debug('Projects: ', response.data);

				if (response.data && response.data.length > 0) {
					if (ValueUtils.isNotSelected(this.props.project)) {
						if (this.projects.length === 1) {
							this.props.project = this.projects[0].projectId;
						} else {
							this.props.project = -1;
						}
					} else {
						if (!_.findWhere(this.projects, {projectId: this.props.project})) {
							this.props.project = -1;
							this.pushError(this.locale.getString('widget.noProjectAccess'));
						}
					}
				} else {
					this.props.project = -1;
					this.pushError(this.locale.getString('widget.noProjects'));
				}

				if (oldValue === this.props.project)
					this.refreshProject(this.props.project);
				this.projectsLoaded = true;
			},
			(error) => {
				this.clearErrors();
				if (this.currentSettings === 'endpoint') {
					this.pushError(error);
				} else {
					this.errorDialogService.error(error);
				}
				this.errorOnProjects = true;
				this.projectsLoaded = true;
			}
			)
		);
	};

	private processAdminProjects = (projects) => {
		if (this.authorization.hasStudioAdminProjectAccess()
			&& InternalProjectTypes.isStudioAdminSupported(this.props.widgetType)) {
			let studioProject = this.adminProjectsService.getStudioAdminProject();
			projects.push({
				projectId: studioProject.projectId,
				name: studioProject.title,
				type: studioProject.type,
				internalProject: true
			});
			this.hasInternalProjects = true;
		}
	};

	private validateCPandReloadAccounts = () => {
		if (ValueUtils.isNotSelected(this.props.contentProviderId)) {
			return;
		}
		let cp = {id: this.props.contentProviderId};
		let action = 'validate';
		let cpData = JSON.parse(JSON.stringify(cp));
		this.addLoadingPromise(this.contentProviderOptionsService.validateCP(cpData, action).then((data) => {
			this.clearErrors();
			if (data.valid) {
				this.reloadAccounts();
			} else {
				this.errorOnAccounts = true;
				this.accountsLoaded = true;
				this.refreshContentProvider(null);
				if (this.currentSettings === 'endpoint') {
					this.pushError(data);
				} else {
					this.errorDialogService.error(data.toString());
				}
			}
		}));

	};

	publicProjectsFilter = (project) => isEmpty(project.internalProject) || project.internalProject === false;
	internalProjectsFilter = (project) => !isEmpty(project.internalProject) && project.internalProject === true;

	showEmbedConfig = () => !!this.widget.embedConfig;
}

app.component('widgetEndpoint', {
	bindings: {
		clearErrors: '<',
		pushError: '<',
		addLoadingPromise: '<',
		loading: '<',
		currentSettings: '<',
		props: '<',
		widget: '<',
		showContentProviderErrors: '<'
	},
	controller: WidgetEndpointComponent,
	templateUrl: 'partials/widgets/settings/cb/widget-endpoint.component.html'
});
