import * as _ from 'underscore';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import * as Highcharts from 'highcharts';
import { ColorShadeUtils } from '@cxstudio/utilities/color-shade-utils.class';
import { ColorConstants } from '@cxstudio/reports/utils/color/color-constants';
import { ApplicationTheme } from '@cxstudio/header/application-theme';
import { Security } from '@cxstudio/auth/security-service';

export class HighchartsConfig {

	constructor(
		private environmentService: EnvironmentService,
		private security: Security,
	) {}

	initDefaultOptions = (): void => {
		this.initOptions(ApplicationTheme.DEFAULT);
	};

	initOptions(theme: ApplicationTheme): void {
		let accessible = !this.environmentService.isUnderTest();
		let customOptions: Highcharts.Options = {
			lang: {
				thousandsSep: ','
			},
			accessibility: {
				enabled: accessible,
				keyboardNavigation: {
					enabled: accessible,
					focusBorder: {
						enabled: accessible,
						style: {
							borderRadius: 0
						}
					}
				}
			},
			chart: {
				backgroundColor: this.getBackgroundColor(theme),
				style: {
					fontFamily: 'var(--font-standard)'
				},
				animation: !this.environmentService.isUnderTest(),
				resetZoomButton: {
					theme: {
						stroke: this.getStrokeColor(theme),
						fill: 'transparent',
						style: this.getLabelStyle(theme),
						states: {
							hover: {
								fill: 'transparent',
								style: this.getLabelStyle(theme),
								stroke: this.getStrokeColor(theme)
							}

						}
					}
				},
				spacing: this.isExperimentalUI() ? [8, 8, 8, 8] : [10, 10, 15, 10]
			},
			plotOptions: {
				series: {
					animation: !this.environmentService.isUnderTest(),
					borderWidth: 2,
					borderColor: 'var(--white)',
					dataLabels: {
						allowOverlap: this.environmentService.isUnderTest(),
						color: this.getTextColor(theme),
						style: {
							fontWeight: '500'
						}
					}
				}
			},
			legend: {
				align: this.isExperimentalUI() ? 'left' : 'center',
				backgroundColor: this.getBackgroundColor(theme),
				itemStyle: this.getLegendStyle(theme),
				itemHoverStyle: {
					color: this.getLegendHoverColor(theme)
				}
			},
			xAxis: angular.extend({}, this.getAxisStyle(theme), {
				title: {
					style: angular.extend({}, this.getAxisLabelStyle(theme), {
						fontWeight: '700'
					} as Highcharts.CSSObject)
				},
				labels: {
					style: this.getAxisLabelStyle(theme) as Highcharts.CSSObject
				},
				gridLineDashStyle: 'dot',
				lineColor: 'transparent'
			}) as Highcharts.XAxisOptions,
			yAxis: angular.extend({}, this.getAxisStyle(theme), {
				title: {
					style: angular.extend({}, this.getAxisLabelStyle(theme), {
						fontWeight: '700'
					} as Highcharts.CSSObject)
				},
				labels: {
					style: this.getAxisLabelStyle(theme) as Highcharts.CSSObject
				},
				gridLineDashStyle: 'dot',
				lineColor: 'transparent'
			}) as Highcharts.YAxisOptions,
			tooltip: {
				style: this.getTooltipStyle(theme)
			}
		};
		Highcharts.setOptions(customOptions);
	}

	private getBackgroundColor(theme: ApplicationTheme): string {
		if (ApplicationTheme.DEFAULT === theme) {
			return ColorConstants.WHITE;
		} else {
			return ColorConstants.DARK_MODE_WIDGET_BACKGROUND;
		}
	}


	private getStrokeColor(theme: ApplicationTheme): string {
		if (ApplicationTheme.DEFAULT === theme) {
			return ColorConstants.CHARCOAL;
		} else {
			return ColorConstants.DARK_MODE_CHART_LINE_COLOR;
		}
	}

	private getLineColor(theme: ApplicationTheme): string {
		if (ApplicationTheme.DEFAULT === theme) {
			return ColorShadeUtils.shade200(ColorConstants.CHARCOAL);
		} else {
			return ColorConstants.DARK_MODE_CHART_LINE_COLOR;
		}
	}

	private getTextColor(theme: ApplicationTheme): string {
		if (ApplicationTheme.DEFAULT === theme) {
			return ColorConstants.GRAY_1000;
		} else {
			return ColorConstants.DARK_MODE_TEXT_COLOR;
		}
	}

	private getLegendStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		return angular.extend(this.getLabelStyle(theme), {
			fontWeight: this.isExperimentalUI() ? '700' : '400'
		});
	}

	private getLabelStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		return angular.extend({
			fontSize: '14px'
		}, this.getTextStyle(theme));
	}

	private getTextStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		return {
			fontFamily: 'inherit',
			fontWeight: '400',
			color: this.getTextColor(theme)
		};
	}

	private getLegendHoverColor(theme: ApplicationTheme): string {
		if (ApplicationTheme.DEFAULT === theme) {
			return ColorConstants.BLACK;
		} else {
			return ColorConstants.DARK_MODE_TEXT_COLOR;
		}
	}

	private getTooltipTextStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		let tooltipTextStyle = this.getTextStyle(theme);
		tooltipTextStyle.color = ColorConstants.CHARCOAL;
		return tooltipTextStyle;
	}

	private getAxisLabelStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		return angular.extend(this.getTextStyle(theme), {
			fontSize: this.isExperimentalUI() ? '14px' : '12px',
			fontWeight: '500',
			color: 'var(--gray-800)'
		});
	}

	private getTooltipStyle(theme: ApplicationTheme): Highcharts.CSSObject {
		return angular.extend({
			fontSize: '12px'
		}, this.getTooltipTextStyle(theme));
	}

	private getAxisStyle(theme: ApplicationTheme): Highcharts.AxisOptions {
		return {
			gridLineColor: this.getLineColor(theme),
			lineColor: this.getLineColor(theme),
			lineWidth: 1,
			tickColor: this.getLineColor(theme),
			labels: {
				style: this.getAxisLabelStyle(theme)
			}
		};
	}

	private isExperimentalUI(): boolean {
		return this.security.getContext()?.experimentalUI;
	}


}

app.service('highchartsConfig', HighchartsConfig);
