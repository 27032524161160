import * as _ from 'underscore';
import { ConversationDataPoint } from '@cxstudio/conversation/conversation-data-point.class';
import { ConversationChartOptions } from './conversation-chart-options.class';

export class ScrollIndicator {
	scrollHighlighter;
	config: ConversationChartOptions;
	svg;

	constructor(config, rootSvg) {
		this.config = config;
		this.svg = rootSvg;

		this.scrollHighlighter = this.svg.append('g')
			.attr('id', 'spine-scroll-highlighter')
			.append('rect')
			.attr('width', '100%')
			.attr('fill', this.config.scrollIndicator.color)
			.attr('opacity', this.config.scrollIndicator.opacity);
	}

	update = (start: ConversationDataPoint, end: ConversationDataPoint): void => {
		if (!start || !end) return; // fail gracefully if we dont have startpoint and endpoint

		let startPoint = start.yPosition;
		let height = end.yPosition - startPoint;

		height += end.height;

		this.scrollHighlighter
			.transition(this.config.animationTransition)
			.attr('y', startPoint)
			.attr('height', height);
	};
}