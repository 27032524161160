import { PeriodOverPeriodMetricType } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric-type';
import { ReportCalculation } from '../calculations/report-calculation';

export class MetricWidgetPOPService {

	constructor() {}

	getPoPField = (selectedMetrics: ReportCalculation[]): PeriodOverPeriodMetricType => {
		if (selectedMetrics.length !== 1) return undefined;
		let metric = selectedMetrics[0];
		return this.getMetricPoPField(metric);
	};

	getMetricPoPField = (metric: ReportCalculation): PeriodOverPeriodMetricType => {
		if (!metric.popField) {
			return metric.usePercentChange ? PeriodOverPeriodMetricType.PERCENT_CHANGE : PeriodOverPeriodMetricType.DELTA;
		}
		return metric.popField;
	};
}

app.service('metricWidgetPOPService', MetricWidgetPOPService);