import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { GridOptions, CellEvent, RowNode } from 'ag-grid-enterprise';
import { AgGridIcons } from '../cell-renderers/ag-grid-icons.const';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { FolderUtils } from '@cxstudio/common/folders/folder-utils';
import { Key } from '@app/shared/util/keyboard-utils.class';

export interface QGridOptions extends GridOptions {
	// makes icons mandatory so that if extending class modifies gridOptions
	// and forgets to include icons, TS will throw an error.
	icons: {[key: string]: string | (() => string)};
}

export class GenericObjectList extends SelfCleaningComponent {
	defaultGridOptions: QGridOptions = {
		icons: AgGridIcons,
		onCellClicked: (params) => {
			this.checkMenuClick(params);
			this.checkFolderClick(params);
 		},
		onCellDoubleClicked: (params) => {
			this.checkNameClick(params);
 		},
		onCellKeyDown: (params) => {
			if ((params.event as KeyboardEvent).key === Key.ENTER) {
				this.checkMenuClick(params as CellEvent);
				this.checkNameClick(params as CellEvent);
			}
		},
		rowClassRules: {
			hiddenItem: params => params.node.data.hide,
		},
		suppressLoadingOverlay: true,
		columnTypes: {
			textColumn: {},
			numberColumn: {},
			dateColumn: {},
			multivalueColumn: {}
		}
	};

	constructor() {
		super();
	}

	private checkMenuClick(params: CellEvent): void {
		// By default context menu is shown on right click
		// To make it work on left click we need to access private contextMenuFactory
		let columnId: string = params.column.getColId();
		if (columnId === ObjectListColumnsService.CONTEXT_MENU_COLUMN) {
			params.api.showContextMenu({
				rowNode: params.node as RowNode,				
				...params
			});
		}
	}

	private checkFolderClick(params: CellEvent): void {
		let columnId: string = params.column.getColId();
		if (columnId === ObjectListColumnsService.PRIMARY_COLUMN) {
			if (FolderUtils.isFolder(params.data)) {
				params.node.setExpanded(!params.node.expanded);
			}
		}
	}

	private checkNameClick(params: CellEvent): void {
		let columnId: string = params.column.getColId();
		if (columnId === ObjectListColumnsService.PRIMARY_COLUMN
				&& !FolderUtils.isFolder(params.data)) {
			this.onObjectClick(params.node.data);
		}
	}

	onObjectClick(object: any): void {}

}
