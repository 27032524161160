import ChartType from '@cxstudio/reports/entities/chart-type';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import * as _ from 'underscore';
import { BarChartExtended } from './extensions/bar-chart-extended.factory';

export class HighchartsExtensions {
	static apply = (options: VisualProperties, chartOptions: Highcharts.Options, chartUtilities): BarChartExtended => {
		if (options.subChartType === ChartType.COLUMN || options.subChartType === ChartType.BAR) {
			return new BarChartExtended(chartOptions, chartUtilities);
		}
	};
}
