import { GlobalNotificationType, GlobalNotification } from './global-notification';


export class GlobalError extends GlobalNotification {
	eid: string;
	constructor(message: string, eid: string) {
		super(message, GlobalNotificationType.ERROR);
		this.eid = eid;
	}

	getReportMsg = (): string => {
		let eid = this.eid;
		return `eid=${eid}`;
	};
}