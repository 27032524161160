import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Injectable } from '@angular/core';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { IReportModel } from '@app/modules/project/model/report-model';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';
import { ModelTree, ModelTreeNode } from '@app/shared/components/tree-selection/model-tree';
import { HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export default class WorkspaceModelsApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService,
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.MODELS);
	}

	getModels = (
		project: WorkspaceProject, withHierarchies = false, cache = CacheOptions.CACHED, local = false
	): AssetPromisePlatformProject<IReportModel[]> => {
		let config = { cache, local };
		let url = this.getBasePath(project);
		return this.cachedHttp.get(url, config)
			.then((models) => {
				if (!withHierarchies) {
					return AssetVisibilityUtils.getVisibleModels(models);
				}
				return models;
			}) as AssetPromisePlatformProject<IReportModel[]>;
	};

	getModelTree = (
		project: WorkspaceProject,
		modelId: number,
		cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<ModelTree> => {
		let config = { cache };
		let url = `${this.getBasePath(project)}/${modelId}`;
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ModelTree>;
	};

	getModelNode = (
		project: WorkspaceProject,
		nodeId: number,
		cache = CacheOptions.CACHED
	): AssetPromisePlatformProject<ModelTreeNode> => {
		let config = { cache };
		let url = `${this.getBasePath(project)}/nodes/${nodeId}`;
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<ModelTreeNode>;
	};

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/resources/ws/${project.workspaceId}/project/${project.projectId}/models`;
	};
}
