import { Inject, Injectable } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import { AlertingApiService } from '@cxstudio/alerts/api/alerting-api.service';
import { StudioAlertServiceFactory } from '@app/modules/alert/services/studio-alert-service-factory';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { IDrillParams } from '@cxstudio/reports/utils/contextMenu/drill-params';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { IUserWidgetAccess } from '@cxstudio/reports/utils/contextMenu/drill/user-widget-access';
import { StudioAlert } from '@cxstudio/alerts/entities/studio-alert';
import { IStudioAlertActionsServiceScope } from '@cxstudio/alerts/studio-alert-actions-service';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { ReportAssetType } from '@cxstudio/reports/entities/report-asset-type';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { IFilterRule } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DrillGroupBy } from '@cxstudio/reports/utils/contextMenu/drill/drill-constants';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { FilterRuleService } from '@app/modules/filter-builder/filter-rule.service';
import { CommonDrillService } from '@app/modules/reports/utils/context-menu/drill/common-drill.service';
import { WidgetMetricsAccessService } from '@app/modules/widget-settings/widget-metrics-access.service';
import { DrillHelperService } from '@app/modules/reports/utils/context-menu/drill/drill-to/drill-helper.service';
import { TreeListTransformUtils } from '@app/modules/item-grid/services/tree-list-transform.utils';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { PromiseUtils } from '@app/util/promise-utils';
import { DrillPoint } from '@cxstudio/reports/entities/drill-point';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';

@Injectable({
	providedIn: 'root'
})
export class MetricAlertDrillService {
	constructor(
		private readonly drillHelperService: DrillHelperService,
		@Inject('security') private readonly security: Security,
		@Inject('alertingApiService') private readonly alertingApiService: AlertingApiService,
		@Inject('studioAlertServiceFactory') private readonly studioAlertServiceFactory: StudioAlertServiceFactory,
		private readonly reportModelsService: ReportModelsService,
		private readonly filterRuleService: FilterRuleService,
		private readonly commonDrill: CommonDrillService,
		@Inject('metricConstants') private readonly metricConstants: MetricConstants,
		private readonly widgetMetricsAccessService: WidgetMetricsAccessService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly projectAccessService: ProjectAccessService
	) {
	}

	getCreateAlertOption = (params: IDrillParams, widget: Widget, userAccess: IUserWidgetAccess) => {
		const drillToHelper = this.drillHelperService.create(params);
		const disabledOption = !userAccess.hasProjectAccess || !userAccess.hasMetricsAccess;

		return drillToHelper.getDrillToMetricAlert(disabledOption, (point: DrillPoint) => {
			if (disabledOption) {
				return;
			}

			this.createMetricAlert(point, widget);
		});
	};

	checkUserAccessToWidgetData = (widget: Widget): Promise<IUserWidgetAccess> => {
		if (!this.security.has('manage_studio_alerts')) {
			return Promise.resolve({} as IUserWidgetAccess);
		}

		if (widget.properties.runAs === this.security.getUser().userEmail) {
			return Promise.resolve({
				hasProjectAccess: true,
				hasMetricsAccess: true
			} as IUserWidgetAccess);
		}

		const projectAccessPromise = this.hasProjectAccess(widget);
		const metricsAccessPromise = this.hasMetricsAccess(widget);

		return Promise.all([projectAccessPromise, metricsAccessPromise]).then(responses => {
			const userAccess: IUserWidgetAccess = {
				hasProjectAccess: responses[0],
				hasMetricsAccess: responses[1]
			};
			return userAccess;
		});
	};

	private readonly hasProjectAccess = (widget: Widget): Promise<boolean> => {
		const project = this.reportAssetUtilsService.getWidgetProject(widget);

		return this.projectAccessService.hasProjectAccess(project);
	};

	private readonly hasMetricsAccess = (widget: Widget): Promise<boolean> => {
		return PromiseUtils.wrap(
			this.widgetMetricsAccessService.hasPrivateMetrics([widget])
				.then(hasPrivateMetrics => !hasPrivateMetrics) as unknown as ng.IPromise<boolean>
		);
	};

	private readonly createMetricAlert = (point: DrillPoint, widget: Widget): void => {
		this.alertingApiService.getStudioAlerts(widget.properties.contentProviderId, widget.properties.accountId)
			.then((studioAlerts) => {
				const tree = TreeListTransformUtils.tree(studioAlerts);
				const sortedTree: StudioAlert[] = tree.sort(
					(a, b) => {
						if (a.name < b.name) {
							return -1;
						}

						if (a.name > b.name) {
							return 1;
						}

						return 0;
					}
				);

				this.getPredefinedSettings(point, widget).then(settings => {
					const scope: IStudioAlertActionsServiceScope = {
						projectSelection: {
							accountId: widget.properties.accountId,
							contentProviderId: widget.properties.contentProviderId,
							projectId: widget.properties.project
						},
						predefinedSettings: settings,
						alertList: sortedTree
					};

					this.studioAlertServiceFactory.createActionsService(scope).createAlert();
				});
			});
	};

	private readonly getAlertName = (point: DrillPoint, widget: Widget): string => {
		const groupingValue = point.displayName;
		const calculationName = this.getCalculation(widget).displayName;
		return `${groupingValue} ${calculationName}`;
	};

	private readonly getCalculation = (widget: Widget): any => {
		return this.commonDrill.getCalculationAttribute(widget);
	};

	private readonly getPredefinedSettings = (point: DrillPoint, widget: Widget): Promise<any> => {
		return this.getFilter(point, widget).then(filterData => {
			return {
				alertName: this.getAlertName(point, widget),
				calculation: this.getCalculation(widget),
				filter: filterData
			};
		});
	};

	private readonly getFilter = (point: DrillPoint, widget: Widget): Promise<any> => {
		const promises = [];

		widget.properties.selectedAttributes.forEach(grouping => {
			if (!AnalyticMetricTypes.isAttribute(grouping) && !AnalyticMetricTypes.isClarabridge(grouping)) {
				return;
			}

			promises.push(this.processGrouping(grouping, point, widget));
		});

		return Promise.all(promises).then(rules => {
			return rules;
		});
	};

	private readonly processGrouping = (grouping: any, point: DrillPoint, widget: Widget): Promise<any> => {
		const identifier = GroupIdentifierHelper.getIdentifier(grouping);
		const value = point && point[identifier];
		if (grouping.type === ReportAssetType.SYS && grouping.name === ClarabridgeMetricName.KEY_TERMS) {
			let attributeName = point.rootCause_attributeName;
			if (attributeName === ClarabridgeMetricName.WORDS) {
				grouping = this.metricConstants.get().WORDS;
			} else if (attributeName === ClarabridgeMetricName.HASHTAG) {
				grouping = this.metricConstants.get().HASHTAG;
			} else if (attributeName === ClarabridgeMetricName.LC) {
				grouping = this.metricConstants.get().LC;
			}
		} else if (grouping.type === ReportAssetType.SYS && grouping.name === ClarabridgeMetricName.WORDS) {
			grouping = this.metricConstants.get().WORDS_MTOKEN;
		}

		const filterRule = this.filterRuleService.generateNewRule(grouping, {
			isThreshold: false
		} as IFilterRule, undefined);

		if (grouping.type === AttributeType.NUMBER) {
			filterRule.value = value;
			return Promise.resolve(filterRule);
		}

		if (grouping.type === DrillGroupBy.TOPIC || grouping.type === DrillGroupBy.TOPICLEAF) {
			const modelNodeId = point[identifier + '_modelNodeId'];

			return this.reportModelsService.getWidgetNodeInfo(widget, modelNodeId).then(node => {
				const path = node.path.replaceAll('-->', ' > ');

				filterRule.values = [{
					id: node.id,
					modelId: node.modelId,
					idPath: point[identifier + '_fullPath'],
					name: node.name,
					path,
					text: path
				}];

				return filterRule;
			});
		}

		filterRule.values = [{
			text: value
		}];

		return Promise.resolve(filterRule);
	};
}

app.service('metricAlertDrill', downgradeInjectable(MetricAlertDrillService));
