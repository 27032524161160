import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { ColDef, GridOptions } from 'ag-grid-enterprise';
import { ConsumptionTrackerApiService } from '../consumption-tracker-api.service';
import { ConsumptionStatisticsTreeItem } from '../entities/consumption-statistics';
import { RawConsumptionStatistics } from '../entities/raw-consumption-statistics';
import { TrackingAssetType } from '../entities/tracking-asset-type.enum';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { StatisticRefinementMode } from '../entities/statistic-refinement-mode.enum';

@Component({
	selector: 'consumption-statistics-table',
	templateUrl: './consumption-statistics-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConsumptionStatisticsTableComponent extends SelfCleaningComponent implements OnInit {
	@Input() searchLabel: string;
	@Input() assetType: TrackingAssetType;
	@Input() consumedColumnTitle: string;
	@Input() remainingColumnTitle: string;
	@Input() totalExpencesTitle: string;
	@Input() allowExport: boolean = false;
	@Input() refinementMode: StatisticRefinementMode = StatisticRefinementMode.ALL_MASTER_ACCOUNTS;

	searchText: string;
	loading: Promise<any>;
	columnDefs: ColDef[];
	gridOptions: GridOptions;
	consumptionStatistics: ConsumptionStatisticsTreeItem[];
	totalExpences: number = 0;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private cxDialogService: CxDialogService,
		private objectListColumns: ObjectListColumnsService,
		private consumptionTrackerApi: ConsumptionTrackerApiService
	) {
		super();
	}

	ngOnInit(): void {
		this.columnDefs = [];

		if (this.isAllMasterAccountsMode()) {
			this.columnDefs.push(
				this.objectListColumns.textColumn('masterAccountId', this.locale.getString('administration.masterAccountId'))
			);
		}

		this.columnDefs.push(
			this.objectListColumns.textColumn('masterAccountName', this.locale.getString('administration.masterAccountName')),
			this.objectListColumns.numberColumn('currentConsumption', this.consumedColumnTitle),
			this.objectListColumns.numberColumn('remainingCredit', this.remainingColumnTitle),
			this.objectListColumns.progressColumn('consumptionProgress', this.locale.getString('administration.consumptionProgress'))
		);

		this.gridOptions = {
			domLayout: 'autoHeight'
		};

		this.reloadTree();
	}

	private reloadTree(): void {
		this.loading = this.consumptionTrackerApi.getConsumptionStatistics(this.assetType, this.refinementMode).then(data => {
			this.consumptionStatistics = this.covertConsumptionStatistics(data);
			this.ref.markForCheck();
		});
		this.ref.markForCheck();
	}

	private covertConsumptionStatistics(statistics: RawConsumptionStatistics[]): ConsumptionStatisticsTreeItem[] {
		let consumptionStatistics: ConsumptionStatisticsTreeItem[] = [];

		statistics.forEach(data => {
			consumptionStatistics.push({
				id: data.masterAccountId,
				name: data.masterAccountName,
				masterAccountId: data.masterAccountId,
				currentConsumption: data.consumption,
				remainingCredit: data.consumptionLimit - data.consumption,
				consumptionProgress: (data.consumption / data.consumptionLimit) * 100
			});

			this.totalExpences += data.totalCost;
		});

		return consumptionStatistics;
	}

	exportData(): void {
		this.cxDialogService.notify(
			this.locale.getString('administration.consumptionReport'),
			this.locale.getString('administration.exportToExcelMessage')
		).result.then(() => {
			this.consumptionTrackerApi.exportData(this.assetType, this.refinementMode);
		}, _.noop);
	}

	showSearchPanel(): boolean {
		return !this.isCurrentMasterAccountMode();
	}

	isCurrentMasterAccountMode(): boolean {
		return this.refinementMode === StatisticRefinementMode.CURRENT_MASTER_ACCOUNT;
	}

	isAllMasterAccountsMode(): boolean {
		return this.refinementMode === StatisticRefinementMode.ALL_MASTER_ACCOUNTS;
	}
}
