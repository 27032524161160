import { Component, OnInit, Input, Output, EventEmitter, Inject, OnChanges, SimpleChanges } from '@angular/core';
import { BoxMetricDefinition } from '@app/modules/metric/definition/box-metric-definition';
import { ControlContainer, NgForm } from '@angular/forms';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { SearchableHierarchyUtils } from '@app/modules/utils/searchable-hierarchy-utils.service';
import { IColorGrades, IColorGradeOptions } from '@app/modules/metric/color-grades/cb-color-grade-bar.component';
import { MetricColorGradesService } from '@app/modules/metric/services/metric-color-grades.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';

@Component({
	selector: 'box-metric-definition',
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ],
	templateUrl: './box-metric-definition.component.html'
})
export class BoxMetricDefinitionComponent implements OnInit, OnChanges {

	readonly NUMBER_LIST_PATTERN = /^\s*(-?[0-9]+(\.[0-9]*)?\s*,\s*)*(-?[0-9]+(\.[0-9]*)?)?\s*$/;

	@Input() definition: BoxMetricDefinition;
	@Output() definitionChange = new EventEmitter<BoxMetricDefinition>();
	@Output() validityChange = new EventEmitter<boolean>();

	@Input() attributes: IReportAttribute[];

	ui: {
		calculations;
		excludeFilter;
	};
	calculation;
	colorGrades: IColorGrades;
	options: Partial<IColorGradeOptions>;

	constructor(
		private metricColorGradesService: MetricColorGradesService,
		@Inject('optionsBuilderProvider') private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService
	) {}

	ngOnInit(): void {
		this.ui = {
			calculations: this.getCalculations(),
			excludeFilter: ''
		};
		this.initSettings();
		this.options = this.metricColorGradesService.getDefinitionOptions(this.definition.type);
	}

	ngOnChanges(changes: SimpleChanges) {
		if (!changes.definition?.firstChange && changes.definition?.previousValue !== changes.definition?.currentValue) {
			this.initSettings();
		}
	}

	private initSettings = (): void => {
		if (!this.definition.name) {
			if (this.ui.calculations.length) {
				this.ui.calculations[0]._expanded = true;
				if (this.ui.calculations[0].children.length) {
					let firstOption = _.find(this.ui.calculations[0].children, (attribute: any) => !attribute.hide);
					this.selectCalculationNode(firstOption);
				}
			}
		}
		this.calculation = this.findCalculation();
		this.colorGrades = this.metricColorGradesService.getColorGrades(this.definition);
		this.ui.excludeFilter = this.definition.excludeFilter?.[0].value.join(', ') || '';
	};

	private findCalculation(name?: string) {
		name = name || this.definition.name;
		return SearchableHierarchyUtils.findMetricNameInHierarchy(this.ui.calculations, name);
	}

	getCalculations() {
		return this.optionsBuilderProvider.getBuilder()
			.withTemplate(this.optionsTemplatesService.metricDefaults())
			.withAttributes(this.attributes, MetricFilters.CALCULATION)
			.build();
	}

	colorGradesChangeHandler = (colorGrades: IColorGrades): void => {
		this.metricColorGradesService.updateBoxMetricDefinition(this.definition, colorGrades);
		this.changeHandler();
	};

	minMaxChangeHandler = (isMin: boolean, value: number) => {
		if (isMin) {
			this.definition.min = value;
		} else {
			this.definition.max = value;
		}
		this.changeHandler();
	};

	private changeHandler = (): void => {
		this.definitionChange.emit(this.definition);
	};

	validityChangeHandler = (isValid: boolean): void => {
		this.validityChange.emit(isValid);
	};

	selectCalculationNode = (node): void => {
		this.calculation = node;
		this.definition.name = node.name;
		this.changeHandler();
	};

	toggleNullInclude = (nullInclude: boolean): void => {
		this.definition.nullInclude = nullInclude;
		this.changeHandler();
	};

	excludeFilterChangeHandler = (): void => {
		this.definition.excludeFilter = [{
			name: this.definition.name,
			value: this.stringArray(this.ui.excludeFilter)
		}];
		this.changeHandler();
	};

	private stringArray = (str: string): string[] => {
		if (!str || (str.length === 0)) return [];
		return str.replace(/;/g, ',').split(',').map((part) => {
			return part.replace(/^\s+|\s+$/g, '');
		}).filter((part) => part?.length);
	};

}
