import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnChanges } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { BaseControlValueAccessor } from '@app/shared/components/forms/base-control-value-accessor';
import { ChangeUtils, SimpleChanges } from '@app/util/change-utils';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { ProjectTabType } from '@cxstudio/projects/project-tab-type';

@Component({
	selector: 'asset-visibility-tabs',
	template: `
<ul class="nav nav-tabs mb-0 mr-16">
	<li *ngFor="let tab of getAssetTabs()"
		class="uib-tab nav-item text-default"
		[class.active]="innerValue === tab.value"
		(click)="onChange(tab.value)">
		<a class="m-0">{{tab.displayName}}</a>
	</li>
</ul>`,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => AssetVisibilityTabsComponent), multi: true}
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssetVisibilityTabsComponent extends BaseControlValueAccessor<ProjectTabType> implements OnChanges {

	@Input() withHidden: boolean;

	constructor(
		ref: ChangeDetectorRef,
		private locale: CxLocaleService,
	) {
		super(ref, ProjectTabType.AVAILABLE);
	}

	ngOnChanges(changes: SimpleChanges<AssetVisibilityTabsComponent>): void {
		if (ChangeUtils.hasChange(changes.withHidden)) {
			if (!this.withHidden && this.innerValue === ProjectTabType.HIDDEN) {
				this.onChange(ProjectTabType.AVAILABLE);
			}
		}
	}

	getAssetTabs(): UIOption<ProjectTabType>[] {
		const result = [{displayName: this.locale.getString('administration.available'), value: ProjectTabType.AVAILABLE}];
		if (this.withHidden) {
			result.push({displayName: this.locale.getString('administration.hidden'), value: ProjectTabType.HIDDEN});
		}
		return result;
	}

}
