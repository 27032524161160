<modal-header
	(cancel)="onCancel()"
	[modalTitle]="'mAccount.exportDialogTitle' | i18n">
</modal-header>

<div class="modal-body br-log-export-props" [ngBusy]="exportDialogBusy">
	<form
		class="form-horizontal"
		name="exportSelectorForm"
		(submit)="export()"
		novalidate
		role="form">

		<div class="form-group">
			<div class="col-sm-3 control-label">
				<label [i18n]="'mAccount.startDate'|i18n"></label>
			</div>

			<div class="col-sm-7 br-log-export-date">
				<date-picker-popup #startDate
					[(value)]="filters.startDate"
					(onChange)="retrieveAmountOfRecords()"></date-picker-popup>
			</div>
		</div>

		<div class="form-group">
			<div class="col-sm-3 control-label">
				<label [i18n]="'mAccount.endDate'|i18n"></label>
			</div>

			<div class="col-sm-7 br-log-export-date">
				<date-picker-popup #endDate
					[(value)]="filters.endDate"
					(onChange)="retrieveAmountOfRecords()"></date-picker-popup>
			</div>
		</div>

		<div class="form-group">
			<div class="col-sm-3 control-label">
				<label [i18n]="'mAccount.filterField'|i18n"></label>
			</div>

			<div class="col-sm-7">
				<simple-dropdown
					class="br-log-export-filter-type form-control p-0 border-0"
					noMinWidth="true"
					[options]="options"
					[(value)]="logQueryBy"
					(onChange)="onLogQueryByChange()">
				</simple-dropdown>
			</div>
		</div>

		<div class="form-group">
			<div class="col-sm-3 control-label">
				<label [i18n]="'mAccount.filter'|i18n"></label>
			</div>

			<div class="col-sm-7">
				<div class="input-group br-export-filter">
					<input type="search" name="query"
						class="flex-input"
						[(ngModel)]="logQuery[logQueryBy]"
						(keyup.enter)="retrieveAmountOfRecords()"
						placeholder="{{'common.search'|i18n}}"
						(change)="searchFilter = logQuery[logQueryBy]"/>
					<button
						type="button"
						class="btn btn-primary q-icon-search"
						(click)="retrieveAmountOfRecords()">
					</button>
				</div>
			</div>
		</div>

		<div class="form-group">
			<div class="col-sm-3 control-label">
				<label [i18n]="'mAccount.records'|i18n"></label>
			</div>

			<div class="col-sm-7">
				<p><b id='number-default'>{{amountOfRecords | number}}</b></p>
			</div>
		</div>
	</form>
</div>

<div class="modal-footer">
	<button
		id="btn-export-cancel"
		type="button"
		class="btn btn-secondary"
		[i18n]="'common.cancel'|i18n"
		(click)="onCancel()"></button>
	<button
		id="btn-export"
		type="button"
		class="btn btn-primary"
		[btnLoading]="saving"
		loadingText="{{'dashboard.saving'|i18n}}"
		[i18n]="'mAccount.export'|i18n"
		[disabled]="amountOfRecords === 0"
		(click)="export()"></button>
</div>
