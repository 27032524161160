import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { UserSessionApiService } from '@app/modules/user/user-session-api.service';
import { AppLoadingService } from '@cxstudio/app-loading';
import { LoginFlow } from '@cxstudio/auth/login-flow';
import { Security } from '@cxstudio/auth/security-service';
import { SingleLoginService } from '@cxstudio/auth/single-login.service';
import { ErrorDialogService } from '@cxstudio/common/cb-error-dialog.service';
import { UrlService } from '@cxstudio/common/url-service.service';
import { DashboardStateService } from '@app/modules/dashboard/services/dashboard-state.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { AppRoute } from '@cxstudio/route/app-route';
import { ApplicationThemeService } from '@app/core/application-theme.service';
import { CBDialogService } from './cb-dialog-service';
import { SecurityApiService } from './data-services/security-api.service';
import { EnvironmentService } from './environment-service';
import { ErrorCode } from '@app/modules/error-page/error-page/error-code';
import { PageTitleUtil } from '@app/core/page-title-util.class';


/**
 * Service for working with security tokens
 */
export class RouteService {
	userLoading = false;
	embedded = false;
	preview = false;
	getRouteParam;
	hasRouteParam;

	readonly EMPTY_PATH = '';
	readonly ROOT_PAGE = '/';
	readonly LOGIN_PAGE = '/login';
	readonly ERROR_PAGE = '/error';
	readonly CALLBACK_PAGE = '/auth-callback';
	readonly HOME_PAGE = '/home';
	readonly DASHBOARDS_PAGE = '/dashboards';
	readonly EXTERNAL_AUTH_PAGE = '/external-auth';

	constructor(
		private readonly $rootScope,
		private readonly $location,
		private readonly $log,
		private readonly $q,
		private readonly $window,
		private readonly environmentService: EnvironmentService,
		private readonly dashboardStateService: DashboardStateService,
		private readonly security: Security,
		private readonly securityApiService: SecurityApiService,
		private readonly userSessionApiService: UserSessionApiService,
		private readonly urlService: UrlService,
		private readonly singleLoginService: SingleLoginService,
		private readonly cbDialogService: CBDialogService,
		private readonly applicationThemeService: ApplicationThemeService,
		private readonly appLoading: AppLoadingService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly $injector,
		private readonly locale: ILocale,
		private readonly errorDialogService: ErrorDialogService
	) {

		this.getRouteParam = this.urlService.getRouteParam;
		this.hasRouteParam = this.urlService.hasRouteParam;

		if (!environmentService.isUnderUnitTest()) {
			// this creates memory leak in tests
			$(document).on('visibilitychange', () => {
				if (document.visibilityState === 'visible') {
					singleLoginService.handleLogoutEvent();
				}
			});
		}
	}

	onRouteChange = (newRoute: AppRoute) => {
		if (newRoute && newRoute.pageTitleKey) {
			PageTitleUtil.setTitle(this.locale.getString(`pageTitle.${newRoute.pageTitleKey}`));
		} else if (!this.$location.path().startsWith(this.HOME_PAGE) && this.$location.path() !== this.DASHBOARDS_PAGE) {
			PageTitleUtil.setTitle(this.locale.getString('pageTitle.default'));
		}

		if (newRoute && newRoute.$$route) {
			this.embedded = !!newRoute.$$route.embedded;
			this.preview = !!newRoute.$$route.preview;
		}

		if (this.environmentService.isIframe()
			&& !newRoute.$$route.redirectTo
			&& !newRoute.$$route.allowEmbedding) {
			this.goToEmbeddedErrorPage();
			return;
		}

		if (this.environmentService.isApplicationOutdated()) {
			this.environmentService.hardRefresh();
			return;
		}

		if (newRoute.$$route.forcedLightTheme) {
			this.applicationThemeService.setForcedLightTheme();
		}

		if (this.hasRouteParam(newRoute, 'redirect')) {
			this.$rootScope.externalRedirect = this.getRouteParam(newRoute, 'redirect');
			this.$location.search('redirect', null);
		}

		let isRouteChangedAfterLogin = this.$rootScope.login;
		if (this.$location.path() === this.LOGIN_PAGE || this.$location.path() === this.CALLBACK_PAGE) {
			this.$rootScope.login = true;
			return;
		} else {
			this.$rootScope.login = false;
		}

		if (newRoute?.public) {
			// Hide global spinner
			this.$rootScope.login = true;
			return;
		}

		if (this.isAppInit()) {
			return;
		}

		if (!this.security.isLoggedIn()) {
			this.tryToUseToken(newRoute).then(() => {
				this.checkRedirectToForcedExternal(isRouteChangedAfterLogin);
			}, () => {
				this.noExistingToken(newRoute);
			});
		} else {
			// If path is changed, need to update last visited state
			if (this.$location.path() !== this.DASHBOARDS_PAGE) {
				this.dashboardStateService.clear(newRoute);
			}

			if (this.$rootScope.externalRedirect) {
				this.redirectToExternal();
				return;
			}

			if (this.hasRouteParam(newRoute, 'fullUrlRedirect')) {
				this.redirect(this.getRouteParam(newRoute, 'fullUrlRedirect'));
			} else {
				this.$injector.get('documentLinkService').processLocationAttributes();
			}
		}
	};

	private checkRedirectToForcedExternal(isRouteChangedAfterLogin): any {
		if (!isRouteChangedAfterLogin) {
			this.populateInitialData();
			return;
		}

		return this.securityApiService.getForcedExternalRedirectUrl()
			.then((resp) => {
				if (resp.url) {
					this.redirect(resp.url);
				} else {
					this.populateInitialData();
				}
			})
			.catch(() => {
				// ignoring and proceeding with studio loading
				this.populateInitialData();
			});
	}

	private redirectToExternal(): any {
		return this.securityApiService.getExternalRedirectUrl(this.$rootScope.externalRedirect)
			.then((resp) => {
				this.redirect(resp.data.url);
			})
			.catch((errorResponse) => {
				this.errorDialogService.error(errorResponse.data);
				this.$location.url(this.DASHBOARDS_PAGE);
			});
	}

	private isAppInit(): any {
		return !this.$location.search().jwt &&
			_.contains([this.ROOT_PAGE, this.EMPTY_PATH, this.EXTERNAL_AUTH_PAGE], this.$location.path());
	}

	goToLoginPage = () => {
		this.security.setContext(null);
		const currentPath = this.$location.path();
		this.$rootScope.redirect = this.$location.url();
		let unifiedRedirect = this.$location.url();

		if (currentPath === this.LOGIN_PAGE || currentPath === this.ROOT_PAGE) {
			this.$rootScope.redirect = null;
			unifiedRedirect = null;
		}

		this.$location.path(this.LOGIN_PAGE);
	};

	goToEmbeddedErrorPage = () => {
		const currentPath = this.$location.url();
		this.$location.path(this.ERROR_PAGE).search({ code: ErrorCode.CANNOT_EMBED, requestedPath: currentPath });
	};

	goToRefreshRequiredPage = () => {
		const currentPath = this.$location.url();
		this.$location.path(this.ERROR_PAGE).search({ code: ErrorCode.REFRESH_REQUIRED, requestedPath: currentPath });
	};

	redirect = (url) => {
		this.$window.location.href = url;
	};

	private tryToUseToken(route): any {
		let deferred = this.$q.defer();

		if (this.singleLoginService.isAnalyzeSessionExpiration()) {
			this.$log.log('Handling analyze session timeout');
			this.singleLoginService.removeAnalyzeSessionExpirationEvent();
			deferred.reject();
			return deferred.promise;
		}

		let ssoToken = this.getSSOUrlToken(route);
		if (this.hasSpecialToken(route)) {
			this.$log.log('Has special token, cleaning storage');
			this.security.setToken(undefined);
			this.security.setAccessToken(undefined);
			if (this.hasSSOToken()) {
				this.security.setLoginFlow(LoginFlow.SSO);
			}
		}

		if (this.security.isAuthenticated()) {
			this.$log.log('Have token in storage');
			deferred.resolve();
			return deferred.promise;
		}

		if (this.$location.search().jwt && this.$location.path() !== this.ROOT_PAGE && this.$location.path() !== this.LOGIN_PAGE) {
			this.$rootScope.redirect = this.$location.path() ? this.$location.path() : this.DASHBOARDS_PAGE;
		}

		this.singleLoginService.loginByExistingToken(ssoToken).then(
			(response) => {
				if (response.token) {
					this.$log.log('Logged by token');
					this.security.setToken(response.token);
				}

				if (response.accessToken) {
					this.$log.log('Logged by OAuth access token');
					this.security.setAccessToken(response.accessToken);
				}

				this.singleLoginService.removeLogoutEvent();

				if (this.$rootScope.externalRedirect) {
					this.redirectToExternal().then(() => {
						deferred.resolve();
					});
					return;
				}

				if (this.$rootScope.redirect) {
					this.$location.url(this.$rootScope.redirect);
					this.$location.search('jwt', null);
					deferred.resolve();
					return;
				}

				if (response.location && (
					this.$location.path() === this.ROOT_PAGE
						|| this.$location.path() === this.LOGIN_PAGE
						|| this.$location.url() === this.DASHBOARDS_PAGE)) {
					this.redirect(response.location);
				}

				deferred.resolve();

			}, () => {
				this.$log.log('No token');
				deferred.reject();
			}
		);

		return deferred.promise;
	}

	private hasSpecialToken(route): boolean {
		return this.hasSSOToken(route);
	}

	private hasSSOToken(route?): boolean {
		return !!this.getSSOUrlToken(route);
	}

	private getSSOUrlToken(route?): boolean {
		// we need 'authToken' for plat < 7.1.12 in other domain (ex cliantro)
		return this.getRouteParam(route, 'authToken') || this.$location.search().jwt;
	}

	private populateInitialData(): any {
		if (this.userLoading)
			return;
		this.userLoading = true;
		this.security.setToken(this.security.getToken()); // fill cookies

		this.singleLoginService.removeNonHttpOnlyCookies();

		this.initializeUserData()
			.finally(() => {
				this.userLoading = false;
			});
	}

	private initializeUserData(): any {
		return this.userSessionApiService.getContext().then((response: any) => {
			let responseData = response.data;
			let context = responseData;

			this.initMasterAccounts(responseData);

			if (context.impersonateMode
					|| this.security.isExternalOAuthSession()
					|| this.security.getLoginFlow() === LoginFlow.SSO) {
				this.security.setContext(context);
				return;
			}

			let passwordPolicy;
			if (!context.registered && !this.$rootScope.pdfToken) {
				passwordPolicy = this.securityApiService.getPasswordPolicy(
					context.user.defaultMasterAccountId);
				this.cbDialogService.showRegisterDialog(context, passwordPolicy);
				this.appLoading.hideAppSpinner();
			} else if (context.passwordExpired && !this.$rootScope.pdfToken) {
				passwordPolicy = this.securityApiService.getPasswordPolicy(
					context.user.defaultMasterAccountId);
				this.cbDialogService.showPasswordResetDialog(context, passwordPolicy);
				this.appLoading.hideAppSpinner();
			} else {
				this.security.setContext(context);
			}
		}, (error) => {
			this.$log.warn('Invalid token', error);
			if (error.status !== 401) {
				// 401 handled by AuthErrorHandler
				this.goToLoginPage();
			}
		});
	}

	private initMasterAccounts(data): any {
		this.betaFeaturesService.init(data.currentMasterAccount.betaFeatures);
	}

	private noExistingToken(route): any {
		if (this.$location.path().match('/login/.*')) {
			this.security.setContext(null);
			this.$location.path(this.$location.path());
		} else if (this.hasRouteParam(route, 'dashboardId')) {
			let dashboardId = this.getRouteParam(route, 'dashboardId');

			this.securityApiService.getExternalAuthStatus(dashboardId, this.$location.url()).then((externalAuth) => {
				if (externalAuth.enabled) {
					if (this.environmentService.isIframe()) {
						this.$location.path(this.EXTERNAL_AUTH_PAGE).search({redirectUrl: externalAuth.redirectTo});
					} else {
						this.redirect(externalAuth.redirectTo);
					}
					return;
				}

				this.goToLoginPage();
			}, this.goToLoginPage);
		} else {
			this.goToLoginPage();
		}
	}

	// TODO: Update
	handleSelectedTab = (availableTabs, selectedCallback, defaultCallback) => {
		if (!this.$location.search().tab || _.isEmpty(availableTabs)) {
			defaultCallback();
			return;
		}

		let tabIndex = _.findIndex(availableTabs, {id: this.$location.search().tab});
		if (tabIndex > -1) {
			selectedCallback(availableTabs[tabIndex], tabIndex);
		} else {
			defaultCallback();
		}
	};

	isEmbeddedView = (): boolean => !!this.embedded;
	isDashboardPreview = (): boolean => !!this.preview;
}

app.service('routeService', RouteService);
