import { Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AlertLevel, ToastService } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MasterAccountLinkSharingService } from '@app/modules/account-administration/api/master-account-link-sharing.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { LinkSharingSettings } from '@cxstudio/master-accounts/settings-components/link-sharing-settings';

@Component({
	selector: 'link-sharing-settings',
	template: `
	<div class="panel panel-default link-sharing-settings">
		<div class="panel-heading">{{"mAccount.linkSharingSettings"|i18n}}</div>
		<div class="panel-body clearfix">
			<div class="d-flex align-items-center mb-16">
				<cb-toggle [(ngModel)]="settings && settings.enabledForBasicUsers" (ngModelChange)="onChangeCallback($event)">
				</cb-toggle>
				<span class="ml-16">{{"mAccount.enabledForBasicUsersLabel"|i18n}}</span>
				<cb-inline-help>
					<help-body i18n="mAccount.enabledForBasicUsersHelp">
					{{"mAccount.enabledForBasicUsersHelp"|i18n}}
					</help-body>
				</cb-inline-help>
			</div>
			<div class="time-to-live-configuration align-items-center mb-16 ml-80">
				<div>
					<span>{{'mAccount.timeToLiveLabel'|i18n}}</span>
					<cb-inline-help>
						<help-body i18n="mAccount.enabledForBasicUsersHelp" [innerHTML]="'mAccount.timeToLiveHelp' | i18n">
						</help-body>
					</cb-inline-help>
				</div>
				<div>
					<input class="time-to-live-input mr-8" [(ngModel)]="settings && settings.daysToLive" disabled="disabled">
					<span>{{'common.days'|i18n}}</span>
				</div>
			</div>
		</div>
	</div>`
})

export class LinkSharingSettingsComponent {
	@Input() settings: LinkSharingSettings;
	constructor(
		private readonly masterAccountLinkSharingService: MasterAccountLinkSharingService,
		private readonly cxDialogService: CxDialogService,
		private readonly toastService: ToastService,
		private readonly locale: CxLocaleService) {
	}

	onChangeCallback($event: any) {
		if ($event) {
			this.saveAndConfirm(true);
		} else {
			this.cxDialogService.warningWithConfirm(
				this.locale.getString('common.warning'),
				this.locale.getString('mAccount.disableForBasicUsersWarning'),
				this.locale.getString('common.yes'),
				this.locale.getString('common.no')).result.then((res: any) => {
					this.saveAndConfirm();
			}).catch((err: any) => this.settings.enabledForBasicUsers = !this.settings.enabledForBasicUsers);
		}
	}

	private saveAndConfirm(enabled: boolean = false) {
		this.masterAccountLinkSharingService.saveLinkSharingSettingsDowngrade(this.settings).then(() => {
			let key = enabled ? 'administration.featureEnabled' : 'administration.featureDisabled';
			this.toastService.addToast(this.locale.getString(key, this.locale.getString('mAccount.linkSharingFeatureName')), AlertLevel.CONFIRM);
		});
	}
}


app.directive('linkSharingSettings',
	downgradeComponent({component: LinkSharingSettingsComponent}));
