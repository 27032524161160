import { DashboardEvent } from '@app/core/cx-event.enum';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { DashboardUnsavedChangesService } from '@app/modules/dashboard/services/dashboard-unsaved-changes.service';
import { CurrentObjectsService } from '@app/shared/services/current-objects-service';
import { GlobalUnloadService } from '@app/shared/services/global-unload.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { IDashboardData } from '@cxstudio/interfaces/dashboard-data.interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { EnvironmentService } from '@cxstudio/services/environment-service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { Security } from './auth/security-service';
import { DashboardBookStateService } from './dashboards/books/dashboard-book-state.service';
import { DashboardFiltersService } from './dashboards/dashboard-filters/dashboard-filters-service';
import { IDashboardHistoryInstance } from './dashboards/dashboard-history.factory';
import { DashboardSaveState } from './dashboards/dashboard-save-status.service';
import { DashboardService } from './dashboards/dashboard-service';
import { DashboardType } from './dashboards/entity/dashboard-type';
import { WidgetsEditService } from './home/widgets-edit.service';
import ILocale from './interfaces/locale-interface';
import { CBDialogService } from './services/cb-dialog-service';
import { DashboardPropsService } from './services/dashboard-props.service';
import { DashboardApiService } from './services/data-services/dashboard-api.service';
import { RedirectService } from './services/redirect-service';
import {LayoutHelper} from '@cxstudio/dashboards/layout-helper.service';

export interface IMainDashboardCtrlScope extends ISimpleScope {
	isAutoSaved: boolean;
	isEditedFromBook: () => boolean;
	returnToBook: () => void;
	processCancelButton: () => void;
	processEditButton: () => void;
	exporting: {
		active: boolean;
	};
	changeEditMode: (editMode: boolean) => void;
	dashboardContainer: IDashboardData;
	dashboardHistory: IDashboardHistoryInstance;
	dashboardFilters: {
		projectTimezone: string;
		projectDateFilters: {
			dateFilters?: any[];
		};
		personalization: any;
	};
	editMode: boolean;
	previewAsMode: boolean;
	projectAttributesFiltered: any[]; // need to move this into filters dropdown
	dismissAutoSaved: () => void;
	logoutStarted: boolean;
	widgetToolbarHidden: boolean;
	checkUnsavedChanges: (bodyText?: string) => Promise<void>;
	processSaveButton: () => Promise<void>;
	dashboardLoad: {
		promise?: ng.IPromise<any>;
	};
	dashboardMenu: any[];
	canEdit: () => boolean;
	shareClick: (dashboard) => void;
	changeToolbarState: () => void;
	hideWidgetToolbarOnZoom: () => boolean;
	resetCurrentDashboard: () => void;
	publishing: boolean;
	hierarchyAccessDenied: boolean;
	applyAttributeFilters: (isDashboardSaved: boolean) => void;
}

export class MainDashboardController {

	constructor(
		private readonly $scope: IMainDashboardCtrlScope,
		private readonly security: Security,
		private readonly $routeParams,
		private readonly $log: ng.ILogService,
		private readonly $location: ng.ILocationService,
		private readonly dashboardService: DashboardService,
		private readonly dashboardListService: DashboardListService,
		private readonly locale: ILocale,
		private readonly dashboardPropsService: DashboardPropsService,
		private readonly dashboardApiService: DashboardApiService,
		private readonly globalUnloadService: GlobalUnloadService,
		private readonly widgetsEditService: WidgetsEditService,
		private readonly dashboardUnsavedChanges: DashboardUnsavedChangesService,
		private readonly cbDialogService: CBDialogService,
		private readonly dashboardFiltersService: DashboardFiltersService,
		private readonly widgetApiService: WidgetApiService,
		private readonly navigationService: NavigationService,
		private readonly dashboardBookState: DashboardBookStateService,
		private readonly DashboardOptions,
		private readonly currentObjects: CurrentObjectsService,
		private readonly dashboardSaveState: DashboardSaveState,
		private readonly redirectService: RedirectService,
		private readonly environmentService: EnvironmentService,
		private readonly layoutHelperService: LayoutHelper
	) {
		if (this.environmentService.isIframe()) {
			this.$location.path(`/embed/dashboard/` + this.$routeParams.dashboardId);
			return;
		}
		this.globalUnloadService.addHandler('dashboard-edit',
			() => this.$scope.dashboardContainer.dashboardHistory?.isChanged()
				? this.showUnsavedChangesDialog()
				: Promise.resolve());

		this.$scope.processSaveButton = this.processSaveButton;
		this.$scope.applyAttributeFilters = this.applyAttributeFilters;
		this.$scope.changeEditMode = this.changeEditMode;
		this.$scope.checkUnsavedChanges = this.checkUnsavedChanges;
		this.$scope.shareClick = this.shareClick;
		this.$scope.changeToolbarState = this.changeToolbarState;
		this.$scope.hideWidgetToolbarOnZoom = this.hideWidgetToolbarOnZoom;
		this.$scope.canEdit = this.canEdit;
		this.$scope.processEditButton = this.processEditButton;
		this.$scope.processCancelButton = this.processCancelButton;
		this.$scope.returnToBook = this.returnToBook;
		this.$scope.isEditedFromBook = this.isEditedFromBook;
		this.$scope.resetCurrentDashboard = this.resetCurrentDashboard;
		this.$scope.dismissAutoSaved = this.dismissAutoSaved;

		this.$scope.dashboardLoad = {

		};

		//init always the last step
		this.initScope();
	}


	private initScope(): void {
		// object which contains dashboard instance. Temporarily moved from "current" until we convert this to a component
		// using "dashboardContainer" here and "dashboardData" at widget level for easier further changes
		this.$scope.dashboardContainer = {
			dashboard: undefined
		} as IDashboardData;

		if (!this.$routeParams.dashboardId) {
			this.handleMissingDashboard();
		}

		this.dashboardListService.reloadDashboardsOld();

		this.$scope.dashboardMenu = [];
		this.$scope.dashboardFilters = {} as any; // need to break this into pieces

		this.$scope.editMode = false;
		this.currentObjects.setEditMode(false);
		this.$scope.isAutoSaved = false;
		this.$scope.widgetToolbarHidden = false;

		this.$scope.exporting = {
			active: false
		};

		if (!this.$scope.projectAttributesFiltered) {
			this.$scope.projectAttributesFiltered = [];
		}

		this.$scope.hierarchyAccessDenied = false;

		//listeners
		this.$scope.$on('sharingPerformed', (event, dashboard, userSelfRemoval, userStillEditor) => {
			if (userSelfRemoval) {
				dashboard.permissions = {};
				this.dashboardListService.reloadDashboardsOld();
				this.processEditPermissionLoss(dashboard);
			} else if (!userStillEditor) {
				delete dashboard.permissions.EDIT;
				dashboard.permissions.VIEW = true;
				this.dashboardListService.updateDashboardsOld([dashboard]);
				this.processEditPermissionLoss(dashboard);
			}

			if (dashboard && !dashboard.createdByPinnedFeedback) {
				this.$scope.dashboardContainer.dashboard = dashboard;
			}
		});

		this.$scope.$on('changeWidgetsOwnerEvent', () => {
			this.refreshDashboardMenu();
		});

		this.$scope.$on('dashboard.hierarchyAccessUpdate', (event, denied: boolean, widgetsLoaded: boolean) => {
			this.$scope.hierarchyAccessDenied = denied;
			if (!denied) {
				return;
			}

			if (this.$scope.editMode) {
				if(!widgetsLoaded) {
					this.changeEditMode(false);
					return;
				}

				if (this.widgetsEditService.isSaving()) {
					this.$log.log('Waiting for widgets to save before processing "Save" button.');
					this.$scope.$watch(() => this.widgetsEditService.isSaving(), (value) => {
						if (!value) {
							this.$log.log('Finished waiting for widgets to save. Processing "Save" button.');
							this.$scope.processSaveButton();
						}
					});
				} else {
					this.$log.log('Widgets are not saving, processing "Save" button.');
					this.$scope.processSaveButton();
				}
			}
		});

		this.$scope.$on('createDashboardFromWidgetsEvent', (event, newDashboardName, newWidgets) => {
			this.$scope.dashboardContainer.dashboard.appliedFiltersArray = this.$scope.dashboardContainer.dashboardHistory.getPersistentFilters();
			this.$scope.dashboardLoad.promise = this.dashboardService
				.createPredefinedDashboard(newWidgets, newDashboardName, this.$scope.dashboardContainer.dashboard)
				.then((data) => {
					this.$location.path('/home/' + data.id);
				});
		});

		this.$scope.hierarchyAccessDenied = true;

		this.$scope.$on('dashboardHierarchyToggleEvent', () => {
			this.$scope.hierarchyAccessDenied = false;
		});

		this.$scope.$on('$destroy', () => {
			this.globalUnloadService.removeHandler('dashboard-edit');
		});

		this.checkAutosaved(this.$scope.dashboardContainer.dashboard);
	}

	private handleMissingDashboard(): void {
		if (this.security.isRedirectToAnalyzeRequested()) {
			return;
		}
		this.getAllDashboardsAndRedirect();
	}

	private getAllDashboardsAndRedirect = () => {
		this.dashboardListService.reloadDashboardsOld().then((dashboardsTree) => {
			if (!_.isUndefined(dashboardsTree)) {
				let lastdashboardId = this.security.getContext().lastDashboardId;
				if (lastdashboardId > 0) {
					this.selectDefaultDashboard();
				} else {
					this.redirectService.goToDashboardList();
				}
			} else {
				this.redirectService.goToDashboardList();
			}
		});
	};

	private selectDefaultDashboard(): void {
		let selected = null;
		let fromUrl = this.$routeParams.dashboardId;
		let urlId;
		if (fromUrl) {
			urlId = parseInt(fromUrl, 10);
		}
		if (!urlId) {
			urlId = this.security.getContext().lastDashboardId;
		}

		if (urlId) {
			selected = _.findWhere(this.dashboardListService.getCurrentDashboardsList(),
				{type: DashboardType.DASHBOARD, id: urlId});
		}
		if (!isEmpty(selected)) {
			this.navigationService.changeDashboard(selected, false);
		}
		if (isEmpty(this.$scope.dashboardContainer.dashboard) || isEmpty(selected)) {
			this.redirectService.goToDashboardList();
		}

	}

	shareClick = () => {
		this.dashboardService.shareDashboard(this.$scope.dashboardContainer.dashboard,
			this.$scope.editMode, this.dashboardSaveState.versionId);
	};

	changeToolbarState = () => {
		this.$scope.widgetToolbarHidden = !this.$scope.widgetToolbarHidden;
		this.refreshDashboardMenu();
	};

	hideWidgetToolbarOnZoom = () => this.layoutHelperService.isZoomModeOn();

	canEdit = () => {
		if (!this.$scope.dashboardContainer.dashboard) {
			return undefined; // one-time binding
		}
		if (!this.security.has('edit_dashboard')) {
			return false;
		}
		if (this.$scope.hierarchyAccessDenied) {
			return false;
		}
		let p = this.$scope.dashboardContainer.dashboard.permissions;
		return p.OWN || p.EDIT;
	};

	processEditButton = () => {
		this.dashboardApiService.getMostRecentSavedVersionTime(this.$scope.dashboardContainer.dashboard.id).then((response) => {
			if (response.data > this.$scope.dashboardContainer.dashboard.modifiedDate) {
				this.$scope.dashboardContainer.dashboard.modifiedDate = response.data;
				this.reloadWidgets();
			}

		});
		this.$scope.dismissAutoSaved();
		this.changeEditMode(true);
		this.$scope.$broadcast('dashboardSaveInitialStateEvent');
	};

	private processEditPermissionLoss(dashboard): void {
		this.$scope.dashboardContainer.dashboardHistory.reset(dashboard);
		this.$scope.editMode = false;
		this.globalUnloadService.removeHandler('dashboard-edit');
		this.redirectService.goToDashboardList();
	}

	processCancelButton = (): void => {
		if (this.widgetsEditService.isSaving()) {
			return;
		}

		this.dashboardFiltersService.removeInvalidFilters(this.$scope.dashboardContainer.dashboard);
		this.checkChanges(false).then(() => {
			this.changeEditMode(false);
			this.returnToBook();
		}, _.noop);
	};

	returnToBook = (): void => {
		if (this.$scope.isEditedFromBook()) {
			this.$location.path('/dashboard/' + this.dashboardBookState.getEditedFromBook())
				.search({ tab: this.$scope.dashboardContainer.dashboard.id });
		}
	};

	isEditedFromBook = (): boolean => {
		return this.dashboardBookState.isEditedFromBook() &&
			(this.dashboardBookState.getEditedDashboard() === this.$scope.dashboardContainer.dashboard.id);
	};

	processSaveButton = (): Promise<void> => {
		if (this.widgetsEditService.isSaving()) {
			return Promise.reject();
		}
		this.dashboardFiltersService.removeInvalidFilters(this.$scope.dashboardContainer.dashboard);

		if (this.$scope.dashboardContainer.dashboard.properties.isAttributeFiltersApplied) {
			this.$scope.dashboardContainer.dashboardHistory.saveFilterUpdates(this.$scope.dashboardContainer.dashboard.appliedFiltersArray);
		}
		this.$scope.applyAttributeFilters(true);
		return this.saveChanges().then(() => {
			this.dashboardPropsService.commitUpdates();
			this.$scope.$broadcast(DashboardEvent.SAVE);
		});
	};

	applyAttributeFilters = (isDashboardSaved: boolean) => {
		// must check for exact equality to false. can't allow this to be triggered by undefined
		if (this.$scope.dashboardContainer.dashboard.properties.isAttributeFiltersApplied === false) {
			if (isDashboardSaved) {
				this.$scope.dashboardContainer.dashboardHistory.saveFilterUpdates(this.$scope.dashboardContainer.dashboard.appliedFiltersArray);
			}

			this.$scope.dashboardContainer.dashboard.properties.isAttributeFiltersApplied = true;
			this.$scope.dashboardContainer.dashboardHistory.setAppliedFilters(this.$scope.dashboardContainer.dashboard.appliedFiltersArray);
			this.dashboardService.refreshDashboard();
		}
	};

	private changeEditMode = (editMode: boolean): void => {
		let reload = false;
		if (editMode && !this.$scope.editMode && (this.$scope.dashboardContainer.dashboardHistory.isAppliedFiltersUnsaved()
				|| this.$scope.dashboardContainer.dashboardHistory.isViewFilterChanged())) {
			this.$scope.dashboardContainer.dashboard.appliedFiltersArray = this.$scope.dashboardContainer.dashboardHistory.getLastFilterSave();
			reload = true;
		}

		this.$scope.dashboardContainer.dashboardHistory.clear();

		if (reload) {
			this.reloadWidgets();
		}

		this.$scope.editMode = editMode;
		this.currentObjects.setEditMode(editMode);
		this.$location.search('edit', this.$scope.editMode ? true : null);
		this.refreshDashboardMenu();
	};

	checkChanges = (editMode: boolean): Promise<void> => {
		if (this.$scope.dashboardContainer.dashboardHistory.isChanged()) {
			return this.showUnsavedChangesDialog();
		} else {
			this.$scope.dashboardContainer.dashboard.appliedFiltersArray = this.$scope.dashboardContainer.dashboardHistory.getLastFilterSave();
			if (this.$scope.dashboardContainer.dashboardHistory.isAppliedFiltersChanged(
				this.$scope.dashboardContainer.dashboard.appliedFiltersArray)) {
				this.$scope.dashboardContainer.dashboard.properties.isAttributeFiltersApplied = false;
				this.$scope.applyAttributeFilters(false);
			}

			if (this.dashboardSaveState.versionId > 0) {
				delete this.dashboardSaveState.versionId;
			}

			if (!editMode) {
				this.$scope.$broadcast('dashboard:cancel');
			}
			return Promise.resolve();
		}
	};

	resetCurrentDashboard = (): void => {
		this.$scope.dashboardContainer = {} as IDashboardData;
	};

	saveChanges(): Promise<void> {
		if (!this.dashboardUnsavedChanges.isDashboardNameUnique()) {
			this.cbDialogService.notify(this.locale.getString('common.warning'),
				this.locale.getString('dashboard.dashboardUniqueWarning'));
			return Promise.reject();
		}

		return this.performFullDashboardSave();
	}

	private performFullDashboardSave(): Promise<void> {
		this.widgetsEditService.startSaving();

		return this.saveDashboardName().then(() => {
			return this.saveWidgets();
		});
	}

	private saveDashboardName(): Promise<any> {
		return PromiseUtils.wrap(this.dashboardService.applyDashboardUpdates(this.$scope.dashboardContainer.dashboard,
			{ name: this.dashboardPropsService.current.name }, false));
	}

	private saveWidgets(): Promise<any> {
		let widgetSaveData = this.widgetsEditService.getWidgetsSaveData();

		this.$scope.dashboardLoad.promise = PromiseUtils.old(this.widgetApiService.saveWidgets(
			this.$scope.dashboardContainer.dashboard.id, widgetSaveData))
			.then(() => this.changeEditMode(false)).finally(this.widgetsEditService.finishSaving);
		return PromiseUtils.wrap(this.$scope.dashboardLoad.promise);
	}

	private reloadWidgets(): void {
		this.$scope.$broadcast(DashboardEvent.RELOAD);
	}

	private refreshDashboardMenu(): void {
		// generate menu depending on permissions
		this.$scope.dashboardMenu = new this.DashboardOptions(this.$scope).get(this.$scope.dashboardContainer.dashboard);
	}

	private rollbackDashboard(): Promise<void> {
		this.$scope.$broadcast('dashboard:cancel');

		if (this.canEdit() && this.dashboardPropsService.getRedoUndo()) {
			this.dashboardService.applyUpdates(this.$scope.dashboardContainer.dashboard, this.dashboardPropsService.firstInitState, true, false)
				.then((data) => {
					this.dashboardPropsService.updateDashboardProps(data);
					this.dashboardPropsService.revertInitialUpdates();
			});
		} else {
			this.dashboardPropsService.revertUpdates();
		}

		this.$scope.dashboardContainer.dashboard.appliedFiltersArray = this.$scope.dashboardContainer.dashboardHistory.getLastFilterSave();
		this.$scope.dashboardContainer.dashboardHistory.clear();
		this.$scope.dashboardContainer.dashboard.properties.isAttributeFiltersApplied = false; //apply once set reverted widgets
		this.widgetsEditService.startSaving();
		let versionId = this.dashboardSaveState.versionId;
		delete this.dashboardSaveState.versionId;
		return this.widgetApiService.restoreWidgetsTempState(this.$scope.dashboardContainer.dashboard.id, versionId)
			.then((widgets) => {
				this.changeEditMode(false);
				this.widgetsEditService.finishSaving();
				this.$scope.$broadcast(DashboardEvent.RESET_WIDGETS, widgets);
				this.$scope.$broadcast('dashboardRollbackFinished');
			}, this.widgetsEditService.finishSaving);
	}

	private showUnsavedChangesDialog(bodyText?: string): Promise<void> {
		return this.dashboardUnsavedChanges.showUnsavedChangesDialog(bodyText).then(save => {
			if (save) {
				let promise = this.saveChanges();
				this.$scope.dashboardContainer.dashboardHistory.saveFilterUpdates(this.$scope.dashboardContainer.dashboard.appliedFiltersArray);
				this.applyAttributeFilters(false);
				return promise;
			} else {
				// prompted to save but not saved
				return this.rollbackDashboard();
			}
		});
	}

	checkUnsavedChanges = (bodyText?: string): Promise<void> => {
		if (this.$scope.dashboardContainer.dashboardHistory?.isChanged()) {
			return this.showUnsavedChangesDialog(bodyText);
		} else {
			return Promise.resolve();
		}
	};

	private checkAutosaved = (dashboard): void => {
		// move to independent component, like concurrent-edit
		if (dashboard && !this.$scope.editMode && dashboard.permissions.OWN) {
			let dashboardCall = this.dashboardApiService.isAutosaved(dashboard.id);
			dashboardCall.then((autosaved) => {
				this.$scope.isAutoSaved = autosaved;
			});
		}
	};

	dismissAutoSaved = (): void => {
		this.$scope.isAutoSaved = false;
	};
}

app.controller('MainDashboardCtrl', MainDashboardController as any);
