import { TableColumn } from '@cxstudio/reports/entities/table-column';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';

import { Injectable, Inject } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';


@Injectable()
export class ComponentsColumnsProvider {

	constructor(
		private readonly locale: CxLocaleService,
		@Inject('tableFormattersService') private readonly tableFormattersService: TableFormattersService,
	) {}

	getColumns = (type: ObjectType): Array<TableColumn<any>> => {
		let columns: Array<TableColumn<any>> = [];
		columns.push({
			name: 'name',
			displayName: this.locale.getString('common.name'),
			formatter: this.defaultFormatter,
			width: 0.3
		});
		if (type === ObjectType.FILTER) {
			columns.push({
				name: 'filterType',
				displayName: this.locale.getString('common.type'),
				formatter: this.defaultFormatter
			});
		}
		columns.push({
			name: 'owner',
			displayName: this.locale.getString('common.owner'),
			formatter: this.defaultFormatter,
			width: 0.4
		});
		columns.push({
			name: 'usagesCount',
			displayName: this.locale.getString('dashboard.widgetCount'),
			formatter: this.dashboardFilterFormatter,
			align: 'center'
		});
		return columns;
	};

	getBookColumns = (): Array<TableColumn<any>> => {
		let columns: Array<TableColumn<any>> = [];

		columns.push({
			name: 'name',
			displayName: this.locale.getString('dashboard.dashboardName'),
			formatter: this.defaultFormatter,
			width: 0.3
		});

		columns.push({
			name: 'owner',
			displayName: this.locale.getString('common.owner'),
			formatter: this.defaultFormatter,
			width: 0.3
		});

		columns.push({
			name: 'lastRun',
			displayName: this.locale.getString('dashboard.lastRun'),
			formatter: this.tableFormattersService.dateFormatter,
			width: 0.2
		});

		columns.push({
			name: 'status',
			displayName: this.locale.getString('dashboard.status'),
			formatter: this.tableFormattersService.statusFormatter,
			align: 'center',
			width: 0.2
		});

		return columns;
	};

	private defaultFormatter = (object: any, field: string): string => {
		return object[field];
	};

	private dashboardFilterFormatter = (object: any, field: string): string => {
		const value = object[field];
		return (value === -1) ? this.locale.getString('dashboard.dashboardWidgetCount') : value;
	};
}

app.service('componentsColumnsProvider', downgradeInjectable(ComponentsColumnsProvider));
