import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { WidgetSettingsUtils } from '@app/modules/widget-settings/settings/widget-settings-utils.class';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DEFAULT_DATE_FILTER_MODE, HistoricDateFilterMode } from '@cxstudio/reports/entities/date-filter-mode';
import { DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import VisualProperties from '@cxstudio/reports/entities/visual-properties';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ReportMetricService } from '@cxstudio/reports/metrics/report-metric-service';
import { KeyMetricListTypes } from '@cxstudio/reports/providers/cb/definitions/key-metric-list-types.constant';
import { IWidgetSettingsOptions, IWidgetSettingsScope, IWidgetSettingsUI } from '@cxstudio/reports/providers/cb/services/cb-settings-service.service';
import { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { MetricConstants } from '../constants/metric-constants.service';

export class TableDefinitionComponent implements ng.IComponentController, IWidgetSettingsScope {

	constants = this.metricConstants.get();
	ui: IWidgetSettingsUI;
	visualProps: VisualProperties;
	props: WidgetProperties;
	options: IWidgetSettingsOptions;
	widget: Widget;
	staticData: any;
	documentLevelOnly: boolean;
	reloadSettings: () => void;
	broadcastSettings: (broadcastEvent: string) => void;
	updateAutoTitle: () => void;

	maxCalculations: number = 10;
	maxGroupings: number = 5;
	maxPoPGrouping: number = 3;
	defaultRows: number = 10;
	groupingsError: string;
	groupingDataPointsError: string;

	dragType: string;

	cb_an_table_default_options = {
		layouts: [{
			name: 'compact',
			displayName: this.locale.getString('widget.compactSpacing')
		}, {
			name: 'comfort',
			displayName: this.locale.getString('widget.comfortableSpacing')
		}]
	};

	listTypes = KeyMetricListTypes;

	calculationsError: string = this.locale.getString(
		'widget.calculationsLimitError', {
			limit: this.maxCalculations,
			details: this.locale.getString('widget.popNotCountTowardLimit')
		});

	//methods defined in child controller. will refactor in the future.
	initLists: () => void;
	setPrimaryTimeGrouping: (timeGrouping) => void;
	initializePeriods: () => void;
	changePop: (pop: boolean) => void;
	onMetricUpdate: (metric) => void;
	applyVisualChanges: () => void;
	addLoadingPromise: (promise: ng.IPromise<any>) => ng.IPromise<any>;
	populateCurrentHierarchyCalculations: (metrics: any, grouping: any, calculations: any, models?: any) => void;
	reloadCommonSettings: (config: any) => ng.IPromise<IWidgetSettings>;


	// have to do this because not everything using embedded controllers are component-ized yet :(
	$watch = this.$scope.$watch;
	$watchCollection = this.$scope.$watchCollection;
	$emit = this.$scope.$emit;

	constructor(
		private $scope,
		private $controller,
		private $log,
		private locale: ILocale,
		private metricConstants: MetricConstants,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private reportMetricService: ReportMetricService,
		private betaFeaturesService: BetaFeaturesService
	) { }

	$onInit = () => {

		this.$controller('AnalyticDefinitionMultiSelectionController', {
			$scope: this,
			multiSelectionConfiguration: {
				withAttributeSelection: true,
				withMetricSelection: true,
				withDocumentLevelOnly: this.documentLevelOnly,
				max: {
					calculations: this.maxCalculations,
					groupings: this.maxGroupings,
					popGroupings: this.maxPoPGrouping
				},
				calculationCountingFilter: (item) => !item.isPopMetric
			}
		});

		this.$scope.$on('clearSettings', this.initProps);
		this.$scope.$on('reloadSettings', this.reloadSettings);

		this.$scope.$watchCollection(() => this.props.selectedAttributes, (newValue, oldValue) => {
			if (newValue === oldValue) return;
			if (!this.props.isCustomTitle)
				this.updateAutoTitle();
			if (!this.showNormalizeGroupsButton()) {
				this.props.normalizeGroups = undefined;
			} else if (this.isForceNormalization()) {
				this.props.normalizeGroups = this.getForcedNormalizeGroupsValue();
			} else if (this.props.normalizeGroups == null) {
				this.props.normalizeGroups = !!this.props.primaryTimeGrouping;
			}

		});

		this.groupingDataPointsError = this.$scope.groupingDataPointsError;
		this.ui = this.ui || {};

		this.resetOptions();

		this.ui.popSelectionEnabled = true;

		if (!this.visualProps.periodLabel) {
			this.visualProps.periodLabel = {};
		}

		//initialize default layout for table
		if (_.isUndefined(this.visualProps.layout)) {
			this.visualProps.layout = 'compact';
		}

		if (isEmpty(this.visualProps.showSampleSize)) {
			this.visualProps.showSampleSize = true;
		}

		this.ui.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1,
			defaultMode: DEFAULT_DATE_FILTER_MODE
		}, {
			field: DatePeriodField.PERIOD2,
			name: DatePeriodName.PERIOD2,
			defaultMode: HistoricDateFilterMode.PREVIOUS_PERIOD
		}];

		this.ui.periodOptions = {
			historic: {}
		};

		this.initPoP();
		this.updateGroupingLimitError();
	};

	$on = (event: string, handler: () => void): () => void => {
		return this.$scope.$on(event, handler);
	};

	onWidgetMetricConfigured = (metric) => {
		if (this.onMetricUpdate) {
			this.onMetricUpdate(metric);
		}
		this.broadcastSettings('redrawDemoTable');
	};

	private updateGroupingLimitError = (): void => {
		this.groupingsError = this.locale.getString('widget.groupingsLimitError', {
			limit: this.props.useHistoricPeriod ? this.maxPoPGrouping : this.maxGroupings
		});
	};

	updatePoP = (pop: boolean): void => {
		this.updateGroupingLimitError();
		this.changePop(pop);
	};

	private resetOptions = () => {
		angular.extend(this.options, this.cb_an_table_default_options);
	};

	private initProps = () => {
		this.$log.debug('init props');
		this.props.selectedAttributes = [];
		this.props.selectedMetrics = [];
		this.options.additionalMetrics = this.options.additionalMetrics
			|| this.optionsBuilderProvider.getBuilder(OptionsConstant.CALCULATION)
				.withStandardMetrics(this.metricConstants.getStandardCalculations())
				.withDocumentLevelOnly(this.documentLevelOnly)
				.build();
		this.options.cogSortByMetrics = angular.copy(this.options.additionalMetrics);
		this.initLists();
	};

	private initPoP = () => {
		if (this.props.primaryTimeGrouping) {
			this.setPrimaryTimeGrouping(this.props.primaryTimeGrouping);
		}
		this.initializePeriods();

		if (_.isUndefined(this.visualProps.periodLabel[this.ui.periods[1]
			.name])) {
			this.visualProps.periodLabel[this.ui.periods[1].name] = this.locale.getString(
				'widget.historic');
		}

		if (_.isUndefined(this.visualProps.periodLabel[this.ui.periods[0]
			.name])) {
			this.visualProps.periodLabel[this.ui.periods[0].name] = '';
		}
	};

	// store the type of the item currently being dragged
	setDragType = (type: string): void => { this.dragType = type; };

	// check the type of the item currently being dragged -- prevents cross-list dragging
	isDragType = (type: string): boolean => _.isUndefined(this.dragType) || (this.dragType === type);

	requiresAtLeastOneMetric = (): boolean => false;

	hasGroupingConfig = (grouping): boolean => WidgetSettingsUtils.hasGroupingConfig(this.props, grouping);

	isAdminProject = (): boolean => InternalProjectTypes.isAdminProject(this.props.project);

	getDisplayName = (item): string => this.reportMetricService.getTopicLevelString(item) || item.displayName;

	showNormalizeGroupsButton = () => {
		return this.betaFeaturesService.isFeatureEnabled(BetaFeature.VALUE_NORMALIZATION)
			&& (this.props.selectedAttributes?.length > 1)
			&& AnalyticMetricTypes.hasCustomGroups(this.props.selectedAttributes);
	};

	isForceNormalization = () => {
		return this.isForceDisabledNormalization() || this.isForceEnabledNormalization();
	};

	getForcedNormalizeGroupsValue = () => {
		if (this.isForceDisabledNormalization()) {
			return false;
		}
		return true;
	};

	isForceEnabledNormalization = () => {
		return AnalyticMetricTypes.isForcedGroupNormalization(this.props.selectedAttributes);
	};

	isForceDisabledNormalization = () => {
		return AnalyticMetricTypes.isForcedDisabledGroupNormalization(this.props.selectedAttributes);
	};
}

app.component('tableDefinition', {
	bindings: {
		ui: '<',
		widget: '<',
		visualProps: '<',
		props: '<',
		options: '<',
		reloadSettings: '<',
		broadcastSettings: '<',
		loadingPromise: '<',
		addLoadingPromise: '<',
		clearErrors: '<',
		updateAutoTitle: '<',
		dashboardFilters: '<',
		isInheritingDashboardDateFilter: '<',
		documentLevelOnly: '<',
		intersectionObserver: '<'
	},
	controller: TableDefinitionComponent,
	templateUrl: 'partials/widgets/settings/table-definition.component.html'
});
