import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { FolderTypes } from '@cxstudio/folders/folder-types-constant';
import { GridContext } from '@cxstudio/grids/grid-context-constant';
import { MenuItemDef } from 'ag-grid-enterprise';
import { DashboardListService } from '../../dashboard-list.service';
import { AgGridCommonActionsService } from '../actions/ag-grid-common-actions.service';
import { AgGridDashboardBulkActionsService } from '../actions/ag-grid-dashboard-bulk-actions.service';
import { AgGridDashboardFolderActionsService } from '../actions/ag-grid-dashboard-folder-actions.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';
import { HtmlUtils } from '@app/shared/util/html-utils.class';

@Injectable({
	providedIn: 'root'
})
export class MoveToOptionService {

	private folderService;

	constructor(
		private readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		private readonly agGridCommonActionsService: AgGridCommonActionsService,
		private readonly agGridDashboardFolderActionsService: AgGridDashboardFolderActionsService,
		private readonly agGridDashboardBulkActionsService: AgGridDashboardBulkActionsService,
		@Inject('FolderService') private readonly FolderService,
	) {
		this.initServices();
	}

	private initServices(): void {
		this.folderService = new this.FolderService(FolderTypes.DASHBOARD);
	}

	get(subMenu: any[]): MenuItemDef {
		return {
			name: this.locale.getString('dashboard.move'),
			subMenu
		};
	}

	getSubMenu(item: any): (string | MenuItemDef)[] {
		let folders: IFolderItem[] = this.getFoldersForMove(item);

		let itemType: GridContext = item.type;

		return _.flatten(
			_.map(folders, (folder) => {
				let action = itemType === GridContext.DASHBOARD || itemType === GridContext.DASHBOARD_SET
					? () => this.agGridCommonActionsService.moveToFolder(item, folder)
					: () => this.agGridDashboardFolderActionsService.moveToFolder(item, folder);
				return this.getMoveToSubmenuOption(folder, action);
		}));
	}

	private getFoldersForMove(item: any): IFolderItem[] {
		return this.folderService.getPlainFoldersForMove(this.dashboardListService.getCurrentDashboardsList(), item);
	}

	getBulkSubMenu(selectedObjects: Dashboard[]): any[] {
		let folders = this.getBulkFoldersForMove(selectedObjects);

		return _.flatten(
			_.map(folders, (folder) => {
				let action = () => this.agGridDashboardBulkActionsService.moveTo(selectedObjects, folder);
				return this.getMoveToSubmenuOption(folder, action);
		}));
	}

	private getBulkFoldersForMove(selectedObjects: Dashboard[]): IFolderItem[] {
		return _.uniq(
			_.flatten(
				_.map(selectedObjects, (selectedObject) => this.getFoldersForMove(selectedObject))
			),
			false,
			(moveItem) => moveItem.name
		);
	}

	private getMoveToSubmenuOption(folder: any, action: () => void) : (string | MenuItemDef) | (string | MenuItemDef)[] {
		if (this.isRootFolder(folder)) {
			return [
				DashboardGridHelperService.MENU_OPTIONS_SEPARATOR,
				{
					name: this.locale.getString('common.moveToRoot'),
					action
				}
			];
		} else {
			return {
				name: HtmlUtils.escapeHtml(folder.name),
				action
			};
		}
	}

	private isRootFolder(folder: any): boolean {
		return folder.id === 0;
	}
}
