import { PeriodOverPeriodMetric } from './period-over-period-metric';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { CxLocaleService } from '@app/core';
import { PeriodOverPeriodMetricType } from './period-over-period-metric-type';
import { MetricDefinition } from '@cxstudio/metrics/entities/metric-definition';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { FilterMetricDefinition } from '@cxstudio/metrics/entities/filter-metric-definition.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';
import { MetricCalculationsTypeService } from '@cxstudio/metrics/metric-calculations-type.service';

export class PercentChangeMetric extends PeriodOverPeriodMetric {
	constructor(
		private locale: CxLocaleService,
		private MetricCalculationsType: MetricCalculationsTypeService,
		private percent100FormatterBuilder
	) {
		super();
	}

	getType = (): PeriodOverPeriodMetricType => {
		return PeriodOverPeriodMetricType.PERCENT_CHANGE;
	};
	getNamePrefix(): string {
		return 'percentChange_';
	}
	getDisplayName = (parentMetricDisplayName: string): string => {
		return `${this.locale.getString('widget.percentChange')} ${parentMetricDisplayName}`;
	};
	isSupported = (metric: ReportCalculation, metricList: Metric[]): boolean => {
		return !(this.isSentimentMetric(metric)
			|| this.isEaseMetric(metric)
			|| this.isEmotionalIntensity(metric)
			|| this.MetricCalculationsType.SATISFACTION.is(metric)
			|| this.isFilteredMetricBasedOnUnsupportedCalculation(metric, metricList));
	};
	applyFormatting = (metric: ReportCalculation): ReportCalculation => {
		metric.useDefaultFormat = false;
		angular.extend(metric, this.percent100FormatterBuilder.getDefaults());
		return metric;
	};

	private isSentimentMetric(metric: ReportCalculation): boolean {
		return metric.name === PredefinedMetricConstants.SENTIMENT;
	}
	private isEaseMetric(metric: ReportCalculation): boolean {
		return metric.name === PredefinedMetricConstants.EASE_SCORE;
	}
	private isEmotionalIntensity(metric: ReportCalculation): boolean {
		return metric.name === PredefinedMetricConstants.EMOTION;
	}
	private isFilteredMetricBasedOnUnsupportedCalculation(metric: ReportCalculation, metricList: Metric[]): boolean {
		//First check if its filter metric
		if (!this.MetricCalculationsType.FILTER_METRIC.is(metric)) {
			return false;
		}
		let unsupportedCalculations: string[] = [
			PredefinedMetricConstants.SENTIMENT,
			PredefinedMetricConstants.EASE_SCORE,
			PredefinedMetricConstants.EMOTION
		];
		if (this.isFilterMetricDefinition(metric.definition)) {
			if (metric && metric.definition
					&& unsupportedCalculations.contains(metric.definition.calculationName)) {
				return true;
			}
			//Then check if metric is based on other metric that is sat
			let otherMetricName = metric.definition.calculationName;
			if (metricList && metricList.length) {
				let otherMetric = _.find(metricList, (item) => item.name === otherMetricName);
				return otherMetric && this.MetricCalculationsType.SATISFACTION.is(otherMetric);
			}
		}
		return false;
	}

	private isFilterMetricDefinition(definition: MetricDefinition): definition is FilterMetricDefinition {
		return definition?.type === MetricType.FILTER;
	}
}

app.service('percentChangeMetric', PercentChangeMetric);
