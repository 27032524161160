import { Inject } from '@angular/core';
import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { FiltersService } from '@app/modules/filter/services/filters.service';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { PredefinedFiltersService } from '@cxstudio/metrics/predefined/predefined-filters.service';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import IFilter from '@cxstudio/report-filters/entity/filter';
import { ScorecardFiltersService } from '@app/modules/scorecards/filters/scorecard-filters.service';
import { IScorecardFilterListItem } from '@app/modules/scorecards/entities/scorecard-filter-list-item';
import { ReportScorecardFiltersService } from '@app/modules/scorecards/filters/report-scorecard-filters.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';

export interface FiltersGroup {
	label: string;
	list: (FilterGroupItem | ScorecardFilterGroupItem)[];
}

export interface FilterGroupItem {
	type: FilterTypes;
	filterId: string;
	name: string;
	hide: boolean;
}

export interface ScorecardFilterGroupItem {
	type: FilterTypes;
	id: string;
	name: string;
	hide: boolean;
	passing: boolean;
	scorecardId: number;
}

@Injectable({
	providedIn: 'root'
})
export class CombinedFiltersService {
	constructor(
		private readonly locale: CxLocaleService,
		private readonly filtersService: FiltersService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly scorecardFiltersService: ScorecardFiltersService,
		private readonly reportScorecardFiltersService: ReportScorecardFiltersService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('predefinedFiltersService') private predefinedFiltersService: PredefinedFiltersService, //consider for NG
	) {}

	getWidgetFilters(widget: Widget): Promise<FiltersGroup[]> {
		const project = this.reportAssetUtilsService.getWidgetProject(widget);

		let promises = [];
		promises.push(this.reportFiltersService.getWidgetPlatformFilters(widget));
		promises.push(this.reportFiltersService.getWidgetStudioFilters(widget));
		promises.push(this.reportScorecardFiltersService.getWidgetScorecardFilters(widget));
		return this.loadWidgetFilters(project, promises);
	}

	getProjectFilters(project: AccountOrWorkspaceProject): Promise<FiltersGroup[]> {
		if (!WorkspaceTransitionUtils.isProjectSelected(project)) {
			return Promise.resolve([]);
		}
		let promises = [];
		promises.push(this.filtersService.getPlatformFilters(project));
		promises.push(this.filtersService.getStudioFilters(project));
		promises.push(this.scorecardFiltersService.getScorecardFilters(project));
		return this.loadWidgetFilters(project, promises);
	}

	private loadWidgetFilters(project: AccountOrWorkspaceProject, promises: Promise<any>[]): Promise<FiltersGroup[]> {
		return Promise.all(promises).then((responses: any) => {
			let platformFilters = responses[0];
			let studioFilters = responses[1];
			let scorecardFilters = responses[2];

			return this.convertAndGroupFilters(platformFilters, studioFilters, scorecardFilters, project.projectId);
		});
	}

	convertAndGroupFilters = (platformFilters: any, studioFilters: any, scorecardFilters: any, projectId: number): FiltersGroup[] => {
		let returnArray = [];

		if (!_.isEmpty(platformFilters)) {
			platformFilters = this.convertFilters(platformFilters, FilterTypes.CMP);
			platformFilters = this.predefinedFiltersService.removePredefinedAnalyzeFilters(platformFilters);
			returnArray.push({
				label: this.locale.getString('widget.analyzeFilters'),
				list: platformFilters
			});
		}

		studioFilters = this.convertFilters(studioFilters, FilterTypes.STUDIO);
		returnArray.push({
			label: this.locale.getString('widget.studioFilters'),
			list: studioFilters
		});

		if (!InternalProjectTypes.isAdminProject(projectId)) {
			let predefinedFilters = this.predefinedFiltersService.getAllFilters();
			returnArray.push({
				label: this.locale.getString('widget.predefinedFilters'),
				list: predefinedFilters
			});
		}

		if (!_.isEmpty(scorecardFilters)) {
			scorecardFilters = this.convertScorecardFilters(scorecardFilters);
			returnArray.push({
				label: this.locale.getString('filter.scorecardFilters'),
				list: scorecardFilters
			});
		}

		return returnArray;
	};

	convertFilters = (filterList: IFilter[], type: FilterTypes): FilterGroupItem[] => {
		if (_.isEmpty(filterList)) {
			return [];
		}

		return filterList.map((filter) => {
			return {
				type,
				filterId: filter.id.toString(),
				name: filter.name,
				hide: filter.hide
			};
		});
	};

	convertScorecardFilters = (filterList: IScorecardFilterListItem[]): ScorecardFilterGroupItem[] => {
		if (_.isEmpty(filterList)) {
			return [];
		}

		return filterList.map((filter) => {
			return {
				type: FilterTypes.SCORECARD,
				id: filter.uniqueId,
				name: filter.name,
				hide: filter.disabled,
				passing: filter.passing,
				scorecardId: filter.scorecardId
			};
		});
	};
}

app.service('combinedFiltersService', downgradeInjectable(CombinedFiltersService));
