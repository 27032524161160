import { Inject, Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { CxHttpService } from '@app/core';
import { UrlService } from '@cxstudio/common/url-service.service';
import { Phrase } from '../explorer-automated-summaries/automated-summaries-utils.class';

type AutomatedCallSummaryResponse = {
	summary: string;
};

export type AutomatedCallSummaryPostCallBody = {
	phrases: Phrase[];
	language?: string;
	sourceType?: string;
	uniqueDocumentIdentifier: string;
};

@Injectable({
	providedIn: 'root'
})
export class AutomatedCallSummaryApiService {
	constructor(
		private readonly http: CxHttpService,
		@Inject('urlService') private readonly urlService: UrlService
	) { }

	generateAutomatedCallSummary = (body: AutomatedCallSummaryPostCallBody): Observable<AutomatedCallSummaryResponse> => {
		return from(
			this.http.post<AutomatedCallSummaryResponse>(
				this.urlService.getAPIUrl(`rest/summarize`),
				body
			)
		);
	};
}
