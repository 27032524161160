import { Component, ElementRef } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { KeyboardSelector } from '@app/shared/util/keyboard-selector.enum';
import { BypassModalBlockHelper, NavigableModal } from './bypass-modal-block-helper.service';


@Component({
	selector: 'bypass-modal-block',
	template: `<div id="bypass-modal-block" *ngIf="showBypassModalBlock()"
					(click)="skipToMainContent($event)"
					(keydown.enter)="skipToMainContent($event)">
					<a class="p-8 m-8" href="javascript:void(0)">{{'common.skipToMainContent'|i18n}}</a>
				</div>`
})
export class BypassModalBlockComponent {

	constructor(
		private elementRef: ElementRef
	) {}

	showBypassModalBlock(): boolean {
		return BypassModalBlockHelper.showBypassModalBlock();
	}
	
	skipToMainContent(event: UIEvent): void {
		event.stopPropagation();
		event.preventDefault();
		let currentModal: NavigableModal = BypassModalBlockHelper.getCurrentModal();
		let modalElement = this.elementRef.nativeElement.parentElement;
		setTimeout(() => 
			$(modalElement)
				.find(currentModal.mainContentSelector)
				.not(KeyboardSelector.NEGATIVE_TAB_INDEX)
				.first()
				.trigger('focus')
		);
	}
}

app.directive('bypassModalBlock', downgradeComponent({component: BypassModalBlockComponent}));