import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { AgGridDashboardContextMenuService } from '@app/modules/dashboard-list/context-menu/ag-grid-dashboard-context-menu.service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { NoEditorToggle } from '@app/modules/object-list/cell-editors/boolean-editor.component.ts/no-editor-toggle.class';
import { FavoriteRendererComponent } from '@app/modules/object-list/cell-renderers/favorite-renderer/favorite-renderer.component';
import { ScheduleRendererComponent } from '@app/modules/object-list/cell-renderers/schedule-renderer/schedule-renderer.component';
import { ColumnField, ObjectListColumnsService } from '@app/modules/object-list/object-list-columns.service';
import { ObjectListUtils } from '@app/modules/object-list/object-list-utils';
import { AgGridNested } from '@app/modules/object-list/types/ag-grid-nested.interface';
import { ExtendedColDef } from '@app/modules/object-list/types/extended-column-definition';
import { TableFilterManager } from '@app/modules/object-list/types/table-filter-manager.class';
import { IconsMap } from '@app/modules/object-list/utilities/icons-map.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { NavigationService } from '@cxstudio/services/navigation.service';
import {
	ColDef,
	ColumnApi,

	GetContextMenuItemsParams,
	GridApi,
	GridReadyEvent,
	MenuItemDef,
	RowNode,
	ValueFormatterParams
} from 'ag-grid-enterprise';

export interface DashboardTree extends Dashboard, AgGridNested {}

export enum DashboardColumnField {
	FAVORITE = 'favorite',
	VIEWS = 'views',
	RATING = 'rating',
	SCHEDULES = 'scheduleCount'
}

@Component({
	selector: 'dashboards-table',
	template: `
<default-objects-table
	tableName="dashboardsList"
	class="fill-container"
	[showCheckbox]="true"
	rowSelection="multiple"
	[getContextMenuItems]="getContextMenuItems"
	[columnDefs]="columnDefs"
	[rowData]="dashboardTree"
	[getNameIcon]="getNameIcon"
	(rowAction)="viewDashboard($event)"
	(gridReady)="onGridReady($event)"
	[tableFilterManager]="filterManager"
></default-objects-table>
`,
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardsTableComponent extends SelfCleaningComponent implements OnInit {
	@Input() filterManager: TableFilterManager;

	dashboardTree: Dashboard[];
	columnDefs: ColDef[];

	gridApi: GridApi;
	gridColumnApi: ColumnApi;

	constructor(
		private readonly ref: ChangeDetectorRef,
		protected readonly locale: CxLocaleService,
		private readonly dashboardListService: DashboardListService,
		private readonly objectListColumns: ObjectListColumnsService,
		private readonly contextMenuService: AgGridDashboardContextMenuService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
	) {
		super();
	}

	ngOnInit(): void {
		this.columnDefs = [
			this.objectListColumns.contextMenuColumn(),
			this.getFavoritesColumnDefinition(),
			this.objectListColumns.dateColumn(ColumnField.MODIFIED_DATE, this.locale.getString('dashboard.modifiedDate')),
			this.objectListColumns.ownerColumn(ColumnField.OWNER, this.locale.getString('common.owner')),
			this.objectListColumns.sharingStatusRendererColumn(),
			this.objectListColumns.numberColumn(DashboardColumnField.VIEWS, this.locale.getString('dashboard.views')),
			this.objectListColumns.labelsColumn(),
			this.getScheduleColumnDefinition()
		].filter(col => !!col);

		this.addSubscription(this.dashboardListService.getDashboards().subscribe(dashboardTree => {
			this.dashboardTree = dashboardTree;
			this.gridApi?.setRowData(this.dashboardTree);
			this.ref.markForCheck();
		}));

		this.addSubscription(this.dashboardListService.getDashboardChange().subscribe(changes => {
			this.gridApi?.applyTransaction(changes);
			this.gridApi?.onSortChanged();
			this.ref.markForCheck();
		}));

		this.dashboardListService.reloadDashboards();
	}

	private getFavoritesColumnDefinition(): ExtendedColDef {
		return this.objectListColumns.getSortableIconColumnDefinition('q-icon-star', {
			headerTooltip: this.locale.getString('common.favoriteDashboardOrBook'),
			headerMenuSortLabels: {
				asc: this.locale.getString('common.sortByUnfavorited'),
				desc: this.locale.getString('common.sortByFavorites')
			},
			field: DashboardColumnField.FAVORITE,
			filter: 'agSetColumnFilter',
			headerName: this.locale.getString('common.favorite'),
			filterParams: {
				suppressMiniFilter: true,
				suppressRemoveEntries: true, // hide the (blanks) option
				values: [
					true,
					false
				],
				valueFormatter: (params: ValueFormatterParams) => {
					return (params.value === true || params.value === 'true') ? this.locale.getString('dashboard.favorited') :
						this.locale.getString('dashboard.unfavorited');
				}
			},
			cellRenderer: FavoriteRendererComponent,
			initialHide: true,
			editable: (params) => !ObjectListUtils.isFolder(params.data),
			cellEditor: NoEditorToggle,
		});
	}

	private getScheduleColumnDefinition(): ColDef {
		return this.objectListColumns.getSortableIconColumnDefinition('q-icon-clock', {
			headerTooltip: this.locale.getString('header.schedules'),
			headerMenuSortLabels: {
				asc: this.locale.getString('common.sortByUnscheduled'),
				desc: this.locale.getString('common.sortByScheduled')
			},
			headerName: this.locale.getString('common.scheduleCount'),
			field: DashboardColumnField.SCHEDULES,
			filter: 'agSetColumnFilter',
			filterParams: {
				suppressMiniFilter: true,
				valueFormatter: (params) => {
					if (!params.value) {
						return this.locale.getString('schedule.notScheduled');
					}
					else if (parseInt(params.value, 10) === 1) {
						return this.locale.getString('schedule.numSchedule',
							{
								num: params.value
							});
					}
					return this.locale.getString('schedule.numSchedules',
						{
							num: parseInt(params.value, 10)
						});
				}
			},
			cellRenderer: ScheduleRendererComponent,
			initialHide: true,
		});
	}

	onGridReady(params: GridReadyEvent) {
		this.gridApi = params.api;
		this.gridColumnApi = params.columnApi;

		//this.gridApi.sizeColumnsToFit();
	}

	isRowSelectable(rowNode: RowNode): boolean {
		return !ObjectListUtils.isFolder(rowNode.data);
	}

	getContextMenuItems = (params: GetContextMenuItemsParams): (string | MenuItemDef)[] => {
		return params.node && this.contextMenuService.getMenuItems(this.gridApi, params.node as RowNode, this.filterManager);
	};

	viewDashboard(dashboard: Dashboard): void {
		this.navigationService.changeDashboard(dashboard, false);
	}

	getNameIcon = (data: Dashboard): string => {
		return IconsMap.dashboardIconsMap[data.type];
	};
}
