import { VisualizationType } from '@cxstudio/reports/visualization-types.constant';
import { IChartDefinitionFactory } from './chart-definition-factory.interface';

export class HighchartsDefinitionFactory {
	constructor(
		private readonly HighchartsPieAnalyticDefinition,
		private readonly HighchartsTreeAnalyticDefinition,
		private readonly HighchartsBubbleAnalyticDefinition,
		private readonly HighchartsNetworkAnalyticDefinition
	) {}

	get = (def, element) => {
		let type = def.options.visualization;
		return this.getAnalyticDefinition(type, def, element);
	};

	private getAnalyticDefinition(type: VisualizationType, def, element): IChartDefinitionFactory {
		switch (type) {
			case VisualizationType.PIE: return new this.HighchartsPieAnalyticDefinition(def, element);
			case VisualizationType.STACKED_PIE: return new this.HighchartsPieAnalyticDefinition(def, element);
			case VisualizationType.HEATMAP: return new this.HighchartsTreeAnalyticDefinition(def, element);
			case VisualizationType.BUBBLE: return new this.HighchartsBubbleAnalyticDefinition(def, element);
			case VisualizationType.NETWORK: return new this.HighchartsNetworkAnalyticDefinition(def, element);
			default: return undefined;
		}
	}
}

app.service('highchartsDefinitionFactory', HighchartsDefinitionFactory);
