import { Component, ChangeDetectionStrategy, Input, Inject, ChangeDetectorRef, OnInit } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { ExportUtils } from '@cxstudio/reports/utils/export/export-utils.service';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import MediaType from '@app/modules/cx-form/file-upload/media-type';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import {
	ProjectSelectionDialogComponent
} from '@app/modules/account-administration/project-selection-dialog/project-selection-dialog.component';
import { WorkspaceId } from '@app/modules/units/workspace-project/workspace-project';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { DetailedValidationError } from '@app/core/detailed-validation-error.interface';
import { FormattedTableColumnsService } from '@app/shared/components/table/formatted-table-columns.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';

export interface OrganizationFiltersWizardUploadDialogInput {
	hierarchy: Hierarchy;
	update: boolean;
}

export interface IProject {
	id: number;
	name: string;
}

export interface IWorkspaceProject {
	workspaceId: WorkspaceId;
	projectId: number;
	projectName: string;
}

@Component({
	selector: 'organization-filters-wizard-upload-modal',
	templateUrl: './organization-filters-wizard-upload-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class OrganizationFiltersWizardUploadModalComponent implements OnInit {
	public readonly MediaType = MediaType;
	@Input() input: OrganizationFiltersWizardUploadDialogInput;

	public successfulUpload = undefined;

	validationErrors: DetailedValidationError[];
	errorTableColumns: TableColumn<DetailedValidationError>[];

	constructor(
		private readonly ref: ChangeDetectorRef,
		private readonly locale: CxLocaleService,
		private readonly organizationApiService: OrganizationApiService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly cxDialogService: CxDialogService,		
		private readonly formattedTableColumnsService: FormattedTableColumnsService,
		@Inject('exportUtils') private readonly exportUtils: ExportUtils
	) {}

	ngOnInit(): void {
		this.errorTableColumns = this.formattedTableColumnsService.getValidateErrorColumns();
	}

	public getFileUploadUrl(): string {
		return this.organizationApiService.getFiltersUploadUrl(this.input.hierarchy.id);
	}

	public handleSuccessfulUpload(_response: boolean): void {
		this.successfulUpload = {$value: true};
	}

	public handleRemovedUpload(): void {
		this.successfulUpload = undefined;
	}

	getTitle = (): string => {
		return this.locale.getString('organization.filtersWizardTitle', {
			hierarchyName: this.input.hierarchy.name
		});
	};

	isEditable = (): boolean => {
		return !!this.input.hierarchy.editable;
	};

	downloadDataFile = (): void => {
		this.organizationApiService.getOrganizationFilters(this.input.hierarchy.id)
			.then(apiResponse => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	};

	downloadTemplateFile = (): void => {
		this.cxDialogService.openDialog(ProjectSelectionDialogComponent).result
			.then((result: IProjectSelection) => {
				if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE)) {
					this.getOrganizationFiltersTemplateForWorkspaceProject(result);
				} else {
					this.getOrganizationFiltersTemplateForProject(result);
				}
			}).catch(() => {});
	};

	private getOrganizationFiltersTemplateForProject(projectSelection: IProjectSelection): void {
		const project: IProject = {
			id: projectSelection.projectId,
			name: projectSelection.projectName
		};

		this.organizationApiService
			.getOrganizationFiltersTemplateForProject(
				this.input.hierarchy.id,
				project,
				projectSelection.contentProviderId
			)
			.then((apiResponse) => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	}

	private getOrganizationFiltersTemplateForWorkspaceProject(projectSelection: IProjectSelection): void {
		const workspaceProject: IWorkspaceProject = {
			workspaceId: projectSelection.workspaceId,
			projectId: projectSelection.projectId,
			projectName: projectSelection.projectName
		};

		this.organizationApiService
			.getOrganizationFiltersTemplateForWorkspaceProject(this.input.hierarchy.id, workspaceProject)
			.then((apiResponse) => {
				this.exportUtils.downloadXLSX(apiResponse);
			});
	}

	updateErrorTable = (errors: DetailedValidationError[]): void => {
		this.validationErrors = errors;
		this.ref.markForCheck();
	};
}
