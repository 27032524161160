import { ChangeDetectionStrategy, Component, Inject, OnInit, ChangeDetectorRef, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { CxLocationService } from '@app/core/cx-location.service';
import { SidebarWizardComponent } from '@app/modules/wizard/sidebar-wizard/sidebar-wizard.component';
import Hierarchy from '@cxstudio/organizations/Hierarchy';
import { OrganizationApiService } from '@app/modules/hierarchy/organization-api.service';
import { CxDialogService } from '@app/modules/dialog/cx-dialog.service';
import { HierarchyUploadWizardSubmitDialogComponent } from './dialog/hierarchy-upload-wizard-submit-dialog.component';
import { HierarchyComponentInput } from './hierarchy-component-input';
import { HierarchyPublicationState } from '@cxstudio/organizations/hierarchy-publication-state';
import { HierarchyMode } from '@cxstudio/organizations/hierarchy-mode';


interface IRouteParams {
	hierarchyId: string;
}

@Component({
	selector: 'hierarchy-upload-wizard-page',
	templateUrl: './hierarchy-upload-wizard-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class HierarchyUploadWizardPageComponent implements OnInit {

	hierarchy: Hierarchy;
	loading: Promise<unknown>;

	@ViewChild(SidebarWizardComponent) wizard: SidebarWizardComponent;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private cxLocation: CxLocationService,
		private organizationApiService: OrganizationApiService,
		private readonly cxDialogService: CxDialogService,
		@Inject('$routeParams') private $routeParams: IRouteParams,
	) { }

	ngOnInit(): void {
		this.reloadHierarchy();
	}

	reloadHierarchy(): void {
		if (this.$routeParams.hierarchyId) {
			const hierarchyId = parseInt(this.$routeParams.hierarchyId, 10);
			this.loading = this.organizationApiService.getOrgHierarchy(hierarchyId).then(response => {
				this.hierarchy = response.data;
				this.ref.markForCheck();
			}, () => this.redirectToHierarchies());
		}
	}

	getWizardHeader(): string {
		if (!this.hierarchy) {
			return '';
		}
		return this.locale.getString('organization.uploadYourHierarchyFiles', {
			hierarchyName: this.hierarchy.name
		});
	}

	private openCancelDialog = (): void => {
		let dialogHeader = this.locale.getString('organization.areYouSure');
		let dialogBody = this.locale.getString('organization.youLoseAllChanges');

		let cancel = (): void => {
			// do nothing
		};

		let discard = (): void => {
			this.organizationApiService.discardUpdate(this.hierarchy.id);
			this.redirectToHierarchies();
		};

		this.cxDialogService.regularWithConfirm(dialogHeader, dialogBody,
			this.locale.getString('organization.discardChanges'), this.locale.getString('common.cancel')).
			result.then(discard, cancel);	
	};

	private openSubmitDialog(): void {
		const input: HierarchyComponentInput = {
			hierarchy: this.hierarchy
		};

		this.cxDialogService.openDialog(HierarchyUploadWizardSubmitDialogComponent, input).result
			.then(() => {
				this.redirectToHierarchies();
			})
			.catch(() => { });
	}


	private hasUpdate(): boolean {
		return this.hierarchy?.hasUpdate;
	}

	getBackToHierarchiesLabel(): string {
		return this.locale.getString('organization.backToHierarchies');
	}

	redirectToHierarchies(): void {
		this.cxLocation.path('/master-account-properties').search({ tab: 'organization' });
	}

	getDiscardLabel(): string {
		return this.locale.getString('organization.discardPendingChanges');
	}

	isDiscardButtonEnabled(): boolean {
		return true;
	}

	showDiscardButton(): boolean {
		return this.hasUpdate();
	}

	processDiscard(): void {
		this.openCancelDialog();
	}

	getApplyChangesLabel(): string {
		return this.locale.getString('organization.applyPendingChanges');
	}

	processApplyChanges(): void {
		let config = {
			includingEmptyRecords: false,
			incrementalMode: false,
			runDeltaClassification: false,
			skipPublishing: true
		};
		this.organizationApiService.applyUpdate(this.hierarchy.id, config);
		this.redirectToHierarchies();
	}

	showApplyChangesButton(): boolean {
		if (this.isDynamicFilteringHierarchy()) {
			return false;
		}
		return this.hasUpdate();
	}

	isApplyChangesEnabled(): boolean {
		return this.hierarchy?.hasUpdateReady;
	}

	getApplyAndPublishLabel(): string {
		if (this.isDynamicFilteringHierarchy()) {
			// dynamic hiearchy cannot be published.
			return this.locale.getString('organization.applyPendingChanges');
		}
		
		if (this.hasUpdate()) {
			return this.locale.getString('organization.applyAndPublishPendingChanges');
		}

		return this.locale.getString('organization.publish');
	}

	processApplyAndPublishChanges(): void {
		if (this.isDynamicFilteringHierarchy()) {
			// cannot submit dynamic hierarchy for classification
			this.processApplyChanges();
		} else {
			this.openSubmitDialog();
		}
	}

	isApplyAndPublishEnabled(): boolean {
		if (!this.hierarchy) {
			return false;
		}
		if (this.hierarchy.hasUpdate) {
			return this.hierarchy.hasUpdateReady;
		}
		return !!this.hierarchy.structureFileName && !!this.hierarchy.filterFileName && !!this.hierarchy.userFileName;
	}

	showApplyAndPublishButton(): boolean {
		if (this.isDynamicFilteringHierarchy()) {
			return this.hierarchy.hasUpdate;
		}

		if (this.hierarchy?.publicationState === HierarchyPublicationState.NOT_SYNCHRONIZED) {
			return false;
		}

		return !this.hierarchyHasModel() || this.hierarchy?.hasUpdate;
	}

	private hierarchyHasModel(): boolean {
		return this.hierarchy?.publicationState === HierarchyPublicationState.PUBLISHED ||
			this.hierarchy?.publicationState === HierarchyPublicationState.CLASSIFYING ||
			this.hierarchy?.publicationState === HierarchyPublicationState.CLASSIFIED;
	}

	private isDynamicFilteringHierarchy = (): boolean => {
		return HierarchyMode.DYNAMIC_FILTERING === this.hierarchy?.hierarchyMode;
	};

	shouldShowDoneButton = (): boolean => {
		return this.isDynamicFilteringHierarchy();
	};

}

app.directive('hierarchyUploadWizardPage',
	downgradeComponent({ component: HierarchyUploadWizardPageComponent }));

