import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { FormatDataType } from '@cxstudio/reports/formatting/format-data-type.enum';
import { MetricMultiplierType } from '@cxstudio/reports/formatting/metric-multiplier-type.enum';
import { ReportConstants } from '@cxstudio/reports/report-constants.service';

export interface Format {
	name: FormatDataType;
	upgrade: <T extends NumberFormatSettings>(item: T) => T;
}
export class FormatsService {

	static formats: Format[] = [{
		name: FormatDataType.NUMBER,
		upgrade: item => {
			delete item.dataType;
			item.thousandsDelimiter = '';
			item.conversion = MetricMultiplierType.NONE;
			item.customFormatting = true;
			return item;
		}
	},
	{
		name: FormatDataType.NUMBER_DELIMITER,
		upgrade: item => {
			delete item.dataType;
			item.thousandsDelimiter = undefined; // undefined  delimiter will result in using locale default
			item.conversion = MetricMultiplierType.NONE;
			item.customFormatting = true;
			return item;
		}
	},
	{
		name: FormatDataType.NA,
		upgrade: item => {
			delete item.dataType;
			item.conversion = MetricMultiplierType.NONE;
			item.customFormatting = true;
			return item;
		}
	},
	{
		name: FormatDataType.PERCENT,
		upgrade: item => {
			delete item.dataType;
			item.suffix = '%';
			item.conversion = MetricMultiplierType.NONE;
			item.customFormatting = true;
			return item;
		}
	},
	{
		name: FormatDataType.PERCENT_100,
		upgrade: item => {
			delete item.dataType;
			item.conversion = MetricMultiplierType.TIMES_HUNDRED;
			item.customFormatting = true;
			item.suffix = '%';
			return item;
		}
	},
	{
		name: FormatDataType.PERCENT_100_NONE,
		upgrade: item => {
			delete item.dataType;
			item.conversion = MetricMultiplierType.TIMES_HUNDRED;
			item.customFormatting = true;
			return item;
		}
	}];

	static find = (name: FormatDataType): Format => {
		return _.findWhere(FormatsService.formats, { name });
	};
}
