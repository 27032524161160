import * as _ from 'underscore';
import BulkUpdateValue from '@cxstudio/user-administration/bulk/bulk-update-value.enum';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { DowngradeDialogService } from '@app/modules/downgrade-utils/downgrade-dialog.service';
import { User } from '../users/entities/user';
import { UsersGroupsApiService } from '@cxstudio/services/data-services/users-groups-api.service';

export interface IUserMembershipItemOnGrid {
	userId: number;
	displayName: string;
	userEmail: string;
	level: number;
	updateValue?: BulkUpdateValue;
}

export class GroupsUserMembership implements ng.IComponentController {

	users: IUserMembershipItemOnGrid[];
	availableUsers: User[];
	search: string;
	close: (result: {$value: any}) => void;
	dismiss: () => void;
	gridType = GridTypes.GROUPS_BULK_USERS;

	constructor(
		private readonly downgradeDialogService: DowngradeDialogService,
		private readonly usersGroupsApiService: UsersGroupsApiService
	) {}

	$onInit(): void {
		this.users = [];
		this.availableUsers = [];
		this.search = '';
	}

	save = (): void => {
		let res = _.groupBy(this.users, (user) => {
			switch (user.updateValue) {
				case BulkUpdateValue.ADD:
					return 'added';
				case BulkUpdateValue.REMOVE:
					return 'removed';
				default:
					return 'none';
			}
		});
		this.close({$value: res});
	};

	cancel = (): void => {
		if (!this.isDataChanged()) {
			this.dismiss();

			return;
		}

		this
			.downgradeDialogService
			.showUnsavedChangesDialogAndResolve(
				this.save,
				this.dismiss,
				'group.unsavedChangesHeader',
				'group.unsavedChanges',
				false
			)
			.catch(() => {})
		;
	};

	private isDataChanged(): boolean {
		return _.some(this.users, (user) => {
			return user.updateValue && user.updateValue !== BulkUpdateValue.NONE;
		});
	}

	getAvailableUsers = (filter: string): ng.IPromise<User[]> => {
		return this.usersGroupsApiService.searchNewGroupMemberCandidates(filter, 100).then((candidates: User[]) => {
			candidates = _.filter(candidates, cand => !_.findWhere(this.users, { userEmail: cand.userEmail }));
			_.each(candidates, this.addCandidate);

			candidates.sort((left, right) => left.userEmail.localeCompare(right.userEmail));
			return candidates.length > 20
				? candidates.slice(0, 20)
				: candidates;
		});
	};

	private addCandidate = (candidate: User): void => {
		if (!_.findWhere(this.availableUsers, { userEmail: candidate.userEmail })) {
			this.availableUsers.push(candidate);
		}
	};

	isUserValidForAddition = (userEmail: string): boolean => !!_.findWhere(this.availableUsers, { userEmail });

	addUser = (userEmail: string): void => {
		let user = _.findWhere(this.availableUsers, { userEmail });
		if (user) {
			this.users.push({
				userId: user.userId,
				displayName: user.userEmail,
				userEmail: user.userEmail,
				level: 0
			});
			this.availableUsers.remove(user);
			this.search = '';
		}
	};

}

app.component('groupsUserMembership', {
	bindings: {
		resolve: '<',
		close: '&',
		dismiss: '&'
	},
	controller: GroupsUserMembership,
	templateUrl: 'partials/user-administration/groups/groups-user-membership.component.html'
});
