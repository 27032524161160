/*
 * Service for providing unique promises
 * (should be called once, even if triggered multiple times)
 */
export class UniqueService {

	static uniquesMap = {};

	static promise = (name, promiseSupplier, successCallback, errorCallback) => {
		if (!UniqueService.uniquesMap[name]) {
			let promise = promiseSupplier();
			UniqueService.uniquesMap[name] = promise;

			promise.then(
				(result) => {
					UniqueService.uniquesMap[name] = null;
					successCallback(result);
				},
				(error) => {
					UniqueService.uniquesMap[name] = null;
					errorCallback(error);
				}
			);
		}
		return UniqueService.uniquesMap[name];
	};
}
