import { Component, ChangeDetectionStrategy, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { WidgetBackgroundColor } from '@cxstudio/reports/settings/widget-background-color';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';

@Component({
	selector: 'background-color',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => BackgroundColorComponent), multi: true},
	],
	template: `
	<cb-radio-group
		id="backgroundColor"
		inline="true"
		[ngModel]="value"
		(ngModelChange)="changeHandler($event)"
		[options]="options"
	></cb-radio-group>
	`
})
export class BackgroundColorComponent implements ControlValueAccessor {
	value: WidgetBackgroundColor;
	options: UIOption<WidgetBackgroundColor>[] = [
		{value: WidgetBackgroundColor.NONE, displayName: this.locale.getString('common.none')},
		{value: WidgetBackgroundColor.DEFAULT, displayName: this.locale.getString('common.default')}
	];

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouchedCallback: () => void = _.noop;
	private onChangeCallback: (val: WidgetBackgroundColor) => void = _.noop;

	constructor(private locale: CxLocaleService) {}

	changeHandler = (value: WidgetBackgroundColor): void => {
		this.value = value;
		this.onChangeCallback(this.value);
		this.onTouchedCallback();
	};

	//ControlValueAccessor
	writeValue(value: any): void {
		if (value !== this.value) {
			this.value = value;
			this.onChangeCallback(this.value);
		}
	}
	registerOnChange(fn: any): void {
		this.onChangeCallback = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouchedCallback = fn;
	}
}

app.directive('backgroundColor', downgradeComponent({component: BackgroundColorComponent}));
