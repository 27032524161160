import { Component, EventEmitter, Input, OnInit, Output, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { IColorGradeLaneItem } from './color-grade-lane-item';
import { ThresholdRule } from '@cxstudio/reports/utils/color/metric-threshold-rules.service';
import { IColorGradeOptions } from './cb-color-grade-bar.component';
import { DefaultDataFormatterBuilderService } from '@app/modules/widget-visualizations/formatters/default-data-formatter-builder.service';
import { IFormatBuilder } from '@app/modules/widget-visualizations/formatters/generic-formatter.service';
import { NumberFormatSettings } from '@app/modules/asset-management/entities/settings.interfaces';
import { CalculationWithFormat } from '@cxstudio/reports/providers/cb/calculations/report-calculation';


@Component({
	selector: 'color-grade-lane',
	templateUrl: './color-grade-lane.component.html'
})

export class ColorGradeLaneComponent implements OnInit {

	@Input() laneItem: IColorGradeLaneItem;
	@Input() isFirst: boolean;
	@Input() isLast: boolean;
	@Input() options: IColorGradeOptions;
	@Input() format: NumberFormatSettings;
	@Output() laneItemChange = new EventEmitter<IColorGradeLaneItem>();

	formatter: IFormatBuilder;

	constructor(
		private locale: CxLocaleService,
		private defaultDataFormatterBuilder: DefaultDataFormatterBuilderService
	) {}

	ngOnInit(): void {
		if (this.format) {
			this.formatter = this.defaultDataFormatterBuilder.getDefaultFormatterBuilder(this.format as CalculationWithFormat);
		}
	}

	getDefaultName = (): string => {
		if (!this.laneItem.defaultName) {
			if (this.isFirst) {
				let cond = this.isRightInclusive() ? '≤ ' : '< ';
				return cond + this.getFormattedValue(this.laneItem.rightThreshold);
			} else if (this.isLast) {
				let cond = this.isLeftInclusive() ? '≥ ' : '> ';
				return cond + this.getFormattedValue(this.laneItem.leftThreshold);
			} else if (this.isLeftInclusive()) {
				return '≥ ' + this.getFormattedValue(this.laneItem.leftThreshold);
			} else {
				return '≤ ' + this.getFormattedValue(this.laneItem.rightThreshold);
			}
		}
		return this.laneItem.defaultName;
	};

	private getFormattedValue = (value: number): string => {
		return this.formatter && this.format ? this.formatter.simpleFormat(value, this.format, {ignoreAlignment: true}) : ('' + value);
	};


	onValueChange = (): void => {
		this.laneItemChange.emit(this.laneItem);
	};

	onThresholdChange = (newValue: string, isLeft: boolean): void => {
		let newThreshold = parseFloat(newValue);
		let isInvalid = isNaN(newThreshold);
		if (isLeft) {
			this.laneItem.leftThresholdInvalid = isInvalid;
			if (!isInvalid) {
				this.laneItem.leftThreshold = newThreshold;
			}
		} else {
			this.laneItem.rightThresholdInvalid = isInvalid;
			if (!isInvalid) {
				this.laneItem.rightThreshold = newThreshold;
			}
		}
		this.onValueChange();
	};

	isLeftInclusive = (): boolean => this.laneItem.leftThresholdRule === ThresholdRule.RIGHT_INCLUSIVE;

	isRightInclusive = (): boolean => this.laneItem.rightThresholdRule === ThresholdRule.LEFT_INCLUSIVE;

	isValueItem = (): boolean => {
		return _.isNumber(this.getValue());
	};

	getValue = (): number => {
		return this.laneItem.value;
	};

	getLeftConditionText = (): string => {
		if (this.isLast) {
			return this.isLeftInclusive()
				? this.locale.getString('metrics.gte')
				: this.locale.getString('metrics.gt') + ' ' + this.laneItem.leftThreshold;
		}
		if (this.isLeftInclusive() && this.isRightInclusive()) {
			return this.locale.getString('common.between');
		} else if (this.isLeftInclusive()) {
			return this.locale.getString('metrics.gte');
		} else if (this.isRightInclusive()) {
			return '';
		} else {
			return this.locale.getString('common.between') + ' ' + this.laneItem.leftThreshold;
		}
	};

	getRightConditionText = (): string => {
		if (this.isFirst) {
			return this.isRightInclusive()
				? this.locale.getString('metrics.lte')
				: this.locale.getString('metrics.lt') + ' ' + this.laneItem.rightThreshold;
		}
		if (this.isLeftInclusive() && this.isRightInclusive()) {
			return this.locale.getString('common.and');
		} else if (this.isLeftInclusive()) {
			return '';
		} else if (this.isRightInclusive()) {
			return this.locale.getString('metrics.lte');
		} else {
			return this.locale.getString('common.and') + ' ' + this.laneItem.rightThreshold;
		}
	};

}
