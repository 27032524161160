import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { Pagination } from '@app/shared/components/pagination/pagination';
import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { PagedResponse } from '@cxstudio/paged-response';
import { DependentMetric } from '@cxstudio/asset-management/dependencies/dependent-metric';
import { MetricConverter } from '@cxstudio/metrics/metric-converter.service';

import { CxLocaleService } from '@app/core';

import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { AssetObjectTypes } from '@app/modules/asset-management/entities/asset-object-types';
import { IExportHandler } from '@app/modules/asset-management/entities/export-handler.interface';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';


type DependencyTypeHandler<A extends StudioAsset, R extends AssetDependency> = (asset: A, pagination: Pagination)
	=> Promise<PagedResponse<R>>;

type MetricsHandler<A extends StudioAsset> = (asset: A, pagination: Pagination)
	=> Promise<PagedResponse<DependentMetric>>;

export abstract class AbstractDependencyTypesProvider<T extends StudioAsset> {

	dependencyTypes: Array<AssetObjectType<T, AssetDependency>>;
	title: string;
	exportHandler: IExportHandler<T>;
	protected allTypes: AssetObjectTypes<T, AssetDependency>;

	constructor(
		protected readonly locale: CxLocaleService,
		protected readonly metricConverter: MetricConverter
	) {
		this.allTypes = new AssetObjectTypes(this.locale);
	}

	protected withHandler = (
		objectType: ObjectType, handler: DependencyTypeHandler<T, AssetDependency>
	): AssetObjectType<T, AssetDependency> => {
		let type = this.allTypes.items[objectType];
		type.handler = handler;
		return type;
	};

	protected withMetricsHandler = (handler: MetricsHandler<T>): AssetObjectType<T, AssetDependency> => {
		return this.withHandler(ObjectType.METRIC, this.wrapMetricsHandler(handler));
	};

	private wrapMetricsHandler = (handler: MetricsHandler<T>): MetricsHandler<T> => {
		return (asset: T, pagination: Pagination) => {
				return handler(asset, pagination).then((response: PagedResponse<DependentMetric>) => {
					response.data.forEach((metric) => {
						metric.definitionTypeName =
							this.metricConverter.codeToString(metric.definitionType);
					});
					return response;
				});
		};
	};
}
