<checkbox-button
	[(ngModel)]="visualProps.plotBandsEnabled"
	(ngModelChange)="onPlotBandsEnabledChange()"
	buttonClass="plot-bands-checkbox btn-sm mb-8"
	label="{{'widget.quadrants'|i18n}}">
</checkbox-button>

<div *ngIf="visualProps.plotBandsEnabled" class="clearfix plot-band-configurer-content">
	<div class="row">
		<div class="col-md-2 col-sm-4 mb-16 plot-line-selector">
			<plot-line-dropdown
				[plotLine]="visualProps.plotBands.lineStyle"
			></plot-line-dropdown>
		</div>
	</div>

	<div class="row plot-line-positions ml-0">
		<div class="col-sm-6 plot-dividers col-md-4 col-sm-12"
			*ngFor="let axisName of axisNames; index as axisNameIndex">
			<div class="form-group">
				<label for="axis_divider_{{axisNameIndex}}" [i18n]="'widget.axisDividerAt'" [i18nParams]="{metric : axisName}"></label>
				<div class="align-center">
					<input type="number"
						id="axis_divider_{{axisNameIndex}}"
						[valueRequired]
						[strictMin]="-1e12"
						[strictMax]="1e12"
						[(ngModel)]="visualProps.plotBands.lines[getAxisType(axisNameIndex)][0]">
				</div>
			</div>
		</div>
	</div>

	<div class="row plot-bands-grid">
		<div class="plot-band-item col-sm-12"
			*ngFor="let plotBand of topPlotBands; index as plotBandIndex">
			<label [innerHTML]="plotBandLabels[plotBandIndex]"></label>
			<plot-band-item [plotBand]="plotBand" class="d-flex" [ngClass]="getPlotBandIdentifierClass(plotBandIndex)"></plot-band-item>
		</div>
	</div>

	<div class="row plot-bands-grid">
		<div class="plot-band-item col-sm-12"
			*ngFor="let plotBand of bottomPlotBands; index as plotBandIndex">
			<label [innerHTML]="plotBandLabels[plotBandIndex + columnsCount]"></label>
			<plot-band-item [plotBand]="plotBand" class="d-flex" [ngClass]="getPlotBandIdentifierClass(plotBandIndex + columnsCount)"></plot-band-item>
		</div>
	</div>

	<div class="row">
		<div class="form-group col-md-4 col-sm-12">
			<label for="quadrantsTextSize" [i18n]="'widget.quadrantsTextSize'"></label>
			<div class="d-flex align-center">
				<input type="number"
					id="quadrantsTextSize"
					class="form-control"
					style="margin-right: 5px; flex: 0 1 80px;"
					name="bandFontSize"
					[(ngModel)]="visualProps.plotBands.fontSize"
					[valueRequired]
					[strictMin]="1"
					[strictMax]="99">
					px
			</div>
		</div>
	</div>
</div>
