import * as _ from 'underscore';
import { INode } from '@app/modules/utils/searchable-hierarchy-utils.service';
import ILocale from '@cxstudio/interfaces/locale-interface';


export class AutocompleteFromHierarchyController implements ng.IController {

	hierarchyList: INode[];
	displayProperty: string;
	searchPlaceholder: string;
	onNodeClick: (params: {node: INode}) => void;
	label: string;
	disabledProperty: boolean;
	disabledItems: INode[];
	appendToBody: () => boolean;
	menuClass: string;
	notRecommendedItems: INode[];
	intersectionObserver: IntersectionObserver;
	customValidation: (node: INode) => boolean;

	searchModel: {
		hierarchySearch: string;
	};

	constructor(
		private locale: ILocale,
		private $element: JQuery,
	) {}

	$onInit(): void {

		this.searchModel = {
			hierarchySearch: ''
		};
		if (!this.searchPlaceholder) {
			this.searchPlaceholder = this.locale.getString('common.find');
		}
		if (!this.displayProperty) {
			this.displayProperty = 'displayName';
		}
		if (!this.appendToBody) {
			this.appendToBody = () => false;
		}
	}

	onDropdownToggle = (open: boolean) => {
		if (!open) {
			// remove focus from input when closed dropdown
			this.$element.find('[uib-dropdown-toggle]').blur();
		}
	};

	selectNode = (node: INode) => {
		if (node.children) return;
		this.onNodeClick({node});
	};
}

app.component('autocompleteFromHierarchy', {
	controller: AutocompleteFromHierarchyController,
	templateUrl: 'partials/custom/autocomplete-from-hierarchy.component.html',
	bindings: {
		hierarchyList: '<',
		displayProperty: '@',
		searchPlaceholder: '@',
		onNodeClick: '&',
		label: '@',
		disabledProperty: '<',
		disabledItems: '<',
		appendToBody: '&?',
		menuClass: '@?',
		notRecommendedItems: '<?',
		intersectionObserver: '<?',
		customValidation: '<?'
	},
});
