import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { IntelligentScoringPreference, ScorecardResultColumn } from '../explorer-scorecard-sort.component';
import { SessionPreferencesService } from '@app/core/storage/session-preferences.service';

@Injectable()
export class ExplorerScorecardDisplayPreference {

	private preferenceSource$: BehaviorSubject<IntelligentScoringPreference>;

	preference$: Observable<IntelligentScoringPreference>;

	constructor(private sessionPreferences: SessionPreferencesService) {
		let initValue: IntelligentScoringPreference = this.sessionPreferences.get('intelligentScoring') || {};
		this.preferenceSource$ = new BehaviorSubject(initValue);
		this.preference$ = this.preferenceSource$.asObservable();
	}

	setValue(value: IntelligentScoringPreference) {
		this.persistSelection(value);
		this.preferenceSource$.next(value);
	}

	private persistSelection = (value: IntelligentScoringPreference): void => {
		this.sessionPreferences.set('intelligentScoring', value);
	};
}
