import * as _ from 'underscore';
import MobileAppSettings from '@cxstudio/mobile/mobile-app-settings.interface';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import MobileUtils from '@cxstudio/projects/mobile-utils.service';
import MobileAppSettingsUI from './mobile-app-settings-ui.interface';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';
import { Errors } from '@cxstudio/common/errors';
import { MobileAppDialogTab } from './mobile-app-dialog-tab.enum';
import MobileAppSettingsApiService from './mobile-app-settings-api.service';
import { Security } from '@cxstudio/auth/security-service';
import { MobileAppSettingsList } from './mobile-app-settings-list';
import { FavoriteProperties } from '@cxstudio/auth/entities/favorite-properties';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { PromiseUtils } from '@app/util/promise-utils';
import { MobileWorkspaceSelectorData } from '@app/modules/mobile/mobile-workspace-selector/mobile-workspace-selector.component';

export default class MobileAppPropertiesComponent implements ng.IComponentController {

	promises: MobileAppConfigurationPromises;
	settings: MobileAppSettings;
	originalSettings: MobileAppSettings;
	onProjectChange?: (change: { $selection: IProjectSelection }) => void;
	onUiCommit?: () => void;
	ui: MobileAppSettingsUI;
	errors: Errors;
	settingsList: MobileAppSettingsList;
	dialogData: any;

	searchingOwnerCandidates: boolean;
	changedProject: boolean;
	private originalAppName: string;
	isWorkspaceEnabled: boolean;
	projectPreselection: Partial<FavoriteProperties>;

	constructor(
		private mobileUtils: MobileUtils,
		private mobileAppSettingsApiService: MobileAppSettingsApiService,
		private security: Security,
		private $scope: ISimpleScope,
		private betaFeaturesService: BetaFeaturesService
	) {}

	$onInit(): void {
		this.projectPreselection = this.buildProjectPreselection();
		this.originalAppName = this.settings.name;
		this.isWorkspaceEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.WORKSPACE);

		this.$scope.$watch(() => this.settings?.submitterEmail, this.verifyOwner);
	}

	onProjectChanged = (projectSelection: IProjectSelection): void => {
		let projectWasSelected = ProjectIdentifier.isProjectSelected(this.settings);
		this.onProjectSelectionFinished(projectSelection);

		if (!this.mobileUtils.matchesSettings(this.settings, projectSelection)) {
			this.settings.contentProviderId = projectSelection.contentProviderId;
			this.settings.accountId = projectSelection.accountId;
			this.settings.projectId = projectSelection.projectId;

			if (projectWasSelected) {
				this.changedProject = true;
			}

			this.bypassProjectChange(projectSelection);
		}
	};

	verifyAppName = (): void => {
		const name = this.settings.name;
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES, 'app-name' ], 'app-name-empty', (Boolean) (name));

		const nameChanged = name !== this.originalAppName;
		const uniqueAmongOthers = !this.settingsList.items.map(item => item.name).contains(name);
		const unique = !nameChanged || uniqueAmongOthers;
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES, 'app-name' ], 'app-name-unique', unique);
	};

	onProjectSelectionFinished = (projectSelection: IProjectSelection): void => {
		this.ui.projectSelected = ProjectIdentifier.isProjectSelected(projectSelection);

		if (this.ui.projectSelected) {
			this.ui.hasAccessToSelectedProject = true;
		} else if (this.isPreviouslySelectedProjectUnaccessible()) {
			this.ui.hasAccessToSelectedProject = false;
		}

		this.ui.projectsLoaded = true;
		this.commitUiChanges();
		this.commitErrors(projectSelection);
	};

	private commitErrors = (projectSelection: IProjectSelection): void => {
		const contentProviderSelected = (Boolean) (projectSelection && projectSelection.contentProviderId > -1);
		const accountSelected = (Boolean) (contentProviderSelected && projectSelection.accountId > -1);
		const projectSelected = (Boolean) (accountSelected && projectSelection.projectId > -1);

		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'select-content-provider', contentProviderSelected);
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'select-account', accountSelected);
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'select-project', projectSelected);
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES ], 'project-access-error', this.ui.hasAccessToSelectedProject);
	};

	private isPreviouslySelectedProjectUnaccessible = (): boolean => {
		return ProjectIdentifier.isProjectSelected(this.settings) && !this.ui.projectsLoaded;
	};

	private commitUiChanges = (): void => {
		if (this.onUiCommit) {
			this.onUiCommit();
		}
	};

	private bypassProjectChange = (projectSelection: IProjectSelection): void => {
		if (this.onProjectChange) {
			this.onProjectChange({ $selection: projectSelection });
		}
	};

	onFilterChanged = (filterId: number): void => {
		this.settings.filterId = filterId;
	};

	onCustomFilterChanged = (customFilter: any): void => {
		this.settings.customFilter = customFilter;
	};

	private buildProjectPreselection = (): Partial<FavoriteProperties> => {
		return {
			favoriteCP: this.settings.contentProviderId,
			favoriteAccount: this.settings.accountId,
			favoriteProject: this.settings.projectId
		};
	};

	canConfigureProject = (): boolean => {
		return this.ui.projectSelected
			&& this.ui.hasAccessToSelectedProject;
	};

	getSettingsOwnerCandidates = (search: string): ng.IPromise<string[]> => {
		this.searchingOwnerCandidates = true;
		return this.mobileAppSettingsApiService.getOwnerCandidates(search)
			.then(candidates => {
				this.searchingOwnerCandidates = false;
				return candidates;
			});
	};

	canEditOwner = (): boolean => {
		return !this.isNewSettings()
			&& (!this.originalSettings.submitterEmail || this.originalSettings.submitterEmail === this.security.getEmail());
	};

	private isNewSettings = (): boolean => {
		return !this.originalSettings.id;
	};

	verifyOwner = (): void => {
		this.errors.setValid([ MobileAppDialogTab.PROPERTIES, 'submitter-email' ],
			'enter-submitter-email', (Boolean) (this.settings.submitterEmail));
	};

	isOwnerChanged = (): boolean => {
		return this.mobileUtils.isOwnerChanged(this.originalSettings, this.settings);
	};

	onChangeInternal = (): void => {
		let illegalAccessProvided = this.mobileUtils.isIllegalAccessProvided(this.settings, this.dialogData);
		this.errors.setValid([ MobileAppDialogTab.USERS ], 'illegal-external-user-access', !illegalAccessProvided);
	};

	isInternalAppUser = (): boolean => {
		return this.mobileUtils.isInternalAppUser(this.security.getEmail());
	};

	onMobileWorkspaceSelectorDataChanged = (selectorData: MobileWorkspaceSelectorData): void => {
		this.settings.workspaceProject = selectorData.workspaceProject;
		this.settings.hierarchyId = selectorData.hierarchyId;
		this.onProjectChanged(selectorData.projectSelection); // required for backwards compatibility
	};

	onWorkspaceProjectsLoading = (promise: Promise<void>): void => {
		this.promises.projects.promise = PromiseUtils.old(promise);
	};
}

app.component('mobileAppProperties', {
	controller: MobileAppPropertiesComponent,
	templateUrl: 'partials/mobile/mobile-app-properties.html',
	bindings: {
		promises: '=',
		settings: '=',
		originalSettings: '=',
		onProjectChange: '&?',
		onUiCommit: '&?',
		ui: '=',
		errors: '=',
		settingsList: '=',
		dialogData: '<'
	}
});
