import { Pagination } from '@app/shared/components/pagination/pagination';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { EmailStatusApi } from './entities/email-status.api.service';
import EmailsSearch from './entities/emails-search';
import MasterAccount from '@cxstudio/system-administration/master-accounts/master-account';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { EmailsTableColumnsService, StatusData } from './emails-table-columns.service';
import { OnInit, ChangeDetectionStrategy, Component, Input, Inject, ChangeDetectorRef } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { Observable } from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { SortUtils } from '@app/shared/util/sort-utils';
import { SortDirection } from '@cxstudio/common/sort-direction';
import { downgradeComponent } from '@angular/upgrade/static';
import { EmailStatus } from './entities/email-status';

@Component({
	selector: 'emails-tab',
	templateUrl: './emails-tab.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailsTabComponent implements OnInit {

	@Input() masterAccounts: MasterAccount[];

	busy: Promise<any>;

	columns: Array<TableColumn<any>>;
	rows: any[];
	pagination: Pagination;

	search: EmailsSearch;
	masterAccount: MasterAccount;

	statuses: Array<Partial<StatusData>>;

	private sortedMasterAccounts: MasterAccount[];

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private emailStatusApi: EmailStatusApi,
		private emailsTableColumnsService: EmailsTableColumnsService,
		@Inject('cbDialogService') private cbDialogService: CBDialogService
	) {
		this.masterAccountSuggestions = this.masterAccountSuggestions.bind(this);
	}

	ngOnInit(): void {
		this.search = {};
		this.pagination = new Pagination(20);
		this.statuses = this.emailsTableColumnsService.getStatuses();
		this.columns = this.emailsTableColumnsService.getColumns();
		this.sortedMasterAccounts = this.masterAccounts.sort(
			SortUtils.getAlphanumericComparator(row => row.accountName, SortDirection.ASC));
		this.reload();
	}

	reload = (): void => {
		this.busy = this.emailStatusApi.getEmailMessages(this.search, this.pagination);
		this.busy.then((response) => {
			this.rows = response.data;
			this.pagination.totalItems = response.totalCount;
			this.ref.markForCheck();
		});
	};

	deleteQueuedEmails = (): void => {
		let dlg = this.cbDialogService.confirm(
			this.locale.getString('common.pleaseConfirm'),
			this.locale.getString('administration.deleteTheseEmails'));
		dlg.result.then(() => {
			this.busy = this.emailStatusApi.deleteEmailMessages(this.search);
			this.busy.then(() => {
				this.reload();
			});
		});
	};

	pageChanged = (pagination: Pagination): void => {
		this.pagination = pagination;
		this.reload();
	};

	masterAccountSuggestionsFormatter = (masterAccount: MasterAccount) => masterAccount.accountName;

	masterAccountSuggestions = (text$: Observable<string>) =>
		text$.pipe(
			debounceTime(200),
			distinctUntilChanged(),
			map(term => {
				//let toSearch = new RegExp('(' + term + ')', 'i');
				let toSearch = new RegExp(term?.escapeRegExp(), 'i');
				return term.length < 2
					? []
					: this.sortedMasterAccounts
						.filter(a => a.accountName.search(toSearch) > -1)
						.slice(0, 10);
			})
		);

	isDeletionSupported = (): boolean => {
		return this.search.status === EmailStatus.QUEUED;
	};
}

app.directive('emailsTab', downgradeComponent({component: EmailsTabComponent}));
