import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { AssetDependency } from '@cxstudio/asset-management/dependencies/asset-dependency';
import { StudioAsset } from '@cxstudio/asset-management/studio-asset';
import { MetricConverter } from '@cxstudio/metrics/metric-converter.service';

import { Inject, Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { AssetObjectType } from '@app/modules/asset-management/entities/asset-object-type';
import { FilterDependenciesApiService } from './filter-dependencies-api-service';
import { ObjectType } from '@app/modules/asset-management/entities/object-type';
import { AbstractDependencyTypesProvider } from '@app/modules/asset-management/services/abstract-dependency-types-provider';


@Injectable()
export class FilterDependencyTypesProvider extends AbstractDependencyTypesProvider<StudioAsset> {

	dependencyTypes: Array<AssetObjectType<StudioAsset, AssetDependency>>;

	constructor(
		locale: CxLocaleService,
		@Inject('metricConverter') metricConverter: MetricConverter,
		private filterDependenciesApiService: FilterDependenciesApiService
	) {
		super(locale, metricConverter);

		this.dependencyTypes = [];
		this.title = locale.getString('common.filter');
		this.exportHandler = this.filterDependenciesApiService;
	}

	withDependencyTypes = (filterType: FilterTypes): FilterDependencyTypesProvider => {
		this.dependencyTypes = [
			this.withHandler(ObjectType.DASHBOARD, this.filterDependenciesApiService.getDashboards)
		];

		if (filterType === FilterTypes.CXSTUDIO) {
			this.dependencyTypes.push(this.withMetricsHandler(this.filterDependenciesApiService.getMetrics));
		}

		return this;
	};
}

app.service('filterDependencyTypesProvider', downgradeInjectable(FilterDependencyTypesProvider));
