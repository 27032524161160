import { IDocumentPreviewerControls } from '@cxstudio/reports/document-explorer/document-previewer-controls.interface';
import { DocExplorerHelperService } from '@cxstudio/reports/document-explorer/doc-explorer-helper.service';

import { KeyboardUtils, Key, KeyModifier } from '@app/shared/util/keyboard-utils.class';
import { KeyboardSelector } from '@app/shared/util/keyboard-selector.enum';
import { DocumentPreviewSelector } from '@app/shared/util/document-preview-selector';
import { DocViewPreferences } from '@app/modules/document-explorer/preferences/doc-view-preferences.class';
import { ReportPaginationService } from '@app/modules/widget-visualizations/report-pagination.service';


export class SentencesPane implements ng.IComponentController {

	private documentManager: IDocumentPreviewerControls;
	sentenceFocusable: boolean;
	private preferences: DocViewPreferences;
	private showSentiment: boolean;
	isDocExplorer: boolean;

	constructor(
		private docExplorerHelperService: DocExplorerHelperService,
		private $timeout: ng.ITimeoutService,
	) {}

	$onInit = () => {
		// required for typescript validation
	};

	initPaginationOptions(): void {
		if (this.docExplorerHelperService.isRefreshPaginationOptions(this.documentManager))  {
			this.docExplorerHelperService.initPaginationOptions();
		}
	}

	onSentenceContainerKeydown(event: KeyboardEvent): void {
		let sentencesContainer: EventTarget = event.target;
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			this.sentenceFocusable = true;
			setTimeout(() => $(sentencesContainer).find(DocumentPreviewSelector.SELECTED_SENTENCE_SELECTOR).first().trigger('focus'));
		} else if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			KeyboardUtils.intercept(event);
			let clickablePaginationArrows = this.getClickablePaginationArrows(sentencesContainer);
			if (clickablePaginationArrows.length > 1) {
				(clickablePaginationArrows.get(1) as HTMLElement).focus();
			} else {
				this.focusPrimaryPane(sentencesContainer);
			}
		}
	}

	private getClickablePaginationArrows(sentencesContainer: EventTarget): JQuery {
		return $(sentencesContainer)
			.closest('.an-document-explorer')
			.find(DocumentPreviewSelector.SENTENCES_CONTAINER_SELECTOR)
			.next()
			.not(DocumentPreviewSelector.PAGINATION_PAGE_SELECTOR)
			.find(':focusable')
			.not(`a${KeyboardSelector.NEGATIVE_TAB_INDEX}`);
	}

	private focusPrimaryPane(sentencesContainer: EventTarget): void {
		$(sentencesContainer)
			.closest(DocumentPreviewSelector.WIDGET_DOCUMENT_PREVIEW_SELECTOR)
			.find(`${DocumentPreviewSelector.PRIMARY_PANE_SELECTOR} :tabbable`)
			.not(KeyboardSelector.NEGATIVE_TAB_INDEX).first().trigger('focus');
	}

	onSentenceKeydown(event: KeyboardEvent, template): void {
		let sentenceOrLink: EventTarget = event.target;
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			this.documentManager.getDocumentPreview(template);
		} else if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.stopPropagation();
			$(sentenceOrLink).closest(DocumentPreviewSelector.SENTENCES_CONTAINER_SELECTOR).trigger('focus');
			this.sentenceFocusable = false;
		} else if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			event.stopPropagation();
			let focusableSentencesAndLinks = this.getFocusableSentencesAndLinks(sentenceOrLink);
			if (focusableSentencesAndLinks.last()[0] === sentenceOrLink) {
				event.preventDefault();
				focusableSentencesAndLinks.first().trigger('focus');
			}
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)) {
			event.stopPropagation();
			let focusableSentencesAndLinks = this.getFocusableSentencesAndLinks(sentenceOrLink);
			if (focusableSentencesAndLinks.first()[0] === sentenceOrLink) {
				event.preventDefault();
				focusableSentencesAndLinks.last().trigger('focus');
			}
		}
	}

	private getFocusableSentencesAndLinks(sentenceOrLink: EventTarget): JQuery {
		return $(sentenceOrLink)
			.closest(DocumentPreviewSelector.SENTENCES_CONTAINER_SELECTOR)
			.find(':focusable');
	}

	onPaginationKeydown(event: KeyboardEvent): void {
		let paginationItem: EventTarget = event.target;
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			event.stopPropagation();
			this.$timeout(() => {
				$(paginationItem).first().trigger('focus');
			});
		} else if (KeyboardUtils.isEventKey(event, Key.TAB, KeyModifier.SHIFT)
				&& paginationItem === this.getClickablePaginationItems(paginationItem).first()[0]) {
			KeyboardUtils.intercept(event);
			this.getSentencesContainer(paginationItem).focus();
		}
	}

	private getClickablePaginationItems(paginationItem: EventTarget): JQuery {
		return $(paginationItem)
			.closest(DocumentPreviewSelector.PAGINATION_DIV_SELECTOR)
			.find('li :focusable')
			.not(`a${KeyboardSelector.NEGATIVE_TAB_INDEX}`);
	}

	private getSentencesContainer(paginationItem: EventTarget): HTMLElement {
		return $(paginationItem)
			.closest(DocumentPreviewSelector.SENTENCES_PANE_SELECTOR)
			.find(DocumentPreviewSelector.SENTENCES_CONTAINER_SELECTOR)
			.first().get(0) as HTMLElement;
	}

	displaySource(): boolean {
		return !this.isDocExplorer
			|| this.preferences.settings.sentencePane?.showSource !== false;
	}

	displaySentiment(): boolean {
		return this.isDocExplorer
			? this.preferences.settings.sentencePane?.showSentiment !== false
			: this.showSentiment;
	}

}

app.component('sentencesPane', {
	bindings: {
		preferences: '<',
		totalPages: '<',
		formatters: '<',
		documentManager: '<',
		showSentiment: '<',
		noResults: '<',
		isDocExplorer: '<'
	},
	controller: SentencesPane,
	templateUrl: 'partials/document-explorer/panes/sentences-pane-component.html'
});
