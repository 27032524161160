import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef } from '@angular/core';
import { WidgetEditEvent } from '@app/core/cx-event.enum';
import { CxLocaleService } from '@app/core/cx-locale.service';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { SidebarEditorService } from '@app/modules/layout/sidebar-editor/sidebar-editor.service';
import { PreviewChartRefreshType } from '@app/modules/reports/real-data-preview/preview-chart-refresh-type.enum';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CloudD3UtilsService } from '@app/modules/widget-visualizations/utilities/cloud-d3-utils.service';
import { ObjectUtils } from '@app/util/object-utils';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import ICurrentWidgets from '@cxstudio/dashboards/widgets/current-widgets.service';
import { CloudShape, CloudSizing } from '@cxstudio/dashboards/widgets/type-definitions/cloud-visual-properties';
import { CloudWidgetType } from '@cxstudio/dashboards/widgets/type-definitions/cloud-widget-type';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { DatePeriod, DatePeriodField, DatePeriodName } from '@cxstudio/reports/entities/date-period';
import { ReportGrouping } from '@cxstudio/reports/entities/report-grouping';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { CalculationColorService } from '@cxstudio/reports/utils/color/calculation-color-service.service';
import { GroupColorService } from '@cxstudio/reports/utils/color/group-color.service';
import { HierarchyService } from '@cxstudio/services/hierarchy-service.service';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import WidgetService from '@cxstudio/services/widget-service';
import { AnyGrouping } from '@cxstudio/reports/entities/any-grouping';

@Component({
	selector: 'cloud-settings',
	templateUrl: './cloud-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CloudSettingsComponent extends SelfCleaningComponent implements OnInit {
	CloudSizing = CloudSizing;

	widget: CloudWidgetType;

	project: WorkspaceProject;
	defaultSelectionPath: any[];
	orientationOptions: UIOption<number>[];
	shapeOptions: UIOption<CloudShape>[];
	hierarchyGrouping: ReportGrouping | null;

	private constants;

	colorSelectorFilter: string[];

	periods: DatePeriod[];

	constructor(
		private ref: ChangeDetectorRef,
		private sidebarEditor: SidebarEditorService,
		private locale: CxLocaleService,
		@Inject('groupColorService') private groupColorService: GroupColorService,
		@Inject('calculationColorService') private calculationColorService: CalculationColorService,
		private cloudD3Utils: CloudD3UtilsService,
		private eventBus: GlobalEventBus,
		@Inject('metricConstants') metricConstants: MetricConstants,
		@Inject('hierarchyService') private hierarchyService: HierarchyService,
		@Inject('currentWidgets') private currentWidgets: ICurrentWidgets,
		@Inject('widgetService') private readonly widgetService: WidgetService,
	) {
		super();
		this.constants = metricConstants.get();
	}

	ngOnInit(): void {
		this.initOptions();
		this.widget = this.sidebarEditor.getEntity();
		this.defaultSelectionPath = [
			{ name: OptionsConstant.NLP },
			{ name: OptionsConstant.WORDS },
			{ name: ClarabridgeMetricName.WORDS }
		];
		this.project = ObjectUtils.copy(this.widget.properties.workspaceProject);
		this.initProps();

		this.colorSelectorFilter = ['palette', 'provider', 'solid', 'group', 'calculation'];

		this.periods = [{
			field: DatePeriodField.PERIOD1,
			name: DatePeriodName.PERIOD1
		}];

		this.addListener(this.eventBus.subscribe(WidgetEditEvent.WORKSPACE_PROJECT_UPDATE,
			(event, workspaceProject: WorkspaceProject, needReset = false)  => {
				this.project = ObjectUtils.copy(workspaceProject);
				if (needReset) {
					this.resetSelections();
				}
				this.ref.detectChanges();
			}));
	}

	initOptions(): void {
		this.orientationOptions = [
			{value: 1, displayName: '1'},
			{value: 2, displayName: '2'},
			{value: 3, displayName: '3'},
			{value: 4, displayName: '4'},
		];
		this.shapeOptions = [
			{ value: CloudShape.CLOUD, displayName: this.locale.getString('widget.cloud') },
			{ value: CloudShape.RECTANGLE, displayName: this.locale.getString('widget.cloudShapeRectangle') }
		];
	}

	initProps(): void {
		if (!this.widget.properties.selectedAttributes) {
			this.widget.properties.selectedAttributes = [];
		}

		if (!this.widget.visualProperties.visualization) {
			this.widget.visualProperties.visualization = WidgetVisualization.CLOUD;
		}

		if (this.widget.created) {
			this.widget.visualProperties.color = this.getDefaultColor();
		}

		if (!this.widget.visualProperties.attributeSelections) {
			this.widget.visualProperties.attributeSelections = {};
		}

		if (!this.widget.properties.selectedMetrics) {
			this.widget.properties.selectedMetrics = [];
			this.selectCalculation(this.constants.VOLUME);
		}

		if (!this.widget.visualProperties.periodLabel) {
			this.widget.visualProperties.periodLabel = {};
		}

		this.cloudD3Utils.populateDefaultOptions(this.widget.visualProperties);
		this.sidebarEditor.setEntity(this.widget);
	}

	private resetSelections(): void {
		this.widget.properties.selectedMetrics = [];
		this.widget.properties.selectedAttributes = [];
		this.selectCalculation(this.constants.VOLUME);
		this.widget.visualProperties.color = this.getDefaultColor();
	}

	private getDefaultColor(): string {
		return InternalProjectTypes.isStudioAdminProject(this.widget.properties.workspaceProject?.projectId) ?
			undefined :
			this.calculationColorService.SENTIMENT5;
	}

	onSizingToggle(linear: boolean) {
		this.widget.visualProperties.cloudSizing = linear ? CloudSizing.LINEAR : CloudSizing.DEFAULT;
		this.updateChartUI();
	}

	onGroupingChange(grouping: ReportGrouping): void {
		if (!grouping) {
			return;
		}
		const index = 0;
		grouping.identifier = GroupIdentifierHelper.generateIdentifier(grouping, index);
		this.widget.properties.selectedAttributes[0] = grouping;
		this.widget.properties.selectedAttributes = [...this.widget.properties.selectedAttributes];
		this.widget.visualProperties.primaryGroup = grouping.name;
		if (this.groupColorService.isGroupColorSupported(grouping)) {
			this.widget.visualProperties.color = this.groupColorService.GROUP_COLOR_PREFIX + grouping.name;
		}
		if (AnalyticMetricTypes.isHierarchyModel(grouping)) {
			this.hierarchyGrouping = grouping;
		} else {
			this.hierarchyGrouping = null;
		}
		this.updateChart();
	}

	onCalculationChange = (node: ReportCalculation): void => {
		this.selectCalculation(node);
		this.updateChart();
	};

	//would be changed once new calculation selector added
	selectCalculation = (node: ReportCalculation) => {
		let calculation: ReportCalculation = ObjectUtils.copy(node);

		this.widget.properties.selectedMetrics[0] = calculation;
		this.widget.properties.selectedMetrics = [...this.widget.properties.selectedMetrics];

		this.widget.visualProperties.attributeSelections.size = calculation;
	};

	onColorMetricChange(metricForColoring: ReportCalculation): void {
		let selectedMetrics: ReportCalculation[] = [this.widget.properties.selectedMetrics[0]];

		if (metricForColoring) {
			selectedMetrics.push(metricForColoring);
		}

		this.widget.properties.selectedMetrics = _.uniq(_.filter(selectedMetrics), 'name');
	}

	onCustomColorChange(customColor: string): void {
		this.widget.visualProperties.customColor = customColor;
	}

	updateColorSelection(colorSelection: string): void {
		this.widget.visualProperties.color = colorSelection;
		this.updateChart();
	}

	updateCustomColor(customColor: string): void {
		this.widget.visualProperties.customColor = customColor;
		this.updateChartUI();
	}

	updateChart(refreshType?: PreviewChartRefreshType): void {
		this.widgetService.updateAutoTitleAsync(this.widget);
		this.sidebarEditor.setPreviewUpdateChange({entity: this.widget, refreshType});
	}

	updateChartUI(): void {
		this.updateChart(PreviewChartRefreshType.UI_ONLY);
	}

	// this potentially should be extracted into some common class for all visualizations
	isPeerReportHierarchyGrouping(grouping: ReportGrouping): boolean {
		return this.isPeerReport() && this.isHierarchyModelGrouping(grouping);
	}

	private isPeerReport(): boolean {
		return this.hierarchyService.isPeerReport(this.currentWidgets.getPersonalization(this.widget.containerId),
			this.widget.properties);
	}

	private isHierarchyModelGrouping(grouping: ReportGrouping): boolean {
		return AnalyticMetricTypes.isHierarchyModel(grouping);
	}

	isSentenceLevel(grouping: ReportGrouping): boolean {
		return AnalyticMetricTypes.isSentenceLevel(grouping, this.widget.properties.selectedAttributes);
	}

	isProjectSelected(): boolean {
		return this.project && WorkspaceTransitionUtils.isProjectSelected(this.project);
	}

	openProperties(grouping: ReportGrouping, template: TemplateRef<unknown>): void {
		const header = `${grouping.displayName}: ${this.locale.getString('dashboard.settingsTitle')}`;
		this.sidebarEditor.openProperties<AnyGrouping>(header, template).then(result => {
			_.extend(grouping, result);
			this.updateChart();
			this.ref.markForCheck();
		}, _.noop);
	}

	isPersonalizationEnabled(): boolean {
		return this.currentWidgets.getPersonalization(this.widget.containerId)?.isHierarchyEnabled();
	}

	getAppliedOrgHierarchyId(): number {
		return this.currentWidgets.getPersonalization(this.widget.containerId)?.getHierarchyId();
	}

}
