import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';


export default class MultiCalculationSelectorComponentController implements ng.IComponentController {

	title: string;
	calculations: any[];
	maxCalculations: number;
	projectIdentifier: ProjectIdentifier;
	onCalculationChange: (change: { $calculation: any; $index: number }) => void;
	onCalculationAdded: () => void;
	onCalculationRemoved: (change: { $index: number }) => void;
	widgetSettingsCache: CachedInvocation;

	constructor(
	) {
	}

	$onInit(): void {
		// required for typescript validation of IController
	}

	moveCalculation = (calculation: any, index: number): void => {
		this.calculations.remove(calculation);
		this.calculations.splice(index, 0, calculation);

		this.commitCalculations();
	};

	private commitCalculations = (): void => {
		this.calculations.forEach(($calculation, $index) => this.onCalculationChange({ $calculation, $index }));
	};

}

app.component('multiCalculationSelector', {
	controller: MultiCalculationSelectorComponentController,
	templateUrl: 'partials/components/multi-calculation-selector.html',
	bindings: {
		title: '@',
		calculations: '=',
		maxCalculations: '<',
		projectIdentifier: '<',
		onCalculationChange: '&',
		onCalculationAdded: '&',
		onCalculationRemoved: '&',
		widgetSettingsCache: '=?',
		errors: '=',
		screenIndex: '<'
	}
});