import { ChangeDetectionStrategy, Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CxLocaleService } from '@app/core';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { MarginType } from '@cxstudio/reports/entities/content-widget-properties';

@Component({
	selector: 'margin-options',
	changeDetection: ChangeDetectionStrategy.OnPush,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => MarginOptionsComponent),
			multi: true
		},
	],
	template: `
	<div class="btn-group" id="text-margins">
		<button
			*ngFor="let opt of options"
			type="button"
			class="btn btn-secondary"
			[ngClass]="{'btn-selected': value === opt.value}"
			(click)="changeHandler(opt.value)">
			{{opt.displayName}}
		</button>
	</div>`
})
export class MarginOptionsComponent implements ControlValueAccessor {
	value: MarginType;

	options: UIOption<MarginType>[] = [
		{value: MarginType.NORMAL, displayName: this.locale.getString('widget.normal')},
		{value: MarginType.NARROW, displayName: this.locale.getString('widget.narrow')}
	];

	//Placeholders for the callbacks which are later provided
	//by the Control Value Accessor
	private onTouch: () => void = _.noop;
	private onChange: (val: MarginType) => void = _.noop;

	constructor(private locale: CxLocaleService) {}

	changeHandler = (value: MarginType): void => {
		this.value = value;
		this.onChange(this.value);
		this.onTouch();
	};

	//From ControlValueAccessor interface
	writeValue(value: MarginType): void {
		if (value !== this.value) {
			this.value = value;
			this.onChange(this.value);
		}
	}

	//From ControlValueAccessor interface
	registerOnChange(fn: any): void {
		this.onChange = fn;
	}

	//From ControlValueAccessor interface
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
}
