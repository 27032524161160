import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { BookTabType } from '@cxstudio/dashboards/entity/book';

@Component({
	selector: 'simple-tab-content',
	template: `
		<i *ngIf="type" class="mr-8" [ngClass]="getIconClass()"></i>
		<span [ngClass]="titleClass">{{name}}</span>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class SimpleTabContentComponent {

	@Input() name: string;
	@Input() type: BookTabType;
	@Input() titleClass: string;

	getIconClass(): string {
		if (this.type === BookTabType.EMBED)
			return 'q-icon-qualtrics-xm';
		return '';
	}

}

app.directive('simpleTabContent', downgradeComponent({component: SimpleTabContentComponent}));
