import { Renderer2, RendererStyleFlags2, AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef,
	Component, ElementRef, HostBinding, Inject, OnDestroy, OnInit, ViewChild
} from '@angular/core';
import { MetricWidgetVisualProperties } from '@app/modules/widget-settings/entities/properties/metric-widget-visual-properties.class';
import { GaugeCssVariables } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-css-variables';
import { GaugeSettings, GaugeSettingsUtils } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/variables/gauge-settings-utils';
import { ComparisonData, HighchartsGaugeDefinitionService, IMetricData } from '@app/modules/widget-visualizations/highcharts/highcharts-gauge/highcharts-gauge-definition.service';
import { HighchartsRendererService } from '@app/modules/widget-visualizations/highcharts/highcharts-renderer.service';
import { HighchartsSingleVisualization } from '@app/modules/widget-visualizations/highcharts/highcharts-single-visualization.class';
import { VisualizationDataService } from '@app/modules/widget-visualizations/visualization-data.service';
import { WidgetSize } from '@app/modules/widget-visualizations/widget-size';
import { ReportUtils } from '@cxstudio/reports/utils/visualization/report-utils.service';
import { CanvasUtils } from '@app/shared/util/canvas-utils.service';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { CxLocaleService } from '@app/core';

@Component({
	selector: 'highcharts-gauge',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div *ngIf="utils" class="d-flex h-100-percent flex-shrink metric-wrapper croppable kb-focusable justify-center"
			 tabindex="0"
			 [attr.aria-label]="getAriaLabel()">
			<div #mainContainer *ngIf="!hasError()" aria-hidden="true"
				 class="gauge-container d-flex justify-center align-items-center w-100-percent h-100-percent overflow-hidden"
				 [ngClass]="getClasses()">
				<div class="gauge-chart">
					<div class="chart-with-metric-container p-relative d-flex flex-direction-column align-center">
						<div #chartContainer class="chart-container" tabindex="-1">
							<figure class="p-absolute"></figure>
						</div>
						<metric-label
							class="main-metric"
							[value]="metricData.displayValue"
							[label]="metricData.label"
						></metric-label>
					</div>
				</div>
				<div *ngIf="metricData.comparisons?.length > 0"
					 class="comparisons d-flex">
					<metric-label *ngFor="let comparison of metricData.comparisons"
								  class="comparison-metric"
								  [ngClass]="comparison.labelClass"
								  [value]="comparison.value"
								  [difference]="comparison.difference"
								  [colorClass]="getColorClass(comparison)"
								  [directionSymbol]="comparison.directionSymbol"
								  [label]="comparison.label"
					></metric-label>
				</div>
			</div>
			<widget-error *ngIf="hasError()" [widgetError]="widgetError" [demo]="demo"></widget-error>
		</div>
		`,
	styles: [`
		.main-metric .metric-value { font-size: var(--metric-font-size); }
		:host .main-metric ::ng-deep .value-affix { font-size: var(--metric-affix-font-size); }
		.comparison-metric .metric-value { font-size: var(--comparison-font-size); }
		:host .comparison-metric ::ng-deep .value-affix { font-size: var(--comparison-affix-font-size); }
		.comparison-metric .metric-difference {
			font-size: var(--delta-font-size);
		}
	`]
})
export class HighchartsGaugeComponent extends HighchartsSingleVisualization
	implements OnInit, AfterViewInit, OnDestroy {

	@HostBinding('class') class = 'w-100-percent h-100-percent';
	@ViewChild('mainContainer', {static: false}) mainContainer: ElementRef<HTMLElement>;
	@ViewChild('chartContainer', {static: false}) chartContainer: ElementRef<HTMLElement>;

	chartOptions: Highcharts.Options;

	metricData: IMetricData;

	currentBaselineIndex: number;

	settingsUtils: GaugeSettingsUtils;
	renderSettings: GaugeSettings;

	constructor(
		ref: ChangeDetectorRef,
		private readonly renderer: Renderer2,
		private readonly locale: CxLocaleService,
		private visualizationDataService: VisualizationDataService,
		private highchartsGaugeDefinition: HighchartsGaugeDefinitionService,
		private highchartsRenderer: HighchartsRendererService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('reportUtils') reportUtils: ReportUtils,
		private readonly canvasUtils: CanvasUtils,
	) {
		super(ref, reportUtils);
	}

	ngOnInit() {
		if (this.checkError(this.visualizationDataService.validateData(this.dataObject, this.widget.name)))
			return;
		this.prepareChartOptions();
		this.settingsUtils = new GaugeSettingsUtils(this.metricData.comparisons, this.canvasUtils);
	}

	ngAfterViewInit(): void {
		if (this.mainContainer)
			this.initResizer(this.mainContainer, () => this.updateUI());
		if (this.chartContainer)
			this.initResizeHandler(this.chartContainer);
		this.renderChart();
		if (this.chartOptions) {
			this.recalculate();
		}
	}

	onChartResize(chart: Highcharts.Chart, currentSize: string, lastSize: string): void {
		super.onChartResize(chart, currentSize, lastSize);
		this.ref.markForCheck();
	}

	private updateUI(): void {
		this.recalculate();
		this.ref.markForCheck();
	}

	private prepareChartOptions(): void {
		this.metricData = this.highchartsGaugeDefinition.getData(this.dataObject,
			this.widget.properties, this.widget.visualProperties as MetricWidgetVisualProperties, this.utils);
		let chartOptions = this.highchartsGaugeDefinition.getChartOptions(this.dataObject,
			this.widget.properties, this.widget.visualProperties as MetricWidgetVisualProperties, this.utils, () => this.updateUI());
		this.chartOptions = chartOptions;
	}

	private renderChart() {
		this.clearChart();
		if (this.chartOptions) {
			// rendering to "absolute" positioned child to let flex automatically define container size
			this.chart = this.highchartsRenderer.renderChart(this.chartOptions as unknown,
				this.chartContainer.nativeElement.firstElementChild as HTMLElement,
				this.highchartsRenderer.pointHighlightingCallback(this.utils, this.demo));
		}
	}

	getClasses(): string[] {
		if (!this.mainContainer)
			return [];
		return [
			this.renderSettings.layout
		];
	}

	private recalculate() {
		const smallAfixes = this.betaFeaturesService.isFeatureEnabled(BetaFeature.METRIC_WIDGET_SMALL_AFFIX);
		this.renderSettings = this.settingsUtils.getGaugeSettings(this.getWidgetSize(), smallAfixes);
		this.convertObjectToCssVars(this.renderSettings.cssVars);
	}

	private convertObjectToCssVars(cssVars: Partial<GaugeCssVariables>): void {
		_.mapObject(cssVars, (value, key) => {
			const cssValue = typeof value === 'number' ? `${value}px` : value;
			this.renderer.setStyle(this.mainContainer.nativeElement,
				`--${key.toKabobCase()}`, `${cssValue}`, RendererStyleFlags2.DashCase);
		});
	}

	private getWidgetSize = (): WidgetSize => {
		return {
			height: this.mainContainer.nativeElement.offsetHeight,
			width: this.mainContainer.nativeElement.offsetWidth
		};
	};

	getColorClass = (comparison: ComparisonData): string => {
		return comparison.colorClass;
	};

	getAriaLabel(): string {
		return `${this.locale.getString('widget.gaugeMetricValue')}: ${this.metricData?.displayValue.value}`;
	}
}
