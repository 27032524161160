import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'excludeValue',
	pure: false
})
export class ExcludeValuePipe implements PipeTransform {
	transform(value: any, args?: any): any {
		if (!value)
			return;
		
		if (args instanceof Array) {
			return value.filter(item => !_.findWhere(args, { id: item.id }));
		}

		return value.filter(item => item.id !== args);
	}
}
