import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
export enum DatePeriodType {
	DAY = 'day',
	WEEK = 'week',
	MONTH = 'month',
	QUARTER = 'quarter',
	YEAR = 'year'
}

export interface IDatePeriod {
	index: number;
	displayName?: string;
	displayNameKey: string;
	value: DatePeriodType;
	cost: number;
}

export const PERIODS: IDatePeriod[] = [
	{index: 0, displayNameKey: 'widget.days', value: DatePeriodType.DAY, cost: 1 / 366},
	{index: 1, displayNameKey: 'widget.weeks', value: DatePeriodType.WEEK, cost: 1 / 53},
	{index: 2, displayNameKey: 'widget.months', value: DatePeriodType.MONTH, cost: 1 / 12},
	{index: 3, displayNameKey: 'widget.quarters', value: DatePeriodType.QUARTER, cost: 1 / 4},
	{index: 4, displayNameKey: 'widget.years', value: DatePeriodType.YEAR, cost: 1}
];

@Injectable({
	providedIn: 'root'
})
export class DatePeriodConstantsService {
	
	constructor(
		private locale: CxLocaleService
	) {}

	getPeriod = (period: DatePeriodType): IDatePeriod => {
		const periods = this.getPeriods();
		return _.findWhere(periods, {value: period}) || periods[0];
	};

	getPeriods = (): IDatePeriod[] => {
		return PERIODS.map(p => ({
			...p,
			displayName: this.locale.getString(p.displayNameKey)
		}));
	};
}

app.service('datePeriodConstants', downgradeInjectable(DatePeriodConstantsService));
