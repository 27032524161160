import { Injectable } from '@angular/core';
import { CustomMathAdapterUtils } from '@app/modules/metric/definition/custom-math/adapter/custom-math-adapter-utils.class';
import { CustomMathExpressionAdapter} from '@app/modules/metric/definition/custom-math/adapter/custom-math-expression-adapter';
import { FormulaSegment, TextToken, TextTokenType  } from '@app/modules/metric/definition/custom-math/adapter/formula-segment';
import { CustomMathAssets } from '@app/modules/metric/definition/custom-math/adapter/custom-math-assets';
import { CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';
import { ListOption } from '@app/shared/components/forms/list-option';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { SystemExpressionItem } from '@cxstudio/metrics/custom-math/system-expression-item.class';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { StandardMetricName } from '@cxstudio/reports/providers/cb/constants/standard-metrics-names';


@Injectable({providedIn: 'root'})
export class SystemMetricAdapter implements CustomMathExpressionAdapter {
	private systemMetrics: ListOption<string>[];

	constructor() {
		this.systemMetrics = [{
			name: 'Volume',
			value: StandardMetricName.VOLUME
		}, {
			name: '% Total',
			value: StandardMetricName.PERCENT_OF_TOTAL
		}, {
			name: '% Parent',
			value: StandardMetricName.PARENT_PERCENT
		}, {
			name: 'Sentiment',
			value: PredefinedMetricConstants.SENTIMENT
		}, {
			name: 'Effort',
			value: PredefinedMetricConstants.EASE_SCORE
		}, {
			name: 'Emotional Intensity',
			value: PredefinedMetricConstants.EMOTION
		}];
	}
	
	parseToken(token: CustomMathToken, assets: CustomMathAssets): FormulaSegment {
		let metric = this.findSystemMetricByName(token.value);

		let escapedValue = token.value ? CustomMathAdapterUtils.escapeSpecialChars(token.value) : '';
		let textToken: TextToken = {text: escapedValue, type: TextTokenType.PREDEFINED_METRIC}; 
		if (!metric) {
			textToken.errors = [CustomMathErrorType.INVALID_TEXT];
		}

		let segment: FormulaSegment = {
			text: token.text,
			startOffset: token.offset,
			textTokens: [
				CustomMathAdapterUtils.openBraceToken(), 
				textToken,
				CustomMathAdapterUtils.closeBraceToken()
			]
		};

		let display = `[${token.value}]`;
		segment.expression = new SystemExpressionItem(metric?.value, display);		
		return segment;
	}
	
	toString(expression: ExpressionItem, assets: CustomMathAssets): string {
		let metricExpression = expression as SystemExpressionItem;
		let systemMetric = this.findSystemMetricByValue(metricExpression.name);
		return `[${systemMetric.name}]`;
	}

	private findSystemMetricByValue = (value: string): ListOption<string> => {
		return _.find(this.systemMetrics, (metric) => metric.value === value);
	};

	private findSystemMetricByName = (name: string): ListOption<string> => {
		return _.find(this.systemMetrics, (metric) => metric.name === name);
	};
}