import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { NLPAttributes } from '@cxstudio/reports/settings/options/nlp-attributes';
import * as _ from 'underscore';
import { MetricConstants } from '../providers/cb/constants/metric-constants.service';


export class WorldAwarenessService {

	private worldAwarenessAttributes: any[];
	private nlpAttributeNames: string[];
	private constants;

	constructor(
		metricConstants: MetricConstants,
		private betaFeaturesService: BetaFeaturesService
	) {
		this.constants = metricConstants.get();

		this.worldAwarenessAttributes = [
			this.constants.CB_INDUSTRY,
			this.constants.CB_COMPANY,
			this.constants.CB_BRAND,
			this.constants.CB_PRODUCT,
			this.constants.DETECTED_FEATURES,
			this.constants.CB_PERSON,
			this.constants.CB_EMAIL,
			this.constants.CB_EVENT,
			this.constants.CB_PHONE,
			this.constants.CB_EMOTICON,
			this.constants.CB_EMOTION,
			this.constants.CB_SENTENCE_TYPE,
			this.constants.CB_DOCUMENT_WORD_COUNT,
			this.constants.CB_CURRENCY,
			this.constants.CB_PROFANITY,
			this.constants.CB_LOYALTY_TENURE,
			this.constants.CB_MEDICAL_CONDITION,
			this.constants.CB_MEDICAL_PROCEDURE,
			this.constants.CB_RX,
			this.constants.REASON_DETECTION,
			this.constants.CB_INTERACTION_TYPE,
			this.constants.CB_INTERACTION_HIGHLIGHTS
		];

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.CHAPTERS)) {
			this.worldAwarenessAttributes.push(this.constants.CB_CHAPTERS);
		}

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.CB_STATED_DURATION)) {
			this.worldAwarenessAttributes.push(this.constants.CB_STATED_DURATION);
		}

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.ISSUE_RESOLUTION_ATTRIBUTES)) {
			this.worldAwarenessAttributes.push(this.constants.CB_CONCLUDING_EVENT);
			this.worldAwarenessAttributes.push(this.constants.CB_CONV_OUTCOME);
			this.worldAwarenessAttributes.push(this.constants.CB_PARTICIPANT_OUTCOME);
		}

		let nlp = new NLPAttributes(metricConstants);
		this.nlpAttributeNames = _.union(nlp.enrichment, nlp.words);
	}

	getWorldAwarenessAttributes = (): any[] => {
		return angular.copy(this.worldAwarenessAttributes);
	};

	isNLPAttribute = (name: string): boolean => {
		return _.contains(this.nlpAttributeNames, name);
	};

	isWordOrLC = (name: string): boolean => {
		return name === this.constants.WORDS_MTOKEN.name
			|| name === this.constants.LC.name
			|| name === this.constants.HASHTAG.name;
	};
}

app.service('worldAwarenessService', WorldAwarenessService);
