import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit} from '@angular/core';
import {downgradeComponent} from '@angular/upgrade/static';
import {CxLocaleService} from '@app/core';
import {CxLocationService} from '@app/core/cx-location.service';
import {UserUploadApiService} from '@app/modules/user-administration/user-upload/user-upload-api.service';
import {
	UserBulkProcessApiService,
	UserUpdateApiService
} from '@app/modules/user-administration/user-upload/user-update-api.service';
import {PromiseUtils} from '@app/util/promise-utils';
import {Security} from '@cxstudio/auth/security-service';
import {UrlService} from '@cxstudio/common/url-service.service';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import {TableColumn} from '@cxstudio/reports/entities/table-column';
import {ExportUtils} from '@cxstudio/reports/utils/export/export-utils.service';
import {RedirectService} from '@cxstudio/services/redirect-service';
import {AsyncTask} from '@app/modules/user-administration/bulk/async-task';
import { AsyncTaskApiService } from '@app/modules/user-administration/bulk/async-task-api.service';
import {UserCreationItemData} from '@app/modules/user-administration/bulk/async-task-item-data';
import {AsyncTaskItemStatus} from '@app/modules/user-administration/bulk/async-task-item-status';
import {AsyncTaskUtils} from '@app/modules/user-administration/bulk/async-task-utils';
import {AlertLevel, ToastService} from '@discover/unified-angular-components/dist/unified-angular-components';
import {
	UserUploadEntity,
	UserUploadErrorField,
	UserUploadPreview,
	UserUploadPreviewColumn,
	UserUploadPreviewRow,
	UserUploadValidationStatus
} from './user-upload-preview';
import {ObjectUtils} from '@app/util/object-utils';
import {GlobalNotificationService} from '@cxstudio/common/global-notification/global-notification-service';
import MediaType from '@app/modules/cx-form/file-upload/media-type';

export enum FileUploadStatus {
	LOADING, SUCCESS, ERROR, SCHEDULING, PROCESSING, INIT
}

interface PreviewRow {
	emailAddress: string;
	licenseType: string;
	firstName: string;
	lastName: string;
	password: string;
	authUniqueId: string;
	customField: string;
	xmAccountId: string;
	xmGlobalUserId: string;
	disable: string;
	groups: string;
	status: string;
	details?: string;
	rowNumber?: number;
}

interface UserFilter {
	textFilter: string;
	statusFilter: UserUploadValidationStatus;
}

interface StatusFilterOption {
	class: string;
	status: UserUploadValidationStatus;
}

@Component({
	selector: 'user-upload-page',
	templateUrl: './user-upload-page.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class UserUploadPageComponent implements OnInit {
	private readonly MAX_FILE_SIZE = 10 * Math.pow(2, 20); //10Mb
	private readonly NOTIFICATION_DURATION = 10;
	readonly MediaType = MediaType;

	readonly DETAILS_COLUMN = {
		name: 'details',
		displayName: this.locale.getString('userAdministration.detailsColumn'),
		formatter: this.tableFormattersService.plainTextFormatter
	};

	readonly ROW_NUMBER_COLUMN = {
		name: 'rowNumber',
		displayName: this.locale.getString('common.row'),
		formatter: this.tableFormattersService.plainTextFormatter,
		width: .05
	};

	/**
	 * Update, as opposed to create. in both cases, it's still bulk
	 */
	@Input() bulkUpdate: boolean;

	statuses = FileUploadStatus;
	fileUploadStatus: FileUploadStatus;

	showStatusFilter: boolean;

	filter: UserFilter = {
		textFilter: '',
		statusFilter: UserUploadValidationStatus.INVALID
	};

	filteredUsers: PreviewRow[] = [];

	statusFilterOptions: StatusFilterOption[] = [{
		class: 'q-icon-accessdenied text-danger',
		status: UserUploadValidationStatus.INVALID
	}, {
		class: 'q-icon-warning text-warning',
		status: UserUploadValidationStatus.DUPLICATE

	}, {
		class: 'q-icon-check text-success',
		status: UserUploadValidationStatus.VALID
	}];

	showProcessingResult: boolean = false;
	fileSummary: string;
	fileUsers: PreviewRow[] = [];
	currentTabColumns: Array<TableColumn<PreviewRow>> = [];
	private taskId: string;

	forceRegister = false;

	loading: Promise<any>;
	service: UserBulkProcessApiService;
	duplicateTabColumns: TableColumn<PreviewRow>[];
	successTabColumns: TableColumn<PreviewRow>[];
	allColumns: TableColumn<PreviewRow>[];
	readonly ERROR_TEMPLATE = `
		<span class="text-danger d-flex align-items-center">
			<span class="q-icon q-icon-accessdenied mr-4" aria-hidden="true"></span>
			<%= value %>
		</span>`;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private location: CxLocationService,
		private userUploadApiService: UserUploadApiService,
		private userUpdateApiService: UserUpdateApiService,
		private toastService: ToastService,
		private asyncTaskApiService: AsyncTaskApiService,
		@Inject('exportUtils') private exportUtils: ExportUtils,
		@Inject('redirectService') private redirectService: RedirectService,
		@Inject('urlService') private urlService: UrlService,
		@Inject('security') private security: Security,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
		@Inject('globalNotificationService') private globalNotificationService: GlobalNotificationService,
	) { }

	ngOnInit(): void {
		if (this.bulkUpdate) {
			this.statusFilterOptions = this.statusFilterOptions.filter(option => option.status !== UserUploadValidationStatus.DUPLICATE);
		}

		this.service = this.bulkUpdate
			? this.userUpdateApiService
			: this.userUploadApiService;

		this.handleQueryParams();
	}

	handleQueryParams(): void {
		if (this.isOpenFromNotification()) {
			let taskId = this.location.search().taskId;
			this.fileUploadStatus = FileUploadStatus.INIT;
			let loadTaskPromise = this.asyncTaskApiService.getTaskStatus<UserCreationItemData>(taskId);
			let loadColumnsPromise = this.service.getColumns();
			this.loading = PromiseUtils.all([loadTaskPromise, loadColumnsPromise]).then((responses) => {
				this.fileUploadStatus = FileUploadStatus.PROCESSING;
				let task = responses[0];
				let columns = responses[1];
				if (!isEmpty(task)) {
					this.allColumns = this.getFileColumns(columns);
					this.setTabColumnDefinitions();
					this.currentTabColumns = this.getColumnDefinitionForCurrentTab();
					this.fileUsers = this.getTaskUsers(task);
					this.filteredUsers = ObjectUtils.copy(this.fileUsers);
					this.showProcessingResult = true;
					this.showStatusFilter = false;
					this.applyUserFilter(this.filter);
					this.ref.detectChanges();
				} else {
					this.globalNotificationService.showWarning(
						this.locale.getString('userAdministration.taskNotFound', { taskId }), this.NOTIFICATION_DURATION);
				}
			});
		}
	}

	/**
	 * Set definitions for columns for secondary tabs that don't show all columns
	 * Requires result from backend before unique identifier column can be defined
	 */
	private setTabColumnDefinitions(): void {
		this.duplicateTabColumns = [
			{...this.ROW_NUMBER_COLUMN, formatter: (obj) => _.template(this.ERROR_TEMPLATE)({value: obj.rowNumber})},
			this.getColumnDefinition({ field: this.getIdentifierProperty(this.allColumns), filterable: true }),
			{...this.DETAILS_COLUMN, formatter: () => this.getIdIsDuplicatedInSystemWarning()}
		];

		this.successTabColumns = [
			this.ROW_NUMBER_COLUMN,
			this.getColumnDefinition({ field: 'firstName', filterable: true }),
			this.getColumnDefinition({ field: 'lastName', filterable: true }),
			this.getColumnDefinition({ field: this.getIdentifierProperty(this.allColumns), filterable: true }),
		];

		if (this.bulkUpdate) {
			this.successTabColumns.push(this.getDisableColumn());
		}

		this.successTabColumns.push({
			name: undefined,
			displayName: this.locale.getString('common.status'),
			formatter: () => this.fileUploadStatus === FileUploadStatus.PROCESSING ?
				this.locale.getString('common.submitted') :
				this.locale.getString('common.pending')
		});
	}

	private getIdentifierProperty(columnDefs: TableColumn<PreviewRow>[]): 'authUniqueId' | 'emailAddress' {
		return !!columnDefs.find(col => col.name === 'authUniqueId') ?
			'authUniqueId' :
			'emailAddress';
	}

	private getDisableColumn(): TableColumn<PreviewRow> {
		if (this.bulkUpdate) {
			return this.getColumnDefinition({ field: 'disable', filterable: true });
		}
	}

	private getStatusLabel(status: AsyncTaskItemStatus): string {
		return this.locale.getString(AsyncTaskUtils.getStatusLabelKey(status));
	}

	downloadTemplate(): void {
		this.service.downloadTemplateFile()
			.then(response => this.exportUtils.downloadXLSX(response));
	}

	hasData(): boolean {
		return !_.isEmpty(this.fileUsers);
	}

	getFileSummary(): string {
		let totalCount = this.fileUsers.length;
		let validCount = this.getCount(UserUploadValidationStatus.VALID);
		let invalidCount = this.getCount(UserUploadValidationStatus.INVALID);
		if (this.bulkUpdate) {
			return this.locale.getString('userAdministration.updateFileSummary',
				{ totalCount, validCount, invalidCount });
		}
		let duplicateCount = this.getCount(UserUploadValidationStatus.DUPLICATE);
		return this.locale.getString('userAdministration.fileSummary',
			{ totalCount, validCount, invalidCount, duplicateCount });
	}

	getCount(status: UserUploadValidationStatus): number {
		return this.fileUsers?.filter(user => user.status === status).length;
	}

	forceRegisterDisabled = (): boolean => {
		return this.security.isForcedExternalAuthentication();
	};

	hasItemsToProcess(): boolean {
		return this.taskId && this.fileUploadStatus === FileUploadStatus.SUCCESS;
	}

	getFilterOptionTitle(statusFilter: StatusFilterOption): string {
		let key = `userAdministration.${statusFilter.status.toLowerCase()}Title`;
		let count = this.getCount(statusFilter.status);
		return this.locale.getString(key, {count});
	}

	/**
	 * Return column definitions based on status filter
	 */
	getColumnDefinitionForCurrentTab(): TableColumn<PreviewRow>[] {
		if (this.filter.statusFilter === UserUploadValidationStatus.DUPLICATE) {
			return this.duplicateTabColumns;
		} else if (this.filter.statusFilter === UserUploadValidationStatus.VALID) {
			return this.successTabColumns;
		}

		return this.allColumns;
	}

	updateStatusFilter(statusFilter: UserUploadValidationStatus): void {
		this.filter.statusFilter = statusFilter;
		this.currentTabColumns = this.getColumnDefinitionForCurrentTab();

		this.applyUserFilter(this.filter);
		this.ref.detectChanges();
	}

	updateTextFilter(textFilter: string): void {
		this.filter.textFilter = textFilter;
		this.applyUserFilter(this.filter);
		this.ref.detectChanges();
	}

	private applyUserFilter(filter: UserFilter) {
		let columns = this.currentTabColumns.filter(column => column.filterable);
		this.filteredUsers = _.chain(this.fileUsers)
			.filter(user => !this.showStatusFilter || user.status === filter.statusFilter)
			.filter(user => {
				if (_.isUndefined(this.filter.textFilter)) return true;
				return _.some(columns, (column) => {
					return !isEmpty(user[column.name])
							? user[column.name].toLowerCase().indexOf(this.filter.textFilter.toLowerCase()) >= 0
							: false;
				});
			})
			.value();
	}

	save(): void {
		if (!this.hasItemsToProcess()) return;
		this.fileUploadStatus = FileUploadStatus.SCHEDULING;

		this.updateValidUsersStatus(AsyncTaskItemStatus.PENDING);

		let promise = this.service.processFileUpload(this.taskId);

		promise.then(
			() => {
				this.toastService.addToast(this.locale.getString('userAdministration.taskSubmitted'), AlertLevel.SUCCESS);
				this.fileUploadStatus = FileUploadStatus.PROCESSING;
				this.updateValidUsersStatus(AsyncTaskItemStatus.SUBMITTED);
		},	() => {
				this.fileUploadStatus = FileUploadStatus.PROCESSING;
				this.updateValidUsersStatus(AsyncTaskItemStatus.FAIL);
		});
	}

	private updateValidUsersStatus(status: AsyncTaskItemStatus) {
		let statusLabel: string = this.getStatusLabel(status);

		_.chain(this.fileUsers)
			.filter(user => user.status === UserUploadValidationStatus.VALID)
			.each(user => user.details = statusLabel);

		this.applyUserFilter(this.filter);
		this.ref.detectChanges();
	}

	cancel(): void {
		this.redirectService.goToUserManagement();
	}

	/**
	 * Identifies whether the page was open via a status update in notifications, which requires slightly different display
	 */
	private isOpenFromNotification = (): boolean => {
		return typeof this.location.search().taskId !== 'undefined';
	};

	private getColumnDefinition = (dataColumn: UserUploadPreviewColumn): TableColumn<PreviewRow> => {
		let displayName = dataColumn.label
				|| this.locale.getString(`userAdministration.${dataColumn.field}Column`);
			return {
				name: dataColumn.field,
				displayName,
				filterable: dataColumn.filterable,
				formatter: this.tableFormattersService.plainTextFormatter,
			} as TableColumn<PreviewRow>;
	};

	private getFileColumns(dataColumns: UserUploadPreviewColumn[]): Array<TableColumn<PreviewRow>> {
		let columns = dataColumns.map(this.getColumnDefinition);
		columns.push(this.DETAILS_COLUMN);
		if (!this.isOpenFromNotification() || this.fileUploadStatus === FileUploadStatus.SUCCESS) {
			// if we are loading updated status from a notification, we won't know the row numbers anymore
			columns.unshift(this.ROW_NUMBER_COLUMN);
		}
		return columns;
	}

	private getFileUsers(dataRows: UserUploadPreviewRow[]): PreviewRow[] {
		return dataRows.map(dataRow => {
			let details = dataRow.messages ? dataRow.messages.join('<br>') : '';
			return this.getPreviewRow(dataRow.user, dataRow.groups, dataRow.validationStatus,
				details, dataRow.errorFields, dataRow.rowNumber);
		});
	}

	private getTaskUsers(task: AsyncTask<UserCreationItemData>): PreviewRow[] {
		return task.items.map(item => {
			return this.getPreviewRow(item.data.user, item.data.groups,
				UserUploadValidationStatus.VALID, this.getStatusLabel(item.status));
		});
	}

	private getIdIsDuplicatedInSystemWarning(): string {
		let item = this.locale.getString(`userAdministration.emailAddressColumn`);
		return this.locale.getString('common.itemIsElsewhereInSystem', {item});
	}

	private getPreviewRow(
		user: UserUploadEntity, groups: string[],
		status: UserUploadValidationStatus, details?: string,
		errorFields?: UserUploadErrorField[], rowNumber?: number
	): PreviewRow {
		let validationWrapper = this.getInvalidFieldWrapper(errorFields);
		return {
			firstName: validationWrapper(user.firstName, UserUploadErrorField.FIRST_NAME),
			lastName: validationWrapper(user.lastName, UserUploadErrorField.LAST_NAME),
			emailAddress: validationWrapper(user.emailAddress, UserUploadErrorField.EMAIL),
			password: validationWrapper(user.password, UserUploadErrorField.PASSWORD),
			licenseType: validationWrapper(user.licenseTypeName, UserUploadErrorField.LICENSE_TYPE),
			authUniqueId: validationWrapper(user.authUniqueId, UserUploadErrorField.AUTH_UNIQUE_ID),
			xmAccountId: validationWrapper(user.xmAccountId, UserUploadErrorField.XM_ACCOUNT_ID),
			xmGlobalUserId: validationWrapper(user.xmGlobalUserId, UserUploadErrorField.XM_GLOBAL_USER_ID),
			disable: validationWrapper(user.toBeDisabled, UserUploadErrorField.DISABLE),
			customField: user.customField,
			groups: validationWrapper(groups ? groups.join(', ') : '', UserUploadErrorField.GROUP_NAME),
			status,
			details,
			rowNumber
		};
	}

	/**
	 * Wrap with error styling if necessary
	 */
	private getInvalidFieldWrapper(errors: UserUploadErrorField[] = []) {
		return (value: string, field: UserUploadErrorField) => {
			let template = `<%= value %>`;
			if (errors.includes(field)) {
				template = this.ERROR_TEMPLATE;
			}

			return _.template(template)({value});
		};
	}

	getTitle(): string {
		return this.bulkUpdate ?
			this.locale.getString('header.userBulkUpdate') :
			this.locale.getString('header.userUpload');
	}

	getFileUploadUrl(): string {
		if (this.bulkUpdate) {
			return this.urlService.getAPIUrl('rest/users/update/file/validate');
		}

		return this.urlService.getAPIUrl('rest/users/upload/file/validate');
	}

	getFileUploadMetadata(): { forceRegister: boolean } {
		return {
			forceRegister: this.forceRegister
		};
	}

	handleSuccessfulUpload(uploadPreview: UserUploadPreview) {
		this.fileUploadStatus = FileUploadStatus.SUCCESS;
		this.allColumns = this.getFileColumns(uploadPreview.columns);
		this.setTabColumnDefinitions();
		this.currentTabColumns = this.getColumnDefinitionForCurrentTab();
		this.fileUsers = this.getFileUsers(uploadPreview.rows);
		this.filteredUsers = ObjectUtils.copy(this.fileUsers);
		this.fileSummary = this.getFileSummary();
		this.showProcessingResult = true;
		this.showStatusFilter = true;
		this.taskId = uploadPreview.taskId;
		this.applyUserFilter(this.filter);
		this.ref.detectChanges();
	}

	handleFailedUpload() {
		this.taskId = null;
		this.fileUploadStatus = FileUploadStatus.ERROR;
		this.ref.detectChanges();
	}

	handleRemovedUpload() {
		this.showProcessingResult = false;
		this.filter.statusFilter = UserUploadValidationStatus.INVALID;
		delete this.filter.textFilter;
		delete this.fileUsers;
		delete this.filteredUsers;
		delete this.fileSummary;
		delete this.taskId;
		this.ref.detectChanges();
	}
}

app.directive('userUploadPage',
		downgradeComponent({component: UserUploadPageComponent}));
