import { Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { SelectAttributeModalTypes } from '@app/modules/hierarchy/attributes/select-attribute-modal-types';
import { downgradeInjectable } from '@angular/upgrade/static';

@Injectable({
	providedIn: 'root'
})
export class SelectAttributeModalTypesService {
	constructor(
		private readonly locale: CxLocaleService
	) { }

	getSelectAttrsForDisplayText = (modalType: SelectAttributeModalTypes, maxAttributes: number): string => {
		switch (modalType) {

			case SelectAttributeModalTypes.DOC_EXPLORER:
			case SelectAttributeModalTypes.PREVIEW_WIDGET:
				return this.isRequiresModels(modalType)
					? this.locale.getString('administration.selectAttrsAndModelsForDisplayDocExplorer', {
						viewType: this.getAttrViewType(modalType)
					})
					: this.locale.getString('administration.selectAttrsForDisplayDocExplorer');
			case SelectAttributeModalTypes.DRILL_MENU:
				return this.locale.getString('administration.selectAttrsAndModelsForDisplayDrillMenu');
			case SelectAttributeModalTypes.ORG_ENRICHMENT:
				return this.locale.getString('administration.editReportableParameters');
		}
	};

	getObjectAvailableDescriptor = (modalType: SelectAttributeModalTypes): string => {
		switch (modalType) {
			case SelectAttributeModalTypes.ORG_ENRICHMENT:
				return this.locale.getString('administration.parametersAvailable');
			case SelectAttributeModalTypes.DRILL_MENU:
				return this.locale.getString('administration.drillable');
			case SelectAttributeModalTypes.DOC_EXPLORER:
				return this.isRequiresModels(modalType) ? this.locale.getString('administration.viewable')
					: this.locale.getString('administration.attrsAvailable');
			case SelectAttributeModalTypes.MOBILE_APP_SETTINGS:
				return this.locale.getString('mobile.attributesAndModelsAvailable');
			default:
				return this.locale.getString('administration.attrsAvailable');
		}
	};

	getObjectSelectedDescriptor = (modalType: SelectAttributeModalTypes): string => {
		switch (modalType) {

			case SelectAttributeModalTypes.ORG_ENRICHMENT:
				return this.locale.getString('administration.parametersSelected');
			case SelectAttributeModalTypes.DRILL_MENU:
				return this.locale.getString('administration.hidden');
			case SelectAttributeModalTypes.DOC_EXPLORER:
				return this.isRequiresModels(modalType) ? this.locale.getString('administration.hidden')
					: this.locale.getString('administration.attrsHidden');
			case SelectAttributeModalTypes.MOBILE_APP_SETTINGS:
				return this.locale.getString('mobile.attributesAndModelsSelected');
			default:
				return this.locale.getString('administration.attrsSelected');
		}
	};

	getOrderByField = (): string => {
		return '\'displayName\'';
	};

	private getAttrViewType(modalType: SelectAttributeModalTypes): string {
		return (modalType === SelectAttributeModalTypes.DOC_EXPLORER) ?
			this.locale.getString('widget.docExplorer') :
			this.locale.getString('docExplorer.toggleContextPane');
	}

	private isRequiresModels = (modalType: SelectAttributeModalTypes): boolean => {
		if (this.isDrillMenuModal(modalType)
			|| this.isPreviewWidgetModal(modalType)
			|| this.isDocExplorerModal(modalType)
			|| this.isMobileAppSettingsModal(modalType)) {
			return true;
		}
		if (this.isDocExplorerModal(modalType)) {
			return true;
		}

		return false;
	};

	private isDrillMenuModal = (modalType: SelectAttributeModalTypes): boolean => {
		return modalType === SelectAttributeModalTypes.DRILL_MENU;
	};

	private isDocExplorerModal = (modalType: SelectAttributeModalTypes): boolean => {
		return modalType === SelectAttributeModalTypes.DOC_EXPLORER;
	};

	private isPreviewWidgetModal = (modalType: SelectAttributeModalTypes): boolean => {
		return modalType === SelectAttributeModalTypes.PREVIEW_WIDGET;
	};

	private isMobileAppSettingsModal = (modalType: SelectAttributeModalTypes): boolean => {
		return modalType === SelectAttributeModalTypes.MOBILE_APP_SETTINGS;
	};
}

app.service('selectAttributeModalTypesService', downgradeInjectable(SelectAttributeModalTypesService));
