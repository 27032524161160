import { HttpHandlers } from '@cxstudio/common/http-handlers';

import * as _ from 'underscore';
import { LinkSharingSettings } from '@cxstudio/master-accounts/settings-components/link-sharing-settings';
import { AssetEditPermissionAction } from '@cxstudio/asset-management/asset-edit-permission-action';
import { CachedHttpService } from '@cxstudio/common/cache/cached-http.service';
import { Caches } from '@cxstudio/common/caches';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { WidgetCacheSettings } from '@app/modules/account-administration/settings/widget-cache-settings/widget-cache-settings.component';
import { IAdminAttribute } from '@app/modules/project/attribute/admin-attribute';
import { MasterAccountProperties } from '@cxstudio/master-accounts/master-account-properties.class';
import { SuggestionType } from '@cxstudio/reports/document-explorer/conversations/suggestion-type.enum';
import { AssetVisibilityUtils } from '@cxstudio/reports/utils/asset-visibility-utils.service';
import { TuningSuggestionsModels, TuningSuggestionsModelsUpdate }
	from '@app/modules/account-administration/tuning-suggestions-tab/topics-tuning-suggestions.component';
import { AssetParameters } from '@app/modules/asset-management/access/asset-parameters/asset-parameters';
import { IReportModel } from '@app/modules/project/model/report-model';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { HttpRequestConfig } from '@cxstudio/common/http-request-config';
import { RequestAssetParameters } from '@app/modules/asset-management/access/asset-parameters/request-asset-parameters.class';
import { ScreeningSettings } from '@app/modules/account-administration/properties/screening-settings-panel/screening-settings-panel.component';
import { AccountOrWorkspaceProject, WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { WorkspaceTransitionUtils } from '@app/modules/units/workspace-project/workspace-transition-utils.class';
import { WidgetEditingPreviewsSettings } from '@app/modules/account-administration/properties/widget-editing-previews-panel/widget-editing-previews-settings-panel.component';
import { MasterAccountContractInfo } from '@cxstudio/master-accounts/contracts/master-account-contract-info';
import { QualtricsEmbeddingSettings } from '@app/modules/account-administration/properties/qualtrics-embedding-panel/qualtrics-embedding-settings-panel.component';

export interface SharingEntitiesConfig {
	simpleOnly?: boolean;
	userIds?: number[];
	groupIds?: number[];
	assetEditAction?: AssetEditPermissionAction;
}

export interface SharingEntitiesSearchConfig {
	simpleOnly?: boolean;
	search?: string;
	searchLimit?: number;
}

export interface IAdminAsset extends IAdminAttribute {
	model?: boolean;
}
export class MasterAccountApiService {

	constructor(
		private readonly $http: ng.IHttpService,
		private readonly httpHandlers: HttpHandlers,
		private readonly cachedHttpService: CachedHttpService
	) { }

	getMasterAccountProperties = (): ng.IPromise<MasterAccountProperties> => {
		return this.$http.get('rest/master_account/properties').then(this.httpHandlers.success);
	};

	updatePasswordPolicy = (passwordPolicy) => {
		return this.$http.post('rest/master_account/password_policy/', passwordPolicy, {});
	};

	getEngagorSettings = () => {
		return this.$http.get('rest/master_account/engagor').then(this.httpHandlers.success);
	};

	updateBrandingColors = (colorMap: {[key: string]: string}): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/branding/colors', colorMap);
	};

	updateBrandingLogo = (logoFile: File): ng.IHttpPromise<void> => {
		let formData = new FormData();
		formData.append('file', logoFile);
		return this.$http.post('rest/master_account/branding/logo', formData);
	};

	deleteBrandingLogo = (): ng.IHttpPromise<void> => {
		return this.$http.delete('rest/master_account/branding/logo');
	};

	updateSmtpProperties = (properties) => {
		return this.$http.put('rest/master_account/smtp-properties', angular.toJson(properties), {});
	};

	getProjectUsers = (contentProviderId, accountId, projectId) => {
		return this.$http.get(`rest/users/filter/contentProvider/${contentProviderId}/account/${accountId}/project/${projectId}`)
			.then((response) => {
				return response.data;
			});
	};

	getSharingEntities = (config?: SharingEntitiesConfig): ng.IPromise<any> => {
		config = config || {};
		return this.$http.post('rest/sharing/entities/getByIds', config).then(this.httpHandlers.success);
	};

	getWholeOrganizationGroup = (hierarchyId: number) => {
		return this.$http.get(`rest/sharing/organization-group/${hierarchyId}`).then(this.httpHandlers.success);
	};

	searchSharingEntities = (config?: SharingEntitiesSearchConfig, queryParams?: any): ng.IPromise<any> => {
		config = config || {};
		let url = 'rest/sharing/entities/search';
		let sharingEntitiesPromise: ng.IPromise<any>;
		if (queryParams) {
			sharingEntitiesPromise = this.$http.post(url, config, {params: queryParams}).then(this.httpHandlers.success);
		} else {
			sharingEntitiesPromise = this.$http.post(url, config).then(this.httpHandlers.success);
		}
		return sharingEntitiesPromise;
	};

	getSharingGroupMembers = (groupId: number) => {
		return this.$http.get('rest/sharing/group/members', { params: { groupId }}).then(this.httpHandlers.success);
	};

	hasEditPermission = (userId: number, action: string) => {
		return this.$http.get('rest/sharing/user/has-permission', { params: { userId, action } }).then(this.httpHandlers.success);
	};

	isEnterpriseViewersEnabled(): ng.IPromise<boolean> {
		return this.$http.get('rest/master_account/enterpriseViewers/enabled').then(this.httpHandlers.success);
	}

	getMasterAccountExists(masterAccountName: string): ng.IPromise<boolean> {
		return this.$http.get(`rest/master_account/exists`, {params: {name: masterAccountName}}).then(this.httpHandlers.success);
	}

	getMasterAccountContractInfo = (): ng.IHttpPromise<MasterAccountContractInfo> => {
		return this.$http.get('rest/master_account/contract_info');
	};

	getConnectorsSettings = () => {
		return this.$http.get('rest/master_account/connectors');
	};

	private getTuningSuggestionsModelsUrl(projectIdentifier: AccountOrWorkspaceProject): string {
		if (WorkspaceTransitionUtils.isWorkspaceProject(projectIdentifier)) {
			const workspaceId: number = projectIdentifier.workspaceId;
			const projectId: number = projectIdentifier.projectId;
			return `rest/master_account/tuning-suggestions/models/workspace/${workspaceId}/project/${projectId}`;
		} else {
			const cpId: number = projectIdentifier.contentProviderId;
			const accountId: number = projectIdentifier.accountId;
			const projectId: number = projectIdentifier.projectId;
			return `rest/master_account/tuning-suggestions/models/content-provider/${cpId}/account/${accountId}/project/${projectId}`;
		}
	}

	getTuningSuggestionsModels(projectIdentifier: AccountOrWorkspaceProject): ng.IPromise<TuningSuggestionsModels> {
		return this.$http.get(this.getTuningSuggestionsModelsUrl(projectIdentifier))
			.then(this.httpHandlers.success, this.httpHandlers.error)
			.then((tuningSuggestionsModelsUpdate: TuningSuggestionsModels) => {
				return {
					allModels: AssetVisibilityUtils.getVisibleModels(tuningSuggestionsModelsUpdate.allModels),
					selectedModels: AssetVisibilityUtils.getVisibleModels(tuningSuggestionsModelsUpdate.selectedModels)
				};
			});
	}

	getTuningSuggestionsAvailableModels(
		projectIdentifier: IProjectSelection,
		workspaceProject: WorkspaceProject,
		assetParameters: AssetParameters
	): ng.IPromise<IReportModel[]> {
		const requestAssetParams = new RequestAssetParameters(assetParameters, projectIdentifier, workspaceProject);
		const config: HttpRequestConfig = {assetParams: requestAssetParams, cache: CacheOptions.CACHED};
		return this.cachedHttpService.cache(Caches.ATTRIBUTES)
			.get(`rest/master_account/tuning-suggestions/models`, config)
			.then(this.httpHandlers.success)
			.then((models: IReportModel[]) => AssetVisibilityUtils.getVisibleModels(models));
	}

	bulkSaveTuningSuggestionsModelsSettings = (projectIdentifier: AccountOrWorkspaceProject,
			tuningSuggestionsModelsUpdate: TuningSuggestionsModelsUpdate): ng.IPromise<any> => {

		return this.$http.put(this.getTuningSuggestionsModelsUrl(projectIdentifier), tuningSuggestionsModelsUpdate)
			.then(this.httpHandlers.invalidateCacheHandler(Caches.ATTRIBUTES));
	};

	updateDocExplorerAttributes = (contentProviderId, accountId, projectId, hiddenAttributes) => {
		let url = `rest/master_account/attributes/doc_explorer/${contentProviderId}/${accountId}/${projectId}`;
		return this.$http.post(url, angular.toJson(hiddenAttributes))
			.then(this.httpHandlers.invalidateCacheHandler(Caches.ASSET_VISIBILITY));
	};

	updateDrillMenuAttributesAndModels = (contentProviderId, accountId, projectId, hiddenAttributes) => {
		// eslint-disable-next-line max-len
		return this.$http.post(`rest/master_account/attributes/drill_menu/contentProvider/${contentProviderId}/accountId/${accountId}/projectId/${projectId}`,
			angular.toJson(hiddenAttributes)).then(this.httpHandlers.invalidateCacheHandler(Caches.ASSET_VISIBILITY));
	};

	updateCustomFieldName = (customField) => {
		let property = { propertyValue: customField };
		return this.$http.put('rest/master_account/update-property/custom-field', property).then(this.httpHandlers.success);
	};

	updateProperty = (propertyName: string, propertyValue: any): ng.IPromise<void> => {
		let property = { propertyName, propertyValue };
		return this.$http.put('rest/master_account/update-property', property).then(this.httpHandlers.success);
	};

	updateTuningSuggestionProperty(type: SuggestionType, value: boolean): ng.IHttpPromise<void> {
		return this.$http.put('rest/master_account/tuning-suggestions/settings', {propertyName: type, propertyValue: value});
	}

	getMasterAccountOwners = (): ng.IPromise<User[]> => {
		return this.$http.get('rest/master_account/account_owners').then(this.httpHandlers.success);
	};

	getMasterAccountCustomerAdmins = (): ng.IHttpPromise<any> => {
		return this.$http.get('rest/master_account/customer_admins');
	};

	saveLinkSharingSettings = (linkSharingSettings: LinkSharingSettings): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/link_sharing_settings', linkSharingSettings);
	};

	saveWidgetCacheSettings = (widgetCacheSettings: WidgetCacheSettings): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/widget_cache_settings', widgetCacheSettings);
	};

	updateScreeningSettings = (screeningSettings: ScreeningSettings): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/screening-settings', screeningSettings);
	};

	updateWidgetEditingPreviewsSettings = (widgetEditingPreviewsSettings: WidgetEditingPreviewsSettings): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/widget-editing-previews-settings', widgetEditingPreviewsSettings);
	};

	updateQualtricsEmbeddingSettings = (qualtricsEmbeddingSettings: QualtricsEmbeddingSettings): ng.IHttpPromise<void> => {
		return this.$http.put('rest/master_account/qualtrics-embedding-settings', qualtricsEmbeddingSettings);
	};

	getBrandingColors(): ng.IPromise<any> {
		return this.$http.get('rest/master_account_properties/branding-colors').then(this.httpHandlers.success);
	}

}

app.service('masterAccountApiService', MasterAccountApiService);
