import { Inject, Injectable } from '@angular/core';
import { IGridDefinition } from '@cxstudio/grids/grid-definition';
import {
	DriversManagementComponent
} from '@cxstudio/drivers/drivers-management.component';
import ManageDriversService from '@cxstudio/drivers/manage-drivers.service';
import { GridFormatter } from '@cxstudio/grids/grid-formatter-service';
import { CommonGridColumns } from '@cxstudio/grids/common-grid-columns.service';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { IGridColumn } from '@cxstudio/grids/grid-column';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { DriversItem, DriversStatus } from '@cxstudio/drivers/entities/drivers-item';
import { DriversType } from '@cxstudio/drivers/entities/drivers-type';
import {
	DriversErrorUtils
} from '@cxstudio/drivers/utils/drivers-error-utils.service';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DateService, DateTimeFormat } from '@cxstudio/services/date-service.service';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';

@Injectable({
	providedIn: 'root'
})
export default class DriversGridDefinition implements IGridDefinition<DriversManagementComponent> {
	constructor(
		private readonly locale: CxLocaleService,
		@Inject('gridFormatterService') private readonly gridFormatterService: GridFormatter,
		@Inject('dateService') private readonly dateService: DateService,
		@Inject('manageDriversService') private manageDriversService: ManageDriversService,
		@Inject('contextMenuTree')  private contextMenuTree: ContextMenuTree,
		@Inject('commonGridColumns') private commonGridColumns: CommonGridColumns
	) {
	}

	init = (_gridMode: GridMode, controller: DriversManagementComponent): Promise<IGridColumn[]> => {
		const onClick = (event, object) => {
			const showHide = {
				name: 'hideToggle',
				text: controller.ui.hideDrivers ? this.locale.getString('dashboard.showAllHidden') : this.locale.getString('dashboard.hideAllHidden'),
				func: () => {
					controller.ui.hideDrivers = !controller.ui.hideDrivers;
				},
				classes: [controller.ui.hideDrivers ? 'items-hidden' : 'items-visible']
			};

			this.contextMenuTree.show(event, object, [showHide], 'header-menu', 360);
		};

		const columns: IGridColumn[] = [
			this.commonGridColumns.getRowSelectorColumn(),
			this.commonGridColumns.getMenuColumn(onClick),
			this.commonGridColumns.getSpacerColumn(),
			{
				id: 'name',
				name: this.locale.getString('drivers.name'),
				field: 'displayName',
				sortable: true,
				minWidth: 280,
				width: 450,
				cssClass: 'cell-title',
				searchable: true,
				formatter: this.gridFormatterService.getNameFormatter(GridTypes.DRIVERS),
				isObjectNameColumn: true
			},
			{
				id: 'created',
				name: this.locale.getString('dashboard.modifiedDate'),
				field: 'modifiedDate',
				sortable: true,
				optional: true,
				minWidth: 100,
				width: 280,
				cssClass: 'cell-date',
				formatter: this.gridFormatterService.DateFormatter
			},
			{
				id: 'ownerName',
				name: this.locale.getString('dashboard.author'),
				field: 'ownerName',
				sortable: true,
				optional: true,
				minWidth: 80,
				width: 230,
				formatter: this.gridFormatterService.getAuthorFormatter()
			},
			{
				id: 'sharingStatus',
				name: this.locale.getString('drivers.sharedStatus'),
				field: 'sharingStatus',
				sortable: true,
				optional: true,
				minWidth: 60,
				width: 150,
				formatter: this.gridFormatterService.SimpleStatusFormatter,
				cssClass: 'cell-status text-center',
				headerCssClass: 'text-center'
			}, {
				id: 'status',
				name: this.locale.getString('dashboard.status'),
				field: 'status',
				sortable: true,
				optional: true,
				minWidth: 60,
				width: 150,
				formatter: this.statusFormatter,
				cssClass: 'cell-status driver-status'
			},
			{
				id: 'project',
				name: this.locale.getString('common.project'),
				field: 'projectName',
				sortable: true,
				optional: true,
				minWidth: 80,
				width: 200,
				searchable: true,
				cssClass: 'cell-project'
			}, {
				id: 'actions',
				name: this.locale.getString('drivers.results'),
				formatter: this.actionsFormatter,
				sortable: false,
				minWidth: 80,
				width: 200,
				cssClass: 'cell-actions'
			}];

		return Promise.resolve(columns);
	};

	private statusFormatter = (_row, _cell, value, _columnDef, dataContext: DriversItem): string => {
		if (dataContext.type !== DriversType.drivers) {
			return '';
		}
		if (this.isErrorMessage(dataContext.errorDetails)) {
			return this.locale.getString('common.error');
		}
		if (DriversErrorUtils.isExpectedError(dataContext.errorDetails)) {
			return this.locale.getString('drivers.needsReview');
		}
		return this.locale.getString('drivers.' + value + 'Status');
	};

	private isErrorMessage(message: string): boolean {
		return message && !DriversErrorUtils.isExpectedError(message);
	}

	private actionsFormatter = (_row, _cell, _value, _columnDef, dataContext: DriversItem): string => {
		if (dataContext.type !== DriversType.drivers) {
			return '';
		}
		if (dataContext.status === DriversStatus.defined) {
			let warning = '';
			if (this.isErrorMessage(dataContext.errorDetails)
				|| DriversErrorUtils.isExpectedError(dataContext.errorDetails)) {
				warning = '<a class="drivers-error" title="{0}"><i class="q-icon-warning"></i></a>'
					.doFormat(this.locale.getString('drivers.driversError'));
				warning += '&nbsp;';
			}
			if (!this.manageDriversService.canEdit(dataContext)) {
				return warning;
			} else {
				let className: string;
				let text: string;
				if (DriversErrorUtils.isExpectedError(dataContext.errorDetails)) {
					className = 'drivers-edit';
					text = this.locale.getString('drivers.checkDefinition');
				} else {
					className = 'drivers-execute';
					text = this.locale.getString('drivers.findDrivers');
				}
				return warning + '<a class="{0}">{1}</a>'.doFormat(className, text);
			}

		}

		if (dataContext.status === DriversStatus.reportable) {
			const runDate = this.dateService.format(dataContext.lastRunDate, DateTimeFormat.BASIC_DATE_TIME) || '';
			return '<a class="drivers-preview" title="{1}">{0}</a>'.doFormat(
				this.locale.getString('drivers.viewDrivers'), runDate
			);
		}

		return '<span class="q-icon-spinner rotate-infinite"></span>';
	};
}

app.service('driversGridDefinition', downgradeInjectable(DriversGridDefinition));
