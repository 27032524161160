import * as _ from 'underscore';
import { PromiseUtils } from '@app/util/promise-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import { ReportAttributesService } from '@app/modules/project/attribute/report-attributes.service';
import { ReportModelsService } from '@app/modules/project/model/report-models.service';
import { ReportAssetUtilsService } from '@app/modules/units/workspace-project/report-asset-utils.service';
import { AssetAccessApiService } from '@app/modules/access-management/api/asset-access-api.service';

export interface IHiddenObject {
	id: number;
	name: string;
	model?: boolean;
}

export class UserObjectsService {

	constructor(
		private readonly reportAttributesService: ReportAttributesService,
		private readonly reportModelsService: ReportModelsService,
		private readonly $q: ng.IQService,
		private readonly reportAssetUtilsService: ReportAssetUtilsService,
		private readonly assetAccessApiService: AssetAccessApiService,
	) {}

	getHiddenAttributesAndModels = (
		widget: Widget, isDoc = false
	): ng.IPromise<IHiddenObject[]> => {
		const project = this.reportAssetUtilsService.getWidgetProject(widget);
		const hiddenAttributesPromise = isDoc
			? PromiseUtils.old(this.assetAccessApiService.getDocExplorerHiddenAssets(project))
			: this.$q.when([]);

		const userHiddenAttributesPromise = PromiseUtils.old(
			this.reportAttributesService.getWidgetUserHiddenAttributes(widget));

		const userHiddenModelsPromise = PromiseUtils.old(
			this.reportModelsService.getWidgetUserHiddenModels(widget));

		return this.$q.all([hiddenAttributesPromise, userHiddenAttributesPromise, userHiddenModelsPromise]).then(result => {
			const hiddenUserAttributes: IHiddenObject[] = result[1];
			let hiddenUserModels = result[2];
			hiddenUserModels = _.each(hiddenUserModels, (model: any) => {
				model.model = true;
			});
			const allHiddenAttributes = hiddenUserAttributes.concat(hiddenUserModels).concat(result[0]);
			return allHiddenAttributes;
		});
	};

}

app.service('userObjectsService', UserObjectsService);
