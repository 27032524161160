import { Inject, Injectable } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { CxDialogService, ModalSize } from '@app/modules/dialog/cx-dialog.service';
import { TextWidgetUtilsService } from '@app/modules/widget-settings/content-widget/text/text-widget-utils.service';
import { Security } from '@cxstudio/auth/security-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardTemplatesApiService } from '@cxstudio/dashboard-templates/api/dashboard-templates-api.service';
import { TemplatePlaceholderService } from '@cxstudio/dashboard-templates/placeholders/template-placeholder-service.service';
import { TemplateWidgetUtils } from '@cxstudio/dashboard-templates/template-widget-utils';
import { DashboardFilter } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter';
import { DashboardFilterTypes } from '@cxstudio/dashboards/dashboard-filters/dashboard-filter-types-constant';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import Widget, { WidgetDisplayType } from '@cxstudio/dashboards/widgets/widget';
import { InternalProjectTypes } from '@cxstudio/internal-projects/internal-project-types.constant';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { WidgetApiService } from '@app/modules/dashboard-edit/widget-api.service';
import * as _ from 'underscore';
import { PromiseUtils } from '@app/util/promise-utils';
import { downgradeInjectable } from '@angular/upgrade/static';
import { CustomTemplate } from '@cxstudio/dashboard-templates/entities/custom-template';
import { TemplateEditDialogComponent } from './template-edit-dialog/template-edit-dialog.component';
import { TemplateEditMappingsDialogComponent } from './template-edit-mappings-dialog/template-edit-mappings-dialog.component';
import { ContentProviderOptionsService } from '@cxstudio/services/data-services/content-provider-options.service';
import { DashboardTemplateCreateDialogComponent } from '@app/modules/unified-templates/dashboard-templates/dashboard-template-create-dialog/dashboard-template-create-dialog.component';

@Injectable({
	providedIn: 'root'
})
export class DashboardTemplateService {
	constructor(
		private readonly cxDialogService: CxDialogService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly locale: CxLocaleService,
		private readonly widgetApiService: WidgetApiService,
		private readonly textWidgetUtils: TextWidgetUtilsService,
		@Inject('contentProviderOptionsService') private readonly contentProviderOptionsService: ContentProviderOptionsService,
		@Inject('maPropertiesService') private readonly maPropertiesService: MAPropertiesService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('templatePlaceholderService') private readonly templatePlaceholderService: TemplatePlaceholderService,
		@Inject('dashboardTemplatesApiService') private readonly dashboardTemplatesApiService: DashboardTemplatesApiService,
		@Inject('security') private readonly security: Security,
	) {}

	createDashboardFromTemplate = (dashboardProperties: Dashboard, template) => {
		let widgets = template.widgets;
		let placeholders = template.placeholders;
		let isStudioAdminDashboard = InternalProjectTypes.isStudioAdminProject(dashboardProperties.properties.project);

		if (!isStudioAdminDashboard && !_.isEmpty(dashboardProperties.appliedFiltersArray)) {
			dashboardProperties.appliedFiltersArray = _.reject(dashboardProperties.appliedFiltersArray,
				filter => this.isStudioAdminFilter(filter));
		}

		widgets.forEach((widget: Widget) => {
			widget.template = true;
			if (!isStudioAdminDashboard && InternalProjectTypes.isStudioAdminProject(widget.properties.project)) {
				widget.properties.selectedAttributes = [];
			}
			if (widget.name === WidgetType.LABEL) {
				this.textWidgetUtils.convertLabelToText(widget);
			}

			if (TemplateWidgetUtils.isPrefilledWidget(widget))
				widget.template = false;
		});

		if (placeholders && (placeholders.groupings.length > 0 || placeholders.calculations.length > 0)) {
			widgets = this.applyPlaceholders(widgets, placeholders);
			this.applyDashboardFilterPlaceholders(placeholders, dashboardProperties);
		}

		return this.dashboardTemplatesApiService.createDashboardFromTemplate({
			templateId: template.id,
			dashboard: dashboardProperties,
			widgets
		});
	};

	private applyDashboardFilterPlaceholders(placeholders, dashboardProperties: Dashboard): void {
		placeholders.groupings.forEach((grouping) => {
			if (grouping.dashboardFilters && grouping.dashboardFilters.length && grouping.replacement) {
				let newDashboardFilter = grouping.replacement.type === 'topics'
					? this.generateTopicDashboardFilterFromReplacement(grouping.replacement)
					: this.generateAttributeDashboardFilterFromReplacement(grouping.replacement);
				dashboardProperties.appliedFiltersArray.push(newDashboardFilter);
			}
		});
	}

	private generateAttributeDashboardFilterFromReplacement(replacement): DashboardFilter {
		let newAttributeFilter = _.extend({filterType: DashboardFilterTypes.ATTRIBUTE}, replacement);
		delete newAttributeFilter.metricType;
		return {selectedAttribute: newAttributeFilter, multiValues: []};

	}
	private generateTopicDashboardFilterFromReplacement(replacement): DashboardFilter {
		let newTopicFilter = {
			displayName: replacement.displayName,
			filterType: DashboardFilterTypes.TOPIC,
			name: replacement.displayName,
			hide: false,
			id: parseInt(replacement.name, 10)
		};
		return {selectedAttribute: newTopicFilter};
	}

	private applyPlaceholders(widgets: Widget[], placeholders): Widget[] {
		let placeholderApplicationResult = this.templatePlaceholderService.applyPlaceholders(widgets, placeholders);

		return placeholderApplicationResult.map((widgetResult) => {
			let widget = widgetResult.widget;

			if (widget.type === WidgetDisplayType.CB) {
				widget.template = widgetResult.missesReplacements;
			}

			return widget;
		});
	}

	// check that user has CPs and the feature is enabled
	templatesEnabled = () => {
		let cpCheck = this.contentProviderOptionsService.getContentProviders();
		let dashboardTemplatesEnabled = this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_TEMPLATES);

		return cpCheck.then((resp) => {
			let contentProviders = resp.data || [];
			return !!(contentProviders.length && dashboardTemplatesEnabled) || this.security.isAdminOrgUser();
		});
	};

	saveDashboardAsTemplate = (dashboard: Dashboard) => {
		let legacyWidgetTypes = [];
		let nonSupportedProjects = [];
		let messageParts = [];

		if (!this.security.has('conduct_security_audit')) {
			nonSupportedProjects.push(InternalProjectTypes.STUDIO_ADMIN_PROJECT_ID);
			messageParts.push(this.locale.getString('templates.legacyWidgetsWarning', {
				widgetsList: this.locale.getString('templates.studioAdminProject')
			}));
		}

		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			legacyWidgetTypes.push('cb_an_scorecard');
			messageParts.push(this.locale.getString('templates.legacyWidgetsWillNotBeCreated', {
				widgets: this.locale.getString('templates.scorecard')
			}));
		}

		this.widgetApiService.checkDashboardHasWidgets(dashboard.id, legacyWidgetTypes, nonSupportedProjects)
			.then((response) => {
				let hasWidgets = response.hasWidgetTypes || response.hasProjects;
				let templateDialogPrecondition = hasWidgets
					? PromiseUtils.wrap(this.showLegacyReportsWarning(messageParts.join(' ')))
					: Promise.resolve();
				return templateDialogPrecondition.then(() => {
					this.showCreateTemplateDialog(dashboard);
				});
			});
	};

	private showLegacyReportsWarning(warningMessage: string): ng.IPromise<void> {
		let skipLegacyWidgetsDialog = this.cbDialogService.confirm(
			this.locale.getString('templates.newTemplateTitle'),
			warningMessage,
			this.locale.getString('common.continue'),
			this.locale.getString('common.cancel'));

		return skipLegacyWidgetsDialog.result;
	}

	private showCreateTemplateDialog(dashboard: Dashboard): void {
		this.cxDialogService
			.openDialog(DashboardTemplateCreateDialogComponent, { dashboard }, {size: ModalSize.MEDIUM})
			.result.then((result) => {}, () => {});
	}

	editDashboardTemplate = (template: CustomTemplate): Promise<void> => {
		return this.cxDialogService
			.openDialog(TemplateEditDialogComponent, { template }, { size: ModalSize.MEDIUM})
			.result;
	};

	editTemplateMappings = (template: CustomTemplate): Promise<any> => {
		return PromiseUtils.wrap(this.dashboardTemplatesApiService.getTemplate(template.id as number)).then(result => {
			let resultTemplate = result as CustomTemplate;
			template.placeholders = resultTemplate.placeholders;
			return this.showEditTemplateMappingsDialog(template);
		});
	};

	private showEditTemplateMappingsDialog(template: CustomTemplate): Promise<any> {
		return this.cxDialogService
			.openDialog(TemplateEditMappingsDialogComponent, { template }, { size: ModalSize.MEDIUM })
			.result;
	}

	addPredefinedDashboardFilters = (template: Dashboard, dashboard: Dashboard) => {
		let appliedFilters = dashboard.appliedFiltersArray;
		let filtersToStore = _.reject(appliedFilters, filter => this.isNonPredefinedFilter(filter));
		this.removeNonPredefinedSavedFilters(filtersToStore);
		filtersToStore = this.removeCustomDateFilters(filtersToStore);
		template.appliedFiltersArray = filtersToStore;
	};

	private isNonPredefinedFilter(filterRule: DashboardFilter): boolean {
		// do not remove studio admin dashboard filters when saving as template
		if (this.isStudioAdminFilter(filterRule)) {
			return false;
		}

		let filterObject = filterRule.selectedAttribute;
		//remove CB attributes listed in CXS-5784
		return _.isUndefined(filterObject) || filterObject.filterType === 'attribute'
			|| filterObject.filterType === 'topic';
	}

	private removeNonPredefinedSavedFilters(filters: DashboardFilter[]): void {
		let savedFilterObject = _.find(filters, filter => filter?.selectedAttribute?.filterType === DashboardFilterTypes.SAVED_FILTER);
		if (savedFilterObject && savedFilterObject.multiValues) {
			savedFilterObject.multiValues = _.filter(savedFilterObject.multiValues, (filterValue) => {
				return filterValue.object.type === FilterTypes.PREDEFINED;
			});
		}
	}

	private removeCustomDateFilters(filters: DashboardFilter[]): DashboardFilter[] {
		let dateFilterObject = _.find(filters, filter => filter?.selectedAttribute?.filterType === DashboardFilterTypes.DATE_RANGE);
		if (dateFilterObject && dateFilterObject.selectedAttributeValue.dateFilterMode.startsWith('customFilter')) {
			return _.reject(filters, filter => filter?.selectedAttribute?.filterType === DashboardFilterTypes.DATE_RANGE);
		}
		return filters;
	}


	private isStudioAdminFilter(filter): boolean {
		return filter && filter.selectedAttribute && InternalProjectTypes.isStudioAdminAttribute(filter.selectedAttribute);
	}

}

app.service('dashboardTemplateService', downgradeInjectable(DashboardTemplateService));
