import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { FilterConditionsService } from '@app/shared/components/filter/filter-condition.service';
import { ColumnConditionOption } from '@app/shared/components/filter/filter-condition-option';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { ListColumnFilter } from '@app/shared/components/filter/list-column-filter';
import { ListOption } from '@app/shared/components/forms/list-option';
import { FilterCondition } from '@app/shared/components/filter/filter-condition';

@Component({
	selector: 'filter-lane',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<div class="d-flex-inline align-items-center">
			<simple-dropdown
				[disabled]="filter.pinned"
				[options]="columns"
				[(value)]="filter.field"
				disableSort="true"
				(onChange)="onColumnChange($event)"
			></simple-dropdown>
			<simple-dropdown
				class="ml-8"
				[disabled]="!conditions || conditions.length === 1"
				[options]="conditions"
				[(value)]="filter.condition"
				disableSort="true"
				(onChange)="onConditionChange()"
			></simple-dropdown>
			<div class="ml-8 filter-value">
				<input *ngIf="!selectedColumn"
					class="w-100-percent"
					disabled="true">
				<input *ngIf="isText()"
					class="w-100-percent"
					type="text"
					[(ngModel)]="filter.value"
					(ngModelChange)="onValueChange()">
				<simple-dropdown *ngIf="isList() || isFlag()"
					[options]="options"
					[(value)]="filter.value"
					selectPrompt="{{'common.all'|i18n}}"
					allowClear="true"
					disableSort="true"
					(onChange)="onValueChange()"
				></simple-dropdown>
				<date-picker-popup *ngIf="isExactDate()"
					[(value)]="filter.date"
					(onChange)="onValueChange()"
				></date-picker-popup>
				<date-range-picker-popup *ngIf="isDateRange()"
					[(fromValue)]="filter.fromDate"
					[(toValue)]="filter.toDate"
					(onChange)="onValueChange()"
				></date-range-picker-popup>
			</div>
		</div>
	`
})

export class FilterLaneComponent implements OnInit {
	@Input() filter: ObjectListFilter;
	@Output() filterChange = new EventEmitter<ObjectListFilter>();
	@Input() columns: ColumnFilterOption[];

	selectedColumn: ColumnFilterOption;
	conditions: ColumnConditionOption[];
	options: Array<ListOption<string>>;

	constructor(
		private filterConditionService: FilterConditionsService
	) {}

	ngOnInit(): void {
		if (this.filter.field) {
			let selected = this.columns.find(column => column.value === this.filter.field);
			this.setColumn(selected);
		}
	}

	private setColumn = (column: ColumnFilterOption): void => {
		this.selectedColumn = column;
		this.conditions = this.filterConditionService.getConditions(column.columnFilterType);
		this.populateListOptions();
	};

	onColumnChange = (column: ColumnFilterOption): void => {
		this.setColumn(column);
		this.filter.condition = this.conditions[0].value;
		this.filter.value = undefined;
		this.onChange();
	};

	onConditionChange = (): void => {
		this.onChange();
	};

	onValueChange = (): void => {
		this.onChange();
	};

	private onChange = (): void => {
		this.filterChange.emit(this.filter);
	};

	isText = (): boolean => {
		return this.selectedColumn?.columnFilterType === ColumnFilterType.TEXT;
	};

	isList = (): boolean => {
		return this.selectedColumn?.columnFilterType === ColumnFilterType.LIST;
	};

	isFlag = (): boolean => {
		return this.selectedColumn?.columnFilterType === ColumnFilterType.FLAG;
	};

	isExactDate = (): boolean => {
		let isExactFilterCondition = this.filter.condition === FilterCondition.AFTER
			|| this.filter.condition === FilterCondition.BEFORE;

		return this.selectedColumn?.columnFilterType === ColumnFilterType.DATE && isExactFilterCondition;
	};

	isDateRange = (): boolean => {
		return this.selectedColumn?.columnFilterType === ColumnFilterType.DATE
			&& this.filter.condition === FilterCondition.BETWEEN;
	};

	private populateListOptions = (): void => {
		if (this.selectedColumn.columnFilterType === ColumnFilterType.LIST) {
			let column: ListColumnFilter = this.selectedColumn as ListColumnFilter;
			if (_.isArray(column.options)) {
				this.options = column.options;
			} else {
				column.options.then(options => this.options = options);
			}
		}
		if (this.selectedColumn.columnFilterType === ColumnFilterType.FLAG) {
			let column: ListColumnFilter = this.selectedColumn as ListColumnFilter;
			if (_.isArray(column.options)) {
				this.options = column.options;
			}
		}
	};
}
