import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';


export enum TableView {
	COMPACT = 'compact',
	COMFORT = 'comfort'
}

/* usage:
	<formatted-table
		[columns]=columnsDefinition
		[rows]=data
	></formatted-table>

	Add [view]="compact" to have smaller paddings
	Add [scrollable]="true" to allow scrolling
*/

@Component({
	selector: 'formatted-table',
	templateUrl: './formatted-table.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormattedTableComponent {
	@Input() columns: Array<TableColumn<any>>;
	@Input() rows: any[];
	@Input() view: TableView = TableView.COMFORT;
	@Input() scrollable: boolean = false;


	getWrapperClasses(): string[] {
		let classes = [];
		if (this.view === TableView.COMFORT) {
			classes.push('cb-table-comfort');
		}
		if (this.view === TableView.COMPACT) {
			classes.push('cb-table-compact');
		}
		if (this.scrollable) {
			classes.push('cb-table-scrollable');
		}
		return classes;
	}

	getCellText(row, column): string {
		let path: string = column.path || column.name;
		return column.formatter ? column.formatter(row, path) : row[path];
	}

	onClick(row, column, event): void {
		if (column.action) {
			column.action(row, event);
		}
	}
}

app.directive('formattedTable', downgradeComponent({component: FormattedTableComponent}));
