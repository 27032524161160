import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import * as CxEvent from '@app/core/cx-event.enum';
import { CxLocationService } from '@app/core/cx-location.service';
import { GlobalEventBus } from '@app/core/global-event-bus.service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { DashboardListService } from '@app/modules/dashboard-list/dashboard-list.service';
import { IHierarchyNode } from '@app/modules/hierarchy/hierarchy-tree-selector/hierarchy-node';
import { UserHomePageService } from '@app/modules/home-page/home-page-layout/user-home-page.service';
import { HomePageWidgetsPanelComponent } from '@app/modules/home-page/home-page-widgets/home-page-widgets-panel/home-page-widgets-panel.component';
import { UserPropertiesApiService } from '@app/modules/user/user-properties-api.service';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { SelfCleaningComponent } from '@app/util/self-cleaning-component';
import { Security } from '@cxstudio/auth/security-service';
import { SlickgridOptions } from '@cxstudio/common/entities/slickgrid-options.class';
import { ITreeItem } from '@cxstudio/common/folders/folder-item.interface';
import { ContextMenuTree } from '@cxstudio/context-menu/context-menu-tree.service';
import { DashboardBookStateService } from '@cxstudio/dashboards/books/dashboard-book-state.service';
import { Book } from '@cxstudio/dashboards/entity/book';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { GridMode } from '@cxstudio/grids/grid-mode';
import { GridTypes } from '@cxstudio/grids/grid-types-constant';
import { GridUtilsService } from '@app/modules/object-list/utilities/grid-utils.service';
import { HeaderConstants } from '@cxstudio/header/header-constants';
import { Interval } from '@cxstudio/interval/interval.service';
import { MAPropertiesService } from '@cxstudio/master-accounts/ma-properties-service.service';
import { MasterAccountProperty } from '@cxstudio/master-accounts/master-account-property.enum';
import { CBDialogService } from '@cxstudio/services/cb-dialog-service';
import { IDashboardListController } from '@app/modules/dashboard-list/services/dashboard-grid-definition.service';
import { ImpersonateUserService } from '@cxstudio/services/impersonate-service.service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { PageTitleUtil } from '@app/core/page-title-util.class';
import { TreeService } from '@cxstudio/services/tree-service.service';
import { User } from '@cxstudio/user-administration/users/entities/user';
import { IRouteParams } from '@app/shared/providers/route-params-provider';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';

export interface DashboardsGridScope {
	tooltipTimer: any;
	refreshFolderHighlighting: (folders: any[]) => void;
	showRatingPopup: (e, dashboard) => void | undefined;
	clearSelections: () => void;
	onMouseLeave: (e: JQuery.MouseLeaveEvent, dashboard: Dashboard, local) => void;
	loading: {
		create: boolean; // create new dashboard
		book: boolean;
		createFolder: boolean;
	};
	tabsEditor: {
		enabled: boolean;
		needRedirectToView: boolean;
		dashboard: Book;
	};
}

enum OAuthClientAlias {
	DOCEBO = 'docebo',
	SALESFORCE = 'salesforce'
}

export enum TooltipType {
	DASHBOARD = 'DASHBOARD',
	SCHEDULE = 'SCHEDULE',
	RATING = 'RATING'
}

@Component({
	selector: 'dashboard-list-page',
	templateUrl: './dashboard-list-page.component.html',
	changeDetection: ChangeDetectionStrategy.Default
})
export class DashboardListPageComponent extends SelfCleaningComponent implements OnInit, OnDestroy {

	//dashboards: any[];
	@Input() isMobile: boolean;
	@Input() isTablet: boolean;

	changedDashboards: ITreeItem[];

	readonly POPUP_TIMEOUT: number = 2000;
	readonly RATING_BAR_SELECTOR = 'br-rating-bar';
	readonly RATING_STAR_SELECTOR = 'br-rating-star';


	@ViewChild(HomePageWidgetsPanelComponent)
	private readonly homePageWidgetsPanelComponent!: HomePageWidgetsPanelComponent;

	//timers
	tooltipTimer;
	timer: any;

	//grid
	gridMode = GridMode.EDIT;
	gridType = GridTypes.DASHBOARD;
	gridOptions: SlickgridOptions;
	gridController: IDashboardListController = {
		isBookEdit: (): boolean => false
	};

	//loading
	loading = {
		create: false, // create new dashboard
		book: null,
		createFolder: false,
	};

	//ui
	ui: {
		hideDashboards: boolean;
		selectedUser: User;
		searchFilter: string;
		dashboardsGridColumns: any[];
	};
	selectedUserDashboards: Dashboard[];
	permissions;
	showDashboardRating: boolean;
	createTemplateFromDashboardEnabled: boolean;
	dashboardsFixedPortSize: boolean;
	lastChecked = null;

	//tooltip
	tooltipStyle = {
		top: 0,
		left: 0,
		visibility: 'hidden',
		opacity: 0
	};
	tooltip: {
		dashboard?: Dashboard;
		type?: TooltipType;
	} = {};

	//menu
	selectionUtils;
	contextMenuUtils;
	actionsService;
	searchFilter: string;
	changeDashboard; // only for mobile
	gridContextMenuItems = [];

	tabsEditor: Partial<{
		enabled: boolean;
		needRedirectToView: boolean;
		dashboard: Book;
	}> = {
			enabled: false
		};

	searchFilterCriteria = [
		{ searchFilterValue: 'books', itemType: 'dashboardSet' },
		{ searchFilterValue: 'dashboards', itemType: 'dashboard' }
	];
	dashboardFilter: string;
	toggleFavoriteAndRefresh: (dashboard: Dashboard) => void;
	visibleList: any[];
	quickSearch: string;

	constructor(
		@Inject('security') private readonly security: Security,
		private readonly cxLocation: CxLocationService,
		@Inject('contextMenuTree') private readonly contextMenuTree: ContextMenuTree,
		private readonly locale: CxLocaleService,
		private readonly userHomePageService: UserHomePageService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('interval') private readonly interval: Interval,
		@Inject('maPropertiesService') private readonly maPropertiesService: MAPropertiesService,
		private readonly betaFeaturesService: BetaFeaturesService,
		@Inject('cbDialogService') private readonly cbDialogService: CBDialogService,
		@Inject('treeService') private readonly treeService: TreeService,
		@Inject('dashboardBookState') private readonly dashboardBookState: DashboardBookStateService,
		@Inject('DashboardSelectUtils') private readonly DashboardSelectUtils,
		@Inject('DashboardContextMenuUtils') private readonly DashboardContextMenuUtils,
		@Inject('DashboardActionsService') private readonly DashboardActionsService,
		private readonly gridUtils: GridUtilsService,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('$routeParams') private readonly $routeParams: IRouteParams,
		@Inject('impersonateUserService') private readonly impersonateUserService: ImpersonateUserService,
		@Inject('$rootScope') private readonly $rootScope: ng.IRootScopeService,
		private readonly userPropertiesApiService: UserPropertiesApiService,
		private readonly ref: ChangeDetectorRef,
		private readonly eventBus: GlobalEventBus,
		private readonly dashboardListService: DashboardListService,
	) {
		super();
	}

	ngOnInit(): void {
		this.$rootScope.commandPaletteHidden = true;
		this.permissions = {
			create: this.security.has('create_dashboard')
		};

		this.createTemplateFromDashboardEnabled = this.betaFeaturesService.isFeatureEnabled(BetaFeature.TEMPLATE_FROM_DASHBOARD)
				&& this.security.has('create_dashboard')
				&& this.security.has('create_internal_templates');

		this.changeDashboard = this.navigationService.changeDashboard;
		this.dashboardsFixedPortSize = this.betaFeaturesService.isFeatureEnabled(BetaFeature.ASSETS_EXP_OPTIMIZATION);

		this.registerEventListeners();

		this.updatePageTitle();

		this.initScope();

		this.showDashboardRating = this.maPropertiesService.isFeatureEnabled(MasterAccountProperty.DASHBOARD_RATING);

		const oauthClientAlias: string = this.$routeParams.limited_access;
		if (oauthClientAlias === OAuthClientAlias.DOCEBO) {
			this.cbDialogService.notify(this.locale.getString('login.doceboAccessIsLimitedHeader'),
				this.locale.getString('login.doceboAccessIsLimited'));
		}

	}

	private updatePageTitle(): void {
		if (!this.tabsEditor.enabled) {
			PageTitleUtil.setTitle(this.locale.getString('pageTitle.object', {
				objectName: this.userHomePageService.getHomePageName() || this.locale.getString('homePage.defaultName')
			}));
		}
	}

	private registerEventListeners(): void {
		const shareListener = this.eventBus.subscribe(CxEvent.DashboardEvent.SHARE, (event, dashboard, userSelfRemoval, userStillEditor) => {
			if (userSelfRemoval) {
				this.treeService.deleteItem(this.getDashboards(), dashboard);
				this.dashboardListService.updateDashboardsOld();
			} else if (!userStillEditor) {
				if (dashboard.permissions.EDIT) {
					delete dashboard.permissions.EDIT;
					dashboard.permissions.VIEW = true;
				}
				this.refreshGrid(dashboard);
			}
		});
		this.addListener(shareListener);

		// move to book editor


		const updateListener = this.eventBus.subscribe(CxEvent.GridEvent.GRID_LIST_UPDATED, (event, dataView) => {
			this.visibleList = [];
			for (let i = 0; i < dataView.getLength(); i++) {
				this.visibleList.push(dataView.getItem(i));
			}
		});
		this.addListener(updateListener);
	}

	private readonly initScope = (): void => {
		this.selectionUtils = new this.DashboardSelectUtils(this);
		this.contextMenuUtils = new this.DashboardContextMenuUtils(this);
		this.actionsService = new this.DashboardActionsService(this);

		this.dashboardBookState.resetEditedFromBook();
		this.dashboardListService.reloadDashboardsOld();
		if (!this.dashboardBookState.hasState()) {
			this.saveLastDashboard(-1);
		}

		this.addSubscription(this.dashboardListService.getDashboardChangeOld().subscribe(change => {
			this.refreshGrid(change);
		}));
		this.addSubscription(this.dashboardListService.getDashboards().subscribe(change => {
			this.refreshGrid(change);
		}));

		this.gridOptions = {
			onContextMenu: this.onContextMenu,
			onClick: this.onClick,
			onDblClick: this.onDblClick,
			onMouseEnter: this.onMouseEnter,
			onMouseLeave: this.onMouseLeave,
			onKeyDown: this.onKeyDown
		};

		this.ui = this.ui || {} as any;
		this.ui.searchFilter = '';
		this.ui.hideDashboards = true;
		this.ui.selectedUser = this.security.getUser();

		this.toggleFavoriteAndRefresh = this.favoriteDashboard;
		this.checkBookEditorState();
	};

	searchFilterFunction = (item, searchString, filterCriteria) => {
		if (_.findWhere(filterCriteria, { searchFilterValue: searchString, itemType: item.type })) {
			return true;
		}
		return false;
	};

	private saveLastDashboard(dashboardId: number): void {
		let properties = {
			last_dashboard_id: dashboardId + ''
		};
		this.userPropertiesApiService.updateProperties(properties).then(_.noop);
	}

	isCurrentUserSelected = (): boolean => this.ui.selectedUser.userId === this.security.getUser().userId;

	private readonly onContextMenu = (event: JQuery.MouseEventBase, object) => {
		event.preventDefault();
		const target = $(event.target);
		if ((target.hasClass(this.RATING_BAR_SELECTOR) || target.hasClass(this.RATING_STAR_SELECTOR))
			&& this.isCurrentUserSelected() && !object.permissions.OWN) {
			this.showRatingPopup(event, object);
		}
	};

	private readonly onClick = (event: JQuery.MouseDownEvent, object, args?) => {
		this.handleCellAction(event, object, args);
	};

	private readonly onKeyDown = (event: KeyboardEvent, object, args?) => {
		if (KeyboardUtils.isEventKey(event, Key.ENTER)) {
			if (!args || args.row !== -1) {
				this.handleCellAction(event, object, args);
				event.stopImmediatePropagation();
			} else if (this.gridUtils.isHeaderCheckbox(event)) {
				this.handleHeaderCheckboxAction();
				event.stopImmediatePropagation();
			}
		} else if (KeyboardUtils.isEventKey(event, Key.TAB)) {
			const contextMenuOptions = $('#dsh-context-menu :focusable');
			if (contextMenuOptions.length) {
				contextMenuOptions.first().trigger('focus');
				event.stopImmediatePropagation();
			}
		}
	};

	private readonly handleHeaderCheckboxAction = () => {
		if (!this.selectionUtils.showSelectAll()) {
			return;
		}
		if (this.selectionUtils.showSomeSelected() || this.selectionUtils.showAllSelected()) {
			this.selectionUtils.clearSelections();
		} else {
			this.selectionUtils.selectAllObjects();
		}
	};

	private readonly handleCellAction = (event: any, object, args?): boolean => {
		// single click only on title
		const target = $(event.target);
		if (event.ctrlKey && this.selectionUtils.isSupportedType(object)) {

			this.lastChecked = object;
			this.selectionUtils.handleCtrlClick(object);
			this.focusCell(args);
			this.ref.markForCheck();
			return;
		}

		if (this.selectionUtils.isSupportedType(object) && (this.gridUtils.isFavoriteStar(event))) {
			this.favoriteDashboard(object).then(() => {
				this.focusCell(args);
			});
		}

		if (this.gridUtils.isMenuClick(event)) {
			const keydown = KeyboardUtils.isKeyDown(event);
			this.contextMenuTree.showObjectListMenu(event, object, this.menuOptions(object), 'dashboards', 360, false,
				() => this.focusCell(args));
			if (!this.selectionUtils.multipleObjectsSelected() && !keydown) {
				// disabled it - menu is too big now, so it doesn't normally fit
				// this.showTooltip(event, object, true);
			}
		} else if (this.selectionUtils.isSupportedType(object)
			&& (this.gridUtils.isNameClick(event) || target.hasClass('br-dashboard'))
			&& this.isCurrentUserSelected()) {
			this.actionsService.viewDashboard(object);
		} else if (this.gridUtils.isRating(event) && !object.permissions.OWN) {
			this.showRatingPopup(event, object);
			this.focusCell(args);
		} else if (this.gridUtils.isBulkCheckbox(event)) {
			this.selectionUtils.handleCheckboxClick(object, this.lastChecked, event.shiftKey);
			this.lastChecked = object;
			this.focusCell(args);
		}
		this.ref.markForCheck();
	};

	private readonly focusCell = (args) => {
		if (!args) {
			return;
		}
		setTimeout(() => {
			this.eventBus.broadcast(CxEvent.GridEvent.FOCUS_GRID_CELL, args.row, args.cell);
		});
	};

	private readonly onDblClick = (event, object) => {
		if (this.selectionUtils.isSupportedType(object) && !this.gridUtils.isBulkCheckbox(event) && this.isCurrentUserSelected()) {
			this.actionsService.viewDashboard(object);
			this.ref.markForCheck();
		}
	};

	menuOptions = (item): any[] => {
		if (!this.isCurrentUserSelected()) {
			return [];
		}

		return this.contextMenuUtils.getContextMenu(item);
	};

	private refreshGrid = (items: ITreeItem[]): void => {
		this.changedDashboards = items;
	};

	resetFilter = (): void => {
		this.searchFilter = this.dashboardFilter = '';
	};

	private readonly positionTooltip = (e: JQuery.MouseEnterEvent, menuPreview) => {
		const contextMenu = $('#dsh-context-menu');
		if (menuPreview && !contextMenu.position()) {
			// menu is already closed, do nothing
			return;
		}
		this.showTooltipForEvent(e);

		if (menuPreview) {
			$('#dsh-context-menu').on('remove', this.hideTooltip);
		}
	};

	private readonly isScheduleCell = (e: JQuery.MouseEnterEvent) =>
		$(e.target).hasClass('cell-schedule') || $(e.target).hasClass('dashboard-schedule-icon');


	private readonly onMouseEnter = (e: JQuery.MouseEnterEvent, dashboard) => {
		if (this.timer) {
			clearTimeout(this.timer);
		}
		if ($(e.target).hasClass('cell-title') || this.isScheduleCell(e)) {
			this.timer = setTimeout(
				() => { this.showTooltip(e, dashboard); }, 300);
		}
	};

	private readonly showTooltip = (e: JQuery.MouseEnterEvent, dashboard: Dashboard, menuPreview?) => {
		if (dashboard.type === 'folder'
			|| $(e.target).hasClass(this.RATING_BAR_SELECTOR)
			|| $(e.target).hasClass(this.RATING_STAR_SELECTOR)
			|| $(e.target).hasClass('br-rating')
			|| $(e.target).hasClass('br-bulk-actions-check')
			|| $(e.target).hasClass('br-left-dashboard-items')) {
			return;
		}

		if (this.tooltip.dashboard !== dashboard) {
			clearTimeout(this.tooltipTimer);
		}
		let tooltipUIElement;
		this.tooltip.dashboard = dashboard;
		if (dashboard.scheduleCount && this.isScheduleCell(e)) {
			this.tooltip.type = TooltipType.SCHEDULE;
			tooltipUIElement = $('#schedule-tooltip');
		} else {
			this.tooltip.type = TooltipType.DASHBOARD;
			tooltipUIElement = $('#dash-tooltip');
		}
		this.tooltipTimer = setTimeout(() => {
			if (menuPreview) {
				tooltipUIElement.addClass('large');
			} else {
				tooltipUIElement.removeClass('large');
			}
			this.positionTooltip(e, menuPreview);
		}, 200);
	};

	readonly onMouseLeave = (e: JQuery.MouseLeaveEvent, dashboard: Dashboard, local): void => {
		clearTimeout(this.timer);
		if (!this.tooltip || $('context-menu').length) {
			return;
		}
		if (this.tooltip.type === TooltipType.RATING && $((e as any).toElement).hasClass('hover-keeper')) {
			return;
		}

		this.hideTooltip();
		clearTimeout(this.tooltipTimer);
		if (!local) {
			this.ref.markForCheck();
		}
	};

	private readonly showTooltipForEvent = (e: JQuery.MouseEventBase): void => {
		const target = $(e.target).parents('.slick-row');
		let top = e.pageY;
		if (target.length) {
			top = target.offset().top;
		}

		const impersonateBarOffset = this.impersonateUserService.isImpersonateActive() ? HeaderConstants.IMPERSONATE_HEADER_HEIGHT : 0;
		top = top - (HeaderConstants.PRIMARY_HEADER_HEIGHT + impersonateBarOffset);

		this.tooltipStyle = {
			top,
			left: e.pageX,
			visibility: 'visible',
			opacity: 1
		};
	};

	private readonly hideTooltip = (): void => {
		delete this.tooltip.dashboard;
		delete this.tooltip.type;
		this.tooltipStyle = {
			top: 0,
			left: 0,
			visibility: 'hidden',
			opacity: 0
		};
	};

	private readonly rateDashboard = (dashboard: Dashboard, rating): void => {
		this.dashboardService.rateDashboard(dashboard, rating).then(() => {
			this.refreshGrid([dashboard]);
		});
	};

	changeRating = (dashboard: Dashboard, rating: number): void => {
		this.rateDashboard(dashboard, Math.round(rating * 2));
	};

	gotoDashboard = (dashboard: Dashboard): void => {
		if (this.isCurrentUserSelected()) {
			this.actionsService.viewDashboard(dashboard);
		}
	};

	dashboardBurgerClick = (event: JQuery.Event, dashboard: Dashboard): void => {
		this.contextMenuTree.show(event, dashboard, this.menuOptions(dashboard), 'dashboards', 360);
	};

	private readonly favoriteDashboard = (dashboard: Dashboard) => {
		dashboard.favorite = !dashboard.favorite;
		return this.dashboardService.favoriteDashboard(dashboard).then(() => {
			this.refreshGrid([dashboard]);
		});
	};

	readonly showRatingPopup = (e, dashboard): void | undefined => {
		if (dashboard.type === 'folder') {
			// just in case
			return;
		}
		if (KeyboardUtils.isKeyDown(e)) {
			// star rating will require design work to show on focus
			return;
		}
		this.tooltip.type = TooltipType.RATING;
		this.tooltip.dashboard = dashboard;
		this.showTooltipForEvent(e);

		this.interval.registerInterval('ratingDelay', this.hideTooltip, this.POPUP_TIMEOUT, 1); // need to hide popup, if we don't enter it
	};

	enterRating = (): void => {
		this.interval.stopIntervalWithName('ratingDelay'); // if we enter popup, it cannot be forgotten anymore
	};

	updateRating = (newRating: number): void => {
		this.rateDashboard(this.tooltip.dashboard, newRating);
		this.hideTooltip();
	};

	showLoginHistory = () => {
		const title = `${this.locale.getString('header.loginHistory')} ${this.security.getEmail()}`;
		this.cbDialogService.custom(title, 'partials/header/login-history-list.html', {},
			this.locale.getString('common.close'), null, 'br-login-history');
	};

	onTabsSave = (dashboard) => {
		this.refreshGrid([dashboard]);
		this.dashboardBookState.resetState();
		this.disableTabsEditor();
	};

	onTabsCancel = () => {
		this.disableTabsEditor();
	};

	disableTabsEditor = () => {
		this.tabsEditor.enabled = false;
		if (this.tabsEditor.needRedirectToView && this.tabsEditor.dashboard.id) {
			this.cxLocation.path(`/dashboard/${this.tabsEditor.dashboard.id}`);
		} else {
			this.$rootScope.commandPaletteHidden = true;
			this.updatePageTitle();
		}
	};

	private readonly checkBookEditorState = () => {
		if (this.dashboardBookState.hasState()) {
			if (this.dashboardBookState.isCreateBook) {
				this.actionsService.enableTabsEditor();
			}

			if (this.dashboardBookState.isEditBook) {
				const dashboard = _.find(this.dashboardListService.getCurrentDashboardsList(), { id: this.dashboardBookState.getBookId() });
				if (dashboard) {
					this.actionsService.enableTabsEditor(dashboard);
					if (this.dashboardBookState.isBookPage) {
						this.tabsEditor.needRedirectToView = true;
					}
				} else {
					this.cbDialogService.warning(this.locale.getString('common.warning'), 'Unable to find book for editing');
				}
			}

			this.dashboardBookState.resetState();
		}
	};

	changeFavoriteForDashboard = (dashboard: Dashboard): void => {
		if (!this.isCurrentUserSelected()) {
			return;
		}
		dashboard.favorite = !dashboard.favorite;
		this.dashboardService.favoriteDashboard(dashboard).then(_.noop);
	};

	onNodeChange(node: IHierarchyNode): void {
		if (this.homePageWidgetsPanelComponent) {
			(this.homePageWidgetsPanelComponent as any).hierarchyNodeChange(node);
		}
	}

	getDashboards(): Dashboard[] {
		if (this.security.isCurrentUser(this.ui.selectedUser?.userEmail))
			return this.dashboardListService.getCurrentDashboardsList();
		else return this.selectedUserDashboards;
	}

	onSelectedUserChange(user: User): void {
		this.ui.selectedUser = user;
		this.selectedUserDashboards = [];
		if (this.security.isCurrentUser(this.ui.selectedUser?.userEmail))
			return;
		this.dashboardListService.getDashboardsForUser(user.userId).then(dashboards => {
			this.selectedUserDashboards = dashboards;
		});
	}

	clearSelections(): void {
		this.selectionUtils.clearSelections();
	}

	refreshFolderHighlighting(folders: any[]): void {
		this.selectionUtils.refreshFolderHighlighting(folders);
	}

}

app.directive('dashboardListPage', downgradeComponent({ component: DashboardListPageComponent }));
