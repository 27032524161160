import { Component, OnInit, Inject, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { Security } from '@cxstudio/auth/security-service';
import Authorization from '@cxstudio/auth/authorization-service';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { DividerUtils } from './divider-check-utils';
import { MenuItem, NamedNavigationItem } from '@discover/unified-angular-components/dist/unified-angular-components/lib/entities/MenuItem';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ScheduleApiService } from '@app/modules/schedules/schedule-api-service';

@Component({
	selector: 'space-dropdown',
	templateUrl: './space-dropdown.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SpaceDropdownComponent implements OnInit {
	optionsList: MenuItem[];
	hasSchedules: boolean = false;

	constructor(
		@Inject('security') private readonly security: Security,
		@Inject('authorization') private readonly authorization: Authorization,
		private readonly scheduleApiService: ScheduleApiService,
		private readonly locale: CxLocaleService,
		private readonly betaFeaturesService: BetaFeaturesService,
		private readonly ref: ChangeDetectorRef,
	) { }

	ngOnInit(): void {
		const schedulePromise = this.security.has('manage_settings') ?
			Promise.resolve(true) :
			this.scheduleApiService.hasDashboardSchedules();

		schedulePromise.then(hasSchedules => {
			this.hasSchedules = hasSchedules;
			this.runOptionsPermChecks();
			this.optionsList = DividerUtils.enforceDividerRules(this.optionsList ?? []);
			this.ref.markForCheck();
		});
	}

	runOptionsPermChecks = (): void => {
		this.optionsList = [
			{
				name: this.locale.getString('header.dashboardsHome'),
				url: '#/dashboards',
				divider: false
			},
			this.processFilterManagementAccess(),
			this.processMetricManagementAccess(),
			this.processUnifiedTemplatesManagementAccess(),
			{
				divider: true
			},
			this.processViewProjectsAccess(),
			this.processDriversManagementAccess(),
			this.processAlertingAccess(),
			this.processDashboardScheduleAccess(),
			this.processInboxTemplateManagementAccess(),
			this.processInteractionExplorerAccess(),
			{
				divider: true
			},
			this.processRecycleBinAccess(),
		].filter(option => !!option) as MenuItem[];
	};

	processInteractionExplorerAccess = (): NamedNavigationItem => {
		if (this.authorization.hasInteractionExplorerAccess()) {
			return {
				name: this.locale.getString('header.interactionExplorer'),
				url: '#/interactions',
				class: 'interactions'
			};
		}
	};

	processFilterManagementAccess = (): NamedNavigationItem => {
		if (this.authorization.hasFilterManagementAccess()) {
			return {
				name: this.locale.getString('header.manageFilters'),
				url: '#/filters',
				class: 'filter_management'
			};
		}
	};

	processMetricManagementAccess = (): NamedNavigationItem => {
		if (this.authorization.hasMetricManagementAccess()) {
			return {
				name: this.locale.getString('header.manageMetrics'),
				url: '#/metrics',
				class: 'metric_management'
			};
		}
	};

	processUnifiedTemplatesManagementAccess = (): NamedNavigationItem => {
		let hasUnifiedTemplatesManagementAccess = this.authorization.hasManageTemplatePermission()
				|| this.authorization.hasCreateTemplatePermission();

		let templateBetas = [
			BetaFeature.METRIC_TEMPLATES,
			BetaFeature.FILTER_TEMPLATES,
			BetaFeature.NEW_DASHBOARD_TEMPLATES];
		if (this.betaFeaturesService.isAnyFeatureEnabled(...templateBetas)
			&& hasUnifiedTemplatesManagementAccess) {
			return {
				name: this.locale.getString('header.manageUnifiedTemplates'),
				url: '#/unified-templates',

			};
		}
	};

	processAlertingAccess = (): NamedNavigationItem => {
		if (this.authorization.hasAlertManagementAccess()) {
			return {
				name: this.locale.getString('header.manageAlerts'),
				url: '#/alerts',
				class: 'alert_management'
			};
		}
	};

	processInboxTemplateManagementAccess = (): NamedNavigationItem => {
		if (this.authorization.hasInboxTemplateManagementAccess()) {
			return {
				name: this.locale.getString('header.manageAlertTemplates'),
				url: '#/alert_templates'
			};
		}
	};

	processDriversManagementAccess = (): NamedNavigationItem => {
		if (this.security.getCurrentMasterAccount().predictionEnabled
		&& this.authorization.hasDriversManagementAccess()) {
			return {
				name: this.locale.getString('header.manageDrivers'),
				url: '#/drivers'
			};
		}
	};

	processViewProjectsAccess = (): NamedNavigationItem => {
		if (this.authorization.hasViewProjectsAccess()) {
			return {
				name: this.locale.getString('common.projects'),
				url: '#/projects',
				class: 'projects_management'
			};
		}
	};

	processDashboardScheduleAccess = (): NamedNavigationItem => {
		if (this.security.has('manage_settings') || this.hasSchedules) {
			return {
				name: this.locale.getString('header.schedules'),
				url: '#/schedules'
			};
		}
	};

	processRecycleBinAccess = (): NamedNavigationItem => {
		if (this.authorization.hasRecycleBinAccess()) {
			return {
				name: this.locale.getString('header.archive'),
				class: 'q-icon q-icon-trash',
				url: '#/recycle_bin'
			};
		}
	};
}

app.directive('spaceDropdown', downgradeComponent({ component: SpaceDropdownComponent }));
