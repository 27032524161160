import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';

export enum EnrichmentIconType {
	PILL = 'PILL',
	ICON = 'ICON',
	TEXT = 'TEXT',
}

export class EnrichmentIcon {

	readonly BASE_PILL_CLASSES = [
		'enrichment-pill',
		'label-bubble',
		'h-40',
		'border-radius-full',
		'font-standard',
		'd-flex-inline',
		'align-center',
		'no-border',
		'ph-16'
	];

	additionalClass = '';
	useBorder: boolean = false;
	useHighlight: boolean = false;
	customClasses: string[] = [];
	color: string;
	iconClass: string;
	title: string;
	displayName: string;

	withClasses(classes: string | string[]): EnrichmentIcon {
		this.customClasses = [].concat(classes);
		return this;
	}

	withDisplayName(displayName: string): EnrichmentIcon {
		this.displayName = displayName;
		return this;
	}

	withTitle(title: string): EnrichmentIcon {
		this.title = title;
		return this;
	}

	withColor(color: string): EnrichmentIcon {
		this.color = color;
		return this;
	}

	withIcon(iconClass: string): EnrichmentIcon {
		this.iconClass = iconClass;
		return this;
	}

	withBorder(withBorder: boolean = true): EnrichmentIcon {
		this.useBorder = withBorder;
		return this;
	}

	withHighlight(withHighlight: boolean = true): EnrichmentIcon {
		this.useHighlight = withHighlight;
		return this;
	}

	as(type: EnrichmentIconType): string {
		switch (type) {
			case EnrichmentIconType.PILL:
				return this.asPill();
			case EnrichmentIconType.ICON:
				return this.asIcon();
			case EnrichmentIconType.TEXT:
				return this.asText();
			default:
				return this.asPill();
		}

	}

	asPill(withRemove: boolean = false): string {
		let classes = [...this.BASE_PILL_CLASSES, ...this.customClasses, withRemove ? 'cursor-pointer' : ''].join(' ');
		let removeButton = `<span class="q-icon q-icon-delete text-lg-1 p-0 ml-4 color-inherit no-background"
			aria-hidden="true"
			style="color: ${ColorUtilsHelper.pickContrastTextColor(this.color)};">
		</span>`;
		return `<span class="${classes}"
			title="${this.title}"
			style="background: ${this.color};">
			<span class="enrichment-pill-icon icon-highlight bg-none mr-2 flex-fixed"
				style="color: ${ColorUtilsHelper.pickContrastTextColor(this.color)};">
				<span class="${this.iconClass}" aria-hidden="true"></span>
			</span>
			<span class="text-ellipsis" style="color: ${ColorUtilsHelper.pickContrastTextColor(this.color)};">
				${this.displayName}
			</span>
			${withRemove ? removeButton : ''}
		</span>`;
	}
	// pills as icons only in preview-table and in feedback-verbatim
	asIcon(): string {
			const innerText = `<span title="${this.title}">
				<i class="${this.iconClass} text-white" aria-label="${this.displayName}"></i>
			</span>`;
			const borderStyle: string = this.useBorder ? `style="border: solid 4px ${this.color}; background-color: ${this.color}"` : '';
			const highlightClass: string = this.useHighlight ? 'icon-highlight' : ''; // might need to be removed later as we should always show circle for icon only
			const borderClasses: string = this.useBorder ? 'enrichment-icon-with-border' : '';
			const baseClasses = `feedback-preview-icon border-radius-full h-40 w-40`;
			return `<div ${borderStyle} style="background-color:${this.color}"
				class="${highlightClass} ${borderClasses} ${baseClasses} ${this.customClasses.join(' ')}">${innerText}</div>`;
	}

	asText(): string {
		return `<div class="tooltip-enrichment">
			<span class="${this.customClasses.join(' ')}">${this.displayName}</span>
		</div>`;
	}
}
