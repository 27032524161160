import { AttributesService } from '@app/modules/project/attribute/attributes.service';
import { IReportAttribute } from '@app/modules/project/attribute/report-attribute';
import { IProjectContext } from '@app/modules/project/context/project-context';
import { ProjectContextService } from '@app/modules/project/context/project-context.service';
import { ModelsService } from '@app/modules/project/model/models.service';
import { IReportModel } from '@app/modules/project/model/report-model';
import { ObjectUtils } from '@app/util/object-utils';
import { PromiseUtils } from '@app/util/promise-utils';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';

export interface IDriverResources {
	attributes: IReportAttribute[];
	wordAttributes: IReportAttribute[];
	models: IReportModel[];
	context: IProjectContext;
	timezone: string;
}

export class DriverResourcesContainer {
	private promise: Promise<IDriverResources>;

	constructor(
		private props: IProjectSelection,
		private readonly projectContextService: ProjectContextService,
		private readonly attributesService: AttributesService,
		private readonly modelsService: ModelsService,
	) {}

	private init(): Promise<IDriverResources> {
		let attributesPromise = this.attributesService.getAttributes(this.props);
		let modelsPromise = this.modelsService.getModels(this.props);
		let projectContextPromise = this.projectContextService.getProjectContext(this.props);
		let timezonePromise = this.projectContextService.getProjectTimezone(this.props);
		let wordAttributesPromise = this.attributesService.getWordAttributes(this.props);
		return PromiseUtils.all([
			attributesPromise, modelsPromise, projectContextPromise, timezonePromise, wordAttributesPromise
		]).then(results => {
			let attributes = results[0];
			let models = results[1];
			let context = results[2];
			let timezone = results[3];
			let wordAttributes = results[4];
			return {attributes, models, context, timezone, wordAttributes};
		});
	}

	get = (): Promise<IDriverResources> => {
		if (!this.promise)
			this.promise = this.init();
		return this.promise.then(result => ObjectUtils.copy(result));
	};

}
