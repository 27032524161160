import * as _ from 'underscore';
import { DashboardFiltersService } from '@cxstudio/dashboards/dashboard-filters/dashboard-filters-service';
import { FilterMatchModeValue } from '@cxstudio/reports/entities/filter-match-mode-value';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { RandomUtils } from '@app/util/random-utils.class';
import { Key, KeyboardUtils } from '@app/shared/util/keyboard-utils.class';
import { IPagingSearch } from '@app/modules/filter-builder/attribute/paging-multiselect/paging-search-factory.class';
import { ColorUtilsHelper } from '@app/modules/widget-visualizations/color-utils-helper.class';
import { RealDataPreviewService } from '@app/modules/reports/real-data-preview/real-data-preview.service';
import { CustomDomSharedStylesHost } from '@app/core/CSP/shared-styles-host.service';

export interface AttributeValueOption {
	name?: string;
	displayName: string;
	attributeName?: string;
	attributeDisplayName?: string;
	object?: any;
	css?: string;
	isAllValues?: boolean;
	existMatch?: boolean;
	special?: boolean;
	matchMode?: FilterMatchModeValue;
	selected?: boolean;
	newValue?: boolean;
	children?: AttributeValueOption[];
}

export interface IMultiselectConfiguration {
	showAttributeName: boolean;
	externalSearch: string;
}

/*
	Array of filtered items should be passed to as "ngModel".
	Each "ngModel" item should contain "name" field for item comparison on item selection
	(can be primitive or object).
*/
export class MultiselectController implements ng.IController {
	config: IMultiselectConfiguration;
	disableSelection: (item, modelValue) => boolean;
	updateSearch: (params: {$search: string}) => void;
	limit: number;
	selectedAttribute: any;
	nestedList: any[];
	onNodeClick: (params: {node: any}) => void;
	onChangeAllClick: (params: {select: boolean}) => void;
	equalFunction: (a, b) => boolean;
	onChange: () => void;
	ngDisabled: () => boolean;
	ngModel: ng.INgModelController;
	multiselectOpen: boolean;
	color?: string;
	externalSearchValue?: string;
	externalOpenValue?: boolean;

	filterText: string;
	uiOptions: IMultiselectConfiguration;
	styleId: string;
	style: JQuery;

	pagingSearch: IPagingSearch;

	constructor(
		private $element: ng.IAugmentedJQuery,
		private dashboardFiltersService: DashboardFiltersService,
		private $scope: ISimpleScope,
		private $timeout: ng.ITimeoutService,
		private realDataPreviewService: RealDataPreviewService,
		private customDomSharedStylesHost: CustomDomSharedStylesHost
	) {}

	$onInit(): void {
		this.filterText = '';
		this.nestedList = this.nestedList || [];
		let defaultConfiguration = {};
		this.limit = this.limit || 100;

		this.config = this.config || {} as any;
		this.uiOptions = $.extend(defaultConfiguration, this.config);
		this.styleId = `s-${RandomUtils.randomString()}`;
		this.addStyles();

		this.$scope.$watch(() => this.externalSearchValue, () => {
			if (this.externalSearchValue !== undefined) {
				if (!this.externalSearchValue?.length) {
					this.multiselectOpen = false;
				} else {
					this.updateSearch({$search: this.externalSearchValue});
				}
			}
		});

		this.$scope.$watch(() => this.externalOpenValue, (newValue) => {
			if (newValue !== undefined) {
				this.multiselectOpen = newValue;
			}
		});

		this.$scope.$watch(() => this.color, (newValue) => {
			if (!this.realDataPreviewService.hasPreviewChanges()) {
				this.updateStyles();
			}

		});
	}

	$onDestroy(): void { }

	private addStyles(): void {
		if (this.color) {
			let opacity = 55;
			let background = ColorUtilsHelper.lighten(this.color);
			let fontColor = ColorUtilsHelper.pickContrastTextColor(background);
			let style = `<style ${this.customDomSharedStylesHost.getCSPNonceAttribute()} type="text/css">
				#${this.styleId} .multiselect-item > a:hover {
					color: ${fontColor} !important;
					background-color: ${this.color}${opacity} !important;
				}
			</style>`;
			this.style = angular.element(style);
			this.$element.append(this.style);
		}

	}

	private updateStyles(): void {
		this.style.remove();
		this.addStyles();
	}

	focusInputAndOpenDropdown = () => {
		this.multiselectOpen = true;
		this.$element.find('input').trigger('focus');
	};

	disable = (item) => {
		if (item.existMatch) return false;
		if (this.disableSelection) return this.disableSelection(item, this.ngModel.$viewValue);

		return !item.selected && (this.getSelectedOptionsCount() >= this.limit);
	};

	setModelAndRender = (val) => {
		this.ngModel.$setViewValue(val);
		this.ngModel.$render();
		if (this.onChange && typeof this.onChange === 'function') {
			this.onChange();
		}
	};

	private changeAllClicked = (select: boolean) => {
		if (this.onChangeAllClick && typeof this.onChangeAllClick === 'function') {
			this.onChangeAllClick({select});
		}
	};

	selectAll = () => {
		this.setNestedSelection(true);
		this.resetModelAndRender();
		this.changeAllClicked(true);
	};

	deselectAll = () => {
		this.setNestedSelection(false);
		this.resetModelAndRender();

		this.updateSearch({$search: this.getSearchText()});
		this.changeAllClicked(false);
	};

	setNestedSelection = (value) => {
		this.nestedList.map((opt) => {
			if (opt.children) {
				opt.children.map((child) => {
					child.selected = value;
					this.populateModelValueItemSelection(child);
				});
			} else {
				opt.selected = value;
				this.populateModelValueItemSelection(opt);
			}
		});
	};

	clickItem = (item, itemType?): void => {
		if (this.disable(item)) {
			return;
		}

		if (item.children) {
			if (itemType === 'selected') {
				item.expandedSelected = !item.expandedSelected;
			} else {
				item.expandedUnselected = !item.expandedUnselected;
			}
			return;
		}

		if (item.existMatch) {
			let selected = item.selected;
			this.deselectAll();
			item.selected = selected;
		}

		item.selected = !item.selected;
		this.populateModelValueItemSelection(item);
		this.resetModelAndRender();

		this.onNodeClick({node: item});
	};

	getSelectedOptionsCount = () => {
		return this.ngModel.$viewValue
			? this.dashboardFiltersService.getNestedSelectedChildren(this.ngModel.$viewValue).length
			: 0;
	};


	populateModelValueItemSelection = (item) => {
		let modelValue = this.ngModel.$viewValue || [];
		this.ngModel.$viewValue = modelValue;

		if (!item.selected) {
			for (let i = 0; i < modelValue.length; i++) {
				let modelItem = modelValue[i];
				if (this.isEqual(item, modelItem)) {
					modelValue.splice(i, 1);
					break;
				}
			}
		} else {
			modelValue.push(item);
		}
	};

	isEqual = (item1, item2): boolean => {
		if (item1.isAllValues || item2.isAllValues) {
			return item1.isAllValues === item2.isAllValues;
		}
		return this.isNamesEqual(item1.name, item2.name);
	};

	isNamesEqual = (item1, item2): boolean => {
		let equalFunc = this.equalFunction || _.isEqual;
		return equalFunc(item1, item2);
	};

	resetModelAndRender = () => {
		this.setModelAndRender(this.ngModel.$viewValue);
	};

	hasPagingSearch = (): boolean => {
		return !!this.pagingSearch;
	};

	getFirstSearchPromise = (): ng.IPromise<any> => {
		if (this.hasPagingSearch() && this.pagingSearch.showFirstSearchSpinner)
			return this.pagingSearch.firstSearchingPromise;
	};

	getElementWidth = (): number => {
		return this.$element.width();
	};

	getSearchText = (): string => {
		return this.externalSearchValue;
	};

	onInMultiselectDropdownKeyup = (event: any): void => {
		if (KeyboardUtils.isEventKey(event, Key.ESCAPE)) {
			event.preventDefault();
			event.stopPropagation();
			this.$timeout(() => {
				this.closeAndFocusOnDropdown();
			});
		}
	};

	private closeAndFocusOnDropdown = (): void => {
		let dropdownButton = this.getDropdownButtonElement();
		dropdownButton.click();
		dropdownButton.focus();
	};

	private getDropdownButtonElement = (): HTMLElement => {
		let currentDropdownContent: JQuery = $(`#${this.styleId}`);
		let multiselectComponent: HTMLElement = currentDropdownContent[0].parentElement;
		return this.getDropdownButton(multiselectComponent);
	};

	private getDropdownButton = (element: any): HTMLElement => {
		if (element.id === 'multiselect-dropdown-button') {
			return element;
		}
		return this.getDropdownButton(element.firstElementChild);
	};
}

app.component('oldMultiselect', {
	controller: MultiselectController,
	bindings: {
		config: '<?',
		disableSelection: '<',
		updateSearch: '&',
		limit: '@',
		selectedAttribute: '<?',
		nestedList: '<',
		onNodeClick: '&',
		onChangeAllClick: '&?',
		equalFunction: '<',
		onChange: '<',
		ngDisabled: '&',
		pagingSearch: '=?',
		dropdownContainer: '<?',
		color: '<?',
		externalOpenValue: '<',
		externalSearchValue: '<',
	},
	require: {
		ngModel: 'ngModel'
	},
	transclude: true,
	templateUrl: 'partials/components/multiselect/old-multiselect.html',
});
