import * as _ from 'underscore';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { ShareAction } from '@cxstudio/common/share-actions.constant';
import { ShareModificationUtilities } from '@cxstudio/sharing/share-modification-utils.service';
import { AssetPermission } from '@cxstudio/asset-management/asset-permission';
import { Security } from '@cxstudio/auth/security-service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { IShareEntity, SharingService, UIShareGroup } from '@cxstudio/sharing/sharing-service.service';
import { AllPermissionsService, PermissionType } from '@app/core/authorization/all-permissions.service';


export class ShareTableComponent implements ng.IComponentController {

	readonly MAX_DISPLAYED_USERS = CONFIG.sharing ? parseInt(CONFIG.sharing.largeGroupSize, 10) : 20;
	readonly SCROLL_ANIMATION_DURATION = 500; // time in ms to take animating the scroll to find an entity

	shareViewPermission;
	shareEditPermission;
	editPermission;
	skipDropdownOffset: boolean;
	isNewAdd: boolean;
	items;
	ngModel;
	targetEntity;
	oneClickRemoval: boolean; // true = show the X to remove items from the list
	onPermissionChange: (item: any, permissionId: string) => void;
	isBulkShare: boolean;
	isOwner: (entity) => boolean;
	itemOwner: string;
	validateInvite: (object: any) => boolean | ng.IPromise<boolean>;
	onRemove: (entity: IShareEntity) => void;
	viewToNotify: boolean;
	showPreview: boolean;
	onPreviewAs: (item: any) => void;

	isInnerUser = this.shareModificationUtils.isInnerUser;
	isCurrentUserInGroup = this.shareModificationUtils.isCurrentUserInGroup;
	currentUserIsItemOwner = this.shareModificationUtils.currentUserIsItemOwner;

	permissions;

	constructor(
		private security: Security,
		private allPermissions: AllPermissionsService,
		private locale: ILocale,
		private $rootScope: ng.IRootScopeService,
		private $scope: ISimpleScope,
		private $element: ng.IAugmentedJQuery,
		private $timeout: ng.ITimeoutService,
		private $q: ng.IQService,
		private shareModificationUtils: ShareModificationUtilities,
		private sharingService: SharingService
	) {}

	$onInit = () => {
		this.$scope.$watch(() => this.targetEntity, this.findAndDisplayEntity);

		this.ngModel.$formatters.push((newValue) => {
			this.items = newValue;
		});

		this.oneClickRemoval = !_.isUndefined(this.oneClickRemoval) ? this.oneClickRemoval : true;

		this.permissions = this.allPermissions.getPermissions({
			VIEW: this.shareViewPermission,
			EDIT: this.shareEditPermission,
			REMOVE: this.shareEditPermission
		}, this.isBulkShare);

		// if we don't have the instant remove button (X) we need to add remove option to the menu
		if (!this.oneClickRemoval) {
			this.permissions.push(this.allPermissions.REMOVE);
		}

		this.validateInvite = _.isUndefined(this.validateInvite)
			? (object: any) => true
			: this.validateInvite;
	};

	getPermission = (id) => this.allPermissions[id];

	isLargeGroup = (group): boolean => group.entity.usersCount > this.MAX_DISPLAYED_USERS;

	toggleGroup = (group: UIShareGroup): void => {
		this.sharingService.toggleGroup(group);
	};

	getItemPostfix = (item): string => {
		if (item.type === 'group' && this.isLargeGroup(item)) {
			return '(' + this.locale.getString('dashboard.nUsers', { count: item.entity.usersCount }) + ')';
		} else {
			return '';
		}
	};

	getAvailablePermissions = (userOrGroup: any) => {
		return _.filter(this.permissions, (permission: any) => {
			if (this.canUserEdit(userOrGroup)) return true;

			return permission.id !== PermissionType.EDIT;
		});
	};

	getPermissionDisplayName = (permissionId: string) => {
		return (this.viewToNotify && permissionId === PermissionType.VIEW) || _.isUndefined(permissionId)
			? this.locale.getString('alert.notify')
			: this.getPermission(permissionId).name;
	};

	private canUserEdit = (item): boolean => {
		return (item.assetEditPermissions && item.assetEditPermissions.contains(this.getEditPermission()))
			|| (item.entity.assetEditPermissions && item.entity.assetEditPermissions.contains(this.getEditPermission()))
			|| (item.type === 'group');
	};

	isAllowedChange = (item, forDelete): boolean => {
		let permission = item._originalPermission || item.permission;

		let canEdit = this.canUserEdit(item);

		if (!forDelete && !canEdit) {
			return permission !== PermissionType.VIEW && permission !== PermissionType.OWN; // allow to downgrade permissions
		} else {
			switch (permission) {
				case PermissionType.OWN: return false;
				case PermissionType.EDIT: return this.security.has(this.getShareEditPermission()) && this.shareModificationUtils.isNotCurrentUser(item);
				case PermissionType.VIEW: return this.security.has(this.getShareViewPermission());
				case PermissionType.REMOVE: return this.security.has(this.getShareEditPermission());
				case 'NONE': return this.security.has(this.getShareEditPermission());
			}
			return false;
		}
	};

	userCanEdit = (user): boolean => {
		let canOnlyView = user.type === 'user'
			&& (user.permission === PermissionType.EDIT && user.permission !== PermissionType.OWN)
			&& ($.type(user.entity) === 'string'
				|| !user.entity.assetEditPermissions.contains(this.getEditPermission()));

		return !canOnlyView;
	};

	fixDropdown = ($event): void => {
		if (this.$rootScope.isMobile || this.skipDropdownOffset)
			return;
		let button = $($event.target);
		if (button.attr('uib-dropdown-toggle') === undefined)
			button = button.parents('[uib-dropdown-toggle]');
		let dropdown = button.parents('.dropdown').find('.dropdown-menu');
		let modal = button.parents('.modal-dialog');
		if (!modal.length)
			return;
		let modalScrollPosition = $('[uib-modal-window]').length ? $('[uib-modal-window]').scrollTop() : 0;

		let topMargin = 30;
		let leftMargin = (modal.parent().outerWidth() - modal.width()) / 2;
		let dropdownTop = button.offset().top + button.outerHeight() - topMargin + modalScrollPosition;
		let dropdownLeft = button.offset().left - leftMargin;
		dropdown.css('top', dropdownTop + 'px');
		dropdown.css('left', dropdownLeft + 'px');
	};

	private getEditPermission = () => this.editPermission || 'edit_dashboard';

	private getShareViewPermission = () => this.shareViewPermission || 'share_view';

	private getShareEditPermission = () => this.shareEditPermission || 'share_edit';

	// private isNotCurrentUser = (item): boolean => item.displayName !== this.security.getEmail();

	showShareRestricted = (item): boolean => {
		return (item.type === 'inner-user') &&
			(item.permission !== PermissionType.VIEW) &&
			item.entity.restricted;
	};

	findAndDisplayEntity = (newItem, lastItem): void => {
		if (newItem && (newItem !== lastItem)) {
			if (!_.find(this.items, newItem)) {
				return;
			}

			this.$timeout(() => {
				// timeout will force wait for digest cycle, in case dropdown needs to open or element needs to be added
				let el: HTMLElement = document.querySelector(`.${this.getItemTargetingClass(newItem)}`);
				this.scrollToEntity(el);
				this.highlightEntity(el);
			}, 1);
		}
	};

	// scroll to the entity in the list
	private scrollToEntity = (entityElement: HTMLElement) => {
		let scrollTarget = entityElement.offsetTop;
		this.$element.animate({scrollTop: scrollTarget}, this.SCROLL_ANIMATION_DURATION);
	};

	// brief fading highlight of the row to draw the user's attention
	private highlightEntity = (entityElement: HTMLElement) => {
		this.$timeout(() => {
			entityElement.classList.add('brand-fade-out');

			this.$timeout(() => {
				// remove the color fade class so that we can reapply it if necessary
				entityElement.classList.remove('brand-fade-out');
			}, 2000);
		}, this.SCROLL_ANIMATION_DURATION);
	};

	getItemTargetingClass = (item): string => {
		let cleanedName = item._name.replace(/[^A-Za-z0-9\-_]/g, '-');
		return `_${item.type}_${cleanedName}`;
	};

	changePermission = (item, permissionId: string): void => {
		if (permissionId === PermissionType.REMOVE) {
			this.changeItemPermission(item, permissionId, ShareAction.DELETE);
		} else {
			this.$q.resolve(this.validateInvite(item._name)).then(valid =>  {
				if (!valid) {
					return;
				}
				if (item.action === ShareAction.CREATE) {
					this.changeItemPermission(item, permissionId);
				} else if (!this.oneClickRemoval) {
					this.changeItemPermission(item, permissionId, ShareAction.UPDATE);
				} else if (this.isNewAdd) {
					this.changeItemPermission(item, permissionId, ShareAction.ADD);
				}
			});
		}
	};

	private changeItemPermission = (item, permissionId: string, action?: ShareAction): void => {
		item.permission = permissionId;
		if (action) {
			item.action = action;
			// style any children (if it's a group) with the deleted/normal style as well
			if (item._children) {
				item._children.map((entityChild) => entityChild.action = action);
			}
			// if item has custom removal method, call it (used for public/all users)
			if (action === ShareAction.DELETE && item.onRemove) {
				item.onRemove();
			}
		}
		if (this.onPermissionChange) {
			this.onPermissionChange(item, permissionId);
		}
	};

	sortByValue = (entity) => {
		//OWNER > PUBLIC > OTHERS
		if (entity.permission === AssetPermission.OWN) return 1;

		return entity.type === 'publicStatus' ? 2 : entity.displayName;
	};

	canChangePermission = (entity, itemOwner: string, isNewAdd: boolean) => {
		return this.shareModificationUtils.canChangePermission(entity, itemOwner, isNewAdd);
	};

	previewAs = (entity) => {
		if (this.showPreview && this.canPreviewAs(entity) && this.onPreviewAs) {
			this.onPreviewAs(entity);
		}
		return;
	};

	canPreviewAs = (entity): boolean => {
		return (entity.type === 'user' || entity.type === 'inner-user');
	};
}

app.component('shareTable', {
	bindings: {
		onPermissionChange: '<',
		onRemove: '<',
		onPreviewAs: '<',
		editPermission: '<',
		shareViewPermission: '<',
		shareEditPermission: '<',
		skipDropdownOffset: '<',
		targetEntity: '<',
		oneClickRemoval: '<',
		isBulkShare: '<',
		isNewAdd: '<',
		itemOwner: '<',
		validateInvite: '<customValidation',
		readOnlyMode: '<',
		showPreview: '<',
		viewToNotify: '<'
	},
	require: {
		ngModel: 'ngModel'
	},
	controller: ShareTableComponent,
	templateUrl: 'partials/asset-management/share-table.component.html'
});
