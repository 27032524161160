import * as moo from 'moo';
import { Injectable } from '@angular/core';
import { CustomMathExpression } from './custom-math-expression';
import { CustomMathError, CustomMathErrorType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-error';
import { CustomMathToken, CustomMathTokenType } from '@app/modules/metric/definition/custom-math/tokenizer/custom-math-token';

export enum MathAggregation {
	AVERAGE = 'average', 
	COUNT = 'count', 
	STANDARD_DEVIATION = 'standard deviation',
	SUM = 'sum', 
	MAX = 'max', 
	MIN = 'min',
	VARIANCE = 'variance', 
	SUM_OF_SQUARES = 'sum of squares', 
	COUNT_DISTINCT = 'count distinct'
}

export enum MathKeyword {
	METRIC = 'metric', 
	HIERARCHY = 'hierarchy', 
	SCORECARD = 'scorecard'
}

export enum MathFunction {
	ABS = 'abs'
}

@Injectable({providedIn: 'root'})
export class CustomMathTokenizerService {
	private lexerRuler;
	private tokenHandlers;

	constructor() {
		this.lexerRuler = {
			metric: {
				match: /\[(?:[^\]]|\\\])*[^\\]\]|\[\]/, 
				value: s => s.slice(1, -1)
					.replace('\\\\', '\\')
					.replace('\\[', '[')
					.replace('\\]', ']')},
			// eslint-disable-next-line id-blacklist
			number: /-?(?:0|(?:[1-9][0-9]*))(?:\.[0-9]+)?/,
			func: {match: /[a-zA-Z](?:[a-zA-Z ]*[a-zA-Z0-9_]+)?/, type: moo.keywords({
				prefix: [MathKeyword.METRIC, MathKeyword.HIERARCHY, MathKeyword.SCORECARD],
				math: [MathFunction.ABS],
				aggr: [
					MathAggregation.AVERAGE, MathAggregation.COUNT,
					MathAggregation.STANDARD_DEVIATION, MathAggregation.SUM,
					MathAggregation.MIN, MathAggregation.MAX,
					MathAggregation.VARIANCE, MathAggregation.SUM_OF_SQUARES,
					MathAggregation.COUNT_DISTINCT
				]
			})},
			lparen: '(',
			rparen: ')',
			plus: '+',
			minus: '-',
			multiply: '*',
			divide: '/',
			pow: '^',
			ws: /[ ]+/,
			lbracket: '[',
			rbracket: ']',
			notRecognized: moo.error
		};
		this.tokenHandlers = {
			// eslint-disable-next-line id-blacklist
			number: this.numberTokenHandler,
			prefix: this.prefixTokenHandler,
			metric: this.metricTokenHandler,
			math: this.mathTokenHandler,
			aggr: this.aggrTokenHandler,
			pow: this.powTokenHandler,
			ws: this.wsTokenHandler
		};
	}

	tokenize = (text: string): CustomMathExpression => {
		let tokens: CustomMathToken[] = [];
		let errors: CustomMathError[] = [];
		let lexer = moo.compile(this.lexerRuler);
		lexer.reset(text);
		let token: moo.Token;
		let previousIsError = false;
		// eslint-disable-next-line no-cond-assign
		while (token = lexer.next()) {
			if (this.isUnrecognizedText(token)) {
				errors.push({
					type: CustomMathErrorType.INVALID_TEXT,
					text: token.text,
					startOffset: token.offset
				});
				this.complementFunctionTokens(tokens);
				previousIsError = true;
			} else {
				if (token.type === 'ws' && previousIsError) {
					 // append the space to the previous token if not operator
					let lastError = errors.last();
					lastError.text += token.text;
				} else {
					let mathTokens = this.handleToken(token, lexer, tokens);
					tokens.pushAll(mathTokens);
					previousIsError = false;
				}
			}
		}
		return { tokens, errors };
	};

	private isUnrecognizedText = (token: moo.Token): boolean => {
		return token.type === 'func' || token.type === 'notRecognized';
	};

	private handleToken = (token: moo.Token, lexer: moo.Lexer, previousTokens?: CustomMathToken[]): CustomMathToken[] => {
		let tokenHandler = this.tokenHandlers[token.type] || this.symbolTokenHandler;
		let mathTokens = tokenHandler(token, lexer, previousTokens);
		return mathTokens;
	};

	private complementFunctionTokens = (tokens: CustomMathToken[]): void => {
		let lastToken = tokens[tokens.length - 1];
		if (tokens.length > 0 && lastToken.text === 'abs(') {
			lastToken.nextTokenIsError = true;
		}
	};

	private numberTokenHandler = (token: moo.Token, lexer: moo.Lexer, previousTokens: CustomMathToken[]): CustomMathToken[] => {
		// '-' of negative number should be treated as minus only when there's a non-symbol or a ')' in front 
		if (parseFloat(token.value) < 0 && !_.isEmpty(previousTokens)) {
			let stripped = _.filter(previousTokens, prevToken => prevToken.type !== CustomMathTokenType.SPACE);
			let lastToken = stripped[stripped.length - 1];
			if (lastToken && (lastToken.type !== CustomMathTokenType.SYMBOL || lastToken.value === ')')) {
				return [{
					type: CustomMathTokenType.SYMBOL,
					text: token.text.charAt(0),
					value: token.text.charAt(0),
					offset: token.offset
				}, {
					type: CustomMathTokenType.NUMBER,
					text: token.text.substring(1),
					value: token.text.substring(1),
					offset: token.offset + 1
				}];
			}
		}
		return [{
			type: CustomMathTokenType.NUMBER,
			text: token.text,
			value: token.value,
			offset: token.offset
		}];
	};

	private symbolTokenHandler = (token: moo.Token): CustomMathToken[] => {
		return [{
			type: CustomMathTokenType.SYMBOL,
			text: token.text,
			value: token.value,
			offset: token.offset
		}];
	};

	private aggrTokenHandler = (token: moo.Token, lexer: moo.Lexer, previousTokens?: CustomMathToken[]): CustomMathToken[] => {
		return this.keywordTokenHandler(token, lexer, CustomMathTokenType.ATTRIBUTE, previousTokens);
	};

	private prefixTokenHandler = (token: moo.Token, lexer: moo.Lexer, previousTokens?: CustomMathToken[]): CustomMathToken[] => {
		let type: CustomMathTokenType = this.getTokenType(token.value);
		return this.keywordTokenHandler(token, lexer, type, previousTokens);
	};

	private getTokenType = (tokenValue: string): CustomMathTokenType => {
		let type: CustomMathTokenType;

		if (tokenValue === MathKeyword.METRIC) {
			type = CustomMathTokenType.METRIC;
		}
		if (tokenValue === MathKeyword.SCORECARD) {
			type = CustomMathTokenType.SCORECARD_METRIC;
		}
		if (tokenValue === MathKeyword.HIERARCHY) {
			type = CustomMathTokenType.HIERARCHY_METRIC;
		}

		return type;
	};

	// for prefix such as metric, hierarchy, scorecard OR aggregation
	private keywordTokenHandler =
		(token: moo.Token, lexer: moo.Lexer, type: CustomMathTokenType, previousTokens?: CustomMathToken[]): CustomMathToken[] => {
			let nextToken: moo.Token = lexer.next();
			if (this.isTokenPart(nextToken)) {
				if (this.isOpenBracket(nextToken)) {
					let tokenAfterOpenBracket: moo.Token = lexer.next();
					nextToken = this.getNextToKeywordToken(nextToken, tokenAfterOpenBracket);
				}
				return this.handleKeywordToken(token, nextToken, type);
			} else {
				return this.handleTokensSeparately(token, nextToken, lexer, previousTokens);
		}
	};

	// keyword[, keyword[A, keyword[A], keyword[]
	private isTokenPart = (token: moo.Token): boolean => {
		let tokenValue = token?.value;
		return token?.type === 'metric' || tokenValue?.startsWith('[');
	};

	private isOpenBracket = (token: moo.Token): boolean => {
		return token?.value === '[';
	};

	private getNextToKeywordToken = (openBracketToken: moo.Token, tokenAfterOpenBracket: moo.Token): moo.Token => {
		if (tokenAfterOpenBracket) {
			tokenAfterOpenBracket.text = '[' + tokenAfterOpenBracket.text;
			return tokenAfterOpenBracket;
		}
		return openBracketToken;
	};

	private handleKeywordToken = (token: moo.Token, nextToken: moo.Token, type: CustomMathTokenType): CustomMathToken[] => {
		let value = nextToken.type === 'metric' ? nextToken.value : undefined;
		let mathToken: CustomMathToken = {
			type,
			text: token.text + nextToken.text,
			value,
			offset: token.offset
		};
		if (type === CustomMathTokenType.ATTRIBUTE) {
			mathToken.subtype = token.value;
		}
		return [mathToken];
	};

	private handleTokensSeparately =
		(token: moo.Token, nextToken: moo.Token, lexer: moo.Lexer, previousTokens?: CustomMathToken[]): CustomMathToken[] => {
			let mathTokens: CustomMathToken[] = this.symbolTokenHandler(token);
			if (nextToken) {
				mathTokens.pushAll(this.handleNextToKeywordToken(nextToken, lexer, previousTokens));
			}

			return mathTokens;
	};

	private handleNextToKeywordToken = (nextToken: moo.Token, lexer: moo.Lexer, previousTokens?: CustomMathToken[]) => {
		let nextTokenHandler = this.tokenHandlers[nextToken.type] || this.symbolTokenHandler;
		return nextTokenHandler(nextToken, lexer, previousTokens);
	};

	private metricTokenHandler = (token: moo.Token, lexer: moo.Lexer): CustomMathToken[] => {
		return [{
			type: CustomMathTokenType.SYSTEM,
			text: token.text,
			value: token.value,
			offset: token.offset
		}];
	};

	private mathTokenHandler = (token: moo.Token, lexer: moo.Lexer): CustomMathToken[] => {
		let parenthesis = lexer.next();
		if (parenthesis) {
			let value = parenthesis.type === 'lparen' ? token.value + parenthesis.value : undefined;
			return [{
				type: CustomMathTokenType.SYMBOL,
				text: token.text + parenthesis.text,
				value,
				offset: token.offset
			}];
		} else {
			return this.symbolTokenHandler(token);
		}
		
	};

	private powTokenHandler = (token: moo.Token, lexer: moo.Lexer): CustomMathToken[] => {
		let text = token.text;
		let nextToken: moo.Token;
		let result: CustomMathToken[] = [{
			type: CustomMathTokenType.SYMBOL,
			text: token.text,
			value: '^(',
			offset: token.offset
		}];
		let space;
		// eslint-disable-next-line no-cond-assign
		while ((nextToken = lexer.next())) {
			if (nextToken.type === 'ws') {
				space = this.handleToken(nextToken, lexer)[0];
				text += nextToken.text;
			} else if (nextToken.type === 'lparen') {
				text += nextToken.text;
				result[0].text = text;
				return result;
			} else if (nextToken.type === 'math') {
				if (space) result.push(space);
				let math = this.handleToken(nextToken, lexer);
				result.pushAll(math);
				let innerTokens = this.parseTillCloseParenthesis(lexer);
				result.pushAll(innerTokens);
				this.addFakeClose(result);
				return result;
			} else {
				if (space) result.push(space);
				let calculation = this.handleToken(nextToken, lexer)[0];
				result.push(calculation);
				this.addFakeClose(result);
				return result;
			}
		}
		return [{
			type: CustomMathTokenType.SYMBOL,
			text: token.text,
			value: token.value,
			offset: token.offset
		}];
	};

	private addFakeClose = (result: CustomMathToken[]): void => {
		const fakeClose: CustomMathToken = {
			type: CustomMathTokenType.SYMBOL,
			value: ')'
		};
		fakeClose.offset = result[result.length - 1].offset + 0.75;
		result.push(fakeClose);
	};

	private parseTillCloseParenthesis = (lexer: moo.Lexer): CustomMathToken[] => {
		let openParenthesis = 1;
		let tokens = [];
		let nextToken: moo.Token;
		// eslint-disable-next-line no-cond-assign
		while ((nextToken = lexer.next())) {
			if (nextToken.type === 'lparen') openParenthesis++;
			if (nextToken.type === 'rparen') openParenthesis--;
			tokens.pushAll(this.handleToken(nextToken, lexer));
			if (openParenthesis === 0) break;
		}
		return tokens;
	};

	private wsTokenHandler = (token: moo.Token): CustomMathToken[] => {
		return  [{
			type: CustomMathTokenType.SPACE,
			text: token.text,
			value: token.value,
			offset: token.offset
		}];
	};
}