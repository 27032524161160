import { Component, OnInit, ChangeDetectionStrategy, Input, Output, EventEmitter,
	ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ScorecardEditorService } from '@app/modules/project/scorecard/editor/scorecard-editor.service';
import { ListOption } from '@app/shared/components/forms/list-option';
import { DriversItem } from '@cxstudio/drivers/entities/drivers-item';
import { DriversReportableTreeItem } from '@cxstudio/drivers/entities/drivers-tree-item';
import { IProjectSelection } from '@cxstudio/projects/project-selection.interface';
import { Scorecard } from '@cxstudio/projects/scorecards/entities/scorecard';
import { ScorecardTopic } from '@cxstudio/projects/scorecards/entities/scorecard-topic';
import { ScorecardFormatterUtils } from '@app/modules/project/scorecard/editor/scorecard-formatter-utils.class';
import { ScorecardModelContext, IScorecardTreeNode } from '@cxstudio/projects/scorecards/scorecard-model-context';
import { Model } from '@cxstudio/reports/entities/model';
import { Subscription } from 'rxjs';
import { CxWizardStepComponent } from '@app/modules/wizard/cx-wizard-step/cx-wizard-step.component';
import { DriversApi } from '@app/modules/drivers/services/drivers-api.service';

@Component({
	selector: 'scorecard-definition-step',
	templateUrl: './scorecard-definition-step.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScorecardDefinitionStepComponent implements OnInit, AfterViewInit, OnDestroy {
	@Input() isNew: boolean;
	@Input() isViewMode: boolean;
	@Input() props: IProjectSelection;
	@Input() scorecard: Scorecard;
	@Input() hasTopicsChanged: () => boolean;

	@Output() onTest = new EventEmitter<void>();
	@Output() onDriverSelected = new EventEmitter<void>();

	@ViewChild('rubricsForm', {static: false}) rubricsForm: NgForm;

	getCheckboxTitleText = this.getWeightOrWeightNLocalizationFunction('scorecards.checkboxTitle');
	getNamedWeightLabel = this.getWeightOrWeightNLocalizationFunction('scorecards.namedWeightLabel');


	drivers: DriversReportableTreeItem[];
	presenceOptions: ListOption<boolean>[];

	autoFailAndWeightMissingError: boolean;
	thresholdBlankError: boolean;
	thresholdOutOfRangeError: boolean;
	thresholdPrecisionError: boolean;
	showCaseUpdatesWarning: boolean;

	modelContext: ScorecardModelContext;
	loading: Promise<any>;
	loadingDrivers: Promise<void>;
	valid: boolean;
	private formStatus$: Subscription;
	private modelContext$: Subscription;
	private loading$: Subscription;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		private scorecardEditorService: ScorecardEditorService,
		private wizardStep: CxWizardStepComponent,
		private driversApi: DriversApi,
	) { }

	ngOnInit(): void {
		this.presenceOptions = [{
			value: true,
			name: this.locale.getString('scorecards.presentLabel')
		}, {
			value: false,
			name: this.locale.getString('scorecards.absentLabel')
		}];

		let modelContext = this.scorecardEditorService.getModelContext();
		this.modelContext = modelContext || {} as ScorecardModelContext;
		this.modelContext$ = this.scorecardEditorService.getModelContextChangeObservable().subscribe((context) => {
			this.modelContext = context;
			this.updateTotal();
			this.isSecondStepValid();
			this.loadDriversForModel(this.modelContext.model);
			this.ref.detectChanges();
		});
		this.loading$ = this.scorecardEditorService.getLoadingChangeObservable().subscribe((promise) => {
			this.loading = Promise.resolve(promise);
			this.ref.detectChanges();
		});
	}

	ngAfterViewInit(): void {
		this.formStatus$ = this.rubricsForm.statusChanges
				.subscribe(this.validityChangeHandler);
	}

	ngOnDestroy(): void {
		if (this.modelContext$) this.modelContext$.unsubscribe();
		if (this.loading$) this.loading$.unsubscribe();
		if (this.formStatus$) this.formStatus$.unsubscribe();
	}

	validityChangeHandler = (): void => {
		this.valid = this.isSecondStepValid();
		this.ref.detectChanges();
		this.wizardStep.setValid(this.valid);
	};

	thresholdChangeHandler = (): void => {
		this.updateThresholdRaw();
		this.validityChangeHandler();
	};

	isSecondStepValid = (): boolean => {
		const isModelSelected = !isEmpty(this.modelContext);
		const thresholdBlankError = isModelSelected && isEmpty(this.modelContext.threshold);
		const thresholdOutOfRangeError = isModelSelected
				&& ((this.modelContext.threshold < 0) || (this.modelContext.threshold > 100));
		const autoFailAndWeightMissingError = isModelSelected
				&& _.some(this.modelContext.scorecardTopics, (topic: ScorecardTopic) => {
					return isEmpty(topic.autoFail) || (!topic.autoFail && isEmpty(topic.topicWeight));
				});
		const thresholdPrecisionError = isModelSelected && !thresholdBlankError
				&& (this.isPrecisionInvalid(this.modelContext.threshold)
					|| this.isPrecisionInvalid(this.modelContext.thresholdRaw));

		this.thresholdBlankError = thresholdBlankError;
		this.thresholdOutOfRangeError = thresholdOutOfRangeError;
		this.autoFailAndWeightMissingError = autoFailAndWeightMissingError;
		this.thresholdPrecisionError = thresholdPrecisionError;

		this.showCaseUpdatesWarning = !this.isNew && this.hasTopicsChanged && this.hasTopicsChanged();

		return !thresholdBlankError
			&& !thresholdOutOfRangeError
			&& !autoFailAndWeightMissingError
			&& !thresholdPrecisionError
			&& this.rubricsForm?.valid;
	};

	private isPrecisionInvalid = (value: number): boolean => {
		return !_.isUndefined(value) && !ScorecardFormatterUtils.isThresholdPrecisionValid(value);
	};

	hasDrivers = (): boolean => {
		return !this.drivers?.isEmpty();
	};

	selectDriver = (driverEntity: DriversItem): void => {
		this.loadingDrivers = this.driversApi.getDriversModel(driverEntity.id).then(driverModel => {
			let hiddenDrivers = _.map(driverEntity.hiddenDrivers, driver => driver.identifier);
			let driverList = driverModel.drivers.filter(driver => !_.contains(hiddenDrivers, driver.identifier));

			this.modelContext.driverModel = {};
			this.modelContext.driver = driverEntity;

			let partitions = _.partition(driverList, (driverItem) => driverItem.strength >= 0);
			partitions.forEach((partition, partitionIndex) => {
				let sortedPartition = _.sortBy(partition, 'strength');

				// We need to reverse sorting for positive drivers
				if (partitionIndex === 0) {
					sortedPartition = sortedPartition.reverse();
				}

				sortedPartition.forEach((driverItem, driverIndex) => {
					if (!driverItem.field.startsWith('_CB_T_')) {
						return;
					}

					let nodePath = driverItem.field.split(':')[1];
					let impactRank = driverIndex + 1;
					this.modelContext.driverModel[nodePath] = driverItem.strength >= 0 ? impactRank : -impactRank;
				});
			});

			this.onDriverSelected.emit();
		});
	};

	private loadDriversForModel = (model: Model): void => {
		this.driversApi.getReportableDriversForModel(
			this.props,
			model.id
		).then(loadedReportableDrivers => {
			this.drivers = loadedReportableDrivers;
			this.ref.detectChanges();
		});
	};

	isScoringEntryVisible = (scoringEntry): boolean => {
		return scoringEntry.visible;
	};

	getTopicLevelPadding = (node: any): string => {
		const level = node.level || 0;
		return node.level ? ((level - 1) * 24) + (node.children ? 0 : 10) + 'px' : '0px';
	};

	toggleScoringEntry = (scoringEntry): void => {
		scoringEntry.expanded = !scoringEntry.expanded;
		this.changeChildrenVisibility(scoringEntry);
	};

	private changeChildrenVisibility = (parent): void => {
		let children = parent.children;
		if (children) {
			children.forEach(child => {
				child.visible = parent.visible && parent.expanded;
				this.changeChildrenVisibility(child);
			});
		}
	};

	getPresenceObject = (scorecardTopic: ScorecardTopic): any => {
		return this.presenceOptions.find(option => {
			return (option.value === scorecardTopic.presence) || ((option.value === false) && isEmpty(scorecardTopic.presence));
		});
	};

	selectPresence = (scorecardTopic: ScorecardTopic, node: any): void => {
		scorecardTopic.presence = node.value;
	};

	getImpactRankForNode = (scoringEntry: ScorecardTopic): string => {
		let impactRank = this.modelContext.driverModel[scoringEntry.idPath];
		return !_.isUndefined(impactRank) ? '' + impactRank : '';
	};

	getImpactRankTooltip = (): string => {
		return this.locale.getString('scorecards.impactRankTooltip', {
			driverName: this.modelContext.driver.displayName
		});
	};

	updateTotal = (): void => {
		let total = 0;
		(this.modelContext.scorecardTopics || []).forEach((scorecardTopic: ScorecardTopic) => {
			if (scorecardTopic.autoFail) {
				scorecardTopic.topicWeight = 0;
			} else {
				total += scorecardTopic.topicWeight || 0;
			}
		});
		this.modelContext.total = total;
		if (!_.isNumber(this.modelContext.thresholdRaw) && _.isNumber(this.modelContext.threshold)) {
			this.updateThresholdRaw();
		}
		this.updateThreshold();
		this.recalculateSumWeights(this.modelContext.scoringEntries);
		this.validityChangeHandler();
	};

	private recalculateSumWeights(entries: IScorecardTreeNode[]): void {
		_.each(entries, entry => {
			this.recalculateSumWeights(entry.children);
			entry.sumWeight = _.reduce(entry.children, (sum, child) => {
				let childWeight = !_.isUndefined(child.topic)
					? child.topic?.topicWeight || 0
					: child.sumWeight;
				return sum + childWeight;
			}, 0);
		});

	}

	isWeighted = (scorecardTopic: ScorecardTopic): boolean => {
		return (scorecardTopic.autoFail === false);
	};

	setAutofail = (scorecardTopic: ScorecardTopic, value: boolean): void => {
		scorecardTopic.autoFail = value;
		this.updateTotal();
	};

	getWeightN = (weight: number): string => {
		if (this.modelContext.total > 0) {
			let weightN = weight ? weight * 100 / this.modelContext.total : 0;
			return weightN.toFixed(2);
		}
		return '';
	};

	updateThreshold = (): void => {
		if (_.isNumber(this.modelContext.total) && this.modelContext.total !== 0 && _.isNumber(this.modelContext.thresholdRaw)) {
			let threshold = this.modelContext.thresholdRaw * 100 / this.modelContext.total;
			this.modelContext.threshold = ScorecardFormatterUtils.normalizeThreshold(threshold);
		}
	};

	updateThresholdRaw = (): void => {
		if (_.isNumber(this.modelContext.total) && _.isNumber(this.modelContext.threshold)) {
			let thresholdRaw = this.modelContext.total * this.modelContext.threshold / 100;
			this.modelContext.thresholdRaw = ScorecardFormatterUtils.normalizeThreshold(thresholdRaw);
		}
	};

	test = (): void => {
		this.onTest.emit();
	};

	private getWeightOrWeightNLocalizationFunction(i18nEntry: string): (entryName: string, weightOrWeightN: 'weight' | 'weightN') => string {
		return (entryName: string, weightOrWeightN: 'weight' | 'weightN'): string => {
			let weight = weightOrWeightN === 'weight' ?
				this.locale.getString('scorecards.weightLabel') :
				this.locale.getString('scorecards.weightNLabel');
			return this.locale.getString(i18nEntry, {name: entryName, weight });
		};
	}
}

app.directive('scorecardDefinitionStep', downgradeComponent({component: ScorecardDefinitionStepComponent}));
