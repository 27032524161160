import { Injectable } from '@angular/core';
import { downgradeInjectable } from '@angular/upgrade/static';
import { ModelSettingsApiService } from '@app/modules/project/model/model-settings-api.service';
import { WorkspaceModelSettingsApiService } from '@app/modules/project/model/workspace-model-settings-api.service';
import { ProjectAssetsHelper, AssetPromise } from '@app/modules/units/project-assets-helper.service';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { ModelSettings } from '@cxstudio/asset-management/configuration/settings-data/model-data';
import { CacheOptions } from '@cxstudio/common/cache-options';

@Injectable({
	providedIn: 'root'
})
export class ModelSettingsService {
	constructor(
		private readonly modelSettingsApiService: ModelSettingsApiService,
		private readonly workspaceModelSettingsApiService: WorkspaceModelSettingsApiService,
	) {}

	getModelSettings = (project: AccountOrWorkspaceProject, modelId: number, cache: CacheOptions): AssetPromise<ModelSettings> => {
		return ProjectAssetsHelper.getAssetsForProject<ModelSettings>(
			project,
			(accountProject) => this.modelSettingsApiService.getModelSettings(accountProject, modelId, cache),
			(workspaceProject) => this.workspaceModelSettingsApiService.getModelSettings(workspaceProject, modelId, cache),
			() => ({})
		);
	};

	saveModelSettings = (project: AccountOrWorkspaceProject, modelId: number, settings: ModelSettings): AssetPromise<void> => {
		return ProjectAssetsHelper.getAssetsForProject<void>(
			project,
			(accountProject) => this.modelSettingsApiService.saveModelSettings(accountProject, modelId, settings),
			(workspaceProject) => this.workspaceModelSettingsApiService.saveModelSettings(workspaceProject, modelId, settings),
			() => {}
		);
	};
}

app.service('modelSettingsService', downgradeInjectable(ModelSettingsService));
