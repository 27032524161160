import { Component, OnInit, ChangeDetectionStrategy, Output, EventEmitter, Input, Inject } from '@angular/core';
import { DocumentExplorerWidget } from '@cxstudio/dashboards/widgets/document-explorer-widget';
import { PredefinedFilterValue } from '@cxstudio/reports/document-explorer/predefined-filter-values.enum';
import { SentencePredefinedFilter } from '@app/modules/document-explorer/sentence-predefined-filter';
import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnSortDirection } from '@cxstudio/common/an-sort-direction';
import { PreviewHelper } from '@cxstudio/reports/preview/preview-helper-service';
import { downgradeComponent } from '@angular/upgrade/static';
import { TuningSuggestionsService } from '../tuning-suggestions/tuning-suggestions.service';
import { IntelligentScoringService } from '../intelligent-scoring/intelligent-scoring.service';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import { ProjectAccessService } from '@app/modules/project/access/project-access.service';

@Component({
	selector: 'document-explorer-toolbar',
	template: `
<div class="explorer-tools d-flex p-16 justify-between"
	[class.audit-mode]="auditMode || rebuttalMode">
	<div *ngIf="!auditMode && !rebuttalMode" class="d-flex flex-fill">
		<document-explorer-sort class="mr-16"
			[sentimentFilters]="sentimentFilters"
			[easeScoreFilters]="easeScoreFilters"
			[sortBy]="sortBy"
			[sortDirection]="sortDirection"
			(sort)="toggleSort.emit($event)"
		></document-explorer-sort>

		<!--  In the future we will implement mstokenname filters elsewhere with a similar UI to that of CXS-1533 -->
		<!-- consider not removing functionality completely. -->
		<!--words-selection props="widget.properties" item= "item" tag-changed="changeWordsFilter()"/-->
		<report-text-filter class="flex-fill search-template input-group overflow-visible mr-16"
			inputLabel="{{'common.search'|i18n}}"
			placeholder="{{'common.search'|i18n}}"
			(filterChange)="searchQueryChange.emit($event)"
			[initialValue]="searchQuery">
		</report-text-filter>

		<document-explorer-metric-filters
			[widget]="widget"
			[sentimentValue]="sentimentValue"
			[sentimentFilters]="sentimentFilters"
			[easeScoreValue]="easeScoreValue"
			[easeScoreFilters]="easeScoreFilters"
			(toggle)="toggleFilter.emit($event)"
		></document-explorer-metric-filters>
	</div>

	<div class="interaction-audit-title"
		*ngIf="auditMode || rebuttalMode"
		[i18n]="'widget.suggestionMode'">
	</div>

	<document-explorer-full-page-toggle
		*ngIf="!isFullPage && !auditMode && !rebuttalMode"
		class="mh-8"
		(click)="fullPageClick.emit()">
	</document-explorer-full-page-toggle>

	<tuning-suggestions-button
		*ngIf="showTuningSuggestions && !rebuttalMode"
		(stateChange)="auditModeChange.emit($event)"
		[hasTemplates]="hasTuningSuggestionTemplates"
		tooltipPlacement="left"
	></tuning-suggestions-button>

	<intelligent-scoring-button
		*ngIf="showIntelligentScoring && !auditMode"
		(stateChange)="rebuttalModeChange.emit($event)"
		tooltipPlacement="left"
	></intelligent-scoring-button>
</div>`,
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class DocumentExplorerToolbarComponent implements OnInit {

	@Input() widget: DocumentExplorerWidget;
	@Input() sentimentValue: PredefinedFilterValue;
	@Input() easeScoreValue: PredefinedFilterValue;
	@Input() sentimentFilters: SentencePredefinedFilter;
	@Input() easeScoreFilters: SentencePredefinedFilter;
	@Input() isFullPage: boolean;

	@Input() sortBy: string;
	@Input() sortDirection: AnSortDirection;

	@Input() auditMode: boolean;
	@Output() auditModeChange = new EventEmitter<boolean>();

	@Input() rebuttalMode: boolean;
	@Output() rebuttalModeChange = new EventEmitter<boolean>();

	@Input() searchQuery: string;
	@Output() searchQueryChange = new EventEmitter<string>();

	@Output() toggleSort = new EventEmitter<string>();
	@Output() toggleFilter = new EventEmitter<{type: PredefinedMetricConstants; value: PredefinedFilterValue}>();
	@Output() fullPageClick = new EventEmitter<void>();

	hasTuningSuggestionTemplates: boolean;
	showTuningSuggestions: boolean;
	showIntelligentScoring: boolean;

	constructor(
		@Inject('previewHelper') private readonly previewHelper: PreviewHelper,
		private readonly projectAccessService: ProjectAccessService,
		private readonly tuningSuggestionsService: TuningSuggestionsService,
		private readonly intelligentScoringService: IntelligentScoringService,
	) { }

	async ngOnInit(): Promise<any> {
		const projectIdentifier = ProjectIdentifier.fromWidgetProperties(this.widget.properties);
		this.showTuningSuggestions = this.tuningSuggestionsService.isAvailable();
		const hasDataAccess = await this.projectAccessService.hasProjectAccess(projectIdentifier);
		this.showIntelligentScoring = this.intelligentScoringService.isRebuttalAvailable()
			&& this.previewHelper.isDocumentPreview(this.widget)
			&& hasDataAccess;

		this.previewHelper.getTuningSuggestionsAvailability(this.widget).then((hasTuningSuggestionTemplates) => {
			this.hasTuningSuggestionTemplates = hasTuningSuggestionTemplates;
		});
	}

}

app.directive('documentExplorerToolbar', downgradeComponent({component: DocumentExplorerToolbarComponent}));
