import { Component, OnInit, Inject, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { Security } from '@cxstudio/auth/security-service';
import { MasterAccountApiService } from '@cxstudio/services/data-services/master-account-api.service';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { PasswordPolicy } from './entities/password-policy';

@Component({
	selector: 'password-policy',
	templateUrl: './password-policy.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordPolicyComponent implements OnInit {
	readonly NUMBER_PATTERN = /^[0-9]+$/;

	passwordPolicy: PasswordPolicy;

	constructor(
		@Inject('securityApiService') private securityApiService: SecurityApiService,
		@Inject('security') private security: Security,
		@Inject('masterAccountApiService') private masterAccountApiService: MasterAccountApiService,
		private ref: ChangeDetectorRef,
	) {}

	ngOnInit(): void {
		this.securityApiService.getPasswordPolicy(this.security.getMasterAccountId()).then((resp) => {
			this.passwordPolicy = resp.data;
			this.ref.detectChanges();
		});
	}

	savePasswordPolicy(): void {
		this.masterAccountApiService.updatePasswordPolicy(this.passwordPolicy);
	}

}

app.directive('passwordPolicy', downgradeComponent({component: PasswordPolicyComponent}));
