import { WidgetIcons } from '@app/modules/dashboard/widget-templates.service';
import ILocale from '@cxstudio/interfaces/locale-interface';
import WidgetType from '@app/modules/widget-settings/widget-type.enum';
import { MetricConstants } from '@cxstudio/reports/providers/cb/constants/metric-constants.service';
import { PeriodOverPeriodMetricService } from '@cxstudio/reports/providers/cb/period-over-period/period-over-period-metric.service';
import { BarWidget } from '@cxstudio/reports/widgets/bar-widget.class';
import { CloudWidget } from '@cxstudio/reports/widgets/cloud-widget.class';
import { HeatmapWidget } from '@cxstudio/reports/widgets/heatmap-widget.class';
import { LineWidget } from '@cxstudio/reports/widgets/line-widget.class';
import { MetricWidget } from '@cxstudio/reports/widgets/metric-widget.class';
import { ModelViewerWidget } from '@cxstudio/reports/widgets/model-viewer-widget.class';
import { NetworkWidget } from '@cxstudio/reports/widgets/network-widget.class';
import { MapWidget } from '@cxstudio/reports/widgets/map-widget.class';
import { PieWidget } from '@cxstudio/reports/widgets/pie-widget.class';
import { PreviewWidget } from '@cxstudio/reports/widgets/preview-widget.class';
import { ReportWidget } from '@cxstudio/reports/widgets/report-widget.class';
import { RubricViewerWidget } from '@cxstudio/reports/widgets/rubric-viewer-widget.class';
import { ScatterWidget } from '@cxstudio/reports/widgets/scatter-widget.class';
import { SelectorWidget } from '@cxstudio/reports/widgets/selector-widget.class';
import { TableWidget } from '@cxstudio/reports/widgets/table-widget.class';

export interface ConversionOption {
	icon?: string;
	title?: string;
	widgetType: WidgetType;
	targetWidget: ReportWidget;
}

export class WidgetConversionsService {

	private MAPPING: {[type in WidgetType]?: () => ConversionOption};

	constructor(
		private locale: ILocale,
		private metricConstants: MetricConstants,
		private periodOverPeriodMetricService: PeriodOverPeriodMetricService,
	) {
		this.MAPPING = {};
		this.MAPPING[WidgetType.BAR] = () => ({
			icon: WidgetIcons.cb_an_bar,
			title: this.locale.getString('widget.barChart'),
			widgetType: WidgetType.BAR,
			targetWidget: new BarWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.LINE] = () => ({
			icon: WidgetIcons.cb_an_line,
			title: this.locale.getString('widget.lineChart'),
			widgetType: WidgetType.LINE,
			targetWidget: new LineWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.TABLE] = () => ({
			icon: WidgetIcons.cb_an_table,
			title: this.locale.getString('widget.tableChart'),
			widgetType: WidgetType.TABLE,
			targetWidget: new TableWidget(this.metricConstants, this.periodOverPeriodMetricService)
		});
		this.MAPPING[WidgetType.SCATTER] = () => ({
			icon: WidgetIcons.cb_an_scatter,
			title: this.locale.getString('widget.scatterChart'),
			widgetType: WidgetType.SCATTER,
			targetWidget: new ScatterWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.PIE] = () => ({
			icon: WidgetIcons.cb_an_pie,
			title: this.locale.getString('widget.pieChart'),
			widgetType: WidgetType.PIE,
			targetWidget: new PieWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.HEATMAP] = () => ({
			icon: WidgetIcons.cb_an_heatmap,
			title: this.locale.getString('widget.heatmap'),
			widgetType: WidgetType.HEATMAP,
			targetWidget: new HeatmapWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.CLOUD] = () => ({
			icon: WidgetIcons.cb_an_cloud,
			title: this.locale.getString('widget.cloud'),
			widgetType: WidgetType.CLOUD,
			targetWidget: new CloudWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.METRIC] = () => ({
			icon: WidgetIcons.cb_an_metric,
			title: this.locale.getString('widget.cb_an_metric'),
			widgetType: WidgetType.METRIC,
			targetWidget: new MetricWidget()
		});
		this.MAPPING[WidgetType.PREVIEW] = () => ({
			widgetType: WidgetType.PREVIEW,
			targetWidget: new PreviewWidget()
		});
		this.MAPPING[WidgetType.SELECTOR] = () => ({
			widgetType: WidgetType.SELECTOR,
			targetWidget: new SelectorWidget()
		});
		this.MAPPING[WidgetType.MODEL_VIEWER] = () => ({
			widgetType: WidgetType.MODEL_VIEWER,
			targetWidget: new ModelViewerWidget()
		});
		this.MAPPING[WidgetType.OBJECT_VIEWER] = () => ({
			widgetType: WidgetType.OBJECT_VIEWER,
			targetWidget: new RubricViewerWidget()
		});
		this.MAPPING[WidgetType.NETWORK] = () => ({
			icon: WidgetIcons.cb_an_network,
			title: this.locale.getString('widget.cb_an_network'),
			widgetType: WidgetType.NETWORK,
			targetWidget: new NetworkWidget(this.metricConstants)
		});
		this.MAPPING[WidgetType.MAP] = () => ({
			icon: WidgetIcons.cb_an_map,
			title: this.locale.getString('widget.cb_an_map'),
			widgetType: WidgetType.MAP,
			targetWidget: new MapWidget(this.metricConstants)
		});
	}

	byWidgetType = (widgetType: WidgetType): ConversionOption => {
		return this.MAPPING[widgetType]?.();
	};

	getSublist = (widgetTypes: WidgetType[]): ConversionOption[] => {
		return _.map(widgetTypes, widgetType => this.MAPPING[widgetType]());
	};
}

app.service('widgetConversions', WidgetConversionsService);
