import { PredefinedMetricConstants } from '@cxstudio/metrics/predefined/predefined-metric-constants';
import { AnalyticMetricTypes } from '@cxstudio/report-filters/constants/analytic-metric-types';
import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { ClarabridgeMetricName } from '@cxstudio/reports/providers/cb/constants/clarabridge-metrics-names';
import { HierarchyUtils } from '@cxstudio/reports/utils/hierarchy-utils.service';

export class AnalyticsDefinitionUtils {

	static isWordsRelated = (grouping: AttributeGrouping): boolean => {
		return grouping && (
			grouping.name === ClarabridgeMetricName.LC
				|| grouping.name === ClarabridgeMetricName.WORDS
				|| grouping.name === ClarabridgeMetricName.HASHTAG
		);
	};

	private static isProjectSummaryGrouping(node: AttributeGrouping): boolean {
		return node && (
			node.name === ClarabridgeMetricName.PROJECT
				|| node.name === ClarabridgeMetricName.MASTER_ACCOUNT
		);
	}

	private static isSentimentGrouping(node: AttributeGrouping): boolean {
		return AnalyticMetricTypes.isPredefinedMetric(node)
			&& AnalyticMetricTypes.isSentimentGroup(node)
			&& !node.standardMetric;
	}

	private static isEffortGrouping(node: AttributeGrouping): boolean {
		return AnalyticMetricTypes.isPredefinedMetric(node)
			&& AnalyticMetricTypes.isEffortGroup(node)
			&& !node.standardMetric;
	}

	private static isTopics(node: AttributeGrouping): boolean {
		return node && AnalyticMetricTypes.isTopics(node);
	}

	static isAnySentiment = (name: string): boolean => {
		return name === PredefinedMetricConstants.SENTIMENT
			|| name === PredefinedMetricConstants.SENTIMENT_3
			|| name === PredefinedMetricConstants.SENTIMENT_5;
	};

	static getHierarchyModelGrouping = (widgetProperties: WidgetProperties) => {
		let metrics = widgetProperties && widgetProperties.selectedAttributes;
		return AnalyticsDefinitionUtils.get(metrics, AnalyticMetricTypes.isHierarchyModel);
	};

	private static get(metrics: any, filter: (metric) => boolean): any[] {
		if (!metrics) return [];

		return _.chain(metrics)
			.filter(attr => !!attr)
			.filter(filter)
			.value();
	}

	private static getFilterForSelections(node: AttributeGrouping): (item) => boolean {
		let optionFilter = item => {
			return item.name === node.name;
		};
		if (AnalyticsDefinitionUtils.isWordsRelated(node)) {
			optionFilter = AnalyticsDefinitionUtils.isWordsRelated;
		}
		if (AnalyticMetricTypes.isTime(node)) {
			optionFilter = AnalyticMetricTypes.isTime;
		}
		if (AnalyticMetricTypes.isHierarchyModel(node)) {
			optionFilter = AnalyticMetricTypes.isHierarchyModel;
		}
		if (AnalyticsDefinitionUtils.isProjectSummaryGrouping(node)) {
			optionFilter = AnalyticsDefinitionUtils.isProjectSummaryGrouping;
		}
		if (AnalyticMetricTypes.isHierarchyModel(node)) {
			optionFilter = AnalyticMetricTypes.isHierarchyModel;
		}
		if (AnalyticsDefinitionUtils.isSentimentGrouping(node)) {
			optionFilter = AnalyticsDefinitionUtils.isSentimentGrouping;
		}
		if (AnalyticsDefinitionUtils.isEffortGrouping(node)) {
			optionFilter = AnalyticsDefinitionUtils.isEffortGrouping;
		}

		if (AnalyticsDefinitionUtils.isTopics(node)) {
			optionFilter = _.noop as any;
		}

		return optionFilter;
	}

	private static getTargetGroups(groups: AttributeGrouping[], node: AttributeGrouping): AttributeGrouping[] {
		let targetGroups;
		if (!groups) {
			return targetGroups;
		}
		return groups;
	}

	private static enableOptions(groups: AttributeGrouping[], previousNode?: AttributeGrouping): void {
		if (previousNode) {
			let targetGroups = AnalyticsDefinitionUtils.getTargetGroups(groups, previousNode);
			let enableOptionFilter = AnalyticsDefinitionUtils.getFilterForSelections(previousNode);
			_.each(targetGroups, group => {
				HierarchyUtils.enableOptions(group, enableOptionFilter);
			});
		}
	}

	private static disableOptions(groups: AttributeGrouping[], node: AttributeGrouping): void {
		if (node) {
			let targetGroups = AnalyticsDefinitionUtils.getTargetGroups(groups, node);
			let disableOptionFilter = AnalyticsDefinitionUtils.getFilterForSelections(node);
			_.each(targetGroups, group => {
				HierarchyUtils.disableOptions(group, disableOptionFilter);
			});
		}
	}

	static toggleAllowedSelections = (groups: AttributeGrouping[], node: AttributeGrouping, previousNode?: AttributeGrouping) => {
		AnalyticsDefinitionUtils.enableOptions(groups, previousNode);
		AnalyticsDefinitionUtils.disableOptions(groups, node);
	};

	static enableAllowedMultiSelection = (groups: AttributeGrouping[], node: AttributeGrouping) => {
		AnalyticsDefinitionUtils.enableOptions(groups, node);
	};

	static disableAllowedMultiSelection = (groups: AttributeGrouping[], node: AttributeGrouping) => {
		AnalyticsDefinitionUtils.disableOptions(groups, node);
	};

	static isSupportStackSorting = (group: AttributeGrouping) => {
		return group && (AnalyticMetricTypes.isStudioMetric(group)
			|| AnalyticMetricTypes.isPredefinedGroup(group));
	};
}



