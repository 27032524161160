
import * as _ from 'underscore';
import { ProjectIdentifier } from '@cxstudio/projects/project-identifier';
import FeedbackViewMobileScreenSettings from './feedback-view-mobile-screen-settings.interface';
import CachedInvocation from '@cxstudio/common/cache/cached-invocation.class';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import WidgetSettingsService, { IWidgetSettings } from '@cxstudio/reports/providers/cb/services/widget-settings.service';
import { DocumentDisplayedItem, DocumentDisplayedItemType } from './document-displayed-item.interface';
import { DocumentViewSelectedAttribute, DocumentViewSelectedItem } from './document-view-selected-item.interface';
import MobileAppSettings from './mobile-app-settings.interface';
import DocumentDisplayedItemsSelection from './document-displayed-items-selection';
import MobileAppConfigurationPromises from './mobile-app-configuration-promises';
import { FeedbackViewMode } from './feedback-view-mode.enum';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { ISimpleScope } from '@cxstudio/interfaces/simple-scope.interface';
import { MetricFilters } from '@cxstudio/reports/utils/metric-filters.service';
import { OptionsTemplatesService } from '@app/modules/widget-settings/options/options-templates.service';
import { OptionsBuilderProvider } from '@cxstudio/reports/settings/options/options-builder-provider.class';
import { OptionsConstant } from '@cxstudio/reports/settings/options/options-constant';

interface FeedbackViewModeOption {
	displayName: string;
	value: FeedbackViewMode;
}

export default class FeedbackViewMobileScreenConfigurationController implements ng.IComponentController {

	screen: FeedbackViewMobileScreenSettings;
	screenIndex: number;
	projectIdentifier: ProjectIdentifier;
	widgetSettingsCache: CachedInvocation;
	settings: MobileAppSettings;
	promises: MobileAppConfigurationPromises;
	feedbackViewModeOptions: FeedbackViewModeOption[];

	displayedItemsTree;

	constructor(
		private $scope: ISimpleScope,
		private widgetSettingsService: WidgetSettingsService,
		private optionsBuilderProvider: OptionsBuilderProvider,
		private optionsTemplatesService: OptionsTemplatesService,
		private locale: ILocale
	) {}

	$onInit(): void {
		this.$scope.$on('settings.projectChanged', this.initializeDisplayedItemsTree);
		this.initializeDisplayedItemsTree();
		this.initializeFeedbackViewModeOptions();
	}

	private initializeDisplayedItemsTree = () => {
		this.resetDisplayedItemsSelector();

		if (ProjectIdentifier.isProjectSelected(this.projectIdentifier)) {
			let widgetProperties = this.propertiesForProjectSelection(this.projectIdentifier);

			let getWidgetSettings = this.widgetSettingsService.getWidgetSettings;
			if (this.widgetSettingsCache) {
				getWidgetSettings = this.widgetSettingsCache.get(getWidgetSettings);
			}

			this.promises.feedback.promise = getWidgetSettings(widgetProperties)
				.then(widgetSettings => this.displayedItemsTree = this.createDisplayedItemsTree(angular.copy(widgetSettings)));
		}
	};

	private resetDisplayedItemsSelector = (): void => {
		this.displayedItemsTree = null;
	};

	private initializeFeedbackViewModeOptions = () => {
		this.feedbackViewModeOptions = [
			{ value: FeedbackViewMode.SENTENCE, displayName: this.locale.getString('preview.sentence') },
			{ value: FeedbackViewMode.SENTENCE_WITH_CONTEXT, displayName: this.locale.getString('preview.sentencesWithContext') },
			{ value: FeedbackViewMode.VERBATIM, displayName: this.locale.getString('preview.verbatim') }
		];
	};

	onRecipientEmailChange = (email: string): void => {
		this.screen.recipientEmail = email;
	};

	onDocumentDisplayedItemsChange = (selection: DocumentDisplayedItemsSelection): void => {
		this.settings.documentViewConfiguration.selectedItems = selection.items.map(this.buildDocumentViewSelectedItem);
	};

	private propertiesForProjectSelection = (projectSelection: ProjectIdentifier): WidgetProperties => {
		return {
			contentProviderId: projectSelection.contentProviderId,
			accountId: projectSelection.accountId,
			project: projectSelection.projectId
		};
	};

	private buildDocumentViewSelectedItem = (modalItem: DocumentDisplayedItem): DocumentViewSelectedItem => {
		if (modalItem.type === DocumentDisplayedItemType.ATTRIBUTE) {
			return this.buildDocumentViewSelectedAttribute(modalItem);
		} else {
			return this.buildDocumentViewSelectedModel(modalItem);
		}
	};

	private buildDocumentViewSelectedAttribute = (modalItem: DocumentDisplayedItem): DocumentViewSelectedAttribute => {
		return {
			id: modalItem.itemId,
			name: modalItem.name,
			displayName: modalItem.originalDisplayName,
			type: DocumentDisplayedItemType.ATTRIBUTE,
			attributeType: modalItem.attributeType,
			level: modalItem.objectType
		};
	};

	private buildDocumentViewSelectedModel = (modalItem: DocumentDisplayedItem): DocumentViewSelectedItem => {
		return {
			id: modalItem.itemId,
			name: modalItem.name,
			displayName: modalItem.originalDisplayName,
			type: DocumentDisplayedItemType.MODEL
		};
	};

	private createDisplayedItemsTree = (widgetSettings: IWidgetSettings): any => {
		let selectedAttributeNames = this.settings.documentViewConfiguration.selectedItems
			.filter(item => item.type === DocumentDisplayedItemType.ATTRIBUTE)
			.map(item => item.name);

		let selectedModelIds = this.settings.documentViewConfiguration.selectedItems
			.filter(item => item.type === DocumentDisplayedItemType.MODEL)
			.map(item => item.id);

		let template = this.optionsTemplatesService.getMobileDocumentDisplayedItemsDialogTemplate(
			model => this.createModelDisplayedItem(selectedModelIds, model),
			attribute => this.createAttributeDisplayedItem(selectedAttributeNames, attribute));

		return this.optionsBuilderProvider.getBuilder(OptionsConstant.GROUP_BY)
				.withTemplate(template)
				.withModels(widgetSettings.models)
				.withAttributes(widgetSettings.attributes, MetricFilters.GROUP_FILTER)
				.withWordAttributes(widgetSettings.wordAttributes)
				.build();
	};

	private createAttributeDisplayedItem = (selectedAttributeNames: string[], attribute): DocumentDisplayedItem => {
		return {
			id: DocumentDisplayedItem.ATTRIBUTE_ID_PREFIX + (attribute.id || attribute.name),
			itemId: attribute.id || 0,
			name: attribute.name,
			type: DocumentDisplayedItemType.ATTRIBUTE,
			attributeType: attribute.type,
			objectType: attribute.objectType,
			displayName: attribute.displayName,
			originalDisplayName: attribute.displayName,
			selected: selectedAttributeNames.contains(attribute.name),
			originalItem: attribute
		} as DocumentDisplayedItem;
	};

	private createModelDisplayedItem = (selectedModelIds: number[], model): DocumentDisplayedItem => {
		let modelId = parseInt(model.name, 10);
		let modelName = model.displayName;

		return {
			id: DocumentDisplayedItem.MODEL_ID_PREFIX + modelId,
			itemId: modelId,
			name: modelName,
			type: DocumentDisplayedItemType.MODEL,
			displayName: modelName,
			originalDisplayName: modelName,
			selected: selectedModelIds.contains(modelId),
			originalItem: model
		} as DocumentDisplayedItem;
	};

	isVerbatimModeSelected = (): boolean => {
		return this.screen.mode === FeedbackViewMode.VERBATIM;
	};

	getVerbatimModeTitle = (): string => {
		return this.isVerbatimModeSelected() ? this.locale.getString('mobile.verbatimModeDisabledOptionTitle') : null;
	};

}

app.component('feedbackViewMobileScreenConfiguration', {
	controller: FeedbackViewMobileScreenConfigurationController,
	templateUrl: 'partials/mobile/feedback-view-mobile-screen-configuration.html',
	bindings: {
		screen: '=',
		screenIndex: '=',
		projectIdentifier: '<',
		widgetSettingsCache: '=?',
		settings: '=',
		promises: '='
	}
});
