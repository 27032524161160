import { Injectable } from '@angular/core';
import { CxHttpService } from '@app/core';
import { HttpPromise } from '@app/core/http-promise';
import { UserUploadPreviewColumn } from '@app/modules/user-administration/user-upload/user-upload-page/user-upload-preview';


export interface UserBulkProcessApiService {
	downloadTemplateFile(): HttpPromise<any>;

	processFileUpload(taskId: string): Promise<any>;

	getColumns(): Promise<UserUploadPreviewColumn[]>;
}

@Injectable({providedIn: 'root'})
export class UserUpdateApiService implements UserBulkProcessApiService {

	constructor(
		private http: CxHttpService
	) {}

	downloadTemplateFile = (): HttpPromise<any> => {
		return this.http.rawGet('rest/users/update/file/template', { responseType: 'arraybuffer' });
	};

	processFileUpload(taskId: string): Promise<any> {
		return this.http.post(`rest/users/update/file/process/${taskId}`);
	}

	getColumns(): Promise<UserUploadPreviewColumn[]> {
		return this.http.get('rest/users/update/file/columns');
	}
}
