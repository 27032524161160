import { Injectable } from '@angular/core';
import { CxCachedHttp } from '@app/core/http/cx-cached-http.class';
import { CxCachedHttpService } from '@app/core/http/cx-cached-http.service';
import { WorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';
import { CacheOptions } from '@cxstudio/common/cache-options';
import { Caches } from '@cxstudio/common/caches';
import { AssetPromisePlatformProject } from '@app/modules/units/project-assets-helper.service';

@Injectable({
	providedIn: 'root'
})
export class WorkspaceProjectContextApiService {
	private cachedHttp: CxCachedHttp;

	constructor(
		private readonly cxCachedHttpService: CxCachedHttpService
	) {
		this.cachedHttp =  this.cxCachedHttpService.cache(Caches.PROJECT_CONTEXT);
	}

	getProjectTimezone(project: WorkspaceProject): AssetPromisePlatformProject<string> {
		const url = this.getBasePath(project) + `/project/${project.projectId}/timezone`;
		const config = { cache: CacheOptions.CACHED, local: true };
		return this.cachedHttp.get(url, config)
			.then(timezone => timezone.name || timezone.offset) as AssetPromisePlatformProject<string>;
	}

	getDesignerPalette(project: WorkspaceProject, local = true, cache = CacheOptions.CACHED): AssetPromisePlatformProject<string[]> {
		const url = this.getBasePath(project) + '/palette';
		const config = { cache, local };
		return this.cachedHttp.get(url, config) as AssetPromisePlatformProject<string[]>;
	}

	private getBasePath = (project: WorkspaceProject): string => {
		return `rest/context/ws/${project.workspaceId}`;
	};
}
