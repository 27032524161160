import { Component, OnInit, Input, Output, EventEmitter,
	ChangeDetectionStrategy, SimpleChanges, OnChanges } from '@angular/core';
import { downgradeComponent } from '@angular/upgrade/static';
import { CxLocaleService } from '@app/core';
import { ColumnFilterType } from '@app/shared/components/filter/column-filter-type';
import { ColumnFilterOption } from '@app/shared/components/filter/column-filter-option';
import { ListColumnFilter } from '@app/shared/components/filter/list-column-filter';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { ListOption } from '@app/shared/components/forms/list-option';
import { AttributesService, IAttributeValuesParams } from '@app/modules/project/attribute/attributes.service';
import { AttributeType } from '@app/modules/project/attribute/attribute-type';
import { ObjectListFilter } from '@app/shared/components/filter/object-list-filter';
import { AccountOrWorkspaceProject } from '@app/modules/units/workspace-project/workspace-project';

@Component({
	selector: 'attributes-filter',
	changeDetection: ChangeDetectionStrategy.OnPush,
	template: `
		<object-list-filter
			[columns]="columns"
			[pinnedColumns]="pinnedColumns"
			[refreshTrigger]="refreshTrigger"
			(apply)="applyFilters($event)"
		></object-list-filter>
	`
})
export class AttributesFilterComponent implements OnInit, OnChanges {

	@Input() project: AccountOrWorkspaceProject;
	@Output() apply = new EventEmitter<ObjectListFilter[]>();

	refreshTrigger: boolean;
	columns: ColumnFilterOption[];
	pinnedColumns: ColumnFilterOption[];

	constructor(
		private locale: CxLocaleService,
		private attributesService: AttributesService,
		private betaFeaturesService: BetaFeaturesService,
	) {}

	ngOnInit(): void {
		let name: ColumnFilterOption = {
			name: this.locale.getString('common.name'),
			value: 'displayName',
			columnFilterType: ColumnFilterType.TEXT
		};

		let type: ListColumnFilter = {
			name: this.locale.getString('attributes.dataType'),
			value: 'type',
			columnFilterType: ColumnFilterType.LIST,
			options: [{
				name: this.locale.getString('administration.text'),
				value: AttributeType.TEXT
			}, {
				name: this.locale.getString('administration.number'),
				value: AttributeType.NUMBER
			}, {
				name: this.locale.getString('administration.date'),
				value: AttributeType.DATE
			}]
		};

		let groupOptions: Array<ListOption<string>> = [{
			name: this.locale.getString('attributes.categoryDerived'),
			value: 'Category derived'
		}, {
			name: this.locale.getString('attributes.system'),
			value: 'System'
		}, {
			name: this.locale.getString('attributes.customer'),
			value: 'Customer-defined'
		}];
		if (this.betaFeaturesService.isFeatureEnabled(BetaFeature.SCORECARDING)) {
			groupOptions.push({
				name: this.locale.getString('attributes.scorecard'),
				value: 'Scorecard'
			});
		}
		let group: ListColumnFilter = {
			name: this.locale.getString('attributes.objectGroup'),
			value: 'objectGroup',
			columnFilterType: ColumnFilterType.LIST,
			options: groupOptions
		};

		this.columns = [name, type, group];
		this.reloadFilters();
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.project.currentValue.projectId !== changes.project.previousValue.projectId) {
			this.refreshTrigger = !this.refreshTrigger;
			this.reloadFilters();
		}
	}

	private reloadFilters = (): void => {
		this.getSourceOptions().then(sourceOptions => {
			let source: ListColumnFilter = {
				name: this.locale.getString('attributes.source'),
				value: 'source',
				columnFilterType: ColumnFilterType.LIST,
				options: sourceOptions
			};
			this.pinnedColumns = [source];
		});
	};

	private getSourceOptions = (): Promise<Array<ListOption<string>>> => {
		let params: Partial<IAttributeValuesParams> = {
			project: this.project,
			attributeNames: ['_id_source'],
		};

		let result: any = this.attributesService.getAttributeValues(params).then((values) => {
			return _.map(values, (item): ListOption<string> => {
				return {value: item, name: item};
			});
		});
		return result;
	};

	applyFilters = (filters: ObjectListFilter[]): void => {
		this.apply.emit(filters);
	};
}

app.directive('attributesFilter', downgradeComponent({component: AttributesFilterComponent}));
