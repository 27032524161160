import { Inject, Injectable } from '@angular/core';
import { TaggingHelper } from '@app/modules/item-grid/services/tagging-helper.service';
import { PromiseUtils } from '@app/util/promise-utils';
import { Security } from '@cxstudio/auth/security-service';
import { IFolderItem } from '@cxstudio/common/folders/folder-item.interface';
import { HiddenItemType } from '@cxstudio/common/hidden-item-type';
import { DashboardService } from '@cxstudio/dashboards/dashboard-service';
import { Dashboard } from '@cxstudio/dashboards/entity/dashboard';
import { DashboardType } from '@cxstudio/dashboards/entity/dashboard-type';
import { SecurityApiService } from '@cxstudio/services/data-services/security-api.service';
import { NavigationService } from '@cxstudio/services/navigation.service';
import { RowNode } from 'ag-grid-enterprise';
import { DashboardListService } from '../../dashboard-list.service';
import { DashboardGridHelperService } from '../dashboard-grid-helper.service';

@Injectable({
	providedIn: 'root'
})
export class AgGridCommonActionsService {
	constructor(
		private readonly dashboardListService: DashboardListService,
		private readonly dashboardGridHelperService: DashboardGridHelperService,
		@Inject('security') private readonly security: Security,
		@Inject('navigationService') private readonly navigationService: NavigationService,
		@Inject('dashboardService') private readonly dashboardService: DashboardService,
		@Inject('securityApiService') private readonly securityApiService: SecurityApiService,
	) {}

	view(dashboard: Dashboard): void {
		this.navigationService.changeDashboard(dashboard, false);
	}

	share(node: RowNode): void {
		this.dashboardService.shareDashboard(node.data).then((changedDashboards) => {
			this.dashboardGridHelperService.updateGridRow(node, changedDashboards[0]);
		});
	}

	copy(dashboard: Dashboard): void {
		this.dashboardListService.setLoading(this.dashboardService.copyDashboard(dashboard)).then((newDashboard) => {
			this.dashboardListService.addDashboards([newDashboard]);
			if (newDashboard.type === DashboardType.DASHBOARD) {
				this.navigationService.changeDashboard(newDashboard, true);
			}
		});
	}

	toggleFavorite(node: RowNode): void {
		let dashboard: Dashboard = node.data;
		dashboard.favorite = !dashboard.favorite;

		PromiseUtils.wrap(this.dashboardService.favoriteDashboard(dashboard)).then(updatedDashboard => {
			this.dashboardGridHelperService.updateGridRow(node, updatedDashboard);
		});
	}

	toggleHide(dashboard: Dashboard): void {
		let key = dashboard.id.toString();
		dashboard.hide = !dashboard.hide;

		this.securityApiService.hideObjectForUser(HiddenItemType.DASHBOARDS, dashboard.hide, key, dashboard.name);

		if (dashboard.hide) {
			//if it is being hidden, add tag to grey it out
			TaggingHelper.tag(dashboard, TaggingHelper.tags.HIDDEN);
			this.security.loggedUser.hiddenDashboards[key] = true;
		} else {
			TaggingHelper.untag(dashboard, TaggingHelper.tags.HIDDEN);
			this.security.loggedUser.hiddenDashboards[key] = false;

		}
	}

	remove(dashboard: Dashboard): void {
		this.dashboardService.removeDashboard(dashboard).then(() => {
			this.dashboardListService.removeDashboards([dashboard]);
		});
	}

	transfer(dashboards: Dashboard[]): void {
		this.dashboardService.transferDashboardsBulk(dashboards).then(() => {
			this.dashboardListService.reloadDashboards();
		});
	}

	moveToFolder(dashboard: Dashboard, folder: IFolderItem): void {
		this.dashboardListService.setLoading(this.dashboardService.moveDashboard(dashboard, folder)).then(() => {
			this.dashboardListService.updateDashboards([dashboard, folder]);
		});
	}

}
