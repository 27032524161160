import * as _ from 'underscore';
import { Component, Input, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { ReportCalculation } from '@cxstudio/reports/providers/cb/calculations/report-calculation';

@Component({
	selector: 'additional-metrics-builder',
	templateUrl: './additional-metrics-builder.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AdditionalMetricsBuilderComponent {
	@Input() maxLimit: number;
	@Input() metrics: ReportCalculation[];
	@Input() hierarchyList: ReportCalculation[];
	@Input() fixedMetrics: ReportCalculation[];

	@Output() metricChange = new EventEmitter<void>();

	constructor() {
	}

	metricCanBeAdded = () => {
		return !this.isCountLimitReached();
	};

	metricCanBeRemoved = (metric: ReportCalculation) => {
		return !this.isFixedMetric(metric) && this.metrics.length > 1;
	};

	addMetric = () => {
		if (!this.isCountLimitReached()) {
			this.metrics.push({} as ReportCalculation);
		}
	};

	removeMetric = (index: number) => {
		if (this.metricCanBeRemoved) {
			this.metrics.splice(index, 1);
		} else {
			this.metrics[0] = {} as ReportCalculation;
		}

		this.metricChange.emit();
	};

	onMetricChange = (index: number, metric: ReportCalculation) => {
		this.metrics[index] = metric;
		this.metricChange.emit();
	};

	private isCountLimitReached = () => {
		return this.metrics.length >= this.maxLimit;
	};

	private isFixedMetric = (metric: ReportCalculation): boolean => {
		return !!_.findWhere(this.fixedMetrics, { name: metric.name });
	};
}