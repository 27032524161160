import { ChangeDetectionStrategy, Component, Input, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { ContentWidget } from '@cxstudio/dashboards/widgets/widget';
import { ContentWidgetProperties, ButtonWidgetVisualProperties, ButtonPosition, ButtonTextPosition, BackgroundType } from '@cxstudio/reports/entities/content-widget-properties';
import { UIOption } from '@discover/unified-angular-components/dist/unified-angular-components';
import { CxLocaleService } from '@app/core';
import { WidgetsEditService } from '@cxstudio/home/widgets-edit.service';
import { ColorUtils } from '@cxstudio/reports/utils/color-utils.service';
import WidgetUtils from '@cxstudio/reports/entities/widget-utils';
import { WidgetUtilsService } from '@cxstudio/reports/utils/widget-utils.service';
import { WidgetVisualization } from '@cxstudio/reports/entities/widget-visualization';

@Component({
	selector: 'content-button-settings',
	templateUrl: './content-button-settings.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContentButtonSettingsComponent implements OnInit {
	@Input() widget: ContentWidget<ContentWidgetProperties, ButtonWidgetVisualProperties>;

	positionOptions: UIOption<ButtonPosition>[];
	textPlacementOptions: UIOption<ButtonTextPosition>[];

	utils: WidgetUtils;
	trigger: boolean;

	constructor(
		private ref: ChangeDetectorRef,
		private locale: CxLocaleService,
		@Inject('widgetsEditService') private widgetsEditService: WidgetsEditService,
		@Inject('colorUtils') private colorUtils: ColorUtils,
		@Inject('widgetUtilsService') private widgetUtilsService: WidgetUtilsService,
	) { }

	ngOnInit(): void {
		this.positionOptions = [
			{value: ButtonPosition.LEFT, displayName: this.locale.getString('widgetSettings.buttonPosition_LEFT')},
			{value: ButtonPosition.CENTER, displayName: this.locale.getString('widgetSettings.buttonPosition_CENTER')},
			{value: ButtonPosition.RIGHT, displayName: this.locale.getString('widgetSettings.buttonPosition_RIGHT')},
		];

		this.textPlacementOptions = [
			{value: ButtonTextPosition.ABOVE, displayName: this.locale.getString('widgetSettings.buttonTextPosition_ABOVE')},
			{value: ButtonTextPosition.BELOW, displayName: this.locale.getString('widgetSettings.buttonTextPosition_BELOW')},
		];

		this.initDefaults();

		this.widgetUtilsService.getBuilder(this.widget).build().then(utils => {
			this.utils = utils;
			this.ref.markForCheck();
		});
	}

	private initDefaults() {
		let visualProps = this.widget.visualProperties;
		visualProps.visualization = WidgetVisualization.BUTTON;
		if (_.isUndefined(visualProps.text)) {
			visualProps.text = this.locale.getString('dashboard.clickHere');
		}
		if (_.isUndefined(visualProps.backgroundColor))
			visualProps.backgroundColor = BackgroundType.DEFAULT;
		if (_.isUndefined(visualProps.position))
			visualProps.position = ButtonPosition.CENTER;
		if (_.isUndefined(visualProps.instructionalTextPosition))
			visualProps.instructionalTextPosition = ButtonTextPosition.ABOVE;
		if (_.isUndefined(visualProps.buttonColor)) {
			visualProps.buttonColor = this.colorUtils.getDefaultCustomColor(
				this.colorUtils.getDashboardDefaultColor(this.widgetsEditService.getDashboard()?.properties));
		}
	}

	redraw(): void {
		this.trigger = !this.trigger;
		this.ref.markForCheck();
	}

}
