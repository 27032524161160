import * as _ from 'underscore';
import TableFormattersService from '@cxstudio/components/table/table-formatters.service';
import { TableColumn } from '@cxstudio/reports/entities/table-column';
import { Injectable, Inject } from '@angular/core';
import { CxLocaleService } from '@app/core';
import { MasterAccountVoiceStatus } from './master-account-voice-status';

@Injectable({
	providedIn: 'root'
})
export class VoiceStatusTableColumnsService {

	constructor(
		private locale: CxLocaleService,
		@Inject('tableFormattersService') private tableFormattersService: TableFormattersService,
	) {}

	getColumns = (): Array<TableColumn<any>> => {
		return [{name: 'masterAccountName',
			displayName: this.locale.getString('common.masterAccount'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'nfsServer',
			displayName: this.locale.getString('mAccount.nfsServer'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'vttServer',
			displayName: this.locale.getString('mAccount.vttServer'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'transcriptionService',
			displayName: this.locale.getString('mAccount.transcriptionService'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'meaningfulSilenceThreshold',
			displayName: this.locale.getString('mAccount.meaningfulSilenceLabel'),
			formatter: this.tableFormattersService.plainTextFormatter,
			width: 0.1
		}, {
			name: 'allowAudioDownload',
			displayName: this.locale.getString('mAccount.allowAudioDownload'),
			formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountVoiceStatus>('allowAudioDownload'),
			width: 0.1
		}, {
			name: 'allowTranscriptDownload',
			displayName: this.locale.getString('mAccount.allowTranscriptDownload'),
			formatter: this.tableFormattersService.genericYesNoFormatter<MasterAccountVoiceStatus>('allowTranscriptDownload'),
			width: 0.1
		}];
	};
}
