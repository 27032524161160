import { Security } from '@cxstudio/auth/security-service';
import { HttpHandlers } from '@cxstudio/common/http-handlers';
import { IDriversDateRange } from '@cxstudio/drivers/entities/drivers-definition';
import { AccountProject } from '@cxstudio/content-provider-api/account-project';
import * as _ from 'underscore';
import { DriversHelperService } from './drivers-helper.service';
import { DriversItem } from '../entities/drivers-item';
import { IFilterRule, AdhocFilter } from '@cxstudio/reports/entities/adhoc-filter.class';
import { DriverResourcesContainer } from '@cxstudio/drivers/editor/driver-resources-container';


export class DriversFiltersPanelController implements ng.IController {

	projectSelection: AccountProject;
	onChange: () => void;
	driversItem: DriversItem;
	resourceContainer: DriverResourcesContainer;

	loaded: boolean;
	filterItems: any[];
	timezone: string;

	private lastFiltersState: {
		dateRange: IDriversDateRange;
		filterRules: IFilterRule[];
	};

	constructor(
		private driversHelperService: DriversHelperService
	) {

	}

	$onInit(): void {
		this.resourceContainer.get().then(resources => {
			this.timezone = resources.timezone;
			this.filterItems = this.driversHelperService.getFilterItems(
				resources.attributes, resources.wordAttributes, resources.models, resources.context);
			this.loaded = true;
		});
	}

	onFiltersChanged = () => {
		let filterRules = this.driversHelperService.getNonEmptyFilters(
			this.driversItem.definition.additionalFilters.filterRules);
		if (!this.driversHelperService.areFiltersValid({filterRules} as AdhocFilter))
			return;
		let newFiltersState = {
			filterRules,
			dateRange: this.driversItem.definition.dateRange
		};
		if (angular.equals(newFiltersState, this.lastFiltersState))
			return;
		this.lastFiltersState = angular.copy(newFiltersState);
		this.onChange();
	};
}

app.component('driversFiltersPanel', {
	controller: DriversFiltersPanelController,
	templateUrl: 'partials/drivers/drivers-filters-panel.html',
	bindings: {
		projectSelection: '<',
		driversItem: '<driver',
		resourceContainer: '<resources',
		onChange: '&'
	}
});
