import { EsQueryService } from '@app/modules/filter-builder/es-query/es-query.service';
import { IPlatformFilter } from '@app/modules/filter/entities/platform-filter';
import { ReportFiltersService } from '@app/modules/filter/services/report-filters.service';
import { PromiseUtils } from '@app/util/promise-utils';
import Widget from '@cxstudio/dashboards/widgets/widget';
import ILocale from '@cxstudio/interfaces/locale-interface';
import { FilterMatchModes } from '@cxstudio/report-filters/constants/filter-match-modes.service';
import { FilterRuleTypes } from '@cxstudio/report-filters/constants/filter-rule-type.value';
import { FilterTypes } from '@cxstudio/report-filters/constants/filter-types-constant';
import { WidgetProperties } from '@cxstudio/reports/entities/widget-properties';
import { DateFilterService } from '@cxstudio/services/date-filter-service';
import * as cloneDeep from 'lodash.clonedeep';
import * as moment from 'moment';
import * as _ from 'underscore';



export class FilterParsingService {
	constructor(
		private readonly $q: ng.IQService,
		private readonly reportFiltersService: ReportFiltersService,
		private readonly locale: ILocale,
		private readonly dateFilterService: DateFilterService,
		private readonly filterMatchModes: FilterMatchModes,
		private readonly esQueryService: EsQueryService,
	) {}

	buildWidgetPlatformFilters = (apiFilters: IPlatformFilter[], appliedFilterIds: number[]): any[] => {
		if (!apiFilters || !apiFilters.length || !appliedFilterIds || !appliedFilterIds.length) {
			return [];
		}

		return appliedFilterIds.map((appliedFilterId) => {
			let apiFilter = _.findWhere(apiFilters, { id: appliedFilterId });
			return apiFilter
				? {
					id: appliedFilterId,
					name: apiFilter.name,
					shared: apiFilter.shared
				} : null;
		}).filter((namedFilter) => {
			return namedFilter !== null;
		});
	};

	getWidgetPlatformFilters = (widget: Widget): ng.IPromise<IPlatformFilter[]> => {
		let filterIds = this.getFilterIds(widget.properties);
		if (filterIds && filterIds.length) {
			return PromiseUtils.old(this.reportFiltersService.getWidgetPlatformFilters(widget))
					.then((response) => this.buildWidgetPlatformFilters(response, filterIds));
		} else {
			return this.$q.when([]);
		}
	};

	private getFilterIds = (properties: WidgetProperties): number[] => {
		return _.chain(properties.appliedFilters && properties.appliedFilters.filters)
			.filter((appliedFilter) => {
				return appliedFilter.type === FilterTypes.CMP;
			}).map((cmpFilter) => {
				return parseInt(cmpFilter.filterId, 10);
			}).value();
	};

	getPrivateWidgetPlatformFilters = (widget: Widget): ng.IPromise<IPlatformFilter[]> => {
		return this.getWidgetPlatformFilters(widget).then((filters) => {
			return filters.filter((filter) => {
				return !filter.shared;
			});
		});
	};

	validateAndModifyDateRange = (dateObject, fromTimeField: string, toTimeField: string): boolean => {
		if (!isEmpty(dateObject) ) {
			let dateOne = dateObject.fromDate;
			let dateTwo = dateObject.toDate;
			//If dateOne is at least 1 day before dateTwo, range is fine
			if (moment(dateOne).isBefore(moment(dateTwo), 'day')) {
				return true;
			} else {
				if (this.validateTwoTimes(dateObject[fromTimeField], dateObject[toTimeField])) {
					return true;
				} else {
					dateObject.fromDate = dateObject.toDate;
					dateObject[fromTimeField] = cloneDeep(dateObject[toTimeField]);
					return false;
				}
			}
		}
		return false;
	};

	private validateTwoTimes = (timeOne, timeTwo): boolean => {
		if ((timeOne.amPm === 'AM' && timeTwo.amPm === 'PM')) {
			return true;
		}
		if ((timeOne.amPm === 'PM' && timeTwo.amPm === 'AM')) {
			return false;
		}
		//Add 12 hour offset for AM/PM
		let momentOne = this.createMomentWithOffset(timeOne);
		let momentTwo = this.createMomentWithOffset(timeTwo);
		return !momentOne.isAfter(momentTwo);
	};

	private createMomentWithOffset = (timeAmPm): moment.Moment => {
		let momentOffset = 0;
		if (timeAmPm.hours === 12) {
			momentOffset = -12 ;
		}
		return moment({hours: timeAmPm.hours + momentOffset, minutes: timeAmPm.minutes});
	};

	getRuleString = (rule): string => {
		if (!rule) return '';
		if (FilterRuleTypes.isEsQueryRule(rule)) return this.esQueryService.getRuleString(rule.esQueryObject);
		if (FilterRuleTypes.isDateRangeRule(rule)) return this.dateFilterService.getDateFilterRuleLabel(rule, undefined);
		if (FilterRuleTypes.isGlobalOtherRule(rule)) {
			return [rule.displayName, this.locale.getString('reportFilters.is'), this.locale.getString('reportFilters.globalOther')].join(' ');
		}
		if (FilterRuleTypes.isRawRule(rule)) return rule.displayName;
		let matchMode = this.filterMatchModes.valueOf(rule);
		if (!matchMode) return '';
		if (this.filterMatchModes.isModelMatch(rule)) return rule.displayName;
		if (rule.existMatch) return `${rule.displayName} ${matchMode.displayName}`;
		return `${rule.displayName} ${matchMode.displayName} ${matchMode.toString(rule)}`;
	};


}

app.service('filterParsingService', FilterParsingService);
