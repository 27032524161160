import { BetaFeature } from '@app/modules/context/beta-features/beta-feature';
import { BetaFeaturesService } from '@app/modules/context/beta-features/beta-features-service';
import { CustomMathFunctionHelper } from '@app/modules/metric/definition/custom-math/editor/custom-math-function-helper.service';
import { MetricType } from '@app/modules/metric/entities/metric-type';
import { ExpressionItem } from '@cxstudio/metrics/custom-math/expression-item.class';
import { ExpressionPieces } from '@cxstudio/metrics/custom-math/expression-pieces.constant';
import { HierarchyMetricExpressionItem } from '@cxstudio/metrics/custom-math/hierarchy-metric-expression-item.class';
import { MetricExpressionItem } from '@cxstudio/metrics/custom-math/metric-expression-item.class';
import { CalculationFunction } from '@cxstudio/reports/calculation-function';
import * as _ from 'underscore';
import { AttributeExpressionItem } from './attribute-expression-item.class';
import { Metric } from '@cxstudio/metrics/entities/metric.class';


export interface CustomMathValidator {
	isValid: (expression: ExpressionItem[]) => boolean;
	warning: string;
}

export enum ExpressionErrorType {
	HANGING_OPERATOR = 'HANGING_OPERATOR',
	CONSECUTIVE_OPERATOR = 'CONSECUTIVE_OPERATOR',
	CONSECUTIVE_VARIABLE = 'CONSECUTIVE_VARIABLE',
	EMPTY_PARENTHESES = 'EMPTY_PARENTHESES',
	IMPLIED_MULTIPLICATION = 'IMPLIED_MULTIPLICATION',
	NOT_COMPLETED_FUNCTION = 'NOT_COMPLETED_FUNCTION',
	NOT_COMPLETED_OPERATORS = 'NOT_COMPLETED_OPERATORS',
	MAX_VARIABLES_IN_EXPRESSION = 'MAX_VARIABLES_IN_EXPRESSION',
	NOT_SUPPORTED_AGGREGATION = 'NOT_SUPPORTED_AGGREGATION'
}

export interface ExpressionError {
	index: number;
	error: ExpressionErrorType;
}

export interface PairExpression {
	index: number;
	open: boolean;
	hasPair: boolean;
}

export interface PairExpressionsContainer {
	parentheses: PairExpression[];
	brackets: PairExpression[];
}

type PairValidator = (item1: ExpressionItem, item2: ExpressionItem) => boolean;

export class CustomMathValidationService {

	// maximum number of variables in an expression
	readonly MAX_VARIABLES_IN_EXPRESSION = 15;

	constructor(
		private customMathFunctionHelper: CustomMathFunctionHelper,
		private betaFeaturesService: BetaFeaturesService
	) {}

	private isEmptyParenthesis = (item1: ExpressionItem, item2: ExpressionItem): boolean => {
		if (this.isOpenParenthesis(item1) && this.isCloseParenthesis(item2)) return false;
		return true;
	};

	private isConsecutiveOperators = (item1: ExpressionItem, item2: ExpressionItem): boolean => {
		if (this.isNonEnclosingSymbol(item1) && this.isNonEnclosingSymbol(item2)) return false;
		if (this.isOpenEnclosingSymbol(item1) && this.isNonEnclosingSymbol(item2)) return false;
		if (this.isNonEnclosingSymbol(item1) && this.isCloseEnclosingSymbol(item2)) return false;

		return true;
	};

	// iterate over items for validation
	private validateItemPairs = (expression: ExpressionItem[], isValidPair: PairValidator): boolean => {
		return this.getInvalidItemPairs(expression, isValidPair).isEmpty();
	};

	private getInvalidItemPairs = (expression: ExpressionItem[], isValidPair: PairValidator): number[] => {
		let result = [];
		if (!expression || !expression.length) return result;
		for (let i = 0; i < expression.length - 1; i++) {
			if (!isValidPair(expression[i], expression[i + 1])) result.push(i);
		}
		return result;
	};

	private isImpliedMultiplication = (item1: ExpressionItem, item2: ExpressionItem): boolean => {
		if ((this.isVariableOrNumber(item1) && this.isOpenParenthesisOrAbsolute(item2)) ||
				(this.isCloseEnclosingSymbol(item1) && this.isVariableOrNumber(item2)) ||
				(this.isCloseEnclosingSymbol(item1) && this.isOpenParenthesisOrAbsolute(item2))) {
			return false;
		}
		return true;
	};

	private isConsecutiveVariables = (item1: ExpressionItem, item2: ExpressionItem): boolean => {
		if (this.isVariableOrNumber(item1) && this.isVariableOrNumber(item2)) { return false;	}
		return true;
	};


	private isVariableCountValid = (expression: ExpressionItem[], metrics: Metric[]): boolean => {
		if (!expression || !expression.length) return true;

		return (this.variableCount(expression, metrics) <= this.MAX_VARIABLES_IN_EXPRESSION);
	};

	private variableCount = (expression: ExpressionItem[], metrics: Metric[]): number => {
		if (!expression || !expression.length) return 0;

		return _.uniq(expression, (item: any) => item.displayName)
			.filter(item => this.isNonValueMetricVariable(item, metrics)).length;
	};

	private isNotEmptyAbsoluteFunction = (item1: ExpressionItem, item2: ExpressionItem): boolean => {
		if (this.isOpenAbsolute(item1) && this.isCloseParenthesis(item2) && !item1.nextItemIsError) return false;
		return true;
	};

	private isVariableOrNumber = (item: ExpressionItem): boolean => {
		return this.isVariable(item) || this.isNumber(item);
	};

	// ------------helpers-----------
	private isSymbol = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.SYMBOL;
	};

	private isNonEnclosingSymbol = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.SYMBOL
			&& !(this.isParenthesis(item)) && !(this.isOpenAbsolute(item)) && !(this.isOpenExponent(item));
	};

	private isOpenEnclosingSymbol = (item: ExpressionItem): boolean => {
		return this.isOpenParenthesis(item) || this.isOpenAbsolute(item) || this.isOpenExponent(item);
	};

	private isCloseEnclosingSymbol = (item: ExpressionItem): boolean => {
		return this.isCloseParenthesis(item);
	};

	private isParenthesis = (item: ExpressionItem): boolean => {
		return (this.isOpenParenthesis(item) || this.isCloseParenthesis(item));
	};

	private isOpenParenthesis = (item: ExpressionItem): boolean => {
		return (item.value === '(');
	};

	private isCloseParenthesis = (item: ExpressionItem): boolean => {
		return (item.value === ')');
	};

	private isOpenAbsolute = (item: ExpressionItem): boolean => {
		return item.value === 'abs(';
	};

	private isOpenExponent = (item: ExpressionItem): boolean => {
		return item.value === '^(';
	};

	private isOpenBracket = (item: ExpressionItem): boolean => {
		return item.value === '[';
	};

	private isCloseBracket = (item: ExpressionItem): boolean => {
		return item.value === ']';
	};

	private isOpenParenthesisOrAbsolute = (item: ExpressionItem): boolean => {
		return this.isOpenParenthesis(item) || this.isOpenAbsolute(item);
	};

	private isNumber = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.NUMBER;
	};

	private isMetric = (item: ExpressionItem): item is MetricExpressionItem => {
		return item.type === ExpressionPieces.METRIC;
	};

	private isNonValueMetric = (item: ExpressionItem, metrics: Metric[]): boolean => {
		if (!this.isMetric(item))
			return false;
		let metric = _.findWhere(metrics, {id: item.id});
		return metric?.definition.type !== MetricType.VARIABLE;
	};

	private isHierarchyMetric = (item: ExpressionItem): item is HierarchyMetricExpressionItem => {
		return item.type === ExpressionPieces.ORGANIZATION_HIERARCHY_METRIC;
	};

	private isAttribute = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.ATTRIBUTE;
	};

	private isScorecardMetric = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.SCORECARD_STUDIO_METRIC;
	};

	private isSystemMetric = (item: ExpressionItem): boolean => {
		return item.type === ExpressionPieces.SYSTEM_METRIC;
	};

	private isVariable = (item: ExpressionItem): boolean => {
		return this.isNonMetricVariable(item) || this.isMetric(item);
	};

	private isNonValueMetricVariable = (item: ExpressionItem, metrics: Metric[]): boolean => {
		return this.isNonMetricVariable(item) || this.isNonValueMetric(item, metrics);
	};

	private isNonMetricVariable = (item: ExpressionItem): boolean => {
		return this.isAttribute(item) || this.isSystemMetric(item) || this.isHierarchyMetric(item) || this.isScorecardMetric(item);
	};

	private getPairErrors = (index: number, error: ExpressionErrorType): ExpressionError[] => {
		return [{
			index,
			error
		}, {
			index: index + 1,
			error
		}];
	};

	getInvalidExpressions = (expression: ExpressionItem[], metrics: Metric[]): ExpressionError[] => {
		if (expression.isEmpty()) return [];
		let errors: ExpressionError[] = [];

		if (!this.isVariableCountValid(expression, metrics)) {
			expression.forEach((item: ExpressionItem, itemIndex: number) => {
				if (this.isNonValueMetricVariable(item, metrics)) {
					errors.push({index: itemIndex, error: ExpressionErrorType.MAX_VARIABLES_IN_EXPRESSION});
				}
			});
		}

		if (this.isNonEnclosingSymbol(expression[0])) {
			errors.push({index: 0, error: ExpressionErrorType.HANGING_OPERATOR});
		}

		if (this.isNonEnclosingSymbol(expression[expression.length - 1])) {
			errors.push({index: expression.length - 1, error: ExpressionErrorType.HANGING_OPERATOR});
		}

		errors.pushAll(this.getNotCompletedFunctionErrors(expression));

		if (!this.betaFeaturesService.isFeatureEnabled(BetaFeature.COUNT_DISTINCT)) {
			errors.pushAll(this.getNotAvailableAggregationErrors(expression));
		}

		this.getInvalidItemPairs(expression, this.isEmptyParenthesis)
			.forEach(index => errors.pushAll(this.getPairErrors(index, ExpressionErrorType.EMPTY_PARENTHESES)));
		this.getInvalidItemPairs(expression, this.isNotEmptyAbsoluteFunction)
			.forEach(index => errors.pushAll(this.getPairErrors(index, ExpressionErrorType.EMPTY_PARENTHESES)));
		this.getInvalidItemPairs(expression, this.isConsecutiveOperators)
			.forEach(index => errors.pushAll(this.getPairErrors(index, ExpressionErrorType.CONSECUTIVE_OPERATOR)));
		this.getInvalidItemPairs(expression, this.isConsecutiveVariables)
			.forEach(index => errors.pushAll(this.getPairErrors(index, ExpressionErrorType.CONSECUTIVE_VARIABLE)));
		this.getInvalidItemPairs(expression, this.isImpliedMultiplication)
			.forEach(index => errors.pushAll(this.getPairErrors(index, ExpressionErrorType.IMPLIED_MULTIPLICATION)));

		errors.pushAll(this.getNotClosedBracketsOrParenthesisErrors(expression));

		return errors;
	};

	private getNotCompletedFunctionErrors(expression: ExpressionItem[]): ExpressionError[] {
		let notCompletedFunctionErrors: ExpressionError[] = [];

		let startFunctionIndexes = this.customMathFunctionHelper.findFunctionStartExpressionItems(expression);
		startFunctionIndexes.forEach(startFunctionIndex => {
			if (!this.customMathFunctionHelper.isFunctionCompleted(expression, startFunctionIndex)) {
				notCompletedFunctionErrors.push({index: startFunctionIndex, error: ExpressionErrorType.NOT_COMPLETED_FUNCTION});
			}
		});

		return notCompletedFunctionErrors;
	}

	private getNotAvailableAggregationErrors(expression: ExpressionItem[]): ExpressionError[] {
		let notAvailableAggregationErrors: ExpressionError[] = [];

		_.each(expression, (expressionItem, index) => {
			if (this.isAttributeItem(expressionItem) && expressionItem.operator === CalculationFunction.COUNT_DISTINCT) {
				notAvailableAggregationErrors.push({index, error: ExpressionErrorType.NOT_SUPPORTED_AGGREGATION});
			}
		});

		return notAvailableAggregationErrors;
	}

	private isAttributeItem(item: ExpressionItem): item is AttributeExpressionItem {
		return item.type === ExpressionPieces.ATTRIBUTE;
	}

	private getNotClosedBracketsOrParenthesisErrors = (expressions: ExpressionItem[]): ExpressionError[] => {
		let parenthesesAndBracketsExpressions: PairExpressionsContainer = this.getParenthesesAndBracketsExpressions(expressions);
		return this.getPairExpressionsErrors(parenthesesAndBracketsExpressions);
	};

	private getParenthesesAndBracketsExpressions(expressions: ExpressionItem[]): PairExpressionsContainer {
		let parentheses: PairExpression[] = [];
		let brackets: PairExpression[] = [];

		_.each(expressions, (expression, index) => {
			if (this.isOpenEnclosingSymbol(expression)) {
				parentheses.push({index, open: true, hasPair: false});
			} else if (this.isCloseEnclosingSymbol(expression)) {
				parentheses.push({index, open: false, hasPair: false});
			} else if (this.isOpenBracket(expression)) {
				brackets.push({index, open: true, hasPair: false});
			} else if (this.isCloseBracket(expression)) {
				brackets.push({index, open: false, hasPair: false});
			}
		});

		return {
			parentheses,
			brackets
		};
	}

	private getPairExpressionsErrors(pairExpressionsContainer: PairExpressionsContainer): ExpressionError[] {
		this.markPairs(pairExpressionsContainer.parentheses);
		this.markPairs(pairExpressionsContainer.brackets);
		return this.convertToErrorExpressions(pairExpressionsContainer);
	}

	private markPairs(pairExpressions: PairExpression[]): void {
		_.each(pairExpressions, (pairExpression, index) => {
			if (pairExpression.open) {
				this.markPair(index, pairExpressions);
			}
		});
	}

	private markPair(index: number, pairExpressions: PairExpression[]): void {
		let additionalOpenParenthesis: number = 0;
		for (let i = index + 1; i < pairExpressions.length; i++) {
			if (pairExpressions[i].open) {
				additionalOpenParenthesis++;
			} else {
				if (additionalOpenParenthesis === 0) {
					pairExpressions[index].hasPair = true;
					pairExpressions[i].hasPair = true;
					break;
				}
				additionalOpenParenthesis--;
			}
		}
	}

	private convertToErrorExpressions(pairExpressionsContainer: PairExpressionsContainer): ExpressionError[] {
		let errors: ExpressionError[] = [];

		let parenthesesErrors: ExpressionError[] = this.convertToErrors(pairExpressionsContainer.parentheses);
		if (parenthesesErrors) {
			errors.pushAll(parenthesesErrors);
		}
		let bracketsErrors: ExpressionError[] = this.convertToErrors(pairExpressionsContainer.brackets);
		if (bracketsErrors) {
			errors.pushAll(bracketsErrors);
		}

		return _.sortBy(errors, 'index');
	}

	private convertToErrors(expressions: PairExpression[]): ExpressionError[] {
		return _.chain(expressions)
			.filter(expression => !expression.hasPair)
			.map(expression => this.buildErrorExpression(expression))
			.value();
	}

	private buildErrorExpression(item: PairExpression): ExpressionError {
		return {
			index: item.index,
			error: ExpressionErrorType.NOT_COMPLETED_OPERATORS
		};
	}

}

app.service('customMathValidationService', CustomMathValidationService);
