import { ValidatorFn, ValidationErrors, UntypedFormGroup } from '@angular/forms';

export function conditionalValidator(predicate, validator): ValidatorFn {
	return (formControl: UntypedFormGroup): ValidationErrors | null => {
		if (!formControl.parent) {
			return null;
		}
		if (predicate()) {
			return validator(formControl);
		}
		return null;
	};
}
