import { AttributeGrouping } from '@cxstudio/reports/entities/attribute-grouping';
import { GroupIdentifierHelper } from '@cxstudio/reports/utils/analytic/group-identifier-helper';
import { BasicWidgetDescriptionBuilder } from './basic-widget-description-builder';

export class ModelDescriptionBuilder extends BasicWidgetDescriptionBuilder {
	
	getDescription = (data?: any) => {
		let metrics = this.properties.selectedMetrics || [];
		let model = this.properties.selectedAttributes && this.properties.selectedAttributes[0]?.displayName;
		let size = this.visual.size
			? this.locale.getString('widgetDescription.modelBubbleSize', { attr: this.utils.getMetricDisplayName(metrics, this.visual.size) })
			: this.locale.getString('widgetDescription.modelBubbleSizeConstant');
		if (size) {
			let selectedAttribute = this.properties.selectedAttributes && this.properties.selectedAttributes[0];
			let topItems = this.utils.getTopItems(data, selectedAttribute, this.visual.size, this.getItemName);
			if (topItems.length >=2) {
				let topBubbles = this.locale.getString('widgetDescription.topBubbles', {
					bubble1: topItems[0],
					bubble2: topItems[1]
				});
				size = this.utils.joinMessageParts([size, topBubbles]);
			}
		}
		let orientation = this.locale.getString(`widgetDescription.${this.visual.orientation}`);
		if (!model || !size || !orientation) {
			return '';
		}
		let total = this.utils.getTotal(this.visual, data);
		let palette = this.utils.getPaletteDescription('bubblePalette', this.visual.color);
		return this.locale.getString('widgetDescription.modelViewerDescription', {
			model,
			size,
			orientation,
			total,
			palette
		});
	};

	private getItemName(dataItem: any, group: AttributeGrouping): string {
		let chunks = [];
		for (let i = 0; i <= dataItem.level; i++) {
			chunks.push(dataItem[GroupIdentifierHelper.generateIdentifier(group, i)]);
		}
		return chunks.join('/');
	}
	
}
