window.CONFIG = {
    "log": false,
    "backend": "cxstudio",
    "gaKey": "UA-113182221-1",
    "mapboxToken": "pk.eyJ1IjoiY2xhcmFicmlkZ2UiLCJhIjoiY2tjd2YzbGJzMGQ3YTM1bnlhMWIxOTlhciJ9.SVGaUZda9siLhEU7_2m_EQ",
    "mapboxWorkerUrl": "mapbox-gl-csp-worker.js",
    "agGridKey": "Using_this_{AG_Grid}_Enterprise_key_{AG-066892}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Qualtrics}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Qualtrics}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{Qualtrics}_need_to_be_licensed___{Qualtrics}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{14_November_2025}____[v3]_[01]_MTc2MzA3ODQwMDAwMA==857dd258707c03f465a480103b460229",
    "dev": false,
    "apiVersion": "226_64112",
    "apiPrefix": "v226_64112/api",
    "commonApiPrefix": "api",
    "version": "7.0.1.226",
    "revision": "64112"
}